import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getUserSalesforceChatTranscript - fetches a single chat transcript
 * from past chats for the current user
 *
 * @arg {string} chatId - The chat id we want to fetch the transcript for
 *
 * @return {Object} - A redux-saga call effect
 */
export const getUserSalesforceChatTranscript = ({ chatId }) =>
  call(websocketFetch, 'get_user_salesforce_chat_transcript', {
    chat_id: chatId,
  });
