/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  GET_PREVIOUS_PREMIUMS_REPORTS,
  GET_PAYROLL_SCHEDULES,
  GET_PREMIUMS_EXPORT_SPLITS,
  SET_PAYROLL_SCHEDULES,
  IMPORT_PAYROLL_SCHEDULE,
  EXPORT_PAYROLL_SCHEDULE,
} from './premiums-report.types';
import { put, call, select, all } from 'redux-saga/effects';
import {
  getPremiumsReport,
  getPreviousPremiumsReports,
  getPremiumsExportSplits,
  getPayrollSchedules,
  setPayrollSchedule,
  exportPayrollSchedule,
} from 'common/services';
import { importPayrollSchedule } from 'common/services/payroll';

import {
  ANALYTICS_EVENT_LABEL_PREMIUMS,
  REPORT_GENERIC_ERROR,
  REPORT_NO_DATA_ERROR,
  REPORT_NO_DATA_ERROR_REASON,
  ANALYTICS_EVENT_REQUEST_REPORT,
  ANALYTICS_EVENT_REPORT_ERROR,
  ANALYTICS_EVENT_REPORT_SUCCESS,
  ANALYTICS_EVENT_NAME_ADMIN,
  ANALYTICS_EVENT_CATEGORY_REPORTS,
} from '../../reports.constants';
import { selectGroupId } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { selectUserEmail } from '../../reports.selectors';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const getErrorForReason = error =>
  error?.info?.reason === REPORT_NO_DATA_ERROR_REASON
    ? REPORT_NO_DATA_ERROR
    : REPORT_GENERIC_ERROR;

export function* requestPremiumsReport({ payload }) {
  try {
    const groupId = yield select(selectGroupId);
    const userEmail = yield select(selectUserEmail);
    const plan_status = payload.planStatus;
    const previous_report_date = payload.previousReportDate;
    const report_name = payload.payCalendar;
    const pay_period = payload.payPeriod;
    const payroll_schedule_id = payload.scheduleId;
    yield call(
      sendAnalyticsEvent,
      {
        category: ANALYTICS_EVENT_CATEGORY_REPORTS,
        action: ANALYTICS_EVENT_REQUEST_REPORT,
        label: ANALYTICS_EVENT_LABEL_PREMIUMS,
        payload,
      },
      ANALYTICS_EVENT_NAME_ADMIN,
    );
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'PREMIUMS_EXPORT_REQUESTED_COPY',
      values: {
        email: userEmail,
      },
    });
    yield getPremiumsReport({
      group_id: groupId,
      previous_report_date,
      ...(plan_status && { plan_status }),
      ...(report_name && { report_name }),
      ...(pay_period && {
        pay_period,
      }),
      ...(payroll_schedule_id && { payroll_schedule_id }),
    });
    yield call(
      sendAnalyticsEvent,
      {
        category: ANALYTICS_EVENT_CATEGORY_REPORTS,
        action: ANALYTICS_EVENT_REPORT_SUCCESS,
        label: ANALYTICS_EVENT_LABEL_PREMIUMS,
        payload,
      },
      ANALYTICS_EVENT_NAME_ADMIN,
    );
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'PREMIUMS_EXPORT_ERROR_COPY',
    });
    yield call(
      sendAnalyticsEvent,
      {
        category: ANALYTICS_EVENT_CATEGORY_REPORTS,
        action: ANALYTICS_EVENT_REPORT_ERROR,
        label: ANALYTICS_EVENT_LABEL_PREMIUMS,
        payload,
      },
      ANALYTICS_EVENT_NAME_ADMIN,
    );
  }
}

export function* requestPreviousPremiumsReports({ payload }) {
  const { skip, limit, payroll_schedule_id } = payload;
  try {
    yield put(GET_PREVIOUS_PREMIUMS_REPORTS.start());
    const groupId = yield select(selectGroupId);
    const response = yield getPreviousPremiumsReports({
      group_id: groupId,
      skip,
      limit,
      ...(payroll_schedule_id && { payroll_schedule_id }),
    });
    yield put(GET_PREVIOUS_PREMIUMS_REPORTS.success({ ...response, skip }));
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'GET_PREVIOUS_REPORTS_ERROR',
    });
    yield put(
      GET_PREVIOUS_PREMIUMS_REPORTS.error({ error: getErrorForReason(e) }),
    );
  }
}

export function* requestChangeReport({ payload }) {
  try {
    const groupId = yield select(selectGroupId);
    const userEmail = yield select(selectUserEmail);
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'PREMIUMS_EXPORT_REQUESTED_COPY',
      values: {
        email: userEmail,
      },
    });
    const response = yield getPremiumsReport({
      group_id: groupId,
      previous_report_id: payload?.previous_report_id,
    });
    if (response?.info?.report?.status === 'failed') {
      throw Error('Report Generation failed');
    }
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'PREMIUMS_EXPORT_ERROR_COPY',
    });
  }
}

export function* requestPayrollSchedules(action) {
  try {
    yield put(GET_PAYROLL_SCHEDULES.start());
    const groupId = yield action?.payload?.group_id || select(selectGroupId);

    const response = yield getPayrollSchedules({ group_id: groupId });
    yield put(GET_PAYROLL_SCHEDULES.success(response));
  } catch (e) {
    yield put(GET_PAYROLL_SCHEDULES.error(e));
  }
}

export function* requestPremiumsExportSplits(action) {
  try {
    const groupId = yield action?.payload?.group_id || select(selectGroupId);

    const response = yield getPremiumsExportSplits({ group_id: groupId });
    yield put(GET_PREMIUMS_EXPORT_SPLITS.success(response));
  } catch (e) {
    yield put(GET_PREMIUMS_EXPORT_SPLITS.error(e));
  }
}

export function* setPayrollSchedules({ payload }) {
  try {
    const response = yield setPayrollSchedule(payload);
    yield put(SET_PAYROLL_SCHEDULES.success(response));
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'SAVE_PAYROLL_SCHEDULE_SUCCESS',
    });
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* importPayrollSchedules({ payload }) {
  try {
    const groupId = yield select(selectGroupId);

    const response = yield importPayrollSchedule({
      ...payload,
      group_id: groupId,
    });
    if (!response.info.errors) {
      yield put(IMPORT_PAYROLL_SCHEDULE.success(response));
      yield call(toastActions.add, {
        type: TOAST_STATUS.SUCCESS,
        textId: 'IMPORT_PAYROLL_SCHEDULE_SUCCESS',
      });
    } else {
      yield all(
        response.info.errors.map(error =>
          call(toastActions.add, {
            type: TOAST_STATUS.ERROR,
            text: error,
            shouldAutoClose: false,
          }),
        ),
      );
    }
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* exportPayrollSchedules({ payload }) {
  try {
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'REQUESTED_PAYROLL_SCHEDULE_EXPORT',
    });
    const response = yield exportPayrollSchedule(payload);
    yield put(EXPORT_PAYROLL_SCHEDULE.success(response));
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}
