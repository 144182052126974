import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape, FormattedHTMLMessage } from '@leagueplatform/locales';
import { vars, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { InputField } from './input-field.view';
import { Button, StatusMessage } from 'apps/employer-experience/ui-kit';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ERROR,
  SUCCESS,
  URL_PATTERN,
  NUMERIC_PLUS_PATTERN,
  PHONE_CALL,
  URL,
} from '../constants';
import { get, isEqual } from 'lodash';

const ActionCardWrapper = styled(Box)`
  background-color: ${vars.colour.offWhite};
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${vars.colour.mediumGrey};
  margin-top: 1.3rem;
`;

const StyledLabel = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${vars.colour.red};
  min-height: 1.75rem;
`;

const StyledButton = styled(Button)`
  height: 2rem;
  max-width: 1rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2rem;
  border-radius: 0.25rem;
  border: 1px solid;
  margin-right: 0.5rem;
`;

class WalletActionCard extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.toggleLockedView = this.toggleLockedView.bind(this);
    this.saveActionCard = this.saveActionCard.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.removeActionCard = this.removeActionCard.bind(this);
    this.state = {
      action: {
        ...props.action,
        primary: props.isPrimaryAction,
      },
      status: get(props.action, 'status', ''),
      locked: props.locked,
      validationErrors: {
        labelError: null,
        valueError: null,
        typeError: null,
      },
    };
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps(newProps) {
    if (this.props !== newProps) {
      const shouldNotUpdateAction =
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        isEqual(newProps.action, this.props.action) ||
        (!get(newProps.action, 'newBenefitSelected') &&
          get(newProps.action, 'status') !== SUCCESS);
      // Note: don't update action card if:
      // 1. it's the same benefit page
      // 2. it's not supposed to display SUCCESS message

      const action = {
        ...newProps.action,
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        primary: this.props.isPrimaryAction,
      };

      if (newProps.action) {
        this.setState({
          // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
          action: shouldNotUpdateAction ? this.state.action : action,
          locked: newProps.action.edited
            ? newProps.action.locked
            : // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
              this.state.locked,
          status: newProps.action.status,
        });
      } else {
        this.setState({
          action,
        });
      }
    }
  }

  // eslint-disable-next-line react/sort-comp -- FIXME: automatically added for existing issue
  validateFields() {
    const {
      intl: { formatMessage },
    } = this.props;
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    const { label, type, value } = this.state.action;
    let labelError = null;
    let typeError = null;
    let valueError = null;

    if (!label) {
      labelError = formatMessage({ id: 'LABEL_REQUIRED' });
    }

    if (!type) {
      typeError = formatMessage({ id: 'TYPE_REQUIRED' });
    }

    if (!value) {
      valueError = formatMessage({ id: 'VALUE_REQUIRED' });
    } else {
      if (type === URL && !value.match(URL_PATTERN)) {
        valueError = formatMessage({ id: 'VALID_URL_REQUIRED' });
      }

      if (type === PHONE_CALL) {
        const pluses = value.split('+').length - 1;
        const digits = value.length - pluses;
        if (digits <= 3) {
          valueError = formatMessage({ id: 'VALID_PHONE_NUMBER_SHORT' });
        }

        if (digits > 15) {
          valueError = formatMessage({ id: 'VALID_PHONE_NUMBER_LONG' });
        }

        if (pluses > 1) {
          valueError = formatMessage({ id: 'VALID_PHONE_NUMBER_PLUS' });
        }

        if (!value.match(NUMERIC_PLUS_PATTERN)) {
          valueError = formatMessage({ id: 'VALID_PHONE_NUMBER_CHARACTERS' });
        }
      }
    }

    return {
      valueError,
      typeError,
      labelError,
      invalid: labelError || typeError || valueError,
    };
  }

  handleChange(evt, field) {
    if (evt.altered !== false || evt.value) {
      const newValue = field === 'type' ? evt.value : evt.target.value;
      const action = {
        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
        ...this.state.action,
        [field]: newValue,
      };
      this.setState({
        action,
        status: '',
        validationErrors: {
          // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
          ...this.state.validationErrors,
          [`${field}Error`]: null,
        },
      });
    }
  }

  toggleLockedView() {
    // by default, primary actions will not be in locked view
    this.setState({
      // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
      locked: this.props.isPrimaryAction ? false : !this.state.locked,
      status: '',
    });
  }

  saveActionCard() {
    const validationErrors = this.validateFields();
    this.setState({
      validationErrors,
    });
    if (!validationErrors.invalid) {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.onSave(this.state.action);
    }
  }

  cancelChanges() {
    this.setState(
      {
        action: {
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          ...this.props.action,
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          primary: this.props.isPrimaryAction,
        },
        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
        locked: this.props.isPrimaryAction ? false : !this.state.locked,
        status: '',
        validationErrors: {
          labelError: null,
          valueError: null,
          typeError: null,
        },
      },
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      () => this.props.onReset(this.state.action),
    );
  }

  removeActionCard() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.onRemove(this.state.action);
  }

  render() {
    const {
      action,
      locked,
      status,
      validationErrors: { labelError, typeError, valueError },
    } = this.state;
    const { isPrimaryAction, intl, canPrimaryBeRemoved } = this.props;
    const message =
      status === ERROR
        ? intl.formatMessage({ id: 'SAVE_WALLET_ACTION_ERROR_MESSAGE' })
        : intl.formatMessage({ id: 'SAVE_WALLET_ACTION_SUCCESS_MESSAGE' });
    return (
      <ActionCardWrapper>
        <Flex>
          <Box width={1 / 2} pr={1}>
            <InputField
              error={labelError}
              htmlFor="label"
              label="Label"
              value={action.label}
              locked={locked}
              onChange={evt => this.handleChange(evt, 'label')}
              tooltipContent={
                <FormattedMessage id="BENEFIT_WALLET_ACTION_LABEL_TOOLTIP" />
              }
            >
              <FormattedMessage id="LABEL" />
            </InputField>
            <StyledLabel>{labelError}</StyledLabel>
          </Box>
          <Box width={1 / 2} pr={1}>
            <InputField
              error={typeError}
              htmlFor="type"
              label="Type"
              value={action.type}
              locked={locked}
              inputType="select"
              onChange={evt => this.handleChange(evt, 'type')}
              tooltipContent={
                <FormattedMessage id="BENEFIT_WALLET_ACTION_TYPE_TOOLTIP" />
              }
            >
              <FormattedMessage id="TYPE" />
            </InputField>
            <StyledLabel>{typeError}</StyledLabel>
          </Box>
        </Flex>
        <Box width={1 / 2} pr={1}>
          <InputField
            error={valueError}
            htmlFor="value"
            label="Value"
            value={action.value}
            locked={locked}
            onChange={evt => this.handleChange(evt, 'value')}
            tooltipContent={
              <FormattedHTMLMessage id="BENEFIT_WALLET_ACTION_VALUE_TOOLTIP" />
            }
          >
            <FormattedMessage id="VALUE" />
          </InputField>
          <StyledLabel>{valueError}</StyledLabel>
        </Box>

        <Flex>
          {!locked && (
            <StyledButton primary onClick={this.saveActionCard}>
              <FormattedMessage id="SAVE" />
            </StyledButton>
          )}
          {!isPrimaryAction && !locked && (
            <StyledButton secondary onClick={this.cancelChanges}>
              <FormattedMessage id="CANCEL" />
            </StyledButton>
          )}
          {!isPrimaryAction && locked && (
            <Flex>
              <StyledButton primary onClick={this.toggleLockedView}>
                <FormattedMessage id="EDIT" />
              </StyledButton>
              <StyledButton warning onClick={this.removeActionCard}>
                <FormattedMessage id="REMOVE_BENEFIT_CONFIRMATION_REMOVE_BTN" />
              </StyledButton>
            </Flex>
          )}
          {isPrimaryAction && canPrimaryBeRemoved && (
            <StyledButton warning onClick={this.removeActionCard}>
              <FormattedMessage id="REMOVE_BENEFIT_CONFIRMATION_REMOVE_BTN" />
            </StyledButton>
          )}
          <StatusMessage status={status} message={message} />
        </Flex>
      </ActionCardWrapper>
    );
  }
}

WalletActionCard.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    status: PropTypes.string,
    primary: PropTypes.bool,
    index: PropTypes.number,
  }),
  locked: PropTypes.bool,
  onReset: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  isPrimaryAction: PropTypes.bool,
  canPrimaryBeRemoved: PropTypes.bool,
  intl: intlShape.isRequired,
};

WalletActionCard.defaultProps = {
  action: {
    label: '',
    type: '',
    value: '',
    primary: false,
    index: null,
    status: '',
  },
  locked: false,
  isPrimaryAction: false,
  onRemove: null,
  onReset: null,
  canPrimaryBeRemoved: false,
};

export default injectIntl(WalletActionCard);
