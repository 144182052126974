/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getEmployerAuditReport = ({
  groupId: group_id,
  flowType: flow_type,
  contentId: content_id,
  requestDate: request_date,
}) =>
  call(websocketFetch, 'request_report', {
    type: 'audit_report',
    request: {
      group_id,
      flow_type,
      content_id,
      request_date,
    },
  });
