import slugify from 'slugify';
import { unescape } from 'lodash';
import camelize from 'camelize';
import {
  ACTION_TYPES,
  ACTION_IDS,
  TAB_IDS,
  PILL_STATUS,
} from '@leagueplatform/wallet-api';
import {
  GET_WALLET,
  GET_WALLET_DEPENDENT_CARDS,
  VIEW_DEPENDENT_CARD,
  CLOSE_VIEW_DEPENDENT_CARD,
} from './wallet.action-types';

const { COVERAGE, DEPENDENT_INFO, DEPENDENTS, TRANSACTIONS, LEAGUE_CREDIT } =
  TAB_IDS;

export const initialState = {
  isLoading: false,
  ready: false,
  error: null,
  benefitCards: [],
  dependentCards: [],
  currentBenefitCardId: null,
  currentSectionId: null,
  currentDependentId: null,
  dependentCardsLoaded: false,
  enrollmentEndDate: null,
  inEnrollmentPeriod: false,
  isFirstTimeEnrollment: false,
};

/**
 * @function mapNavItemsFromActions Return action and tabs for a given wallet card.
 *
 * These are differentiated by an action's `type`:
 * `content` and `open-url`. Content comes with HTML which is just displayed in a tab
 * while open-url either acts as open phone app, redirect to external or internal tab below
 * wallet.
 * More details in: `modules/wallet/src/common/card-action.constants.js`
 *
 * Special Case:
 * Intercept Card Details action by its `id` and treat is a tab. We needed to do this to
 * support the edit and delete functionality on the card details display which
 * isn't supported via the backend generate HTMl and web's wallet.
 *
 */
export const mapNavItemsFromActions = status => action => {
  const slugifySettings = {
    remove: /[$*_+~.()'"!:@%]/g,
    lower: true,
  };
  const isCardInfo = action.id === ACTION_IDS.CARD_INFO;

  if (action.type === ACTION_TYPES.CONTENT && !isCardInfo) {
    const sectionId = slugify(action.name, slugifySettings);
    return {
      ...action,
      navItemType: 'tab',
      key: sectionId,
      sectionId,
    };
  }
  const urlParts = action.url && action.url.split('/');
  const lastUrlPart =
    urlParts && urlParts.length > 0 ? urlParts[urlParts.length - 1] : '';
  const isTab =
    isCardInfo ||
    [
      COVERAGE,
      DEPENDENT_INFO,
      DEPENDENTS,
      TRANSACTIONS,
      LEAGUE_CREDIT,
    ].includes(lastUrlPart);
  const key = isCardInfo
    ? TAB_IDS.CARD_INFO
    : slugify(lastUrlPart, slugifySettings);

  const result = {
    ...action,
    name: unescape(action.name),
    key,
    sectionId: key,
    navItemType: isTab ? 'tab' : 'action',
    isModifiedAction: isTab,
    status,
    urlType:
      action.url && action.url.indexOf('http') === 0 ? 'external' : 'internal',
  };

  return result;
};

const getCardType = type => {
  switch (type) {
    case 'psa':
      return 'lsa';
    case 'drugs':
      return 'drug';
    case 'healthPlan':
      return 'health_plan';
    default:
      return type;
  }
};

/**
 * Cards have their images urls point absolutely to prod, which isn't great
 * Instead, use the absolute url for local and otherwise point use relative URL
 * */

export const updateCardHtmlUrls = html => {
  if (!html) return '';
  return html.replace(/https:\/\/app\.league\.com\/static/g, '/static');
};

const OLD_BENEFITS_TYPE_MAP = {
  psa: 'LIFESTYLE_SPENDING_ACCOUNT',
  hsa: 'HEALTH_SPENDING_ACCOUNT',
  hra: 'HEALTH_REIMBURSEMENT_ARRANGEMENT',
  healthPlan: 'HEALTH_INSURANCE',
  travel: 'TRAVEL',
  drugs: 'DRUGS',
};

const oldBenefitsOrder = ['health_plan', 'hra', 'hsa', 'lsa', 'travel', 'drug'];

const oldCardTypes = ['psa', 'hsa', 'hra', 'healthPlan', 'travel', 'drugs'];

export const transformOldBenefitsModelCards = group => {
  const cards = [];
  if (group) {
    oldCardTypes.forEach(type => {
      const card = group[type];
      const cardType = getCardType(type);
      if (card)
        cards.push({
          ...card,
          id: cardType,
          nameTranslationId: OLD_BENEFITS_TYPE_MAP[type],
          productType: cardType,
          type: cardType,
        });
    });
  }

  return cards;
};

export const deriveCardState = card => {
  const startDate = new Date(card.startDate || card.policyStartDate);
  const endDate = new Date(card.endDate || card.policyEndDate);
  const inactiveDate = card.inactiveDate ? new Date(card.inactiveDate) : null;
  const { status } = card;

  return {
    startDate,
    endDate,
    status,
    inactiveDate,
  };
};

/**
 * Note: treat this card as "active"
 */
const transformLeagueCreditData = data => {
  const mapNavItemsWithStatus = mapNavItemsFromActions(data.status);
  return {
    ...data,
    id: 'league-credit',
    actions: data.actions?.map(mapNavItemsWithStatus),
    productType: 'promo_code',
    type: 'personal_store_credit_balance',
    nameTranslationId: 'LEAGUE_CREDIT',
    cardHtml: updateCardHtmlUrls(data.renderedTemplate?.front),
    status: PILL_STATUS.ACTIVE,
  };
};

export const transformOldBenefitsDependentCards = group => {
  const cards = [];
  if (group) {
    oldCardTypes.forEach(type => {
      const cardDependents = group[type];
      if (cardDependents) {
        cardDependents.forEach(cardDependent => {
          cards.push({
            ...cardDependent,
            id: getCardType(type),
          });
        });
      }
    });
  }

  return cards.concat.apply([], cards);
};

export const normalizeBenefitCard = card => {
  const cardState = deriveCardState(card);
  const mapNavItemsWithStatus = mapNavItemsFromActions(card.status);
  return {
    ...card,
    ...cardState,
    cardHtml: updateCardHtmlUrls(card.renderedTemplate?.front),
    actions: card.actions?.map(mapNavItemsWithStatus),
  };
};

export const normalizeWalletContent = walletData => {
  const { groups, benefitCards, personalStoreCreditBalance } = walletData;

  let modifiedBenefitCards = [];
  let modifiedGroups = [];
  if (benefitCards) {
    modifiedBenefitCards = benefitCards.map(normalizeBenefitCard);
  }

  if (Array.isArray(groups)) {
    const group = groups[0];
    if (group) {
      modifiedGroups = transformOldBenefitsModelCards(group)
        .map(card => {
          const cardState = deriveCardState(card);
          const mapNavItemsWithStatus = mapNavItemsFromActions(card.status);
          return {
            ...card,
            ...cardState,
            cardHtml: updateCardHtmlUrls(card.renderedTemplate.front),
            actions: card.actions.map(mapNavItemsWithStatus),
          };
        })
        .sort((a, b) => {
          return (
            oldBenefitsOrder.indexOf(a.type) - oldBenefitsOrder.indexOf(b.type)
          );
        });
    }
  }

  const leagueCredit = transformLeagueCreditData(personalStoreCreditBalance);

  return {
    benefitCards: [...modifiedBenefitCards, ...modifiedGroups, leagueCredit],
  };
};

export const applyGetWallet = (state, action) => {
  const info = camelize((action.payload || {}).info);
  const wallet = info ? normalizeWalletContent(info) : {};

  return {
    ...state,
    isLoading: false,
    ready: true,
    benefitCards: wallet.benefitCards,
    currentBenefitCardId: state.currentBenefitCardId || null,
    inEnrollmentPeriod: info.inEnrollmentPeriod,
    isFirstTimeEnrollment: info.isFirstTimeEnrollment,
    enrollmentEndDate: info.enrollmentEndDate
      ? new Date(info.enrollmentEndDate)
      : null,
    selectionStatus: info.selectionStatus,
  };
};

const applyGetWalletError = (state, action) => {
  return {
    ...state,
    isLoading: false,
    ready: true,
    dependentCardsLoaded: false,
    error: camelize(action.payload.info),
  };
};

const applyWalletDependentCards = (state, action) => {
  const info = camelize((action.payload || {}).info);
  const { benefitCards, groups } = info;

  let cardsToProcess = [];
  if (benefitCards) {
    cardsToProcess = benefitCards;
  } else if (Array.isArray(groups)) {
    const oldBenefits = transformOldBenefitsDependentCards(groups[0]);
    cardsToProcess = [...oldBenefits];
  }

  const dependentCards = cardsToProcess.map(card => ({
    dependentId: card.dependentId,
    cardHtml: updateCardHtmlUrls(card.renderedTemplate.front),
    benefitId: card.id,
  }));

  return {
    ...state,
    dependentCardsLoaded: true,
    dependentCards,
  };
};

const applyViewDependentCard = (state, action) => {
  return {
    ...state,
    currentDependentId: action.dependentId,
  };
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_WALLET.STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_WALLET_DEPENDENT_CARDS.STARTED:
      return {
        ...state,
        dependentCardsLoaded: false,
      };
    case GET_WALLET.SUCCEEDED:
      return applyGetWallet(state, action);
    case GET_WALLET.ERRORED:
    case GET_WALLET_DEPENDENT_CARDS.ERRORED:
      return applyGetWalletError(state, action);
    case GET_WALLET_DEPENDENT_CARDS.SUCCEEDED:
      return applyWalletDependentCards(state, action);
    case VIEW_DEPENDENT_CARD:
      return applyViewDependentCard(state, action);
    case CLOSE_VIEW_DEPENDENT_CARD:
      return {
        ...state,
        currentDependentId: null,
      };
    default:
      return state;
  }
};

export default reducer;
