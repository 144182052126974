import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal, Backdrop } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Button } from 'apps/employer-experience/ui-kit';
import { FormattedMessage } from 'react-intl';

export const UpdateContentModal = ({
  isOpen,
  onUpdateContent,
  toggleModal,
  modalHeader,
  confirmButtonId,
  children,
  productName,
}) =>
  isOpen && (
    <Backdrop>
      <ConfirmationModal
        headerText={
          <FormattedMessage
            id={modalHeader}
            values={{
              productName,
            }}
          />
        }
        footerContent={
          <Flex>
            <Button confirmModal cancel onClick={toggleModal}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button confirmModal primary onClick={onUpdateContent}>
              <FormattedMessage id={confirmButtonId} />
            </Button>
          </Flex>
        }
      >
        {children}
      </ConfirmationModal>
    </Backdrop>
  );

UpdateContentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onUpdateContent: PropTypes.func.isRequired,
  modalHeader: PropTypes.string.isRequired,
  confirmButtonId: PropTypes.string.isRequired,
  productName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

UpdateContentModal.defaultProps = {
  productName: '',
};
