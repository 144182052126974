import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  BodyOne,
  HeadingTwo,
  SecondaryButton,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';

class ActionConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.actionOptions = {};

    this.onActionConfirmed = this.onActionConfirmed.bind(this);
    this.updateActionOptions = this.updateActionOptions.bind(this);
    this.close = this.close.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps({ open, updatingEmployees }) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.open !== open) {
      this.setState({ open });
    }

    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (!updatingEmployees && !!this.props.updatingEmployees) {
      this.close();
    }
  }

  onActionConfirmed() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.action(this.actionOptions);
  }

  close() {
    this.setState({ open: false });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.close();
  }

  updateActionOptions(options = {}) {
    this.actionOptions = { ...this.actionOptions, ...options };
  }

  render() {
    this.actionOptions = {};
    const { open } = this.state;
    const {
      children,
      header,
      description,
      cancelCopy,
      confirmCopy,
      updatingEmployees,
      actionFormType,
    } = this.props;

    return (
      <div>
        <Modal
          size="small"
          open={open}
          onClose={this.close}
          className="modal-employees-action-confirmation"
        >
          <HeadingTwo>{header}</HeadingTwo>
          {description ? (
            <ModalContent>
              <BodyOne>{description}</BodyOne>
            </ModalContent>
          ) : null}
          {children ? (
            <ModalContent className="modal-employees-action-confirmation__content">
              {children({
                update: this.updateActionOptions,
                formType: actionFormType,
              })}
            </ModalContent>
          ) : null}
          <ModalButtons>
            <SecondaryButton onClick={this.close} marginRight="one">
              {cancelCopy}
            </SecondaryButton>
            <PrimaryButton
              disabled={!!updatingEmployees}
              onClick={this.onActionConfirmed}
            >
              {confirmCopy}
            </PrimaryButton>
          </ModalButtons>
        </Modal>
      </div>
    );
  }
}

ActionConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  header: PropTypes.string,
  confirmCopy: PropTypes.string,
  description: PropTypes.string,
  cancelCopy: PropTypes.string,
  action: PropTypes.func,
  children: PropTypes.func,
  updatingEmployees: PropTypes.shape({
    type: PropTypes.string,
    payload: PropTypes.shape({
      userIds: PropTypes.arrayOf(PropTypes.string),
      groupId: PropTypes.string,
      effectiveDate: PropTypes.string,
    }),
  }),
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  actionFormType: PropTypes.string.isRequired,
};

ActionConfirmation.defaultProps = {
  description: '',
  cancelCopy: 'Cancel',
  header: '',
  confirmCopy: 'Confirm',
  action: () => {},
  children: null,
  updatingEmployees: null,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  employeesServiceStatus: null,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  actionFormType: '',
};

export default ActionConfirmation;
