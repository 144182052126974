import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map, reject, find } from 'lodash';
import decamelizeKeys from 'decamelize-keys';
import {
  compose,
  withStateHandlers,
  withHandlers,
  lifecycle,
  withProps,
} from 'recompose';
import { Container, Segment, Input, Button } from 'semantic-ui-react';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';

const withDocuments = withStateHandlers(
  ({ value = [] }) => ({ documents: value }),
  {
    onRemoveDocument:
      ({ documents }, { setFieldValue, name }) =>
      documentId => {
        const updatedDocuments = reject(
          documents,
          doc => doc.documentId === documentId,
        );

        setFieldValue(
          name,
          map(updatedDocuments, doc => decamelizeKeys(doc)),
        );

        return {
          documents: updatedDocuments,
        };
      },

    onAddDocument:
      ({ documents }, { setFieldValue, name }) =>
      benefitDocument => {
        if (!find(documents, doc => benefitDocument.id === doc.documentId)) {
          const newDoc = {
            type: 'content_reference',
            documentId: benefitDocument.id,
            title: benefitDocument.content.title,
            content: benefitDocument.content,
          };
          setFieldValue(
            name,
            map([...documents, newDoc], doc => decamelizeKeys(doc)),
          );

          return {
            documents: [...documents, newDoc],
          };
        }

        return {
          documents,
        };
      },
  },
);

const withFileLoading = withStateHandlers(
  ({ loading = false }) => ({ loading }),
  {
    startLoading: () => () => ({ loading: true }),
    stopLoading: () => () => ({ loading: false }),
  },
);

const withFileUploadHandlers = withHandlers({
  handleFileUpload:
    ({ uploadFile, startLoading }) =>
    ({ target, benefitId }) => {
      const file = target.files[0];
      startLoading();
      uploadFile({ file, benefitId });
    },
});

const withBenefitId = withProps(({ values }) => ({
  benefitId: values.id,
}));

const withInitializer = lifecycle({
  componentWillReceiveProps({
    loading,
    uploadedDocument,
    stopLoading,
    onAddDocument,
  }) {
    if (loading && uploadedDocument) {
      stopLoading();
      onAddDocument(uploadedDocument);
      this.props.resetUploadedDocuments();
    }
  },
});

const Documents = ({
  onRemoveDocument,
  handleFileUpload,
  documents,
  loading,
  benefitId,
  isAdmin,
  renderDocument,
}) => {
  return (
    <Container className="container--benefit-documents">
      {documents?.length ? (
        <div>
          {documents.map(doc =>
            renderDocument({
              documentId: doc.document_id,
              onRemoveDocument,
              title: doc.title,
            }),
          )}
        </div>
      ) : (
        <Segment className="segment--benefit-documents__empty">
          No files have been added to this benefit.
        </Segment>
      )}
      {isAdmin && (
        <Button className="button--upload" loading={loading}>
          <span>Upload</span>
          <Input
            type="file"
            onChange={({ target }) => handleFileUpload({ target, benefitId })}
          />
        </Button>
      )}
    </Container>
  );
};

Documents.propTypes = {
  onRemoveDocument: PropTypes.func.isRequired,
  renderDocument: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  benefitId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentId: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

Documents.defaultProps = {
  documents: [],
};

export default compose(
  connect(state => ({ isAdmin: selectUserIsAdmin(state) })),
  withDocuments,
  withFileLoading,
  withFileUploadHandlers,
  withBenefitId,
  withInitializer,
)(Documents);
