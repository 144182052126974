import { createSelector } from 'reselect';
import { selectUser } from './user.selectors';
import { selectUserGroups } from './user-group.selectors';
import {
  CONSUMER,
  ADMIN,
  HR_ADMIN,
  HEALTH_ADMIN,
  CLAIMS_AGENT,
  SUPER_ADMIN,
} from '@leagueplatform/web-common';

/**
 * This is the role selector you are looking for! The role set on
 * `state.user.role` is the canonical role to use for RBAC etc.
 */
export const selectUserRole = createSelector(selectUser, user => user?.role);

const selectGroupsUserIsAdminOf = createSelector(selectUserGroups, groups =>
  groups?.filter(group => group.role === ADMIN && !group.suspended),
);

export const selectUserIsHRAdmin = createSelector(
  selectGroupsUserIsAdminOf,
  groups => !!groups?.length,
);

export const selectUserIsAdmin = createSelector(
  selectUserRole,
  role => role === ADMIN || role === SUPER_ADMIN,
);

export const selectUserIsConsumer = createSelector(
  selectUserRole,
  role => role === CONSUMER,
);

export const selectUserIsHealthAdmin = createSelector(
  selectUserRole,
  role => role === HEALTH_ADMIN,
);

export const selectUserIsClaimsAgent = createSelector(
  selectUserRole,
  role => role && [CLAIMS_AGENT, ADMIN, SUPER_ADMIN].includes(role),
);

export const selectUserRoles = createSelector(
  [selectUserRole, selectUserIsHRAdmin],
  (role, isHRAdmin) => [role, ...(isHRAdmin ? [HR_ADMIN] : [])],
);

const intersection = (array1, array2) =>
  array1.filter(value => array2.includes(value));

export const selectUserHasRole = roles =>
  createSelector(
    selectUserRoles,
    userRoles => intersection(roles, userRoles).length > 0,
  );
