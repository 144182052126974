import {
  GROUP_CREDIT_CONTRACT_FORM_SUBMITTED,
  GROUP_SUBSCRIPTION_RATES_SUBMITTED,
  GROUP_BILLING_CONTACT_FORM_SUBMITTED,
  GROUP_INVOICE_PAYMENT_FORM_SUBMITTED,
  GROUP_BILLING_PREFERENCE_FORM_SUBMITTED,
  RESET_UPDATE_STATUS,
  ADMIN_EMPLOYER_OUTSTANDING_BALANCE,
  HR_EMPLOYER_OUTSTANDING_BALANCE,
} from './types';

export const groupCreditContractFormSubmitted = payload => ({
  type: GROUP_CREDIT_CONTRACT_FORM_SUBMITTED,
  payload,
});

export const groupInvoicePaymentFormSubmitted = payload => ({
  type: GROUP_INVOICE_PAYMENT_FORM_SUBMITTED,
  payload,
});

export const groupBillingPreferenceFormSubmitted = payload => ({
  type: GROUP_BILLING_PREFERENCE_FORM_SUBMITTED,
  payload,
});

export const groupSubscriptionRatesSubmitted = payload => ({
  type: GROUP_SUBSCRIPTION_RATES_SUBMITTED,
  payload,
});

export const groupBillingContactFormSubmitted = payload => ({
  type: GROUP_BILLING_CONTACT_FORM_SUBMITTED,
  payload,
});

export const resetUpdateStatus = payload => ({
  type: RESET_UPDATE_STATUS,
  payload,
});

export const adminGetEmployerOutstandingBalance = payload => ({
  type: ADMIN_EMPLOYER_OUTSTANDING_BALANCE,
  payload,
});

export const hrGetEmployerOutstandingBalance = payload => ({
  type: HR_EMPLOYER_OUTSTANDING_BALANCE,
  payload,
});
