import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from '@leagueplatform/locales';
import {
  selectCanShowPayrollReport,
  selectDigitalBeneficiariesEnabled,
} from './employer-reports.selectors';
import { Tab } from 'semantic-ui-react';
import {
  BenefitsReportContainer,
  EmployeeDemographicsContainer,
  EnrolmentContainer,
  EnrolmentProgressContainer,
} from 'apps/employer-experience/pages/EmployerDetails/employer-reports';
import {
  UsageReportsPage as UsageReport,
  PayrollReportsPage as PayrollReports,
  CarrierAuditsReportsContainer as CarrierAuditsReports,
  DependentsReportsContainer as DependentsReports,
} from 'apps/employer-experience-v2/employer-reports';
import { BeneficiariesReportContainer } from './beneficiaries-report.container';
import SpendingAccountBalanceReport from 'apps/employer-experience-v2/employer-reports/components/spending-account-balance-report';
import { selectCanShowSpendingAccountBalanceReport } from 'apps/employer-experience-v2/employer-reports/reports.selectors';
import { EnrollmentContainer } from 'common/insight-dashboards/enrollment/enrollment.container';
import { SpendingAccountsContainer } from 'common/insight-dashboards/spending-accounts/spending-accounts.container';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';

const getTab = ({ menuItem, Component, ...props }) => ({
  menuItem,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <Component {...props} />
    </Tab.Pane>
  ),
});

export const useEmployerReportsTabs = ({ dashboards, ...props }) => {
  const { formatMessage } = useIntl();
  const { data: isFeatureFlagEnabled } = useFeatureFlagQuery(
    'bi_spending_account_enabled_admin',
  );
  const canShowPayrollReports = useSelector(selectCanShowPayrollReport);
  const canShowSpendingAccountBalanceReport = useSelector(
    selectCanShowSpendingAccountBalanceReport,
  );
  const digitalBeneficiariesEnabled = useSelector(
    selectDigitalBeneficiariesEnabled,
  );
  const tabs = [];

  if (dashboards?.['spending-account']) {
    const newProps = {
      ...props,
      ...{
        dashboards: dashboards?.['spending-account'],
      },
    };
    tabs.push(
      getTab({
        ...newProps,
        Component: SpendingAccountsContainer,
        menuItem: formatMessage({ id: 'SPENDING_ACCOUNTS_INSIGHTS' }),
      }),
    );
  }
  if (isFeatureFlagEnabled) {
    if (canShowSpendingAccountBalanceReport) {
      tabs.push(
        getTab({
          ...props,
          Component: SpendingAccountBalanceReport,
          menuItem: formatMessage({ id: 'SPENDING_ACCOUNT_BALANCE' }),
        }),
      );
    }
    tabs.push(
      getTab({
        ...props,
        Component: UsageReport,
        menuItem: formatMessage({ id: 'USAGE' }),
      }),
    );
  }

  if (dashboards?.enrollment) {
    const newProps = {
      ...props,
      ...{
        dashboards: dashboards.enrollment,
      },
    };
    tabs.push(
      getTab({
        ...newProps,
        Component: EnrollmentContainer,
        menuItem: formatMessage({ id: 'ENROLLMENT' }),
      }),
    );
  }

  if (!dashboards?.enrollment) {
    tabs.push(
      getTab({
        ...props,
        Component: EnrolmentProgressContainer,
        menuItem: formatMessage({ id: 'ENROLLMENT_PROGRESS' }),
      }),
    );
  }

  tabs.push(
    getTab({
      ...props,
      Component: EnrolmentContainer,
      menuItem: formatMessage({ id: 'ENROLLMENT_DETAILS' }),
    }),
  );

  if (canShowPayrollReports) {
    tabs.push(
      getTab({
        ...props,
        Component: PayrollReports,
        menuItem: formatMessage({ id: 'PAYROLL' }),
      }),
    );
  }

  if (digitalBeneficiariesEnabled) {
    tabs.push(
      getTab({
        ...props,
        Component: BeneficiariesReportContainer,
        menuItem: formatMessage({ id: 'BENEFICIARIES' }),
      }),
    );
  }

  tabs.push(
    getTab({
      ...props,
      Component: EmployeeDemographicsContainer,
      menuItem: formatMessage({ id: 'EMPLOYEE_DEMOGRAPHICS' }),
    }),
  );

  tabs.push(
    getTab({
      ...props,
      Component: BenefitsReportContainer,
      menuItem: formatMessage({ id: 'BENEFITS' }),
    }),
  );

  tabs.push(
    getTab({
      ...props,
      Component: CarrierAuditsReports,
      menuItem: formatMessage({ id: 'CARRIER_AUDITS' }),
    }),
  );

  tabs.push(
    getTab({
      ...props,
      Component: DependentsReports,
      menuItem: formatMessage({ id: 'DEPENDENTS' }),
    }),
  );

  return tabs;
};
