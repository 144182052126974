import { selectApp } from 'apps/employer-experience-v2/app.selectors';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const selectSpendingAccountBalanceReport = createSelector(
  selectApp,
  get('employerSpendingAccountBalanceReport'),
);

export const selectSpendingAccountBalanceReportReady = createSelector(
  selectSpendingAccountBalanceReport,
  get('spendingAccountBalanceReportReady'),
);

export const selectSpendingAccountBalanceReportError = createSelector(
  selectSpendingAccountBalanceReport,
  get('spendingAccountBalanceReportError'),
);

export const selectSpendingAccountBalanceReportLoading = createSelector(
  selectSpendingAccountBalanceReport,
  get('spendingAccountBalanceReportLoading'),
);
