import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const validatePaymentCredentials = ({
  accountNumber,
  routingNumber,
  bankingInfoType,
}) =>
  call(websocketFetch, 'validate_payment_credentials', {
    account_number: accountNumber,
    routing_number: routingNumber,
    banking_info_type: bankingInfoType,
  });
