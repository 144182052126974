import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getUserRewardsHistory - fetches a list of a user's rewards history
 *
 * @arg {string} userId - A user's id
 * @arg {number} offset - The paging offset
 * @arg {number} limit - The paging limit
 *
 * @return {Object} - A redux-saga call effect
 */
export const getUserRewardsHistory = ({
  userId,
  offset = 0,
  limit = 25,
} = {}) =>
  call(websocketFetch, 'get_user_rewards_history', {
    user_id: userId,
    offset,
    limit,
  });
