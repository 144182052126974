import { request } from 'common/websocket-redux';
import { fork, all, takeLatest, call } from 'redux-saga/effects';
import { rewardsHistorySaga } from './pages/RewardsHistoryPage/rewards-history-page.saga';
import { purchaseHistorySaga } from './pages/PurchaseHistoryPage/purchase-history-page.saga';
import { resetAuthFactors } from 'common/services/reset-auth-factors.service';
import { RESET_AUTH_FACTORS } from './member-tooling.actions';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

function* showAuthSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'RESET_MFA_SUCCESS',
  });
}

function* showAuthErrorToast(action) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: action.payload?.info?.reason,
  });
}

function* requestResetAuthFactors({ payload: { userId } }) {
  yield request(RESET_AUTH_FACTORS, resetAuthFactors(userId));
}

export function* memberToolingSaga() {
  yield takeLatest(RESET_AUTH_FACTORS.BASE, requestResetAuthFactors);
  yield takeLatest(RESET_AUTH_FACTORS.SUCCEEDED, showAuthSuccessToast);
  yield takeLatest(RESET_AUTH_FACTORS.ERRORED, showAuthErrorToast);
  yield all([fork(rewardsHistorySaga), fork(purchaseHistorySaga)]);
}
