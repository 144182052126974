import { SET_FEE, RESET_STATUS } from './fees.action-types';

export const saveFee = (payload, params) => {
  return {
    type: SET_FEE.BASE,
    payload,
    params,
  };
};

export const resetStatus = () => {
  return {
    type: RESET_STATUS,
  };
};
