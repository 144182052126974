import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function calculateBenefitCost -
 *
 * There are more possible keys than the ones listed below but these are the ones that we care about in the new
 * enrollment experience
 *
 * @arg {string} groupId  - The employee's employer group ID
 * @arg {string} planId  - plan ID the employee is enrolling in
 * @arg {object} benefit - the benefit to calculate the cost of
 * @arg {string} benefit.benefit_id - the benefit ID for the benefit cost you want to calculate
 * @arg {[]Object} benefit.amounts - array of contribution amounts
 *
 * @returns {Object} - An object
 */

export const calculateBenefitCost = ({ groupId, planId, benefit }) =>
  call(websocketFetch, 'calculate_benefit_cost', {
    group_id: groupId,
    plan_id: planId,
    benefit,
  });
