import {
  colour,
  fontSizeInPx,
  StyledComponentRefWrapper,
} from '@leagueplatform/ui-kit';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BreadcrumbLinkItem = styled(StyledComponentRefWrapper(Link))`
  font-weight: normal;
  font-size: ${fontSizeInPx(2)};
  color: ${colour('navyDark')};
  text-decoration: none;
`;
