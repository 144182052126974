import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  TextInput,
  HeadingThree,
  HeadingOne,
  BodyOneSecondary,
  Link,
  Label,
  LabelText,
  Radio,
  ErrorMessage,
  ChoiceText,
  SubtitleOne,
  PrimaryButton,
  genesisStyled,
  BodyTwo,
  Image,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import {
  breakpoints,
  MultiSelect as UiKitMultiSelect,
  DateInput,
  Responsive,
  DottedTooltipModal,
  TooltipHeader,
} from '@leagueplatform/ui-kit';
import {
  getPostalCodeTranslationId,
  getCityTranslationId,
  getProvinceTranslationId,
  isPhoneNumber,
  CANADA,
  UNITED_STATES,
} from '@leagueplatform/web-common';
import { useValidationSchema } from 'common/json-schema-form';
import {
  isZipCode,
  isPostalCode,
} from 'common/adaptive-forms/validation/rules';
import {
  genderIdentityOptions,
  pronounOptions,
  countryOptions,
} from './dependent-profile-form-helpers';
import { FEMALE, MALE } from 'common/state/user/sex.constants';
import dependentUserSchema from './dependent-user.schema.json';
import { Logo } from 'common/components/Logos/Logo';
import ERROR_ICON from 'apps/public/assets/error-circle.svg';
import { SIGN_OUT } from 'apps/auth/auth.actions';
import { dependentProfileFormSubmitted } from '../enroll-dependent.actions';

const FieldRow = ({ children }) => (
  <Flex
    marginBottom="two"
    width={1}
    flexDirection={{ _: 'column', tablet: 'row' }}
  >
    {children}
  </Flex>
);

FieldRow.propTypes = {
  children: PropTypes.node.isRequired,
};

// Override ui-kit MultiSelect CSS to look like Genesis inputs
const MultiSelect = genesisStyled(UiKitMultiSelect)`
  .ui-kit-multi-select__placeholder {
    color: ${({ theme }) => theme.colors.onSurface.text.subdued};
  }
  .ui-kit-multi-select__control {
    border-color: ${({ theme }) => theme.colors.onSurface.border.default};
  }
`;

const Tooltip = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Responsive breakpoint={breakpoints.lapAndAbove}>
        <TooltipHeader
          tooltipLink={formatMessage({ id: 'WHY_DO_YOU_ASK' })}
          tooltipText={
            <Box fontSize="body2" color="onSurface.text.primary" minWidth={350}>
              {children}
            </Box>
          }
          verticalPosition="bottom: 3rem;"
          pointerPosition={50}
          inline
        />
      </Responsive>
      <Responsive breakpoint={breakpoints.belowLap}>
        <DottedTooltipModal
          label={formatMessage({ id: 'WHY_DO_YOU_ASK' })}
          content={children}
          inline
        />
      </Responsive>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

const emptyValues = {
  first_name: '',
  last_name: '',
  preferred_name: '',
  sex: '',
  gender_identity: [],
  pronouns: [],
  date_of_birth: undefined,
  language: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  postal: '',
  country: CANADA,
  phone_number: '',
};

function validate(values) {
  const errors = {};
  if (values.country === UNITED_STATES && !isZipCode(null, values.postal)) {
    errors.postal = 'Invalid zip code';
  }
  if (values.country === CANADA && !isPostalCode(null, values.postal)) {
    errors.postal = 'Invalid postal code';
  }
  if (values.date_of_birth > new Date()) {
    errors.date_of_birth = 'Invalid date of birth';
  }
  if (!isPhoneNumber(values.phone_number)) {
    errors.phone_number = 'Invalid phone number';
  }
  return errors;
}

const useTranslatedOptions = options => {
  const { formatMessage } = useIntl();
  return options.map(option => ({
    ...option,
    label: formatMessage({ id: option.label }),
  }));
};

export function DependentProfileForm({ initialValues }) {
  const { formatMessage, formatHTMLMessage } = useIntl();
  const dispatch = useDispatch();
  const validationSchema = useValidationSchema({
    schema: dependentUserSchema,
  });
  const translatedGenderIdentityOptions = useTranslatedOptions(
    genderIdentityOptions,
  );
  const translatedPronounOptions = useTranslatedOptions(pronounOptions);
  const translatedCountryOptions = useTranslatedOptions(countryOptions);

  const onSubmit = values => dispatch(dependentProfileFormSubmitted(values));

  return (
    <Formik
      initialValues={initialValues ?? emptyValues}
      onSubmit={onSubmit}
      validate={validate}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        submitCount,
      }) => {
        function handleMultiSelectChange(name, selectedOptions, action) {
          if (!action) return;
          setFieldValue(name, selectedOptions ?? []);
        }
        const showValidationErrorsBanner = submitCount > 0 && !isEmpty(errors);
        return (
          <Box
            backgroundColor="surface.background.secondary"
            position="relative"
          >
            <Box width={1} position="fixed" zIndex={99}>
              <Flex
                height={56}
                backgroundColor="surface.background.primary"
                boxShadow="card"
                justifyContent="space-between"
                alignItems="center"
                paddingRight="two"
                paddingLeft="one"
              >
                <Logo width={96} height={30} />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
                <Link
                  onClick={() => dispatch(SIGN_OUT.request())}
                  as="button"
                  border="none"
                  backgroundColor="transparent"
                >
                  {formatMessage({ id: 'SIGN_OUT' })}
                </Link>
              </Flex>
              <div role="alert">
                {showValidationErrorsBanner && (
                  <Flex
                    data-testid="error_banner"
                    backgroundColor="critical.background.subdued"
                    borderColor="critical.border.default"
                    borderStyle="solid"
                    borderWidth="thin"
                    borderRadius="medium"
                    paddingY="threeQuarters"
                    paddingX="one"
                    alignItems="center"
                  >
                    <Image
                      src={ERROR_ICON}
                      role="presentation"
                      size={24}
                      marginRight="threeQuarters"
                    />
                    <BodyTwo>
                      {formatMessage(
                        { id: 'DEX_VALIDATION_ERRORS_BANNER' },
                        {
                          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
                          strong: msg => <strong>{msg}</strong>,
                        },
                      )}
                    </BodyTwo>
                  </Flex>
                )}
              </div>
            </Box>
            <Box maxWidth={1024} marginX="auto" paddingY="two">
              <HeadingOne
                marginTop={56}
                marginBottom="two"
                marginLeft={{
                  _: 'one',
                  phone: 'two',
                  laptop: 'none',
                }}
              >
                {formatMessage({ id: 'YOUR_PROFILE' })}
              </HeadingOne>
              <Form>
                <>
                  <Flex
                    flexDirection="column"
                    borderColor="interactive.background.disabled"
                    borderRadius="medium"
                    borderWidth="thick"
                    padding={['one', 'two', 'twoAndHalf']}
                    backgroundColor="surface.background.primary"
                  >
                    <HeadingThree marginBottom="oneAndHalf" as="h2">
                      {formatMessage({ id: 'YOUR_PROFILE_SECTION_HEADING' })}
                    </HeadingThree>
                    <SubtitleOne
                      paddingBottom="one"
                      marginBottom="two"
                      borderBottomWidth="thin"
                      borderBottomColor="interactive.background.disabled"
                      as="h3"
                    >
                      {formatMessage({ id: 'PERSONAL_DETAILS' })}
                    </SubtitleOne>
                    <FieldRow>
                      <Label flexGrow={1} flexBasis={0}>
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({ id: 'LEGAL_FIRST_NAME' })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="first_name"
                          value={values.first_name}
                          error={touched.first_name && errors.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.first_name && errors.first_name && (
                          <ErrorMessage marginTop="quarter">
                            {errors.first_name}
                          </ErrorMessage>
                        )}
                      </Label>
                      <Box width="two" />
                      <Label
                        flexGrow={1}
                        flexBasis={0}
                        marginTop={{ _: 'two', tablet: 'none' }}
                      >
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({ id: 'LAST_NAME' })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="last_name"
                          value={values.last_name}
                          error={touched.last_name && errors.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.last_name && errors.last_name && (
                          <ErrorMessage marginTop="quarter">
                            {errors.last_name}
                          </ErrorMessage>
                        )}
                      </Label>
                    </FieldRow>
                    <FieldRow>
                      <Box flexGrow={1} flexBasis={0}>
                        <Flex
                          marginBottom="quarter"
                          justifyContent="space-between"
                        >
                          <LabelText as="label" htmlFor="preferred_name">
                            {formatMessage({ id: 'WHAT_SHOULD_WE_CALL_YOU' })} (
                            {formatMessage({ id: 'OPTIONAL' })})
                          </LabelText>
                          <Tooltip>
                            {formatMessage({ id: 'PREFERRED_NAME_TOOLTIP' })}
                          </Tooltip>
                        </Flex>
                        <TextInput
                          id="preferred_name"
                          name="preferred_name"
                          value={values.preferred_name}
                          error={
                            touched.preferred_name && errors.preferred_name
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.preferred_name && errors.preferred_name && (
                          <ErrorMessage marginTop="quarter">
                            {errors.preferred_name}
                          </ErrorMessage>
                        )}
                      </Box>
                      <Box width="two" />
                      <Box flexGrow={1} flexBasis={0}>
                        <Box marginTop={{ _: 'two', tablet: 'none' }}>
                          <Flex
                            marginBottom="quarter"
                            justifyContent="space-between"
                          >
                            <LabelText as="label" htmlFor="radio-group-sex">
                              {formatMessage({ id: 'SEX' })}
                            </LabelText>
                            <Tooltip>
                              {formatHTMLMessage({
                                id: 'PROFILE_SEX_TOOLTIP',
                              })}
                            </Tooltip>
                          </Flex>
                          <Flex paddingY="threeQuarters">
                            <Label display="flex" marginRight="one">
                              <Radio
                                name="radio-group-sex-female"
                                value="female"
                                error={touched.sex && errors.sex}
                                checked={values.sex === FEMALE}
                                onBlur={() => setFieldTouched('sex')}
                                onChange={() => setFieldValue('sex', FEMALE)}
                              />
                              <ChoiceText marginLeft="threeQuarters">
                                {formatMessage({ id: 'FEMALE' })}
                              </ChoiceText>
                            </Label>
                            <Label display="flex">
                              <Radio
                                name="radio-group-sex-male"
                                value="male"
                                error={touched.sex && errors.sex}
                                checked={values.sex === MALE}
                                onBlur={() => setFieldTouched('sex')}
                                onChange={() => setFieldValue('sex', MALE)}
                              />
                              <ChoiceText marginLeft="threeQuarters">
                                {formatMessage({ id: 'MALE' })}
                              </ChoiceText>
                            </Label>
                          </Flex>
                          {touched.sex && errors.sex && (
                            <ErrorMessage marginTop="quarter">
                              {errors.sex}
                            </ErrorMessage>
                          )}
                        </Box>
                      </Box>
                    </FieldRow>
                    <Flex
                      flexWrap="wrap"
                      marginBottom="two"
                      paddingX="oneAndHalf"
                      paddingTop="oneAndHalf"
                      paddingBottom="two"
                      justifyContent="center"
                      borderRadius="medium"
                      borderColor="interactive.background.disabled"
                      borderWidth="thin"
                      backgroundColor="surface.background.secondary"
                    >
                      <SubtitleOne
                        textAlign="center"
                        marginBottom="half"
                        as="h3"
                      >
                        {formatMessage({
                          id: 'LEAGUE_SUPPORTS_GENDER_EXPRESSION',
                        })}
                      </SubtitleOne>
                      <Box width={1} marginBottom="one" textAlign="center">
                        <BodyOneSecondary>
                          {formatMessage({
                            id: 'THIS_INFO_WILL_NOT_BE_SHARED',
                          })}
                        </BodyOneSecondary>
                      </Box>
                      <Flex
                        width={1}
                        flexDirection={{ _: 'column', tablet: 'row' }}
                      >
                        <Box flexGrow={1} flexBasis={0}>
                          <Flex
                            marginBottom="quarter"
                            justifyContent="space-between"
                          >
                            <LabelText as="label" htmlFor="gender_identity">
                              {formatMessage({ id: 'GENDER_IDENTITY' })} (
                              {formatMessage({ id: 'OPTIONAL' })})
                            </LabelText>
                            <Tooltip>
                              {formatHTMLMessage({
                                id: 'GENDER_IDENTITY_TOOLTIP',
                              })}
                            </Tooltip>
                          </Flex>
                          <MultiSelect
                            inputId="gender_identity"
                            canCreate
                            options={translatedGenderIdentityOptions}
                            onChange={(selected, action) =>
                              handleMultiSelectChange(
                                'gender_identity',
                                selected,
                                action,
                              )
                            }
                            onBlur={handleBlur}
                          />
                        </Box>
                        <Box width="two" />
                        <Box
                          flexGrow={1}
                          flexBasis={0}
                          marginTop={{ _: 'two', tablet: 'none' }}
                        >
                          <Flex
                            marginBottom="quarter"
                            justifyContent="space-between"
                          >
                            <LabelText as="label" htmlFor="pronouns">
                              {formatMessage({ id: 'PRONOUNS' })} (
                              {formatMessage({ id: 'OPTIONAL' })})
                            </LabelText>
                            <Tooltip>
                              {formatHTMLMessage({
                                id: 'PRONOUNS_TOOLTIP',
                              })}
                            </Tooltip>
                          </Flex>
                          <MultiSelect
                            inputId="pronouns"
                            canCreate
                            options={translatedPronounOptions}
                            onChange={(selected, action) =>
                              handleMultiSelectChange(
                                'pronouns',
                                selected,
                                action,
                              )
                            }
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                    <FieldRow>
                      <Box flexGrow={1} flexBasis={0}>
                        <Label>
                          <Box marginBottom="quarter">
                            <LabelText>
                              {formatMessage({ id: 'DATE_OF_BIRTH' })}
                            </LabelText>
                          </Box>
                          <DateInput
                            name="date_of_birth"
                            value={values.date_of_birth}
                            hasError={Boolean(
                              touched.date_of_birth && errors.date_of_birth,
                            )}
                            onChange={(_, { date, error }) => {
                              setFieldValue(
                                'date_of_birth',
                                error || date,
                                !error,
                              );
                              if (error) setFieldError('date_of_birth', error);
                            }}
                            onBlur={(_, { error }) =>
                              setFieldTouched('date_of_birth', true, !error)
                            }
                          />
                          {touched.date_of_birth && errors.date_of_birth && (
                            <ErrorMessage marginTop="quarter">
                              {errors.date_of_birth}
                            </ErrorMessage>
                          )}
                        </Label>
                      </Box>
                      <Box width="two" />
                      <Box
                        flexGrow={1}
                        flexBasis={0}
                        marginTop={{ _: 'two', tablet: 'none' }}
                      >
                        <Flex
                          marginBottom="quarter"
                          justifyContent="space-between"
                        >
                          <LabelText as="label" htmlFor="language">
                            {formatMessage({ id: 'LANGUAGE_PREFERENCE' })}
                          </LabelText>
                          <Tooltip>
                            {formatHTMLMessage({
                              id: 'PROFILE_LOCALE_TOOLTIP',
                            })}
                          </Tooltip>
                        </Flex>
                        <Select
                          id="language"
                          name="language"
                          flexGrow={1}
                          flexBasis={0}
                          placeholder={formatMessage({ id: 'SELECT_LANGUAGE' })}
                          options={[
                            {
                              label: formatMessage({ id: 'ENGLISH' }),
                              value: 'en',
                            },
                            {
                              label: formatMessage({ id: 'FRENCH' }),
                              value: 'fr',
                            },
                          ]}
                          value={values.language}
                          error={touched.language && errors.language}
                          onChange={selected =>
                            setFieldValue('language', selected.value)
                          }
                          onBlur={() => setFieldTouched('language')}
                        />
                        {touched.language && errors.language && (
                          <ErrorMessage marginTop="quarter">
                            {errors.language}
                          </ErrorMessage>
                        )}
                      </Box>
                    </FieldRow>
                    <SubtitleOne
                      paddingBottom="one"
                      marginBottom="two"
                      borderBottomWidth="thin"
                      borderBottomColor="interactive.background.disabled"
                      as="h3"
                    >
                      {formatMessage({ id: 'ADDRESS' })}
                    </SubtitleOne>
                    <FieldRow>
                      <Label flexGrow={1} flexBasis={0}>
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({ id: 'STREET_ADDRESS' })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="address1"
                          value={values.address1}
                          error={touched.address1 && errors.address1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.address1 && errors.address1 && (
                          <ErrorMessage marginTop="quarter">
                            {errors.address1}
                          </ErrorMessage>
                        )}
                      </Label>
                      <Box width="two" />

                      <Label
                        flexGrow={1}
                        flexBasis={0}
                        marginTop={{ _: 'two', tablet: 'none' }}
                      >
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({ id: 'SUITE_NUMBER' })} (
                            {formatMessage({ id: 'OPTIONAL' })})
                          </LabelText>
                        </Box>
                        <TextInput
                          name="address2"
                          value={values.address2}
                          error={touched.address2 && errors.address2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.address2 && errors.address2 && (
                          <ErrorMessage marginTop="quarter">
                            {errors.address2}
                          </ErrorMessage>
                        )}
                      </Label>
                    </FieldRow>
                    <FieldRow>
                      <Label flexGrow={1} flexBasis={0}>
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({
                              id: getCityTranslationId(values.country),
                            })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="city"
                          value={values.city}
                          error={touched.city && errors.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.city && errors.city && (
                          <ErrorMessage marginTop="quarter">
                            {errors.city}
                          </ErrorMessage>
                        )}
                      </Label>
                      <Box width="two" />

                      <Label
                        flexGrow={1}
                        flexBasis={0}
                        marginTop={{ _: 'two', tablet: 'none' }}
                      >
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({
                              id: getProvinceTranslationId(values.country),
                            })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="province"
                          value={values.province}
                          error={touched.province && errors.province}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.province && errors.province && (
                          <ErrorMessage marginTop="quarter">
                            {errors.province}
                          </ErrorMessage>
                        )}
                      </Label>
                    </FieldRow>
                    <FieldRow>
                      <Label flexGrow={1} flexBasis={0}>
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({
                              id: getPostalCodeTranslationId(values.country),
                            })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="postal"
                          value={values.postal}
                          error={touched.postal && errors.postal}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.postal && errors.postal && (
                          <ErrorMessage marginTop="quarter">
                            {errors.postal}
                          </ErrorMessage>
                        )}
                      </Label>
                      <Box width="two" />
                      <Label
                        flexGrow={1}
                        flexBasis={0}
                        marginTop={{ _: 'two', tablet: 'none' }}
                      >
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({ id: 'COUNTRY' })}
                          </LabelText>
                        </Box>
                        <Select
                          name="country"
                          flexGrow={1}
                          flexBasis={0}
                          options={translatedCountryOptions}
                          error={errors.country}
                          value={values.country}
                          onChange={selected =>
                            setFieldValue('country', selected.value)
                          }
                          onBlur={() => setFieldTouched('country')}
                        />
                        {touched.country && errors.country && (
                          <ErrorMessage marginTop="quarter">
                            {errors.country}
                          </ErrorMessage>
                        )}
                      </Label>
                    </FieldRow>
                    <FieldRow>
                      <Label flexGrow={1} flexBasis={0}>
                        <Box marginBottom="quarter">
                          <LabelText>
                            {formatMessage({ id: 'PHONE_NUMBER' })}
                          </LabelText>
                        </Box>
                        <TextInput
                          name="phone_number"
                          value={values.phone_number}
                          error={touched.phone_number && errors.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.phone_number && errors.phone_number && (
                          <ErrorMessage marginTop="quarter">
                            {errors.phone_number}
                          </ErrorMessage>
                        )}
                      </Label>
                      <Box width="two" />
                      <Box flexGrow={1} flexBasis={0} />
                    </FieldRow>
                  </Flex>
                  <Box
                    marginTop="two"
                    padding="twoAndHalf"
                    borderColor="interactive.background.disabled"
                    borderRadius="medium"
                    borderWidth="thick"
                    backgroundColor="surface.background.primary"
                    textAlign="center"
                  >
                    <SubtitleOne marginBottom="oneAndHalf" as="h3">
                      {formatMessage({ id: 'BY_SUBMITTING_YOU_CERTIFY' })}
                    </SubtitleOne>
                    <PrimaryButton
                      type="submit"
                      onClick={handleSubmit}
                      data-testid="submit_button"
                    >
                      {formatMessage({ id: 'SUBMIT' })}
                    </PrimaryButton>
                  </Box>
                </>
              </Form>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}

DependentProfileForm.propTypes = {
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    preferred_name: PropTypes.string,
    sex: PropTypes.string,
    gender_identity: PropTypes.arrayOf(PropTypes.string),
    pronouns: PropTypes.arrayOf(PropTypes.string),
    date_of_birth: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    language: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postal: PropTypes.string,
    country: PropTypes.string,
    phone_number: PropTypes.string,
  }),
};

DependentProfileForm.defaultProps = {
  initialValues: null, // To provide a falsy value for the fallback if no values are passed
};
