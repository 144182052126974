import { all, fork, takeLatest, put } from 'redux-saga/effects';
import {
  watchClaimsLogInit,
  watchClaimNoteSubmission,
} from './claim-log/claim-log.saga';
import { claimSettlementSaga } from './settlement';
import { paymentSaga } from './payment';
import { userDocumentsSaga } from 'common/components/user-documents/user-documents.saga';
import {
  CLOSE_UPLOAD_MODAL,
  REQUEST_SET_USER_DOCUMENT,
} from 'common/components/user-documents/user-documents.types';
import { closeUploadModal } from 'common/components/user-documents/user-documents.actions';
import { unpinDocument } from './claim-details.actions';

// Clear pinned document info from store when upload documents modal is closed
export function* watchCloseUploadDocumentModal() {
  yield takeLatest(CLOSE_UPLOAD_MODAL, () => put(unpinDocument()));
}

/**
 * Close upload user document modal on success. Watcher lives here because this component supports
 * upload user document via the claims tools' pin functionality. Hopefully more transparent!
 */
export function* watchRequestUploadUserDocumentSuccess() {
  yield takeLatest(REQUEST_SET_USER_DOCUMENT.SUCCEEDED, () =>
    put(closeUploadModal()),
  );
}

export function* claimDetailsSaga() {
  yield all([
    fork(watchClaimsLogInit),
    fork(watchClaimNoteSubmission),
    fork(claimSettlementSaga),
    fork(paymentSaga),
    fork(userDocumentsSaga),
    fork(watchCloseUploadDocumentModal),
    fork(watchRequestUploadUserDocumentSuccess),
  ]);
}
