import { get as getFrom } from 'lodash';
import { flow, get, map } from 'lodash/fp';
import { createSelector } from 'reselect';
import { EMPLOYER_STATUS_TYPE_MAP } from 'common/constants';

export const selectPage = get('apps.employer-experience.pages.employers');

export const selectEmployers = createSelector(
  selectPage,
  flow([
    get('employers'),
    map(employer => ({
      groupId: employer.groupId,
      dateCreated: employer.dateCreated,
      groupName: employer.group.name,
      status:
        employer.group.employer && employer.group.employer.status
          ? employer.group.employer.status
          : employer.state,
      statusTranslationKey:
        EMPLOYER_STATUS_TYPE_MAP[
          employer.group.employer && employer.group.employer.status
            ? employer.group.employer.status
            : employer.state
        ],
      newBenefits: getFrom(employer, 'group.employer.newBenefits') || false,
    })),
  ]),
);
export const selectLoading = createSelector(selectPage, get('loading'));

export const selectError = createSelector(selectPage, get('error'));
