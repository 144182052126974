/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import {
  request,
  CONFIGURE_CONNECTION,
  websocketSaga,
} from 'common/websocket-redux';
import { getGroupPreEnrollmentConfig } from './services/get-group-pre-enrollment-config.service';
import { addUserToAnonymousGroup } from './services/add-user-to-anonymous-group.service';
import { verifyPreEnrollmentUser } from './services/verify-pre-enrollment-user.service';
import {
  GET_GROUP_PRE_ENROLLMENT_CONFIG,
  ELIGIBILITY_TOOLTIP_OPENED,
  ELIGIBILITY_SELECTED,
  VERIFICATION_FORM_SUBMITTED,
  VERIFY_ACCOUNT,
  IDENTIFIER_HINT_TOGGLED,
  ACCOUNT_FIELDS_INVALIDATED,
  SIGN_IN_SELECTED,
  HELP_EMAIL_SELECTED,
  ELIGIBILITY_PAGE_LIFECYCLE,
  VERIFICATION_PAGE_LIFECYCLE,
  INVITE_NOT_VERIFIED_PAGE_LIFECYCLE,
  INVITE_EXPIRED_PAGE_LIFECYCLE,
} from './invite-access.actions';
import {
  ACCOUNT_NOT_VERIFIED,
  NOT_IN_ENROLLMENT_PERIOD,
} from './invite-access-constants';
import {
  getAccountActivePath,
  getLeagueStartUrl,
  getVerificationPath,
} from './invite-access-paths';
import {
  reportPageVisit,
  reportVerifyAccount,
  reportVerifyInvite,
  reportAccountFound,
  reportInviteVerified,
  reportAccountNotFoundError,
  reportInviteNotVerifiedError,
  reportOutOfEnrollmentError,
  reportIdentifierHintToggle,
  reportAccountFieldErrors,
  reportSignInClick,
  reportHelpEmailClick,
  reportEligibilityTooltipOpened,
  reportEligibilityValue,
} from './invite-access-analytics';
import { APP_CONFIG } from 'app-config';

export function* handleVerificationFormSubmission(action) {
  const isInviteVerification = Boolean(action?.payload?.invitationId);
  if (isInviteVerification) {
    yield call(reportVerifyInvite, action.payload.invitationId);
  } else {
    yield call(reportVerifyAccount);
  }
  const { employerIdentifier, ...verificationFields } = action.payload;
  yield put(VERIFY_ACCOUNT.start());
  try {
    const response = yield verifyPreEnrollmentUser(verificationFields);
    // Failed to verify account
    if (!get(response, 'info')) {
      if (isInviteVerification) {
        yield call(reportInviteNotVerifiedError, action.payload.invitationId);
      } else {
        yield call(reportAccountNotFoundError);
      }

      return yield put(VERIFY_ACCOUNT.error(ACCOUNT_NOT_VERIFIED));
    }
    // Member is verifying before their enrollment period
    if (get(response, 'info.before_enrollment_period')) {
      yield reportOutOfEnrollmentError();
      const enrollmentStartDate = get(response, 'info.enrollment_start_date');
      return yield put(
        VERIFY_ACCOUNT.error(NOT_IN_ENROLLMENT_PERIOD, enrollmentStartDate),
      );
    }
    // Successfully verified, redirect to Get Started to create account
    if (get(response, 'info.active') === false) {
      yield call(
        isInviteVerification ? reportInviteVerified : reportAccountFound,
        action.payload.invitationId,
      );
      const { email, invitation_id: invitationCode } = response.info;
      window.location = getLeagueStartUrl({ email, invitationCode });
    }
    // Successfully verified, account already active
    if (get(response, 'info.active') === true) {
      yield put(push(getAccountActivePath(employerIdentifier)));
    }
    return yield put(VERIFY_ACCOUNT.success(response));
  } catch (error) {
    return yield put(VERIFY_ACCOUNT.error(error));
  }
}

export function* handleEligibilitySelection(action) {
  const { isEligible, anonymousGroupId, employerIdentifier } = action.payload;
  yield call(reportEligibilityValue, isEligible);
  if (isEligible === true) {
    yield put(push(getVerificationPath(employerIdentifier)));
  }
  if (isEligible === false) {
    const invitationCode = get(
      yield addUserToAnonymousGroup({
        groupId: anonymousGroupId,
        urlSlug: employerIdentifier,
      }),
      'info.invitation_code',
    );
    window.location = getLeagueStartUrl({ invitationCode });
  }
}

function* fetchGroupPreEnrollmentConfig(action) {
  yield request(
    GET_GROUP_PRE_ENROLLMENT_CONFIG,
    getGroupPreEnrollmentConfig({
      urlSlug: action.payload.props.employerIdentifier,
    }),
    action.payload.props ?? {},
  );
  yield;
}

function* initialize(action) {
  yield fork(websocketSaga, { websocketUrl: APP_CONFIG.REACT_APP_API_URL });
  yield take(CONFIGURE_CONNECTION.SUCCEEDED);
  yield fetchGroupPreEnrollmentConfig(action);
  yield fork(analytics);
}

function* analytics() {
  yield takeEvery(GET_GROUP_PRE_ENROLLMENT_CONFIG.SUCCEEDED, reportPageVisit);
  yield takeEvery(ELIGIBILITY_TOOLTIP_OPENED, reportEligibilityTooltipOpened);
  yield takeEvery(IDENTIFIER_HINT_TOGGLED, reportIdentifierHintToggle);
  yield takeEvery(ACCOUNT_FIELDS_INVALIDATED, reportAccountFieldErrors);
  yield takeEvery(SIGN_IN_SELECTED, reportSignInClick);
  yield takeEvery(HELP_EMAIL_SELECTED, reportHelpEmailClick);
}

export function* inviteAccessSaga() {
  yield takeLatest(
    [
      ELIGIBILITY_PAGE_LIFECYCLE.VISITED,
      VERIFICATION_PAGE_LIFECYCLE.VISITED,
      INVITE_NOT_VERIFIED_PAGE_LIFECYCLE.VISITED,
      INVITE_EXPIRED_PAGE_LIFECYCLE.VISITED,
    ],
    initialize,
  );
  yield takeLatest(ELIGIBILITY_SELECTED, handleEligibilitySelection);
  yield takeLatest(
    VERIFICATION_FORM_SUBMITTED,
    handleVerificationFormSubmission,
  );
}
