import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { store } from './invite.store';
import { InviteContainer } from './invite.container';
import { DependentInviteContainer } from './dependent-invite/dependent-invite.container';

export function Invite() {
  return (
    <Provider store={store}>
      <Switch>
        <Route path="/start/:inviteId" component={InviteContainer} />
        <Route
          path="/dependent-invite/:inviteId"
          component={DependentInviteContainer}
        />
      </Switch>
    </Provider>
  );
}

Invite.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      inviteId: PropTypes.string,
    }),
  }).isRequired,
};
