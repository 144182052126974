import PersonBeneficiarySchema from './person-beneficiary.schema.json';
import OrganizationBeneficiarySchema from './organization-beneficiary.schema.json';
import { BeneficiaryAddressField } from './beneficiary-address-field.view';
import { BeneficiaryTrusteeField } from './beneficiary-trustee-field.view';
import { BeneficiaryTooltipDOB } from './beneficiary-tooltip-dob.view';

const PERSON = 'person';
const ORGANIZATION = 'organization';
export const BENEFICIARY_TYPES = {
  PERSON,
  ORGANIZATION,
};

const enhancePersonBeneficiarySchema = () => ({
  ...PersonBeneficiarySchema,
  properties: {
    ...PersonBeneficiarySchema.properties,
    date_of_birth: {
      ...PersonBeneficiarySchema.properties.date_of_birth,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
    },
  },
});

export const schemaByBeneficiaryType = {
  [PERSON]: enhancePersonBeneficiarySchema(),
  [ORGANIZATION]: OrganizationBeneficiarySchema,
};

export const metadataByBeneficiaryType = {
  [PERSON]: {
    fieldOrder: [
      'first_name',
      'middle_name',
      'last_name',
      'national_identification_number',
      'relationship',
      'date_of_birth',
      'revocable',
      'trustee.first_name',
      'trustee.last_name',
      'trustee.company_name',
    ],
    hiddenFields: ['province', 'country'],
    fieldOverrides: {
      trustee: BeneficiaryTrusteeField,
      date_of_birth: BeneficiaryTooltipDOB,
    },
  },
  [ORGANIZATION]: {
    fieldOrder: [
      'legal_name',
      'tax_id',
      'address.address1',
      'address.address2',
      'address.city',
      'address.province',
      'address.country',
      'address.postal',
    ],
    fieldOverrides: {
      address: BeneficiaryAddressField,
    },
  },
};

export const DESIGNATION_TYPES = {
  PRIMARY: 'primary',
  CONTINGENT: 'contingent',
};
