import {
  GET_GROUP_DASHBOARD_URL,
  getGroupDashboardUrl,
} from '@leagueplatform/admin-api';
import { useQuery } from 'react-query';

export const useBillingInsights = (groupId, dashboardId, year, month) => {
  let parameters = {};
  const billingDivisionQuery = Object.fromEntries([
    ...new URLSearchParams(window.location.search),
  ]);

  parameters = {
    year,
    month,
  };
  if (billingDivisionQuery.billing_division_name) {
    parameters = {
      year,
      month,
      ...billingDivisionQuery,
    };
  }

  const { data: dashboardData, isLoading } = useQuery(
    [GET_GROUP_DASHBOARD_URL, groupId],
    () =>
      getGroupDashboardUrl({
        groupId,
        dashboardId,
        parameters,
      }),
  );

  return {
    dashboardData,
    isLoading,
  };
};
