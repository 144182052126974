import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  Box,
  Link,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { UTC } from 'common/constants';

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const Row = props => <Box as="tr" {...props} />;
const HeaderCell = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <SubtitleOne as="th" textAlign="left" paddingY="half" {...props} />
);
const RowCell = props => (
  <BodyOne
    as="td"
    paddingLeft="half"
    paddingY="threeQuarters"
    borderTopWidth="thin"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const ScheduledReportsTable = ({ schedule, groupId }) => {
  const { formatMessage } = useIntl();
  const registeredPeriods =
    schedule?.schedule
      ?.reverse()
      .filter(
        period =>
          period.contributions_result?.contributions_registered ||
          period.payroll_result?.report_id,
      ) ?? [];

  if (!registeredPeriods.length) {
    return <BodyOne>{formatMessage({ id: 'NO_SCHEDULED_REPORTS' })}</BodyOne>;
  }
  return (
    <Box as="table" width={1}>
      <thead>
        <Row>
          <HeaderCell>{formatMessage({ id: 'PAY_PERIOD' })}</HeaderCell>
          <HeaderCell>
            {formatMessage({ id: 'PAY_PERIOD_REGISTER_DATE' })}
          </HeaderCell>
          <HeaderCell />
        </Row>
      </thead>
      <tbody>
        {registeredPeriods.map(period => (
          <Row key={period.label}>
            <RowCell>{period.label}</RowCell>
            <RowCell>
              {period.scheduled_on
                ? format(utcToZonedTime(period.scheduled_on, UTC), 'PPpp')
                : ''}
            </RowCell>
            <RowCell textAlign="right" paddingRight="half">
              <Link
                href={`/app/admin/employers/${groupId}/payroll/scheduled-reports/${schedule.id}/${period.label}`}
              >
                {formatMessage({ id: 'VIEW' })}
              </Link>
            </RowCell>
          </Row>
        ))}
      </tbody>
    </Box>
  );
};

ScheduledReportsTable.propTypes = {
  schedule: PropTypes.shape({
    schedule: PropTypes.arrayOf(
      PropTypes.shape({
        contributions_result: PropTypes.shape({
          contributions_registered: PropTypes.bool,
        }),
        payroll_result: PropTypes.shape({
          report_id: PropTypes.string,
        }),
      }),
    ),
    id: PropTypes.string,
  }),
  groupId: PropTypes.string.isRequired,
};

ScheduledReportsTable.defaultProps = {
  schedule: { schedule: [] },
};
