import { createSelector } from 'reselect';
import { selectEmployerDetails } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { set, get } from 'lodash';

export const selectAddingBenefit = createSelector(
  selectEmployerDetails,
  ({ employerBenefits }) => !!employerBenefits.addingBenefit,
);

export const selectRemovingBenefit = createSelector(
  selectEmployerDetails,
  ({ employerBenefits }) => !!employerBenefits.removingBenefit,
);

export const selectErrorAddingBenefit = createSelector(
  selectEmployerDetails,
  ({ employerBenefits }) => !!employerBenefits.errorAddingBenefit,
);

export const selectBenefitEntities = createSelector(
  selectEmployerDetails,
  ({ employerBenefits }) => employerBenefits.entities.benefits,
);

export const selectBenefitFormData = createSelector(
  selectEmployerDetails,
  ({ employerBenefits }) => employerBenefits.entities.formData,
);

export const selectNormalizedBenefitsForPlanInClass = (
  structure,
  benefitPlanId,
  benefitClassId,
  allBenefitEntities,
) => {
  const planIdsForClass = structure[benefitClassId] || {};
  const benefitIdsForPlan = planIdsForClass[benefitPlanId];
  const benefits = [];
  const normalized = {};
  benefitIdsForPlan.forEach(benefitId => {
    if (allBenefitEntities[benefitId]) {
      benefits.push(allBenefitEntities[benefitId]);
    }
  });

  benefits.forEach(benefit => {
    // need to show multiple benefits of same type for renewal benefits
    if (!normalized[benefit.benefitType]) {
      normalized[benefit.benefitType] = {};
    }
    normalized[benefit.benefitType][benefit.id] = benefit;
  });

  return normalized;
};

/*
TODO:benefit-case-migration-EMP-1087
https://everlong.atlassian.net/browse/EMP-1087
This function exists so that we can use benefit.benefit_type
instead of benefit.benefitType (see selectNormalizedBenefitsForPlanInClass).
It's a part of a temporary solution to the problem of mutated schemas.
*/
export const selectBenefitFormDataForPlanInClass = (
  structure,
  benefitPlanId,
  benefitClassId,
  allBenefitEntities,
) => {
  const planIdsForClass = structure[benefitClassId] || {};
  const benefitIdsForPlan = planIdsForClass[benefitPlanId];
  const benefits = [];
  const normalized = {};
  benefitIdsForPlan.forEach(benefitId => {
    if (allBenefitEntities[benefitId]) {
      benefits.push(allBenefitEntities[benefitId]);
    }
  });

  benefits.forEach(benefit => {
    // need to show multiple benefits of same type for renewal benefits
    if (!normalized[benefit.benefit_type]) {
      normalized[benefit.benefit_type] = {};
    }
    normalized[benefit.benefit_type][benefit.id] = benefit;
  });

  return normalized;
};

const updateBenefitsList = (benefitsByProductType, unsavedBenefits) => {
  const newBenefitsList = unsavedBenefits.reduce(
    (benefitsList, unsavedBenefit) => {
      const productType = unsavedBenefit.productName || unsavedBenefit.fullName;
      const { benefitType } = unsavedBenefit;

      set(
        benefitsList,
        `${productType}`,
        get(benefitsList, `${productType}`, {}),
      );
      return set(benefitsList, `${productType}.${benefitType}`, {
        ...get(benefitsList, `${productType}.${benefitType}`, {}),
        [unsavedBenefit.unsavedId]: unsavedBenefit,
      });
    },
    benefitsByProductType,
  );

  return newBenefitsList;
};

export const selectBenefitsByProductTypeInClass = (
  structure,
  benefitPlanId,
  benefitClassId,
  allBenefitEntities,
  unsavedBenefits,
) => {
  const planIdsForClass = get(structure, `${benefitClassId}`, {});
  const benefitIdsForPlan = planIdsForClass[benefitPlanId];

  const benefits = benefitIdsForPlan.reduce(
    (benefitsList, benefitId) =>
      allBenefitEntities[benefitId]
        ? [...benefitsList, allBenefitEntities[benefitId]]
        : benefitsList,
    [],
  );

  const normalized = benefits.reduce((benefitsList, benefit) => {
    const productType = benefit.productName;
    const { benefitType } = benefit;

    // group benefit by "benefit_product_type" / benefitProductType
    // using "product_name" / "productName" here for the nicer text format of product type
    set(
      benefitsList,
      `${productType}`,
      get(benefitsList, `${productType}`, {}),
    );

    return set(benefitsList, `${productType}.${benefitType}`, {
      ...get(benefitsList, `${productType}.${benefitType}`, {}),
      [benefit.id]: benefit,
    });
  }, {});

  return updateBenefitsList(normalized, unsavedBenefits);
};

export const selectBenefitsForPlanByType = benefitsByProductType => {
  const benefitsByTypeList = Object.values(benefitsByProductType);
  const benefitsList = {};

  benefitsByTypeList.forEach(benefitsByType => {
    Object.assign(benefitsList, benefitsByType);
  });

  return benefitsList;
};

export const selectBenefitTypeSchemas = createSelector(
  selectEmployerDetails,
  ({ benefitTypeSchemas }) => {
    return benefitTypeSchemas;
  },
);

export const selectBenefitTypeSchema = (benefitType, state) =>
  selectBenefitTypeSchemas(state)[benefitType] || {};

export const selectCatalogue = createSelector(
  selectEmployerDetails,
  ({ catalogue }) => {
    return catalogue?.entities?.catalogue ?? {};
  },
);

export const selectUpdatingGroupCategories = createSelector(
  selectEmployerDetails,
  details => {
    return get(details, 'processingCategoriesUpload');
  },
);

export const selectCatalogueOptions = createSelector(
  selectCatalogue,
  catalogue =>
    Object.keys(catalogue).map(benefitType => {
      const { fullName, benefitVendorName } = catalogue[benefitType];
      return {
        benefitType,
        fullName,
        benefitVendorName,
      };
    }),
);

export const selectEmployerBenefitsUiIsLoading = state =>
  state?.apps?.['employer-experience']?.ui?.employerBenefits.isLoading;

export const selectEmployerBenefitsLoadingByPlanId = createSelector(
  (_, { benefitPlanId }) => benefitPlanId,
  selectEmployerBenefitsUiIsLoading,
  (planId, isLoading) => isLoading.employerBenefitsByPlanId[planId] ?? false,
);
