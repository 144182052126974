import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import TimePicker from 'react-time-picker';
import { FormattedMessage } from 'react-intl';
import { DateInput } from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const StyledTimePicker = styled(TimePicker)`
  .react-time-picker__inputGroup__input {
    line-height: 3rem;
    height: initial;
  }
  .react-time-picker__wrapper {
    border: none;
  }

  .react-time-picker__button {
    display: none;
  }
`;

export const AnnualPeriodDate = ({
  value,
  name,
  setDateValue,
  defaultHour,
  defaultMinute,
  employerTzName,
}) => {
  const asMoment = moment.tz(value, employerTzName);
  const momentValue = asMoment.isValid() ? asMoment : undefined;
  return (
    <Flex my={1}>
      <Flex w={7 / 10}>
        <DateInput
          value={
            momentValue &&
            new Date(
              momentValue.year(),
              momentValue.month(),
              momentValue.date(),
              0,
              0,
            )
          }
          name={name}
          onChange={(_, { date: newValue }) => {
            if (newValue) {
              setDateValue(
                moment.tz(
                  [
                    newValue.getUTCFullYear(),
                    newValue.getUTCMonth(),
                    newValue.getUTCDate(),
                    momentValue ? momentValue.hour() : defaultHour,
                    momentValue ? momentValue.minutes() : defaultMinute,
                    0,
                  ],
                  employerTzName,
                ),
              );
            }
          }}
        />
      </Flex>

      {momentValue && (
        <Flex ml={1} w={3 / 10} align="center">
          <FormattedMessage id="TIME_SELECTOR_TEXT_1" />
          <StyledTimePicker
            disableClock
            value={momentValue.format('H:mm:ss') || ''}
            onChange={newValue => {
              if (newValue) {
                const newTime = newValue
                  ? newValue.split(':')
                  : [`${defaultHour}`, `${defaultMinute}`, '0'];
                setDateValue(
                  moment.tz(
                    [
                      momentValue.year(),
                      momentValue.month(),
                      momentValue.date(),
                      ...newTime,
                    ],
                    employerTzName,
                  ),
                );
              }
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

AnnualPeriodDate.propTypes = {
  value: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  setDateValue: PropTypes.func.isRequired,
  defaultHour: PropTypes.number,
  defaultMinute: PropTypes.number,
  employerTzName: PropTypes.string.isRequired,
};

AnnualPeriodDate.defaultProps = {
  value: null,
  defaultHour: 0,
  defaultMinute: 0,
};
