import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import { submitWalletOverview } from '../benefit-wallet.actions';
import {
  selectProviderBenefit,
  selectTemplateVariableOptions,
} from '../benefit-wallet.selectors';
import { OVERVIEW } from '../constants';
import OverviewPresenter from './overview.view';

export const getWalletActions = benefit =>
  get(benefit, 'provider_wallet_action.actions', []);

export const getBenefitOverview = benefit => {
  const actions = getWalletActions(benefit);
  return actions.find(a => a.type === OVERVIEW && !a.benefit_class_id);
};

export const filterWalletOverview = walletActions =>
  walletActions.filter(
    action => action.type !== OVERVIEW && !action.benefit_class_id,
  );

export const createOverview = value => ({
  benefit_class_id: null,
  index: -2,
  label: 'Overview',
  primary: false,
  type: OVERVIEW,
  value,
});

export const useUpdateCode = walletOverviewValue => {
  const [code, setCode] = useState(walletOverviewValue);
  return { code, setCode };
};

export const useToggleModal = ({ code }) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);

  const toggleUpdateModal = useCallback(
    () => setIsUpdateModalOpen(!isUpdateModalOpen),
    [isUpdateModalOpen, setIsUpdateModalOpen],
  );

  const toggleClearModal = useCallback(() => {
    return code && setIsClearModalOpen(!isClearModalOpen);
  }, [code, isClearModalOpen, setIsClearModalOpen]);

  return {
    isUpdateModalOpen,
    isClearModalOpen,
    toggleUpdateModal,
    toggleClearModal,
  };
};

export const overviewHandlerHooks = ({
  code,
  toggleData,
  setCode,
  providerBenefit,
  groupId,
  submitOverview,
  walletOverview,
}) => {
  const { toggleUpdateModal, toggleClearModal } = toggleData;
  const walletActions = getWalletActions(providerBenefit);

  const onClearOverview = () => {
    // clear the content of Overview, and send to API
    const updatedActions = filterWalletOverview(walletActions);

    submitOverview({
      providerId: groupId,
      benefitType: providerBenefit.id,
      actions: updatedActions,
    });

    setCode(''); // clear code content
    toggleClearModal();
  };

  const onUpdateOverview = () => {
    let updatedActions = filterWalletOverview(walletActions);

    if (code) {
      // submit configurable wallet overview, actions and usage instructions
      updatedActions = walletOverview
        ? walletActions.map(action =>
            action.type === OVERVIEW && !action.benefit_class_id
              ? { ...walletOverview, value: code }
              : action,
          )
        : [createOverview(code), ...walletActions];
    }

    submitOverview({
      providerId: groupId,
      benefitType: providerBenefit.id,
      actions: updatedActions,
    });
    toggleUpdateModal();
  };

  return { onClearOverview, onUpdateOverview };
};

export const useOverviewHooks = ({ providerBenefit, ...props }) => {
  const walletOverview = getBenefitOverview(providerBenefit);
  const walletOverviewValue = get(walletOverview, 'value') || '';
  const providerBenefitId = get(providerBenefit, 'id');

  // hooks to update text editor content
  const updateCodeHookData = useUpdateCode(walletOverviewValue);
  const { setCode } = updateCodeHookData;

  // hooks to update clear and update modal
  const toggleModalHooksData = useToggleModal({
    ...updateCodeHookData,
  });

  // hooks for submit and clear handler
  const overviewHandlerHooksData = overviewHandlerHooks({
    toggleData: toggleModalHooksData,
    walletOverview,
    providerBenefit,
    ...updateCodeHookData,
    ...props,
  });

  useEffect(() => {
    setCode(walletOverviewValue);
  }, [setCode, walletOverviewValue, providerBenefitId]);

  return {
    ...updateCodeHookData,
    ...toggleModalHooksData,
    ...overviewHandlerHooksData,
  };
};

export const OverviewContainer = state => {
  const hooks = useOverviewHooks(state);

  const allProps = {
    ...state,
    ...hooks,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <OverviewPresenter {...allProps} />;
};

export const Overview = connect(
  createStructuredSelector({
    providerBenefit: selectProviderBenefit,
    templateVariableOptions: selectTemplateVariableOptions,
  }),
  { submitOverview: submitWalletOverview },
)(OverviewContainer);
