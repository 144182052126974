import React from 'react';
import { useSelector } from 'react-redux';
import { selectDependentById } from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { DependentView } from 'common/employee-dependents/dependent.view';

const useDependentContainer = ({ dependentId, groupId }) => {
  const dependent = useSelector(state =>
    selectDependentById(state, { dependentId, groupId }),
  );

  return { dependent };
};

export const ViewDependent = props => {
  const dependentViewProps = useDependentContainer(props);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <DependentView {...props} {...dependentViewProps} showEdit />;
};
