export default {
  aetnaDental: {
    name: 'aetnaDental',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },

          {
            name: 'network_name',
            span: 4,
          },

          {
            name: 'physical_card',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'in_network_dental_maximum_amount',
            span: 6,
          },

          {
            name: 'in_network_dental_deductible_single',
            span: 6,
          },

          {
            name: 'in_network_dental_deductible_family',
            span: 6,
          },

          {
            name: 'in_network_dental_diagnostic_services',
            span: 6,
          },

          {
            name: 'in_network_dental_preventive_services',
            span: 6,
          },

          {
            name: 'in_network_dental_basic_services',
            span: 6,
          },

          {
            name: 'in_network_dental_major_services',
            span: 6,
          },

          {
            name: 'in_network_orthodontic_services',
            span: 6,
          },

          {
            name: 'out_of_network_dental_maximum_amount',
            span: 6,
          },

          {
            name: 'out_of_network_dental_deductible_single',
            span: 6,
          },

          {
            name: 'out_of_network_dental_deductible_family',
            span: 6,
          },

          {
            name: 'out_of_network_dental_diagnostic_services',
            span: 6,
          },

          {
            name: 'out_of_network_dental_preventive_services',
            span: 6,
          },

          {
            name: 'out_of_network_dental_basic_services',
            span: 6,
          },

          {
            name: 'out_of_network_dental_major_services',
            span: 6,
          },

          {
            name: 'out_of_network_orthodontic_services',
            span: 6,
          },

          {
            name: 'preferred_dental_network_available',
            span: 6,
          },
        ],
      },
    ],
  },
};
