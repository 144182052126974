import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Modal, Backdrop, ClickEater } from '@leagueplatform/ui-kit';

import { Button } from 'common/components/button/button.view';

export const ConfirmRemoveItemModal = ({
  onConfirm,
  onCancel,
  itemName,
  isConfirming,
}) => {
  const { formatMessage } = useIntl();
  return (
    isConfirming && (
      <Backdrop onClose={onCancel}>
        <Modal.Container>
          <ClickEater>
            <Modal.Header>
              <Modal.HeaderTitle>
                {formatMessage({ id: 'CONFIRM_REMOVE_ITEM' }, { itemName })}
              </Modal.HeaderTitle>
            </Modal.Header>
            <Modal.Body>{formatMessage({ id: 'CANT_UNDO' })}</Modal.Body>
            <Modal.Footer>
              <Button onClick={onCancel}>
                {formatMessage({ id: 'CANCEL' })}
              </Button>
              <Button warning ml={2} onClick={onConfirm}>
                {formatMessage({ id: 'YES_REMOVE' })}
              </Button>
            </Modal.Footer>
          </ClickEater>
        </Modal.Container>
      </Backdrop>
    )
  );
};

ConfirmRemoveItemModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
};

ConfirmRemoveItemModal.defaultProps = {
  isConfirming: false,
};
