/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { ENVIRONMENT } from '@leagueplatform/core';
import {
  LOCALHOST,
  TEST2,
  TEST3,
  TEST4,
} from 'common/utilities/app-config-constants';

const APP_CONFIG_OVERRIDES_FEATURE_FLAG = 'rel_webplat_appConfigOverrides';

/**
 * List of APP_CONFIG properties that can be overridden
 * by value set in localStorage
 *
 * Current deny listed APP_CONFIG properties:
 * - REACT_APP_ENV
 */
const overridableEnvKeys = [
  'REACT_APP_ROOT_ROUTE',
  'REACT_APP_API_URL',
  'REACT_APP_REST_API_URL',
  'REACT_APP_LEGACY_REST_API',
  'REACT_APP_CONTENT_SERVER_URL',
  'REACT_APP_WR_URL',
  'REACT_APP_GA',
  'REACT_APP_GA_EE',
  'REACT_APP_GTM_ID',
  'REACT_APP_GTM_AUTH',
  'REACT_APP_GTM_PREVIEW',
  'REACT_APP_REDUX_LOG', // values: 'enabled' / 'disabled'
  'REACT_APP_PRIVACY_LOCK_MAX_IDLE_TIME', // values: string value of a number. e.g. '30000'
  'REACT_APP_CHAT_PRIVACY_LOCK_MAX_IDLE_TIME', // values: string value of a number. e.g. '30000'
  'REACT_APP_ADMIN_PRIVACY_LOCK_MAX_IDLE_TIME', // values: string value of a number. e.g. '30000'
  'REACT_APP_IS_PRIVACY_LOCK_ENABLED', // values: 'true' / 'false'
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_AUTH0_CORE_API_AUDIENCE',
  'REACT_APP_IS_RAWR_ENABLED', // values: 'true' / 'false'
  'REACT_APP_SEGMENT',
];

/**
 * Current deny listed APP_CONFIG properties that can not be overridden via localStorage
 */
const protectedEnvKeys = ['REACT_APP_ENV'];

const allEnvKeys = [...overridableEnvKeys, ...protectedEnvKeys];

/**
 * List of  environments in which
 * APP_CONFIG properties that can be overridden
 * by value set in localStorage
 *
 * Current deny listed environments:
 * - Production
 * - Testprod
 */
const allowedAppConfigOverrideEnvironments = [
  LOCALHOST,
  TEST2,
  TEST3,
  TEST4,
  ENVIRONMENT.STAGING,
];

export const bootTimeAppConfig = {};
// eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
for (const key of allEnvKeys) {
  // eslint-disable-next-line no-underscore-dangle
  bootTimeAppConfig[key] = window.__APP_CONFIG__?.[key] ?? process.env[key];
}

// Only add override functions to the window in allow-listed environments
if (
  allowedAppConfigOverrideEnvironments.includes(bootTimeAppConfig.REACT_APP_ENV)
) {
  /**
   * Function to abstract away complexity of setting an App Config override
   *
   * League devs can call addConfigOverride on the window in order to set a
   * app config override object in localStorage. This app config override
   * will only be accessed in allow-listed environments
   */
  window.top.setAppConfigOverrides = overrideObj =>
    localStorage.setItem(
      APP_CONFIG_OVERRIDES_FEATURE_FLAG,
      JSON.stringify({ ...overrideObj }),
    );

  /**
   * Function to abstract away complexity of getting App Config overrides
   * can be paired with setAppConfigOverrides to merge existing overrides
   * with new overrides
   */
  window.top.getAppConfigOverrides = () =>
    JSON.parse(localStorage.getItem(APP_CONFIG_OVERRIDES_FEATURE_FLAG));

  /**
   * Helper function to abstract away complexity of clearing an App Config override
   */
  window.top.clearAppConfigOverrides = () => {
    localStorage.removeItem(APP_CONFIG_OVERRIDES_FEATURE_FLAG);
  };

  /**
   * Helper function to see all potential overridable environment keys
   */
  window.top.getOverridableEnvKeys = () => overridableEnvKeys;

  /**
   * Helper function to see the current app config
   */
  window.top.getAppConfig = () => APP_CONFIG;
}

const getLocalStorageAppConfigOverrides = () => {
  if (
    !allowedAppConfigOverrideEnvironments.includes(
      bootTimeAppConfig.REACT_APP_ENV,
    )
  ) {
    return {};
  }
  const configOverrides = JSON.parse(
    localStorage.getItem(APP_CONFIG_OVERRIDES_FEATURE_FLAG),
  );
  if (!configOverrides) return {};

  const allowedLocalStorageOverrides = {};
  // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
  for (const key of overridableEnvKeys) {
    if (configOverrides[key]) {
      allowedLocalStorageOverrides[key] = configOverrides[key];
    }
  }
  return allowedLocalStorageOverrides;
};

export const setupAppConfig = () => {
  return {
    ...bootTimeAppConfig,
    ...getLocalStorageAppConfigOverrides(),
  };
};

export const APP_CONFIG = setupAppConfig();
