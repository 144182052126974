import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useIntl } from '@leagueplatform/locales';
import { Modal } from 'apps/employer-experience/ui-kit';
import {
  Box,
  Flex,
  HeadingTwo,
  HeadingThree,
  ErrorMessage,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { selectDefaultBenefitsPerBundle } from '../../../enrollment-config.selectors';

export const DefaultPlanViewerPresenter = ({
  defaultBenefitsPerBundle,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  if (!defaultBenefitsPerBundle) return null;

  return (
    <Modal>
      <Flex minWidth={500} maxWidth={1000} flexDirection="column">
        <HeadingTwo marginBottom="one">
          {formatMessage({ id: 'VIEW_DEFAULT_PLAN' })}
        </HeadingTwo>
        {defaultBenefitsPerBundle.length > 1 && (
          <ErrorMessage
            marginX="half"
            marginY="one"
            padding="half"
            marginRight={0}
            borderRadius="small"
          >
            {formatMessage({ id: 'DEFAULTS_IN_MULTIPLE_BUNDLES_WARNING' })}
          </ErrorMessage>
        )}
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          marginTop="one"
          marginBottom="oneAndHalf"
        >
          {defaultBenefitsPerBundle.length > 0 ? (
            defaultBenefitsPerBundle.map(bundle => (
              <Flex
                key={bundle.bundle_id}
                flexDirection="column"
                marginRight="one"
              >
                <HeadingThree>{bundle.name} Bundle</HeadingThree>
                <Box as="ul">
                  {bundle.defaultBenefits.map(({ id, name, shortName }) => (
                    <Box fontSize="body1" as="li" key={id} marginY="half">
                      <strong>{shortName}</strong> - {name}
                    </Box>
                  ))}
                </Box>
              </Flex>
            ))
          ) : (
            <Flex paddingX="half">
              <strong>
                {formatMessage({ id: 'NO_DEFAULT_BENEFITS_IN_PLAN' })}
              </strong>
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="flex-end" marginTop="two">
          <SecondaryButton onClick={onClose}>
            {formatMessage({ id: 'GOT_IT' })}
          </SecondaryButton>
        </Flex>
      </Flex>
    </Modal>
  );
};

DefaultPlanViewerPresenter.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultBenefitsPerBundle: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ),
};

DefaultPlanViewerPresenter.defaultProps = {
  defaultBenefitsPerBundle: [],
};

export const DefaultPlanViewer = connect(
  createStructuredSelector({
    defaultBenefitsPerBundle: selectDefaultBenefitsPerBundle,
  }),
)(DefaultPlanViewerPresenter);
