import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { FieldWrapper } from 'common/json-schema-form/fields';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { selectAvailableBenefitSetsForCurrentBundle } from '../enrollment-config.selectors';
import { MultiSelect } from '@leagueplatform/ui-kit';

export const LinkedSelectionSetsSetIdsPresenter = ({
  field: { name, value: fieldValue } = {},
  form,
  propSchema,
  benefitSetIdOptions = [],
  ...props
}) => {
  const selectedIds = fieldValue || [];
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <MultiSelect
        name={name}
        options={benefitSetIdOptions}
        placeholder="Add Benefit Set Ids"
        disabled={propSchema.readOnly}
        hasError={form.errors[name]}
        onChange={selectedOptions => {
          const selectedOptionsArray = Array.isArray(selectedOptions)
            ? selectedOptions
            : [];
          form.setFieldValue(
            name,
            selectedOptionsArray.map(option => option.value),
          );
        }}
        value={benefitSetIdOptions.filter(option =>
          selectedIds.includes(option.value),
        )}
      />
    </FieldWrapper>
  );
};

LinkedSelectionSetsSetIdsPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  benefitSetIdOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

LinkedSelectionSetsSetIdsPresenter.defaultProps = {
  benefitSetIdOptions: [],
};

const mapStateToProps = state => {
  const benefitSets = selectAvailableBenefitSetsForCurrentBundle(state);
  return {
    benefitSetIdOptions: benefitSets.map(set => ({
      label: `${set.id} (${set.name})`,
      value: set.id,
    })),
  };
};

export const LinkedSelectionSetsSetIds = connect(mapStateToProps)(
  LinkedSelectionSetsSetIdsPresenter,
);
