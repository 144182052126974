import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { DateInput } from '@leagueplatform/ui-kit';
import {
  Label,
  ErrorMessage,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { InputFieldGroup } from '../elements';

export const DateField = ({ name, label }) => (
  <InputFieldGroup>
    <Field name={name}>
      {({
        field,
        form: { setFieldTouched, setFieldValue },
        meta: { touched, error },
      }) => (
        <Label as="fieldset">
          <LabelText as="legend">{label}</LabelText>
          <DateInput
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...field}
            id="dateOfBirth"
            onBlur={() => setFieldTouched(name, true)}
            onChange={(_, { year, month, day }) => {
              setFieldValue(name, {
                year: Number(year),
                month: Number(month) + 1,
                day: Number(day),
              });
            }}
            hasError={Boolean(touched && error)}
            required
          />
          {touched && error && (
            <ErrorMessage role="alert">{error}</ErrorMessage>
          )}
        </Label>
      )}
    </Field>
  </InputFieldGroup>
);

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
