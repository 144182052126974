import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { copyBenefitPlan } from '../actions';
import { CopyPlanPresenter } from './copy-plan.view';
import { selectBenefitClasses } from '../../../selectors';
import { selectGroupId } from '../../../../selectors';

export const CopyPlan = connect(
  createStructuredSelector({
    benefitClasses: selectBenefitClasses,
    groupId: selectGroupId,
  }),
  { copyBenefitPlan },
)(CopyPlanPresenter);
