import { useCallback, useEffect, useState } from 'react';
import { useFetch } from 'common/hooks/use-fetch/use-fetch.hook';

export const useEmployerLooker = ({ groupId }) => {
  const { fetch, loading, error } = useFetch();
  const [lookerURL, setLookerURL] = useState('');

  /**
   * NOTE: This is only for POC, and should be updated in the future.
   * Instead of using useFetch, these should be done properly
   * using saga, reducers and selectors to get the response, url and error.
   */
  const loadLookerDashboard = useCallback(async () => {
    const response = await fetch({
      messageType: 'generate_signed_url_looker',
      info: {
        group_id: groupId,
      },
    });

    const responseURL = response?.signed_url;
    if (responseURL) {
      setLookerURL(responseURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
  }, [groupId]);

  useEffect(() => {
    loadLookerDashboard();
  }, [loadLookerDashboard, groupId]);

  useEffect(() => {
    return () => {
      setLookerURL('');
    };
  }, [setLookerURL]);

  return {
    lookerURL,
    loading,
    error,
    loadLookerDashboard,
    setLookerURL,
  };
};
