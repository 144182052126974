import React from 'react';
import PropTypes from 'prop-types';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  genesisStyled,
  Box,
  Flex,
  LabelText,
  Label,
} from '@leagueplatform/genesis-commons';
import { Tooltip, TooltipContentV2 } from '@leagueplatform/ui-kit';
import { QuestionIcon } from 'apps/employer-experience/components/question-icon.component';
import { getHRAdminAnalytics } from 'common/utilities/get-hr-admin-analytics';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const LabelWrapper = genesisStyled(Box)`
  & label.field-label {
    white-space: normal;
    overflow: initial;
  }
`;

export const FieldHints = ({ htmlFor, label, tooltip, required, readOnly }) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();
  const showOptional = !required && !readOnly;

  const trackFieldHintAnalyticsEvent = textDetail => {
    if (isInHRAdminPortal) {
      /*
        NOTE1: the input components from common/adaptive-forms are currently being used only
        on the employer-experience side, not on the member-experience.
        There is no easy way to track the onMouseEnter event on a field level because the forms
        on the employer-experience are generated dynamically with "createFormGenerator", as a result,
        we decided to keep the analytics inside this low-level component.

        NOTE2: we are tracking the analytics only for HR Admin for now.
      */
      const getparam = getHRAdminAnalytics();

      return trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HR_ADMIN,
        screen_name: getparam[0],
        sub_product_area: getparam[1] || null,
        detail: 'hover for info',
        text_detail: textDetail,
      });
    }
    return false;
  };

  return (
    <LabelWrapper marginBottom="half">
      <Label htmlFor={htmlFor} className="field-label">
        <Flex>
          <LabelText marginRight="half" lineHeight="none">
            <span title={label}>{label} </span>
          </LabelText>
          {tooltip && (
            <Box marginRight="half">
              <Tooltip>
                <QuestionIcon />
                <TooltipContentV2
                  center
                  trackAnalytics={() => {
                    trackFieldHintAnalyticsEvent(tooltip);
                  }}
                >
                  {tooltip}
                </TooltipContentV2>
              </Tooltip>
            </Box>
          )}
          {showOptional && (
            <span className="field-label__required">optional</span>
          )}
        </Flex>
      </Label>
    </LabelWrapper>
  );
};

FieldHints.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FieldHints.defaultProps = {
  tooltip: null,
  required: false,
  readOnly: false,
};

/* eslint-disable react/prop-types */
export const withFieldHints = WrappedComponent => props =>
  (
    <>
      <FieldHints
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        htmlFor={props.id}
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        label={props.label}
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        tooltip={props.tooltip}
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        required={props.required}
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        readOnly={props.readOnly}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <WrappedComponent {...props} />
    </>
  );
/* eslint-enable react/prop-types */
