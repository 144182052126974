import camelize from 'camelize';
import { GET_BENEFIT_CARD_PREVIEW } from './benefit-preview.actions';
import { normalizeBenefitCard } from '../wallet.reducer';

const initialState = { cards: [] };

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export function benefitPreviewReducer(state = initialState, action) {
  if (action.type === GET_BENEFIT_CARD_PREVIEW.SUCCEEDED) {
    return {
      cards: action.payload.info.benefit_cards.map(card => {
        return normalizeBenefitCard(camelize(card));
      }),
    };
  }
  return state;
}
