import { createRequestTypes } from 'common/websocket-redux';

export const RECEIPT_VISITED = 'wallet/RECEIPT_VISITED';
export const GET_RECEIPT_RESOURCES = createRequestTypes(
  'wallet/GET_RECEIPT_RESOURCES',
);

export function receiptVisited({ paymentId }) {
  return {
    type: RECEIPT_VISITED,
    payload: { paymentId },
  };
}
