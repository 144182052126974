import { createRequestTypes } from 'common/websocket-redux';

// BILLING CALLS

export const GROUP_CREDIT_CONTRACT_FORM_SUBMITTED =
  'billing/GROUP_CREDIT_CONTRACT_FORM_SUBMITTED';

export const GROUP_SUBSCRIPTION_RATES_SUBMITTED =
  'billing/GROUP_SUBSCRIPTION_RATES_SUBMITTED';

export const GROUP_BILLING_CONTACT_FORM_SUBMITTED =
  'billing/GROUP_BILLING_CONTACT_FORM_SUBMITTED';

export const GROUP_INVOICE_PAYMENT_FORM_SUBMITTED =
  'billing/GROUP_INVOICE_PAYMENT_FORM_SUBMITTED';

export const GROUP_BILLING_PREFERENCE_FORM_SUBMITTED =
  'billing/GROUP_BILLING_PREFERENCE_FORM_SUBMITTED';

export const RESET_UPDATE_STATUS = 'billing/RESET_UPDATE_STATUS';

export const UPDATE_GROUP = {
  STARTED: 'billing/UPDATE_GROUP_STARTED',
  SUCCEEDED: 'billing/UPDATE_GROUP_SUCCEEDED',
  ERRORED: 'billing/UPDATE_GROUP_ERRORED',
};

// SERVICE CALLS

export const SET_GROUP_CREDIT_CONTRACT = {
  STARTED: 'service/SET_GROUP_CREDIT_CONTRACT_STARTED',
  SUCCEEDED: 'service/SET_GROUP_CREDIT_CONTRACT_SUCCEEDED',
  ERRORED: 'service/SET_GROUP_CREDIT_CONTRACT_ERRORED',
};

export const SET_GROUP_SUBSCRIPTION_RATES = {
  STARTED: 'service/SET_GROUP_SUBSCRIPTION_RATES_STARTED',
  SUCCEEDED: 'service/SET_GROUP_SUBSCRIPTION_RATES_SUCCEEDED',
  ERRORED: 'service/SET_GROUP_SUBSCRIPTION_RATES_ERRORED',
};

export const SET_GROUP_BILLING_CONTACT = {
  STARTED: 'service/SET_GROUP_BILLING_CONTACT_STARTED',
  SUCCEEDED: 'service/SET_GROUP_BILLING_CONTACT_SUCCEEDED',
  ERRORED: 'service/SET_GROUP_BILLING_CONTACT_ERRORED',
};

export const SET_INVOICE_PAYMENT = {
  STARTED: 'service/SET_INVOICE_PAYMENT_STARTED',
  SUCCEEDED: 'service/SET_INVOICE_PAYMENT_SUCCEEDED',
  ERRORED: 'service/SET_INVOICE_PAYMENT_ERRORED',
};

export const SET_GROUP_BILLING_PREFERENCE = createRequestTypes(
  'service/SET_GROUP_BILLING_PREFERENCE',
);

export const GET_EMPLOYER_BENEFITS_BILLING = createRequestTypes(
  'service/GET_EMPLOYER_BENEFITS',
);

export const SET_EMPLOYER_BENEFITS = createRequestTypes(
  'service/SET_EMPLOYER_BENEFITS',
);

export const GET_EMPLOYER_OUTSTANDING_BALANCE = createRequestTypes(
  'service/GET_EMPLOYER_OUTSTANDING_BALANCE',
);

export const ADMIN_EMPLOYER_OUTSTANDING_BALANCE =
  'billing/ADMIN_EMPLOYER_OUTSTANDING_BALANCE';

export const HR_EMPLOYER_OUTSTANDING_BALANCE =
  'billing/HR_EMPLOYER_OUTSTANDING_BALANCE';
