import { combineReducers } from 'redux';
import { homeReducer as homeFeed } from 'apps/member-experience/pages/HomeFeed/home-feed.reducer';
import { notificationsReducer as notifications } from 'apps/member-experience/pages/notifications/notifications.reducer';
import walletReducer from 'apps/member-experience/pages/wallet/wallet.reducer';
import { receiptReducer } from 'apps/member-experience/pages/wallet/receipt/receipt.reducer';
import { submitClaimReducer } from 'apps/member-experience/pages/claims/submit-claim/state/submit-claim.reducer';
import { claimsReducer } from 'apps/member-experience/pages/claims/claim-history';
import { claimDetailsReducer } from 'apps/member-experience/pages/claims/claim-details';
import { bankingInfoReducer } from 'apps/member-experience/pages/banking-info/banking-info.reducer';
import { qleScreenerReducer } from 'apps/member-experience/pages/QleScreener/qle-screener.reducer';
import { manageDependentsReducer } from 'apps/member-experience/pages/manage-dependents/manage-dependents.reducer';
import { qleRequestReducer } from 'apps/member-experience/pages/QleRequest/qle-request.reducer';
import { rewardsReducer } from 'apps/member-experience/pages/Rewards/rewards-page.reducer';
import { parentCategoryPageReducer } from 'apps/member-experience/pages/Solutions/ParentCategoryPage/parent-category-page.reducer';
import { chatPageReducer } from 'apps/member-experience/pages/Chat/chat-page.reducer';
import { chatWindowReducer } from 'apps/member-experience/pages/Chat/ChatWindow/chat-window.reducer';
import { beneficiariesReducer } from 'apps/member-experience/pages/beneficiaries/beneficiaries.reducer';
import { userProfileReducer } from 'apps/member-experience/pages/UserProfile/profile-page.reducer';
import { healthRewardsReducer } from 'apps/member-experience/resources/rewards/health-rewards.reducer';
import {
  GET_CURRENT_GEOLOCATION,
  SET_CURRENT_GEOLOCATION,
  USE_STORED_LOCATION,
} from 'common/state/geolocation/types';
import { GET_GROUP } from './types';
import { quickActions } from './quick-actions/quick-actions.reducer';
import { paymentCredentials } from 'apps/member-experience/resources/payment-credentials/payment-credentials.reducer';

export const initialState = {
  name: 'memberExperience',
  type: 'private',
  isGettingGeolocation: false,
  useStoredLocation: false,
  useGeolocation: false,
  group: null,
};

const applyGettingGeolocation = state => ({
  ...state,
  isGettingGeolocation: true,
});

const applyCurrentGeolocation = state => ({
  ...state,
  isGettingGeolocation: false,
  useGeolocation: true,
  useStoredLocation: false,
});

const applyUseStoredLocation = state => ({
  ...state,
  isGettingGeolocation: false,
  useGeolocation: false,
  useStoredLocation: true,
});

const setGroup = (state, payload) => ({
  ...state,
  group: payload.info,
});

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'service/RESPONSE:SIGNED_OUT':
      return initialState;
    case GET_CURRENT_GEOLOCATION:
      return applyGettingGeolocation(state);
    case SET_CURRENT_GEOLOCATION:
      return applyCurrentGeolocation(state);
    case USE_STORED_LOCATION:
      return applyUseStoredLocation(state);
    case GET_GROUP.SUCCEEDED:
      return setGroup(state, action.payload);
    default:
      return state;
  }
};

const walletPage = combineReducers({
  receipt: receiptReducer,
  wallet: walletReducer,
});

const pages = combineReducers({
  bankingInfoPage: bankingInfoReducer,
  chatPage: chatPageReducer,
  chatWindow: chatWindowReducer,
  claimDetails: claimDetailsReducer,
  claims: claimsReducer,
  parentCategoryPage: parentCategoryPageReducer,
  homeFeed,
  manageDependents: manageDependentsReducer,
  notifications,
  qleRequest: qleRequestReducer,
  qleScreener: qleScreenerReducer,
  walletPage,
  beneficiaries: beneficiariesReducer,
  rewards: rewardsReducer,
  userProfile: userProfileReducer,
});

const ui = combineReducers({
  submitClaim: submitClaimReducer,
});

const resources = combineReducers({
  rewards: healthRewardsReducer,
});

export default combineReducers({
  info: reducer,
  ui,
  pages,
  quickActions,
  paymentCredentials,
  resources,
});
