import {
  ADD_VENDOR_TO_PRODUCT_SYNC,
  FETCH_GROUP_VENDORS,
} from './salesforce-sync.types';

export const initialState = {
  salesforceSync: {
    groupVendors: [],
    isSaving: false,
  },
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const salesforceSyncReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUP_VENDORS.SUCCEEDED: {
      const groupVendors = action.payload?.info?.vendors;
      return {
        ...state,
        salesforceSync: {
          ...state.salesforceSync,
          groupVendors,
        },
      };
    }
    case ADD_VENDOR_TO_PRODUCT_SYNC.STARTED: {
      return {
        ...state,
        salesforceSync: {
          ...state.salesforceSync,
          isSaving: true,
        },
      };
    }
    case ADD_VENDOR_TO_PRODUCT_SYNC.ERRORED:
    case ADD_VENDOR_TO_PRODUCT_SYNC.SUCCEEDED: {
      return {
        ...state,
        salesforceSync: {
          ...state.salesforceSync,
          isSaving: false,
        },
      };
    }
    default:
      return state;
  }
};
