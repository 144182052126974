import { createSelector } from 'reselect';
import { DEPENDENT_INVITE_ERROR_STATUS } from '../invite.constants';

const selectApp = state => state;

export const selectReady = createSelector(selectApp, state => state.ready);
export const selectInviteStatus = createSelector(
  selectApp,
  state => state?.info?.status,
);

export const selectError = createSelector(
  selectApp,
  selectInviteStatus,
  (state, inviteStatus) => {
    const { error } = state;
    return error || DEPENDENT_INVITE_ERROR_STATUS.includes(inviteStatus);
  },
);
