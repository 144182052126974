import { concat, map, mapValues } from 'lodash';
import { reduce } from 'lodash/fp';

export const merge = (collector, object) => ({
  ...collector,
  ...mapValues(object, (value, key) => concat(collector[key] || [], value)),
});

export const collectValuesByKey = reduce(merge, []);

const combineValidators =
  (...validators) =>
  values => {
    const results = map(validators, validate => validate(values));
    return collectValuesByKey(results);
  };

export default combineValidators;
