import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from '@leagueplatform/locales';
import { Box, Flex, BodyOne } from '@leagueplatform/genesis-commons';
import { Pill } from '@leagueplatform/ui-kit';

export const IN_ENROLLMENT = 'in_enrollment';
export const ENROLLMENT_CLOSED = 'enrollment_closed';

const statusColors = {
  [ENROLLMENT_CLOSED]: 'orange',
  [IN_ENROLLMENT]: 'red',
};

const getTranslationIds = status => {
  switch (status) {
    case IN_ENROLLMENT:
      return {
        statusLabelId: 'IN_ENROLLMENT',
        notSubmittedTextId: 'NOT_SUBMITTED',
      };
    case ENROLLMENT_CLOSED:
      return {
        statusLabelId: 'ENROLLMENT_CLOSED',
        notSubmittedTextId: 'NEVER_SUBMITTED_DEFAULTED',
      };
    default:
      return {};
  }
};

export const SubmittedStatus = ({
  status,
  lastSubmittedTime,
  employerTzName,
}) => {
  const { formatMessage } = useIntl();
  const { statusLabelId, notSubmittedTextId } = getTranslationIds(status);
  return status ? (
    <Flex width={1 / 2} flexDirection="column" marginTop="quarter">
      <Box>
        <Pill color={statusColors[status]}>
          {formatMessage({ id: statusLabelId })}
        </Pill>
      </Box>
      <Box marginTop="one">
        <BodyOne>
          <strong>{formatMessage({ id: 'SUBMITTED_STATE' })}</strong>{' '}
          {lastSubmittedTime
            ? formatMessage(
                {
                  id: 'LAST_SUBMITTED',
                },
                {
                  submittedTime: `${moment
                    .tz(lastSubmittedTime, employerTzName)
                    .format('DD-MMM-YYYY HH:MMA z')}`,
                },
              )
            : formatMessage({ id: notSubmittedTextId })}
        </BodyOne>
      </Box>
    </Flex>
  ) : null;
};

SubmittedStatus.propTypes = {
  status: PropTypes.oneOf([IN_ENROLLMENT, ENROLLMENT_CLOSED]).isRequired,
  lastSubmittedTime: PropTypes.string,
  employerTzName: PropTypes.string.isRequired,
};

SubmittedStatus.defaultProps = {
  lastSubmittedTime: undefined,
};
