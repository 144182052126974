import { createRequestTypes } from 'common/websocket-redux';

export const ON_INIT_CHAT = 'chat/ON_INIT_CHAT';
export const ON_INIT_CHAT_HISTORY = 'chat/ON_INIT_CHAT_HISTORY';
export const ON_INIT_CHAT_HISTORY_DETAILS = 'chat/ON_INIT_CHAT_HISTORY_DETAILS';
export const ON_GET_CHAT_TRANSCRIPTS = 'chat/ON_GET_CHAT_TRANSCRIPTS';
export const RESET_CHAT_TRANSCRIPTS = 'chat/RESET_CHAT_TRANSCRIPTS';
export const OPEN_MODAL = 'chat/OPEN_MODAL';
export const CLOSE_MODAL = 'chat/CLOSE_MODAL';

export const GET_CHAT_AVAILABILITY = createRequestTypes(
  'chat/GET_CHAT_AVAILABILITY',
);

export const CREATE_SALESFORCE_OFFLINE_CASE = createRequestTypes(
  'chat/CREATE_SALESFORCE_OFFLINE_CASE',
);

export const GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS = createRequestTypes(
  'chat/GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS',
);

export const GET_SALESFORCE_CHAT_TRANSCRIPTS = createRequestTypes(
  'chat/GET_SALESFORCE_CHAT_TRANSCRIPTS',
);

export const GET_SALESFORCE_CHAT_TRANSCRIPT = createRequestTypes(
  'chat/GET_SALESFORCE_CHAT_TRANSCRIPT',
);

export const GET_CHAT_AGENTS = createRequestTypes('chat/GET_CHAT_AGENTS');
