/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { createSelector } from 'reselect';
import { getActiveGroup } from '@leagueplatform/web-common';
import { isEmpty } from 'lodash';

export const selectGroupId = createSelector(
  state => state?.apps?.['employer-experience']?.group,
  ({ group_id }) => group_id,
);

export const selectActiveGroup = createSelector(
  state => state?.apps?.['employer-experience']?.employeeProfile?.data?.groups,
  getActiveGroup,
);

export const selectDependents = createSelector(
  selectActiveGroup,
  activeGroup =>
    activeGroup?.dependents?.map(dependent => ({
      ...dependent,
      pendingSuspension:
        !dependent.suspended && !!dependent.remove_qualifying_life_event_date,
    })) || [],
);

export const selectActiveDependents = createSelector(
  selectDependents,
  dependents => dependents.filter(dependent => !dependent.suspended),
);

export const selectInactiveDependents = createSelector(
  selectDependents,
  dependents => dependents.filter(dependent => dependent.suspended),
);

/*
  Creates different instances of the selector to properly memoize it
  when called multiple times with different dependentId
*/
export const makeSelectDependentById = () =>
  createSelector(
    (_, dependentId) => dependentId,
    selectDependents,
    (dependentId, dependents) => {
      const dependent = dependents.find(
        ({ dependent_id }) => dependent_id === dependentId,
      );

      if (dependent !== undefined) {
        return dependent;
      }

      return {};
    },
  );

export const selectEmployeeDependentsPath = createSelector(
  state => state?.apps?.['employer-experience']?.employeeProfile?.data?.user_id,
  userId => `/manage/employees/${userId}/dependents`,
);

const selectEmployeeDependents = state =>
  state?.apps?.['employer-experience']?.dependents;

export const selectLoading = createSelector(
  selectEmployeeDependents,
  ({ loading }) => loading,
);

export const selectDependentRelationshipTypes = createSelector(
  selectEmployeeDependents,
  ({ relationshipTypes }) => relationshipTypes,
);

export const selectDependentAddQLEReasons = createSelector(
  selectEmployeeDependents,
  ({ dependentAddQLEReasons }) =>
    dependentAddQLEReasons?.map(({ type: value, name: text }) => ({
      text,
      value,
    })),
);

export const selectIsRequiredFormDataEmpty = createSelector(
  selectDependentRelationshipTypes,
  selectDependentAddQLEReasons,
  (relationshipTypes, qleReasons) =>
    isEmpty(qleReasons) || isEmpty(relationshipTypes),
);

export const selectIsDependentEditLoading = createSelector(
  selectIsRequiredFormDataEmpty,
  makeSelectDependentById(),
  (isRequiredFormDataEmpty, dependent) =>
    isRequiredFormDataEmpty || isEmpty(dependent),
);

export const selectDependentTerminationTypeOptions = createSelector(
  selectEmployeeDependents,
  employeeDependents =>
    employeeDependents?.dependentTerminationTypeOptions?.map(
      ({ type: value, name: text }) => ({
        text,
        value,
      }),
    ) || [],
);

export const selectEmployerCountryCode = state =>
  state?.apps?.['employer-experience']?.pages?.employerDetails?.employer?.group
    ?.countryCode;
