import React from 'react';
import { onlyAuthenticated } from 'common/state/user/role-based-redirect.hocs';

const AsyncDownloadPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-download' */ 'apps/download/download.container'
  ),
);

export const DownloadPage = onlyAuthenticated(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncDownloadPage {...props} />
));
