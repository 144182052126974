import React from 'react';
import { Box, css, genesisStyled } from '@leagueplatform/genesis-commons';

const TooltipIcon = genesisStyled(Box)(
  css({
    width: 'one',
    height: 'one',
    backgroundColor: 'decorative.brand.primary.default',
    borderRadius: '100%',
    color: 'highlight.background.secondary',
    fontSize: 'caption',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
);

export const QuestionIcon = () => (
  <Box width="one" height="one">
    <TooltipIcon>?</TooltipIcon>
  </Box>
);
