import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setQLEConfiguration Send API call to `set_qle_configuration`
 *
 * If `allow_nex` is true, sends action to handle redirecting to NEX.
 *
 * worker saga, plus service for the fetch request
 * @param {string} groupId - group id / employer id
 * @param {string} benefitClassId - benefit class id
 * @param {string} qualifyingLifeEventType string - birth, marriage, divorce
 * @param {boolean} needsHRApproval - if an HR Admin needs to approve this. Common in the States
 * @param {boolean} allowToAddDependents - this QLE is configured so that a user is allowed to add dependents
 * @param {boolean}allowToRemoveDependents - this QLE is configured so that a user is allowed to remove dependents
 * @param {boolean} allowNex -
 * @param {string} typeformUL - URL To the typeform form
 * @param {boolean} suspended - activate / deactivate (on/off) this particular configuration
 * @param {int} coverageEffectiveDatePolicy - when new coverage effective date should be
 *    0 - QLE date
 *    1 - first of following month
 * Note: constants are defined in src/apps/employer-experience/pages/EmployerDetails/Benefits/QLEConfiguration/qle-configuration-form/coverage-effective-date-policy.constants.js
 */
export const setQLEConfiguration = payload => {
  const {
    groupId,
    benefitClassId,
    qualifyingLifeEventType,
    daysAfterQleEventDate,
    daysBeforeQleEventDate,
    needsHrApproval,
    allowToAddDependents,
    allowToRemoveDependents,
    allowNex,
    typeformUrl,
    suspended,
    coverageEffectiveDatePolicy,
  } = payload;
  return call(websocketFetch, 'set_qle_configuration', {
    group_id: groupId,
    benefit_class_id: benefitClassId,
    qualifying_life_event_type: qualifyingLifeEventType,
    days_after_qle_event_date: daysAfterQleEventDate,
    days_before_qle_event_date: daysBeforeQleEventDate,
    needs_hr_approval: needsHrApproval,
    allow_to_add_dependents: allowToAddDependents,
    allow_to_remove_dependents: allowToRemoveDependents,
    allow_nex: allowNex,
    typeform_url: typeformUrl,
    suspended,
    coverage_effective_date_policy: coverageEffectiveDatePolicy,
  });
};
