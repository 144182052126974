import { compose, withProps, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import {
  DOCUMENT_TYPES,
  IDENTIFICATION,
  OTHER,
} from '@leagueplatform/web-common';
import { Formik } from 'formik-legacy';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import { DATE_FORMAT } from 'common/adaptive-forms';
import {
  selectIsLoading,
  selectUserDocuments,
  selectFlattenedUserDocumentsImages,
} from 'common/components/user-documents/user-documents.selectors';
import {
  uploadUserDocument,
  closeUploadModal,
  confirmRemoveUserDocument,
  cancelEditUserDocument,
  updateUserDocument,
  openImageViewer,
  closeImageViewer,
  requestSubmitToStripe,
  removeUserDocument,
} from 'common/components/user-documents/user-documents.actions';

const withState = connect(
  createStructuredSelector({
    isLoading: selectIsLoading,
    documents: selectUserDocuments,
    images: selectFlattenedUserDocumentsImages,
  }),
  {
    uploadUserDocument,
    updateUserDocument,
    closeUploadModal,
    confirmRemoveUserDocument,
    cancelEditUserDocument,
    openImageViewer,
    closeImageViewer,
    requestSubmitToStripe,
    removeUserDocument,
  },
);

const withFormikEnhancer = Formik({
  handleSubmit: (values, { props }) => {
    if (!props.dateError) {
      if (props.currentUserDocument) {
        if (!values.files) {
          // safeguard if no files array present
          // (which happens when editing a pinned doc in admin tool)
          props.updateUserDocument({
            ...values,
            files: values.files,
          });
        } else {
          props.updateUserDocument(values);
        }
      } else {
        props.uploadUserDocument(values);
      }
    }
  },

  mapPropsToValues: ({ currentUserDocument, userId, files, uploadedFiles }) => {
    return currentUserDocument
      ? { ...currentUserDocument, files: currentUserDocument.uploadedFiles }
      : {
          documentType: '',
          name: '',
          expirationDate: null,
          documentTypeOtherDescription: '',
          notes: '',
          files: files || [],
          uploadedFiles: uploadedFiles || [],
          userId,
          uploading: true,
        };
  },

  validate: (
    { name, files, documentType, documentTypeOtherDescription },
    { dateError },
  ) => {
    const errors = {};

    if (!name) errors.name = true;
    if (
      (files && files.length === 0) ||
      (files && files.length > 2 && documentType === IDENTIFICATION)
    )
      errors.files = true;
    if (documentType === OTHER && !documentTypeOtherDescription) {
      errors.documentTypeOtherDescription = true;
    }
    if (documentType === 'null' || documentType === '')
      errors.documentType = true;
    if (dateError) errors.expirationDate = true;
    return errors;
  },
});

const withHandlers = withStateHandlers(
  ({ dateError = false, file = null }) => ({
    dateError,
    file,
  }),
  {
    checkDate: () => value =>
      value.length === 0 || moment(value, DATE_FORMAT, true).isValid()
        ? { dateError: false }
        : { dateError: true },
    setFile: () => file => ({ file }),
  },
);

const withModalHandlers = withStateHandlers(
  ({ isEoiModalOpen = false, isRemoveConfirmationOpen = false }) => ({
    isEoiModalOpen,
    isRemoveConfirmationOpen,
  }),
  {
    toggleModal:
      ({ isEoiModalOpen }) =>
      () => ({
        isEoiModalOpen: !isEoiModalOpen,
      }),
    toggleRemoveConfirmation:
      ({ isRemoveConfirmationOpen }) =>
      () => ({
        isRemoveConfirmationOpen: !isRemoveConfirmationOpen,
      }),
  },
);

const mapDocumentTypes = withProps(({ intl }) => ({
  documentTypes: DOCUMENT_TYPES.map(({ id, value }) => ({
    value,
    label: intl.formatMessage({ id }),
    // admin-form uses older Select which takes text
    text: intl.formatMessage({ id }),
  })),
}));

// this viewFile fn is used within the edit/upload doc modal vs
// a very similar one in my-documents.hoc.js
const withViewFile = withProps(
  ({ currentUserDocument, openImageViewer: _openImageViewer, images }) =>
    currentUserDocument && {
      viewFile: currentUserDocument.isImageContentType
        ? url => _openImageViewer(images.findIndex(imgUrl => imgUrl === url))
        : url => window.open(url, '_blank'),
    },
);

const withRemoveDocument = withProps(
  ({ currentUserDocument, removeUserDocument: _removeUserDocument }) =>
    currentUserDocument && {
      removeDocument: () =>
        // isMember is a flag to make sure page refreshes after
        _removeUserDocument({ ...currentUserDocument, isMember: true }),
    },
);

export const UserDocumentForm = compose(
  withState,
  withHandlers,
  withModalHandlers,
  injectIntl,
  mapDocumentTypes,
  withViewFile,
  withFormikEnhancer,
  withRemoveDocument,
);
