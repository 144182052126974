import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Image,
  Label,
  LabelText,
  QuietButton,
  TextField,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { useIntl } from '@leagueplatform/locales';
import CloseIcon from 'apps/public/assets/close.svg';

// show current filter and all other filters that are not yet used
export const allowedOptions = (filterOptions, filters, index) =>
  filterOptions.reduce((acc, option) => {
    const isOptionAllowed =
      !filters[index] ||
      filters[index].field === option.fieldName ||
      !filters.some(filter => filter.field === option.fieldName);
    return isOptionAllowed
      ? [
          ...acc,
          {
            label: option.label,
            value: option.fieldName,
          },
        ]
      : acc;
  }, []);

export const DeductionCodeFilter = ({
  filter,
  allowedFilterTypes,
  onChangeFilterType,
  onDeleteFilter,
  onChangeFilterValue,
  isSelectable,
  allowedFilterValues,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Flex marginTop="one">
      <Label flexGrow={2} marginRight="one">
        <LabelText marginBottom="quarter">
          {formatMessage({ id: 'FIELD' })}
        </LabelText>
        <Select
          value={filter.field}
          onChange={({ value }) => onChangeFilterType(value)}
          options={allowedFilterTypes}
        />
      </Label>
      <Label flexGrow={3} marginRight="one">
        <LabelText marginBottom="quarter">
          {formatMessage({ id: 'VALUE' })}
        </LabelText>
        {isSelectable ? (
          <Select
            value={filter.value || allowedFilterValues[0]}
            onChange={({ value }) => onChangeFilterValue(value)}
            options={allowedFilterValues}
          />
        ) : (
          <TextField
            id="DEDUCTION_CODE_FILTERS"
            value={filter.value}
            onChange={evt => onChangeFilterValue(evt.currentTarget.value)}
          />
        )}
      </Label>
      <QuietButton
        id={`delete-${filter.field}`}
        onClick={onDeleteFilter}
        alignSelf="flex-end"
      >
        <Image
          src={CloseIcon}
          alt={`Delete filter ${filter.field}`}
          size="one"
        />
      </QuietButton>
    </Flex>
  );
};
DeductionCodeFilter.propTypes = {
  filter: PropTypes.shape({
    field: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  allowedFilterTypes: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChangeFilterType: PropTypes.func.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
  onChangeFilterValue: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  allowedFilterValues: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
DeductionCodeFilter.defaultProps = {
  allowedFilterValues: [],
};

export const DeductionCodeFilters = ({
  filters,
  filterOptions,
  onChangeFilterType,
  onChangeFilterValue,
  onDeleteFilter,
  onCreateFilter,
  disableCreateButton,
  getFilterOptionByFieldName,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Flex flexDirection="column">
      {filters.map((filter, index) => {
        const filterOption = getFilterOptionByFieldName(
          filterOptions,
          filter.field,
        );
        return (
          <DeductionCodeFilter
            key={filter.field}
            filter={filter}
            allowedFilterTypes={allowedOptions(filterOptions, filters, index)}
            onChangeFilterType={value => onChangeFilterType(index, value)}
            onChangeFilterValue={value => onChangeFilterValue(index, value)}
            onDeleteFilter={() => onDeleteFilter(index)}
            isSelectable={filterOption.isSelectable}
            allowedFilterValues={filterOption.values}
          />
        );
      })}
      <QuietButton
        id="create-filter-button"
        disabled={disableCreateButton}
        alignSelf="center"
        marginY="one"
        onClick={() => onCreateFilter()}
      >
        {formatMessage({ id: 'ADD_FILTER' })}
      </QuietButton>
    </Flex>
  );
};
DeductionCodeFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      fieldName: PropTypes.string,
      isSelectable: PropTypes.bool,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onChangeFilterType: PropTypes.func.isRequired,
  onChangeFilterValue: PropTypes.func.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
  onCreateFilter: PropTypes.func.isRequired,
  disableCreateButton: PropTypes.bool.isRequired,
  getFilterOptionByFieldName: PropTypes.func.isRequired,
};
