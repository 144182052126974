import {
  SET_VALIDATED_DIRECT_DEPOSIT_ACCOUNT_INFO,
  CREATE_DIRECT_DEPOSIT_ACCOUNT,
  RESET_TO_INITIAL_STATE,
  GENERATE_INTERAC_CODE,
} from './account-info-form.types';

/**
 * Send CREATE_DIRECT_DEPOSIT_ACCOUNT action with accountType, our required payment credential information.
 * Payment credential id is optional, and if given, saga listener will edit instead of create.
 * *
 * @param {string} accountType: one of `STRIPE_EU`, `EFT_CA`, `EFT_US` defined in banking-info-types.js
 * @param {object} requiredCredentials: our  payment credential information, not necessarily matching bank format. Format:
 *  {
 *    accountNumber - Stripe IBAN or accountNumber for direct deposit
 *    routingNumber - Direct deposit only. If US, routing number is given, if CA, compose of prefix, institution and branch number,
 *    name - Direct deposit only. Name of account holder.
 *    payeeDefault - bool, is default? If only account, Backend automatically sets as default.
 *  }
 * @param {string} paymentCredentialsId: id of existing payment credential in our records. If given, saga handler will edit instead of create.
 */
export const createDirectDepositAccount = ({
  accountType,
  requiredCredentials,
  paymentCredentialsId = null,
}) => ({
  type: CREATE_DIRECT_DEPOSIT_ACCOUNT,
  payload: {
    accountType,
    requiredCredentials,
    paymentCredentialsId,
  },
});

// NOTE: Should be given validate_payment_credentials'response. If isValid is true, backend returns errorCode and errorMessage as empty strings
export const setValidatedDirectDepositAccountInfo = ({
  valid,
  code,
  message,
}) => ({
  type: SET_VALIDATED_DIRECT_DEPOSIT_ACCOUNT_INFO,
  payload: {
    valid,
    code,
    message,
  },
});

// Used when unmounting account info form.
export const resetToInitialState = () => ({
  type: RESET_TO_INITIAL_STATE,
});

export const generateInteracCode = paymentCredentialsId => ({
  type: GENERATE_INTERAC_CODE,
  payload: {
    paymentCredentialsId,
  },
});
