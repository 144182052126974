import React from 'react';
import PropTypes from 'prop-types';

const ErrorFormField = ({ children, errors, touched, fieldName, ...props }) => {
  const hasErrors = errors[fieldName] && touched[fieldName];
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading, react/no-unknown-property -- FIXME: automatically added for existing issue
    <div error={hasErrors} {...props}>
      {children}
      {hasErrors && <span>{errors[fieldName].join(', ')}</span>}
    </div>
  );
};

ErrorFormField.propTypes = {
  children: PropTypes.node.isRequired,
  fieldName: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ErrorFormField;
