import { get } from 'lodash';
import { RETRY_INTERAC_PAYMENT } from './payment.types';
import { UPDATE_CLAIM } from '../settlement/settlement.types';

const initialState = { error: null, success: null };

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const claimPaymentUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRY_INTERAC_PAYMENT.STARTED:
    case UPDATE_CLAIM.SUCCEEDED:
      return { ...state, error: null, success: null };
    case RETRY_INTERAC_PAYMENT.SUCCEEDED:
      return { ...state, error: null, success: true };
    case RETRY_INTERAC_PAYMENT.ERRORED:
      return {
        ...state,
        success: null,
        error: get(action, 'payload.info.reason'),
      };
    default:
      return state;
  }
};
