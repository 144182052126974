import React from 'react';
import { Redirect } from 'react-router-dom';
import { createSelector } from 'reselect';
import { removeRedirectUrl } from 'apps/auth/auth.utils';
import {
  selectUser,
  selectIsFTUXComplete,
  selectIsDependent,
  selectIsDependentProfileComplete,
} from 'common/state/user/user.selectors';
import {
  selectUserIsConsumer,
  selectUserIsHealthAdmin,
  selectUserIsAdmin,
  selectUserIsClaimsAgent,
  selectUserIsHRAdmin,
} from 'common/state/user/role.selectors';
import { isEmpty } from 'lodash';
import { selectHasUserAcceptedTerms } from 'apps/terms/terms.selectors';
import { selectIsAuthenticated } from 'apps/auth/selectors';

export const selectIsRootLoading = createSelector(
  selectUser,
  selectIsAuthenticated,
  (user, isAuthenticated) =>
    !isAuthenticated || !user?.userId || isEmpty(user?.user_config),
);

const selectRedirectPath = (state, redirectPath) => redirectPath;

const selectHasRedirectPath = createSelector(selectRedirectPath, Boolean);

export const selectRootRedirect = createSelector(
  selectUserIsConsumer,
  selectUserIsHealthAdmin,
  selectUserIsAdmin,
  selectUserIsClaimsAgent,
  selectHasUserAcceptedTerms,
  selectHasRedirectPath,
  selectRedirectPath,
  selectIsFTUXComplete,
  selectUserIsHRAdmin,
  selectIsDependent,
  selectIsDependentProfileComplete,
  (
    isMember,
    isHealthAdmin,
    isAdmin,
    isClaimsAgent,
    hasUserAcceptedTerms,
    hasRedirectPath,
    redirectPath,
    isFTUXComplete,
    isHrAdmin,
    isDependent,
    isDependentProfileComplete,
  ) => {
    /*
     * NOTE: these redirect conditions must be defined in this order.
     * By default, when all the conditions are not met, it should redirect to error page.
     *
     * Incomplete account state conditions:
     * /terms => Terms & Conditions page (any roles except League admin)
     * /dependent/profile => Dependent Profile
     * /enroll => Enrollment (NEX)
     *
     * Cookie URL
     * /<redirect-url-path> => Cookie redirect url
     *
     * /admin => League admin
     * /manage => HR admin
     * /member => Member Experience
     * /404 => error page (default)
     */
    const redirects = [
      {
        // Terms & Conditions
        condition: isMember && !hasUserAcceptedTerms,
        Component: () => <Redirect to="/terms" />,
      },
      {
        // Dependent profile
        condition: isDependent && !isDependentProfileComplete,
        Component: () => <Redirect to="/dependent/profile" />,
      },
      {
        // Enroll
        condition: isMember && !isFTUXComplete,
        Component: () => <Redirect to="/enroll" />,
      },
      {
        // Cookie redirect url
        condition: hasRedirectPath,
        Component: () => {
          removeRedirectUrl();
          return <Redirect to={redirectPath} />;
        },
      },
      {
        // Admin redirects
        condition: isAdmin || isClaimsAgent || isHealthAdmin,
        Component: () => <Redirect to="/admin" />,
      },
      {
        // HR admin
        condition: isMember && isHrAdmin,
        Component: () => <Redirect to="/manage" />,
      },
      {
        // Member experience
        condition: isMember,
        Component: () => <Redirect to="/member" />,
      },
    ];

    return (
      redirects.find(({ condition }) => Boolean(condition)) ?? {
        // Error (default)
        Component: () => {
          throw new Error('No route match found for user');
        },
      }
    );
  },
);
