import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { PlanSelectionPresenter } from './plan-selection.view';
import { selectMappedClassedAndPlans } from '../enrollment-design.selectors';
import { BenefitClassWithPlanType } from '../benefit.types';
import { setCurrentPlanId } from '../../enrollment-config/enrollment-config.actions';

export interface PlanSelectionStateProps {
  mappedClassesAndPlans: BenefitClassWithPlanType[];
}

export interface PlanSelectionStateOwnProps {
  groupId: string;
  selectedPlanId?: string;
}

export interface PlanSelectionDispatchProps {
  setCurrentPlanId: typeof setCurrentPlanId;
}

const mapStateToProps = createStructuredSelector<any, PlanSelectionStateProps>({
  mappedClassesAndPlans: selectMappedClassedAndPlans,
});

const mapDispatchToProps = {
  setCurrentPlanId,
};

export const PlanSelectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanSelectionPresenter);
