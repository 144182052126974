import styled from 'styled-components';
import { colour, Link } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 0.8rem;
  color: ${colour('darkBlack')};
  &:hover {
    text-decoration: underline;
    color: ${colour('darkBlack')};
  }
  margin: 0 0.6rem;
`;

export const DownloadLinkCSV = ({ contentId, rel }) => (
  <StyledLink href={getContentUrl(contentId)} target="_blank" rel={rel}>
    <FormattedMessage id="CSV_LINK" />
  </StyledLink>
);

DownloadLinkCSV.propTypes = {
  contentId: PropTypes.string.isRequired,
  rel: PropTypes.string,
};

DownloadLinkCSV.defaultProps = {
  rel: 'noopener noreferrer',
};
