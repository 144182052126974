import { createRequestTypes } from 'common/websocket-redux';

export const CREATE_DIRECT_DEPOSIT_ACCOUNT =
  'account-info-form/CREATE_DIRECT_DEPOSIT_ACCOUNT';

export const REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT = createRequestTypes(
  'account-info-form/REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT',
);

export const SET_VALIDATED_DIRECT_DEPOSIT_ACCOUNT_INFO =
  'account-info-form/SET_VALIDATED_DIRECT_DEPOSIT_ACCOUNT_INFO';

export const RESET_TO_INITIAL_STATE =
  'account-info-form/RESET_TO_INITIAL_STATE';

export const GENERATE_INTERAC_CODE = 'account-info-form/GENERATE_INTERAC_CODE';

export const REQUEST_GENERATE_INTERAC_CODE = createRequestTypes(
  'account-info-form/REQUEST_GENERATE_INTERAC_CODE',
);
