import { withStateHandlers, withProps, compose } from 'recompose';
import { keyBy, mapValues, filter, toLower, trim, includes } from 'lodash';
import SelectDivision from './invoice-table-select-division.view';
import { SELECT_ALL_DIVISIONS_ID } from 'apps/employer-experience/pages/EmployerDetails/Billing/constants';
import { injectIntl } from 'react-intl';

const withState = withProps(({ billingDivisions, intl }) => ({
  divisionOptions: [
    {
      billingDivisionId: SELECT_ALL_DIVISIONS_ID,
      name: intl.formatMessage({ id: 'ALL' }),
    },
    ...billingDivisions,
  ],
}));

/*
  divisionSearchStr is the sanitized search input
  if a billing division's name includes this string
  then a key-value pair of {billingDivisionId: true} is created
  signifying that this billing division should be visible
*/
const withDivisionSearch = withStateHandlers(
  () => ({ divisionSearchStr: '', divisionSearchInput: '' }),
  {
    onSearch: () => input => ({
      divisionSearchInput: input,
      divisionSearchStr: toLower(trim(input)),
    }),
    onClearSearch: () => () => ({
      divisionSearchInput: '',
      divisionSearchStr: '',
    }),
  },
);

const filterDivisionOptions = (divisionOptions, input) =>
  filter(divisionOptions, ({ name }) => includes(toLower(trim(name)), input));

const withFilterProps = withProps(
  ({ divisionOptions: options, divisionSearchStr: input }) => ({
    // create a map of { billingDivisionId: true } pairs
    visibleOptions: mapValues(
      keyBy(filterDivisionOptions(options, input), 'billingDivisionId'),
      () => true,
    ),
  }),
);

export default compose(
  injectIntl,
  withState,
  withDivisionSearch,
  withFilterProps,
)(SelectDivision);
