import { all, call, race, take, takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getClaims } from 'common/services';
import { claimsAnalyticsSaga } from './claims-analytics.saga';
import { FRONTEND_CLAIM_STATUS_TYPES } from 'common/constants';
import {
  VISITED,
  EXITED,
  OPTIONS_CHANGED,
  GET_CLAIMS_REQUEST,
} from './claims.action-types';

const baseParameters = {
  order: {
    type: 'last_updated',
    asc: false,
  },
  statuses: FRONTEND_CLAIM_STATUS_TYPES,
};

export function* requestClaims(options = {}) {
  const parameters = { ...baseParameters, ...options };
  yield request(GET_CLAIMS_REQUEST, getClaims(parameters), { options });
}

export const watchers = all([
  takeLatest(OPTIONS_CHANGED, action => requestClaims(action.payload.options)),
  claimsAnalyticsSaga(),
]);

export function* claimsSaga() {
  while (true) {
    yield take(VISITED);
    yield call(requestClaims);
    yield race({
      task: watchers,
      cancel: take(EXITED),
    });
  }
}
