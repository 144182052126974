import { Props as ReactSelectProps } from 'react-select';
import { BoxProps, InputProps } from '@leagueplatform/genesis-commons';

export type OptionType = {
  label: React.ReactNode;
  value: string | number | boolean | null | undefined;
  isDisabled?: boolean;
};

export type OptionGroupType = {
  label: React.ReactNode;
  options: OptionType[];
};

export type GroupedOptionsType = Array<OptionGroupType>;

export interface OptionsProps {
  options: OptionType[] | GroupedOptionsType;
}

export interface SelectProps
  extends Omit<InputProps, 'value' | 'name'>,
    Omit<ReactSelectProps<OptionsProps['options']>, 'theme' | 'value'>,
    BoxProps,
    OptionsProps {
  value?: OptionType['value'];
}

export const isOptionGroup = (option: any): option is OptionGroupType =>
  (option as OptionGroupType).options !== undefined;

export const isGroupedOptions = (options: any): options is GroupedOptionsType =>
  (options ?? []).some(isOptionGroup);

export const isOptions = (options: any): options is OptionType[] =>
  Array.isArray(options) && !isGroupedOptions(options);
