/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import {
  vars,
  Tooltip,
  TooltipContent,
  TooltipContentV2,
} from '@leagueplatform/ui-kit';
import {
  getInvoiceStatusTextId,
  getInvoiceStatusColour,
} from 'apps/employer-experience/pages/EmployerDetails/Billing/operations';
import {
  Spinner,
  Flex,
  genesisStyled,
  Image,
} from '@leagueplatform/genesis-commons';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { useInvoiceRow } from './use-invoice-row.hook';
import questionmark from 'apps/public/assets/questionmark-in-circle.svg';

const DownloadLink = styled.a`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
  margin-left: 1.6rem;
`;

const StyledLink = genesisStyled(Link)`
  font-weight: bold;
  margin: 0 .6rem 0 1.6rem;
  &:hover {
    text-decoration: underline;
  }
`;

const DisabledDownloadLink = styled.a`
  font-weight: bold;
  margin-left: 1.6rem;
  color: ${vars.colour.greyLight};
  &:hover {
    text-decoration: none;
    color: ${vars.colour.greyLight};
  }
`;

const ContentTitle = styled.span`
  font-weight: 500;
  margin-right: 0.5rem;
`;

const ContentRow = styled.tr`
  background-color: ${vars.colour.greyLightest};

  td {
    border-top: none !important;
    padding: 0.5rem 0 0.5rem 1rem !important;
  }
`;

const NAMessage = styled.span`
  margin-left: 1.6rem;
`;

const StatusCircle = styled.span`
  cursor: pointer;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${({ colour }) => colour};
  border-radius: 50%;
`;

const InvoiceStatusContent = styled.span`
  font-size: 0.75rem;
  font-weight: ${vars.fontWeight.medium};
  padding: 0.2rem 0.7rem;
  text-align: center;
  min-width: 4rem;
  white-space: nowrap;
  text-transform: uppercase;
`;

const InvoiceNumber = styled.span`
  margin-left: 0.75rem;
`;

const InvoiceRow = ({
  invoice,
  isExpanded,
  showBillingDivision,
  onDownloadCsv,
  onDownloadExternalInvoice,
  onToggleInvoiceStatus,
  isDryRun,
  billingInsightsData,
  isAdmin,
  groupId,
}) => {
  const { formatDate } = useIntl();
  const state = invoice.invoice_state;

  const { month, year, billingDivisionName, isBillingInsight } = useInvoiceRow(
    billingInsightsData,
    invoice,
  );

  let formatBillingDivisionName = billingDivisionName;

  if (billingDivisionName?.includes(',')) {
    formatBillingDivisionName = `"${billingDivisionName}"`;
  }
  return (
    <>
      <tr>
        <td>
          <Tooltip inline>
            <StatusCircle
              onClick={e =>
                onToggleInvoiceStatus(e, {
                  state,
                  invoiceId: invoice.invoice_id,
                })
              }
              colour={getInvoiceStatusColour(state)}
            />
            <TooltipContent borderRadius="1px" center>
              <InvoiceStatusContent>
                <FormattedMessage id={getInvoiceStatusTextId(state)} />
              </InvoiceStatusContent>
            </TooltipContent>
          </Tooltip>
          <InvoiceNumber>{invoice.invoice_number}</InvoiceNumber>
        </td>
        <td>
          <FormattedNumber
            style="currency" // eslint-disable-line react/style-prop-object
            currencyDisplay="symbol"
            currency={invoice.invoice_currency}
            value={invoice.invoice_amount}
          />
        </td>
        <td>
          {formatDate(invoice.invoice_date_created, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </td>
        {showBillingDivision && (
          <td>
            <div title={invoice.billing_division_name}>
              {invoice.billing_division_name}
            </div>
          </td>
        )}
        <td>{invoice.billing_period}</td>
        <td>
          <Flex justify="center">
            {invoice.invoice_provider === 'league' &&
              !invoice.content_references?.pdf &&
              !invoice.content_references?.csv && (
                <NAMessage>
                  <FormattedMessage id="NA" />
                </NAMessage>
              )}
            {invoice.invoice_provider === 'league' &&
              invoice.content_references?.pdf && (
                <DownloadLink
                  target="_blank"
                  href={getContentUrl(invoice.content_references?.pdf)}
                  onClick={e => e.stopPropagation()}
                >
                  PDF
                </DownloadLink>
              )}
            {invoice.invoice_provider === 'zab' &&
              ((invoice.is_disabled && (
                <DisabledDownloadLink>
                  {(invoice.is_loading && (
                    <Spinner
                      width="24px"
                      height="12px"
                      display="inline-block"
                      isLoading
                    />
                  )) ||
                    'PDF'}
                </DisabledDownloadLink>
              )) || (
                <DownloadLink onClick={onDownloadExternalInvoice}>
                  {(invoice.is_loading && (
                    <Spinner
                      width="24px"
                      height="12px"
                      display="inline-block"
                      isLoading
                    />
                  )) ||
                    'PDF'}
                </DownloadLink>
              ))}

            {invoice.content_references?.csv && !isBillingInsight && (
              <DownloadLink
                target="_blank"
                href={getContentUrl(invoice.content_references?.csv)}
                onClick={onDownloadCsv}
              >
                CSV
              </DownloadLink>
            )}
            {!isAdmin && isBillingInsight && (
              <>
                <StyledLink
                  to={
                    billingDivisionName === null
                      ? `/manage/billing/insights/${year}/${month}`
                      : `/manage/billing/insights/${year}/${month}?billing_division_name=${encodeURIComponent(
                          formatBillingDivisionName,
                        )}`
                  }
                >
                  CSV
                </StyledLink>
                <Tooltip>
                  <Image src={questionmark} size="one" />
                  <TooltipContentV2 right>
                    <FormattedMessage id="INVOICES_TOOLTIP" />
                  </TooltipContentV2>
                </Tooltip>
              </>
            )}
            {isAdmin && isBillingInsight && (
              <>
                <StyledLink
                  to={
                    billingDivisionName === null
                      ? `/admin/employers/${groupId}/billing/invoices/insights/${year}/${month}`
                      : `/admin/employers/${groupId}/billing/invoices/insights/${year}/${month}?billing_division_name=${encodeURIComponent(
                          formatBillingDivisionName,
                        )}`
                  }
                >
                  CSV
                </StyledLink>
                <Tooltip>
                  <Image src={questionmark} size="one" />
                  <TooltipContentV2 right>
                    <FormattedMessage id="INVOICES_TOOLTIP" />
                  </TooltipContentV2>
                </Tooltip>
              </>
            )}
          </Flex>
        </td>
      </tr>
      {isExpanded && (
        <ContentRow>
          <td>
            <ContentTitle>
              <FormattedMessage id="TOTAL_AMOUNT" />
            </ContentTitle>
            <FormattedNumber
              style="currency" // eslint-disable-line react/style-prop-object
              currencyDisplay="symbol"
              currency={invoice.invoice_currency}
              value={invoice.invoice_amount}
            />
          </td>
          {isDryRun && (
            <td>
              <ContentTitle>
                <FormattedMessage id="DRY_RUN_CREATED" />
              </ContentTitle>
            </td>
          )}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td colSpan="2" />
        </ContentRow>
      )}
    </>
  );
};

InvoiceRow.propTypes = {
  invoice: PropTypes.shape({
    invoice_id: PropTypes.string.isRequired,
    invoice_number: PropTypes.string.isRequired,
    invoice_date_created: PropTypes.string.isRequired,
    invoice_date: PropTypes.string.isRequired,
    invoice_currency: PropTypes.string.isRequired,
    invoice_amount: PropTypes.number.isRequired,
    invoice_provider: PropTypes.string.isRequired,
    invoice_state: PropTypes.string.isRequired,
    invoice_type: PropTypes.string.isRequired,
    billing_division_name: PropTypes.string,
    billing_period: PropTypes.string,
    content_references: PropTypes.shape({
      pdf: PropTypes.string,
      csv: PropTypes.string,
    }),
    is_disabled: PropTypes.bool,
    is_loading: PropTypes.bool,
  }).isRequired,
  showBillingDivision: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isDryRun: PropTypes.bool,
  onDownloadCsv: PropTypes.func,
  onToggleInvoiceStatus: PropTypes.func,
  onDownloadExternalInvoice: PropTypes.func,
  billingInsightsData: PropTypes.shape({}),
  isAdmin: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
};

InvoiceRow.defaultProps = {
  showBillingDivision: false,
  isExpanded: false,
  isDryRun: false,
  onDownloadCsv: () => {},
  onToggleInvoiceStatus: () => {},
  onDownloadExternalInvoice: () => {},
  billingInsightsData: null,
};

export default InvoiceRow;
