import {
  get,
  orderBy,
  mapValues,
  compose,
  flatten,
  groupBy,
  values,
  map,
  filter,
} from 'lodash/fp';
import { createSelector } from 'reselect';
import { selectApp, selectGroup } from '../app.selectors';
import { benefitProductTypes } from './benefits.constants';
import { set } from 'lodash';
import { BENEFIT_STATUS_CREATED } from '../benefits/constants';

export const selectClasses = createSelector(
  selectGroup,
  get('benefit_classes'),
);
export const selectPlans = createSelector(
  selectApp,
  get('employerBenefitPlans'),
);
export const selectInitialBenefits = createSelector(
  selectApp,
  get('employerBenefits'),
);

const groupByType = groupBy(val => val.benefit_product_type);
const sortInAlphabeticalOrder = orderBy(
  ['benefit_product_type', 'full_name'],
  ['asc'],
);

const sortByBenefitProductType = list =>
  list.sort((a, b) => {
    const aTypeIndex = benefitProductTypes.indexOf(a.benefit_product_type);
    const bTypeIndex = benefitProductTypes.indexOf(b.benefit_product_type);

    // "a" is a known type but "b" is unknown - sort a before b
    if (aTypeIndex >= 0 && bTypeIndex < 0) {
      return -1;
    }

    // "b" is a known type but "a" is unknown - sort b before a
    if (aTypeIndex < 0 && bTypeIndex >= 0) {
      return 1;
    }

    // Sort based on index
    return aTypeIndex - bTypeIndex;
  });

export const orderBenefits = compose(
  sortByBenefitProductType,
  flatten,
  values,
  mapValues(sortInAlphabeticalOrder),
  groupByType,
);

export const selectPlansWithBenefits = createSelector(
  [selectInitialBenefits, selectPlans],
  (benefits, plans) =>
    map(
      plan => ({
        ...plan,
        benefits: filter({ plan_id: plan.id }, benefits),
      }),
      plans,
    ),
);

const filterBenefitsCreated = benefits =>
  benefits.filter(benefit => benefit.benefit_status !== BENEFIT_STATUS_CREATED);

const groupByProductType = benefits => {
  // group benefits by their product type / short name
  const normalized = benefits.reduce((benefitsList, benefit) => {
    const productType = benefit.product_name;
    const benefitType = benefit.benefit_type;
    set(benefitsList, `${productType}`, {
      ...benefitsList[productType],
    });
    return set(benefitsList, `${productType}.${benefitType}`, {
      ...benefitsList[productType][benefitType],
      [benefit.id]: benefit,
    });
  }, {});
  return normalized;
};

const benefitsInClass = benefitClassId =>
  compose(
    groupByProductType,
    filterBenefitsCreated,
    orderBenefits,
    flatten,
    map(get('benefits')),
    filter({ benefit_class_id: benefitClassId }),
  );

export const selectClassesWithBenefits = createSelector(
  [selectPlansWithBenefits, selectClasses],
  (plans, classes) =>
    map(
      benefitClass => ({
        ...benefitClass,
        benefits: benefitsInClass(benefitClass.id)(plans),
      }),
      classes,
    ),
);

export const selectEmployerBenefitDocuments = createSelector(
  selectApp,
  get('employerBenefitDocuments.data.entities.documents'),
);

/* UI */

export const selectUI = createSelector(selectApp, get('ui.employerBenefits'));

export const selectUploadedDocument = () =>
  createSelector(selectUI, get('benefitDocument'));

export const selectEmployerBenefitDocumentsReady = () =>
  createSelector(selectUI, get('employerBenefitDocumentsReady'));

export const selectBenefitsReady = createSelector(selectUI, get('ready'));

export const selectReady = createSelector(
  [selectBenefitsReady, selectEmployerBenefitDocumentsReady],
  (benefitsReady, documentsReady) => benefitsReady && documentsReady,
);
