import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { createStructuredSelector } from 'reselect';
import EnrollmentSelectionReport from './enrollment-selection-report.view';
import { getEnrollmentSelectionReport as getEnrollmentSelectionReportAction } from '../../finance-reports.actions';
import {
  selectEnrollmentSelectionsReportContentId,
  selectEnrollmentSelectionsReportLoading,
} from '../../../selectors';
import {
  ENROLLMENT_SELECTIONS_REPORT_GROUP_ID,
  ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS,
} from '../../../constants';

const useValidate = () => {
  const validate = useCallback(values => {
    const errors = {};
    if (values?.[ENROLLMENT_SELECTIONS_REPORT_GROUP_ID] === '') {
      errors[ENROLLMENT_SELECTIONS_REPORT_GROUP_ID] = true;
    }
    return errors;
  }, []);
  return validate;
};

const useOnSubmit = ({ getEnrollmentSelectionReport }) => {
  return useCallback(
    values => {
      const payload = {
        group_id: values?.[ENROLLMENT_SELECTIONS_REPORT_GROUP_ID],
        ...(values?.[ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS] && {
          plan_status: values?.[ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS],
        }),
      };
      getEnrollmentSelectionReport(payload);
    },
    [getEnrollmentSelectionReport],
  );
};

export const EnrollmentSelectionReportFormContainer = props => {
  const validate = useValidate();
  const onSubmit = useOnSubmit(props);
  return (
    <Formik
      validateOnChange
      initialValues={{
        [ENROLLMENT_SELECTIONS_REPORT_GROUP_ID]: '',
        [ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS]: '',
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {formProps => (
        <Form>
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
          <EnrollmentSelectionReport {...props} {...formProps} />
        </Form>
      )}
    </Formik>
  );
};

export const EnrollmentSelectionReportContainer = connect(
  createStructuredSelector({
    selectionsReportContentId: selectEnrollmentSelectionsReportContentId,
    selectionsReportLoading: selectEnrollmentSelectionsReportLoading,
  }),
  {
    getEnrollmentSelectionReport: getEnrollmentSelectionReportAction,
  },
)(EnrollmentSelectionReportFormContainer);
