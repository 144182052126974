import styled, { css } from 'styled-components';
import {
  colour,
  media,
  radiusInPx,
  spaceInPx,
  Label,
} from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const radioInnerHeight = '20px';
const radioCheckedHeight = '1.4rem';
const borderWidth = '2px';
export const radioHeight = '24px'; // radioInnerHeight + 2* borderWidth

export const EnrollmentRadioBox = styled(Flex)`
  position: relative;

  .radio-button {
    padding: ${spaceInPx(3)};
    font-weight: 800;
    background-color: ${colour('greyLightest')};
    border-radius: ${radiusInPx(2)};
    flex-grow: 1;
    cursor: pointer;

    [type='radio'] {
      position: absolute;
      left: -9999px;
    }
    [type='radio'] + label {
      display: inline-block;
      position: relative;
      margin: 0;
      padding-left: 30px;
      line-height: ${radioInnerHeight};
      cursor: pointer;
    }
    [type='radio'] + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: ${radioInnerHeight};
      height: ${radioInnerHeight};
      border: ${borderWidth} solid ${colour('greyLight')};
      border-radius: 100%;
      background: #fff;
    }
    &.disabled {
      [type='radio'] + label {
        color: ${colour('greyLight')};
      }
      [type='radio'] + label:before {
        border-color: ${colour('greyLighter')};
      }
    }
    [type='radio'] + label:after {
      content: '';
      width: ${radioCheckedHeight};
      height: ${radioCheckedHeight};
      background: ${colour('purple')};
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
    }
    [type='radio']:checked + label:after {
      opacity: 1;
    }
    &.error {
      &:hover,
      &.radio-button--focused {
        [type='radio'] + label:before {
          border-color: ${colour('red')};
          background-color: ${colour('redLightest')};
        }
        [type='radio'] + label:after {
          border-color: ${colour('red')};
          background-color: ${colour('red')};
        }
      }
      &.disabled {
        [type='radio'] + label:before,
        [type='radio']:checked + label:after {
          opacity: 0.5;
        }
      }
    }
    &:not(.disabled):not(.error) {
      &:hover,
      &.radio-button--focused {
        background-color: ${colour('purpleLightest')};
        [type='radio'] + label:before {
          border-color: ${colour('purpleLight')};
        }
      }
    }
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${colour('greyLightest')};
        border-color: ${colour('greyLighter')};
        span {
          color: ${colour('greyLight')};
        }
      `};

    ${media.belowTablet`
      padding: ${spaceInPx(3)} 24px;
    `};
  }
`;

export const EnrollmentRadioBoxLabel = styled(Label)`
  margin: 0;
  font-weight: 800;
`;
