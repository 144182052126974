import { schema } from 'normalizr';
import { compact, cloneDeep } from 'lodash';

export const benefitClass = new schema.Entity('benefitClasses');

export const benefitPlan = new schema.Entity('benefitPlans');

export const benefit = new schema.Entity('benefits');

export const benefitDocument = new schema.Entity('benefitDocuments');

export const catalogue = new schema.Entity(
  'catalogue',
  {},
  { idAttribute: 'benefitType' },
);

export const compactSchema = (normalized, entityKey) => {
  const cleaned = cloneDeep(normalized);
  if (cleaned.result) {
    cleaned.result = compact(cleaned.result);
  }

  if (cleaned.entities[entityKey]) {
    Object.keys(normalized.entities[entityKey]).forEach(key => {
      if (key === 'undefined') {
        delete cleaned.entities[entityKey][key];
      }
    });
  }

  return cleaned;
};
