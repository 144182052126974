import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const Page = ({ activeStepIndex, children, steps, groupId }) => {
  return (
    <div>
      <Button
        className="text-button bulk"
        as={Link}
        to={`/admin/employers/${groupId}/employees`}
      >
        &larr; <FormattedMessage id="BACK_TO_EMPLOYEES" />
      </Button>
      <Header activeStepIndex={activeStepIndex} steps={steps} />
      {children}
    </div>
  );
};

Page.propTypes = {
  activeStepIndex: PropTypes.number.isRequired,
  steps: PropTypes.shape({
    index: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  groupId: PropTypes.string.isRequired,
};

export default Page;
