import {
  GET_QLE_CONFIGURATIONS,
  SET_EMPLOYER_QLE_APPROVAL_EMAILS,
  GET_EMPLOYER_QLE_APPROVAL_EMAILS,
  SET_QLE_TYPE_AND_ID,
  SET_BENEFIT_CLASS_ID,
} from './qle-configuration.action-types';

export const getQLEConfigurations = ({ benefitClassId, groupId }) => ({
  type: GET_QLE_CONFIGURATIONS,
  payload: {
    benefitClassId,
    groupId,
  },
});

/**
 * @function setHREmails Return action creator for `SET_EMPLOYER_QLE_APPROVAL_EMAILS`.
 * NOTE: Since this is called after values have been validated, it is lazy loaded into state!!!
 */
export const setHREmails = ({ qleApprovalEmails, groupId }) => ({
  type: SET_EMPLOYER_QLE_APPROVAL_EMAILS,
  payload: {
    qleApprovalEmails,
    groupId,
  },
});

export const getEmployerQLEApprovalEmails = ({ groupId }) => ({
  type: GET_EMPLOYER_QLE_APPROVAL_EMAILS,
  payload: {
    groupId,
  },
});

export const setQLETypeAndId = ({ qleType, qleConfigurationId }) => ({
  type: SET_QLE_TYPE_AND_ID,
  payload: {
    qleType,
    qleConfigurationId,
  },
});

export const setBenefitClassId = ({ benefitClassId }) => ({
  type: SET_BENEFIT_CLASS_ID,
  payload: {
    benefitClassId,
  },
});
