import { createRequestTypes } from 'common/websocket-redux';

export const TOGGLE_DEX_MODAL = 'manage-dependents/TOGGLE_DEX_MODAL';

export const GET_DEPENDENTS = 'manage-dependents/GET_DEPENDENTS';

// is user config going to be needed anyhwere else?
export const GET_USER_CONFIG = 'manage-dependents/GET_USER_CONFIG';

export const REQUEST_DEPENDENTS = createRequestTypes(
  'manage-dependents/REQUEST_DEPENDENTS',
);

export const REQUEST_DEPENDENT_ACTIONS = createRequestTypes(
  'manage-dependents/REQUEST_DEPENDENT_ACTIONS',
);

export const SET_DEPENDENT = createRequestTypes(
  'manage-dependents/SET_DEPENDENT',
);

// Might be worth making this globally accessible later!
export const SHOW_GENERIC_ERROR = 'manage-dependents/SHOW_GENERIC_ERROR';
export const REQUEST_USER_CONFIG = createRequestTypes(
  'manage-dependents/REQUEST_USER_CONFIG',
);

export const INVITE_DEPENDENT = createRequestTypes(
  'manage-dependents/INVITE_DEPENDENT',
);

export const REQUEST_DEPENDENT_SCHEMA = createRequestTypes(
  'manage-dependents/REQUEST_DEPENDENT_SCHEMA',
);

export const LOAD_DEPENDENTS = 'manage-dependents/LOAD_DEPENDENTS';
