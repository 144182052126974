export default {
  name: 'dryRunForm',
  groups: [
    {
      title: 'Invoice Dry Run',
      fields: [
        {
          name: 'issue_time',
          span: 6,
        },
      ],
    },
  ],
};
