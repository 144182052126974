import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dimmer, Header, Loader, Message } from 'semantic-ui-react';
import { Box, Text } from '@leagueplatform/ui-kit';
import { EMPLOYER_STATUSES } from 'common/constants';
import { FormattedMessage } from 'react-intl';
import SuspendConfirmation from './suspend-confirmation.view';

const EditEmployerForm = ({
  companyName,
  children,
  profileIsUpdating,
  serviceStatus,
  employerStatus,
  publishEmployer,
  activateEmployer,
  suspendEmployer,
  suspended,
  toggleVisibility,
  isConfirmationVisible,
}) => {
  const employerStatusMessage = () => {
    if (employerStatus === EMPLOYER_STATUSES.DRAFT) {
      return 'PUBLISH_EMPLOYER';
    }
    if (
      employerStatus === EMPLOYER_STATUSES.READY ||
      employerStatus === EMPLOYER_STATUSES.PUBLISHED ||
      employerStatus === EMPLOYER_STATUSES.SUSPENDED ||
      suspended
    ) {
      return 'ACTIVATE_EMPLOYER';
    }
    if (employerStatus === EMPLOYER_STATUSES.ACTIVE && !suspended) {
      return 'SUSPEND_EMPLOYER';
    }
    return 'LOADING';
  };

  const handleClick = e => {
    e.preventDefault();
    if (employerStatus === EMPLOYER_STATUSES.DRAFT) {
      return publishEmployer();
    }
    if (
      employerStatus === EMPLOYER_STATUSES.READY ||
      employerStatus === EMPLOYER_STATUSES.PUBLISHED ||
      employerStatus === EMPLOYER_STATUSES.SUSPENDED ||
      suspended
    ) {
      return activateEmployer();
    }
    if (employerStatus === EMPLOYER_STATUSES.ACTIVE && !suspended) {
      return toggleVisibility();
    }
    return e.preventDefault();
  };

  return (
    <Box>
      {serviceStatus && (
        <Message negative={serviceStatus.error} positive={!serviceStatus.error}>
          <Message.Header>
            <FormattedMessage
              id={
                serviceStatus.error
                  ? 'ERROR_STATE_TITLE'
                  : 'SUCCESS_STATE_TITLE'
              }
            />
          </Message.Header>
          <Text>{serviceStatus.reason}</Text>
        </Message>
      )}
      <Header as="h2">
        <FormattedMessage id="EDIT_COMPANY_TITLE" values={{ companyName }} />
        <Header.Subheader>
          <FormattedMessage id="EDIT_COMPANY_INTRO" />
        </Header.Subheader>
        {employerStatus === EMPLOYER_STATUSES.SUSPENDED || suspended ? null : (
          <Button
            name="employerStatusChange"
            positive={
              employerStatus === EMPLOYER_STATUSES.DRAFT ||
              employerStatus === EMPLOYER_STATUSES.READY ||
              employerStatus === EMPLOYER_STATUSES.PUBLISHED ||
              employerStatus === EMPLOYER_STATUSES.SUSPENDED ||
              suspended
            }
            negative={employerStatus === EMPLOYER_STATUSES.ACTIVE && !suspended}
            loading={employerStatus === 'loading'}
            onClick={e => handleClick(e)}
          >
            <FormattedMessage id={employerStatusMessage()} />
          </Button>
        )}
      </Header>
      <Dimmer.Dimmable as={Box} dimmed={profileIsUpdating}>
        <Dimmer active={profileIsUpdating} inverted>
          <Loader />
        </Dimmer>
        {children}
      </Dimmer.Dimmable>
      {isConfirmationVisible && (
        <SuspendConfirmation
          onClose={() => toggleVisibility()}
          name={companyName}
          onActionConfirmedClick={() => {
            suspendEmployer();
            toggleVisibility();
          }}
        />
      )}
    </Box>
  );
};

EditEmployerForm.propTypes = {
  profileIsUpdating: PropTypes.bool.isRequired,
  serviceStatus: PropTypes.shape({
    error: PropTypes.bool,
    form: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    reason: PropTypes.string,
  }),
  employerStatus: PropTypes.string.isRequired,
  publishEmployer: PropTypes.func.isRequired,
  activateEmployer: PropTypes.func.isRequired,
  suspendEmployer: PropTypes.func.isRequired,
  suspended: PropTypes.bool.isRequired,
  children: PropTypes.node,
  companyName: PropTypes.string.isRequired,
  isConfirmationVisible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
};

EditEmployerForm.defaultProps = {
  serviceStatus: null,
  children: null,
};

export default EditEmployerForm;
