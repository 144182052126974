import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_BENEFICIARY_DETAILS = createRequestTypes(
  'beneficiaries/GET_BENEFICIARY_DETAILS',
);
export const SET_BENEFICIARY_DESIGNATIONS = createRequestTypes(
  'beneficiaries/SET_BENEFICIARY_DESIGNATIONS',
);
export const SET_BENEFICIARIES = createRequestTypes(
  'beneficiaries/SET_BENEFICIARIES',
);
export const BENEFICIARIES_LIFECYCLE = createLifecycleTypes(
  'beneficiaries/BENEFICIARIES_LIFECYCLE',
);
export const ADD_BENEFICIARY_DESIGNATION =
  'beneficiaries/ADD_BENEFICIARY_DESIGNATION';
export const REMOVE_BENEFICIARY_DESIGNATION =
  'beneficiaries/REMOVE_BENEFICIARY_DESIGNATION';
export const SET_DESIGNATION_SHARE_PERCENTAGE =
  'beneficiaries/SET_DESIGNATION_SHARE_PERCENTAGE';
export const SET_REVOCABLE_DESIGNATION =
  'beneficiaries/SET_REVOCABLE_DESIGNATION';
