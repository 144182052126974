import {
  ON_INIT_CHAT,
  ON_INIT_CHAT_HISTORY,
  ON_INIT_CHAT_HISTORY_DETAILS,
  ON_GET_CHAT_TRANSCRIPTS,
  RESET_CHAT_TRANSCRIPTS,
  CREATE_SALESFORCE_OFFLINE_CASE,
  GET_CHAT_AGENTS,
  OPEN_MODAL,
  CLOSE_MODAL,
} from './chat-page.action-types';

export const initChat = () => ({
  type: ON_INIT_CHAT,
});

export const initChatHistory = () => ({
  type: ON_INIT_CHAT_HISTORY,
});

export const initChatHistoryDetails = ({ chatId }) => ({
  type: ON_INIT_CHAT_HISTORY_DETAILS,
  payload: { chatId },
});

export const getChatTranscripts = ({ offset }) => ({
  type: ON_GET_CHAT_TRANSCRIPTS,
  payload: { offset },
});

export const resetChatTranscripts = () => ({
  type: RESET_CHAT_TRANSCRIPTS,
});

export const createOfflineCase = ({ subject, agentType }) =>
  CREATE_SALESFORCE_OFFLINE_CASE.request({ subject, agentType });

export const getChatAgents = () => ({
  type: GET_CHAT_AGENTS,
});

export const openModal = ({ modalType, agentType }) => ({
  type: OPEN_MODAL,
  payload: { modalType, agentType },
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
