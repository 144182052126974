import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectEmployeeName } from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { RemoveDependentModalPresenter } from './remove-dependent-modal.view';

const mapStateToProps = () =>
  createStructuredSelector({
    employeeName: selectEmployeeName,
  });

export const RemoveDependentModalContainer = props => {
  const { onRemove } = props;

  const onSubmit = ({ terminationDate, terminationReason }) =>
    onRemove({ terminationDate, terminationReason });

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <RemoveDependentModalPresenter onSubmit={onSubmit} {...props} />;
};

RemoveDependentModalContainer.propTypes = {
  onRemove: PropTypes.func.isRequired,
};

export const RemoveDependentModal = connect(mapStateToProps)(
  RemoveDependentModalContainer,
);
