import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';

const GroupedBenefitOptions = ({ openUpdateFlexDollarsModal, planId }) => {
  const { formatMessage } = useIntl();
  return (
    <Dropdown
      pointing="top right"
      icon="ellipsis horizontal"
      text=" "
      className="dropdown-benefit-plan-options"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text={formatMessage({ id: 'UPDATE_FLEX_DOLLARS' })}
          onClick={() => openUpdateFlexDollarsModal(planId)}
          data-testid="open-update-flex-dollars"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

GroupedBenefitOptions.propTypes = {
  planId: PropTypes.string.isRequired,
  openUpdateFlexDollarsModal: PropTypes.func.isRequired,
};

export default GroupedBenefitOptions;
