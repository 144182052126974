import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { EnrollmentTemplateType } from 'apps/employer-experience/pages/EmployerDetails/enrollment-design/enrollment-experience-config/enrollment-design-options.types';

export type SetEnrollmentTemplatesParams = {
  templateType: EnrollmentTemplateType;
  groupId?: string | null;
  benefitClassId?: string | null;
  templateId?: string | null;
};

export const setEnrollmentTemplates = ({
  templateType,
  groupId = null,
  benefitClassId = null,
  templateId = null,
}: SetEnrollmentTemplatesParams) =>
  call(websocketFetch, 'set_enrollment_templates', {
    template_type: templateType,
    group_id: groupId,
    benefit_class_id: benefitClassId,
    template_id: templateId,
  });
