import { UPLOAD_BENEFIT_DOCUMENT_FILE_REQUESTED } from './documents.types';

export const uploadFile = ({ sessionId, file, benefitId }) => ({
  type: UPLOAD_BENEFIT_DOCUMENT_FILE_REQUESTED,
  payload: {
    sessionId,
    benefitId,
    file,
  },
});
