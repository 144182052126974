import { createRequestTypes } from 'common/websocket-redux';

export const GET_ALLOCATION_DRY_RUN = createRequestTypes(
  'allocation/GET_ALLOCATION_DRY_RUN',
);

export type GetAllocationDryRunPayload = {
  userId: string;
  planId: string;
  groupId?: string;
  timestamp?: Date;
};

export const getAllocationDryRun = (payload: GetAllocationDryRunPayload) =>
  GET_ALLOCATION_DRY_RUN.request(payload, null);
