import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Header, Segment, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  Box,
  Flex,
  HeadingTwo,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { colour, spaceInPx, Icon, ICONS } from '@leagueplatform/ui-kit';
import SubscriptionFee from './components/SubscriptionFee';
import PlatformFee from './components/PlatformFee';
import { get } from 'lodash';
import { AdhocBilling } from './components';

const CollapsibleContainer = genesisStyled(Box)`
  border-radius: 0.5rem;
  line-height: 1.4375;
  position: relative;
`;

export const CollapsibleContentContainer = genesisStyled(Box)`
  overflow: hidden;
  height: auto;
  transition: max-height 0.5s ease-in-out;

  ${({ isExpanded }) => {
    if (isExpanded) {
      return `max-height: 1000px;`;
    }
    return `max-height: 0px;`;
  }};
`;

export const Chevron = styled(Icon)`
  fill: ${colour('gray')};
  display: flex;
  margin-right: ${spaceInPx(1)};
  opacity: 0.7;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
  transition-duration: 0.5s;
  transition-timing-function: ease;
  height: 1rem !important;
`;

class Fees extends Component {
  // eslint-disable-next-line react/state-in-constructor -- FIXME: automatically added for existing issue
  state = {
    isExpanded: false,
  };

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.resetUpdateStatus('fees');
  }

  handleChange = (name, value) => {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('changed', true);
    if (value !== '') {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.setFieldValue(name, Number(value));
    } else {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.setFieldValue(name, value);
    }
  };

  render() {
    const {
      handleSubmit,
      setFieldValue,
      values,
      handleBlur,
      subscriptionRates,
      groupId,
      updateStatus,
      errors,
      touched,
      showAdhocBillingForm,
    } = this.props;
    const { isExpanded } = this.state;

    const buttonMessage = () => {
      switch (updateStatus.fees) {
        case 'start':
          return 'SAVING';
        case 'success':
          return 'FEES_SAVED';
        case 'error':
          return 'FEES_ERRORED';
        default:
          return 'SAVE_FEES';
      }
    };

    return (
      <Box>
        <Segment vertical>
          <Header as="h4">
            <FormattedMessage id="FEES" />
          </Header>
        </Segment>
        {showAdhocBillingForm && <AdhocBilling />}
        <CollapsibleContainer>
          <Flex
            onClick={() => this.setState({ isExpanded: !isExpanded })}
            isExpanded={isExpanded}
            alignItems="center"
          >
            <Chevron icon={ICONS.CHEVRON_RIGHT} isExpanded={isExpanded} />
            <HeadingTwo margin={0}>
              <FormattedMessage id="LEGACY_FEES" />
            </HeadingTwo>
          </Flex>
          <CollapsibleContentContainer isExpanded={isExpanded}>
            <Form onSubmit={handleSubmit}>
              <Segment vertical>
                <SubscriptionFee
                  subscriptionRates={subscriptionRates}
                  setFieldValue={setFieldValue}
                  groupId={groupId}
                  errors={errors}
                  errorIndexList={
                    errors && errors.errorIndexList ? errors.errorIndexList : []
                  }
                />
              </Segment>
              <Segment vertical>
                <PlatformFee
                  value={get(values, 'platformFees', 0)}
                  handleChange={this.handleChange}
                  handleBlur={handleBlur}
                  error={get(errors, 'platformFees', false)}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </Segment>
              <Segment vertical>
                <Form.Button
                  type="submit"
                  color={updateStatus.fees === 'error' ? 'red' : 'violet'}
                  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                  disabled={!values.changed}
                  loading={updateStatus.fees === 'start'}
                >
                  <FormattedMessage id={buttonMessage()} />
                </Form.Button>
              </Segment>
            </Form>
          </CollapsibleContentContainer>
        </CollapsibleContainer>
      </Box>
    );
  }
}

Fees.propTypes = {
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetUpdateStatus: PropTypes.func.isRequired,
  subscriptionRates: PropTypes.arrayOf(
    PropTypes.shape({
      lower: PropTypes.string,
      upper: PropTypes.string,
      rate: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  values: PropTypes.shape({
    subscriptionRates: PropTypes.arrayOf(
      PropTypes.shape({
        lower: PropTypes.string,
        upper: PropTypes.string,
        rate: PropTypes.string,
      }),
    ),
  }).isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateStatus: PropTypes.shape({
    payment: PropTypes.string,
    fees: PropTypes.string,
    prepayment: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
  showAdhocBillingForm: PropTypes.bool,
};

Fees.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  values: {},
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  touched: {},
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  errors: {},
  // TODO -- remove
  showAdhocBillingForm: true,
};

export default Fees;
