import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import {
  BENEFIT_STATUS_MAP,
  EMPLOYEE_BENEFIT_STATUS_TRANSITIONS,
  BENEFIT_STATUS_COLORS,
  CAN_BE_REMOVED,
  BENEFIT_STATUS_OPTED_OUT,
} from './constants';
import { startOfDayInTimeZone } from 'common/adaptive-forms';
import { DEFAULT_TIME_ZONE } from 'common/constants';

export const useStatusTransitions = ({
  transitions = [],
  isSpendingAccount = false,
}) => {
  const { formatMessage } = useIntl();
  return transitions
    .map(value => ({
      value,
      text: BENEFIT_STATUS_MAP[value]
        ? formatMessage({ id: BENEFIT_STATUS_MAP[value] })
        : value,
    }))
    .filter(
      ({ value }) => value !== BENEFIT_STATUS_OPTED_OUT || !isSpendingAccount,
    );
};

const Options = ({
  status,
  isSpendingAccount,
  isAdmin,
  removeBenefit,
  onStatusChange,
  canAddFunds,
  addFunds,
  adjustEndDate,
  expirationDate,
}) => {
  const { formatMessage } = useIntl();

  const statusTransitions = useStatusTransitions({
    transitions: EMPLOYEE_BENEFIT_STATUS_TRANSITIONS?.[status],
    isSpendingAccount,
  });
  const canRemove = !status || CAN_BE_REMOVED.includes(status);
  const canShowOptions =
    (isAdmin && (statusTransitions.length || canRemove)) || canAddFunds;

  const dateNow = new Date();
  const formatCurrentDate = startOfDayInTimeZone(
    dateNow,
    DEFAULT_TIME_ZONE,
  ).toISOString();
  const formatExpirationDate = startOfDayInTimeZone(
    expirationDate,
    DEFAULT_TIME_ZONE,
  ).toISOString();
  // formatExpirationDate is null mean the funds will never expire
  const showAddFundsButton =
    formatExpirationDate > formatCurrentDate || formatExpirationDate === null;

  return canShowOptions ? (
    <Dropdown
      icon="ellipsis horizontal"
      text=" "
      className="dropdown-benefit-options"
    >
      <Dropdown.Menu>
        {isAdmin ? (
          <Dropdown.Item
            className="dropdown-benefit-options__positive"
            text={formatMessage({ id: 'ADJUST_END_DATE' })}
            basic
            onClick={adjustEndDate}
          />
        ) : null}
        {isAdmin && canRemove ? (
          <Dropdown.Item
            className="dropdown-benefit-options__negative"
            text={formatMessage({ id: 'REMOVE_USER_BENEFIT' })}
            basic
            onClick={removeBenefit}
          />
        ) : null}
        {canAddFunds && showAddFundsButton ? (
          <Dropdown.Item
            className="dropdown-benefit-options__positive"
            text={formatMessage({ id: 'ADD_FUNDS' })}
            basic
            onClick={addFunds}
          />
        ) : null}
        {isAdmin && statusTransitions.length > 0 && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header
              content={formatMessage({
                id: 'USER_BENEFIT_OPTIONS_HEADER',
              })}
            />
            {statusTransitions.map(({ value, text }) => (
              <Dropdown.Item
                key={value}
                className={`dropdown-benefit-options dropdown-benefit-status-${
                  BENEFIT_STATUS_COLORS[value] || 'default'
                }`}
                text={text}
                icon="circle"
                basic
                onClick={() => onStatusChange(value)}
              />
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

Options.propTypes = {
  isAdmin: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  canAddFunds: PropTypes.bool.isRequired,
  removeBenefit: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  status: PropTypes.string,
  isSpendingAccount: PropTypes.bool,
  addFunds: PropTypes.func.isRequired,
  adjustEndDate: PropTypes.func.isRequired,
  expirationDate: PropTypes.string,
};

Options.defaultProps = {
  isAdmin: false,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  canAddFunds: false,
  isSpendingAccount: false,
  status: null,
  expirationDate: null,
};

export default Options;
