import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Dimmer } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import { HeadingTwo } from '@leagueplatform/genesis-commons';
import { TabPanel, TabContent } from 'apps/employer-experience/ui-kit/tabs';
import FinanceInvoicingDiagnostics from './components/InvoiceDiagnostics';
import { NetSuiteInvoiceReport } from './components/NetsuiteInvoiceReport';

const FinanceInvoicing = () => {
  const { formatMessage } = useIntl();
  return (
    <Dimmer.Dimmable as={Box}>
      <HeadingTwo marginBottom="one">
        {formatMessage({ id: 'FINANCE_INVOICING' })}
      </HeadingTwo>
      <TabPanel>
        <TabContent>
          <FinanceInvoicingDiagnostics />
          <NetSuiteInvoiceReport />
        </TabContent>
      </TabPanel>
    </Dimmer.Dimmable>
  );
};

export default FinanceInvoicing;
