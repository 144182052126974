import { fork, takeLatest, select, put, call } from 'redux-saga/effects';
import { websocketFetch, request } from 'common/websocket-redux';
import { explodeObject } from 'common/adaptive-forms';
import { get } from 'lodash';
import {
  GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV,
  GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV,
  GENERATE_CSV_FORM_SUBMITTED,
  EMPLOYER_REPORTS_LIFECYCLE,
  GET_EMPLOYER_HR_DRIVERS,
  GET_GROUP,
} from './reports.types';
import {
  EMPLOYER_PAYROLL_REPORTS_LIFECYCLE,
  initializePayroll,
  onPayrollReportDownload,
  EMPLOYER_PAYROLL_REPORTS_DOWNLOAD,
} from './components/payroll-report';
import {
  REQUEST_PREMIUMS_REPORT,
  REQUEST_PREVIOUS_PREMIUMS_REPORT,
  REQUEST_CHANGE_REPORT,
  GET_PAYROLL_SCHEDULES,
  GET_PREMIUMS_EXPORT_SPLITS,
  SET_PAYROLL_SCHEDULES,
  IMPORT_PAYROLL_SCHEDULE,
  EXPORT_PAYROLL_SCHEDULE,
  requestPremiumsReport,
  requestPreviousPremiumsReports,
  requestChangeReport,
  requestPayrollSchedules,
  requestPremiumsExportSplits,
  setPayrollSchedules,
  importPayrollSchedules,
  exportPayrollSchedules,
} from './components/premiums-report';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import { getEmployerDrivers, getGroup } from 'common/services';
import {
  FETCH_EMPLOYER_ENROLMENT_REPORT,
  FETCH_EMPLOYER_ENROLMENT_PROGRESS_REPORT,
  FETCH_EMPLOYEE_DEMOGRAPHICS_REPORT,
  FETCH_EMPLOYER_BENEFITS_REPORT,
  GET_BENEFICIARIES_REPORT,
} from 'apps/employer-experience/pages/EmployerDetails/employer-reports/employer-reports.types';
import {
  getEmployerEnrolmentReport,
  getEmployerEnrolmentProgressReport,
  getEmployeeDemographicsReportSaga,
  getEmployerBenefitsReportSaga,
  getBeneficiariesReportRequest,
} from 'apps/employer-experience/pages/EmployerDetails/employer-reports/employer-reports.saga';
import {
  requestSpendingAccountBalanceReport,
  REQUEST_SPENDING_ACCOUNT_BALANCE_REPORT,
} from './components/spending-account-balance-report';
import {
  REPORT_NO_DATA_ERROR,
  REPORT_GENERIC_ERROR,
  REPORT_NO_DATA_ERROR_REASON,
} from './reports.constants';
import { carrierAuditReportsSagas } from './components/carrier-audits-report/carrier-audits-report.sagas';
import { dependentsReportsSagas } from './components/dependents-report';
import { pricingScenarioReportSaga } from 'apps/employer-experience/pages/EmployerDetails/Payroll/pricing-config-qa-report/index';
import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
  ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
  ANALYTICS_EVENT_DOWNLOAD_FAILURE,
} from '../constants';

export function* generateCsv({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;
  const formValues = rest;
  const data = explodeObject(formValues);
  const requestType = data?.is_usage_summary
    ? GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV
    : GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV;
  const reportName = data?.is_usage_summary
    ? 'usage summary'
    : 'detailed usage';

  const response = yield request(requestType, [
    websocketFetch,
    'get_group_usage_report',
    data,
  ]);

  const contentId = get(response, 'info.content_id');

  if (contentId) {
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: reportName,
        detail: `generate ${reportName} report`,
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
    window.open(getContentUrl(contentId), '_blank');
  } else {
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: reportName,
        detail: `generate ${reportName} report`,
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
    const error =
      get(response, 'payload.info.reason') === REPORT_NO_DATA_ERROR_REASON
        ? REPORT_NO_DATA_ERROR
        : REPORT_GENERIC_ERROR;
    yield put(requestType.error({ error }));
  }
}

export function* initializeReportsPage({ payload }) {
  const groupId = yield select(selectCurrentUserGroupId);
  yield request(GET_GROUP, getGroup(payload?.props?.groupId ?? groupId));
  yield request(
    GET_EMPLOYER_HR_DRIVERS,
    getEmployerDrivers(payload?.props?.groupId ?? groupId),
  );
}

export function* employerReportsSagas() {
  yield takeLatest(GENERATE_CSV_FORM_SUBMITTED, generateCsv);
  yield takeLatest(
    EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.VISITED,
    initializePayroll,
  );
  yield takeLatest(FETCH_EMPLOYER_ENROLMENT_REPORT, getEmployerEnrolmentReport);
  yield takeLatest(
    FETCH_EMPLOYER_ENROLMENT_PROGRESS_REPORT,
    getEmployerEnrolmentProgressReport,
  );
  yield takeLatest(
    FETCH_EMPLOYEE_DEMOGRAPHICS_REPORT,
    getEmployeeDemographicsReportSaga,
  );
  yield takeLatest(
    FETCH_EMPLOYER_BENEFITS_REPORT,
    getEmployerBenefitsReportSaga,
  );
  yield takeLatest(EMPLOYER_REPORTS_LIFECYCLE.VISITED, initializeReportsPage);
  yield takeLatest(
    REQUEST_SPENDING_ACCOUNT_BALANCE_REPORT,
    requestSpendingAccountBalanceReport,
  );
  yield takeLatest(EMPLOYER_PAYROLL_REPORTS_DOWNLOAD, onPayrollReportDownload);
  yield takeLatest(
    GET_BENEFICIARIES_REPORT.BASE,
    getBeneficiariesReportRequest,
  );
  yield takeLatest(REQUEST_PREMIUMS_REPORT, requestPremiumsReport);
  yield takeLatest(
    REQUEST_PREVIOUS_PREMIUMS_REPORT,
    requestPreviousPremiumsReports,
  );
  yield takeLatest(REQUEST_CHANGE_REPORT, requestChangeReport);
  yield takeLatest(GET_PAYROLL_SCHEDULES.BASE, requestPayrollSchedules);
  yield takeLatest(
    GET_PREMIUMS_EXPORT_SPLITS.BASE,
    requestPremiumsExportSplits,
  );
  yield takeLatest(
    GET_PREMIUMS_EXPORT_SPLITS.BASE,
    requestPremiumsExportSplits,
  );
  yield takeLatest(SET_PAYROLL_SCHEDULES.BASE, setPayrollSchedules);
  yield takeLatest(IMPORT_PAYROLL_SCHEDULE.BASE, importPayrollSchedules);
  yield takeLatest(EXPORT_PAYROLL_SCHEDULE.BASE, exportPayrollSchedules);
  yield fork(carrierAuditReportsSagas);
  yield fork(dependentsReportsSagas);
  yield fork(pricingScenarioReportSaga);
}
