import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getWallet = ({ userId }) =>
  call(websocketFetch, 'get_wallet', {
    user_id: userId,
    suppress_card_actions_and_templates: true,
    options: {
      platform: 'web',
    },
    version: 3,
  });
