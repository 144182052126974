import {
  EVENT_NAME,
  trackAnalyticsEvent,
  PRODUCT_AREA,
  sendAnalyticsPageView,
} from '@leagueplatform/analytics';
import { useWalletQuery } from '@leagueplatform/wallet';
import { useLocation } from 'react-router-dom';
import { CLAIMS_SCREEN_NAMES } from '../constants/claims-screen-names';

// TODO: don't require the selectedBenefitType depend on formik form
const useClaimsAnalyticsData = (
  screenName?: CLAIMS_SCREEN_NAMES,
  selectedBenefitType?: string,
  onSuccess?: (data: any) => void,
) => {
  // requested_benefit_type?: string;
  const { search } = useLocation();
  const benefitType =
    selectedBenefitType ??
    new URLSearchParams(search).get('requested_benefit_type');
  const { data: benefitData } = useWalletQuery(undefined, {
    onSuccess,
    select: data => {
      const cardData = data.benefit_cards.find(
        benefit => benefit.type === benefitType,
      );

      return {
        benefit_name: cardData?.full_name,
        benefit_id: cardData?.id,
        vendor_id: cardData?.vendor_id,
        benefit_product_type: cardData?.product_type,
        screen_name: screenName,
        product_area: PRODUCT_AREA.WALLET,
      };
    },
  });

  return benefitData;
};

type EventProps = Parameters<typeof trackAnalyticsEvent>[1];

export const useClaimsAnalytics = (
  screenName: CLAIMS_SCREEN_NAMES,
  partialParams?: EventProps,
  selectedBenefitType?: string,
  claimId?: string | null,
) => {
  const claimData = claimId ? { claim_id: claimId } : {};
  const benefitData = useClaimsAnalyticsData(screenName, selectedBenefitType);

  return (eventName: EVENT_NAME, props?: {}) => {
    trackAnalyticsEvent(eventName, {
      ...benefitData,
      ...claimData,
      ...partialParams,
      ...props,
    });
  };
};

export const useClaimsScreenLoaded = (
  screenName: CLAIMS_SCREEN_NAMES,
  selectedBenefitType?: string,
  claimId?: string | null,
) => {
  const claimData = claimId ? { claim_id: claimId } : {};
  useClaimsAnalyticsData(screenName, selectedBenefitType, benefitData => {
    sendAnalyticsPageView({
      ...benefitData,
      ...claimData,
      screen_name: screenName,
    });
  });
};
