import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { selectAvailableBenefitSets } from '../enrollment-config.selectors';
import { FieldWrapper } from 'common/json-schema-form/fields';
import { Select } from 'common/form-fields/Select/Select';

export const SetIdSelectPresenter = ({
  field: { name, value } = {},
  form,
  propSchema,
  setOptions,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <Select
        name={name}
        options={setOptions}
        placeholder="Choose a Benefit Set"
        disabled={propSchema.readOnly}
        hasError={form.errors[name]}
        isClearable
        onChange={evt => {
          const { value: val } = evt ?? {};
          form.setFieldValue(name, val || evt);
        }}
        value={value}
      />
    </FieldWrapper>
  );
};

SetIdSelectPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  setOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

SetIdSelectPresenter.defaultProps = {
  setOptions: [],
};

const getSetLabel = ({ id, name, bundleName }) => {
  return bundleName ? `${name}: ${bundleName} (${id})` : `${name} (${id})`;
};

const mapStateToProps = state => {
  const sets = selectAvailableBenefitSets(state);
  return {
    setOptions: Object.values(sets).map(set => ({
      label: getSetLabel(set),
      value: set.id,
    })),
  };
};

export const SetIdSelect = connect(mapStateToProps)(SetIdSelectPresenter);
