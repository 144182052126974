import PropTypes from 'prop-types';

export const claimShape = {
  status: PropTypes.string,
  id: PropTypes.string,
  amount: PropTypes.number,
  vendor: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string,
      contentType: PropTypes.string,
    }),
  ),
};
