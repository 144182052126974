import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const setBeneficiaries = ({
  groupId = null,
  userId = null,
  beneficiaries,
}) =>
  call(websocketFetch, 'set_beneficiaries', {
    beneficiaries,
    group_id: groupId,
    user_id: userId,
  });
