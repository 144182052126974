import { eventChannel } from 'redux-saga';

function getHostName(url) {
  if (!url) return '';
  const a = document.createElement('a');
  a.href = url;
  return a.hostname;
}

/**
 * Wraps the passed event listener in validation against cross-site scripting
 * by ignoring messages that are from a different origin.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#Security_concerns
 *
 * this is the most secure CORS validation I could find.
 * from https://labs.detectify.com/2017/02/28/hacking-slack-using-postmessage-and-websocket-reconnect-to-steal-your-precious-token/
 *
 * @param {function} listener
 * @return {function}
 */
const makeValidatedListener = listener => event => {
  const isSameOrigin = window.location.hostname === getHostName(event.origin);
  if (isSameOrigin) {
    listener(event);
  }
};

/**
 * A secure wrapper around window.postMessage that ensures correctly setting
 * a secure same-origin targetOrigin to prevent XSS attacks
 * @param {DOMWindow} targetWindow - The window, iframe etc. to post the message to
 * @param {object} message - the message object
 */
export const postMessage = function postMessage(targetWindow, message) {
  targetWindow.postMessage(message, window.location.origin);
};

/**
 * Secure wrapper around window.addEventListener to prevent XSS attacks by
 * validating that the event.origin of received events is from the same host
 * before handling them.
 * @param {function} listener - only called if the event is from a trusted origin
 */
export const addPostMessageListener = function addPostMessageListener(
  listener,
) {
  const validatedListener = makeValidatedListener(listener);
  window.addEventListener('message', validatedListener, false);
  return validatedListener;
};

export function createPostMessageChannel() {
  return eventChannel(emit => {
    const handleMessage = e => emit(e);

    window.addEventListener('message', handleMessage, false);

    return () => window.removeEventListener('message', handleMessage);
  });
}
