import { TabList } from '../tab-list/tab-list.view';
import {
  getThemeValue,
  radiusInPx,
  spaceInPx,
  Box,
} from '@leagueplatform/ui-kit';
import styled from 'styled-components';

export const TabPanel = styled(Box)`
  border: 1px solid ${getThemeValue('colors.neutral.grayLight')};
  border-radius: ${radiusInPx(3)};

  ${TabList} {
    padding: 0 ${spaceInPx(2)};
    background: ${getThemeValue('colors.offWhite')};
    border-top-left-radius: ${radiusInPx(3)};
    border-top-right-radius: ${radiusInPx(3)};
    margin-bottom: 0;
  }
`;
