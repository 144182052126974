import {
  FETCH_EMPLOYER_ENROLMENT_REPORT,
  FETCH_EMPLOYER_ENROLMENT_PROGRESS_REPORT,
  FETCH_EMPLOYEE_DEMOGRAPHICS_REPORT,
  FETCH_EMPLOYER_BENEFITS_REPORT,
  GET_BENEFICIARIES_REPORT,
} from './employer-reports.types';

export const getEmployerEnrolmentReport = (groupId, isLeagueAdmin) => ({
  type: FETCH_EMPLOYER_ENROLMENT_REPORT,
  payload: {
    groupId,
    isLeagueAdmin,
  },
});

export const getEmployerEnrolmentProgressReport = (groupId, isLeagueAdmin) => ({
  type: FETCH_EMPLOYER_ENROLMENT_PROGRESS_REPORT,
  payload: {
    groupId,
    isLeagueAdmin,
  },
});

export const getEmployeeDemographicsReport = (groupId, isLeagueAdmin) => ({
  type: FETCH_EMPLOYEE_DEMOGRAPHICS_REPORT,
  payload: {
    groupId,
    isLeagueAdmin,
  },
});

export const getEmployerBenefitsReport = (groupId, isLeagueAdmin) => ({
  type: FETCH_EMPLOYER_BENEFITS_REPORT,
  payload: {
    groupId,
    isLeagueAdmin,
  },
});

export const getBeneficiariesReport = (groupId, isLeagueAdmin) => ({
  type: GET_BENEFICIARIES_REPORT.BASE,
  payload: {
    groupId,
    isLeagueAdmin,
  },
});
