import { select, put, fork, takeLatest, call } from 'redux-saga/effects';
import { selectGroupId } from '../Employee/selectors';
import { websocketFetch } from 'common/websocket-redux';
import { BULK_ADD_EMPLOYEES, UPLOAD_CSV_REQUESTED } from './types';
import { upload } from 'apps/upload/upload.saga';

export function* uploadCensus(action) {
  const { file } = action.payload;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);

  try {
    yield put({ type: BULK_ADD_EMPLOYEES.STARTED });
    // The upload saga takes an array of files and returns an array of contentIds
    const contentIds = yield call(upload, [file]);
    const referenceId = contentIds?.[0];
    const groupId = yield select(selectGroupId);
    const payload = yield call(websocketFetch, 'admin_bulk_set_employees', {
      group_id: groupId,
      content_id: referenceId,
    });
    yield put({ type: BULK_ADD_EMPLOYEES.SUCCEEDED, payload });
  } catch (error) {
    yield put({ type: BULK_ADD_EMPLOYEES.ERRORED, payload: error });
  }
}

export function* bulkAddEmployeesSaga() {
  yield fork(takeLatest, UPLOAD_CSV_REQUESTED, uploadCensus);
}
