import { request, websocketFetch } from 'common/websocket-redux';
import { fork, all, takeLatest, call } from 'redux-saga/effects';
import { getHealthProfileForAdmin } from 'common/services';
import { requestHealthActivities } from '../HealthActivities/health-activities-page.saga';
import { GET_HEALTH_ACTIVITIES } from '../HealthActivities/health-activities-page.actions';
import {
  ON_GET_HEALTH_PROFILE,
  GET_HEALTH_PROFILE,
  GET_HEALTH_PROGRAMS,
  ON_GET_HEALTH_PROGRAMS,
} from './health-concierge-tooling.actions';

export const getHealthProgramsForAdmin = userId =>
  call(websocketFetch, 'get_user_health_goal_programs_for_admin', {
    user_id: userId,
    exclude_complete: true,
  });

export function* requestHealthProfile({ payload: { userId } }) {
  yield request(GET_HEALTH_PROFILE, getHealthProfileForAdmin(userId));
}

export function* requestHealthPrograms({ payload: { userId } }) {
  yield request(GET_HEALTH_PROGRAMS, getHealthProgramsForAdmin(userId));
}

export function* healthConciergeSaga() {
  yield fork(all, [
    takeLatest(ON_GET_HEALTH_PROFILE, requestHealthProfile),
    takeLatest(ON_GET_HEALTH_PROGRAMS, requestHealthPrograms),
    takeLatest(GET_HEALTH_ACTIVITIES.BASE, requestHealthActivities),
  ]);
}
