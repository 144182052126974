import {
  GET_SPENDING_ACCOUNT_BALANCE_REPORT,
  SPENDING_ACCOUNT_BALANCE_REPORT_LIFECYCLE,
} from './spending-account-balance-report.types';
import { get } from 'lodash';

export const initialState = {
  spendingAccountBalanceReportLoading: false,
  spendingAccountBalanceReportError: null,
  spendingAccountBalanceReportReady: false,
};

export const employerSpendingAccountBalanceReportReducer = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_SPENDING_ACCOUNT_BALANCE_REPORT.STARTED:
      return { ...initialState, spendingAccountBalanceReportLoading: true };
    case GET_SPENDING_ACCOUNT_BALANCE_REPORT.SUCCEEDED:
      return { ...initialState, spendingAccountBalanceReportReady: true };
    case GET_SPENDING_ACCOUNT_BALANCE_REPORT.ERRORED:
      return {
        ...initialState,
        spendingAccountBalanceReportError: get(action, 'payload.error'),
      };
    case SPENDING_ACCOUNT_BALANCE_REPORT_LIFECYCLE.VISITED:
      return initialState;
    default:
      return state;
  }
};
