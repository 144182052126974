/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { validatePaymentCredentials as _validatePaymentCredentials } from 'common/services';
import {
  USER_HAS_NO_ADDRESS,
  USER_HAS_NO_DATE_OF_BIRTH,
  INVALID_ROUTING_NUMBER,
} from './payment-credential-validation-error-codes';
import { pick } from 'lodash';
import { TOAST_MESSAGE, INPUT_ERROR_TEXT } from '../../message-types';
import { EFT_CA } from '../../banking-info-types';

// These errors are shown as toasts
const validErrorCodes = [USER_HAS_NO_ADDRESS, USER_HAS_NO_DATE_OF_BIRTH];

export const getMessageType = (errorCode, bankingInfoType) => {
  // Since CA direct deposit doesn't have routing number input and that there is only 1 error message which suggests that there is an
  // error in either branch or institution number, show this error as a toast instead of under inputs.
  if (bankingInfoType === EFT_CA && errorCode === INVALID_ROUTING_NUMBER) {
    return TOAST_MESSAGE;
  }

  return validErrorCodes.includes(errorCode) ? TOAST_MESSAGE : INPUT_ERROR_TEXT;
};

/**
 * Called when user clicks "Add Account" button, prior to submitting payment credentials to create
 * a direct deposit account.
 *
 * Note: Handles required empty payment credential data input as well.
 *
 * @return {object} { valid, code, messageType, message }
 *  valid: bool, Are given credentials valid or not?
 *  code: string, Backend error code describing type of error occurred. If valid, is empty string
 *  messageType: string, Used to determine how to use 'message', either as toast or error text under label
 *  message: string, Reason why not valid. If valid, is empty string
 *  textId: string, used only for empty field cases. Return appropriate message for banking info type.
 *
 * Note: When not given required params (ex: accountNumber), websocket error is caught and formatted. Custom message returned in banking-info.sagas
 */
export function* validatePaymentCredentials({
  accountNumber,
  routingNumber,
  bankingInfoType,
  isEditing = false, // Only used to determine which generic error message to use when websocket errors occur
}) {
  let valid = false;
  let code = null;
  let messageType = null;
  let message = null;
  let textId = null;
  try {
    const response = yield _validatePaymentCredentials({
      accountNumber,
      routingNumber,
      bankingInfoType,
    });
    const {
      valid: _valid,
      error_code: _errorCode,
      error_message: _errorMessage,
    } = pick(response.info, ['valid', 'error_code', 'error_message']);
    if (_valid) {
      valid = true;
    } else {
      code = _errorCode;
      messageType = getMessageType(_errorCode, bankingInfoType);
      message = _errorMessage;
    }
  } catch (error) {
    // Just show generic toast error message when weird websocket errors occur
    messageType = TOAST_MESSAGE;
    textId = isEditing
      ? 'AN_ISSUE_EDITING_YOUR_ACCOUNT'
      : 'AN_ISSUE_ADDING_YOUR_ACCOUNT';
  }

  return {
    valid,
    code,
    messageType,
    message,
    textId,
  };
}
