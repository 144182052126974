import React from 'react';
import { Formik } from 'formik-legacy';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  withStateHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash/fp';
import { Loader } from 'semantic-ui-react';
import { SettlementForm } from './form/settlement-form.view';
import { getUpdatedAllocationAmounts } from './settlement.utilities';
import {
  selectIsReady,
  selectError,
  selectSuccess,
  selectUpdating,
  selectUpdatedAt,
  selectInitialFormValues,
  selectInitialClaimStatus,
  selectFunds,
  selectIsOldBenefitsModelUser,
  selectCategoryOptions,
  selectDependentOptions,
  selectVendorOptions,
  selectClaimDescription,
  selectDuplicateClaim,
  selectResolvingDuplicate,
  selectCustCareNote,
  selectAvailableAccountsWithApprovedAmounts,
  selectCanReadjustAllocationAmounts,
  selectClaimUserId,
  selectDateCreated,
  selectBenefitNames,
} from './settlement.selectors';
import {
  saveValuesRequested,
  submitClaimForm,
  vendorQueryChanged,
  approveDuplicateClaim,
  rejectDuplicateClaim,
  cancelClaimApproval,
  submitUpdatedAllocationAmounts,
} from './settlement.actions';

const withInitializer = compose(
  connect(
    createStructuredSelector({
      ready: selectIsReady,
      error: selectError,
      success: selectSuccess,
      updating: selectUpdating,
      updatedAt: selectUpdatedAt,
    }),
  ),
  branch(
    props => !props.ready,
    renderComponent(() => <Loader active inline="centered" />),
  ),
);

const withConnect = connect(
  createStructuredSelector({
    initialClaimStatus: selectInitialClaimStatus,
    initialFormValues: selectInitialFormValues,
    funds: selectFunds,
    isOldBenefitsModelUser: selectIsOldBenefitsModelUser,
    categoryOptions: selectCategoryOptions,
    dependentOptions: selectDependentOptions,
    vendorOptions: selectVendorOptions,
    claimDescription: selectClaimDescription,
    custCareNote: selectCustCareNote,
    duplicateClaim: selectDuplicateClaim,
    resolvingDuplicate: selectResolvingDuplicate,
    initialAllocations: selectAvailableAccountsWithApprovedAmounts,
    canReadjustAllocationAmounts: selectCanReadjustAllocationAmounts,
    claimUserId: selectClaimUserId,
    dateCreated: selectDateCreated,
    benefitNames: selectBenefitNames,
  }),
  {
    submitClaimForm,
    vendorQueryChanged,
    saveValuesRequested,
    approveDuplicateClaim,
    rejectDuplicateClaim,
    cancelClaimApproval,
    submitUpdatedAllocationAmounts,
  },
);

const scrollUp = lifecycle({
  componentDidUpdate({ updatedAt }) {
    if (this.props.updatedAt > updatedAt) window.scrollTo(0, 0);
  },
});

const getUpdateType = (initialStatus, status) =>
  status === initialStatus ? 'update' : status;

const withFormikEnhancer = Formik({
  handleSubmit: (values, formikBag) => {
    /* when adjusting allocation amounts, we submit to a different API endpoint than `edit_claim` and require a confirmation modal */
    const { props } = formikBag;
    const { isAdjustingApprovedAllocations, claimUserId } = props;
    if (isAdjustingApprovedAllocations) {
      /* Either show the confirmation dialog or submit the values */
      return props.isConfirmingEditedAllocations
        ? props.submitUpdatedAllocationAmounts(
            claimUserId,
            props.initialAllocations,
            values,
          )
        : props.confirmEditedAllocations();
    }

    /* Normal Claim update flow */
    const updateType = getUpdateType(props.initialClaimStatus, values.status);

    /* Reset certain form fields that are being jerks */
    formikBag.setFieldValue('message', null);

    return props.submitClaimForm(values, updateType);
  },
  mapPropsToValues: get('initialFormValues'),
});

const getPolicyAllocationTotal = (total, policy) => {
  const amount = policy.amountApproved || 0;
  return total + amount;
};

/* Parse through policies. Only allow submit if new values total === previous values total AND no policy exceeds its limit */
const canSubmitAdjustedAllocations = (initialAllocations, values) => {
  const initialTotalAmountApproved = initialAllocations.reduce(
    getPolicyAllocationTotal,
    0,
  );
  const updatedAllocations = getUpdatedAllocationAmounts(
    initialAllocations,
    values,
  );
  const invalidAllocation = updatedAllocations.find(
    policy =>
      policy.amountApproved > policy.available ||
      (!policy.categoryId && policy.amountAdjusted),
  );

  const totalOfUpdatedAmounts = updatedAllocations.reduce(
    getPolicyAllocationTotal,
    0,
  );

  if (
    invalidAllocation ||
    totalOfUpdatedAmounts !== initialTotalAmountApproved
  ) {
    return false;
  }

  return (
    updatedAllocations.filter(allocation => allocation.wasUpdated).length > 0
  );
};

const withEditAllocationsHandler = withStateHandlers(
  () => ({
    isAdjustingApprovedAllocations: false,
    isConfirmingEditedAllocations: false,
  }),
  {
    editAllocations: () => () => ({ isAdjustingApprovedAllocations: true }),
    confirmEditedAllocations: () => () => ({
      isConfirmingEditedAllocations: true,
    }),
    closeConfirmEditedAllocations: () => () => ({
      isConfirmingEditedAllocations: false,
    }),
    updatedAllocationsSubmitted: () => () => {
      return {
        isConfirmingEditedAllocations: false,
        isAdjustingApprovedAllocations: false,
      };
    },
    stopEditingAllocations: () => () => ({
      isAdjustingApprovedAllocations: false,
    }),
  },
);

const withSelectCoverageCategoryHandler = withStateHandlers(
  () => ({
    isSelectingCoverageCategory: false,
    targetBenefitId: '',
    handleSelectCategory: () => {},
  }),
  {
    openSelectCoverageCategory: () => handler => ({
      isSelectingCoverageCategory: true,
      handleSelectCategory: handler,
    }),
    closeSelectCoverageCategory: () => () => ({
      isSelectingCoverageCategory: false,
    }),
    setTargetBenefitId: () => benefitId => ({
      targetBenefitId: benefitId,
    }),
  },
);

const setFormProps = withProps(
  ({
    initialClaimStatus,
    values,
    initialAllocations,
    isAdjustingApprovedAllocations,
  }) => ({
    canEditPolicyAllocations:
      initialClaimStatus !== values.status || isAdjustingApprovedAllocations,
    canSubmit: isAdjustingApprovedAllocations
      ? canSubmitAdjustedAllocations(initialAllocations, values)
      : true,
    updateType: getUpdateType(initialClaimStatus, values.status),
  }),
);

export const Settlement = compose(
  withInitializer,
  withConnect,
  scrollUp,
  withEditAllocationsHandler,
  withSelectCoverageCategoryHandler,
  withFormikEnhancer,
  setFormProps,
)(SettlementForm);
