import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const submitClaim = ({
  amountClaimed,
  description,
  requestedBenefitType,
  contentIds,
}) =>
  call(websocketFetch, 'submit_claim', {
    content_ids: contentIds,
    description,
    amount_claimed: { value: amountClaimed },
    requested_benefit_type: requestedBenefitType,
  });
