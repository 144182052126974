import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const setUserQLEEnrollment = ({
  userId,
  groupId,
  coverageStartDate,
  enrollmentEndDate,
  qleType,
}) =>
  call(websocketFetch, 'set_user_qle_enrollment', {
    user_id: userId,
    group_id: groupId,
    coverage_effective_date: coverageStartDate,
    enrollment_end_date: enrollmentEndDate,
    qle_type: qleType,
  });
