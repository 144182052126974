import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const EMPLOYEE_SEARCH_LIFECYCLE = createLifecycleTypes(
  'emloyee-search/EMPLOYEE_SEARCH_LIFECYCLE',
);

export const SEARCH_USERS = createRequestTypes('employee-search/SEARCH_USERS');

export const EMPLOYEE_ROW_SELECTED = 'employee-search/EMPLOYEE_ROW_SELECTED';

export const BULK_UPLOAD_SELECTED = 'employee-search/BULK_UPLOAD_SELECTED';
export const SEARCH_PARAMS_CHANGED = 'employee-search/SEARCH_PARAMS_CHANGED';
export const NEXT_SEARCH_RESULTS_PAGE_REQUESTED =
  'employee-search/NEXT_SEARCH_RESULTS_PAGE_REQUESTED';

export const TERMINATE_EMPLOYEES = 'employee-search/TERMINATE_EMPLOYEES';
export const SUSPEND_EMPLOYEES = 'employee-search/SUSPEND_EMPLOYEES';
export const REINSTATE_EMPLOYEES = 'employee-search/REINSTATE_EMPLOYEES';

export const DO_TERMINATE_EMPLOYEES = createRequestTypes(
  'employee-search/DO_TERMINATE_EMPLOYEES',
);
export const DO_SUSPEND_EMPLOYEES = createRequestTypes(
  'employee-search/DO_SUSPEND_EMPLOYEES',
);
export const DO_REINSTATE_EMPLOYEES = createRequestTypes(
  'employee-search/DO_REINSTATE_EMPLOYEES',
);

export const EMPLOYEE_SEARCH_INIT = createRequestTypes('employee-search/INIT');

export const UNSCHEDULE_EMPLOYEES_SUSPENSION =
  'employee-search/UNSCHEDULE_EMPLOYEES_SUSPENSION';
export const UNSCHEDULE_EMPLOYEES_TERMINATION =
  'employee-search/UNSCHEDULE_EMPLOYEES_TERMINATION';
export const UNSCHEDULE_EMPLOYEES_REINSTATEMENT =
  'employee-search/UNSCHEDULE_EMPLOYEES_REINSTATEMENT';

export const DO_UNSCHEDULE_EMPLOYEES_SUSPENSION = createRequestTypes(
  'employee-search/DO_UNSCHEDULE_EMPLOYEES_SUSPENSION',
);
export const DO_UNSCHEDULE_EMPLOYEES_TERMINATION = createRequestTypes(
  'employee-search/DO_UNSCHEDULE_EMPLOYEES_TERMINATION',
);
export const DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT = createRequestTypes(
  'employee-search/DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT',
);

export const SET_USER_ROLE_IN_GROUP = 'employee-search/SET_USER_ROLE_IN_GROUP';
export const DO_SET_USER_ROLE_IN_GROUP = createRequestTypes(
  'employee-search/DO_SET_USER_ROLE_IN_GROUP',
);
