import { takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { GET_ALLOCATION_DRY_RUN } from './allocation-tool.actions';
import { benefitAllocationDryRun } from 'common/services/enrollment';
import { getGroup } from 'common/services';

export function* requestAllocationDryRun({ payload }) {
  const { groupId } = payload;
  let employerTzName;
  if (groupId) {
    const data = yield getGroup(payload.groupId);
    employerTzName = data.info.tz_name;
  }
  yield request(GET_ALLOCATION_DRY_RUN, benefitAllocationDryRun(payload), {
    employerTzName,
  });
}

export function* allocationToolSaga() {
  yield takeLatest(GET_ALLOCATION_DRY_RUN.BASE, requestAllocationDryRun);
}
