import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const setBeneficiaryDesignations = ({
  groupId = null,
  userId = null,
  designations,
}) =>
  call(websocketFetch, 'set_beneficiary_designations', {
    designations,
    group_id: groupId,
    user_id: userId,
  });
