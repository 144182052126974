import { useIntl } from '@leagueplatform/locales';
import {
  BenefitSet,
  EnrollmentStepItemType,
} from './enrollment-design-options.types';

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
interface useValidationProps {
  values: any;
  activeEditingItem: EnrollmentStepItemType | null;
}

export const useEnrollmentExperienceConfigValidation = (
  enrollmentStepUrls: any,
) => {
  const { formatMessage } = useIntl();

  const validation = ({ values, activeEditingItem }: useValidationProps) => {
    const errors: any = {};

    if (activeEditingItem) {
      const activeEditingItemType = (activeEditingItem as BenefitSet)?.type
        ? (activeEditingItem as BenefitSet)?.type
        : 'step';

      if (activeEditingItem) {
        if (activeEditingItemType !== 'set') {
          if (!values.name) {
            errors.name = formatMessage({
              id: 'EDITING_PANEL_NAME_IS_REQUIRED',
            });
          }

          if (activeEditingItemType === 'step') {
            // eslint-disable-next-line prefer-regex-literals -- FIXME: automatically added for existing issue
            const urlRegex = new RegExp(/^[a-zA-Z_-]+$/g);
            if (!urlRegex.test(values.url || '')) {
              errors.url = formatMessage({
                id: 'EDITING_PANEL_URL_MUST_BE_VALID',
              });
            }

            const duplicate = enrollmentStepUrls
              .filter((step: any) => step.id !== activeEditingItem?.id)
              .find((step: any) => step.url === values.url);
            if (duplicate) {
              errors.url = formatMessage(
                {
                  id: 'EDITING_PANEL_URL_MUST_BE_UNIQUE',
                },
                {
                  url: duplicate.url,
                },
              );
            }

            if (!values.url) {
              errors.url = formatMessage({
                id: 'EDITING_PANEL_URL_IS_REQUIRED',
              });
            }
          }
        }
      }
    }

    return errors;
  };

  return validation;
};
