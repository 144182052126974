import React, { useEffect } from 'react';
import { Tab, Segment } from 'semantic-ui-react';
import {
  BodyOne,
  HeadingTwo,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import PropTypes from 'prop-types';

import { PricingScenarioReport } from './pricing-config-qa-report';
import { PremiumsReportsPage as PremiumsReports } from 'apps/employer-experience-v2/employer-reports';
import PayCalendars from './employer-pay-calendar.container';
import { RateSheetExtract } from './rate-sheet-extract/rate-sheet-extract.container';
import { ScheduledReports } from './employer-scheduled-reports.container';
import { PAYROLL_FEATURE_FLAG } from './employer-payroll.types';
// Will need this when creating a new payroll config page
// import { PayrollConfig } from './pages/payroll-config/payroll-config.container';
import { DeductionCodeContainer } from './pages/deduction-codes/deduction-codes.container';
import { YearToDateReport } from './year-to-date-report/year-to-date-report.container';

const FlatSegment = genesisStyled(Segment)`
    margin-top: ${({ theme }) => theme.space.two}px !important;
    border: 1px solid ${({ theme }) => theme.colors.onSurface.border.subdued};
    padding: 0 !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0485168) !important;

  .ui.pointing.secondary.menu {
    background-color: ${({ theme }) => theme.colors.surface.background.subdued};
    padding: 0 ${({ theme }) => theme.space.two}px;
    display: flex;
    flex-wrap: wrap;
  }
  .ui.pointing.secondary.menu > .item {
    padding-bottom: ${({ theme }) => theme.space.half}px !important;
    margin-right: ${({ theme }) => theme.space.two}px !important;
    margin-top: ${({ theme }) => theme.space.one}px !important;
  }

  .ui.basic.segment.active.tab {
    padding: 0 ${({ theme }) => theme.space.two}px;
    margin: ${({ theme }) => theme.space.oneAndHalf}px 0;
  }
`;
const premiumsReport = ({ label, featureFlags }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      <PremiumsReports featureFlags={featureFlags} />
    </Tab.Pane>
  ),
});

const payCalendars = ({ label, ...props }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <PayCalendars {...props} />
    </Tab.Pane>
  ),
});

const pricingScenarioReport = ({ label, ...props }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <PricingScenarioReport {...props} />
    </Tab.Pane>
  ),
});

const rateSheetExtract = ({ label, ...props }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <RateSheetExtract {...props} />
    </Tab.Pane>
  ),
});

const scheduledReports = ({ label, ...props }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ScheduledReports {...props} />
    </Tab.Pane>
  ),
});

// Hide payroll config tab, will expose it with a new format
// const payrollConfig = ({ label, ...props }) => ({
//   menuItem: label,
//   render: () => (
//     <Tab.Pane basic attached={false}>
//       <PayrollConfig {...props} />
//     </Tab.Pane>
//   ),
// });

const deductionCodes = ({ label, ...props }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <DeductionCodeContainer {...props} />
    </Tab.Pane>
  ),
});

const yearToDateReport = ({ label, ...props }) => ({
  menuItem: label,
  render: () => (
    <Tab.Pane basic attached={false}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <YearToDateReport {...props} />
    </Tab.Pane>
  ),
});

const getTab = tab => {
  switch (tab) {
    case 'premiums-report':
      return 0;
    case 'pay-calendars':
      return 1;
    case 'pricing-scenario-report':
      return 2;
    case 'rate-sheet-extract':
      return 3;
    case 'scheduled-reports':
      return 4;
    // Hide payroll config tab, will expose it with a new format
    // case 'payroll-config':
    //   return 5;
    case 'deduction-codes':
      return 6;
    case 'ytd-report':
      return 7;
    default:
      return 0;
  }
};

export const EmployerPayroll = ({
  match,
  groupId,
  exit,
  loadPayroll,
  featureFlags,
}) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    return () => {
      exit();
    };
  }, [exit]);

  useEffect(() => {
    loadPayroll({ group_id: groupId });
  }, [loadPayroll, groupId]);

  return (
    <div>
      <div>
        <HeadingTwo>{formatMessage({ id: 'PAYROLL' })}</HeadingTwo>
        <BodyOne color="onSurface.text.subdued">
          {formatMessage({ id: 'PAYROLL_TAB_DESCRIPTION' })}
        </BodyOne>
      </div>
      <FlatSegment>
        <Tab
          defaultActiveIndex={getTab(match?.params?.tab)}
          menu={{ secondary: true, pointing: true }}
          panes={[
            premiumsReport({
              label: formatMessage({ id: 'PREMIUMS' }),
              featureFlags,
            }),
            payCalendars({
              label: formatMessage({ id: 'PAY_CALENDARS' }),
              groupId,
            }),
            pricingScenarioReport({
              label: formatMessage({ id: 'PRICING_SCENARIO_REPORT' }),
              groupId,
            }),
            rateSheetExtract({
              label: formatMessage({ id: 'RATE_SHEET_EXTRACT' }),
              groupId,
            }),
            scheduledReports({
              label: formatMessage({ id: 'SCHEDULED_REPORTS' }),
              groupId,
            }),
            // Hide payroll config tab, will expose it with a new format
            // payrollConfig({
            //   label: formatMessage({ id: 'PAYROLL_CONFIG' }),
            //   groupId,
            // }),
            deductionCodes({
              label: formatMessage({ id: 'DEDUCTION_CODES' }),
              groupId,
            }),
            yearToDateReport({
              label: formatMessage({ id: 'YTD_REPORT' }),
              groupId,
            }),
          ]}
        />
      </FlatSegment>
    </div>
  );
};

EmployerPayroll.propTypes = {
  groupId: PropTypes.string,
  exit: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }).isRequired,
  loadPayroll: PropTypes.func.isRequired,
  featureFlags: PropTypes.shape({ [PAYROLL_FEATURE_FLAG]: PropTypes.bool }),
};

EmployerPayroll.defaultProps = {
  groupId: null,
  featureFlags: {},
};
