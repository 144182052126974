import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image, Text } from '@leagueplatform/genesis-commons';
import { genesisFocus } from '@leagueplatform/ui-kit';
import CHEVRON_DOWN from 'apps/public/assets/chevron-down.svg';

const ContentHeader = ({
  imgSrc,
  imgWidth,
  title,
  isExpanded,
  headerStyling,
}) => {
  return (
    <Flex alignItems="center" width={1}>
      {imgSrc && (
        <Box maxWidth={imgWidth} marginRight="half">
          <Image src={imgSrc} alt="" width={imgWidth} display="block" />
        </Box>
      )}
      <Text
        fontSize="heading4"
        color="onSurface.text.primary"
        fontWeight="bold"
        flexGrow={1}
        textAlign="left"
        as="span"
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...headerStyling}
      >
        {title}
      </Text>
      <Box
        size="one"
        transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
      >
        <Image src={CHEVRON_DOWN} alt="" display="block" />
      </Box>
    </Flex>
  );
};

ContentHeader.propTypes = {
  imgSrc: PropTypes.string,
  imgWidth: PropTypes.string,
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  headerStyling: PropTypes.shape({
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
  }),
};

ContentHeader.defaultProps = {
  imgSrc: null,
  imgWidth: null,
  isExpanded: false,
  headerStyling: {},
};

export const CollapsibleContent = ({
  initiallyExpanded,
  children,
  marginTop,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const COLLAPSIBLE_SECTION_ID = 'collapsible-section';
  const COLLAPSIBLE_BUTTON_ID = 'collapsible-button';

  return (
    <Box
      backgroundColor="surface.background.secondary"
      borderRadius="small"
      padding="oneAndHalf"
      width={1}
      marginTop={marginTop}
    >
      <Box
        as="button"
        type="button"
        backgroundColor="transparent"
        border="none"
        width={1}
        focusStyle={genesisFocus}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-controls={COLLAPSIBLE_SECTION_ID}
        id={COLLAPSIBLE_BUTTON_ID}
        hoverStyle={{
          cursor: 'pointer',
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <ContentHeader isExpanded={isExpanded} {...props} />
      </Box>
      {isExpanded && (
        <Box
          role="region"
          id={COLLAPSIBLE_SECTION_ID}
          aria-labelledby={COLLAPSIBLE_BUTTON_ID}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

CollapsibleContent.propTypes = {
  initiallyExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
  marginTop: PropTypes.string,
};

CollapsibleContent.defaultProps = {
  initiallyExpanded: false,
  marginTop: 'none',
};
