export default {
  employeeHealthSavings: {
    name: 'employeeHealthSavings',
    groups: [
      {
        title: 'Member Policy Details',
        fields: [
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Allocation Settings',
        fields: [
          {
            name: 'parent.allocation_frequency',
            span: 6,
          },
          {
            name: 'parent.prorate_late_enrollment',
            span: 6,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'parent.benefit_waiting_period_months',
            span: 6,
          },
          {
            name: 'parent.opt_out',
            span: 6,
          },
          {
            name: 'parent.prevent_default_allocation',
            span: 6,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'parent.benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'parent.rollover_policy',
            span: 6,
          },
          {
            name: 'parent.auto_renew',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'id',
            span: 6,
          },
          {
            name: 'parent.id',
            span: 6,
          },
          {
            name: 'user_id',
            span: 6,
          },
          {
            name: 'parent.vendor',
            span: 6,
          },
          {
            name: 'parent.benefit_vendor_id',
            span: 6,
          },
          {
            name: 'parent.benefit_type',
            span: 6,
          },
          {
            name: 'parent.benefit_product_type',
            span: 6,
          },
          {
            name: 'status',
            span: 6,
          },
          {
            name: 'parent.benefit_status',
            span: 6,
          },
          {
            name: 'grace_period_start_date',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
          {
            name: 'inactive_date',
            span: 6,
          },
          {
            name: 'reinstated_date',
            span: 6,
          },
          {
            name: 'parent.provider_type',
            span: 6,
          },
          {
            name: 'parent.provider_id',
            span: 6,
          },
          {
            name: 'parent.renewed_from',
            span: 6,
          },
          {
            name: 'parent.plan_id',
            span: 6,
          },
          {
            name: 'parent.vendor_class_reference.vendor_id',
            span: 6,
          },
          {
            name: 'parent.vendor_class_reference.external_id',
            span: 6,
          },
          {
            name: 'parent.vendor_class_reference.external_parent_id',
            span: 6,
          },
        ],
      },
    ],
  },
};
