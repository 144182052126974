/* This is old home page code! please improve to best practices as you make changes */

import { takeLatest, takeEvery, fork, put } from 'redux-saga/effects';
import { loadQuickActions } from 'apps/member-experience/state/quick-actions/quick-actions.actions';
import {
  FETCH_CONFIG,
  ON_INIT_HOMEPAGE,
  FETCH_USER_MERCH_LIST,
  ON_INIT_USER_MERCHLIST,
  ON_INIT_USER_LIFEMARKET_MERCHLIST,
  FETCH_USER_LIFEMARKET_MERCHLIST,
} from './home-feed.types';
import { request, websocketFetch } from 'common/websocket-redux';

export function* homePageInit() {
  yield fork(request, FETCH_CONFIG, [websocketFetch, 'get_home_page_config']);
  yield put(loadQuickActions());
}

export function* watchHomePageInit() {
  yield takeLatest(ON_INIT_HOMEPAGE, homePageInit);
}

export function* getUserMerchList(action) {
  yield request(FETCH_USER_MERCH_LIST, [
    websocketFetch,
    'get_merch_list_for_location',
    { list_id: action.payload.listId },
  ]);
}

export function* getUserLifemarketMerchList(action) {
  yield request(FETCH_USER_LIFEMARKET_MERCHLIST, [
    websocketFetch,
    'get_lifemarket_merch_list',
    { list_id: action.payload.listId, max_image_height_px: 500 },
  ]);
}

export function* watchUserMerchListInit() {
  yield takeEvery(ON_INIT_USER_MERCHLIST, getUserMerchList);
}

export function* watchUserLifemarketMerchListInit() {
  yield takeEvery(
    ON_INIT_USER_LIFEMARKET_MERCHLIST,
    getUserLifemarketMerchList,
  );
}

export function* homeFeedSaga() {
  yield fork(watchHomePageInit);
  yield fork(watchUserMerchListInit);
  yield fork(watchUserLifemarketMerchListInit);
}
