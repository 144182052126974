import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  colour,
  fontSizeInPx,
  Box,
  FontAwesomeIcon,
} from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { useConfirm } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { ConfirmRemoveItemModal } from './confirm-remove-item-modal.view';

const ItemContainer = styled(Flex)`
  cursor: pointer;
  color: ${colour('greyLight')};
  &:hover {
    background-color: ${colour('purpleLightest')};
    color: ${colour('grey')};
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const Chevron = styled(props => <FontAwesomeIcon {...props} />).attrs({
  icon: 'chevron-right',
})`
  font-size: ${fontSizeInPx(4)}px;
  color: currentColor;
`;

const RemoveButton = styled(Button)`
  &:not(:hover) {
    background-color: transparent;
    border-color: transparent;
    color: ${colour('red')};
  }
`;

export const CollectionFormItem = ({ name, onConfirmRemove, onItemClick }) => {
  const { setIsConfirming, ...confirmProps } = useConfirm({
    onConfirm: onConfirmRemove,
  });

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ConfirmRemoveItemModal {...confirmProps} itemName={name} />
      <ItemContainer
        justify="space-between"
        align="center"
        p={2}
        my={1}
        borderWidth={1}
        borderColor="greyLight"
        borderRadius={2}
        onClick={onItemClick}
      >
        <Box flex="1">{name}</Box>
        <RemoveButton
          onClick={evt => {
            evt.stopPropagation();
            setIsConfirming(true);
          }}
          warning
        >
          Remove
        </RemoveButton>
        <Box ml={2}>
          <Chevron />
        </Box>
      </ItemContainer>
    </>
  );
};

CollectionFormItem.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  onConfirmRemove: PropTypes.func.isRequired,
};

CollectionFormItem.defaultProps = {
  name: '',
};

export const CollectionFormEmpty = ({ name }) => {
  const { formatMessage } = useIntl();
  return (
    <Box p={3} my={1} bg="greyLightest" borderRadius={2}>
      {formatMessage({ id: 'NO_ITEMS_OF_NAME' }, { name })}
    </Box>
  );
};

CollectionFormEmpty.propTypes = {
  name: PropTypes.string,
};

CollectionFormEmpty.defaultProps = {
  name: '',
};

export const CollectionForm = ({ children, ...props }) =>
  React.cloneElement(children, props);

CollectionForm.propTypes = {
  children: PropTypes.node.isRequired,
};
