import { useMutation, useQueryClient } from 'react-query';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { GET_USER_PROFILE_MESSAGE_TYPE } from '@leagueplatform/user-profile-api';
import { respondStudentCertification } from '../data/respond-student-certification';

export const useStudentCertification = ({ userId, dependentId, groupId }) => {
  const queryClient = useQueryClient();
  const contentMutation = useMutation(
    values =>
      respondStudentCertification({
        groupId,
        userId,
        dependentId,
        isFullTimeStudent: values.isFullTimeStudent,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE, userId]);
        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          textId: 'RESPONDED_STUDENT_CERTIFICATION',
        });
      },
      onError: error => {
        const errorObj = JSON.parse(error.message);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR',
          content: errorObj?.info?.reason,
        });
      },
    },
  );
  const onSubmitRespondStudentCertification = values => {
    contentMutation.mutate(values);
  };

  return {
    onSubmitRespondStudentCertification,
  };
};
