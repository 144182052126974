import {
  ANALYTICS_VIEW_HELP_POPUP,
  ANALYTICS_FRONTEND_ERROR_VALIDATING_ACCOUNT,
  ANALYTICS_BACKEND_ERROR_VALIDATING_ACCOUNT,
  ANALYTICS_VALIDATE_ACCOUNT,
} from './account-info-form-analytics.types';

export const analyticsViewHelpPopup = ({ isEditing, accountType }) => ({
  type: ANALYTICS_VIEW_HELP_POPUP,
  payload: {
    isEditing,
    accountType,
  },
});

export const analyticsFrontendErrorValidatingAccount = ({
  isEditing,
  accountType,
}) => ({
  type: ANALYTICS_FRONTEND_ERROR_VALIDATING_ACCOUNT,
  payload: {
    isEditing,
    accountType,
  },
});

export const analyticsBackendErrorValidatingAccount = ({
  isEditing,
  accountType,
}) => ({
  type: ANALYTICS_BACKEND_ERROR_VALIDATING_ACCOUNT,
  payload: {
    isEditing,
    accountType,
  },
});

export const analyticsValidateAccount = ({ isEditing, accountType }) => ({
  type: ANALYTICS_VALIDATE_ACCOUNT,
  payload: {
    isEditing,
    accountType,
  },
});
