import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Create Interac deposit account. CA only.
 * If given paymentCredentialsId, service will update instead of create.
 *
 * @param {string} email: Account holder's email linked to their Interac account
 * @param {string} userId: Member's user id
 * @param {string} paymentCredentialsId: given if account already in our records. Signifies update instead of create
 * @param {bool} payeeDefault: is default account?
 */
export const setInteracProfile = ({
  email,
  userId,
  paymentCredentialsId = null,
  payeeDefault = null,
}) =>
  call(websocketFetch, 'set_interac_profile', {
    email,
    user_id: userId,
    payment_credentials_id: paymentCredentialsId,
    default: payeeDefault,
  });
