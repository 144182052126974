import { put, select, takeLatest, call } from 'redux-saga/effects';
import { get } from 'lodash';
import {
  NEW_EMPLOYEE_VISITED,
  EDIT_EMPLOYEE_VISITED,
  ADMIN_GET_EMPLOYEE_SCHEMA,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EDIT_RESOURCES,
  NEW_EMPLOYEE_FORM_SUBMITTED,
  BENEFIT_CLASS_INPUT_CHANGED,
  EDIT_EMPLOYEE_FORM_SUBMITTED,
  ADMIN_GET_EMPLOYEE,
  EMPLOYEE_ROW_SELECTED,
  UPDATE_EMPLOYEE_CONTRIBUTIONS,
  GET_USER_DEDUCTIONS,
  UPDATE_USER_DEDUCTIONS,
  GET_USER_ADJUSTMENTS,
} from './types';
import { getUserBenefitContributions } from './actions';
import { GET_EMPLOYER_BENEFIT_PLANS } from '../Benefits/types';
import { GET_GROUP } from '../types';
import { REQUEST_USER_DOCUMENTS } from 'common/components/user-documents/user-documents.types';
import {
  GET_USER_PLAN_ENROLLMENT,
  REMOVE_USER_PLAN_ENROLLMENT,
} from './components/plan-enrollment/plan-enrollment.actions';
import { selectGroupId } from './selectors';
import { explodeObject } from 'common/adaptive-forms';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  getUserDocuments,
  adminGetEmployee,
  adminGetEmployeeSchema,
  adminSetEmployee,
  getUserProfile,
  getGroup,
} from 'common/services';
import { registerBenefitContribution } from 'common/services/payroll/register-benefit-contribution.service';
import { getBenefitPlanOptions as _getBenefitPlanOptions } from 'common/benefit-sets/get-benefit-sets.service';
import { getBenefitSelection as _getBenefitSelection } from 'apps/enrollment/resources/get-benefit-selection.service';
import { GET_EMPLOYEE_USER_PROFILE } from 'apps/employer-experience-v2/employee-profile/profile.types';
import { push } from 'connected-react-router';
import { CONTRIBUTOR_TYPES } from 'common/benefits';
import { LOAD_EMPLOYER_BENEFIT_PLANS } from 'common/benefit-plans';
import { getUserDeductions } from 'common/services/payroll/get-user-deductions.services';
import { registerUserDeductions } from 'common/services/payroll/register-user-deductions.services';
import { getUserAdjustments } from 'common/services/payroll/get-user-adjustment.service';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getResourcesForNewEmployee(groupId, benefitClassId) {
  yield request(
    ADMIN_GET_EMPLOYEE_SCHEMA,
    adminGetEmployeeSchema({
      groupId,
      benefitClassId,
      adminEditable: true,
    }),
  );
}

export function* initResourcesForNewEmployee(action) {
  const groupId = yield select(selectGroupId);
  const benefitClassId = get(action, 'payload.benefitClassId');
  yield getResourcesForNewEmployee(groupId, benefitClassId);
}

export function* getBenefitPlanOptions({ groupId, planId }) {
  return yield _getBenefitPlanOptions(groupId, planId);
}

export function* fetchPlans({ groupId }) {
  const response = yield request(GET_EMPLOYER_BENEFIT_PLANS, [
    websocketFetch,
    'get_employer_benefit_plans',
    { group_id: groupId },
  ]);
  return response?.info ?? {};
}

export function* getBenefitSelection({ groupId, userId }) {
  return yield _getBenefitSelection({ groupId, userId });
}

export function* getEmployeePlanId(payload) {
  const { plans } = yield call(fetchPlans, payload);
  return yield get(
    plans.find(plan => {
      return plan.benefit_class_id === payload.benefitClassId;
    }),
    'id',
  );
}

export function* getResourcesForEditEmployee(action) {
  const { userId, groupId } = action.payload;
  try {
    yield put(GET_EDIT_RESOURCES.start());
    const employeeResponse = yield request(
      ADMIN_GET_EMPLOYEE,
      adminGetEmployee({ userId, groupId }),
    );
    const benefitClassId = employeeResponse.info.benefit_class_id;
    if (!benefitClassId) throw new Error('Benefit Class ID undefined');
    const schemaResponse = yield request(
      ADMIN_GET_EMPLOYEE_SCHEMA,
      adminGetEmployeeSchema({
        groupId,
        benefitClassId,
        userId,
        adminEditable: true,
      }),
    );
    yield request(GET_EMPLOYEE_USER_PROFILE, getUserProfile(userId));
    yield request(REQUEST_USER_DOCUMENTS, getUserDocuments(userId));
    yield request(GET_GROUP, getGroup(groupId));
    yield put({
      type: LOAD_EMPLOYER_BENEFIT_PLANS.BASE,
      payload: { groupId, benefitClassId },
    });
    yield put(
      GET_EDIT_RESOURCES.success({
        employee: employeeResponse.info,
        schema: schemaResponse.info,
      }),
    );
  } catch (error) {
    yield put({ type: GET_EDIT_RESOURCES.ERRORED, error });
  }
}

export const formValuesToServicePayload = form => ({
  ...explodeObject(form),
  version: 2,
});

export function* createEmployee(action) {
  yield put(CREATE_EMPLOYEE.start());
  try {
    const newEmployeeInfo = formValuesToServicePayload(action.payload);
    const response = yield adminSetEmployee(newEmployeeInfo);
    const userId = response.info.invitation.user_id;
    const groupId = response.info.invitation.group_id;
    yield put(push(`/admin/employers/${groupId}/employees/${userId}`));
    yield put({
      type: CREATE_EMPLOYEE.SUCCEEDED,
      payload: { userId, groupId },
    });

    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'NEW_EMPLOYEE_SUCCESS_MESSAGE',

      values: {
        group: action.payload.group_name,
      },
    });
  } catch (error) {
    yield put(CREATE_EMPLOYEE.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'EMPLOYEE_PROFILE_ERROR',
    });
  }
}

export function* updateEmployee(action) {
  yield put(UPDATE_EMPLOYEE.start());
  try {
    const employeeInfo = formValuesToServicePayload(action.payload);
    const response = yield adminSetEmployee(employeeInfo);
    yield put(UPDATE_EMPLOYEE.success(response));

    yield call(getResourcesForEditEmployee, {
      payload: { groupId: employeeInfo.group_id, userId: employeeInfo.user_id },
    });

    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'EMPLOYEE_PROFILE_SAVED',
    });
  } catch (error) {
    yield put(UPDATE_EMPLOYEE.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'EMPLOYEE_PROFILE_ERROR',
    });
  }
}

export function* navigateToEmployeeProfile(action) {
  yield put(
    push(
      `/admin/employers/${action.payload.groupId}/employees/${action.payload.userId}`,
    ),
  );
}

export function* updatedEmployeeContributions(action) {
  const { contributions, userId, contributionBenefitTypes } = action.payload;
  try {
    if (contributions.length > 0) {
      // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
      for (const contribution of contributions) {
        yield registerBenefitContribution(contribution);
      }
      yield put(
        getUserBenefitContributions({
          userId,
          contributionBenefitTypes: [contributionBenefitTypes],
          contributor: CONTRIBUTOR_TYPES.EMPLOYEE,
        }),
      );
    }
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'UPDATE_CONTRIBUTIONS_ERROR',
    });
  }
}

export function* fetchUserDeductions({ payload }) {
  yield request(GET_USER_DEDUCTIONS, getUserDeductions(payload));
}

export function* showErrorToast(action) {
  yield call(toastActions.add, {
    V2: TOAST_STATUS.true,
    type: TOAST_STATUS.ERROR,
    text: action?.payload?.info?.reason,
  });
}

export function* updateRegisteredUserDeductions({ payload }) {
  yield request(UPDATE_USER_DEDUCTIONS, registerUserDeductions(payload));
}

export function* showUpdateSuccessToast() {
  yield call(toastActions.add, {
    V2: TOAST_STATUS.true,
    type: TOAST_STATUS.SUCCESS,
    textId: 'UPDATE_AMOUNT_SUCCESS',
  });
}

export function* requestUserPlanEnrollment(action) {
  const payload = action?.payload;
  yield request(GET_USER_PLAN_ENROLLMENT, [
    websocketFetch,
    'get_user_plan_enrollment',
    {
      user_id: payload?.userId,
      group_id: payload?.groupId,
      plan_id: payload?.planId,
    },
  ]);
}

export function* requestRemoveUserPlanEnrollment(action) {
  const payload = action?.payload;
  yield request(REMOVE_USER_PLAN_ENROLLMENT, [
    websocketFetch,
    'remove_user_plan_enrollment',
    {
      user_id: payload?.userId,
      group_id: payload?.groupId,
      plan_id: payload?.planId,
    },
  ]);
}

export function* fetchUserAdjustments({ payload }) {
  yield request(GET_USER_ADJUSTMENTS, getUserAdjustments(payload));
}

export function* employeeSaga() {
  yield takeLatest(
    [NEW_EMPLOYEE_VISITED, BENEFIT_CLASS_INPUT_CHANGED],
    initResourcesForNewEmployee,
  );
  yield takeLatest(NEW_EMPLOYEE_FORM_SUBMITTED, createEmployee);
  yield takeLatest(EDIT_EMPLOYEE_VISITED, getResourcesForEditEmployee);
  yield takeLatest(EDIT_EMPLOYEE_FORM_SUBMITTED, updateEmployee);
  yield takeLatest(EMPLOYEE_ROW_SELECTED, navigateToEmployeeProfile);
  yield takeLatest(UPDATE_EMPLOYEE_CONTRIBUTIONS, updatedEmployeeContributions);
  yield takeLatest(GET_USER_DEDUCTIONS.BASE, fetchUserDeductions);
  yield takeLatest(UPDATE_USER_DEDUCTIONS.BASE, updateRegisteredUserDeductions);
  yield takeLatest(UPDATE_USER_DEDUCTIONS.SUCCEEDED, showUpdateSuccessToast);
  yield takeLatest(
    [
      GET_USER_DEDUCTIONS.ERRORED,
      UPDATE_USER_DEDUCTIONS.ERRORED,
      GET_USER_ADJUSTMENTS.ERRORED,
    ],
    showErrorToast,
  );
  yield takeLatest(
    [GET_USER_PLAN_ENROLLMENT.BASE, REMOVE_USER_PLAN_ENROLLMENT.SUCCEEDED],
    requestUserPlanEnrollment,
  );
  yield takeLatest(
    REMOVE_USER_PLAN_ENROLLMENT.BASE,
    requestRemoveUserPlanEnrollment,
  );
  yield takeLatest(GET_USER_ADJUSTMENTS.BASE, fetchUserAdjustments);
}
