import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withInitializer } from './initializer.view';
import Page from './page.view';
import { selectClassesWithBenefits } from './benefits.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';

const withState = connect(
  createStructuredSelector({
    benefitClasses: selectClassesWithBenefits,
    isAdmin: selectUserIsAdmin,
  }),
);

export default compose(withInitializer, withState)(Page);
