import React from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { BodyOne, BodyTwoSecondary } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { DraggableBox } from '../enrollment-experience-config.styles';
import {
  BenefitSet,
  EnrollmentStepItemType,
} from '../enrollment-design-options.types';

interface DraggableBenefitSetProps {
  set: BenefitSet;
  index: number;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  activeEditingItemId?: string;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  openEditingPanel?: (item: EnrollmentStepItemType) => void;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  isInsideGroup?: boolean;
}

interface BenefitSetItemProps extends DraggableBenefitSetProps {
  snapshot: DraggableStateSnapshot;
  provided: DraggableProvided;
}

export const BenefitSetItem = ({
  set,
  isInsideGroup,
  openEditingPanel,
  snapshot,
  provided,
  activeEditingItemId,
}: BenefitSetItemProps) => {
  const { formatMessage } = useIntl();

  const onBenefitSetClick = (e: Event) => {
    e.stopPropagation();
    if (openEditingPanel) openEditingPanel(set);
  };

  return (
    <DraggableBox
      isActive={activeEditingItemId === set.id}
      backgroundColor="surface.background.tertiary"
      isDragging={snapshot.isDragging}
      innerRef={provided.innerRef}
      onClick={onBenefitSetClick}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...provided.draggableProps}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...provided.dragHandleProps}
    >
      <BodyOne data-testid={isInsideGroup ? 'group-set-title' : 'set-title'}>
        {set.name}{' '}
        <BodyTwoSecondary as="span">
          {formatMessage({ id: 'ENROLLMENT_DESIGN_SET' })}
        </BodyTwoSecondary>
      </BodyOne>
    </DraggableBox>
  );
};

export const DraggableBenefitSet = ({
  set,
  index,
  ...props
}: DraggableBenefitSetProps) => {
  return (
    <Draggable key={set.id} draggableId={set.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <BenefitSetItem
          set={set}
          provided={provided}
          snapshot={snapshot}
          index={index}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      )}
    </Draggable>
  );
};
