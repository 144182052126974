import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { vars, Box, FontAwesomeIcon } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Document } from 'common/components/document/document.view';
import { UserDocumentInfo } from '../user-document-info/user-document-info.view';
import PDF_ICON from 'apps/public/assets/pdf-placeholder.svg';
import { getContentUrl } from '@leagueplatform/league-content-api';

const Text = styled(Box)`
  line-height: 1.6;
  color: ${vars.colour.purpleDark};
`;

const Label = styled(Text)`
  font-weight: 500;
`;

const ActionLabel = styled(Label)`
  cursor: pointer;
  transition: opacity 250ms ease-out;
  width: 100%;
  text-align: center;
  &:hover {
    opacity: 0.75;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const EditIcon = styled(props => <FontAwesomeIcon {...props} />)`
  color: ${vars.colour.purpleText};
  font-size: 24px;
  height: 100%;
  transition: opacity 200ms ease-out;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export const UserDocument = ({
  contentId,
  documentType,
  documentTypeOtherDescription,
  expirationDate,
  onActionLabelClick,
  onImageClick,
  isImageContentType,
}) => (
  <Document
    imageUrl={isImageContentType ? getContentUrl(contentId) : PDF_ICON}
    onImageClick={onImageClick}
    height="220px"
    isImageContentType={isImageContentType}
  >
    <Flex flexDirection="column" w="100%" onClick={e => e.stopPropagation()}>
      <Flex w="100%" p={2} flexDirection="row">
        {expirationDate || documentType ? (
          <>
            <Flex flexDirection="row" w={8 / 9}>
              <UserDocumentInfo
                expirationDate={expirationDate}
                documentType={documentType}
                documentTypeOtherDescription={documentTypeOtherDescription}
                onClick={onActionLabelClick}
              />
            </Flex>
            <Flex w={1 / 9}>
              <EditIcon icon="edit" onClick={onActionLabelClick} />
            </Flex>
          </>
        ) : (
          <Flex
            justify="center"
            align="center"
            flexDirection="column"
            w="100%"
            onClick={onActionLabelClick}
            pt={1}
            pb={1}
          >
            <ActionLabel>
              <FormattedMessage id="ADD_DOCUMENT_INFORMATION" />
            </ActionLabel>
          </Flex>
        )}
      </Flex>
    </Flex>
  </Document>
);

export const UserDocumentShape = PropTypes.shape({
  contentId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  originalClaimId: PropTypes.string,
  expirationDate: PropTypes.string,
  documentType: PropTypes.string,
  documentTypeOtherDescription: PropTypes.string,
  notes: PropTypes.string,
});

UserDocument.propTypes = {
  isImageContentType: PropTypes.bool.isRequired,
  contentId: PropTypes.string.isRequired,
  documentType: PropTypes.string,
  documentTypeOtherDescription: PropTypes.string,
  expirationDate: PropTypes.string,
  onImageClick: PropTypes.func.isRequired,
  onActionLabelClick: PropTypes.func,
};

UserDocument.defaultProps = {
  documentType: null,
  documentTypeOtherDescription: null,
  expirationDate: null,
  onActionLabelClick: noop,
};
