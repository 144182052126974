/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { has } from 'lodash';
import { flow, isEmpty, negate } from 'lodash/fp';
import {
  contributionBenefitProductTypes,
  getPeriodTypeForBenefit,
  PERIOD_TYPES,
  CONTRIBUTOR_TYPES,
  PERIOD_TYPE_TRANSLATION_IDS,
} from 'common/benefits/metadata';
import {
  isContributionSet,
  getEnrollmentSteps,
  getGenericEnrollmentSteps,
  normalizeBenefitSetFromSteps,
  EAP_SLUG,
  getGESteps,
} from 'common/benefit-sets';
import { getThirdPersonCoverageTypeTranslationId } from 'common/dependents/dependents-metadata';
import { getStepIndex } from 'common/benefit-sets/enrollment-steps';
import { BENEFIT_TYPES } from '../../benefit-sets/benefit-set-types';
import { normalizeBenefitSetFromStepsWithContent } from 'common/benefit-sets/get-benefit-sets.service';

const money = ({ C, F = 0, M = F * 100 } = {}) => ({ C, F, M });

const zeroDollars = ({ groupCurrency }) => money({ C: groupCurrency });

const hasMoney = amount => (amount?.F ?? 0) !== 0;

const benefitIsWaived = ({ opt_out: waived } = {}) => waived === true;

const benefitIsUnselected = ({ opt_out: waived } = {}) => waived === null;

// Dang special case for monthly employee contribution.
// For details see https://everlong.atlassian.net/browse/ON-3448
const getEmployeeMonthlyCost = amounts => {
  const employeeMonthlyCost = (amounts || []).find(
    ({ contributor_type: type, contribution_period: period }) =>
      type === CONTRIBUTOR_TYPES.EMPLOYEE && period === PERIOD_TYPES.MONTH,
  );
  return employeeMonthlyCost?.amount;
};

export const getCost = ({
  cost,
  pay_period_cost: payPeriodCost,
  showCostsPerPayPeriod = false,
  amounts,
}) => {
  const source = showCostsPerPayPeriod ? payPeriodCost : cost;
  const employeeMonthlyCost = getEmployeeMonthlyCost(amounts);
  if (employeeMonthlyCost) return employeeMonthlyCost;
  const flexDollars = source?.flex_dollar;
  if (hasMoney(flexDollars)) return flexDollars;
  const approvedCost = source?.approved_cost;
  if (hasMoney(approvedCost)) return approvedCost;
  return source?.payroll_deduction;
};

const identValueIntlMessage = value => [
  { id: 'IDENT_VALUE' },
  { value: value || ' ' },
];

const identAmountIntlMessage = amount => [
  { id: 'IDENT_AMOUNT' },
  { amount: amount || ' ' },
];

const contributionIntlMessage = amount => [
  { id: 'AMOUNT_CONTRIBUTION' },
  { amount },
];

const amountIntlMessage = amount => identValueIntlMessage(amount);

const mapBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: identValueIntlMessage(benefitSummary?.benefit_name),
  costMessage: amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

const mapWaivedBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: [{ id: 'WAIVED' }],
  costMessage: amountIntlMessage(zeroDollars(benefitSummary)),
});

const mapUnselectedBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: [{ id: 'NO_SELECTION_MADE' }],
  costMessage: amountIntlMessage(zeroDollars(benefitSummary)),
});

const getContributionSelection = benefitSummary => {
  const { amounts, groupCurrency } = benefitSummary;
  if (amounts && typeof amounts.reduce === 'function') {
    const amountsSum = amounts.reduce((sum, amount) => {
      const proratedAmount = amount?.proration?.prorated_amount;
      const value = proratedAmount ? proratedAmount?.F : amount?.amount?.F;
      return sum + value;
    }, 0);
    return money({ C: groupCurrency, F: amountsSum });
  }
  return benefitSummary?.cost?.total_cost ?? zeroDollars(benefitSummary);
};

const mapContributionBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: contributionIntlMessage(
    getContributionSelection(benefitSummary),
  ),
  costMessage: amountIntlMessage(
    getCost({ ...benefitSummary, showCostsPerPayPeriod: false }) ||
      zeroDollars(benefitSummary),
  ),
  isFundedByLeftoverFlexDollars: (benefitSummary?.amounts || []).some(
    ({ contributor_type: type }) => type === CONTRIBUTOR_TYPES.LEFTOVER_FLEX,
  ),
  isProrated: (benefitSummary?.amounts || []).some(({ proration }) =>
    has(proration, 'prorated_amount'),
  ),
});

const mapApprovedAmountBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: identAmountIntlMessage(
    benefitSummary?.approved_coverage_amount,
  ),
  costMessage: amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

const mapVolumeAndDependentBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: [
    {
      id: getThirdPersonCoverageTypeTranslationId(
        benefitSummary?.selection?.dependent_coverage?.value,
      ),
    },
  ],
  selectionMessage: identValueIntlMessage(
    benefitSummary?.selection?.coverage_volume_option?.selection_option,
  ),
  costMessage: amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

const mapVolumeBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: identValueIntlMessage(
    benefitSummary?.selection?.coverage_volume_option?.selection_option,
  ),
  costMessage: amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

const mapDependentBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: [
    {
      id: getThirdPersonCoverageTypeTranslationId(
        benefitSummary?.selection?.dependent_coverage?.value ||
          'NO_DEPENDENT_SELECTION',
      ),
    },
  ],
  selectionMessage: identValueIntlMessage(benefitSummary?.benefit_name),
  costMessage: amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

/* Disablity benefit such as STD and LTD
   selectionMessage: the coverage amount per period
   coveragePeriodTypeId: the translation id of the coverage period
      STD: $<amount> / Week
      LTD: $<amount> / Month
*/
const mapDisabilityBenefit = benefitSummary => {
  const volumeAmount =
    benefitSummary?.coverage_volume_per_period?.volume_amount ?? null;
  const periodTypeId =
    PERIOD_TYPE_TRANSLATION_IDS?.[
      benefitSummary?.coverage_volume_per_period?.period
    ] ?? null;
  const shouldShowCoverageVolume =
    volumeAmount?.F > 1 && !benefitSummary?.hideDisabilityVolume;

  return {
    ...benefitSummary,
    id: benefitSummary?.benefit_id,
    coverageMessage: shouldShowCoverageVolume
      ? identAmountIntlMessage(volumeAmount)
      : null,
    coveragePeriodTypeId: shouldShowCoverageVolume ? periodTypeId : null,
    selectionMessage: identValueIntlMessage(benefitSummary?.benefit_name),
    costMessage: amountIntlMessage(
      getCost(benefitSummary) ?? zeroDollars(benefitSummary),
    ),
  };
};

export const mapBenefitSummaryToSelection = benefitSummary => {
  const hasDependentCoverage = has(
    benefitSummary,
    'selection.dependent_coverage',
  );
  const hasVolumeCoverage = has(
    benefitSummary,
    'selection.coverage_volume_option.selection_option',
  );
  const isDisabilityBenefit =
    benefitSummary.product_type === BENEFIT_TYPES.std ||
    benefitSummary.product_type === BENEFIT_TYPES.ltd;

  if (contributionBenefitProductTypes.includes(benefitSummary?.product_type)) {
    return mapContributionBenefit(benefitSummary);
  }
  if (benefitIsWaived(benefitSummary)) {
    return mapWaivedBenefit(benefitSummary);
  }
  if (benefitIsUnselected(benefitSummary)) {
    return mapUnselectedBenefit(benefitSummary);
  }
  if (isDisabilityBenefit) {
    return mapDisabilityBenefit(benefitSummary);
  }
  if (has(benefitSummary, 'approved_coverage_amount')) {
    return mapApprovedAmountBenefit(benefitSummary);
  }
  if (hasVolumeCoverage && hasDependentCoverage) {
    return mapVolumeAndDependentBenefit(benefitSummary);
  }
  if (hasVolumeCoverage) {
    return mapVolumeBenefit(benefitSummary);
  }
  if (hasDependentCoverage) {
    return mapDependentBenefit(benefitSummary);
  }
  return mapBenefit(benefitSummary);
};

const removeWaivedBenefits = set => {
  const benefits = set?.benefits ?? [];
  // if the set is contributions, it we don't want to leave behind a "waived" benefit for a set with all benefits waived.
  if (isContributionSet(set.typeId))
    return {
      ...set,
      benefits: benefits.filter(negate(benefitIsWaived)),
    };
  // if there is one or fewer benefits just return them all
  if (benefits.length <= 1) return set;
  // else ...
  return {
    ...set,
    // if every benefit is waived, return the first benefit so we have
    // something to show as the waived step, but blank out the name
    benefits: benefits.every(benefitIsWaived)
      ? [{ ...benefits[0], benefit_name: null }]
      : // otherwise filter down to only the opted in benefits (those that are not waived!)
        benefits.filter(negate(benefitIsWaived)),
  };
};

const removeUnselectedBenefits = set => {
  const benefits = set?.benefits ?? [];
  // if there is one or fewer benefits,
  // or if it's a contribution set
  // just return them all,
  if (benefits.length <= 1 || isContributionSet(set.typeId)) return set;

  return {
    ...set,
    // when all benefits in a set are unselected;
    // map the first benefit as the sole, unselected benefit in the set.
    benefits: benefits.every(benefitIsUnselected)
      ? [{ ...benefits[0], benefit_name: null, opt_out: null }]
      : // otherwise remove unselected benefits
        benefits.filter(negate(benefitIsUnselected)),
  };
};

export const flattenStepBenefitSets = sets => {
  const selections = [];
  const addSetIdToBenefits = set => ({
    ...set,
    benefits: set.benefits.map(benefit => ({
      ...benefit,
      setId: set.id,
    })),
  });
  (sets || []).forEach(
    flow(
      removeWaivedBenefits,
      removeUnselectedBenefits,
      addSetIdToBenefits,
      set => set.benefits && selections.push(...set.benefits),
    ),
  );
  return selections;
};

const addPeriodType = ({ product_type, payGroupPeriodTypeId, ...rest }) => ({
  ...rest,
  product_type,
  payGroupPeriodTypeId,
  periodType: getPeriodTypeForBenefit({ product_type, payGroupPeriodTypeId }),
});

export const mapStepToViewModel =
  ({
    groupCurrency = 'USD',
    showCostsPerPayPeriod = false,
    payGroupPeriodTypeId,
    hideDisabilityVolume,
  } = {}) =>
  step => {
    const addProps = bennie => ({
      ...bennie,
      groupCurrency,
      showCostsPerPayPeriod,
      payGroupPeriodTypeId,
      hideDisabilityVolume,
    });
    const selections = flattenStepBenefitSets(step.benefitSets).map(
      flow(addProps, addPeriodType, mapBenefitSummaryToSelection),
    );
    return { ...step, selections };
  };

const makeStep = set => ({
  ...set,
  benefitSets: [set],
});

const stepHasSelections = step => step?.selections?.length > 0;

// @TODO - remove this after GE and consume only mapSummarySetsToStepsGE
// this is the entry point selector to generate data for the coverage summary
// and what should included in each experience coverage summary selector
export const mapSummarySetsToSteps = (
  sets,
  groupCurrency,
  showCostsPerPayPeriod,
  payGroupPeriodTypeId,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  summarySteps = [],
  hideDisabilityVolume,
) => {
  const normalizedSets = Object.values(sets || {}).map(
    normalizeBenefitSetFromSteps(summarySteps),
  );
  // EAP is not shown on enrollment step nav but is shown in coverage summary
  const eapSet = normalizedSets.find(({ typeId }) => typeId === EAP_SLUG);

  const genericEnrollmentSteps = getGenericEnrollmentSteps(
    normalizedSets,
    [],
    summarySteps,
  );

  const isGenericEnrollment = !isEmpty(genericEnrollmentSteps);

  const steps = {
    ...getEnrollmentSteps(normalizedSets),
    ...genericEnrollmentSteps,
    ...(eapSet && { [EAP_SLUG]: makeStep(eapSet) }),
  };

  // Temporary while we are only using summarySteps for generic steps
  function getGenericEnrollmentStepIndex(item) {
    return summarySteps.findIndex(
      element =>
        // If generic step, then ID's will match
        element.id === item.id ||
        // If legacy step, we need to match url to the id
        element.url === item.id,
    );
  }

  return Object.values(steps)
    .map(
      mapStepToViewModel({
        groupCurrency,
        showCostsPerPayPeriod,
        payGroupPeriodTypeId,
        hideDisabilityVolume,
      }),
    )
    .filter(stepHasSelections)
    .sort((a, b) => {
      // To maintain order from get_coverage_summary "steps" array when there is a basic step in get_coverage_summary "steps" array
      if (isGenericEnrollment) {
        const firstItemIndex = getGenericEnrollmentStepIndex(a);
        const secondItemIndex = getGenericEnrollmentStepIndex(b);
        // This will append any step not in get_coverage_summary "steps" array at the end of the steps list
        if (firstItemIndex > -1 && secondItemIndex > -1)
          return secondItemIndex > firstItemIndex ? -1 : 1;
        return firstItemIndex === -1 ? 1 : -1;
      }

      // To maintain order from enrollmentSteps
      const firstItemStepIndex = getStepIndex(a.id);
      const secondItemStepIndex = getStepIndex(b.id);
      if (firstItemStepIndex > secondItemStepIndex) return 1;
      if (firstItemStepIndex < secondItemStepIndex) return -1;
      return 0;
    });
};

export const mapSummarySetsToStepsGE = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  sets = [],
  groupCurrency,
  showCostsPerPayPeriod,
  payGroupPeriodTypeId,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  summarySteps = [],
  hideDisabilityVolume,
) => {
  const normalizedSets = sets.map(
    normalizeBenefitSetFromStepsWithContent(summarySteps),
  );

  // EAP is not shown on enrollment step nav but is shown in coverage summary if the payroll deduction is greater than $0
  const eapSet = normalizedSets.find(({ typeId }) => typeId === EAP_SLUG);

  const genericEnrollmentSteps = getGESteps(normalizedSets, [], summarySteps);

  const steps = {
    ...genericEnrollmentSteps,
    ...(eapSet && {
      [EAP_SLUG]: makeStep(eapSet),
    }),
  };

  return Object.values(steps)
    .map(
      mapStepToViewModel({
        groupCurrency,
        showCostsPerPayPeriod,
        payGroupPeriodTypeId,
        hideDisabilityVolume,
      }),
    )
    .filter(stepHasSelections);
};
