import {
  SET_BILLING_DIVISION_FORM_SUBMITTED,
  REQUEST_REMOVE_BILLING_DIVISION,
  START_BILLING_DIVISION_EDIT,
  DONE_BILLING_DIVISION_EDIT,
  EDIT_BILLING_DIVISION_NAME,
} from './employer-divisions.types';

export const submitAddBillingDivisionForm = division => ({
  type: SET_BILLING_DIVISION_FORM_SUBMITTED,
  payload: division,
});

export const removeBillingDivision = payload => ({
  type: REQUEST_REMOVE_BILLING_DIVISION,
  payload,
});

export const toggleEditDivision = (value, payload) => {
  if (value) {
    return {
      type: START_BILLING_DIVISION_EDIT,
      payload,
    };
  }

  return {
    type: DONE_BILLING_DIVISION_EDIT,
    payload,
  };
};

export const editBillingDivisionName = payload => {
  return {
    type: EDIT_BILLING_DIVISION_NAME,
    payload,
  };
};
