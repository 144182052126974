import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { map } from 'lodash';
import { format } from 'date-fns';
import { Card, Form, Grid, Header, Label } from 'semantic-ui-react';
import {
  withAllocationHelpers,
  withTaxReset,
  withIsWithinGracePeriod,
} from './policy-card.hoc';
import { CategorySelect } from './category-select.view';
import { getSymbolForCurrency } from '@leagueplatform/web-common';

const dateFormat = 'dd MMMM yyyy';

const enhance = compose(
  withAllocationHelpers,
  withTaxReset,
  withIsWithinGracePeriod,
  setDisplayName('PolicyCard'),
);

export const PolicyCard = enhance(
  ({
    name,
    startDate,
    endDate,
    available,
    values,
    taxTypes,
    categoryOptions,
    getAmountApproved,
    getTaxValue,
    setAmountApproved,
    setTaxValue,
    getCategoryId,
    setCategoryId,
    readOnly,
    openSelectCoverageCategory,
    setTargetBenefitId,
    benefitId,
    graceStartDate,
    graceEndDate,
    isWithinGracePeriod,
  }) => (
    <Card fluid>
      <div className="card-header">
        <Header as="h2">{name}</Header>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h6" className="no-margin">
              Benefit Start Date
            </Header>
            <span>{format(new Date(startDate), dateFormat)}</span>
          </Grid.Column>
          <Grid.Column>
            <Header as="h6" className="no-margin">
              Benefit End Date
            </Header>
            <span>{format(new Date(endDate), dateFormat)}</span>
          </Grid.Column>
        </Grid>
        {isWithinGracePeriod && (
          <Grid columns={2}>
            <Header as="h5" className="no-margin">
              Member is currently in grace period.
            </Header>
            <Grid.Column>
              <Header as="h6" className="no-margin">
                Grace Period Start Date
              </Header>
              <span>{format(new Date(graceStartDate), dateFormat)}</span>
            </Grid.Column>
            <Grid.Column>
              <Header as="h6" className="no-margin">
                Grace Period End Date
              </Header>
              <span>{format(new Date(graceEndDate), dateFormat)}</span>
            </Grid.Column>
          </Grid>
        )}
      </div>
      <Card.Content>
        <Form.Field width={12}>
          {/* Used to map coverage id's to names */}
          <CategorySelect
            label="Category"
            value={getCategoryId()}
            options={categoryOptions}
            readOnly={readOnly}
            openSelectCoverageCategory={() => {
              setTargetBenefitId(benefitId);
              openSelectCoverageCategory(setCategoryId);
            }}
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              label="Approved Amount"
              type="number"
              step="0.01"
              labelPosition="right"
              disabled={readOnly}
              value={getAmountApproved()}
              onChange={(event, { value }) => {
                setAmountApproved(value);
              }}
            >
              <Label basic>{getSymbolForCurrency(values.currency)}</Label>
              <input />
              <Label basic>{values.currency}</Label>
            </Form.Input>
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Balance"
              type="number"
              step="0.01"
              labelPosition="right"
              disabled
            >
              <Label basic>{getSymbolForCurrency(values.currency)}</Label>
              <input value={available} />
              <Label basic>{values.currency}</Label>
            </Form.Input>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {map(taxTypes, taxType => (
            <Form.Field width={6} key={taxType}>
              <Form.Input
                label={taxType}
                type="number"
                step="0.01"
                labelPosition="right"
                disabled={readOnly}
                value={getTaxValue(taxType)}
                onChange={(event, { value }) => setTaxValue(taxType, value)}
              >
                <Label basic>{getSymbolForCurrency(values.currency)}</Label>
                <input />
                <Label basic>{values.currency}</Label>
              </Form.Input>
            </Form.Field>
          ))}
        </Form.Group>
      </Card.Content>
    </Card>
  ),
);
