import React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import styled, { css } from 'styled-components';
import { StyledComponentRefWrapper } from '@leagueplatform/ui-kit';
import { generatePath, Link, LinkProps } from 'react-router-dom';
import { useIntl } from '@leagueplatform/locales';
import {
  PathToStepMap,
  Step as StepType,
} from '../enrollment-design.constants';
import { StepChangeLinkButton } from '../step-change-link-button/step-change-link-button.view';
import { theme } from 'theme';

export interface EnrollmentDesignHeaderProps {
  activeStep: StepType;
  url: string;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  selectedPlanId?: string;
}

const StepStyles = css`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.decorative.brand.primary.pastel};
  color: ${theme.colors.decorative.brand.primary.dark};
  padding: 0.2rem 1.5rem;
  margin-right: 0.2rem;
  font-weight: 500;

  &:first-child {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
  }

  &:last-child {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    margin-right: 0;
  }
`;

interface StepLinkProps extends LinkProps {
  active?: boolean;
}

// TODO: Fix formatting
// const StepLink = styled(StyledComponentRefWrapper(Link)) <StepLinkProps>`
// instead of
// const StepLink = styled(StyledComponentRefWrapper(Link))<StepLinkProps>`
const StepLink = styled(StyledComponentRefWrapper(Link))<StepLinkProps>`
  ${StepStyles}

  &:hover {
    background-color: ${theme.colors.decorative.brand.primary.brightest};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${theme.colors.decorative.brand.primary.brightest};
    `}
`;

const Step = styled.span`
  ${StepStyles}
`;
export const EnrollmentDesignHeader = ({
  activeStep,
  url,
  selectedPlanId,
}: EnrollmentDesignHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Flex marginBottom="two" justifyContent="space-between">
        {Object.keys(PathToStepMap).map(step => {
          const stepPath = `${url}${step}`;
          const stepId = PathToStepMap[step];

          if (stepPath.includes(':planId') && !selectedPlanId) {
            return <Step key={stepId}>{formatMessage({ id: stepId })}</Step>;
          }

          return (
            <StepLink
              key={stepId}
              to={generatePath(stepPath, { planId: selectedPlanId })}
              active={stepId === activeStep}
            >
              {formatMessage({ id: stepId })}
            </StepLink>
          );
        })}
      </Flex>
      <StepChangeLinkButton
        url={url}
        planId={selectedPlanId}
        activeStep={activeStep}
      />
    </>
  );
};
