import React from 'react';
import { Button, Header, Label, Grid } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ActionsDropdown from 'apps/employer-experience-v2/employee-profile/actions-dropdown.container';
import styled from 'styled-components';
import { vars, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Link } from 'react-router-dom';
import {
  TabLink,
  TabList,
  TabPanel,
  TabContent,
} from 'apps/employer-experience/ui-kit/tabs';
import { useIntl } from '@leagueplatform/locales';
import { EmployeeRoutes } from './employee.routes';
import { EMPLOYEE_STATE_NAMES } from 'common/constants';
import { ADMIN } from '@leagueplatform/web-common';

const ContainerWithActionsDropdown = styled(Box)`
  .dropdown.visible .menu.visible {
    position: absolute;
  }
`;

const HRAdminBadge = styled.span`
  text-transform: capitalize;
  font-size: 1.5rem;
  color: ${vars.colour.mediumGrey};
`;

export const EmployeeTabs = ({
  employeeName,
  employeeEmail,
  employeeInviteURL,
  employeeStatus,
  employeeUserId,
  groupId,
  groupRole,
  hasSpendingAccounts,
  invitationUrl,
  isAdmin,
  employeeGetError,
  employeeBaseUrl,
  suspendedDate,
  suspendedType,
  employeeCanReinstate,
}) => {
  const { formatMessage } = useIntl();
  if (employeeGetError) {
    return (
      <Grid.Row>
        <Button
          as={Link}
          to={`/admin/employers/${groupId}/employees`}
          className="text-button"
        >
          &larr; <FormattedMessage id="BACK_TO_EMPLOYEES" />
        </Button>
        <Grid.Column width={16}>
          <Header as="h1">Employee Not Found</Header>
        </Grid.Column>
      </Grid.Row>
    );
  }
  const employeeStatusName = EMPLOYEE_STATE_NAMES[employeeStatus] || 'UNKNOWN';

  return (
    <Box>
      <Button
        as={Link}
        to={`/admin/employers/${groupId}/employees`}
        className="text-button"
      >
        &larr; <FormattedMessage id="BACK_TO_EMPLOYEES" />
      </Button>
      <Flex justify="space-between" pt={2} mb={2}>
        <Header as="h2">
          <Box textAlign="left">
            {employeeName !== null ? (
              <span className="employee-label__name">{employeeName}</span>
            ) : (
              <span className="employee-label__email">{employeeEmail}</span>
            )}
            {groupRole === ADMIN && (
              <HRAdminBadge>&nbsp;-&nbsp;{groupRole}</HRAdminBadge>
            )}
            <Label
              className={`label-${employeeStatus} label-profile`}
              icon="circle"
              content={formatMessage({ id: employeeStatusName })}
            />
          </Box>
        </Header>
        <ContainerWithActionsDropdown textAlign="right">
          <ActionsDropdown
            userId={employeeUserId}
            groupId={groupId}
            isAdmin={isAdmin}
            status={employeeStatus}
            groupRole={groupRole}
            name={employeeName}
            text="Actions"
            icon="dropdown"
            className="actions-dropdown-button-reverse"
            url={employeeInviteURL || invitationUrl}
            hasSpendingAccounts={hasSpendingAccounts}
            suspendedDate={suspendedDate}
            suspendedType={suspendedType}
            showReinstateAction={employeeCanReinstate?.showReinstateAction}
            showReinstateCancel={employeeCanReinstate?.showReinstateCancel}
          />
        </ContainerWithActionsDropdown>
      </Flex>
      <TabPanel>
        <TabList>
          <TabLink to={`${employeeBaseUrl}/profile`}>
            {formatMessage({ id: 'PROFILE' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/benefits`}>
            {formatMessage({ id: 'BENEFITS' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/documents`}>
            {formatMessage({ id: 'SUPPORTING_DOCUMENTS' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/forms`}>
            {formatMessage({ id: 'FORMS' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/summary`}>
            {formatMessage({ id: 'BENEFITS_SUMMARY' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/dependents`}>
            {formatMessage({ id: 'DEPENDENTS' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/tasa-contributions`}>
            {formatMessage({ id: 'TASA_CONTRIBUTIONS' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/payroll-deductions`}>
            {formatMessage({ id: 'PAYROLL_DEDUCTIONS' })}
          </TabLink>
          <TabLink to={`${employeeBaseUrl}/credits`}>
            {formatMessage({ id: 'CERTIFICATION_CREDITS' })}
          </TabLink>
        </TabList>
        <TabContent>
          <EmployeeRoutes
            employeeBaseUrl={employeeBaseUrl}
            employeeUserId={employeeUserId}
            groupId={groupId}
          />
        </TabContent>
      </TabPanel>
    </Box>
  );
};

EmployeeTabs.propTypes = {
  employeeName: PropTypes.string,
  employeeUserId: PropTypes.string.isRequired,
  employeeInviteURL: PropTypes.string,
  employeeStatus: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  groupRole: PropTypes.string,
  employeeEmail: PropTypes.string,
  hasSpendingAccounts: PropTypes.bool,
  invitationUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  employeeGetError: PropTypes.bool,
  employeeBaseUrl: PropTypes.string,
  suspendedDate: PropTypes.string,
  suspendedType: PropTypes.string,
  employeeCanReinstate: PropTypes.shape({
    showReinstateAction: PropTypes.bool,
    showReinstateCancel: PropTypes.bool,
  }),
};

EmployeeTabs.defaultProps = {
  employeeEmail: null,
  employeeName: null,
  invitationUrl: null,
  employeeInviteURL: null,
  employeeStatus: null,
  employeeBaseUrl: null,
  groupRole: null,
  hasSpendingAccounts: false,
  isAdmin: false,
  employeeGetError: false,
  suspendedDate: '',
  suspendedType: '',
  employeeCanReinstate: null,
};

export default EmployeeTabs;
