import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectEmployeeEditId } from '../Employee/selectors';
import EmployeeDetails from './presenter';

const mapStateToProps = createStructuredSelector({
  employeeId: selectEmployeeEditId,
});

export default connect(mapStateToProps)(EmployeeDetails);
