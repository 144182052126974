import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, FormattedHTMLMessage } from '@leagueplatform/locales';
import { defaultFlexDollarTerm } from 'common/constants';
import {
  colour,
  Box,
  Text,
  Tooltip,
  TooltipContentV2,
} from '@leagueplatform/ui-kit';

const TooltipAnchorText = styled(Text)`
  border-bottom: 2px dotted ${colour('mediumGrey')};
`;

export const ProratedLabel = ({ flexDollarTerm, children }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Tooltip inline>
        <TooltipAnchorText fontWeight={500} mt={0} mr="1ch" color="purple">
          {formatMessage({ id: 'PRORATED_VALUE' })}
        </TooltipAnchorText>
        <TooltipContentV2>
          <Box textAlign="left">
            <FormattedHTMLMessage
              id="WHAT_IS_PRORATION_TOOLTIP"
              values={{ flexDollarTerm }}
            />
          </Box>
        </TooltipContentV2>
      </Tooltip>
      {children}
    </>
  );
};
ProratedLabel.propTypes = {
  children: PropTypes.node.isRequired,
  flexDollarTerm: PropTypes.string,
};

ProratedLabel.defaultProps = {
  flexDollarTerm: defaultFlexDollarTerm,
};
