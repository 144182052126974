import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { genesisStyled } from '@leagueplatform/genesis-commons';
import { LOOKER_URL } from '../employer-insights.constants';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { getHRAdminAnalytics } from 'common/utilities/get-hr-admin-analytics';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const IFrame = genesisStyled.iframe`
  height: 800px;
  width: 100%
`;

export const DashboardView = ({ url, onLoad }) => {
  const iFrameRef = useRef();
  const isInHRAdminPortal = useIsInHRAdminPortal();
  const [startTime, setStartTime] = useState(null);

  const setIframeHeight = ({ height }) => {
    iFrameRef.current.style.height = `${height}px`;
  };

  const sendAnalytics = detail => {
    const getparam = getHRAdminAnalytics();
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.HR_ADMIN,
      screen_name: getparam[0],
      sub_product_area: getparam[1] || null,
      detail,
    });
  };

  const millisToSeconds = useCallback((name, millis) => {
    const seconds = (millis / 1000).toFixed(2);
    return sendAnalytics(`${name} loaded in ${seconds}`);
  }, []);

  const dashboardDownload = useCallback(
    dashboard => {
      if (isInHRAdminPortal)
        sendAnalytics(`download ${dashboard.dashboard.title.toLowerCase()}`);
    },
    [isInHRAdminPortal],
  );

  const tileDownload = useCallback(
    tile => {
      if (isInHRAdminPortal)
        sendAnalytics(`download ${tile.tile.title.toLowerCase()}`);
    },
    [isInHRAdminPortal],
  );

  const downloadStart = useCallback(() => {
    if (isInHRAdminPortal) {
      setStartTime(performance.now());
    }
  }, [isInHRAdminPortal]);

  const dashboardComplete = useCallback(
    dashboard => {
      onLoad();
      if (isInHRAdminPortal) {
        const completedTime = performance.now();

        const milisecondsFinal = completedTime - startTime;
        millisToSeconds(
          dashboard.dashboard.title.toLowerCase(),
          milisecondsFinal,
        );
      }
    },
    [isInHRAdminPortal, onLoad, startTime, millisToSeconds],
  );

  useEffect(() => {
    const eventsCallback = {
      'dashboard:run:complete': dashboardComplete,
      'page:properties:changed': setIframeHeight,
      'dashboard:download': dashboardDownload,
      'dashboard:tile:download': tileDownload,
      'dashboard:run:start': downloadStart,
    };

    const handleMessage = event => {
      if (event.origin === LOOKER_URL) {
        const data = JSON.parse(event.data);
        const callback = eventsCallback[data.type];
        if (callback) callback(data);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [
    url,
    onLoad,
    dashboardDownload,
    tileDownload,
    downloadStart,
    dashboardComplete,
  ]);

  return <IFrame src={url} title="bi-iframe" ref={iFrameRef} />;
};

DashboardView.propTypes = {
  url: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
};

DashboardView.defaultProps = {
  url: '',
};
