import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Message } from 'semantic-ui-react';
import { Box, Text } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import EmployeesHeader from './Header';
import SearchForm from './SearchForm';
import Table from './Table';
import { employeePropTypes } from '../constants';
import ActionsDropdown from './actions-dropdown.container';

const Page = ({
  employees,
  loading,
  error,
  searchText,
  hasMoreResults,
  hasEmptyResults,
  onSearchParamsChanged,
  onEndOfListReached,
  onEmployeeRowSelected,
  employerId,
  employeesServiceStatus,
  isAdmin,
  hasSpendingAccounts,
  hasTopupableSpendingAccounts,
  employeesVisited,
  resetEmployeesState,
}) => {
  useEffect(() => {
    employeesVisited();
    return () => resetEmployeesState();
  }, [employeesVisited, resetEmployeesState]);

  return (
    <Box>
      <EmployeesHeader groupId={employerId} isAdmin={isAdmin} />
      <SearchForm
        loading={loading}
        onSearchParamsChanged={onSearchParamsChanged}
        isAdmin={isAdmin}
      />
      <Header as="h3">
        {searchText && (
          <FormattedMessage
            id="RESULTS_FOR"
            values={{
              query: searchText,
            }}
          />
        )}
      </Header>
      {employeesServiceStatus && (
        <Message
          negative={employeesServiceStatus.error}
          positive={!employeesServiceStatus.error}
        >
          <Message.Header>
            <FormattedMessage
              id={
                employeesServiceStatus.error
                  ? 'ERROR_STATE_TITLE'
                  : 'SUCCESS_STATE_TITLE'
              }
            />
          </Message.Header>
          <Text>{employeesServiceStatus.reason}</Text>
        </Message>
      )}
      <Table
        ActionsDropdown={ActionsDropdown}
        loading={loading}
        error={error}
        employees={employees}
        onEndOfListReached={onEndOfListReached}
        hasMoreResults={hasMoreResults}
        onEmployeeRowSelected={onEmployeeRowSelected}
        employerId={employerId}
        isAdmin={isAdmin}
        hasSpendingAccounts={hasSpendingAccounts}
        hasTopupableSpendingAccounts={hasTopupableSpendingAccounts}
        hasEmptyResults={hasEmptyResults}
      />
    </Box>
  );
};

Page.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.shape(employeePropTypes)).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  hasEmptyResults: PropTypes.bool.isRequired,
  onSearchParamsChanged: PropTypes.func.isRequired,
  onEndOfListReached: PropTypes.func.isRequired,
  onEmployeeRowSelected: PropTypes.func.isRequired,
  employerId: PropTypes.string.isRequired,
  employeesServiceStatus: PropTypes.shape({
    error: PropTypes.bool,
    reason: PropTypes.string,
  }),
  resetEmployeesState: PropTypes.func.isRequired,
  employeesVisited: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  searchText: PropTypes.string,
  hasSpendingAccounts: PropTypes.bool.isRequired,
  hasTopupableSpendingAccounts: PropTypes.bool.isRequired,
};

Page.defaultProps = {
  employeesServiceStatus: null,
  searchText: null,
};

export default Page;
