/**
 * Created this file to lay some ground work for future refactoring all of account-info-form.
 * Right now, all of this component's redux stuff is handled in banking info, which no longer
 * makes much sense.
 */
import { put, takeLatest, fork, take, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GENERATE_INTERAC_CODE,
  REQUEST_GENERATE_INTERAC_CODE,
  CREATE_DIRECT_DEPOSIT_ACCOUNT,
  REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT,
} from './account-info-form.types';
import { request } from 'common/websocket-redux';
import {
  generateInteracCode,
  setStripeProfile,
  setEftProfile,
  setInteracProfile,
} from 'common/services';
import { accountInfoFormAnalyticsSaga } from './account-info-form-analytics.sagas';
import { validatePaymentCredentials } from './validate-payment-credentials.service';
import {
  analyticsBackendErrorValidatingAccount,
  analyticsValidateAccount,
} from './account-info-form-analytics.actions';
import { openConfirmationModal } from '../direct-deposit-account.actions';
import { TOAST_MESSAGE, INPUT_ERROR_TEXT } from '../../message-types';
import { STRIPE_EU, EFT_CA, EFT_US, INTERAC } from '../../banking-info-types';
import {
  CREATE_DIRECT_DEPOSIT_CONFIRMED,
  CREATE_DIRECT_DEPOSIT_CANCELLED,
} from '../direct-deposit-account.types';
import { includes } from 'lodash';
import { setValidatedDirectDepositAccountInfo } from './account-info-form.actions';
import { selectUserId } from 'common/state/user/user.selectors';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';

export function* requestGenerateInteracCode({ payload }) {
  yield request(
    REQUEST_GENERATE_INTERAC_CODE,
    generateInteracCode({ paymentCredentialsId: payload.paymentCredentialsId }),
  );
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'NEW_PASSCODE_GENERATED_SUCCESS',
  });
}

/**
 * Used to handle payment credential validation, creation and editing errors, either display it as toast message or under specific input.
 *
 * Toast Messages:
 * If toast message and error is accompanied by an error code (`code`), an error
 * message is received from service response which is displayed. Otherwise,
 * show generic error message.
 *
 * validation required props:
 * @param {string} messageType: Either `TOAST_MESSAGE` or `INPUT_ERROR_TEXT`, used to define how error is displayed
 * @param {string} code: If has a value, it's a specific error message from Backend involving payment credential.
 * If not specified, it's a payment credential validation error or catch for any websocket error.
 * @param {string} message: Only given if specific type of error where it has a `code`. Error message from Backend.
 * @param {bool} valid: Only given if input error. Always will be false! (hm...)
 */
export function* handleCreateDirectDepositAccountErrorMessages(validation) {
  const { messageType, code, message, valid, textId } = validation;

  // Handle missing account info or empty credentials error
  if (messageType === TOAST_MESSAGE) {
    const textParam = code ? { text: message } : { textId };

    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,

      ...textParam, // NOTE: Will eventually need to support other empty state
    });
  } else if (messageType === INPUT_ERROR_TEXT) {
    yield put(setValidatedDirectDepositAccountInfo({ valid, code, message }));
  }

  yield put({ type: REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.ERRORED });
}

/**
 * Handles create/edit create direct deposit account for direct deposit CA/US and Stripe.
 * If action's payload contains `paymentCredentialsId`, it's editing.
 *
 * Flow:
 * First validates given credentials. If not valid, response is shown as error message under inputs or toast message.
 * Next, confirmation modal is opened. If cancelled, close modal and exit out of saga.
 * If confirmed, uses appropriate service call to match banking type.
 *
 * If at any point an error occurs, show generic adding/editing error state.
 */
export function* requestCreateDirectDepositAccount(action) {
  const { paymentCredentialsId, accountType } = action.payload;
  const isEditing = !!paymentCredentialsId;

  try {
    // paymentCredentialsId only given if editing
    const { requiredCredentials } = action.payload;
    const userId = yield select(selectUserId); // Only needed if direct deposit. Interac will use it when support added.
    const {
      accountNumber,
      name, // `null` if Stripe
      routingNumber, // `null` if Stripe
      payeeDefault,
      email, // Interac only
    } = requiredCredentials;

    yield put({ type: REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.STARTED });

    // Our validation doesn't need to check email, so skip.
    if (accountType !== INTERAC) {
      yield put(analyticsValidateAccount({ isEditing, accountType }));
      const validation = yield validatePaymentCredentials({
        accountNumber,
        routingNumber,
        bankingInfoType: accountType,
        isEditing,
      });

      if (!validation.valid) {
        yield put(
          analyticsBackendErrorValidatingAccount({ isEditing, accountType }),
        );
        return yield handleCreateDirectDepositAccountErrorMessages(validation);
      }
    }

    // Reset state if valid. Isn't needed but clears error when valid credentials given after invalid
    yield put(
      setValidatedDirectDepositAccountInfo({
        valid: true,
        code: null,
        message: null,
      }),
    );

    yield put(openConfirmationModal(requiredCredentials)); // Pass values to show in confirmation modal

    const { type: userActionType } = yield take([
      CREATE_DIRECT_DEPOSIT_CONFIRMED,
      CREATE_DIRECT_DEPOSIT_CANCELLED,
    ]);

    if (userActionType === CREATE_DIRECT_DEPOSIT_CANCELLED) {
      return yield put({
        type: REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.CANCELLED,
      });
    }

    // NOTE: Eventually will handle Interac for US and CA members
    if (accountType === STRIPE_EU) {
      yield setStripeProfile({
        accountNumber,
        paymentCredentialsId,
        payeeDefault,
      });
    } else if (includes([EFT_CA, EFT_US], accountType)) {
      yield setEftProfile({
        name,
        accountNumber,
        routingNumber,
        userId,
        paymentCredentialsId,
        payeeDefault,
      });
    } else if (accountType === INTERAC) {
      yield setInteracProfile({
        email,
        userId,
        paymentCredentialsId,
        payeeDefault,
      });
    }

    return yield put({
      type: REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.SUCCEEDED,
      payload: {
        isEditing, // Determines either adding or editing success toast message
      },
    });
  } catch (error) {
    // Passes payload only to for analytics
    yield put({
      type: REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.ERRORED,
      payload: { isEditing, accountType },
    });
    // Catch any non-specific errors and show them as toasts messages. Mostly errors come from the calls to "set {accountType} profile" if user profile is missing info.
    // Another error comes from setInteracProfile if invalid email address. This is because we don't have a validator for Interac emails.
    const { code, reason } = error.info;
    const textId = isEditing
      ? 'AN_ISSUE_EDITING_YOUR_ACCOUNT'
      : 'AN_ISSUE_ADDING_YOUR_ACCOUNT';

    return yield handleCreateDirectDepositAccountErrorMessages({
      messageType: TOAST_MESSAGE,
      ...(code // Handles all Backend errors with a toast message if code is given
        ? {
            code,
            message: reason,
          }
        : {
            // NOTE: Use generic error message otherwise
            textId,
          }),
    });
  }
}

export function* handleSuccessToastMessage({ payload }) {
  const { isEditing } = payload;
  const textId = isEditing ? 'WE_SAVED_YOUR_CHANGES' : 'SUCCESS_ACCOUNT_ADDED';

  if (textId) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId,
    });
  }
}

function* onCreateDirectDepositAccountSuccess() {
  yield put(push('/member/banking-info'));
}

export function* accountInfoFormSagas() {
  yield fork(takeLatest, GENERATE_INTERAC_CODE, requestGenerateInteracCode);
  yield fork(
    takeLatest,
    CREATE_DIRECT_DEPOSIT_ACCOUNT,
    requestCreateDirectDepositAccount,
  );
  yield fork(
    takeLatest,
    REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.SUCCEEDED,
    onCreateDirectDepositAccountSuccess,
  );
  yield fork(
    takeLatest,
    REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.SUCCEEDED,
    handleSuccessToastMessage,
  );
  yield fork(accountInfoFormAnalyticsSaga);
}
