import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  getThemeValue,
  colour,
  FontAwesomeIcon,
  StyledComponentRefWrapper,
} from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const StyledWrapper = styled(Flex)`
  a:last-child > svg {
    display: none;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const Chevron = styled(props => <FontAwesomeIcon {...props} />).attrs({
  icon: 'chevron-right',
})`
  color: ${colour('purple')};
  font-size: ${getThemeValue('fontSizes.2')}px;
  margin: 0 0.5rem;
`;

const Link = styled(StyledComponentRefWrapper(ReactRouterLink))`
  font-size: ${getThemeValue('fontSizes.2')}px;
  line-height: 1;
  font-weight: ${getThemeValue('fontWeight.regular')};
  color: inherit;
  text-decoration: none;
`;

export const BreadcrumbLinks = ({ links }) => (
  <StyledWrapper>
    {links.map(({ to, children, ...rest }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      <Link to={to} key={to} {...rest}>
        {children}
        <Chevron />
      </Link>
    ))}
  </StyledWrapper>
);

export const BreadcrumbLinksPropType = PropTypes.arrayOf(
  PropTypes.shape({
    children: PropTypes.node,
    to: PropTypes.string,
  }),
);

BreadcrumbLinks.propTypes = {
  links: BreadcrumbLinksPropType,
};

BreadcrumbLinks.defaultProps = {
  links: [],
};
