/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { request } from 'common/websocket-redux';
import {
  adminSetDependents,
  adminRemoveDependent,
  getDependentQLEReasonOptions,
  getDependentRelationshipTypes,
} from 'common/services';
import {
  GET_DEPENDENT_ADD_QLE_REASONS,
  GET_DEPENDENT_RELATIONSHIP_TYPES,
  SET_DEPENDENT,
  GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
  TERMINATE_DEPENDENT,
} from './employee-dependents.action-types';
import {
  selectDependents,
  selectEmployeeDependentsPath,
  selectGroupId,
} from './employee-dependents.selectors';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* terminateDependent({
  payload: {
    dependentId,
    terminationReason,
    groupId,
    terminationDate,
    employeeId,
  },
}) {
  yield request(
    TERMINATE_DEPENDENT,
    adminRemoveDependent({
      user_id: employeeId,
      dependent_id: dependentId,
      group_id: groupId,
      qualifying_life_event_reason: terminationReason,
      benefits_termination_date: terminationDate,
    }),
  );
}

function* showTerminateDependentSuccessToast() {
  yield navigateToDependentsListAndRefresh();
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'DEPENDENT_REMOVED',
  });
}

function* showTerminateDependentErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'DEPENDENT_REMOVED_ERROR',
  });
}

export function* getDependentTerminationTypeOptions() {
  yield request(
    GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
    getDependentQLEReasonOptions({ event_type: 'remove' }),
  );
}

const updateDependentsList = (dependentList, dependentToUpdate) => {
  const list = dependentList.map(dependent =>
    dependent.dependent_id === dependentToUpdate.dependent_id
      ? dependentToUpdate
      : dependent,
  );

  if (!dependentToUpdate.dependent_id) {
    list.push(dependentToUpdate);
  }

  return list;
};

function* navigateToDependentsListAndRefresh() {
  const redirectPath = yield select(selectEmployeeDependentsPath);

  // Redirect
  yield put(push(redirectPath));
}

export function* saveDependentRequest({
  payload: { dependent, groupId, userId },
}) {
  const dependentList = yield select(selectDependents);
  const dependents = updateDependentsList(dependentList, dependent);
  yield request(
    SET_DEPENDENT,
    adminSetDependents({ dependents, group_id: groupId, user_id: userId }),
  );
}

function* saveDependentSucceeded() {
  yield navigateToDependentsListAndRefresh();

  // toast 🍻
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'DEPENDENT_UPDATED',
  });
}

function* saveDependentErrored({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: payload.info.reason,
    shouldAutoClose: false,
  });
}

export function* getDependentAddQLEReasons() {
  yield request(
    GET_DEPENDENT_ADD_QLE_REASONS,
    getDependentQLEReasonOptions({ event_type: 'add' }),
  );
}

export function* getDependentRelationshipTypeOptions() {
  const groupId = yield select(selectGroupId);
  yield request(
    GET_DEPENDENT_RELATIONSHIP_TYPES,
    getDependentRelationshipTypes({ group_id: groupId }),
  );
}

export function* dependentSaga() {
  yield takeLatest(
    GET_DEPENDENT_TERMINATION_TYPE_OPTIONS.BASE,
    getDependentTerminationTypeOptions,
  );
  yield takeLatest(
    GET_DEPENDENT_RELATIONSHIP_TYPES.BASE,
    getDependentRelationshipTypeOptions,
  );
  yield takeLatest(
    GET_DEPENDENT_ADD_QLE_REASONS.BASE,
    getDependentAddQLEReasons,
  );
  yield takeLatest(TERMINATE_DEPENDENT.BASE, terminateDependent);
  yield takeLatest(
    TERMINATE_DEPENDENT.SUCCEEDED,
    showTerminateDependentSuccessToast,
  );
  yield takeLatest(
    TERMINATE_DEPENDENT.ERRORED,
    showTerminateDependentErrorToast,
  );
  yield takeLatest(SET_DEPENDENT.BASE, saveDependentRequest);
  yield takeLatest(SET_DEPENDENT.SUCCEEDED, saveDependentSucceeded);
  yield takeLatest(SET_DEPENDENT.ERRORED, saveDependentErrored);
}
