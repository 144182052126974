import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colour, Box } from '@leagueplatform/ui-kit';
import { defaultFlexDollarTermId } from 'common/constants';
import { CoverageSummaryStep } from './coverage-summary-list-step.view';
import { COVERAGE_SUMMARY_SLUG } from 'apps/enrollment/paths';

const Divider = styled(Box)`
  border-bottom: 1px solid ${colour('greyLighter')};
  border-image: linear-gradient(
      to left,
      transparent,
      ${colour('greyLighter')},
      transparent
    )
    1;
`;

export const CoverageSummaryList = ({
  summarySteps,
  currentStepName,
  isCollapsible,
  showCostsPerPayPeriod,
  flexDollarTermId,
  activeStepFromUrl,
}) => (
  <Box mb={40}>
    <ul>
      {summarySteps &&
        summarySteps.map((step, idx) => {
          /* expand step only when:
            - isCollapsible: true
            - it's the same benefit as current page
            - the page is coverage summary
          */
          const isStepExpanded = isCollapsible
            ? currentStepName === step.name || // @TODO remove after GE. Remove currentStepName === step.name only.
              activeStepFromUrl === step.url ||
              currentStepName === COVERAGE_SUMMARY_SLUG
            : true;

          return (
            <li key={step.name}>
              <CoverageSummaryStep
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...step}
                isCollapsible={isCollapsible}
                isStepExpanded={isStepExpanded}
                showCostsPerPayPeriod={showCostsPerPayPeriod}
                flexDollarTermId={flexDollarTermId}
              />
              {idx < summarySteps.length - 1 && <Divider mb={24} />}
            </li>
          );
        })}
    </ul>
  </Box>
);

CoverageSummaryList.propTypes = {
  summarySteps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentStepName: PropTypes.string.isRequired,
  isCollapsible: PropTypes.bool,
  showCostsPerPayPeriod: PropTypes.bool,
  flexDollarTermId: PropTypes.string,
  activeStepFromUrl: PropTypes.string.isRequired,
};

CoverageSummaryList.defaultProps = {
  isCollapsible: false,
  showCostsPerPayPeriod: false,
  flexDollarTermId: defaultFlexDollarTermId,
};
