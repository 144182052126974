export default {
  amount: {
    input_type: 'text',
    position: 1,
    hidden: false,
    placeholder: '',
    tooltip: '',
    label: 'Amount',
    read_only: false,
    masked: false,
    field_name: 'amount',
    json_type: 'number',
    go_type: 'float64',
    validation_rules: [{ args: null, hint: 'is required', rule: 'required' }],
  },
  billing_division_id: {
    input_type: 'select',
    position: 2,
    hidden: false,
    placeholder: '',
    tooltip: '',
    label: 'Billing Division',
    read_only: false,
    masked: false,
    field_name: 'billing_division_id',
    json_type: 'string',
    go_type: 'string',
  },
  note: {
    input_type: 'text',
    position: 3,
    hidden: false,
    placeholder: '',
    tooltip: '',
    label: 'Note',
    read_only: false,
    masked: false,
    field_name: 'note',
    json_type: 'string',
    go_type: 'string',
    validation_rules: [
      {
        rule: 'max',
        hint: 'must be at most 200',
        args: {
          size: 200,
        },
      },
      {
        args: null,
        hint: 'is required',
        rule: 'required',
      },
    ],
  },
};
