import {
  REQUEST_PREMIUMS_REPORT,
  REQUEST_PREVIOUS_PREMIUMS_REPORT,
  REQUEST_CHANGE_REPORT,
  GET_PAYROLL_SCHEDULES,
  GET_PREMIUMS_EXPORT_SPLITS,
  SET_PAYROLL_SCHEDULES,
  SHOW_IMPORT_MODAL,
  SHOW_RENAME_MODAL,
  IMPORT_PAYROLL_SCHEDULE,
  EXPORT_PAYROLL_SCHEDULE,
  SHOW_EXPORT_MODAL,
} from './premiums-report.types';

export const getPremiumsReport = payload => ({
  type: REQUEST_PREMIUMS_REPORT,
  payload,
});

export const getPreviousPremiumsReports = payload => {
  return {
    type: REQUEST_PREVIOUS_PREMIUMS_REPORT,
    payload,
  };
};

export const getChangeReport = payload => {
  return {
    type: REQUEST_CHANGE_REPORT,
    payload,
  };
};

export const requestPayrollSchedules = payload => {
  return {
    type: GET_PAYROLL_SCHEDULES.BASE,
    payload,
  };
};

export const requestPremiumsExportSplits = payload => {
  return {
    type: GET_PREMIUMS_EXPORT_SPLITS.BASE,
    payload,
  };
};

export const setPayrollSchedules = payload => ({
  type: SET_PAYROLL_SCHEDULES.BASE,
  payload,
});

export const setShowImportModal = payload => ({
  type: SHOW_IMPORT_MODAL,
  payload,
});

export const setShowRenameModal = payload => ({
  type: SHOW_RENAME_MODAL,
  payload,
});

export const importPayrollSchedule = payload => ({
  type: IMPORT_PAYROLL_SCHEDULE.BASE,
  payload,
});

export const exportPayrollSchedule = payload => ({
  type: EXPORT_PAYROLL_SCHEDULE.BASE,
  payload,
});
export const setShowExportModal = payload => ({
  type: SHOW_EXPORT_MODAL,
  payload,
});
