import { createRequestTypes } from 'common/websocket-redux';

export const REQUEST_RETRIGGER_PAYMENT =
  'script-helpers/REQUEST_RETRIGGER_PAYMENT';

export const RETRIGGER_PAYMENT = createRequestTypes(
  'script-helpers/RETRIGGER_PAYMENT',
);

export const retriggerPayment = payload => ({
  type: REQUEST_RETRIGGER_PAYMENT,
  payload,
});
