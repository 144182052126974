export default {
  employeeRBCTravel: {
    name: 'employeeRBCTravel',
    groups: [
      {
        title: 'Member Policy Details',
        fields: [
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Employer Policy Details',
        fields: [
          {
            name: 'parent.benefit_start_date',
            span: 6,
          },
          {
            name: 'parent.benefit_end_date',
            span: 6,
          },
          {
            name: 'parent.policy_id',
            span: 6,
          },
          {
            name: 'parent.documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'parent.prevent_default_allocation',
            span: 4,
          },
          {
            name: 'parent.opt_out',
            span: 4,
          },
          {
            name: 'parent.benefit_waiting_period_months',
            span: 4,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'parent.benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'parent.auto_renew',
            span: 6,
          },
        ],
      },
      {
        title: 'Description of Coverage',
        fields: [
          {
            name: 'parent.trip_duration',
            span: 6,
          },
          {
            name: 'parent.cancellation_or_interruption_included',
            span: 6,
          },
          {
            name: 'parent.max_benefit_retired_class',
            span: 6,
          },
          {
            name: 'parent.max_basis_retired_class',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'id',
            span: 4,
          },
          {
            name: 'user_id',
            span: 4,
          },
          {
            name: 'grace_period_start_date',
            span: 4,
          },
          {
            name: 'inactive_date',
            span: 4,
          },
          {
            name: 'reinstated_date',
            span: 4,
          },
          {
            name: 'parent.provider_id',
            span: 4,
          },
          {
            name: 'parent.provider_type',
            span: 4,
          },
          {
            name: 'parent.vendor',
            span: 4,
          },
          {
            name: 'parent.benefit_type',
            span: 4,
          },
          {
            name: 'parent.benefit_product_type',
            span: 4,
          },
          {
            name: 'parent.id',
            span: 4,
          },
          {
            name: 'parent.benefit_status',
            span: 4,
          },
          {
            name: 'parent.plan_id',
            span: 4,
          },
          {
            name: 'parent.benefit_vendor_id',
            span: 4,
          },
          {
            name: 'parent.renewed_from',
            span: 4,
          },
          {
            name: 'parent.dependent_classes',
            span: 12,
          },
        ],
      },
    ],
  },
};
