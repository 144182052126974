import React from 'react';
import PropTypes from 'prop-types';
import { TextEditor } from '../../BenefitWallet/TextEditor';
import { Box } from '@leagueplatform/ui-kit';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import styled from 'styled-components';
import { Button } from 'apps/employer-experience/ui-kit';
import { UpdateContentModal } from '../../BenefitWallet/update-content-modal.view';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@leagueplatform/locales';

const LoadingIndicatorContainer = styled(Box)`
  position: relative;
`;

const StyledButton = styled(Button)`
  height: 3rem;
  margin-top: 1rem;
`;

const EditorContainer = styled(Box)`
  display: ${({ loading, benefitId }) =>
    !loading && benefitId ? 'block' : 'none'};
`;

const JsonEditMode = ({
  code,
  setCode,
  benefitId,
  loading,
  onUpdateBenefitPriceConfiguration,
  isSaveModalOpen,
  toggleSaveModal,
}) => {
  return loading ? (
    <LoadingIndicatorContainer>
      <LoadingIndicator />
    </LoadingIndicatorContainer>
  ) : (
    <EditorContainer benefitId={benefitId} loading={loading}>
      <TextEditor code={code} setCode={setCode} />
      <StyledButton primary onClick={toggleSaveModal}>
        <FormattedMessage id="SAVE" />
      </StyledButton>
      <UpdateContentModal
        isOpen={isSaveModalOpen}
        toggleModal={toggleSaveModal}
        modalHeader="PRICING_CONFIG_SAVE_HEADER"
        onUpdateContent={onUpdateBenefitPriceConfiguration}
        confirmButtonId="SAVE"
      >
        <FormattedHTMLMessage
          id="PRICING_CONFIG_SAVE_CONTENT"
          values={{ benefitId }}
        />
      </UpdateContentModal>
    </EditorContainer>
  );
};

export default JsonEditMode;
JsonEditMode.propTypes = {
  code: PropTypes.string,
  setCode: PropTypes.func.isRequired,
  benefitId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onUpdateBenefitPriceConfiguration: PropTypes.func.isRequired,
  toggleSaveModal: PropTypes.func.isRequired,
  isSaveModalOpen: PropTypes.bool.isRequired,
};

JsonEditMode.defaultProps = {
  benefitId: null,
  code: '{}',
};
