/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import camelize from 'camelize';
import { normalize } from 'normalizr';
import { cloneDeep, uniq, omit } from 'lodash';
import {
  benefitPlan as benefitPlanSchema,
  benefit as benefitSchema,
  catalogue as catalogueSchema,
} from '../../schema';
import { GET_EMPLOYER_BENEFITS_BILLING } from '../../Billing/types';
import {
  ADD_BENEFIT_PLAN,
  SET_BENEFIT_PLAN,
  RENAME_BENEFIT_PLAN,
  REMOVE_BENEFIT_PLAN,
  REMOVE_BENEFIT_PLAN_SERVICE,
  FETCH_CATALOGUE,
  GET_LEAGUE_BENEFITS,
  GET_EMPLOYER_BENEFITS,
  FETCH_BENEFIT_TYPE_SCHEMAS,
  GET_BENEFIT_TYPE_SCHEMAS,
} from './Plans/types';
import {
  SET_BENEFIT_CLASS,
  ADD_BENEFIT_CLASS,
  RENAME_BENEFIT_CLASS,
  REMOVE_BENEFIT_CLASS_SERVICE,
  REMOVE_BENEFIT_CLASS,
  GET_EMPLOYER_BENEFIT_PLAN,
  GET_EMPLOYER_BENEFIT_PLANS,
} from '../types';
import {
  REMOVE_BENEFIT,
  ADD_BENEFIT,
  UPDATE_BENEFIT,
  RENEW_BENEFIT,
  REMOVE_EMPLOYER_BENEFIT,
  SET_EMPLOYER_BENEFITS,
  RENEW_EMPLOYER_BENEFIT,
  UPDATE_GROUP_CATEGORIES,
} from './Plans/BenefitPlan/Benefits/types';
import {
  ADD_EMPLOYER_BENEFIT_DOCUMENT,
  UPDATE_EMPLOYER_BENEFIT_DOCUMENT,
  SET_EMPLOYER_BENEFIT_DOCUMENT,
} from './Plans/BenefitPlan/Benefits/BenefitTypes/Documents/types';

export const addBenefitToStructure = (
  newState,
  { benefitId, benefitPlanId, benefitClassId },
) => {
  const classStructure =
    newState.employerBenefitBenefitsStructure?.[benefitClassId];

  if (!classStructure) {
    return;
  }

  const planStructure = classStructure[benefitPlanId];

  if (!planStructure) {
    return;
  }

  const index = planStructure.indexOf(benefitId);
  if (index === -1) {
    planStructure.push(benefitId);
  }
};

export const addBenefitPlanToStructure = (
  newState,
  { benefitPlanId, benefitClassId },
) => {
  const classStructure =
    newState.employerBenefitBenefitsStructure[benefitClassId];

  if (!classStructure) {
    return;
  }

  if (!classStructure[benefitPlanId]) {
    classStructure[benefitPlanId] = [];
  }
};

export const addOrUpdateBenefitClass = (state, newOrUpdatedBenefitClass) => {
  const newState = cloneDeep(state);
  const { entities, result } = newState.employerBenefitClasses;

  if (!entities.benefitClasses[newOrUpdatedBenefitClass.id]) {
    result.push(newOrUpdatedBenefitClass.id);
  }

  entities.benefitClasses[newOrUpdatedBenefitClass.id] =
    newOrUpdatedBenefitClass;

  if (!newState.employerBenefitBenefitsStructure[newOrUpdatedBenefitClass.id]) {
    newState.employerBenefitBenefitsStructure[newOrUpdatedBenefitClass.id] = {};
  }

  return newState;
};

export const addOrUpdateBenefitPlan = (state, newOrUpdatedBenefitPlan) => {
  const newState = cloneDeep(state);
  const { entities, result } = newState.employerBenefitPlans;

  if (!entities.benefitPlans[newOrUpdatedBenefitPlan.id]) {
    result.push(newOrUpdatedBenefitPlan.id);
  }

  entities.benefitPlans[newOrUpdatedBenefitPlan.id] = newOrUpdatedBenefitPlan;

  addBenefitPlanToStructure(newState, {
    benefitPlanId: newOrUpdatedBenefitPlan.id,
    benefitClassId: newOrUpdatedBenefitPlan.benefitClassId,
  });

  return newState;
};

export const addOrUpdateBenefit = (state, ids, newOrUpdatedBenefit) => {
  const newState = cloneDeep(state);
  const { entities, result } = newState.employerBenefits;

  if (!entities.benefits[ids.id]) {
    result.push(ids.id);
  }

  entities.benefits[ids.id] = newOrUpdatedBenefit;

  addBenefitToStructure(newState, {
    benefitId: ids.id,
    benefitPlanId: ids.benefitPlanId,
    benefitClassId: ids.benefitClassId,
  });

  return newState;
};

export const removeBenefitClass = (state, removedBenefitClassId) => {
  const newState = cloneDeep(state);
  const { entities, result } = newState.employerBenefitClasses;

  if (entities.benefitClasses[removedBenefitClassId]) {
    delete entities.benefitClasses[removedBenefitClassId];
    const index = result.indexOf(removedBenefitClassId);
    if (index > -1) {
      result.splice(index, 1);
    }
  }

  if (newState.employerBenefitBenefitsStructure[removedBenefitClassId]) {
    delete newState.employerBenefitBenefitsStructure[removedBenefitClassId];
  }

  return newState;
};

export const removeBenefitPlan = (state, removedBenefitPlanId) => {
  const newState = cloneDeep(state);
  const { entities, result } = newState.employerBenefitPlans;

  if (entities.benefitPlans[removedBenefitPlanId]) {
    const { benefitClassId } = entities.benefitPlans[removedBenefitPlanId];
    if (newState.employerBenefitBenefitsStructure[benefitClassId]) {
      delete newState.employerBenefitBenefitsStructure[benefitClassId][
        removedBenefitPlanId
      ];
    }
    delete entities.benefitPlans[removedBenefitPlanId];
    const index = result.indexOf(removedBenefitPlanId);
    if (index > -1) {
      result.splice(index, 1);
    }
  }

  return newState;
};

export const removeBenefit = (state, removedBenefit) => {
  const newState = cloneDeep(state);
  const { result } = newState.employerBenefits;
  const { entities } = newState.employerBenefits;
  const { benefitId, benefitClassId, benefitPlanId } = removedBenefit;

  if (entities.benefits[benefitId]) {
    if (
      newState.employerBenefitBenefitsStructure[benefitClassId] &&
      newState.employerBenefitBenefitsStructure[benefitClassId][benefitPlanId]
    ) {
      const benefitIdsInPlan =
        newState.employerBenefitBenefitsStructure[benefitClassId][
          benefitPlanId
        ];
      const indexOfBenefitIdInPlan = benefitIdsInPlan.indexOf(benefitId);
      if (indexOfBenefitIdInPlan > -1) {
        newState.employerBenefitBenefitsStructure[benefitClassId][
          benefitPlanId
        ].splice(indexOfBenefitIdInPlan, 1);
      }
    }
    delete entities.benefits[benefitId];
    const index = result.indexOf(benefitId);
    if (index > -1) {
      result.splice(index, 1);
    }
  }

  return newState;
};

const applyBenefitClassSet = (state, { payload }) => {
  const benefitClass = camelize(payload.info);
  const newState = addOrUpdateBenefitClass(state, benefitClass);

  delete newState.employerBenefitClasses.errorAddingBenefitClass;
  delete newState.employerBenefitClasses.addingBenefitClass;
  delete newState.employerBenefitClasses.errorRenamingBenefitClass;
  delete newState.employerBenefitClasses.renamingBenefitClass;

  return newState;
};

const applyAddBenefitClassErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitClasses.errorAddingBenefitClass = true;
  delete newState.employerBenefitClasses.addingBenefitClass;

  return newState;
};

const applyRenameBenefitClassErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitClasses.errorRenamingBenefitClass = true;
  delete newState.employerBenefitClasses.renamingBenefitClass;
  return newState;
};

const applyAddOrRenameBenefitClassErrored = state => {
  if (state.employerBenefitClasses.addingBenefitClass) {
    return applyAddBenefitClassErrored(state);
  }
  if (state.employerBenefitClasses.renamingBenefitClass) {
    return applyRenameBenefitClassErrored(state);
  }
  return state;
};

const applyAddBenefitClassStarted = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitClasses.addingBenefitClass = true;
  return newState;
};

const applyRenameBenefitClassStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefitClasses.renamingBenefitClass = payload.id;
  return newState;
};

const applyRemoveBenefitClassStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefitClasses.removingBenefitClass = payload.benefitClassId;
  return newState;
};

const applyRemoveBenefitClassErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitClasses.errorRemovingBenefitClass = true;
  delete newState.employerBenefitClasses.removingBenefitClass;
  return newState;
};

const applySuccessRemovingBenefitClass = state => {
  const newState = removeBenefitClass(
    state,
    state.employerBenefitClasses.removingBenefitClass,
  );

  delete newState.employerBenefitClasses.removingBenefitClass;
  delete newState.employerBenefitClasses.errorRemovingBenefitClass;
  return newState;
};

const applySuccessFetchingBenefitPlan = (state, { payload }) => {
  const benefitPlan = camelize(payload.info);
  const newState = addOrUpdateBenefitPlan(state, benefitPlan);
  return newState;
};

const applyFetchPlansStarted = (state, { meta }) => {
  const newState = cloneDeep(state);
  if (meta) {
    newState.employerBenefitPlans.fetchingBenefitPlans = meta.benefitClassId;
  }
  return newState;
};

const applySuccessFetchingBenefitPlans = (state, { payload }) => {
  const newState = cloneDeep(state);

  if (payload.info.plans) {
    const plans = normalize(camelize(payload.info.plans), [benefitPlanSchema]);

    const plansEntities = plans.entities.benefitPlans;
    const plansResults = plans.result;

    newState.employerBenefitPlans.entities.benefitPlans = {
      ...newState.employerBenefitPlans.entities.benefitPlans,
      ...plansEntities,
    };

    newState.employerBenefitPlans.result = uniq([
      ...newState.employerBenefitPlans.result,
      ...plansResults,
    ]);

    plansResults.forEach(benefitPlanId => {
      addBenefitPlanToStructure(newState, {
        benefitPlanId,
        benefitClassId: newState.employerBenefitPlans.fetchingBenefitPlans,
      });
    });
  }

  delete newState.employerBenefitPlans.fetchingBenefitPlans;
  delete newState.employerBenefitPlans.errorFetchingBenefitPlans;
  return newState;
};

const applyErrorFetchingPlans = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.errorFetchingBenefitPlans = true;
  delete newState.employerBenefitPlans.fetchingBenefitPlans;
  return newState;
};

const applyAddBenefitPlanStarted = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.addingBenefitPlan = true;
  return newState;
};

const applyAddBenefitPlanErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.errorAddingBenefitPlan = true;
  delete newState.employerBenefitPlans.addingBenefitPlan;

  return newState;
};

const applyRenameBenefitPlanErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.errorRenamingBenefitPlan = true;
  delete newState.employerBenefitPlans.renamingBenefitPlan;
  return newState;
};

const applyAddOrEditEmployerBenefitErrored = state => {
  if (state.employerBenefits.addingBenefit) {
    return applyAddBenefitErrored(state);
  }
  if (state.employerBenefits.editingBenefit) {
    return applyEditBenefitErrored(state);
  }
  return state;
};

const applyBenefitSet = (state, { payload }) => {
  const benefit =
    payload.info.benefits && payload.info.benefits.length
      ? camelize(payload.info.benefits[0])
      : undefined;

  if (!benefit) {
    return state;
  }

  const ids = {
    ...state.employerBenefits.addingBenefit,
    id: benefit.id,
  };

  const newState = addOrUpdateBenefit(state, ids, benefit);
  newState.employerBenefits.successCreatingEditingBenefit = true;
  delete newState.employerBenefits.errorAddingBenefit;
  delete newState.employerBenefits.addingBenefit;
  delete newState.employerBenefits.errorEditingBenefit;
  delete newState.employerBenefits.editingBenefit;

  return newState;
};

const applyRemoveBenefitStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefits.removingBenefit = {
    benefitId: payload.benefitId,
    benefitPlanId: payload.benefitPlanId,
    benefitClassId: payload.benefitClassId,
  };
  return newState;
};

const applyRemoveBenefitErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefits.errorRemovingBenefit = true;
  delete newState.employerBenefits.removingBenefit;
  return newState;
};

const applySuccessRemovingBenefit = state => {
  const newState = removeBenefit(state, state.employerBenefits.removingBenefit);

  delete newState.employerBenefits.removingBenefit;
  delete newState.employerBenefits.errorRemovingBenefit;
  return newState;
};

const applyRenewBenefitStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefits.renewingBenefit = {
    benefitIds: payload.benefitIds,
  };
  return newState;
};

const applyRenewBenefitErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefits.errorRenewingBenefit = true;
  delete newState.employerBenefits.renewingBenefit;
  return newState;
};

const applySuccessRenewingBenefit = state => {
  const newState = cloneDeep(state);
  delete newState.employerBenefits.renewingBenefit;
  delete newState.employerBenefits.errorRenewingBenefit;
  return newState;
};

const applyStartingUpdatingGroupCategories = state => {
  const newState = cloneDeep(state);
  newState.processingCategoriesUpload = true;
  return newState;
};

const applySuccessUpdatingGroupCategories = state => {
  const newState = cloneDeep(state);
  newState.processingCategoriesUpload = false;
  return newState;
};

const applyFetchBenefitTypeSchemasStarted = state => {
  const newState = cloneDeep(state);
  newState.benefitTypeSchemas.fetchingBenefitTypeSchemas = true;
  return newState;
};

const applyAddOrRenameBenefitPlanErrored = state => {
  if (state.employerBenefitPlans.addingBenefitPlan) {
    return applyAddBenefitPlanErrored(state);
  }
  if (state.employerBenefitPlans.renamingBenefitPlan) {
    return applyRenameBenefitPlanErrored(state);
  }
  return state;
};

const applyBenefitPlanSet = (state, { payload }) => {
  const benefitPlan = camelize(payload.info.plan);
  const success = { addedSuccessfully: true };
  const newState = addOrUpdateBenefitPlan(state, benefitPlan, success);

  delete newState.employerBenefitPlans.errorAddingBenefitPlan;
  delete newState.employerBenefitPlans.addingBenefitPlan;
  delete newState.employerBenefitPlans.errorRenamingBenefitPlan;
  delete newState.employerBenefitPlans.renamingBenefitPlan;

  return newState;
};

const applyRenameBenefitPlanStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.renamingBenefitPlan = payload.id;
  return newState;
};

const applyRemoveBenefitPlanStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.removingBenefitPlan = payload.benefitPlanId;
  return newState;
};

const applyRemoveBenefitPlanErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitPlans.errorRemovingBenefitPlan = true;
  delete newState.employerBenefitPlans.removingBenefitPlan;
  return newState;
};

const applySuccessRemovingBenefitPlan = state => {
  const newState = removeBenefitPlan(
    state,
    state.employerBenefitPlans.removingBenefitPlan,
  );

  delete newState.employerBenefitPlans.removingBenefitPlan;
  delete newState.employerBenefitPlans.errorRemovingBenefitPlan;
  return newState;
};

const applyFetchCatalogueStarted = state => {
  const newState = cloneDeep(state);
  newState.catalogue.fetchingCatalogue = true;
  return newState;
};

const applySuccessFetchingCatalogue = (state, { payload }) => {
  const newState = cloneDeep(state);

  if (payload.info.benefits) {
    const benefits = normalize(camelize(payload.info.benefits || [{}]), [
      catalogueSchema,
    ]);

    newState.catalogue = {
      ...newState.catalogue,
      ...benefits,
    };
  }

  delete newState.catalogue.fetchingCatalogue;
  delete newState.catalogue.errorFetchingCatalogue;
  return newState;
};

const applyErrorFetchingCatalogue = state => {
  const newState = cloneDeep(state);
  newState.catalogue.errorFetchingCatalogue = true;
  delete newState.catalogue.fetchingCatalogue;
  return newState;
};

const applyFetchBenefitsStarted = (state, { meta }) => {
  const newState = cloneDeep(state);
  if (meta) {
    newState.employerBenefits.fetchingBenefits = {
      benefitPlanId: meta.benefitPlanId,
      benefitClassId: meta.benefitClassId,
    };
  }
  return newState;
};

const applySuccessFetchingBenefits = (state, { payload }) => {
  const newState = cloneDeep(state);

  if (payload.info.benefits) {
    const benefits = normalize(camelize(payload.info.benefits), [
      benefitSchema,
    ]);
    const benefitsFormData = normalize(payload.info.benefits, [benefitSchema]);
    const benefitsEntities = benefits.entities.benefits;
    const benefitsResults = benefits.result;

    newState.employerBenefits.entities.benefits = {
      ...newState.employerBenefits.entities.benefits,
      ...benefitsEntities,
    };

    // TODO:benefit-case-migration-EMP-1087
    newState.employerBenefits.entities.formData =
      benefitsFormData.entities.benefits;

    newState.employerBenefits.result = uniq([
      ...newState.employerBenefits.result,
      ...benefitsResults,
    ]);

    if (newState.employerBenefits.fetchingBenefits) {
      benefitsResults.forEach(benefitId => {
        addBenefitToStructure(newState, {
          benefitId,
          benefitClassId:
            newState.employerBenefits.fetchingBenefits.benefitClassId,
          benefitPlanId:
            newState.employerBenefits.fetchingBenefits.benefitPlanId,
        });
      });
    }
  }

  delete newState.employerBenefits.fetchingBenefits;
  delete newState.employerBenefits.errorFetchingBenefits;
  return newState;
};

const applyErrorFetchingBenefits = state => {
  const newState = cloneDeep(state);
  newState.employerBenefits.errorFetchingBenefits = true;
  delete newState.employerBenefits.fetchingBenefits;
  return newState;
};

const applyAddBenefitStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    employerBenefits: {
      ...omit(newState.employerBenefits, ['successCreatingEditingBenefit']),
      addingBenefit: {
        benefitPlanId: payload.benefitPlanId,
        benefitClassId: payload.benefitClassId,
      },
    },
  };
};

const applyUpdateBenefitStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    employerBenefits: {
      ...omit(newState.employerBenefits, ['successCreatingEditingBenefit']),
      addingBenefit: {
        benefitPlanId: payload.benefitPlanId,
        benefitClassId: payload.benefitClassId,
      },
    },
  };
};

const applyAddBenefitErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefits.errorAddingBenefit = true;
  delete newState.employerBenefits.addingBenefit;

  return newState;
};

const applyEditBenefitErrored = state => {
  const newState = cloneDeep(state);
  newState.employerBenefits.errorEditingBenefit = true;
  delete newState.employerBenefits.editingBenefit;
  return newState;
};

const applySuccessFetchingBenefitTypeSchemas = (state, { payload }) => {
  const newState = cloneDeep(state);
  const benefitTypeSchemas = payload.info.benefit_type_schemas || {};

  newState.benefitTypeSchemas = {
    ...newState.benefitTypeSchemas.entities,
    ...benefitTypeSchemas,
  };

  delete newState.benefitTypeSchemas.fetchingBenefitTypeSchemas;
  delete newState.benefitTypeSchemas.errorFetchingBenefitTypeSchemas;
  return newState;
};

const applyErrorFetchingBenefitTypeSchemas = state => {
  const newState = cloneDeep(state);
  newState.benefitTypeSchemas.errorFetchingBenefitTypeSchemas = true;
  delete newState.benefitTypeSchemas.fetchingBenefitTypeSchemas;
  return newState;
};

const applyAddEmployerBenefitDocumentStarted = (state, { payload }) => {
  const newState = cloneDeep(state);
  newState.employerBenefitDocuments.addingEmployerBenefitDocument = {
    contentId: payload.contentId,
    benefitId: payload.benefitId,
  };
  return newState;
};

const applySuccessAddingEmployerBenefitDocument = (state, { payload }) => {
  const addedDocument =
    payload.info.documents && payload.info.documents.length
      ? camelize(payload.info.documents[0])
      : undefined;
  addedDocument.benefitId =
    state.employerBenefitDocuments.addingEmployerBenefitDocument.benefitId;

  if (!addedDocument) {
    return state;
  }

  const newState = cloneDeep(state);
  const { entities, result } = newState.employerBenefitDocuments;

  if (!entities.benefitDocuments[addedDocument.id]) {
    result.push(addedDocument.id);
  }

  entities.benefitDocuments[addedDocument.id] = addedDocument;

  delete newState.employerBenefitDocuments.addingEmployerBenefitDocument;
  delete newState.employerBenefitDocuments.errorAddingEmployerBenefitDocument;
  return newState;
};

const applyErrorAddingEmployerBenefitDocument = state => {
  const newState = cloneDeep(state);
  newState.employerBenefitDocuments.errorAddingEmployerBenefitDocument = true;
  delete newState.employerBenefitDocuments.addingEmployerBenefitDocument;
  return newState;
};

export const benefitClassReducer = (state, action) => {
  if (!action) return state;
  switch (action.type) {
    case SET_BENEFIT_CLASS.SUCCEEDED:
      return applyBenefitClassSet(state, action);
    case SET_BENEFIT_CLASS.ERRORED:
      return applyAddOrRenameBenefitClassErrored(state);
    case ADD_BENEFIT_CLASS:
      return applyAddBenefitClassStarted(state);
    case RENAME_BENEFIT_CLASS:
      return applyRenameBenefitClassStarted(state, action);
    case REMOVE_BENEFIT_CLASS:
      return applyRemoveBenefitClassStarted(state, action);
    case REMOVE_BENEFIT_CLASS_SERVICE.ERRORED:
      return applyRemoveBenefitClassErrored(state);
    case REMOVE_BENEFIT_CLASS_SERVICE.SUCCEEDED:
      return applySuccessRemovingBenefitClass(state);
    case GET_EMPLOYER_BENEFIT_PLAN.SUCCEEDED:
      return applySuccessFetchingBenefitPlan(state, action);
    case GET_EMPLOYER_BENEFIT_PLANS.STARTED:
      return applyFetchPlansStarted(state, action);
    case GET_EMPLOYER_BENEFIT_PLANS.SUCCEEDED:
      return applySuccessFetchingBenefitPlans(state, action);
    case GET_EMPLOYER_BENEFIT_PLANS.ERRORED:
      return applyErrorFetchingPlans(state, action);
    case ADD_BENEFIT_PLAN:
      return applyAddBenefitPlanStarted(state);
    case SET_BENEFIT_PLAN.ERRORED:
      return applyAddOrRenameBenefitPlanErrored(state);
    case SET_BENEFIT_PLAN.SUCCEEDED:
      return applyBenefitPlanSet(state, action);
    case RENAME_BENEFIT_PLAN:
      return applyRenameBenefitPlanStarted(state, action);
    case REMOVE_BENEFIT_PLAN:
      return applyRemoveBenefitPlanStarted(state, action);
    case REMOVE_BENEFIT_PLAN_SERVICE.ERRORED:
      return applyRemoveBenefitPlanErrored(state);
    case REMOVE_BENEFIT_PLAN_SERVICE.SUCCEEDED:
      return applySuccessRemovingBenefitPlan(state);
    case FETCH_CATALOGUE:
      return applyFetchCatalogueStarted(state, action);
    case GET_LEAGUE_BENEFITS.SUCCEEDED:
      return applySuccessFetchingCatalogue(state, action);
    case GET_LEAGUE_BENEFITS.ERRORED:
      return applyErrorFetchingCatalogue(state, action);
    case GET_EMPLOYER_BENEFITS.STARTED:
      return applyFetchBenefitsStarted(state, action);
    case GET_EMPLOYER_BENEFITS_BILLING.SUCCEEDED:
    case GET_EMPLOYER_BENEFITS.SUCCEEDED:
      return applySuccessFetchingBenefits(state, action);
    case GET_EMPLOYER_BENEFITS_BILLING.ERRORED:
    case GET_EMPLOYER_BENEFITS.ERRORED:
      return applyErrorFetchingBenefits(state, action);
    case REMOVE_BENEFIT:
      return applyRemoveBenefitStarted(state, action);
    case REMOVE_EMPLOYER_BENEFIT.ERRORED:
      return applyRemoveBenefitErrored(state);
    case REMOVE_EMPLOYER_BENEFIT.SUCCEEDED:
      return applySuccessRemovingBenefit(state);
    case RENEW_BENEFIT:
      return applyRenewBenefitStarted(state, action);
    case RENEW_EMPLOYER_BENEFIT.ERRORED:
      return applyRenewBenefitErrored(state);
    case RENEW_EMPLOYER_BENEFIT.SUCCEEDED:
      return applySuccessRenewingBenefit(state);
    case UPDATE_GROUP_CATEGORIES.SUCCEEDED:
      return applySuccessUpdatingGroupCategories(state);
    case UPDATE_GROUP_CATEGORIES.BASE:
      return applyStartingUpdatingGroupCategories(state);
    case ADD_BENEFIT:
      return applyAddBenefitStarted(state, action);
    case UPDATE_BENEFIT:
      return applyUpdateBenefitStarted(state, action);
    case SET_EMPLOYER_BENEFITS.ERRORED:
      return applyAddOrEditEmployerBenefitErrored(state);
    case SET_EMPLOYER_BENEFITS.SUCCEEDED:
      return applyBenefitSet(state, action);
    case FETCH_BENEFIT_TYPE_SCHEMAS:
      return applyFetchBenefitTypeSchemasStarted(state, action);
    case GET_BENEFIT_TYPE_SCHEMAS.SUCCEEDED:
      return applySuccessFetchingBenefitTypeSchemas(state, action);
    case GET_BENEFIT_TYPE_SCHEMAS.ERRORED:
      return applyErrorFetchingBenefitTypeSchemas(state, action);
    case ADD_EMPLOYER_BENEFIT_DOCUMENT:
    case UPDATE_EMPLOYER_BENEFIT_DOCUMENT:
      return applyAddEmployerBenefitDocumentStarted(state, action);
    case SET_EMPLOYER_BENEFIT_DOCUMENT.SUCCEEDED:
      return applySuccessAddingEmployerBenefitDocument(state, action);
    case SET_EMPLOYER_BENEFIT_DOCUMENT.ERRORED:
      return applyErrorAddingEmployerBenefitDocument(state, action);
    default:
      return state;
  }
};
