import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { QLEConfigurationForm } from './qle-configuration-form.view';
import {
  submitQLEConfiguration,
  cancelQLEConfiguration,
} from './qle-configuration-form.action-creators';
import {
  setHREmails,
  setQLETypeAndId,
  setBenefitClassId,
} from '../qle-configuration.action-creators';
import { selectGroupId } from '../../../selectors';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { changeEmailsToArray } from './change-emails-to-array';
import { RESET, UNSET } from '../qle-configuration.constants';
import { isEmail } from 'common/adaptive-forms/validation/rules';
import {
  selectSelectedQLEConfiguration,
  selectSelectedQLEConfigurationId,
  selectSelectedQLEType,
  selectSelectedBenefitClass,
  selectQLEApprovalEmails,
} from '../qle-configuration.selectors';
import isEmpty from 'lodash/isEmpty';
import { QLE_DATE } from './coverage-effective-date-policy.constants';

const withReduxProps = connect(
  createStructuredSelector({
    qleConfiguration: selectSelectedQLEConfiguration,
    qleConfigurationId: selectSelectedQLEConfigurationId,
    groupId: selectGroupId,
    benefitClass: selectSelectedBenefitClass,
    qualifyingLifeEventType: selectSelectedQLEType,
    qleApprovalEmails: selectQLEApprovalEmails,
  }),
  {
    submitQLEConfiguration,
    setHREmails,
    setQLETypeAndId,
    setBenefitClassId,
    cancelQLEConfiguration,
  },
);

/*
 daysAfterQleEventDate: preceding, 0 for "No", "-1" for infinity
 daysBeforeQleEventDate: future, 0 for "No", "-1" for infinity
 BOTH daysAfterQleEventDate and daysBeforeQleEventDate can't be 0 however!
*/
const mapPropsToValues = ({ qleConfiguration, qleApprovalEmails }) => {
  let enableTypeform = false;
  let enableManageDependents = false;
  let allowDaysAfterQleEventDate = false;
  let allowDaysBeforeQleEventDate = false;
  let daysBeforeQleEventDate = '';
  let daysAfterQleEventDate = '';

  let suspended = false;
  if (qleConfiguration) {
    const {
      typeformUrl,
      allowNex,
      allowToAddDependents,
      allowToRemoveDependents,
      daysAfterQleEventDate: configDaysAfterQLEEventDate,
      daysBeforeQleEventDate: configDaysBeforeQLEEventDate,
    } = qleConfiguration;
    enableTypeform = typeformUrl.length > 0 && !allowNex;
    enableManageDependents = allowToAddDependents || allowToRemoveDependents;
    allowDaysAfterQleEventDate = configDaysAfterQLEEventDate !== UNSET;
    allowDaysBeforeQleEventDate = configDaysBeforeQLEEventDate !== UNSET;
    daysBeforeQleEventDate = configDaysBeforeQLEEventDate || '';
    daysAfterQleEventDate = configDaysAfterQLEEventDate || '';
    suspended = qleConfiguration.suspended;
  }

  return {
    qualifyingLifeEventType: '',
    needsHrApproval: false,
    allowToAddDependents: false,
    allowToRemoveDependents: false,
    allowNex: true,
    typeformUrl: '',
    createdAt: '',
    // adding key/values to formik for special errors
    daysBeforeOrAfter: false,
    noNexCustomSet: false,
    // Extra piece of info not from configuration
    qleApprovalEmails: isEmpty(qleApprovalEmails)
      ? ''
      : qleApprovalEmails.join(', '),
    coverageEffectiveDatePolicy: QLE_DATE, // Note: this is a string! Gets converted to int when sent to service
    // adding key/values to formik for the toggles
    allowDaysAfterQleEventDate,
    allowDaysBeforeQleEventDate,
    ...(qleConfiguration || {}),
    daysBeforeQleEventDate,
    daysAfterQleEventDate,
    enableTypeform,
    enableManageDependents,
    suspended,
  };
};

export const validate = (values, { intl }) => {
  const {
    daysBeforeQleEventDate,
    daysAfterQleEventDate,
    needsHrApproval,
    qleApprovalEmails,
    allowNex,
    allowToAddDependents,
    allowToRemoveDependents,
    typeformUrl,
    enableTypeform,
  } = values;
  const errors = {};
  const isMissingDateRangeConfiguration =
    daysBeforeQleEventDate === RESET.DAYS_BEFORE_QLE_EVENT_DATE &&
    daysAfterQleEventDate === RESET.DAYS_AFTER_QLE_EVENT_DATE;
  const isMissingHrApproval = needsHrApproval && qleApprovalEmails.length === 0;
  // NOTE: enableManageDependents and enableTypeform are flags to control that sections display only. They are not set to API
  const isMissingAllManageDependentsConfiguration =
    !allowToAddDependents && !allowToRemoveDependents;
  const isMissingAllTypeformConfigurations =
    enableTypeform && !typeformUrl.trim();
  const isMissingConfigurationType =
    !allowNex &&
    (isMissingAllManageDependentsConfiguration ||
      isMissingAllTypeformConfigurations);
  const hasInvalidEmails =
    qleApprovalEmails &&
    changeEmailsToArray(qleApprovalEmails).some(email => !isEmail(null, email));

  if (isMissingDateRangeConfiguration) {
    errors.daysBeforeOrAfter = intl.formatMessage({
      id: 'QLE_CONFIG_FORM_ERROR_REPORTING_WINDOW',
    });
  }

  if (isMissingHrApproval) {
    errors.qleApprovalEmails = intl.formatMessage({
      id: 'QLE_CONFIG_FORM_ERROR_MISSING_HR_EMAIL',
    });
  }

  if (hasInvalidEmails) {
    errors.qleApprovalEmails = intl.formatMessage({
      id: 'PLEASE_ENTER_A_VALID_EMAIL',
    });
  }

  if (isMissingConfigurationType) {
    errors.noNexCustomSet = intl.formatMessage({
      id: 'QLE_CONFIG_FORM_ERROR_MISSING_NON_NEX_CONFIG',
    });
  }

  return errors;
};

const handleSubmit = (
  values,
  {
    props: {
      groupId,
      benefitClass,
      qleApprovalEmails: propsQleApprovalEmails,
      qualifyingLifeEventType,
      setHREmails: setQleApproverEmails,
      submitQLEConfiguration: submitSetQLEConfiguration,
    },
  },
) => {
  const { qleApprovalEmails, needsHrApproval } = values;

  if (needsHrApproval && qleApprovalEmails) {
    const emailArray = changeEmailsToArray(qleApprovalEmails);
    if (JSON.stringify(propsQleApprovalEmails) !== JSON.stringify(emailArray)) {
      setQleApproverEmails({ qleApprovalEmails: emailArray, groupId });
    }
  }

  /**
   * If it's a brand new qle, there won't be a qle configuration id
   * So make this one "on" by default
   *
   * Convert the empty string (which we are using to make the placeholder display)
   * back to 0 for the submit to the api
   */
  const formValues = {
    ...values,
    daysBeforeQleEventDate: values.daysBeforeQleEventDate
      ? values.daysBeforeQleEventDate
      : 0,
    daysAfterQleEventDate: values.daysAfterQleEventDate
      ? values.daysAfterQleEventDate
      : 0,
    groupId,
    benefitClassId: benefitClass.id,
    qualifyingLifeEventType,
  };

  return submitSetQLEConfiguration(formValues);
};

const withFormikHOC = withFormik({
  validate,
  mapPropsToValues,
  handleSubmit,
  enableReinitialize: true,
});

export const QleConfigurationFormContainer = compose(
  injectIntl,
  withReduxProps,
  withFormikHOC,
  withRouter,
)(QLEConfigurationForm);
