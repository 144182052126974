import { all, call, fork, takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  NEW_EMPLOYER_VISITED,
  NEW_EMPLOYER_FORM_SUBMITTED,
  EDIT_EMPLOYER_VISITED,
  EDIT_EMPLOYER_FORM_INIT_STARTED,
  EDIT_EMPLOYER_FORM_INIT_COMPLETED,
  EDIT_EMPLOYER_FORM_SUBMITTED,
  CREATE_EMPLOYER,
  CREATE_EMPLOYER_SCHEMA,
  UPDATE_EMPLOYER,
  PUBLISH_EMPLOYER,
  ACTIVATE_EMPLOYER,
  SUSPEND_EMPLOYER,
  PUBLISH_EMPLOYER_SELECTED,
  ACTIVATE_EMPLOYER_SELECTED,
  SUSPEND_EMPLOYER_SELECTED,
  UPDATE_GROUP,
} from './types';
import { GET_GROUP } from '../types';
import { request, websocketFetch } from 'common/websocket-redux';
import { explodeObject } from 'common/adaptive-forms';
import { selectGroupId } from '../selectors';

export function* getCreateEmployerSchema() {
  yield request(CREATE_EMPLOYER_SCHEMA, [
    websocketFetch,
    'get_create_employer_schema',
  ]);
}
export function* getResourcesForEdit(groupId) {
  yield put({ type: EDIT_EMPLOYER_FORM_INIT_STARTED });
  const [employerResponse, schemaResponse] = yield all([
    call(websocketFetch, 'get_employer', { group_id: groupId }),
    call(websocketFetch, 'get_create_employer_schema'),
  ]);
  yield put({
    type: EDIT_EMPLOYER_FORM_INIT_COMPLETED,
    payload: {
      employer: employerResponse.info.employer,
      schema: schemaResponse.info,
    },
  });
}

export function* createEmployer(action) {
  const formValues = action.payload;
  const info = explodeObject(formValues);
  yield request(CREATE_EMPLOYER, [websocketFetch, 'create_employer', info]);
}

export function* navigateToEmployerProfile(action) {
  const groupId = action.payload.info.employer.group_id;
  yield put(push(`/admin/employers/${groupId}/profile`));
}

export function* getGroup(info) {
  // try updating the group
  yield put({ type: UPDATE_GROUP.STARTED, payload: info });
  try {
    const updateGroup = yield request(GET_GROUP, [
      websocketFetch,
      'get_group',
      info,
    ]);
    yield put({
      type: UPDATE_GROUP.SUCCEEDED,
      payload: { updateGroup },
    });
  } catch (err) {
    yield put({
      type: UPDATE_GROUP.ERRORED,
      payload: err,
    });
  }
}

export function* updateEmployer({ payload }) {
  const info = explodeObject(payload);
  const groupId = yield select(selectGroupId);

  yield request(UPDATE_EMPLOYER, [websocketFetch, 'update_employer', info]);
  yield getGroup({ group_id: groupId });
}

export function* publishEmployer(groupId) {
  const info = {
    group_id: groupId,
  };
  yield put({ type: PUBLISH_EMPLOYER.STARTED, payload: info });
  try {
    const response = yield call(
      websocketFetch,
      'publish_employer_benefits',
      info,
    );
    yield put({
      type: PUBLISH_EMPLOYER.SUCCEEDED,
      payload: response,
    });
    yield call(getGroup, info);
  } catch (error) {
    yield put({ type: PUBLISH_EMPLOYER.ERRORED, payload: error });
  }
}

export function* activateEmployer(groupId) {
  const info = {
    group_id: groupId,
  };
  yield put({ type: ACTIVATE_EMPLOYER.STARTED, payload: info });
  try {
    const response = yield call(websocketFetch, 'activate_employer', info);
    yield put({
      type: ACTIVATE_EMPLOYER.SUCCEEDED,
      payload: response,
    });
    yield call(getGroup, info);
  } catch (error) {
    yield put({ type: ACTIVATE_EMPLOYER.ERRORED, payload: error });
  }
}

export function* suspendEmployer(groupId) {
  const info = {
    group_id: groupId,
  };
  yield put({ type: SUSPEND_EMPLOYER.STARTED, payload: info });
  try {
    const response = yield call(websocketFetch, 'remove_group', info);
    yield put({
      type: SUSPEND_EMPLOYER.SUCCEEDED,
      payload: response,
    });
    yield call(getGroup, info);
  } catch (error) {
    yield put({ type: SUSPEND_EMPLOYER.ERRORED, payload: error });
  }
}

export function* initialize() {
  yield fork(getCreateEmployerSchema);
  yield fork(takeLatest, NEW_EMPLOYER_FORM_SUBMITTED, createEmployer);
  yield fork(takeLatest, CREATE_EMPLOYER.SUCCEEDED, navigateToEmployerProfile);
}

export function* newEmployerSaga() {
  yield takeLatest(NEW_EMPLOYER_VISITED, initialize);
}

export function* editEmployerSaga(groupId) {
  yield fork(takeLatest, EDIT_EMPLOYER_VISITED, getResourcesForEdit, groupId);
  yield fork(takeLatest, EDIT_EMPLOYER_FORM_SUBMITTED, updateEmployer);
  yield fork(takeLatest, PUBLISH_EMPLOYER_SELECTED, publishEmployer, groupId);
  yield fork(takeLatest, ACTIVATE_EMPLOYER_SELECTED, activateEmployer, groupId);
  yield fork(takeLatest, SUSPEND_EMPLOYER_SELECTED, suspendEmployer, groupId);
}
