import { groupBy } from 'lodash';

export const selectWalletTemplates = (state, benefitType) => {
  const allWalletTemplates = state.apps['employer-experience'].walletTemplates;
  if (!allWalletTemplates) return null;
  const result = Object.values(
    groupBy(
      allWalletTemplates && allWalletTemplates[benefitType],
      template => template?.benefit_product_type,
    ),
  );
  return result.length > 0 ? result : null;
};
