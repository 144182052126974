import { combineReducers } from 'redux';
import { chatAvailabilityReducer } from './reducers/chat-availability.reducer';
import { chatTranscriptsReducer } from './reducers/chat-transcripts.reducer';
import { chatTranscriptReducer } from './reducers/chat-transcript.reducer';
import { chatAgentsReducer } from './reducers/chat-agents.reducer';
import { makeStatusReducer } from 'common/request/status.reducer';
import {
  CREATE_SALESFORCE_OFFLINE_CASE,
  OPEN_MODAL,
  CLOSE_MODAL,
} from './chat-page.action-types';

const modal = (state = {}, action = {}) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        [action.payload.modalType]: { agentType: action.payload.agentType },
      };
    case CLOSE_MODAL:
      return {};
    default:
      return state;
  }
};

export const chatPageReducer = combineReducers({
  chatAvailability: chatAvailabilityReducer,
  offlineCaseStatus: makeStatusReducer(CREATE_SALESFORCE_OFFLINE_CASE),
  chatTranscripts: chatTranscriptsReducer,
  chatTranscript: chatTranscriptReducer,
  chatAgents: chatAgentsReducer,
  modal,
});
