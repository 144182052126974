import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Box, Flex, Link } from '@leagueplatform/genesis-commons';
import { SubmittedStatus } from './submitted-status.view';
import {
  TotalBreakdown,
  CoverageSummaryTable,
  useLoadCoverageSummary,
  LoadingBox,
} from 'common/coverage-summary';
import { currencyPropType } from '@leagueplatform/web-common';
import { EmptyState } from 'apps/employer-experience/ui-kit/empty-state.view';
import { LoadingIndicator } from './loading-indicator.view';
import { EmployerCostBanner } from 'common/coverage-summary/employer-cost-banner/employer-cost-banner.view';
import { PlanYearSelect } from 'common/components/plan-year-select';

/**
 * check if str is not equal to the last segment of the path
 * here, we pass in the current path ex: path/to/coverage-summary/planId
 * and we want to check that the passed in planId isn't already in the
 * current path
 */
const isNotLastPath = (planId, url = '') => url.split('/').pop() !== planId;

export const CoverageSummaryPresenter = ({
  isCoverageSummaryLoaded,
  showTotalBreakdown,
  showEmployerCostBanner,
  initialPlanId,
  benefitPlans,
  history,
  showBenefitSelectionsToolLink,
  employeeBaseUrl,
  match: {
    path,
    params: { planId },
  },
  ...props
}) => {
  const [currentPlanId, setCurrentPlanId] = useState();
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);

  const {
    employeeFormValues: { benefit_class_id: benefitClassId },
    userId,
    groupId,
    totalCost,
  } = props;

  const computedPlanId = currentPlanId ?? planId ?? initialPlanId;

  useLoadCoverageSummary({
    ...props,
    benefitClassId,
    planId: computedPlanId,
  });

  useEffect(() => {
    if (computedPlanId) setCurrentPlanId(computedPlanId);
  }, [computedPlanId]);

  useEffect(() => {
    if (currentPlanId && isNotLastPath(currentPlanId, path)) {
      history.push(path.replace(/:planId\?/i, currentPlanId));
    }
  }, [currentPlanId, path, history]);

  useEffect(() => {
    if (!hasInitiallyLoaded && isCoverageSummaryLoaded)
      setHasInitiallyLoaded(true);
  }, [isCoverageSummaryLoaded, hasInitiallyLoaded]);

  if (!hasInitiallyLoaded) return <LoadingIndicator />;

  return totalCost === null && isCoverageSummaryLoaded ? (
    <EmptyState p={3}>
      <FormattedMessage id="COVERAGE_SUMMARY_UNAVAILABLE_MESSAGE" />
    </EmptyState>
  ) : (
    <Box padding="one">
      {showBenefitSelectionsToolLink && (
        <Flex width={1} justifyContent="space-between">
          <Link
            as={NavLink}
            to={`/admin/benefit-selections/${userId}/${groupId}/${currentPlanId}`}
          >
            <FormattedMessage id="VIEW_BENEFIT_SELECTIONS_DIFF" />
          </Link>
          <Link
            as={NavLink}
            to={`/admin/allocation-dry-run/${userId}/${currentPlanId}/${groupId}`}
          >
            <FormattedMessage id="VIEW_BENEFIT_ALLOCATION_DRY_RUN" />
          </Link>
        </Flex>
      )}
      <Box marginY="two">
        <Flex marginBottom="oneAndHalf">
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
          <SubmittedStatus {...props} />
          {benefitPlans?.length > 1 && (
            <Flex width={1 / 2} justifyContent="flex-end">
              <PlanYearSelect
                benefitPlans={benefitPlans}
                value={currentPlanId}
                width={1 / 2}
                onChange={({ value }) => setCurrentPlanId(value)}
              />
            </Flex>
          )}
        </Flex>
        {isCoverageSummaryLoaded ? (
          <Box marginY="two">
            <Box marginBottom="two" textAlign="right">
              <Link
                as={NavLink}
                to={`${employeeBaseUrl}/plan-enrollment/${currentPlanId}`}
              >
                <FormattedMessage id="VIEW_USER_PLAN_ENROLLMENT" />
              </Link>
            </Box>
            {showTotalBreakdown && (
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              <TotalBreakdown {...props} paddingLeft="two" />
            )}
            {showEmployerCostBanner && (
              <Box marginTop={showTotalBreakdown ? 'two' : 'none'}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
                <EmployerCostBanner {...props} />
              </Box>
            )}
          </Box>
        ) : (
          <LoadingBox />
        )}

        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <CoverageSummaryTable {...props} />
      </Box>
    </Box>
  );
};

CoverageSummaryPresenter.propTypes = {
  showTotalBreakdown: PropTypes.bool.isRequired,
  showEmployerCostBanner: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  employeeFormValues: PropTypes.shape({
    benefit_class_id: PropTypes.string,
  }).isRequired,
  isCoverageSummaryLoaded: PropTypes.bool,
  loadCoverageSummary: PropTypes.func.isRequired,
  loadEmployerBenefitPlans: PropTypes.func.isRequired,
  initialPlanId: PropTypes.string,
  totalCost: currencyPropType,
  benefitPlans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      planId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  showBenefitSelectionsToolLink: PropTypes.bool,
  employeeBaseUrl: PropTypes.string.isRequired,
};

CoverageSummaryPresenter.defaultProps = {
  totalCost: null,
  initialPlanId: undefined,
  isCoverageSummaryLoaded: false,
  benefitPlans: [],
  showBenefitSelectionsToolLink: false,
};
