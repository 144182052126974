import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import {
  PlanSelectionStateProps,
  PlanSelectionDispatchProps,
  PlanSelectionStateOwnProps,
} from './plan-selection.container';
import {
  BenefitPlanListItem,
  NoPlanListItem,
} from './benefit-plan-list-item/benefit-plan-list-item.view';
import {
  BodyOneSecondary,
  Box,
  HeadingFour,
  Text,
  TextField,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { cloneDeep, escapeRegExp } from 'lodash';
import { BenefitClassWithPlanType } from '../benefit.types';
import { useDebounce } from '@leagueplatform/web-common';
import { StepContainer } from '../step-container/step-container.view';

export interface PlanSelectionPresenterProps
  extends PlanSelectionStateProps,
    PlanSelectionDispatchProps,
    PlanSelectionStateOwnProps {}

function filterClassesAndPlans(
  // eslint-disable-next-line @typescript-eslint/default-param-last -- FIXME: automatically added for existing issue
  classesAndPlans: BenefitClassWithPlanType[] = [],
  searchTerm: string,
) {
  // Regex that will match the words in the search term in any order

  const searchRegex = new RegExp(
    searchTerm
      .split(' ')
      .map(word => `(?=.*${escapeRegExp(word)})`)
      .join(''),
    'i',
  );

  const getMatch = (name: string) => name.match(searchRegex);

  return classesAndPlans.reduce((acc, benefitClass) => {
    if (getMatch(benefitClass.name)) {
      acc.push(benefitClass);
      return acc;
    }

    const benefitClassWithFilteredPlans = cloneDeep(benefitClass);
    benefitClassWithFilteredPlans.plans = benefitClass.plans.filter(p =>
      getMatch(`${benefitClass.name} ${p.name}`),
    );
    if (benefitClassWithFilteredPlans.plans.length) {
      acc.push(benefitClassWithFilteredPlans);
    }

    return acc;
  }, [] as Array<BenefitClassWithPlanType>);
}

export const PlanSelectionPresenter = ({
  mappedClassesAndPlans,
  selectedPlanId,
  setCurrentPlanId,
}: PlanSelectionPresenterProps) => {
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');
  const [filteredClassesAndPlans, setFilteredClassesAndPlans] = useState(
    mappedClassesAndPlans,
  );

  const debouncedSearchText = useDebounce(searchText, 100);

  useEffect(() => {
    setFilteredClassesAndPlans(
      filterClassesAndPlans(mappedClassesAndPlans, debouncedSearchText),
    );
  }, [debouncedSearchText, mappedClassesAndPlans]);

  return (
    <StepContainer
      title="ENROLLMENT_DESIGN_PLAN_SELECTION_TITLE"
      selectedPlanId={selectedPlanId}
    >
      <>
        <Box as="form">
          <TextField
            id="ENROLLMENT_DESIGN_PLAN_SELECTION_SEARCH"
            name="search"
            label={formatMessage({ id: 'SEARCH' })}
            placeholder={formatMessage({ id: 'ENTER_CLASS_OR_PLAN' })}
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            marginBottom="two"
          />
        </Box>
        {filteredClassesAndPlans?.map(benefitClass => (
          <>
            <HeadingFour>
              {benefitClass.name}{' '}
              {/* TODO: investigate why `tertiary.border.default` is being used here if it's not supported */}
              {/* @ts-expect-error */}
              <Text as="span" color="tertiary.border.default">
                {formatMessage({ id: 'CLASS' })}{' '}
              </Text>
            </HeadingFour>
            <Box marginBottom="one" marginTop="one">
              <ul key={benefitClass.id}>
                {benefitClass.plans.length ? (
                  benefitClass.plans?.map(benefitPlan => (
                    <li>
                      <BenefitPlanListItem
                        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                        {...benefitPlan}
                        key={benefitPlan.id}
                        isSelected={benefitPlan.id === selectedPlanId}
                        setActiveListPlanId={setCurrentPlanId}
                      />
                    </li>
                  ))
                ) : (
                  <li>
                    <NoPlanListItem key={benefitClass.id} />
                  </li>
                )}
              </ul>
            </Box>
          </>
        ))}
        {(!filteredClassesAndPlans || !filteredClassesAndPlans?.length) && (
          <BodyOneSecondary>
            {formatMessage({ id: 'NO_PLAN_SELECTION_RESULTS' })}
          </BodyOneSecondary>
        )}
      </>
    </StepContainer>
  );
};
