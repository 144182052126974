import { all, takeLatest } from 'redux-saga/effects';
import { analytics } from 'common/utilities';
import {
  REQUEST_GENERATE_INTERAC_CODE,
  REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT,
} from './account-info-form.types';
import {
  ANALYTICS_VIEW_HELP_POPUP,
  ANALYTICS_FRONTEND_ERROR_VALIDATING_ACCOUNT,
  ANALYTICS_BACKEND_ERROR_VALIDATING_ACCOUNT,
  ANALYTICS_VALIDATE_ACCOUNT,
} from './account-info-form-analytics.types';

export function* accountInfoFormAnalyticsSaga() {
  yield all([
    takeLatest(
      REQUEST_GENERATE_INTERAC_CODE.SUCCEEDED,
      analytics.bankingInfo.generateNewInteracCode,
    ),
    takeLatest(
      REQUEST_GENERATE_INTERAC_CODE.ERRORED,
      analytics.bankingInfo.errorGeneratingNewInteracCode,
    ),
    takeLatest(ANALYTICS_VIEW_HELP_POPUP, analytics.bankingInfo.viewHelpPopup),
    takeLatest(
      ANALYTICS_FRONTEND_ERROR_VALIDATING_ACCOUNT,
      analytics.bankingInfo.frontendErrorValidatingAccount,
    ),
    takeLatest(
      ANALYTICS_BACKEND_ERROR_VALIDATING_ACCOUNT,
      analytics.bankingInfo.backendErrorValidatingAccount,
    ),
    takeLatest(
      REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.ERRORED,
      analytics.bankingInfo.errorSubmitAccount,
    ),
    takeLatest(
      ANALYTICS_VALIDATE_ACCOUNT,
      analytics.bankingInfo.validateAccount,
    ),
  ]);
}
