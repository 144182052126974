export default {
  name: 'adjustmentForm',
  groups: [
    {
      title: 'Make Adjustment',
      fields: [
        {
          name: 'amount',
          span: 6,
        },
        {
          name: 'note',
          span: 6,
        },
        {
          name: 'billing_division_id',
          span: 6,
        },
      ],
    },
  ],
};
