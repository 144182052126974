import { createRequestTypes } from 'common/websocket-redux';

export const GET_WALLET_TEMPLATES_FOR_GROUP = createRequestTypes(
  'wallet-templates/GET_WALLET_TEMPLATES_FOR_GROUP',
);

export const CREATE_TEMPLATE_SELECTED =
  'wallet-templates/CREATE_TEMPLATE_SELECTED';

export function createTemplateSelected({ benefitId, benefitType }) {
  return {
    type: CREATE_TEMPLATE_SELECTED,
    payload: { benefitId, benefitType },
  };
}
