export default {
  gwlDental: {
    name: 'gwlDental',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'benefit_year',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'basic_included',
            span: 6,
          },

          {
            name: 'basic_reimbursement_percent',
            span: 6,
          },

          {
            name: 'max_amount_basic',
            span: 6,
          },

          {
            name: 'major_restorative_included',
            span: 6,
          },

          {
            name: 'max_amount_major',
            span: 6,
          },

          {
            name: 'orthodontic_services.eligibility',
            span: 6,
          },

          {
            name: 'orthodontic_services.reimbursement_percent',
            span: 6,
          },

          {
            name: 'orthodontic_services.lifetime_maximum',
            span: 6,
          },

          {
            name: 'dental_accident_reimbursement_percent',
            span: 6,
          },

          {
            name: 'recall_service_frequency',
            span: 6,
          },

          {
            name: 'recall_service_interval_months',
            span: 6,
          },
        ],
      },
    ],
  },
};
