import { setQLEConfiguration } from 'common/services';
import { request } from 'common/websocket-redux';
import get from 'lodash/get';
import { push } from 'connected-react-router';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getQLEConfigurations,
  setQLETypeAndId,
} from '../qle-configuration.action-creators';
import {
  SERVICE_SET_QLE_CONFIGURATION,
  SUBMIT_QLE_CONFIGURATION_FORM,
  CANCEL_QLE_CONFIGURATION_FORM,
} from './qle-configuration-form.action-types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

/**
 * @function setQLEConfigurationSaga Send API call to `SERVICE_set_qle_configuration`. On response,
 * store new QLE configuration id in redux and refetch QLE configurations for benefit class.
 *
 * If `allow_nex` is true, sends action to handle redirecting to NEX.
 *
 * worker saga, plus service for the fetch request
 * @param {string} groupId - group id / employer id
 * @param {string} benefitClassId - benefit class id
 * @param {string} qualifyingLifeEventType string - birth, marriage, divorce
 * @param {number} daysBeforeQleEventDate - amount of days this QLE is allowed to be set in the future
 * @param {number} daysAfterQleEventDate - amount of days this QLE is allowed to be set in the past
 * @param {boolean} needsHRApproval - if an HR Admin needs to approve this. Common in the States
 * @param {boolean} allowToAddDependents - this QLE is configured so that a user is allowed to add dependents
 * @param {boolean}allowToRemoveDependents - this QLE is configured so that a user is allowed to remove dependents
 * @param {boolean} allowNex -
 * @param {string} typeformUL - URL To the typeform form
 * @param {boolean} suspended - activate / deactivate (on/off) this particular configuration
 * @param {int} coverageEffectiveDatePolicy - One of the values from `coverage-effective-date-policy.constants`.
 */
export function* setQLEConfigurationSaga({ payload }) {
  const { groupId, benefitClassId, qualifyingLifeEventType } = payload;

  const response = yield request(
    SERVICE_SET_QLE_CONFIGURATION,
    setQLEConfiguration(payload),
  );

  if (!response.error) {
    const newQleConfigurationId = get(response, 'info.qle_configuration_id');
    yield put(
      setQLETypeAndId({
        qleType: qualifyingLifeEventType,
        qleConfigurationId: newQleConfigurationId,
      }),
    );
    yield put(
      push(`/admin/employers/${groupId}/benefits/qle-config/${benefitClassId}`),
    );
    yield put(getQLEConfigurations({ benefitClassId, groupId }));
  }
}

/**
 * set_employer_qle_approval_emails is in qle-configuration.sagas
 * because it's used in the landing page AND this form
 */
export function* showErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID',
  });
}

export function* cancelQLEConfiguration(action) {
  const { groupId, benefitClassId } = action.payload;
  yield put(
    push(`/admin/employers/${groupId}/benefits/qle-config/${benefitClassId}`),
  );
}

export function* QLEConfigurationFormSagas() {
  yield fork(
    takeLatest,
    SUBMIT_QLE_CONFIGURATION_FORM,
    setQLEConfigurationSaga,
  );
  yield fork(takeLatest, SERVICE_SET_QLE_CONFIGURATION.ERRORED, showErrorToast);
  yield fork(takeLatest, CANCEL_QLE_CONFIGURATION_FORM, cancelQLEConfiguration);
}
