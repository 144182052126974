import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { EmployeeDependents } from './employee-dependents.container';
import { DependentRouter } from './dependent/dependent-router.view';
import { AddDependent } from './dependent/add';

export const EmployeeDependentsRouter = ({ groupId, userId }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <EmployeeDependents groupId={groupId} />
      </Route>
      <Route
        exact
        path={`${path}/add`}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        render={props => <AddDependent dependentsBaseUrl={path} {...props} />}
      />
      <Route
        path={`${path}/:dependentId`}
        render={props => (
          <DependentRouter
            dependentsBaseUrl={path}
            groupId={groupId}
            userId={userId}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        )}
      />
      {/* eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue */}
      <Route component={() => <Redirect to={`${path}`} />} />
    </Switch>
  );
};

EmployeeDependentsRouter.propTypes = {
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};
