import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import { BodyOne } from '@leagueplatform/genesis-commons';
import { KEY } from '@leagueplatform/web-common';
import {
  colour,
  radiusInPx,
  spaceInPx,
  leagueFont,
  Backdrop,
  Modal,
  ClickEater,
} from '@leagueplatform/ui-kit';
import { ErrorMessage, ModalButton, ModalLoaderButton } from './modal-elements';

const Label = styled.label`
  display: block;
  margin-top: ${spaceInPx(3)};
`;

const Input = styled.input`
  ${leagueFont()};
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: ${spaceInPx(1)};
  border-radius: ${radiusInPx(2)};
  border: 1px solid ${colour('greyLight')};
`;

export const ImportPlanDesignModal = ({
  onClose,
  onSubmit,
  onConfirmSuccessfulImport,
  groupId,
  planDesignRequestStatus,
}) => {
  const [spreadsheetUrl, setSpreadsheetUrl] = useState(null);
  const onInputChange = e => setSpreadsheetUrl(e.target.value);
  const { loading, success, error, errorReason } = planDesignRequestStatus;
  const { formatMessage } = useIntl();

  return (
    <Backdrop onClose={onClose}>
      <Modal.Container>
        <ClickEater>
          <Modal.Header>
            <Modal.HeaderTitle>
              {formatMessage({ id: 'IMPORT_PLAN_DESIGN' })}
            </Modal.HeaderTitle>
          </Modal.Header>
          <Modal.Body>
            {success ? (
              <BodyOne>
                {formatMessage({ id: 'IMPORT_PLAN_DESIGN_SUCCESS' })}
              </BodyOne>
            ) : (
              <>
                <BodyOne>
                  {formatMessage({ id: 'IMPORT_PLAN_DESIGN_DESCRIPTION' })}
                </BodyOne>
                <Label>{formatMessage({ id: 'DOCUMENT_URL' })}</Label>
                <Input
                  disabled={success}
                  placeholder="https://docs.google.com/spreadsheets/url"
                  onChange={onInputChange}
                  onKeyDown={e =>
                    e.key === KEY.ENTER && onSubmit({ groupId, spreadsheetUrl })
                  }
                />
                {error && <ErrorMessage>{errorReason}</ErrorMessage>}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {success ? (
              <ModalButton primary onClick={onConfirmSuccessfulImport}>
                {formatMessage({ id: 'VIEW_BENEFITS' })}
              </ModalButton>
            ) : (
              <>
                <ModalButton onClick={onClose} disabled={loading}>
                  {formatMessage({ id: 'CANCEL' })}
                </ModalButton>
                <ModalLoaderButton
                  disabled={!spreadsheetUrl}
                  loading={loading}
                  primary
                  onClick={() => onSubmit({ groupId, spreadsheetUrl })}
                >
                  {formatMessage({ id: 'IMPORT_PLAN_DESIGN' })}
                </ModalLoaderButton>
              </>
            )}
          </Modal.Footer>
        </ClickEater>
      </Modal.Container>
    </Backdrop>
  );
};

ImportPlanDesignModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onConfirmSuccessfulImport: PropTypes.func,
  groupId: PropTypes.string.isRequired,
  planDesignRequestStatus: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
    errorReason: PropTypes.string,
  }).isRequired,
};

ImportPlanDesignModal.defaultProps = {
  onConfirmSuccessfulImport: () => {},
};
