import { createSelector } from 'reselect';
import { get, last, map, mapValues, pipe } from 'lodash/fp';
import { assign, concat, isEmpty, sortBy, omit } from 'lodash';
import { flattenObject } from 'common/adaptive-forms';
import { selectData } from '../claim-details.selectors';
import { EMPLOYEE_DEPENDENT_VALUE } from './settlement.constants';
import {
  selectUserDocuments,
  selectCurrentDocumentId,
} from 'common/components/user-documents/user-documents.selectors';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const selectRoot = get('apps.employer-experience.claimSettlement');

export const selectUI = get('apps.employer-experience.ui.claimSettlement');

export const selectIsReady = createSelector(selectUI, get('ready'));

export const selectError = createSelector(selectUI, get('error'));

export const selectSuccess = createSelector(selectUI, get('success'));

export const selectUpdating = createSelector(selectUI, get('updating'));

export const selectUpdatedAt = createSelector(selectUI, get('updatedAt'));

export const selectDependents = createSelector(selectRoot, get('dependents'));

export const makeDependentOption = ({ id, name }) => ({
  value: id,
  text: name,
});

export const makeDependentOptions = map(makeDependentOption);

export const selectClaim = createSelector(selectRoot, get('claim'));

export const selectClaimNotFound = createSelector(selectClaim, claim => !claim);

export const selectClaimId = createSelector(selectClaim, get('claimId'));

export const selectClaimUser = createSelector(selectRoot, get('claimUser'));

export const selectClaimUserId = createSelector(selectClaimUser, get('userId'));

export const selectClaimUserGroup = createSelector(
  selectRoot,
  get('claimUserGroup'),
);

export const selectGroupCurrency = createSelector(selectClaimUserGroup, group =>
  group.currency ? group.currency : 'CAD',
);

export const selectDependentOptions = createSelector(
  selectClaimUser,
  selectDependents,
  (claimUser, dependents) => {
    if (isEmpty(dependents)) {
      return null;
    }

    return [
      {
        value: EMPLOYEE_DEPENDENT_VALUE,
        text: `${claimUser.displayName} (Employee)`,
      },
      ...makeDependentOptions(dependents),
    ];
  },
);

export const selectInitialClaimStatus = createSelector(
  selectRoot,
  get('initialClaimStatus'),
);
export const selectClaimDescription = createSelector(
  selectRoot,
  get('claimDescription'),
);

export const selectReferenceId = createSelector(selectRoot, get('referenceId'));

export const selectInitialFormValues = createSelector(
  selectRoot,
  pipe(get('initialFormValues'), flattenObject),
);

export const selectFunds = createSelector(selectRoot, get('funds'));

export const selectBenefitNames = createSelector(
  selectRoot,
  root => root?.benefitNames,
);

export const selectCategoryOptions = createSelector(
  selectRoot,
  pipe(
    get('categories'),
    mapValues(map(category => ({ value: category.id, text: category.name }))),
  ),
);

export const selectClaimAgent = createSelector(selectRoot, get('claimAgent'));

export const selectVendors = createSelector(selectRoot, get('vendors'));

export const selectVendorOptions = createSelector(
  selectVendors,
  map(vendor => ({ value: vendor, title: vendor })),
);

export const selectDuplicateClaim = createSelector(
  selectUI,
  get('duplicateClaim'),
);

export const selectDuplicateClaimForUpdate = createSelector(
  selectDuplicateClaim,
  get('claimForUpdate'),
);

export const selectResolvingDuplicate = createSelector(
  selectUI,
  get('resolvingDuplicate'),
);

export const addHistoryKey = item =>
  assign({}, item, {
    kind: 'history',
    key: `${item.type}_${item.time}`,
  });

export const addFullUserName = item => {
  const withFullName = assign({}, item, {
    fullUserName: `${item.user.first_name} ${item.user.last_name}`,
  });
  return omit(withFullName, 'user');
};

export const withHistoryProps = item => addFullUserName(addHistoryKey(item));

export const addNoteKey = note =>
  assign({}, note, {
    kind: 'note',
    key: note.note_id,
  });

export const withNoteProps = note => addNoteKey(note);

export const getNotes = createSelector(
  selectData,
  selectClaimId,
  (data, claimId) => {
    if (data && data.claimNotes) {
      return data.claimNotes.filter(note => note.claim_id === claimId);
    }
    return [];
  },
);

export const getClaimHistory = createSelector(selectData, get('claimHistory'));

export const getHistoryWithProps = createSelector(
  getClaimHistory,
  map(withHistoryProps),
);

export const getNotesWithProps = createSelector(getNotes, map(withNoteProps));

export const selectClaimLog = createSelector(
  getHistoryWithProps,
  getNotesWithProps,
  (history, notes) => sortBy(concat(history, notes), 'time').reverse(),
);

export const selectCustCareNote = createSelector(
  getNotesWithProps,
  pipe(last, get('text')),
);

export const selectClaimIsApproved = createSelector(
  selectInitialClaimStatus,
  status => status && ['approved', 'paid'].includes(status),
);

export const selectPolicyAllocations = createSelector(
  selectRoot,
  get('policyAllocations'),
);

export const selectAvailableAccountsWithApprovedAmounts = createSelector(
  selectFunds,
  selectPolicyAllocations,
  (accounts, allocations) =>
    accounts &&
    allocations &&
    accounts.map(account => ({
      ...account,
      amountApproved: (
        allocations.find(policy => policy.type === account.policyKind) || {}
      ).amount_approved,
    })),
);

export const selectCanReadjustAllocationAmounts = createSelector(
  selectClaimIsApproved,
  selectAvailableAccountsWithApprovedAmounts,
  (isApproved, accounts) => isApproved && accounts.length > 1,
);

export const selectClaimDocumentsData = createSelector(
  selectRoot,
  get('claimDocuments'),
);

export const selectRequestedBenefitType = createSelector(
  selectRoot,
  get('requestedBenefitType'),
);

export const selectPaymentTransaction = createSelector(
  selectRoot,
  get('payment'),
);

const visibleTabs = ['settlement', 'claim-log', 'user-history', 'chat'];
export const selectVisibleTabs = createSelector(
  selectClaim,
  selectPaymentTransaction,
  (claim, paymentTransaction) => {
    return claim && paymentTransaction
      ? [...visibleTabs, 'payments']
      : visibleTabs;
  },
);

export const selectClaimDocuments = createSelector(
  selectClaimDocumentsData,
  selectUserDocuments,
  selectClaimId,
  (claimDocuments, userDocuments, claimId) => {
    if (claimDocuments && userDocuments) {
      return claimDocuments.map(doc => {
        const supportingDoc = userDocuments.find(
          sDoc => sDoc.contentIds[0] === doc.contentId,
        );
        const contentType = supportingDoc
          ? supportingDoc.contentType
          : doc.contentType;
        const originalClaimId = supportingDoc
          ? supportingDoc.originalClaimId
          : doc.originalClaimId;
        const isOwnDocument = originalClaimId === claimId;
        return {
          ...doc,
          contentType,
          originalClaimId,
          isOwnDocument,
          contentIds: supportingDoc
            ? supportingDoc.contentIds
            : [doc.contentId],
          contentUrl: getContentUrl(doc.contentId),
          contentUrls: [getContentUrl(doc.contentId)],
          isAttachedToClaim: doc.isAttachedToClaim || !!supportingDoc,
          isImageContentType:
            contentType === 'image/jpeg' || contentType === 'image/png',
          documentType: supportingDoc ? supportingDoc.documentType : null,
          documentTypeOtherDescription:
            supportingDoc && supportingDoc.documentTypeOtherDescription
              ? supportingDoc.documentTypeOtherDescription
              : null,
          name: supportingDoc ? supportingDoc.name : null,
          userDocumentId: supportingDoc ? supportingDoc.userDocumentId : null,
          expirationDate: supportingDoc ? supportingDoc.expirationDate : null,
        };
      });
    }

    return null;
  },
);

export const selectClaimUserDocuments = createSelector(
  selectClaimDocuments,
  selectUserDocuments,
  (claimDocuments, userDocuments) => {
    if (claimDocuments && userDocuments) {
      return userDocuments.map(userDoc => {
        const isAttachedToClaim = claimDocuments.some(
          claimDoc => claimDoc.contentId === userDoc.contentId,
        );
        return {
          ...userDoc,
          contentUrl: [getContentUrl(userDoc.contentIds[0])],
          isAttachedToClaim,
        };
      });
    }

    return null;
  },
);

export const selectCurrentUserDocument = createSelector(
  selectUserDocuments,
  selectCurrentDocumentId,
  (documents, contentId) =>
    documents && documents.find(doc => doc.contentId === contentId),
);

export const selectIsOldBenefitsModelUser = createSelector(
  selectRoot,
  get('isOldBenefitsModelUser'),
);

export const selectDateCreated = createSelector(selectRoot, get('dateCreated'));
