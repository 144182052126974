export const getTotalPercentage = designations =>
  designations?.reduce(
    (total, designation) =>
      total + parseInt(designation?.share_percentage || 0, 10),
    0,
  );

export const isTotalPercentValid = percent => percent !== 100;

const beneficiariesBaseUrlPath = '/member/beneficiaries';

export const getBeneficiariesUrl = path =>
  path ? `${beneficiariesBaseUrlPath}/${path}` : beneficiariesBaseUrlPath;

export const parameterize = string => string?.replace(/\s/g, '-').toLowerCase();
