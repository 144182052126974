import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { isImageContentType } from '@leagueplatform/web-common';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { flatten } from 'lodash';

export const selectEmployerUserDocuments = get(
  'apps.employer-experience.userDocuments',
);

export const selectMemberUserDocuments = get(
  'apps.member-experience.userDocuments',
);

export const selectUserDocuments = createSelector(
  selectEmployerUserDocuments,
  selectMemberUserDocuments,
  (employerDocuments, memberDocuments) =>
    (employerDocuments || memberDocuments).documents
      .map(doc => ({
        ...doc,
        contentUrls: doc.contentIds.map(contentId => getContentUrl(contentId)), // doc.contentId is an array, make contentUrl an array by mapping through
      }))
      .sort((a, b) => {
        return new Date(b.uploadedDate) - new Date(a.uploadedDate);
      }),
);

export const selectUserDocumentsUI = get(
  'apps.employer-experience.ui.userDocuments',
);

export const selectIsRequesting = createSelector(
  selectUserDocumentsUI,
  get('isRequesting'),
);

export const selectIsUploading = createSelector(
  selectUserDocumentsUI,
  get('isUploading'),
);

export const selectIsUploadModalOpen = createSelector(
  selectUserDocumentsUI,
  get('isUploadModalOpen'),
);

export const selectIsImageViewerOpen = createSelector(
  selectUserDocumentsUI,
  get('isImageViewerOpen'),
);

export const selectIsLoading = createSelector(
  selectIsRequesting,
  selectIsUploading,
  (isRequesting, isUploading) => isUploading || isRequesting,
);

export const selectCurrentDocumentId = createSelector(
  selectUserDocumentsUI,
  get('currentDocumentId'),
);

export const selectActiveIndex = createSelector(
  selectUserDocumentsUI,
  get('activeIndex'),
);

export const selectIsEditingUserDocument = createSelector(
  selectUserDocumentsUI,
  get('isEditingUserDocument'),
);

export const selectIsRemovingUserDocument = createSelector(
  selectUserDocumentsUI,
  get('isRemovingUserDocument'),
);

export const selectCurrentUserDocument = createSelector(
  selectUserDocuments,
  selectCurrentDocumentId,
  (documents, contentId) =>
    documents && documents.find(doc => doc.contentId === contentId),
);

export const selectUserDocumentsImages = createSelector(
  selectUserDocuments,
  documents =>
    documents &&
    documents
      .filter(doc => isImageContentType(doc.contentType))
      .map(doc => {
        return { src: doc.contentUrls, id: doc.contentIds, alt: doc.name };
      }),
);

export const selectFlattenedUserDocumentsImages = createSelector(
  selectUserDocuments,
  documents =>
    documents &&
    flatten(
      documents
        .filter(doc => isImageContentType(doc.contentType))
        .map(doc => doc.contentUrls),
    ),
);
