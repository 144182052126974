import { GET_PURCHASE_HISTORY } from './purchase-history-page.action-types';
import { LIMITS } from './purchase-history-page.constants';

export const initialState = {
  isLoading: false,
  hasErrored: false,
  areMoreAvailable: false,
  offset: 0,
  history: null,
};

const isMoreAvailable = (state, history) =>
  history.length === LIMITS.PURCHASE_HISTORY + state.offset;

export const purchaseHistoryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PURCHASE_HISTORY.BASE:
      if (action.payload.offset === 0) {
        return { ...initialState };
      }

      return {
        ...state,
        offset: action.payload.offset,
      };
    case GET_PURCHASE_HISTORY.STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PURCHASE_HISTORY.SUCCEEDED: {
      const newHistory = action.payload.info.history ?? [];
      const history = [...(state.history ?? []), ...newHistory];

      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        areMoreAvailable: isMoreAvailable(state, history),
        history,
      };
    }
    case GET_PURCHASE_HISTORY.ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
      };
    default:
      return state;
  }
};
