import {
  selectReportContentId,
  selectIsReportLoading,
  selectReportError,
  selectUserEmail,
} from './employer-reports.selectors';
import { createStructuredSelector } from 'reselect';
import { getEmployerEnrolmentReport } from './employer-reports.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { EnrolmentReport } from './enrolment-report.view';
import { ENROLMENT_REPORT_LIFECYCLE } from './employer-reports.types';

const mapStateToProps = connect(
  createStructuredSelector({
    contentId: selectReportContentId,
    loadingState: selectIsReportLoading,
    reportError: selectReportError,
    email: selectUserEmail,
  }),
);

const withActions = connect(null, {
  getEmployerEnrolmentReport,
  visit: ENROLMENT_REPORT_LIFECYCLE.visit,
});

export const EnrolmentContainer = compose(
  mapStateToProps,
  withActions,
)(EnrolmentReport);
