// Analytics Event Constants
export const ANALYTICS_EVENT_NAME_ADMIN = 'ga_admin_event';
export const ANALYTICS_EVENT_CATEGORY_PAYROLL = 'payroll';
export const ANALYTICS_EVENT_ACTION_RE_REGISTER_SUCCEEDED =
  're_register_succeeded';
export const ANALYTICS_EVENT_ACTION_RE_REGISTER_FAILED = 're_register_failed';
export const ANALYTICS_EVENT_LABEL_RE_REGISTER_BUTTON = 're-register button';

export const RE_REGISTRATION_REASONS = [
  'Pricing Config Issue',
  'Integration Issue - Missing Field for Payroll',
  'Benefit Allocation Issue',
  'US TASA related Issue',
  'Volume/Coverage Amount Update',
  'Other',
];
