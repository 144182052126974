import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { TermsContainer } from './terms.container';
import { useHistory } from 'react-router-dom';
import reducer from 'common/state/reducer';
import { rootSaga } from 'common/state/root.saga';
import { createConfiguredStore } from 'store/store';

export const UserTerms = () => {
  const history = useHistory();
  const [store] = useState(() =>
    createConfiguredStore({
      reducer: reducer(history),
      history,
      saga: rootSaga,
    }),
  );

  return (
    <Provider store={store}>
      <TermsContainer />
    </Provider>
  );
};
