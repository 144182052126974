import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setBenefitSelection
 *
 * This is an enrollment API to set a user's benefit selection. Typically, you will pass
 * either an array of benefits w/selection data OR a bundle ID.
 *
 * @param {string} groupId - the employer group ID
 * @param {string} userId (optional) - the employee user ID. If not supplied the current user context is assumed by services
 * @param {object[]} benefits (optional) - array of benefits with selections
 * @param {string} bundleId (optional) - if the plan has multiple bundles, pass their bundle selection here
 * @param {object} planConfig (optional) - ACL: Admin only, sets plan config
 */

export const setBenefitSelection = ({
  groupId,
  benefits = null,
  bundleId = null,
  userId = null,
  planConfig = null,
  planId,
  certifications,
}) =>
  call(websocketFetch, 'set_benefit_selection', {
    benefits,
    group_id: groupId,
    user_id: userId,
    bundle_id: bundleId,
    plan_config: planConfig,
    plan_id: planId,
    certifications,
  });
