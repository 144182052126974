import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  genesisStyled,
  css,
  Flex,
  Box,
  TextInput,
  Overline,
  PrimaryButton,
  Checkbox,
  ChoiceText,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useExportCustomPlanFilterHook } from './use-export-custom-plan-filter.hook';

const FilterContainer = genesisStyled(Box)(({ isFilterOpen }) => {
  return css({
    backgroundColor: 'surface.background.primary',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    display: isFilterOpen ? 'block' : 'none',
    borderRadius: '3px',
    position: 'absolute',
    zIndex: '1',
    top: '75px',
    width: '100%',
  });
});

const FilterItem = genesisStyled(Label)(
  css({
    width: '100%',
    paddingX: 'half',
    '&:hover': {
      backgroundColor: 'surface.background.secondary',
    },
  }),
);

export const ExportCustomPlanFilter = ({ onChange, data, onClear }) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef(null);

  const {
    checkUncheckLabel,
    getSelected,
    selectedLabels,
    selectItem,
    setSelectedLabels,
    setIsFilterOpen,
    isFilterOpen,
    setSearch,
    filteredLabels,
    search,
    labels,
  } = useExportCustomPlanFilterHook(onChange, data, inputRef);

  return (
    <Flex alignItems="flex-end">
      <Box
        position="relative"
        width="34%"
        as="form"
        ref={inputRef}
        paddingRight="half"
      >
        <Label htmlFor="text_input">{formatMessage({ id: 'FILTER' })}</Label>
        <TextInput
          name="text_input"
          onChange={event => setSearch(event.target.value)}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          onKeyPress={() => setIsFilterOpen(true)}
          placeholder={getSelected(selectedLabels)}
          id="text_input"
        />
        <FilterContainer
          isFilterOpen={isFilterOpen}
          maxHeight={400}
          height="fit-content"
          overflow="scroll"
          as="ul"
          paddingX="one"
          paddingY="threeQuarters"
        >
          {labels.map((group, i) => (
            <Box key={group.label}>
              {filteredLabels[i].length > 0 && (
                <Overline>{group.name}</Overline>
              )}
              {group?.options
                .filter(item => {
                  if (search) {
                    return (
                      item.label.toLowerCase().indexOf(search?.toLowerCase()) >
                      -1
                    );
                  }
                  return item;
                })
                .map(item => {
                  const selectedBenefit = {
                    label: group.label,
                    item: item.label,
                  };
                  return (
                    <FilterItem
                      key={item.label}
                      as="li"
                      onClick={() =>
                        !item.disabled && selectItem(selectedBenefit)
                      }
                    >
                      <Flex minHeight={50} alignItems="center" width="100%">
                        <Checkbox
                          name="checkbox-group"
                          value={item.label}
                          checked={checkUncheckLabel(
                            selectedLabels,
                            selectedBenefit,
                          )}
                          disabled={item.disabled}
                          aria-checked={checkUncheckLabel(
                            selectedLabels,
                            selectedBenefit,
                          )}
                          aria-labelledby={item.label}
                        />
                        <ChoiceText
                          marginLeft="threeQuarters"
                          id={item.label}
                          htmlFor={item.label}
                        >
                          {item.label}
                        </ChoiceText>
                      </Flex>
                    </FilterItem>
                  );
                })}
            </Box>
          ))}
        </FilterContainer>
      </Box>

      <PrimaryButton
        marginLeft="one"
        disabled={selectedLabels.length <= 0}
        onClick={() => {
          setSelectedLabels({});
          onClear();
        }}
        height="fit-content"
      >
        {formatMessage({ id: 'CLEAR_FILTERS' })}
      </PrimaryButton>
    </Flex>
  );
};

ExportCustomPlanFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

ExportCustomPlanFilter.defaultProps = {
  data: null,
};
