import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  Box,
  HeadingThree,
  HeadingFour,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { breakpoints, Divider, Responsive } from '@leagueplatform/ui-kit';
import { ContributionsSummarySelection } from './contributions-summary-selection.view';
import { BenefitIcon } from 'common/benefits/benefit-icon.view';

export const ContributionsSummary = ({
  name,
  id,
  selections,
  translatedName,
  icon,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Flex alignItems="center" marginBottom="oneAndHalf">
        <BenefitIcon benefitType={id} src={icon} size="64px" />
        <HeadingThree>
          {translatedName || formatMessage({ id: name })}
        </HeadingThree>
      </Flex>

      <ul>
        {selections &&
          selections.map((selection, idx) => {
            return (
              <li key={selection?.benefit_id}>
                <Flex justifyContent="space-between" alignItems="baseline">
                  <HeadingFour>{selection?.benefit_name}</HeadingFour>
                  <Responsive breakpoint={breakpoints.tabletAndAbove}>
                    <BodyOneSecondary textAlign="right">
                      {formatMessage(
                        { id: 'PROVIDED_BY_VENDOR' },
                        { vendor: selection?.vendor_name },
                      )}
                    </BodyOneSecondary>
                  </Responsive>
                </Flex>
                <Responsive breakpoint={breakpoints.belowTablet}>
                  <BodyOneSecondary marginTop="half" marginBottom="one">
                    {formatMessage(
                      { id: 'PROVIDED_BY_VENDOR' },
                      { vendor: selection?.vendor_name },
                    )}
                  </BodyOneSecondary>
                </Responsive>
                {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
                <ContributionsSummarySelection {...selection} {...props} />
                {idx < selections.length - 1 && (
                  <Divider my={20} bottomBorder />
                )}
              </li>
            );
          })}
      </ul>
    </Box>
  );
};

ContributionsSummary.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  translatedName: PropTypes.string,
  icon: PropTypes.string,
};

ContributionsSummary.defaultProps = {
  translatedName: undefined,
  icon: undefined,
};
