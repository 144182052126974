import { Image, VisuallyHidden } from '@leagueplatform/genesis-commons';
import {
  Button,
  Box,
  styled,
  keyframes,
  StackLayout,
  HeadingText,
} from '@leagueplatform/genesis-core';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DocumentModal } from './components/document-modal/document-modal.view';
import { DocumentList } from './components/document-list/document-list.component';
import { Summary } from './components/claim-detail-summary/claim-detail-summary.component';
import { SUBMITTED, MORE_INFORMATION_NEEDED } from 'common/constants';
import { includes } from 'lodash';
import { CancelClaimModal } from './components/cancel-claim-modal/cancel-claim-modal.component';
import { useIntl } from '@leagueplatform/locales';
import ChevronLeft from 'apps/public/assets/chevron-left.svg';
import { MemberLink } from 'apps/member-experience/resources/routing/member-link.view';
import { useClaimsAnalytics } from '../hooks/use-claims-analytics.hook';
import { CLAIMS_SCREEN_NAMES } from '../constants/claims-screen-names';
import { EVENT_NAME } from '@leagueplatform/analytics';
import Timeline, {
  mapClaimHistoryToComponents,
} from './components/timeline/timeline.view';

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const FlexWrapper = styled(Box, {
  $$fadeAnimation: fadeIn,
  animation: '$$fadeAnimation 0.4s ease',
  display: 'flex',
  gap: '$two',
  flexWrap: 'wrap',

  '& > :first-child': {
    flexGrow: 2,
    flexBasis: '50ch',
  },

  '& > :last-child': {
    flexBasis: 0,
    flexGrow: 1,
    minInlineSize: '26%',
  },
});

const Container = styled(Box, {
  borderColor: '$onSurfaceBorderSubdued',
  borderRadius: '$small',
  borderStyle: 'solid',
  borderWidth: '$thin',
  padding: '$oneAndHalf',
});

const showEditFor = [SUBMITTED, MORE_INFORMATION_NEEDED];
const chatUrl = '/member/chat';

export const ClaimDetails = ({
  claim,
  claimHistory,
  documents,
  editClaimSelected,
  cancelClaimSelected,
  onToggleDocumentSelected,
  chatClaimSelected,
  ready,
}) => {
  const { formatMessage } = useIntl();
  const params = new URLSearchParams(window.location.search);
  const comingFromWallet = params.get('source') === 'wallet';
  const backButtonUrl = comingFromWallet
    ? '/member/wallet'
    : '/member/claims-history';
  const [showCancelClaimModal, setShowCancelClaimModal] = useState(false);

  const sendAnalyticEvent = useClaimsAnalytics(
    CLAIMS_SCREEN_NAMES.CLAIM_OVERVIEW,
    {},
    claim?.requested_benefit_type,
    claim?.claim_id,
  );

  const handleCloseCancelClaimModal = useCallback(() => {
    setShowCancelClaimModal(false);
    sendAnalyticEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: CLAIMS_SCREEN_NAMES.CLAIM_CONFIRM_CANCELLATION_MODAL,
      detail: 'go back',
    });
  }, [sendAnalyticEvent]);

  const handleCancelClaim = useCallback(() => {
    cancelClaimSelected(claim?.claim_id);
    setShowCancelClaimModal(false);
    sendAnalyticEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: CLAIMS_SCREEN_NAMES.CLAIM_CONFIRM_CANCELLATION_MODAL,
      detail: 'cancel claim',
    });
  }, [cancelClaimSelected, claim?.claim_id, sendAnalyticEvent]);

  return (
    <Box
      as="section"
      css={{
        marginTop: '$three',
        marginX: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1000px',
        paddingBottom: '$four',
        paddingLeft: '$two',
        paddingRight: '$two',
      }}
    >
      <MemberLink
        to={backButtonUrl}
        css={{
          display: 'inline-flex',
          alignItems: 'center',
          marginBottom: '$two',
        }}
      >
        <Image
          alt=""
          height={24}
          loading="eager"
          marginRight="half"
          src={ChevronLeft}
        />
        {formatMessage({
          id: comingFromWallet ? 'BACK_TO_WALLET' : 'BACK_TO_CLAIMS',
        })}
      </MemberLink>
      {ready && (
        <FlexWrapper>
          <Box>
            <Container>
              <Summary claim={claim} />
            </Container>
            <HeadingText
              level="2"
              size="md"
              css={{ marginBottom: '$one', marginTop: '$oneAndHalf' }}
            >
              {formatMessage({ id: 'CLAIM_STATUS' })}
            </HeadingText>
            <Container>
              <Timeline
                items={mapClaimHistoryToComponents(
                  claimHistory.map(c => ({
                    ...c,
                    onEditClaim: editClaimSelected,
                    claimId: claim.claim_id,
                  })),
                )}
              />
            </Container>
            <HeadingText
              level="2"
              size="md"
              css={{ marginBottom: '$one', marginTop: '$oneAndHalf' }}
            >
              {formatMessage({ id: 'CLAIM_DOCUMENTS' })}
            </HeadingText>
            <DocumentList
              documents={documents}
              onToggleDocumentSelected={onToggleDocumentSelected}
            />
            <DocumentModal />
          </Box>
          <Box>
            <StackLayout
              spacing={{ '@initial': '$half', '@mobile': '$oneAndHalf' }}
            >
              <VisuallyHidden as="h2">
                {formatMessage({ id: 'CLAIM_DETAIL_ASIDE_HEADING' })}
              </VisuallyHidden>
              {includes(showEditFor, claim.status) && (
                <Button
                  as={MemberLink}
                  to={`/member/edit-claim/${claim.claim_id}`}
                  onClick={() => editClaimSelected(claim.claim_id)}
                  icon="interfaceEdit"
                  width="fillContainer"
                  css={{
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  {formatMessage({ id: 'EDIT_CLAIM' })}
                </Button>
              )}
              {includes(showEditFor, claim.status) && (
                <Button
                  priority="secondary"
                  onClick={() => {
                    setShowCancelClaimModal(true);
                    sendAnalyticEvent(EVENT_NAME.BUTTON_CLICKED, {
                      detail: 'cancel claim',
                    });
                  }}
                  icon="statusErrorStatus"
                  width="fillContainer"
                >
                  {formatMessage({ id: 'CANCEL_CLAIM' })}
                </Button>
              )}
              <Button
                as={MemberLink}
                priority="secondary"
                to={chatUrl}
                onClick={() => chatClaimSelected()}
                icon="illustrativeSupportQuestionChat"
                width="fillContainer"
                css={{
                  '&:hover': {
                    textDecoration: 'none',
                  },
                  textAlign: 'center',
                }}
              >
                {formatMessage({ id: 'CHAT_WITH_AN_AGENT' })}
              </Button>
            </StackLayout>
          </Box>
        </FlexWrapper>
      )}
      {showCancelClaimModal && (
        <CancelClaimModal
          closeModal={handleCloseCancelClaimModal}
          cancelClaim={handleCancelClaim}
          claim={claim}
        />
      )}
    </Box>
  );
};

ClaimDetails.propTypes = {
  claim: PropTypes.object, // eslint-disable-line
  claimHistory: PropTypes.array, // eslint-disable-line
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      content_id: PropTypes.string,
      content_type: PropTypes.string,
    }),
  ).isRequired,
  editClaimSelected: PropTypes.func.isRequired,
  cancelClaimSelected: PropTypes.func.isRequired,
  onToggleDocumentSelected: PropTypes.func.isRequired,
  chatClaimSelected: PropTypes.func.isRequired,
  ready: PropTypes.bool,
};

ClaimDetails.defaultProps = {
  claim: {},
  claimHistory: [],
  ready: false,
};
