import { isAuth0Enabled } from './auth.utils';
import {
  authSaga as authClassicSaga,
  authenticate,
  redirectToSignIn,
  authenticateWithExistingSession,
} from './auth-classic/auth-classic.saga';
import {
  auth0Saga,
  authenticateWebSocketMessage as auth0AuthenticateWebSocketMessage,
} from './auth0/auth0.saga';

export function authSaga() {
  return isAuth0Enabled() ? auth0Saga() : authClassicSaga();
}

export function authenticateWebSocketMessage(action) {
  return isAuth0Enabled() ? auth0AuthenticateWebSocketMessage(action) : null;
}

export { authenticate, redirectToSignIn, authenticateWithExistingSession };
