export const useInvoiceRow = (billingInsightsData, invoice) => {
  const validateBillingName = ['None', ''];

  const billingDivisionName = !validateBillingName.includes(
    invoice.billing_division_name,
  );

  const date = new Date(invoice.invoice_date);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;

  const isBillingInsight = billingInsightsData && year >= 2023;

  return {
    year,
    month,
    billingDivisionName: billingDivisionName
      ? invoice.billing_division_name
      : null,
    isBillingInsight,
  };
};
