import { get } from 'lodash';
import {
  VERIFY_ACCOUNT,
  GET_GROUP_PRE_ENROLLMENT_CONFIG,
} from './invite-access.actions';
import {
  ACCOUNT_NOT_VERIFIED,
  NOT_IN_ENROLLMENT_PERIOD,
} from './invite-access-constants';

const initialState = {
  ready: false,
  verifying: false,
  config: null,
  verificationError: false,
  beforeEnrollmentPeriod: false,
  enrollmentStartDate: null,
  error: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const inviteAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_PRE_ENROLLMENT_CONFIG.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const config = get(action, 'payload.info.group_pre_enrollment_config');
      return { ...state, config, ready: true };
    case VERIFY_ACCOUNT.STARTED:
      return {
        ...state,
        verifying: true,
      };
    case VERIFY_ACCOUNT.SUCCEEDED:
      return {
        ...state,
        verificationError: false,
        enrollmentPeriodError: false,
        verifying: false,
        error: false,
      };
    case VERIFY_ACCOUNT.ERRORED:
      if (action.payload === ACCOUNT_NOT_VERIFIED) {
        return {
          ...state,
          verifying: false,
          verificationError: true,
          enrollmentStartDate: null,
        };
      }
      if (action.payload === NOT_IN_ENROLLMENT_PERIOD) {
        return {
          ...state,
          verifying: false,
          verificationError: false,
          beforeEnrollmentPeriod: true,
          enrollmentStartDate: action.meta && new Date(action.meta),
        };
      }
      return {
        ...state,
        verifying: false,
        error: true,
      };
    case VERIFY_ACCOUNT.CANCELLED:
      return {
        ...state,
        verifying: false,
      };
    default:
      return state;
  }
};
