import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { isKPMG, isShopify } from 'apps/utils/employer-utils';

export const selectApp = state => {
  return state.apps.memberExperience;
};

export const selectInfo = createSelector(selectApp, app => app?.info);

export const selectGroup = createSelector(selectInfo, get('group'));

export const selectGroupId = createSelector(selectGroup, get('group_id'));

export const selectGroupName = createSelector(selectGroup, get('group.name'));

export const selectGroupEmployer = createSelector(
  selectGroup,
  get('group.employer'),
);

export const selectGroupAnnualEnrollmentPeriod = createSelector(
  selectGroupEmployer,
  get('benefits_annual_enrollment_period'),
);

export const selectGroupCountryCode = createSelector(
  selectGroup,
  group => group?.group?.country_code,
);

export const selectIsKPMG = createSelector(selectGroupName, isKPMG);

export const selectIsShopify = createSelector(selectGroupName, isShopify);
