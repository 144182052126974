export default {
  vspVision: {
    name: 'vspVision',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'benefit_year',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },

          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'in_network_eye_exam',
            span: 6,
          },

          {
            name: 'in_network_eye_exam_interval',
            span: 6,
          },

          {
            name: 'in_network_single_vision_lenses',
            span: 6,
          },

          {
            name: 'in_network_lined_bifocal_lenses',
            span: 6,
          },

          {
            name: 'in_network_lined_trifocal_lenses',
            span: 6,
          },

          {
            name: 'in_network_lenticular_lenses',
            span: 6,
          },

          {
            name: 'in_network_lenses_interval',
            span: 6,
          },

          {
            name: 'in_network_frames',
            span: 6,
          },

          {
            name: 'in_network_frames_interval',
            span: 6,
          },

          {
            name: 'in_network_elective_contact_lenses',
            span: 6,
          },

          {
            name: 'in_network_medically_necessary_contact_lenses',
            span: 6,
          },

          {
            name: 'in_network_materials_copay',
            span: 6,
          },

          {
            name: 'in_network_laser_eye_surgery',
            span: 6,
          },

          {
            name: 'out_of_network_eye_exam',
            span: 6,
          },

          {
            name: 'out_of_network_eye_exam_interval',
            span: 6,
          },

          {
            name: 'out_of_network_single_vision_lenses',
            span: 6,
          },

          {
            name: 'out_of_network_lined_bifocal_lenses',
            span: 6,
          },

          {
            name: 'out_of_network_lined_trifocal_lenses',
            span: 6,
          },

          {
            name: 'out_of_network_lenticular_lenses',
            span: 6,
          },

          {
            name: 'out_of_network_lenses_interval',
            span: 6,
          },

          {
            name: 'out_of_network_frames',
            span: 6,
          },

          {
            name: 'out_of_network_frames_interval',
            span: 6,
          },

          {
            name: 'out_of_network_elective_contact_lenses',
            span: 6,
          },

          {
            name: 'out_of_network_medically_necessary_contact_lenses',
            span: 6,
          },

          {
            name: 'out_of_network_materials_copay',
            span: 6,
          },

          {
            name: 'out_of_network_laser_eye_surgery',
            span: 6,
          },
        ],
      },
    ],
  },
};
