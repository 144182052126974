import React from 'react';
import PropTypes from 'prop-types';
import { vars, ConfirmationModal, Backdrop } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Button, StyledDayPickerInput } from '../../employer-experience/ui-kit';
import {
  BENEFIT_STATUS_MAP_COPY,
  DATE_FORMAT,
  BENEFIT_STATUS_OPTED_OUT,
  BENEFIT_STATUS_READY,
  ADJUST_BENEFIT_END_DATE,
  REMOVE_BENEFIT,
} from './constants';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { FormattedHTMLMessage } from '@leagueplatform/locales';

const DayPickerWrapper = styled.div`
  margin-top: 0.5rem;
  max-width: 22rem;

  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${vars.colour.red};
  line-height: 1.4rem;
  margin-top: 0.6rem;
`;

const StyledModalNote = styled.div`
  margin-top: 0.8rem;
`;

const StyledModalBody = styled.div`
  margin-top: -0.5rem;
`;

const getHeaderText = (confirmType, fullName) => {
  switch (confirmType) {
    case REMOVE_BENEFIT:
      return (
        <FormattedMessage
          id="REMOVE_BENEFIT_CONFIRMATION_HEADER"
          values={{ benefitName: fullName }}
        />
      );
    case ADJUST_BENEFIT_END_DATE:
      return <FormattedMessage id="ADJUST_END_DATE" />;
    default:
      return (
        <FormattedMessage
          id="STATUS_BENEFIT_CONFIRMATION_HEADER"
          values={{ benefitName: fullName }}
        />
      );
  }
};

const getContentText = (confirmType, fullName, benefit) => {
  switch (confirmType) {
    case REMOVE_BENEFIT:
      return (
        <FormattedMessage
          id="REMOVE_BENEFIT_CONFIRMATION_COPY"
          values={{ benefitName: fullName }}
        />
      );
    case ADJUST_BENEFIT_END_DATE:
      return (
        <FormattedMessage
          id="ADJUST_END_DATE_CONFIRMATION_COPY"
          values={{
            benefitName: <strong>{fullName}</strong>,
            benefitEndDate: (
              <strong>
                <FormattedDate
                  value={
                    benefit.inactive_date || benefit.parent.benefit_end_date
                  }
                  month="long"
                  year="numeric"
                  day="2-digit"
                />
              </strong>
            ),
          }}
        />
      );
    default:
      return (
        <FormattedHTMLMessage
          id="STATUS_BENEFIT_CONFIRMATION_COPY"
          values={{
            benefitName: fullName,
            benefitStatus: BENEFIT_STATUS_MAP_COPY[benefit.status],
            newStatus: BENEFIT_STATUS_MAP_COPY[confirmType],
          }}
        />
      );
  }
};

const getContentNote = confirmType => {
  switch (confirmType) {
    case BENEFIT_STATUS_OPTED_OUT:
      return <FormattedHTMLMessage id="OPTED_OUT_BENEFIT_CONFIRMATION_NOTE" />;
    case BENEFIT_STATUS_READY:
      return <FormattedHTMLMessage id="READY_BENEFIT_CONFIRMATION_NOTE" />;
    case ADJUST_BENEFIT_END_DATE:
      return <FormattedHTMLMessage id="ADJUST_END_DATE_CONFIRMATION_NOTE" />;
    default:
      return null;
  }
};

const getButtonText = confirmType => {
  switch (confirmType) {
    case REMOVE_BENEFIT:
      return <FormattedMessage id="REMOVE_BENEFIT_CONFIRMATION_REMOVE_BTN" />;
    case ADJUST_BENEFIT_END_DATE:
      return <FormattedMessage id="ADJUST_END_DATE_CONFIRMATION_CONFIRM_BTN" />;
    default:
      return <FormattedMessage id="STATUS_BENEFIT_CONFIRMATION_CONFIRM_BTN" />;
  }
};

export const ConfirmModal = ({
  confirmType,
  fullName,
  closeConfirmation,
  submitConfirmation,
  benefit,
  onDateChange,
  invalidDate,
  newEndDate,
  emptyDateError,
}) => (
  <Backdrop>
    <ConfirmationModal
      headerText={getHeaderText(confirmType, fullName)}
      footerContent={
        <Flex>
          <Button confirmModal cancel onClick={closeConfirmation}>
            <FormattedMessage id="BENEFIT_STATUS_CONFIRMATION_CANCEL_BTN" />
          </Button>
          <Button
            confirmModal
            primary={confirmType !== REMOVE_BENEFIT}
            warning={confirmType === REMOVE_BENEFIT}
            onClick={submitConfirmation}
            disabled={confirmType === ADJUST_BENEFIT_END_DATE && invalidDate}
          >
            {getButtonText(confirmType)}
          </Button>
        </Flex>
      }
    >
      <StyledModalBody>
        {getContentText(confirmType, fullName, benefit)}
      </StyledModalBody>
      <StyledModalNote>{getContentNote(confirmType)}</StyledModalNote>
      {confirmType === ADJUST_BENEFIT_END_DATE && (
        <DayPickerWrapper>
          <FormattedHTMLMessage id="SELECT_DATE" />
          <StyledDayPickerInput
            dayPickerProps={{
              enableOutsideDays: false,
            }}
            placeholder={DATE_FORMAT}
            format={DATE_FORMAT}
            value={newEndDate ? newEndDate.format(DATE_FORMAT) : ''}
            name="effectiveDate"
            onDayChange={onDateChange}
            error={invalidDate || emptyDateError}
            confirmModal
          />
          {invalidDate && (
            <ErrorMessage>
              <FormattedMessage id="ADJUST_END_DATE_ERROR" />
            </ErrorMessage>
          )}
          {emptyDateError && (
            <ErrorMessage>
              <FormattedMessage id="ADJUST_END_DATE_EMPTY_ERROR" />
            </ErrorMessage>
          )}
        </DayPickerWrapper>
      )}
    </ConfirmationModal>
  </Backdrop>
);

ConfirmModal.propTypes = {
  confirmType: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  benefit: PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  submitConfirmation: PropTypes.func.isRequired,
  closeConfirmation: PropTypes.func.isRequired,
  newEndDate: PropTypes.object /* eslint react/forbid-prop-types: 0 */,
  onDateChange: PropTypes.func.isRequired,
  invalidDate: PropTypes.bool,
  emptyDateError: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  newEndDate: null,
  invalidDate: false,
  emptyDateError: false,
};
