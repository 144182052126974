import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { pick } from 'lodash';
import { configureConnection } from './configure-connection.service';
import { request } from './request';
import {
  CONNECTION_OPENED,
  CONNECTION_CLOSED,
  CONFIGURE_CONNECTION,
  MESSAGE_SENT,
  MESSAGE_RECEIVED,
  ERROR,
} from './types';

export const messageEventAsAction = event => ({
  type: MESSAGE_RECEIVED,
  payload: JSON.parse(event.data),
});

export const actionAsMessage = action =>
  JSON.stringify(
    pick(action.payload, ['message_type', 'info', 'message_id', 'api_version']),
  );

function createSocketChannel(socket) {
  const ws = socket;
  const emitter = emit => {
    ws.onopen = () => emit({ type: CONNECTION_OPENED });
    ws.onmessage = event => emit(messageEventAsAction(event));
    ws.onclose = () => emit({ type: CONNECTION_CLOSED });
    ws.onerror = error => emit({ type: ERROR, error });
    return () => ws.close();
  };
  return eventChannel(emitter);
}

function* watchIncomingMessages(channel) {
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* watchOutgoingMessages({ socket, onBeforeSend }, action) {
  if (typeof onBeforeSend === 'function') {
    yield call(onBeforeSend, action);
  }
  const message = yield call(actionAsMessage, action);
  yield call([socket, socket.send], message);
}

export function* requestConfigureConnection() {
  yield request(CONFIGURE_CONNECTION, configureConnection());
}

const createWebSocket = (...args) => new WebSocket(...args);

/**
 * websocketSaga manages the creation and renewal of WebSocket connections,
 * maintaining at most one connection at a time. `socketChannel` is responsible
 * for translating incoming socket events to Redux actions.
 */
export function* websocketSaga({ onBeforeSend, websocketUrl } = {}) {
  yield takeLatest(CONNECTION_OPENED, requestConfigureConnection);
  // this while loop causes the saga to create a new connection when the previous one is closed
  while (true) {
    const socket = yield call(createWebSocket, websocketUrl);
    const socketChannel = yield call(createSocketChannel, socket);

    const incoming = yield fork(watchIncomingMessages, socketChannel);
    const outgoing = yield takeEvery(MESSAGE_SENT, watchOutgoingMessages, {
      socket,
      onBeforeSend,
    });

    yield take(CONNECTION_CLOSED);
    yield cancel(incoming);
    yield cancel(outgoing);
    yield call([socketChannel, socketChannel.close]);
  }
}

/**
 * @private exported for testing purposes only
 */
export const privateExports = {
  createWebSocket,
  createSocketChannel,
  watchIncomingMessages,
  watchOutgoingMessages,
};
