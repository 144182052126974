import { withStateHandlers, compose, withHandlers, withProps } from 'recompose';
import { BenefitWrapper } from './benefit-wrapper.view';
import { selectPolicyEndDate } from './benefits.selectors';
import { connect } from 'react-redux';
import { REMOVE_BENEFIT, ADJUST_BENEFIT_END_DATE } from './constants';

const withActive = withStateHandlers(({ active = false }) => ({ active }), {
  toggleActive:
    ({ active }) =>
    () => ({ active: !active }),
});

const withConfirmAction = withStateHandlers(() => ({ confirmOpen: false }), {
  showConfirmation: () => confirmType => ({
    confirmOpen: true,
    confirmType,
  }),
  closeConfirmation: () => () => ({ confirmOpen: false }),
});

const withOnStatusChange = withHandlers({
  onStatusChange:
    ({ benefit, updateBenefitStatus }) =>
    status => {
      updateBenefitStatus({ id: benefit.id, status });
    },
});

const withAddFundsConfirmation = withStateHandlers(
  () => ({ addFundsConfirmationVisible: false }),
  {
    showAddFundsConfirmation: () => () => ({
      addFundsConfirmationVisible: true,
    }),
    closeAddFundsConfirmation: () => () => ({
      addFundsConfirmationVisible: false,
    }),
  },
);

const mapStateToProps = withProps(({ benefit }) => ({
  userId: benefit.user_id,
  groupId: benefit.parent ? benefit.parent.provider_id : null,
  benefitType: benefit.parent ? benefit.parent.benefit_type : null,
  benefitStatus: benefit.status || benefit.benefit_status,
  benefitEndDate: benefit.benefit_end_date || null,
  userBenefitsView: !!benefit.parent,
  expirationDate: benefit?.fund?.expiration_date,
}));

const withConfirmModalHandler = withHandlers({
  onConfirm:
    ({
      benefit,
      confirmType,
      removeBenefit,
      onStatusChange,
      closeConfirmation,
      adjustBenefitEndDate,
      fullName,
      groupId,
      userId,
    }) =>
    ({ newEndDate }) => {
      switch (confirmType) {
        case REMOVE_BENEFIT:
          removeBenefit(benefit);
          break;
        case ADJUST_BENEFIT_END_DATE:
          adjustBenefitEndDate({
            benefitId: benefit.id,
            benefitName: fullName,
            newEndDate: newEndDate.toDate(),
            groupId,
            userId,
          });
          break;
        default:
          onStatusChange(confirmType);
      }
      closeConfirmation();
    },
});

const stateToProps = connect((state, { benefit }) => ({
  policyEndDate: benefit.parent
    ? selectPolicyEndDate(benefit.parent.id)(state)
    : null,
}));

export const BenefitWrapperContainer = compose(
  stateToProps,
  mapStateToProps,
  withConfirmAction,
  withActive,
  withOnStatusChange,
  withAddFundsConfirmation,
  withConfirmModalHandler,
)(BenefitWrapper);
