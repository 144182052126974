import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Message, Header, Accordion } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import { isEmpty } from 'lodash';
import ChangeBenefitClass from './change-benefit-class.view';
import {
  ErrorMessage,
  RemoveSuccessMessage,
  UpdateSuccessMessage,
  AddSuccessMessage,
  UpdateEmployeeClassSuccessMessage,
} from './benefits-status-messages.view';
import { FormattedMessage } from 'react-intl';
import {
  genesisStyled,
  Box,
  Flex,
  Text,
  css,
} from '@leagueplatform/genesis-commons';
import { Tooltip, TooltipContentV2 } from '@leagueplatform/ui-kit';
import { QuestionIcon } from 'apps/employer-experience/components/question-icon.component';
import GroupedBenefits from './grouped-benefits.view';
import { UpdateFlexDollars } from './update-flex-dollars';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
  ANALYTICS_EVENT_HR_ADMIN_CONTACT,
  ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
} from '../constants';

const BenefitCollection = genesisStyled.span(
  css({
    textTransform: 'capitalize',
    fontSize: '1.5rem',
    color: 'onSurface.text.subdued',
    paddingLeft: '.25rem',
  }),
);

const ExplanationLabel = genesisStyled.div`
  padding-bottom: 1rem;
`;

const ToolTipLabel = genesisStyled.span`
  font-size: 1rem;
  font-weight: 300;
`;

const TooltipContentV2Wrap = genesisStyled(Flex)`
  flex-direction: column;
  white-space: initial;
`;

const usePopupTranslatedText = () => {
  const { formatMessage } = useIntl();

  const explanationLabelText = formatMessage({ id: 'CLASSES_EXPLANATION' });
  const examplesText = formatMessage({ id: 'EXAMPLES' });
  const classesExampleText = formatMessage({ id: 'CLASSES_EXAMPLE' });

  return {
    explanationLabelText,
    examplesText,
    classesExampleText,
  };
};

const Page = ({
  classes,
  employee,
  selectedClass,
  employeeClass,
  isAdmin,
  loadingBenefits,
  groupedBenefits,
  ...pageProps
}) => {
  const link = 'help@league.com?Subject=Benefits%20change%20request';
  const activePlansIdsRef = useRef([]);
  const [isUpdateFlexDollarsModalOpen, setIsUpdateFlexDollarsModalOpen] =
    useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState();

  const openUpdateFlexDollarsModal = planId => {
    setSelectedPlanId(planId);
    setIsUpdateFlexDollarsModalOpen(true);
  };

  const { explanationLabelText, examplesText, classesExampleText } =
    usePopupTranslatedText();

  useEffect(() => {
    activePlansIdsRef.current = groupedBenefits
      .filter(({ classId }) => classId === selectedClass)
      .map(({ planId }) => planId);
  }, [groupedBenefits, selectedClass]);

  const sendAnalytics = (detail, textDetail) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
      product_area: PRODUCT_AREA.HR_ADMIN,
      sub_product_area: ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
      link: !textDetail ? link : null,
      detail,
      text_detail: textDetail,
    });
  };

  return (
    <Dimmer.Dimmable
      as={Box}
      className="container--benefits"
      pb={2}
      overflowY="auto"
    >
      {!isAdmin && (
        <Message info>
          <Message.Header>
            <FormattedMessage id="WANT_TO_MAKE_A_CHANGE" />
          </Message.Header>
          <Text>
            <FormattedMessage id="WE_CAN_HELP" />
            <a
              href={`mailto:${link}`}
              className="message-purple__link"
              onClick={() =>
                sendAnalytics(ANALYTICS_EVENT_HR_ADMIN_CONTACT, null)
              }
            >
              {' '}
              <FormattedMessage id="HELP_EMAIL" />
            </a>
          </Text>
        </Message>
      )}
      <ErrorMessage />
      <RemoveSuccessMessage />
      <UpdateSuccessMessage />
      <AddSuccessMessage />
      <UpdateEmployeeClassSuccessMessage />
      <Header as="h3" className="employee-benefit-class">
        <Flex alignItems="center">
          {employeeClass && <span>{employeeClass} </span>}
          <BenefitCollection>
            <FormattedMessage id="CHANGE_CLASS_LABEL" />
          </BenefitCollection>
          <ToolTipLabel
            onMouseEnter={() =>
              sendAnalytics('hover for info', explanationLabelText)
            }
          >
            <Box marginX="half">
              <Tooltip>
                <QuestionIcon />
                <TooltipContentV2
                  width="400px"
                  top
                  positionShift={{ x: -10, y: 30 }}
                >
                  <TooltipContentV2Wrap>
                    <ExplanationLabel>{explanationLabelText}</ExplanationLabel>
                    <strong>{examplesText}</strong>
                    <span>{classesExampleText}</span>
                  </TooltipContentV2Wrap>
                </TooltipContentV2>
              </Tooltip>
            </Box>
          </ToolTipLabel>
        </Flex>
      </Header>

      <ChangeBenefitClass
        classes={classes}
        employee={employee}
        selectedClass={selectedClass}
        isAdmin={isAdmin}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...pageProps}
      />

      {loadingBenefits ? (
        <Loader active inline />
      ) : (
        <Accordion
          styled
          fluid
          className="accordion--benefit-class accordion--grouped-benefits"
        >
          {!isEmpty(groupedBenefits) &&
            groupedBenefits.map(({ planId, ...groupedBenefitsProps }) => {
              return (
                <GroupedBenefits
                  key={planId}
                  initialActiveState={activePlansIdsRef.current.includes(
                    planId,
                  )}
                  planId={planId}
                  selectedClass={selectedClass}
                  isAdmin={isAdmin}
                  openUpdateFlexDollarsModal={openUpdateFlexDollarsModal}
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...pageProps}
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...groupedBenefitsProps}
                />
              );
            })}
        </Accordion>
      )}

      {isUpdateFlexDollarsModalOpen && (
        <UpdateFlexDollars
          planId={selectedPlanId}
          setIsModalOpen={setIsUpdateFlexDollarsModalOpen}
        />
      )}
    </Dimmer.Dimmable>
  );
};

Page.propTypes = {
  groupedBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      classId: PropTypes.string,
      className: PropTypes.string,
      planId: PropTypes.string,
      planName: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
      benefits: PropTypes.array,
    }),
  ).isRequired,
  loadingBenefits: PropTypes.bool.isRequired,
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  selectedClass: PropTypes.string,
  employeeClass: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  featureFlags: PropTypes.shape({
    topUpSpendingAccount: PropTypes.bool,
  }),
  employee: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
};

Page.defaultProps = {
  selectedClass: '',
  employeeClass: '',
  featureFlags: {
    topUpSpendingAccount: false,
  },
  employee: {
    first_name: '',
    last_name: '',
  },
};

export default Page;
