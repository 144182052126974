import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik-legacy';
import { Button } from 'semantic-ui-react';
import {
  createFormGenerator,
  createFormValueFormatter,
  createValidator,
  combineValidators,
} from 'common/adaptive-forms';
import { submitForm } from './adjustments.actions';
import layout from './add-edit-adjustment.layout';
import schema from './add-edit-adjustment.schema';
import { FormattedMessage } from 'react-intl';
import SelectBillingDivision from 'apps/employer-experience/pages/EmployerDetails/Billing/components/SelectBillingDivision';
import { selectBillingDivisionValidator } from 'apps/employer-experience/pages/EmployerDetails/Billing/components/SelectBillingDivision/utilities';
import { SUBMIT_ATTEMPTED } from 'common/adaptive-forms/constants';
import { selectGroupBillingPreference } from './adjustments.selectors';
import { DEFAULT_BILLING_DIVISION_ID } from 'apps/employer-experience/pages/EmployerDetails/Billing/constants';
import { get } from 'lodash';

const Submit = ({ loading, setStatus }) => (
  <Button
    type="submit"
    value="Submit"
    onClick={() => setStatus(SUBMIT_ATTEMPTED)}
    color="violet"
    loading={loading}
  >
    <FormattedMessage id="MAKE_ADJUSTMENT" />
  </Button>
);

Submit.propTypes = {
  loading: PropTypes.bool,
  setStatus: PropTypes.func.isRequired,
};

Submit.defaultProps = {
  loading: false,
};

const withFormikEnhancer = Formik({
  validate: (values, props) =>
    combineValidators(
      props.validate,
      selectBillingDivisionValidator(props),
    )(values),
  mapPropsToValues: props => ({ group_id: props.groupId, ...props.formValues }),
  handleSubmit: (values, { props }) =>
    props.submitForm({
      ...values,
      billing_division_id: get(
        values,
        'billing_division_id',
        DEFAULT_BILLING_DIVISION_ID,
      ),
    }),
});

const mapStateToProps = state => ({
  generateForm: createFormGenerator({
    schema,
    layout,
    customComponents: {
      submit: Submit,
      billing_division_id: SelectBillingDivision,
    },
  }),
  formValues: createFormValueFormatter(schema)({}),
  validate: createValidator(schema),
  billingPreference: selectGroupBillingPreference(state),
});

const AddEditAdjustment = compose(
  connect(mapStateToProps, { submitForm }),
  withFormikEnhancer,
)(props => props.generateForm(props));

export default AddEditAdjustment;
