import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { isNil } from 'lodash';

// This is a temporary import measure until after the genesis sprint
import { InputField } from 'apps/employer-experience/ui-kit/form-fields';

import { spaceInPx, Heading } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { useIntl } from '@leagueplatform/locales';
import { US, CA } from 'common/state/user/country-codes.constants';
import { Divider } from '../../components/divider.view';

import { MaskedNINField } from './masked-nin-field';

const SubmitButton = styled(Button)`
  margin-left: ${spaceInPx(2)};
`;

const SectionHeading = styled(Heading)`
  margin-bottom: 0;
`;

export const DependentForm = ({
  initialValues,
  onSubmit,
  onCancel,
  fields,
  loading,
  countryCode,
}) => {
  const { formatMessage } = useIntl();
  const mandatoryChildFields = ['over_age_disabled', 'student'];

  const validate = values => {
    const errors = {};
    const isSpouse = values.relationship_type === 'spouse';
    const isChild = values.relationship_type === 'child';
    const requiresTobacco =
      (isSpouse && countryCode === CA) || countryCode === US;

    Object.keys(fields).forEach(name => {
      const field = fields[name];
      const hasEmptyValue = isNil(values[name]);
      let showError = false;

      if (name === 'tobacco_user' && hasEmptyValue && requiresTobacco) {
        showError = true;
      }
      if (mandatoryChildFields.includes(name) && isChild && hasEmptyValue) {
        showError = true;
      }
      if (field.required && hasEmptyValue) {
        showError = true;
      }

      if (showError) {
        errors[name] = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      }
    });

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        setFieldValue,
        setFieldError,
        submitCount,
      }) => {
        const hasSubmitted = submitCount > 0;
        const isSpouse = values.relationship_type === 'spouse';
        const requiresTobacco =
          (isSpouse && countryCode === CA) || countryCode === US;

        return (
          <form onSubmit={handleSubmit}>
            <Flex wrap>
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.first_name}
                error={
                  (hasSubmitted || touched.first_name) && errors.first_name
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.last_name}
                error={(hasSubmitted || touched.last_name) && errors.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.date_of_birth}
                error={
                  (hasSubmitted || touched.date_of_birth) &&
                  errors.date_of_birth
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.gender}
                error={(hasSubmitted || touched.gender) && errors.gender}
                onChange={handleChange}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.email}
                error={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <MaskedNINField
                value={values.national_identification_number}
                name="national_identification_number"
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                error={errors.national_identification_number}
                touched={touched.national_identification_number}
                setFieldError={setFieldError}
                omitCheckedValue={values.omit_national_identification_number}
                dependentId={initialValues.dependent_id}
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.national_identification_number}
              />
            </Flex>

            <SectionHeading level="4">
              {formatMessage({ id: 'DEPENDENT_DETAILS' })}
            </SectionHeading>
            <Divider bottomBorder pt={1} />
            <Flex wrap>
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.relationship_type}
                error={
                  (hasSubmitted || touched.relationship_type) &&
                  errors.relationship_type
                }
                onChange={handleChange}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.partnership_effective_date}
                error={
                  (hasSubmitted || touched.partnership_effective_date) &&
                  errors.partnership_effective_date
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {requiresTobacco && (
                <InputField
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...fields.tobacco_user}
                  error={
                    (hasSubmitted || touched.tobacco_user) &&
                    errors.tobacco_user
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
              {values.relationship_type === 'child' && (
                <>
                  <InputField
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...fields.student}
                    error={touched.student && errors.student}
                    onChange={handleChange}
                  />
                  <InputField
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...fields.over_age_disabled}
                    error={
                      touched.over_age_disabled && errors.over_age_disabled
                    }
                    onChange={handleChange}
                  />
                  <InputField
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...fields.child_of_domestic_partner}
                    error={
                      touched.child_of_domestic_partner &&
                      errors.child_of_domestic_partner
                    }
                    onChange={handleChange}
                  />
                </>
              )}
            </Flex>
            <Flex wrap>
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.add_qualifying_life_event_reason}
                error={
                  touched.add_qualifying_life_event_reason &&
                  errors.add_qualifying_life_event_reason
                }
                onChange={handleChange}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.add_qualifying_life_event_date}
                error={
                  touched.add_qualifying_life_event_date &&
                  errors.add_qualifying_life_event_date
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Flex>
            <Flex wrap>
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.verification_status}
                error={
                  touched.verification_status && errors.verification_status
                }
                onChange={handleChange}
              />
              <InputField
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...fields.verification_status_effective_date}
                error={
                  touched.verification_status_effective_date &&
                  errors.verification_status_effective_date
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Flex>
            <Flex justify="flex-end" mt={3}>
              <Button type="button" onClick={onCancel}>
                {formatMessage({ id: 'CANCEL' })}
              </Button>
              <SubmitButton type="submit" primary disabled={loading}>
                {initialValues.dependent_id
                  ? formatMessage({ id: 'UPDATE' })
                  : formatMessage({ id: 'ADD_DEPENDENT' })}
              </SubmitButton>
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
};

DependentForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  initialValues: PropTypes.shape(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  fields: PropTypes.shape(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
};

DependentForm.defaultProps = {
  initialValues: {},
};
