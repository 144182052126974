import {
  call,
  takeLatest,
  fork,
  select,
  put,
  all,
  takeEvery,
} from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { get } from 'lodash';
import {
  EMPLOYER_INVOICES_LIFECYCLE,
  EMPLOYER_INVOICES_INIT,
  DOWNLOAD_INVOICE_CSV,
  INVOICE_DRY_RUN,
  REQUEST_INVOICE_DRY_RUN,
  SAVE_INVOICE_STATUS,
  DOWNLOAD_EXTERNAL_INVOICE,
} from './invoices.types';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import { ANALYTICS } from 'common/constants';
import { getDryRun } from 'common/services';
import { HR_EMPLOYER_OUTSTANDING_BALANCE } from 'apps/employer-experience/pages/EmployerDetails/Billing/types';
import { hrGetEmployerOutstandingBalance } from 'apps/employer-experience/pages/EmployerDetails/Billing/saga';
import { selectTouchedInvoices } from './invoices.selectors';
import { getExternalInvoiceFile } from 'common/services/get-external-invoice-file.service';
import { getGroupChargeInvoices } from 'common/services/get-group-charge-invoices.service';
import { INVOICE_INVOICED_STATE } from 'apps/employer-experience/pages/EmployerDetails/Billing/constants';
import { GET_GROUP } from 'apps/employer-experience/pages/EmployerDetails/types';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* initialize({ payload }) {
  try {
    yield put({ type: EMPLOYER_INVOICES_INIT.STARTED });
    let groupId = yield select(selectCurrentUserGroupId);
    if (payload.props.groupId) {
      groupId = payload.props.groupId;
    }
    const [groupResponse, invoicesResponse] = yield all([
      request(GET_GROUP, [websocketFetch, 'get_group', { group_id: groupId }]),
      getGroupChargeInvoices({
        version: 2,
        group_id: groupId,
      }),
    ]);

    yield put({
      type: EMPLOYER_INVOICES_INIT.SUCCEEDED,
      payload: {
        group: groupResponse?.info,
        invoices: invoicesResponse?.info?.invoices,
      },
    });
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_STATE_MESSAGE',
      values: { error },
    });
    yield put({ type: EMPLOYER_INVOICES_INIT.ERRORED, error });
  }
}

export function* downloadInvoiceCsv() {
  yield call(sendAnalyticsEvent, {
    category: ANALYTICS.categories.INVOICES,
    action: 'Download Invoice CSV',
  });
}

export function* invoiceDryRun({ payload }) {
  try {
    yield put(INVOICE_DRY_RUN.start());
    const response = yield getDryRun(payload);
    const invoices = get(response, 'info.invoices');
    yield put(
      INVOICE_DRY_RUN.success(
        invoices?.map(invoice => ({
          invoice_id: 'Dry Run',
          invoice_number: 'Dry Run',
          invoice_date: invoice.timestamp,
          invoice_currency: invoice.currency,
          invoice_amount: invoice.total_amount ?? 0,
          invoice_provider: 'league',
          invoice_type: 'invoice',
          invoice_state: invoice.state ?? INVOICE_INVOICED_STATE,
          billing_division_name: invoice.billing_division_name,
          billing_period: '-',
          content_references: invoice.content_references,
        })),
      ),
    );
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'DRY_RUN_ERROR',
    });
    yield put(INVOICE_DRY_RUN.error());
  }
}

export function* saveInvoiceStatus() {
  const invoices = yield select(selectTouchedInvoices);
  yield all(
    invoices.map(invoice =>
      call(websocketFetch, 'set_group_invoice_state', {
        invoice_id: invoice.invoice_id,
        state: invoice.invoice_state,
      }),
    ),
  );
  yield put({
    type: SAVE_INVOICE_STATUS.SUCCEEDED,
  });
}

function downloadPDF(fileName, contents) {
  const linkSource = `data:application/pdf;base64,${contents}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function* downloadExternalInvoiceFile({ payload }) {
  try {
    yield put(DOWNLOAD_EXTERNAL_INVOICE.start());
    const response = yield getExternalInvoiceFile(payload);
    yield put(DOWNLOAD_EXTERNAL_INVOICE.success(payload));
    downloadPDF(response.info.file.name, response.info.file.contents);
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_STATE_MESSAGE',
      values: { error },
    });
    yield put(DOWNLOAD_EXTERNAL_INVOICE.error(payload));
  }
}

export function* employerInvoicesSagas() {
  yield fork(takeLatest, EMPLOYER_INVOICES_LIFECYCLE.VISITED, initialize);
  yield fork(takeLatest, REQUEST_INVOICE_DRY_RUN, invoiceDryRun);
  yield fork(takeLatest, DOWNLOAD_INVOICE_CSV, downloadInvoiceCsv);
  yield fork(takeLatest, SAVE_INVOICE_STATUS.BASE, saveInvoiceStatus);
  yield fork(
    takeLatest,
    HR_EMPLOYER_OUTSTANDING_BALANCE,
    hrGetEmployerOutstandingBalance,
  );
  yield fork(
    takeEvery,
    DOWNLOAD_EXTERNAL_INVOICE.BASE,
    downloadExternalInvoiceFile,
  );
}
