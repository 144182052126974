import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from '@leagueplatform/genesis-commons';
import { Spinner } from '@leagueplatform/ui-kit';

export const LoadingSecondaryButton = ({ children, isLoading, ...props }) => {
  const [minWidth, setMinWidth] = useState(190);
  const buttonRef = useRef();

  useLayoutEffect(() => {
    const buttonWidth = buttonRef?.current?.clientWidth;
    if (!minWidth || minWidth < buttonWidth) setMinWidth(buttonWidth);
  }, [buttonRef, setMinWidth, minWidth]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <SecondaryButton {...props} ref={buttonRef} minWidth={190}>
      {isLoading ? <Spinner size="oneAndHalf" /> : children}
    </SecondaryButton>
  );
};

LoadingSecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

LoadingSecondaryButton.defaultProps = {
  isLoading: false,
};
