import React from 'react';
import { uniqueId } from 'lodash';

// All our IDs are this length.
// An extra check to avoid sending typos to the backend if the user copied a partial ID.
const standardGUIDLength = 32;
export const isGuidLength = string => string.length === standardGUIDLength;

// For the most generalized success message, take api response message type and format like a sentence
export const snakeCaseToSentence = string => {
  const sentence =
    string[0].toUpperCase() + string.slice(1).split('_').join(' ');
  return `${sentence}.`;
};

// Snake case to string for keys in returned objects
export const snakeCaseToString = string => string.split('_').join(' ');

// Tool to pick up any object and display it as a list with bolder keys and regular weight values
// Display only keys that have values

export const objectToHtmlList = obj => {
  return Object.keys(obj)
    .sort()
    .map(key => {
      const val = obj[key];
      return (
        <li key={uniqueId()}>
          <strong>{`${snakeCaseToString(key)}: `}</strong>
          <span>{val || '(no value returned)'}</span>
        </li>
      );
    });
};
