import {
  REQUEST_CLEAR_INVOICING_DIAGNOSTICS,
  REQUEST_GET_INVOICING_DIAGNOSTICS,
  REQUEST_GET_NETSUITE_INVOICE_REPORT,
} from './finance-invoicing.types';

export const getInvoicingDiagnostics = payload => ({
  type: REQUEST_GET_INVOICING_DIAGNOSTICS,
  payload,
});

export const clearInvoicingDiagnostics = () => ({
  type: REQUEST_CLEAR_INVOICING_DIAGNOSTICS,
});

export const getNetSuiteInvoiceReport = payload => ({
  type: REQUEST_GET_NETSUITE_INVOICE_REPORT,
  payload,
});
