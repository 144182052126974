import { takeLatest, all, fork, call } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  getWallet as _getWallet,
  getWalletDependentCards as _getWalletDependentCards,
} from 'common/services';
import { receiptSaga } from './receipt/receipt.saga';
import {
  GET_WALLET,
  GET_WALLET_DEPENDENT_CARDS,
  LOAD_WALLET,
  LOAD_WALLET_DEPENDENT_CARDS,
  REDEEM_PROMO_CODE,
  PROMO_CODE_REDEEMED,
  GET_LEAGUE_CREDIT_BALANCE,
  LOAD_LEAGUE_CREDIT_BALANCE,
  EMAIL_CUSTOMER_SUPPORT,
} from './wallet.action-types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getWallet({ userId }) {
  yield request(GET_WALLET, _getWallet({ userId }));
}

export function* getWalletDependentCards({ payload: { userId } }) {
  yield request(
    GET_WALLET_DEPENDENT_CARDS,
    _getWalletDependentCards({ userId }),
  );
}

export function* redeemPromoCode({ code }) {
  yield request(REDEEM_PROMO_CODE, [
    websocketFetch,
    'redeem_promo_code',
    { code },
  ]);
}

export function* getLeagueCreditBalance({ userId }) {
  yield request(GET_LEAGUE_CREDIT_BALANCE, [
    websocketFetch,
    'get_user_store_credit_balance',
    { user_id: userId },
  ]);
}

export function* emailCustomerSupport({ cardType, userId }) {
  try {
    const text = `My ${cardType} card is not activated - UserID ${userId}`;
    const supportUrl = `mailto:support@league.com?subject=${encodeURIComponent(
      text,
    )}`;
    window.location.href = supportUrl;
    yield supportUrl;
  } catch (e) {
    throw new Error(e);
  }
}

export function* addSuccessMessage() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'PROMO_CODE_SUCCESS',
  });
}

export function* addErrorMessage() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'PROMO_CODE_ERRORED',
  });
}

export function* walletSagas() {
  yield fork(all, [
    takeLatest([LOAD_WALLET, REDEEM_PROMO_CODE.SUCCEEDED], getWallet),
    takeLatest(PROMO_CODE_REDEEMED, redeemPromoCode),
    takeLatest(LOAD_WALLET_DEPENDENT_CARDS, getWalletDependentCards),
    takeLatest(LOAD_LEAGUE_CREDIT_BALANCE, getLeagueCreditBalance),
    takeLatest(EMAIL_CUSTOMER_SUPPORT, emailCustomerSupport),
    takeLatest(REDEEM_PROMO_CODE.SUCCEEDED, addSuccessMessage),
    takeLatest(REDEEM_PROMO_CODE.ERRORED, addErrorMessage),
    receiptSaga(),
  ]);
}
