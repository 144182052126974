import { call, fork, put, takeLatest, select } from 'redux-saga/effects';
import cookie from 'react-cookies';
import {
  websocketFetch,
  CONFIGURE_CONNECTION,
  AUTHENTICATE_CONNECTION,
  request,
} from 'common/websocket-redux';
import { selectIsInWidgetRunner } from 'common/state/application/application.selectors';
import { setURLToRedirectAfterLogin, getSignInUrl } from 'apps/auth/auth.utils';
import { REDIRECT_TO_SIGN_IN, SIGN_OUT } from '../auth.actions';
import { loadAuthCookies } from './load-auth-cookies';
import { cancelIdleTimer } from 'common/privacy-lock/idle-timer';

const OLD_IDLE_TIME_COOKIE_KEY = 'idle-time-react';

export function* redirectToSignIn(action) {
  const { timeout } = action?.meta?.payload ?? {};
  const isInWidgetRunnerFrame = yield select(selectIsInWidgetRunner);
  const signInUrl = yield call(getSignInUrl, { timeout });

  yield call([cookie, cookie.remove], OLD_IDLE_TIME_COOKIE_KEY);
  if (!isInWidgetRunnerFrame) {
    yield call(setURLToRedirectAfterLogin);
  }
  // uses window.top to ensure WidgetRunner is sent to sign-in
  yield call([window.top.location, window.top.location.assign], signInUrl);
}

export function* authenticate(sessionId, deviceToken) {
  yield request(AUTHENTICATE_CONNECTION, [
    websocketFetch,
    'authenticate',
    {
      session_id: sessionId,
      platform: 'web-tng',
      device_token: deviceToken,
    },
  ]);
}

export function* authenticateWithExistingSession() {
  const { sessionId, deviceToken } = loadAuthCookies();
  if (sessionId && deviceToken) {
    yield call(authenticate, sessionId, deviceToken);
  } else {
    yield put({ type: AUTHENTICATE_CONNECTION.ERRORED });
  }
}

export function* signOut(signOutSelectedAction) {
  yield put(SIGN_OUT.start());
  try {
    const response = yield call(websocketFetch, 'sign_out');
    cookie.remove('SID');
    cookie.remove('SID2');
    cookie.remove('U');
    cookie.remove('R');
    cookie.remove('DISTRICT');
    cookie.remove('employer-admin');
    cookie.remove('SUB_R');
    cancelIdleTimer();
    yield put(SIGN_OUT.success(response, signOutSelectedAction));
  } catch (error) {
    yield put(SIGN_OUT.error(error));
  }
}

export function* authSaga() {
  yield fork(
    takeLatest,
    CONFIGURE_CONNECTION.SUCCEEDED,
    authenticateWithExistingSession,
  );
  yield fork(
    takeLatest,
    [REDIRECT_TO_SIGN_IN, AUTHENTICATE_CONNECTION.ERRORED],
    redirectToSignIn,
  );
  yield fork(takeLatest, SIGN_OUT.BASE, signOut);
  yield fork(takeLatest, SIGN_OUT.SUCCEEDED, redirectToSignIn);
}
