import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Benefits from 'apps/employer-experience-v2/employee-benefits/page.container';
import { EmployeeUserDocuments } from './employee-user-documents/employee-user-documents.view';
import { EmployeeUserForms } from './employee-user-forms/employee-user-forms.view';
import { CoverageSummary } from './coverage-summary/coverage-summary.container';
import EditEmployeeForm from './EditEmployeeForm';
import { EmployeeDependentsRouter } from './employee-dependents/employee-dependents-router.view';
import styled from 'styled-components';
import { Box } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import { EmployeeDeductions } from './employee-deductions/employee-deductions.container';
import { PayrollDeductions } from './payroll-deductions/payroll-deductions.container';
import { PlanEnrollment } from './plan-enrollment/plan-enrollment.container';
import { CertificationCredits } from './certification-credits/medical-wavier.component';

const MinHeightBox = styled(Box)`
  min-height: 300px;
`;

export const EmployeeRoutes = ({
  employeeBaseUrl,
  employeeUserId,
  groupId,
}) => (
  <Switch>
    <Route path={`${employeeBaseUrl}/profile`} component={EditEmployeeForm} />
    <Route path={`${employeeBaseUrl}/benefits`} component={Benefits} />
    <Route
      path={`${employeeBaseUrl}/documents`}
      render={() => <EmployeeUserDocuments userId={employeeUserId} />}
    />
    <Route
      path={`${employeeBaseUrl}/forms`}
      render={() => (
        <EmployeeUserForms userId={employeeUserId} groupId={groupId} />
      )}
    />
    <Route
      path={`${employeeBaseUrl}/summary/:planId?`}
      render={() => (
        <MinHeightBox>
          <CoverageSummary
            groupId={groupId}
            userId={employeeUserId}
            employeeBaseUrl={employeeBaseUrl}
          />
        </MinHeightBox>
      )}
    />
    <Route
      path={`${employeeBaseUrl}/dependents`}
      render={() => (
        <EmployeeDependentsRouter groupId={groupId} userId={employeeUserId} />
      )}
    />
    <Route
      path={`${employeeBaseUrl}/tasa-contributions`}
      render={() => (
        <EmployeeDeductions employeeGroupId={groupId} userId={employeeUserId} />
      )}
    />
    <Route
      path={`${employeeBaseUrl}/payroll-deductions`}
      render={() => (
        <PayrollDeductions employeeGroupId={groupId} userId={employeeUserId} />
      )}
    />
    <Route
      path={`${employeeBaseUrl}/credits`}
      render={() => (
        <CertificationCredits
          employeeGroupId={groupId}
          userId={employeeUserId}
        />
      )}
    />

    <Route
      path={`${employeeBaseUrl}/plan-enrollment/:planId?`}
      render={() => (
        <PlanEnrollment
          employeeGroupId={groupId}
          userId={employeeUserId}
          employeeBaseUrl={employeeBaseUrl}
        />
      )}
    />
    {/* eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue */}
    <Route component={() => <Redirect to={`${employeeBaseUrl}/profile`} />} />
  </Switch>
);

EmployeeRoutes.propTypes = {
  employeeBaseUrl: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  employeeUserId: PropTypes.string.isRequired,
};
