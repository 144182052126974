import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Header, Form } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import ErrorFormField from 'common/adaptive-forms/forms/components/ErrorFormField';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.resetUpdateStatus('contact');
  }

  handleChange(name, value) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('changed', true);
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue(name, value);
  }

  render() {
    const { values, handleBlur, handleSubmit, updateStatus, errors, touched } =
      this.props;
    const buttonMessage = () => {
      switch (updateStatus.contact) {
        case 'start':
          return 'SAVING';
        case 'success':
          return 'CONTACT_SAVED';
        case 'error':
          return 'CONTACT_ERRORED';
        default:
          return 'SAVE_CONTACT';
      }
    };
    return (
      <Box>
        <Header as="h4">
          <FormattedMessage id="CONTACT" />
        </Header>
        <Form onSubmit={handleSubmit}>
          <Form.Field width={8}>
            <ErrorFormField
              fieldName="billing_email"
              errors={errors}
              touched={touched}
            >
              <Form.Input
                label="Email"
                name="billing_email"
                value={values.billing_email}
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                onBlur={handleBlur}
              />
            </ErrorFormField>
          </Form.Field>
          <Form.Button
            type="submit"
            value="Submit"
            color={updateStatus.contact === 'error' ? 'red' : 'violet'}
            // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
            disabled={!values.changed}
            loading={updateStatus.contact === 'start'}
          >
            <FormattedMessage id={buttonMessage()} />
          </Form.Button>
        </Form>
      </Box>
    );
  }
}

Contact.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetUpdateStatus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  values: PropTypes.shape({
    billing_email: PropTypes.string.isRequired,
  }).isRequired,
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateStatus: PropTypes.shape({
    payment: PropTypes.string,
    fees: PropTypes.string,
    prepayment: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
};

Contact.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  values: {},
};

export default Contact;
