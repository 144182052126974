export default {
  leagueHSA: {
    name: 'leagueHSA',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Allocation Settings',
        fields: [
          {
            name: 'benefit.allocation_frequency',
            span: 6,
          },
          {
            name: 'benefit.prorate_late_enrollment',
            span: 6,
          },
          {
            name: 'benefit.funds_grant_at_activation_date',
            span: 12,
          },
          {
            name: 'benefit.funds_grant_start_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Total Policy Allocations',
        fields: [
          {
            name: 'dependent_class_coverage',
            span: 12,
          },
          {
            name: 'benefit.dependent_class_amounts',
            span: 12,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'benefit_waiting_period_months',
            span: 6,
          },
          {
            name: 'opt_out',
            span: 6,
          },
          {
            name: 'prevent_default_allocation',
            span: 6,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'benefit.rollover_policy',
            span: 6,
          },
          {
            name: 'auto_renew',
            span: 6,
          },
          {
            name: 'benefit.rollover_maximum',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'id',
            span: 4,
          },
          {
            name: 'benefit_vendor_id',
            span: 4,
          },
          {
            name: 'benefit_type',
            span: 4,
          },
          {
            name: 'benefit_product_type',
            span: 4,
          },
          {
            name: 'benefit_status',
            span: 4,
          },
          {
            name: 'provider_type',
            span: 4,
          },
          {
            name: 'provider_id',
            span: 4,
          },
          {
            name: 'renewed_from',
            span: 4,
          },
          {
            name: 'plan_id',
            span: 4,
          },
          {
            name: 'benefit.vendor_class_reference.vendor_id',
            span: 12,
          },
          {
            name: 'benefit.vendor_class_reference.external_id',
            span: 12,
          },
          {
            name: 'benefit.vendor_class_reference.external_parent_id',
            span: 12,
          },
        ],
      },
    ],
  },
};
