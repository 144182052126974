export default {
  name: 'divisionsForm',
  groups: [
    {
      title: 'Add Billing Division',
      fields: [
        {
          name: 'name',
          span: 10,
        },
      ],
    },
  ],
};
