import { get } from 'lodash';
import { GET_EMPLOYEE_USER_PROFILE } from './profile.types';

export const initialState = {
  loading: true,
  error: null,
};

const applyGetUserStarted = state => ({
  ...state,
  loading: true,
});

const applyGetUserErrored = (state, action) => ({
  ...state,
  loading: false,
  error: get(action, 'payload.info.reason'),
});

const applyGetUserSucceeded = state => ({
  ...state,
  loading: false,
  error: null,
});

export const employeeProfileUIReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMPLOYEE_USER_PROFILE.STARTED:
      return applyGetUserStarted(state, action);
    case GET_EMPLOYEE_USER_PROFILE.SUCCEEDED:
      return applyGetUserSucceeded(state, action);
    case GET_EMPLOYEE_USER_PROFILE.ERRORED:
      return applyGetUserErrored(state, action);
    default:
      return state;
  }
};
