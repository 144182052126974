import { useState, useCallback } from 'react';

export const useActionsDropdown = () => {
  const [actionEffectiveDate, setActionEffectiveDate] = useState(null);
  const [reasonForTermination, setReasonForTermination] = useState(null);
  const [noticeDate, setActionNoticeDate] = useState(null);
  const [eobDate, setActionEOBDate] = useState(null);
  const [addFundsConfirmationVisible, setAddFundsConfirmationVisible] =
    useState(false);
  const [actionFormType, setActionFormType] = useState(null);
  const [actionsConfirmationVisible, setActionsConfirmationVisible] =
    useState(false);
  const [
    resetUserBenefitsConfirmationVisible,
    setResetUserBenefitsConfirmationVisible,
  ] = useState(false);
  const [initiateQLEConfirmationVisible, setInitiateQLEConfirmationVisible] =
    useState(false);

  const showActionsConfirmation = useCallback(actionFormTypeProp => {
    setActionsConfirmationVisible(true);
    setActionFormType(actionFormTypeProp);
  }, []);
  const hideActionsConfirmation = useCallback(() => {
    setActionsConfirmationVisible(false);
    setActionEffectiveDate('');
    setReasonForTermination('');
    setActionNoticeDate('');
    setActionEOBDate('');
  }, []);

  return {
    actionEffectiveDate,
    reasonForTermination,
    noticeDate,
    eobDate,
    addFundsConfirmationVisible,
    actionFormType,
    actionsConfirmationVisible,
    resetUserBenefitsConfirmationVisible,
    initiateQLEConfirmationVisible,
    setEffectiveDate: setActionEffectiveDate,
    setReason: setReasonForTermination,
    setNoticeDate: setActionNoticeDate,
    setEOBDate: setActionEOBDate,
    addFundsConfirmation: setAddFundsConfirmationVisible,
    showActionsConfirmation,
    hideActionsConfirmation,
    resetUserBenefitsConfirmation: setResetUserBenefitsConfirmationVisible,
    initiateQLEConfirmation: setInitiateQLEConfirmationVisible,
  };
};
