import { all, takeLatest, put, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import {
  getBenefitPlanOptionsSchema,
  getBenefitPlanOptions,
  setBenefitPlanOptions,
  setBenefitPlanEnrollment,
  validateBenefitPlanConfig,
} from 'common/services/enrollment';
import { getEmployerBenefitPlan } from 'common/services/get-employer-benefit-plan.service';
import { getEmployerBenefitPlans } from 'common/services/get-employer-benefit-plans.service';
import {
  ENROLLMENT_CONFIG_VISITED,
  GET_BENEFIT_PLAN_OPTIONS_SCHEMA,
  FETCH_BENEFIT_PLAN_OPTIONS,
  GET_BENEFIT_PLAN_OPTIONS,
  SET_BENEFIT_PLAN_OPTIONS,
  CREATE_BENEFIT_PLAN_OPTIONS,
  UPLOAD_FORM_DOCUMENTS,
  SET_CURRENT_PLAN_ID,
  BENEFIT_PLAN_OPTIONS_NOT_FOUND,
  START_ENROLLMENT,
  VALIDATE_BENEFIT_PLAN_CONFIG,
} from './enrollment-config.types';
import {
  GET_EMPLOYER_BENEFIT_PLAN,
  GET_EMPLOYER_BENEFIT_PLANS,
} from '../Benefits/types';
import { upload } from 'apps/upload/upload.saga';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchBenefitPlanOptionsSchema({ payload } = {}) {
  yield request(
    GET_BENEFIT_PLAN_OPTIONS_SCHEMA,
    getBenefitPlanOptionsSchema(payload),
    payload,
  );
}

export function* fetchBenefitPlanOptions({ payload } = {}) {
  yield request(
    GET_BENEFIT_PLAN_OPTIONS,
    getBenefitPlanOptions(payload),
    payload,
  );
}

export function* refetchBenefitPlanOptions({ meta } = {}) {
  const { plan_id: planId } = meta;
  yield all([
    request(GET_BENEFIT_PLAN_OPTIONS, getBenefitPlanOptions({ planId }), meta),
    request(
      GET_EMPLOYER_BENEFIT_PLAN,
      getEmployerBenefitPlan({ planId }),
      meta,
    ),
  ]);
}

export function* setBenefitPlanOptionsSaga({ payload } = {}) {
  const response = yield request(
    SET_BENEFIT_PLAN_OPTIONS,
    setBenefitPlanOptions(payload),
    payload,
  );

  const toastMessage = response?.error
    ? {
        type: TOAST_STATUS.ERROR,
        textId: 'PLAN_OPTIONS_SAVE_ERROR',
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        textId: 'PLAN_OPTIONS_SAVED_SUCCESS',
      };

  yield call(toastActions.add, toastMessage);
}

export function* createBenefitPlanOptions({ payload } = {}) {
  const { planId } = payload;
  yield request(
    CREATE_BENEFIT_PLAN_OPTIONS,
    setBenefitPlanOptions({
      planId,
      bundles: [
        {
          name: 'Default',
        },
      ],
    }),
    {
      plan_id: planId,
    },
  );
}

export function* getBenefitPlanOptionsErrored({ payload, meta } = {}) {
  const {
    info: { reason },
  } = payload;
  const { planId } = meta;
  const notFoundRegex = new RegExp(`plan ${planId}:.*not found`);
  if (notFoundRegex.test(reason)) {
    yield put({
      type: BENEFIT_PLAN_OPTIONS_NOT_FOUND,
      payload: {
        planId,
      },
    });
  }
}

export function* uploadFormDocuments({ payload }) {
  try {
    const { file, onUploadSuccess } = payload;
    yield put(UPLOAD_FORM_DOCUMENTS.start());
    // The upload saga takes an array of files and returns an array of contentIds
    const contentIds = yield call(upload, [file]);
    const referenceId = contentIds?.[0];
    if (referenceId?.length) {
      yield call(onUploadSuccess, {
        title: payload.file.name,
        content_id: referenceId,
        content_type: payload.file.type,
        url: getContentUrl(referenceId),
      }); // TODO: passing a callback via an action is a redux anti-pattern, JEFF - love, me (Jeff)
      yield put(UPLOAD_FORM_DOCUMENTS.success(contentIds));
      yield call(toastActions.add, {
        type: TOAST_STATUS.SUCCESS,
        textId: 'UPLOADING_ENROLLMENT_DOCUMENT_SUCCEEDED',
      });
    } else {
      throw new Error('Error saving document');
    }
  } catch (error) {
    yield put(UPLOAD_FORM_DOCUMENTS.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_UPLOADING_ENROLLMENT_DOCUMENT',
    });
  }
}

export function* fetchEmployerBenefitPlans({ payload }) {
  yield request(
    GET_EMPLOYER_BENEFIT_PLANS,
    getEmployerBenefitPlans(payload),
    payload,
  );
}

export function* requestStartEnrollment({ payload }) {
  const response = yield request(
    START_ENROLLMENT,
    setBenefitPlanEnrollment(payload),
    {
      plan_id: payload?.planId,
    },
  );

  const toast = response?.error
    ? {
        type: TOAST_STATUS.ERROR,
        textId: 'START_ENROLLMENT_SERVER_ERROR',
        values: {
          reason: response?.payload?.info?.reason ?? 'no reason provided',
        },
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        textId: 'ENROLLMENT_STARTED_SUCCESSFULLY',
      };

  yield call(toastActions.add, toast);
}

export function* validateEmployerBenefitPlanConfigs(action) {
  const plans = action?.payload?.info?.plans ?? [];
  yield all(
    plans.map(({ id: planId }) =>
      request(
        VALIDATE_BENEFIT_PLAN_CONFIG,
        validateBenefitPlanConfig({ planId }),
        { planId },
      ),
    ),
  );
}

export function* enrollmentConfigSaga() {
  yield takeLatest(
    [ENROLLMENT_CONFIG_VISITED, SET_CURRENT_PLAN_ID],
    fetchBenefitPlanOptionsSchema,
  );
  yield takeLatest(UPLOAD_FORM_DOCUMENTS.BASE, uploadFormDocuments);
  yield takeLatest(FETCH_BENEFIT_PLAN_OPTIONS, fetchBenefitPlanOptions);
  yield takeLatest(SET_BENEFIT_PLAN_OPTIONS.BASE, setBenefitPlanOptionsSaga);
  yield takeLatest(
    [
      SET_BENEFIT_PLAN_OPTIONS.SUCCEEDED,
      CREATE_BENEFIT_PLAN_OPTIONS.SUCCEEDED,
      START_ENROLLMENT.SUCCEEDED,
    ],
    refetchBenefitPlanOptions,
  );
  yield takeLatest(
    GET_BENEFIT_PLAN_OPTIONS.ERRORED,
    getBenefitPlanOptionsErrored,
  );
  yield takeLatest(CREATE_BENEFIT_PLAN_OPTIONS.BASE, createBenefitPlanOptions);
  yield takeLatest(GET_EMPLOYER_BENEFIT_PLANS.BASE, fetchEmployerBenefitPlans);
  yield takeLatest(START_ENROLLMENT.BASE, requestStartEnrollment);
  yield takeLatest(
    GET_EMPLOYER_BENEFIT_PLANS.SUCCEEDED,
    validateEmployerBenefitPlanConfigs,
  );
}
