import { getInviteVerificationPath } from 'apps/invite-access/invite-access-paths';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const useVerifyAccount = () => {
  const { employerIdentifier, invitationId } = useParams();
  const history = useHistory();

  const onVerifyClick = useCallback(() => {
    const verificationPath = getInviteVerificationPath(
      employerIdentifier,
      invitationId,
    );
    history.push(verificationPath);
  }, [history, employerIdentifier, invitationId]);

  return {
    onVerifyClick,
  };
};
