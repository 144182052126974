import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Formik } from 'formik';
import {
  Box,
  HeadingThree,
  PrimaryButton,
  SecondaryButton,
  TextField,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { PAGE_STATE } from './constants';
import { DeductionCodeFilters } from './deduction-code-filters.view';
import { useFilterOptions } from '../../resources/deduction-codes/use-filter-options.hook';

const mapFilter = (filter = {}) => {
  return Object.entries(filter).map(([field, value]) => ({
    field,
    value,
  }));
};

const generatePayload = ({ id, code, filter, groupId }) => {
  return {
    group_id: groupId,
    deduction_codes: [
      {
        id,
        code,
        filter: filter.reduce(
          (acc, { field, value }) => ({ ...acc, [field]: value }),
          {},
        ),
      },
    ],
  };
};

export const DeductionCodeFormPresenter = ({
  currentCode,
  payrollSchedules,
  pageState,
  setPageState,
  groupId,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { filterOptions, getFilterOptionByFieldName } = useFilterOptions({
    payrollSchedules,
  });

  return (
    <Formik
      key={currentCode.id}
      initialValues={{
        filter: mapFilter(currentCode.filter),
        code: currentCode.code,
      }}
      enableReinitialize
      onSubmit={values => {
        setPageState(PAGE_STATE.SHOWING);
        onSubmit(generatePayload({ ...values, id: currentCode.id, groupId }));
      }}
    >
      {({ values, handleSubmit, setFieldValue, ...formProps }) => {
        return (
          <Box>
            <HeadingThree marginBottom="one">
              {formatMessage({
                id:
                  pageState === PAGE_STATE.CREATING
                    ? 'NEW_DEDUCTION_CODE'
                    : 'EDIT_DEDUCTION_CODE',
              })}
            </HeadingThree>
            <TextField
              id="DEDUCTION_CODE"
              value={values.code}
              onChange={e => setFieldValue('code', e.currentTarget.value)}
              label={formatMessage({ id: 'DEDUCTION_CODE' })}
            />
            <FieldArray name="filter">
              {fieldProps => {
                const { push, remove } = fieldProps;
                return (
                  <DeductionCodeFilters
                    filters={values.filter}
                    filterOptions={filterOptions}
                    onChangeFilterType={(index, value) => {
                      setFieldValue(`filter.${index}.value`, '');
                      setFieldValue(`filter.${index}.field`, value);
                    }}
                    onChangeFilterValue={(index, value) =>
                      setFieldValue(`filter.${index}.value`, value)
                    }
                    onDeleteFilter={index => remove(index)}
                    onCreateFilter={() =>
                      push({
                        // pre-select the first non-selected field
                        field: filterOptions.find(option =>
                          values.filter.every(
                            filter => filter.field !== option.fieldName,
                          ),
                        ).fieldName,
                        value: '',
                      })
                    }
                    disableCreateButton={
                      filterOptions.length === values.filter.length
                    }
                    getFilterOptionByFieldName={getFilterOptionByFieldName}
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...formProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...fieldProps}
                  />
                );
              }}
            </FieldArray>

            <PrimaryButton
              data-testid="save-deduction-code"
              marginRight="one"
              type="submit"
              onClick={handleSubmit}
            >
              {formatMessage({ id: 'SAVE' })}
            </PrimaryButton>
            <SecondaryButton onClick={() => setPageState(PAGE_STATE.SHOWING)}>
              {formatMessage({ id: 'CANCEL' })}
            </SecondaryButton>
          </Box>
        );
      }}
    </Formik>
  );
};
DeductionCodeFormPresenter.propTypes = {
  groupId: PropTypes.string.isRequired,
  pageState: PropTypes.string.isRequired,
  setPageState: PropTypes.func.isRequired,
  currentCode: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    filter: PropTypes.shape({
      premium_type: PropTypes.string,
      benefit_type: PropTypes.string,
      benefit_plan_name: PropTypes.string,
      dependent_coverage_level: PropTypes.string,
      province_of_residence: PropTypes.string,
      province_of_employment: PropTypes.string,
      payroll_schedule_id: PropTypes.string,
    }),
  }).isRequired,
  payrollSchedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      schedule_name: PropTypes.string,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
