import React from 'react';
/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { getDependents } from 'common/services/get-dependents.service';
import { getUserConfig } from 'common/services/get-user-config.service';
import { inviteDependent } from 'common/services/invite-dependent.service';
import { setDependent } from 'common/services';
import { getDependentSchema } from 'common/services/get-dependent-schema.service';
import { selectUserGroupId } from 'common/state/user/user-group.selectors';
import { request, websocketFetch } from 'common/websocket-redux';
import { fork, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  GET_DEPENDENTS,
  GET_USER_CONFIG,
  INVITE_DEPENDENT,
  REQUEST_DEPENDENT_SCHEMA,
  REQUEST_DEPENDENTS,
  REQUEST_USER_CONFIG,
  SET_DEPENDENT,
  SHOW_GENERIC_ERROR,
  REQUEST_DEPENDENT_ACTIONS,
  LOAD_DEPENDENTS,
} from './manage-dependents.action-types';
import { EMAIL_EXISTS_ERROR_CODE } from './utils';
import { selectUserId } from 'common/state/user/user.selectors';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestSetDependent({ payload, meta }) {
  yield request(SET_DEPENDENT, setDependent(payload), meta);
}

export function* requestGetDependentSchema(action) {
  yield put(REQUEST_DEPENDENT_SCHEMA.start());
  const {
    action: userAction,
    id,
    qleRequestId,
    relationshipType,
    schemaType,
  } = action.payload;
  try {
    const response = yield getDependentSchema({
      action: userAction,
      qleRequestId,
      relationshipType,
      schemaType,
    });

    const {
      info: { metadata, dependent_schema: schema },
    } = response;

    yield put(REQUEST_DEPENDENT_SCHEMA.success({ metadata, schema, id }));
  } catch (error) {
    yield put(REQUEST_DEPENDENT_SCHEMA.error(error));
  }
}

export function* initializeDependentsResources(action) {
  const groupId = yield select(selectUserGroupId);
  yield put(REQUEST_DEPENDENTS.start());
  yield put(REQUEST_DEPENDENT_ACTIONS.start());
  try {
    const {
      info: { dependents, banners, league_family_helpcentre_link },
    } = yield getDependents({
      groupId: action.payload.groupId || groupId,
      userId: action.payload.userId || action.payload.primary_user_id,
      includeSuspended: true,
      sortByActionableDexInvitationStatus: true,
    });
    const {
      info: { actions },
    } = yield call(websocketFetch, 'get_dependents_actions', {
      qle_request_id: action.payload.qleId || null,
    });
    yield put(
      REQUEST_DEPENDENTS.success({
        dependents,
        banners,
        league_family_helpcentre_link,
      }),
    );
    yield put(REQUEST_DEPENDENT_ACTIONS.success({ actions }));
  } catch (error) {
    yield put(REQUEST_DEPENDENTS.error(error));
  }
}

export function* requestUserConfig() {
  yield request(REQUEST_USER_CONFIG, getUserConfig());
}

export function* requestInviteDependent(action) {
  const { dependentId, email } = action.payload;
  yield request(
    INVITE_DEPENDENT,
    inviteDependent({
      dependentId,
      email,
    }),
  );
}

export function* showErrorToast(action) {
  if (
    [
      REQUEST_DEPENDENT_ACTIONS.ERRORED,
      REQUEST_DEPENDENT_SCHEMA.ERRORED,
      REQUEST_DEPENDENTS.ERRORED,
      SHOW_GENERIC_ERROR,
    ].includes(action.type)
  ) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'SORRY_ERROR_ONLOAD_PLEASE_REFRESH',
    });
  }
  if (action.type === REQUEST_USER_CONFIG.ERRORED) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'SORRY_REQUEST_HAS_EXPIRED',
    });
  }
  if (
    action.type === INVITE_DEPENDENT.ERRORED &&
    action.payload.info?.code !== EMAIL_EXISTS_ERROR_CODE
  ) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'DEX_GENERIC_ERROR_MESSAGE_HEADING',
      contentId: 'DEX_GENERIC_ERROR_MESSAGE',
      values: {
        a: chunks => <a href="mailto:help@league.com">{chunks}</a>,
      },
    });
  }

  if (action.type === SET_DEPENDENT.ERRORED) {
    const { reason } = action.payload?.info || {};

    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG',
      content: reason,
      contentId: reason ? '' : 'DEPENDENT_ERROR_MESSAGE',
    });
  }
}

export function* showSetDependentSuccessToast(action) {
  const addToastActionProps = action?.meta?.isEditing
    ? {
        type: TOAST_STATUS.SUCCESS,
        textId: 'DEPENDENT_EDITED_SUCCESS_HEADING',
        contentId: 'DEPENDENT_EDITED_SUCCESS_MESSAGE',
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        textId: 'DEPENDENT_ADDED_SUCCESS_HEADING',
        contentId: 'DEPENDENT_ADDED_SUCCESS_MESSAGE',
      };

  yield call(toastActions.add, addToastActionProps);
}

export function* showInviteDependentSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'DEX_SUCCESS_MESSAGE_HEADING',
    contentId: 'DEX_SUCCESS_MESSAGE',
  });
}

export function* sendDexInviteAnalytics({ error, payload }) {
  if (error) {
    yield call(sendAnalyticsEvent, {
      category: 'Manage Dependents',
      action: 'Invite Failed',
      label: payload.info?.message?.info?.dependent_id,
      error_message: payload.info?.code,
    });
  } else {
    yield call(sendAnalyticsEvent, {
      category: 'Manage Dependents',
      action: 'Invite Sent',
      label: payload.info?.dependent_id,
    });
  }
}

export function* requestGetDependents(action) {
  const groupId = yield select(selectUserGroupId);
  const userId = yield select(selectUserId);
  yield put(REQUEST_DEPENDENTS.start());
  try {
    const {
      info: { dependents },
    } = yield getDependents({
      groupId: action.payload.groupId || groupId,
      userId: action.payload.userId || userId,
      includeSuspended: true,
      sortByActionableDexInvitationStatus: true,
    });
    yield put(REQUEST_DEPENDENTS.success({ dependents }));
  } catch (error) {
    yield put(REQUEST_DEPENDENTS.error(error));
  }
}
// Needed so we do not show a success toast or loading state
export function* loadDependents(action) {
  const groupId = yield select(selectUserGroupId);
  const userId = yield select(selectUserId);
  try {
    const {
      info: { dependents },
    } = yield getDependents({
      groupId: action.payload.groupId || groupId,
      userId: action.payload.userId || userId,
      includeSuspended: true,
    });
    yield put(REQUEST_DEPENDENTS.success({ dependents }));
  } catch (error) {
    yield put(REQUEST_DEPENDENTS.error(error));
  }
}

export function* manageDependentsSaga() {
  yield fork(takeLatest, [LOAD_DEPENDENTS], loadDependents);
  yield fork(
    takeLatest,
    [GET_DEPENDENTS, INVITE_DEPENDENT.SUCCEEDED],
    initializeDependentsResources,
  );
  yield fork(takeLatest, [SET_DEPENDENT.SUCCEEDED], requestGetDependents);
  yield fork(
    takeLatest,
    [SET_DEPENDENT.SUCCEEDED],
    showSetDependentSuccessToast,
  );
  yield fork(
    takeLatest,
    [INVITE_DEPENDENT.SUCCEEDED],
    showInviteDependentSuccessToast,
  );
  yield fork(
    takeLatest,
    [
      INVITE_DEPENDENT.ERRORED,
      REQUEST_DEPENDENT_ACTIONS.ERRORED,
      REQUEST_DEPENDENTS.ERRORED,
      REQUEST_USER_CONFIG.ERRORED,
      SET_DEPENDENT.ERRORED,
      SHOW_GENERIC_ERROR,
    ],
    showErrorToast,
  );
  yield fork(
    takeLatest,
    [INVITE_DEPENDENT.ERRORED, INVITE_DEPENDENT.SUCCEEDED],
    sendDexInviteAnalytics,
  );
  yield fork(takeLatest, GET_USER_CONFIG, requestUserConfig);
  yield fork(takeLatest, INVITE_DEPENDENT.BASE, requestInviteDependent);
  yield fork(takeLatest, SET_DEPENDENT.BASE, requestSetDependent);
  yield fork(
    takeLatest,
    REQUEST_DEPENDENT_SCHEMA.BASE,
    requestGetDependentSchema,
  );
}
