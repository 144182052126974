import { connect } from 'react-redux';
import {
  selectGroupBillingPreference,
  selectGroupBillingDivisions,
} from 'apps/employer-experience-v2/employer-adjustments/adjustments.selectors';
import { withProps, compose } from 'recompose';
import SelectBillingDivision from './select-billing-division.view';
import {
  BILLING_PREFERENCE_BY_DIVISION,
  DEFAULT_BILLING_DIVISION_ID,
  DEFAULT_BILLING_DIVISION_VALUE,
} from '../../constants';
import { createStructuredSelector } from 'reselect';

const withState = connect(
  createStructuredSelector({
    billingPreference: selectGroupBillingPreference,
    billingDivisions: selectGroupBillingDivisions,
  }),
);

const mapProps = withProps(
  ({ billingDivisions, billingPreference, value, setFieldValue }) => ({
    options: billingDivisions.map(({ billingDivisionId, name }) => ({
      value:
        billingDivisionId === DEFAULT_BILLING_DIVISION_ID
          ? DEFAULT_BILLING_DIVISION_VALUE
          : billingDivisionId,
      text: name,
    })),
    isConsolidated:
      !billingPreference ||
      !billingPreference.includes(BILLING_PREFERENCE_BY_DIVISION),
    fieldValue:
      value === DEFAULT_BILLING_DIVISION_ID
        ? DEFAULT_BILLING_DIVISION_VALUE
        : value,
    setValue: (name, val) =>
      setFieldValue(
        name,
        val === DEFAULT_BILLING_DIVISION_VALUE
          ? DEFAULT_BILLING_DIVISION_ID
          : val,
      ),
  }),
);

export default compose(withState, mapProps)(SelectBillingDivision);
