/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BodyOne,
  Box,
  genesisStyled,
  Flex,
  SubtitleOne,
  BodyTwo,
  Overline,
  PrimaryButton,
  Text,
  css,
  QuietButton,
  Image,
  BodyOneSecondary,
  Label,
  LabelText,
  HeadingFour,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { useIntl } from '@leagueplatform/locales';
import { Icon, ICONS, FontAwesomeIcon } from '@leagueplatform/ui-kit';
import {
  Dropdown,
  DropdownItems,
  DropdownItem,
} from 'common/components/dropdown';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { Link } from 'react-router-dom';
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV';
import { ImportPaySchedule } from './import-pay-schedule.container';
import { RenamePaySchedule } from './rename-pay-schedule.view';
import { ExportPaySchedule } from './export-pay-schedule.view';
import { STATUS } from 'apps/employer-experience-v2/employer-reports/components/premiums-report/premiums-report.reducer';
import checkmark from 'apps/public/assets/checkmark-in-green-circle.svg';
import { useFilteredPayrollSchedules } from './employer-payroll.hook';
import { getPayrollReportConfigs } from './employer-payroll.actions';
import { PayrollReportConfigs } from './payroll-report-configs-modal.view';

const StyledIcon = genesisStyled(Icon)(
  css({
    fill: 'onSurface.icon.primary',
    marginX: 'one',
  }),
);

const LargerIcon = genesisStyled(Icon)(
  css({
    marginRight: 'threeQuarters',
  }),
);

const StyledDropdownItems = genesisStyled(DropdownItems)`
  &&&::before {
    content: none;
  }
  padding: ${({ theme }) => theme.space.one}px 0;
  top: 50%;
  left: 50%;
  z-index: 1;
`;

const StyledDropdownItem = genesisStyled(DropdownItem)(
  css({
    paddingY: 'half',
    paddingX: 'oneAndHalf',
  }),
);

const FullLink = genesisStyled(Link)`
  width: 100%;
`;

const HoverableFlex = genesisStyled(Flex)(
  css({
    '&:hover': {
      backgroundColor: 'surface.background.secondary',
    },
  }),
);

const renamePayrollSchedule = (setPayrollSchedules, schedule, name) => {
  setPayrollSchedules({ ...schedule, schedule_name: name });
};

const linkPayrollConfigs = (setPayrollSchedules, schedule, configId) => {
  setPayrollSchedules({ ...schedule, payroll_split_config_id: configId });
};

const DottedDropdownTrigger = ({ onToggle }) => {
  const { formatMessage } = useIntl();
  return (
    <QuietButton
      paddingX="one"
      height="92px"
      onClick={() => {
        onToggle();
      }}
      aria-label={formatMessage({ id: 'PAY_CALENDAR_MENU' })}
    >
      <FontAwesomeIcon icon={faEllipsisV} />
    </QuietButton>
  );
};
DottedDropdownTrigger.propTypes = {
  onToggle: PropTypes.func,
};

DottedDropdownTrigger.defaultProps = {
  onToggle: () => {},
};

const NoPaySchedules = ({
  payrollSchedulesLoading,
  setShowImportModal,
  formatMessage,
}) => (
  <Flex
    flexDirection="column"
    paddingY="five"
    paddingX="two"
    justifyContent="center"
    textAlign="center"
  >
    {payrollSchedulesLoading === STATUS.LOADING ? (
      <LoadingIndicator />
    ) : (
      <>
        <BodyOne color="onSurface.text.subdued">
          {formatMessage({ id: 'NO_PAY_SCHEDULE_UPLOADED' })}
        </BodyOne>
        <Flex
          alignSelf="center"
          marginTop="one"
          cursor="pointer"
          onClick={() => setShowImportModal(true)}
        >
          <LargerIcon icon={ICONS.FILE_ARROW_UP} size="24px" />
          <BodyOne>{formatMessage({ id: 'ADD_PAY_CALENDAR' })}</BodyOne>
        </Flex>
      </>
    )}
  </Flex>
);
NoPaySchedules.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  payrollSchedulesLoading: PropTypes.string.isRequired,
  setShowImportModal: PropTypes.func.isRequired,
};

const PayrollScheduleItem = ({
  scheduleItem,
  exportPayrollSchedule,
  setShowRenameModal,
  setSelectedCalendar,
  formatMessage,
  groupId,
  setShowPayrollReportConfigsModal,
}) => {
  const { plan_periods, schedule_name } = scheduleItem;

  return plan_periods.map(period => {
    return (
      <Flex
        marginTop="two"
        borderRadius="small"
        borderWidth="thin"
        borderColor="interactive.background.disabled"
        key={`${schedule_name}-${period}`}
      >
        <Flex width={1}>
          <Box
            borderRightWidth="thin"
            borderRightStyle="solid"
            borderRightColor="interactive.background.disabled"
          >
            <Dropdown onClick={e => e.nativeEvent.stopImmediatePropagation()}>
              <DottedDropdownTrigger />
              <StyledDropdownItems>
                <StyledDropdownItem
                  onClick={() => {
                    setShowRenameModal(true);
                    setSelectedCalendar(scheduleItem);
                  }}
                >
                  <BodyTwo>
                    {formatMessage({ id: 'RENAME_BENEFITS_CLASS' })}
                  </BodyTwo>
                </StyledDropdownItem>
                <StyledDropdownItem
                  onClick={() => {
                    exportPayrollSchedule({
                      payrollScheduleId: scheduleItem.id,
                    });
                  }}
                >
                  <BodyTwo>
                    {formatMessage({ id: 'EXPORT_PAY_SCHEDULE' })}
                  </BodyTwo>
                </StyledDropdownItem>
                <StyledDropdownItem
                  onClick={() => {
                    setShowPayrollReportConfigsModal(true);
                    setSelectedCalendar(scheduleItem);
                  }}
                >
                  <BodyTwo>
                    {formatMessage({ id: 'LINK_PAYROLL_REPORT_CONFIGS' })}
                  </BodyTwo>
                </StyledDropdownItem>
              </StyledDropdownItems>
            </Dropdown>
          </Box>

          <FullLink
            to={`/admin/employers/${groupId}/payroll/${scheduleItem.id}/edit-schedule`}
          >
            <HoverableFlex
              width={1}
              paddingY="oneAndHalf"
              justifyContent="space-between"
              alignItems="center"
              transition="all 200ms ease-out"
            >
              <Flex>
                <Flex flexDirection="column" marginLeft="oneAndHalf">
                  <Text
                    color="onSurface.text.subdued"
                    fontSize="overline"
                    fontWeight="bold"
                  >
                    {period}
                  </Text>
                  <SubtitleOne>{`${
                    schedule_name || formatMessage({ id: 'DEFAULT' })
                  } ${formatMessage({
                    id: 'PAY_CALENDAR',
                  })}`}</SubtitleOne>
                </Flex>
                <Flex alignItems="center" marginTop="half">
                  <Flex
                    marginLeft="threeQuarters"
                    borderRadius="small"
                    padding="half"
                    alignItems="center"
                    backgroundColor="success.background.subdued"
                  >
                    <Image
                      src={checkmark}
                      size="one"
                      marginRight="half"
                      alt=""
                    />
                    <Overline fontWeight="bold">
                      {formatMessage({
                        id: 'PAY_SCHEDULE_UPLOADED',
                      }).toUpperCase()}
                    </Overline>
                  </Flex>
                </Flex>
              </Flex>

              <StyledIcon icon={ICONS.CHEVRON_RIGHT} size="12px" />
            </HoverableFlex>
          </FullLink>
        </Flex>
      </Flex>
    );
  });
};

export const PayCalendars = ({
  groupId,
  payrollSchedules,
  payrollSchedulesLoading,
  setPayrollSchedules,
  showImportModal,
  setShowImportModal,
  showRenameModal,
  setShowRenameModal,
  importPayrollSchedule,
  exportPayrollSchedule,
  showExportModal,
  documentUrl,
  documentName,
  setShowExportModal,
  planFilterOptions,
}) => {
  const [selectedCalendar, setSelectedCalendar] = useState();
  const [showPayrollReportConfigsModal, setShowPayrollReportConfigsModal] =
    useState();
  const { formatMessage } = useIntl();
  const { setPlanPeriodsFilter, filteredPayrollSchedules } =
    useFilteredPayrollSchedules(payrollSchedules);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPayrollReportConfigs({ groupId }));
  }, [dispatch, groupId]);

  return (
    <Box>
      {showImportModal && (
        <ImportPaySchedule
          onClose={() => setShowImportModal(false)}
          importPayrollSchedule={importPayrollSchedule}
        />
      )}
      {showRenameModal && (
        <RenamePaySchedule
          initialValue={selectedCalendar.schedule_name}
          onClose={() => setShowRenameModal(false)}
          onSubmit={name =>
            renamePayrollSchedule(setPayrollSchedules, selectedCalendar, name)
          }
        />
      )}
      {showExportModal && (
        <ExportPaySchedule
          documentUrl={documentUrl}
          documentName={documentName}
          onClose={() => setShowExportModal(false)}
        />
      )}
      {showPayrollReportConfigsModal && (
        <PayrollReportConfigs
          selectedCalendar={selectedCalendar}
          onClose={() => setShowPayrollReportConfigsModal(false)}
          onSubmit={configId =>
            linkPayrollConfigs(setPayrollSchedules, selectedCalendar, configId)
          }
        />
      )}
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <HeadingFour>{formatMessage({ id: 'PAY_CALENDARS' })}</HeadingFour>
          <BodyOneSecondary>
            {formatMessage({ id: 'PAY_CALENDARS_COPY' })}
          </BodyOneSecondary>
        </Flex>
        <PrimaryButton
          marginLeft="one"
          onClick={() => setShowImportModal(true)}
        >
          {formatMessage({ id: 'ADD_PAY_CALENDAR' })}
        </PrimaryButton>
      </Flex>
      <Label display="flex" alignItems="center" marginTop="one">
        <LabelText marginRight="one">Filter by Plan Period</LabelText>
        <Select
          isMulti
          flexGrow={1}
          placeholder="Select Plan Period"
          options={planFilterOptions}
          onChange={evt =>
            setPlanPeriodsFilter(evt ? evt.map(({ value }) => value) : [])
          }
        />
      </Label>

      {filteredPayrollSchedules.length ? (
        filteredPayrollSchedules.map(scheduleItem => (
          <PayrollScheduleItem
            key={scheduleItem.id}
            exportPayrollSchedule={exportPayrollSchedule}
            scheduleItem={scheduleItem}
            setShowImportModal={setShowImportModal}
            setShowRenameModal={setShowRenameModal}
            setSelectedCalendar={setSelectedCalendar}
            setShowPayrollReportConfigsModal={setShowPayrollReportConfigsModal}
            formatMessage={formatMessage}
            groupId={groupId}
          />
        ))
      ) : (
        <NoPaySchedules
          payrollSchedulesLoading={payrollSchedulesLoading}
          setShowImportModal={setShowImportModal}
          formatMessage={formatMessage}
        />
      )}
    </Box>
  );
};

PayCalendars.propTypes = {
  groupId: PropTypes.string.isRequired,
  payrollSchedulesLoading: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPayrollSchedules: PropTypes.func.isRequired,
  showImportModal: PropTypes.bool.isRequired,
  setShowImportModal: PropTypes.func.isRequired,
  showRenameModal: PropTypes.bool.isRequired,
  setShowRenameModal: PropTypes.func.isRequired,
  importPayrollSchedule: PropTypes.func.isRequired,
  exportPayrollSchedule: PropTypes.func.isRequired,
  showExportModal: PropTypes.bool.isRequired,
  documentUrl: PropTypes.string.isRequired,
  documentName: PropTypes.string.isRequired,
  setShowExportModal: PropTypes.func.isRequired,
  planFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
PayCalendars.defaultProps = {
  planFilterOptions: [],
};
