export const LIMITS = {
  PURCHASE_HISTORY: 10,
};

export const PAYMENT_TYPES = {
  LEAGUE_CREDIT: 'promo_code',
};

export const PURCHASE_TYPES = {
  CREDIT: 'credit',
};
