import { escapeRegExp, filter, partialRight, map } from 'lodash';
import { compose, withProps, withHandlers } from 'recompose';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import { Dropdown, Header, Icon, Popup } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';

import onClickOutside from 'react-onclickoutside';

const Item = injectIntl(({ title, planName, areMultiple, intl }) => {
  const tooltipContent = intl.formatMessage({
    id: 'DUPLICATE_BENEFIT_NAME_WARNING',
  });

  return (
    <Header as="h2" className="header--catalogue-result">
      <span>
        {title}{' '}
        {areMultiple ? (
          <Popup
            wide
            inverted
            trigger={
              <Icon
                name="warning circle"
                color="orange"
                size="tiny"
                style={{ display: 'inline-block', fontSize: '1em' }}
              />
            }
            content={tooltipContent}
            position="top center"
          />
        ) : (
          ''
        )}
      </span>
      <Header.Subheader>{planName}</Header.Subheader>
    </Header>
  );
});

Item.propTypes = {
  title: PropTypes.string.isRequired,
  planName: PropTypes.string,
  areMultiple: PropTypes.bool,
};

Item.defaultProps = {
  vendorName: '',
  areMultiple: false,
  planName: null,
};

const getItemName = (benefitVendorName, fullName, networkName) =>
  `${benefitVendorName} ${fullName} ${networkName ? `(${networkName})` : ''}`;

const generateOptions = partialRight(map, item => ({
  key: item.id,
  text: item.full_name,
  value: item.id,
  content: (
    <Item
      title={getItemName(
        item.benefit_vendor_name,
        item.full_name,
        item.network_name,
      )}
      planName={item.plan_name}
      areMultiple={item.areMultiple}
    />
  ),
}));

const mapOptions = withProps(({ catalogue }) => ({
  options: generateOptions(catalogue),
}));

const withSearchHandler = withHandlers({
  handleSearch:
    ({ catalogue }) =>
    (results, input) => {
      const re = new RegExp(escapeRegExp(input), 'i');
      const isMatch = result =>
        re.test(catalogue[result.key].full_name) ||
        re.test(catalogue[result.key].description) ||
        re.test(catalogue[result.key].benefit_vendor_name) ||
        re.test(catalogue[result.key].benefit_product_type);
      return filter(results, isMatch);
    },
});

const withSelectHandler = withHandlers({
  handleSelect:
    ({ catalogue, onChange }) =>
    (e, { value }) =>
      onChange({ benefitProduct: catalogue[value] }),
});

class Catalogue extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    /**
     * In search mode Semantic UI React Dropdown uses
     * the first option as the placeholder.
     * We find this is confusing to the user and therefore,
     * are overriding it manually on component mount.
     */
    this.dropdown.ref.querySelector('.default.text').innerText =
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.intl.formatMessage({
        id: 'SEARCH_BENEFITS_PLACEHOLDER',
      });
  }

  handleClickOutside() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.onClose();
  }

  render() {
    const { handleSelect, handleSearch, onClose, options, intl } = this.props;

    return (
      <Box className="container--search-catalogue">
        <Dropdown
          ref={el => {
            this.dropdown = el;
          }}
          defaultOpen
          closeOnChange
          closeOnBlur
          onClose={onClose}
          selectOnNavigation={false}
          className="dropdown--search-catalogue"
          selection
          onChange={handleSelect}
          fluid
          search={handleSearch}
          options={options}
          placeholder={intl.formatMessage({
            id: 'SEARCH_BENEFITS_PLACEHOLDER',
          })}
        />
      </Box>
    );
  }
}

Catalogue.propTypes = {
  intl: intlShape.isRequired,
  options: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  mapOptions,
  withSearchHandler,
  withSelectHandler,
)(onClickOutside(Catalogue));
