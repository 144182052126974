import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getDeductionCodes calls the backend to fetch deduction codes. id or group id must be specified
 * @param payload contains
 *    group_id - filter deduction codes by group
 *    ids - array of ids to fetch; if not specified, fetch all
 * @returns payload containing
 *    group_id
 *    deduction_codes - array of:
 *        id
 *        code
 *        filter
 *            premium_type
 *            benefit_type
 *            benefit_plan_name
 *            dependent_coverage_level
 *            province_of_residence
 *            province_of_employment
 *            payroll_schedule_id
 */
export const getDeductionCodes = payload =>
  call(websocketFetch, 'get_deduction_codes', payload);
