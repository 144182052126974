import { all } from 'redux-saga/effects';
import { readjustBenefitAmount } from 'common/services';
import { getUpdatedAllocationAmounts } from './settlement.utilities';

export function* readjustBenefitAmounts({
  userId,
  values,
  initialAllocations,
}) {
  const updatedAmounts = getUpdatedAllocationAmounts(
    initialAllocations,
    values,
  );

  /* Extract the source accounts from the available list.  */

  const sources = initialAllocations
    .filter(policy => {
      const updated = updatedAmounts.find(
        updatedPolicy => updatedPolicy.type === policy.type,
      );
      return updated.amountApproved < policy.amountApproved; // filter for accounts with lower adjusted balances, i.e. a source account
    })
    .map(policy => {
      const updated = updatedAmounts.find(
        updatedPolicy => updatedPolicy.type === policy.type,
      );
      return {
        ...policy,
        amountAdjusted: updated.amountAdjusted,
      };
    });

  /* Calculate the total of adjusted funds */

  const totalAdjustedFunds = sources.reduce((total, source) => {
    return total + (source.amountAdjusted || 0);
  }, 0);

  /* Generate a call to the API for every adjustment. Each adjustment takes a single source and a single destination benefit */
  const adjustments = [];
  updatedAmounts
    .filter(amount => !sources.map(source => source.type).includes(amount.type))
    .forEach(updated => {
      let source;
      let transactionAmount;
      let hasSufficientBalance;
      let amountMoved = 0;

      /* Loop until we have moved enough balance to the destination policy */
      while (amountMoved !== updated.amountAdjusted) {
        if (amountMoved > totalAdjustedFunds || sources.length === 0) {
          // I don't think this can happen, but let's guard against it anyway
          throw new Error('Attempted to move more funds than are available');
        }

        /* Calculate if the current source has enough funds to transfer the full amount to the destination
         * If yes, just subtract the total from the source acount. Otherwise, shift it off the source array and use its entire
         * balance. In either case, generate a new adjustment to be sent to the API */

        hasSufficientBalance =
          sources[0].amountAdjusted >= updated.amountAdjusted;
        source = hasSufficientBalance ? sources[0] : sources.shift(); // reference current source or remove it
        transactionAmount = hasSufficientBalance
          ? updated.amountAdjusted
          : source.amountAdjusted;
        amountMoved += transactionAmount;
        source.amountAdjusted -= transactionAmount;
        adjustments.push({
          sourceBenefitId: source.id,
          destBenefitId: updated.id,
          amount: transactionAmount,
          categoryId: updated.categoryId,
        });
      }
    });

  return yield all(
    adjustments.map(adjustment => {
      const { sourceBenefitId, destBenefitId, categoryId, amount } = adjustment;
      return readjustBenefitAmount({
        realOperation: true,
        userId,
        amount,
        sourceBenefitId,
        destBenefitId,
        claimId: values.claim_id,
        destCategoryId: categoryId,
      });
    }),
  );
}
