import { getQleRequest, setQleEnrollment } from 'common/services';
import { request } from 'common/websocket-redux';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  GET_QLE_REQUEST,
  SERVICE_GET_QLE_REQUEST,
  HANDLE_NEX,
  REQUEST_SET_QLE_ENROLLMENT,
} from './qle-request.action-types';
import { handleNEX } from './qle-request.action-creators';
import get from 'lodash/get';
import { EXPIRED, INVALID_REQUEST } from './error-codes.constants';
import { push } from 'connected-react-router';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

/**
 * @function handleQLERequestError Handle error message for `get_qle_request` error based
 * off of response error code and return to home page.
 *
 * Uses generic error message if none of the anticipated error codes are received.
 * @param {string} errorCode error code from service response
 */
export function* handleQLERequestError(errorCode) {
  let textId = 'WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID'; // Default generic QLE error message

  if (errorCode === EXPIRED) {
    textId = 'SORRY_QLE_REQUEST_EXPIRED';
  } else if (errorCode === INVALID_REQUEST) {
    textId = 'SORRY_REFRESH_THE_PAGE';
  }

  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId,
  });

  yield put(push('/member/home'));
}

/**
 * @function requestGetQLE Send API call to `get_qle_request` to which returns necessary
 * data to configure QLE request landing page as well as necessary info of the submitted QLE request.
 *
 * If `allow_nex` is true, sends action to handle redirecting to NEX.
 *
 * worker saga, plus service for the fetch request
 * @param {object} action - contains payload with `qleId` needed to fetch QLE request
 */
export function* requestGetQLE(action) {
  const qleId = get(action, 'payload.qleId');
  const response = yield request(
    SERVICE_GET_QLE_REQUEST,
    getQleRequest({
      qleId,
    }),
  );

  const allowNex = get(response, 'info.allow_nex');

  if (response.error) {
    const errorCode = get(response, 'payload.info.code');

    yield handleQLERequestError(errorCode);
  } else if (allowNex) {
    yield put(handleNEX({ qleRequestId: qleId }));
  }
}

/**
 * @function goHomeAndShowGenericErrorMessage Redirect to home page and show generic expired or invalid message.
 */
export function* goHomeAndShowGenericErrorMessage() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID', // Misleading string name, this text shows generic try again message
  });

  yield put(push('/member/home'));
}

/**
 * @function handleGoToNEX Send API call to `set_qle_enrollment` which will setup NEX for the current member
 * (extracted by backend in context), and on success, redirect to NEX. This fails if the group does is not configured
 * for modular flex enrollment.
 *
 * On fail, redirect to home and display generic retry error message.
 *
 * Note: Members on NEX will only get the flag `allow_nex` in their QLE request config.
 * @param {object} action - contains payload which should have the qle request id needed to for API call
 */
export function* handleGoToNEX({ payload }) {
  const { qleRequestId } = payload;
  try {
    const response = yield request(
      REQUEST_SET_QLE_ENROLLMENT,
      setQleEnrollment({ qleRequestId }),
    );

    if (!response.error) {
      yield put(push('/enroll'));
    } else {
      yield goHomeAndShowGenericErrorMessage();
    }
  } catch (error) {
    // In case the websocket fails
    yield goHomeAndShowGenericErrorMessage();
  }
}

// watcher saga
export function* qleRequestSagas() {
  yield fork(takeLatest, GET_QLE_REQUEST, requestGetQLE);
  yield fork(takeLatest, HANDLE_NEX, handleGoToNEX);
}
