import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/genesis-commons';
import { Tooltip, TooltipContentV2 } from '@leagueplatform/ui-kit';

export const Required = () => {
  const { formatMessage } = useIntl();
  return (
    <Tooltip>
      <Box color="onSurface.text.critical"> *</Box>
      <TooltipContentV2>
        {formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' })}
      </TooltipContentV2>
    </Tooltip>
  );
};
