import React from 'react';
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';

export const NEW_TAB = 'new-tab';

/**
 * Use whenever you need to open a new tab.
 * Make sure to add aria-describedby attribute on a component that triggers the opening of the new tab e.g. Link
 */
export const ScreenReaderAdvanceWarning = ({ id }) => {
  const { formatMessage } = useIntl();

  return (
    <VisuallyHidden id={id}>
      {formatMessage({ id: 'WARNING_OPENS_NEW_TAB' })}
    </VisuallyHidden>
  );
};

ScreenReaderAdvanceWarning.propTypes = {
  id: PropTypes.string.isRequired,
};
