import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import EnrollmentStepListPresenter from './enrollment-steps-list/enrollment-steps-list.view';
import { StepContainer } from '../step-container/step-container.view';
import {
  Box,
  Flex,
  PrimaryButton,
  DangerButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import AvailableBenefitSetsListPresenter from './available-benefit-sets-list/available-benefit-sets-list.view';
import {
  reorderElements,
  reorderElementsInStepsList,
  moveBenefitSetsBetweenLists,
  isDroppableListGroup,
  deleteGroupOrStepFromList,
  updateMatchedElementInList,
  getMatchedElementInList,
} from './utils';
import {
  BenefitSet,
  BenefitStep,
  BenefitSetGroup,
  EnrollmentStepItemType,
} from './enrollment-design-options.types';
import { AVAILABLE_BENEFIT_SETS_LIST } from './enrollment-experience-config.constants';
import { useIntl } from '@leagueplatform/locales';
import { Formik } from 'formik';
import { BlockToggleNodeModal } from '../../enrollment-config/block-toggle-node-modal.view';
import { useConfirm } from '@leagueplatform/web-common';
import { useEnrollmentExperienceConfigValidation } from './enrollment-experience-config.validation';
// eslint-disable-next-line import/no-named-as-default -- FIXME: automatically added for existing issue
import EditingPanelContainer from './editing-panel/editing-panel.container';
import { ConfirmApplyDefaultConfigModal } from './confirm-apply-default-config-modal.view';
import { Toast, TOAST_STATUS } from '@leagueplatform/toast-messages';

interface EnrollmentExperienceConfigPresenterProps {
  selectedPlanId: string;
  groupId: string;
  enrollmentSteps: BenefitStep[];
  availableBenefitSets: BenefitSet[];
  isSaving: boolean;
  showToast: (toastDetail: Toast) => void;
  saveEnrollmentSteps: (steps: BenefitStep[]) => void;
  enrollmentStepUrls: any[];
  resetEnrollmentSteps: () => void;
}

interface UseEnrollmentStepListProps {
  enrollmentSteps: BenefitStep[];
}

export const useEnrollmentStepList = ({
  enrollmentSteps,
}: UseEnrollmentStepListProps) => {
  const [stepsList, setStepsList] = useState<BenefitStep[]>([]);

  useEffect(() => {
    if (enrollmentSteps) setStepsList([...enrollmentSteps]);
  }, [enrollmentSteps, setStepsList]);

  const createNewStep = useCallback(() => {
    const benefitStep = new BenefitStep('New Step');
    setStepsList([...stepsList, benefitStep]);
  }, [stepsList]);

  const createNewGroup = useCallback(
    (stepId: string) => {
      const benefitGroup = new BenefitSetGroup('New Group');
      setStepsList(
        stepsList.map(step => {
          if (step.id === stepId)
            return {
              ...step,
              sets: [...(step?.sets ?? []), benefitGroup],
            };
          return step;
        }),
      );
    },
    [stepsList],
  );

  return { stepsList, setStepsList, createNewStep, createNewGroup };
};

const EnrollmentExperienceConfigPresenter = ({
  selectedPlanId,
  enrollmentSteps,
  availableBenefitSets,
  isSaving,
  showToast,
  saveEnrollmentSteps,
  enrollmentStepUrls,
  resetEnrollmentSteps,
}: EnrollmentExperienceConfigPresenterProps) => {
  const { formatMessage } = useIntl();
  const { stepsList, setStepsList, createNewStep, createNewGroup } =
    useEnrollmentStepList({
      enrollmentSteps,
    });
  const validation =
    useEnrollmentExperienceConfigValidation(enrollmentStepUrls);
  const [availableBenefitSetsList, setAvailableBenefitSetsList] =
    useState<BenefitSet[]>(availableBenefitSets);
  const [hasUnsavedFormChanges, setHasUnsavedFormChanges] = useState(false);
  const [hasUnsavedDragChanges, setHasUnsavedDragChanges] = useState(false);
  const { setIsConfirming, ...confirmProps } = useConfirm();
  const [refreshStepList, setRefreshStepList] = useState<boolean>(false);
  const [showApplyDefaultConfigModal, setShowApplyDefaultConfigModal] =
    useState(false);

  const [activeEditingItem, setActiveEditingItem] =
    useState<EnrollmentStepItemType | null>(null);

  useEffect(() => {
    if (refreshStepList) setRefreshStepList(false);
  }, [refreshStepList]);

  useEffect(() => {
    if (availableBenefitSets)
      setAvailableBenefitSetsList([...availableBenefitSets]);
  }, [availableBenefitSets, setAvailableBenefitSetsList]);

  useEffect(() => {
    // We need to update the activeEditingItem after saving
    if (activeEditingItem) {
      const updatedItem = getMatchedElementInList(
        stepsList,
        activeEditingItem.id,
      );
      setActiveEditingItem(updatedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsList]);

  useEffect(() => {
    const hasChanges =
      JSON.stringify(stepsList) !== JSON.stringify(enrollmentSteps);
    setHasUnsavedDragChanges(hasChanges);
  }, [stepsList, enrollmentSteps]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination, draggableId } = result;

      if (!destination) return;

      if (
        source?.droppableId === AVAILABLE_BENEFIT_SETS_LIST &&
        source?.droppableId === destination?.droppableId
      ) {
        setAvailableBenefitSetsList(
          reorderElements(
            availableBenefitSetsList,
            source.index,
            destination.index,
          ),
        );
      } else if (
        (source.droppableId === AVAILABLE_BENEFIT_SETS_LIST ||
          destination.droppableId === AVAILABLE_BENEFIT_SETS_LIST) &&
        source.droppableId !== destination.droppableId
      ) {
        // Show an attention message to not drag group inside available benefit sets
        if (isDroppableListGroup(draggableId)) {
          showToast({
            type: TOAST_STATUS.ATTENTION,
            textId: 'GROUP_DRAG_ERROR_WITHIN_LIST',
          });
          return;
        }
        const { updatedAvailableBenefitSetsList, updatedStepsList } =
          moveBenefitSetsBetweenLists(availableBenefitSetsList, stepsList, {
            source,
            destination,
            draggableId,
          });
        setAvailableBenefitSetsList(updatedAvailableBenefitSetsList);
        setStepsList(updatedStepsList);
      } else {
        const { list, refreshList } = reorderElementsInStepsList(stepsList, {
          source,
          destination,
          draggableId,
        });
        setStepsList(list);
        if (refreshList) setRefreshStepList(refreshList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
    [stepsList, availableBenefitSetsList],
  );

  const resetList = () => {
    setStepsList([...enrollmentSteps]);
    setAvailableBenefitSetsList([...availableBenefitSets]);
    setActiveEditingItem(null);
  };

  const deleteActiveStepOrGroup = () => {
    const { updatedList: updatedStepList, removedBenefitSets } =
      deleteGroupOrStepFromList(stepsList, activeEditingItem?.id);
    setStepsList([...updatedStepList]);
    setAvailableBenefitSetsList([
      ...availableBenefitSetsList,
      ...removedBenefitSets,
    ]);
    setActiveEditingItem(null);
  };

  const confirmSetActiveEditingItem = (item: EnrollmentStepItemType) => {
    if (hasUnsavedFormChanges) {
      setIsConfirming(true);
    } else {
      setActiveEditingItem(item);
    }
  };

  const confirmClearActiveEditingItem = () => {
    if (hasUnsavedFormChanges) {
      setIsConfirming(true);
    } else {
      setActiveEditingItem(null);
    }
  };

  const createNewGroupInStep = (id: string) => {
    setRefreshStepList(true);
    createNewGroup(id);
  };

  const onClickApplyDefaultConfig = () => {
    setShowApplyDefaultConfigModal(true);
  };

  const applyDefaultConfig = () => {
    setShowApplyDefaultConfigModal(false);
    resetEnrollmentSteps();
  };

  const disableSaveResetButton =
    isSaving || !(hasUnsavedDragChanges || hasUnsavedFormChanges);

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <BlockToggleNodeModal {...confirmProps} />
      {showApplyDefaultConfigModal && (
        <ConfirmApplyDefaultConfigModal
          onCancel={() => setShowApplyDefaultConfigModal(false)}
          onConfirm={applyDefaultConfig}
        />
      )}
      <StepContainer
        title="ENROLLMENT_EXPERIENCE_CONFIG_TITLE"
        selectedPlanId={selectedPlanId}
      >
        <Formik
          key={activeEditingItem?.id}
          enableReinitialize
          initialValues={{
            name: activeEditingItem?.name || '',
            layout: (activeEditingItem as BenefitSet)?.layout,
            url: (activeEditingItem as BenefitStep)?.url,
            content: activeEditingItem?.content || null,
          }}
          validate={values => validation({ values, activeEditingItem })}
          onSubmit={values => {
            if (activeEditingItem) {
              const itemToUpdate = {
                ...activeEditingItem,
                ...values,
              };
              saveEnrollmentSteps(
                updateMatchedElementInList(stepsList, itemToUpdate),
              );
            } else {
              saveEnrollmentSteps(stepsList);
            }
          }}
        >
          {({ handleSubmit, handleReset, dirty, ...formProps }) => {
            if (dirty !== hasUnsavedFormChanges)
              setHasUnsavedFormChanges(dirty);
            return (
              <>
                <Flex flex="1" flexWrap="wrap">
                  <Flex
                    width={{
                      _: 1,
                      tablet: 1 / 2,
                    }}
                  >
                    <Box width="100%">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <AvailableBenefitSetsListPresenter
                          availableBenefitSetsList={availableBenefitSetsList}
                        />
                        <EnrollmentStepListPresenter
                          refreshStepList={refreshStepList}
                          activeEditingItemId={activeEditingItem?.id}
                          setActiveEditingItem={confirmSetActiveEditingItem}
                          stepsList={stepsList}
                        />
                      </DragDropContext>
                      <SecondaryButton
                        marginRight="half"
                        marginY="quarter"
                        onClick={createNewStep}
                      >
                        {formatMessage({ id: 'CREATE_STEP_TEXT' })}
                      </SecondaryButton>
                    </Box>
                  </Flex>
                  <Flex
                    width={{
                      _: 1,
                      tablet: 1 / 2,
                    }}
                  >
                    <EditingPanelContainer
                      onCreateGroupClick={createNewGroupInStep}
                      activeEditingItem={activeEditingItem}
                      deleteActiveStepOrGroup={deleteActiveStepOrGroup}
                      clearActiveEditingId={confirmClearActiveEditingItem}
                      formProps={formProps}
                    />
                  </Flex>
                </Flex>
                <Flex
                  marginTop="one"
                  borderTopWidth="thin"
                  justifyContent="space-between"
                  paddingTop="one"
                  borderTopColor="onSurface.border.subdued"
                >
                  <div>
                    <SecondaryButton
                      marginRight="half"
                      marginY="quarter"
                      disabled={isSaving}
                      onClick={onClickApplyDefaultConfig}
                    >
                      {formatMessage({ id: 'APPLY_DEFAULT_CONFIG_BUTTON' })}
                    </SecondaryButton>
                    <DangerButton
                      disabled={disableSaveResetButton}
                      onClick={() => {
                        handleReset();
                        resetList();
                      }}
                    >
                      {formatMessage({ id: 'RESET' })}
                    </DangerButton>
                  </div>
                  <PrimaryButton
                    disabled={disableSaveResetButton}
                    marginLeft="half"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {formatMessage({ id: 'SAVE' })}
                  </PrimaryButton>
                </Flex>
              </>
            );
          }}
        </Formik>
      </StepContainer>
    </>
  );
};

export default EnrollmentExperienceConfigPresenter;
