import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import {
  terminateEmployees,
  suspendEmployees,
  reinstateEmployees,
} from '../../actions';
import { selectEmployeeStatusInGroup } from '../../selectors';
import { selectEmployerId } from '../../../selectors';
import EmployeesBulkActions from './presenter';

const mapStateToProps = (state, { selectedEmployees, employees }) => ({
  selectedEmployees,
  employees,
  employerId: selectEmployerId(state),
});

export default compose(
  connect(mapStateToProps, {
    terminateEmployees,
    suspendEmployees,
    reinstateEmployees,
  }),
  withHandlers({
    /* istanbul ignore next */
    terminateEmployees:
      props =>
      ({ selectedEmployees, effectiveDate, reason }) => {
        props.terminateEmployees({
          effectiveDate,
          reason,
          userIds: selectedEmployees,
          groupId: props.employerId,
        });
        props.onToggleModal(false);
      },
    /* istanbul ignore next */
    suspendEmployees:
      props =>
      ({ selectedEmployees, effectiveDate }) => {
        props.suspendEmployees({
          effectiveDate,
          userIds: selectedEmployees,
          groupId: props.employerId,
        });
      },
    /* istanbul ignore next */
    reinstateEmployees:
      props =>
      ({ selectedEmployees, effectiveDate }) => {
        props.reinstateEmployees({
          effectiveDate,
          userIds: selectedEmployees,
          groupId: props.employerId,
        });
      },
    /* istanbul ignore next */
    getEmployeeStatusInGroup:
      ({ employees, employerId }) =>
      ({ employeeId }) => {
        return selectEmployeeStatusInGroup({
          employees,
          employerId,
          employeeId,
        });
      },
  }),
)(EmployeesBulkActions);
