import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  BodyOne,
  BodyTwo,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { theme as uiKitTheme, Icon, ICONS } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import ErrorIcon from 'common/ui-kit/assets/error-icon.svg';
import WarningIcon from 'apps/public/assets/warning-icon.svg';
import { WARNING_SEVERITY } from '../enrollment-config.constants';

const Li = genesisStyled(BodyTwo)(
  css({
    listStyleType: 'disc',
    marginLeft: 'quarter',
    marginTop: 'quarter',
  }),
);

export const Chevron = genesisStyled(Icon)`
  display: flex;
  opacity: 0.7;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
  transition-duration: 0.5s;
  transition-timing-function: ease;
`;

const getStyleProp = error => ({
  backgroundColor: error
    ? 'critical.background.subdued'
    : 'warning.background.subdued',
  icon: error ? ErrorIcon : WarningIcon,
});

export const FormValidationBanner = ({
  severity, // 'error' or 'warning'
  messages, // array of error/warning messages
  formType,
}) => {
  const { formatMessage } = useIntl();
  const isExpandable = messages.length > 1;
  const [isExpanded, setIsExpanded] = useState(false);

  const error = severity === WARNING_SEVERITY.ERROR;
  const bannerStyleProp = getStyleProp(error);
  const formTitle = formType.toLowerCase();

  return (
    messages.length > 0 && (
      <Box
        backgroundColor={bannerStyleProp.backgroundColor}
        padding="one"
        marginTop="one"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Flex alignItems="center">
            <Flex width="one" height="one">
              <img
                src={bannerStyleProp.icon}
                alt={severity}
                width="100%"
                height="100%"
              />
            </Flex>
            <BodyOne marginLeft="half">
              {error
                ? formatMessage(
                    { id: 'FORM_VALIDATION_ERROR_HEADER' },
                    {
                      errorCount: messages.length,
                      formTitle,
                      // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
                      strong: msg => <strong>{msg}</strong>,
                    },
                  )
                : formatMessage(
                    { id: 'FORM_VALIDATION_WARNING_HEADER' },
                    {
                      warningCount: messages.length,
                      formTitle,
                      // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
                      strong: msg => <strong>{msg}</strong>,
                    },
                  )}
            </BodyOne>
          </Flex>
          {isExpandable && (
            <Chevron
              isExpanded={isExpanded}
              icon={ICONS.CHEVRON_RIGHT}
              colour={uiKitTheme.colors.purpleDarkest}
              size={16}
            />
          )}
        </Flex>
        {(!isExpandable || isExpanded) && (
          <Box marginTop="half">
            {messages.map(message => (
              <Li as="li">{message}</Li>
            ))}
          </Box>
        )}
      </Box>
    )
  );
};

FormValidationBanner.propTypes = {
  severity: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  formType: PropTypes.string.isRequired,
};

FormValidationBanner.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  formType: '',
};
