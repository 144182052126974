import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik-legacy';
import { createValidator } from 'common/adaptive-forms';
import { emailSchema } from '../../validation-schema';
import { selectGroupId, selectBillingUpdateStatus } from '../../selectors';
import {
  groupBillingContactFormSubmitted,
  resetUpdateStatus,
} from '../../actions';
import Contact from './presenter';

const withFormikEnhancer = Formik({
  handleSubmit: (values, { props }) => {
    const emailValue = values.billing_email
      ? values.billing_email
      : props.billing_email;

    props.groupBillingContactFormSubmitted({
      groupId: props.groupId,
      billingEmail: emailValue,
    });
  },
  mapPropsToValues: props => {
    const billingContact = props.billingContact ? props.billingContact : '';
    const formValues = {
      billing_email: billingContact,
      changed: false,
    };
    return formValues;
  },
  validate: createValidator(emailSchema),
});

const mapStateToProps = state => ({
  groupId: selectGroupId(state),
  updateStatus: selectBillingUpdateStatus(state),
  resetUpdateStatus,
});

export default compose(
  connect(mapStateToProps, {
    groupBillingContactFormSubmitted,
    resetUpdateStatus,
  }),
  withFormikEnhancer,
)(Contact);
