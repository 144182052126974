import React from 'react';
import PropTypes from 'prop-types';
import { withFieldHints } from './FieldHints';
import { MaskedNINInput } from 'apps/employer-experience/ui-kit';

export const Masked = ({
  name,
  value,
  placeholder,
  readOnly,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <MaskedNINInput
      name={name}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      onBlur={handleBlur}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
      autoComplete="off"
    />
  );
};

Masked.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

Masked.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  value: '',
  placeholder: '',
};

export default withFieldHints(Masked);
