import { combineReducers } from 'redux';
import { GET_RECEIPT_RESOURCES } from './receipt.actions';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const ready = (state = false, action) => {
  switch (action?.type) {
    case GET_RECEIPT_RESOURCES.STARTED:
      return false;
    case GET_RECEIPT_RESOURCES.SUCCEEDED:
      return true;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const data = (state = {}, action) => {
  switch (action?.type) {
    case GET_RECEIPT_RESOURCES.SUCCEEDED:
      return action.payload;
    default:
      return state;
  }
};

export const receiptReducer = combineReducers({
  ready,
  data,
});
