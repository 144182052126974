import { request } from 'common/websocket-redux';
import { all, fork, takeLatest, call } from 'redux-saga/effects';
import {
  getIdentityProviderForUser,
  getUserProfile,
  setUserProfile,
} from 'common/services';
import { changeEmail } from 'common/services/change-email.service';
import { changePhoneNumber } from 'common/services/change-phone-number.service';
import {
  GET_IDENTITY_PROVIDER_FOR_USER,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBER,
} from './user-profile.actions';

import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestIdentityProviderForUser({ payload: { userId } }) {
  yield request(
    GET_IDENTITY_PROVIDER_FOR_USER,
    getIdentityProviderForUser(userId),
    { userId },
  );
}

export function* requestUserProfile({ payload: { userId } }) {
  yield request(GET_USER_PROFILE, getUserProfile(userId), { userId });
}

export function* updateUserProfile({ payload: { userId, userProfile } }) {
  yield request(UPDATE_USER_PROFILE, setUserProfile(userProfile), { userId });
}

export function* showEmailResetSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'EMAIL_UPDATE_SUCCESS',
  });
}

export function* showEmailResetErrorToast(action) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: action?.payload?.info?.reason,
  });
}

export function* showPhoneNumberResetSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'PHONE_NUMBER_UPDATE_SUCCESS',
  });
}

export function* showPhoneNumberResetErrorToast(action) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: action?.payload?.info?.reason,
  });
}

export function* requestChangeEmail({ payload: { userId, email } }) {
  yield request(CHANGE_EMAIL, changeEmail(userId, email), { userId, email });
}

export function* requestChangePhoneNumber({
  payload: { userId, confirmPhoneNumber, newPhoneCountryCode },
}) {
  yield request(
    CHANGE_PHONE_NUMBER,
    changePhoneNumber(userId, confirmPhoneNumber, newPhoneCountryCode),
    { userId, confirmPhoneNumber, newPhoneCountryCode },
  );
}

export function* userProfileSaga() {
  yield fork(all, [
    takeLatest(GET_USER_PROFILE.BASE, requestUserProfile),
    takeLatest(
      GET_IDENTITY_PROVIDER_FOR_USER.BASE,
      requestIdentityProviderForUser,
    ),
    takeLatest(UPDATE_USER_PROFILE.BASE, updateUserProfile),
    takeLatest(CHANGE_EMAIL.BASE, requestChangeEmail),
    takeLatest(CHANGE_EMAIL.SUCCEEDED, showEmailResetSuccessToast),
    takeLatest(CHANGE_EMAIL.ERRORED, showEmailResetErrorToast),
    takeLatest(CHANGE_PHONE_NUMBER.BASE, requestChangePhoneNumber),
    takeLatest(CHANGE_PHONE_NUMBER.SUCCEEDED, showPhoneNumberResetSuccessToast),
    takeLatest(CHANGE_PHONE_NUMBER.ERRORED, showPhoneNumberResetErrorToast),
  ]);
}
