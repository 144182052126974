import EmployerTable from './presenter';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLoading, selectError, selectEmployers } from '../selectors';

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  error: selectError,
  employers: selectEmployers,
});

export default connect(mapStateToProps)(EmployerTable);
