import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_EMPLOYER_ENROLMENT_REPORT = createRequestTypes(
  'services/GET_EMPLOYER_ENROLMENT_REPORT',
);

export const FETCH_EMPLOYER_ENROLMENT_REPORT =
  'employer-reports/FETCH_EMPLOYER_ENROLMENT_REPORT';

export const ENROLMENT_REPORT_LIFECYCLE = createLifecycleTypes(
  'employer-reports/ENROLMENT_REPORT_LIFECYCLE',
);

export const GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT = createRequestTypes(
  'employer-reports/GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT',
);

export const FETCH_EMPLOYER_ENROLMENT_PROGRESS_REPORT =
  'employer-reports/FETCH_EMPLOYER_ENROLMENT_PROGRESS_REPORT';

export const ENROLMENT_PROGRESS_REPORT_LIFECYCLE = createLifecycleTypes(
  'employer-reports/ENROLMENT_PROGRESS_REPORT_LIFECYCLE',
);

export const GET_EMPLOYEE_DEMOGRAPHICS_REPORT = createRequestTypes(
  'employer-reports/GET_EMPLOYEE_DEMOGRAPHICS_REPORT',
);

export const FETCH_EMPLOYEE_DEMOGRAPHICS_REPORT =
  'employer-reports/FETCH_EMPLOYEE_DEMOGRAPHICS_REPORT';

export const EMPLOYEE_DEMOGRAPHICS_REPORT_LIFECYCLE = createLifecycleTypes(
  'employer-reports/EMPLOYEE_DEMOGRAPHICS_REPORT_LIFECYCLE',
);

export const GET_EMPLOYER_HR_DRIVERS = createRequestTypes(
  'employer-reports/GET_EMPLOYER_HR_DRIVERS',
);

export const EMPLOYER_REPORTS_LIFECYCLE = createLifecycleTypes(
  'employer-reports/EMPLOYER_REPORTS_LIFECYCLE',
);

export const FETCH_EMPLOYER_BENEFITS_REPORT =
  'employer-reports/FETCH_EMPLOYER_BENEFITS_REPORT';

export const GET_EMPLOYER_BENEFITS_REPORT = createRequestTypes(
  'employer-reports/GET_EMPLOYER_BENEFITS_REPORT',
);

export const EMPLOYER_BENEFITS_REPORT_LIFECYCLE = createLifecycleTypes(
  'employer-reports/EMPLOYER_BENEFITS_REPORT_LIFECYCLE',
);

export const GET_BENEFICIARIES_REPORT = createRequestTypes(
  'employer-reports/GET_BENEFICIARIES_REPORT',
);

export const EMPLOYER_BENEFICIARIES_REPORT_LIFECYCLE = createLifecycleTypes(
  'employer-reports/EMPLOYER_BENEFITS_REPORT_LIFECYCLE',
);
