export default {
  gwlBestDoctors: {
    name: 'gwlBestDoctors',
    groups: [
      {
        position: 0,
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            position: 0,
            span: 6,
          },
          {
            name: 'benefit_end_date',
            position: 1,
            span: 6,
          },
        ],
      },
      {
        position: 1,
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            position: 0,
            span: 12,
          },
        ],
      },
      {
        position: 1,
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            position: 0,
            span: 3,
          },
          {
            name: 'policy_id',
            position: 0,
            span: 3,
          },
          {
            name: 'opt_out',
            span: 3,
          },

          {
            name: 'auto_renew',
            span: 3,
          },
        ],
      },
    ],
  },
};
