/**
 * Requires refactoring. This file should eventually just import each saga from its components.
 */
import { takeLatest, fork, put, call } from 'redux-saga/effects';
import { setDefaultAccount } from 'common/services';
import { getUserPaymentCredentials } from 'apps/member-experience/resources/payment-credentials/payment-credentials.actions';
import { SET_DEFAULT_ACCOUNT } from './banking-info.actions';
import { REQUEST_DELETE_DIRECT_DEPOSIT } from './direct-deposit-account/delete-direct-deposit/delete-direct-deposit.types';
import {
  REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT,
  REQUEST_GENERATE_INTERAC_CODE,
} from './direct-deposit-account/account-info-form/account-info-form.types';
import { bankingInfoAnalyticsSaga } from './banking-info-analytics.sagas';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestUserPaymentCredentials() {
  yield put(getUserPaymentCredentials()); // Emit the action to trigger payment-credentials.saga in resources
}

export function* requestSetDefaultAccount({
  payload: { paymentCredentialsId },
}) {
  try {
    yield setDefaultAccount(paymentCredentialsId);
    yield call(requestUserPaymentCredentials);
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'DEFAULT_PAYEE_SUCCESS',
    });
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'DEFAULT_PAYEE_ERROR',
    });
  }
}

export function* bankingInfoSagas() {
  yield fork(
    takeLatest,
    [
      REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.SUCCEEDED,
      REQUEST_GENERATE_INTERAC_CODE.SUCCEEDED,
      REQUEST_DELETE_DIRECT_DEPOSIT.SUCCEEDED,
    ],
    requestUserPaymentCredentials,
  );
  yield fork(takeLatest, SET_DEFAULT_ACCOUNT, requestSetDefaultAccount);
  yield fork(bankingInfoAnalyticsSaga);
}
