import { GET_QLE_REQUEST, HANDLE_NEX } from './qle-request.action-types';

export const getQleRequest = ({ qleId }) => ({
  type: GET_QLE_REQUEST,
  payload: {
    qleId,
  },
});

export const handleNEX = ({ qleRequestId }) => ({
  type: HANDLE_NEX,
  payload: {
    qleRequestId,
  },
});
