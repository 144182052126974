/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  ADMIN_GET_EMPLOYEE_SCHEMA,
  ADMIN_GET_EMPLOYEE,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EDIT_RESOURCES,
  EMPLOYEE_ROW_SELECTED,
  CLEAR_EMPLOYEE_DETAILS,
  GET_USER_DEDUCTIONS,
  UPDATE_USER_DEDUCTIONS,
  GET_USER_ADJUSTMENTS,
} from './types';
import {
  VIEWING_BULK_ADD_EMPLOYEE,
  BULK_ADD_EMPLOYEES,
} from '../BulkAddEmployees/types';

export const initialState = {
  employeeFormFieldsSchema: null,
  employeeEditId: null,
  employeeEditUser: null,
  employeeSaveError: null,
  bulkUploading: null,
  createEmployeeLoading: false,
  employeeGetError: null,
};

const applySuccessFetchingEmployeeRequiredFields = (state, action) => ({
  ...state,
  employeeFormFieldsSchema: action.payload.info,
});

export const applySuccessFetchingEmployeeProfile = (state, action) => ({
  ...state,
  employeeProfile: action.payload.info,
  employeeProfileReady: true,
});

export const applyCreateError = (state, action) => ({
  ...state,
  employeeSaveError: action.payload.info.reason,
  createEmployeeLoading: false,
});

export const applyUpdateError = (state, action) => ({
  ...state,
  employeeSaveError: action.payload.info.reason,
});
export const createEmployeeSucceeded = (state, action) => ({
  ...state,
  createEmployeeLoading: false,
  employeeEditId: action.payload.userId,
  employeeInviteURL: action.payload.inviteURL,
  employeeProfileReady: false,
  employeeSaveError: null,
  employeeStatus: action.payload.status,
});

export const getEditResourcesSucceeded = (state, action) => ({
  ...state,
  employeeFormFieldsSchema: action.payload.schema,
  employeeEditUser: action.payload.employee,
  employeeProfileReady: true,
});

export const applyViewingBulkAddEmployees = state => ({
  ...state,
  bulkAddEmployees: {},
});

export const applyBulkAppEmployeesStarted = state => ({
  ...state,
  bulkUploading: true,
});

export const applyBulkAppEmployeesSucceeded = (state, { payload }) => ({
  ...state,
  bulkAddEmployees: payload.info || {},
  bulkUploading: null,
});

export const applyBulkAppEmployeesErrored = (state, { payload }) => ({
  ...state,
  bulkAddEmployees:
    {
      errors: payload.info,
    } || {},
  bulkUploading: null,
});

export const applyLoadingState = state => ({
  ...state,
  createEmployeeLoading: true,
});

export const applyErrorFetchingEmployeeProfile = state => ({
  ...state,
  employeeGetError: true,
});

export const clearEmployeeDetails = state => ({
  ...state,
  employeeGetError: false,
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employeeReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case ADMIN_GET_EMPLOYEE_SCHEMA.SUCCEEDED:
      return applySuccessFetchingEmployeeRequiredFields(state, action);
    case CREATE_EMPLOYEE.STARTED:
      return applyLoadingState(state, action);
    case EMPLOYEE_ROW_SELECTED:
    case CREATE_EMPLOYEE.SUCCEEDED:
      return createEmployeeSucceeded(state, action);
    case CREATE_EMPLOYEE.ERRORED:
      return applyCreateError(state, action);
    case UPDATE_EMPLOYEE.ERRORED:
      return applyUpdateError(state, action);
    case ADMIN_GET_EMPLOYEE.SUCCEEDED:
      return applySuccessFetchingEmployeeProfile(state, action);
    case ADMIN_GET_EMPLOYEE.ERRORED:
      return applyErrorFetchingEmployeeProfile(state, action);
    case GET_EDIT_RESOURCES.SUCCEEDED:
      return getEditResourcesSucceeded(state, action);
    case VIEWING_BULK_ADD_EMPLOYEE:
      return applyViewingBulkAddEmployees(state);
    case BULK_ADD_EMPLOYEES.STARTED:
      return applyBulkAppEmployeesStarted(state, action);
    case BULK_ADD_EMPLOYEES.SUCCEEDED:
      return applyBulkAppEmployeesSucceeded(state, action);
    case BULK_ADD_EMPLOYEES.ERRORED:
      return applyBulkAppEmployeesErrored(state, action);
    case CLEAR_EMPLOYEE_DETAILS:
      return clearEmployeeDetails(state);
    default:
      return state;
  }
};

export const userDeductionsState = {
  user_deductions: [],
};

const compareUserDeductions = (a, b) => {
  return (
    a?.benefit_type === b?.benefit_type &&
    a?.plan_period?.start_date === b?.plan_period?.start_date &&
    a?.plan_period?.end_date === b?.plan_period?.end_date &&
    a?.pay_period?.start_date === b?.pay_period?.start_date &&
    a?.pay_period?.end_date === b?.pay_period?.end_date
  );
};
// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const payrollDeductions = (state = userDeductionsState, action) => {
  switch (action?.type) {
    case GET_USER_DEDUCTIONS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      const user_deductions = action?.payload?.info?.user_deductions || [];
      return {
        user_deductions,
      };
    case UPDATE_USER_DEDUCTIONS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const userDeductionsUpdate = action?.payload?.info;
      return {
        user_deductions: state.user_deductions.map(userDeduction => {
          return compareUserDeductions(userDeduction, userDeductionsUpdate)
            ? userDeductionsUpdate
            : userDeduction;
        }),
      };
    default:
      return state;
  }
};

export const userAdjustmentState = {
  user_adjustments: [],
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const payrollAdjustments = (state = userAdjustmentState, action) => {
  switch (action?.type) {
    case GET_USER_ADJUSTMENTS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      const user_adjustments = action?.payload?.info?.user_adjustments || [];
      return {
        user_adjustments,
      };
    default:
      return state;
  }
};
