import { get } from 'lodash';
import {
  GET_EMPLOYEE_USER_PROFILE,
  SHOULD_REFETCH_EMPLOYEE,
} from './profile.types';

export const initialState = {
  data: null,
  employeeFormSchema: null,
  employeeFormData: null,
  shouldRefetch: false,
};

const applyGetUserStarted = state => ({
  ...state,
  data: null,
});

const applyGetUserSucceeded = (state, action) => ({
  ...state,
  data: get(action, 'payload.info') || {},
});

export const employeeProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMPLOYEE_USER_PROFILE.STARTED:
      return applyGetUserStarted(state);
    case GET_EMPLOYEE_USER_PROFILE.SUCCEEDED:
      return applyGetUserSucceeded(state, action);
    case SHOULD_REFETCH_EMPLOYEE.SUCCEEDED:
      return {
        ...state,
        shouldRefetch: action.payload,
      };
    default:
      return state;
  }
};
