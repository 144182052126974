import { createRequestTypes } from 'common/websocket-redux';

export const GET_WALLET_ACTIONS = createRequestTypes(
  'service/GET_WALLET_ACTIONS',
);

export const EDIT_BENEFIT_WALLET_VISITED =
  'benefits/EDIT_BENEFIT_WALLET_VISITED';

export const BENEFIT_WALLET_DETAILS_CLEARED =
  'benefits/BENEFIT_WALLET_DETAILS_CLEARED';

export const BENEFIT_WALLET_ACTIONS_SUBMITTED =
  'benefits/BENEFIT_WALLET_ACTIONS_SUBMITTED';

export const REMOVE_BENEFIT_WALLET_ACTIONS_REQUESTED =
  'benefits/REMOVE_BENEFIT_WALLET_ACTIONS_REQUESTED';

export const SET_PROVIDER_WALLET_ACTIONS = createRequestTypes(
  'service/SET_PROVIDER_WALLET_ACTIONS',
);

export const PROVIDER_BENEFIT_SELECTED = 'benefits/PROVIDER_BENEFIT_SELECTED';

export const WALLET_OVERVIEW_SUBMITTED = 'benefits/WALLET_OVERVIEW_SUBMITTED';

export const USAGE_INSTRUCTIONS_SUBMITTED =
  'benefits/USAGE_INSTRUCTIONS_SUBMITTED';
