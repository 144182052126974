import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  HeadingThree,
  BodyOne,
  SubtitleOne,
  QuietButton,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { Modal } from 'apps/employer-experience/ui-kit';
import {
  selectCurrentPlanName,
  selectCurrentPlanId,
  selectCurrentPlanBenefitClassName,
} from '../../../enrollment-config.selectors';
import { startEnrollment } from '../../../enrollment-config.actions';
import {
  selectEmployerName,
  selectEmployerId,
} from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { PlanValidation } from '../plan-validation/plan-validation.view';

export const useStartEnrollmentProps = ({
  startEnrollment: onStartEnrollmentConfirm,
  onClose: onCancel,
  planId,
  groupId,
  ...props
}) => {
  const onConfirm = useCallback(() => {
    onStartEnrollmentConfirm({ planId, groupId });
    onCancel();
  }, [onStartEnrollmentConfirm, onCancel, planId, groupId]);

  return {
    ...props,
    onCancel,
    onConfirm,
  };
};

export const StartEnrollmentPresenter = ({
  employerName,
  benefitClassName,
  planName,
  ...props
}) => {
  const { onConfirm, onCancel } = useStartEnrollmentProps(props);
  const { formatMessage } = useIntl();
  return (
    <Modal>
      <Flex width={550} flexDirection="column">
        <HeadingThree>
          {formatMessage({ id: 'CONFIRM_START_ENROLLMENT' })}
        </HeadingThree>

        <BodyOne marginY="half">
          {formatMessage({ id: 'OPEN_ENROLLMENT_TEXT' }, { employerName })}
        </BodyOne>
        <PlanValidation />
        <Box marginTop="oneAndHalf" marginBottom="half">
          <Flex>
            <Box width={65}>
              <BodyOne>{formatMessage({ id: 'CLASS' })}:</BodyOne>
            </Box>
            <SubtitleOne>{benefitClassName}</SubtitleOne>
          </Flex>
          <Flex>
            <Box width={65}>
              <BodyOne>{formatMessage({ id: 'PLAN' })}:</BodyOne>
            </Box>
            <SubtitleOne>{planName}</SubtitleOne>
          </Flex>
        </Box>
        <Flex justifyContent="flex-end">
          <QuietButton onClick={() => onCancel()} marginRight="one">
            {formatMessage({ id: 'CANCEL' })}
          </QuietButton>
          <PrimaryButton onClick={() => onConfirm()}>
            {formatMessage({ id: 'CONFIRM' })}
          </PrimaryButton>
        </Flex>
      </Flex>
    </Modal>
  );
};

StartEnrollmentPresenter.propTypes = {
  employerName: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  benefitClassName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  groupId: selectEmployerId,
  planId: selectCurrentPlanId,
  planName: selectCurrentPlanName,
  benefitClassName: selectCurrentPlanBenefitClassName,
  employerName: selectEmployerName,
});

const mapDispatchToProps = { startEnrollment };

export const StartEnrollment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartEnrollmentPresenter);
