import { connect } from 'react-redux';
import { DeductionCodeFormPresenter } from './deduction-code-form.view';
import { selectPayrollSchedules } from '../../resources/deduction-codes/deduction-codes.selectors';
import { createStructuredSelector } from 'reselect';

export const DeductionCodeForm = connect(
  createStructuredSelector({
    payrollSchedules: selectPayrollSchedules,
  }),
)(DeductionCodeFormPresenter);
