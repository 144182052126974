import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RequestPremiumsReport } from './request-premiums-report.view';
import { getPremiumsReport } from './premiums-report.actions';
import { selectPayrollSchedulesWithReportName } from './premiums-report.selectors';

const mapStateToProps = createStructuredSelector({
  payrollSchedulesWithReportName: selectPayrollSchedulesWithReportName,
});

const mapDispatchToProps = {
  getPremiumsReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestPremiumsReport);
