import { takeLatest, put, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { get } from 'lodash';
import {
  getProviderWalletActions,
  setProviderWalletAction,
} from 'common/services';
import {
  EDIT_BENEFIT_WALLET_VISITED,
  USAGE_INSTRUCTIONS_SUBMITTED,
  GET_WALLET_ACTIONS,
  BENEFIT_WALLET_ACTIONS_SUBMITTED,
  REMOVE_BENEFIT_WALLET_ACTIONS_REQUESTED,
  SET_PROVIDER_WALLET_ACTIONS,
  WALLET_OVERVIEW_SUBMITTED,
} from './benefit-wallet.types';
import {
  USAGE_INSTRUCTIONS,
  OVERVIEW,
  REMOVE_WALLET_ACTION,
} from './constants';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* showToastMessage(action) {
  const { type, textId } = action;
  yield call(toastActions.add, {
    type,
    textId,
  });
}

export function* setProviderWalletActions(action) {
  yield request(
    SET_PROVIDER_WALLET_ACTIONS,
    setProviderWalletAction(action.payload),
    { actionType: action.meta.actionType },
  );
}

export function* showSetProviderWalletActionsSuccessToast({ meta = {} }) {
  let textId = 'SAVE_WALLET_ACTION_SUCCESS_MESSAGE';
  switch (get(meta, 'actionType', '')) {
    case USAGE_INSTRUCTIONS:
      textId = 'USAGE_INSTRUCTIONS_SUCCESS';
      break;
    case OVERVIEW:
      textId = 'WALLET_OVERVIEW_SUCCESS';
      break;
    case REMOVE_WALLET_ACTION:
      textId = 'REMOVE_WALLET_ACTION_SUCCESS_MESSAGE';
      break;
    default:
      break;
  }
  if (meta.actionType) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId,
    });
  }
}

export function* showSetProviderWalletActionsErrorToast({ meta = {} }) {
  let textId = 'SAVE_WALLET_ACTION_ERROR_MESSAGE';
  switch (get(meta, 'actionType', '')) {
    case USAGE_INSTRUCTIONS:
      textId = 'USAGE_INSTRUCTIONS_ERROR';
      break;
    case OVERVIEW:
      textId = 'WALLET_OVERVIEW_ERROR';
      break;
    case REMOVE_WALLET_ACTION:
      textId = 'REMOVE_WALLET_ACTION_ERROR_MESSAGE';
      break;
    default:
      break;
  }
  if (meta.actionType) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId,
    });
  }
}

export function* fetchWalletActions(action) {
  try {
    const { groupId } = action.payload;
    yield request(GET_WALLET_ACTIONS, getProviderWalletActions(groupId));
  } catch (error) {
    yield put({ type: GET_WALLET_ACTIONS.ERRORED, error });
  }
}

export function* setWalletActions({ payload: { info, key } }) {
  try {
    yield put({ type: SET_PROVIDER_WALLET_ACTIONS.STARTED });

    const response = yield setProviderWalletAction(info);
    yield put(SET_PROVIDER_WALLET_ACTIONS.success({ ...response, key }));
  } catch (error) {
    yield put(SET_PROVIDER_WALLET_ACTIONS.error({ error, key }));
  }
}

export function* employerBenefitWalletSaga() {
  yield takeLatest(EDIT_BENEFIT_WALLET_VISITED, fetchWalletActions);
  yield takeLatest(WALLET_OVERVIEW_SUBMITTED, setProviderWalletActions);
  yield takeLatest(USAGE_INSTRUCTIONS_SUBMITTED, setProviderWalletActions);
  yield takeLatest(
    SET_PROVIDER_WALLET_ACTIONS.SUCCEEDED,
    showSetProviderWalletActionsSuccessToast,
  );
  yield takeLatest(
    SET_PROVIDER_WALLET_ACTIONS.ERRORED,
    showSetProviderWalletActionsErrorToast,
  );
  yield takeLatest(BENEFIT_WALLET_ACTIONS_SUBMITTED, setWalletActions);
  yield takeLatest(
    REMOVE_BENEFIT_WALLET_ACTIONS_REQUESTED,
    setProviderWalletActions,
  );
}
