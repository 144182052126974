import React from 'react';
import { fieldProps } from './field-shape';
import { Select } from 'common/form-fields/Select/Select';
import { useIntl } from '@leagueplatform/locales';

export const SelectField = ({
  field: { name, ...field },
  form: { handleBlur, setFieldValue },
  disabled,
  readOnly,
  placeholder,
  label,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const formattedLabel = formatMessage({ id: label });

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...field}
      name={name}
      placeholder={placeholder ?? formatMessage({ id: 'SELECT_ONE' })}
      disabled={Boolean(disabled ?? readOnly)}
      aria-label={formattedLabel}
      onBlur={({ value }) => {
        handleBlur({ target: { value, name } });
      }}
      onChange={evt => {
        const value = evt ? evt.value : null;
        setFieldValue(name, value);
      }}
      data-testid={`select_field_${name}`}
    />
  );
};

SelectField.propTypes = fieldProps;
