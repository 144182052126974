import { request } from 'common/websocket-redux';
import { takeLatest } from 'redux-saga/effects';
import { getMapleUser } from 'common/services/get-maple-user.service';
import { GET_MAPLE_USER } from './maple-user.actions';

export function* requestMapleUser({ payload: { userId } }) {
  yield request(GET_MAPLE_USER, getMapleUser(userId), { userId });
}

export function* mapleUserSaga() {
  yield takeLatest(GET_MAPLE_USER.BASE, requestMapleUser);
}
