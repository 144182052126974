import styled, { css } from 'styled-components';
import { getThemeValue, vars } from '@leagueplatform/ui-kit';
import { Button as baseButton } from 'common/components/button/button.view';
import { remPx } from './theme';

const {
  darkRed,
  greyLight,
  lightMediumGrey,
  purple,
  purpleLighter,
  purpleText,
  red,
  white,
  darkRose,
} = vars.colour;

const confirmModalRules = css`
  height: 3rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 1rem;
`;

const cancelRules = css`
  background-color: ${lightMediumGrey};
  border-color: ${lightMediumGrey};
  color: ${purpleText};
  &:hover:not([disabled]) {
    background-color: ${greyLight};
    border-color: ${greyLight};
    color: ${purpleText};
  }
`;

const warningRules = css`
  background-color: ${red};
  color: ${white};
  border-color: ${red};
  &:hover:not([disabled]) {
    border-color: ${darkRed};
    background-color: ${darkRed};
  }
  &[disabled] {
    background-color: ${darkRose};
    border-color: ${darkRose};
    color: ${white};
    cursor: initial;
  }
`;

const primaryDisabled = css`
  &[disabled] {
    background-color: ${purpleLighter};
    border-color: ${purpleLighter};
    color: ${white};
    cursor: initial;
  }
`;

const transparentRules = css`
  border: none;
  padding: 0;
  color: ${purple};
  background-color: transparent;
  height: unset;

  &:hover:not([disabled]) {
    background-color: transparent;
    border: none;
  }
`;

export const Button = styled(baseButton)`
  max-height: ${remPx(58)};
  padding-left: ${remPx(39)};
  padding-right: ${remPx(39)};
  font-size: ${getThemeValue('fontSizes.2')}px;
  font-weight: 500;

  ${({ confirmModal }) => confirmModal && confirmModalRules}
  ${({ primary }) => primary && primaryDisabled}
  ${({ cancel }) => cancel && cancelRules}
  ${({ warning }) => warning && warningRules}
  ${({ transparent }) => transparent && transparentRules}
`;
