/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { provinceStateShortToLong } from 'common/constants';
import { SALESFORCE_VISITOR_LANGUAGES } from './locale.constants';
import { HTML_REFS } from './chat-window.constants';
import { isSpanish, isFrench } from '@leagueplatform/locales';
import { postMessage } from 'common/utilities/post-message-helpers';

export function configureLiveagentDetails({
  chatMap,
  firstName,
  lastName,
  province,
  userId,
  chatConfig,
  agentConfig,
}) {
  const validatedProvince = provinceStateShortToLong[province] ?? province;
  const language = getVisitorLanguage();

  window.liveagent
    .addCustomDetail('Contact ID', chatMap.contact_id)
    .saveToTranscript('ContactId');
  window.liveagent
    .addCustomDetail('Signature', chatMap.signature)
    .saveToTranscript('Signature__c');
  window.liveagent
    .addCustomDetail('Chat Token', chatMap.chat_token)
    .saveToTranscript('Chat_Token__c');
  window.liveagent.addCustomDetail('Case Status', 'New');
  window.liveagent.addCustomDetail(
    'Case Subject',
    `Live Chat with ${firstName} ${lastName}`,
  );

  // The below value is used in the pre-chat form
  window.liveagent.addCustomDetail('League Province', validatedProvince);
  window.liveagent.addCustomDetail('League Visitor Language', language);
  window.liveagent.addCustomDetail('League User Id', userId);

  window.liveagent
    .findOrCreate('Contact')
    .map('Id', 'Contact ID', true, true, false)
    .map('League_User_Id__c', 'League User Id', true, true, false)
    .saveToTranscript('ContactId');

  window.liveagent
    .findOrCreate('Case')
    .map('Subject', 'Case Subject', false, true, true)
    .map('Status', 'Case Status', false, true, true)
    .map('ContactId', 'Contact ID', false, true, true)
    .saveToTranscript('CaseId')
    .showOnCreate();

  // Set member's display name as seen by the agent
  window.liveagent.setName(firstName);

  // initialize salesforce stuff required before starting chat
  window.liveagent.init(
    chatConfig.live_agent_endpoint,
    agentConfig.deployment_id,
    chatConfig.organization_id,
  );

  if (!window._laq) window._laq = []; // eslint-disable-line no-underscore-dangle

  // eslint-disable-next-line no-underscore-dangle
  window._laq.push(() => {
    window.liveagent.showWhenOnline(
      agentConfig.button_id,
      document.getElementById(HTML_REFS.CHAT_ONLINE),
    );
    window.liveagent.showWhenOffline(
      agentConfig.button_id,
      document.getElementById(HTML_REFS.CHAT_OFFLINE),
    );
  });
}

export function checkLiveagentAvailability(buttonId) {
  return new Promise((resolve, reject) => {
    // important, without these values set up, salesforce chat fails to start
    window.liveagent.addButtonEventHandler(buttonId, event => {
      if (event === window.liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
        resolve();
      }
      if (event === window.liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
        reject(new Error('No agents are available'));
      }
    });
  });
}

export function startLiveagentChat(buttonId) {
  // Target an iframe with a specific button id for salesforce to take over
  window.liveagent.startChatWithWindow(buttonId, HTML_REFS.CHAT_IFRAME);
}

/**
 * This pushed a message up to the top window where the idle-timer is listening
 * for posted messages and resets privacy lock timer.
 */
export function resetPrivacyLock() {
  //  Currently the idle-timer listener isn't looking for a specific message,
  //  so 'chat_user_activity' is in place solely as a clarification of the message origin.
  postMessage(window.top, 'chat_user_activity');
}

export function getVisitorLanguage() {
  if (isFrench()) {
    return SALESFORCE_VISITOR_LANGUAGES.FRENCH;
  }
  if (isSpanish()) {
    return SALESFORCE_VISITOR_LANGUAGES.SPANISH;
  }

  return SALESFORCE_VISITOR_LANGUAGES.ENGLISH;
}
