import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CarrierAuditsReportsPresenter } from './carrier-audits-report.view';
import {
  getCarrierAuditsReport as getCarrierAuditsReportAction,
  getAuditFlows as getAuditFlowsAction,
} from './carrier-audits-report.actions';
import {
  selectCarrierAuditReportLoading,
  selectAuditFlows,
} from './carrier-audits-report.selectors';

const mapStateToProps = createStructuredSelector({
  carrierAuditReportLoading: selectCarrierAuditReportLoading,
  auditFlows: selectAuditFlows,
});

const mapDispatchToProps = {
  getCarrierAuditsReport: getCarrierAuditsReportAction,
  getAuditFlows: getAuditFlowsAction,
};

export const CarrierAuditsReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ getAuditFlows, ...props }) => {
  useEffect(() => {
    getAuditFlows();
  }, [getAuditFlows]);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <CarrierAuditsReportsPresenter {...props} />;
});
