import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { selectIsRootLoading, selectRootRedirect } from './root.selectors';
import { getCookieRedirectUrl, removeRedirectUrl } from 'apps/auth/auth.utils';

// Exclude the root app paths from redirect URL.
// If you're already in root app, you don't need to get redirected to it again.
const ROOT_PATHS = ['/', '/app', '/app/'];
const getRedirectPath = () => {
  const cookieRedirectUrl = getCookieRedirectUrl();
  const path = cookieRedirectUrl?.replace(/^\/app/, ''); // to avoid the double `/app` in the path
  return ROOT_PATHS.includes(path) ? null : path;
};

export const RootRoutes = () => {
  const isLoading = useSelector(selectIsRootLoading);
  const redirectPath = getRedirectPath();
  const { Component } = useSelector(state =>
    selectRootRedirect(state, redirectPath),
  );

  if (redirectPath) removeRedirectUrl(); // make sure cookie is cleared after page is loaded

  if (isLoading) return <LoadingIndicator />;

  return <Component />;
};
