import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { selectBenefitClasses } from '../selectors';

export const selectQLEConfiguration = get(
  'apps.employer-experience.pages.qleConfiguration',
);

export const selectSelectedBenefitClassId = createSelector(
  selectQLEConfiguration,
  get('benefitClassId'),
);

export const selectSelectedQLEType = createSelector(
  selectQLEConfiguration,
  get('qleType'),
);

export const selectSelectedQLEConfigurationId = createSelector(
  selectQLEConfiguration,
  get('qleConfigurationId'),
);

export const selectQLEConfigurations = createSelector(
  selectQLEConfiguration,
  get('qleConfigurations'),
);

export const selectQLEApprovalEmails = createSelector(
  selectQLEConfiguration,
  get('qleApprovalEmails'),
);

export const selectSelectedQLEConfiguration = createSelector(
  selectSelectedQLEConfigurationId,
  selectQLEConfigurations,
  (qleConfigurationId, qleConfigurations) => {
    if (qleConfigurationId && qleConfigurations) {
      const result = qleConfigurations.filter(
        config => config.qleConfigurationId === qleConfigurationId,
      );
      return result[0];
    }
    return null;
  },
);

export const selectSelectedBenefitClass = createSelector(
  selectBenefitClasses,
  selectSelectedBenefitClassId,
  (benefitClasses, benefitClassId) =>
    benefitClasses.find(benefitClass => benefitClass.id === benefitClassId),
);
