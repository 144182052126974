import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

const prefix = 'admin/payroll';

export const PAYROLL_FEATURE_FLAG = 'payroll_px_tab';

export const GET_RATE_SHEET_EXTRACT = createRequestTypes(
  'services/GET_RATE_SHEET_EXTRACT',
);

export const GET_USER_BENEFITS = createRequestTypes(
  'services/GET_USER_BENEFITS',
);

export const GET_USER_BENEFIT_CONTRIBUTIONS = createRequestTypes(
  'services/GET_USER_BENEFIT_CONTRIBUTIONS',
);

export const EMPLOYEE_TASA_DEDUCTIONS_LIFECYCLE = createLifecycleTypes(
  'admin/employer-experience/EMPLOYEE_TASA_DEDUCTIONS_LIFECYCLE',
);

export const GET_REPORTS = createRequestTypes('services/GET_REPORTS');

export const LOAD_PREMIUMS_EXPORTS = createRequestTypes(
  `${prefix}/LOAD_PREMIUMS_EXPORTS`,
);

export const GET_PAYROLL_FEATURE_FLAG = createRequestTypes(
  `${prefix}/GET_PAYROLL_FEATURE_FLAG`,
);

export const GET_YEAR_TO_DATE_REPORT = createRequestTypes(
  'services/GET_YEAR_TO_DATE_REPORT',
);

export const GET_REPORT_PRESETS = createRequestTypes(
  'services/GET_REPORT_PRESETS',
);

export const SET_REPORT_PRESETS = createRequestTypes(
  'services/SET_REPORT_PRESETS',
);

export const GET_CUSTOMIZED_REPORT = createRequestTypes(
  'services/GET_CUSTOMIZED_REPORT',
);

export const GET_PAYROLL_REPORT_CONFIGS = createRequestTypes(
  'services/GET_PAYROLL_REPORT_CONFIGS',
);
