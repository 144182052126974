import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  branch,
  lifecycle,
  renderComponent,
  withStateHandlers,
} from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Formik } from 'formik-legacy';
import { Loader } from 'semantic-ui-react';
import { SUBMIT_ATTEMPTED } from 'common/adaptive-forms';
import NewEmployeePage from './NewEmployeePage';
import {
  selectGroupId,
  selectEmployeeSchema,
  selectNewEmployeeReady,
  selectFormGenerator,
  selectValidator,
  selectCreateEmployeeLoading,
} from './selectors';
import {
  newEmployeeVisited,
  newEmployeeSubmitted,
  benefitClassChanged,
} from './actions';
import PropTypes from 'prop-types';
import { selectEmployerName } from '../selectors';

const withState = connect(
  createStructuredSelector({
    schema: selectEmployeeSchema,
    groupId: selectGroupId,
    groupName: selectEmployerName,
    generateLayout: selectFormGenerator,
    validate: selectValidator,
    loading: selectCreateEmployeeLoading,
  }),
  { newEmployeeSubmitted, benefitClassChanged },
);

const withFormikEnhancer = Formik({
  validateOnChange: true,
  validate: (values, props) => props.validate(values),
  handleSubmit: (values, { props }) => {
    if (!props.loading) {
      props.newEmployeeSubmitted(values);
      props.scrollTo();
    }
  },
  mapPropsToValues: props => ({
    group_id: props.groupId,
    group_name: props.groupName,
  }),
});

const withStatus = withStateHandlers(() => ({ status: '' }), {
  submitAttempted: () => () => ({ status: SUBMIT_ATTEMPTED }),
});

const NewEmployeeForm = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment -- FIXME: automatically added for existing issue
  <NewEmployeePage {...props}>{props.generateLayout(props)}</NewEmployeePage>
);

const withReadyState = connect(
  state => ({ ready: selectNewEmployeeReady(state) }),
  { newEmployeeVisited },
);

const withInitializer = lifecycle({
  componentDidMount() {
    this.props.newEmployeeVisited();
  },
});

const withLoadingSpinner = branch(
  props => !props.ready,
  renderComponent(() => <Loader active inline="centered" />),
);

const withLoader = compose(withReadyState, withInitializer, withLoadingSpinner);

NewEmployeeForm.propTypes = {
  generateLayout: PropTypes.func.isRequired,
};

export default compose(
  withLoader,
  withStatus,
  withState,
  withFormikEnhancer,
)(NewEmployeeForm);
