import React from 'react';
import { Box, Flex, BodyTwo, Image } from '@leagueplatform/genesis-commons';
import WarningCircle from 'apps/public/assets/circle-warning-icon.svg';

export const WarningMessage = () => (
  <Flex
    marginTop="two"
    paddingY="half"
    paddingX="one"
    backgroundColor="warning.background.subdued"
    borderStyle="solid"
    borderWidth="thin"
    borderColor="warning.icon"
    borderRadius="medium"
    alignItems="center"
  >
    <Image
      src={WarningCircle}
      role="presentation"
      height="oneAndHalf"
      width="oneAndHalf"
      marginRight={14}
    />
    <Box>
      <BodyTwo fontWeight="medium" display="inline">
        Please note:
      </BodyTwo>
      <BodyTwo marginLeft="quarter" display="inline">
        You must save this benefit for the changes to apply.
      </BodyTwo>
    </Box>
  </Flex>
);
