/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { put, all, call, select } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { EMPLOYER_PAYROLL_INIT } from './payroll-report.types';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import {
  ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
  ANALYTICS_EVENT_DOWNLOAD_FAILURE,
  ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
} from '../../../constants';
import { get } from 'lodash';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';

export function* initializePayroll({ payload }) {
  try {
    yield put({ type: EMPLOYER_PAYROLL_INIT.STARTED });
    let groupId = yield select(selectCurrentUserGroupId);
    const payloadGroupId = get(payload, 'groupId');
    if (payloadGroupId) {
      groupId = payloadGroupId;
    }
    const [groupResponse, receiptsResponse] = yield all([
      call(websocketFetch, 'get_group', { group_id: groupId }),
      call(websocketFetch, 'get_group_charge_invoices', { group_id: groupId }),
    ]);

    yield put({
      type: EMPLOYER_PAYROLL_INIT.SUCCEEDED,
      payload: {
        group: groupResponse.info,
        invoices: receiptsResponse.info.group_charge_invoices,
      },
    });
  } catch (error) {
    yield put({ type: EMPLOYER_PAYROLL_INIT.ERRORED, error });
  }
}

export function* onPayrollReportDownload({ payload }) {
  try {
    if (!payload.isLeagueAdmin) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'payroll',
        detail: 'generate payroll report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    if (!payload.isLeagueAdmin) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'payroll',
        detail: 'generate payroll report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
  }
}
