import React, { useMemo } from 'react';
import {
  Flex,
  StyleProps,
  QuietButton,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import styled from 'styled-components';
import { generatePath, Link, LinkProps } from 'react-router-dom';
import { Steps, StepToPathMap } from '../enrollment-design.constants';
import { Icon } from 'semantic-ui-react';
import { StyledComponentRefWrapper } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';

const StyledLink = styled(StyledComponentRefWrapper(Link))<LinkProps>`
  margin-left: auto;
`;

const StyledQuietButton = genesisStyled(QuietButton)<StyleProps>`
  color: ${({ theme }) => theme.colors.decorative.brand.primary.default};
  &:disabled {
    background-color: ${({ theme }) => theme.colors.surface.background.primary};
    border-color: ${({ theme }) => theme.colors.surface.background.primary};
  }
`;

export interface StepChangeLinkButtonProps {
  activeStep: string;
  planId?: string;
  url?: string;
}

const getStepButtons = (
  activeStep: string,
  planId?: String,
): {
  prevStep: string | undefined;
  nextStep: string | undefined;
  disableNextStepLink: boolean;
} => {
  let prevStep;
  let nextStep;
  let disableNextStepLink = false;
  // eslint-disable-next-line default-case -- FIXME: automatically added for existing issue
  switch (activeStep) {
    case Steps.STEP1:
      if (!planId) disableNextStepLink = true;
      nextStep = Steps.STEP2;
      break;
    case Steps.STEP2:
      nextStep = Steps.STEP3;
      prevStep = Steps.STEP1;
      break;
    case Steps.STEP3:
      prevStep = Steps.STEP2;
  }
  return {
    prevStep,
    nextStep,
    disableNextStepLink,
  };
};

const useNavigationButtonDetails = ({
  activeStep,
  planId,
}: StepChangeLinkButtonProps) => {
  const stepLinkDetails = useMemo(
    () => ({
      ...getStepButtons(activeStep, planId),
    }),
    [activeStep, planId],
  );
  return stepLinkDetails;
};

export const StepChangeLinkButton = ({
  activeStep,
  planId,
  url,
}: StepChangeLinkButtonProps) => {
  const { formatMessage } = useIntl();
  const { prevStep, nextStep, disableNextStepLink } =
    useNavigationButtonDetails({ activeStep, planId });
  return (
    <Flex marginBottom="two">
      {prevStep && (
        <Link to={generatePath(`${url}${StepToPathMap[prevStep]}`, { planId })}>
          <StyledQuietButton>
            <Icon name="angle left" />{' '}
            {formatMessage({ id: 'ENROLLMENT_DESIGN_PREV_STEP_BUTTON' })}
          </StyledQuietButton>
        </Link>
      )}
      {nextStep && (
        <StyledLink
          to={
            planId
              ? generatePath(`${url}${StepToPathMap[nextStep]}`, { planId })
              : generatePath(`${url}`)
          }
        >
          <StyledQuietButton disabled={disableNextStepLink}>
            {formatMessage({ id: 'ENROLLMENT_DESIGN_NEXT_STEP_BUTTON' })}{' '}
            <Icon name="angle right" />
          </StyledQuietButton>
        </StyledLink>
      )}
    </Flex>
  );
};

StepChangeLinkButton.defaultProps = {
  planId: '',
  url: '',
};
