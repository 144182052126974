import { combineReducers } from 'redux';
import { BOOT } from 'common/state/types';

const booting = (state = false, action = {}) => {
  switch (action.type) {
    case BOOT.STARTED:
      return true;
    case BOOT.SUCCEEDED:
      return false;
    default:
      return state;
  }
};

const isInWidgetRunner = (state = false, action = {}) => {
  switch (action.type) {
    case BOOT.STARTED:
      return action?.payload?.isInWidgetRunner;
    default:
      return state;
  }
};

export const application = combineReducers({
  booting,
  isInWidgetRunner,
});
