import { compose, withStateHandlers, withProps } from 'recompose';
import { set, get } from 'lodash';
import DependentClassAmounts from './dependent-class-amounts.view';
import { RULESETS } from './constants';

export const calculateRuleSet = ({ all, single, couple, family }) => {
  let ruleSet;

  if (all && single === couple && couple === family) {
    ruleSet = RULESETS.ALL;
  } else if (all && couple === family) {
    ruleSet = RULESETS.SINGLE_FAMILY;
  } else {
    ruleSet = RULESETS.SINGLE_COUPLE_FAMILY;
  }

  return ruleSet;
};

export const mapRuleSetToValues = (
  ruleSet,
  { all, single, couple, family },
) => {
  const amounts = {};

  if (ruleSet === RULESETS.ALL) {
    // eslint-disable-next-line no-restricted-globals -- FIXME: automatically added for existing issue
    amounts.all = isNaN(all) ? single : all;
    amounts.single = amounts.all;
    amounts.couple = amounts.all;
    amounts.family = amounts.all;
  } else if (ruleSet === RULESETS.SINGLE_FAMILY) {
    // eslint-disable-next-line no-restricted-globals -- FIXME: automatically added for existing issue
    amounts.all = isNaN(all) ? single : all;
    amounts.single = amounts.all;
    amounts.couple = family;
    amounts.family = family;
  } else if (ruleSet === RULESETS.SINGLE_COUPLE_FAMILY) {
    amounts.all = 0;
    amounts.single = single;
    amounts.couple = couple;
    amounts.family = family;
  }
  return amounts;
};

const withAmounts = withStateHandlers(
  ({ values }) => {
    const amounts = {
      all:
        get(values, 'benefit.dependent_class_amounts.all') ||
        get(values, 'parent.benefit.dependent_class_amounts.all') ||
        0,
      single:
        get(values, 'benefit.dependent_class_amounts.single') ||
        get(values, 'parent.benefit.dependent_class_amounts.single') ||
        0,
      couple:
        get(values, 'benefit.dependent_class_amounts.couple') ||
        get(values, 'parent.benefit.dependent_class_amounts.couple') ||
        0,
      family:
        get(values, 'benefit.dependent_class_amounts.family') ||
        get(values, 'parent.benefit.dependent_class_amounts.family') ||
        0,
    };
    return { amounts };
  },
  {
    setAmount:
      ({ amounts }, { setFieldValue, name }) =>
      ({ name: fieldName, value, ruleSet }) => {
        let formValue = Number(value);
        if (!value && value !== 0) {
          formValue = null;
        }

        const updatedAmounts = mapRuleSetToValues(
          ruleSet,
          set(amounts, fieldName, formValue),
        );
        setFieldValue(name, updatedAmounts);
        return updatedAmounts;
      },

    setAmounts:
      (_, { setFieldValue, name }) =>
      amounts => {
        setFieldValue(name, amounts);
        return { amounts };
      },
  },
);

const mapRuleSet = withProps(({ amounts }) => ({
  ruleSet: calculateRuleSet(amounts),
}));

const withRuleSets = withStateHandlers(({ ruleSet }) => ({ ruleSet }), {
  updateRuleSet:
    (_, { setAmounts, amounts }) =>
    (event, { value }) => {
      setAmounts(mapRuleSetToValues(value, amounts));
      return {
        ruleSet: value,
      };
    },
});

export default compose(
  withAmounts,
  mapRuleSet,
  withRuleSets,
)(DependentClassAmounts);
