import { createRequestTypes } from 'common/websocket-redux';

export const SET_USER_PROFILE = createRequestTypes(
  'userProfile/SET_USER_PROFILE',
);

export const GET_USER_AUTH_METHOD = createRequestTypes(
  'userProfile/GET_USER_AUTH_METHOD',
);

export const GET_USER_DEPENDENTS = createRequestTypes(
  'userProfile/GET_USER_DEPENDENTS',
);

export const REQUEST_PHONE_NUMBER_CHANGE = createRequestTypes(
  'userProfile/REQUEST_PHONE_NUMBER_CHANGE',
);

export const REQUEST_PHONE_NUMBER_CHANGE_CALL = createRequestTypes(
  'userProfile/REQUEST_PHONE_NUMBER_CHANGE_CALL',
);

export const CONFIRM_PHONE_NUMBER_CHANGE = createRequestTypes(
  'userProfile/CONFIRM_PHONE_NUMBER_CHANGE',
);

export const REQUEST_CHANGE_PASSWORD = createRequestTypes(
  'userProfile/REQUEST_CHANGE_PASSWORD',
);

export const UPLOAD_PROFILE_PHOTO = createRequestTypes(
  'userProfile/UPLOAD_PROFILE_PHOTO',
);

export const RESET_PROFILE_PHOTO = createRequestTypes(
  'userProfile/RESET_PROFILE_PHOTO',
);
