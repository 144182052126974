import { map, zip } from 'lodash';

export const compare = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

/*
This is a comparator function used for sorting fields with nested positions.
Inputs fieldA and fieldB might look something like this…

 { position: [1,2,4] }, { position: [1,2,3] }

where the fieldA would be considered greater than fieldB. See unit tests for
more examples.

TODO: Parse the schema in a way that tracks the maximum depth of the hierachy so
that this function can be optimized.
*/
export const comparator = (fieldA, fieldB) => {
  const positionPairs = zip(fieldA.position, fieldB.position);
  const comparisons = map(positionPairs, ([positionA, positionB]) =>
    compare(positionA, positionB),
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const c of comparisons) if (c !== 0) return c;
  return 0;
};

export const sortByPosition = fieldList => fieldList.sort(comparator);
