import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal, ClickEater } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingThree,
  genesisStyled,
  PrimaryButton,
  SecondaryButton,
  Label,
  BodyTwo,
  LabelText,
  Box,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import { PAY_PERIOD_PREMIUM_EXPORTS_REPORT } from './premiums-report.constants';
import { PLAN_STATUSES } from 'common/constants';

const StyledHeader = genesisStyled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.surface.background.primary};
  border-bottom: 1px solid ${({ theme }) =>
    theme.colors.onSurface.border.subdued};
`;

const StyledFooter = genesisStyled(Modal.Footer)`
  background-color: ${({ theme }) => theme.colors.surface.background.primary};
  border-bottom: 1px solid ${({ theme }) =>
    theme.colors.onSurface.border.subdued};
  padding: ${({ theme }) => theme.space.two}px;
`;

const SubLabel = genesisStyled(BodyTwo)`
  color: ${({ theme }) => theme.colors.onSurface.text.subdued}
`;

const formatPayPeriods = (payrollSchedule = []) => {
  const registeredSchedules = payrollSchedule.filter(
    schedule =>
      schedule.contributions_result?.contributions_registered ||
      schedule.payroll_result?.report_id,
  );

  return registeredSchedules.reverse().map(schedule => ({
    label: schedule.label,
    value: schedule.pay_period,
  }));
};

const getPremiumsReportPayload = ({
  requestType,
  payCalendar,
  payPeriod,
  planStatus,
}) => {
  if (requestType === PAY_PERIOD_PREMIUM_EXPORTS_REPORT) {
    return {
      payPeriod,
      payCalendar: payCalendar.value,
      scheduleId: payCalendar.id,
    };
  }
  return {
    planStatus,
  };
};

export const RequestPremiumsReport = ({
  requestType,
  onClose,
  getPremiumsReport,
  payrollSchedulesWithReportName,
}) => {
  const { formatMessage } = useIntl();
  const options = [
    {
      label: formatMessage({ id: 'ACTIVE' }),
      value: PLAN_STATUSES.ACTIVE,
    },
    {
      label: formatMessage({ id: 'ENROLLING' }),
      value: PLAN_STATUSES.ENROLLING,
    },
  ];
  const defaultOption = options[0];
  const [planStatus, setPlanStatus] = useState(defaultOption.value);
  const [payCalendar, setPayCalendar] = useState(
    payrollSchedulesWithReportName[0],
  );
  const [payPeriods, setPayPeriods] = useState(
    formatPayPeriods(payrollSchedulesWithReportName[0]?.schedule),
  );
  const [payPeriod, setPayPeriod] = useState(payPeriods[0]?.value);

  return (
    <Backdrop onClose={onClose}>
      <Modal.Container>
        <ClickEater>
          <StyledHeader>
            <HeadingThree>
              {formatMessage({ id: 'REQUEST_REPORT' })}
            </HeadingThree>
          </StyledHeader>
          <Modal.Body>
            <Label>
              <LabelText>{formatMessage({ id: 'PLAN_STATUS' })}</LabelText>
              <Select
                options={options}
                defaultValue={defaultOption.value}
                onChange={({ value }) => setPlanStatus(value)}
                marginTop="threeQuarters"
                maxMenuHeight={100}
              />
            </Label>
            {requestType === PAY_PERIOD_PREMIUM_EXPORTS_REPORT && (
              <Box>
                <Label marginTop="two">
                  <LabelText>{formatMessage({ id: 'PAY_CALENDAR' })}</LabelText>
                  <Select
                    options={payrollSchedulesWithReportName}
                    value={payCalendar?.value}
                    onChange={({ value, schedule }) => {
                      const newPeriods = formatPayPeriods(schedule);
                      setPayPeriods(newPeriods);
                      setPayPeriod(newPeriods[0]?.value);
                      setPayCalendar(value);
                    }}
                    marginTop="threeQuarters"
                    maxMenuHeight={100}
                  />
                </Label>
                <Label marginTop="two">
                  <LabelText>
                    {formatMessage({ id: 'PAY_PERIOD' })}
                    <SubLabel>
                      {formatMessage({ id: 'ONLY_REGISTERED_PAY_PERIODS' })}
                    </SubLabel>
                  </LabelText>
                  <Select
                    options={payPeriods}
                    value={payPeriod}
                    isClearable
                    onChange={evt => {
                      setPayPeriod(evt?.value);
                    }}
                    marginTop="threeQuarters"
                    maxMenuHeight={100}
                  />
                </Label>
              </Box>
            )}
          </Modal.Body>
          <StyledFooter>
            <SecondaryButton marginRight="one" onClick={onClose}>
              {formatMessage({ id: 'CANCEL' })}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                getPremiumsReport(
                  getPremiumsReportPayload({
                    requestType,
                    payCalendar,
                    payPeriod,
                    planStatus,
                  }),
                );
                onClose();
              }}
            >
              {formatMessage({ id: 'REQUEST' })}
            </PrimaryButton>
          </StyledFooter>
        </ClickEater>
      </Modal.Container>
    </Backdrop>
  );
};

RequestPremiumsReport.propTypes = {
  requestType: PropTypes.string.isRequired,
  getPremiumsReport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedulesWithReportName: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
};

RequestPremiumsReport.defaultProps = {};
