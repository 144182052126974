import { combineReducers } from 'redux';
import { GET_BENEFIT_SELECTIONS_DIFF } from './benefit-selections-tool.actions';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const isLoading = (state = false, action) => {
  switch (action?.type) {
    case GET_BENEFIT_SELECTIONS_DIFF.STARTED:
      return true;
    case GET_BENEFIT_SELECTIONS_DIFF.SUCCEEDED:
    case GET_BENEFIT_SELECTIONS_DIFF.ERRORED:
    case GET_BENEFIT_SELECTIONS_DIFF.CANCELLED:
      return false;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const diff = (state = null, action) => {
  switch (action?.type) {
    case GET_BENEFIT_SELECTIONS_DIFF.SUCCEEDED:
      return action.payload.info.diff;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const error = (state = null, action) => {
  switch (action?.type) {
    case GET_BENEFIT_SELECTIONS_DIFF.ERRORED:
      return action.payload.info.reason;
    case GET_BENEFIT_SELECTIONS_DIFF.STARTED:
      return null;
    default:
      return state;
  }
};

export const benefitSelectionsDiffReducer = combineReducers({
  isLoading,
  diff,
  error,
});
