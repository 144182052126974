import camelize from 'camelize';
import { normalize } from 'normalizr';
import {
  GET_GROUP,
  EMPLOYER_DETAILS_LOADING,
  EMPLOYER_DETAILS_READY,
  CLEAR_EMPLOYER_DETAILS,
} from './types';
import {
  compactSchema,
  benefitClass as benefitClassSchema,
  benefitPlan as benefitPlanSchema,
  benefit as benefitSchema,
  catalogue as catalogueSchema,
  benefitDocument as benefitDocumentsSchema,
} from './schema';
import { benefitClassReducer } from './Benefits/BenefitClass/benefit-class.reducer';
import { billingReducer } from './Billing/billing.reducer';
import { employerProfileReducer } from './Profile/employer-profile.reducer';
import { employeeReducer } from './Employee/reducer';
import { benefitWalletReducer } from './Benefits/BenefitWallet/benefit-wallet.reducer';
import { pricingConfigurationReducer } from './Benefits/PricingConfiguration/pricing-configuration.reducer';
import { planDesignReducer } from './Benefits/PlanDesign/plan-design.reducer';
import { salesforceSyncReducer } from './Benefits/SalesforceSync/salesforce-sync.reducer';
import {
  employerPayrollReducer,
  employerPayrollInitialState,
} from './Payroll/employer-payroll.reducer';

export const initialState = {
  ready: false,
  error: false,
  catalogue: compactSchema(
    normalize({ catalogue: {} }, [catalogueSchema]),
    'catalogue',
  ),
  employerForm: null,
  employerServiceStatus: null,
  employerFormSchema: null,
  newEmployerFormReady: false,
  editEmployerFormReady: false,
  profileIsUpdating: false,
  employerBenefitClasses: compactSchema(
    normalize({ benefitClasses: {} }, [benefitClassSchema]),
    'benefitClasses',
  ),
  employerBenefitPlans: compactSchema(
    normalize({ benefitPlans: {} }, [benefitPlanSchema]),
    'benefitPlans',
  ),
  employerBenefits: compactSchema(
    normalize({ benefits: {} }, [benefitSchema]),
    'benefits',
  ),
  employerBenefitDocuments: compactSchema(
    normalize({ benefitDocuments: {} }, [benefitDocumentsSchema]),
    'benefitDocuments',
  ),
  processingCategoriesUpload: false,
  benefitTypeSchemas: {},
  employerBenefitBenefitsStructure: {},
  billingReady: false,
  billingUpdateStatus: {
    payment: 'initial',
    billingPreference: 'initial',
    fees: 'initial',
    prepayment: 'initial',
    contact: 'initial',
  },
  employerOutstandingBalance: {},
  employerOutstandingBalanceReady: false,
  employerOutstandingBalanceError: false,
  planDesignRequestStatus: {
    success: false,
    loading: false,
    error: false,
    errorReason: '',
  },
  employerPayroll: employerPayrollInitialState,
};

const applyGetGroup = (state, action) => {
  const benefitClasses = action.payload.info.benefit_classes;
  const employerBenefitClasses = benefitClasses
    ? normalize(camelize(benefitClasses), [benefitClassSchema])
    : initialState.employerBenefitClasses;
  const newEmployerBenefitsStructures = employerBenefitClasses.result.reduce(
    (acc, benefitClassId) => {
      if (!state.employerBenefitBenefitsStructure[benefitClassId]) {
        return {
          ...acc,
          [benefitClassId]: {},
        };
      }

      return acc;
    },
    {},
  );

  return {
    ...state,
    employer: camelize(action.payload.info),
    employerBenefitClasses,
    employerBenefitBenefitsStructure: {
      ...state.employerBenefitBenefitsStructure,
      ...newEmployerBenefitsStructures,
    },
  };
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerDetailsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case EMPLOYER_DETAILS_LOADING:
      return { ...state, ready: false };
    case EMPLOYER_DETAILS_READY:
      return { ...state, ready: true };
    case GET_GROUP.SUCCEEDED:
      return applyGetGroup(state, action);
    case CLEAR_EMPLOYER_DETAILS:
      return initialState;
    default:
    // continue
  }
  // in future use composeReducers ;)
  const benefitState = benefitClassReducer(state, action);
  const profileState = employerProfileReducer(benefitState, action);
  const billingState = billingReducer(profileState, action);
  const employeeState = employeeReducer(billingState, action);
  const benefitWalletState = benefitWalletReducer(employeeState, action);
  const importPlanDesignState = planDesignReducer(benefitWalletState, action);
  const pricingConfigurationState = pricingConfigurationReducer(
    importPlanDesignState,
    action,
  );
  const salesforceSyncState = salesforceSyncReducer(
    pricingConfigurationState,
    action,
  );
  const employerPayrollState = employerPayrollReducer(
    salesforceSyncState.employerPayroll,
    action,
  );

  return {
    ...salesforceSyncState,
    employerPayroll: employerPayrollState,
  };
};
