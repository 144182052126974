/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  GET_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  GET_UNREAD_NOTIICATION_COUNT,
} from './notifications.types';
import camelize from 'camelize';

const initialState = {
  isLoading: false,
  error: undefined,
  notifications: [],
  earliestFetchedDate: undefined,
  isEndOfList: false,
  limit: 10,
  unreadCount: 0,
};

const applyFetching = state => ({
  ...state,
  isLoading: true,
});

const applyFetchLimit = (state, { limit, after }) => {
  return {
    ...state,
    limit,
    after,
  };
};

const applyNotifications = (state, action) => {
  const { notifications } = camelize(action.payload.info);
  const notificationCount = (notifications || []).length;
  const earliestDate = notifications
    ? new Date(notifications[notificationCount - 1].timestamp)
    : null;

  return {
    ...state,
    isLoading: false,
    notifications: notifications
      ? [...state.notifications, ...notifications]
      : [],
    earliestFetchedDate: earliestDate,
    isEndOfList: notificationCount < state.limit || earliestDate <= state.after,
  };
};

const applyNotificationCount = (state, action) => {
  const { unread_count } = action.payload.info;

  return {
    ...state,
    unreadCount: unread_count,
  };
};

const applyFechNotificationsError = (state, { payload }) => {
  return {
    ...initialState,
    isLoading: false,
    error: payload,
  };
};

export const notificationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return applyFetchLimit(state, action);
    case GET_NOTIFICATIONS.STARTED:
      return applyFetching(state, action);
    case GET_NOTIFICATIONS.SUCCEEDED:
      return applyNotifications(state, action);
    case GET_NOTIFICATIONS.FAILED:
      return applyFechNotificationsError(state, action);
    case GET_UNREAD_NOTIICATION_COUNT.SUCCEEDED:
      return applyNotificationCount(state, action);
    default:
      return state;
  }
};
