export const ANALYTICS_VIEW_HELP_POPUP =
  'account-info-form/ANALYTICS_VIEW_HELP_POPUP';

export const ANALYTICS_FRONTEND_ERROR_VALIDATING_ACCOUNT =
  'account-info-form/ANALYTICS_FRONTEND_ERROR_VALIDATING_ACCOUNT';

export const ANALYTICS_BACKEND_ERROR_VALIDATING_ACCOUNT =
  'account-info-form/ANALYTICS_BACKEND_ERROR_VALIDATING_ACCOUNT';

export const ANALYTICS_VALIDATE_ACCOUNT =
  'account-info-form/ANALYTICS_VALIDATE_ACCOUNT';
