import React, { useEffect } from 'react';
import { HeadingFour, Text, Flex } from '@leagueplatform/genesis-commons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { EmployerReportsInfoBox, EmployerReportsLoaderButton } from './ui-kit';
import { NoteText } from 'apps/employer-experience/ui-kit/report-elements.view';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const EmployerBenefitsReportDownload = styled(EmployerReportsLoaderButton)`
  margin: 1rem 0;
`;

export const BenefitsReport = ({
  groupId,
  loading,
  getEmployerBenefitsReport,
  contentId,
  visit,
  ...props
}) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();

  const { formatMessage } = useIntl();
  // lifecycle
  useEffect(() => {
    visit();
  }, [visit]);
  return (
    <>
      <Flex align="flex-start">
        <HeadingFour marginBottom="one">
          {formatMessage({ id: 'EMPLOYER_BENEFITS_REPORT' })}
        </HeadingFour>
      </Flex>
      <Text marginBottom="one">
        {formatMessage({ id: 'EMPLOYER_BENEFITS_REPORT_DESCRIPTION' })}
      </Text>
      <Text>
        <NoteText>{formatMessage({ id: 'NOTE' })}:</NoteText>
        {formatMessage({ id: 'REPORTS_LOADING_NOTE_COPY' })}
      </Text>
      <EmployerBenefitsReportDownload
        primary
        type="submit"
        loading={loading}
        onClick={() => getEmployerBenefitsReport(groupId, isInHRAdminPortal)}
      >
        {formatMessage({ id: 'DOWNLOAD_EMPLOYER_BENEFITS_REPORT' })}
      </EmployerBenefitsReportDownload>
      <EmployerReportsInfoBox
        ready={!!contentId}
        loading={loading}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
    </>
  );
};

BenefitsReport.propTypes = {
  groupId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  getEmployerBenefitsReport: PropTypes.func.isRequired,
  contentId: PropTypes.string,
  error: PropTypes.string,
  email: PropTypes.string.isRequired,
  visit: PropTypes.func.isRequired,
};

BenefitsReport.defaultProps = {
  groupId: null,
  contentId: null,
  error: null,
};
