export default {
  addEditEmployer: {
    groups: [
      {
        title: 'Basic Info',
        position: 0,
        fields: [
          {
            name: 'company_name',
            position: 0,
            span: 12,
          },
          {
            name: 'country',
            position: 1,
            span: 12,
          },
          {
            name: 'currency',
            position: 2,
            span: 4,
          },
          {
            name: 'address.address1',
            position: 0,
            span: 6,
          },
          {
            name: 'address.address2',
            position: 1,
            span: 6,
          },
          {
            name: 'address.city',
            position: 2,
            span: 6,
          },
          {
            name: 'address.province',
            position: 2,
            span: 6,
          },
          {
            name: 'address.country',
            position: 2,
            span: 6,
          },
          {
            name: 'address.postal_code',
            position: 2,
            span: 6,
          },
          {
            name: 'waiting_period',
            position: 2,
            span: 6,
          },
          {
            name: 'waiting_period_days',
            position: 2,
            span: 6,
          },
        ],
      },
    ],
  },
};
