/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { takeLatest, fork, call, all } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { analytics } from 'common/utilities';
import {
  GET_USER_REWARD_POINTS,
  GET_REWARD_LEVELS,
  SET_REDEEM_POINTS,
  REDEEM_POINTS_CLICKED,
  GET_LIFEMARKET_PRODUCTS,
} from './rewards-page.actions';
import { fetchRewardPoints } from 'apps/member-experience/resources/rewards/health-rewards.saga';
import { getLeaguePointsRewardLevels } from 'common/services/health-programs';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchUserRewards() {
  yield call(fetchRewardPoints);
  yield call(fetchRewardLevels);
  yield call(fetchLifemarketRecommendedProducts);
}

export function* fetchRewardLevels() {
  yield request(GET_REWARD_LEVELS, getLeaguePointsRewardLevels());
}

export function* setRedeemPoints(action) {
  yield request(SET_REDEEM_POINTS, [
    websocketFetch,
    'redeem_league_points',
    { points: action?.payload?.points },
  ]);
  yield call(analytics?.healthGoals?.redeemPoints, action?.payload?.points);
  yield call(fetchUserRewards);
}

export function* fetchLifemarketRecommendedProducts() {
  yield request(GET_LIFEMARKET_PRODUCTS, [
    websocketFetch,
    'get_lifemarket_merch_list',
    {
      list_id: 'recommended_lifemarket_items',
      max_image_height_px: 500,
    },
  ]);
}

export function* showRewardErrorToast(error) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'REWARDS_ERROR',
    contentId: error?.payload?.info?.reason,
  });
}

export function* rewardsPageSaga() {
  yield fork(all, [
    takeLatest(GET_USER_REWARD_POINTS.BASE, fetchUserRewards),
    takeLatest(REDEEM_POINTS_CLICKED, setRedeemPoints),
    takeLatest(GET_USER_REWARD_POINTS.ERRORED, showRewardErrorToast),
  ]);
}
