import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import { selectEmployerDetails } from '../../../selectors';
import { selectCatalogue } from './BenefitPlan/Benefits/selectors';
import { benefitPlan as benefitPlanSchema } from '../../../schema';
import { denormalize } from 'normalizr';

export const selectBenefitPlans = createSelector(
  selectEmployerDetails,
  ({ employerBenefitPlans } = {}) =>
    denormalize(
      employerBenefitPlans?.result,
      [benefitPlanSchema],
      employerBenefitPlans?.entities,
    ),
);

export const selectBenefitPlanEntities = createSelector(
  selectEmployerDetails,
  employerDetails =>
    get(employerDetails, 'employerBenefitPlans.entities.benefitPlans', {}),
);

export const selectBenefitPlanById = (state, { benefitPlanId }) =>
  selectBenefitPlanEntities(state)[benefitPlanId];

export const selectStructure = createSelector(
  selectEmployerDetails,
  ({ employerBenefitBenefitsStructure }) => employerBenefitBenefitsStructure,
);

export const selectEmployerBenefitsUiIsLoading = state =>
  state?.apps?.['employer-experience']?.ui?.employerBenefits.isLoading;

export const selectIsLoadingByClassId = createSelector(
  (_, { benefitClassId }) => benefitClassId,
  selectEmployerBenefitsUiIsLoading,
  (classId, isLoading) => isLoading.plansByBenefitClassId[classId] ?? false,
);

export const selectBenefitPlansForClass = (
  structure,
  benefitClassId,
  allBenefitPlanEntities,
) => {
  const planIdsForClass = structure[benefitClassId] || {};
  const plans = [];
  Object.keys(planIdsForClass).forEach(planId => {
    if (allBenefitPlanEntities[planId]) {
      plans.push(allBenefitPlanEntities[planId]);
    }
  });
  return plans;
};

export const selectAddingBenefitPlan = createSelector(
  selectEmployerDetails,
  ({ employerBenefitPlans }) => !!employerBenefitPlans.addingBenefitPlan,
);

export const selectErrorAddingBenefitPlan = createSelector(
  selectEmployerDetails,
  ({ employerBenefitPlans }) => !!employerBenefitPlans.errorAddingBenefitPlan,
);

export const selectRenamingBenefitPlan = createSelector(
  selectEmployerDetails,
  ({ employerBenefitPlans }) => employerBenefitPlans.renamingBenefitPlan || '',
);

export const selectErrorRenamingBenefitPlan = createSelector(
  selectEmployerDetails,
  ({ employerBenefitPlans }) => !!employerBenefitPlans.errorRenamingBenefitPlan,
);

export const selectIsLastBenefitPlan = createSelector(
  selectEmployerDetails,
  ({ employerBenefitPlans }) => employerBenefitPlans.result.length === 1,
);

export const selectHasCatalogue = createSelector(
  selectCatalogue,
  catalogue => !isEmpty(catalogue),
);
