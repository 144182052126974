import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getOnboardingWorkflow - calls `get_onboarding_workflow` which returns an array of
 * enrollment steps. These steps can be set to skip via the admin employee profile view so
 * we're using this to determine if the we should include the dependents or benefits steps in NEX
 *
 * @arg {string} groupId  the group ID for the currently enrolling group
 * @arg {string} userId  the user's user ID
 * @arg {string} planId  (optional) the plan ID to check. If not provided, plan to check will be inferred
 *
 * @returns {object[]}
 */

export const getOnboardingWorkflow = ({
  groupId = null,
  userId = null,
  planId = null,
} = {}) =>
  call(websocketFetch, 'get_onboarding_workflow', {
    group_id: groupId,
    user_id: userId,
    plan_id: planId,
  });
