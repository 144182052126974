import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { BodyOne, ErrorMessage } from '@leagueplatform/genesis-commons';
import { FormCard } from 'common/insurance-forms/form-card.view';
import { EOITooltipContent } from 'common/insurance-forms/eoi-tooltip.view';
import { InfoBannerCard } from 'common/info-banner-card/info-banner-card.view';
import {
  ACTION_DOWNLOAD_EOI_FORM,
  INSURANCE_FORM_STATUS_TYPES,
} from 'common/insurance-forms/insurance-forms.constants';
import { useGetUserBenefitForm } from 'common/insurance-forms/use-get-user-benefit-form.hook';
import { LoadingSecondaryButton } from './loading-secondary-button.view';
import { useFormattedStringsList } from 'apps/enrollment/utils/use-formatted-strings-list.hook';

const getBannerInstructionsId = (
  isFormAvailable,
  isKPMGPartnerWithOptionalLTD,
  isShopify,
  formEOIContentId,
) => {
  if (isKPMGPartnerWithOptionalLTD)
    return 'KPMG_EQUITY_PARTNER_WITH_OPTIONAL_LTD_EOI_FORM_INSTRUCTIONS';
  if (isShopify && !formEOIContentId)
    return 'SHOPIFY_EOI_FORM_NOT_READY_INSTRUCTIONS';
  return isFormAvailable
    ? 'EOI_FORM_READY_INSTRUCTIONS'
    : 'FORM_NOT_READY_INSTRUCTIONS';
};
export const EOICard = ({
  available: isFormAvailable,
  benefit_product_names: eoiBenefitNames,
  benefit_product_types: eoiProductTypes,
  isLoading,
  url,
  isKPMGPartnerWithOptionalLTD,
  sendAnalytics,
  isShopify,
  formEOIContentId,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const showDownloadShopifyButton = isShopify && formEOIContentId;
  const showDownloadButton =
    (isFormAvailable && !isKPMGPartnerWithOptionalLTD && (url || isLoading)) ||
    showDownloadShopifyButton;

  const getUserBenefitForm = useGetUserBenefitForm(props);

  useEffect(() => {
    getUserBenefitForm();
  }, [getUserBenefitForm]);

  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (isLoading) setIsReady(true);
  }, [isLoading]);

  const showErrorMsg =
    isReady &&
    isFormAvailable &&
    !url &&
    !isLoading &&
    !isKPMGPartnerWithOptionalLTD &&
    showDownloadButton;

  const bannerInstructionsId = getBannerInstructionsId(
    isFormAvailable && url,
    isKPMGPartnerWithOptionalLTD,
    isShopify,
    formEOIContentId,
  );

  const getLocaleOrValue = (string, i) => {
    const localeBenefit = eoiProductTypes[i].toUpperCase();
    if (string && !formatMessage({ id: localeBenefit }).includes('_')) {
      return formatMessage({ id: localeBenefit });
    }
    return string;
  };

  const { getFormattedStringsList } = useFormattedStringsList();
  const formattedEOIBenefitNames = getFormattedStringsList(
    eoiBenefitNames.map((string, i) => getLocaleOrValue(string, i)),
  );

  const strong = text => <strong>{text}</strong>;

  const tooltipText = formatMessage(
    { id: 'GENERIC_TOOLTIP_NAME' },
    { name: `${formatMessage({ id: 'EVIDENCE_OF_INSURABILITY_EOI' })}` },
  );

  const downloadForm = () => {
    if (!isLoading) {
      if (sendAnalytics) sendAnalytics(ACTION_DOWNLOAD_EOI_FORM);
      window.open(url, '_blank', 'noopener=true,noreferrer=true');
    }
  };

  return (
    <FormCard
      title={formatMessage({ id: 'EVIDENCE_OF_INSURABILITY_EOI' })}
      description={formatMessage(
        { id: 'EOI_CARD_DESCRIPTION' },
        {
          benefitNames: formattedEOIBenefitNames,
          strong,
        },
      )}
      formType={INSURANCE_FORM_STATUS_TYPES.REQUIRED}
      tooltip={
        <BodyOne>
          <EOITooltipContent />
        </BodyOne>
      }
      tooltipText={tooltipText}
      productTypesId={eoiProductTypes?.join('-')}
    >
      <InfoBannerCard marginTop="oneAndHalf">
        <BodyOne>
          {formatMessage({ id: bannerInstructionsId }, { strong })}
        </BodyOne>
      </InfoBannerCard>

      {showDownloadButton && (
        <LoadingSecondaryButton
          marginTop="oneAndHalf"
          width={{
            _: 1,
            tablet: 'inherit',
          }}
          onClick={downloadForm}
          isLoading={isLoading}
        >
          {formatMessage({ id: 'DOWNLOAD_FORM' })}
        </LoadingSecondaryButton>
      )}

      {showErrorMsg && (
        <ErrorMessage marginTop="oneAndHalf" fontWeight="bold">
          {formatMessage({
            id: 'INSURANCE_ERROR_PREPARING_FORMS',
          })}
        </ErrorMessage>
      )}
    </FormCard>
  );
};

EOICard.propTypes = {
  available: PropTypes.bool.isRequired,
  benefit_product_names: PropTypes.arrayOf(PropTypes.string).isRequired,
  benefit_product_types: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool,
  isKPMGPartnerWithOptionalLTD: PropTypes.bool,
  url: PropTypes.string,
  sendAnalytics: PropTypes.func,
  isShopify: PropTypes.bool.isRequired,
  formEOIContentId: PropTypes.string,
};

EOICard.defaultProps = {
  isLoading: false,
  isKPMGPartnerWithOptionalLTD: false,
  url: '',
  sendAnalytics: null,
  formEOIContentId: '',
};
