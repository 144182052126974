const EXPIRED = 'expired';
const REDEEMED = 'redeemed';
const SENT = 'sent';

export const INVITE_STATUS = {
  EXPIRED,
  REDEEMED,
  SENT,
};

export const DEPENDENT_INVITE_ERROR_STATUS = [
  INVITE_STATUS.EXPIRED,
  INVITE_STATUS.REDEEMED,
];
