import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';

const Title = styled.span`
  margin-left: 0.5rem;
`;

Title.displayName = 'AccountSummaryLabelTitle';

export const AccountSummary = injectIntl(({ balance, allocated }) => (
  <div>
    <Label size="small">
      <Title>
        <FormattedMessage id="BALANCE" />
      </Title>
      <Label.Detail>
        <FormattedNumber
          style="currency" // eslint-disable-line
          currencyDisplay="symbol"
          currency={balance.C}
          value={balance.F}
        />
      </Label.Detail>
    </Label>
    <Label size="small">
      <Title>
        <FormattedMessage id="ALLOCATION" />
      </Title>
      <Label.Detail>
        <FormattedNumber
          style="currency" // eslint-disable-line
          currencyDisplay="symbol"
          currency={allocated.C}
          value={allocated.F}
        />
      </Label.Detail>
    </Label>
  </div>
));

AccountSummary.displayName = 'AccountSummary';

const currencyType = PropTypes.shape({
  F: PropTypes.number.isRequired,
  C: PropTypes.string.isRequired,
});

AccountSummary.propTypes = {
  balance: currencyType.isRequired,
  allocated: currencyType.isRequired,
};
