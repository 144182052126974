import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HeadingFour,
  BodyOne,
  PrimaryButton,
  TextInput,
  Label,
  LabelText,
  Link,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { STATUS } from './pricing-config-qa-report.reducer';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const PricingScenarioReport = ({
  requestStatus,
  contentId,
  fileName,
  groupId,
  getPricingScenarioReport,
}) => {
  const { formatMessage } = useIntl();

  const [userIDs, setUserIDs] = useState([]);
  const onChange = evt => {
    try {
      const inputArray = evt.target.value.replace(/\s/g, '').split(',');
      setUserIDs(inputArray);
    } catch (err) {
      setUserIDs([]);
    }
  };

  return (
    <Box>
      <HeadingFour marginBottom="one">
        {formatMessage({ id: 'PRICING_SCENARIO_REPORT' })}
      </HeadingFour>
      <BodyOne marginBottom="one">
        {formatMessage({ id: 'PRICING_SCENARIO_COPY' })}
      </BodyOne>
      <Label>
        <LabelText>
          {formatMessage({ id: 'COMMA_SEPARATED_USER_IDS' })}
        </LabelText>
        <TextInput
          marginBottom="one"
          value={userIDs.join(', ')}
          onChange={onChange}
        />
      </Label>
      <PrimaryButton
        onClick={() =>
          getPricingScenarioReport({
            group_id: groupId,
            user_ids: userIDs,
          })
        }
        disabled={requestStatus === STATUS.LOADING || !userIDs}
      >
        {formatMessage({ id: 'REQUEST_REPORT' })}
      </PrimaryButton>
      {contentId && (
        <Link marginLeft="one" href={getContentUrl(contentId)}>
          {`${formatMessage({ id: 'DOWNLOAD' })} ${fileName}.csv`}
        </Link>
      )}
    </Box>
  );
};

PricingScenarioReport.propTypes = {
  requestStatus: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  getPricingScenarioReport: PropTypes.func.isRequired,
};
