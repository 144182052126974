export const SET_DEFAULT_ACCOUNT = 'banking-info/SET_DEFAULT_ACCOUNT';
export const VISITED = 'banking-info/VISITED';
export const EXITED = 'banking-info/EXITED';

export const setDefaultAccount = paymentCredentialsId => ({
  type: SET_DEFAULT_ACCOUNT,
  payload: {
    paymentCredentialsId,
  },
});

export const visited = () => ({
  type: VISITED,
});

export const exited = () => ({
  type: EXITED,
});
