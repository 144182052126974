import { APP_CONFIG } from 'app-config';

export const ROOT_PATH = '/enroll';
export const WELCOME_PATH = `${ROOT_PATH}/:step`;
export const STEP_PATH = `${ROOT_PATH}/:planId/:step`;

export const IOS_PATH =
  'https://itunes.apple.com/ca/app/league-better-benefits/id952832544';
export const ANDROID_PATH =
  'https://play.google.com/store/apps/details?id=life.league&hl=en';

export const MEMBER_EXPERIENCE_URL = `${APP_CONFIG.REACT_APP_WR_URL}/member`;

export const MEMBER_DOCUMENTS_URL = `${APP_CONFIG.REACT_APP_WR_URL}/member/documents`;

export const MEMBER_FORMS_URL = `${APP_CONFIG.REACT_APP_WR_URL}/member/documents/forms`;

export const MEMBER_WALLET_URL = `${APP_CONFIG.REACT_APP_WR_URL}/member/wallet`;

export const WELCOME_SLUG = 'welcome';
export const QLE_WELCOME_SLUG = 'qle';
export const PROFILE_SLUG = 'profile';
export const DEPENDENTS_SLUG = 'dependents';
export const PLAN_SLUG = 'plan';
export const NEXT_STEPS_SLUG = 'next-steps';
export const COVERAGE_SUMMARY_SLUG = 'coverage-summary';

export const getWelcomePath = () => `${ROOT_PATH}/${WELCOME_SLUG}`;
export const getStepPath = (step, planId) => `${ROOT_PATH}/${planId}/${step}`;

const sideNavExcludedPaths = [NEXT_STEPS_SLUG, WELCOME_SLUG, QLE_WELCOME_SLUG];

export const showNavForPath = path => !sideNavExcludedPaths.includes(path);
