import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import {
  SERVICE_GET_QLE_CONFIGURATIONS,
  SERVICE_GET_EMPLOYER_QLE_APPROVAL_EMAILS,
  SET_EMPLOYER_QLE_APPROVAL_EMAILS,
  SET_BENEFIT_CLASS_ID,
  SET_QLE_TYPE_AND_ID,
} from './qle-configuration.action-types';
import { CANCEL_QLE_CONFIGURATION_FORM } from './qle-configuration-form/qle-configuration-form.action-types';
import { SET_IS_EDIT_HR_EMAIL_ENABLED } from './benefit-class-selection/benefit-class-selection.action-types';

// `null` indicates no API calls have been sent, thus initial state
export const initialState = {
  benefitClassId: null,
  qleType: null,
  qleConfigurationId: null,
  qleConfigurations: null,
  qleApprovalEmails: null,
  isEditHREmailEnabled: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const qleConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BENEFIT_CLASS_ID:
      return {
        ...state,
        benefitClassId: action.payload.benefitClassId,
      };
    case SET_QLE_TYPE_AND_ID:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const { qleType, qleConfigurationId } = action.payload;
      return {
        ...state,
        qleType,
        qleConfigurationId,
      };
    // Set this state back to null to hide the qle configuration table while fetching!
    case SERVICE_GET_QLE_CONFIGURATIONS.STARTED:
      return {
        ...state,
        qleConfigurations: null,
      };
    case SERVICE_GET_QLE_CONFIGURATIONS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const { qle_configurations: qleConfigurations } = action.payload.info;

      return {
        ...state,
        qleConfigurations: qleConfigurations.map(configuration => {
          const camelCasedQLEConfiguration = mapKeys(configuration, (_, key) =>
            camelCase(key),
          );

          // Backend sends us int, but for this value to work in a <select> it has to be converted to a string
          camelCasedQLEConfiguration.coverageEffectiveDatePolicy =
            camelCasedQLEConfiguration.coverageEffectiveDatePolicy.toString();

          return camelCasedQLEConfiguration;
        }),
      };
    case SERVICE_GET_EMPLOYER_QLE_APPROVAL_EMAILS.SUCCEEDED:
      return {
        ...state,
        qleApprovalEmails: action?.payload?.info?.qle_approval_emails ?? [],
      };
    case SET_EMPLOYER_QLE_APPROVAL_EMAILS:
      return {
        ...state,
        qleApprovalEmails: get(action, 'payload.qleApprovalEmails'),
      };
    case CANCEL_QLE_CONFIGURATION_FORM:
      return {
        ...state,
        qleConfigurationId: null,
      };
    case SET_IS_EDIT_HR_EMAIL_ENABLED:
      return {
        ...state,
        isEditHREmailEnabled: get(action, 'payload.isEditHREmailEnabled'),
      };
    default:
      return state;
  }
};
