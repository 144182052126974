import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextAction } from '@leagueplatform/genesis-core';
import { postToWR } from 'common/utilities';
import { useSelector } from 'react-redux';
import { selectIsInWidgetRunner } from 'common/state/application/application.selectors';

// TODO: This is a temporary solution to get the member link to work with WR.
//       This should be replaced with a proper solution when WR routing is removed.
const handleWRNavigation = (to, onClick, event) => {
  event.preventDefault();
  if (onClick !== null) {
    event.persist();
    onClick(event);
  }
  postToWR('goToUrl', { path: to });
};

export const MemberLink = ({ to, children, onClick, ...props }) => {
  const isInWR = useSelector(selectIsInWidgetRunner);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
  const handleClick = useCallback(
    isInWR ? event => handleWRNavigation(to, onClick, event) : onClick,
    [to, onClick, isInWR],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <TextAction as={RouterLink} to={to} onClick={handleClick} {...props}>
      {children}
    </TextAction>
  );
};

MemberLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};
MemberLink.defaultProps = {
  onClick: null,
};
