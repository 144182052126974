import { put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_EMPLOYEE_SPENDING_ACCOUNTS,
  GET_EMPLOYEE_SPENDING_ACCOUNTS,
} from './employee-spending-accounts.types';
import camelize from 'camelize';
import { getUserBenefits, getUserBenefitSummaries } from 'common/services';

export function* getEmployeeSpendingAccounts({ payload }) {
  try {
    const { userId, groupId } = payload;
    yield put({ type: FETCH_EMPLOYEE_SPENDING_ACCOUNTS.STARTED });
    const benefitsResponse = yield getUserBenefits({
      userId,
      groupId,
    });
    const summariesResponse = yield getUserBenefitSummaries({
      userIds: [userId],
      groupId,
    });
    const benefits = camelize(benefitsResponse.info.benefits);
    const summaries = camelize(summariesResponse.info.benefit_summaries);
    yield put({
      type: FETCH_EMPLOYEE_SPENDING_ACCOUNTS.SUCCEEDED,
      payload: {
        benefits,
        summaries,
      },
    });
  } catch (error) {
    yield put({ type: FETCH_EMPLOYEE_SPENDING_ACCOUNTS.ERRORED, error });
  }
}

export function* employeeSpendingAccountsSagas() {
  yield takeLatest(GET_EMPLOYEE_SPENDING_ACCOUNTS, getEmployeeSpendingAccounts);
}
