import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  HeadingFour,
  Image,
  BodyOne,
  BackgroundImage,
  HeadingTwo,
  genesisStyled,
  Link,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useParams } from 'react-router-dom';
import BACKGROUND_IMAGE from './assets/laptop-man-wide.png';
import COVERAGE_ICON from './assets/credit-cards.svg';
import PRODUCTS_ICON from './assets/shop.svg';
import ANSWERS_ICON from './assets/phone.svg';
import REWARDS_ICON from './assets/trophy.svg';
import { useIntl } from '@leagueplatform/locales';
import { Auth0ClientWrapperInstance } from 'apps/auth/auth0/auth0.clientwrapper';

export const StyledBackgroundImage = genesisStyled(BackgroundImage)`
  background-attachment: fixed;
`;

export const AuthWrapper = props => {
  return (
    <Box
      marginRight={{
        _: 'auto',
        laptop: 'none',
      }}
      marginLeft={{
        _: 'auto',
        laptop: '12.5%',
      }}
      paddingX={{
        _: 'two',
        laptop: 'none',
      }}
      paddingY={{
        _: 'two',
        laptop: 'four',
      }}
      textAlign={{
        _: 'center',
        laptop: 'left',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

export const AuthBackgroundImage = props => (
  <StyledBackgroundImage
    role="presentation"
    src={BACKGROUND_IMAGE}
    display={{
      _: 'none',
      laptop: 'block',
    }}
    backgroundPosition="right"
    backgroundRepeat="no-repeat"
    backgroundSize="contain"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const AuthTopSectionWrapper = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Box width={{ laptop: '500px' }} {...props} />
);

export const AuthErrorWrapper = props => (
  <Box
    backgroundColor="highlight.background.subdued"
    padding="one"
    borderRadius="medium"
    marginTop="four"
    marginBottom="two"
    marginX="auto"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const useTriggerGroupInviteSignIn = inviteId => {
  return useCallback(() => {
    Auth0ClientWrapperInstance.loginWithRedirect({
      prompt: 'login',
      el_invite_code: inviteId,
      el_invite_type: 'group',
    });
  }, [inviteId]);
};

export const AuthSignInLink = () => {
  const { formatMessage } = useIntl();
  const { inviteId } = useParams();
  const triggerGroupInviteSignIn = useTriggerGroupInviteSignIn(inviteId);
  return (
    <>
      <BodyOne display="inline">
        {formatMessage({ id: 'ALREADY_HAVE_ACCOUNT' })}
      </BodyOne>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
      <Link
        as="button"
        marginLeft="half"
        display="inline"
        border="none"
        background="transparent"
        onClick={triggerGroupInviteSignIn}
      >
        {formatMessage({ id: 'SIGN_IN' })}
      </Link>
    </>
  );
};

export const DescriptionSegment = ({ heading, body, image }) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      marginTop="twoAndHalf"
      flexDirection={{
        _: 'column',
        laptop: 'row',
      }}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        height={100}
        marginRight={{
          laptop: 'two',
        }}
        marginBottom="two"
      >
        <Image width="100%" role="presentation" src={image} />
      </Box>
      <Box
        maxWidth={{
          laptop: 350,
        }}
      >
        <HeadingFour as="h3">{formatMessage({ id: heading })}</HeadingFour>
        <BodyOne marginY="one">{formatMessage({ id: body })}</BodyOne>
      </Box>
    </Flex>
  );
};

DescriptionSegment.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export const AuthTopSection = ({ firstName, onCreateAccount }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingTwo
        as="h1"
        marginTop={{
          _: 'twoAndHalf',
          laptop: 'four',
        }}
      >
        {firstName
          ? formatMessage({ id: 'WELCOME_EXCLAMATION_NAME' }, { firstName })
          : formatMessage({ id: 'WELCOME_EXCLAMATION' })}
      </HeadingTwo>
      <HeadingTwo>{formatMessage({ id: 'LETS_CREATE_ACCOUNT' })}</HeadingTwo>
      <BodyOne marginTop="one">
        {formatMessage({ id: 'YOULL_GET_TOOLS' })}
      </BodyOne>
      <PrimaryButton
        display="block"
        marginTop="two"
        marginBottom="three"
        marginX={{
          _: 'auto',
          laptop: 'none',
        }}
        width={{
          _: '100%',
          laptop: 'fit-content',
        }}
        onClick={onCreateAccount}
      >
        {formatMessage({ id: 'CREATE_ACCOUNT' })}
      </PrimaryButton>
    </>
  );
};

AuthTopSection.propTypes = {
  firstName: PropTypes.string,
  onCreateAccount: PropTypes.func.isRequired,
};

AuthTopSection.defaultProps = {
  firstName: null,
};

export const AuthBottomSection = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingTwo
        marginTop={{
          _: 'four',
          laptop: 'five',
        }}
        paddingTop={{
          _: 'none',
          laptop: 'five',
        }}
        borderTopColor="onSurface.border.subdued"
        borderTopStyle="solid"
        borderTopWidth={{
          _: 'none',
          laptop: 'thin',
        }}
      >
        {formatMessage({ id: 'IN_IT_FOR_YOU' })}
      </HeadingTwo>
      <DescriptionSegment
        heading="COVERAGE_HEADING"
        body="COVERAGE_BODY"
        image={COVERAGE_ICON}
      />
      <DescriptionSegment
        heading="PRODUCTS_HEADING"
        body="PRODUCTS_BODY"
        image={PRODUCTS_ICON}
      />
      <DescriptionSegment
        heading="ANSWERS_HEADING"
        body="ANSWERS_BODY"
        image={ANSWERS_ICON}
      />
      <DescriptionSegment
        heading="REWARDS_HEADING"
        body="REWARDS_BODY"
        image={REWARDS_ICON}
      />
    </>
  );
};
