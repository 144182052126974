import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Label,
  LabelText,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { BenefitsPicker } from '../../benefit-sets/benefits-picker/benefits-picker.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import {
  selectCurrentPlanId,
  selectAvailableBenefitsByPlanId,
} from '../enrollment-config.selectors';

const BenefitsPickerFieldPresenter = ({
  availableBenefits,
  field,
  form,
  name,
  propSchema: { title, description },
}) => {
  return (
    <Label htmlFor={field.name}>
      <LabelText>{title}</LabelText>
      <BodyTwoSecondary>{description}</BodyTwoSecondary>
      <BenefitsPicker
        initialPickedBenefits={field.value}
        initialAvailableBenefits={availableBenefits}
        onBenefitsChanged={newValues => form.setFieldValue(name, newValues)}
      />
    </Label>
  );
};

BenefitsPickerFieldPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  availableBenefits: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  const benefitPlanId = selectCurrentPlanId(state);
  return {
    availableBenefits: selectAvailableBenefitsByPlanId(state, {
      benefitPlanId,
    }),
  };
};

export const BenefitsPickerField = compose(connect(mapStateToProps))(
  BenefitsPickerFieldPresenter,
);
