/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InvoiceRow from './invoice-row.view';
import { get, uniqueId, isEmpty } from 'lodash';
import { vars, Table } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled from 'styled-components';
import SelectDivision from './invoice-table-select-division.container';
import { Message, Button } from 'semantic-ui-react';

const StyledTable = styled(Table)`
  thead {
    background-color: ${vars.colour.white};
    font-size: 1rem;
    text-transform: none;
  }

  th {
    padding: 0 0 0 1rem;
    color: ${vars.colour.navyDark};
    ${({ empty }) =>
      empty &&
      `
      border-bottom: 1px solid ${vars.colour.greyLighter};
      padding-bottom: 0.8rem;
    `};
  }

  th:last-child,
  th:first-child {
    padding-left: 0;
  }

  td {
    border-top: 1px solid ${vars.colour.greyLighter};
    padding: 0.8rem 0 0 1rem;
    overflow: visible;
  }

  td:last-child,
  td:first-child {
    padding-left: 0;
  }

  td:first-child {
    overflow: visible;
  }

  tr {
    cursor: pointer;
  }

  thead > tr {
    cursor: default;
  }

  th:last-child,
  td:last-child {
    width: 1rem;
    text-align: right;
  }

  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0 0.8rem;
  margin-bottom: ${({ empty }) => (empty ? '0.5rem' : '2rem')};
`;

const StyledMessage = styled(Message)`
  margin-top: 0 !important;
`;

const InvoiceTable = ({
  isAdmin,
  isDryRun,
  invoices,
  billingDivisions,
  showBillingDivision,
  expandedRowsMap,
  itemIdKey,
  onDownloadCsv,
  onDownloadExternalInvoice,
  selectedDivisionId,
  onDivisionSelect,
  onToggleInvoiceStatus,
  onSaveInvoiceStatus,
  invoicesTouched,
  groupId,
  billingInsightsData,
}) => (
  <>
    <Flex align="baseline">
      {showBillingDivision && (
        <SelectDivision
          billingDivisions={billingDivisions}
          selectedDivisionId={selectedDivisionId}
          onSelect={onDivisionSelect}
        />
      )}
    </Flex>
    <StyledTable empty={isEmpty(invoices)}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="INVOICE_HEADER_INVOICE_NUMBER" />
          </th>
          <th>
            <FormattedMessage id="INVOICE_HEADER_INVOICE_AMOUNT" />
          </th>
          <th>
            <FormattedMessage id="INVOICE_HEADER_INVOICE_DATE" />
          </th>
          {showBillingDivision && (
            <th>
              <FormattedMessage id="INVOICE_BILLING_DIVISION" />
            </th>
          )}
          <th>
            <FormattedMessage id="PAYROLL_HEADER_BILLING_PERIOD" />
          </th>
          <th>
            <FormattedMessage id="VIEW_OR_DOWNLOAD" />
          </th>
        </tr>
      </thead>
      <tbody>
        {invoices.reverse().map(invoice => {
          const itemId = invoice[itemIdKey];
          const isExpanded = get(expandedRowsMap, itemId, false);
          return (
            <InvoiceRow
              key={uniqueId()}
              invoice={invoice}
              showBillingDivision={showBillingDivision}
              isExpanded={isExpanded}
              isDryRun={isDryRun}
              onDownloadCsv={onDownloadCsv}
              isAdmin={isAdmin}
              groupId={groupId}
              billingInsightsData={billingInsightsData}
              onDownloadExternalInvoice={e =>
                onDownloadExternalInvoice(e, {
                  group_id: groupId,
                  record_id: invoice.invoice_id,
                  record_type: invoice.invoice_type,
                })
              }
              onToggleInvoiceStatus={
                isAdmin && invoice.invoice_provider === 'league'
                  ? onToggleInvoiceStatus
                  : () => {}
              }
            />
          );
        })}
      </tbody>
    </StyledTable>
    {isAdmin && (
      <Button
        color="violet"
        disabled={!invoicesTouched}
        onClick={onSaveInvoiceStatus}
      >
        <FormattedMessage id="SAVE" />
      </Button>
    )}
    {isEmpty(invoices) && (
      <StyledMessage>
        <FormattedMessage id="NO_INVOICES_BY_DIVISION_MESSAGE" />
      </StyledMessage>
    )}
  </>
);

InvoiceTable.defaultProps = {
  invoices: null,
  isDryRun: false,
  billingDivisions: null,
  expandedRowsMap: {},
  showBillingDivision: false,
  invoicesTouched: false,
  isAdmin: false,
  billingInsightsData: null,
};

InvoiceTable.propTypes = {
  invoices: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  isDryRun: PropTypes.bool,
  billingDivisions: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  expandedRowsMap: PropTypes.object /* eslint react/forbid-prop-types: 0 */,
  itemIdKey: PropTypes.string.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
  onToggleInvoiceStatus: PropTypes.func.isRequired,
  showBillingDivision: PropTypes.bool,
  selectedDivisionId: PropTypes.string.isRequired,
  onDivisionSelect: PropTypes.func.isRequired,
  invoicesTouched: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onSaveInvoiceStatus: PropTypes.func.isRequired,
  onDownloadExternalInvoice: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  billingInsightsData: PropTypes.shape({}),
};

export default InvoiceTable;
