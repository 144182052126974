import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  StyleProps,
  Radio,
  Text,
  Label,
  ChoiceText,
  genesisStyled,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { useValidationStatus } from '../../../enrollment-config/tree-navigation/use-validation-status.hook';
import { BenefitPlanWithValidation } from '../../benefit.types';

type LabelWrapperProps = StyleProps & {
  statusColor: string;
  isSelected: boolean;
  hasNoPlan: boolean;
};

const LabelWrapper = genesisStyled(Flex)<LabelWrapperProps>`
  border: 1px solid;
  font-weight: 500;
  text-overflow: ellipsis;
  padding: 1rem;
  flex: 1;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.decorative.brand.primary.brightest
      : theme.colors.surface.background.primary};
  border-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.decorative.brand.primary.brightest
      : theme.colors.interactive.action.disabled};
  border-left: ${({ hasNoPlan }) => (hasNoPlan ? '1px solid' : '8px solid')};
  border-left-color: ${({ statusColor, theme }) =>
    statusColor || theme.colors.interactive.action.disabled};
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.decorative.brand.primary.brightest};
    border-color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.colors.decorative.brand.primary.brightest
        : theme.colors.interactive.action.disabled};
    border-left-color: ${({ statusColor, theme }) =>
      statusColor || theme.colors.interactive.action.disabled};
  }
`;

export interface BenefitClassPlanListItemProps
  extends BenefitPlanWithValidation {
  isSelected: boolean;
  setActiveListPlanId: Dispatch<SetStateAction<string | undefined>>;
}

export const NoPlanListItem = () => {
  const { formatMessage } = useIntl();

  return (
    <Flex alignItems="center">
      <BodyOneSecondary marginLeft="two">
        {formatMessage({ id: 'NO_PLAN_MESSAGE' })}
      </BodyOneSecondary>
    </Flex>
  );
};

export const BenefitPlanListItem = ({
  id,
  name,
  isSelected,
  validationWarnings,
  validateWarningStatus,
  setActiveListPlanId,
}: BenefitClassPlanListItemProps) => {
  const { formatMessage } = useIntl();
  const validationStatusProp = useValidationStatus({
    status: validateWarningStatus,
    validationWarnings,
  });

  return (
    <Flex alignItems="center">
      <Label display="flex" flex="1" marginBottom="one">
        <Radio
          name="radio-selected-plan-id"
          value={id}
          checked={isSelected}
          onChange={() => setActiveListPlanId(id)}
        />
        <LabelWrapper
          data-testid={`plan-${id}`}
          isSelected={isSelected}
          marginLeft="threeQuarters"
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...validationStatusProp}
        >
          <ChoiceText marginLeft="threeQuarters">
            {name}{' '}
            {/* TODO: investigate why `tertiary.border.default` is being used here if it's not supported */}
            {/* @ts-expect-error */}
            <Text as="span" color="tertiary.border.default">
              {`${formatMessage({
                id: 'PLAN',
              })}`}
            </Text>
          </ChoiceText>
        </LabelWrapper>
      </Label>
    </Flex>
  );
};
