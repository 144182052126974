/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, reject } from 'lodash';
import { Container, Segment } from 'semantic-ui-react';
import Document from './document.view';
import { EditDocumentModal } from './edit-document-modal.view';
import { UploadDocumentModal } from './upload-document-modal.view';
import { PrimaryButton, Box, BodyOne } from '@leagueplatform/genesis-commons';

export const getContentIdFromDocumentId = (documentId, refs = {}) => {
  const ref = refs[documentId];
  return ref && ref.content ? ref.content.content_id : null;
};

export const getDisplayTitleFromDocumentId = (documentId, refs = {}) => {
  const ref = refs[documentId];
  return ref?.display_title || '';
};

class Documents extends Component {
  constructor(props) {
    super(props);

    const { documents } = this.props;

    this.state = {
      documents: documents || [],
      isEditingDocument: false,
      isUploadingDocument: false,
      documentData: {},
    };

    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.onRemoveDocument = this.onRemoveDocument.bind(this);
    this.onEditDocument = this.onEditDocument.bind(this);
    this.onDocumentEdited = this.onDocumentEdited.bind(this);
    this.onReorderDocument = this.onReorderDocument.bind(this);
  }

  UNSAFE_componentWillReceiveProps({
    documents,
    employerBenefitDocuments,
    addingEmployerBenefitDocuments,
  }) {
    const { setFieldValue, name } = this.props;
    // Request was made to add a benefit document to employer.
    if (
      this.props.addingEmployerBenefitDocuments &&
      !addingEmployerBenefitDocuments
    ) {
      const { contentId } = this.props.addingEmployerBenefitDocuments;
      const benefitDocumentId = find(
        Object.keys(employerBenefitDocuments),
        employerBenefitDocumentId =>
          employerBenefitDocuments[employerBenefitDocumentId].content
            .contentId === contentId,
      );
      const benefitDocument = employerBenefitDocuments[benefitDocumentId];

      // If the doc doesn't already exist in the benefit then add.
      if (
        benefitDocument &&
        !find(documents, doc => benefitDocument.id === doc.document_id)
      ) {
        const newDoc = {
          document_id: benefitDocument.id,
          content_id: contentId,
          display_title: benefitDocument.content.display_title,
          title: benefitDocument.content.title,
          benefitId: benefitDocument.benefitId,
        };

        setFieldValue(name, [...this.state.documents, newDoc]);
      }
    } else {
      const newState = documents ?? [];
      this.setState(() => ({ documents: newState }));
    }
  }

  onFileUploaded({ contentId, name, type, displayTitle }) {
    const { addEmployerBenefitDocument } = this.props;
    addEmployerBenefitDocument({
      title: name,
      type,
      contentId,
      displayTitle,
    });
  }

  onDocumentEdited({ documentId, displayTitle }) {
    const { updateEmployerBenefitDocument, benefitDocuments } = this.props;
    const document = benefitDocuments[documentId];
    const updatedDocument = { ...document, display_title: displayTitle };
    updateEmployerBenefitDocument(updatedDocument);
  }

  onReorderDocument(index, up) {
    const { documents } = this.state;
    const { setFieldValue, name } = this.props;
    const targetIndex = up ? index - 1 : index + 1;
    const tmp = documents[targetIndex];
    const updatedDocuments = [...documents];
    updatedDocuments[targetIndex] = documents[index];
    updatedDocuments[index] = tmp;
    setFieldValue(name, updatedDocuments);
  }

  onRemoveDocument(documentId) {
    const { setFieldValue, name } = this.props;
    const { documents } = this.state;
    const updatedDocuments = reject(
      documents,
      doc => doc.document_id === documentId,
    );
    setFieldValue(name, updatedDocuments);
  }

  onEditDocument({ documentId, displayTitle, title, contentId }) {
    this.setState(() => ({
      isEditingDocument: true,
      documentData: {
        documentId,
        displayTitle,
        title,
        contentId,
      },
    }));
  }

  render() {
    const { documents, isUploadingDocument, isEditingDocument, documentData } =
      this.state;
    const { benefitId, benefitDocuments } = this.props;
    return (
      <Box>
        <BodyOne marginBottom="half" color="#424f6f">
          Benefit Documents
        </BodyOne>
        <Box
          padding="one"
          borderRadius="medium"
          borderStyle="solid"
          borderWidth="thin"
          borderColor="#b2b3b4"
        >
          <Container className="container--benefit-documents">
            {documents && documents.length ? (
              <div>
                {documents
                  .filter(
                    doc =>
                      !doc.benefitId ||
                      (doc.benefitId && doc.benefitId === benefitId),
                  )
                  .map((doc, index) => (
                    <Document
                      key={doc.document_id}
                      documentId={doc.document_id}
                      contentId={
                        doc.content_id ||
                        getContentIdFromDocumentId(
                          doc.document_id,
                          benefitDocuments,
                        )
                      }
                      displayTitle={
                        doc.display_title ||
                        getDisplayTitleFromDocumentId(
                          doc.document_id,
                          benefitDocuments,
                        )
                      }
                      title={doc.title}
                      index={index}
                      length={documents.length}
                      onRemoveDocument={this.onRemoveDocument}
                      onEditDocument={this.onEditDocument}
                      onReorderDocument={this.onReorderDocument}
                    />
                  ))}
              </div>
            ) : (
              <Segment className="segment--benefit-documents__empty">
                No files have been added to this benefit.
              </Segment>
            )}
            <Box textAlign="right" marginTop="one">
              <PrimaryButton
                onClick={e => {
                  e.preventDefault();
                  this.setState(() => ({ isUploadingDocument: true }));
                }}
              >
                Upload
              </PrimaryButton>
            </Box>
          </Container>
          {isUploadingDocument && (
            <UploadDocumentModal
              hideModal={() => {
                this.setState(() => ({ isUploadingDocument: false }));
              }}
              onSubmit={args => this.onFileUploaded({ ...args })}
            />
          )}
          {isEditingDocument && (
            <EditDocumentModal
              documentData={documentData}
              hideModal={() => {
                this.setState(() => ({ isEditingDocument: false }));
              }}
              onSubmit={this.onDocumentEdited}
            />
          )}
        </Box>
      </Box>
    );
  }
}

Documents.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      document_id: PropTypes.string,
      title: PropTypes.string,
      benefitId: PropTypes.string,
    }),
  ),
  benefitId: PropTypes.string.isRequired,
  addingEmployerBenefitDocuments: PropTypes.shape({
    contentId: PropTypes.string,
  }),
  employerBenefitDocuments: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  benefitDocuments: PropTypes.shape({}).isRequired,
  addEmployerBenefitDocument: PropTypes.func.isRequired,
  updateEmployerBenefitDocument: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  documents: [],
  addingEmployerBenefitDocuments: null,
};

export default Documents;
