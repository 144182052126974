import React from 'react';
import { Image, Link, Flex } from '@leagueplatform/genesis-commons';
import ArrowLeftBlurple from './assets/arrow-left-blurple.svg';
import { Link as RouterLink } from 'react-router-dom';

interface BackLinkProps {
  to: string;
  label: string;
  onClick?: () => void;
}

export const BackLink = ({ to, label, onClick }: BackLinkProps) => (
  <Link
    onClick={onClick}
    as={RouterLink}
    to={to}
    marginBottom="oneAndHalf"
    paddingY="half"
    width="fit-content"
  >
    <Flex>
      <Image
        src={ArrowLeftBlurple}
        role="presentation"
        size="full"
        paddingRight="threeQuarters"
      />
      {label}
    </Flex>
  </Link>
);
