import React from 'react';
import PropTypes from 'prop-types';
import { genesisStyled } from '@leagueplatform/genesis-commons';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getStepPath } from '../paths';

const StyledLink = genesisStyled(Link)`
  &:hover {
    text-decoration: underline;
  };
`;

export const DependentsLink = ({ planId }) => (
  <StyledLink to={getStepPath('dependents', planId)}>
    <FormattedMessage id="DEPENDENT_COVERAGE_SELECT_DEPENDENTS_LINK_LABEL" />
  </StyledLink>
);

DependentsLink.propTypes = {
  planId: PropTypes.string.isRequired,
};
