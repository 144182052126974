import React from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import { LabelText } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

import styled from 'styled-components';
import { vars } from '@leagueplatform/ui-kit';

const ErrorMessage = styled.div`
  color: ${vars.colour.red};
  line-height: 1.4rem;
  margin-top: 0.6rem;
`;

export const StyledSelect = styled(Dropdown).attrs({
  selection: true,
  fluid: true,
})`
  &&&& {
    padding: 16px 36px 16px 12px;
    min-height: initial;
  }
`;

export const ActionsConfirmationTerminationCodeDropdown = ({
  options,
  onChange,
  reasonIsEmpty,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form.Field fluid={1}>
      <LabelText as="label" htmlFor="termination-reason">
        {formatMessage({ id: 'SCHEDULE_TERMINATION_CODE_LABEL' })}
      </LabelText>
      {options.length > 0 && (
        <StyledSelect
          id="termination-reason"
          name="termination-reason"
          placeholder={formatMessage({
            id: 'SCHEDULE_TERMINATION_CODE_LABEL',
          })}
          options={options}
          onChange={(event, { value }) => {
            onChange(value);
          }}
        />
      )}
      {reasonIsEmpty && (
        <ErrorMessage>
          {formatMessage({ id: 'EMPTY_REASON_TERMINATE_ERROR' })}
        </ErrorMessage>
      )}
    </Form.Field>
  );
};

ActionsConfirmationTerminationCodeDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  reasonIsEmpty: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

ActionsConfirmationTerminationCodeDropdown.defaultProps = {
  reasonIsEmpty: false,
};

export default ActionsConfirmationTerminationCodeDropdown;
