import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMPLOYEE_STATE_MAP } from 'common/constants';
import { selectEmployerId } from '../../selectors';
import isFuture from 'date-fns/isFuture';

export const selectApp = get('apps.employer-experience');

export const calculateEmployeeStatus = (employee, employerId) => {
  if (employee) {
    const group = employee.groups.find(g => g.group_id === employerId);
    return group.state && EMPLOYEE_STATE_MAP[group.state];
  }
  return null;
};

export const selectEmployee = createSelector(
  selectApp,
  get('employeeProfile.data'),
);

const selectEmployeeGroup = createSelector(
  [selectEmployee, selectEmployerId],
  (employee, employerId) =>
    employee?.groups?.find(g => g.group_id === employerId),
);

export const selectEmployeeEmail = createSelector(selectEmployee, get('email'));

export const selectEmployeeSuspendedDate = createSelector(
  selectEmployeeGroup,
  group => group?.suspended_date,
);

export const selectEmployeeSuspendedType = createSelector(
  selectEmployeeGroup,
  group => group?.suspended_type,
);

export const selectEmployeeCanReinstate = createSelector(
  selectEmployeeGroup,
  group => {
    const inactiveStatus = [
      EMPLOYEE_STATE_MAP.suspended,
      EMPLOYEE_STATE_MAP.terminated,
      EMPLOYEE_STATE_MAP.extension_of_benefits,
    ];
    const mappedStatus = group?.state && EMPLOYEE_STATE_MAP[group?.state];

    const hasPendingReinstatement =
      group?.reinstated_date && isFuture(new Date(group?.reinstated_date));
    const showReinstateAction =
      inactiveStatus.includes(mappedStatus) && !hasPendingReinstatement;
    const showReinstateCancel =
      inactiveStatus.includes(mappedStatus) && hasPendingReinstatement;
    return { showReinstateAction, showReinstateCancel };
  },
);

export const selectEmployeeName = createSelector(selectEmployee, employee => {
  if (employee) {
    if (employee.user_profile.first_name && employee.user_profile.last_name) {
      return `${employee.user_profile.first_name} ${employee.user_profile.last_name}`;
    }
  }
  return null;
});

export const selectEmployeeStatus = createSelector(
  [selectEmployee, selectEmployerId],
  (employee, employerId) => calculateEmployeeStatus(employee, employerId),
);

export const selectEmployeeUserId = createSelector(selectEmployee, employee =>
  employee ? employee.user_id : '',
);
