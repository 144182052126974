import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash/fp';
import { UserDocuments } from '../user-documents.view';
import { Box, ImageViewer } from '@leagueplatform/ui-kit';
import { isImageContentType } from '@leagueplatform/web-common';
import { flatten } from 'lodash';

export class UserDocumentsWithImageViewer extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    imageViewerVisible: false,
    imageWidth: 0,
    activeIndex: 0,
    images: [],
  };

  componentDidMount() {
    this.updateWindowDimensions();
    this.updateImages();
  }

  UNSAFE_componentWillReceiveProps() {
    this.updateImages();
  }

  setActiveIndex = index => {
    this.setState({ activeIndex: index });
  };

  // calculate the space available to the right of claim info container
  updateWindowDimensions = () => {
    if (this.container) {
      const xCoord = this.container.getBoundingClientRect().x;
      const imageWidth = window.innerWidth - xCoord;
      this.setState({ imageWidth });
    }
  };

  openImageViewer = (evt, url) => {
    evt.preventDefault(); // to prevent navigation to the image url
    this.setState(state => ({
      activeIndex: findIndex(imageUrl => imageUrl === url, state.images),
      imageViewerVisible: true,
    }));
  };

  closeImageViewer = activeIndex => {
    this.setState({
      activeIndex,
      imageViewerVisible: false,
    });
  };

  updateImages() {
    // flatten is needed since contentUrls is an array
    // so we would otherwise end up with an array of arrays
    const { documents } = this.props;
    const images = flatten(
      documents
        .filter(doc => isImageContentType(doc.contentType))
        .map(doc => doc.contentUrls),
    );
    this.setState({ images });
  }

  render() {
    const { imageViewerVisible, images, imageWidth, activeIndex } = this.state;

    return (
      <Box
        ref={elem => {
          this.container = elem;
        }}
        width="100%"
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <UserDocuments {...this.props} openViewer={this.openImageViewer} />
        {imageViewerVisible && (
          <ImageViewer
            activeIndex={activeIndex}
            visible={imageViewerVisible}
            onClose={() => this.closeImageViewer(0)}
            onChange={this.setActiveIndex}
            images={images}
            imageWidth={imageWidth}
          />
        )}
      </Box>
    );
  }
}

UserDocumentsWithImageViewer.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string,
      contentType: PropTypes.string,
      contentUrl: PropTypes.string,
    }),
  ).isRequired,
};
