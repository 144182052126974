import {
  FETCH_EMPLOYEE_SPENDING_ACCOUNTS,
  RESET_EMPLOYEE_SPENDING_ACCOUNTS,
} from './employee-spending-accounts.types';
import { compose, map, keyBy, filter } from 'lodash/fp';

export const initialState = {
  accounts: [],
  ready: false,
};

export const applyEmployeeSpendingAccounts = (state, { payload }) => {
  const { benefits, summaries } = payload;
  const summariesMap = keyBy('userBenefitId', summaries);
  return {
    ...state,
    accounts: compose(
      filter(o => summariesMap[o.id] && summariesMap[o.id].isSpendingAccount),
      map(o => ({
        id: o.id,
        benefit: o,
        summary: summariesMap[o.id],
        account: o.account,
        fullName: o.parent.fullName,
        benefitType: o.parent.benefitType,
      })),
    )(benefits),
    ready: true,
  };
};

export const employeeSpendingAccountsReducer = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = initialState,
  action,
) => {
  if (!action) return state;
  switch (action.type) {
    case FETCH_EMPLOYEE_SPENDING_ACCOUNTS.STARTED:
      return { ...state, ready: false };
    case FETCH_EMPLOYEE_SPENDING_ACCOUNTS.SUCCEEDED:
      return applyEmployeeSpendingAccounts(state, action);
    case RESET_EMPLOYEE_SPENDING_ACCOUNTS:
      return initialState;
    default:
      return state;
  }
};
