import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectReady,
  selectError,
  selectInviteStatus,
} from './dependent-invite.selectors';
import {
  DEPENDENT_INVITE_PAGE_LIFECYCLE,
  createAccountDependentInvite,
} from '../invite.actions';
import { DependentInviteView } from './dependent-invite.view';

export const DependentInviteContainer = props => {
  const dispatch = useDispatch();

  const { inviteId } = useParams();

  const ready = useSelector(selectReady);
  const error = useSelector(selectError);
  const inviteStatus = useSelector(selectInviteStatus);

  const allProps = {
    ...props,
    ready,
    error,
    inviteStatus,
  };

  // lifecycle
  useEffect(() => {
    dispatch(DEPENDENT_INVITE_PAGE_LIFECYCLE.visit({ inviteId }));
  }, [dispatch, inviteId]);

  const onCreateAccount = useCallback(() => {
    dispatch(createAccountDependentInvite({ inviteId }));
  }, [dispatch, inviteId]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <DependentInviteView {...allProps} onCreateAccount={onCreateAccount} />
  );
};
