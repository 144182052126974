import { createRequestTypes } from 'common/websocket-redux';

export const GET_QLE_CONFIGURATIONS =
  'qle-configuration/GET_QLE_CONFIGURATIONS';
export const SERVICE_GET_QLE_CONFIGURATIONS = createRequestTypes(
  'qle-configuration/SERVICE_GET_QLE_CONFIGURATIONS',
);

export const GET_EMPLOYER_QLE_APPROVAL_EMAILS =
  'qle-configuration/GET_EMPLOYER_QLE_APPROVAL_EMAILS';
export const SERVICE_GET_EMPLOYER_QLE_APPROVAL_EMAILS = createRequestTypes(
  'qle-configuration/GET_EMPLOYER_QLE_APPROVAL_EMAILS',
);

export const SET_EMPLOYER_QLE_APPROVAL_EMAILS =
  'qle-configuration/SET_EMPLOYER_QLE_APPROVAL_EMAILS';

export const SERVICE_SET_EMPLOYER_QLE_APPROVAL_EMAILS = createRequestTypes(
  'qle-configuration/SET_EMPLOYER_QLE_APPROVAL_EMAILS',
);

export const SET_QLE_TYPE_AND_ID = 'qle-configuration/SET_QLE_TYPE_AND_ID';

export const SET_BENEFIT_CLASS_ID =
  'qle-configuration-form/SET_BENEFIT_CLASS_ID';
