import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import styled from 'styled-components';
import get from 'lodash/get';
import {
  fontSizeInPx,
  spaceInPx,
  vars,
  Modal,
  Backdrop,
  ClickEater,
  Text,
  Box,
} from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { useIntl, FormattedHTMLMessage } from '@leagueplatform/locales';
import { initialValues } from './__mocks__/initialValues';

const StyledHeader = styled(Box)`
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${vars.colour.greyLight};
  padding: ${spaceInPx(2)} ${spaceInPx(3)};
`;

const ToggleQLEModal = styled(Modal.Body)`
  width: 480px;
  border-radius: 4px;
  text-align: left;
  &&& {
    padding: 0;
  }
`;

export const StyledButton = styled(Button)`
  height: 48px;
  font-weight: bold;
  font-size: 16px;
`;

export const CancelButton = styled(StyledButton)`
  margin-right: ${spaceInPx(2)};
`;

export const ToggleQLEStateModal = ({ onClose, handleSubmit, isOn }) => {
  const { formatMessage } = useIntl();
  return (
    <Backdrop onClose={onClose}>
      <ClickEater>
        <ToggleQLEModal>
          <StyledHeader>
            <Text color="purpleDarkest" fontSize={2} fontWeight="bold">
              {formatMessage(
                {
                  id: 'ARE_YOU_SURE_TURN_QLE',
                },
                {
                  toggle: isOn
                    ? formatMessage({ id: 'OFF_CAPS' })
                    : formatMessage({ id: 'ON_CAPS' }),
                },
              )}
            </Text>
          </StyledHeader>
          <Box p={3}>
            <Text fontSize={2} color="greyDark">
              <FormattedHTMLMessage
                id={isOn ? 'TURN_OFF_IMPLICATIONS' : 'TURN_ON_IMPLICATIONS'}
              />
            </Text>
            <Flex justify="flex-end" mt={3}>
              <CancelButton secondary onClick={onClose}>
                {formatMessage({ id: 'CANCEL' })}
              </CancelButton>
              <StyledButton primary onClick={handleSubmit}>
                {formatMessage(
                  {
                    id: 'TURN',
                  },
                  {
                    toggle: isOn
                      ? formatMessage({ id: 'OFF' })
                      : formatMessage({ id: 'ON' }),
                  },
                )}
              </StyledButton>
            </Flex>
          </Box>
        </ToggleQLEModal>
      </ClickEater>
    </Backdrop>
  );
};

ToggleQLEStateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOn: PropTypes.bool.isRequired,
};

export const ButtonAsLink = styled.button`
  border: none;
  background: ${vars.colour.white};
  color: ${vars.colour.red};
  padding: ${spaceInPx(0)};
  font-size: ${fontSizeInPx(2)};
  margin-top: ${spaceInPx(1)};
`;

const CloserText = styled(Text)`
  &&& {
    margin: 0;
  }
`;

export const ToggleQLEState = ({
  qleConfigurationId,
  values,
  setFieldValue,
}) => {
  const isOn = !values.suspended;
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();
  const handleClick = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const handleSubmit = () => {
    setFieldValue('suspended', !values.suspended);
    setShowModal(false);
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <CloserText fontSize={1} color="darkGrey">
        {formatMessage({ id: 'LAST_UPDATED' })}
      </CloserText>
      <CloserText fontSize={1} color="darkGrey" fontWeight="bold">
        {moment(get(values, 'createdAt')).format('DD MMMM YYYY, hh:mm a')}
      </CloserText>
      <ButtonAsLink onClick={handleClick}>
        {formatMessage(
          {
            id: 'TURN_THIS_QLE',
          },
          {
            toggle: isOn
              ? formatMessage({ id: 'OFF_CAPS' })
              : formatMessage({ id: 'ON_CAPS' }),
          },
        )}
      </ButtonAsLink>
      {showModal && (
        <ToggleQLEStateModal
          onClose={onClose}
          handleSubmit={handleSubmit}
          qleConfigurationId={qleConfigurationId}
          isOn={isOn}
        />
      )}
    </>
  );
};

ToggleQLEState.propTypes = {
  qleConfigurationId: PropTypes.string,
  values: PropTypes.shape({
    suspended: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  qleConfiguration: PropTypes.shape({
    qualifyingLifeEventType: PropTypes.string,
    daysAfterQleEventDate: PropTypes.number,
    daysBeforeQleEventDate: PropTypes.number,
    needsHrApproval: PropTypes.bool,
    allowToAddDependents: PropTypes.bool,
    allowToRemoveDependents: PropTypes.bool,
    allowNex: PropTypes.bool,
    typeformUrl: PropTypes.string,
    suspended: PropTypes.bool,
    setQLETypeAndId: PropTypes.func,
  }),
};

ToggleQLEState.defaultProps = {
  qleConfigurationId: '',
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  qualifyingLifeEventType: '',
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  benefitClassId: '',
  qleConfiguration: initialValues,
};
