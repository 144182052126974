import { createRequestTypes } from './request';

export const CONNECTION_OPENED = 'websocket/CONNECTION_OPENED';
export const CONNECTION_CLOSED = 'websocket/CONNECTION_CLOSED';
export const CONFIGURE_CONNECTION = createRequestTypes(
  'websocket/CONFIGURE_CONNECTION',
);
export const MESSAGE_RECEIVED = 'websocket/MESSAGE_RECEIVED';
export const MESSAGE_SENT = 'websocket/MESSAGE_SENT';
export const ERROR = 'websocket/ERROR';

/**
 * Action to signify that the WebSocket is being authenticated with the current
 * User credentials.
 *
 * This could result in a AUTHENTICATE.SUCCEEDED meaning a authenticated
 * connection has been established and an authenticated user context is
 * available.
 *
 * OTH a AUTHENTICATE.ERRORED indicates that the authentication request was
 * rejected: the user identity could not be established and the user should be
 * redirected to log-in
 */
export const AUTHENTICATE_CONNECTION = createRequestTypes(
  'websocket/AUTHENTICATE_CONNECTION',
);

/**
 * Before each request we need to check that we are still authenticated and
 * potentially re-authenticate the websocket.
 */
export const REAUTHENTICATE_CONNECTION = createRequestTypes(
  'websocket/REAUTHENTICATE_CONNECTION',
);
