import { useState } from 'react';
import { v4 as uuid } from 'js-uuid';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const fetch = async ({ messageType = '', info = {}, ...rest }) => {
    let response = '';
    setLoading(true);

    try {
      response = await SocketAsFetch.fetch({
        message_type: messageType,
        info,
        ...rest,
      });

      setError(false);
      setData(response);
    } catch (e) {
      let message;

      if (e instanceof Error) {
        message = e.message;
      } else {
        message = String(e);
      }
      setError({ ...JSON.parse(message), id: uuid() });
    }

    setLoading(false);
    return response;
  };

  return {
    fetch,
    loading,
    error,
    data,
  };
};
