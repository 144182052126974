import { selectApplicationIsBooting } from 'common/state/application/application.selectors';
import {
  selectIsFetching,
  selectMemberPostFtux,
  selectMemberTermsAndConditions,
  selectMustAcceptTerms,
  selectUserConfig,
} from 'common/state/user/user.selectors';
import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectHasUserAcceptedTerms = createSelector(
  selectMustAcceptTerms,
  selectMemberTermsAndConditions,
  selectMemberPostFtux,
  (mustAcceptTerms, memberTermsAndConditions, memberPostFtux) =>
    !mustAcceptTerms &&
    Boolean(memberTermsAndConditions) &&
    memberPostFtux?.terms,
);

export const selectIsRevisedTerms = createSelector(
  selectMustAcceptTerms,
  selectMemberTermsAndConditions,
  (mustAcceptTerms, memberTermsAndConditions) =>
    mustAcceptTerms && Boolean(memberTermsAndConditions),
);

export const selectUserDataIsLoading = createSelector(
  selectUserConfig,
  selectIsFetching,
  selectApplicationIsBooting,
  (userConfig, isFetching, isBooting) =>
    isFetching || isBooting || _.isEmpty(userConfig),
);
