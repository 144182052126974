import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { EnrollmentTemplateType } from 'apps/employer-experience/pages/EmployerDetails/enrollment-design/enrollment-experience-config/enrollment-design-options.types';

export type GetEnrollmentTemplatesParams = {
  groupId: string;
  benefitClassId: string;
  templateTypes: Array<EnrollmentTemplateType>;
  templateId: string;
};

export const getEnrollmentTemplates = ({
  groupId,
  benefitClassId,
  templateTypes,
  templateId,
}: GetEnrollmentTemplatesParams) =>
  call(websocketFetch, 'get_enrollment_templates', {
    group_id: groupId,
    benefit_class_id: benefitClassId,
    template_types: templateTypes,
    template_id: templateId,
  });
