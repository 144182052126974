import { takeLatest } from 'redux-saga/effects';
import { get, pipe } from 'lodash/fp';
import { request } from 'common/websocket-redux';
import { retryInteracPayment } from 'common/services';
import {
  RETRY_INTERAC_PAYMENT,
  RESEND_PAYMENT_SELECTED,
} from './payment.types';

export const retryPayment = transactionId =>
  request(RETRY_INTERAC_PAYMENT, retryInteracPayment(transactionId));

export const paymentSaga = () =>
  takeLatest(
    RESEND_PAYMENT_SELECTED,
    pipe(get('payload.transactionId'), retryPayment),
  );
