import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { LabelText } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '../../constants';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import ConditionalRenderWrappingTag from './conditional-render-wrapping-tag';

const ActionsConfirmationEOBDateForm = ({
  eobDateValue,
  onDateChange,
  displayInline,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConditionalRenderWrappingTag displayInline={displayInline}>
      <Form.Field fluid>
        <LabelText as="label" htmlFor="eobDate">
          {formatMessage({ id: `EXTENSION_BENEFITS_END_DATE` })}
        </LabelText>
        <StyledDayPickerInput
          id="eobDate"
          dayPickerProps={{
            enableOutsideDays: false,
          }}
          placeholder={DATE_FORMAT}
          format={DATE_FORMAT}
          value={eobDateValue}
          name="eobDate"
          onDayChange={onDateChange}
          confirmModal
        />
      </Form.Field>
    </ConditionalRenderWrappingTag>
  );
};

ActionsConfirmationEOBDateForm.propTypes = {
  eobDateValue: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  displayInline: PropTypes.bool.isRequired,
};

ActionsConfirmationEOBDateForm.defaultProps = {
  eobDateValue: '',
};

export default ActionsConfirmationEOBDateForm;
