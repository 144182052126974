import {
  GET_DASHBOARDS,
  GET_DASHBOARD_URL,
} from './employer-insights.action-types';

const initialState = {
  dashboardURLs: {},
  errors: null,
  loading: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerInsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARDS.SUCCEEDED:
      return {
        ...state,
        loadingDashboards: false,
        dashboards: action.payload.info?.dashboards,
      };
    case GET_DASHBOARDS.STARTED:
      return { ...state, loadingDashboards: true };
    case GET_DASHBOARDS.ERRORED:
      return {
        ...state,
        errors: action?.payload?.info?.reason,
        loadingDashboards: false,
      };
    case GET_DASHBOARD_URL.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const { info } = action.payload;
      return {
        ...state,
        loadingDashboardURL: false,
        dashboardURLs: {
          ...state.dashboardURLs,
          [info?.dashboard_id]: info?.dashboard_url,
        },
      };
    case GET_DASHBOARD_URL.STARTED:
      return { ...state, loadingDashboardURL: true };
    case GET_DASHBOARD_URL.ERRORED:
      return {
        ...state,
        errors: action?.payload?.info?.reason,
        loadingDashboardURL: false,
      };
    default:
      return state;
  }
};
