import React from 'react';
import PropTypes from 'prop-types';
import { Box, getColourObject } from '@leagueplatform/ui-kit';
import styled from 'styled-components';

import { Flex } from 'common/components/primitives.view';

const StyledError = styled(Box)`
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0.8rem 0;

  &&& {
    color: ${({ theme }) => getColourObject(theme).red};
  }
`;

export const ErrorWrapper = ({ children, error, className }) => (
  <Flex className={`error-wrapper ${className}`} flexDirection="column">
    <Flex flexDirection="column">{children}</Flex>
    {error && <StyledError className="message">{error}</StyledError>}
  </Flex>
);
ErrorWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};
ErrorWrapper.defaultProps = {
  error: null,
  className: '',
};
