import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik-legacy';
import { injectIntl } from 'react-intl';
import camelize from 'camelize';
import { createValidator } from 'common/adaptive-forms';
import { prePaymentsSchema } from '../../validation-schema';
import {
  selectGroupId,
  selectCreditPropsToValue,
  selectBillingUpdateStatus,
} from '../../selectors';
import {
  groupCreditContractFormSubmitted,
  resetUpdateStatus,
} from '../../actions';

import PrePayments from './presenter';

const withFormikEnhancer = Formik({
  handleSubmit: (values, { props }) => {
    // loop through employer's benefits
    let dataCollection = [];
    dataCollection = props.benefitTypes.map(benefit => {
      const type = camelize(benefit.benefitType);
      const groupCreditContracts = props.groupCreditContracts
        ? props.groupCreditContracts
        : {};
      const bufferValue = values[`billing_${benefit.type}_buffer`]
        ? values[`billing_${benefit.type}_buffer`]
        : 0;
      const canAddValue = groupCreditContracts[type]
        ? groupCreditContracts[type].canAdd
        : true;
      const adminFeeValue = groupCreditContracts[type]
        ? groupCreditContracts[type].adminFee * 100
        : 0;

      return {
        benefitType: benefit.benefitType,
        canAdd: canAddValue,
        bufferVal: bufferValue / 100,
        adminFeeVal: adminFeeValue / 100,
      };
    });
    if (dataCollection.length) {
      // call the api to set the group credit contract
      props.groupCreditContractFormSubmitted({
        groupId: props.groupId,
        info: dataCollection,
      });
    }
  },
  mapPropsToValues: props => selectCreditPropsToValue(props),
  validate: createValidator(prePaymentsSchema),
});

const mapStateToProps = state => ({
  groupId: selectGroupId(state),
  updateStatus: selectBillingUpdateStatus(state),
  resetUpdateStatus,
});

export default compose(
  connect(mapStateToProps, {
    groupCreditContractFormSubmitted,
    resetUpdateStatus,
  }),
  injectIntl,
  withFormikEnhancer,
)(PrePayments);
