import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  getThemeValue,
  colour,
  spaceInPx,
  Label,
  Box,
} from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';

const RADIO_DIAMETER = '24px';
const RADIO_DOT_DIAMETER = '14px;';
const RADIO_BORDER_WIDTH = 2;

const StyledLabel = styled(Label)`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  padding: 40px; /* ${spaceInPx(3)}; */
  color: ${colour('greyDark')};
  font-size: ${getThemeValue('fontSizes.3')}px;
  font-weight: ${getThemeValue('fontWeight.bold')};
  background-color: ${colour('greyLightest')};
  border: 1px solid ${colour('greyLighter')};
  border-radius: ${getThemeValue('radii.2')}px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

  ${({ disabled }) => {
    return disabled
      ? css`
          background-color: ${colour('greyLightest')};
          border-color: ${colour('greyLighter')};
          color: ${colour('greyLight')};
          pointer-events: none;
        `
      : css`
          &:hover {
            background-color: ${colour('purpleLightest')};
          }
        `;
  }}

  [type='radio'] {
    opacity: 0;
    cursor: pointer;
  }
`;

const StyledRadioButton = styled(Box)`
  background-color: ${colour('white')};
  border: ${RADIO_BORDER_WIDTH}px solid ${colour('greyLighter')};
  border-radius: 100%;
  width: ${RADIO_DIAMETER};
  height: ${RADIO_DIAMETER};
  justify-content: center;
  margin-top: 24px;
  align-self: center;

  ${({ disabled }) => {
    return disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          ${StyledLabel}:hover & {
            border-color: ${colour('purpleLight')};
          }
        `;
  }} ${({ error }) =>
    error &&
    css`
      border-color: ${colour('red')};
      background-color: ${colour('redLightest')};
    `};
  ${({ focused }) =>
    focused &&
    css`
      border-color: ${colour('purpleLight')};
    `};
`;

const CheckedRadio = styled(Box)`
  background-color: ${colour('purple')};
  border-radius: 100%;
  width: ${RADIO_DOT_DIAMETER};
  height: ${RADIO_DOT_DIAMETER};
  justify-content: center;
  margin-top: ${RADIO_BORDER_WIDTH + 1}px;
  margin-left: ${RADIO_BORDER_WIDTH + 1}px;
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  ${({ error }) =>
    error &&
    css`
      background-color: ${colour('red')};
    `};
`;

export const EnrollmentRadioColumnBox = ({
  id,
  disabled,
  checked,
  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
  error,
  onChange,
  value,
  children,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <StyledLabel for={id} htmlFor={id} disabled={disabled} error={error}>
      <span>{children}</span>

      <StyledRadioButton
        className="radio-wrapper"
        error={error}
        disabled={disabled}
        focused={focused}
      >
        <CheckedRadio checked={checked} error={error} disabled={disabled}>
          <input
            type="radio"
            name={id}
            disabled={disabled}
            checked={checked}
            value={value || id}
            onChange={onChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            id={id}
          />
        </CheckedRadio>
      </StyledRadioButton>
    </StyledLabel>
  );
};

EnrollmentRadioColumnBox.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  children: PropTypes.string,
};

EnrollmentRadioColumnBox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: null,
  value: null,
  children: null,
};
