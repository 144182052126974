import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  BodyTwoSecondary,
  Box,
  Flex,
  HeadingFour,
  Label,
  LabelText,
  TextField,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import { isEmpty } from 'lodash';
import { ContentfulIntegration } from '../contentful-integration/contentful-integration.view';
import {
  BenefitSet,
  BenefitSetLayoutTypes,
  EnrollmentStepItemType,
} from '../enrollment-design-options.types';
import {
  BENEFIT_SET_TYPE,
  BENEFIT_GROUP_TYPE,
} from '../enrollment-experience-config.constants';
import EditingPanelGroupHeader from './editing-panel-group-header.view';
import EditingPanelStepHeader from './editing-panel-step-header.view';
import EditingPanelHeaderTitle from './editing-panel-header-title.view';

interface EditingPanelPresenterProps {
  activeEditingItem: EnrollmentStepItemType | null;
  onCreateGroupClick: (stepId: string) => void;
  clearActiveEditingId: () => void;
  deleteActiveStepOrGroup: () => void;
  formProps: any;
  createContentfulTemplate: (type: string) => void;
  templates: any[];
  templatesLoading: boolean;
}

const EditingPanelPresenter = ({
  templates,
  createContentfulTemplate,
  activeEditingItem,
  formProps,
  templatesLoading,
  ...props
}: EditingPanelPresenterProps) => {
  const { formatMessage } = useIntl();

  const activeEditingItemType = (activeEditingItem as BenefitSet)?.type
    ? (activeEditingItem as BenefitSet)?.type
    : 'step';

  const layoutOptions = [
    {
      label: 'Basic',
      value: BenefitSetLayoutTypes.BASIC,
    },
    {
      label: 'Comparison Table',
      value: BenefitSetLayoutTypes.COMPARISON,
    },
    {
      label: 'Volume Picker',
      value: BenefitSetLayoutTypes.VOLUME,
    },
    {
      label: 'Contribution Picker',
      value: BenefitSetLayoutTypes.CONTRIBUTION,
    },
  ];

  const getHeaderFromType = (type: string) => {
    switch (type) {
      case BENEFIT_SET_TYPE:
        return (
          <EditingPanelHeaderTitle
            activeEditingItem={activeEditingItem}
            title="ENROLLMENT_DESIGN_SET"
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        );
      case BENEFIT_GROUP_TYPE:
        return (
          <EditingPanelGroupHeader
            activeEditingItem={activeEditingItem}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        );
      default:
        return (
          <EditingPanelStepHeader
            activeEditingItem={activeEditingItem}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        );
    }
  };

  const { handleChange, errors, values, handleBlur, setFieldValue } = formProps;

  return (
    <Flex flexDirection="column">
      <HeadingFour color="onSurface.text.subdued" marginBottom="half">
        {formatMessage({
          id: 'ENROLLMENT_DESIGN_SETTINGS',
        })}
      </HeadingFour>
      <Box
        padding="one"
        backgroundColor="surface.background.primary"
        borderWidth="thin"
        borderColor="onSurface.border.subdued"
        borderRadius="small"
        minHeight="500px"
      >
        {activeEditingItem && !isEmpty(activeEditingItem) ? (
          <>
            {getHeaderFromType(activeEditingItemType)}

            {activeEditingItemType !== 'set' && (
              <TextField
                marginBottom="oneAndHalf"
                name="name"
                label={formatMessage({ id: 'EDITING_PANEL_NAME' })}
                placeholder={formatMessage({
                  id: 'EDITING_PANEL_NAME_PLACEHOLDER',
                })}
                hint={formatMessage({
                  id: 'EDITING_PANEL_NAME_HINT',
                })}
                onChange={handleChange}
                error={errors.name}
                value={values.name}
                onBlur={handleBlur}
                required
              />
            )}

            {activeEditingItemType === 'step' && (
              <TextField
                marginBottom="oneAndHalf"
                name="url"
                label={formatMessage({ id: 'EDITING_PANEL_URL' })}
                placeholder={formatMessage({
                  id: 'EDITING_PANEL_URL_PLACEHOLDER',
                })}
                hint={formatMessage({
                  id: 'EDITING_PANEL_URL_HINT',
                })}
                onChange={handleChange}
                error={errors.url}
                value={values.url}
                onBlur={handleBlur}
                required
              />
            )}

            {activeEditingItemType === 'set' && (
              <Label marginBottom="oneAndHalf">
                <LabelText marginBottom="half">
                  {formatMessage({ id: 'EDITING_PANEL_LAYOUT' })}
                </LabelText>
                <Select
                  name="layout"
                  placeholder={formatMessage({
                    id: 'EDITING_PANEL_LAYOUT_PLACEHOLDER',
                  })}
                  options={layoutOptions}
                  isClearable
                  onChange={(evt: any) => {
                    const { value: val } = evt ?? {};
                    setFieldValue('layout', val || evt);
                  }}
                  value={values.layout}
                />
              </Label>
            )}

            <ContentfulIntegration
              templates={templates}
              isLoading={templatesLoading}
              createContentfulTemplate={createContentfulTemplate}
              selectedTemplate={values.content}
              onChange={value => setFieldValue('content', value || null)}
              type={activeEditingItemType}
            />
          </>
        ) : (
          <Box padding="two">
            <BodyTwoSecondary textAlign="center">
              {formatMessage({
                id: 'ENROLLMENT_DESIGN_SELECT_TO_EDIT',
              })}
            </BodyTwoSecondary>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default EditingPanelPresenter;
