import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  VisuallyHidden,
  HeadingThree,
  BodyOneSecondary,
  DangerButton,
  Image,
  QuietButton,
  SubtleButton,
} from '@leagueplatform/genesis-commons';
import CLOSE_ICON from 'apps/public/assets/close.svg';
import PropTypes from 'prop-types';
import { Modal } from '@leagueplatform/ui-kit';
import { useClaimsScreenLoaded } from '../../../hooks/use-claims-analytics.hook';
import { CLAIMS_SCREEN_NAMES } from '../../../constants/claims-screen-names';

export const CancelClaimModal = ({ closeModal, cancelClaim, claim }) => {
  const { formatMessage } = useIntl();

  useClaimsScreenLoaded(
    CLAIMS_SCREEN_NAMES.CLAIM_CONFIRM_CANCELLATION_MODAL,
    claim.requested_benefit_type,
    claim.claim_id,
  );

  return (
    <Modal.Wrapper onClose={closeModal}>
      <Box
        backgroundColor="surface.background.primary"
        borderRadius="medium"
        maxWidth="100%"
        width={600}
      >
        <Box>
          <Box
            textAlign="right"
            paddingTop="threeQuarters"
            paddingX="threeQuarters"
          >
            <QuietButton
              onClick={closeModal}
              paddingX="none"
              paddingY="none"
              borderRadius="circle"
              size="two"
              borderWidth="thin"
              borderStyle="solid"
              borderColor="onSurface.border.subdued"
            >
              <VisuallyHidden>{formatMessage({ id: 'CLOSE' })}</VisuallyHidden>
              <Image
                src={CLOSE_ICON}
                role="presentation"
                size="threeQuarters"
              />
            </QuietButton>
          </Box>
          <HeadingThree as="h2" marginBottom="one" paddingX="two">
            {formatMessage({ id: 'CANCEL_CLAIM_HEADING' })}
          </HeadingThree>
          <BodyOneSecondary paddingX="two" paddingBottom="two">
            {formatMessage({ id: 'CANCEL_CLAIM_BODY' })}
          </BodyOneSecondary>
          <Flex
            padding="two"
            justifyContent="flex-end"
            borderTopWidth="thin"
            borderTopColor="onSurface.border.subdued"
          >
            <SubtleButton marginRight="one" onClick={closeModal}>
              {formatMessage({ id: 'BACK' })}
            </SubtleButton>
            <DangerButton onClick={cancelClaim}>
              {formatMessage({ id: 'CANCEL_CLAIM_BUTTON' })}
            </DangerButton>
          </Flex>
        </Box>
      </Box>
    </Modal.Wrapper>
  );
};

CancelClaimModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  cancelClaim: PropTypes.func.isRequired,
  claim: PropTypes.shape({
    claim_id: PropTypes.string.isRequired,
    requested_benefit_type: PropTypes.string.isRequired,
  }).isRequired,
};
