import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingFour,
  css,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { selectCurrentPlanValidationWarnings } from '../../../enrollment-config.selectors';
import { WARNING_SEVERITY } from '../../../enrollment-config.constants';
import {
  getWarningsOfSeverity,
  warningMessageShape,
} from '../../../plan-options-validation/plan-options-validation.utils';

const Li = genesisStyled(Box)(
  css({
    listStyleType: 'disc',
    marginLeft: 'one',
    paddingLeft: 'quarter',
  }),
);

export const usePlanValidationWarnings = validationWarnings => {
  const errors = useMemo(
    () =>
      validationWarnings.filter(getWarningsOfSeverity(WARNING_SEVERITY.ERROR)),
    [validationWarnings],
  );
  const warnings = useMemo(
    () =>
      validationWarnings.filter(
        getWarningsOfSeverity(WARNING_SEVERITY.WARNING),
      ),
    [validationWarnings],
  );
  const isValid = useMemo(
    () => validationWarnings.length === 0,
    [validationWarnings],
  );
  return {
    errors,
    warnings,
    isValid,
  };
};

export const PlanValidationPresenter = ({ validationWarnings }) => {
  const { errors, warnings, isValid } =
    usePlanValidationWarnings(validationWarnings);
  const { formatMessage } = useIntl();
  if (isValid)
    return (
      <Box
        marginY="oneAndHalf"
        fontSize="body1"
        backgroundColor="success.background.subdued"
        padding="one"
        borderRadius="medium"
      >
        {formatMessage({ id: 'NO_VALIDATION_WARNINGS_MESSAGE' })}
      </Box>
    );
  return (
    <Box
      marginY="oneAndHalf"
      backgroundColor={
        errors.length > 0
          ? 'critical.background.subdued'
          : 'warning.background.subdued'
      }
      padding="one"
      borderRadius="medium"
    >
      <HeadingFour>
        {formatMessage({ id: 'VALIDATION_WARNINGS_HEADER' })}
      </HeadingFour>
      {errors.length > 0 && (
        <Box marginY="one">
          <Box fontWeight="bold" marginBottom="half">
            {formatMessage(
              { id: 'VALIDATION_WARNING_ERRORS' },
              { errorCount: errors.length },
            )}
          </Box>
          <Box as="ul">
            {errors.map(({ message }) => (
              <Li as="li">{message}</Li>
            ))}
          </Box>
        </Box>
      )}
      {warnings.length > 0 && (
        <Box marginY="one">
          <Box fontWeight="bold" marginBottom="half">
            {formatMessage(
              { id: 'VALIDATION_WARNING_WARNINGS' },
              { warningCount: warnings.length },
            )}
          </Box>
          <Box as="ul">
            {warnings.map(({ message }) => (
              <Li as="li">{message}</Li>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

PlanValidationPresenter.propTypes = {
  validationWarnings: PropTypes.arrayOf(warningMessageShape).isRequired,
};

export const PlanValidation = connect(
  createStructuredSelector({
    validationWarnings: selectCurrentPlanValidationWarnings,
  }),
)(PlanValidationPresenter);
