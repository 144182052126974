import {
  BodyTwoSecondary,
  Flex,
  HeadingFour,
  QuietButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import React from 'react';
import { EnrollmentStepItemType } from '../enrollment-design-options.types';

interface EditingPanelHeaderTitleProps {
  activeEditingItem: EnrollmentStepItemType | null;
  clearActiveEditingId: () => void;
  title: string;
}

const EditingPanelHeaderTitle = ({
  activeEditingItem,
  clearActiveEditingId,
  title,
}: EditingPanelHeaderTitleProps) => {
  const { formatMessage } = useIntl();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      marginBottom="threeQuarters"
    >
      <HeadingFour>
        {activeEditingItem?.name}{' '}
        <BodyTwoSecondary as="span">
          {formatMessage({ id: title })}
        </BodyTwoSecondary>
      </HeadingFour>
      <QuietButton onClick={clearActiveEditingId}>
        {formatMessage({ id: 'CLOSE' })}
      </QuietButton>
    </Flex>
  );
};

export default EditingPanelHeaderTitle;
