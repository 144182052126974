import React from 'react';
import PropTypes from 'prop-types';
import { Flex, BodyOne, SubtitleOne } from '@leagueplatform/genesis-commons';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { Logo } from 'common/components/Logos/Logo';
import {
  AuthBackgroundImage,
  AuthWrapper,
  AuthErrorWrapper,
  AuthTopSection,
  AuthTopSectionWrapper,
  AuthBottomSection,
} from 'apps/auth/auth0/auth.parts';
import { useDependentInviteError } from './use-dependent-invite-error.hook';
import { INVITE_STATUS } from '../invite.constants';

export const DependentInviteView = ({
  ready,
  error,
  inviteStatus,
  onCreateAccount,
}) => {
  const { errorMessageTitle, errorMessageNote } = useDependentInviteError({
    inviteStatus,
  });

  if (!ready) {
    return <LoadingIndicator />;
  }

  return (
    <Flex>
      <AuthWrapper>
        <AuthTopSectionWrapper>
          <Logo />
          {error ? (
            <AuthErrorWrapper>
              <SubtitleOne>{errorMessageTitle}</SubtitleOne>
              <BodyOne marginTop="half">{errorMessageNote}</BodyOne>
            </AuthErrorWrapper>
          ) : (
            <AuthTopSection onCreateAccount={onCreateAccount} />
          )}
        </AuthTopSectionWrapper>
        <AuthBottomSection />
      </AuthWrapper>
      <AuthBackgroundImage />
    </Flex>
  );
};

DependentInviteView.propTypes = {
  ready: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  inviteStatus: PropTypes.oneOf(Object.values(INVITE_STATUS)),
  onCreateAccount: PropTypes.func.isRequired,
};

DependentInviteView.defaultProps = {
  inviteStatus: null,
};
