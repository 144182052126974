import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectGroupId } from '../selectors';
import { EmployerReports } from './employer-reports.component';
import { Loading } from 'common/insight-dashboards/loading.view';
import { useInsightsDashboards } from 'common/insight-dashboards/use-insights-dashboards.hook';

export const EmployerInsights = props => {
  const groupId = useSelector(selectGroupId);

  const { isLoadingDashboards, dashboards } = useInsightsDashboards(groupId);
  if (isLoadingDashboards) return <Loading />;

  return (
    <EmployerReports
      groupId={groupId}
      dashboards={dashboards}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

EmployerInsights.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
};
