import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LeagueLogo from 'apps/public/assets/logo-league.png';

import {
  Box,
  Flex,
  Image,
  HeadingTwo,
  BodyOne,
  Label,
  Checkbox,
  ChoiceText,
  PrimaryButton,
  Link,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { RevisedTermsMessage } from './revised-terms.view';
import { useSelector } from 'react-redux';
import { selectTermsEmbeddedUrl } from 'common/state/user-settings/user-settings.selectors';
import { getPrivacyPolicyUrl } from './privacy-url';
import { usePageViewAnalytics } from '@leagueplatform/analytics';

export const TermsView = ({
  onNextStepClick,
  areTermsAgreedTo,
  isMarketingOptedIn,
  isRevisedTerms,
}) => {
  const [isTermsAgreedChecked, setIsTermsAgreedChecked] =
    useState(areTermsAgreedTo);
  const [isMarketingOptInChecked, setIsMarketingOptInChecked] =
    useState(isMarketingOptedIn);
  const termsUrl = useSelector(selectTermsEmbeddedUrl);
  const { formatMessage } = useIntl();

  usePageViewAnalytics({
    screen_name: 'Terms and Conditions',
  });

  return (
    <>
      <Flex flexDirection="column" alignItems="center" marginY="half">
        <Image src={LeagueLogo} width="five" height="auto" alt="League logo" />
      </Flex>

      <Box padding="oneAndHalf" textAlign="center">
        {isRevisedTerms && <RevisedTermsMessage />}

        <HeadingTwo as="h1" paddingY="one">
          {formatMessage({ id: 'TERMS_AND_PRIVACY' })}
        </HeadingTwo>
        <BodyOne paddingY="one">
          {formatMessage(
            {
              id: 'PLEASE_REVIEW_OUR_TERMS_CONDITIONS',
            },
            {
              // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
              a: text => (
                <Link href={getPrivacyPolicyUrl()} target="_blank">
                  {text}
                </Link>
              ),
            },
          )}
        </BodyOne>

        <Box
          as="iframe"
          borderWidth="thin"
          borderRadius="medium"
          marginX="auto"
          marginTop="one"
          marginBottom="two"
          padding="quarter"
          height={350}
          width="100%"
          maxWidth={950}
          display="block"
          src={termsUrl}
          title={formatMessage({ id: 'TERMS_AND_PRIVACY' })}
        />

        <Box maxWidth={550} marginX="auto" textAlign="left">
          <Label display="flex" marginBottom="one">
            <Checkbox
              marginRight="threeQuarters"
              checked={isTermsAgreedChecked}
              onChange={e => setIsTermsAgreedChecked(e.target.checked)}
            />
            <ChoiceText>
              {formatMessage({ id: 'I_AGREE_TO_THE_TERMS_AND' })}
            </ChoiceText>
          </Label>
          <Label
            display={isMarketingOptedIn ? 'none' : 'flex'}
            marginBottom="one"
          >
            <Checkbox
              marginRight="threeQuarters"
              checked={isMarketingOptInChecked}
              onChange={e => setIsMarketingOptInChecked(e.target.checked)}
            />
            <ChoiceText>
              {formatMessage({ id: 'I_WOULD_LIKE_TO_OPTIN_MARKETING' })}
            </ChoiceText>
          </Label>
          <Box textAlign="center" marginTop="three">
            <PrimaryButton
              disabled={!isTermsAgreedChecked}
              onClick={() =>
                onNextStepClick(isTermsAgreedChecked, isMarketingOptInChecked)
              }
            >
              {formatMessage({ id: 'NEXT_STEP' })}
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

TermsView.propTypes = {
  onNextStepClick: PropTypes.func.isRequired,
  areTermsAgreedTo: PropTypes.bool,
  isMarketingOptedIn: PropTypes.bool,
  isRevisedTerms: PropTypes.bool,
};

TermsView.defaultProps = {
  areTermsAgreedTo: false,
  isMarketingOptedIn: false,
  isRevisedTerms: false,
};
