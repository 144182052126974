import { call, fork, takeLatest } from 'redux-saga/effects';
import { websocketFetch, request } from 'common/websocket-redux';
import { explodeObject } from 'common/adaptive-forms';
import {
  ADD_ADJUSTMENT,
  GET_ADJUSTMENTS,
  ADD_ADJUSTMENT_FORM_SUBMITTED,
  EMPLOYER_ADJUSTMENTS_LIFECYCLE,
  EMPLOYER_ADJUSTMENTS_INIT,
  REQUEST_REMOVE_ADJUSTMENT,
  REMOVE_ADJUSTMENT,
} from './adjustments.types';

export function* getAdjustments(groupId) {
  return yield request(GET_ADJUSTMENTS, [
    websocketFetch,
    'get_group_invoice_adjustments',
    { group_id: groupId },
  ]);
}

export function* addAdjustment(action) {
  const formValues = action.payload;
  const data = explodeObject(formValues);
  yield request(ADD_ADJUSTMENT, [
    websocketFetch,
    'add_group_invoice_adjustment',
    data,
  ]);
  yield call(getAdjustments, data.group_id);
}

export function* removeAdjustment({ payload }) {
  yield request(REMOVE_ADJUSTMENT, [
    websocketFetch,
    'remove_group_invoice_adjustment',
    payload,
  ]);
  yield call(getAdjustments, payload.group_id);
}

export function* initialize({ payload }) {
  yield request(EMPLOYER_ADJUSTMENTS_INIT, [
    getAdjustments,
    payload.props.groupId,
  ]);
}

export function* employerAdjustmentsSagas() {
  yield fork(takeLatest, ADD_ADJUSTMENT_FORM_SUBMITTED, addAdjustment);
  yield fork(takeLatest, REQUEST_REMOVE_ADJUSTMENT, removeAdjustment);
  yield fork(takeLatest, EMPLOYER_ADJUSTMENTS_LIFECYCLE.VISITED, initialize);
}
