import { EmployerPayroll } from './employer-payroll.view';
import { PREMIUMS_REPORT_LIFECYCLE } from 'apps/employer-experience-v2/employer-reports/components/premiums-report/premiums-report.types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectGroupId } from '../selectors';
import { selectPayrollFeatureFlags } from './employer-payroll.selectors';
import { loadPayroll } from './employer-payroll.actions';

export default connect(
  createStructuredSelector({
    groupId: selectGroupId,
    featureFlags: selectPayrollFeatureFlags,
  }),
  {
    exit: PREMIUMS_REPORT_LIFECYCLE.exit,
    loadPayroll,
  },
)(EmployerPayroll);
