export const BENEFIT_STATUS_OPTED_OUT = 'opted_out';
export const BENEFIT_STATUS_CREATED = 'created';
export const BENEFIT_STATUS_READY = 'ready';
export const BENEFIT_STATUS_ACTIVE = 'active';
export const BENEFIT_STATUS_GRACE_PERIOD = 'grace_period';
export const BENEFIT_STATUS_INACTIVE = 'inactive';
export const BENEFIT_STATUS_INACTIVE_GRACE_PERIOD = 'inactive_grace_period';

export const CAN_BE_REMOVED = [BENEFIT_STATUS_READY, BENEFIT_STATUS_CREATED];

export const BENEFIT_STATUS_COLORS = {};
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_OPTED_OUT] = 'violet';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_CREATED] = 'orange';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_READY] = 'green';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_ACTIVE] = 'teal';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_GRACE_PERIOD] = 'yellow';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_INACTIVE] = 'red';

export const BENEFIT_STATUS_MAP = {};
BENEFIT_STATUS_MAP[BENEFIT_STATUS_OPTED_OUT] = 'BENEFIT_OPTED_OUT';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_CREATED] = 'BENEFIT_CREATED';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_READY] = 'BENEFIT_READY';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_ACTIVE] = 'BENEFIT_ACTIVE';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_GRACE_PERIOD] = 'BENEFIT_GRACE_PERIOD';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE] = 'BENEFIT_INACTIVE';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE_GRACE_PERIOD] =
  'BENEFIT_INACTIVE_GRACE_PERIOD';

export const EMPLOYEE_BENEFITS_BULK_STATUS_TRANSITIONS = [
  BENEFIT_STATUS_READY,
  BENEFIT_STATUS_ACTIVE,
  BENEFIT_STATUS_GRACE_PERIOD,
  BENEFIT_STATUS_INACTIVE,
];

export const EMPLOYEE_BENEFIT_STATUS_TRANSITIONS = {};

EMPLOYEE_BENEFIT_STATUS_TRANSITIONS[BENEFIT_STATUS_CREATED] = [
  BENEFIT_STATUS_READY,
];

export const SPENDING_ACCOUNTS_FIELD_SPECS = {
  dependent_class_coverage: {
    input_type: 'select',
    select_options: {
      single: 'Single',
      couple: 'Couple',
      family: 'Family',
    },
    hidden: false,
    placeholder: '',
    tooltip: '',
    label: 'Coverage',
    read_only: true,
    field_name: 'dependent_class_coverage',
    json_type: 'string',
    go_type: 'string',
    validation_rules: [
      {
        rule: 'stringinlist',
        hint: 'must be valid',
        args: {
          list: ['single', 'couple', 'family'],
        },
      },
    ],
  },
};

/* Analytics Event Constants */
export const ANALYTICS_EVENT_CATEGORY_EMPLOYEE_BENEFITS = 'employee_benefits';
export const ANALYTICS_EVENT_FLEX_DOLLARS_SUCCESS = 'flex_dollars_succeeded';
export const ANALYTICS_EVENT_LABEL_UPDATE_FLEX_DOLLARS = 'update flex dollars';
