import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

const AccountOverviewContainer = styled.div`
  margin-bottom: 4rem;
  padding-top: 2rem;

  & .account-overview h3 {
    font-weight: 400 !important;
    font-size: 1.25rem !important;
    color: #271a4d !important;
  }

  & .coverage-description > div:not(.account-overview) {
    display: none;
  }

  & .tabular {
    display: flex;
    border-top: 1px solid #e9e7e5;
    border-right: 1px solid #e9e7e5;
    border-left: 1px solid #e9e7e5;
    padding: 1rem;
  }

  & .tabular:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  & .tabular:last-of-type {
    border-top-color: #c4b2ef;
    border-bottom: 1px solid #c4b2ef;
    border-right: 1px solid #c4b2ef;
    border-left: 1px solid #c4b2ef;
    background-color: #f7f4fd;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  & .desc {
    flex-grow: 1;
    flex-basis: auto;
    order: 0;
    padding-right: 0.25em;
  }

  & .value {
    order: 2;
    flex-basis: auto;
    flex-grow: 0;
    padding-left: 0.25em;
  }

  & .label {
    text-transform: capitalize !important;
    padding: 0 !important;
    font-weight: 500 !important;
  }
`;

AccountOverviewContainer.displayName = 'AccountOverviewContainer';

export const AccountOverview = ({ body }) => (
  <AccountOverviewContainer
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(body, {
        FORCE_BODY: true,
        ALLOWED_ATTR: ['target', 'class', 'href'],
      }),
    }}
  />
);

AccountOverview.propTypes = {
  body: PropTypes.string.isRequired,
};
