import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/genesis-commons';
import { TitleDropdown } from '../title-dropdown/title-dropdown.view';
import { InsightsReportsView } from '../insights-reports/insights-reports.view';
import { DashboardContainer } from '../dashboard/dashboard.container';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { FeedbackCard } from '@leagueplatform/web-common-components';
import { ENROLLMENT_SURVEY_STRING } from '../employer-insights.constants';
import { useInsightsDashboardFeedback } from '../use-insights-dashboard-feedback.hook';

export const EnrollmentView = ({ dashboards, groupId }) => {
  const { formatMessage } = useIntl();
  const firstOption = dashboards?.[0]?.options?.[0];
  const [selectedItem, setSelectedItem] = useState(firstOption);
  const filteredReports = dashboards.find(item => item.type === 'reports');
  const isReportsSelected =
    filteredReports?.options.find(item => item.id === selectedItem.id) ?? false;

  const handleSelect = (item, view) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: 'insights',
      product_area: PRODUCT_AREA.HR_ADMIN,
      sub_product_area: 'enrollment',
      detail: `select ${item.title.toLowerCase()} from ${view}`,
    });
    setSelectedItem(item);
  };

  const feedbackUrl = useInsightsDashboardFeedback(
    groupId,
    ENROLLMENT_SURVEY_STRING,
  );

  return (
    <Box>
      <FeedbackCard
        title={formatMessage({
          id: 'ENROLLMENT_INSIGHTS_FEEDBACK_BANNER_TITLE',
        })}
        subtitle={formatMessage({ id: 'ENROLLMENT_INSIGHTS_FEEDBACK_BANNER' })}
        minMaxTextColor="onSurface.text.primary"
        feedbackUrl={feedbackUrl}
      />

      <TitleDropdown
        options={dashboards}
        selectedItem={selectedItem}
        onSelect={handleSelect}
      />
      <DashboardContainer dashboardId={selectedItem?.id} groupId={groupId} />
      {!isReportsSelected && (
        <InsightsReportsView
          options={filteredReports}
          onSelect={handleSelect}
        />
      )}
    </Box>
  );
};

EnrollmentView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  dashboards: PropTypes.arrayOf(PropTypes.object),
  groupId: PropTypes.string.isRequired,
};

EnrollmentView.defaultProps = {
  dashboards: [],
};
