import { takeLatest } from 'redux-saga/effects';
import { request, AUTHENTICATE_CONNECTION } from 'common/websocket-redux';
import { GET_USER_SETTINGS } from './user-settings.types';
import { getUserSettings } from 'common/services';

export function* requestUserSettings() {
  yield request(GET_USER_SETTINGS, getUserSettings());
}

export function* userSettingsSagas() {
  yield takeLatest(AUTHENTICATE_CONNECTION.SUCCEEDED, requestUserSettings);
}
