import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Label } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import { FORM_TYPES } from '../../constants';
import { TerminationForm } from './TerminationForm';
import { DateInputForm } from './DateInputForm';

class EmployeesBulkActions extends Component {
  // eslint-disable-next-line react/state-in-constructor -- FIXME: automatically added for existing issue
  state = {
    // eslint-disable-next-line react/no-unused-state -- FIXME: automatically added for existing issue
    confirmAction: null,
    // eslint-disable-next-line react/no-unused-state -- FIXME: automatically added for existing issue
    openConfirmation: false,
  };

  getBulkMenuOptions = () => {
    let canTerminate = true;
    let canSuspend = true;
    let canReinstate = false;

    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.selectedEmployees.forEach(employeeId => {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      const status = this.props.getEmployeeStatusInGroup({
        employeeId,
      });

      if (status.canReinstate) {
        canReinstate = status.canReinstate;
      }

      if (!status.canTerminate) {
        canTerminate = status.canTerminate;
      }

      if (!status.canSuspend) {
        canSuspend = status.canSuspend;
      }
    });

    return { canTerminate, canSuspend, canReinstate };
  };

  // eslint-disable-next-line react/no-unused-class-component-methods -- FIXME: automatically added for existing issue
  confirmedAction = () => {
    // eslint-disable-next-line react/no-unused-state -- FIXME: automatically added for existing issue
    this.setState({ confirmAction: null });
  };

  render() {
    const {
      intl,
      terminateEmployees,
      reinstateEmployees,
      suspendEmployees,
      selectedEmployees,
      onAskConfirmation,
    } = this.props;
    const bulkMenuOptions = this.getBulkMenuOptions();
    return (
      <Label className="label-bulk-employee-actions">
        <Label.Detail>{selectedEmployees.length}</Label.Detail>
        <span className="bulk-copy">
          <FormattedMessage id="BULK_ACTIONS" />
        </span>
        <Dropdown
          className="dropdown-bulk-employee-actions"
          text=" "
          icon="caret down"
          disabled={!selectedEmployees.length}
        >
          <Dropdown.Menu>
            {bulkMenuOptions.canSuspend ? (
              <Dropdown.Item
                className="dropdown-bulk-employee-actions"
                text={intl.formatMessage({ id: 'SUSPEND_EMPLOYEES' })}
                as={Button}
                basic
                onClick={() =>
                  onAskConfirmation({
                    header: intl.formatMessage({
                      id: 'BULK_SUSPENSION_CONFIRMATION_HEADER',
                    }),
                    description: intl.formatMessage({
                      id: 'BULK_SUSPENSION_CONFIRMATION_DESCRIPTION',
                    }),
                    confirmCopy: intl.formatMessage({
                      id: 'BULK_SUSPENSION_CONFIRMATION_BUTTON',
                    }),
                    actionFormType: FORM_TYPES.suspendConfirmation,
                    child: DateInputForm,
                    action: (options = {}) =>
                      suspendEmployees({ ...options, selectedEmployees }),
                  })
                }
              />
            ) : null}
            {bulkMenuOptions.canReinstate ? (
              <Dropdown.Item
                className="dropdown-bulk-employee-actions"
                text={intl.formatMessage({ id: 'REINSTATE_EMPLOYEES' })}
                as={Button}
                basic
                onClick={() =>
                  onAskConfirmation({
                    header: intl.formatMessage({
                      id: 'BULK_REINSTATE_CONFIRMATION_HEADER',
                    }),
                    description: intl.formatMessage({
                      id: 'BULK_REINSTATE_CONFIRMATION_DESCRIPTION',
                    }),
                    confirmCopy: intl.formatMessage({
                      id: 'BULK_REINSTATE_CONFIRMATION_BUTTON',
                    }),
                    actionFormType: FORM_TYPES.reinstateConfirmation,
                    child: DateInputForm,
                    action: (options = {}) =>
                      reinstateEmployees({ ...options, selectedEmployees }),
                  })
                }
              />
            ) : null}
            {bulkMenuOptions.canTerminate ? <Dropdown.Divider /> : null}
            {bulkMenuOptions.canTerminate ? (
              <Dropdown.Item
                className="dropdown-bulk-employee-actions__negative"
                text={intl.formatMessage({ id: 'TERMINATE_EMPLOYEES' })}
                as={Button}
                basic
                onClick={() =>
                  onAskConfirmation({
                    header: intl.formatMessage({
                      id: 'BULK_TERMINATION_CONFIRMATION_HEADER',
                    }),
                    description: intl.formatMessage({
                      id: 'BULK_TERMINATION_CONFIRMATION_DESCRIPTION',
                    }),
                    confirmCopy: intl.formatMessage({
                      id: 'BULK_TERMINATION_CONFIRMATION_BUTTON',
                    }),
                    actionFormType: FORM_TYPES.terminateConfirmation,
                    child: TerminationForm,
                    action: (options = {}) =>
                      terminateEmployees({ ...options, selectedEmployees }),
                  })
                }
              />
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </Label>
    );
  }
}

EmployeesBulkActions.propTypes = {
  selectedEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
  reinstateEmployees: PropTypes.func.isRequired,
  terminateEmployees: PropTypes.func.isRequired,
  suspendEmployees: PropTypes.func.isRequired,
  getEmployeeStatusInGroup: PropTypes.func.isRequired,
  onAskConfirmation: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export const WrappedComponent = EmployeesBulkActions;

export default injectIntl(EmployeesBulkActions);
