/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon } from 'semantic-ui-react';
import { DATE_FORMAT, dayInTimeZone } from 'common/adaptive-forms';
import { DEFAULT_TIME_ZONE } from 'common/constants';
import { formatCurrency } from '@leagueplatform/web-common';
import { map } from 'lodash';
import { FormattedMessage } from 'react-intl';

export const PaymentAdjustmentItem = ({
  id,
  amount,
  billingDivision,
  note,
  date,
  remove,
  isConsolidated,
}) => (
  <Table.Row key={id}>
    <Table.Cell>{date}</Table.Cell>
    <Table.Cell>${amount}</Table.Cell>
    {!isConsolidated && <Table.Cell>{billingDivision}</Table.Cell>}
    <Table.Cell>{note}</Table.Cell>
    <Table.Cell>
      <Icon name="trash outline" link onClick={() => remove(id)} />
    </Table.Cell>
  </Table.Row>
);

PaymentAdjustmentItem.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  billingDivision: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  isConsolidated: PropTypes.bool.isRequired,
};

const PaymentsAdjustmentsList = ({
  list,
  renderItem,
  emptyMessage,
  isConsolidated,
}) => (
  <div>
    {list.length ? (
      <Table basic="very" padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="PAYMENT_ADJUSTMENT_DATE" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="PAYMENT_ADJUSTMENT_AMOUNT" />
            </Table.HeaderCell>
            {!isConsolidated && (
              <Table.HeaderCell>
                <FormattedMessage id="PAYMENT_ADJUSTMENT_BILLING_DIVISION" />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              <FormattedMessage id="PAYMENT_ADJUSTMENT_NOTE" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {map(list, ({ id, amount, billing_division_id, note, date }) =>
            renderItem({
              id,
              billingDivisionId: billing_division_id,
              note,
              date: dayInTimeZone(date, DEFAULT_TIME_ZONE).format(DATE_FORMAT),
              amount: formatCurrency(amount),
              isConsolidated,
            }),
          )}
        </Table.Body>
      </Table>
    ) : (
      <div>{emptyMessage}</div>
    )}
  </div>
);

PaymentsAdjustmentsList.propTypes = {
  list: PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
  renderItem: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  isConsolidated: PropTypes.bool.isRequired,
};

export default PaymentsAdjustmentsList;
