import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@leagueplatform/genesis-commons';
import { Modal, useModalA11yProps } from '@leagueplatform/ui-kit';
import { MOBILE_NAV_HEIGHT } from '../navigation/navigation.constants';

export const ModalWrapper = ({ onClose, ...props }) => {
  const modalAriaProps = useModalA11yProps();

  return (
    <Modal.Wrapper onClose={onClose}>
      <Flex
        position={{ _: 'fixed', tablet: 'relative' }}
        top={{ _: MOBILE_NAV_HEIGHT }}
        maxHeight={{
          _: `calc(100% - ${MOBILE_NAV_HEIGHT})`,
          tablet: '100%',
        }}
        minHeight={{
          _: `calc(100% - ${MOBILE_NAV_HEIGHT})`,
          tablet: 'auto',
        }}
        maxWidth="560px"
        borderRadius={{
          _: 0,
          tablet: 'large',
        }}
        backgroundColor="surface.background.primary"
        padding={{
          _: 'oneAndHalf',
          tablet: 'twoAndHalf',
        }}
        flexDirection="column"
        width={1}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...modalAriaProps}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
    </Modal.Wrapper>
  );
};

ModalWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
};
