import {
  GET_USER_BENEFIT_FLEX_DOLLARS,
  SET_USER_BENEFIT_FLEX_DOLLARS,
} from './update-flex-dollars.types';

export const getUserBenefitFlexDollars = payload => ({
  type: GET_USER_BENEFIT_FLEX_DOLLARS.BASE,
  payload,
});

export const setUserBenefitFlexDollars = payload => ({
  type: SET_USER_BENEFIT_FLEX_DOLLARS.BASE,
  payload,
});
