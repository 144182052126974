export const BENEFIT_STATUS_OPTED_OUT = 'opted_out';
export const BENEFIT_STATUS_CREATED = 'created';
export const BENEFIT_STATUS_READY = 'ready';
export const BENEFIT_STATUS_ACTIVE = 'active';
export const BENEFIT_STATUS_GRACE_PERIOD = 'grace_period';
export const BENEFIT_STATUS_INACTIVE = 'inactive';
export const BENEFIT_STATUS_INACTIVE_GRACE_PERIOD = 'inactive_grace_period';

export const BENEFIT_STATUS_COLORS = {};
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_OPTED_OUT] = 'violet';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_CREATED] = 'orange';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_READY] = 'blue';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_ACTIVE] = 'teal';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_GRACE_PERIOD] = 'yellow';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_INACTIVE] = 'red';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_INACTIVE_GRACE_PERIOD] = 'red';

export const BENEFIT_STATUS_MAP = {};
BENEFIT_STATUS_MAP[BENEFIT_STATUS_OPTED_OUT] = 'BENEFIT_OPTED_OUT';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_CREATED] = 'BENEFIT_DRAFT';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_READY] = 'BENEFIT_PUBLISHED';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_ACTIVE] = 'BENEFIT_ACTIVE';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_GRACE_PERIOD] = 'BENEFIT_GRACE_PERIOD';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE] = 'BENEFIT_INACTIVE';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE_GRACE_PERIOD] = 'BENEFIT_INACTIVE';
