import { all, call, select, takeLatest } from 'redux-saga/effects';
import { locationChangedTo } from 'common/utilities/url';
import { selectParams } from './selectors';
import {
  FETCH_CLAIMS,
  FETCH_AGENTS,
  ON_PAGE_NUMBER_SELECTED,
  ON_SORT_ORDER_CHANGED,
  ON_FILTER_CHANGED,
} from './types';
import { request, websocketFetch } from 'common/websocket-redux';

export const isReferenceId = text => /^\d{3}-\d{3}$/.test(text);

export const withReferenceId = params =>
  isReferenceId(params.query)
    ? { ...params, query: null, reference_id: params.query }
    : params;

export function* fetchClaims() {
  const params = withReferenceId(yield select(selectParams));
  yield request(FETCH_CLAIMS, [websocketFetch, 'get_claims', params]);
}

export function* fetchAgents() {
  yield request(FETCH_AGENTS, [websocketFetch, 'get_claim_agents']);
}

export function* fetchSubmittedClaims() {
  const params = withReferenceId(yield select(selectParams));
  params.statuses = ['submitted'];
  yield request(FETCH_CLAIMS, [websocketFetch, 'get_claims', params]);
}

export function* fetchAll() {
  yield all([call(fetchSubmittedClaims), call(fetchAgents)]);
}

export const didNavigateHere = locationChangedTo('/admin/claims');

export function* claimsSaga() {
  yield takeLatest(didNavigateHere, fetchAll);
  yield takeLatest(
    [ON_PAGE_NUMBER_SELECTED, ON_SORT_ORDER_CHANGED, ON_FILTER_CHANGED],
    fetchClaims,
  );
}
