import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Confirm } from 'semantic-ui-react';
import { selectSessionId } from 'apps/auth/selectors';
import {
  selectUserDocuments,
  selectIsLoading,
  selectCurrentUserDocument,
  selectIsEditingUserDocument,
  selectIsRemovingUserDocument,
  UserDocumentsWithImageViewer,
  selectIsUploadModalOpen,
  Actions,
} from 'common/components/user-documents';
import { Box, Backdrop } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { AdminForm } from 'common/components/user-documents/user-document-forms/admin-form.presenter';
import { useIntl } from '@leagueplatform/locales';

const withState = connect(
  createStructuredSelector({
    isLoading: selectIsLoading,
    documents: selectUserDocuments,
    sessionId: selectSessionId,
    currentUserDocument: selectCurrentUserDocument,
    isEditingUserDocument: selectIsEditingUserDocument,
    isRemovingUserDocument: selectIsRemovingUserDocument,
    isUploadModalOpen: selectIsUploadModalOpen,
  }),
  {
    editUserDocument: Actions.editUserDocument,
    removeUserDocument: Actions.removeUserDocument,
    confirmRemoveUserDocument: Actions.confirmRemoveUserDocument,
    resetUserDocuments: Actions.resetUserDocuments,
    cancelEditUserDocument: Actions.cancelEditUserDocument,
    cancelRemoveUserDocument: Actions.cancelRemoveUserDocument,
    openUploadModal: Actions.openUploadModal,
    closeUploadModal: Actions.closeUploadModal,
  },
);

export const EmployeeUserDocuments = compose(withState)(
  ({
    documents,
    userId,
    resetUserDocuments,
    currentUserDocument,
    isEditingUserDocument,
    isRemovingUserDocument,
    confirmRemoveUserDocument,
    openUploadModal,
    isUploadModalOpen,
    cancelRemoveUserDocument,
    editUserDocument,
    removeUserDocument,
    isLoading,
    sessionId,
  }) => {
    const { formatMessage } = useIntl();
    return (
      <Box pb={2}>
        <UserDocumentsWithImageViewer
          documents={documents}
          userId={userId}
          isLoading={isLoading}
          sessionId={sessionId}
          editUserDocument={editUserDocument}
          resetUserDocuments={resetUserDocuments}
          confirmRemoveUserDocument={confirmRemoveUserDocument}
        />
        <Flex align="center">
          <Button small onClick={openUploadModal}>
            {formatMessage({ id: 'UPLOAD_SUPPORTING_DOCUMENT' })}
          </Button>
        </Flex>
        {isUploadModalOpen && (
          <Backdrop>
            <AdminForm sessionId={sessionId} userId={userId} />
          </Backdrop>
        )}
        {isEditingUserDocument && currentUserDocument && (
          <Backdrop>
            {isRemovingUserDocument ? (
              <Confirm
                size="mini"
                header="Delete Supporting Document"
                content={
                  <Box p={2}>
                    {formatMessage({
                      id: 'CONFIRM_DELETE_SUPPORTING_DOC_TEXT',
                    })}
                  </Box>
                }
                open={isRemovingUserDocument}
                onCancel={cancelRemoveUserDocument}
                confirmButton="Delete"
                onConfirm={() =>
                  removeUserDocument({
                    userId,
                    contentId: currentUserDocument.contentId,
                  })
                }
              />
            ) : (
              <AdminForm
                userId={userId}
                contentId={currentUserDocument.contentId}
                currentUserDocument={currentUserDocument}
              />
            )}
          </Backdrop>
        )}
      </Box>
    );
  },
);
