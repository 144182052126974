import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { useSchemaMetadata } from 'common/json-schema-form';
import { TranslatedEntityFormField } from 'common/json-schema-form/fields';
import {
  Box,
  Flex,
  QuietButton,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';

const trackAnalytics = submissionType => {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.ENROLLMENT,
    screen_name: 'beneficiaries',
    detail: 'save beneficiary',
    sub_detail: submissionType,
  });
};
export const FieldWrapper = ({ isEven, isGrid, children }) =>
  isGrid ? (
    <Box width={1 / 2}>
      <Box paddingRight={isEven ? 'half' : 0} paddingLeft={isEven ? 0 : 'half'}>
        {children}
      </Box>
    </Box>
  ) : (
    children
  );

FieldWrapper.propTypes = {
  isEven: PropTypes.bool.isRequired,
  isGrid: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const BeneficiariesEntityFormFields = props => {
  const fields = useSchemaMetadata(props);
  const gridFields = ['address', 'trustee', 'revocable'];
  return fields.map(([name, propSchema], idx) => {
    const isEven = idx % 2 === 0;
    return (
      <FieldWrapper
        key={name}
        isEven={isEven}
        isGrid={!gridFields.includes(name)}
      >
        <TranslatedEntityFormField
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
          name={name}
          propSchema={propSchema}
        />
      </FieldWrapper>
    );
  });
};

export const BeneficiaryEntityForm = ({ onCancel, ...props }) => {
  const { formatMessage } = useIntl();
  return (
    <Form>
      <Flex flexWrap="wrap" width="100%">
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <BeneficiariesEntityFormFields {...props} />
      </Flex>
      <Flex marginTop="oneAndHalf">
        <PrimaryButton
          type="submit"
          marginRight="one"
          data-testid="save-beneficiary-form"
          onClick={() => trackAnalytics('success')}
        >
          {formatMessage({ id: 'SAVE' })}
        </PrimaryButton>
        <QuietButton
          type="button"
          onClick={() => {
            trackAnalytics('failure');
            onCancel();
          }}
          marginRight="one"
          data-testid="cancel-beneficiary-form"
        >
          {formatMessage({ id: 'CANCEL' })}
        </QuietButton>
      </Flex>
    </Form>
  );
};

BeneficiaryEntityForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};
