import { all, takeEvery, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { ANALYTICS_EVENT_NAME_ADMIN } from 'apps/employer-experience-v2/employer-reports/reports.constants';
import { CLAIM_SELECTED, OPTIONS_CHANGED } from './claims.action-types';

const categories = {
  CLAIMS_HISTORY_LIST: 'Claims History List',
  CLAIMS_HISTORY_EMPTY_STATE: 'Claims History Empty State',
  CLAIMS_HISTORY_DETAIL: 'Claims History Detail',
};

function* viewDetails() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_LIST,
      action: 'View Details',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

function* submitClaim() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_EMPTY_STATE,
      action: 'Submit Claim',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

function* viewAllClaims() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_EMPTY_STATE,
      action: 'View all claims',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

function* registerStatusFilterChange(action) {
  const status = get(action, 'payload.options.statuses.0');
  if (status) {
    yield call(
      sendAnalyticsEvent,
      {
        category: categories.CLAIMS_HISTORY_LIST,
        action: 'Filter',
        label: status,
      },
      ANALYTICS_EVENT_NAME_ADMIN,
    );
  }
}

const didNavigateToWallet = action =>
  action.type === LOCATION_CHANGE &&
  action.payload.pathname === '/member/wallet';

const didNavigateToClaimsList = action =>
  action.type === LOCATION_CHANGE &&
  action.payload.pathname === '/member/claims';

export function* claimsAnalyticsSaga() {
  yield all([
    takeEvery(CLAIM_SELECTED, viewDetails),
    takeEvery(OPTIONS_CHANGED, registerStatusFilterChange),
    takeEvery(didNavigateToWallet, submitClaim),
    takeEvery(didNavigateToClaimsList, viewAllClaims),
  ]);
}
