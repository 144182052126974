import { CONTRIBUTOR_TYPES } from 'common/benefits/metadata';

export const getLeftoverFlexContribution = spendingAccountConfig =>
  (spendingAccountConfig?.amounts ?? []).find(
    ({ contributor_type: type }) => type === CONTRIBUTOR_TYPES.LEFTOVER_FLEX,
  );

export const hasEmployeeContributionType = spendingAccountConfig =>
  (spendingAccountConfig?.amounts ?? []).some(
    ({ contributor_type: type }) => type === CONTRIBUTOR_TYPES.EMPLOYEE,
  );

export const leftoverFlexBenefitsSelector = benefits => {
  const leftoverFlexBenefits = (Object.values(benefits) || []).reduce(
    (acc, benefit) => {
      const spendingAccountConfig = benefit.spending_account_config;
      const leftoverContribution = getLeftoverFlexContribution(
        spendingAccountConfig,
      );
      const isFundedOnlyByLeftoverFlex =
        (spendingAccountConfig?.amounts ?? []).length === 1 &&
        spendingAccountConfig.amounts[0].contributor_type ===
          CONTRIBUTOR_TYPES.LEFTOVER_FLEX;

      return leftoverContribution
        ? [
            ...acc,
            {
              benefitId: benefit?.benefit_id,
              benefitName: benefit?.benefit_name,
              percent: leftoverContribution?.fixed_percent ?? 100,
              isFundedOnlyByLeftoverFlex,
            },
          ]
        : acc;
    },
    [],
  );

  const lsa = benefits.find(benefit => benefit.product_type === 'lsa');
  const defaultBenefit = lsa
    ? [
        {
          benefitId: lsa?.benefit_id,
          benefitName: lsa?.benefit_name,
          percent: 100,
        },
      ]
    : [];

  return leftoverFlexBenefits.length > 0
    ? leftoverFlexBenefits
    : defaultBenefit;
};
