/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { get, has, isEmpty, isNil } from 'lodash';
import { getOr, prop } from 'lodash/fp';
import { planOptions } from 'common/benefit-sets';
import { selectBenefitClasses } from '../Benefits/selectors';
import { selectBenefitPlans } from '../Benefits/BenefitClass/Plans/selectors';
import { selectEmployerDetails } from '../selectors';
import {
  BENEFIT_CLASSES,
  BENEFIT_CLASS,
  BENEFIT_PLAN,
  ROOT,
} from './enrollment-config.constants';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { schemaMetadata, canBeTreeNode, ENTITY_REFS } from './schema-metadata';
import { ROOT_REF } from 'common/json-schema-form';
import { PLAN_STATUSES } from 'common/constants';
import { getHighestSeverity } from './plan-options-validation/plan-options-validation.utils';
import { propertyCompareFn } from 'propertyCompareFn';

export const selectEmployerExperienceApp = getOr(
  {},
  'apps.employer-experience',
);

export const selectBenefitSetEntities = createSelector(
  selectEmployerExperienceApp,
  getOr({}, 'employerBenefitPlanOptions.entities.benefitSets'),
);

export const selectBenefitSets = createSelector(
  selectBenefitSetEntities,
  Object.values,
);

const selectEnrollmentConfig = createSelector(
  selectEmployerExperienceApp,
  getOr({}, 'enrollmentConfig'),
);

export const selectBenefitPlanOptionsSchema = createSelector(
  selectEnrollmentConfig,
  getOr({}, 'benefitPlanOptionsSchema'),
);

export const selectPlanOptionsEntities = createSelector(
  selectEmployerExperienceApp,
  getOr({}, 'employerBenefitPlanOptions.entities'),
);

export const selectPlanOptionsBenefitSets = createSelector(
  selectPlanOptionsEntities,
  getOr({}, 'benefitSets'),
);

export const selectPlanOptionsLoading = createSelector(
  selectEmployerExperienceApp,
  getOr(false, 'employerBenefitPlanOptions.loading'),
);

export const selectSubmissionError = createSelector(
  selectEmployerExperienceApp,
  getOr('', 'employerBenefitPlanOptions.submissionError'),
);

export const selectPlanOptionsDataByPlanId = createSelector(
  (_, { benefitPlanId }) => benefitPlanId,
  selectPlanOptionsEntities,
  (benefitPlanId, entities) => {
    const plan = get(entities, ['planOptions', benefitPlanId]);
    return denormalize(plan, planOptions, entities);
  },
);

export const getDenormalizedPlanOptionsById = ({ benefitPlanId, entities }) => {
  const plan = get(entities, ['planOptions', benefitPlanId]);
  return denormalize(plan, planOptions, entities);
};

export const selectDenormalizedPlanOptions = createSelector(
  selectPlanOptionsEntities,
  entities =>
    Object.keys(get(entities, 'planOptions', {})).reduce(
      (benefitPlanOptions, benefitPlanId) => {
        return {
          ...benefitPlanOptions,
          [benefitPlanId]: getDenormalizedPlanOptionsById({
            benefitPlanId,
            entities,
          }),
        };
      },
      {},
    ),
);

export const selectCurrentPlanId = createSelector(
  selectEnrollmentConfig,
  prop('currentPlanId'),
);

export const selectCurrentBundlePath = createSelector(
  selectEnrollmentConfig,
  prop('currentBundlePath'),
);

export const selectMissingPlanOptions = createSelector(
  selectEnrollmentConfig,
  prop('missingPlanOptions'),
);

export const selectCurrentPlan = createSelector(
  selectCurrentPlanId,
  selectPlanOptionsEntities,
  (planId, entities) => entities?.planOptions?.[planId],
);

export const selectPlanOptionsValidation = createSelector(
  selectEnrollmentConfig,
  enrollmentConfig => enrollmentConfig?.planOptionsValidation ?? {},
);

export const selectCurrentValidationWarnings = createSelector(
  selectCurrentPlanId,
  selectPlanOptionsValidation,
  (planId, validation) => validation?.[planId] ?? {},
);

export const selectCurrentPlanValidationWarnings = createSelector(
  selectCurrentValidationWarnings,
  validationWarnings => validationWarnings?.planWarnings ?? [],
);

export const getItemName = ({
  item = {},
  itemSchemaMetadata = {},
  itemSchemaDefinition = {},
  itemIndex = 0,
}) => {
  // see if schemaMetadata exist for the item
  if (get(item, itemSchemaMetadata.nameKey)) {
    return item[itemSchemaMetadata.nameKey];
  }

  // try to get 'name' property from the schema

  if (get(item, 'name')) {
    return item.name;
  }

  // use title + index
  if (get(itemSchemaDefinition, 'title')) {
    return `${itemSchemaDefinition.title} ${itemIndex + 1}`;
  }

  // default to Item 1, Item 2 ... if all the above fails
  return `Item ${itemIndex + 1}`;
};

const getValidationWarningsForEntity = (
  planValidationWarnings,
  $ref,
  formData,
) => {
  switch ($ref) {
    case ENTITY_REFS.BENEFIT:
      return (
        planValidationWarnings?.benefitWarnings?.[formData?.benefit_id] ?? []
      );
    case ENTITY_REFS.BENEFIT_SET:
      return planValidationWarnings?.setWarnings?.[formData?.id] ?? [];
    case ENTITY_REFS.PLAN_OPTIONS:
      return planValidationWarnings?.planWarnings;
    default:
      return [];
  }
};

export const buildTreeFromSchema = ({
  schema = {},
  schemaDefinitions = {},
  entityData = {},
  parentTreePath = '',
  parentSchemaRef = '',
  parentDataPath = null,
  planValidationWarnings,
  validationPathMap,
}) =>
  Object.entries(get(schema, 'properties', []))
    .filter(([, property]) => canBeTreeNode(property))
    .filter(
      ([, property]) => has(property, 'items.$ref') || has(property, '$ref'),
    )
    .reduce((childrenList, [propertyName, property], index) => {
      const treePath = parentTreePath
        ? `${parentTreePath}.children.${index}`
        : `children.${index}`;
      const entityDataPath = [parentDataPath, propertyName]
        .filter(path => !isNil(path))
        .join('.');
      // entity has a child if type is 'array'
      const node = {
        name: property.title || propertyName,
        treePath,
        entityDataPath,
        schemaRef: `${parentSchemaRef}/properties/${propertyName}`,
      };

      let childSchemaName;
      let childEntities = [];
      const childSchema = property.items;
      if (childSchema) {
        childSchemaName = childSchema.$ref.split('/').pop();
        // check if item ref exists in 'definitions' catalogue
        if (schemaDefinitions[childSchemaName]) {
          // add data if exists
          node.entityData = []; // if no data, set it to []
          if (!isEmpty(entityData)) {
            const childMetadata = schemaMetadata[childSchema.$ref];
            const childData = get(entityData, propertyName) || [];
            node.entityData = childData;

            childEntities = childData.map((childEntityData, idx) => {
              const childName = getItemName({
                item: childEntityData,
                itemSchemaMetadata: childMetadata,
                itemSchemaDefinition: property,
                itemIndex: idx,
              });

              const validationWarnings = getValidationWarningsForEntity(
                planValidationWarnings,
                childSchema.$ref,
                childEntityData,
              );
              const childTreePath = `${treePath}.children.${idx}`;

              if (validationWarnings?.length > 0) {
                // eslint-disable-next-line no-param-reassign
                validationPathMap[childTreePath] =
                  getHighestSeverity(validationWarnings);
              }

              return {
                name: childName,
                schemaRef: childSchema.$ref,
                entityData: childEntityData,
                treePath: childTreePath,
                entityDataPath: [entityDataPath, idx].join('.'),
                validationWarnings,
              };
            });
          }

          // build node's children
          node.children = childEntities.map(child => {
            const children = buildTreeFromSchema({
              schema: schemaDefinitions[childSchemaName],
              schemaDefinitions,
              entityData: child.entityData,
              parentTreePath: child.treePath,
              parentSchemaRef: child.schemaRef,
              parentDataPath: child.entityDataPath,
              planValidationWarnings,
              validationPathMap,
            });

            // only add 'children' when it actually has a child
            return children.length > 0
              ? {
                  ...child,
                  children,
                }
              : child;
          });
        }
      } else {
        const entityName = property.$ref.split('/').pop();
        const objectEntityAsTreeNode = schemaDefinitions[entityName];
        node.schemaRef = property.$ref;
        node.entityData = entityData?.[propertyName] ?? {}; // if no data, set it to {}
        const children = buildTreeFromSchema({
          schema: objectEntityAsTreeNode,
          schemaDefinitions,
          entityData: entityData[propertyName],
          parentTreePath: node.treePath,
          parentSchemaRef: node.schemaRef,
          parentDataPath: node.entityDataPath,
          planValidationWarnings,
          validationPathMap,
        });
        if (children.length > 0) {
          node.children = children;
        }
      }

      return [...childrenList, node];
    }, []);

export const buildPlanOptionsTree = ({
  benefitPlanOptionsSchema,
  benefitPlanOptionsData,
  ...other
}) =>
  buildTreeFromSchema({
    ...other,
    schema: benefitPlanOptionsSchema,
    schemaDefinitions: benefitPlanOptionsSchema.definitions,
    entityData: benefitPlanOptionsData,
  });

export const selectMappedBenefitClassesData = createSelector(
  selectBenefitClasses,
  selectBenefitPlans,
  selectBenefitPlanOptionsSchema,
  selectDenormalizedPlanOptions,
  selectMissingPlanOptions,
  selectPlanOptionsValidation,
  (
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitClasses = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitPlans = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitPlanOptionsSchema = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitPlanOptionsData = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    missingPlanOptions = [],
    allPlanValidationWarnings,
  ) => {
    const validationPathMap = {};
    const tree = {
      name: BENEFIT_CLASSES,
      treePath: ROOT,
      entityDataPath: null,
      children: Object.values(benefitClasses).map((benefitClass, idx) => {
        const classTreePath = `children.${idx}`;
        return {
          name: benefitClass.name,
          entityType: BENEFIT_CLASS,
          treePath: classTreePath,
          entityDataPath: null,
          children: Object.values(benefitPlans)
            .filter(
              benefitPlan => benefitPlan.benefitClassId === benefitClass.id,
            )
            .map((benefitPlan, index) => {
              const planValidationWarnings =
                allPlanValidationWarnings?.[benefitPlan.id] ?? {};
              const validationWarnings = getValidationWarningsForEntity(
                planValidationWarnings,
                ROOT_REF,
              );
              const treePath = `${classTreePath}.children.${index}`;
              if (validationWarnings?.length > 0) {
                validationPathMap[treePath] =
                  getHighestSeverity(validationWarnings);
              }
              return {
                treePath,
                validationWarnings,
                name: benefitPlan.name,
                entityType: BENEFIT_PLAN,
                planId: benefitPlan.id,
                entityData: benefitPlanOptionsData[benefitPlan.id],
                entityDataPath: `plan[${benefitPlan.id}]`,
                schemaRef: ROOT_REF,
                children:
                  !missingPlanOptions.includes(benefitPlan.id) &&
                  buildPlanOptionsTree({
                    benefitPlanOptionsSchema,
                    benefitPlanOptionsData:
                      benefitPlanOptionsData[benefitPlan.id],
                    parentTreePath: `${classTreePath}.children.${index}`,
                    parentSchemaRef: ROOT_REF,
                    parentDataPath: `plan[${benefitPlan.id}]`,
                    planValidationWarnings,
                    validationPathMap,
                  }),
              };
            }),
        };
      }),
    };

    return { tree, validationPathMap };
  },
);

export const selectMappedBenefitClassesTreeData = createSelector(
  selectMappedBenefitClassesData,
  data => data?.tree,
);

// @TODO remove above selector after releasing NEDT
// Selector to just fetch plan related tree part in enrollment config
export const selectActiveBenefitPlanData = createSelector(
  selectCurrentPlanId,
  selectBenefitPlans,
  selectBenefitPlanOptionsSchema,
  selectDenormalizedPlanOptions,
  selectMissingPlanOptions,
  selectPlanOptionsValidation,
  (
    currentPlanId,
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitPlans = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitPlanOptionsSchema = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    benefitPlanOptionsData = {},
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    missingPlanOptions = [],
    allPlanValidationWarnings,
  ) => {
    const validationPathMap = {};
    const benefitPlan = Object.values(benefitPlans).find(
      _benefitPlan => _benefitPlan.id === currentPlanId,
    );
    let tree = {};
    if (benefitPlan) {
      const planValidationWarnings =
        allPlanValidationWarnings?.[benefitPlan.id] ?? {};
      const validationWarnings = getValidationWarningsForEntity(
        planValidationWarnings,
        ROOT_REF,
      );
      const treePath = ROOT;
      if (validationWarnings?.length > 0) {
        validationPathMap[treePath] = getHighestSeverity(validationWarnings);
      }
      tree = {
        treePath,
        validationWarnings,
        name: benefitPlan.name,
        entityType: BENEFIT_PLAN,
        planId: benefitPlan.id,
        entityData: benefitPlanOptionsData[benefitPlan.id],
        entityDataPath: `plan[${benefitPlan.id}]`,
        schemaRef: ROOT_REF,
        children:
          !missingPlanOptions.includes(benefitPlan.id) &&
          buildPlanOptionsTree({
            benefitPlanOptionsSchema,
            benefitPlanOptionsData: benefitPlanOptionsData[benefitPlan.id],
            parentTreePath: '',
            parentSchemaRef: ROOT_REF,
            parentDataPath: `plan[${benefitPlan.id}]`,
            planValidationWarnings,
            validationPathMap,
          }),
      };
    }

    return { tree, validationPathMap };
  },
);

export const selectActiveBenefitPlanTreeData = createSelector(
  selectActiveBenefitPlanData,
  data => data?.tree,
);

export const selectValidationPathMap = createSelector(
  selectMappedBenefitClassesData,
  data => data?.validationPathMap,
);

export const selectEmployerBenefits = createSelector(
  selectEmployerDetails,
  prop('employerBenefits.entities.formData'),
);

export const selectAvailableBenefitsByPlanId = createSelector(
  (_, { benefitPlanId }) => benefitPlanId,
  selectCurrentPlanId,
  selectEmployerBenefits,
  selectBenefitSets,
  (injectedPlanId, currentPlanId, employerBenefits = {}, benefitSets = []) => {
    const planId = currentPlanId || injectedPlanId;
    if (!planId) return [];
    const benefitSetsBenefitIds = benefitSets.reduce(
      (benefitsIds, set) =>
        set.benefits ? [...benefitsIds, ...set.benefits] : benefitsIds,
      [],
    );

    return Object.values(employerBenefits)
      .filter(
        benefit =>
          benefit.plan_id === planId &&
          !benefitSetsBenefitIds.includes(benefit.id),
      )
      .sort(propertyCompareFn('full_name'))
      .map(
        ({
          id: benefit_id,
          full_name: benefit_full_name,
          plan_name: benefit_name,
        }) => ({
          benefit_id,
          benefit_name,
          benefit_full_name,
        }),
      );
  },
);

export const selectCurrentPlanStatus = createSelector(
  selectCurrentPlan,
  plan => plan?.plan_details?.status,
);

export const selectCanStartEnrollment = createSelector(
  selectCurrentPlanStatus,
  status => status === PLAN_STATUSES.CREATED,
);

export const selectCurrentPlanName = createSelector(
  selectCurrentPlan,
  plan => plan?.plan_details?.name,
);

export const selectCurrentPlanBenefitClassName = createSelector(
  selectCurrentPlan,
  selectBenefitClasses,
  (plan, benefitClasses) => {
    const planBenefitClassId = plan?.plan_details?.benefit_class_id;
    return benefitClasses?.find(({ id }) => id === planBenefitClassId)?.name;
  },
);

export const selectAvailableBundles = createSelector(
  selectCurrentPlanId,
  selectPlanOptionsEntities,
  (planId, entities) => {
    if (!planId) return [];
    const bundleIds = get(entities, `planOptions.${planId}.bundles`, []);
    return bundleIds.map(bundleId => get(entities, `bundles.${bundleId}`));
  },
);

export const selectDefaultBenefitsPerBundle = createSelector(
  selectAvailableBundles,
  selectPlanOptionsEntities,
  (bundles, entities) =>
    bundles
      .map(bundle => {
        return {
          name: bundle.name,
          id: bundle.id,
          defaultBenefits: bundle?.benefit_sets?.reduce(
            (defaultBenefits, setId) => {
              const set = entities.benefitSets[setId];
              const setDefaults =
                set?.benefits?.reduce((setDefaultBenefits, benefitId) => {
                  const {
                    default: isDefault,
                    benefit_id: id,
                    benefit_short_name: shortName,
                    benefit_name: name,
                  } = entities.benefits[benefitId] ?? {};
                  return isDefault
                    ? [
                        ...setDefaultBenefits,
                        {
                          name,
                          shortName,
                          id,
                        },
                      ]
                    : setDefaultBenefits;
                }, []) ?? [];

              return [...defaultBenefits, ...setDefaults];
            },
            [],
          ),
        };
      })
      .filter(bundle => bundle.defaultBenefits.length > 0),
);

export const selectCurrentBundleId = createSelector(
  selectCurrentPlanId,
  selectCurrentBundlePath,
  selectPlanOptionsEntities,
  (planId, bundlePath, entities) =>
    get(entities, `planOptions.${planId}.${bundlePath}`),
);

export const selectAvailableBenefitSets = createSelector(
  selectAvailableBundles,
  selectPlanOptionsEntities,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  (bundles = [], entities) => {
    const setIds = bundles.reduce(
      (benefitSetIds, { benefit_sets: bundleSetIds }) =>
        bundleSetIds ? [...benefitSetIds, ...bundleSetIds] : benefitSetIds,
      [],
    );
    return setIds.map(id => {
      const set = get(entities, `benefitSets.${id}`);
      return {
        ...set,
        bundleName:
          bundles.length > 1
            ? get(entities, `bundles.${set.bundleId}.name`)
            : null,
      };
    });
  },
);

export const selectAvailableBenefitSetsForCurrentBundle = createSelector(
  selectCurrentBundleId,
  selectPlanOptionsEntities,
  (bundleId, entities) => {
    if (!bundleId) return [];
    return get(entities, `bundles.${bundleId}.benefit_sets`, []).map(id =>
      get(entities, `benefitSets.${id}`),
    );
  },
);

export const selectAvailableBenefitsForCurrentBundle = createSelector(
  selectAvailableBenefitSetsForCurrentBundle,
  selectPlanOptionsEntities,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  (benefitSets = [], entities) => {
    const benefitIds = benefitSets.reduce(
      (benefitsIds, set) =>
        set.benefits ? [...benefitsIds, ...set.benefits] : benefitsIds,
      [],
    );
    return benefitIds.map(id => get(entities, `benefits.${id}`));
  },
);
