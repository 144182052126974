import camelize from 'camelize';
import { combineReducers } from 'redux';
import {
  healthActivitiesInitialState,
  healthActivities,
} from '../HealthActivities/health-activities-page.reducer';
import {
  GET_HEALTH_PROFILE,
  ON_ROUTE_CHANGE,
  GET_HEALTH_PROGRAMS,
} from './health-concierge-tooling.actions';

export const initialState = {
  isLoading: false,
  error: {
    hasError: false,
    errorMsg: '',
  },
  healthProfile: {},
  healthPrograms: [],
  healthActivities: healthActivitiesInitialState,
};

const healthProfile = (state = initialState.healthProfile, action = {}) => {
  switch (action.type) {
    case GET_HEALTH_PROFILE.SUCCEEDED: {
      const {
        healthProfile: { riskFactorInputs, userRiskFactors },
      } = camelize(action.payload.info);
      const riskFactors = Object.values(userRiskFactors ?? {}).map(
        ({ riskFactor = {}, atRisk = false }) => ({
          ...riskFactor,
          atRisk,
        }),
      );

      return { riskFactorInputs, riskFactors };
    }
    default:
      return state;
  }
};

const healthPrograms = (state = initialState.healthPrograms, action = {}) => {
  switch (action.type) {
    case GET_HEALTH_PROGRAMS.SUCCEEDED:
      return camelize(action.payload.info.user_programs ?? []);
    default:
      return state;
  }
};

const isLoading = (state = initialState.isLoading, action = {}) => {
  switch (action.type) {
    case ON_ROUTE_CHANGE:
    case GET_HEALTH_PROFILE.STARTED:
    case GET_HEALTH_PROGRAMS.STARTED:
      return true;
    case GET_HEALTH_PROFILE.SUCCEEDED:
    case GET_HEALTH_PROGRAMS.SUCCEEDED:
    case GET_HEALTH_PROFILE.ERRORED:
    case GET_HEALTH_PROGRAMS.ERRORED:
      return false;
    default:
      return state;
  }
};

const error = (state = initialState.error, action = {}) => {
  switch (action.type) {
    case ON_ROUTE_CHANGE:
    case GET_HEALTH_PROFILE.STARTED:
    case GET_HEALTH_PROGRAMS.STARTED:
      return initialState.error;
    case GET_HEALTH_PROFILE.ERRORED:
    case GET_HEALTH_PROGRAMS.ERRORED: {
      return {
        hasError: true,
        errorMsg: action?.payload?.info?.reason ?? 'Something went wrong...',
      };
    }
    default:
      return state;
  }
};

export const healthConciergeReducer = combineReducers({
  healthProfile,
  healthPrograms,
  healthActivities,
  isLoading,
  error,
});
