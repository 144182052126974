import React from 'react';
import PropTypes from 'prop-types';
import { Treebeard, decorators } from 'react-treebeard';
import { treeStyle } from './tree-navigation-style';
import { TreeNodeContainer } from './tree-node-container.view';
import { TreeNodeHeader } from './tree-node-header.view';

export const TreeNavigationPresenter = ({ treeData, onToggle }) => {
  return (
    treeData && (
      <Treebeard
        data={treeData}
        onToggle={onToggle}
        style={treeStyle}
        decorators={{
          ...decorators,
          Container: TreeNodeContainer,
          Header: TreeNodeHeader,
        }}
      />
    )
  );
};

TreeNavigationPresenter.propTypes = {
  treeData: PropTypes.shape({
    name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    children: PropTypes.array,
  }),
  onToggle: PropTypes.func.isRequired,
};

TreeNavigationPresenter.defaultProps = {
  treeData: null,
};
