import { createTypeCaster } from './cast-types';
import { flattenObject } from './utilities';

export const createFormValueFormatter = schema => {
  const cast = createTypeCaster(schema);
  return (formValues, decamelize = false) => {
    const flatValues = flattenObject(formValues, decamelize);
    return cast(flatValues);
  };
};
