import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getUserSalesforceChatTranscripts - fetches a list of chat
 * transcripts from past chats for the current user
 *
 * @arg {number} offset - The paging offset for a list of chat transcripts
 * @arg {number} limit - The paging limit for a list of chat transcripts
 *
 * @return {Object} - A redux-saga call effect
 */
export const getUserSalesforceChatTranscripts = ({
  offset = 0,
  limit = 25,
} = {}) =>
  call(websocketFetch, 'get_user_salesforce_chat_transcripts', {
    offset,
    limit,
  });
