import { request } from 'common/websocket-redux';
import { takeLatest } from 'redux-saga/effects';
import { getUserVaccinationWorkflow } from './user-vaccination-workflow.service';
import { GET_USER_VACCINATION_WORKFLOW } from './user-vaccination-workflow.actions';

export function* requestUserVaccinationWorkflow({
  payload: { vaccineType, userId },
}) {
  yield request(
    GET_USER_VACCINATION_WORKFLOW,
    getUserVaccinationWorkflow({ vaccineType, userId }),
    { entityId: `${vaccineType}-${userId}` },
  );
}

export function* userVaccinationWorkflowSaga() {
  yield takeLatest(
    GET_USER_VACCINATION_WORKFLOW.BASE,
    requestUserVaccinationWorkflow,
  );
}
