import {
  difference,
  intersection,
  isUndefined,
  indexOf,
  split,
  startsWith,
  uniq,
  isArray as isArrayLodash,
} from 'lodash';
import { isValid as isValidDate, isBefore, isAfter, parseISO } from 'date-fns';
import {
  emailRegex,
  validPriceAmountRegex,
  phoneNumberRegex,
  phoneCountryCodeRegex,
  postalCodeRegex,
  zipCodeRegex,
  usSSNFormatWithDashes,
  usSSNFormatWithoutDashes,
  maskedSSNFormatWithDashes,
  maskedSSNFormatWithoutDashes,
} from '../utilities';

export const isBoolean = (args, value) => typeof value === 'boolean';

export const isNumber = (args, value) => typeof value === 'number';

export const isString = (args, value) => typeof value === 'string';

export const isArray = (args, value) => isArrayLodash(value);

export const charsInString = string => uniq(split(string, ''));

export const isDefined = (args, value) => !isUndefined(value);

export const isAtLeast = (args, value) => value >= args.size;

export const isAtMost = (args, value) => value <= args.size;

export const belongsToCharacterSet = (args, value) => {
  const validChars = charsInString(args.characters);
  const inputChars = charsInString(value);
  const invalidChars = difference(inputChars, validChars);
  return invalidChars.length < 1;
};

export const containsCharacters = (args, value) => {
  const requiredChars = charsInString(args.characters);
  const inputChars = charsInString(value);
  const present = intersection(requiredChars, inputChars);
  return present.length === requiredChars.length;
};

export const isEmail = (args, value) => emailRegex.test(value);

export const isPhoneNumber = (args, value) => {
  return phoneNumberRegex.test(value);
};

export const isValidPriceAmount = value => {
  return validPriceAmountRegex.test(value);
};

export const isPhoneCountryCode = (args, value) => {
  return phoneCountryCodeRegex.test(value);
};

export const isObjectId = (args, value) =>
  value &&
  value.length === 32 &&
  belongsToCharacterSet({ characters: '0123456789abcdef' }, value);

export const hasPrefix = (args, value) => startsWith(value, args.prefix);

export const hasMinLength = (args, value) => value && value.length >= args.size;

export const hasMaxLength = (args, value) =>
  !isUndefined(value) && value.length <= args.size;

export const isLengthy = (args, value) => value && value.length > 0;

export const belongsToList = (args, value) => indexOf(args.list, value) > -1;

export const isDate = (args, value) => isValidDate(new Date(value));

export const dateIsBefore = (args, value) =>
  isBefore(parseISO(value), parseISO(args.date));

export const dateIsAfter = (args, value) =>
  isAfter(parseISO(value), parseISO(args.date));

export const isPostalCode = (args, value) =>
  postalCodeRegex.test(value.replace(/\s/g, ''));

export const isZipCode = (args, value) => zipCodeRegex.test(value);

export const isUSSSN = (args, value) =>
  maskedSSNFormatWithDashes.test(value) ||
  maskedSSNFormatWithoutDashes.test(value) ||
  usSSNFormatWithDashes.test(value) ||
  usSSNFormatWithoutDashes.test(value);
