import { APP_CONFIG } from 'app-config';
import { addMessageType } from 'common/websocket-redux';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { inviteAccessReducer } from './invite-access.reducer';
import { inviteAccessSaga } from './invite-access.saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';

const sagaMiddleware = createSagaMiddleware();

function getMiddlewares(history) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  if (APP_CONFIG.REACT_APP_REDUX_LOG === 'enabled') {
    const logger = createLogger({
      actionTransformer: addMessageType,
      collapsed: true,
      duration: true,
    });
    return compose(applyMiddleware(logger, ...middlewares));
  }
  return applyMiddleware(...middlewares);
}

export const createConfiguredStore = history => {
  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      resources: inviteAccessReducer,
    }),
    compose(getMiddlewares(history)),
  );
  sagaMiddleware.run(inviteAccessSaga);
  return store;
};
