import { take, fork } from 'redux-saga/effects';
import { websocketSaga, CONFIGURE_CONNECTION } from 'common/websocket-redux';
import { ssoErrorSaga } from './sso/sso-error.saga';
import { APP_CONFIG } from 'app-config';

export function* errorSaga() {
  yield fork(websocketSaga, { websocketUrl: APP_CONFIG.REACT_APP_API_URL });
  // note: Auth not required for this page
  yield take(CONFIGURE_CONNECTION.SUCCEEDED);
  yield fork(ssoErrorSaga);
}
