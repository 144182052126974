import React from 'react';
import { PrimaryButton } from '@leagueplatform/genesis-commons';
import { ErrorMessage } from 'common/components/ErrorMessage/error-message.view';
import { useIntl } from '@leagueplatform/locales';

export const AppErrorMessage = () => {
  const { formatMessage } = useIntl();
  return (
    <ErrorMessage
      title={formatMessage({ id: 'ERROR_STATE_TITLE' })}
      message={formatMessage({ id: 'ERROR_STATE_MESSAGE' })}
    >
      <PrimaryButton
        as="a"
        style={{ textDecoration: 'none' }}
        href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}
      >
        {formatMessage({ id: 'CONTACT_CUSTOMER_SERVICE' })}
      </PrimaryButton>
    </ErrorMessage>
  );
};
