import { createSelector } from 'reselect';
import { get } from 'lodash';
import {
  createValidator,
  createFormGenerator,
  createFormValueFormatter,
} from 'common/adaptive-forms';
import Submit from './submit.view';

export const mapPropsToValues = props => {
  /* Dependent Class Amounts requires special handling to support an object
  value type in League spending accounts. */
  const dependentClassAmounts = get(
    props,
    'values.benefit.dependent_class_amounts',
  );
  const values = createFormValueFormatter(props.schema)(props.values);
  return {
    ...values,
    'benefit.dependent_class_amounts': dependentClassAmounts,
    plan_id: props.planId || props.values.plan_id,
    provider_id: props.groupId || props.values.provider_id,
  };
};

export const selectEmployerBenefits = state =>
  get(
    state,
    'apps.employer-experience.pages.employerDetails.employerBenefits.entities',
    {},
  );

export const selectPolicyEndDate = parentId =>
  createSelector(selectEmployerBenefits, ({ benefits }) =>
    get(benefits, `${parentId}.benefitEndDate`, null),
  );

export const selectLayout =
  name =>
  ({ layouts }) =>
    layouts[name];

export const selectBenefitTypesSchemas = state =>
  get(state, 'apps.employer-experience.benefitTypesSchemas.employer', {});

export const selectUserBenefitTypesSchemas = state =>
  get(state, 'apps.employer-experience.benefitTypesSchemas.users', {});

export const selectBenefitSchema = name =>
  createSelector(selectBenefitTypesSchemas, schemas => schemas[name]);

export const selectUserBenefitSchema = name =>
  createSelector(selectUserBenefitTypesSchemas, schemas => schemas[name]);

export const createValidatorSelector = name =>
  createSelector(selectBenefitSchema(name), createValidator);

export const createFormGeneratorSelector = (
  benefitType,
  layoutName,
  customComponents = {},
  customSchemaSpecs = {},
) =>
  createSelector(
    [selectBenefitSchema(benefitType), selectLayout(layoutName)],
    (schema, layout) => {
      return createFormGenerator({
        schema: { ...schema, ...customSchemaSpecs },
        layout,
        customComponents: {
          submit: Submit,
          ...customComponents,
        },
      });
    },
  );
