import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { isEntityId, normalizePolicyKind } from '@leagueplatform/web-common';

export const getUserBenefitCategories = ({
  userId,
  benefitId = null,
  asOfDate = null,
  query = null,
  version = null,
}) => {
  const benefitIdParameter = isEntityId(benefitId)
    ? { user_benefit_id: benefitId }
    : { policy_kind: normalizePolicyKind(benefitId) };

  return call(websocketFetch, 'get_user_benefit_categories', {
    user_id: userId,
    as_of_date: asOfDate,
    query,
    version,
    ...benefitIdParameter,
  });
};
