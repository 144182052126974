import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect, Label, CheckBox } from '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import { Text, Box } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

export const SalesforceSyncContent = ({
  vendorsList,
  selectedVendorsList,
  onSelectionChange,
  onSaveVendors,
  isSaveVendorsLoading,
  shouldCreateCasesForEmployeeCOBRA,
  onCreateCasesForEmployeeCOBRAChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Text marginBottom="one">
        {formatMessage({ id: 'SALESFORCE_SYNC_INTRO' })}
      </Text>
      <Text marginBottom="one">
        {formatMessage({ id: 'SALESFORCE_SYNC_NOTE' })}
      </Text>
      <Box marginBottom="two">
        <Label htmlFor="vendorsList">Vendors</Label>
        <MultiSelect
          name="vendorsList"
          inputId="vendorsList"
          options={vendorsList}
          placeholder="Choose vendors"
          isMulti
          disabled={isSaveVendorsLoading}
          onChange={currentSelection => {
            onSelectionChange(currentSelection);
          }}
          value={selectedVendorsList}
        />
      </Box>

      <Text marginBottom="one">
        {formatMessage({ id: 'SALESFORCE_SYNC_COBRA_DESCRIPTION' })}
      </Text>
      <Box marginBottom="two">
        <CheckBox
          id="createCasesForEmployeeCOBRACheckbox"
          name="createCasesForEmployeeCOBRACheckbox"
          onChange={({ target: { checked } }) => {
            onCreateCasesForEmployeeCOBRAChange(checked);
          }}
          checked={shouldCreateCasesForEmployeeCOBRA}
          disabled={isSaveVendorsLoading}
        >
          Create cases for employee COBRA
        </CheckBox>
      </Box>

      <LoaderButton
        primary
        onClick={() => onSaveVendors()}
        loading={isSaveVendorsLoading}
      >
        {formatMessage({ id: 'SALESFORCE_SYNC_ENABLE_BUTTON' })}
      </LoaderButton>
    </>
  );
};

SalesforceSyncContent.propTypes = {
  vendorsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedVendorsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onSelectionChange: PropTypes.func.isRequired,
  onSaveVendors: PropTypes.func.isRequired,
  isSaveVendorsLoading: PropTypes.bool,
  shouldCreateCasesForEmployeeCOBRA: PropTypes.bool,
  onCreateCasesForEmployeeCOBRAChange: PropTypes.func.isRequired,
};

SalesforceSyncContent.defaultProps = {
  vendorsList: [],
  selectedVendorsList: [],
  isSaveVendorsLoading: false,
  shouldCreateCasesForEmployeeCOBRA: false,
};
