export const ANALYTICS_EVENT_HR_ADMIN_PRIMARY_TAB = 'go to primary tab';
export const ANALYTICS_EVENT_HR_ADMIN_SELECT_SECTION = 'select section';
export const ANALYTICS_EVENT_HR_ADMIN_SELECT_TAB = 'select tab';
export const ANALYTICS_EVENT_HR_ADMIN_FILTER = 'filter to active';
export const ANALYTICS_EVENT_HR_ADMIN_MORE = 'get more info';
export const ANALYTICS_EVENT_HR_ADMIN_CONTACT = 'contact league';

export const ANALYTICS_EVENT_HR_ADMIN_INSIGHTS = 'insights';
export const ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES = 'employees';
export const ANALYTICS_EVENT_HR_ADMIN_BENEFITS = 'benefits';
export const ANALYTICS_EVENT_HR_ADMIN_BILLING = 'billing';
export const ANALYTICS_EVENT_HR_ADMIN_COMPANY = 'profile';

export const ANALYTICS_EVENT_HR_ADMIN_PROFILE = 'profile';
export const ANALYTICS_EVENT_HR_ADMIN_DEPENDENTS = 'dependents';
export const ANALYTICS_EVENT_HR_ADMIN_CREDITS = 'credits';
export const ANALYTICS_EVENT_HR_ADMIN_SUMMARY = 'summary';

export const ANALYTICS_EVENT_DOWNLOAD_SUCCESS = 'success';
export const ANALYTICS_EVENT_DOWNLOAD_FAILURE = 'failure';
