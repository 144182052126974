import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { selectIsAuthenticated } from 'apps/auth/selectors';
import {
  selectUserIsAdmin,
  selectUserIsClaimsAgent,
  selectUserIsHealthAdmin,
  selectUserHasRole,
} from 'common/state/user/role.selectors';
import { selectIsUserLoaded } from 'common/state/user/user.selectors';
import { LoadingIndicator } from '@leagueplatform/web-common-components';

/**
 * @module common/state/user/role-based-redirect.hocs
 * Role Based Access Higher Order Components (RBAC HOCs)
 * These HOCs will redirect a user to sign-in if they attempt to access a route that they do not have the correct role to access.
 *
 * @module common/state/user/role-based-visibility.hocs
 * These pair well with role-based-visibility hocs to prevent links from being exposed to users with insufficient privileges to visit them
 *
 * @see common/state/user/user.reducer
 * @see apps/auth/auth-classic/auth.reducer
 * These RBAC HOCs depend on redux state from the `state.user` and `state.authentication` slices.
 * These slices are not available in every app (namely Enrollment).
 */

const authenticatingSelector = state => !selectIsUserLoaded(state);

export const onlyAuthenticated = connectedRouterRedirect({
  redirectPath: '/sign-in',
  allowRedirectBack: false,
  wrapperDisplayName: 'OnlyAuthenticated ',
  authenticatedSelector: selectIsAuthenticated,
});

export const onlyAdmin = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  wrapperDisplayName: 'OnlyAdmin',
  authenticatedSelector: selectUserIsAdmin,
  authenticatingSelector,
  AuthenticatingComponent: LoadingIndicator,
});

export const onlyHealthAdmin = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  wrapperDisplayName: 'OnlyHealthAdmin',
  authenticatedSelector: selectUserIsHealthAdmin,
  authenticatingSelector,
  AuthenticatingComponent: LoadingIndicator,
});

export const onlyClaimsAgent = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  wrapperDisplayName: 'OnlyClaimsAgent',
  authenticatedSelector: selectUserIsClaimsAgent,
  authenticatingSelector,
  AuthenticatingComponent: LoadingIndicator,
});

export const onlyRoles = roles =>
  connectedRouterRedirect({
    redirectPath: '/',
    allowRedirectBack: false,
    wrapperDisplayName: 'OnlyRoles',
    authenticatedSelector: selectUserHasRole(roles),
    authenticatingSelector,
    AuthenticatingComponent: LoadingIndicator,
  });
