import React from 'react';
import {
  Flex,
  Box,
  genesisStyled,
  BodyOne,
  HeadingThree,
  GenesisTheme,
} from '@leagueplatform/genesis-commons';
import { FormattedMessage } from 'react-intl';
import { selectPlanAndClassName } from '../enrollment-design.selectors';
import { useSelector } from 'react-redux';

export interface StepContainerProps {
  children: React.ReactNode;
  title: string;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  selectedPlanId?: string | undefined;
}

const Container = genesisStyled(Box)<{ theme: GenesisTheme }>`
  border: 1px solid ${({ theme }) =>
    theme.colors.interactive.background.disabled};
  border-radius: 4px;
  overflow: hidden;
`;

const HeaderContainer = genesisStyled(Flex)<{ theme: GenesisTheme }>`
  background: ${({ theme }) => theme.colors.surface.background.secondary};
  border-radius: 4px 4px 0 0;
`;

export const StepContainer = ({
  children,
  title,
  selectedPlanId,
}: StepContainerProps) => {
  const selectedPlanAndClassName = useSelector(selectPlanAndClassName);

  return (
    <Container>
      <HeaderContainer
        justifyContent="space-between"
        alignItems="center"
        py="18px"
        px="24px"
      >
        <HeadingThree>
          <FormattedMessage id={title} />
        </HeadingThree>
        {selectedPlanId && (
          <Box>
            <BodyOne>
              <strong>
                <FormattedMessage id="CLASS_PLAN_NAME" />:{' '}
              </strong>
              {`${selectedPlanAndClassName?.class} / ${selectedPlanAndClassName?.plan}`}
            </BodyOne>
            <BodyOne>
              <strong>
                <FormattedMessage id="PLAN_ID" />:{' '}
              </strong>{' '}
              {selectedPlanId}
            </BodyOne>
          </Box>
        )}
      </HeaderContainer>
      <Box px="24px" py="18px">
        {children}
      </Box>
    </Container>
  );
};
