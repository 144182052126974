import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getClaimHistory
 * @arg {string} claimId
 * @returns {object[]} - An array of claim history items
 */
export const getClaimHistory = claimId =>
  call(websocketFetch, 'get_claim_history', { claim_id: claimId });
