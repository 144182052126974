import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { WalletCard } from '@leagueplatform/wallet';

export function BenefitPreview({ cards, match, requestBenefitPreview }) {
  const { benefitId, groupId } = match.params;
  useEffect(
    () => requestBenefitPreview({ benefitId, groupId }),
    [benefitId, groupId, requestBenefitPreview],
  );
  return (
    cards && (
      <Flex m={3} wrap="wrap">
        {cards.map(card => {
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          return <WalletCard key={card.id} {...card} />;
        })}
      </Flex>
    )
  );
}

BenefitPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  requestBenefitPreview: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
