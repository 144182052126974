import React from 'react';
import styled from 'styled-components';
import { Box, SubtitleOne } from '@leagueplatform/genesis-commons';
import { getThemeValue } from '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import { useIntl } from '@leagueplatform/locales';
import PropTypes from 'prop-types';
import { DownloadLinkCSV } from 'apps/employer-experience/ui-kit';

const StyledLoaderButton = styled(LoaderButton)`
  font-size: ${getThemeValue('space.2')}px;
  height: 3rem;
  padding: 0 1.5rem;
`;

const FinanceReportsStatementOfAccount = ({
  statementOfAccountContentId,
  statementOfAccountLoading,
  getStatementOfAccount,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box padding="half">
      <SubtitleOne marginBottom="half">
        {formatMessage({ id: 'STATEMENT_OF_ACCOUNT' })}
      </SubtitleOne>
      <StyledLoaderButton
        loading={statementOfAccountLoading}
        primary
        onClick={getStatementOfAccount}
      >
        {formatMessage({ id: 'GET_STATEMENT_OF_ACCOUNT' })}
      </StyledLoaderButton>
      {statementOfAccountContentId && (
        <>
          {formatMessage({ id: 'STATEMENT_OF_ACCOUNT_DOWNLOAD' })}
          <DownloadLinkCSV contentId={statementOfAccountContentId} />
        </>
      )}
    </Box>
  );
};

FinanceReportsStatementOfAccount.propTypes = {
  statementOfAccountContentId: PropTypes.string,
  getStatementOfAccount: PropTypes.func.isRequired,
  statementOfAccountLoading: PropTypes.bool.isRequired,
};

FinanceReportsStatementOfAccount.defaultProps = {
  statementOfAccountContentId: null,
};

export default FinanceReportsStatementOfAccount;
