import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { selectEmployerDetails, selectEmployerBenefits } from '../selectors';
import { benefitClass as benefitClassSchema } from '../schema';
import { get } from 'lodash';
import { format, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const selectBenefitClasses = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses } = {}) =>
    denormalize(
      employerBenefitClasses?.result,
      [benefitClassSchema],
      employerBenefitClasses?.entities,
    ),
);

export const selectBenefitClassesEntities = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses }) =>
    employerBenefitClasses.entities.benefitClasses,
);

export const selectPricingConfig = createSelector(
  selectEmployerDetails,
  ({ pricingConfiguration }) => pricingConfiguration,
);

export const selectSelectedBenefitPrice = createSelector(
  selectPricingConfig,
  pricingConfig => get(pricingConfig, 'benefitPriceConfiguration', null),
);
export const selectSelectedBenefitIdForPricingConfig = createSelector(
  selectPricingConfig,
  pricingConfig => get(pricingConfig, 'selectedBenefitId', null),
);

export const selectPricingConfigLoading = createSelector(
  selectPricingConfig,
  pricingConfig => get(pricingConfig, 'loading', false),
);

export const selectBenefitPricesToCsvLoading = createSelector(
  selectPricingConfig,
  pricingConfig => get(pricingConfig, 'loadingBenefitPricesToCsv', false),
);

export const selectBenefitPricesCsvContentId = createSelector(
  selectPricingConfig,
  pricingConfig => get(pricingConfig, 'csvContentId', ''),
);

const sortByName = (a, b) => {
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1;
  }
  if (a.name.toUpperCase() < b.name.toUpperCase()) {
    return -1;
  }
  return 0;
};

export const selectStructuredEmployerBenefits = createSelector(
  selectEmployerBenefits,
  benefits => {
    const groupByBenefitType = Object.entries(benefits).reduce(
      (acc, [id, benefit]) => {
        const employerBenefitString = [
          benefit.productName,
          benefit.planName,
          benefit.className,
          benefit.fullName,
        ]
          .filter(item => item)
          .join(' ');
        const benefitStart = format(
          utcToZonedTime(benefit.benefitStartDate, 'UTC'),
          'yyyy',
        );
        const benefitEnd = format(
          subDays(utcToZonedTime(benefit.benefitEndDate, 'UTC'), 1),
          'yyyy',
        );

        const benefitYear = `${benefitStart}${
          benefitStart !== benefitEnd ? ` - ${benefitEnd}` : ''
        }`;

        const benefitYears =
          acc.filter(benefitPlanYear => benefitPlanYear.name !== benefitYear) ??
          [];
        const benefitYearTypes =
          (
            acc.find(benefitPlanYear => benefitPlanYear.name === benefitYear)
              ?.children ?? []
          ).filter(
            benefitPlanYear => benefitPlanYear.name !== benefit.fullName,
          ) ?? [];

        const benefitYearTypeChildren =
          (
            acc.find(benefitPlanYear => benefitPlanYear.name === benefitYear)
              ?.children ?? []
          ).find(benefitPlanYear => benefitPlanYear.name === benefit.fullName)
            ?.children ?? [];

        return [
          ...benefitYears,
          {
            name: benefitYear,
            children: [
              ...benefitYearTypes,
              {
                name: benefit.fullName,
                children: [
                  ...benefitYearTypeChildren,
                  {
                    name: employerBenefitString,
                    benefitId: id,
                  },
                ].sort(sortByName),
              },
            ].sort(sortByName),
          },
        ].sort(sortByName);
      },
      [],
    );

    return {
      name: 'Benefits',
      children: groupByBenefitType,
    };
  },
);

// selects employer benefits in a tree like structure
// input: benefits from `selectEmployerBenefits` in the shape {benefitId : benefit, benefitId2: benefit2}
// output: benefit grouped by benefit year > benefit type > benefit options
export const selectEmployerBenefitsDropdown = createSelector(
  selectEmployerBenefits,
  benefits => {
    return Object.entries(benefits).reduce((acc, [id, benefit]) => {
      const employerBenefitOptionLabel = [
        benefit.productName,
        benefit.className,
        benefit.fullName,
        benefit.planName,
      ]
        .filter(item => item)
        .join(' ');

      const benefitStart = format(
        utcToZonedTime(benefit.benefitStartDate, 'UTC'),
        'yyyy',
      );
      const benefitEnd = format(
        subDays(utcToZonedTime(benefit.benefitEndDate, 'UTC'), 1),
        'yyyy',
      );

      const benefitYear = `${benefitStart}${
        benefitStart !== benefitEnd ? ` - ${benefitEnd}` : ''
      }`;

      const benefitTypes = acc[benefitYear] ?? [];
      const benefitTypeChildren =
        benefitTypes.find(benefitType => benefitType.label === benefit.fullName)
          ?.benefitTypeOptions ?? [];
      return {
        ...acc,
        [benefitYear]: [
          ...benefitTypes.filter(type => type.label !== benefit.fullName),
          {
            label: benefit.fullName,
            value: benefit.fullName,
            benefitTypeOptions: [
              ...benefitTypeChildren,
              {
                label: employerBenefitOptionLabel,
                value: id,
                benefitType: benefit.fullName,
                benefitYear,
              },
            ],
          },
        ],
      };
    }, {});
  },
);

export const selectBenefitYearOptions = createSelector(
  selectEmployerBenefitsDropdown,
  benefits => {
    return Object.keys(benefits).map(benefitYear => ({
      label: benefitYear,
      value: benefitYear,
    }));
  },
);

export const selectAddingBenefitClass = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses }) => !!employerBenefitClasses.addingBenefitClass,
);

export const selectErrorAddingBenefitClass = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses }) =>
    !!employerBenefitClasses.errorAddingBenefitClass,
);

export const selectRenamingBenefitClass = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses }) =>
    employerBenefitClasses.renamingBenefitClass || '',
);

export const selectErrorRenamingBenefitClass = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses }) =>
    !!employerBenefitClasses.errorRenamingBenefitClass,
);

export const selectIsLastBenefitClass = createSelector(
  selectEmployerDetails,
  ({ employerBenefitClasses }) => employerBenefitClasses.result.length === 1,
);
