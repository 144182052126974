import { fork, takeLatest, put, call, all } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  SET_GROUP_CREDIT_CONTRACT,
  GROUP_CREDIT_CONTRACT_FORM_SUBMITTED,
  SET_GROUP_SUBSCRIPTION_RATES,
  GROUP_SUBSCRIPTION_RATES_SUBMITTED,
  SET_GROUP_BILLING_CONTACT,
  GROUP_BILLING_CONTACT_FORM_SUBMITTED,
  SET_INVOICE_PAYMENT,
  GROUP_INVOICE_PAYMENT_FORM_SUBMITTED,
  UPDATE_GROUP,
  SET_GROUP_BILLING_PREFERENCE,
  GROUP_BILLING_PREFERENCE_FORM_SUBMITTED,
  GET_EMPLOYER_OUTSTANDING_BALANCE,
  ADMIN_EMPLOYER_OUTSTANDING_BALANCE,
} from './types';
import {
  GET_GROUP,
  EMPLOYER_BILLING_LOADING,
  EMPLOYER_BILLING_READY,
} from '../types';
import { get } from 'lodash';

export function* getGroup(id) {
  yield request(GET_GROUP, [websocketFetch, 'get_group', { group_id: id }]);
}

// PAYMENT TAB
export function* setInvoicePayment(action) {
  const { groupId, info } = action.payload;
  const data = {
    group_id: groupId,
    invoice_payment: info,
  };
  yield put({ type: SET_INVOICE_PAYMENT.STARTED, payload: data });
  try {
    const response = yield call(websocketFetch, 'set_invoice_payment', data);
    yield put({ type: SET_INVOICE_PAYMENT.SUCCEEDED, payload: response });

    // try updating the group
    yield put({ type: UPDATE_GROUP.STARTED, payload: { group_id: groupId } });
    try {
      const updateGroup = yield call(getGroup, groupId);
      yield put({
        type: UPDATE_GROUP.SUCCEEDED,
        payload: { updateGroup },
      });
    } catch (err) {
      yield put({
        type: UPDATE_GROUP.ERRORED,
        payload: err,
      });
    }
  } catch (error) {
    yield put({ type: SET_INVOICE_PAYMENT.ERRORED, payload: error });
  }
}

export function* setGroupBillingPreference(action) {
  const { groupId, billingPreference } = action.payload;
  const data = {
    group_id: groupId,
    billing_preference: billingPreference,
  };
  yield put({ type: SET_GROUP_BILLING_PREFERENCE.STARTED });
  try {
    const response = yield call(
      websocketFetch,
      'set_group_billing_preference',
      data,
    );
    yield put({
      type: SET_GROUP_BILLING_PREFERENCE.SUCCEEDED,
      payload: response,
    });
    // try updating the group
    yield put({ type: UPDATE_GROUP.STARTED, payload: { group_id: groupId } });
    try {
      const updateGroup = yield call(getGroup, groupId);
      yield put({
        type: UPDATE_GROUP.SUCCEEDED,
        payload: { updateGroup },
      });
    } catch (err) {
      yield put({
        type: UPDATE_GROUP.ERRORED,
        payload: err,
      });
    }
  } catch (error) {
    yield put({ type: SET_GROUP_BILLING_PREFERENCE.ERRORED });
  }
}

// FEES TAB
export function* setGroupSubscriptionRates(action) {
  const { groupId, subscriptionRates, platformFees } = action.payload;
  const info = {
    group_id: groupId,
    subscription_rates: subscriptionRates,
    subscription_rate_pepm: platformFees,
  };

  yield put({ type: SET_GROUP_SUBSCRIPTION_RATES.STARTED, payload: info });
  try {
    const response = yield call(
      websocketFetch,
      'set_group_subscription_rates',
      info,
    );
    yield put({
      type: SET_GROUP_SUBSCRIPTION_RATES.SUCCEEDED,
      payload: response,
    });

    // try updating the group
    yield put({ type: UPDATE_GROUP.STARTED, payload: { group_id: groupId } });
    try {
      const updateGroup = yield call(getGroup, groupId);
      yield put({
        type: UPDATE_GROUP.SUCCEEDED,
        payload: { updateGroup },
      });
    } catch (err) {
      yield put({
        type: UPDATE_GROUP.ERRORED,
        payload: err,
      });
    }
  } catch (error) {
    yield put({ type: SET_GROUP_SUBSCRIPTION_RATES.ERRORED, payload: error });
  }
}

// PRE-PAYMENTS TAB & ADMIN FEES
export function* setGroupCreditContract(action) {
  const { groupId, info } = action.payload;
  const infoWithVersionsArray = info.map(data => {
    return {
      group_id: groupId,
      type: data.benefitType,
      can_add: data.canAdd,
      buffer: data.bufferVal,
      admin_fee: data.adminFeeVal,
      version: 1,
    };
  });

  yield put({
    type: SET_GROUP_CREDIT_CONTRACT.STARTED,
    payload: infoWithVersionsArray,
  });
  try {
    const responses = yield all(
      infoWithVersionsArray.map(pl => {
        return call(websocketFetch, 'set_group_credit_contract', pl);
      }),
    );
    yield put({
      type: SET_GROUP_CREDIT_CONTRACT.SUCCEEDED,
      payload: responses,
    });

    // try updating the group
    yield put({ type: UPDATE_GROUP.STARTED, payload: { group_id: groupId } });
    try {
      const updateGroup = yield call(getGroup, groupId);
      yield put({
        type: UPDATE_GROUP.SUCCEEDED,
        payload: { updateGroup },
      });
    } catch (err) {
      yield put({
        type: UPDATE_GROUP.ERRORED,
        payload: err,
      });
    }
  } catch (error) {
    yield put({ type: SET_GROUP_CREDIT_CONTRACT.ERRORED, payload: error });
  }
}

// CONTACT TAB
export function* setGroupBillingContact(action) {
  const { groupId, billingEmail } = action.payload;
  const info = {
    group_id: groupId,
    email: billingEmail,
  };

  yield put({ type: SET_GROUP_BILLING_CONTACT.STARTED, payload: info });
  try {
    const response = yield call(
      websocketFetch,
      'set_group_billing_contact',
      info,
    );
    yield put({ type: SET_GROUP_BILLING_CONTACT.SUCCEEDED, payload: response });

    // try updating the group
    yield put({ type: UPDATE_GROUP.STARTED, payload: { group_id: groupId } });
    try {
      const updateGroup = yield call(getGroup, groupId);
      yield put({
        type: UPDATE_GROUP.SUCCEEDED,
        payload: { updateGroup },
      });
    } catch (err) {
      yield put({
        type: UPDATE_GROUP.ERRORED,
        payload: err,
      });
    }
  } catch (error) {
    yield put({ type: SET_GROUP_BILLING_CONTACT.ERRORED, payload: error });
  }
}

export function* getEmployerOutstandingBalance({ groupId, service, path }) {
  try {
    yield put({ type: GET_EMPLOYER_OUTSTANDING_BALANCE.STARTED });
    const invoicesResponse = yield call(websocketFetch, service, {
      group_id: groupId,
      billing_division_id: '',
    });
    const outstandingBalance = get(invoicesResponse, path);
    yield put({
      type: GET_EMPLOYER_OUTSTANDING_BALANCE.SUCCEEDED,
      payload: {
        outstandingBalance,
      },
    });
  } catch (error) {
    yield put({ type: GET_EMPLOYER_OUTSTANDING_BALANCE.ERRORED, error });
  }
}

export function* adminGetEmployerOutstandingBalance({ payload }) {
  const { groupId } = payload;
  const service = 'get_outstanding_group_invoices';
  const path = 'info.outstanding_balances[0]';
  yield call(getEmployerOutstandingBalance, { groupId, service, path });
}

export function* hrGetEmployerOutstandingBalance({ payload }) {
  const { groupId } = payload;
  const service = 'get_group_outstanding_group_invoices';
  const path = 'info.outstanding_balance';
  yield call(getEmployerOutstandingBalance, { groupId, service, path });
}

export function* initializeBilllingPage() {
  yield put({ type: EMPLOYER_BILLING_LOADING });
  yield put({ type: EMPLOYER_BILLING_READY });
}

export function* employerBillingSaga() {
  yield fork(
    takeLatest,
    GROUP_CREDIT_CONTRACT_FORM_SUBMITTED,
    setGroupCreditContract,
  );
  yield fork(
    takeLatest,
    GROUP_SUBSCRIPTION_RATES_SUBMITTED,
    setGroupSubscriptionRates,
  );
  yield fork(
    takeLatest,
    GROUP_BILLING_CONTACT_FORM_SUBMITTED,
    setGroupBillingContact,
  );
  yield fork(
    takeLatest,
    GROUP_INVOICE_PAYMENT_FORM_SUBMITTED,
    setInvoicePayment,
  );
  yield fork(
    takeLatest,
    GROUP_BILLING_PREFERENCE_FORM_SUBMITTED,
    setGroupBillingPreference,
  );
  yield fork(
    takeLatest,
    ADMIN_EMPLOYER_OUTSTANDING_BALANCE,
    adminGetEmployerOutstandingBalance,
  );
  yield fork(initializeBilllingPage);
}
