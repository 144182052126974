export function makeEntitiesReducer(requestAction) {
  return function entitiesReducer(state = {}, action = {}) {
    switch (action?.type) {
      case requestAction.SUCCEEDED:
        return { ...state, [action.meta.entityId]: action.payload.info };
      default:
        return state;
    }
  };
}
