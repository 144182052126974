export const initialValues = {
  qualifyingLifeEventType: '',
  daysBeforeQleEventDate: 0,
  daysAfterQleEventDate: 0,
  needsHrApproval: false,
  qleApprovalEmails: '',
  allowToAddDependents: false,
  allowToRemoveDependents: false,
  allowNex: true, // to keep the custom config hidden
  typeformUrl: '',
  suspended: false,
  createdAt: '2018-09-02T00:00:00.000Z',
  // adding features to formik for special errors
  daysInPastAndFuture: false,
  noNexCustomSet: false,
  // adding key/values to formik for the toggles
  allowDaysAfterQleEventDate: false,
  allowDaysBeforeQleEventDate: false,
  enableManageDependents: false,
  enableTypeform: false,
};
