import { invert } from 'lodash';

export const ENROLLMENT_DESIGN_ROOT_PATH: string =
  '/admin/employers/:groupId/benefits/new-enrollment-design';
export const ROOT_PATH: string = '';
export const BENEFIT_SET_CONFIG_PATH: string = '/:planId/benefit-config';
export const ENROLLMENT_EXPERIENCE_CONFIG_PATH: string =
  '/:planId/experience-config';

export type Step = (typeof Steps)[keyof typeof Steps];

export const Steps = {
  STEP1: 'SELECT_PLAN',
  STEP2: 'BENEFIT_SET_CONFIG',
  STEP3: 'ENROLLMENT_EXPERIENCE_CONFIG',
} as const;

export const PathToStepMap = {
  [ROOT_PATH]: Steps.STEP1,
  [BENEFIT_SET_CONFIG_PATH]: Steps.STEP2,
  [ENROLLMENT_EXPERIENCE_CONFIG_PATH]: Steps.STEP3,
};

export const StepToPathMap = invert(PathToStepMap);
