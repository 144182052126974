import React from 'react';
import { vars, Box, Text } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import { BreadcrumbLinks } from 'apps/employer-experience/pages/EmployerDetails/benefit-sets/breadcrumb-links/breadcrumb-links.view';
import { makeQLEConfigBreadcrumbLinks } from './make-qle-config-breadcrumb-links';

// note: couldn't get this working with borderRadius+borderColor+borderWidth?
// `overflow: hidden;` so the HeaderContainer doesn't cover left border
const Container = styled(Box)`
  border: 1px solid ${vars.colour.greyLighter};
  border-radius: 4px;
  overflow: hidden;
`;

const HeaderContainer = styled(Flex)`
  background: ${vars.colour.greyLightest};
  border-radius: 4px 4px 0 0;
`;

/**
 * @function QLEConfigurationCard component
 *
 * This should only render after benefit classes have been fetched.
 * This is important because it generates its own links needed to support Breadcrumb
 * links based off of that!
 *
 * @param {*} children - other components you want inside the card
 * @param {Array} links - Links for the breadcrumb, takes children (can be text or React components), and links (paths)
 */

const QLEConfigurationCard = ({
  children,
  rightSide,
  location,
  benefitClasses, // TODO: Replace with selected benefit class once that selector is merged
  paddingAroundContent,
  showBreadcrumbs,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <HeaderContainer>
        <Text fontSize={4} py="24px" px={3} my="24px" fontWeight="bold">
          {formatMessage({ id: 'QLE_CONFIGURATIONS' })}
        </Text>
      </HeaderContainer>
      {/* Normal: Padding all around. Only for QLE Configuration Table, only padding around breadcrumbs */}
      <Box p={paddingAroundContent ? 3 : 0}>
        <Flex
          justify="space-between"
          px={!paddingAroundContent ? 3 : 0}
          pt={!paddingAroundContent ? 3 : 0}
        >
          {showBreadcrumbs && (
            <BreadcrumbLinks
              links={makeQLEConfigBreadcrumbLinks({
                pathname: location.pathname,
                formatMessage,
                benefitClasses,
              })}
            />
          )}
          {rightSide && <Box textAlign="right">{rightSide}</Box>}
        </Flex>
        {children}
      </Box>
    </Container>
  );
};

QLEConfigurationCard.propTypes = {
  children: PropTypes.node.isRequired,
  rightSide: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  benefitClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  paddingAroundContent: PropTypes.bool,
  showBreadcrumbs: PropTypes.bool,
};

QLEConfigurationCard.defaultProps = {
  rightSide: null,
  benefitClasses: [],
  paddingAroundContent: false, // Only when table is contained by this, should there be no padding
  showBreadcrumbs: true,
};

export default QLEConfigurationCard;
