/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { throttle } from 'lodash';
import cookie from 'react-cookies';
import { getCookieOptions } from 'common/utilities/cookies';
import { addPostMessageListener } from 'common/utilities/post-message-helpers';
import { FIFTEEN_MINUTES } from './privacy-lock.constants';

/**
 * Adapted verbatim (with minor syntax changes) from Gavin's idle-timer written for Widget Runner
 * https://github.com/EverlongProject/frontend-web/blob/b0b8e62631fe07fb859d5032559b302d039b19eb/wr/apps/league/widgets/league/util/idle-timer/idle-timer.js
 *
 * This utility lets us detect when a user has been idle for some amount of time
 * based on mouse and keyboard activity. The interface provides the startIdleTimer() and
 * cancelIdleTimer() functions, documented below. We use react-cookies to track the time so
 * that it will work across multiple browser windows.
 */

export const IDLE_TIME_COOKIE_KEY = 'idle-time';
export const MAX_IDLE_TIME_LOCAL_STORAGE_KEY = 'maxIdleTime';

let timerId = null;
let onTimeout = null;

const registerUserActivity = throttle(
  () => cookie.save(IDLE_TIME_COOKIE_KEY, Date.now(), getCookieOptions()),
  1000,
);

const postMessageListener = {
  current: registerUserActivity,
};

const checkTimeout = () => {
  const idleTime = Date.now() - cookie.load(IDLE_TIME_COOKIE_KEY);
  if (idleTime > getMaxIdleTime()) onTimeout();
};

/**
 * Retrieves maximum amount of time that a user can be inactive
 * before being considered idle
 * @returns {number} The maximum idle timeout in milliseconds
 */
export const getMaxIdleTime = () => {
  const maxIdleTime =
    localStorage.getItem(MAX_IDLE_TIME_LOCAL_STORAGE_KEY) ?? FIFTEEN_MINUTES;
  return Number(maxIdleTime);
};

/**
 * Sets maximum amount of time that a user can be inactive
 * before being considered idle
 * @param {number} maxIdleTime The maximum idle timeout in milliseconds
 */
export const setMaxIdleTime = idleTime => {
  if (typeof idleTime !== 'number')
    throw new Error('idleTime must be a number');
  localStorage.setItem(MAX_IDLE_TIME_LOCAL_STORAGE_KEY, idleTime);
};

/**
 * Cancel the timer and clean up.
 */
export const cancelIdleTimer = () => {
  window.top.removeEventListener('mousemove', registerUserActivity);
  window.top.removeEventListener('scroll', registerUserActivity);
  window.top.removeEventListener('keydown', registerUserActivity);
  window.removeEventListener('message', postMessageListener.current);
  postMessageListener.current = registerUserActivity;
  clearInterval(timerId);
  timerId = null;
  cookie.remove(IDLE_TIME_COOKIE_KEY);
  localStorage.removeItem(MAX_IDLE_TIME_LOCAL_STORAGE_KEY);
};

/**
 * Start the timer, cancelling the pre-existing one.
 * @param {number} options.maxIdleTime The maximum amount of time in
 * milliseconds that a user can be inactive before being considered idle.
 * @param {function} options.onTimeout The function that should be called when
 * the timeout expires.
 */
export const startIdleTimer = options => {
  if (typeof timerId === 'number') {
    // eslint-disable-next-line no-console
    console.warn('Idle timer started while it was already running.');
  }
  cancelIdleTimer();
  if (typeof options.maxIdleTime !== 'number')
    throw new Error('maxIdleTime must be a number');
  if (typeof options.onTimeout !== 'function')
    throw new Error('onTimeout must be a function');
  window.top.addEventListener('mousemove', registerUserActivity);
  window.top.addEventListener('scroll', registerUserActivity);
  window.top.addEventListener('keydown', registerUserActivity);
  postMessageListener.current = addPostMessageListener(registerUserActivity);
  setMaxIdleTime(options.maxIdleTime);
  onTimeout = options.onTimeout;
  cookie.save(IDLE_TIME_COOKIE_KEY, Date.now(), getCookieOptions());
  timerId = setInterval(checkTimeout, 1000);
};
