import React from 'react';
import PropTypes from 'prop-types';
import { EmployerReportsLoaderButton } from 'apps/employer-experience/pages/EmployerDetails/employer-reports/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { HeadingFour, Text, Flex } from '@leagueplatform/genesis-commons';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';
import { NoteText } from 'apps/employer-experience/ui-kit/report-elements.view';

export const DependentsReportsPresenter = ({
  getDependentsReport,
  dependentsReportLoading,
}) => {
  const { formatMessage } = useIntl();
  const isInHRAdminPortal = useIsInHRAdminPortal();

  return (
    <>
      <Flex marginBottom="one">
        <HeadingFour>{formatMessage({ id: 'DEPENDENTS_REPORT' })}</HeadingFour>
      </Flex>
      <Text marginBottom="one">
        {formatMessage({ id: 'DEPENDENTS_REPORT_DESCRIPTION' })}
      </Text>
      <Text marginBottom="one">
        <NoteText>{formatMessage({ id: 'NOTE' })}:</NoteText>
        {formatMessage({ id: 'REPORTS_LOADING_NOTE_COPY' })}
      </Text>
      <EmployerReportsLoaderButton
        primary
        type="submit"
        loading={dependentsReportLoading}
        onClick={() => getDependentsReport(isInHRAdminPortal)}
        data-testid="EmployerReportsLoaderButton"
      >
        {formatMessage({ id: 'REQUEST_REPORT' })}
      </EmployerReportsLoaderButton>
    </>
  );
};

DependentsReportsPresenter.propTypes = {
  getDependentsReport: PropTypes.func.isRequired,
  dependentsReportLoading: PropTypes.bool.isRequired,
};
