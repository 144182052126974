/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const setTemplatedGenericBenefit = ({
  mode,
  template_id,
  template_name,
}) =>
  call(websocketFetch, 'set_templated_generic_benefit', {
    mode,
    template_id,
    template_name,
  });
