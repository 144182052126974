import { takeLatest, put, select } from 'redux-saga/effects';
import { getUserProfile } from 'common/services/get-user-profile.service';
import { getServicePayments } from './get-service-payments.service';
import { getValidationForm } from './get-validation-form.service';
import { RECEIPT_VISITED, GET_RECEIPT_RESOURCES } from './receipt.actions';
import { formatReceiptData } from './format-receipt-data';
import { selectUserId } from 'apps/auth/selectors';

export function* initializeResources({ payload }) {
  const { paymentId } = payload;
  const userId = yield select(selectUserId);
  try {
    // Get payment data
    yield put(GET_RECEIPT_RESOURCES.start());
    const payments = yield getServicePayments(paymentId);
    const payment = payments?.info?.payments?.find(
      p => p?.paid_by_profile?.user_id === userId,
    );
    const proId = payment?.provider?.user_id;

    // Get pro profile for phone number
    let providerProfile;
    try {
      providerProfile = yield getUserProfile(proId);
    } catch (error) {
      providerProfile = null;
    }

    // Get validation form for license numbers
    let validationForm;
    try {
      validationForm = yield getValidationForm(proId);
    } catch (error) {
      validationForm = null;
    }

    // Boil down all 3 responses to what we need
    const data = formatReceiptData({
      payment,
      providerProfile,
      validationForm,
    });
    yield put(GET_RECEIPT_RESOURCES.success(data));
  } catch (error) {
    yield put(GET_RECEIPT_RESOURCES.error(error));
  }
}

export function* receiptSaga() {
  yield takeLatest(RECEIPT_VISITED, initializeResources);
}
