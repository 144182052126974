import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { setUserBenefitClass } from './benefits.actions';
import { ChangeBenefitClassModal } from './change-benefit-class-modal.view';
import { Box } from '@leagueplatform/genesis-commons';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
  ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
} from '../constants';

const withActions = connect(null, { setUserBenefitClass });

const withClassStateHandlers = withStateHandlers(
  ({ selectedClass }) => ({
    isOpen: false,
    selectedClass,
    nextClass: selectedClass,
  }),
  {
    toggleModal:
      ({ isOpen }) =>
      () => ({ isOpen: !isOpen }),
    saveNextClass: () => value => ({ nextClass: value }),
    saveClass:
      ({ nextClass }) =>
      () => ({ selectedClass: nextClass }),
  },
);

const sendAnalytics = (isAdmin, benClass) => {
  if (!isAdmin) {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.HR_ADMIN,
      screen_name: ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
      sub_product_area: ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
      detail: 'change class',
      sub_detail: benClass,
    });
  }
};

const withClassHandlers = withHandlers({
  handleChange:
    ({ toggleModal, saveNextClass, selectedClass }) =>
    value => {
      if (value !== selectedClass) {
        toggleModal();
      }
      saveNextClass(value);
    },
  handleConfirm:
    ({
      saveClass,
      nextClass,
      setUserBenefitClass: onConfirm,
      isAdmin,
      classes,
    }) =>
    e => {
      e.preventDefault();
      saveClass();
      onConfirm(nextClass);
      const benClass = classes.filter(benefit => benefit.value === nextClass);
      sendAnalytics(isAdmin, benClass?.[0]?.text);
    },
  handleCancel: () => e => {
    e.preventDefault();
  },
});

const ChangeBenefitClass = ({
  classes,
  selectedClass,
  nextClass,
  handleChange,
  isAdmin,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box marginBottom="two">
      <Dropdown
        className="dropdown--benefit-class-change"
        selection
        floating
        text={formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_CONFIRM' })}
        options={classes}
        value={selectedClass}
        onChange={(_, { value }) => handleChange(value)}
      />
      <ChangeBenefitClassModal
        isAdmin
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...rest}
        targetBenefitClassId={nextClass !== selectedClass ? nextClass : null}
      />
    </Box>
  );
};

ChangeBenefitClass.propTypes = {
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  employee: PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  isOpen: PropTypes.bool.isRequired,
  selectedClass: PropTypes.string,
  nextClass: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

ChangeBenefitClass.defaultProps = {
  selectedClass: '',
  nextClass: '',
  isAdmin: false,
};

export default compose(
  withActions,
  withClassStateHandlers,
  withClassHandlers,
)(ChangeBenefitClass);
