/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setEmployerQleApprovalEmails Send API call to `set_employer_qle_approval_emails`
 *
 * worker saga, plus service for the fetch request
 * @param {string} groupId - group id / employer id
 * @param {array} qleApprovalEmails - Array of email addresses
 */
export const setEmployerQleApprovalEmails = ({
  groupId: group_id,
  qleApprovalEmails: qle_approval_emails,
}) =>
  call(websocketFetch, 'set_employer_qle_approval_emails', {
    group_id,
    qle_approval_emails,
  });
