import React from 'react';
import { connect } from 'react-redux';
import {
  branch,
  lifecycle,
  renderComponent,
  compose,
  withHandlers,
} from 'recompose';
import { Loader } from 'semantic-ui-react';
import { selectUserReady } from './benefits.selectors';
import { EMPLOYEE_BENEFITS_PAGE_LIFECYCLE } from './benefits.types';
import { benefitSubmitted } from '../benefits/benefit.actions';

export const withDispatchers = connect(null, {
  visit: EMPLOYEE_BENEFITS_PAGE_LIFECYCLE.visit,
  exit: EMPLOYEE_BENEFITS_PAGE_LIFECYCLE.exit,
  submitBenefit: benefitSubmitted,
});

export const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.visit(this.props);
  },
  componentWillUnmount() {
    this.props.exit(this.props);
  },
});

const withReadyState = connect(state => ({ ready: selectUserReady(state) }));

const withLoadingSpinner = branch(
  props => !props.ready,
  renderComponent(() => <Loader active inline="centered" />),
);

const withBenefitsHandlers = withHandlers({
  removeBenefit:
    ({ onRemoveBenefit }) =>
    benefit => {
      onRemoveBenefit({ benefitId: benefit.id });
    },

  addNewBenefit:
    ({ submitBenefit }) =>
    benefit => {
      submitBenefit({ parent: benefit });
    },
});

export const withInitializer = compose(
  withDispatchers,
  withLifecycleActions,
  withReadyState,
  withLoadingSpinner,
  withBenefitsHandlers,
);
