import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const INVITE_PAGE_LIFECYCLE = createLifecycleTypes(
  'invite/INVITE_PAGE_LIFECYCLE',
);
export const DEPENDENT_INVITE_PAGE_LIFECYCLE = createLifecycleTypes(
  'invite/DEPENDENT_INVITE_PAGE_LIFECYCLE',
);
export const FETCH_INVITE = createRequestTypes('invite/FETCH_INVITE');
export const FETCH_DEPENDENT_INVITE = createRequestTypes(
  'invite/FETCH_DEPENDENT_INVITE',
);

export const CREATE_ACCOUNT_GROUP_INVITE = createRequestTypes(
  'invite/CREATE_ACCOUNT_GROUP_INVITE',
);
export const CREATE_ACCOUNT_DEPENDENT_INVITE = createRequestTypes(
  'invite/CREATE_ACCOUNT_DEPENDENT_INVITE',
);

export const fetchInvite = inviteId => ({
  type: FETCH_INVITE.BASE,
  payload: {
    inviteId,
  },
});

export const fetchDependentInvite = inviteId => ({
  type: FETCH_DEPENDENT_INVITE.BASE,
  payload: {
    inviteId,
  },
});

export const createAccountGroupInvite = ({ inviteId }) => ({
  type: CREATE_ACCOUNT_GROUP_INVITE.BASE,
  payload: {
    inviteId,
    inviteType: 'group',
  },
});

export const createAccountDependentInvite = ({ inviteId }) => ({
  type: CREATE_ACCOUNT_DEPENDENT_INVITE.BASE,
  payload: {
    inviteId,
    inviteType: 'dependent',
  },
});
