import { compareAsc } from 'date-fns';

export const datesAreAscending = (dateLeft, dateRight) =>
  compareAsc(dateLeft, dateRight) === -1;

export const validateDateRange = values => {
  if (Boolean(values.benefit_start_date && values.benefit_end_date) === false)
    return {};
  const startDate = new Date(values.benefit_start_date);
  const endDate = new Date(values.benefit_end_date);
  const valid = datesAreAscending(startDate, endDate);
  return valid
    ? {}
    : {
        benefit_start_date: 'Must be before end date',
        benefit_end_date: 'Must be after start date',
      };
};
