import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PremiumsReportPage from './premiums-report.view';
import {
  getPremiumsReport,
  getPreviousPremiumsReports,
  getChangeReport,
  requestPayrollSchedules,
  requestPremiumsExportSplits,
} from './premiums-report.actions';
import {
  selectFormattedPremiumsReports,
  selectReportStatus,
  selectPreviousReportsSkip,
  selectPayrollSchedulesWithLabels,
  selectPremiumsExportSplits,
  selectTotalPreviousReports,
} from './premiums-report.selectors';

const mapStateToProps = createStructuredSelector({
  previousReports: selectFormattedPremiumsReports,
  previousReportsLoading: selectReportStatus,
  previousReportsSkip: selectPreviousReportsSkip,
  payrollSchedules: selectPayrollSchedulesWithLabels,
  premiumsExportSplits: selectPremiumsExportSplits,
  totalPreviousReports: selectTotalPreviousReports,
});

const mapDispatchToProps = {
  getPremiumsReport,
  getChangeReport,
  requestPayrollSchedules,
  requestPremiumsExportSplits,
  onFetchReportsRequested: getPreviousPremiumsReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumsReportPage);
