import { fork, takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_QLE_CONFIGURATIONS,
  SERVICE_GET_QLE_CONFIGURATIONS,
  GET_EMPLOYER_QLE_APPROVAL_EMAILS,
  SERVICE_GET_EMPLOYER_QLE_APPROVAL_EMAILS,
  SET_EMPLOYER_QLE_APPROVAL_EMAILS,
  SERVICE_SET_EMPLOYER_QLE_APPROVAL_EMAILS,
} from './qle-configuration.action-types';
import { SET_BENEFIT_CLASS_ID } from './benefit-class-selection/benefit-class-selection.action-types';
import { request } from 'common/websocket-redux';
import {
  getQLEConfigurations,
  getEmployerQleApprovalEmails,
  setEmployerQleApprovalEmails,
} from 'common/services';
import { QLEConfigurationFormSagas } from './qle-configuration-form/qle-configuration-form.sagas';
import { SERVICE_SET_QLE_CONFIGURATION } from './qle-configuration-form/qle-configuration-form.action-types';
import { setIsEditHREmailEnabled } from './benefit-class-selection/benefit-class-selection.action-creators';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestGetQLEConfigurations({ payload }) {
  yield request(SERVICE_GET_QLE_CONFIGURATIONS, getQLEConfigurations(payload));
}

/**
 * @function getHREmails Send API call to `set_employer_qle_approval_emails`
 *
 * worker saga, plus service for the fetch request
 * @param {string} groupId - group id / employer id
 */
export function* requestEmployerHREmails({ payload }) {
  yield request(
    SERVICE_GET_EMPLOYER_QLE_APPROVAL_EMAILS,
    getEmployerQleApprovalEmails(payload),
  );
}

/**
 * @function setHREmails Send API call to `set_employer_qle_approval_emails`
 * worker saga, plus service for the fetch request. Disable edit state if
 * successful call.
 *
 * @param {string} groupId - group id / employer id
 * @param {array} qleApprovalEmails - Array of email addresses
 */
export function* setEmployerHREmails({ payload }) {
  const response = yield request(
    SERVICE_SET_EMPLOYER_QLE_APPROVAL_EMAILS,
    setEmployerQleApprovalEmails(payload),
  );

  if (!response.error) {
    yield put(setIsEditHREmailEnabled({ isEditHREmailEnabled: false }));
  }
}

export function* showSettingsAppliedToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'SETTINGS_APPLIED',
  });
}

export function* showHrEMailsUpdatedToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'HR_EMAILS_UPDATED',
  });
}

/**
 * set_employer_qle_approval_emails is in qle-configuration.sagas
 * because it's used in the landing page AND this form
 */
export function* showErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID',
  });
}

export function* QLEConfigurationSagas() {
  yield fork(QLEConfigurationFormSagas);
  yield fork(
    takeLatest,
    [GET_QLE_CONFIGURATIONS, SET_BENEFIT_CLASS_ID],
    requestGetQLEConfigurations,
  );
  yield fork(
    takeLatest,
    [GET_EMPLOYER_QLE_APPROVAL_EMAILS],
    requestEmployerHREmails,
  );
  yield fork(takeLatest, SET_EMPLOYER_QLE_APPROVAL_EMAILS, setEmployerHREmails);
  yield fork(
    takeLatest,
    SERVICE_SET_QLE_CONFIGURATION.SUCCEEDED,
    showSettingsAppliedToast,
  );
  yield fork(
    takeLatest,
    SERVICE_SET_EMPLOYER_QLE_APPROVAL_EMAILS.SUCCEEDED,
    showHrEMailsUpdatedToast,
  );
  yield fork(
    takeLatest,
    SERVICE_SET_EMPLOYER_QLE_APPROVAL_EMAILS.ERRORED,
    showErrorToast,
  );
}
