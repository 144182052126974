import { compose } from 'redux';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { EMPLOYER_PAYROLL_REPORTS_LIFECYCLE } from './payroll-report.types';
import { selectInvoices } from './payroll-report.selectors';
import PayrollReportsPage from './payroll-report.view';
import { downloadPayrollReport } from './payroll-report.actions';

const withDispatchers = connect(null, {
  visit: EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.visit,
  exit: EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.exit,
});

const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.visit(this.props.groupId);
  },
  componentWillUnmount() {
    this.props.exit();
  },
});

const mapStateToProps = connect(
  createStructuredSelector({
    invoices: selectInvoices,
  }),
);

const withActions = connect(null, {
  onReportDownload: downloadPayrollReport,
});

export default compose(
  withDispatchers,
  withLifecycleActions,
  mapStateToProps,
  withActions,
)(PayrollReportsPage);
