import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { LabelText } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '../../constants';
import styled from 'styled-components';
import { vars } from '@leagueplatform/ui-kit';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import ConditionalRenderWrappingTag from './conditional-render-wrapping-tag';

const ErrorMessage = styled.div`
  color: ${vars.colour.red};
  line-height: 1.4rem;
  margin-top: 0.6rem;
`;

const ActionsConfirmationDateForm = ({
  isAdmin,
  invalidDate,
  effectiveDateValue,
  onDateChange,
  dateIsEmpty,
  datePickerLabel,
  invalidDateErrorMsg,
  emptyDateErrorMsg,
  displayInline,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConditionalRenderWrappingTag displayInline={displayInline}>
      <Form.Field fluid>
        <LabelText as="label" htmlFor="effectiveDate">
          {formatMessage({ id: `${datePickerLabel}` })}
        </LabelText>
        <StyledDayPickerInput
          id="effectiveDate"
          dayPickerProps={{
            enableOutsideDays: false,
          }}
          placeholder={DATE_FORMAT}
          format={DATE_FORMAT}
          value={effectiveDateValue}
          name="effectiveDate"
          onDayChange={onDateChange}
          error={invalidDate || dateIsEmpty}
          confirmModal
        />
        {invalidDate && (
          <ErrorMessage>
            {formatMessage({ id: invalidDateErrorMsg })}
            {!isAdmin && formatMessage({ id: 'PLEASE_CONTACT_FOR_SUPPORT' })}
          </ErrorMessage>
        )}
        {dateIsEmpty && (
          <ErrorMessage>
            {formatMessage({ id: emptyDateErrorMsg })}
          </ErrorMessage>
        )}
      </Form.Field>
    </ConditionalRenderWrappingTag>
  );
};

ActionsConfirmationDateForm.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  datePickerLabel: PropTypes.string,
  effectiveDateValue: PropTypes.string.isRequired,
  invalidDate: PropTypes.bool.isRequired,
  onDateChange: PropTypes.func.isRequired,
  dateIsEmpty: PropTypes.bool.isRequired,
  displayInline: PropTypes.bool.isRequired,
  invalidDateErrorMsg: PropTypes.string,
  emptyDateErrorMsg: PropTypes.string,
};

ActionsConfirmationDateForm.defaultProps = {
  datePickerLabel: '',
  invalidDateErrorMsg: '',
  emptyDateErrorMsg: '',
};

export default ActionsConfirmationDateForm;
