import {
  chunk,
  concat,
  flatMap,
  isArray,
  isObjectLike,
  reduce,
  snakeCase,
} from 'lodash';

const isObject = value => isObjectLike(value) && !isArray(value);

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const traverse = (refs = [], parentPath = [], resource, decamelize) =>
  flatMap(resource, (value, key) => {
    const path = concat(parentPath, decamelize ? snakeCase(key) : key);
    return isObject(value)
      ? traverse(refs, path, value, decamelize)
      : concat(refs, [path, value]);
  });

export const flattenAddress = (formValues, ref) => {
  const [path, value] = ref;
  const flatPath = path.join('.');
  return { ...formValues, [flatPath]: value };
};

export const flattenObject = (resource, decamelize = false) => {
  const flatRefs = traverse([], [], resource, decamelize);
  const refList = chunk(flatRefs, 2);
  return reduce(refList, flattenAddress, {});
};
