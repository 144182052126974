import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  PrimaryButton,
  SecondaryButton,
  BodyOne,
  HeadingThree,
} from '@leagueplatform/genesis-commons';

import { useIntl } from '@leagueplatform/locales';
import { Modal, Backdrop, ClickEater, Box } from '@leagueplatform/ui-kit';

const FullBody = styled(Modal.Body)`
  height: 100%;
`;

export const RemoveFeeModal = ({ onClose, onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <Backdrop>
      <Modal.Container>
        <ClickEater>
          <Modal.Header>
            <HeadingThree>{formatMessage({ id: 'REMOVE_FEE' })}</HeadingThree>
          </Modal.Header>

          <FullBody>
            <Box mb={3}>
              <BodyOne>{formatMessage({ id: 'REMOVE_FEE_CONFIRM' })}</BodyOne>
            </Box>
          </FullBody>
          <Modal.Footer>
            <SecondaryButton onClick={onClose} type="button">
              {formatMessage({ id: 'CANCEL' })}
            </SecondaryButton>
            <PrimaryButton onClick={onSubmit} marginLeft="one">
              {formatMessage({ id: 'REMOVE_FEE' })}
            </PrimaryButton>
          </Modal.Footer>
        </ClickEater>
      </Modal.Container>
    </Backdrop>
  );
};

RemoveFeeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
