import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { AddFundsForm } from './add-funds.view';
import { connect } from 'react-redux';
import { useIntl } from '@leagueplatform/locales';
import { createStructuredSelector } from 'reselect';
import {
  topUpUserBenefit as topUpUserBenefitAction,
  resetTopUpStatus,
} from './add-funds.actions';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { selectTopUpStatus } from './add-funds.selectors';
import { getEmployeeSpendingAccounts } from '../../employee-spending-accounts/employee-spending-accounts.actions';
import { selectEmployeeSpendingAccounts } from '../../employee-spending-accounts/employee-spending-accounts.selectors';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const mapStateToProps = createStructuredSelector({
  accounts: selectEmployeeSpendingAccounts,
  topUpStatus: selectTopUpStatus,
});

const mapDispatchToProps = {
  topUpUserBenefit: topUpUserBenefitAction,
  resetTopUpStatus,
  getEmployeeSpendingAccounts,
};

export const AddFundsContainer = ({
  benefitId,
  topUpUserBenefit,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const isInHRAdminPortal = useIsInHRAdminPortal();

  return (
    <Formik
      initialValues={{
        benefitId,
        amount: '',
      }}
      onSubmit={({ benefitId: benefitIdValue, amount }) => {
        topUpUserBenefit({
          benefitId: benefitIdValue,
          amount: Number(amount),
        });
        if (isInHRAdminPortal) {
          trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
            product_area: PRODUCT_AREA.HR_ADMIN,
            sub_product_area: null,
            screen_name: 'employees',
            detail: 'select action',
            sub_detail: 'add funds',
          });
        }
      }}
      validateOnChange={false}
      validate={values => {
        const errors = {};
        if (!values.benefitId) {
          errors.benefitId = formatMessage({
            id: 'MISSING_SPENDING_ACCOUNT',
          });
        }

        if (!Number(values.amount)) {
          errors.amount = formatMessage({
            id: 'INCORRECT_AMOUNT',
          });
        }
        return errors;
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      {formProps => <AddFundsForm {...props} {...formProps} />}
    </Formik>
  );
};

AddFundsContainer.propTypes = {
  benefitId: PropTypes.string,
  topUpUserBenefit: PropTypes.func.isRequired,
};

AddFundsContainer.defaultProps = {
  benefitId: undefined,
};

export const AddFunds = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddFundsContainer);
