import { combineReducers } from 'redux';
import {
  SET_ASSERTIVE_MESSAGE,
  SET_POLITE_MESSAGE,
} from './aria-live-region.actions';

const assertiveMessage = (state = '', action = {}) => {
  switch (action.type) {
    case SET_ASSERTIVE_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

const politeMessage = (state = '', action = {}) => {
  switch (action.type) {
    case SET_POLITE_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

export const ariaLiveRegionReducer = combineReducers({
  assertiveMessage,
  politeMessage,
});
