import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { ALLOCATION_OPTIONS } from 'common/constants';
import { Box } from '@leagueplatform/ui-kit';
import { Icon } from 'semantic-ui-react';

const SummaryContainer = styled(Box)`
  line-height: 1.4;
`;

const CalendarIcon = styled(Icon)`
  float: left;
  margin-top: 5px;
  margin-right: 12px;
`;

const getDateVerbiage = date => {
  return (
    <strong>
      <FormattedDate
        value={new Date(date)}
        month="short"
        day="numeric"
        year="numeric"
      />
    </strong>
  );
};

const getFrequencyVerbiage = allocationFrequency => {
  return (
    <strong>
      {
        (ALLOCATION_OPTIONS.find(o => o.value === allocationFrequency) || {})
          .text
      }
    </strong>
  );
};

export const AllocationSummary = ({
  allocationFrequency,
  benefitStartDate,
  benefitEndDate,
}) => {
  return (
    <SummaryContainer>
      <CalendarIcon size="big" className="calendar alternate violet icon" />
      <FormattedMessage
        id={
          allocationFrequency === 'full-amount'
            ? 'ONE_TIME_TOTAL_ALLOCATION_SUMMARY'
            : 'TOTAL_ALLOCATION_SUMMARY'
        }
        values={{
          frequency: getFrequencyVerbiage(allocationFrequency),
          startDate: getDateVerbiage(benefitStartDate),
          endDate: getDateVerbiage(benefitEndDate),
        }}
      />
    </SummaryContainer>
  );
};

AllocationSummary.propTypes = {
  allocationFrequency: PropTypes.string.isRequired,
  benefitStartDate: PropTypes.string.isRequired,
  benefitEndDate: PropTypes.string.isRequired,
};
