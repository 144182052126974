import React, { useState, useEffect } from 'react';
import Row from './row.view';
import { getInviteURL } from '../selectors';

const useRow = ({
  groups,
  groupId,
  onToggleEmployee,
  userId,
  selectedEmployees,
}) => {
  const [employeeSelected, setEmployeeSelected] = useState(false);
  const inviteURL = getInviteURL(groups, groupId);

  const onSelectEmployee = (_, { checked }) => {
    onToggleEmployee({
      employeeId: userId,
      add: checked,
    });
    setEmployeeSelected(checked);
  };

  useEffect(() => {
    const index = selectedEmployees.indexOf(userId);
    setEmployeeSelected(index > -1);
  }, [selectedEmployees, userId]);

  return {
    employeeSelected,
    onSelectEmployee,
    inviteURL,
  };
};

const RowContainer = props => {
  const rowProps = useRow(props);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <Row {...props} {...rowProps} />;
};

export default RowContainer;
