import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { renewBenefitPlan } from '../actions';
import { RenewPlanPresenter } from './renew-plan.view';
import { selectGroupId, selectEmployerTzName } from '../../../../selectors';

const mapStateToProps = createStructuredSelector({
  groupId: selectGroupId,
  employerTzName: selectEmployerTzName,
});

const mapDispatchToProps = { renewBenefitPlan };

export const RenewPlan = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenewPlanPresenter);
