import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createConfiguredStore } from './invite-access.store';
import { InviteAccessRouter } from './invite-access-router.view';
import { useHistory } from 'react-router-dom';

export function InviteAccess() {
  const history = useHistory();
  const [store] = useState(createConfiguredStore(history));

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <InviteAccessRouter />
      </ConnectedRouter>
    </Provider>
  );
}
