export const SET_ASSERTIVE_MESSAGE = 'aria-live-region/SET_ASSERTIVE_MESSAGE';
export const SET_POLITE_MESSAGE = 'aria-live-region/SET_POLITE_MESSAGE';

/**
 * Use this action creator to set an assertive live message that will be spoken
 * by a screen reader to the user if they are using one. An assertive message
 * should sparingly be used and is meant to get the user's immediate attention
 * as it will interrupt what the screen reader is currently doing. This should
 * only be used for time-sensitive/critical notifications. (ex: form fails to
 * submit, input field error).
 *
 * @param {String} messageId A value that matches a key in en.json
 */
export const announceAssertiveMessage = messageId => ({
  type: SET_ASSERTIVE_MESSAGE,
  payload: messageId,
});

/**
 * Use this action creator to set a polite live message that will be spoken by
 * a screen reader to the user if they are using one. A polite message should be
 * used in most cases when we want to notify a user that something has changed
 * on screen (ex: a new chat message). The screen reader will finish what it is
 * currently doing before it announcing the message.
 *
 * @param {String} messageId A value that matches a key in en.json
 */
export const announcePoliteMessage = messageId => ({
  type: SET_POLITE_MESSAGE,
  payload: messageId,
});
