import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, getColourObject } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';

const DocumentContainer = styled(Flex)`
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(182, 189, 199, 0.5);
`;

const DocumentImage = styled.img`
  cursor: pointer;
  width: 100%;
  border-radius: 8px 8px 0 0;
`;

const DocumentBackgroundImage = styled(Box)`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: ${props => (props.isImageContentType ? 'cover' : '100px')};
  background-position: 50%;
  display: block;
  width: 100%;
  height: ${props => (props.isImageContentType ? props.height : '220px')};
  border-radius: 8px 8px 0 0;
  cursor: pointer;
`;

const DocumentInfo = styled(Flex)`
  bottom: 0;
  left: 0;
  width: 100%;
  color: ${({ theme }) => getColourObject(theme).white};
  font-size: 12px;
  background-color: ${({ theme }) => getColourObject(theme).offWhite};
  border-radius: 0 0 8px 8px;
  min-height: 50px;
  cursor: auto;
`;

export const Document = ({
  children,
  imageUrl,
  height,
  onImageClick,
  isImageContentType,
}) => (
  <DocumentContainer flexDirection="column" mt={2} mb={2}>
    {!isImageContentType || height ? (
      <DocumentBackgroundImage
        onClick={onImageClick}
        imageUrl={imageUrl}
        height={height}
        isImageContentType={isImageContentType}
      />
    ) : (
      <DocumentImage onClick={onImageClick} src={imageUrl} />
    )}
    <DocumentInfo onClick={e => e.stopPropagation()}>{children}</DocumentInfo>
  </DocumentContainer>
);

Document.propTypes = {
  children: PropTypes.node.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  height: PropTypes.string,
  isImageContentType: PropTypes.bool,
};

Document.defaultProps = {
  height: null,
  isImageContentType: true,
  onImageClick: null,
};
