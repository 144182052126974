import { createRequestTypes } from 'common/websocket-redux';

export const GET_DEPENDENT_TERMINATION_TYPE_OPTIONS = createRequestTypes(
  'hr-admin/GET_DEPENDENT_TERMINATION_TYPE_OPTIONS',
);

export const GET_DEPENDENT_ADD_QLE_REASONS = createRequestTypes(
  'hr-admin/GET_DEPENDENT_ADD_QLE_REASONS',
);

export const GET_DEPENDENT_RELATIONSHIP_TYPES = createRequestTypes(
  'hr-admin/GET_DEPENDENT_RELATIONSHIP_TYPES',
);

export const SET_DEPENDENT = createRequestTypes('hr-admin/SET_DEPENDENT');

export const TERMINATE_DEPENDENT = createRequestTypes(
  'hr-admin/TERMINATE_DEPENDENT',
);
