import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { clone } from 'lodash';
import { Table, Visibility, Checkbox } from 'semantic-ui-react';
import { createStructuredSelector } from 'reselect';

import { employeePropTypes } from '../constants';
import EmployeeRow from './row.container';
import ActionConfirmation from './ActionConfirmation';
import EmployeesBulkActions from './EmployeesBulkActions';
import { visibleOnlyAdmin } from 'common/state/user/role-based-visibility.hocs';

import {
  selectUpdatingEmployees,
  selectEmployeesServiceStatus,
} from '../selectors';

const NoResultsLabel = styled.div`
  font-size: 2rem;
  font-weight: 500;
`;

const TableView = styled(Visibility)`
  overflow-x: auto;
`;

class EmployeesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployees: [],
      isModalOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ loading }) {
    const { loading: previousLoading } = this.props;
    if (previousLoading && !loading) {
      this.setState({ selectedEmployees: [] });
    }
  }

  onToggleModal = val => {
    const { isModalOpen } = this.state;
    let newVal = !isModalOpen;
    if (val !== undefined) {
      newVal = val;
    }
    this.setState({ isModalOpen: newVal });
  };

  onToggleEmployee = ({ employeeId, add }) => {
    const { selectedEmployees } = this.state;
    const index = selectedEmployees.indexOf(employeeId);
    if (index > -1 && !add) {
      const selected = clone(selectedEmployees);
      selected.splice(index, 1);
      this.setState({ selectedEmployees: selected });
    } else if (index < 0 && add) {
      const selected = clone(selectedEmployees);
      selected.push(employeeId);
      this.setState({ selectedEmployees: selected });
    }
  };

  onBulkSelect = (event, { checked }) => {
    const { employees } = this.props;
    let updatedSelected = [];
    if (checked) {
      updatedSelected = employees.map(({ userId }) => userId);
    }

    this.setState({
      selectedEmployees: updatedSelected,
    });
  };

  onAskConfirmation = options => {
    this.setState({ confirmAction: options });
    this.onToggleModal();
  };

  render() {
    const {
      employees,
      loading,
      hasMoreResults,
      onEndOfListReached,
      onEmployeeRowSelected,
      employerId,
      isAdmin,
      ActionsDropdown,
      hasSpendingAccounts,
      hasTopupableSpendingAccounts,
      hasEmptyResults,
      updatingEmployees,
      employeesServiceStatus,
    } = this.props;
    const { selectedEmployees, isModalOpen, confirmAction } = this.state;
    const { onToggleModal, onAskConfirmation } = this;

    const OnlyAdminHeader = visibleOnlyAdmin(Table.HeaderCell);
    const OnlyAdminBulkActions = visibleOnlyAdmin(EmployeesBulkActions);

    const allEmployeesSelected = employees.length === selectedEmployees.length;

    return (
      <TableView
        onBottomVisible={() => hasMoreResults && onEndOfListReached()}
        once={false}
      >
        <OnlyAdminBulkActions
          employees={employees}
          selectedEmployees={selectedEmployees}
          isModalOpen={isModalOpen}
          onToggleModal={onToggleModal}
          onAskConfirmation={onAskConfirmation}
          confirmAction={confirmAction}
        />

        <ActionConfirmation
          updatingEmployees={updatingEmployees}
          employeesServiceStatus={employeesServiceStatus}
          open={isModalOpen}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...(confirmAction || {})}
          close={() => {
            onToggleModal(false);
          }}
          confirmed={this.confirmedAction}
        >
          {options =>
            confirmAction && confirmAction.child ? (
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              <confirmAction.child {...options} />
            ) : null
          }
        </ActionConfirmation>

        <Table basic="very" padded>
          <Table.Header>
            <Table.Row>
              <OnlyAdminHeader>
                <Checkbox
                  name="selectAllEmployees"
                  onChange={this.onBulkSelect}
                  checked={allEmployeesSelected}
                />
              </OnlyAdminHeader>
              <Table.HeaderCell className="sort-cell">
                <FormattedMessage id="EMPLOYEE" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="STATUS" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="CLASS" />
              </Table.HeaderCell>
              {hasSpendingAccounts && (
                <Table.HeaderCell>
                  <FormattedMessage id="ALLOCATION" />
                </Table.HeaderCell>
              )}
              {hasSpendingAccounts && (
                <Table.HeaderCell>
                  <FormattedMessage id="BALANCE" />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                <FormattedMessage id="ACTION" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {!hasEmptyResults && (
            <Table.Body>
              {employees.map((employee, index) => (
                <EmployeeRow
                  ActionsDropdown={ActionsDropdown}
                  selectedEmployees={selectedEmployees}
                  onToggleEmployee={this.onToggleEmployee}
                  selectEmployee={onEmployeeRowSelected}
                  key={employee.userId}
                  employerId={employerId}
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...employee}
                  isAdmin={isAdmin}
                  hasSpendingAccounts={hasSpendingAccounts}
                  hasTopupableSpendingAccounts={hasTopupableSpendingAccounts}
                  index={index}
                />
              ))}
            </Table.Body>
          )}
          {!loading && hasEmptyResults && (
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <NoResultsLabel>
                    <FormattedMessage id="NO_RESULTS" />
                  </NoResultsLabel>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </TableView>
    );
  }
}

EmployeesTable.propTypes = {
  loading: PropTypes.bool,
  employees: PropTypes.arrayOf(PropTypes.shape(employeePropTypes)).isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  onEndOfListReached: PropTypes.func.isRequired,
  onEmployeeRowSelected: PropTypes.func.isRequired,
  employerId: PropTypes.string,
  isAdmin: PropTypes.bool,
  ActionsDropdown: PropTypes.func.isRequired,
  hasSpendingAccounts: PropTypes.bool.isRequired,
  hasTopupableSpendingAccounts: PropTypes.bool.isRequired,
  hasEmptyResults: PropTypes.bool,
  updatingEmployees: PropTypes.shape({
    type: PropTypes.string,
    payload: PropTypes.shape({
      userIds: PropTypes.arrayOf(PropTypes.string),
      groupId: PropTypes.string,
      effectiveDate: PropTypes.string,
    }),
  }),
  employeesServiceStatus: PropTypes.shape({
    error: PropTypes.bool,
    reason: PropTypes.string,
  }),
};

EmployeesTable.defaultProps = {
  loading: false,
  isAdmin: false,
  employerId: undefined,
  hasEmptyResults: false,
  updatingEmployees: null,
  employeesServiceStatus: null,
};

const mapStateToProps = createStructuredSelector({
  updatingEmployees: selectUpdatingEmployees,
  employeesServiceStatus: selectEmployeesServiceStatus,
});

export const WrappedComponent = EmployeesTable;

export default injectIntl(connect(mapStateToProps)(EmployeesTable));
