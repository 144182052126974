import React from 'react';
import PropTypes from 'prop-types';
import { BodyOne } from '@leagueplatform/genesis-commons';
import { useFlexDollarText } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

export const LeftoverBenefitText = ({
  leftoverFlexBenefits,
  flexDollarTerm,
}) => {
  const { formatMessage } = useIntl();
  const leftoverFlexDollarMessage = useFlexDollarText({ leftoverFlexBenefits });
  return leftoverFlexBenefits.length > 0 ? (
    <BodyOne as="p" marginTop="one">
      <span>
        {formatMessage(
          { id: 'FLEX_DOLLAR_INFO_TEXT' },
          { flexDollarTerm, message: leftoverFlexDollarMessage },
        )}
      </span>
    </BodyOne>
  ) : null;
};

const LeftoverFlexBenefitsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    benefit_id: PropTypes.string,
    name: PropTypes.string,
    percent: PropTypes.string,
  }),
);

LeftoverBenefitText.propTypes = {
  leftoverFlexBenefits: LeftoverFlexBenefitsPropTypes.isRequired,
  flexDollarTerm: PropTypes.string.isRequired,
};
