export const feeSchema = ({ billingDivisions, formatMessage }) => ({
  fee_type: {
    inputType: 'select',
    label: 'FEE_TYPE',
    name: 'fee_type',
    required: true,
    placeholder: formatMessage({ id: 'FEE_TYPE_HELPER' }),
    options: [
      {
        label: formatMessage({ id: 'PLATFORM' }),
        value: 'Platform',
      },
      {
        label: formatMessage({ id: 'HEALTH_ADVISORY' }),
        value: 'Health Advisory',
      },
      {
        label: formatMessage({ id: 'IMPLEMENTATION' }),
        value: 'Implementation',
      },
      {
        label: formatMessage({ id: 'INTEGRATION' }),
        value: 'Integration',
      },
      {
        label: formatMessage({ id: 'CREDIT_ADJUSTMENT' }),
        value: 'Credit Adjustment',
      },
      {
        label: formatMessage({ id: 'DISCOUNT' }),
        value: 'Discount',
      },
      {
        label: formatMessage({ id: 'FIXED_MONTHLY_PLATFORM_FEES' }),
        value: 'Fixed Monthly Platform Fees',
      },
      {
        label: formatMessage({ id: 'OTHER' }),
        value: 'Other',
      },
    ],
  },
  rate: {
    inputType: 'text',
    label: 'FEE_RATE',
    name: 'rate',
    required: true,
    placeholder: formatMessage({ id: 'FEE_RATE_PLACEHOLDER' }),
    tooltip: 'ADHOC_FEE_RATE_TOOLTIP',
  },
  units: {
    inputType: 'text',
    label: 'UNITS',
    name: 'units',
    required: true,
    placeholder: '100',
  },
  term: {
    inputType: 'text',
    label: 'TERM',
    name: 'term',
    required: false,
  },
  term_units: {
    inputType: 'select',
    label: 'TERM',
    name: 'term_units',
    required: false,
    options: [
      {
        label: formatMessage({ id: 'MONTHS' }),
        value: 'months',
      },
      {
        label: formatMessage({ id: 'QUARTERS' }),
        value: 'quarters',
      },
      {
        label: formatMessage({ id: 'YEARS' }),
        value: 'years',
      },
    ],
  },
  date: {
    inputType: 'date',
    label: 'BILLING_PERIOD_START_DATE',
    name: 'date',
    required: true,
  },
  end_date: {
    inputType: 'date',
    label: 'BILLING_PERIOD_END_DATE',
    name: 'end_date',
    required: false,
  },
  billing_division_id: {
    inputType: 'select',
    label: 'BILLING_DIVISION',
    name: 'billing_division_id',
    options: billingDivisions.map(division => ({
      label: division.name,
      value: division.billingDivisionId,
    })),
    required: false,
  },
  note: {
    inputType: 'text',
    label: 'NOTES',
    name: 'note',
    required: false,
  },
});

export const NEGATIVE_FEE_TYPES = ['Credit Adjustment', 'Discount'];
