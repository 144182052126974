/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HeadingThree,
  BodyOne,
  Flex,
  Label,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { useIntl } from '@leagueplatform/locales';
import { DeductionsTable } from './employee-deductions-table.container';

export const EmployeeDeductionsPresenter = ({
  getUserBenefits,
  userId,
  groupId,
  contributionAccounts,
  contributions,
  getUserBenefitContributions,
  exit,
}) => {
  const { formatMessage } = useIntl();
  const [accountType, setAccountType] = useState();
  // we can only get contributions by type, when we want them by user_benefit_id as well!
  const contributionsForAccount = contributions.filter(({ user_benefit_id }) =>
    accountType?.id ? user_benefit_id === accountType?.id : true,
  );
  const totalContributions =
    contributionsForAccount?.reduce((acc, contribution) => {
      return acc + contribution.amount.F;
    }, 0) ?? 0;

  useEffect(() => {
    getUserBenefits({ userId, groupId });
  }, [getUserBenefits, groupId, userId]);

  useEffect(() => {
    return () => {
      exit();
    };
  }, [exit]);

  useEffect(() => {
    if (contributionAccounts?.length && !accountType) {
      setAccountType(contributionAccounts[0]);
      getUserBenefitContributions({
        userId,
        contributionBenefitTypes: [contributionAccounts[0].value.split(',')[0]],
        contributor: 'employee',
      });
    }
  }, [contributionAccounts, getUserBenefitContributions, userId, accountType]);

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Box>
          <HeadingThree>
            {formatMessage({ id: 'EMPLOYEE_TASA_DEDUCTIONS' })}
          </HeadingThree>
          <BodyOne color="onSurface.text.subdued">
            {formatMessage({ id: 'EMPLOYEE_TASA_DEDUCTIONS_COPY' })}
          </BodyOne>
        </Box>
        <Label minWidth="50%">
          <LabelText>
            {formatMessage({ id: 'CONTRIBUTIONS_ACCOUNT' })}
          </LabelText>
          <Select
            key={accountType?.value}
            onChange={option => {
              setAccountType(option);
              getUserBenefitContributions({
                userId,
                contributionBenefitTypes: [option.value.split(',')[0]],
                contributor: 'employee',
              });
            }}
            value={accountType?.value}
            options={contributionAccounts}
          />
        </Label>
      </Flex>
      <DeductionsTable
        totalContributions={totalContributions}
        accountType={accountType}
        contributions={contributionsForAccount}
        userId={userId}
      />
    </Box>
  );
};
EmployeeDeductionsPresenter.propTypes = {
  getUserBenefits: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  contributionAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  contributions: PropTypes.arrayOf(
    PropTypes.shape({
      user_benefit_id: PropTypes.string,
    }),
  ),
  getUserBenefitContributions: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
};

EmployeeDeductionsPresenter.defaultProps = {
  contributionAccounts: [],
  contributions: [],
};
