import { createRequestTypes } from 'common/websocket-redux';

export const ADD_BENEFIT_CLASS_TYPEFORM_URLS =
  'integrations/ADD_BENEFIT_CLASS_TYPEFORM_URLS';
export const SET_BENEFIT_CLASS_TYPEFORM_URLS = createRequestTypes(
  'integrations/SET_BENEFIT_CLASS_TYPEFORM_URLS',
);

export const FETCH_BENEFIT_CLASS_TYPEFORM_URLS =
  'integrations/FETCH_BENEFIT_CLASS_TYPEFORM_URLS';
export const GET_BENEFIT_CLASS_TYPEFORM_URLS = createRequestTypes(
  'integrations/GET_BENEFIT_CLASS_TYPEFORM_URLS',
);

export const RESET_INTEGRATION_FORM_UI =
  'integrations/RESET_INTEGRATION_FORM_UI';
