import {
  SET_BENEFIT_CLASS_ID,
  SET_IS_EDIT_HR_EMAIL_ENABLED,
} from './benefit-class-selection.action-types';

export const setBenefitClassId = ({ benefitClassId, groupId }) => ({
  type: SET_BENEFIT_CLASS_ID,
  payload: {
    benefitClassId,
    groupId,
  },
});

export const setIsEditHREmailEnabled = ({ isEditHREmailEnabled }) => ({
  type: SET_IS_EDIT_HR_EMAIL_ENABLED,
  payload: {
    isEditHREmailEnabled,
  },
});
