export default {
  lfLTD: {
    name: 'lfLTD',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },

          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'monthly_benefit_flat_amount',
            span: 6,
          },

          {
            name: 'monthly_benefit_percent_of_salary',
            span: 6,
          },

          {
            name: 'monthly_benefit_graded_schedule_percent_first',
            span: 6,
          },

          {
            name: 'monthly_benefit_graded_schedule_upper_first',
            span: 6,
          },

          {
            name: 'monthly_benefit_graded_schedule_percent_second',
            span: 6,
          },

          {
            name: 'monthly_benefit_graded_schedule_upper_second',
            span: 6,
          },

          {
            name: 'monthly_benefit_graded_schedule_percent_remainder',
            span: 6,
          },

          {
            name: 'monthly_minimum_benefit_amount',
            span: 6,
          },

          {
            name: 'monthly_maximum_benefit_amount',
            span: 6,
          },

          {
            name: 'no_evidence_maximum',
            span: 6,
          },

          {
            name: 'elimination_period_days',
            span: 6,
          },
        ],
      },
    ],
  },
};
