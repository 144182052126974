import { GET_PENDING_FEES } from './fees.action-types';

export const initialState = {
  feesList: [],
  errors: null,
  loading: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const pendingFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_FEES.SUCCEEDED:
      return {
        ...state,
        feesList: action.payload.info.ad_hoc_fees ?? [],
      };
    default:
      return state;
  }
};
