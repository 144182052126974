export const emailSchema = {
  billing_email: {
    field_name: 'billing_email',
    json_type: 'string',
    validation_rules: [
      {
        rule: 'email',
        hint: 'must be a valid email',
      },
      {
        rule: 'required',
        hint: 'cannot be empty',
        args: null,
      },
      {
        rule: 'min',
        hint: 'must be at least 6 characters',
        args: {
          size: 6,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 300 characters',
        args: {
          size: 300,
        },
      },
    ],
  },
};

export const prePaymentsSchema = {
  billing_hsa_buffer: {
    field_name: 'billing_hsa_buffer',
    json_type: 'number',
    validation_rules: [
      {
        rule: 'min',
        hint: 'must be at least 0',
        args: {
          size: 0,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 100',
        args: {
          size: 100,
        },
      },
    ],
  },
  billing_lsa_buffer: {
    field_name: 'billing_lsa_buffer',
    json_type: 'number',
    validation_rules: [
      {
        rule: 'min',
        hint: 'must be at least 0',
        args: {
          size: 0,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 100',
        args: {
          size: 100,
        },
      },
    ],
  },
  billing_charity_buffer: {
    field_name: 'billing_charity_buffer',
    json_type: 'number',
    validation_rules: [
      {
        rule: 'min',
        hint: 'must be at least 0',
        args: {
          size: 0,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 100',
        args: {
          size: 100,
        },
      },
    ],
  },
};

export const adminFeeSchema = {
  billing_hsa_admin_fee: {
    field_name: 'billing_hsa_admin_fee',
    json_type: 'number',
    validation_rules: [
      {
        rule: 'min',
        hint: 'must be at least 0',
        args: {
          size: 0,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 100',
        args: {
          size: 100,
        },
      },
    ],
  },
  billing_lsa_admin_fee: {
    field_name: 'billing_lsa_admin_fee',
    json_type: 'number',
    validation_rules: [
      {
        rule: 'min',
        hint: 'must be at least 0',
        args: {
          size: 0,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 100',
        args: {
          size: 100,
        },
      },
    ],
  },
  billing_charity_admin_fee: {
    field_name: 'billing_charity_admin_fee',
    json_type: 'number',
    validation_rules: [
      {
        rule: 'min',
        hint: 'must be at least 0',
        args: {
          size: 0,
        },
      },
      {
        rule: 'max',
        hint: 'must be at most 100',
        args: {
          size: 100,
        },
      },
    ],
  },
};
