import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withProps } from 'recompose';

import Documents from '../benefits/documents/documents.view';
import Document from '../benefits/documents/document.view';
import { uploadFile as uploadBenefitDocument } from '../benefits/documents/documents.actions';
import { resetUploadedDocuments } from './ui.actions';
import {
  selectUploadedDocument,
  selectUserBenefitDocuments,
} from './benefits.selectors';
import { getContentUrl } from '@leagueplatform/league-content-api';

const withDocumentUrl = withProps(({ userBenefitDocuments, documentId }) => {
  return {
    documentUrl: userBenefitDocuments[documentId]
      ? getContentUrl(
          get(userBenefitDocuments[documentId], 'content.content_id'),
        )
      : null,
  };
});

const EmployeeDocument = withDocumentUrl(Document);

EmployeeDocument.propTypes = {
  userBenefitDocuments:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  documentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
};

const EmployeeDocuments = props => (
  <Documents
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
    renderDocument={documentProps => (
      <EmployeeDocument
        key={documentProps.documentId}
        documentId={documentProps.documentId}
        title={documentProps.title}
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        userBenefitDocuments={props.userBenefitDocuments}
        onRemoveDocument={documentProps.onRemoveDocument}
      />
    )}
  />
);

EmployeeDocuments.propTypes = {
  userBenefitDocuments:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
};

const withState = connect(
  state => ({
    uploadedDocument: selectUploadedDocument(state),
    userBenefitDocuments: selectUserBenefitDocuments(state),
  }),
  { uploadFile: uploadBenefitDocument, resetUploadedDocuments },
);

export default withState(EmployeeDocuments);
