import React from 'react';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { APP_CONFIG } from 'app-config';
import { getUserProfile } from 'common/services/get-user-profile.service';
import { setPostFTUX } from 'common/services/enrollment';
import { setUserProfile } from 'common/services/set-user-profile.service';
import { redeemDependentInvite } from './redeem-dependent-invite.service';
import { getDependentInvitesForUser } from './get-dependent-invites-for-user.service';
import {
  DEPENDENT_PROFILE_FORM_SUBMITTED,
  SET_USER_PROFILE,
} from './enroll-dependent.actions';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

function formatUserProfile(values) {
  return {
    first_name: values.first_name,
    last_name: values.last_name,
    preferred_name: values.preferred_name,
    sex: values.sex,
    gender_identity: values.gender_identity.map(option => option.value),
    pronouns: values.pronouns.map(option => option.value),
    date_of_birth: values.date_of_birth,
    language: values.language,
    phone_number: values.phone_number,
    locations: [
      {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        province: values.province,
        postal: values.postal,
        country: values.country,
        isDefault: true,
        type: 'home',
      },
    ],
  };
}

export function* getInviteCodes() {
  try {
    const { invitations } = (yield getDependentInvitesForUser()).info;
    const sentInviteCode = invitations.find(
      i => i.status === 'sent',
    )?.invitation_code;
    const hasRedeemedInvite = invitations.some(i => i.status === 'redeemed');
    return { hasRedeemedInvite, sentInviteCode };
  } catch (error) {
    return null;
  }
}

export function* setPostFTUXStep() {
  const profile = (yield getUserProfile()).info;
  yield setPostFTUX({ ...profile.member_post_ftux, dependent_profile: true });
}

export function* handleFormSubmission(action) {
  yield put(SET_USER_PROFILE.start());
  try {
    // Temporary: if this flow change caught a user between WR and React, check if they have an invite to redeem.
    // Call invite redemption only if they have a "sent" invite but NO "redeemed" invite.
    const { hasRedeemedInvite, sentInviteCode } = yield* getInviteCodes();
    if (!hasRedeemedInvite && sentInviteCode)
      yield redeemDependentInvite(sentInviteCode);
    // Remove the above a week after the change went into production
    yield setUserProfile(formatUserProfile(action.payload));
    yield* setPostFTUXStep();
    yield put(SET_USER_PROFILE.success());
    window.location = APP_CONFIG.REACT_APP_WR_URL;
  } catch (error) {
    yield put(SET_USER_PROFILE.error(error));
  }
}

export function* showErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'DEX_GENERIC_ERROR_MESSAGE_HEADING',
    contentId: 'DEX_GENERIC_ERROR_MESSAGE',
    values: {
      a: chunks => <a href="mailto:help@league.com">{chunks}</a>,
    },
  });
}

export function* enrollDependentSaga() {
  yield all([
    takeLatest(DEPENDENT_PROFILE_FORM_SUBMITTED, handleFormSubmission),
    takeLatest(SET_USER_PROFILE.ERRORED, showErrorToast),
  ]);
}
