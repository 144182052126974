import React from 'react';
import {
  Tooltip,
  TooltipContent,
  Box,
  QuestionIcon,
} from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MinWidthTooltipContent = styled(Box)`
  min-width: 25rem;
`;

export const QuestionTooltip = ({ children }) => (
  <Tooltip>
    <QuestionIcon />
    <TooltipContent verticalPosition="bottom: 3rem;" borderRadius="5px" center>
      <MinWidthTooltipContent p={2}>{children}</MinWidthTooltipContent>
    </TooltipContent>
  </Tooltip>
);

QuestionTooltip.propTypes = {
  children: PropTypes.node,
};

QuestionTooltip.defaultProps = {
  children: null,
};
