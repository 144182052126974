import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import {
  HeadingTwo,
  BodyOne,
  PrimaryButton,
  DangerButton,
  QuietButton,
} from '@leagueplatform/genesis-commons';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';

export const DuplicateClaimModal = ({
  duplicateClaimId,
  duplicateClaimReferenceId,
  resolvingDuplicate,
  onCancel,
  onApprove,
  onReject,
}) => {
  const claimLink = (
    <Link to={`/admin/claims/${duplicateClaimId}`}>
      <FormattedMessage
        id="CLAIM_REF"
        values={{ referenceId: duplicateClaimReferenceId }}
      />
    </Link>
  );
  return (
    <Modal open>
      <HeadingTwo>
        <FormattedMessage id="DUPLICATE_CLAIM_HEADER" values={{ claimLink }} />
      </HeadingTwo>
      <ModalContent className="duplicate-claim-modal">
        <BodyOne marginBottom="one">
          <FormattedMessage id="DUPLICATE_CLAIM_INTRO" />
        </BodyOne>
        <BodyOne className="duplicate-claim-modal__action">
          <strong>
            <FormattedMessage
              id="DUPLICATE_CLAIM_CANCEL_ACTION"
              as={List.Header}
            />
          </strong>{' '}
          –{' '}
          <FormattedMessage
            id="DUPLICATE_CLAIM_CANCEL_DESCRIPTION"
            as={List.Content}
          />
        </BodyOne>
        <BodyOne className="duplicate-claim-modal__action">
          <strong>
            <FormattedMessage
              id="DUPLICATE_CLAIM_APPROVE_ACTION"
              as={List.Header}
            />
          </strong>{' '}
          –{' '}
          <FormattedMessage
            id="DUPLICATE_CLAIM_APPROVE_DESCRIPTION"
            as={List.Content}
          />
        </BodyOne>
        <BodyOne className="duplicate-claim-modal__action">
          <strong>
            <FormattedMessage
              id="DUPLICATE_CLAIM_REJECT_ACTION"
              as={List.Header}
            />
          </strong>{' '}
          –{' '}
          <FormattedMessage
            id="DUPLICATE_CLAIM_REJECT_DESCRIPTION"
            as={List.Content}
          />
        </BodyOne>
        <BodyOne marginTop="one">
          <FormattedMessage id="DUPLICATE_CLAIM_CONTACT_MANAGER" />
        </BodyOne>
      </ModalContent>
      <ModalButtons>
        <QuietButton onClick={onCancel} marginRight="one">
          <FormattedMessage id="DUPLICATE_CLAIM_CANCEL_ACTION" />
        </QuietButton>
        <DangerButton
          onClick={onApprove}
          marginRight="one"
          disabled={resolvingDuplicate}
        >
          <FormattedMessage id="DUPLICATE_CLAIM_APPROVE_ACTION" />
        </DangerButton>
        <PrimaryButton onClick={onReject} disabled={resolvingDuplicate}>
          <FormattedMessage id="DUPLICATE_CLAIM_REJECT_ACTION" />
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

DuplicateClaimModal.propTypes = {
  duplicateClaimId: PropTypes.string.isRequired,
  duplicateClaimReferenceId: PropTypes.string.isRequired,
  resolvingDuplicate: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};
