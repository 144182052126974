import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectApp = get('apps.employer-experience');

export const selectEmployerDetails = createSelector(
  selectApp,
  get('pages.employerDetails'),
);

export const selectEmployeeProfile = createSelector(
  selectEmployerDetails,
  get('employeeEditUser'),
);
