import {
  SEARCH_PARAMS_CHANGED,
  NEXT_SEARCH_RESULTS_PAGE_REQUESTED,
  TERMINATE_EMPLOYEES,
  GET_TERMINATION_CODES,
  SUSPEND_EMPLOYEES,
  REINSTATE_EMPLOYEES,
  RESET_EMPLOYEES_STATE,
  EMPLOYEES_VISITED,
  UNSCHEDULE_EMPLOYEES_SUSPENSION,
  UNSCHEDULE_EMPLOYEES_TERMINATION,
  UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  SET_USER_ROLE_IN_GROUP,
} from './types';

export const onSearchParamsChanged = params => ({
  type: SEARCH_PARAMS_CHANGED,
  payload: params,
});

export const nextSearchPageRequested = () => ({
  type: NEXT_SEARCH_RESULTS_PAGE_REQUESTED,
});

export const terminateEmployees = payload => ({
  type: TERMINATE_EMPLOYEES,
  payload,
});

export const getTerminationCodes = () => ({
  type: GET_TERMINATION_CODES,
});

export const suspendEmployees = payload => ({
  type: SUSPEND_EMPLOYEES,
  payload,
});

export const reinstateEmployees = payload => ({
  type: REINSTATE_EMPLOYEES,
  payload,
});

export const resetEmployeesState = () => ({
  type: RESET_EMPLOYEES_STATE,
});

export const employeesVisited = () => ({
  type: EMPLOYEES_VISITED,
});

export const unscheduleEmployeesSuspension = payload => ({
  type: UNSCHEDULE_EMPLOYEES_SUSPENSION,
  payload,
});

export const unscheduleEmployeesTermination = payload => ({
  type: UNSCHEDULE_EMPLOYEES_TERMINATION,
  payload,
});

export const unscheduleEmployeesReinstatement = payload => ({
  type: UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  payload,
});

export const setUserRoleInGroup = ({ userId, groupId, role }) => ({
  type: SET_USER_ROLE_IN_GROUP,
  payload: {
    userId,
    groupId,
    role,
  },
});
