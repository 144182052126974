import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/genesis-commons';
import { BENEFIT_TYPES } from 'common/benefit-sets/benefit-set-types';
import ACCIDENT_ICON from 'apps/public/assets/accident-icon.svg';
import CRITICAL_ILLNESS_ICON from 'apps/public/assets/critical-illness-icon.svg';
import HOSPITAL_INDEMNITY_ICON from 'apps/public/assets/hospital-indemnity-icon.svg';
import DENTAL_ICON from 'apps/public/assets/dental-icon.svg';
import DRUG_ICON from 'apps/public/assets/drug-icon.svg';
import LIFE_ICON from 'apps/public/assets/life-icon.svg';
import LTD_ICON from 'apps/public/assets/ltd-icon.svg';
import MEDICAL_ICON from 'apps/public/assets/medical-icon.svg';
import DEFAULT_ICON from 'apps/public/assets/rewards-icon.svg';
import STD_ICON from 'apps/public/assets/std-icon.svg';
import VISION_ICON from 'apps/public/assets/vision-icon.svg';
import SPENDING_ACCOUNTS_ICON from 'apps/public/assets/spending-accounts-icon.svg';

export const benefitIcons = {
  [BENEFIT_TYPES.accidental_death_dismemberment]: LIFE_ICON,
  [BENEFIT_TYPES.accident]: ACCIDENT_ICON,
  [BENEFIT_TYPES.critical_illness]: CRITICAL_ILLNESS_ICON,
  [BENEFIT_TYPES.hospital_indemnity]: HOSPITAL_INDEMNITY_ICON,
  [BENEFIT_TYPES.drug]: DRUG_ICON,
  [BENEFIT_TYPES.medical]: MEDICAL_ICON,
  [BENEFIT_TYPES.dental]: DENTAL_ICON,
  [BENEFIT_TYPES.vision]: VISION_ICON,
  [BENEFIT_TYPES.life]: LIFE_ICON,
  [BENEFIT_TYPES.life_and_add]: LIFE_ICON,
  [BENEFIT_TYPES.std]: STD_ICON,
  [BENEFIT_TYPES.ltd]: LTD_ICON,
  [BENEFIT_TYPES.spending_accounts]: SPENDING_ACCOUNTS_ICON,
  [BENEFIT_TYPES.contributions]: SPENDING_ACCOUNTS_ICON,
  default: DEFAULT_ICON,
};

export const getBenefitIcon = benefitType =>
  benefitIcons[benefitType] ?? benefitIcons.default;

export const BenefitIcon = ({ benefitType, size, src }) => {
  const benefitIconSrc = useMemo(
    () => src || getBenefitIcon(benefitType),
    [benefitType, src],
  );

  return (
    <Box width={size} height={size} marginRight="one">
      <img src={benefitIconSrc} alt="" width="100%" height="100%" />
    </Box>
  );
};

BenefitIcon.propTypes = {
  benefitType: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string,
};

BenefitIcon.defaultProps = {
  size: '48px',
  benefitType: 'default',
  src: null,
};
