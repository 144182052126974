import camelize from 'camelize';
import { GET_USER_SETTINGS } from './user-settings.types';

const initialState = {
  settings: null,
};

export const applyUserSettings = (state, action) => {
  const settings = camelize(action.payload.info);
  return {
    ...state,
    settings,
  };
};

export const userSettingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_SETTINGS.SUCCEEDED:
      return applyUserSettings(state, action);
    default:
      return state;
  }
};
