import { useMemo } from 'react';
import {
  BENEFIT_CLASS,
  BENEFIT_PLAN,
  VALIDATION_STATUS_TYPES,
} from '../enrollment-config.constants';
import { ENTITY_REFS, PROPERTY_REFS } from '../schema-metadata';

export const useTreeNodeHeaderDisplayState = ({
  schemaRef,
  entityType,
  status,
  children,
  active,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
  const refsWithStatus = [
    ENTITY_REFS.BENEFIT,
    ENTITY_REFS.BENEFIT_SET,
    ENTITY_REFS.BUNDLE,
    PROPERTY_REFS.BENEFITS,
    PROPERTY_REFS.BUNDLES,
    PROPERTY_REFS.BENEFIT_SETS,
  ];

  const showPlanContextMenu = useMemo(
    () => Boolean(entityType === BENEFIT_PLAN && active),
    [entityType, active],
  );
  const showStatusIndicator = useMemo(() => {
    if (
      status === VALIDATION_STATUS_TYPES.VALID &&
      entityType !== BENEFIT_CLASS
    )
      return false;

    return Boolean(
      [BENEFIT_PLAN, BENEFIT_CLASS].includes(entityType) ||
        refsWithStatus.includes(schemaRef) ||
        children?.some(child => refsWithStatus.includes(child?.schemaRef)),
    );
  }, [status, entityType, schemaRef, children, refsWithStatus]);

  return { showStatusIndicator, showPlanContextMenu };
};
