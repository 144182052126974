import { get } from 'lodash';
import { LOCALHOST, TESTPROD } from './app-config-constants';

/**
 * @function isLocalhost are we currently running in localhost? I dunno, call me and find out!
 *
 * @param {object} mockWindow super optional, useful only for testing with a mockWindow! Definitely
 * don't pass this argument in production code
 *
 * @returns {boolean}
 */

export const isLocalhost = mockWindow => {
  const w = mockWindow || window;
  return get(w, 'location.hostname') === LOCALHOST;
};
export const isTestProd = mockWindow => {
  const w = mockWindow || window;
  return get(w, 'location.hostname') === TESTPROD;
};
