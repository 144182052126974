import { analytics } from 'common/utilities';
import { cancel, fork, take, takeLatest, all } from 'redux-saga/effects';
import {
  REQUEST_SET_USER_DOCUMENT,
  MY_DOCUMENTS_LIFECYCLE,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  REQUEST_UPLOAD_USER_DOCUMENT,
  EDIT_USER_DOCUMENT,
  EDIT_USER_DOCUMENT_CANCELLED,
} from './user-documents.types';
import { OTHER } from '@leagueplatform/web-common';

export function* registerListDocuments() {
  yield analytics.pageViews.documents.myDocumentsList();
}

export function* registerEditOrUploadAttempt({ type }) {
  const actionType = type;
  yield actionType === OPEN_UPLOAD_MODAL &&
    analytics.pageViews.documents.myDocumentsAdd();
  yield actionType === EDIT_USER_DOCUMENT &&
    analytics.pageViews.documents.myDocumentsEdit();
  yield analytics.documents.documentsEditOrUploadAttempt();
}

export function* registerCloseForm() {
  yield analytics.documents.documentsEditOrUploadCancel();
}

export function* registerSavedDocumentSuccess({ payload, meta }) {
  const { documentType, documentTypeOtherDescription } = payload;
  const { upload } = meta;

  yield upload
    ? analytics.documents.uploadDocument(documentType)
    : analytics.documents.editDocument(documentType);

  yield documentType === OTHER &&
    analytics.documents.customDocumentTypeSet(documentTypeOtherDescription);

  yield analytics.documents.documentsApiCallSuccess();
}

export function* registerEditOrUploadFail(action) {
  yield analytics.documents.documentsApiCallFail(action.payload.info?.reason);
}

export function* userDocumentsAnalyticsSaga() {
  while (true) {
    yield take(MY_DOCUMENTS_LIFECYCLE.VISITED);
    yield registerListDocuments();

    const watchers = yield all([
      fork(
        takeLatest,
        [EDIT_USER_DOCUMENT, OPEN_UPLOAD_MODAL],
        registerEditOrUploadAttempt,
      ),
      fork(
        takeLatest,
        [EDIT_USER_DOCUMENT_CANCELLED, CLOSE_UPLOAD_MODAL],
        registerCloseForm,
      ),
      fork(
        takeLatest,
        REQUEST_SET_USER_DOCUMENT.SUCCEEDED,
        registerSavedDocumentSuccess,
      ),
      fork(
        takeLatest,
        [
          REQUEST_UPLOAD_USER_DOCUMENT.ERRORED,
          REQUEST_SET_USER_DOCUMENT.ERRORED,
        ],
        registerEditOrUploadFail,
      ),
    ]);
    yield take(MY_DOCUMENTS_LIFECYCLE.EXITED);
    yield cancel(...watchers);
  }
}
