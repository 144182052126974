import cookie from 'react-cookies';

export const loadAuthCookies = () => {
  /*
    NOTE: we had an issue with using the old DT cookie (and SID) that was created on league.com (instead of .league.com)
    so we're using DT2 (version 2) and SID2 for React app from now on
  */
  const sessionId = cookie.load('SID2');
  const userId = cookie.load('U');
  const role = cookie.load('R');
  const deviceToken = cookie.load(`DT2_${userId}`);
  return { sessionId, userId, role, deviceToken };
};
