import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { branch, lifecycle, renderComponent } from 'recompose';
import { Loader } from 'semantic-ui-react';
import { selectReady } from './adjustments.selectors';
import { EMPLOYER_ADJUSTMENTS_LIFECYCLE } from './adjustments.types';

export const withDispatchers = connect(null, {
  visit: EMPLOYER_ADJUSTMENTS_LIFECYCLE.visit,
  exit: EMPLOYER_ADJUSTMENTS_LIFECYCLE.exit,
});

export const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.visit(this.props);
  },
  componentWillUnmount() {
    this.props.exit(this.props);
  },
});

const withReadyState = connect(state => ({ ready: selectReady(state) }));

const withLoadingSpinner = branch(
  props => !props.ready,
  renderComponent(() => <Loader active inline="centered" />),
);

export const withLifecycleInitializer = compose(
  withDispatchers,
  withLifecycleActions,
  withReadyState,
  withLoadingSpinner,
);
