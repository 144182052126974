import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Create direct deposit for CA and US accounts.
 * If given paymentCredentialsId, service will update instead of create.
 *
 * Common Params:
 * @param {string} name: Account holder's name
 * @param {string} userId: member's user id (probably used to determine if CA or US)
 * @param {string} paymentCredentialsId: given if account already in our records. Signifies update instead of create.
 * @param {bool} payeeDefault: is default account?
 *
 * CA Params:
 * @param {string} accountNumber: Must have length between 9-12
 * @param {string} routingNumber: Prefixed by "0" and composed of institution and branch numbers, must be a total length of 9
 *
 * US Params:
 * @param {string} accountNumber: Must have length between 4-17
 * @param {string} routingNumber: Must have 9 digits in length. Not composed by anything, just a single input
 */
export const setEftProfile = ({
  name,
  accountNumber,
  routingNumber,
  userId,
  paymentCredentialsId = null,
  payeeDefault = null,
}) =>
  call(websocketFetch, 'set_eft_profile', {
    name,
    account_number: accountNumber,
    routing_number: routingNumber,
    user_id: userId,
    payment_credentials_id: paymentCredentialsId,
    default: payeeDefault,
  });
