import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { SUBMIT_ATTEMPTED } from '../../constants';
import { ScrollContextConsumer } from 'common/components/scroll-context/scroll-context.view';

const Submit = ({ name, setStatus }) => (
  <ScrollContextConsumer>
    {({ scrollTo }) => (
      <Form.Button
        color="violet"
        name={name}
        onClick={() => {
          setStatus(SUBMIT_ATTEMPTED);
          scrollTo();
        }}
        style={{ marginTop: '2rem' }}
      >
        <FormattedMessage id="SUBMIT" />
      </Form.Button>
    )}
  </ScrollContextConsumer>
);

Submit.propTypes = {
  name: PropTypes.string.isRequired,
  setStatus: PropTypes.func,
};

Submit.defaultProps = {
  setStatus: () => {
    // eslint-disable-next-line no-console
    console.warn("Formik's setStatus is not implemented for this form.");
  },
};

export default Submit;
