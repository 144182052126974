import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PaymentMethods from './components/PaymentMethods';
import BillingPreference from './components/BillingPreference';
import PrePayments from './components/PrePayments';
import Contact from './components/Contact';
import Fees from './components/Fees';
import { EmployerAdjustments } from 'apps/employer-experience-v2/employer-adjustments';
import { InvoicesPage } from 'apps/employer-experience-v2/employer-invoices/invoices.view';
import { EmployerDivisions } from 'apps/employer-experience-v2/employer-divisions';
import { uniqBy } from 'lodash';
import { NetSuiteId } from './components/NetSuite/net-suite-id.component';
import BillingInsights from 'common/billing-insights/billing-insights.component';
import { SubscriptionFees } from './components/Fees/components/NewSubscriptionFees/subscription-fees.component';

export const BillingRoutes = ({
  invoiceAsPayment,
  billingPreference,
  groupCreditContracts,
  billingContact,
  groupId,
  benefits,
  match,
}) => {
  const getBenefitTypes = () => {
    const benefitTypes = [];
    benefits.result.forEach(benefit => {
      const type = benefits.entities.benefits[benefit].benefitType;
      if (type && type.includes('league_spending_')) {
        benefitTypes.push({
          type: `${benefits.entities.benefits[benefit].benefitProductType}`,
          id: `${benefit}`,
          benefitType: type,
        });
      }
    });
    return uniqBy(benefitTypes, 'benefitType');
  };

  const benefitTypes = getBenefitTypes();
  return (
    <Switch>
      <Route
        path={`${match.url}/preferences`}
        render={() => (
          <>
            <PaymentMethods invoiceAsPayment={invoiceAsPayment} />
            <BillingPreference billingPreference={billingPreference} />
            <NetSuiteId groupId={groupId} />
          </>
        )}
      />
      <Route
        path={`${match.url}/subscription-fees`}
        render={() => <SubscriptionFees groupId={groupId} />}
      />
      <Route
        path={`${match.url}/pre-payments`}
        render={() => (
          <PrePayments
            groupCreditContracts={groupCreditContracts}
            benefitTypes={benefitTypes}
          />
        )}
      />
      <Route
        path={`${match.url}/contact`}
        render={() => <Contact billingContact={billingContact} />}
      />
      <Route
        path={`${match.url}/adjustments`}
        render={() => <EmployerAdjustments groupId={groupId} />}
      />
      <Route
        exact
        path={`${match.url}/invoices`}
        render={() => <InvoicesPage groupId={groupId} />}
      />
      <Route
        path={`${match.url}/divisions`}
        render={() => <EmployerDivisions groupId={groupId} />}
      />
      <Route
        path={`${match.url}/fees`}
        render={() => <Fees groupId={groupId} />}
      />
      <Route
        path={`${match.url}/invoices/insights/:year/:month`}
        search="?billing_division_name=billingDivisionName"
        render={props => (
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          <BillingInsights {...props} groupId={groupId} isAdmin />
        )}
      />
      <Route
        path={`${match.url}`}
        // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
        component={() => <Redirect to={`${match.url}/preferences`} />}
      />
    </Switch>
  );
};

BillingRoutes.defaultProps = {
  benefits: {},
};

BillingRoutes.propTypes = {
  groupId: PropTypes.string.isRequired,
  billingPreference: PropTypes.string.isRequired,
  invoiceAsPayment: PropTypes.bool.isRequired,
  groupCreditContracts: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  billingContact: PropTypes.string.isRequired,
  benefits: PropTypes.shape({
    entities: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
      benefits: PropTypes.object,
    }),
    result: PropTypes.arrayOf(PropTypes.string),
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};
