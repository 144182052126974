import { compose, mapProps } from 'recompose';
import { omit } from 'lodash/fp';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { withRouter } from 'react-router-dom';
import {
  selectUserIsAdmin,
  selectUserIsClaimsAgent,
  selectUserHasRole,
} from 'common/state/user/role.selectors';

/**
 * @module common/state/user/role-based-visibility.hocs
 * Role Based Access Higher Order Components (RBAC HOCs)
 * These HOCs will hide a component to a user unless they have the correct role to access it.
 *
 * @see common/state/user/role-based-redirects.hocs
 * These pair well with role-based redirects to guard against users accessing protected routes.
 *
 * @see common/state/user/user.reducer
 * @see apps/auth/auth-classic/auth.reducer
 * These RBAC HOCs depend on redux state from the `state.user` and `state.authentication` slices.
 * These slices are not available in every app (namely Enrollment).
 */

const visibleOnlyAdminHOC = connectedAuthWrapper({
  wrapperDisplayName: 'VisibleOnlyAdmin',
  authenticatedSelector: selectUserIsAdmin,
});

const omitProps = compose(
  mapProps,
  omit,
)([
  'isAuthenticating',
  'isAuthenticated',
  'dispatch',
  'redirect',
  'redirectPath',
  'staticContext',
]);

export const visibleOnlyAdmin = compose(
  withRouter,
  visibleOnlyAdminHOC,
  omitProps,
);

export const visibleOnlyRoles = roles =>
  connectedAuthWrapper({
    wrapperDisplayName: 'VisibleOnlyRoles',
    authenticatedSelector: selectUserHasRole(roles),
  });

const visibleOnlyClaimsAgentHOC = connectedAuthWrapper({
  wrapperDisplayName: 'VisibleOnlyClaimsAgent',
  authenticatedSelector: selectUserIsClaimsAgent,
});

export const visibleOnlyClaimsAgent = compose(
  withRouter,
  visibleOnlyClaimsAgentHOC,
  omitProps,
);
