import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon, Label } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import AddRenameInput from '../../../AddRenameInput';

class AddBenefitPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canAdd: false,
      adding: false,
      benefitPlanName: '',
      hasError: false,
    };

    this.toggleAddBenefitPlan = this.toggleAddBenefitPlan.bind(this);
    this.onAddBenefitPlan = this.onAddBenefitPlan.bind(this);
    this.onBenefitPlanNameChange = this.onBenefitPlanNameChange.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps({ errorAddingBenefitPlan, addingBenefitPlan }) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (errorAddingBenefitPlan && this.props.addingBenefitPlan) {
      this.setState(() => ({ adding: false, hasError: true }));
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    } else if (!addingBenefitPlan && this.props.addingBenefitPlan) {
      this.setState(() => ({
        adding: false,
        benefitPlanName: '',
        canAdd: false,
        hasError: false,
      }));
    }
  }

  onAddBenefitPlan() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    const value = this.state.benefitPlanName;

    if (value.trim()) {
      this.setState(() => ({
        adding: true,
        hasError: false,
        benefitPlanName: value,
      }));
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.addBenefitPlan({ name: value });
    } else {
      this.setState(() => ({ hasError: true }));
    }
  }

  onBenefitPlanNameChange(e, { value }) {
    const newValue = value;
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.hasError && newValue) {
      this.setState(() => ({ benefitPlanName: newValue, hasError: false }));
    } else {
      this.setState(() => ({ benefitPlanName: newValue }));
    }
  }

  toggleAddBenefitPlan() {
    this.setState(previousState => ({ canAdd: !previousState.canAdd }));
  }

  render() {
    const { canAdd, adding, benefitPlanName, hasError } = this.state;
    const { intl } = this.props;

    return (
      <Container className="container--add-benefit-plan">
        {!canAdd ? (
          <Label onClick={this.toggleAddBenefitPlan}>
            <Icon fitted name="add" />
            <FormattedMessage id="ADD_BENEFITS_PLAN" />
          </Label>
        ) : (
          <AddRenameInput
            hasError={hasError}
            name={benefitPlanName}
            onChangeHandler={this.onBenefitPlanNameChange}
            onSubmitHandler={this.onAddBenefitPlan}
            shouldLoad={adding}
            contentId={intl.formatMessage({ id: 'ADD_BENEFITS_PLAN' })}
            placeholderId={intl.formatMessage({
              id: 'ADD_BENEFITS_PLAN_PLACEHOLDER',
            })}
            toggle={this.toggleAddBenefitPlan}
          />
        )}
      </Container>
    );
  }
}

AddBenefitPlan.propTypes = {
  intl: intlShape.isRequired,
  addBenefitPlan: PropTypes.func.isRequired,
  errorAddingBenefitPlan: PropTypes.bool.isRequired,
  addingBenefitPlan: PropTypes.bool.isRequired,
};

export default injectIntl(AddBenefitPlan);
