export const isKPMG = employerName => {
  const companies = ['KPMG'];
  const re = new RegExp(companies.join('|'));
  return re.test(employerName);
};

export const INCOME_PARTNER = 'income partner';
export const EQUITY_PARTNER = 'equity partner';
export const FULL_TIME_EMPLOYEE = 'full-time employee';
export const TEMPORARY_EMPLOYEE = 'temporary employee';
export const CO_OP = 'co-op';

/* Returns a boolean value if the employer is KPMG
  Current known use cases: display Wellness Pool instead
  of Flexible Dollars for KPMG */

export const isKPMGEmployeeType = (
  type,
  benefitClassId,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  benefitClasses = [],
  company,
) => {
  return (
    isKPMG(company) &&
    benefitClasses.some(benefitClass => {
      return (
        benefitClass.id === benefitClassId &&
        benefitClass.name.toLowerCase() === type
      );
    })
  );
};

const isKPMGIncomePartner = (benefitClassId, benefitClasses, company) =>
  isKPMGEmployeeType(INCOME_PARTNER, benefitClassId, benefitClasses, company);

export const isKPMGEquityPartner = (benefitClassId, benefitClasses, company) =>
  isKPMGEmployeeType(EQUITY_PARTNER, benefitClassId, benefitClasses, company);

export const isKPMGPartner = (benefitClassId, benefitClasses, company) => {
  return (
    isKPMGIncomePartner(benefitClassId, benefitClasses, company) ||
    isKPMGEquityPartner(benefitClassId, benefitClasses, company)
  );
};

export const isKPMGEmployee = (benefitClassId, benefitClasses, company) => {
  return (
    isKPMGEmployeeType(
      FULL_TIME_EMPLOYEE,
      benefitClassId,
      benefitClasses,
      company,
    ) ||
    isKPMGEmployeeType(
      TEMPORARY_EMPLOYEE,
      benefitClassId,
      benefitClasses,
      company,
    )
  );
};

export const isLushUS = employerName => {
  const companies = ['Lush US'].join('|');
  const exceptions = ['Non-Continental'].join('|');
  const re = new RegExp(`(${companies})(?!.*(?:${exceptions})).*`);
  return re.test(employerName);
};

export const isTrupanion = employerName => {
  const companies = ['Trupanion'];
  const re = new RegExp(companies.join('|'));
  return re.test(employerName);
};

export const isShopify = employerName => {
  const companies = ['Shopify'];
  const re = new RegExp(companies.join('|'));
  return re.test(employerName);
};

export const isEmployerWithRiskPersona = employerName => {
  const companies = ['Flipp', 'Diamond Schmitt Architects'];
  const re = new RegExp(companies.join('|'));
  return re.test(employerName);
};

export const isBlueCatUsId = employerId => {
  const id = '7ace585c97b1825d999bceecb88c4b4d';
  return employerId === id;
};

export const isFinning = employerName => {
  const companies = ['Finning'];
  const re = new RegExp(companies.join('|'));
  return re.test(employerName);
};
