import { takeLatest, put, call, fork } from 'redux-saga/lib/effects';
import {
  GET_CURRENT_GEOLOCATION,
  SET_CURRENT_GEOLOCATION,
  USE_STORED_LOCATION,
} from './types';

function getCurrentPosition() {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(
      location => {
        const { latitude, longitude } = location.coords;
        resolve({ latitude, longitude });
      },
      () => {
        resolve(undefined);
      },
    );
  });
}

export function* getGeolocation() {
  const coordinates = yield call(getCurrentPosition);
  if (coordinates) {
    yield put({ type: SET_CURRENT_GEOLOCATION, coordinates });
  } else {
    yield put({ type: USE_STORED_LOCATION });
  }
}

export function* watchGetCurrentGeolocation() {
  yield takeLatest(GET_CURRENT_GEOLOCATION, getGeolocation);
}

export function* geolocationSaga() {
  yield fork(watchGetCurrentGeolocation);
}
