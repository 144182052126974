import { get, keyBy, mapValues, compose } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectApp = get('apps.employer-experience');

export const selectUI = createSelector(
  selectApp,
  get('ui.editEmployerIntegrations'),
);

export const selectBenefitClassTypeformUrls = createSelector(
  selectApp,
  app => app.employerIntegrations.typeform,
);

export const selectEmployerIntegrationFormValues = createSelector(
  selectBenefitClassTypeformUrls,
  typeform =>
    compose(mapValues('typeformUrl'), keyBy('id'))(typeform.benefitClasses),
);
