import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getNetSuiteInvoiceReport = ({ fromDate = null, toDate = null }) =>
  call(websocketFetch, 'request_report', {
    type: 'netsuite_invoice_report',
    request: {
      from: fromDate,
      to: toDate,
    },
  });
