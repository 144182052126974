import { websocketFetch } from 'common/websocket-redux';
import { call } from 'redux-saga/effects';

/**
 * @function getGroupDashboards get available dashboards for a group
 *
 * @param {string} groupId the employer group ID
 *
 * @returns {object} an object
 */

export const getGroupDashboards = ({ groupId }) =>
  call(websocketFetch, 'get_group_dashboards', {
    group_id: groupId,
  });
