import { STATUS } from './status.constants';

export function makeStatusReducer(requestAction) {
  return function statusReducer(state = STATUS.IDLE, action = {}) {
    switch (action?.type) {
      case requestAction.STARTED:
        return STATUS.LOADING;
      case requestAction.SUCCEEDED:
        return STATUS.SUCCESS;
      case requestAction.ERRORED:
        return STATUS.ERROR;
      default:
        return state;
    }
  };
}
