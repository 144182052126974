import { useIntl } from '@leagueplatform/locales';
import { isKPMGPartner } from 'apps/utils/employer-utils';
import { getPeriodTypeTranslation } from 'common/benefits/metadata';

const payrollDeductionMessageIds = {
  Weekly: 'WEEKLY_PAYROLL_DEDUCTION',
  'Bi-Weekly': 'BI_WEEKLY_PAYROLL_DEDUCTION',
  'Semi-Monthly': 'SEMI_MONTHLY_PAYROLL_DEDUCTION',
  Monthly: 'MONTHLY_PAYROLL_DEDUCTION',
  'Monthly Draw': 'REDUCTION_IN_MONTHLY_DRAW',
};

const defaultPayGroupMessageId = getPeriodTypeTranslation('Semi-Monthly');
const defaultPayrollDeductionMessageId =
  payrollDeductionMessageIds['Semi-Monthly'];

const getPayrollDeductionMessageId = payGroup =>
  payrollDeductionMessageIds[payGroup] || defaultPayrollDeductionMessageId;

const getPayGroupMessageId = payGroup =>
  getPeriodTypeTranslation(payGroup) || defaultPayGroupMessageId;

export const usePayrollDeduction = ({
  benefitClassId,
  benefitClasses,
  company,
  perPayPeriodCosts,
  benefitSetsWithEOIExpired,
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const showPayrollDeduction = !isKPMGPartner(
    benefitClassId,
    benefitClasses,
    company,
  );

  const payrollDeduction =
    benefitSetsWithEOIExpired?.length > 0
      ? perPayPeriodCosts?.approved_payroll_deduction
      : perPayPeriodCosts?.payroll_deduction;

  return {
    payrollDeduction: showPayrollDeduction
      ? formatMoney(payrollDeduction ?? {})
      : null,
    payGroup: formatMessage({
      id: getPayGroupMessageId(perPayPeriodCosts?.pay_group),
    }),
    payrollDeductionText: formatMessage({
      id: getPayrollDeductionMessageId(perPayPeriodCosts?.pay_group),
    }),
  };
};
