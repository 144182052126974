import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const EMPLOYER_INVOICES_LIFECYCLE = createLifecycleTypes(
  'hr/employer-billing/invoices/EMPLOYER_INVOICES_LIFECYCLE',
);

export const EMPLOYER_INVOICES_INIT = createRequestTypes(
  'hr/employer-billing/invoices/INIT',
);

export const DOWNLOAD_INVOICE_CSV =
  'hr/employer-billing/invoices/DOWNLOAD_INVOICE_CSV';

export const REQUEST_INVOICE_DRY_RUN =
  'admin/employer-adjustments/REQUEST_INVOICE_DRY_RUN';
export const INVOICE_DRY_RUN = createRequestTypes('services/INVOICE_DRY_RUN');

export const TOGGLE_INVOICE_STATUS = createRequestTypes(
  'services/TOGGLE_INVOICE_STATUS',
);

export const SAVE_INVOICE_STATUS = createRequestTypes(
  'services/SAVE_INVOICE_STATUS',
);

export const DOWNLOAD_EXTERNAL_INVOICE = createRequestTypes(
  'services/DOWNLOAD_EXTERNAL_INVOICE',
);
