import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, LapAndAbove } from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const maxWidth = 1200;

const pageContainerAttrs = {
  px: [0, 0, 0, 3, 4],
  mb: [1, 1, 1, 1, 3],
  mx: 'auto',
};
export const PageContainer = styled(Box).attrs(pageContainerAttrs)`
  max-width: ${maxWidth}px;
`;

export const FlexPageContainer = styled(Flex).attrs({
  ...pageContainerAttrs,
  flexDirection: 'column',
  alignItems: 'center',
})`
  max-width: ${maxWidth}px;
`;

export const PageHeadContainer = ({ children, ...props }) => (
  <LapAndAbove>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <Box my={2} {...props}>
      {children}
    </Box>
  </LapAndAbove>
);

PageHeadContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
