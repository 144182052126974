import { get, omit } from 'lodash';
import {
  ADD_EMPLOYEE_BENEFIT_DOCUMENT,
  GET_USER_BENEFITS,
  ADD_USER_BENEFITS,
  UPDATE_USER_BENEFITS,
  REMOVE_USER_BENEFIT,
  REQUEST_REMOVE_USER_BENEFIT,
  SET_USER_BENEFIT_CLASS,
  FETCHED_ALL_USER_BENEFIT_DOCUMENTS,
  EMPLOYEE_BENEFITS_PAGE_LIFECYCLE,
} from './benefits.types';
import { RESET_UPLOADED_DOCUMENTS } from './ui.types';

const uiReset = {
  error: null,
  updating: false,
  updated: false,
  adding: false,
  added: false,
  removing: null,
  removed: null,
  changedClass: null,
};

export const initialState = {
  loading: false,
  ...uiReset,
  fetchingUserBenefits: false,
  userBenefitDocumentsReady: false,
};

const applyAddEmployeeBenefitDocumentSucceeded = (state, { payload }) => ({
  ...state,
  benefitDocument: get(payload, 'info.documents[0]'),
});

const applySetUserBenefitClassStarted = state => ({
  ...state,
  loading: true,
});

const applyGetUserBenefitsStarted = state => ({
  ...state,
  fetchingUserBenefits: true,
});

const applyGetUserBenefitsErrored = (state, action) => ({
  ...state,
  fetchingUserBenefits: false,
  error: get(action, 'payload.info.reason') || true,
});

const applyGetUserBenefitsSucceeded = state => ({
  ...state,
  fetchingUserBenefits: false,
  error: null,
});

const applySetUserBenefitClassErrored = (state, action) => ({
  ...state,
  loading: false,
  error: get(action, 'payload.info.reason') || true,
});

const applySetUserBenefitClassSucceeded = (state, action) => ({
  ...state,
  loading: false,
  error: null,
  changedClass: action.payload.changedClass,
  changedClassMessage: action.payload.info.message,
});

const applyUpdateUserBenefitsStarted = state => ({
  ...state,
  ...uiReset,
  updating: true,
});

const applyUpdateUserBenefitsSucceeded = state => ({
  ...state,
  ...uiReset,
  updating: false,
  updated: true,
  error: null,
});

const applyUpdateUserBenefitsErrored = (state, action) => ({
  ...state,
  ...uiReset,
  updating: false,
  error: get(action, 'payload.info.reason') || true,
});

const applyAddUserBenefitsStarted = state => ({
  ...state,
  ...uiReset,
  adding: true,
});

const applyAddUserBenefitsSucceeded = state => ({
  ...state,
  ...uiReset,
  adding: false,
  added: true,
  error: null,
});

const applyAddUserBenefitsErrored = (state, action) => ({
  ...state,
  ...uiReset,
  adding: false,
  error: get(action, 'payload.info.reason') || true,
});

const applyRemoveUserBenefitStarted = (state, { payload }) => ({
  ...state,
  ...uiReset,
  removing: payload.benefitId,
});

const applyRemoveUserBenefitErrored = (state, action) => ({
  ...state,
  ...uiReset,
  removing: null,
  error: get(action, 'payload.info.reason') || true,
});

const applySuccessRemovingUserBenefit = state => ({
  ...state,
  ...uiReset,
  removing: null,
  removed: state.removing,
  error: null,
});

const applyAllUserBenefitDocumentsReady = state => ({
  ...state,
  userBenefitDocumentsReady: true,
});

export const employeeBenefitsUIReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case RESET_UPLOADED_DOCUMENTS:
      return omit(state, 'benefitDocument');
    case ADD_EMPLOYEE_BENEFIT_DOCUMENT.SUCCEEDED:
      return applyAddEmployeeBenefitDocumentSucceeded(state, action);
    case GET_USER_BENEFITS.STARTED:
      return applyGetUserBenefitsStarted(state, action);
    case SET_USER_BENEFIT_CLASS.STARTED:
      return applySetUserBenefitClassStarted(state, action);
    case GET_USER_BENEFITS.SUCCEEDED:
      return applyGetUserBenefitsSucceeded(state, action);
    case SET_USER_BENEFIT_CLASS.SUCCEEDED:
      return applySetUserBenefitClassSucceeded(state, action);
    case GET_USER_BENEFITS.ERRORED:
      return applyGetUserBenefitsErrored(state, action);
    case UPDATE_USER_BENEFITS.STARTED:
      return applyUpdateUserBenefitsStarted(state);
    case UPDATE_USER_BENEFITS.SUCCEEDED:
      return applyUpdateUserBenefitsSucceeded(state);
    case UPDATE_USER_BENEFITS.ERRORED:
      return applyUpdateUserBenefitsErrored(state, action);
    case ADD_USER_BENEFITS.STARTED:
      return applyAddUserBenefitsStarted(state);
    case ADD_USER_BENEFITS.SUCCEEDED:
      return applyAddUserBenefitsSucceeded(state);
    case ADD_USER_BENEFITS.ERRORED:
      return applyAddUserBenefitsErrored(state, action);
    case REQUEST_REMOVE_USER_BENEFIT:
      return applyRemoveUserBenefitStarted(state, action);
    case REMOVE_USER_BENEFIT.ERRORED:
      return applyRemoveUserBenefitErrored(state);
    case REMOVE_USER_BENEFIT.SUCCEEDED:
      return applySuccessRemovingUserBenefit(state);
    case SET_USER_BENEFIT_CLASS.ERRORED:
      return applySetUserBenefitClassErrored(state, action);
    case FETCHED_ALL_USER_BENEFIT_DOCUMENTS:
      return applyAllUserBenefitDocumentsReady(state, action);
    case EMPLOYEE_BENEFITS_PAGE_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
