import React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  genesisStyled,
  HeadingFour,
  BackgroundColorSystemProp,
} from '@leagueplatform/genesis-commons';
import { DraggableProvided, DroppableProvided } from 'react-beautiful-dnd';
import { useIntl } from '@leagueplatform/locales';

interface DroppableListProps extends BoxProps {
  innerRef: DroppableProvided['innerRef'];
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  isDraggingOver?: boolean;
}

interface DroppableListContainerProps extends FlexProps {
  title: string;
  children: React.ReactNode;
}

interface DragDropBoxProps extends BoxProps {
  innerRef: DraggableProvided['innerRef'] | DroppableProvided['innerRef'];
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  isDragHandle?: boolean;
}

interface DraggableBoxProps extends DragDropBoxProps {
  isDragging: boolean;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  isGroup?: boolean;
  backgroundColor?: BackgroundColorSystemProp;
}

interface DroppableBoxProps extends DragDropBoxProps {
  isDraggingOver: boolean;
}

export const DroppableList = ({
  isDraggingOver,
  innerRef,
  ...props
}: DroppableListProps) => {
  return (
    <Box
      padding="half"
      backgroundColor={
        isDraggingOver
          ? 'surface.background.secondary'
          : 'surface.background.primary'
      }
      borderWidth="thin"
      // TODO: investigate why `interactive.focus` is being used here if it's not supported
      // @ts-expect-error
      borderColor={
        isDraggingOver ? 'interactive.focus' : 'interactive.border.default'
      }
      borderRadius="small"
      minHeight="100px"
      ref={innerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

export const DroppableListContainer = ({
  title,
  children,
  ...props
}: DroppableListContainerProps) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      flexDirection="column"
      paddingRight="one"
      marginBottom="one"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <HeadingFour color="onSurface.text.subdued" marginBottom="half">
        {formatMessage({ id: title })}
      </HeadingFour>
      {children}
    </Flex>
  );
};

export const StyledBox = genesisStyled(Box)<BoxProps>`
  :last-child {
    margin-bottom: 0;
  }
`;

export const DragDropBox = ({ innerRef, ...props }: DragDropBoxProps) => (
  <StyledBox
    padding="half"
    borderRadius="small"
    borderWidth="thin"
    borderColor="onSurface.border.subdued"
    borderStyle="solid"
    marginBottom="half"
    ref={innerRef}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const DraggableBox = ({
  isDragging,
  isGroup,
  isActive,
  backgroundColor = 'interactive.background.default',
  ...props
}: DraggableBoxProps) => {
  let backgroundColorForDropBox: BackgroundColorSystemProp = backgroundColor;

  if (isActive) {
    backgroundColorForDropBox = 'interactive.background.hovered';
  }
  return (
    <DragDropBox
      backgroundColor={
        isDragging
          ? 'interactive.background.hovered'
          : backgroundColorForDropBox
      }
      borderColor={
        isDragging
          ? 'interactive.border.default'
          : 'interactive.border.disabled'
      }
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

export const getDroppableBoxStyles = (
  isDraggingOver: boolean | undefined,
): BoxProps => {
  if (!isDraggingOver) {
    return {
      borderColor: 'transparent',
      borderStyle: 'solid',
      backgroundColor: 'transparent',
    };
  }
  return {
    borderColor: 'interactive.focus.outer',
    borderStyle: 'dashed',
    backgroundColor: 'highlight.background.subdued',
  };
};

export const DroppableBox = ({
  isDraggingOver,
  ...props
}: DroppableBoxProps) => {
  const dragDropBoxStyles = getDroppableBoxStyles(isDraggingOver);
  return (
    <DragDropBox
      paddingX="half"
      paddingY="threeQuarters"
      minHeight="30px"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...dragDropBoxStyles}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};
