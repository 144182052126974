import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectRequestStatus,
  selectContentId,
  selectFileName,
} from './pricing-config-qa-report.selectors';
import { getPricingScenarioReport } from './pricing-config-qa-report.actions';
import { PricingScenarioReport as Report } from './pricing-config-qa-report.view';

export const PricingScenarioReport = connect(
  createStructuredSelector({
    requestStatus: selectRequestStatus,
    contentId: selectContentId,
    fileName: selectFileName,
  }),
  {
    getPricingScenarioReport,
  },
)(Report);
