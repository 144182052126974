import { invertMap } from '@leagueplatform/web-common';

/**
 * Defines all available benefit set types and which benefit sets they contain.
 *
 * TODO: Map the remaining benefits based on their slugified names.
 */

export const BENEFIT_TYPES = {
  accidental_death_dismemberment: 'accidental-death-dismemberment',
  drug: 'drug',
  medical: 'medical',
  dental: 'dental',
  vision: 'vision',
  best_doctors: 'best-doctors',
  hospital_indemnity: 'hospital-indemnity',
  travel: 'travel',
  eap: 'eap',
  life: 'life',
  dependent_life: 'dependent-life',
  life_and_add: 'life-and-add', // normalizedBenefitSet changed 'Life and AD&D' to 'life-and-add'
  accident: 'accident',
  critical_illness: 'critical-illness',
  std: 'std',
  ltd: 'ltd',
  partner_liability: 'partner-liability',
  spending_accounts: 'spending-accounts',
  contributions: 'tax-advantaged-accounts',
};

export const BENEFIT_SET_NAMES: { [key: string]: string } = {
  // Accidental Death and Dismemberment
  accidental_death_dismemberment: 'Accidental Death and Dismemberment',
  voluntary_accidental_death_dismemberment: 'Voluntary AD&D',
  optional_accidental_death_dismemberment: 'Optional AD&D',
  spouse_voluntary_accidental_death_dismemberment: 'Spouse Voluntary AD&D',
  child_voluntary_accidental_death_dismemberment: 'Child Voluntary AD&D',
  // Drug
  drug: 'Drug',
  // Medical
  medical: 'Medical',
  vision: 'Vision',
  health: 'Health',
  // Dental
  dental: 'Dental',

  // Life
  life: 'Life',
  optional_life: 'Optional Life',
  dependent_life: 'Dependent Life',
  spouse_optional_life: 'Spouse Optional Life',
  child_optional_life: 'Child Optional Life',
  voluntary_life: 'Voluntary Life',
  spouse_voluntary_life: 'Spouse Voluntary Life',
  child_voluntary_life: 'Child Voluntary Life',
  // Life and AD&D
  life_and_add: 'Life and AD&D',
  voluntary_life_and_add: 'Voluntary Life and AD&D',
  spouse_voluntary_life_and_add: 'Spouse Voluntary Life and AD&D',
  child_voluntary_life_and_add: 'Child Voluntary Life and AD&D',
  // Accident
  accident: 'Accident',
  optional_accident: 'Optional Accident',
  spouse_accident: 'Spouse Accident',
  child_accident: 'Child Accident',
  // Critical Illness
  critical_illness: 'Critical Illness',
  optional_critical_illness: 'Optional Critical Illness',
  optional_spousal_critical_illness: 'Optional Spousal Critical Illness',
  optional_child_critical_illness: 'Optional Child Critical Illness',
  // Hospital Indemnity
  hospital_indemnity: 'Hospital Indemnity',
  // STD
  std: 'STD',
  optional_std: 'Optional STD',
  voluntary_std: 'Voluntary STD',
  // LTD
  ltd: 'LTD',
  optional_ltd: 'Optional LTD',
  voluntary_ltd: 'Voluntary LTD',
  // Partner Liability
  partner_liability: 'Partner Liability',
  // Spending Accounts
  spending_accounts: 'Spending Accounts',
  wellness_accounts: 'Wellness Accounts',
  contributions: 'Tax-Advantaged Accounts',
  // Programs
  additional_programs: 'Additional Programs',
};

export const ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG =
  'accidental-death-and-dismemberment';
export const VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG = 'voluntary-add';
export const SPOUSE_VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG =
  'spouse-voluntary-add';
export const CHILD_VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG =
  'child-voluntary-add';
// Drug
export const DRUG_SLUG = 'drug';
// Medical
export const MEDICAL_SLUG = 'medical';
export const VISION_SLUG = 'vision';
export const PARAMEDICAL_SLUG = 'paramedical';
export const HEALTHCARE_SLUG = 'healthcare';
export const HEALTH_SLUG = 'health';
// Dental
export const DENTAL_SLUG = 'dental';
// Best Doctors
export const BEST_DOCTORS_SLUG = 'best-doctors';
// Travel
export const TRAVEL_SLUG = 'travel';
// EAP
export const EAP_SLUG = 'eap';
// Life
export const LIFE_SLUG = 'life';
export const OPTIONAL_LIFE_SLUG = 'optional-life';
export const DEPENDENT_LIFE_SLUG = 'dependent-life';
export const SPOUSE_OPTIONAL_LIFE_SLUG = 'spouse-optional-life';
export const CHILD_OPTIONAL_LIFE_SLUG = 'child-optional-life';
export const VOLUNTARY_LIFE_SLUG = 'voluntary-life';
export const SPOUSE_VOLUNTARY_LIFE_SLUG = 'spouse-voluntary-life';
export const CHILD_VOLUNTARY_LIFE_SLUG = 'child-voluntary-life';
// Life and AD&D
export const LIFE_AND_ADD_SLUG = 'life-and-add';
export const VOLUNTARY_LIFE_AND_ADD_SLUG = 'voluntary-life-and-add';
export const SPOUSE_VOLUNTARY_LIFE_AND_ADD_SLUG =
  'spouse-voluntary-life-and-add';
export const CHILD_VOLUNTARY_LIFE_AND_ADD_SLUG = 'child-voluntary-life-and-add';
// Accident
export const ACCIDENT_SLUG = 'accident';
export const OPTIONAL_ACCIDENT_SLUG = 'optional-accident';
export const OPTIONAL_ADD_SLUG = 'optional-add';
export const OPTIONAL_SPOUSE_ADD_SLUG = 'optional-spouse-add';
export const SPOUSE_ACCIDENT_SLUG = 'spouse-accident';
export const CHILD_ACCIDENT_SLUG = 'child-accident';
// Critical Illness
export const CRITICAL_ILLNESS_SLUG = 'critical-illness';
export const OPTIONAL_CRITICAL_ILLNESS_SLUG = 'optional-critical-illness';
export const OPTIONAL_SPOUSAL_CRITICAL_ILLNESS_SLUG =
  'optional-spousal-critical-illness';
export const OPTIONAL_SPOUSE_CRITICAL_ILLNESS_SLUG =
  'optional-spouse-critical-illness';
export const OPTIONAL_CHILD_CRITICAL_ILLNESS_SLUG =
  'optional-child-critical-illness';
// Hospital Care
export const HOSPITAL_INDEMNITY_SLUG = 'hospital-indemnity';
// STD
export const STD_SLUG = 'std';
export const OPTIONAL_STD_SLUG = 'optional-std';
export const VOLUNTARY_STD_SLUG = 'voluntary-std';
// LTD
export const LTD_SLUG = 'ltd';
export const OPTIONAL_LTD_SLUG = 'optional-ltd';
export const VOLUNTARY_LTD_SLUG = 'voluntary-ltd';
// Partner Liability
export const PARTNER_LIABILITY_SLUG = 'partner-liability';
// Spending Accounts
export const SPENDING_ACCOUNTS_SLUG = 'spending-accounts';
export const WELLNESS_ACCOUNTS_SLUG = 'wellness-accounts';
export const CONTRIBUTIONS_SLUG = 'tax-advantaged-accounts';
// Expat
export const EXPAT_SLUG = 'Expat';

const typesToBenefitSets = {
  [BENEFIT_TYPES.accidental_death_dismemberment]: [
    ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG,
    VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG,
    SPOUSE_VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG,
    CHILD_VOLUNTARY_ACCIDENTAL_DEATH_DISMEMBERMENT_SLUG,
  ],
  [BENEFIT_TYPES.drug]: [DRUG_SLUG],
  [BENEFIT_TYPES.medical]: [
    MEDICAL_SLUG,
    PARAMEDICAL_SLUG,
    HEALTHCARE_SLUG,
    HEALTH_SLUG,
  ],
  [BENEFIT_TYPES.dental]: [DENTAL_SLUG],
  [BENEFIT_TYPES.vision]: [VISION_SLUG],
  [BENEFIT_TYPES.best_doctors]: [BEST_DOCTORS_SLUG],
  [BENEFIT_TYPES.travel]: [TRAVEL_SLUG],
  [BENEFIT_TYPES.eap]: [EAP_SLUG],
  [BENEFIT_TYPES.life]: [
    LIFE_SLUG,
    OPTIONAL_LIFE_SLUG,
    DEPENDENT_LIFE_SLUG,
    SPOUSE_OPTIONAL_LIFE_SLUG,
    CHILD_OPTIONAL_LIFE_SLUG,
    VOLUNTARY_LIFE_SLUG,
    SPOUSE_VOLUNTARY_LIFE_SLUG,
    CHILD_VOLUNTARY_LIFE_SLUG,
  ],
  [BENEFIT_TYPES.life_and_add]: [
    LIFE_AND_ADD_SLUG,
    VOLUNTARY_LIFE_AND_ADD_SLUG,
    SPOUSE_VOLUNTARY_LIFE_AND_ADD_SLUG,
    CHILD_VOLUNTARY_LIFE_AND_ADD_SLUG,
  ],
  [BENEFIT_TYPES.accident]: [
    ACCIDENT_SLUG,
    OPTIONAL_ACCIDENT_SLUG,
    OPTIONAL_ADD_SLUG,
    OPTIONAL_SPOUSE_ADD_SLUG,
    SPOUSE_ACCIDENT_SLUG,
    CHILD_ACCIDENT_SLUG,
  ],
  [BENEFIT_TYPES.critical_illness]: [
    CRITICAL_ILLNESS_SLUG,
    OPTIONAL_CRITICAL_ILLNESS_SLUG,
    OPTIONAL_SPOUSAL_CRITICAL_ILLNESS_SLUG,
    OPTIONAL_SPOUSE_CRITICAL_ILLNESS_SLUG,
    OPTIONAL_CHILD_CRITICAL_ILLNESS_SLUG,
  ],
  [BENEFIT_TYPES.hospital_indemnity]: [HOSPITAL_INDEMNITY_SLUG],
  [BENEFIT_TYPES.std]: [STD_SLUG, OPTIONAL_STD_SLUG, VOLUNTARY_STD_SLUG],
  [BENEFIT_TYPES.ltd]: [LTD_SLUG, OPTIONAL_LTD_SLUG, VOLUNTARY_LTD_SLUG],
  [BENEFIT_TYPES.partner_liability]: [PARTNER_LIABILITY_SLUG],
  [BENEFIT_TYPES.spending_accounts]: [
    SPENDING_ACCOUNTS_SLUG,
    WELLNESS_ACCOUNTS_SLUG,
  ],
  [BENEFIT_TYPES.contributions]: [CONTRIBUTIONS_SLUG],
};

export const typeToLegacyTypeMap: {
  [key: string]: string;
} = {
  accident: 'accident',
  accidental_death_and_dismemberment: 'accidental_death_dismemberment',
  additional_programs: 'additional_programs',
  child_accident: 'child_accident',
  child_optional_life: 'child_optional_life',
  child_voluntary_add: 'child_voluntary_accidental_death_dismemberment',
  child_voluntary_life: 'child_voluntary_life',
  child_voluntary_life_add: 'child_voluntary_life_and_add',
  critical_illness: 'critical_illness',
  dental: 'dental',
  dependent_life: 'dependent_life',
  drug: 'drug',
  health: 'health',
  hospital_indemnity: 'hospital_indemnity',
  ltd: 'ltd',
  life: 'life',
  life_add: 'life_and_add',
  medical: 'medical',
  optional_add: 'optional_accidental_death_dismemberment',
  optional_accident: 'optional_accident',
  optional_child_critical_illness: 'optional_child_critical_illness',
  optional_critical_illness: 'optional_critical_illness',
  optional_ltd: 'optional_ltd',
  optional_life: 'optional_life',
  optional_std: 'optional_std',
  optional_spousal_critical_illness: 'optional_spousal_critical_illness',
  partner_liability: 'partner_liability',
  std: 'std',
  spending_accounts: 'spending_accounts',
  spouse_accident: 'spouse_accident',
  spouse_optional_life: 'spouse_optional_life',
  spouse_voluntary_add: 'spouse_voluntary_accidental_death_dismemberment',
  spouse_voluntary_life: 'spouse_voluntary_life',
  spouse_voluntary_life_add: 'spouse_voluntary_life_and_add',
  tax_advantaged_accounts: 'contributions',
  vision: 'vision',
  voluntary_add: 'voluntary_accidental_death_dismemberment',
  voluntary_ltd: 'voluntary_ltd',
  voluntary_life: 'voluntary_life',
  voluntary_life_add: 'voluntary_life_and_add',
  voluntary_std: 'voluntary_std',
  wellness_accounts: 'wellness_accounts',
};

/**
 * Performs a one-time inversion of the type map, mapping each benefit set to
 * it's type for more efficient lookups.
 */
export const benefitsToTypes = invertMap(typesToBenefitSets);

export const getBenefitType = (benefitType: string): string =>
  benefitsToTypes[benefitType];

export const isContributionSet = (setType: string): boolean =>
  [BENEFIT_TYPES.spending_accounts, BENEFIT_TYPES.contributions].includes(
    setType,
  );
