import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/ui-kit';
import { Header, Loader, Label, Grid } from 'semantic-ui-react';
import EmployerTabs from './employer-tabs.view';
import { useRouteMatch, useLocation } from 'react-router-dom';

/**
 *  @name EmployerDetails
 *  @desc renders the view for /admin/{groupId}
 */

export const EmployerDetails = ({
  employerName,
  employerStatus,
  ready,
  visitEmployer,
  clearEmployerDetails,
}) => {
  const match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    const { groupId } = match.params;
    visitEmployer({ groupId });
    // cleanup
    return () => clearEmployerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
  }, []);

  if (!ready) {
    return <Loader active inline="centered" />;
  }
  if (!employerName) {
    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as="h1">Employer Not Found</Header>
        </Grid.Column>
      </Grid.Row>
    );
  }

  return (
    <Box textAlign="left">
      <Header as="h1">
        <span>{employerName}</span>
        <Label
          className={`label-${employerStatus}`}
          icon="circle"
          content={employerStatus}
        />
      </Header>
      <EmployerTabs
        match={match}
        location={location}
        employerName={employerName}
      />
    </Box>
  );
};

EmployerDetails.propTypes = {
  ready: PropTypes.bool.isRequired,
  employerName: PropTypes.string,
  clearEmployerDetails: PropTypes.func.isRequired,
  visitEmployer: PropTypes.func.isRequired,
  employerStatus: PropTypes.string,
};

EmployerDetails.defaultProps = {
  employerName: '',
  employerStatus: null,
};
