import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from '@leagueplatform/genesis-commons';
import { Logo } from 'common/components/Logos/Logo';

export function CoBrand({ employerLogoUrl }) {
  return (
    <Flex
      display="inline-flex"
      position="absolute"
      top="three"
      alignItems="center"
      alignSelf="flex-start"
    >
      <Box paddingRight={['half', 'half', 'oneAndHalf']}>
        <Logo />
      </Box>
      {employerLogoUrl && (
        <Box
          borderLeftStyle="solid"
          borderLeftColor="onSurface.border.subdued"
          borderLeftWidth="thin"
          paddingRight="oneAndHalf"
          paddingLeft="half"
        >
          <Image src={employerLogoUrl} alt="" maxWidth="100%" />
        </Box>
      )}
    </Flex>
  );
}

CoBrand.propTypes = {
  employerLogoUrl: PropTypes.string,
};

CoBrand.defaultProps = {
  employerLogoUrl: undefined,
};
