import React from 'react';
import { Field } from 'formik';
import { MultiSelect } from '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import {
  Box,
  ErrorMessage,
  Flex,
  TextField,
  Label,
  SubtitleOne,
  genesisStyled,
} from '@leagueplatform/genesis-commons';

import { DownloadLinkCSV } from 'apps/employer-experience/ui-kit';
import PropTypes from 'prop-types';
import {
  ENROLLMENT_SELECTIONS_REPORT_GROUP_ID,
  ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS,
} from '../../../constants';
import { useIntl } from '@leagueplatform/locales';
import { PLAN_STATUSES } from 'common/constants';

const StyledLoaderButton = genesisStyled(LoaderButton)`
  font-size: ${({ theme }) => theme.fontSizes.body1}px;
  height: ${({ theme }) => theme.sizes.three}px;
  padding: 0 ${({ theme }) => theme.sizes.oneAndHalf}px;
`;

const EnrollmentSelectionReport = ({
  selectionsReportLoading,
  selectionsReportContentId,
  handleSubmit,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl();
  const showError =
    errors[ENROLLMENT_SELECTIONS_REPORT_GROUP_ID] &&
    touched[ENROLLMENT_SELECTIONS_REPORT_GROUP_ID];
  return (
    <Box marginTop="two" width={1}>
      <SubtitleOne marginTop="three" marginBottom="half" paddingX="half">
        {formatMessage({ id: 'ENROLLMENT_SELECTION_REPORT' })}
      </SubtitleOne>
      <Flex width={1}>
        <Box width={1 / 2} padding="half">
          <Field
            as={TextField}
            name={ENROLLMENT_SELECTIONS_REPORT_GROUP_ID}
            label={formatMessage({ id: 'GROUP_ID' })}
            value={values[ENROLLMENT_SELECTIONS_REPORT_GROUP_ID]}
            required
          />
          {showError && (
            <ErrorMessage>
              {formatMessage({ id: 'REQUIRED_FORM' })}
            </ErrorMessage>
          )}
        </Box>
        <Box width={1 / 2} padding="half">
          <Label
            fontWeight="medium"
            marginBottom="quarter"
            color="onSurface.text.subdued"
          >
            {formatMessage({ id: 'PLAN_STATUS' })}
          </Label>
          <MultiSelect
            margin={0}
            isMulti={false}
            name={ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS}
            onChange={({ value }) =>
              setFieldValue(ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS, value)
            }
            selectedValue={values[ENROLLMENT_SELECTIONS_REPORT_PLAN_STATUS]}
            options={[
              {
                label: '',
                value: '',
              },
              ...Object.keys(PLAN_STATUSES).map(key => ({
                label: formatMessage({ id: key }),
                value: PLAN_STATUSES[key],
              })),
            ]}
          />
        </Box>
      </Flex>
      <Box padding="half">
        <StyledLoaderButton
          loading={selectionsReportLoading}
          primary
          onClick={handleSubmit}
        >
          {formatMessage({ id: 'GET_ENROLLMENT_SELECTION_REPORT' })}
        </StyledLoaderButton>
        {selectionsReportContentId && (
          <>
            {formatMessage({ id: 'ENROLLMENT_SELECTION_REPORT_DOWNLOAD' })}
            <DownloadLinkCSV contentId={selectionsReportContentId} />
          </>
        )}
      </Box>
    </Box>
  );
};

EnrollmentSelectionReport.propTypes = {
  selectionsReportContentId: PropTypes.string,
  selectionsReportLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    ENROLLMENT_SELECTIONS_REPORT_GROUP_ID: PropTypes.string,
  }),
  errors: PropTypes.shape({
    ENROLLMENT_SELECTIONS_REPORT_GROUP_ID: PropTypes.bool,
  }),
  touched: PropTypes.shape({
    ENROLLMENT_SELECTIONS_REPORT_GROUP_ID: PropTypes.bool,
  }),
};

EnrollmentSelectionReport.defaultProps = {
  selectionsReportContentId: null,
  selectionsReportLoading: false,
  values: {
    ENROLLMENT_SELECTIONS_REPORT_GROUP_ID: '',
  },
  errors: {
    ENROLLMENT_SELECTIONS_REPORT_GROUP_ID: false,
  },
  touched: {
    ENROLLMENT_SELECTIONS_REPORT_GROUP_ID: false,
  },
};

export default EnrollmentSelectionReport;
