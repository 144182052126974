import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@leagueplatform/genesis-commons';
import {
  TooltipModal,
  Tooltip,
  TooltipContentV2 as TooltipContent,
  LightQuestionIcon,
} from '@leagueplatform/ui-kit';

export const CoverageSummaryModalTooltip = ({ children }) => (
  <TooltipModal
    label={
      <Flex alignItems="center" justifyContent="center">
        <LightQuestionIcon />
      </Flex>
    }
    content={children}
  />
);

CoverageSummaryModalTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CoverageSummaryHoverTooltip = ({ altText, ...props }) => (
  <Tooltip>
    <LightQuestionIcon altText={altText} />
    <TooltipContent
      positionShift={{
        y: 30,
        x: -10,
      }}
      borderRadius={5}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <Box
        as="span"
        display="block"
        fontSize="body1"
        p={1}
        minWidth="250px"
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
    </TooltipContent>
  </Tooltip>
);

CoverageSummaryHoverTooltip.propTypes = {
  altText: PropTypes.string,
};
CoverageSummaryHoverTooltip.defaultProps = {
  altText: 'Tooltip',
};

export const CoverageSummaryTooltip = ({ asModal, ...props }) => {
  return asModal ? (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <CoverageSummaryModalTooltip {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <CoverageSummaryHoverTooltip {...props} />
  );
};

CoverageSummaryTooltip.propTypes = {
  asModal: PropTypes.bool,
};

CoverageSummaryTooltip.defaultProps = {
  asModal: false,
};
