import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik-legacy';
import { Button } from 'semantic-ui-react';
import { submitAddBillingDivisionForm } from './employer-divisions.actions';
import layout from './employer-divisions.layout';
import schema from './employer-divisions.schema';
import { FormattedMessage } from 'react-intl';
import {
  createFormGenerator,
  createFormValueFormatter,
  createValidator,
} from 'common/adaptive-forms';

const Submit = () => (
  <Button type="submit" value="Submit" color="violet">
    <FormattedMessage id="ADD_BILLING_DIVISION" />
  </Button>
);

const withFormikEnhancer = Formik({
  validateOnBlur: false,
  validate: (values, props) => props.validate(values),
  mapPropsToValues: props => ({
    group_id: props.groupId,
    billing_division_id: '',
    ...props.formValues,
  }),
  handleSubmit: (values, formikBag) => {
    formikBag.resetForm();
    return formikBag.props.submitAddBillingDivisionForm(values);
  },
});

const mapStateToProps = () => ({
  generateForm: createFormGenerator({
    schema,
    layout,
    customComponents: {
      submit: Submit,
    },
  }),
  formValues: createFormValueFormatter(schema)({}),
  validate: createValidator(schema),
});

const AddDivision = compose(
  connect(mapStateToProps, { submitAddBillingDivisionForm }),
  withFormikEnhancer,
)(props => props.generateForm(props));

export default AddDivision;
