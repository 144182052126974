import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setDeductionCodes calls the backend to update a deduction code
 * @param payload of type:
 *   {
 *     group_id: string;
 *     deduction_codes: [
 *       {
 *         id: string;
 *         code: string;
 *         filter: {
 *           premium_type: string;
 *           benefit_type: string;
 *           benefit_plan_name?: string;
 *           dependent_coverage_level?: string;
 *           province_of_residence?: string;
 *           province_of_employment?: string;
 *           payroll_schedule_id?: string;
 *         }
 *       }
 *     ];
 *   }
 * @returns object of type:
 *   {
 *     group_id: string;
 *     deduction_codes: [
 *       {
 *         deduction_code: {
 *           id: string;
 *           code: string;
 *           filter: {
 *             premium_type: string;
 *             benefit_type: string;
 *             benefit_plan_name?: string;
 *             dependent_coverage_level?: string;
 *             province_of_residence?: string;
 *             province_of_employment?: string;
 *             payroll_schedule_id?: string;
 *           }
 *         }
 *         error?: string;
 *       }
 *     ];
 *   }
 */
export const setDeductionCodes = payload =>
  call(websocketFetch, 'set_deduction_codes', payload);
