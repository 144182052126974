import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { vars, Input, CheckBox, Box, Text } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled from 'styled-components';
import { INFINITY } from '../qle-configuration.constants';

import { useIntl } from '@leagueplatform/locales';

const StyledInput = styled(Input)`
  height: 3rem;
  width: 368px;
  margin: 0 0 16px 0;
  border: 1px solid ${vars.colour.greyLight};
  font-size: 16px;
  border-radius: 3px;
  &&& {
    padding: 0 16px;
  }
`;

const StyledCheckBox = styled(CheckBox)`
  & label {
    cursor: pointer;
  }
`;

export const NumberOfDays = ({
  name,
  handleChange,
  value,
  disabled,
  setFieldValue,
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (value === INFINITY) {
      setChecked(true);
    }
  }, [setChecked, value]);
  const { formatMessage } = useIntl();

  const handleChecked = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (newChecked) {
      setFieldValue(name, INFINITY);
    } else {
      setFieldValue(name, '');
    }
  };
  return (
    <Box>
      <StyledInput
        name={name}
        type={value === INFINITY ? 'text' : 'number'}
        id={name}
        onChange={handleChange}
        value={
          checked || value === INFINITY
            ? formatMessage({ id: 'INFINITE_DAYS' })
            : value
        }
        disabled={checked || disabled}
        placeholder={
          checked
            ? formatMessage({ id: 'INFINITE_DAYS' })
            : formatMessage({ id: 'NUMBER_OF_DAYS_MUST_BE_GREATER_THAN_0' })
        }
      />
      <Flex align="center">
        <StyledCheckBox
          id={`${name}-checkbox`}
          name={`${name}-checkbox`}
          onChange={handleChecked}
          checked={checked}
          disabled={disabled}
        />
        <Text fontSize={2}>
          {formatMessage({ id: 'ALLOW_INFINITE_NUMBER_OF_DAYS' })}
        </Text>
      </Flex>
    </Box>
  );
};

NumberOfDays.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

NumberOfDays.defaultProps = {
  value: 0,
};
