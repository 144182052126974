/* This is old home page code! please improve to best practices as you make changes */
import { createRequestTypes } from 'common/websocket-redux';

export const FETCH_CONFIG = createRequestTypes('homeFeed/FETCH_CONFIG');
export const ON_INIT_HOMEPAGE = 'homeFeed/ON_INIT_HOMEPAGE';
export const FETCH_USER_MERCH_LIST = createRequestTypes(
  'homeFeed/FETCH_USER_MERCH_LIST',
);
export const ON_INIT_USER_MERCHLIST = 'homeFeed/ON_INIT_USER_MERCHLIST';
export const ON_INIT_USER_LIFEMARKET_MERCHLIST =
  'homeFeed/ON_INIT_USER_LIFEMARKET_MERCHLIST';

export const FETCH_USER_LIFEMARKET_MERCHLIST = createRequestTypes(
  'homeFeed/FETCH_USER_LIFEMARKET_MERCHLIST',
);
