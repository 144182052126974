import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  Box,
  HeadingThree,
  HeadingFour,
  Image,
  Flex,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import chevronDown from 'apps/public/assets/chevron-down.svg';
import { ScheduledReportsTable } from './employer-scheduled-reports-table.view';

const StyledBox = genesisStyled(Box)(
  css({
    '&:last-child': {
      marginBottom: 0,
    },
  }),
);

const TogglePanel = ({ label, children, childProps, ...props }) => {
  const [toggleChild, setToggleChild] = useState(false);

  return (
    <StyledBox
      marginBottom="one"
      borderWidth="thin"
      borderRadius="small"
      backgroundColor="surface.background.primary"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <Flex
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => setToggleChild(!toggleChild)}
      >
        <HeadingFour paddingY="oneAndHalf" paddingX="two">
          {label}
        </HeadingFour>
        <Image
          src={chevronDown}
          size={14}
          alignSelf="center"
          marginRight="two"
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...(toggleChild && { transform: 'rotate(180deg)' })}
        />
      </Flex>
      {toggleChild && (
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <Box padding="one" borderTopWidth="thin" {...childProps}>
          {children}
        </Box>
      )}
    </StyledBox>
  );
};
TogglePanel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  childProps: PropTypes.shape({}),
};
TogglePanel.defaultProps = {
  label: '',
  children: undefined,
  childProps: {},
};

export const ScheduledReportsPresenter = ({ groupId, payrollSchedules }) => {
  const { formatMessage } = useIntl();

  return (
    <Box minHeight={300}>
      <HeadingThree>{formatMessage({ id: 'SCHEDULED_REPORTS' })}</HeadingThree>
      <BodyOne marginBottom="two">
        {formatMessage({ id: 'SCHEDULED_REPORTS_COPY' })}
      </BodyOne>

      {payrollSchedules.length ? (
        payrollSchedules.map(planYear => (
          <TogglePanel
            label={formatMessage(
              { id: 'YEAR_PLAN_YEAR' },
              { year: planYear.planYear },
            )}
            childProps={{ backgroundColor: 'surface.background.secondary' }}
            key={`${planYear.planYear}_dropdown`}
          >
            {planYear.schedules.map(schedule => {
              return (
                <TogglePanel
                  key={`${schedule.id}`}
                  label={formatMessage(
                    { id: 'NAMED_PAY_CALENDAR' },
                    { name: schedule.schedule_name },
                  )}
                  childProps={{ paddingX: 'two', paddingY: 'oneAndHalf' }}
                >
                  <ScheduledReportsTable
                    schedule={schedule}
                    groupId={groupId}
                  />
                </TogglePanel>
              );
            })}
          </TogglePanel>
        ))
      ) : (
        <BodyOne>{formatMessage({ id: 'NO_PAY_CALENDARS' })} </BodyOne>
      )}
    </Box>
  );
};

ScheduledReportsPresenter.propTypes = {
  payrollSchedules: PropTypes.arrayOf(
    PropTypes.shape({
      planYear: PropTypes.string,
    }),
  ),
  groupId: PropTypes.string.isRequired,
};

ScheduledReportsPresenter.defaultProps = {
  payrollSchedules: [],
};
