/* eslint-disable no-underscore-dangle */

// Implements a mechanism to allow mobile webviews to pass authentication state to us.
// The goal of this mechanism is to avoid users needing to sign in again when the web app
// is loaded within a webview in the context of a native mobile app (where the user is already
// authenticated).

// The webview will set a cookie to let us know that we should wait for it to pass authentication
// state.
export function shouldWaitForMobileAuth() {
  return document.cookie.includes('waitForMobileAuth=true');
}

export class MobileAuthStateListener {
  #authState = null;

  #initialStateSet = Promise.resolve(false);

  setup() {
    // Don't bother setting up the callback if we are not expecting to wait for passed auth state.
    if (!shouldWaitForMobileAuth()) {
      return;
    }

    this.#initialStateSet = new Promise(resolve => {
      // Set up a callback on the window global that will allow the mobile app code to pass authentication state.
      window.__LEAGUE_AUTH__ = {
        send: state => {
          this.#authState = state;

          // Resolve the initialStateSet promise; this won't do anything if it was already resolved by a previous call
          resolve(true);
        },
      };
    });
  }

  getState() {
    return this.#authState;
  }

  async isInitialStateSet() {
    return this.#initialStateSet;
  }
}

export default new MobileAuthStateListener();
