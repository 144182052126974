import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Image } from '@leagueplatform/genesis-commons';
import CIRCLE_INFO_ICON from 'apps/public/assets/circle-info-icon.svg';
import CIRCLE_WARNING_ICON from 'apps/public/assets/circle-warning-icon.svg';

const CardFooter = ({
  children,
  backgroundColor,
  iconSrc,
  iconWidth,
  ...props
}) => (
  <Flex
    padding="threeQuarters"
    backgroundColor={backgroundColor}
    alignItems="center"
    width={1}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    <Box maxWidth={iconWidth}>
      <Image src={iconSrc} alt="" width={iconWidth} />
    </Box>
    <Box marginLeft="threeQuarters" width={1}>
      {children}
    </Box>
  </Flex>
);

CardFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  iconWidth: PropTypes.string,
};

CardFooter.defaultProps = {
  iconWidth: 'two',
};

export const CardInfoFooter = props => (
  <CardFooter
    backgroundColor="highlight.background.subdued"
    iconSrc={CIRCLE_INFO_ICON}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const CardWarningFooter = props => (
  <CardFooter
    backgroundColor="warning.background.subdued"
    iconSrc={CIRCLE_WARNING_ICON}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);
