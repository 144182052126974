import {
  ADD_BENEFIT,
  REMOVE_BENEFIT,
  REMOVE_GROUP_CATEGORIES,
  RENEW_BENEFIT,
  UPDATE_BENEFIT,
  UPDATE_GROUP_CATEGORIES,
} from './types';

export const addUpdateBenefit = (benefit, benefitClassId) => ({
  type: benefit.id ? UPDATE_BENEFIT : ADD_BENEFIT,
  payload: { benefit, benefitClassId },
});

export const removeBenefit = payload => ({
  type: REMOVE_BENEFIT,
  payload,
});

export const renewBenefit = payload => ({
  type: RENEW_BENEFIT,
  payload,
});

export const updateGroupCategories = payload => ({
  type: UPDATE_GROUP_CATEGORIES.BASE,
  payload,
});

export const removeGroupCategories = payload => ({
  type: REMOVE_GROUP_CATEGORIES.BASE,
  payload,
});
