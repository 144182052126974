import { takeLatest, fork, all, select, put, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import {
  getChatAvailability,
  getSalesforceConfig,
  createSalesforceOfflineCase,
  getUserSalesforceChatTranscripts,
  getUserSalesforceChatTranscript,
} from 'common/services';
import { selectUserId } from 'apps/auth/selectors';
import { generateChatEvent, ACTIONS } from './chat-page.analytics';
import {
  ON_INIT_CHAT,
  ON_INIT_CHAT_HISTORY,
  ON_INIT_CHAT_HISTORY_DETAILS,
  ON_GET_CHAT_TRANSCRIPTS,
  RESET_CHAT_TRANSCRIPTS,
  GET_CHAT_AVAILABILITY,
  CREATE_SALESFORCE_OFFLINE_CASE,
  GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS,
  GET_SALESFORCE_CHAT_TRANSCRIPTS,
  GET_SALESFORCE_CHAT_TRANSCRIPT,
  GET_CHAT_AGENTS,
} from './chat-page.action-types';
import { LIMITS } from './constants';
import { closeModal } from './chat-page.action-creators';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* chatInit() {
  yield request(GET_CHAT_AVAILABILITY, getChatAvailability());
  yield request(GET_CHAT_AGENTS, getSalesforceConfig());
}

export function* chatHistoryInit() {
  yield put({ type: RESET_CHAT_TRANSCRIPTS });
  yield request(
    GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS,
    getUserSalesforceChatTranscripts({ limit: LIMITS.CHAT_TRANSCRIPTS }),
  );
}

export function* chatHistoryDetailsInit({ payload: { chatId } }) {
  yield request(
    GET_SALESFORCE_CHAT_TRANSCRIPT,
    getUserSalesforceChatTranscript({
      chatId,
    }),
  );
}

export function* getChatTranscripts({ payload: { offset } }) {
  yield request(
    GET_SALESFORCE_CHAT_TRANSCRIPTS,
    getUserSalesforceChatTranscripts({
      limit: LIMITS.CHAT_TRANSCRIPTS,
      offset,
    }),
  );
}

export function* createOfflineCase({ payload: { subject, agentType } }) {
  const userId = yield select(selectUserId);
  yield request(
    CREATE_SALESFORCE_OFFLINE_CASE,
    createSalesforceOfflineCase({
      userId,
      subject,
      agentType,
    }),
  );
  yield call(
    sendAnalyticsEvent,
    generateChatEvent(agentType, ACTIONS.SEND_OFFLINE_MESSAGE),
  );
}

export function* createOfflineCaseSucceeded() {
  yield put(closeModal());
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'CHAT_CREATE_OFFLINE_CASE_SUCCESS',
    contentId: 'CHAT_CREATE_OFFLINE_CASE_SUCCESS_BODY',
  });
}

export function* chatSagas() {
  yield fork(all, [
    takeLatest(ON_INIT_CHAT, chatInit),
    takeLatest(ON_INIT_CHAT_HISTORY, chatHistoryInit),
    takeLatest(ON_INIT_CHAT_HISTORY_DETAILS, chatHistoryDetailsInit),
    takeLatest(ON_GET_CHAT_TRANSCRIPTS, getChatTranscripts),
    takeLatest(CREATE_SALESFORCE_OFFLINE_CASE.BASE, createOfflineCase),
    takeLatest(
      CREATE_SALESFORCE_OFFLINE_CASE.SUCCEEDED,
      createOfflineCaseSucceeded,
    ),
  ]);
}
