import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spaceInPx, Heading } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { withRouter, Link } from 'react-router-dom';
import { EmptyState } from 'apps/employer-experience/ui-kit/empty-state.view';
import { DependentList } from './dependent-list.view';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/genesis-commons';

const Title = styled(Heading)`
  font-weight: bold;
  margin-bottom: 0;
`;

const TitleWrapper = styled(Flex)`
  margin-bottom: ${spaceInPx(2)};
`;

export const EmployeeDependentsView = withRouter(
  ({ activeDependents, inactiveDependents, showAdd, match }) => {
    const { formatMessage } = useIntl();

    return (
      <Box overflowY="auto">
        <TitleWrapper justify="space-between" align="center">
          <Title level={2}>{formatMessage({ id: 'DEPENDENTS' })}</Title>
          {showAdd && (
            <Link to={`${match.url}/add`}>
              <Button type="button">
                {formatMessage({ id: 'ADD_DEPENDENT' })}
              </Button>
            </Link>
          )}
        </TitleWrapper>
        {activeDependents.length > 0 && (
          <DependentList
            title={formatMessage({ id: 'EMPLOYEE_ACTIVE_DEPENDENTS' })}
            dependentList={activeDependents}
            isActive
          />
        )}
        {inactiveDependents.length > 0 && (
          <DependentList
            title={formatMessage({ id: 'EMPLOYEE_INACTIVE_DEPENDENTS' })}
            dependentList={inactiveDependents}
          />
        )}
        {!inactiveDependents.length && !activeDependents.length && (
          <EmptyState p={3}>
            {formatMessage({ id: 'EMPLOYEE_NO_DEPENDENTS' })}
          </EmptyState>
        )}
      </Box>
    );
  },
);

EmployeeDependentsView.propTypes = {
  activeDependents: PropTypes.arrayOf(
    PropTypes.shape({
      dependent_id: PropTypes.string,
      relationship_type: PropTypes.string,
    }),
  ).isRequired,
  inactiveDependents: PropTypes.arrayOf(
    PropTypes.shape({
      dependent_id: PropTypes.string,
      relationship_type: PropTypes.string,
    }),
  ).isRequired,
  showAdd: PropTypes.bool,
};

EmployeeDependentsView.defaultProps = {
  showAdd: false,
};
