import { connect } from 'react-redux';
import {
  selectEmployerBenefitsDropdown,
  selectSelectedBenefitPrice,
  selectBenefitYearOptions,
} from '../../selectors';
import { selectGroupId } from '../../../selectors';
import {
  submitBenefitPriceConfig,
  errorParsingJson,
} from '../pricing-configuration.actions';
import { CopyPricingConfig as CopyPricingConfigModal } from './copy-pricing-config.view';

export const CopyPricingConfig = connect(
  state => {
    return {
      benefits: selectEmployerBenefitsDropdown(state),
      initialJson: selectSelectedBenefitPrice(state),
      groupId: selectGroupId(state),
      benefitYearOptions: selectBenefitYearOptions(state),
    };
  },
  { submitBenefitPriceConfig, showError: errorParsingJson },
)(CopyPricingConfigModal);
