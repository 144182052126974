import { createSelector } from 'reselect';
import {
  selectEmployerDetails,
  selectEmployer,
  selectEmployerId,
} from '../selectors';
import { get } from 'lodash/fp';
import { omit, filter, find, isString } from 'lodash';
import {
  createValidator,
  createFormGenerator,
  createFormValueFormatter,
  Date,
} from 'common/adaptive-forms';
import { getActiveEmployerGroup } from '@leagueplatform/web-common';
import BenefitClassIDSelect from './components/BenefitClassIDSelect';
import { EMPLOYEE_STATE_MAP } from 'common/constants';
import { selectEmployerPayroll } from '../Payroll/employer-payroll.selectors';

export const selectGroupId = createSelector(selectEmployer, get('groupId'));

export const selectEmployeeEditId = createSelector(
  selectEmployerDetails,
  get('employeeEditId'),
);

export const selectEmployeeSaveError = createSelector(
  selectEmployerDetails,
  get('employeeSaveError'),
);

export const selectEmployeeGetError = createSelector(
  selectEmployerDetails,
  get('employeeGetError'),
);

export const selectEmployeeSchema = createSelector(
  selectEmployerDetails,
  get('employeeFormFieldsSchema'),
);

export const selectCreateEmployeeLoading = createSelector(
  selectEmployerDetails,
  get('createEmployeeLoading'),
);

export const selectNewEmployeeReady = createSelector(
  selectEmployeeSchema,
  schema => Boolean(schema),
);

export const selectEmployeeProfileReady = createSelector(
  selectEmployerDetails,
  get('employeeProfileReady'),
);

export const selectEmployeeProfile = createSelector(
  selectEmployerDetails,
  get('employeeEditUser'),
);

export const selectEmployeeInvitationUrl = createSelector(
  selectEmployeeProfile,
  profile =>
    profile
      ? `${window.location.protocol}//${window.location.host}/start/${profile.invitation_code}`
      : null,
);

export const selectEmployeeName = createSelector(
  selectEmployeeProfile,
  employee => (employee ? `${employee.first_name} ${employee.last_name}` : ''),
);

export const selectEmployeeGroupRole = createSelector(
  [
    get('apps.employer-experience.employeeProfile.data.groups'),
    selectEmployerId,
  ],
  (groups, groupId) =>
    groups && groups.length > 0 && groupId
      ? groups.find(group => group.group_id === groupId).role
      : null,
);

export const selectEmployeeInviteURL = createSelector(
  selectEmployerDetails,
  get('employeeInviteURL'),
);

export const selectActiveGroup = createSelector(
  get('apps.employer-experience.employeeProfile.data.groups'),
  getActiveEmployerGroup,
);

const selectGroupById = createSelector(
  (_, props) => props?.groupId,
  get('apps.employer-experience.employeeProfile.data.groups'),
  (groupId, groups) => groups?.find(group => group.group_id === groupId),
);

export const selectDependents = createSelector(selectGroupById, activeGroup =>
  (get('dependents', activeGroup) || []).map(dependent => ({
    ...dependent,
    pendingSuspension:
      !dependent.suspended &&
      isString(dependent.remove_qualifying_life_event_date),
  })),
);

export const selectGroupInvitationId = createSelector(
  selectActiveGroup,
  get('invitation_id'),
);

export const selectActiveDependents = createSelector(
  selectDependents,
  dependents => filter(dependents, dependent => !dependent.suspended),
);

export const selectInactiveDependents = createSelector(
  selectDependents,
  dependents => filter(dependents, dependent => dependent.suspended),
);

export const selectDependentById = createSelector(
  (_, props) => props?.dependentId,
  selectDependents,
  (dependentId, dependents) => {
    const dependent = find(dependents, { dependent_id: dependentId });
    if (dependent !== undefined) {
      return {
        ...dependent,
      };
    }
    return {};
  },
);

export const selectEmployeeStatus = createSelector(
  selectEmployeeProfile,
  profile => profile && EMPLOYEE_STATE_MAP[profile.state],
);

export const selectLayout = get('layouts.viewEditEmployee');

export const selectUserStatus = get(
  'apps.employer-experience.pages.employerDetails.employeeStatus',
);

export const selectValidator = createSelector(
  selectEmployeeSchema,
  createValidator,
);

export const selectFormGenerator = createSelector(
  [selectEmployeeSchema, selectLayout],
  (employeeSchema, layout) => {
    let schema = { ...employeeSchema };
    schema = omit(schema, 'user_id');
    return createFormGenerator({
      schema,
      layout,
      customComponents: {
        benefit_class_id: BenefitClassIDSelect,
        benefits_start_date: Date,
      },
    });
  },
);

export const selectEditFormLayoutGenerator = createSelector(
  [selectEmployeeSchema, selectLayout, selectUserStatus],
  (schema, layout, status) => {
    const newSchema = { ...schema };
    if (status === 'initial') {
      newSchema.email.read_only = true;
    }
    return createFormGenerator({
      schema,
      layout,
      customComponents: {
        benefit_class_id: BenefitClassIDSelect,
        benefits_start_date: Date,
      },
    });
  },
);

export const selectFormValueFormatter = createSelector(
  selectEmployeeSchema,
  createFormValueFormatter,
);

export const selectEmployeeFormValues = createSelector(
  [selectFormValueFormatter, selectEmployeeProfile],
  (format, formData) => format(formData),
);

export const selectEmployerName = createSelector(
  selectEmployerDetails,
  get('employer.group.name'),
);

export const selectUserDeductions = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.payrollDeductions?.user_deductions,
);

export const selectUserAdjustments = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.payrollAdjustments?.user_adjustments,
);
