import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const GET_USER_CERTIFICATION_MESSAGE_TYPE = 'get_user_certifications';

export const getUserCertifications = async (userId: string) =>
  SocketAsFetch.fetch({
    message_type: GET_USER_CERTIFICATION_MESSAGE_TYPE,
    info: {
      user_id: userId,
    },
  });
