import { BENEFIT_TYPES } from './benefit-set-types';
import { EnrollmentStep } from 'common/types/enrollment-types';
/*
  id: // based on BENEFIT_TYPES of the benefit sets
  name: // left navigation mobile width
  shortName: // left navigation full width
*/

export const enrollmentSteps: Array<EnrollmentStep> = [
  {
    id: BENEFIT_TYPES.drug,
    name: 'DRUG_INSURANCE',
    shortName: 'DRUG',
  },
  {
    id: BENEFIT_TYPES.medical,
    name: 'MEDICAL_INSURANCE',
    shortName: 'MEDICAL',
  },
  {
    id: BENEFIT_TYPES.dental,
    name: 'DENTAL_INSURANCE',
    shortName: 'DENTAL',
  },
  {
    id: BENEFIT_TYPES.vision,
    name: 'VISION_INSURANCE',
    shortName: 'VISION',
  },
  {
    id: BENEFIT_TYPES.life_and_add,
    name: 'LIFE_AND_ADD_INSURANCE',
    shortName: 'LIFE_AND_ADD',
  },
  {
    id: BENEFIT_TYPES.life,
    name: 'LIFE_INSURANCE',
    shortName: 'LIFE',
  },
  {
    id: BENEFIT_TYPES.accident,
    name: 'ACCIDENT_INSURANCE',
    shortName: 'ACCIDENT',
  },
  {
    id: BENEFIT_TYPES.accidental_death_dismemberment,
    name: 'ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE',
    shortName: 'AD_AND_D',
  },
  {
    id: BENEFIT_TYPES.critical_illness,
    name: 'CRITICAL_ILLNESS',
    shortName: 'CRITICAL_ILLNESS',
  },
  {
    id: BENEFIT_TYPES.hospital_indemnity,
    name: 'HOSPITAL_INDEMNITY',
    shortName: 'HOSPITAL_INDEMNITY',
  },
  {
    id: BENEFIT_TYPES.std,
    name: 'SHORT_TERM_DISABILITY',
    shortName: 'STD',
  },
  {
    id: BENEFIT_TYPES.ltd,
    name: 'LONG_TERM_DISABILITY',
    shortName: 'LTD',
  },
  {
    id: BENEFIT_TYPES.partner_liability,
    name: 'GROUP_UMBRELLA_LIABILITY_INSURANCE',
    shortName: 'GROUP_UMBRELLA_LIABILITY',
  },
  {
    id: BENEFIT_TYPES.spending_accounts,
    name: 'WELLNESS_ACCOUNTS',
    shortName: 'WELLNESS_ACCOUNTS',
  },
  {
    id: BENEFIT_TYPES.contributions,
    name: 'CONTRIBUTIONS',
    shortName: 'CONTRIBUTIONS',
  },
];

export const getStepMetadata = (step: string): EnrollmentStep | undefined =>
  enrollmentSteps.find(enrollmentStep => enrollmentStep.id === step);

export const getStepIndex = (step: string): number =>
  enrollmentSteps.findIndex(enrollmentStep => enrollmentStep.id === step);

export const isEnrollmentStep = (step: string): boolean =>
  getStepIndex(step) > -1;
