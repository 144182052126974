import camelize from 'camelize';
import { combineReducers } from 'redux';
import { STATUS } from '../utils';
import { GET_USER_PAYMENT_CREDENTIALS } from './payment-credentials.actions';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const data = (state = null, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_USER_PAYMENT_CREDENTIALS.SUCCEEDED:
      return camelize(action.payload.info?.credentials);
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const status = (state = STATUS.IDLE, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_USER_PAYMENT_CREDENTIALS.STARTED:
      return STATUS.LOADING;
    case GET_USER_PAYMENT_CREDENTIALS.SUCCEEDED:
      return STATUS.SUCCESS;
    case GET_USER_PAYMENT_CREDENTIALS.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

export const paymentCredentials = combineReducers({
  data,
  status,
});
