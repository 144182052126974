import React from 'react';
import PropTypes from 'prop-types';
import { BodyOne, Flex, SubtitleOne } from '@leagueplatform/genesis-commons';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { Logo } from 'common/components/Logos/Logo';
import {
  AuthBackgroundImage,
  AuthWrapper,
  AuthErrorWrapper,
  AuthTopSection,
  AuthTopSectionWrapper,
  AuthSignInLink,
  AuthBottomSection,
} from 'apps/auth/auth0/auth.parts';
import { FormattedMessage } from 'react-intl';

export const InviteView = ({ ready, error, ...props }) => {
  if (!ready) {
    return <LoadingIndicator />;
  }
  return (
    <Flex>
      <AuthWrapper>
        <AuthTopSectionWrapper>
          <Logo />
          {error ? (
            <AuthErrorWrapper>
              <SubtitleOne as="h1">
                <FormattedMessage id="INVITE_ERROR_SOMETHING_WENT_WRONG" />
              </SubtitleOne>
              <BodyOne marginTop="half">
                <FormattedMessage id="GET_STARTED_ERROR" />
              </BodyOne>
            </AuthErrorWrapper>
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            <AuthTopSection {...props} />
          )}
          <AuthSignInLink />
        </AuthTopSectionWrapper>
        <AuthBottomSection />
      </AuthWrapper>
      <AuthBackgroundImage />
    </Flex>
  );
};

InviteView.propTypes = {
  ready: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};
