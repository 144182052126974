import React from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableRubric,
} from 'react-beautiful-dnd';
import { BodyOne, BodyTwoSecondary } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  DraggableBox,
  DroppableBox,
} from '../enrollment-experience-config.styles';
import {
  DraggableBenefitSet,
  BenefitSetItem,
} from '../draggable-benefit-set/draggable-benefit-set.view';
import {
  BenefitSetGroup,
  EnrollmentStepItemType,
} from '../enrollment-design-options.types';

import { createDragDropId } from '../utils';
import {
  DROPPABLE_TYPES,
  BENEFIT_SET_TYPE,
} from '../enrollment-experience-config.constants';

interface DraggableBenefitSetGroupProps {
  group: BenefitSetGroup;
  index: number;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  activeEditingItemId?: string;
  openEditingPanel: (item: EnrollmentStepItemType) => void;
}

export const DraggableBenefitSetGroup = ({
  group,
  index,
  openEditingPanel,
  activeEditingItemId,
}: DraggableBenefitSetGroupProps) => {
  const { formatMessage } = useIntl();

  const onBenefitSetGroupClick = (e: Event) => {
    e.stopPropagation();
    openEditingPanel(group);
  };

  return (
    <Draggable
      key={group.id}
      draggableId={createDragDropId(group.id, DROPPABLE_TYPES.GROUP)}
      index={index}
    >
      {(
        dragProvided: DraggableProvided,
        dragSnapshot: DraggableStateSnapshot,
      ) => (
        <DraggableBox
          isDragging={dragSnapshot.isDragging}
          isGroup
          isActive={activeEditingItemId === group.id}
          innerRef={dragProvided.innerRef}
          backgroundColor="surface.background.secondary"
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...dragProvided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...dragProvided.dragHandleProps}
          onClick={onBenefitSetGroupClick}
        >
          <BodyOne data-testid="set-title">
            {group.name}{' '}
            <BodyTwoSecondary as="span">
              {formatMessage({ id: 'ENROLLMENT_DESIGN_GROUP' })}
            </BodyTwoSecondary>
          </BodyOne>
          <Droppable
            type={BENEFIT_SET_TYPE}
            droppableId={createDragDropId(group.id, DROPPABLE_TYPES.GROUP)}
            renderClone={(
              provided: DraggableProvided,
              snapshot: DraggableStateSnapshot,
              rubic: DraggableRubric,
            ) => {
              const set = group.sets[rubic.source.index];
              return (
                <BenefitSetItem
                  openEditingPanel={openEditingPanel}
                  set={set}
                  provided={provided}
                  snapshot={snapshot}
                  index={index}
                />
              );
            }}
          >
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot,
            ) => (
              <DroppableBox
                isDraggingOver={dropSnapshot.isDraggingOver}
                innerRef={dropProvided.innerRef}
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...dropProvided.droppableProps}
              >
                {group.sets?.map((set: any, indexChild: number) => (
                  <DraggableBenefitSet
                    activeEditingItemId={activeEditingItemId}
                    openEditingPanel={openEditingPanel}
                    key={set.id}
                    set={set}
                    isInsideGroup
                    index={indexChild}
                  />
                ))}
                {dropProvided.placeholder}
              </DroppableBox>
            )}
          </Droppable>
        </DraggableBox>
      )}
    </Draggable>
  );
};

export default DraggableBenefitSetGroup;
