import { createSelector } from 'reselect';
import { selectEmployerPayroll } from '../../employer-payroll.selectors';
import { STATUS } from './deduction-codes.reducer';

export const selectDeductionCodes = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll.deductionCodeEditor.deductionCodes ?? [],
);

export const selectPayrollSchedules = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll.deductionCodeEditor.payrollSchedules ?? [],
);

export const selectStatus = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll.deductionCodeEditor.status ?? STATUS.IDLE,
);
