import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import {
  FRONTEND_CLAIM_STATUS_TYPES,
  CLAIM_STATUS_TRANSLATIONS,
  VIEW_ALL,
} from 'common/constants';
import { ClaimsPage } from './claim-history.component';
import {
  selectClaims,
  selectReady,
  selectStatusFilter,
  selectNeedsMoreInfo,
} from './state/claims.selectors';
import {
  claimSelected,
  optionsChanged,
  visited,
  exited,
} from './state/claims.action-creators';

/**
 * Depending on a claim's state, it may contain an approved amount or a claimed
 * amount. Since we display a generic "Amount" field in the claims list, this
 * function figures out what to show.
 * @param {object} claim - a back-end claim object
 * @return {object} { value: Number, currency: String }
 */
export const getAmountProps = claim => {
  const amount = claim.amount_approved || claim.amount_claimed || {};
  return {
    amount: amount.value,
    currency: amount.currency,
  };
};

export const getDocumentsProp = claim =>
  Array.isArray(claim.claim_documents) &&
  claim.claim_documents.map(document => ({
    contentId: document.content_id,
    contentType: document.content_type,
  }));

/**
 * Format a back-end claim object for presentation.
 * @param {object} claim object from get_claims
 * @return {object} simplified claim object for presentation
 */
export const getClaimProps = claim => ({
  id: claim.claim_id,
  reference: claim.reference_id,
  status: claim.status,
  vendor: claim.vendor,
  lastUpdateTime: claim.last_event_timestamp,
  documents: getDocumentsProp(claim),
  ...getAmountProps(claim),
});

export const ClaimHistoryContainer = compose(
  injectIntl,
  connect(
    createStructuredSelector({
      userId: get('authentication.userId'),
      ready: selectReady,
    }),
    { visited, exited },
  ),
  lifecycle({
    componentDidMount() {
      this.props.visited({ userId: this.props.userId });
    },
    componentWillUnmount() {
      this.props.exited();
    },
  }),
  connect(
    createStructuredSelector({
      claims: selectClaims,
      statusFilter: selectStatusFilter,
      needsMoreInfo: selectNeedsMoreInfo,
    }),
    {
      onSelectClaim: claimSelected,
      onChangeStatus: status =>
        optionsChanged(status === VIEW_ALL ? {} : { statuses: [status] }),
    },
  ),
  withProps(({ claims, intl }) => ({
    claims: claims.map(getClaimProps),
    statusOptions: [
      {
        value: VIEW_ALL,
        label: intl.formatMessage({ id: 'VIEW_ALL' }),
      },
      ...FRONTEND_CLAIM_STATUS_TYPES.map(status => ({
        value: status,
        label: intl.formatMessage({ id: CLAIM_STATUS_TRANSLATIONS[status] }),
      })),
    ],
  })),
)(ClaimsPage);
