import React from 'react';
import {
  colour,
  Box,
  Backdrop,
  ConfirmationModal,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Button } from 'apps/employer-experience/ui-kit';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import WalletActionCard from './wallet-action-card.view';
import { get } from 'lodash';

const EmptyState = styled(Flex)`
  min-height: 37.5rem;
  background-color: ${colour('greyLightest')};
  justify-content: center;
  align-items: center;
  text-align: center;

  &.small {
    min-height: 15rem;
    margin-top: 1.3rem;
  }
`;

const WalletActionHeader = styled(Box)`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 500;
`;

const WalletActionsContainer = styled(Box)`
  padding: 0 1rem;
  ${WalletActionHeader}:first-child {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

const AddActionBtn = styled(Button)`
  margin-top: 1.3rem;
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.4rem;
`;

const WalletActions = ({
  providerBenefit,
  primaryAction,
  moreActions,
  onResetActionCard,
  onSaveActionCard,
  onRemoveActionCard,
  addMoreAction,
  openModal,
  toggleModal,
  setActionToBeRemoved,
}) => {
  return providerBenefit ? (
    <WalletActionsContainer>
      <WalletActionHeader>
        <FormattedMessage
          id="BENEFIT_NAME_WALLET_ACTIONS_HEADER"
          values={{
            benefitName: providerBenefit.full_name,
            vendorName: providerBenefit.vendor_name,
          }}
        />
      </WalletActionHeader>
      <FormattedMessage id="BENEFIT_NAME_WALLET_ACTIONS_SUBHEADER" />

      <WalletActionHeader>
        <FormattedMessage id="PRIMARY_ACTION" />
      </WalletActionHeader>
      <WalletActionCard
        action={primaryAction}
        onSave={action => onSaveActionCard(action)}
        isPrimaryAction
        canPrimaryBeRemoved={!!primaryAction && moreActions.length === 0}
        onRemove={elem => {
          toggleModal();
          setActionToBeRemoved(elem);
        }}
      />
      <WalletActionHeader>
        <FormattedMessage id="MORE_ACTIONS" />
      </WalletActionHeader>
      {primaryAction ? (
        <Box>
          {moreActions.map(action => (
            <WalletActionCard
              action={action}
              key={`${action.type}-${action.index}`}
              locked={get(action, 'locked', true)}
              isPrimaryAction={false}
              onReset={elem => onResetActionCard(elem)}
              onSave={elem => onSaveActionCard(elem)}
              onRemove={elem => {
                toggleModal();
                setActionToBeRemoved(elem);
              }}
            />
          ))}
          <AddActionBtn transparent onClick={addMoreAction}>
            <FormattedMessage id="ADD_MORE_ACTION" />
          </AddActionBtn>
        </Box>
      ) : (
        <EmptyState className="small">
          <FormattedMessage id="CANT_ADD_SECONDARY_ACTIONS" />
        </EmptyState>
      )}
      {openModal && (
        <Backdrop>
          <ConfirmationModal
            headerText={
              <FormattedMessage id="REMOVE_WALLET_ACTIONS_MODAL_HEADER" />
            }
            footerContent={
              <Flex>
                <Button
                  confirmModal
                  cancel
                  onClick={() => {
                    toggleModal();
                    setActionToBeRemoved(null);
                  }}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                  confirmModal
                  primary
                  onClick={() => {
                    toggleModal();
                    onRemoveActionCard();
                  }}
                >
                  <FormattedMessage id="CONFIRM" />
                </Button>
              </Flex>
            }
          >
            <FormattedMessage id="REMOVE_WALLET_ACTIONS_MODAL_BODY" />
          </ConfirmationModal>
        </Backdrop>
      )}
    </WalletActionsContainer>
  ) : (
    <EmptyState>
      <FormattedMessage id="NO_SELECTED_BENEFIT" />
    </EmptyState>
  );
};

export default WalletActions;

WalletActions.propTypes = {
  primaryAction: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    primary: PropTypes.bool,
  }),
  moreActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      primary: PropTypes.bool,
      locked: PropTypes.bool,
    }),
  ),
  onResetActionCard: PropTypes.func.isRequired,
  onSaveActionCard: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onRemoveActionCard: PropTypes.func.isRequired,
  addMoreAction: PropTypes.func.isRequired,
  setActionToBeRemoved: PropTypes.func.isRequired,
  providerBenefit: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

WalletActions.defaultProps = {
  providerBenefit: null,
  primaryAction: null,
  moreActions: [],
};
