import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Submit member QLE info and receive a response dictating start re-enrollment or
 * pending HR approval.
 *
 * On error, returns error object containing reason.
 *
 * NOTE: Currently schema is hardcoded due to frontend being ahead of backend.
 *
 * @param {string} qualifyingLifeEventType: constant received from backend when fetching QLE schema.
 * Note: Currently schema is hard-coded for MVP. Current values are only 'marriage' and 'new_born_baby'
 * @param {object} questionnaireAnswers: Object containing key value pairs. Key are unique to each
 * question asked in QLE and extracted from QLE schema. Value is the data user has input.
 */
export const setQleRequest = ({
  qualifyingLifeEventType,
  questionnaireAnswers,
  groupId,
}) =>
  call(websocketFetch, 'set_qle_request', {
    qualifying_life_event_type: qualifyingLifeEventType,
    qualifying_life_event_date: questionnaireAnswers.qualifying_life_event_date,
    qualifying_life_event_questionnaire: questionnaireAnswers,
    group_id: groupId,
  });
