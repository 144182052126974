import { call, takeLatest, fork, select } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { callWithDelay } from 'common/utilities/call-with-delay';
import { selectGroupId } from '../selectors';
import {
  selectOffset,
  selectQuery,
  selectIncludeSuspended,
  selectAdminRole,
} from './selectors';
import { ITEMS_PER_INFINITE_SCROLL } from './constants';
import {
  GET_USERS_IN_GROUP,
  GET_TERMINATION_CODES,
  SEARCH_PARAMS_CHANGED,
  NEXT_SEARCH_RESULTS_PAGE_REQUESTED,
  TERMINATE_EMPLOYEES,
  SUSPEND_EMPLOYEES,
  REINSTATE_EMPLOYEES,
  DO_TERMINATE_EMPLOYEES,
  DO_SUSPEND_EMPLOYEES,
  DO_REINSTATE_EMPLOYEES,
  EMPLOYEES_VISITED,
  UNSCHEDULE_EMPLOYEES_SUSPENSION,
  UNSCHEDULE_EMPLOYEES_TERMINATION,
  UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  DO_UNSCHEDULE_EMPLOYEES_SUSPENSION,
  DO_UNSCHEDULE_EMPLOYEES_TERMINATION,
  DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  SET_USER_ROLE_IN_GROUP,
  DO_SET_USER_ROLE_IN_GROUP,
} from './types';
import { DO_TOP_UP_USER_BENEFIT } from '../../add-funds/add-funds.types';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { getTerminationCodes } from 'apps/employer-experience-v2/employee-search/search.saga';

export function* getUsersInGroup(payload, offset) {
  const groupId = yield select(selectGroupId);
  const info = {
    group_id: groupId,
    query: payload.query,
    offset,
    limit: ITEMS_PER_INFINITE_SCROLL,
    include_suspended: payload.includeSuspended,
    roles: payload.adminRoleFilter ? ['admin'] : null,
  };

  return yield request(
    GET_USERS_IN_GROUP,
    [websocketFetch, 'get_users_in_group', info],
    info,
  );
}

export function* newQuery(action) {
  yield call(getUsersInGroup, action.payload, 0);
}

export function* getNextPage() {
  const query = yield select(selectQuery);
  const includeSuspended = yield select(selectIncludeSuspended);
  const adminRoleFilter = yield select(selectAdminRole);
  const offset = yield select(selectOffset);
  const nextOffset = offset + ITEMS_PER_INFINITE_SCROLL;

  const payload = {
    query,
    includeSuspended,
    adminRoleFilter,
  };

  yield call(getUsersInGroup, payload, nextOffset);
}

export function* watchSearchParamChanges() {
  yield takeLatest(SEARCH_PARAMS_CHANGED, callWithDelay, newQuery);
}

export function* watchScrollingPageRequests() {
  yield takeLatest(NEXT_SEARCH_RESULTS_PAGE_REQUESTED, getNextPage);
}

export function* terminateEmployees({ payload }) {
  const { groupId, userIds, effectiveDate, reason, noticeDate, eobDate } =
    payload;
  yield request(DO_TERMINATE_EMPLOYEES, [
    websocketFetch,
    'terminate_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
      termination_reason: reason,
      suspended_notice_date: noticeDate,
      suspended_benefits_extension_date: eobDate,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* suspendEmployees({ payload }) {
  const { groupId, userIds, effectiveDate } = payload;
  yield request(DO_SUSPEND_EMPLOYEES, [
    websocketFetch,
    'suspend_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* reinstateEmployees({ payload }) {
  const { groupId, userIds, effectiveDate } = payload;
  yield request(DO_REINSTATE_EMPLOYEES, [
    websocketFetch,
    'reinstate_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* unscheduleEmployeesSuspension({ payload }) {
  const { groupId, userIds } = payload;
  yield request(DO_UNSCHEDULE_EMPLOYEES_SUSPENSION, [
    websocketFetch,
    'cancel_suspend_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* unscheduleEmployeesTermination({ payload }) {
  const { groupId, userIds } = payload;
  yield request(DO_UNSCHEDULE_EMPLOYEES_TERMINATION, [
    websocketFetch,
    'cancel_terminate_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* unscheduleEmployeesReinstatement({ payload }) {
  const { groupId, userIds } = payload;
  yield request(DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT, [
    websocketFetch,
    'cancel_reinstate_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* setUserRoleInGroup({ payload }) {
  const { groupId, userId, role } = payload;
  yield request(DO_SET_USER_ROLE_IN_GROUP, [
    websocketFetch,
    'set_user_role_in_group',
    {
      group_id: groupId,
      user_id: userId,
      role,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* refreshEmployeesList() {
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* initialize() {
  yield call(getUsersInGroup, '', 0);
  yield fork(watchSearchParamChanges);
  yield fork(watchScrollingPageRequests);

  yield takeLatest(TERMINATE_EMPLOYEES, terminateEmployees);
  yield takeLatest(GET_TERMINATION_CODES, getTerminationCodes);
  yield takeLatest(SUSPEND_EMPLOYEES, suspendEmployees);
  yield takeLatest(REINSTATE_EMPLOYEES, reinstateEmployees);
  yield takeLatest(
    UNSCHEDULE_EMPLOYEES_SUSPENSION,
    unscheduleEmployeesSuspension,
  );
  yield takeLatest(
    UNSCHEDULE_EMPLOYEES_TERMINATION,
    unscheduleEmployeesTermination,
  );
  yield takeLatest(
    UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
    unscheduleEmployeesReinstatement,
  );
  yield takeLatest(SET_USER_ROLE_IN_GROUP, setUserRoleInGroup);

  yield takeLatest(DO_TOP_UP_USER_BENEFIT.SUCCEEDED, refreshEmployeesList);
}

export function* employeesSaga() {
  yield takeLatest(EMPLOYEES_VISITED, initialize);
}
