import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  HeadingThree,
  SubtitleOne,
  SubtitleTwo,
  BodyOne,
  BodyTwo,
  QuietButton,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { FontAwesomeIcon, Backdrop, ClickEater } from '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import faFile from '@fortawesome/fontawesome-free-solid/faFile';

// eslint-disable-next-line react/jsx-props-no-spreading
const FloatingIcon = genesisStyled(props => <FontAwesomeIcon {...props} />)`
  float: right;
`;

export const ChangeReportModal = ({
  onClose,
  getChangeReport,
  loading,
  reportId,
  internalReportId,
  dateCreated,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const payload = { previous_report_id: internalReportId };
  return (
    <Backdrop>
      <ClickEater>
        <Flex
          maxWidth="560px"
          borderRadius={[0, 'large']}
          backgroundColor="surface.background.primary"
          maxHeight={['100%', '98%']}
          minHeight={['100%', 'auto']}
          flexDirection="column"
          width={1}
        >
          <Box
            borderTopLeftRadius="small"
            borderTopRightRadius="small"
            borderBottomWidth="thin"
            borderBottomStyle="solid"
            borderBottomColor="onSurface.border.default"
          >
            <HeadingThree paddingX="two" paddingY="one">
              {formatMessage({ id: 'CREATE_CHANGE_REPORT' })}
            </HeadingThree>
          </Box>
          <Box
            flexGrow={1}
            flexShrink={1}
            paddingX="two"
            paddingTop="one"
            paddingBottom="half"
          >
            <BodyOne paddingBottom="one" color="onSurface.text.subdued">
              {formatMessage({ id: 'REQUEST_CHANGE_REPORT_EXPLANATION' })}
            </BodyOne>
            <BodyTwo paddingBottom="one" color="onSurface.text.subdued">
              {formatMessage({ id: 'REQUEST_CHANGE_REPORT_NOTE' })}
            </BodyTwo>
            <SubtitleOne paddingBottom="half">
              {formatMessage({ id: 'SELECTED_REPORT' })}
            </SubtitleOne>
            <Box backgroundColor="surface.background.secondary" padding="one">
              <FloatingIcon icon={faFile} float="right" />
              <Box paddingBottom="half">
                <SubtitleTwo>{formatMessage({ id: 'REPORT_ID' })}</SubtitleTwo>
                <BodyOne>{reportId}</BodyOne>
              </Box>
              <Box paddingTop="half">
                <SubtitleTwo>{formatMessage({ id: 'DATE' })}</SubtitleTwo>
                <BodyOne>
                  {formatDate(new Date(dateCreated), {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    timeZoneName: 'short',
                    timeZone: 'UTC',
                  })}
                </BodyOne>
              </Box>
            </Box>
          </Box>
          <Flex
            justifyContent="flex-end"
            paddingLeft="two"
            paddingRight="two"
            paddingTop="one"
            paddingBottom="two"
            flexWrap={['wrap', 'auto']}
          >
            <QuietButton
              marginRight={[0, 'one']}
              marginBottom={['half', 0]}
              onClick={onClose}
              width={[1, 'auto']}
            >
              {formatMessage({ id: 'CANCEL' })}
            </QuietButton>
            <LoaderButton
              primary
              onClick={() => {
                getChangeReport(payload);
                onClose();
              }}
              width={[1, 'auto']}
              loading={loading}
            >
              {formatMessage({ id: 'RUN_REPORT' })}
            </LoaderButton>
          </Flex>
        </Flex>
      </ClickEater>
    </Backdrop>
  );
};

ChangeReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  getChangeReport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  reportId: PropTypes.string,
  internalReportId: PropTypes.string,
  dateCreated: PropTypes.string,
};

ChangeReportModal.defaultProps = {
  loading: false,
  reportId: '',
  internalReportId: '',
  dateCreated: '',
};
