/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { APP_CONFIG } from 'app-config';
import cookies from 'react-cookies';
import { mapObjectToURLSearchParams } from 'common/utilities/url';
import { AUTH0_FEATURE_FLAG } from './auth0/auth0.constants';
import { shouldWaitForMobileAuth } from './auth0/auth0.mobile-auth-state-listener';
import { getCookieOptions } from 'common/utilities/cookies';
import { Auth0ClientWrapperInstance } from './auth0/auth0.clientwrapper';

export const isAuth0Enabled = () =>
  window.localStorage.getItem(AUTH0_FEATURE_FLAG) === 'true' ||
  // If the new mobile auth passing mechanism is enabled, have it also implicitly enable Auth0,
  // since it only works with Auth0. It is tricky for the native webview code to toggle this
  // feature flag otherwise.
  shouldWaitForMobileAuth();

export const getSignInPath = () =>
  isAuth0Enabled() ? '/app/sign-in' : '/sign-in';

const MULTI_TENANT_ROUTES = ['/admin', '/app/admin'];

const canSelectTenantId = () =>
  MULTI_TENANT_ROUTES.some(route => window.location.pathname.startsWith(route));

const notNullish = ([, value]) => value != null;

/**
 * Returns the sign-in url with added query params.
 * @param {object} params query params to add to the sign-in url e.g. { timeout: 1 }
 */
export const getSignInUrl = params => {
  const searchParams = Object.fromEntries(
    Object.entries({
      ...(params ?? {}),
      selectTenant: canSelectTenantId() ? 1 : null,
    }).filter(notNullish),
  );

  const signInUrl = new URL(getSignInPath(), APP_CONFIG.REACT_APP_WR_URL);
  signInUrl.search = mapObjectToURLSearchParams(searchParams);

  return signInUrl.toString();
};

export const getTargetUrl = ({ location: { pathname, search } } = window) =>
  pathname.includes('sign-in') || pathname.includes('sign-out') // Ignore sign-in or out, to avoid loop
    ? null
    : pathname.replace(/^\/app/, '') + search; // Cut off '/app' from the front - it's part of router base

export const getCookieRedirectUrl = () => cookies.load('orig_req_uri');

// WidgetRunner will take care of setting the orig_req_uri cookie for us
// do not call this method if the app is inside Widget Runner
export const setURLToRedirectAfterLogin = () => {
  const DO_NOT_REDIRECT_TO = ['sign-in'];
  const cookieOptions = getCookieOptions();
  // `window.top.location` stores the location of the top when when privacy lock
  // triggers in an iframe (ie chat in enrollment, or member app in WidgetRunner)
  const { pathname, search } = window.top.location;

  const redirect = `${pathname}${search}`;
  const isCurrentURLSafe = !DO_NOT_REDIRECT_TO.some(str =>
    redirect.includes(str),
  );

  if (isCurrentURLSafe) {
    cookies.save('orig_req_uri', redirect, cookieOptions);
  }
};

export const removeRedirectUrl = () => {
  const cookieOptions = getCookieOptions();
  cookies.remove('orig_req_uri', cookieOptions);
};

export const getCookieExchangeRequestURL = urlBase =>
  `${urlBase}/auth0/exchange`;

export const getCookieExchangeRequestParams = accessToken => {
  return {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
    body: {},
    credentials: 'include',
  };
};

/**
 * Function that redirects a user to an external link after setting a cookie
 * with a valid access token. Allows Auth0 credentials to be maintained.
 * Takes the same parameters as window.open().
 *
 * If only a url is provided, the user will be redirected to that page in
 * the current tab. If either a target or features (or both) parameters are
 * provided, the link will open in a new tab with those parameters. See
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/open for more
 * details
 *
 * When authenticating the SSOusing Auth0 Credentials for external links, we
 * need to attach cookies that contain the AT before doing direct navigation. This
 * function hits the rest exchange endpoint to attach the cookie before redirecting
 * the user. If the request fails, we attempt a second time before redirecting. If
 * the request has failed twice in a row, the user will need to log in after they
 * have been redirected.
 *
 * @param  {string} url url we should navigate to after we have hit the exchange endpoint
 * @param  {string | undefined} target A DOMString specifying the name of the browsing context (window, <iframe> or tab) into which to load the specified resource. See window.open() documentation on MDN for more details
 * @param  {string | undefined} features A DOMString containing a comma-separated list of window features. See window.open() documentation on MDN
 */
// TODO: replace with {handleAuthenticationForExternalLinks} from '@leagueplatform/auth-standalone'
// the implementation is slightly different, so we need to test it before making this move
export const handleAuthenticationForExternalLinks = async (
  url,
  target,
  features,
) => {
  if (isAuth0Enabled()) {
    await attachCookiesWithAccessToken(APP_CONFIG.REACT_APP_LEGACY_REST_API);
  }
  // if no target or features are provided, redirect user to new url
  // in current tab. Otherwise, open the url with the provided target
  // and features
  if (!target && !features) {
    window.location.href = url;
  } else {
    window.open(url, target, features);
  }
};

/**
 * Helper function to hit an exchange endpoint that will set a cookie for the
 * user with a valid access token. This function will take the passed url base
 * parameter, generate a request with the access token, and attempt to make the
 * exchange.
 *
 * @param  {string} exchangeUrlBase url base for the endpoint we are trying to hit, typically taken from the APP_CONFIG. For example, APP_CONFIG.REACT_APP_LEGACY_REST_API, or APP_CONFIG.REACT_APP_CONTENT_SERVER_URL
 */
// TODO: replace with {attachCookiesWithAccessToken} from '@leagueplatform/auth-standalone'
// the implementation is slightly different, so we need to test it before making this move
export const attachCookiesWithAccessToken = async exchangeUrlBase => {
  const accessToken = await Auth0ClientWrapperInstance.getAccessToken();
  const exchangeRequestURL = getCookieExchangeRequestURL(exchangeUrlBase);
  const exchangeRequestParams = getCookieExchangeRequestParams(accessToken);
  const exchangeRequest = new Request(
    exchangeRequestURL,
    exchangeRequestParams,
  );

  try {
    await fetch(exchangeRequest);
  } catch (ignore) {
    // Ignore errors if fetch fails
  }
};
