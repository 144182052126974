import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { InviteAccessSidebar } from './sidebar';

const Layout = styled(Flex)`
  height: 100vh;
  flex-direction: row;
  ${media.belowLap`
  flex-direction: column;
  `};
`;

export function SidebarLayout(props) {
  return (
    <Layout>
      {/* eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue */}
      <Box width={[1, 1, 1, 0.7]}>{props.children}</Box>
      <Box width={[1, 1, 1, 0.3]}>
        <InviteAccessSidebar />
      </Box>
    </Layout>
  );
}

SidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
