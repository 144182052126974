import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';

import {
  BodyOne,
  Label,
  LabelText,
  SecondaryButton,
  PrimaryButton,
  TextInput,
  HeadingTwo,
  Link,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

const examplePayCalendarLink =
  'https://docs.google.com/spreadsheets/d/1b6M9pnH81XUO8ZU70OpytdF2oRMhHRe_wXtJj6O-t64';

export const ImportPaySchedulePresenter = ({
  groupId,
  planPeriods,
  onClose,
  importPayrollSchedule,
  getEmployerBenefitPlans,
}) => {
  const { formatMessage } = useIntl();
  const [documentUrl, setDocumentUrl] = useState('');
  const [planPeriod, setPlanPeriod] = useState();

  useEffect(() => {
    getEmployerBenefitPlans({ groupId });
  }, [getEmployerBenefitPlans, groupId]);

  return (
    <Modal>
      <HeadingTwo>{formatMessage({ id: 'ADD_PAY_CALENDAR' })}</HeadingTwo>
      <ModalContent>
        <BodyOne>{formatMessage({ id: 'IMPORT_PAY_CALENDAR_COPY' })}</BodyOne>
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href={examplePayCalendarLink}
        >
          {examplePayCalendarLink}
        </Link>

        <BodyOne marginTop="threeQuarters">
          <strong>{formatMessage({ id: 'PLEASE_NOTE' })}:&nbsp;</strong>
          {formatMessage({ id: 'IMPORT_PAY_CALENDAR_NOTE' })}
        </BodyOne>
        <Label marginTop="oneAndHalf">
          <LabelText>{formatMessage({ id: 'PLAN_PERIOD' })}</LabelText>
          <Select
            options={planPeriods}
            onChange={evt => setPlanPeriod(evt.planPeriod)}
          />
        </Label>
        <Label marginTop="oneAndHalf">
          <LabelText>{formatMessage({ id: 'DOCUMENT_URL' })}</LabelText>
          <TextInput
            value={documentUrl}
            onChange={e => setDocumentUrl(e.currentTarget.value)}
            placeholder="https://docs.google.com/spreadsheets/url"
          />
        </Label>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton
          onClick={() =>
            importPayrollSchedule({
              plan_period: planPeriod,
              document_url: documentUrl,
            })
          }
        >
          {formatMessage({ id: 'IMPORT' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

ImportPaySchedulePresenter.propTypes = {
  groupId: PropTypes.string.isRequired,
  planPeriods: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      planPeriod: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string,
      }),
    }),
  ),
  onClose: PropTypes.func.isRequired,
  importPayrollSchedule: PropTypes.func.isRequired,
  getEmployerBenefitPlans: PropTypes.func.isRequired,
};

ImportPaySchedulePresenter.defaultProps = {
  planPeriods: [],
};
