export default {
  name: {
    input_type: 'text',
    position: 1,
    hidden: false,
    placeholder: '',
    tooltip:
      'Billing divisions are discrete parts of your company that are used for billing purposes',
    label: 'Billing Division',
    read_only: false,
    masked: false,
    field_name: 'name',
    json_type: 'string',
    go_type: 'string',
    validation_rules: [{ args: null, hint: 'is required', rule: 'required' }],
  },
};
