import React, { Component } from 'react';
import { Header, Input, Form, Button, Label } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import EmployerTable from './Table';

class Employers extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: '' };
  }

  handleSearchChange(value) {
    this.setState({ searchText: value });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.onSearchTextChanged(value);
  }

  render() {
    return (
      <Box>
        <Header as="h1">
          <FormattedMessage id="ALL_EMPLOYERS" />
          <Button
            name="reset-search"
            floated="right"
            color="violet"
            // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
            onClick={() => this.props.history.push('/admin/employers/new')}
          >
            <FormattedMessage id="ADD_EMPLOYER" />
          </Button>
        </Header>
        <Form>
          <Form.Field>
            <FormattedMessage id="SEARCH_EMPLOYERS" tagName="label" />
            <Input
              name="search-text"
              icon="search"
              // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
              value={this.state.searchText}
              onChange={event => this.handleSearchChange(event.target.value)}
              autoComplete="off"
            />
            <Label className="subtext">
              <FormattedMessage id="EMPLOYER_SEARCH_HINT" tagName="label" />
            </Label>
          </Form.Field>
        </Form>
        <Header as="h3">
          {/* eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue */}
          {this.state.searchText && (
            <FormattedMessage
              id="RESULTS_FOR"
              values={{
                // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
                query: this.state.searchText,
              }}
            />
          )}
        </Header>
        <EmployerTable
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          searchText={this.state.searchText}
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          onSearchPageRequested={this.props.onSearchPageRequested}
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          onEmployerSelected={this.props.onEmployerSelected}
        />
      </Box>
    );
  }
}

Employers.propTypes = {
  onSearchTextChanged: PropTypes.func.isRequired,
  onSearchPageRequested: PropTypes.func.isRequired,
  onEmployerSelected: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default Employers;
