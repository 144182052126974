import React from 'react';

const AsyncAppPublic = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-public' */ 'apps/public/components/AppPublic'
  ),
);

const AsyncLogin = React.lazy(() =>
  import(/* webpackChunkName: 'app-public' */ 'apps/public/pages/Login'),
);

export const LoginPage = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncAppPublic {...props}>
    <AsyncLogin />
  </AsyncAppPublic>
);

const AsyncAccountRetrieval = React.lazy(() =>
  import(
    // eslint-disable-next-line import/extensions
    /* webpackChunkName: 'app-public' */ 'apps/public/pages/AccountRetrieval/account-retrieval.component.tsx'
  ),
);

export const AccountRetrieval = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AsyncAppPublic {...props}>
    <AsyncAccountRetrieval />
  </AsyncAppPublic>
);

const Async404 = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-public' */ 'apps/public/pages/PageNotFound/page-not-found.container'
  ),
);

export const NoMatch = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncAppPublic {...props}>
    <Async404 />
  </AsyncAppPublic>
);

const AsyncDownloadApp = React.lazy(() =>
  import(
    /* webpackChunkName: 'app' */ 'apps/public/pages/DownloadApp/download-app.view'
  ),
);

const AsyncBasicContainer = React.lazy(() =>
  import(/* webpackChunkName: 'app' */ 'apps/public/components/BasicContainer'),
);

export const DownloadAppPage = () => {
  return (
    <AsyncBasicContainer>
      <AsyncDownloadApp />
    </AsyncBasicContainer>
  );
};

const AsyncOnboarding = React.lazy(() =>
  import(/* webpackChunkName: 'app' */ '@leagueplatform/onboarding'),
);

export const Onboarding = () => {
  return (
    <AsyncOnboarding contentfulId="2dvaIyIRW0C7XKiXF1RO99" tenantId="league" />
  );
};
