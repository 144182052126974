import { combineReducers } from 'redux';
import { GET_YEAR_TO_DATE_REPORT } from '../employer-payroll.types';
import { STATUS } from '../employer-payroll.constants';

export const initialState = {
  contentId: '',
  fileName: '',
};

export const report = (state = initialState, action = {}) => {
  switch (action?.type) {
    case GET_YEAR_TO_DATE_REPORT.STARTED:
      return {
        ...state,
        contentId: '',
        fileName: '',
      };
    case GET_YEAR_TO_DATE_REPORT.SUCCEEDED:
      return {
        ...state,
        contentId: action?.payload?.info?.report?.report_files[0]?.content_id,
        fileName: action?.payload?.info?.report?.report_files[0]?.filename,
      };
    case GET_YEAR_TO_DATE_REPORT.ERRORED:
      return {
        ...state,
        contentId: '',
        fileName: '',
      };
    default:
      return state;
  }
};

export const requestStatus = (state = STATUS.IDLE, action = {}) => {
  switch (action?.type) {
    case GET_YEAR_TO_DATE_REPORT.STARTED:
      return STATUS.LOADING;
    case GET_YEAR_TO_DATE_REPORT.SUCCEEDED:
      return STATUS.SUCCESS;
    case GET_YEAR_TO_DATE_REPORT.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

export const yearToDateReport = combineReducers({
  report,
  requestStatus,
});
