import { useReducer, useCallback } from 'react';

export const SET_IS_MENU_OPEN = 'set-is-menu-open';
export const RESET = 'reset';
export const VIEW_DEFAULT_PLAN = 'view-default-plan';
export const START_ENROLLMENT = 'start-enrollment';

export const initialState = {
  isMenuOpen: false,
  isViewingDefaultPlan: false,
  isStartingEnrollment: false,
};

const init = state => state;

export const planContextReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload,
      };
    case VIEW_DEFAULT_PLAN:
      return {
        ...state,
        isViewingDefaultPlan: true,
      };
    case START_ENROLLMENT:
      return {
        ...state,
        isStartingEnrollment: true,
      };
    case RESET:
      return init(initialState);
    default:
      throw new Error('Unknown action type in planContextReducer');
  }
};

export const usePlanContextMenuReducer = () => {
  const [{ isMenuOpen, isViewingDefaultPlan, isStartingEnrollment }, dispatch] =
    useReducer(planContextReducer, initialState, init);

  const toggleOpen = useCallback(
    () => dispatch({ type: SET_IS_MENU_OPEN, payload: !isMenuOpen }),
    [dispatch, isMenuOpen],
  );
  const reset = useCallback(() => dispatch({ type: RESET }), [dispatch]);
  const viewDefaultPlan = useCallback(
    () => dispatch({ type: VIEW_DEFAULT_PLAN }),
    [dispatch],
  );
  const startEnrollment = useCallback(
    () => dispatch({ type: START_ENROLLMENT }),
    [dispatch],
  );

  return {
    isMenuOpen,
    isViewingDefaultPlan,
    isStartingEnrollment,
    toggleOpen,
    reset,
    viewDefaultPlan,
    startEnrollment,
  };
};
