import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colour, media, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { isIE } from '../helpers';
import { CoBrandContainer } from './co-brand';

export const StepLayout = styled(Flex)`
  color: ${colour('purpleDarkest')};
  max-width: 820px;
  padding: 0 32px;
  margin: auto;
  height: 100vh;
  align-items: center;

  ${media.belowLap`
    height: inherit;
    padding: 0 24px;
  `};

  ${isIE &&
  css`
    display: block;
  `};
`;

const ContentLayout = styled(Box)`
  width: 100%;
  min-height: 450px;
  height: auto;
  margin: auto;
  padding: 138px 0;

  ${media.belowLap`
    padding-bottom: 48px;
  `};
`;

export const StepPage = ({ children }) => (
  <StepLayout>
    <CoBrandContainer />
    <ContentLayout py={[4, 4, '120px', '48px']}>
      <div>{children}</div>
    </ContentLayout>
  </StepLayout>
);

StepPage.propTypes = {
  children: PropTypes.node.isRequired,
};
