import { GET_SALESFORCE_CHAT_TRANSCRIPT } from '../chat-page.action-types';

export const chatTranscriptInitialState = {
  isLoading: false,
  hasErrored: false,
  transcript: null,
};

export const chatTranscriptReducer = (
  state = chatTranscriptInitialState,
  action = {},
) => {
  switch (action.type) {
    case GET_SALESFORCE_CHAT_TRANSCRIPT.STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SALESFORCE_CHAT_TRANSCRIPT.SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        transcript: action.payload.info,
      };
    case GET_SALESFORCE_CHAT_TRANSCRIPT.ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
      };
    default:
      return state;
  }
};
