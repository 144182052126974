import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withLifecycleInitializer } from './initializer.view';
import EmployerAdjustmentsPage from './employer-adjustments-page.view';
import { removeAdjustment } from './adjustments.actions';
import {
  selectAdjustments,
  selectAdding,
  selectGroupBillingDivisions,
  selectGroupBillingPreference,
} from './adjustments.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';

const withState = connect(
  createStructuredSelector({
    isAdmin: selectUserIsAdmin,
    adjustments: selectAdjustments,
    adding: selectAdding,
    billingDivisions: selectGroupBillingDivisions,
    billingPreference: selectGroupBillingPreference,
  }),
  { removeAdjustment },
);

export const EmployerAdjustments = compose(
  withLifecycleInitializer,
  withState,
)(EmployerAdjustmentsPage);
