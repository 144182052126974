/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { pick, keys, first, get } from 'lodash';
import {
  provinceStateShortToLong,
  CANADA,
  UNITED_STATES,
  ENROLLMENT_TYPE_QLE,
} from 'common/constants';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';

export const enrollmentStepPrefix = 'enrollment_step_';

const normalizeLocation = location => ({
  ...location,
  postal_code: location.postal,
  province: provinceStateShortToLong[location.province] || location.province,
  state: provinceStateShortToLong[location.state] || location.state,
});

const normalizeNationalIdentificationNumber = ({
  national_identification_number,
  social_security_number,
}) => national_identification_number || social_security_number;

export const getProfileValuesFromSchema = (userData, schema) => {
  const profile = pick(userData, keys(schema));
  const location = first(userData.locations);
  const address = location
    ? pick(normalizeLocation(location), keys(schema))
    : null;
  return {
    ...profile,
    ...address,
    ...(schema.national_identification_number && {
      national_identification_number:
        normalizeNationalIdentificationNumber(userData),
    }),
    ...(profile.date_of_birth && {
      date_of_birth: moment.utc(profile.date_of_birth).toDate(),
    }),
  };
};

/**
 * `get_benefit_prices` and `get_benefit_selection` require user to a set value for
 * the `tobacco_user` property. This function is used to test that a server_error is
 * related to this property as we need to sometimes catch and continue app execution
 */

export const isTobaccoUserError = error =>
  /missing tobacco_user/.test(get(error, 'info.reason', ''));

export const hasNoDateOfBirthError = error =>
  /missing date_of_birth/.test(get(error, 'info.reason', ''));

export const isRecoverableGetBenefitSelectionError = error =>
  isTobaccoUserError(error) || hasNoDateOfBirthError(error);

/**
 * get_benefit_prices fails for spouse dependents over the age of 65.
 */
export const isOptionalSpousalLifeRateError = error =>
  /Could not find Optional Spousal Life Rate/.test(
    get(error, 'info.reason', ''),
  );

/**
 * Date validation functions
 */

export const DMY_FORMAT_USERS = ['en-IE', 'fr-IE'];
export const MDY_FORMAT_USERS = ['en-US'];
export const YMD_FORMAT_USERS = ['en-CA', 'fr-CA'];
// DMY = day month year
export const DMY_FORMAT = 'DD/MM/YYYY';
export const MDY_FORMAT = 'MM/DD/YYYY';
export const YMD_FORMAT = 'YYYY/MM/DD';
export const DEFAULT_FORMAT = MDY_FORMAT; // en-US format uber ales

// Month Day, YYYY - e.g. Oct 1, 2020 or Oct 25, 2020
export const SHORT_DATE_FORMAT = 'MMM D, YYYY';

// Month D, YYYY - eg. October 30, 2019
export const dateFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const localeUserMapping = [
  [DMY_FORMAT, DMY_FORMAT_USERS],
  [MDY_FORMAT, MDY_FORMAT_USERS],
  [YMD_FORMAT, YMD_FORMAT_USERS],
];
export const getDateFormat = locale =>
  localeUserMapping.reduce(
    (format, [localeFormat, localeUsers]) =>
      localeUsers.includes(locale) ? localeFormat : format,
    DEFAULT_FORMAT,
  );

// The regex turns AA/BBB/CCCC/DEE into ABCDE
// 1. replaces repeating sequence of chars with one occurrence
// 2. removes slashes
// thanks https://stackoverflow.com/a/46701355
export const getDateFormatName = locale =>
  getDateFormat(locale).replace(/[^\w\s]|(.)(?=\1)/g, '');

const dateIncludesDashesAndSlashes = date => {
  if (typeof date !== 'string') return false;
  // also checking for dashes, otherwise it will invalidate the iso dates
  return date.includes('-') && date.includes('/');
};

export const isValidDate = (date, dateFormat) => {
  const dateWithFormat = moment(date, dateFormat);
  return !dateIncludesDashesAndSlashes(date) && dateWithFormat.isValid();
};

const northAmericanCountries = [CANADA, UNITED_STATES];

// Checks if the country code is not CA or not US
export const isNorthAmerica = countryCode =>
  northAmericanCountries.includes(countryCode);

export const fiveDaysFrom = date => date + 5 * 24 * 3600 * 1000;

export const isLeagueHSA = (vendor, productType) =>
  productType === 'hsa' && vendor === 'league';

export const isQleEnrollment = enrollmentType =>
  enrollmentType === ENROLLMENT_TYPE_QLE;

export const zeroDollar = currency => ({
  F: 0,
  C: currency,
  M: 0,
});
