import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { selectGroupId } from '../Employee/selectors';
import { FormattedMessage } from 'react-intl';
import { Button, Segment, Container, Header } from 'semantic-ui-react';
import { APP_CONFIG } from 'app-config';
import Page from './Page';
import { Text } from '@leagueplatform/ui-kit';

const withState = connect(state => ({
  templateUrl: `${
    APP_CONFIG.REACT_APP_CONTENT_SERVER_URL
  }/csv/employee/add?group_id=${selectGroupId(state)}`,
}));

const DownloadTemplate = ({ templateUrl, stepForward, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Page {...rest}>
    <Segment attached padded="very">
      <Container>
        <Header as="h3">
          <FormattedMessage id="BULK_UPLOAD_STEP_1_HEADER" />
        </Header>
        <Text>
          <FormattedMessage id="BULK_UPLOAD_STEP_1_CONTENT" />
        </Text>
      </Container>
      <Button color="violet" as="a" href={templateUrl}>
        <FormattedMessage id="BULK_UPLOAD_DOWNLOAD_BTN_LABEL" />
      </Button>
    </Segment>
    <Segment attached textAlign="right" padded>
      <Button color="violet" onClick={() => stepForward(1)}>
        <FormattedMessage id="BULK_UPLOAD_NEXT_BTN_LABEL" />
      </Button>
    </Segment>
  </Page>
);

DownloadTemplate.propTypes = {
  stepForward: PropTypes.func.isRequired,
  templateUrl: PropTypes.string.isRequired,
};

export default compose(withState)(DownloadTemplate);
