import React from 'react';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { Controlled } from 'react-codemirror2';
import './codemirror.css';
import PropTypes from 'prop-types';

export const TextEditor = ({ code, setCode }) => (
  <Controlled
    value={code}
    options={{
      mode: 'htmlmixed',
      theme: 'material',
      lineNumbers: true,
    }}
    onBeforeChange={(e, state, value) => setCode(value)}
  />
);

TextEditor.propTypes = {
  code: PropTypes.string,
  setCode: PropTypes.func.isRequired,
};

TextEditor.defaultProps = {
  code: '',
};
