import { colour, fontSizeInPx, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled from 'styled-components';
import { Button } from 'apps/employer-experience/ui-kit';

export const EmptyState = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  min-height: 37.5rem;
  background-color: ${colour('greyLightest')};
`;

export const ButtonContainer = styled(Flex).attrs({
  pt: 2,
})`
  height: 3rem;
  ${Button}:first-child {
    margin-left: 0;
  }
`;

export const Title = styled(Box).attrs({
  mt: 0,
  mb: 1,
})`
  font-size: ${fontSizeInPx(3)};
  line-height: 1.5rem;
  font-weight: 500;
`;

export const Subtitle = styled(Box)`
  line-height: 1.5rem;
`;
