import { createSelector } from 'reselect';
import { get, toUpper } from 'lodash';

const userPrimaryGroup = user => {
  let primaryGroup = null;
  (user.groups || []).forEach(group => {
    if (['employer', 'user_group'].includes(group.group_type)) {
      primaryGroup = group;
    }
  });

  return primaryGroup;
};

const selectApp = state => state.apps['employer-experience'];

const selectPage = createSelector(selectApp, app => app.pages.claimDetails);

export const selectData = createSelector(selectPage, page => page && page.data);

export const selectClaimNotesLoaded = createSelector(selectData, data => {
  return data.claimNotesLoaded ? data.claimNotesLoaded : false;
});

const selectRequestedBenefitType = createSelector(selectData, data =>
  data ? toUpper(get(data, 'claim.requested_benefit_type')) : null,
);

const selectPinnedDocument = createSelector(
  selectPage,
  page => page.pinnedDocument,
);

const selectors = {
  selectData,
  selectPage,
  userPrimaryGroup,
  selectRequestedBenefitType,
  selectPinnedDocument,
};

export default selectors;
