/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { useState, useEffect } from 'react';

export const useActiveNode = ({
  treeData,
  setFormData,
  setFormDataPath,
  set$Ref,
  setValidationWarnings,
}) => {
  const [activeNode, setActiveNodeState] = useState(null);

  // on activeNode update
  useEffect(() => {
    setFormData(activeNode?.entityData);
    setFormDataPath(activeNode?.entityDataPath);
    set$Ref(activeNode?.schemaRef);
    setValidationWarnings(activeNode?.validationWarnings);
  }, [
    activeNode,
    set$Ref,
    setFormData,
    setFormDataPath,
    setValidationWarnings,
  ]);

  // Set active node to initial tree form when no active node is set.
  useEffect(() => {
    if (treeData && treeData.treePath && !activeNode) activateNode(treeData);
  }, [treeData, activeNode]);

  const activateNode = node => {
    // eslint-disable-next-line no-param-reassign
    node.active = true; // set the new node to be active
    setActiveNodeState(node);
  };

  const setActiveNode = node => {
    if (activeNode && activeNode.treePath !== node.treePath) {
      activeNode.active = false; // set previously active node to false
      activateNode(node);
    } else {
      activateNode(node);
    }
  };

  return {
    activeNode,
    setActiveNode,
  };
};
