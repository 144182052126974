import types, {
  CLAIM_RESOURCES_LOADED,
  USER_HISTORY_INITIALIZED,
  PIN_DOCUMENT,
  UNPIN_DOCUMENT,
} from './claim-details.types';

/**
 * In claims tool, send document's content id to supplement file upload
 * and url to display preview.
 * @param {*} contentURL: url to file on our content server. Contains content id
 */
export const pinDocument = ({ contentId, contentUrl }) => ({
  type: PIN_DOCUMENT,
  payload: {
    contentId,
    contentUrl,
  },
});

/**
 * Clear data on pinned document. Should be called if adding claim document as user
 * document is cancelled.
 */
export const unpinDocument = () => ({
  type: UNPIN_DOCUMENT,
});

const setClaimDetailsTab = tab => ({
  type: types.SET_CLAIM_DETAILS_TAB,
  payload: { tab },
});

const requestClaim = payload => ({
  type: types.REQUEST_CLAIM_DETAILS_CLAIM,
  payload,
});

const requestClaimUser = payload => ({
  type: types.REQUEST_CLAIM_DETAILS_CLAIM_USER,
  payload,
});

const requestClaimUserDependents = payload => ({
  type: types.REQUEST_CLAIM_DETAILS_CLAIM_USER_DEPENDENTS,
  payload,
});

const requestClaimAgent = payload => ({
  type: types.REQUEST_CLAIM_DETAILS_CLAIM_AGENT,
  payload,
});

const requestClaimUserGroup = payload => ({
  type: types.REQUEST_CLAIM_DETAILS_CLAIM_USER_GROUP,
  payload,
});

export const claimResourcesLoaded = claim => ({
  type: CLAIM_RESOURCES_LOADED,
  payload: claim,
});

export const initUserHistory = userId => ({
  type: USER_HISTORY_INITIALIZED,
  payload: { userId },
});

export default {
  claimResourcesLoaded,
  initUserHistory,
  setClaimDetailsTab,
  requestClaim,
  requestClaimUser,
  requestClaimAgent,
  requestClaimUserGroup,
  requestClaimUserDependents,
};
