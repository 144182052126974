import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon, Label } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import BenefitOptions from './BenefitOptions';
import { BenefitConfirmationModal } from './BenefitConfirmationModal/BenefitConfirmationModal';
import { UpdateGroupCategoriesModal } from './UpdateGroupCategoriesModal';
import BenefitContainer from '../BenefitTypes/BenefitContainer';
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
  BENEFIT_STATUS_MAP,
  BENEFIT_STATUS_COLORS,
  BENEFIT_STATUS_INACTIVE_GRACE_PERIOD,
  BENEFIT_STATUS_READY,
} from './constants';
import { vars, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled from 'styled-components';
import { flattenObject } from 'common/adaptive-forms';

const BenefitLeftWrapper = styled(Flex)`
  align-items: center;
  padding-right: 0.2rem;
  max-width: 70%;
  min-width: 0;
`;

const OverflowContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
`;

const CarrierNameWrapper = styled.span`
  font-weight: 100;
  opacity: 0.5;
`;

const BenefitPeriodLabel = styled.span`
  height: 2rem;
  margin-top: 0.25rem;
  margin-left: 0.625rem;
  background-color: ${vars.colour.offWhite};
  color: ${vars.colour.navyDark};
  font-size: 0.9rem;
  text-transform: capitalize;
  padding: 0.2rem 0.75rem 0 0.75rem;
`;

const BenefitNameFlex = styled(Flex)`
  flex-direction: column;
  min-width: 0;
`;

const PlanNameWrapper = styled.span`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1rem;
`;

export const Benefit = ({
  addingBenefit,
  benefitClassId,
  benefitEndDate,
  benefitPlanId,
  benefitPlanName,
  benefitStatus,
  benefitType,
  benefitVendorName,
  groupId,
  id,
  name,
  removeBenefit,
  removeGroupCategories,
  addUpdateBenefit,
  removeUnsavedBenefit,
  unsavedId,
  values,
  renewBenefit,
}) => {
  const { formatMessage } = useIntl();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [active, setActive] = useState(!!unsavedId);
  const [showGroupCategoriesModal, setShowGroupCategoriesModal] =
    useState(false);
  const [showRemoveGroupCategoriesModal, setShowRemoveGroupCategoriesModal] =
    useState(false);

  const onTitleClick = () => {
    setActive(!active);
  };

  const onRemoveBenefit = () => {
    if (unsavedId) {
      removeUnsavedBenefit(unsavedId);
    } else {
      setConfirmRemove(true);
    }
  };

  const removeBenefitConfirm = () => {
    setConfirmRemove(false);
    removeBenefit({ id });
  };

  const onRemoveGroupCategoriesConfirm = () => {
    setShowRemoveGroupCategoriesModal(false);
    removeGroupCategories({ benefitId: id });
  };

  const publishBenefit = () => {
    const benefit = {
      ...flattenObject(values),
      benefit_status: BENEFIT_STATUS_READY,
    };
    addUpdateBenefit(benefit);
  };

  const openWalletPreview = () => {
    window.open(`/app/admin/benefit-preview/${id}/${groupId}`);
  };

  return (
    <Accordion fluid styled className="employer-benefit accordion--benefit">
      <Accordion.Title active={active} onClick={onTitleClick}>
        <Flex justify="space-between">
          <BenefitLeftWrapper>
            <Icon name="dropdown" />
            <BenefitNameFlex>
              <OverflowContainer>
                <FormattedMessage id="BENEFIT_NAME" values={{ name }} />
                <CarrierNameWrapper>
                  <FormattedMessage
                    id="BENEFIT_VENDOR_NAME"
                    values={{ vendorName: benefitVendorName }}
                  />
                </CarrierNameWrapper>
              </OverflowContainer>
              {benefitPlanName && (
                <PlanNameWrapper>{benefitPlanName}</PlanNameWrapper>
              )}
            </BenefitNameFlex>
          </BenefitLeftWrapper>
          <Flex align="center">
            {benefitStatus && (
              <Label
                size="small"
                className={`label-benefit-status label-benefit-status-${
                  BENEFIT_STATUS_COLORS[benefitStatus] || 'default'
                }`}
              >
                <Icon name="circle" />
                {BENEFIT_STATUS_MAP[benefitStatus] ? (
                  <FormattedMessage id={BENEFIT_STATUS_MAP[benefitStatus]} />
                ) : (
                  benefitStatus
                )}
              </Label>
            )}
            {benefitStatus === BENEFIT_STATUS_INACTIVE_GRACE_PERIOD && (
              <BenefitPeriodLabel>
                <Icon name="clock" color="red" />
                <FormattedMessage id="IN_GRACE_PERIOD" />
              </BenefitPeriodLabel>
            )}
            {benefitStatus !== BENEFIT_STATUS_INACTIVE_GRACE_PERIOD &&
              benefitEndDate && (
                <BenefitPeriodLabel>
                  <Icon name="calendar" />
                  <FormattedMessage
                    id="ENDS_DATE"
                    values={{
                      date: (
                        <FormattedDate
                          value={benefitEndDate}
                          month="short"
                          year="numeric"
                        />
                      ),
                    }}
                  />
                </BenefitPeriodLabel>
              )}
            <BenefitOptions
              onRemoveGroupCategories={() =>
                setShowRemoveGroupCategoriesModal(true)
              }
              removeBenefit={onRemoveBenefit}
              renewBenefit={() => renewBenefit({ ids: [id] })}
              publishBenefit={publishBenefit}
              benefitStatus={benefitStatus}
              benefitType={values.benefit_product_type}
              autoRenewOn={values.auto_renew}
              onWalletPreviewClick={openWalletPreview}
              onUpdateGroupCategories={() => setShowGroupCategoriesModal(true)}
            />
          </Flex>
        </Flex>
      </Accordion.Title>
      <Accordion.Content active={active}>
        {active && (
          <BenefitContainer
            benefitPlanId={benefitPlanId}
            benefitClassId={benefitClassId}
            benefitId={id}
            benefitType={benefitType}
            values={values}
            addingBenefit={addingBenefit}
          />
        )}
      </Accordion.Content>
      {confirmRemove && (
        <BenefitConfirmationModal
          title={formatMessage(
            { id: 'REMOVE_BENEFIT_CONFIRMATION_HEADER' },
            { benefitName: name },
          )}
          description={formatMessage(
            { id: 'REMOVE_BENEFIT_CONFIRMATION_COPY' },
            { benefitName: name },
          )}
          onCancel={() => setConfirmRemove(false)}
          onConfirm={removeBenefitConfirm}
          isRemove
        />
      )}
      {showGroupCategoriesModal && (
        <UpdateGroupCategoriesModal
          close={() => setShowGroupCategoriesModal(false)}
          groupId={groupId}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...values}
        />
      )}
      {showRemoveGroupCategoriesModal && (
        <BenefitConfirmationModal
          title={formatMessage(
            { id: 'REMOVE_COVERAGE_CATEGORY_CONFIRMATION_TITLE' },
            { benefitName: name },
          )}
          description={formatMessage(
            { id: 'REMOVE_COVERAGE_CATEGORY_CONFIRMATION_DESCRIPTION' },
            { benefitName: name },
          )}
          onCancel={() => setShowRemoveGroupCategoriesModal(false)}
          benefitName={name}
          onConfirm={onRemoveGroupCategoriesConfirm}
          isRemove
        />
      )}
    </Accordion>
  );
};

export const propTypes = {
  name: PropTypes.string.isRequired,
  benefitVendorName: PropTypes.string,
  id: PropTypes.string,
  unsavedId: PropTypes.number,
  removeBenefit: PropTypes.func.isRequired,
  removeUnsavedBenefit: PropTypes.func.isRequired,
  removeGroupCategories: PropTypes.func.isRequired,
  benefitClassId: PropTypes.string.isRequired,
  benefitPlanId: PropTypes.string.isRequired,
  benefitType: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  values: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  addingBenefit: PropTypes.bool.isRequired,
  renewBenefit: PropTypes.func.isRequired,
  benefitStatus: PropTypes.string,
  addUpdateBenefit: PropTypes.func.isRequired,
  benefitEndDate: PropTypes.string,
  benefitPlanName: PropTypes.string,
};

Benefit.defaultProps = {
  id: '',
  benefitVendorName: '',
  unsavedId: null,
  values: {},
  benefitStatus: null,
  benefitEndDate: null,
  benefitPlanName: null,
};

Benefit.propTypes = propTypes;
