import styled from 'styled-components';
import { getThemeValue, media, vars, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import {
  genesisStyled,
  HeadingThree,
  css,
  HeadingTwo,
  HeadingFour,
} from '@leagueplatform/genesis-commons';

const { purple, mediumGrey, purpleDark } = vars.colour;

export const CardHeading = genesisStyled(HeadingThree)(
  css({
    marginTop: 'none',
    marginBottom: 'one',
  }),
);
CardHeading.displayName = 'Enrollment.CardHeading';

export const LargeCardHeading = genesisStyled(HeadingTwo)`
  font-size: 2rem;
  margin: 0;
`;

export const CardSubHeading = genesisStyled(HeadingFour)`
  font-size: 2rem;
  margin: 0;
`;

export const HeaderFlexContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeaderFlexContainer = styled(Flex).attrs({
  justify: 'space-between',
  w: 1,
  align: 'center',
})`
  font-size: ${getThemeValue('fontSizes.4')}px;
  font-weight: 700;
`;

const getColor = ({ color, highlight }) => {
  if (color) return color;
  return highlight ? purple : purpleDark;
};

const getDotted = ({ dotted }) =>
  dotted &&
  ` > span {
      border-bottom: 2px dotted ${mediumGrey};
    }`;

const getElipsis = ({ ellipsis }) =>
  ellipsis &&
  `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;

export const FlexCardHeading = styled(Box).attrs(props => ({
  fontSize: props.size || '1.8rem',
}))`
  ${({ bold }) => (bold ? 'font-weight: 700' : 'font-weight: 300')};
  ${({ isSpan }) => isSpan && 'display: inline-block'};
  ${({ uppercase }) => uppercase && 'text-transform: capitalize;'};
  color: ${getColor};
  ${getDotted};
  ${getElipsis};

  ${media.belowLap`
    font-size: 1.6rem;
  `};

  ${media.belowTablet`
    font-size: 1.4rem;
  `};
`;
