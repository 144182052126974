import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const changePhoneNumber = (
  userId,
  confirmPhoneNumber,
  newPhoneCountryCode,
) =>
  call(websocketFetch, 'reset_credentials_phone', {
    user_id: userId,
    new_phone: confirmPhoneNumber,
    new_phone_country_code: Number(newPhoneCountryCode),
  });
