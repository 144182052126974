import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { FieldWrapper } from 'common/json-schema-form/fields';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { selectAvailableBundles } from '../enrollment-config.selectors';
import { Select } from 'common/form-fields/Select/Select';

export const BundleIdSelectPresenter = ({
  field: { name, value } = {},
  form,
  propSchema,
  bundleOptions,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <Select
        name={name}
        options={bundleOptions}
        placeholder="Choose a bundle"
        disabled={propSchema.readOnly}
        hasError={form.errors[name]}
        isClearable
        onChange={evt => {
          const { value: val } = evt ?? {};
          form.setFieldValue(name, val || evt);
        }}
        value={value}
      />
    </FieldWrapper>
  );
};

BundleIdSelectPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  bundleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

BundleIdSelectPresenter.defaultProps = {
  bundleOptions: [],
};

const mapStateToProps = state => {
  const bundles = selectAvailableBundles(state);
  return {
    bundleOptions: Object.values(bundles).map(bundle => ({
      label: bundle.name,
      value: bundle.id,
    })),
  };
};

export const BundleIdSelect = connect(mapStateToProps)(BundleIdSelectPresenter);
