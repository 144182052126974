import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, omit } from 'lodash';
import { injectIntl } from 'react-intl';
import { get } from 'lodash/fp';
import {
  compose,
  withHandlers,
  branch,
  renderComponent,
  withStateHandlers,
  lifecycle,
  withProps,
} from 'recompose';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Benefits } from './benefits.view';
import {
  removeBenefit,
  removeGroupCategories as removeGroupCategoriesAction,
  renewBenefit,
  addUpdateBenefit,
} from './actions';
import {
  selectAddingBenefit,
  selectCatalogue,
  selectCatalogueOptions,
  selectBenefitEntities,
  selectBenefitFormData,
  selectNormalizedBenefitsForPlanInClass,
  selectBenefitFormDataForPlanInClass,
  selectBenefitsForPlanByType,
  selectBenefitsByProductTypeInClass,
  selectEmployerBenefitsLoadingByPlanId,
} from './selectors';
import { selectStructure } from '../../selectors';
import { selectGroupId } from '../../../../../selectors';

const withLoading = branch(
  get('loading'),
  renderComponent(() => (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  )),
);

const withUnsavedBenefits = withStateHandlers(
  ({ unsavedBenefits = [] }) => ({ unsavedBenefits }),
  {
    removeUnsavedBenefit:
      ({ unsavedBenefits }) =>
      unsaved => ({
        unsavedBenefits: unsavedBenefits.filter(
          ({ unsavedId }) => unsavedId !== unsaved,
        ),
      }),

    addUnsavedBenefit:
      ({ unsavedBenefits }) =>
      benefitProduct => {
        let unsavedBenefit = cloneDeep(benefitProduct);
        const newUnsavedBenefits = cloneDeep(unsavedBenefits);
        unsavedBenefit.unsavedId = unsavedBenefits.length + 1;
        unsavedBenefit = omit(unsavedBenefit, 'id');
        newUnsavedBenefits.push(unsavedBenefit);
        return { unsavedBenefits: newUnsavedBenefits };
      },

    setUnsavedBenefits: () => newUnsavedBenefits => ({
      unsavedBenefits: newUnsavedBenefits,
    }),
  },
);

const withSavedBenefitFilteredOut = lifecycle({
  componentWillReceiveProps({
    addingBenefit,
    unsavedBenefits,
    setUnsavedBenefits,
  }) {
    if (!addingBenefit && this.props.addingBenefit) {
      setUnsavedBenefits(
        unsavedBenefits.filter(
          ({ benefitType }) =>
            benefitType === this.props.addingBenefit.benefitType,
        ),
      );
    }
  },
});

const mapDispatchToActions = {
  removeGroupCategories: removeGroupCategoriesAction,
  removeBenefit,
  renewBenefit,
  addUpdateBenefit,
};

const mapStateToProps = (state, { benefitClassId, benefitPlanId }) => {
  const loading = selectEmployerBenefitsLoadingByPlanId(state, {
    benefitPlanId,
  });
  return {
    benefitClassId,
    benefitPlanId,
    loading,
    groupId: selectGroupId(state),
    addingBenefit: selectAddingBenefit(state),
    structure: selectStructure(state),
    catalogue: selectCatalogue(state),
    catalogueOptions: selectCatalogueOptions(state),
    allBenefitEntities: selectBenefitEntities(state),
    // TODO:benefit-case-migration-EMP-1087
    allBenefitFormData: selectBenefitFormData(state),
  };
};

const withBenefitHandlers = withHandlers({
  getBenefitsForPlanByByType: props => () => {
    return selectNormalizedBenefitsForPlanInClass(
      props.structure,
      props.benefitPlanId,
      props.benefitClassId,
      props.allBenefitEntities,
    );
  },
  // TODO:benefit-case-migration-EMP-1087
  getBenefitsFormDataForPlanByType: props => () => {
    return selectBenefitFormDataForPlanInClass(
      props.structure,
      props.benefitPlanId,
      props.benefitClassId,
      props.allBenefitFormData,
    );
  },
  getBenefitsForPlanByProductType: props => () => {
    return selectBenefitsByProductTypeInClass(
      props.structure,
      props.benefitPlanId,
      props.benefitClassId,
      props.allBenefitEntities,
      props.unsavedBenefits,
    );
  },
  removeBenefit:
    props =>
    ({ id }) =>
      props.removeBenefit({
        benefitId: id,
        groupId: props.groupId,
        benefitPlanId: props.benefitPlanId,
        benefitClassId: props.benefitClassId,
      }),
  renewBenefit:
    props =>
    ({ ids }) =>
      props.renewBenefit({
        benefitIds: ids,
        groupId: props.groupId,
        benefitPlanId: props.benefitPlanId,
        benefitClassId: props.benefitClassId,
      }),
  addUpdateBenefit: props => benefit =>
    props.addUpdateBenefit(benefit, props.benefitClassId),
});

const withBenefitFormProps = withProps(
  ({
    getBenefitsFormDataForPlanByType,
    getBenefitsForPlanByProductType,
    catalogue,
  }) => {
    const benefitsByProductType = cloneDeep(getBenefitsForPlanByProductType());
    const benefitsByType = cloneDeep(
      selectBenefitsForPlanByType(benefitsByProductType),
    );
    return {
      benefitsByProductType,
      isCatalogueEmpty: !Object.keys(catalogue).filter(
        benefitType => !benefitsByType[benefitType],
      ).length,
      formValues: cloneDeep(getBenefitsFormDataForPlanByType()),
    };
  },
);

export default compose(
  connect(mapStateToProps, mapDispatchToActions),
  withUnsavedBenefits,
  withLoading,
  withBenefitHandlers,
  withBenefitFormProps,
  withSavedBenefitFilteredOut,
  injectIntl,
)(Benefits);
