import React from 'react';
import { Dropdown, Tab, Segment } from 'semantic-ui-react';
import {
  HeadingTwo,
  Box,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { vars } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { WalletActions } from './WalletActions';
import { UsageInstructions } from './UsageInstructions';
import { Overview } from './Overview';
import { WalletLandingTemplateForm } from './wallet-landing-template-form/wallet-landing-template-form.component';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const NoBenefitsBox = styled(Flex)`
  background-color: ${vars.colour.offWhite};
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
`;

const NoBenefitsSubHeader = genesisStyled(Box)`
  max-width: 30rem;
  text-align: center;
`;

const walletPanes = groupId => [
  {
    menuItem: 'Overview',
    render: () => (
      <Tab.Pane className="overview" basic attached={false}>
        <Overview groupId={groupId} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Wallet Actions',
    render: () => (
      <Tab.Pane basic attached={false}>
        <WalletActions groupId={groupId} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Usage Instructions',
    render: () => (
      <Tab.Pane className="usage-instructions" basic attached={false}>
        <UsageInstructions groupId={groupId} />
      </Tab.Pane>
    ),
  },
];

const BenefitWalletTabs = ({
  providerBenefits,
  groupId,
  selectProviderBenefit,
  providerBenefitsList,
}) => {
  const { formatMessage, formatHTMLMessage } = useIntl();

  return (
    <Box>
      <WalletLandingTemplateForm groupId={groupId} />
      <HeadingTwo marginTop="two" marginBottom="half">
        {formatMessage({ id: 'BENEFITS_WALLET_HEADER' })}
      </HeadingTwo>

      {providerBenefitsList.length > 0 ? (
        <>
          {formatMessage({ id: 'BENEFITS_WALLET_HEADER_SUB_HEADER' })}
          <Box marginTop="1rem">
            <Dropdown
              className="dropdown--choose-configurable-wallet-benefit"
              selection
              floating
              placeholder={formatMessage({ id: 'SELECT_BENEFIT' })}
              options={providerBenefitsList}
              onChange={(e, { value }) => {
                selectProviderBenefit({
                  ...providerBenefits[value],
                  id: value,
                });
              }}
            />
            <Segment className="segment--billing">
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={walletPanes(groupId)}
                className="tab--billing"
              />
            </Segment>
          </Box>
        </>
      ) : (
        <NoBenefitsBox>
          {formatHTMLMessage({ id: 'NO_CONFIGURABLE_BENEFITS_HEADER' })}
          <NoBenefitsSubHeader>
            {formatMessage({ id: 'NO_CONFIGURABLE_BENEFITS_SUB_HEADER' })}
          </NoBenefitsSubHeader>
        </NoBenefitsBox>
      )}
    </Box>
  );
};

export default BenefitWalletTabs;

BenefitWalletTabs.propTypes = {
  providerBenefits: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  providerBenefitsList: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  groupId: PropTypes.string.isRequired,
  selectProviderBenefit: PropTypes.func.isRequired,
};
