import { PayCalendars } from './employer-pay-calendar.view';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  setPayrollSchedules,
  setShowImportModal,
  setShowRenameModal,
  importPayrollSchedule,
  exportPayrollSchedule,
  setShowExportModal,
} from 'apps/employer-experience-v2/employer-reports/components/premiums-report/premiums-report.actions';
import {
  selectShowImportModal,
  selectShowRenameModal,
  selectScheduleStatus,
  selectPayrollSchedulesWithPlanPeriods,
  selectShowExportModal,
  selectDocumentUrl,
  selectDocumentName,
  selectPlanPeriodFilterOptions,
} from 'apps/employer-experience-v2/employer-reports/components/premiums-report/premiums-report.selectors';

export default connect(
  createStructuredSelector({
    payrollSchedules: selectPayrollSchedulesWithPlanPeriods,
    payrollSchedulesLoading: selectScheduleStatus,
    showImportModal: selectShowImportModal,
    showRenameModal: selectShowRenameModal,
    showExportModal: selectShowExportModal,
    documentUrl: selectDocumentUrl,
    documentName: selectDocumentName,
    planFilterOptions: selectPlanPeriodFilterOptions,
  }),
  {
    setPayrollSchedules,
    setShowImportModal,
    setShowRenameModal,
    importPayrollSchedule,
    exportPayrollSchedule,
    setShowExportModal,
  },
)(PayCalendars);
