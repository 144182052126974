/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { take, takeLatest, takeEvery, call, put } from 'redux-saga/lib/effects';
import { push } from 'connected-react-router';
import { createPostMessageChannel } from 'common/utilities/post-message-helpers';
import {
  REACT_HOST_LIFECYCLE,
  postToWRMessageReceived,
  GO_TO_URL_POSTED_TO_WR,
} from './react-host.actions';

export function* reactHostSaga() {
  yield takeLatest(REACT_HOST_LIFECYCLE.VISITED, openPostMessageChannel);
  yield takeEvery(GO_TO_URL_POSTED_TO_WR, handleGoToUrl);
}

export function* handleGoToUrl(action) {
  const { payload: { path } = {} } = action;
  if (path) yield put(push(path));
}

export function* openPostMessageChannel() {
  const messageChannel = yield call(createPostMessageChannel);
  yield takeEvery(messageChannel, putPostToWR);
  yield take(REACT_HOST_LIFECYCLE.EXITED);
  messageChannel.close();
}

const isPostToWRMessage = ({ data }) => data?.source === 'postToWR';

export function putPostToWR(messageEvent) {
  if (isPostToWRMessage(messageEvent)) {
    return put(postToWRMessageReceived(messageEvent));
  }
  return null;
}
