import styled from 'styled-components';
import {
  propTypes as systemPropTypes,
  responsiveStyle,
  space as legacySpace,
  width as legacyWidth,
  fontSize as legacyFontSize,
  color as legacyColor,
  borderRadius as legacyBorderRadius,
  borderColor as legacyBorderColor,
  borderWidth as legacyBorderWidth,
  boxShadow as legacyBoxShadow,
  alignSelf as legacyAlignSelf,
  flex as legacyFlex,
  fontWeight as legacyFontWeight,
  alignItems as legacyAlignItems,
  justifyContent as legacyJustifyContent,
  flexWrap as legacyFlexWrap,
  flexDirection as legacyFlexDirection,
} from 'styled-system-legacy';

// New styled-system props
import {
  compose,
  space,
  width,
  color,
  fontSize,
  fontFamily,
  textAlign as newTextAlign,
  lineHeight as newLineHeight,
  fontWeight,
  letterSpacing,
  borders,
  boxShadow,
  alignSelf,
  flex,
  order as newOrder,
} from 'styled-system-old';

const order = responsiveStyle('order');
const textAlign = responsiveStyle('textAlign');
const lineHeight = responsiveStyle('lineHeight');

const baseSystems = compose(
  legacySpace,
  legacyWidth,
  legacyFontSize,
  legacyColor,
  legacyBorderRadius,
  legacyBorderColor,
  legacyBorderWidth,
  legacyBoxShadow,
  legacyAlignSelf,
  legacyFlex,
  legacyFontWeight,
  order,
  textAlign,
  lineHeight,
  legacyAlignItems,
  legacyJustifyContent,
  legacyFlexWrap,
  legacyFlexDirection,
);

/**
 * Flex Component
 * Extends the Box component and sets display flex. It also includes the flex related props.
 */
export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
  ${baseSystems}
`;

Flex.displayName = 'Flex';
Flex.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...newTextAlign.propTypes,
  ...newLineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...borders.propTypes,
  ...boxShadow.propTypes,
  ...alignSelf.propTypes,
  ...flex.propTypes,
  ...newOrder.propTypes,
  ...systemPropTypes.alignItems,
  ...systemPropTypes.justifyContent,
  ...systemPropTypes.flexWrap,
  ...systemPropTypes.flexDirection,
  ...systemPropTypes.flex,
};

/**
 * Inline Flex Component
 * Extends the Flex component and sets display to inline-flex.
 */
export const InlineFlex = styled(Flex)`
  display: inline-flex;
`;
InlineFlex.displayName = 'InlineFlex';
