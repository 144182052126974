import { APP_CONFIG } from 'app-config';

export const AUTH0_DOMAIN = APP_CONFIG.REACT_APP_AUTH0_DOMAIN;

export const AUTH0_CLIENT_ID = APP_CONFIG.REACT_APP_AUTH0_CLIENT_ID;

export const AUTH0_CORE_API_AUDIENCE =
  APP_CONFIG.REACT_APP_AUTH0_CORE_API_AUDIENCE;

export const AUTH0_FEATURE_FLAG = 'rel_idp_auth0IsEnabled';

export const AUTH0_CLAIM_NAMESPACE = 'https://el/';

export const AUTH0_CLAIM_USER_ID = `${AUTH0_CLAIM_NAMESPACE}user_id`;

// these strings are what we will get back from the backend service
export const PROVIDER_TYPES = {
  LEGACY: 'legacy',
  AUTH0: 'auth0',
};

export const USER_PROVIDER_TRANSLATION_IDS = {
  [PROVIDER_TYPES.AUTH0]: 'AUTH0_USER',
  [PROVIDER_TYPES.LEGACY]: 'LEGACY_USER',
  default: 'LEGACY_USER',
};

export const AUTH0_CUSTOM_ERROR_TYPE = 'unauthorized';
export const AUTH0_GENERIC_ERROR_PATH = 'generic_error';
export const AUTH_ERROR_ROUTE = '/auth-error/:error_message';
