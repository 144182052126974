import { some } from 'lodash/fp';
import { MORE_INFORMATION_NEEDED } from 'common/constants';
import { GET_CLAIMS_REQUEST, EXITED } from './claims.action-types';

export const initialState = {
  ready: false,
  error: null,
  claims: [],
  options: null,
  needsMoreInfo: null,
};

export const includesClaimThatNeedsMoreInfo = some(
  claim => claim.status === MORE_INFORMATION_NEEDED,
);

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const claimsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_CLAIMS_REQUEST.SUCCEEDED:
      return {
        ...state,
        ready: true,
        claims: action.payload.info.claims,
        options: action.meta.options,
        needsMoreInfo:
          state.needsMoreInfo === null
            ? includesClaimThatNeedsMoreInfo(action.payload.info.claims)
            : state.needsMoreInfo,
      };
    case GET_CLAIMS_REQUEST.ERRORED:
      return {
        ...state,
        ready: true,
        error: true,
      };
    case EXITED:
      return initialState;
    default:
      return state;
  }
};
