import React from 'react';
import PropTypes from 'prop-types';
import { BodyOne, BodyTwo, Link } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { InfoBannerCard } from 'common/info-banner-card/info-banner-card.view';
import {
  breakpoints,
  TooltipHeader,
  TooltipModal,
  Responsive,
} from '@leagueplatform/ui-kit';

const DesignateBeneficiariesTooltip = () => {
  const { formatMessage } = useIntl();

  const tooltipLabel = (
    <BodyOne
      color="decorative.brand.primary.darkest"
      fontSize="body1"
      fontWeight="regular"
      display="inline-block"
      borderBottom="2px dotted"
      borderColor="decorative.brand.primary.darkest"
    >
      {formatMessage({ id: 'DESIGNATE_BENEFICIARIES' })}
    </BodyOne>
  );

  const tooltipContent = formatMessage(
    { id: 'BENEFICIARIES_INFO_BANNER_CARD_TOOLTIP' },
    // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
    { p: text => <p>{text}</p> },
  );

  return (
    <>
      <Responsive breakpoint={breakpoints.lapAndAbove}>
        <TooltipHeader
          tooltipLink={tooltipLabel}
          tooltipText={tooltipContent}
          inline
          fontWeight="300"
          bottom
          center
          right={false}
          verticalPosition="bottom: 40px"
        />
      </Responsive>
      <Responsive breakpoint={breakpoints.belowLap}>
        <TooltipModal
          isFullScreen
          display="inline"
          label={tooltipLabel}
          content={<BodyTwo>{tooltipContent}</BodyTwo>}
        />
      </Responsive>
    </>
  );
};

export const BeneficiariesCard = ({ beneficiariesArticleLink, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <InfoBannerCard {...props}>
      <BodyOne as="div" display="inline">
        {formatMessage(
          { id: 'BENEFICIARIES_INFO_BANNER_CARD' },
          {
            designateBeneficiariesTooltip: <DesignateBeneficiariesTooltip />,
          },
        )}
      </BodyOne>
      <Link
        href={beneficiariesArticleLink}
        target="_blank"
        marginLeft="quarter"
        aria-label={formatMessage({ id: 'LEARN_MORE_ABOUT_BENEFICIARIES' })}
        style={{ textDecoration: 'underline' }}
      >
        {formatMessage({ id: 'LEARN_MORE' })}
      </Link>
    </InfoBannerCard>
  );
};

BeneficiariesCard.propTypes = {
  beneficiariesArticleLink: PropTypes.string.isRequired,
};
