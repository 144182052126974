import { cloneDeep } from 'lodash';
import {
  CREATE_EMPLOYER,
  UPDATE_EMPLOYER,
  CREATE_EMPLOYER_SCHEMA,
  EDIT_EMPLOYER_FORM_INIT_STARTED,
  EDIT_EMPLOYER_FORM_INIT_COMPLETED,
  PUBLISH_EMPLOYER,
  SUSPEND_EMPLOYER,
  ACTIVATE_EMPLOYER,
} from './types';

const applySetEmployerFormError = (state, action) => {
  return {
    ...state,
    profileIsUpdating: false,
    employerServiceStatus: {
      error: true,
      form: action.payload.info.message.info,
      reason: action.payload.info.reason,
    },
  };
};

const applyEmployerStatusUpdateSucceeded = state => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    employerServiceStatus: {
      error: false,
      reason: 'Employer Successfully Updated',
    },
  };
};
const applyEmployerStatusUpdateErrored = (state, action) => {
  const newState = cloneDeep(state);
  newState.employer.group.employer.status = newState.prevStatus;
  return {
    ...newState,
    employerServiceStatus: {
      error: true,
      reason: action.payload.info.reason,
    },
  };
};

const applyEmployerStatusUpdateStarted = state => {
  const newState = cloneDeep(state);
  const prevStatus = state.employer.group.employer.status;
  newState.employer.group.employer.status = 'loading';
  return {
    ...newState,
    prevStatus,
  };
};

export const employerProfileReducer = (state, action) => {
  if (!action) return state;
  switch (action.type) {
    case CREATE_EMPLOYER_SCHEMA.SUCCEEDED:
      return {
        ...state,
        employerFormFieldsSchema: action.payload.info,
        employerSchemaReady: true,
      };
    case CREATE_EMPLOYER.STARTED:
    case UPDATE_EMPLOYER.STARTED:
      return { ...state, profileIsUpdating: true };
    case CREATE_EMPLOYER.ERRORED:
    case UPDATE_EMPLOYER.ERRORED:
      return applySetEmployerFormError(state, action);
    case CREATE_EMPLOYER.SUCCEEDED:
      return {
        ...state,
        employerServiceStatus: {
          error: false,
          reason: 'Employer Successfully Created',
        },
        profileIsUpdating: false,
      };
    case UPDATE_EMPLOYER.SUCCEEDED:
      return {
        ...state,
        employerServiceStatus: {
          error: false,
          reason: 'Employer State Successfully Updated',
        },
        profileIsUpdating: false,
      };
    case EDIT_EMPLOYER_FORM_INIT_STARTED:
      return {
        ...state,
        editEmployerFormReady: false,
      };
    case EDIT_EMPLOYER_FORM_INIT_COMPLETED:
      return {
        ...state,
        employerForm: action.payload.employer,
        employerFormSchema: action.payload.schema,
        editEmployerFormReady: true,
      };
    case PUBLISH_EMPLOYER.STARTED:
    case ACTIVATE_EMPLOYER.STARTED:
    case SUSPEND_EMPLOYER.STARTED:
      return applyEmployerStatusUpdateStarted(state);
    case PUBLISH_EMPLOYER.SUCCEEDED:
    case ACTIVATE_EMPLOYER.SUCCEEDED:
    case SUSPEND_EMPLOYER.SUCCEEDED:
      return applyEmployerStatusUpdateSucceeded(state);
    case PUBLISH_EMPLOYER.ERRORED:
    case ACTIVATE_EMPLOYER.ERRORED:
    case SUSPEND_EMPLOYER.ERRORED:
      return applyEmployerStatusUpdateErrored(state, action);
    default:
      return state;
  }
};
