import { createRequestTypes } from 'common/websocket-redux';

export const FETCH_BENEFIT_PRICE_BY_ID = 'benefits/FETCH_BENEFIT_PRICE_BY_ID';
export const SUBMIT_BENEFIT_PRICE_CONFIG =
  'benefits/SUBMIT_BENEFIT_PRICE_CONFIG';
export const ERROR_PARSING_JSON = 'benefits/ERROR_PARSING_JSON';
export const CLEAR_BENEFIT_PRICE_CONFIG_STATE =
  'benefits/CLEAR_BENEFIT_PRICE_CONFIG_STATE';
export const FETCH_BENEFIT_PLANS_FOR_PRICING_CONFIG =
  'benefits/FETCH_BENEFIT_PLANS_FOR_PRICING_CONFIG';
export const REQUEST_BENEFIT_PRICES_CSV = 'benefits/REQUEST_BENEFIT_PRICES_CSV';
export const FETCH_BENEFIT_PRICES_CSV = createRequestTypes(
  'benefits/BENEFIT_PRICES_CSV',
);
