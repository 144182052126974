import {
  REQUEST_INVOICE_DRY_RUN,
  DOWNLOAD_INVOICE_CSV,
  TOGGLE_INVOICE_STATUS,
  SAVE_INVOICE_STATUS,
  DOWNLOAD_EXTERNAL_INVOICE,
} from './invoices.types';

export const invoiceDryRun = payload => ({
  type: REQUEST_INVOICE_DRY_RUN,
  payload,
});

export const downloadInvoiceCsv = () => ({
  type: DOWNLOAD_INVOICE_CSV,
});

export const toggleInvoiceStatus = payload => ({
  type: TOGGLE_INVOICE_STATUS.BASE,
  payload,
});

export const saveInvoiceStatus = payload => ({
  type: SAVE_INVOICE_STATUS.BASE,
  payload,
});

export const downloadExternalInvoice = payload => ({
  type: DOWNLOAD_EXTERNAL_INVOICE.BASE,
  payload,
});
