import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { debounce } from 'lodash';
import {
  Checkbox,
  Label,
  ChoiceText,
  genesisStyled,
  css,
  Flex,
  LabelText,
  TextInput,
  Spinner,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
  ANALYTICS_EVENT_HR_ADMIN_FILTER,
} from '../../../../../employer-experience-v2/constants';

const StyledField = genesisStyled(Form.Field)(
  css({
    width: '48%',
  }),
);

const SearchForm = ({ onSearchParamsChanged, loading, isAdmin }) => {
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState('');
  const [includeSuspended, setIncludeSuspended] = useState(false);
  const [adminRoleFilter, setAdminRoleFilter] = useState(false);

  useEffect(() => {
    onSearchParamsChanged({ includeSuspended, adminRoleFilter, query });
  }, [
    query,
    onSearchParamsChanged,
    includeSuspended,
    adminRoleFilter,
    isAdmin,
  ]);

  const sendAnalytics = (detail, subDetail) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.HR_ADMIN,
      screen_name: ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
      detail,
      sub_detail: subDetail,
      sub_product_area: null,
    });
  };

  const debouncedSearch = debounce(async criteria => {
    await setQuery(criteria);
    if (!isAdmin) sendAnalytics('search', criteria);
  }, 300);

  return (
    <Form className="employees-search-form">
      <Flex width="100%" justifyContent="space-between">
        <StyledField>
          <Label marginBottom="one">
            <LabelText marginBottom="quarter">
              {formatMessage({ id: 'SEARCH_EMPLOYEES' })}
            </LabelText>
            <TextInput
              name="text_input"
              onChange={event => debouncedSearch(event.target.value)}
            />
          </Label>
          <Label className="subtext">
            {formatMessage({ id: 'EMPLOYEE_SEARCH_HINT' })}
          </Label>
        </StyledField>
        <StyledField>
          <LabelText paddingLeft={5}>
            {formatMessage({ id: 'EMPLOYEE_SEARCH_FILTER_LABEL' })}
          </LabelText>
          <Label marginBottom="one" paddingLeft={5}>
            <Flex height="50px" alignItems="center">
              <Checkbox
                defaultChecked={!includeSuspended}
                name="checkbox-group"
                value={!includeSuspended}
                onChange={e => {
                  setIncludeSuspended(!e.target.checked);
                  if (!isAdmin)
                    sendAnalytics(
                      ANALYTICS_EVENT_HR_ADMIN_FILTER,
                      includeSuspended ? 'checked' : 'unchecked',
                    );
                }}
              />
              <ChoiceText marginLeft="threeQuarters">
                {formatMessage({ id: 'ACTIVE_EMPLOYEE_SEARCH_FILTER_TEXT' })}
              </ChoiceText>
            </Flex>
          </Label>
          {isAdmin && (
            <Label marginBottom="one" paddingLeft={5}>
              <Flex height="50px" alignItems="center">
                <Checkbox
                  defaultChecked={adminRoleFilter}
                  name="checkbox-group"
                  value={adminRoleFilter}
                  onChange={e => setAdminRoleFilter(e.target.checked)}
                />
                <ChoiceText marginLeft="threeQuarters">
                  {formatMessage({ id: 'HR_ADMIN_SEARCH_FILTER_TEXT' })}
                </ChoiceText>
              </Flex>
            </Label>
          )}
        </StyledField>
      </Flex>
      <Spinner isLoading={loading} />
    </Form>
  );
};

SearchForm.propTypes = {
  onSearchParamsChanged: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default SearchForm;
