import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { useHistory } from 'react-router-dom';
import reducer from 'common/state/reducer';
import { rootSaga } from 'common/state/root.saga';
import { createConfiguredStore } from '../../store/store';
import { RootRoutes } from './root.routes';
import { AppErrorMessage } from 'common/components/app-error-message/app-error-message.view';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';

export function RootApp() {
  const history = useHistory();
  const [store] = useState(() =>
    createConfiguredStore({
      reducer: reducer(history),
      history,
      saga: rootSaga,
    }),
  );

  return (
    <Provider store={store}>
      <ObservabilityErrorBoundary fallback={<AppErrorMessage />}>
        <RootRoutes />
      </ObservabilityErrorBoundary>
    </Provider>
  );
}
