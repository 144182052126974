import { takeLatest, put, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import {
  getInvoicingDiagnostics,
  clearInvoicingDiagnostics,
} from 'common/services';
import { getNetSuiteInvoiceReport } from 'common/services/payroll';
import { get } from 'lodash';
import {
  CLEAR_INVOICING_DIAGNOSTICS,
  GET_INVOICING_DIAGNOSTICS,
  REQUEST_CLEAR_INVOICING_DIAGNOSTICS,
  REQUEST_GET_INVOICING_DIAGNOSTICS,
  GET_NETSUITE_INVOICE_REPORT,
  REQUEST_GET_NETSUITE_INVOICE_REPORT,
} from './finance-invoicing.types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestInvoiceDiagnostics(payload) {
  try {
    yield put(GET_INVOICING_DIAGNOSTICS.start());
    const response = yield getInvoicingDiagnostics(payload);
    const contentId = get(response, 'info');
    yield put(GET_INVOICING_DIAGNOSTICS.success(contentId));
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'GET_INVOICE_DIAGNOSTICS_ERROR',
    });
    yield put(GET_INVOICING_DIAGNOSTICS.error());
  }
}

export function* requestClearInvoiceDiagnostics() {
  yield request(CLEAR_INVOICING_DIAGNOSTICS, clearInvoicingDiagnostics);
}

export function* showClearInvoiceDiagnosticSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'CLEAR_INVOICE_DIAGNOSTICS_SUCCESS',
  });
}

export function* showClearInvoiceDiagnosticsErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'CLEAR_INVOICE_DIAGNOSTICS_ERROR',
  });
}

export function* requestNetSuiteInvoiceReport(action) {
  try {
    yield put(GET_NETSUITE_INVOICE_REPORT.start());
    const response = yield getNetSuiteInvoiceReport(action.payload);
    const contentId = response?.info?.report?.report_files?.[0]?.content_id;
    if (contentId) {
      yield put(GET_NETSUITE_INVOICE_REPORT.success(contentId));
    } else {
      yield put(GET_NETSUITE_INVOICE_REPORT.error());
    }
  } catch (error) {
    yield put(GET_NETSUITE_INVOICE_REPORT.error());
  }
}

export function* showGetNetSuiteInvoiceReportSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'GET_NETSUITE_INVOICE_REPORT_SUCCESS',
  });
}

export function* showGetNetSuiteInvoiceReportErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'GET_NETSUITE_INVOICE_REPORT_ERROR',
  });
}

export function* financeToolsInvoicingSaga() {
  yield takeLatest(
    REQUEST_GET_INVOICING_DIAGNOSTICS,
    requestInvoiceDiagnostics,
  );
  yield takeLatest(
    REQUEST_CLEAR_INVOICING_DIAGNOSTICS,
    requestClearInvoiceDiagnostics,
  );
  yield takeLatest(
    CLEAR_INVOICING_DIAGNOSTICS.SUCCEEDED,
    showClearInvoiceDiagnosticSuccessToast,
  );
  yield takeLatest(
    CLEAR_INVOICING_DIAGNOSTICS.ERRORED,
    showClearInvoiceDiagnosticsErrorToast,
  );
  yield takeLatest(
    REQUEST_GET_NETSUITE_INVOICE_REPORT,
    requestNetSuiteInvoiceReport,
  );
  yield takeLatest(
    GET_NETSUITE_INVOICE_REPORT.SUCCEEDED,
    showGetNetSuiteInvoiceReportSuccessToast,
  );
  yield takeLatest(
    GET_NETSUITE_INVOICE_REPORT.ERRORED,
    showGetNetSuiteInvoiceReportErrorToast,
  );
}
