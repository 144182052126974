import { keyBy } from 'lodash';
import { enrollmentSteps } from './enrollment-steps';
import {
  EnrollmentStep,
  BenefitSet,
  Benefit,
  BenefitSelection,
} from 'common/types/enrollment-types';
import { LAYOUT_TYPES } from 'apps/enrollment/enrollment.constants';
/**
 * Enhance a benefit set's linked selections by populating them with the linked
 * benefit names. Benefit sets can have a key called `linked_selection_sets` with
 * the following structure:
 *
 *    [ { [selection]: [id, ...] } ]
 *
 * `selection` is the benefit selection option that is linked (such as "dependent_coverage")
 * and it's value is an array of benefit set IDs which it shares this selection with.
 * This function turns the array of IDs into an array of objects, each containing
 * the benefit set ID and it's name:
 *
 * [ { [selection]: [ { id, name }, ... ] }]
 *
 * @param {Object} set - a benefit set with possible linked benefit set selections
 * @param {Array<Object>} allSetsById  - all benefit sets available
 * @return {Object} the `set` with enhanced `linked_selection_sets` data
 */
export const withLinkedBenefitSets = (set: BenefitSet, allSetsById: any) => {
  const linkedSelectionSets = set.linked_selection_sets || [];

  const setsWithNames = linkedSelectionSets.reduce(
    (sets, linked) => ({
      ...sets,
      [linked.key]: linked.set_ids.map(id => ({
        id,
        name: allSetsById?.[id]?.displayable_name,
      })),
    }),
    {},
  );
  return {
    ...set,
    linked_selection_sets: setsWithNames,
  };
};

/**
 * @function getEnrollmentSteps
 * This iterates the predefined enrollment navigation steps and parses
 * benefitSets. The idea is to hide navigation that does not contain any sets.
 *
 * @param {object[]} benefitSets - array of benefit sets from the services response to `get_benefit_plan_options`
 * @param {object[]} benefitSelection - array of the benefit selection objects from the response to `get_benefit_selection`
 *
 * @returns {} - object keyed by step id
 *
 */

export function getEnrollmentSteps(
  benefitSets: Array<BenefitSet> = [],
  benefitSelections: Array<BenefitSelection> = [],
): Array<EnrollmentStep> {
  const benefitSetsById = keyBy(benefitSets, 'id');
  const benefitSelectionById = keyBy(benefitSelections, 'benefit_id');

  return enrollmentSteps.reduce((steps: any, step: EnrollmentStep) => {
    const stepBenefitSets = benefitSets.filter(
      (set: BenefitSet) => set.type === step.id,
    );
    const stepHasEligibleBenefits = stepBenefitSets.some(set =>
      set.benefits.some(
        (benefit: Benefit) =>
          (benefitSelectionById?.[benefit.benefit_id]?.ineligible ?? false) !==
          true,
      ),
    );
    if (stepBenefitSets.length > 0 && stepHasEligibleBenefits) {
      const enhancedBenefitSets = stepBenefitSets.map(set =>
        withLinkedBenefitSets(set, benefitSetsById),
      );
      return {
        ...steps,
        [step.id]: {
          ...step,
          benefitSets: enhancedBenefitSets,
        },
      };
    }
    return steps;
  }, {});
}

// @TODO - Need to rename this function to getEnrollmentStepsForCoverageSummary in future
export function getGenericEnrollmentSteps(
  benefitSets: Array<BenefitSet> = [],
  benefitSelections: Array<BenefitSelection> = [],
  summarySteps: Array<any> = [],
): Array<EnrollmentStep> {
  const benefitSetsById = keyBy(benefitSets, 'id');
  const benefitSelectionById = keyBy(benefitSelections, 'benefit_id');

  // TODO: This is temporary while we are only using get_enrollment_steps to generate coverage summary for generic benefit sets, phase 4 we will migrate all of the coverage summary to use get_enrollment_steps
  const genericEnrollmentSteps = summarySteps
    .filter((step: any) =>
      step?.sets?.some((stepSet: any) => stepSet.layout === LAYOUT_TYPES.BASIC),
    )
    .reduce((steps: any, step: any) => {
      const stepBenefitSetsById = step?.sets.map((set: any) => set.id);
      const stepBenefitSets = benefitSets.filter((set: any) =>
        stepBenefitSetsById.includes(set.id),
      );
      const stepHasEligibleBenefits = stepBenefitSets.some(set =>
        set.benefits.some(
          (benefit: Benefit) =>
            (benefitSelectionById?.[benefit.benefit_id]?.ineligible ??
              false) !== true,
        ),
      );
      if (stepBenefitSets.length > 0 && stepHasEligibleBenefits) {
        const enhancedBenefitSets = stepBenefitSets.map(set =>
          withLinkedBenefitSets(set, benefitSetsById),
        );

        const { id, title: translatedName, icon } = step;

        return {
          ...steps,
          [step.id]: {
            id,
            translatedName,
            icon,
            benefitSets: enhancedBenefitSets,
          },
        };
      }
      return steps;
    }, {});

  return { ...genericEnrollmentSteps };
}
