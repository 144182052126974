/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { BodyOne, Flex, SubtitleOne } from '@leagueplatform/genesis-commons';
import { Logo } from 'common/components/Logos/Logo';
import {
  AuthBackgroundImage,
  AuthWrapper,
  AuthErrorWrapper,
  AuthTopSectionWrapper,
  AuthSignInLink,
  AuthBottomSection,
} from 'apps/auth/auth0/auth.parts';
import { useParams } from 'react-router-dom';
import { HtmlToReact } from '@leagueplatform/web-common';
import { FormattedMessage } from 'react-intl';

const ContactUsAnchorFormatter = chunks => (
  <a href="mailto:help@league.com">{chunks}</a>
);

export const AuthErrorView = () => {
  const { formatMessage } = useIntl();
  const { error_message } = useParams();
  const genericErrorString = formatMessage(
    {
      id: 'AUTH0_GENERIC_ERROR_MESSAGE',
    },
    {
      a: ContactUsAnchorFormatter,
    },
  );
  return (
    <Flex>
      <AuthWrapper>
        <AuthTopSectionWrapper>
          <Logo />
          <AuthErrorWrapper>
            <SubtitleOne as="h1">
              <FormattedMessage id="INVITE_ERROR_SOMETHING_WENT_WRONG" />
            </SubtitleOne>
            <BodyOne marginTop="half">
              {error_message && error_message !== 'generic_error'
                ? error_message
                : HtmlToReact({ htmlString: genericErrorString })}
            </BodyOne>
          </AuthErrorWrapper>
          <AuthSignInLink />
        </AuthTopSectionWrapper>
        <AuthBottomSection />
      </AuthWrapper>
      <AuthBackgroundImage />
    </Flex>
  );
};
