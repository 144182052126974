import { put } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { GET_USER_REWARD_POINTS } from './health-rewards.actions';

export function* fetchRewardPoints() {
  yield request(GET_USER_REWARD_POINTS, [
    websocketFetch,
    'get_user_league_points_rewards_balance',
  ]);
}

export function* healthRewardsSaga() {
  yield put({ type: GET_USER_REWARD_POINTS.BASE });
}
