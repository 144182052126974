import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Flex,
  BodyOne,
  HeadingThree,
  Box,
} from '@leagueplatform/genesis-commons';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { InsuranceForms } from 'common/insurance-forms/insurance-forms.container';
import {
  selectFormsIsReady,
  selectHasForms,
} from 'common/insurance-forms/insurance-forms.selectors';
import { PlanYearSelect } from 'common/components/plan-year-select';
import { createStructuredSelector } from 'reselect';
import { selectSessionId } from 'apps/auth/selectors';
import { loadBenefitFormList } from 'common/insurance-forms/insurance-forms.actions';
import { selectBenefitPlanList } from 'common/benefit-plans/benefit-plans.selectors';

const Loading = () => (
  <Box position="relative">
    <LoadingIndicator />
  </Box>
);

const EmptyState = () => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="twoAndHalf"
      backgroundColor="surface.background.secondary"
      borderRadius="small"
    >
      <HeadingThree width="100%" textAlign="center">
        {formatMessage({ id: 'NO_FORMS_AVAILABLE_FOR_EMPLOYEE_PLAN' })}
      </HeadingThree>
    </Flex>
  );
};

const EmployeeUserFormsContent = ({
  hasForms,
  benefitPlans,
  currentPlanId,
  setCurrentPlanId,
  ...props
}) => {
  const { formatMessage } = useIntl();
  return hasForms ? (
    <>
      <BodyOne marginBottom="one">
        {formatMessage({
          id: 'AVAILABLE_FORMS_FOR_EMPLOYEE_PLAN',
        })}
      </BodyOne>
      {benefitPlans?.length > 1 && (
        <Flex width={1 / 2}>
          <PlanYearSelect
            benefitPlans={benefitPlans}
            value={currentPlanId}
            width={1 / 2}
            onChange={({ value }) => setCurrentPlanId(value)}
          />
        </Flex>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <InsuranceForms {...props} planId={currentPlanId} />
    </>
  ) : (
    <EmptyState />
  );
};

const EmployeeUserFormsView = ({ isReady, fetchBenefitFormList, ...props }) => {
  const { formatMessage } = useIntl();
  const [currentPlanId, setCurrentPlanId] = useState();
  const { userId, groupId, benefitPlans } = props;

  useEffect(() => {
    if (benefitPlans) setCurrentPlanId(benefitPlans[0]?.id);
  }, [benefitPlans]);

  useDocumentTitle(`${formatMessage({ id: 'FORMS' })}`);

  useEffect(() => {
    if (userId && groupId && currentPlanId) {
      fetchBenefitFormList({
        userId,
        groupId,
        planId: currentPlanId,
      });
    }
  }, [fetchBenefitFormList, userId, groupId, currentPlanId]);

  return (
    <Flex
      flexDirection="column"
      minWidth={700}
      minHeight={300}
      maxWidth={1000}
      marginX="auto"
      marginY="none"
    >
      {isReady ? (
        <EmployeeUserFormsContent
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
          currentPlanId={currentPlanId}
          setCurrentPlanId={setCurrentPlanId}
        />
      ) : (
        <Loading />
      )}
    </Flex>
  );
};

const withState = connect(
  createStructuredSelector({
    sessionId: selectSessionId,
    benefitPlans: selectBenefitPlanList,
    isReady: selectFormsIsReady,
    hasForms: selectHasForms,
  }),
  { fetchBenefitFormList: loadBenefitFormList },
);

export const EmployeeUserForms = compose(withState)(EmployeeUserFormsView);

EmployeeUserFormsContent.propTypes = {
  hasForms: PropTypes.bool.isRequired,
  benefitPlans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  currentPlanId: PropTypes.string.isRequired,
  setCurrentPlanId: PropTypes.func.isRequired,
};

EmployeeUserFormsView.propTypes = {
  isReady: PropTypes.bool.isRequired,
  fetchBenefitFormList: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  benefitPlans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
};
