import PropTypes from 'prop-types';

export const fieldProps = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    value: PropTypes.any,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
      value: PropTypes.any.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasError: PropTypes.bool,
};
