export const mapEmployerBenefitDocumentToPayload = ({
  title,
  type,
  contentId,
  displayTitle,
}) => ({
  type: 'content_reference',
  display_title: displayTitle,
  content: {
    title,
    content_id: contentId,
    content_type: type,
  },
});
