import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik-legacy';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { selectGroupId, selectBillingUpdateStatus } from '../../selectors';
import {
  groupBillingPreferenceFormSubmitted,
  resetUpdateStatus,
} from '../../actions';
import BillingPreferencePresenter from './billing-preference.view';

const withFormikEnhancer = Formik({
  handleSubmit: (values, { props }) => {
    props.groupBillingPreferenceFormSubmitted({
      groupId: props.groupId,
      billingPreference: values.billingPreference,
    });
  },

  mapPropsToValues: props => {
    const formValues = {
      billingPreference: get(props, 'billingPreference', 'consolidated'),
      changed: false,
    };
    return formValues;
  },
});

const mapStateToProps = state => ({
  groupId: selectGroupId(state),
  updateStatus: selectBillingUpdateStatus(state),
  resetUpdateStatus,
});

export default compose(
  connect(mapStateToProps, {
    groupBillingPreferenceFormSubmitted,
    resetUpdateStatus,
  }),
  injectIntl,
  withFormikEnhancer,
)(BillingPreferencePresenter);
