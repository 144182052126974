import { GET_WALLET_TEMPLATES_FOR_GROUP } from './wallet-templates.actions';

export const initialState = null;

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export function walletTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_WALLET_TEMPLATES_FOR_GROUP.SUCCEEDED:
      return action.payload.info;
    default:
      return state;
  }
}
