const addToErrorList = (errorObj, msg, idx) => {
  const obj = errorObj;
  if (!obj.prevError) {
    obj.errors.push(msg);
    obj.prevError = true;
  }
  obj.errorIndexList.push(idx);
  return obj;
};

export const subRatesValidator = values => {
  const subRates = values.subscriptionRates;
  let subRatesErrors = {};
  let errorObj = {
    errors: [],
    prevError: false,
    errorIndexList: [],
  };

  const typeError = "the 'lower' value must be of type number";
  const negativeError = "the 'lower' value must be greater than 0";
  const lessThanError =
    "the 'lower' value must be greater than the previous row";

  subRates.forEach((item, index) => {
    const curLower = parseFloat(item.lower);

    // check and validate lower limit value
    // eslint-disable-next-line no-restricted-globals -- FIXME: automatically added for existing issue
    if (isNaN(curLower) && item.lower !== '') {
      errorObj = addToErrorList(errorObj, typeError, index);
    } else {
      // item's type is number
      if (curLower < 0) {
        // check if value is > 0 or not
        errorObj = addToErrorList(errorObj, negativeError, index);
      }
      // check if the value of the row is less than previous one -> ERROR
      if (index > 0 && curLower < parseFloat(subRates[index - 1].lower)) {
        errorObj = addToErrorList(errorObj, lessThanError, index);
      }
    }
  });
  if (errorObj.errors.length > 0) {
    subRatesErrors = {
      subscriptionRates: errorObj.errors,
      errorIndexList: errorObj.errorIndexList,
    };
  }

  return subRatesErrors;
};
