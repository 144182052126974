import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from 'lodash';
import moment from 'moment-timezone';
import TimelineItem from '../timeline-item/timeline-item.view';
import {
  MORE_INFORMATION_NEEDED,
  FRONTEND_CLAIM_STATUS_TYPES as claimTypes,
} from '@leagueplatform/web-common';

/**
 * Get a display name from a user object
 * @param {object} user - The user object
 * @returns {string} The user's name
 */
export const getName = ({ first_name: firstName, last_name: lastName }) =>
  `${firstName} ${lastName}`;

/**
 * Map a claim object to TimelineItem format
 * @param {object} claim - Claim data
 * @returns {object} Object ready for use in <TimelineItem />
 */
export const mapClaimToTimelineItem = ({
  description,
  message,
  time,
  type,
  user,
}) => ({
  author: getName(user),
  date: time,
  info: message.length > 0 ? message : [],
  needsInfo: type === MORE_INFORMATION_NEEDED,
  status: type,
  title: description,
});

/**
 * Map a claim history API response to TimelineItem components
 * @param {object} response - The api response for claim history
 * @returns {array} an array of <TimelineItem />s
 */
export const mapClaimHistoryToComponents = response =>
  response
    .filter(item => claimTypes.indexOf(item.type) !== -1)
    .sort((a, b) =>
      moment(a.date).isBefore(moment(b.date), 'minute') ? 1 : -1,
    )
    .map((item, index) => (
      <TimelineItem
        mostRecent={index === 0}
        key={uniqueId('timeline-item-')}
        claimId={item.claimId}
        onEditClaim={item.onEditClaim}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...mapClaimToTimelineItem(item)}
      />
    ));

const Timeline = ({ items }) => <div>{items}</div>;

Timeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
};

Timeline.defaultProps = {
  items: [],
};

export default Timeline;
