import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { SubtitleOne } from '@leagueplatform/genesis-commons';

import {
  selectGroupId,
  selectBillingDivisions,
} from 'apps/employer-experience/pages/EmployerDetails/selectors';

import { useIntl } from '@leagueplatform/locales';

import { PendingFeesComponent } from './pending-fees.view';
import {
  getPendingFees as getPendingFeesAction,
  issueAdhocInvoice as issueAdhocInvoiceAction,
  getAdhocInvoicePreview as getAdhocInvoicePreviewAction,
  removePendingFee,
} from './store/fees.actions';
import { selectPendingFees } from './store/fees.selectors';

const mapStateToProps = createStructuredSelector({
  pendingFees: selectPendingFees,
  groupId: selectGroupId,
  billingDivisions: selectBillingDivisions,
});

const mapDispatchToActions = {
  getPendingFees: getPendingFeesAction,
  issueInvoice: issueAdhocInvoiceAction,
  getAdhocInvoicePreview: getAdhocInvoicePreviewAction,
  removePendingFee,
};

export const PendingFees = connect(
  mapStateToProps,
  mapDispatchToActions,
)(({ getPendingFees, issueInvoice, groupId, ...props }) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    getPendingFees();
  }, [getPendingFees]);

  const issueAdhocInvoice = () => {
    const link = (
      <Link to={`/admin/employers/${groupId}/billing/invoices`}>
        {formatMessage({ id: 'ACTIVE' })}
      </Link>
    );

    const content = (
      <SubtitleOne>
        <FormattedMessage id="ISSUE_INVOICE_SUCCESS" values={{ link }} />
      </SubtitleOne>
    );

    issueInvoice(
      { group_id: groupId },
      {
        content,
      },
    );
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <PendingFeesComponent {...props} issueAdhocInvoice={issueAdhocInvoice} />
  );
});

export default PendingFees;
