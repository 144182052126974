import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HeadingFour,
  BodyOne,
  PrimaryButton,
  TextInput,
  Flex,
  Label,
  LabelText,
  Link,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { leagueFont } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import { DATE_FORMAT } from 'apps/employer-experience/pages/EmployerDetails/Employees/constants';
import { format, parseISO } from 'date-fns';
import { toTimeZoneString } from '@leagueplatform/web-common';
import { getContentUrl } from '@leagueplatform/league-content-api';

import { STATUS } from '../employer-payroll.constants';

const DayPickerWrapper = styled.div`
  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }

  .DayPickerInput input {
    padding: 0.82rem;
    width: 100%;
    ${leagueFont()};
  }
`;

export const YearToDateReportPresenter = ({
  payGroupOptions,
  billingDivisionsOptions,
  benefitClassesOptions,
  billingDivisionsValue,
  benefitClassesValue,
  payGroupValue,
  userIDs,
  startDateValue,
  endDateValue,
  setBillingDivisionsValue,
  setBenefitClassesValue,
  setPayGroupValue,
  setUserIDs,
  setStartDate,
  setEndDate,
  requestStatus,
  contentId,
  fileName,
  onRequestReport,
}) => {
  const { formatMessage } = useIntl();
  const onChange = evt => {
    try {
      const inputArray = evt.target.value.replace(/\s/g, '').split(',');
      setUserIDs(inputArray);
    } catch (err) {
      setUserIDs([]);
    }
  };

  return (
    <Box>
      <HeadingFour marginBottom="one">
        {formatMessage({ id: 'YTD_REPORT' })}
      </HeadingFour>
      <BodyOne marginBottom="one" color="onSurface.text.subdued">
        {formatMessage({ id: 'YTD_REPORT_COPY' })}
      </BodyOne>
      <Flex justifyContent="space-between" marginY="oneAndHalf">
        <Label minWidth="49%" zIndex="1">
          <LabelText>{formatMessage({ id: 'START_DATE' })}</LabelText>
          <DayPickerWrapper>
            <StyledDayPickerInput
              placeholder={DATE_FORMAT}
              onDayChange={value => {
                setStartDate(
                  toTimeZoneString({ date: value.toDate(), timezone: 'utc' }),
                );
              }}
              value={
                startDateValue &&
                format(parseISO(startDateValue), 'dd-MMM-yyyy')
              }
            />
          </DayPickerWrapper>
        </Label>
        <Label minWidth="49%" zIndex="1">
          <LabelText>{formatMessage({ id: 'YTD_END_DATE' })}</LabelText>
          <DayPickerWrapper minWidth="49%">
            <StyledDayPickerInput
              placeholder={DATE_FORMAT}
              onDayChange={value => {
                setEndDate(
                  toTimeZoneString({ date: value.toDate(), timezone: 'utc' }),
                );
              }}
              value={
                endDateValue && format(parseISO(endDateValue), 'dd-MMM-yyyy')
              }
            />
          </DayPickerWrapper>
        </Label>
      </Flex>
      <Flex justifyContent="space-between" marginY="oneAndHalf">
        <Label minWidth="49%">
          <LabelText>{formatMessage({ id: 'BENEFIT_CLASS' })}</LabelText>
          <Select
            options={benefitClassesOptions}
            value={benefitClassesValue}
            onChange={event => setBenefitClassesValue(event.value)}
          />
        </Label>
        <Label minWidth="49%">
          <LabelText>{formatMessage({ id: 'BILLING_DIVISIONS' })}</LabelText>
          <Select
            options={billingDivisionsOptions}
            value={billingDivisionsValue}
            onChange={event => setBillingDivisionsValue(event.value)}
          />
        </Label>
      </Flex>
      <Flex justifyContent="space-between" marginY="oneAndHalf">
        <Label minWidth="49%">
          <LabelText>{formatMessage({ id: 'PAY_GROUP' })}</LabelText>
          <Select
            aria-label={formatMessage({ id: 'PAY_GROUP' })}
            options={payGroupOptions}
            value={payGroupValue}
            onChange={event => setPayGroupValue(event.value)}
          />
        </Label>
        <Label minWidth="49%">
          <LabelText>
            {formatMessage({ id: 'COMMA_SEPARATED_USER_IDS' })}
          </LabelText>
          <TextInput
            marginBottom="one"
            value={userIDs.join(', ')}
            onChange={onChange}
          />
        </Label>
      </Flex>
      <PrimaryButton
        onClick={onRequestReport}
        disabled={requestStatus === STATUS.LOADING}
      >
        {formatMessage({ id: 'REQUEST_REPORT' })}
      </PrimaryButton>
      {contentId && (
        <Link marginLeft="one" href={getContentUrl(contentId)}>
          {`${formatMessage({ id: 'DOWNLOAD' })} ${fileName}`}
        </Link>
      )}
    </Box>
  );
};

YearToDateReportPresenter.propTypes = {
  payGroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  billingDivisionsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  benefitClassesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  billingDivisionsValue: PropTypes.string,
  benefitClassesValue: PropTypes.string,
  payGroupValue: PropTypes.string,
  startDateValue: PropTypes.string,
  endDateValue: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  userIDs: PropTypes.arrayOf(PropTypes.any),
  setBillingDivisionsValue: PropTypes.func.isRequired,
  setBenefitClassesValue: PropTypes.func.isRequired,
  setPayGroupValue: PropTypes.func.isRequired,
  setUserIDs: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  requestStatus: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onRequestReport: PropTypes.func.isRequired,
};

YearToDateReportPresenter.defaultProps = {
  payGroupOptions: [],
  billingDivisionsOptions: [],
  benefitClassesOptions: [],
  billingDivisionsValue: '',
  benefitClassesValue: '',
  payGroupValue: '',
  startDateValue: undefined,
  endDateValue: undefined,
  userIDs: [],
};
