/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getUserBenefitFlexDollars = ({
  groupId: group_id,
  planId: plan_id,
  userId: user_id,
}) =>
  call(websocketFetch, 'get_user_benefit_flex_dollars', {
    group_id,
    plan_id,
    user_id,
  });
