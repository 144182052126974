import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Step } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const Header = ({ activeStepIndex, steps }) => (
  <Step.Group attached="top" ordered>
    {map(steps, step => (
      <Step
        key={step.index}
        active={activeStepIndex === step.index}
        completed={activeStepIndex > step.index}
      >
        <Step.Content>
          <Step.Title>
            <FormattedMessage id={step.title} />
          </Step.Title>
          <Step.Description>
            <FormattedMessage id={step.description} />
          </Step.Description>
        </Step.Content>
      </Step>
    ))}
  </Step.Group>
);

Header.propTypes = {
  activeStepIndex: PropTypes.number.isRequired,
  steps: PropTypes.shape({
    index: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default Header;
