import { useState, useEffect } from 'react';

export const useRateSheetExtractHandlers = ({ structuredEmployeeBenefits }) => {
  const [data, setData] = useState(structuredEmployeeBenefits);
  const [treeData, setTreeData] = useState(data);
  const [cursor, setCursor] = useState();

  useEffect(() => {
    setData(structuredEmployeeBenefits);
    setTreeData(structuredEmployeeBenefits);
  }, [structuredEmployeeBenefits]);

  // traverse the tree and get class ids and benefit ids
  const generatePayload = ({ node, classIds = [], benefitIds = [] }) => {
    if (node.checked && node.benefitId) {
      benefitIds.push(node.benefitId);
    }
    if (node.checked && node.classId) {
      classIds.push(node.classId);
    }

    // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
    for (const child of node.children ?? []) {
      generatePayload({ node: child, classIds, benefitIds });
    }
    return { classIds, benefitIds };
  };

  // save toggle state on main data tree
  const saveToggledData = ({ node, selectedNode }) => {
    const root = node;
    if (node.name === selectedNode.name) {
      root.toggled = selectedNode.toggled;
      return;
    }
    // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
    for (const child of node.children ?? []) {
      saveToggledData({ node: child, selectedNode });
    }
  };

  // evaluate parent checked status (when children changes)
  const evaluateTieredClick = ({ node }) => {
    const root = node;
    if (!node.children) return node;

    const allChildrenClicked = (node.children ?? [])
      .map(child => evaluateTieredClick({ node: child }))
      .every(checked => checked.checked);
    root.checked = allChildrenClicked;

    return root;
  };

  const defaultMatcher = (filterText, node) => {
    return (
      node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
      node.benefitId === filterText ||
      node.classId === filterText
    );
  };

  // filter nodes/children nodes that match
  const filterChildNode = (node, filter, matcher) => {
    return (
      matcher(filter, node) ||
      (node.children &&
        node.children.length &&
        Boolean(
          node.children.find(child => filterChildNode(child, filter, matcher)),
        ))
    );
  };

  // filter tree using search bar
  const filterTree = (node, filter, matcher = defaultMatcher) => {
    // keep all children if current node matches
    if (matcher(filter, node) || !node.children) {
      return node;
    }
    // keep the ones that match or have matching descendants
    const filtered = node.children
      .filter(child => filterChildNode(child, filter, matcher))
      .map(child => filterTree(child, filter, matcher));
    return { ...node, children: filtered };
  };

  // when tree header is clicked
  const onToggle = (node, toggled) => {
    const selectedNode = node;
    if (cursor) {
      cursor.active = false;
    }
    selectedNode.active = true;
    if (selectedNode.children) {
      selectedNode.toggled = toggled;
      saveToggledData({ node: data, selectedNode });
    }
    setCursor(selectedNode);
    setTreeData(evaluateTieredClick({ node: treeData }));
    setData({ ...data }); // save the tree
  };

  const onChange = evt =>
    setTreeData(
      evaluateTieredClick({
        node: filterTree(data, evt.currentTarget.value),
      }),
    );

  return {
    onToggle,
    onChange,
    generatePayload,
    data,
    treeData,
  };
};

export default useRateSheetExtractHandlers;
