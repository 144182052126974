import {
  GET_RATE_SHEET_EXTRACT,
  GET_USER_BENEFITS,
  GET_USER_BENEFIT_CONTRIBUTIONS,
  GET_REPORTS,
  LOAD_PREMIUMS_EXPORTS,
  GET_YEAR_TO_DATE_REPORT,
  GET_REPORT_PRESETS,
  SET_REPORT_PRESETS,
  GET_CUSTOMIZED_REPORT,
  GET_PAYROLL_REPORT_CONFIGS,
} from './employer-payroll.types';

export const getRateSheetExtract = payload => ({
  type: GET_RATE_SHEET_EXTRACT.BASE,
  payload,
});

export const getUserBenefits = payload => ({
  type: GET_USER_BENEFITS.BASE,
  payload,
});

export const getUserBenefitContributions = payload => ({
  type: GET_USER_BENEFIT_CONTRIBUTIONS.BASE,
  payload,
});

export const getReports = payload => ({
  type: GET_REPORTS.BASE,
  payload,
});

export const loadPayroll = payload => {
  return {
    type: LOAD_PREMIUMS_EXPORTS.BASE,
    payload,
  };
};

export const getYearToDateReport = payload => ({
  type: GET_YEAR_TO_DATE_REPORT.BASE,
  payload,
});

export const getReportPresets = payload => ({
  type: GET_REPORT_PRESETS.BASE,
  payload,
});

export const setReportPresets = payload => ({
  type: SET_REPORT_PRESETS.BASE,
  payload,
});

export const getCustomizedReport = payload => ({
  type: GET_CUSTOMIZED_REPORT.BASE,
  payload,
});

export const getPayrollReportConfigs = payload => ({
  type: GET_PAYROLL_REPORT_CONFIGS.BASE,
  payload,
});
