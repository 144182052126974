import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName } from 'recompose';
import { Formik } from 'formik-legacy';
import { Container } from 'semantic-ui-react';
import { combineValidators } from 'common/adaptive-forms';
import { validateDateRange } from '../benefits/utilities';
import { BENEFIT_LIFECYCLE } from '../benefits/benefits.types';
import {
  mapPropsToValues,
  createValidatorSelector,
  createFormGeneratorSelector,
  selectUserBenefitSchema,
} from './benefits.selectors';
import { benefitSubmitted } from '../benefits/benefit.actions';
import { selectGroupCurrency } from '../app.selectors';

const Default = props => (
  <Container className="container--benefit-product">
    {/* eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue */}
    {props.generateLayout(props)}
  </Container>
);

Default.propTypes = {
  generateLayout: PropTypes.func.isRequired,
};

const mapStateToProps = (
  state,
  { benefitType, layoutName, customComponents, customSchemaSpecs },
) => ({
  validate: createValidatorSelector(benefitType)(state),
  generateLayout: createFormGeneratorSelector(
    benefitType,
    layoutName,
    customComponents,
    customSchemaSpecs,
    ['Parent'],
  )(state),
  schema: selectUserBenefitSchema(benefitType)(state),
  groupCurrency: selectGroupCurrency(state),
});

const withFormikEnhancer = Formik({
  validate: (values, { validate }) => {
    return combineValidators(validate, validateDateRange)(values);
  },
  handleSubmit: (values, { props }) => {
    props.benefitSubmitted(values);
  },
  mapPropsToValues,
});

const withInitializer = lifecycle({
  componentDidMount() {
    this.props.visit();
  },

  componentWillUnmount() {
    this.props.exit();
  },

  componentWillReceiveProps(nextProps) {
    if (!this.props.schema && nextProps.schema) {
      this.props.resetForm();
    }
  },
});

export default compose(
  setDisplayName('EmployeeBenefitDefault'),
  connect(mapStateToProps, {
    benefitSubmitted,
    visit: BENEFIT_LIFECYCLE.visit,
    exit: BENEFIT_LIFECYCLE.exit,
  }),
  withFormikEnhancer,
  withInitializer,
)(Default);
