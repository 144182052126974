/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { createSelector } from 'reselect';
import { selectEmployerDetails } from '../../selectors';

export const selectGroupVendors = createSelector(
  selectEmployerDetails,
  employerDetails => {
    const groupVendors = employerDetails?.salesforceSync?.groupVendors;

    if (!groupVendors) return [];

    return groupVendors
      .filter(({ vendor_id }) => vendor_id !== 'league')
      .map(({ vendor_id, name }) => ({
        value: vendor_id,
        label: name,
      }));
  },
);

export const selectCreateCasesForEmployeeCOBRA = createSelector(
  selectEmployerDetails,
  employerDetails =>
    employerDetails?.employer?.productVendorSyncConfig
      ?.createCasesForEmployeeCobra,
);

export const selectIsSaving = createSelector(
  selectEmployerDetails,
  employerDetails => {
    return employerDetails?.salesforceSync?.isSaving;
  },
);

export const selectGroupVendorSync = createSelector(
  selectEmployerDetails,
  employerDetails =>
    employerDetails?.employer?.productVendorSyncConfig?.vendors,
);
