import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  BodyTwoSecondary,
  SubtitleOne,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { Icon, ICONS, genesisFocus } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { postToWR } from 'common/utilities';
import { getBeneficiariesUrl } from '../beneficiaries.utilities';

export const YourBeneficiaries = ({
  totalBeneficiaries,
  beneficiariesLastUpdated,
  history,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const theme = useTheme();

  const goToBeneficiariesListView = () => {
    const path = getBeneficiariesUrl('list');
    postToWR('updateUrl', { path });
    history.push(path);
  };

  return (
    <Flex
      as="button"
      width={1}
      backgroundColor="transparent"
      borderRadius="small"
      borderStyle="solid"
      borderWidth="thin"
      borderColor="interactive.background.disabled"
      marginTop="oneAndHalf"
      paddingY="oneAndHalf"
      paddingX="two"
      justifyContent="space-between"
      alignItems="center"
      onClick={goToBeneficiariesListView}
      hoverStyle={{
        cursor: 'pointer',
      }}
      focusStyle={genesisFocus}
    >
      <Box textAlign="left">
        <SubtitleOne as="span">
          {formatMessage(
            { id: 'DIGITAL_BENEFICIARIES_CARD_TOTAL_BENEFICIARIES' },
            { totalBeneficiaries },
          )}
        </SubtitleOne>
        {beneficiariesLastUpdated && (
          <BodyTwoSecondary marginTop="quarter">
            {formatMessage(
              { id: 'DIGITAL_BENEFICIARIES_CARD_LAST_UPDATED' },
              {
                lastUpdated: formatDate(beneficiariesLastUpdated, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }),
              },
            )}
          </BodyTwoSecondary>
        )}
      </Box>
      <Icon
        icon={ICONS.CHEVRON_RIGHT}
        colour={theme.colors.onSurface.text.subdued}
      />
    </Flex>
  );
};

YourBeneficiaries.propTypes = {
  totalBeneficiaries: PropTypes.number.isRequired,
  beneficiariesLastUpdated: PropTypes.string,
  history: {
    push: PropTypes.func,
  }.isRequired,
};

YourBeneficiaries.defaultProps = {
  beneficiariesLastUpdated: null,
};
