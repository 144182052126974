import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  BodyOne,
  SubtitleOne,
  Link,
  ErrorMessage,
} from '@leagueplatform/genesis-commons';
import { FormCard } from 'common/insurance-forms/form-card.view';
import { InfoBannerCard } from 'common/info-banner-card/info-banner-card.view';
import {
  ACTION_DOWNLOAD_BENEFICIARIES_FORM,
  INSURANCE_FORM_STATUS_TYPES,
} from 'common/insurance-forms/insurance-forms.constants';
import { useGetUserBenefitForm } from 'common/insurance-forms/use-get-user-benefit-form.hook';
import { LoadingSecondaryButton } from './loading-secondary-button.view';
import {
  ScreenReaderAdvanceWarning,
  NEW_TAB,
} from 'apps/enrollment/ui-kit/screen-reader-advance-warning.view';

export const PaperBeneficiariesCard = ({
  available: isFormAvailable,
  uploadDocumentsLink,
  isLoading,
  url,
  sendAnalytics,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const showDownloadButton = isFormAvailable && (url || isLoading);

  const getUserBenefitForm = useGetUserBenefitForm(props);

  useEffect(() => {
    getUserBenefitForm();
  }, [getUserBenefitForm]);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isLoading) setIsReady(true);
  }, [isLoading]);

  const showErrorMsg = isReady && isFormAvailable && !url && !isLoading;

  const bannerInstructionsId = isFormAvailable
    ? 'PAPER_BENEFICIARIES_FORM_READY_INSTRUCTIONS'
    : 'FORM_NOT_READY_INSTRUCTIONS';

  const downloadForm = () => {
    if (!isLoading) {
      if (sendAnalytics) sendAnalytics(ACTION_DOWNLOAD_BENEFICIARIES_FORM);
      window.open(url, '_blank', 'noopener=true,noreferrer=true');
    }
  };

  const strong = text => <strong>{text}</strong>;

  const tooltipText = formatMessage(
    { id: 'GENERIC_TOOLTIP_NAME' },
    { name: `${formatMessage({ id: 'BENEFICIARIES' })}` },
  );

  return (
    <FormCard
      title={formatMessage({ id: 'BENEFICIARIES' })}
      description={formatMessage({
        id: 'BENEFICIARIES_FORM_CARD_DESCRIPTION',
      })}
      formType={INSURANCE_FORM_STATUS_TYPES.RECOMMENDED}
      tooltip={
        <BodyOne>
          {formatMessage(
            { id: 'BENEFICIARIES_FORM_CARD_TOOLTIP' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { p: text => <p>{text}</p> },
          )}
        </BodyOne>
      }
      tooltipText={tooltipText}
    >
      <SubtitleOne
        as="p"
        color="onSurface.text.subdued"
        marginTop="threeQuarters"
      >
        {formatMessage({ id: 'PAPER_BENEFICIARIES_CARD_DESCRIPTION_NOTE' })}
      </SubtitleOne>
      <InfoBannerCard marginTop="oneAndHalf">
        <BodyOne>
          {formatMessage({ id: bannerInstructionsId }, { strong })}
          {isFormAvailable && (
            <>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={uploadDocumentsLink}
                marginLeft="half"
                aria-describedby={`${NEW_TAB}-learn-more`}
                aria-label={formatMessage({
                  id: 'LEARN_MORE_ABOUT_ADDING_DOCUMENTS',
                })}
              >
                {formatMessage({ id: 'LEARN_MORE' })}
              </Link>
              <ScreenReaderAdvanceWarning id={`${NEW_TAB}-learn-more`} />
            </>
          )}
        </BodyOne>
      </InfoBannerCard>

      {showDownloadButton && (
        <>
          <LoadingSecondaryButton
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            marginTop="oneAndHalf"
            width={{
              _: 1,
              tablet: 'inherit',
            }}
            onClick={downloadForm}
            isLoading={isLoading}
            aria-describedby={`${NEW_TAB}-download`}
          >
            {formatMessage({ id: 'DOWNLOAD_FORM' })}
          </LoadingSecondaryButton>
          <ScreenReaderAdvanceWarning id={`${NEW_TAB}-download`} />
        </>
      )}
      {showErrorMsg && (
        <ErrorMessage marginTop="oneAndHalf" fontWeight="bold">
          {formatMessage({
            id: 'INSURANCE_ERROR_PREPARING_FORMS',
          })}
        </ErrorMessage>
      )}
    </FormCard>
  );
};

PaperBeneficiariesCard.propTypes = {
  available: PropTypes.bool.isRequired,
  uploadDocumentsLink: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  url: PropTypes.string,
  sendAnalytics: PropTypes.func,
};

PaperBeneficiariesCard.defaultProps = {
  isLoading: false,
  url: '',
  sendAnalytics: null,
};
