import { omit } from 'lodash';
import { createSelector } from 'reselect';
import {
  createValidator,
  createFormGenerator,
  createFormValueFormatter,
} from 'common/adaptive-forms';
import { selectEmployerDetails } from '../../../../../../selectors';
import { selectBenefitClassesEntities } from '../../../../../selectors';

export const selectBenefitsClassName = (state, benefitClassId) =>
  selectBenefitClassesEntities(state)[benefitClassId].name;

export const mapPropsToValues = props => {
  const values = props.prepareFormValues
    ? props.prepareFormValues(props.values)
    : props.values;
  return {
    ...values,
    plan_id: props.benefitPlanId,
    provider_id: props.groupId,
    benefit_type: props.benefitType,
  };
};

export const selectBenefitPropsToValues = (benefit = {}) => {
  const values = {};

  const nestedBenefit = benefit.benefit;

  if (nestedBenefit) {
    values['benefit.allocation_frequency'] =
      nestedBenefit.allocation_frequency || '';
    values['benefit.rollover_policy'] = nestedBenefit.rollover_policy || '';
    values['benefit.prorate_late_enrollment'] =
      nestedBenefit.prorate_late_enrollment || false;
    values['benefit.issue_tax_receipt'] =
      nestedBenefit.issue_tax_receipt || false;
    values.dependentClassAmounts = nestedBenefit.dependent_class_amounts || {};
    values['benefit.funds_grant_start_date'] =
      nestedBenefit.funds_grant_start_date || '';
  }

  values.dependentClassAmounts = values.dependentClassAmounts || {};
  values.benefit_end_date = benefit.benefit_end_date;
  values.benefit_start_date = benefit.benefit_start_date;
  values.benefit_type = benefit.benefit_type;
  values.benefit_product_type = benefit.benefit_product_type;
  values.full_name = benefit.full_name; // REMOVE?
  values.description = benefit.description; // REMOVE?
  values.id = benefit.id;

  return values;
};

export const selectLeagueSpendingHsaSchema = createSelector(
  selectEmployerDetails,
  details => details.benefitTypeSchemas.league_spending_hsa,
);

export const selectLeagueSpendingLsaSchema = createSelector(
  selectEmployerDetails,
  details => details.benefitTypeSchemas.league_spending_lsa,
);

export const selectLeagueSpendingFsaSchema = createSelector(
  selectEmployerDetails,
  details => details.benefitTypeSchemas.league_spending_fsa,
);

export const selectLeagueSpendinCharitySchema = createSelector(
  selectEmployerDetails,
  details => details.benefitTypeSchemas.league_spending_charity,
);

export const selectBenefitSchema = name =>
  createSelector(
    selectEmployerDetails,
    details => details.benefitTypeSchemas[name],
  );

export const selectLayout =
  name =>
  ({ layouts }) =>
    layouts[name];

export const selectLeagueSpendingHsaValidator = createSelector(
  selectLeagueSpendingHsaSchema,
  createValidator,
);

export const selectLeagueSpendingLsaValidator = createSelector(
  selectLeagueSpendingLsaSchema,
  createValidator,
);

export const selectLeagueSpendingFsaValidator = createSelector(
  selectLeagueSpendingFsaSchema,
  createValidator,
);

export const selectLeagueSpendingCharityValidator = createSelector(
  selectLeagueSpendinCharitySchema,
  createValidator,
);

export const createValidatorSelector = name =>
  createSelector(selectBenefitSchema(name), createValidator);

export const createFormGeneratorSelector = (
  benefitType,
  layoutName,
  customComponents,
) =>
  createSelector(
    [selectBenefitSchema(benefitType), selectLayout(layoutName)],
    (schema, layout) =>
      createFormGenerator({
        schema: omit(schema, 'dependent_classes'),
        layout,
        customComponents,
      }),
  );
export const createFormFormatterSelector = name =>
  createSelector(selectBenefitSchema(name), createFormValueFormatter);

export const selectBenefitCreationError = createSelector(
  selectEmployerDetails,
  details => details.employerBenefits.errorAddingBenefit,
);

export const selectBenefitCreationSuccess = createSelector(
  selectEmployerDetails,
  details => !!details.employerBenefits.successCreatingEditingBenefit,
);
