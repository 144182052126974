import { useState } from 'react';

export const useContributionUpdates = ({
  userId,
  updateContributions,
  accountType,
}) => {
  const [contributionUpdates, setContributionUpdates] = useState({});
  const saveContributions = () => {
    updateContributions({
      contributions: Object.values(contributionUpdates),
      userId,
      contributionBenefitTypes: accountType.value,
    });
    setContributionUpdates({});
  };
  return {
    contributionUpdates,
    setContributionUpdates,
    saveContributions,
  };
};
