import { takeLatest, put, call } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  ADD_BENEFIT_PLAN,
  SET_BENEFIT_PLAN,
  RENAME_BENEFIT_PLAN,
  REMOVE_BENEFIT_PLAN,
  REMOVE_BENEFIT_PLAN_SERVICE,
  FETCH_CATALOGUE,
  GET_LEAGUE_BENEFITS,
  FETCH_BENEFITS,
  GET_EMPLOYER_BENEFITS,
  GET_BENEFIT_TYPE_SCHEMAS,
  FETCH_BENEFIT_TYPE_SCHEMAS,
  COPY_BENEFIT_PLAN,
  RENEW_BENEFIT_PLAN,
} from './types';
import { getEmployerBenefits } from 'common/services';
import { copyBenefitPlan, renewBenefitPlan } from 'common/services/enrollment';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* setBenefitPlan(action) {
  const { groupId, name, benefitClassId, providerType, providerId } =
    action.payload;
  yield request(SET_BENEFIT_PLAN, [
    websocketFetch,
    'set_employer_benefit_plan',
    {
      group_id: groupId,
      plan: {
        name,
        benefit_class_id: benefitClassId,
        provider_type: providerType,
        provider_id: providerId,
      },
    },
  ]);
}

export function* renameBenefitPlan(action) {
  const { groupId, name, id, benefitClassId, providerType, providerId } =
    action.payload;
  yield request(SET_BENEFIT_PLAN, [
    websocketFetch,
    'set_employer_benefit_plan',
    {
      group_id: groupId,
      plan: {
        name,
        id,
        benefit_class_id: benefitClassId,
        provider_type: providerType,
        provider_id: providerId,
      },
    },
  ]);
}

export function* removeBenefitPlan(action) {
  const { groupId, benefitPlanId } = action.payload;
  yield request(REMOVE_BENEFIT_PLAN_SERVICE, [
    websocketFetch,
    'remove_employer_benefit_plan',
    {
      group_id: groupId,
      plan_id: benefitPlanId,
    },
  ]);
}

export function* fetchCatalogue({ payload }) {
  const { groupId } = payload;
  yield request(GET_LEAGUE_BENEFITS, [
    websocketFetch,
    'get_league_benefits',
    {
      group_id: groupId,
    },
  ]);
}

export function* fetchBenefitTypeSchemas(action) {
  const { groupId } = action.payload;
  yield request(GET_BENEFIT_TYPE_SCHEMAS, [
    websocketFetch,
    'get_benefit_type_schemas',
    {
      group_id: groupId,
    },
  ]);
}

export function* fetchBenefits(action) {
  const { groupId, benefitPlanId, benefitClassId } = action.payload;
  yield request(
    GET_EMPLOYER_BENEFITS,
    getEmployerBenefits({ groupId, benefitPlanId }),
    {
      benefitClassId,
      benefitPlanId,
      groupId,
    },
  );
}

export function* requestCopyBenefitPlan({ payload }) {
  try {
    yield put(COPY_BENEFIT_PLAN.start(payload));
    const response = yield copyBenefitPlan(payload);
    yield put(COPY_BENEFIT_PLAN.success(response, payload));
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'PLAN_COPIED_SUCCESSFULLY',
    });
  } catch (error) {
    yield put(COPY_BENEFIT_PLAN.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_COPYING_PLAN',
      values: {
        error: error?.info?.reason,
      },
    });
  }
}

export function* requestRenewBenefitPlan({ payload }) {
  try {
    yield put(RENEW_BENEFIT_PLAN.start(payload));
    const response = yield renewBenefitPlan(payload);
    yield put(RENEW_BENEFIT_PLAN.success(response, payload));
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'PLAN_RENEWED_SUCCESSFULLY',
    });
  } catch (error) {
    yield put(RENEW_BENEFIT_PLAN.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_RENEWING_PLAN',
      values: {
        error: error?.info?.reason,
      },
    });
  }
}

export function* employerBenefitPlansSaga() {
  yield takeLatest(ADD_BENEFIT_PLAN, setBenefitPlan);
  yield takeLatest(FETCH_CATALOGUE, fetchCatalogue);
  yield takeLatest(FETCH_BENEFIT_TYPE_SCHEMAS, fetchBenefitTypeSchemas);
  yield takeLatest(FETCH_BENEFITS, fetchBenefits);
  yield takeLatest(RENAME_BENEFIT_PLAN, renameBenefitPlan);
  yield takeLatest(REMOVE_BENEFIT_PLAN, removeBenefitPlan);
  yield takeLatest(COPY_BENEFIT_PLAN.BASE, requestCopyBenefitPlan);
  yield takeLatest(RENEW_BENEFIT_PLAN.BASE, requestRenewBenefitPlan);
}
