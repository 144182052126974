import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectChangeAllocationStatus = get(
  'apps.employer-experience.ui.changeAllocation',
);

export const selectChangeAllocationComplete = createSelector(
  selectChangeAllocationStatus,
  get('completed'),
);
