import {
  DELETE_DEDUCTION_CODES,
  GET_DEDUCTION_CODES,
  SET_DEDUCTION_CODES,
  IMPORT_DEDUCTION_CODES,
  EXPORT_DEDUCTION_CODES,
  LOAD_DEDUCTION_CODE_EDITOR,
} from './deduction-codes.types';

export const loadDeductionCodeEditor = payload => ({
  type: LOAD_DEDUCTION_CODE_EDITOR.BASE,
  payload,
});

export const getDeductionCodes = payload => ({
  type: GET_DEDUCTION_CODES.BASE,
  payload,
});

export const setDeductionCodes = payload => ({
  type: SET_DEDUCTION_CODES.BASE,
  payload,
});

export const deleteDeductionCodes = payload => ({
  type: DELETE_DEDUCTION_CODES.BASE,
  payload,
});

export const importDeductionCodes = payload => ({
  type: IMPORT_DEDUCTION_CODES.BASE,
  payload,
});

export const exportDeductionCodes = payload => ({
  type: EXPORT_DEDUCTION_CODES.BASE,
  payload,
});
