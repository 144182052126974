import { connect } from 'react-redux';
import { selectBenefitClasses } from '../../selectors';
import { BenefitClassSelection } from './benefit-class-selection.view';
import { compose, branch, renderComponent } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
  setBenefitClassId,
  setIsEditHREmailEnabled,
} from './benefit-class-selection.action-creators';
import { withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { setHREmails } from '../qle-configuration.action-creators';
import { selectQLEApprovalEmails } from '../qle-configuration.selectors';
import { isEmail } from 'common/adaptive-forms/validation/rules';
import { changeEmailsToArray } from '../qle-configuration-form/change-emails-to-array';
import { selectGroupId } from '../../../selectors';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { selectIsEditHREmailEnabled } from './benefit-class-selection.selectors';

const withState = connect(
  createStructuredSelector({
    benefitClasses: selectBenefitClasses,
    qleApprovalEmails: selectQLEApprovalEmails,
    groupId: selectGroupId,
    isEditHREmailEnabled: selectIsEditHREmailEnabled,
  }),
  {
    setBenefitClassId,
    setHREmails,
    setIsEditHREmailEnabled,
  },
);

/**
 * `qleApprovalEmails` are stored in a string within Formik, but as an array
 * in state due to backend contract. Conversion is done to change that!
 */
export const initialValues = {
  qleApprovalEmails: '',
};

export const validate = (values, { intl }) => {
  const { qleApprovalEmails } = values;
  const errors = {};

  const hasEmptyEmails = qleApprovalEmails.length === 0;
  const hasInvalidEmails =
    qleApprovalEmails &&
    changeEmailsToArray(qleApprovalEmails).some(email => !isEmail(null, email));

  if (hasEmptyEmails) {
    errors.qleApprovalEmails = intl.formatMessage({
      id: 'QLE_CONFIG_FORM_ERROR_MISSING_HR_EMAIL',
    });
  }

  if (hasInvalidEmails) {
    errors.qleApprovalEmails = intl.formatMessage({
      id: 'PLEASE_ENTER_A_VALID_EMAIL',
    });
  }

  return errors;
};

const mapPropsToValues = ({ qleApprovalEmails }) => {
  const values = {
    ...initialValues,
  };

  /**
   * Here qleApprovalEmails are from the backend (get_hr_approval_emails).
   * They come back as an array of strings! This is where it gets a bit rough,
   * because the frontend works with strings while the backend expects an array of strings.
   */
  if (qleApprovalEmails && qleApprovalEmails.length !== 0) {
    values.qleApprovalEmails = qleApprovalEmails.join(', ');
  }

  return values;
};

const handleSubmit = (values, { props }) => {
  // `setHREmails` also lazy loads `qleApprovalEmails` into state since it's been validated already
  const emailList = changeEmailsToArray(values.qleApprovalEmails);
  if (JSON.stringify(props.qleApprovalEmails) !== JSON.stringify(emailList)) {
    props.setHREmails({
      qleApprovalEmails: emailList,
      groupId: props.groupId,
    });
  }
};

const withFormikHOC = withFormik({
  initialValues,
  validate,
  mapPropsToValues,
  handleSubmit,
  validateOnChange: false,
});

/** Do this step _before_ formik to make sure we have fetched and received QLE approval emails.
 * Without this, the email list won't be populated because Formik's initial state will be used.
 */
const withLoadingState = branch(
  ({ qleApprovalEmails }) => qleApprovalEmails === null,
  renderComponent(LoadingIndicator),
);

export default compose(
  injectIntl,
  withState,
  withLoadingState,
  withFormikHOC,
)(BenefitClassSelection);
