import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import 'raf/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './fonts.css';

import League, { LEAGUE_MODULE_NAMES, ENVIRONMENT } from '@leagueplatform/core';
import { APP_CONFIG } from 'app-config';
import { appUiConfig } from 'app-ui-config';
import {
  handleAuthenticationForExternalLinks,
  isAuth0Enabled,
} from 'apps/auth/auth.utils';
import { AUTH_ERROR_ROUTE } from 'apps/auth/auth0/auth0.constants';
import mobileAuthStateListener from 'apps/auth/auth0/auth0.mobile-auth-state-listener';
import {
  AccountRetrieval,
  DownloadAppPage,
} from 'apps/public/public-routes.view';
import { RootApp } from 'apps/root';
import { UserTerms } from 'apps/terms';
import { ROOT_NODE_ID, TENANT_ID, APP_ID } from 'common/constants';
import { CUSTOM_ENV_MAPPING } from 'common/utilities/app-config-constants';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { AllExperiences } from './all-experiences.view';
import { Auth0LandingPage } from './apps/auth/auth0-landing-page.view';
import { ErrorPages } from './apps/error';
import { Invite } from './apps/invite';
import { InviteAccess } from './apps/invite-access/invite-access.container';
import { strings } from './strings';
import { legacyStrings } from '@leagueplatform/legacy-locales';

const EnrollmentExperience = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-enroll' */ 'apps/enrollment/enrollment-experience.view'
  ),
);

const appEnvironment = Object.values(ENVIRONMENT).includes(
  APP_CONFIG.REACT_APP_ENV,
)
  ? APP_CONFIG.REACT_APP_ENV
  : CUSTOM_ENV_MAPPING[APP_CONFIG.REACT_APP_ENV];

const config = {
  core: {
    tenantId: TENANT_ID.LEAGUE,
    clientId: APP_ID,
    analytics: {
      GAPropertyId: APP_CONFIG.REACT_APP_GA,
      segmentKey: APP_CONFIG.REACT_APP_SEGMENT,
    },
    api: {
      wsUrl: APP_CONFIG.REACT_APP_API_URL,
      url: APP_CONFIG.REACT_APP_REST_API_URL,
      legacyRestApi: APP_CONFIG.REACT_APP_LEGACY_REST_API,
    },
    contentUrl: APP_CONFIG.REACT_APP_CONTENT_SERVER_URL,
    appEnvironment,
    customMethods: {
      handleLink: async url => {
        // TODO: Update function to handleAuth from @leagueplatform/auth-utils when WEBPLAT-1526 is merged
        await handleAuthenticationForExternalLinks(url, '_blank', 'noreferrer');
      },
    },
    ui: appUiConfig,
    i18n: {
      strings: {
        default: { ...legacyStrings.default, ...strings.default },
        es: { ...legacyStrings.es, ...strings.es },
        fr: { ...legacyStrings.fr, ...strings.fr },
      },
    },
  },
  healthProfile: {
    ratingsBarEnabled: true,
    careSdkEnabled: true,
    chatWithProfessionalEnabled: true,
  },
  careCollections: {
    showCareCollectionFeedbackComponent: true,
  },
  careDiscovery: {
    showGetAssistanceNowSubheading: true,
    showProviderAvailability: true,
    masonryAppId: {
      left: 'b2b_care_homepage',
    },
  },
  wallet: {
    showBenefitDetailFeedbackComponent: true,
    showBenefitStatusBannerCreated: true,
    showBenefitStatusBannerPending: true,
    showBenefitStatusBannerExpired: true,
    typeformIds: {
      en: 'KTHvQp',
      es: 'kjNBydg7',
      fr: 'RpXf0o',
    },
  },
};

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/member/health-need',
  [LEAGUE_MODULE_NAMES.wallet]: '/member/wallet',
  [LEAGUE_MODULE_NAMES.healthJourney]: '/member/health-journey',
  [LEAGUE_MODULE_NAMES.healthJourneyHistory]: '/member/health-history',
  [LEAGUE_MODULE_NAMES.healthPrograms]: '/member/health-programs',
  [LEAGUE_MODULE_NAMES.healthProgress]: '/member/health-progress',
  [LEAGUE_MODULE_NAMES.onboarding]: '/member/onboarding',
  [LEAGUE_MODULE_NAMES.rewardsAchievements]: '/member/achievements',
  [LEAGUE_MODULE_NAMES.careCollections]: '/member/care-collections',
  [LEAGUE_MODULE_NAMES.careDiscovery]: '/member/care-discovery',
  [LEAGUE_MODULE_NAMES.aboutMe]: '/member/about-me',
});

// If we are being embedded in a native webview, we need to configure a listener early to be able
// to receive authentication state.
mobileAuthStateListener.setup();

/* TODO
  Break out remaining experiences from the AllExperiences component,
  give them their own Redux stores and route to them here.
*/
ReactDOM.render(
  <League.LeagueProvider config={config}>
    <Suspense fallback={null}>
      <BrowserRouter basename={APP_CONFIG.REACT_APP_ROOT_ROUTE}>
        <Switch>
          <Route exact path="/">
            <RootApp />
          </Route>
          <Route path="/enroll" component={EnrollmentExperience} />
          <Route path="/error" component={ErrorPages} />
          <Redirect from="/terms-privacy" to="/terms" />
          <Route path="/terms" component={UserTerms} />
          <Route path="/(lookup|eligibility|verify)" component={InviteAccess} />
          {isAuth0Enabled() && (
            <Route
              path="/(start|dependent-invite)/:inviteId"
              component={Invite}
            />
          )}
          {isAuth0Enabled() && (
            <Route
              path={['/(sign-in|sign-out)', AUTH_ERROR_ROUTE]}
              component={Auth0LandingPage}
            />
          )}
          <Route path="/download-app" component={DownloadAppPage} />
          <Route path="/account-retrieval" component={AccountRetrieval} />
          <Route path="*" component={AllExperiences} />
        </Switch>
      </BrowserRouter>
      {/*
    ReactQueryDevtools is only built/shown in non-production environments, so it's fine to leave it here:
    https://react-query.tanstack.com/devtools#import-the-devtools
    */}
      <ReactQueryDevtools />
    </Suspense>
  </League.LeagueProvider>,
  document.getElementById(ROOT_NODE_ID),
);
