import { all, cancel, fork, take, takeLatest, call } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  getClaim as _getClaim,
  getClaimHistory as _getClaimHistory,
} from 'common/services';
import { claimDetailsAnalyticsSaga } from './claim-details-analytics.saga';
import {
  INITIALIZE,
  VISITED,
  EXITED,
  CANCEL_CLAIM_SELECTED,
  CANCEL_CLAIM,
} from './claim-details.actions';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

function* getClaim(claimId) {
  return (yield _getClaim(claimId)).info;
}

function* getClaimHistory(claimId) {
  return (yield _getClaimHistory(claimId)).info.history;
}

export const requestInitialResources = claimId =>
  request(
    INITIALIZE,
    all({
      claim: getClaim(claimId),
      claimHistory: getClaimHistory(claimId),
    }),
  );

export function* requestCancelClaim({ payload }) {
  const { claimId } = payload;
  yield request(CANCEL_CLAIM, [
    websocketFetch,
    'cancel_claim',
    {
      claim_id: claimId,
    },
  ]);
}

export function* showCancelClaimErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'CANCEL_CLAIM_ERROR_HEADING',
    contentId: 'CANCEL_CLAIM_ERROR_BODY',
  });
}

export function* showCancelClaimSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'CANCEL_CLAIM_SUCCESS_HEADING',
    contentId: 'CANCEL_CLAIM_SUCCESS_BODY',
  });
}

function* handleCancelClaim(action) {
  // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
  const { claim_id: claimId } = action?.payload?.info;
  yield showCancelClaimSuccessToast(action);
  yield requestInitialResources(claimId);
}

export function* claimDetailsSaga() {
  while (true) {
    const {
      payload: { claimId },
    } = yield take(VISITED);
    yield requestInitialResources(claimId);
    const analyticsSaga = yield fork(claimDetailsAnalyticsSaga);
    const cancelClaimWatcher = yield fork(
      takeLatest,
      CANCEL_CLAIM_SELECTED,
      requestCancelClaim,
    );
    const errorToastWatcher = yield fork(
      takeLatest,
      CANCEL_CLAIM.ERRORED,
      showCancelClaimErrorToast,
    );
    const handleCancelClaimWatcher = yield fork(
      takeLatest,
      CANCEL_CLAIM.SUCCEEDED,
      handleCancelClaim,
    );
    yield take(EXITED);
    yield cancel(
      analyticsSaga,
      cancelClaimWatcher,
      errorToastWatcher,
      handleCancelClaimWatcher,
    );
  }
}
