import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  BodyOneSecondary,
  SubtitleOne,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useFormattedContributionSummaryMessages } from '../../use-formatted-coverage-summary-messages.hook';

const SelectionWrapper = props => (
  <Flex
    justifyContent="space-between"
    marginTop="half"
    alignItems="center"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);
// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const ContributionTitle = props => <BodyOneSecondary {...props} />;
const ContributionValue = ({
  showCostsPerPayPeriod,
  formattedValue,
  periodType,
}) => {
  const { formatMessage } = useIntl();
  const formattedPeriodType = periodType
    ? formatMessage({ id: periodType })
    : '';

  return (
    <Flex
      as="p"
      flexDirection={['column', 'column', 'row']}
      alignItems={['flex-end', 'flex-end', 'center']}
      textAlign="right"
    >
      <SubtitleOne as="span" color="onSurface.text.primary">
        {formattedValue}
      </SubtitleOne>
      {showCostsPerPayPeriod && (
        <BodyTwoSecondary as="span" marginLeft="quarter">
          {`/ ${formattedPeriodType}`}
        </BodyTwoSecondary>
      )}
    </Flex>
  );
};

ContributionValue.propTypes = {
  formattedValue: PropTypes.string.isRequired,
  periodType: PropTypes.string.isRequired,
  showCostsPerPayPeriod: PropTypes.bool.isRequired,
};

export const ContributionsSummarySelection = props => {
  const { formatMessage } = useIntl();

  const {
    formattedEmployeeAllocation,
    formattedEmployerAllocation,
    formattedTotalAllocation,
  } = useFormattedContributionSummaryMessages(props);

  return (
    <Box marginBottom="oneAndHalf">
      {formattedTotalAllocation ? (
        <SelectionWrapper>
          <ContributionTitle>
            {formatMessage({ id: 'TOTAL_ALLOCATION' })}
          </ContributionTitle>
          <ContributionValue
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            formattedValue={formattedTotalAllocation}
          />
        </SelectionWrapper>
      ) : (
        <>
          {formattedEmployeeAllocation && (
            <SelectionWrapper>
              <ContributionTitle>
                {formatMessage({ id: 'PERSONAL_ALLOCATION' })}
              </ContributionTitle>
              <ContributionValue
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...props}
                formattedValue={formattedEmployeeAllocation}
              />
            </SelectionWrapper>
          )}
          {formattedEmployerAllocation && (
            <SelectionWrapper>
              <ContributionTitle>
                {formatMessage({ id: 'EMPLOYER_ALLOCATION' })}
              </ContributionTitle>
              <ContributionValue
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...props}
                formattedValue={formattedEmployerAllocation}
              />
            </SelectionWrapper>
          )}
        </>
      )}
    </Box>
  );
};
