export { explodeObject } from './explode-object';
export { flattenObject } from './flatten-object';
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validPriceAmountRegex = /^(?!0\.00)[1-9]\d{0,2}(\d{3})*(\.\d\d)?$/;
export const phoneNumberRegex =
  // eslint-disable-next-line no-useless-escape -- FIXME: automatically added for existing issue
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
export const phoneCountryCodeRegex = /^[0-9]+$/;
export const postalCodeRegex = /^[a-z]\d[a-z]\d[a-z]\d$/i;
export const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const usSSNFormatWithDashes =
  /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
export const usSSNFormatWithoutDashes =
  /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
export const maskedSSNFormatWithDashes = /^[*]{3}-[*]{2}-[*]{1}[\d]{3}$/;
export const maskedSSNFormatWithoutDashes = /^[*]{6}[\d]{3}$/;
export * from './time';
