import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  HeadingTwo,
  SecondaryButton,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';

const SuspendConfirmation = ({ name, onClose, onActionConfirmedClick }) => {
  return (
    <Modal
      size="small"
      open
      onClose={onClose}
      className="modal-employees-action-confirmation action-modal-suspend"
    >
      <HeadingTwo>
        <FormattedMessage
          id="SUSPENSION_CONFIRMATION_HEADER"
          values={{ name }}
        />
      </HeadingTwo>
      <ModalContent>
        <FormattedMessage
          id="SUSPENSION_EMPLOYER_CONFIRMATION_DESCRIPTION"
          values={{ name }}
        />
      </ModalContent>
      <ModalButtons>
        <SecondaryButton onClick={onClose} marginRight="one">
          <FormattedMessage id="CANCEL" />
        </SecondaryButton>
        <PrimaryButton onClick={onActionConfirmedClick}>
          <FormattedMessage id="SUSPEND_EMPLOYER" />
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

SuspendConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  onActionConfirmedClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuspendConfirmation;
