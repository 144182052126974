import { takeLatest, select } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { LOAD_COVERAGE_SUMMARY } from './coverage-summary.actions';
import { LOAD_EMPLOYER_BENEFIT_PLANS } from 'common/benefit-plans';
import { getCoverageSummary } from './get-coverage-summary.service';
import { getEmployerBenefitPlans } from 'common/services';
import { selectCurrentUserBenefitClassId } from './coverage-summary.selectors';
import { PLAN_STATUSES } from 'common/constants';

export function* loadCoverageSummary({ payload }) {
  yield request(LOAD_COVERAGE_SUMMARY, getCoverageSummary(payload), payload);
}

export function* loadBenefitPlansByClassId({ payload }) {
  const currentUserBenefitClassId = yield select(
    selectCurrentUserBenefitClassId,
  );
  const paramBenefitClassId =
    payload.benefitClassId ?? currentUserBenefitClassId;
  yield request(
    LOAD_EMPLOYER_BENEFIT_PLANS,
    getEmployerBenefitPlans({
      groupId: payload.groupId,
      benefitClassIds: [paramBenefitClassId],
      statuses: [PLAN_STATUSES.ACTIVE, PLAN_STATUSES.ENROLLING],
    }),
    payload,
  );
}

export function* coverageSummarySaga() {
  yield takeLatest(LOAD_COVERAGE_SUMMARY.BASE, loadCoverageSummary);
  yield takeLatest(LOAD_EMPLOYER_BENEFIT_PLANS.BASE, loadBenefitPlansByClassId);
}
