/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { selectApp } from 'apps/employer-experience-v2/app.selectors';
import { selectEmployerTimeZone } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const selectPremiumsReport = createSelector(
  selectApp,
  get('employerPremiumsReport'),
);

const selectReportsSchedules = createSelector(
  selectPremiumsReport,
  slice => slice?.reportsSchedules,
);

export const selectPreviousReports = createSelector(
  selectReportsSchedules,
  get('previousReports'),
);

export const selectPreviousReportsSkip = createSelector(
  selectReportsSchedules,
  get('previousReportsSkip'),
);

export const selectPayrollSchedules = createSelector(
  selectReportsSchedules,
  report => report?.payrollSchedules ?? [],
);

const getFormattedPayPeriodDates = ({ pay_period: period }) => {
  const startDate = utcToZonedTime(period?.start_date, 'UTC') ?? new Date();
  const endDate = utcToZonedTime(period?.end_date, 'UTC') ?? new Date();
  const startDateString = format(
    startDate,
    `MMMM d${startDate.year !== endDate.year ? ', yyyy' : ''}`,
  );
  const endDateString = format(endDate, 'MMMM d, yyyy');

  return { startDateString, endDateString };
};

const getFormattedYear = period => {
  const startYear = format(utcToZonedTime(period.start_date, 'UTC'), 'yyyy');
  const endYear = format(utcToZonedTime(period.end_date, 'UTC'), 'yyyy');
  return `${startYear}${endYear !== startYear ? `- ${endYear}` : ''}`;
};

const mapPayPeriod = period => {
  const { startDateString, endDateString } = getFormattedPayPeriodDates(period);
  return { ...period, label: `${startDateString} - ${endDateString}` };
};

const isRegistered = period => {
  return (
    period.contributions_result?.contributions_registered ||
    period.payroll_result?.report_id
  );
};

const getCategorizedPayrollSchedule = schedule => {
  const registeredPeriods = schedule.filter(period => isRegistered(period));
  const notRegisteredPeriods = schedule.filter(period => !isRegistered(period));
  return {
    registeredPeriods: registeredPeriods.map(mapPayPeriod),
    notRegisteredPeriods: notRegisteredPeriods.map(mapPayPeriod),
  };
};

export const selectCategorizedPayrollSchedulesWithLabels = createSelector(
  selectPayrollSchedules,
  payrollSchedules =>
    payrollSchedules.map(payrollSchedule => ({
      ...payrollSchedule,
      schedule: getCategorizedPayrollSchedule(payrollSchedule.schedule),
    })),
);

export const selectPayrollSchedulesWithLabels = createSelector(
  selectPayrollSchedules,
  payrollSchedules =>
    payrollSchedules.map(payrollSchedule => {
      const yearString = getFormattedYear(
        payrollSchedule?.schedule[0]?.plan_period,
      );
      return {
        ...payrollSchedule,
        label: `${payrollSchedule.schedule_name} ${yearString}`,
        schedule: payrollSchedule.schedule?.map(schedule => {
          const { startDateString, endDateString } =
            getFormattedPayPeriodDates(schedule);
          return {
            ...schedule,
            label: `${startDateString} - ${endDateString}`,
          };
        }),
      };
    }),
);

export const selectPremiumsExportSplits = createSelector(
  selectReportsSchedules,
  get('premiumsExportSplits'),
);

export const selectTotalPreviousReports = createSelector(
  selectReportsSchedules,
  get('totalPreviousReports'),
);

const parseAndFormatPeriodWithTz = (period, timeZone, dateFormat) => {
  const periodStart = format(
    utcToZonedTime(period.start_date, timeZone),
    dateFormat,
  );
  const periodEnd = format(
    utcToZonedTime(period.end_date, timeZone),
    dateFormat,
  );
  return `${periodStart} - ${periodEnd}`;
};

export const selectPayrollSchedulesWithReportName = createSelector(
  selectPayrollSchedulesWithLabels,
  selectPremiumsExportSplits,
  selectEmployerTimeZone,
  (payrollSchedules, premiumsExportSplits, timeZone) => {
    return payrollSchedules.map(
      ({ schedule, payroll_split_config_id, schedule_name, id }) => {
        const planPeriod = schedule[0]?.plan_period;
        const formattedPlanPeriodWithTz = planPeriod
          ? parseAndFormatPeriodWithTz(planPeriod, timeZone, 'MMM d, yyyy')
          : '';
        return {
          label: `${schedule_name} (${formattedPlanPeriodWithTz})`,
          value:
            (premiumsExportSplits ?? []).find(
              split => split.id === payroll_split_config_id,
            )?.report_name || 'premiums_export',
          schedule,
          id,
        };
      },
    );
  },
);

export const selectShowImportModal = createSelector(
  selectReportsSchedules,
  report => report?.showImportModal,
);

export const selectShowRenameModal = createSelector(
  selectReportsSchedules,
  report => report?.showRenameModal,
);

export const selectPayrollSchedulesWithPlanPeriods = createSelector(
  selectPayrollSchedulesWithLabels,
  selectEmployerTimeZone,
  (payrollSchedules, timeZone) => {
    return payrollSchedules
      .map(payrollSchedule => {
        // get unique plan period within a schedule
        const planPeriods = payrollSchedule.schedule.reduce((acc, curr) => {
          const planPeriod = parseAndFormatPeriodWithTz(
            curr.plan_period,
            timeZone,
            'MMM d, yyyy',
          );
          return acc.includes(planPeriod) ? acc : [...acc, planPeriod];
        }, []);

        return { ...payrollSchedule, plan_periods: planPeriods };
      })
      .sort((a, b) => {
        return (
          -1 * // sort reverse chronologically
          (a.schedule[0]?.plan_period?.start_date ?? '').localeCompare(
            b.schedule[0]?.plan_period?.start_date ?? '',
          )
        );
      });
  },
);

export const selectReportStatus = createSelector(
  selectPremiumsReport,
  slice => slice?.reportStatus,
);

export const selectScheduleStatus = createSelector(
  selectPremiumsReport,
  slice => slice?.scheduleStatus,
);

export const selectShowExportModal = createSelector(
  selectReportsSchedules,
  report => report?.showExportModal,
);

export const selectDocumentUrl = createSelector(
  selectReportsSchedules,
  report => report?.documentUrl,
);
export const selectDocumentName = createSelector(
  selectReportsSchedules,
  report => report?.documentName,
);

export const selectCategorizedPayrollSchedulesWithLabelsById = createSelector(
  (_, { scheduleId }) => scheduleId,
  selectCategorizedPayrollSchedulesWithLabels,
  (scheduleId, payrollSchedules) =>
    payrollSchedules.find(schedule => schedule.id === scheduleId),
);

export const selectPlanPeriodFilterOptions = createSelector(
  selectPayrollSchedulesWithPlanPeriods,
  payrollSchedules =>
    // determine unique plan periods across all schedules
    payrollSchedules
      .reduce((acc, schedule) => {
        const planPeriods = schedule.plan_periods.filter(
          planPeriod => !acc.includes(planPeriod),
        );
        return [...acc, ...planPeriods];
      }, [])
      .map(planPeriod => ({
        label: planPeriod,
        value: planPeriod,
      })),
);

export const selectFormattedPremiumsReports = createSelector(
  selectPreviousReports,
  previousReports => {
    const records = previousReports?.reduce((acc, item) => {
      const type = item.report_type.split('_');
      const idPrefix = type[0];
      const reportType = type.slice(-1)[0];
      const record = {
        internal_report_id: item.internal_report_id,
        date_created: item.date_created,
        type: reportType,
        [`${idPrefix}_content_id`]: item.content_id,
        user_id: item.user_id,
        pay_period: item.pay_period,
        report_id: item.report_id,
      };
      const reportIdType = `${item.internal_report_id}-${reportType}`;
      return {
        ...acc,
        [reportIdType]: {
          ...acc[reportIdType],
          ...record,
        },
      };
    }, {});
    return records ? Object.values(records) : [];
  },
);
