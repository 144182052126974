import { request } from 'common/websocket-redux';
import { fork, all, takeLatest } from 'redux-saga/effects';
import { getUserRewardsHistory } from 'common/services';
import { GET_USER_REWARDS_HISTORY } from './rewards-history-page.action-types';
import { LIMITS } from './rewards-history-page.constants';

export function* getRewardsHistory({ payload: { userId, offset } }) {
  yield request(
    GET_USER_REWARDS_HISTORY,
    getUserRewardsHistory({ userId, offset, limit: LIMITS.REWARDS_HISTORY }),
  );
}

export function* rewardsHistorySaga() {
  yield fork(all, [
    takeLatest(GET_USER_REWARDS_HISTORY.BASE, getRewardsHistory),
  ]);
}
