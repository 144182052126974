import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 *   @param {string} user_id (required)
 *   @param {string} group_id (required)
 *   @param {array} enrollment_types (optional) - ["annual", "qle", "new_hire", "benefit_class_change", "activation"]
 *   @param {array} statuses (optional) - ["closed", "open", "paused", "started", "submitted"]
 */
export const getUserPlanEnrollment = ({
  userId,
  groupId,
  enrollmentTypes,
  statuses,
} = {}) =>
  call(websocketFetch, 'get_user_plan_enrollment', {
    user_id: userId,
    group_id: groupId,
    enrollment_types: enrollmentTypes,
    statuses,
  });
