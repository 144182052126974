import React from 'react';
import { withRouter, Switch, Route, Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BenefitsOverview } from './BenefitsOverview';
import { BenefitWalletTabs } from './BenefitWallet';
import { EnrollmentConfig } from '../enrollment-config';
import { EnrollmentDesignRoutes } from '../enrollment-design';
import { PricingConfigurationTab } from './PricingConfiguration';
import {
  colour,
  Box,
  Text,
  StyledComponentRefWrapper,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled, { css } from 'styled-components';
import { remPx } from 'apps/employer-experience/ui-kit/theme';
import { isTabActive } from 'apps/employer-experience/utilities';
import QLEConfiguration from './QLEConfiguration/qle-configuration.container';
import { SalesforceSyncTab } from './SalesforceSync';
import { useIntl } from '@leagueplatform/locales';

const StyledLink = styled(StyledComponentRefWrapper(Link))`
  padding: 0.2rem 1.5rem;
  color: ${colour('purple')};

  &:hover {
    color: ${colour('purpleDark')};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${colour('purpleLightest')};
      color: ${colour('purpleDark')};
      border-radius: ${remPx(32)};
    `};
`;

const BenefitsRouting = ({ match, location, employerName }) => {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Flex flex="1 1 auto" my={3}>
        <StyledLink
          to={`${match.url}/plan-design`}
          name="plan-design"
          active={isTabActive(match, location, 'plan-design')}
        >
          {formatMessage({ id: 'PLAN_DESIGN' })}
        </StyledLink>
        <StyledLink
          to={`${match.url}/enrollment-design`}
          name="enrollment-design"
          active={
            isTabActive(match, location, 'enrollment-design') ||
            isTabActive(match, location, 'new-enrollment-design')
          }
        >
          {formatMessage({ id: 'ENROLLMENT_DESIGN' })}
        </StyledLink>
        <StyledLink
          to={`${match.url}/wallet-details`}
          name="wallet-details"
          active={isTabActive(match, location, 'wallet-details')}
        >
          {formatMessage({ id: 'WALLET_DETAILS' })}
        </StyledLink>
        <StyledLink
          to={`${match.url}/qle-config`}
          name="qle-config"
          active={isTabActive(match, location, 'qle-config')}
        >
          {formatMessage({ id: 'QLE_CONFIG' })}
        </StyledLink>
        <StyledLink
          to={`${match.url}/pricing-config`}
          name="pricing-config"
          active={isTabActive(match, location, 'pricing-config')}
        >
          {formatMessage({ id: 'PRICING_CONFIG' })}
        </StyledLink>
        <StyledLink
          to={`${match.url}/product-vendor-sync`}
          name="product-vendor-sync"
          active={isTabActive(match, location, 'product-vendor-sync')}
        >
          {formatMessage({ id: 'SALESFORCE_SYNC_TAB_LABEL' })}
        </StyledLink>
      </Flex>

      <Switch>
        {/* Benefits Plan Design Routes */}
        <Route
          exact
          path={`${match.path}/plan-design`}
          component={BenefitsOverview}
        />

        {/* Benefits Enrollment Design Routes */}
        <Route
          path={`${match.path}/enrollment-design`}
          render={routeProps => {
            return (
              <>
                <Text>
                  {formatMessage(
                    { id: 'ENROLLMENT_DESIGN_BLURB' },
                    { employerName },
                  )}
                </Text>
                <Box py={3}>
                  <EnrollmentConfig
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...routeProps}
                    employerName={employerName}
                    groupId={routeProps.match?.params?.groupId}
                  />
                </Box>
              </>
            );
          }}
        />

        {/* New Benefits Enrollment Design Routes */}
        {/* @TODO - Replace `enrollment-design` route once feature is complete */}
        <Route
          path={`${match.path}/new-enrollment-design`}
          render={() => {
            return (
              <>
                <Text>
                  {formatMessage(
                    { id: 'ENROLLMENT_DESIGN_BLURB' },
                    { employerName },
                  )}
                </Text>
                <Box py={3}>
                  <EnrollmentDesignRoutes />
                </Box>
              </>
            );
          }}
        />

        {/* Benefits Wallet Details Routes */}
        <Route
          exact
          path={`${match.path}/wallet-details`}
          component={BenefitWalletTabs}
        />

        {/* Benefits QLE Configuration Routes */}
        <Route path={`${match.path}/qle-config`} component={QLEConfiguration} />

        {/* Pricing Configuration Routes */}
        <Route
          exact
          path={`${match.path}/pricing-config`}
          render={routeProps => (
            <PricingConfigurationTab
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...routeProps}
              employerName={employerName}
            />
          )}
        />

        {/* Product Vendor Sync Config Routes */}
        <Route
          exact
          path={`${match.path}/product-vendor-sync`}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          render={routeProps => <SalesforceSyncTab {...routeProps} />}
        />

        {/* Redirection route '/benefits' -> '/benefits/plan-design' */}
        {/* eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue */}
        <Route component={() => <Redirect to={`${match.url}/plan-design`} />} />
      </Switch>
    </Box>
  );
};

BenefitsRouting.propTypes = {
  location: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  employerName: PropTypes.string,
};

BenefitsRouting.defaultProps = {
  location: {},
  employerName: null,
};

export default withRouter(BenefitsRouting);
