export const CHAT_STATES = {
  INITIAL: null,
  ESTABLISHED: 'ESTABLISHED',
  FAILED: 'FAILED',
};

export const HTML_REFS = {
  CHAT_IFRAME: 'live-agent-iframe',
  CHAT_ONLINE: 'live-agent-dummy-online',
  CHAT_OFFLINE: 'live-agent-dummy-offline',
};

export const CHAT_EVENTS = {
  CHAT_START: 'chasitorChatEstablished',
  CHAT_END: 'chasitorChasitorChatEnded',
  CHAT_REQUEST_SUCCESSFUL: 'chasitorChatRequestSuccessful',
  CHAT_REQUEST_FAILED: 'chasitorChatRequestFailed',
  CHAT_CLOSED: 'chat_closed',
  AGENT_CHAT_END: 'chasitorAgentChatEnded',
  AGENT_TYPING_UPDATE: 'chasitorAgentTypingUpdate',
  AGENT_CHAT_MESSAGE: 'chasitorAgentChatMessage',
  FILE_TRANSFER_REQUESTED: 'fileTransferRequested',
  FILE_TRANSFER_SUCCESS: 'fileTransferSuccess',
  FILE_TRANSFER_FAILURE: 'fileTransferFailure',
  FILE_TRANSFER_CANCELED: 'fileTransferCanceled',
  FEEDBACK_URL_SET: 'chat-feedback-url-set',
  FEEDBACK_INITIATED: 'chat-feedback-initiated',
  SALESFORCE_EVENT: 'SF_LIVE_AGENT',
  SALESFORCE_ANALYTIC_EVENT: 'salesforce-analytics-event',
  CHAT_ACTIVITY: 'chat_activity',
  QUEUE_UPDATE: 'salesforce-queue-position',
  WAIT_TIME_UPDATE: 'salesforce-wait-time',
  OPEN_END_CHAT_MODAL: 'open-end-chat-modal',
  OPEN_CHAT_FAILED_MODAL: 'open-chat-failed-modal',
  OPEN_CHAT_RECONNECTING_MODAL: 'open-chat-reconnecting-modal',
  OPEN_CHAT_CONNECTION_LOST_MODAL: 'open-chat-connection-lost-modal',
  CLOSE_CHAT_MODAL: 'close-chat-modal',
  END_CHAT: 'end-chat',
};

export const CHAT_MODALS = {
  END_CHAT: 'END_CHAT',
  CHAT_FAILED: 'CHAT_FAILED',
  CHAT_RECONNECTING: 'CHAT_RECONNECTING',
  CHAT_CONNECTION_LOST: 'CHAT_CONNECTION_LOST',
};

export const CHAT_MODALS_BY_EVENT = {
  [CHAT_EVENTS.OPEN_END_CHAT_MODAL]: CHAT_MODALS.END_CHAT,
  [CHAT_EVENTS.OPEN_CHAT_FAILED_MODAL]: CHAT_MODALS.CHAT_FAILED,
  [CHAT_EVENTS.OPEN_CHAT_RECONNECTING_MODAL]: CHAT_MODALS.CHAT_RECONNECTING,
  [CHAT_EVENTS.OPEN_CHAT_CONNECTION_LOST_MODAL]:
    CHAT_MODALS.CHAT_CONNECTION_LOST,
};

export const ARIA_LIVE_MESSAGES_BY_EVENT = {
  [CHAT_EVENTS.AGENT_TYPING_UPDATE]: 'THE_AGENT_IS_TYPING',
  [CHAT_EVENTS.AGENT_CHAT_MESSAGE]: 'NEW_MESSAGE_FROM_THE_AGENT',
  [CHAT_EVENTS.AGENT_CHAT_END]: 'THE_AGENT_HAS_ENDED_THE_CHAT',
  [CHAT_EVENTS.FILE_TRANSFER_REQUESTED]: 'THE_AGENT_HAS_REQUESTED_A_FILE',
  [CHAT_EVENTS.FILE_TRANSFER_SUCCESS]: 'YOUR_FILE_HAS_BEEN_UPLOADED',
  [CHAT_EVENTS.FILE_TRANSFER_CANCELED]: 'FILE_TRANSFER_IS_CANCELED',
  [CHAT_EVENTS.FILE_TRANSFER_FAILURE]: 'YOUR_FILE_FAILED_TO_UPLOAD',
};
