import { EDIT_EMPLOYEE_LIFECYCLE } from './edit-employee.types';
import { GET_EDIT_RESOURCES } from './v1';

const initialState = { ready: false, success: false, error: false };

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const editEmployeeUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EDIT_RESOURCES.STARTED:
      return { ...state, ready: false, error: false };
    case GET_EDIT_RESOURCES.SUCCEEDED:
      return { ...state, ready: true, error: false };
    case GET_EDIT_RESOURCES.ERRORED:
      return { ...state, ready: true, success: false, error: true };
    case EDIT_EMPLOYEE_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
