import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getGroupEnrollmentProgressReport = payload =>
  call(websocketFetch, 'request_report', {
    type: 'enrollment_progress_report',
    request: {
      group_id: payload.groupId,
    },
  });
