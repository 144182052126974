import { merge, get } from 'lodash';
import {
  GET_CLAIM_HISTORY,
  GET_CLAIM_NOTES,
} from './claim-log/claim-log.types';
import { GET_USER_HISTORY } from './user-history/user-history.types';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SIGN_OUT } from 'apps/auth/auth.actions';
import { PIN_DOCUMENT, UNPIN_DOCUMENT } from './claim-details.types';

export const initialState = {
  isFetching: false,
  data: {},
  duplicateClaim: null,
  vendors: [],
  pinnedDocument: null, // Used to store contentId and contentUrl when pinning document in an attempt to upload a user document
};

const applyClaimHistory = (state, action) =>
  merge({}, state, {
    data: { claimHistory: action.payload.info.history },
  });

const applyClaimNotes = (state, action) =>
  merge({}, state, {
    data: { claimNotes: action.payload.info.notes, claimNotesLoaded: true },
  });

const applyUserHistory = (state, action) =>
  merge({}, state, {
    data: { userHistory: action.payload.info.claims },
  });

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CLAIM_HISTORY.SUCCEEDED:
      return applyClaimHistory(state, action);
    case GET_CLAIM_NOTES.SUCCEEDED:
      return applyClaimNotes(state, action);
    case GET_USER_HISTORY.SUCCEEDED:
      return applyUserHistory(state, action);
    case SIGN_OUT.SUCCEEDED:
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };
    case PIN_DOCUMENT:
      return {
        ...state,
        pinnedDocument: get(action, 'payload'),
      };
    case UNPIN_DOCUMENT:
      return {
        ...state,
        pinnedDocument: null,
      };
    default:
      return state;
  }
};

export default reducer;
