import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import { Backdrop, Modal, ClickEater, CheckBox } from '@leagueplatform/ui-kit';
import {
  Box,
  Flex,
  BodyOne,
  BodyTwo,
  SubtitleOne,
  SubtitleTwo,
  LabelText,
  Caption,
} from '@leagueplatform/genesis-commons';
import {
  ErrorMessage,
  ModalButton,
  ModalLoaderButton,
  LinkBox,
} from './modal-elements';
import { ExportCustomPlanFilter } from './export-custom-plan-filter';

const WideModalContainer = styled(Modal.Container)`
  max-width: none;
  width: 90%;
`;

const BenefitsBucket = props => (
  <Box
    height="342px"
    overflowY="scroll"
    marginX="none"
    marginTop="oneAndHalf"
    marginBottom="one"
    backgroundColor="surface.background.secondary"
    padding="one"
    borderRadius="medium"
    borderStyle="solid"
    borderWidth="thin"
    borderColor="onSurface.border.subdued"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const CircleButton = styled(props => (
  <Box
    borderRadius="50%"
    marginLeft="half"
    fontSize="heading1"
    textAlign="center"
    height="two"
    minWidth="two"
    lineHeight="27px"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
))`
  cursor: pointer;
`;

const ListItem = ({
  benefitName,
  benefitVendorName,
  className,
  icon,
  onClick,
  initialYear,
}) => (
  <Flex
    backgroundColor="surface.background.primary"
    borderWidth="thin"
    borderColor="onSurface.border.subdued"
    borderRadius="small"
    paddingX="oneAndHalf"
    paddingY="half"
    marginBottom="half"
    justifyContent="space-between"
    alignItems="center"
  >
    <Box>
      <SubtitleTwo fontWeight="bold" mb={0}>
        {benefitName}
      </SubtitleTwo>
      <Caption>
        {benefitVendorName} {className && `• ${className}`} • {initialYear}
      </Caption>
    </Box>
    {icon === 'add' ? (
      <CircleButton
        icon="add"
        color="decorative.brand.primary.default"
        backgroundColor="decorative.brand.primary.pastel"
        onClick={onClick}
        data-test="addButton"
      >
        +
      </CircleButton>
    ) : (
      <CircleButton
        icon="remove"
        color="critical.icon"
        backgroundColor="critical.background.subdued"
        onClick={onClick}
      >
        -
      </CircleButton>
    )}
  </Flex>
);

export const filterSelection = (selectedBenefits, initialBenefits) => {
  const allBens = initialBenefits.map(item => {
    let count = 0;

    Object.keys(selectedBenefits).map(key => {
      if (selectedBenefits[key]?.includes(item[key])) count += 1;
      return key;
    });
    const selectionKeys = Object.values(selectedBenefits).filter(
      value => value.length > 0,
    );

    return { ...item, disabled: count !== selectionKeys.length };
  });

  return allBens;
};

export const ExportCustomPlanModal = ({
  onClose,
  onSubmit,
  planDesignRequestStatus,
  groupId,
  filteredEmployerBenefits: allBenefits,
}) => {
  const { formatMessage } = useIntl();
  const [benefitIds, setBenefitIds] = useState([]);
  const [exportBenefitIds, setExportBenefitIds] = useState(false);
  const [benefits, setBenefits] = useState(allBenefits);

  const addBenefit = id => {
    setBenefitIds([...benefitIds, id]);
  };
  const removeBenefit = id =>
    setBenefitIds(benefitIds.filter(item => item !== id));

  const onInputChange = e => setExportBenefitIds(e.target.checked);

  const initialBenefits = [...benefits].map(v => ({ ...v, disabled: null }));

  const { loading, success, error, errorReason, exportedSheetUrl } =
    planDesignRequestStatus;

  return (
    <Backdrop onClose={onClose}>
      <WideModalContainer>
        <ClickEater>
          <Modal.Header>
            <Modal.HeaderTitle>
              {formatMessage({ id: 'EXPORT_CUSTOM_PLAN' })}
            </Modal.HeaderTitle>
          </Modal.Header>
          <Modal.Body>
            {success ? (
              <>
                <BodyOne>
                  {formatMessage({ id: 'EXPORT_PLAN_DESIGN_SUCCESS' })}
                </BodyOne>
                <LinkBox url={exportedSheetUrl} />
              </>
            ) : (
              <>
                <ExportCustomPlanFilter
                  data={allBenefits}
                  onChange={selectedBenefits =>
                    setBenefits(
                      filterSelection(selectedBenefits, initialBenefits),
                    )
                  }
                  onClear={() => setBenefits(initialBenefits)}
                />

                <BodyOne>
                  {formatMessage({ id: 'EXPORT_CUSTOM_PLAN_DESCRIPTION' })}
                </BodyOne>
                <Flex marginTop="two">
                  <Box marginRight="oneAndHalf" width={1 / 2}>
                    <SubtitleOne>
                      {formatMessage({ id: 'AVAILABLE_BENEFITS' })}
                    </SubtitleOne>
                    <BodyTwo>
                      {formatMessage({
                        id: 'AVAILABLE_BENEFITS_DESCRIPTION',
                      })}
                    </BodyTwo>
                  </Box>

                  <Box marginLeft="oneAndHalf" width={1 / 2}>
                    <SubtitleOne>
                      {formatMessage({ id: 'CUSTOM_PLAN' })}
                    </SubtitleOne>
                    <BodyTwo>
                      {formatMessage({ id: 'CUSTOM_PLAN_DESCRIPTION' })}
                    </BodyTwo>
                  </Box>
                </Flex>
                <Flex>
                  <Box marginRight="oneAndHalf" width={1 / 2}>
                    <BenefitsBucket>
                      {benefits &&
                        benefits
                          .filter(
                            item =>
                              !benefitIds.includes(item.id) && !item.disabled,
                          )
                          .map(benefit => (
                            <ListItem
                              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                              {...benefit}
                              key={benefit.id}
                              icon="add"
                              onClick={() => addBenefit(benefit.id)}
                            />
                          ))}
                    </BenefitsBucket>
                  </Box>
                  <Box marginLeft="oneAndHalf" width={1 / 2}>
                    <BenefitsBucket>
                      {allBenefits &&
                        allBenefits
                          // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                          .filter(item => benefitIds.includes(item.id))
                          .map(benefit => (
                            <ListItem
                              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                              {...benefit}
                              key={benefit.id}
                              icon="remove"
                              onClick={() => removeBenefit(benefit.id)}
                            />
                          ))}
                    </BenefitsBucket>
                    <Flex alignItems="center" marginTop="one">
                      <CheckBox
                        id="export-checkbox"
                        name="export-checkbox"
                        onChange={onInputChange}
                        checked={exportBenefitIds}
                        disabled={loading}
                      />
                      <LabelText as="label" htmlFor="export-checkbox">
                        {formatMessage({ id: 'EXPORT_BENEFIT_IDS' })}
                      </LabelText>
                    </Flex>
                  </Box>
                </Flex>

                {error && <ErrorMessage>{errorReason}</ErrorMessage>}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {success ? (
              <ModalButton primary onClick={onClose}>
                {formatMessage({ id: 'CLOSE' })}
              </ModalButton>
            ) : (
              <>
                <ModalButton onClick={onClose} disabled={loading}>
                  {formatMessage({ id: 'CANCEL' })}
                </ModalButton>
                <ModalLoaderButton
                  loading={loading}
                  primary
                  onClick={() =>
                    onSubmit({ groupId, benefitIds, exportBenefitIds })
                  }
                >
                  {formatMessage({ id: 'EXPORT_CUSTOM_PLAN' })}
                </ModalLoaderButton>
              </>
            )}
          </Modal.Footer>
        </ClickEater>
      </WideModalContainer>
    </Backdrop>
  );
};

ExportCustomPlanModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  planDesignRequestStatus: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorReason: PropTypes.string.isRequired,
    exportedSheetUrl: PropTypes.string,
  }),
  filteredEmployerBenefits: PropTypes.shape({}),
};

ExportCustomPlanModal.defaultProps = {
  filteredEmployerBenefits: null,
  planDesignRequestStatus: PropTypes.shape({
    exportedSheetUrl: '',
  }),
};

ListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  benefitName: PropTypes.string,
  benefitVendorName: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  initialYear: PropTypes.string.isRequired,
};

ListItem.defaultProps = {
  benefitName: null,
  benefitVendorName: null,
  className: null,
  icon: null,
};
