import { get } from 'lodash';
import { GET_USER_BENEFIT_CLASSES } from './benefits.types';

export const initialClassesState = {
  classes: {},
};

const applyGetUserBenefitClassesSucceeded = (state, { payload }) => ({
  ...state,
  classes: get(payload, 'info.benefit_class_id.select_options'),
});

export const employeeClassesReducer = (
  state = initialClassesState,
  action = {},
) => {
  switch (action.type) {
    case GET_USER_BENEFIT_CLASSES.SUCCEEDED:
      return applyGetUserBenefitClassesSucceeded(state, action);
    default:
      return state;
  }
};
