/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { selectApp } from 'apps/employer-experience-v2/app.selectors';
import { createSelector } from 'reselect';
import { startCase } from 'lodash';
import { propertyCompareFn } from 'propertyCompareFn';

const selectCarrierAuditsReport = createSelector(
  selectApp,
  ({ employerCarrierAuditsReport }) => employerCarrierAuditsReport,
);

export const selectCarrierAuditReportLoading = createSelector(
  selectCarrierAuditsReport,
  ({ carrierAuditReportLoading }) => carrierAuditReportLoading,
);

export const selectAuditFlows = createSelector(
  selectCarrierAuditsReport,
  report =>
    report?.auditFlows
      ?.map(({ flow_type, metadata }) => ({
        value: flow_type,
        label: startCase(`${metadata.group_name} ${metadata.vendor_id}`),
      }))
      .sort(propertyCompareFn('label')),
);
