import React from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { BodyOne, BodyTwoSecondary } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  DraggableBox,
  DroppableBox,
} from '../enrollment-experience-config.styles';
import {
  BenefitStep,
  EnrollmentStepItemType,
} from '../enrollment-design-options.types';
import { DraggableBenefitSetGroup } from '../draggable-benefit-set-group/draggable-benefit-set-group.view';

import { DraggableBenefitSet } from '../draggable-benefit-set/draggable-benefit-set.view';
import { createDragDropId } from '../utils';
import {
  DROPPABLE_TYPES,
  BENEFIT_SET_TYPE,
  BENEFIT_GROUP_TYPE,
} from '../enrollment-experience-config.constants';

interface DraggableBenefitStepProps {
  step: BenefitStep;
  index: number;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  activeEditingItemId?: string;
  openEditingPanel: (item: EnrollmentStepItemType) => void;
}

const DraggableBenefitStep = ({
  step,
  index,
  openEditingPanel,
  activeEditingItemId,
}: DraggableBenefitStepProps) => {
  const { formatMessage } = useIntl();
  return (
    <Draggable
      key={step.id}
      draggableId={createDragDropId(step.id, DROPPABLE_TYPES.STEP)}
      index={index}
    >
      {(
        dragProvided: DraggableProvided,
        dragSnapshot: DraggableStateSnapshot,
      ) => (
        <DraggableBox
          backgroundColor="surface.background.primary"
          isDragging={dragSnapshot.isDragging}
          innerRef={dragProvided.innerRef}
          isActive={activeEditingItemId === step.id}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...dragProvided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...dragProvided.dragHandleProps}
          onClick={() => openEditingPanel(step)}
        >
          <BodyOne data-testid="step-title">
            {step.name}{' '}
            <BodyTwoSecondary as="span">
              {formatMessage({ id: 'ENROLLMENT_DESIGN_STEP' })}
            </BodyTwoSecondary>
          </BodyOne>
          <Droppable
            droppableId={createDragDropId(step.id, DROPPABLE_TYPES.STEP)}
            key={step.id}
            type={BENEFIT_SET_TYPE}
          >
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot,
            ) => {
              return (
                <DroppableBox
                  key={step.id}
                  isDraggingOver={dropSnapshot.isDraggingOver}
                  innerRef={dropProvided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...dropProvided.droppableProps}
                >
                  {step.sets?.map((set: any, setIndex: number) =>
                    set?.type === BENEFIT_GROUP_TYPE ? (
                      <DraggableBenefitSetGroup
                        activeEditingItemId={activeEditingItemId}
                        openEditingPanel={openEditingPanel}
                        key={set.id}
                        group={set}
                        index={setIndex}
                      />
                    ) : (
                      <DraggableBenefitSet
                        activeEditingItemId={activeEditingItemId}
                        openEditingPanel={openEditingPanel}
                        key={set.id}
                        set={set}
                        index={setIndex}
                      />
                    ),
                  )}
                  {dropProvided.placeholder}
                </DroppableBox>
              );
            }}
          </Droppable>
        </DraggableBox>
      )}
    </Draggable>
  );
};

export default DraggableBenefitStep;
