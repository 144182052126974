import camelize from 'camelize';
import { GET_FEATURE_FLAGS } from './feature-flags.actions';

export const initialState = {
  error: false,
  success: false,
};

const applyFeatureFlags = (state, action) => {
  const { userFlags } = camelize(action.payload?.info ?? {});

  return {
    ...state,
    success: true,
    ...userFlags,
  };
};

export const featureFlagsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FEATURE_FLAGS.SUCCEEDED:
      return applyFeatureFlags(state, action);
    case GET_FEATURE_FLAGS.ERRORED:
      return {
        error: true,
        success: false,
      };
    default:
      return state;
  }
};
