import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  breakpoints,
  TooltipHeader,
  TooltipModal,
  Responsive,
} from '@leagueplatform/ui-kit';
import { BodyOne, genesisStyled, css } from '@leagueplatform/genesis-commons';

const StyledList = genesisStyled.ul(
  css({
    listStylePosition: 'outside',
    listStyleType: 'disc',
    marginBottom: 'half',
    paddingLeft: 'one',
  }),
);

const Li = genesisStyled.li(
  css({
    marginLeft: 'one',
    marginTop: 'half',
  }),
);

export const EOITooltipContent = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      {formatMessage({ id: 'EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT1' })}
      <StyledList>
        {formatMessage(
          { id: 'EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_LIST' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { li: text => <Li>{text}</Li> },
        )}
      </StyledList>
      {formatMessage({ id: 'EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT2' })}
    </>
  );
};

export const EOITooltip = () => {
  const { formatMessage } = useIntl();

  const tooltipLabel = (
    <BodyOne display="inline-block" borderBottom="2px dotted">
      {formatMessage({ id: 'EVIDENCE_OF_INSURABILITY' })}
    </BodyOne>
  );

  return (
    <>
      <Responsive breakpoint={breakpoints.tabletAndAbove}>
        <TooltipHeader
          tooltipLink={tooltipLabel}
          tooltipText={<EOITooltipContent />}
          inline
          top
          center
          right={false}
          verticalPosition="top: 40px"
        />
      </Responsive>
      <Responsive breakpoint={breakpoints.belowTablet}>
        <TooltipModal
          display="inline"
          isFullScreen
          label={tooltipLabel}
          content={<EOITooltipContent />}
        />
      </Responsive>
    </>
  );
};
