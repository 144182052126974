import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, filter } from 'lodash';
import { Dimmer, Message, Table } from 'semantic-ui-react';
import { Heading, Box, Text } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import PayrollRow from './payroll-row.view';
import styled from 'styled-components';

const CenteredHeaderCell = styled(Table.HeaderCell)`
  text-align: center;
`;

const PayrollReportsPage = ({ invoices, onReportDownload }) => {
  const filteredInvoices = filter(
    invoices,
    invoice =>
      invoice.content_references.ca_payroll ||
      invoice.content_references.dayforce,
  );

  return (
    <Dimmer.Dimmable as={Box}>
      <Heading level={5}>
        <FormattedMessage id="PAYROLL_HEADER" />
      </Heading>
      <Text>
        <FormattedMessage id="PAYROLL_HEADER_COPY" />
      </Text>
      {!isEmpty(filteredInvoices) ? (
        <Table basic="very" padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="PAYROLL_HEADER_BILLING_PERIOD" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="PAYROLL_HEADER_DATE_ISSUED" />
              </Table.HeaderCell>
              <CenteredHeaderCell>
                <FormattedMessage id="PAYROLL_HEADER_DOWNLOAD" />
              </CenteredHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(filteredInvoices, invoice => (
              <PayrollRow
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...invoice}
                key={invoice.receipt_number}
                onReportDownload={onReportDownload}
                contentReferences={invoice.content_references}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message>
          <FormattedMessage id="NO_PAYROLL_MESSAGE" />
        </Message>
      )}
    </Dimmer.Dimmable>
  );
};

PayrollReportsPage.propTypes = {
  invoices: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  onReportDownload: PropTypes.func.isRequired,
};

PayrollReportsPage.defaultProps = {
  invoices: null,
};

export default PayrollReportsPage;
