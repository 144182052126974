import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Formik, Form } from 'formik';
import {
  Box,
  TextField,
  PrimaryButton,
  LabelText,
  Flex,
  Label,
  Spinner,
  SubtitleOne,
  HeadingThree,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import { Toggle } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import { useSubscriptionFees } from './use-subscription-fees.hook';
import formSubscriptionFeesSchema from './form-subscription-fees.schema.json';
import { useValidationSchema } from 'common/json-schema-form';

export const SubscriptionFees = ({ groupId }) => {
  const { formatMessage } = useIntl();

  const { subscriptionFeeData, onSubmitSubscriptionFees, isLoading } =
    useSubscriptionFees(groupId);

  const validationSchema = useValidationSchema({
    schema: formSubscriptionFeesSchema,
  });

  const chargeTypeOptions = [
    { value: 'no fees', label: 'No Subscription Fees' },
    { value: 'pepm', label: 'Subscription Fees PEPM' },
    {
      value: 'per benefit class',
      label: 'Subscription Fees Per Benefit Class',
    },
  ];

  const benefitClasses = subscriptionFeeData?.benefit_classes;

  const newSubscriptionFee = benefitClasses?.map(benefitClass => {
    const entries = Object.entries(
      subscriptionFeeData?.platform_fees_per_benefit_class ?? '',
    );
    let newObj = { ...benefitClass, value: 0 };
    entries.map(([key, value]) => {
      if (benefitClass.id === key) {
        newObj = { ...benefitClass, value };
      }
      return newObj;
    });
    return newObj;
  });

  const chargeTypeInitialValue = () => {
    if (subscriptionFeeData?.subscription_rate_pepm) {
      return chargeTypeOptions[1].value;
    }
    if (newSubscriptionFee?.find(item => item.value !== 0)) {
      return chargeTypeOptions[2].value;
    }
    return chargeTypeOptions[0].value;
  };

  const handleSubmitSubscriptionFees = async (values, actions) => {
    let newValues = values;
    if (values.chargeSubscriptionFeeType === 'per benefit class') {
      newValues = {
        ...newValues,
        subscriptionRatePepm: 0,
      };
    }
    if (values.chargeSubscriptionFeeType === 'pepm') {
      newValues = {
        ...newValues,
        platformFeesPerBenefitClass: values.platformFeesPerBenefitClass.map(
          item => ({
            ...item,
            value: 0,
          }),
        ),
      };
    }
    await onSubmitSubscriptionFees(newValues);
    actions.resetForm();
  };

  const initialValues = {
    chargeSubscriptionFeeType: chargeTypeInitialValue(),
    groupId,
    platformFeesForInvitedUsers:
      subscriptionFeeData?.platform_fees_for_invited_users || false,
    subscriptionRatePepm: subscriptionFeeData?.subscription_rate_pepm || 0,
    platformFeesPerBenefitClass: newSubscriptionFee,
  };

  if (isLoading) return <Spinner isLoading={isLoading} />;

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSubscriptionFees}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <Box>
              <Label>
                <LabelText paddingBottom="oneAndHalf" marginRight="oneAndHalf">
                  <HeadingThree fontWeight="normal">
                    {formatMessage({
                      id: 'CHARGE_SUBSCRIPTION_FEES',
                    })}
                  </HeadingThree>
                </LabelText>
                <Select
                  name="chargeSubscriptionFeeType"
                  options={chargeTypeOptions}
                  value={values.chargeSubscriptionFeeType}
                  onChange={option => {
                    setFieldValue('chargeSubscriptionFeeType', option.value);
                  }}
                />
              </Label>

              {(values.chargeSubscriptionFeeType === 'pepm' ||
                values.chargeSubscriptionFeeType === 'per benefit class') && (
                <Box marginTop="oneAndHalf">
                  <Label>
                    <Flex>
                      <LabelText
                        paddingBottom="oneAndHalf"
                        marginRight="oneAndHalf"
                      >
                        <SubtitleOne fontWeight="normal">
                          {formatMessage({
                            id: 'SUBSCRIPTION_FEES_FOR_INVITED_USER',
                          })}
                        </SubtitleOne>
                      </LabelText>
                      <Toggle
                        name="platformFeesForInvitedUsers"
                        checked={!!values.platformFeesForInvitedUsers}
                        onChange={() =>
                          setFieldValue(
                            'platformFeesForInvitedUsers',
                            !values.platformFeesForInvitedUsers,
                          )
                        }
                      />
                    </Flex>
                  </Label>
                </Box>
              )}

              {values.chargeSubscriptionFeeType === 'pepm' && (
                <Box marginTop="one">
                  <Label>
                    <Flex justifyContent="space-between">
                      <LabelText>
                        <SubtitleOne fontWeight="normal">
                          {formatMessage({ id: 'SUBSCRIPTION_RATE' })}
                        </SubtitleOne>
                      </LabelText>
                      <TextField
                        placeholder="$"
                        type="number"
                        name="subscriptionRatePepm"
                        value={values?.subscriptionRatePepm}
                        onChange={evt => {
                          setFieldValue(
                            'subscriptionRatePepm',
                            evt.target.value,
                          );
                        }}
                      />
                    </Flex>
                  </Label>
                </Box>
              )}

              {values.chargeSubscriptionFeeType === 'per benefit class' && (
                <Box marginY="one">
                  <Label marginRight="oneAndHalf">
                    <SubtitleOne fontWeight="normal">
                      {formatMessage({
                        id: 'SUBSCRIPTION_FEES_FOR_PER_BENEFIT_CLASS',
                      })}
                    </SubtitleOne>

                    {values.platformFeesPerBenefitClass.map(
                      (platformFee, index) => (
                        <Flex justifyContent="space-between" marginTop="half">
                          <LabelText>
                            <BodyOne>{platformFee.name}</BodyOne>
                          </LabelText>
                          <TextField
                            value={platformFee.value}
                            type="number"
                            name={`platformFeesPerBenefitClass.${index}`}
                            placeholder="$"
                            onChange={evt => {
                              setFieldValue(
                                `platformFeesPerBenefitClass.${index}`,
                                {
                                  ...values.platformFeesPerBenefitClass[index],
                                  value: evt.target.value,
                                },
                              );
                            }}
                          />
                        </Flex>
                      ),
                    )}
                  </Label>
                </Box>
              )}
            </Box>
            {(values.chargeSubscriptionFeeType === 'pepm' ||
              values.chargeSubscriptionFeeType === 'per benefit class') && (
              <PrimaryButton marginY="two" onClick={handleSubmit} type="submit">
                {formatMessage({ id: 'SAVE_SUBSCRIPTION_FEES' })}
              </PrimaryButton>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

SubscriptionFees.propTypes = {
  groupId: PropTypes.string.isRequired,
};
