import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Box, Flex, css, genesisStyled } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { usePlanContextMenuReducer } from './use-plan-context-menu-reducer';
import { DefaultPlanViewer } from './default-plan-viewer/default-plan-viewer.view';
import { StartEnrollment } from './start-enrollment';
import {
  selectCanStartEnrollment,
  selectCurrentPlanStatus,
  selectCurrentPlanId,
} from '../../enrollment-config.selectors';
import { useExportBenefitPlanOptions } from './use-export-benefit-plan-options.hook';
import { PLAN_STATUSES } from 'common/constants';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const MenuItemBase = genesisStyled(Box)(
  css({
    whiteSpace: 'pre',
    '&:hover': {
      backgroundColor: 'decorative.brand.primary.pastel',
      cursor: 'pointer',
    },
  }),
);

const MenuItem = ({ disabled, ...props }) =>
  disabled ? (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      paddingX="one"
      paddingY="half"
      color="tertiary.border.default"
    />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <MenuItemBase {...props} paddingX="one" paddingY="half" />
  );

MenuItem.propTypes = {
  disabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  disabled: false,
};

const getStartEnrollmentTextIdForStatus = status => {
  switch (status) {
    case PLAN_STATUSES.ACTIVE:
      return 'ENROLLMENT_COMPLETE';
    case PLAN_STATUSES.ENROLLING:
      return 'ENROLLMENT_STARTED';
    default:
      return 'START_ENROLLMENT';
  }
};

export const PlanContextMenuPresenter = ({
  canStartEnrollment,
  planStatus,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    isMenuOpen,
    isViewingDefaultPlan,
    isStartingEnrollment,
    toggleOpen,
    reset,
    viewDefaultPlan,
    startEnrollment,
  } = usePlanContextMenuReducer();
  const {
    downloadExport,
    loading: isDownloadingExport,
    error,
  } = useExportBenefitPlanOptions(props);

  useEffect(() => {
    if (error) {
      toastActions.add({ text: error.info.reason, type: TOAST_STATUS.ERROR });
    }
  }, [error]);

  return (
    <Flex
      position="relative"
      onClick={evt => evt.stopPropagation()}
      padding="quarter"
      justifyContent="center"
      fontSize="body2"
    >
      <Box onClick={() => toggleOpen()}>•••</Box>
      {isMenuOpen && (
        <Box
          backgroundColor="surface.background.primary"
          position="absolute"
          top="90%"
          right={10}
          paddingY="one"
          boxShadow="dropdown"
          color="onSurface.text.primary"
          zIndex={99}
          minWidth={200}
        >
          <MenuItem onClick={() => viewDefaultPlan()}>
            {formatMessage({ id: 'VIEW_DEFAULT_PLAN' })}
          </MenuItem>
          <MenuItem onClick={downloadExport} disabled={isDownloadingExport}>
            {!isDownloadingExport
              ? formatMessage({ id: 'EXPORT_CONFIGURATION' })
              : formatMessage({ id: 'DOWNLOADING' })}
          </MenuItem>
          <MenuItem
            disabled={!canStartEnrollment}
            onClick={() => canStartEnrollment && startEnrollment()}
          >
            {formatMessage({
              id: getStartEnrollmentTextIdForStatus(planStatus),
            })}
          </MenuItem>
        </Box>
      )}
      {isViewingDefaultPlan && <DefaultPlanViewer onClose={() => reset()} />}
      {isStartingEnrollment && (
        <StartEnrollment
          onClose={() => reset()}
          canStartEnrollment={canStartEnrollment}
        />
      )}
    </Flex>
  );
};

PlanContextMenuPresenter.propTypes = {
  planStatus: PropTypes.oneOf([
    PLAN_STATUSES.ACTIVE,
    PLAN_STATUSES.ENROLLING,
    PLAN_STATUSES.CREATED,
  ]).isRequired,
  canStartEnrollment: PropTypes.bool.isRequired,
};

export const PlanContextMenu = connect(
  createStructuredSelector({
    planId: selectCurrentPlanId,
    planStatus: selectCurrentPlanStatus,
    canStartEnrollment: selectCanStartEnrollment,
  }),
)(PlanContextMenuPresenter);
