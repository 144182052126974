const SET_CLAIM_DETAILS_TAB = 'page:claim-details/SET_CURRENT_TAB';
const REQUEST_CLAIM_DETAILS_CLAIM = 'page:claim-details/REQUEST_CLAIM';
const REQUEST_CLAIM_DETAILS_CLAIM_USER =
  'page:claim-details/REQUEST_CLAIM_USER';
const REQUEST_CLAIM_DETAILS_CLAIM_USER_DEPENDENTS =
  'page:claim-details/REQUEST_CLAIM_USER_DEPENDENTS';
const REQUEST_CLAIM_DETAILS_CLAIM_AGENT =
  'page:claim-details/REQUEST_CLAIM_USER_AGENT';
const REQUEST_CLAIM_DETAILS_CLAIM_USER_GROUP =
  'page:claim-details/REQUEST_CLAIM_USER_GROUP';
export const CLAIM_RESOURCES_LOADED =
  'page:claim-details/CLAIM_RESOURCES_LOADED';
export const USER_HISTORY_INITIALIZED =
  'page:claim-details/USER_HISTORY_INITIALIZED';
export const PIN_DOCUMENT = 'claim-document/PIN_DOCUMENT';
export const UNPIN_DOCUMENT = 'claim-document/UNPIN_DOCUMENT';

export default {
  SET_CLAIM_DETAILS_TAB,
  REQUEST_CLAIM_DETAILS_CLAIM,
  REQUEST_CLAIM_DETAILS_CLAIM_USER,
  REQUEST_CLAIM_DETAILS_CLAIM_AGENT,
  REQUEST_CLAIM_DETAILS_CLAIM_USER_GROUP,
  REQUEST_CLAIM_DETAILS_CLAIM_USER_DEPENDENTS,
  PIN_DOCUMENT,
  UNPIN_DOCUMENT,
};
