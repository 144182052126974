import React from 'react';
import PropTypes from 'prop-types';
import { Table, Label, List, Checkbox } from 'semantic-ui-react';
import { employeePropTypes } from '../constants';
import ChangesMessage from './ChangesMessage';
import { visibleOnlyAdmin } from 'common/state/user/role-based-visibility.hocs';
import styled from 'styled-components';
import { FormattedNumber } from 'react-intl';
import { vars, Text } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { EMPLOYEE_STATE_NAMES } from 'common/constants';
import { ADMIN } from '@leagueplatform/web-common';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES } from '../../../../../employer-experience-v2/constants';

const RowWithContextMenuCell = styled(Table.Row)`
  cursor: pointer;
  td.context-menu-cell.allow-overflow {
    overflow: visible;

    .menu.visible {
      position: absolute;
    }
  }
`;

const HRAdminBadge = styled.span`
  display: inline-block;
  color: ${vars.colour.mediumGrey};
`;

const OnlyAdmin = visibleOnlyAdmin(Table.Cell);

const Row = ({
  name,
  email,
  status,
  userId,
  allocated,
  balance,
  selectEmployee,
  employerId,
  groupId,
  groupRole,
  benefitClass,
  suspendedType,
  suspendedDate,
  reinstatedDate,
  isAdmin,
  ActionsDropdown,
  hasSpendingAccounts,
  hasTopupableSpendingAccounts,
  employeeSelected,
  inviteURL,
  onSelectEmployee,
  index,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const statusName = EMPLOYEE_STATE_NAMES[status] || 'UNKNOWN';

  const selectRow = () => {
    selectEmployee(userId, employerId, status, inviteURL);

    if (!isAdmin) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
        product_area: PRODUCT_AREA.HR_ADMIN,
        detail: 'select employee',
        sub_product_area: null,
      });
    }
  };
  return (
    <RowWithContextMenuCell key={userId}>
      <OnlyAdmin>
        <Checkbox
          name="selectAllEmployee"
          checked={employeeSelected}
          onChange={onSelectEmployee}
        />
      </OnlyAdmin>
      <Table.Cell
        className="title-cell"
        onClick={selectRow}
        data-testid="employee-row"
      >
        <List>
          <List.Item>
            <div>
              <Text className="title-cell__name">
                {name}
                {groupRole === ADMIN && (
                  <HRAdminBadge>&nbsp;-&nbsp;{groupRole}</HRAdminBadge>
                )}
                <br />
              </Text>
              <Text className="title-cell__email">{email}</Text>
              <Text>
                <ChangesMessage
                  suspendedType={suspendedType}
                  suspendedDate={suspendedDate}
                  reinstatedDate={reinstatedDate}
                />
              </Text>
            </div>
          </List.Item>
        </List>
      </Table.Cell>
      <Table.Cell className="status-cell">
        <Label
          className={`label-${status}`}
          icon="circle"
          content={formatMessage({ id: statusName })}
        />
      </Table.Cell>
      <Table.Cell>
        <Text className="title-cell__class">{benefitClass}</Text>
      </Table.Cell>
      {hasSpendingAccounts && (
        <Table.Cell className="allocation-amount">
          {allocated && allocated.C && (
            <FormattedNumber
              style="currency" // eslint-disable-line
              currencyDisplay="symbol"
              currency={allocated.C}
              value={allocated.F}
            />
          )}
        </Table.Cell>
      )}
      {hasSpendingAccounts && (
        <Table.Cell className="balance-amount">
          {balance && balance.C && (
            <FormattedNumber
              style="currency" // eslint-disable-line
              currencyDisplay="symbol"
              currency={balance.C}
              value={balance.F}
            />
          )}
        </Table.Cell>
      )}
      <Table.Cell className="context-menu-cell allow-overflow">
        <ActionsDropdown
          name={name}
          userId={userId}
          groupId={groupId}
          groupRole={groupRole}
          status={status}
          suspendedType={suspendedType}
          suspendedDate={suspendedDate}
          reinstateDate={reinstatedDate}
          icon="ellipsis horizontal"
          btn={false}
          url={inviteURL}
          isAdmin={isAdmin}
          hasSpendingAccounts={hasSpendingAccounts}
          hasTopupableSpendingAccounts={hasTopupableSpendingAccounts}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      </Table.Cell>
    </RowWithContextMenuCell>
  );
};

Row.propTypes = {
  ...employeePropTypes,
  selectedEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleEmployee: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  ActionsDropdown: PropTypes.func.isRequired,
  hasSpendingAccounts: PropTypes.bool.isRequired,
  hasTopupableSpendingAccounts: PropTypes.bool.isRequired,
  employeeSelected: PropTypes.bool.isRequired,
  inviteURL: PropTypes.string.isRequired,
  onSelectEmployee: PropTypes.func.isRequired,
  invitationId: PropTypes.string.isRequired,
};

export default Row;
