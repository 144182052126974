import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon, Label } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class AddBenefit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCatalogue: false,
    };

    this.shouldShowCatalogue = this.shouldShowCatalogue.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps({ showCatalogue }) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.showCatalogue !== showCatalogue) {
      this.setState({ showCatalogue });
    }
  }

  async shouldShowCatalogue() {
    await this.setState(previousState => ({
      showCatalogue: !previousState.showCatalogue,
    }));
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.shouldShowCatalogue(this.state.showCatalogue);
  }

  render() {
    return (
      <Container className="container--add-benefit">
        <Label onClick={this.shouldShowCatalogue}>
          <Icon fitted name="add" />
          <FormattedMessage id="ADD_BENEFIT" />
        </Label>
      </Container>
    );
  }
}

AddBenefit.propTypes = {
  shouldShowCatalogue: PropTypes.func.isRequired,
  showCatalogue: PropTypes.bool.isRequired,
};

export default injectIntl(AddBenefit);
