import {
  GET_USER_DOCUMENTS,
  USER_DOCUMENT_SET,
  EDIT_DOCUMENT_FORM_SUBMITTED,
  USER_DOCUMENT_REMOVE_CONFIRMED,
  CONFIRM_USER_DOCUMENT_REMOVAL,
  USER_DOCUMENT_REMOVE_CANCELLED,
  RESET_USER_DOCUMENTS,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  OPEN_IMAGE_VIEWER,
  CLOSE_IMAGE_VIEWER,
  UPLOAD_DOCUMENT_FORM_SUBMITTED,
  EDIT_USER_DOCUMENT,
  PIN_CLAIM_DOCUMENT,
  REQUEST_USER_DOCUMENTS,
  ADD_DOCUMENT_TO_CLAIM,
  REMOVE_DOCUMENT_FROM_CLAIM,
  EDIT_USER_DOCUMENT_CANCELLED,
  SET_ACTIVE_INDEX,
  REQUEST_SUBMIT_TO_STRIPE,
  GENERATE_USER_FORM_CONTENT_ID,
} from './user-documents.types';

/**
 * Since forms are newly generated from the backend, we have to request a new content id
 * each time we want to download a form.
 */
export const generateUserFormContentId = ({ formType, benefitId }) => ({
  type: GENERATE_USER_FORM_CONTENT_ID,
  payload: { formType, benefitId },
});

export const getUserDocuments = userId => ({
  type: GET_USER_DOCUMENTS,
  userId,
});

export const userDocumentsLoaded = ({ info }) => ({
  type: REQUEST_USER_DOCUMENTS.SUCCEEDED,
  payload: info,
});

export const setUserDocument = ({
  userId,
  contentId,
  notes,
  expirationDate,
  documentType,
  documentTypeOtherDescription,
  name,
}) => ({
  type: USER_DOCUMENT_SET,
  userId,
  contentId,
  documentType,
  documentTypeOtherDescription,
  name,
  expirationDate,
  notes,
});

export const updateUserDocument = ({
  userId,
  notes,
  expirationDate,
  documentType,
  documentTypeOtherDescription,
  name,
  files,
  userDocumentId,
}) => ({
  type: EDIT_DOCUMENT_FORM_SUBMITTED,
  userId,
  documentType,
  documentTypeOtherDescription,
  name,
  expirationDate,
  notes,
  files,
  userDocumentId,
});

export const removeUserDocument = ({
  userId,
  contentId,
  userDocumentId,
  isMember,
}) => ({
  type: USER_DOCUMENT_REMOVE_CONFIRMED,
  userId,
  contentId,
  userDocumentId,
  isMember,
});

export const confirmRemoveUserDocument = (userId, contentId) => ({
  type: CONFIRM_USER_DOCUMENT_REMOVAL,
  userId,
  contentId,
});

export const cancelRemoveUserDocument = () => ({
  type: USER_DOCUMENT_REMOVE_CANCELLED,
});

export const resetUserDocuments = () => ({
  type: RESET_USER_DOCUMENTS,
});

export const openUploadModal = () => ({
  type: OPEN_UPLOAD_MODAL,
});

export const closeUploadModal = () => ({
  type: CLOSE_UPLOAD_MODAL,
});

export const openImageViewer = activeIndex => ({
  type: OPEN_IMAGE_VIEWER,
  activeIndex,
});

export const setActiveIndex = activeIndex => ({
  type: SET_ACTIVE_INDEX,
  activeIndex,
});

export const closeImageViewer = () => ({
  type: CLOSE_IMAGE_VIEWER,
});

export const uploadUserDocument = payload => ({
  type: UPLOAD_DOCUMENT_FORM_SUBMITTED,
  payload,
});

export const requestSubmitToStripe = payload => ({
  type: REQUEST_SUBMIT_TO_STRIPE,
  payload,
});

export const editUserDocument = contentId => ({
  type: EDIT_USER_DOCUMENT,
  contentId,
});

export const cancelEditUserDocument = () => ({
  type: EDIT_USER_DOCUMENT_CANCELLED,
});

export const pinClaimDocument = ({
  contentIds,
  contentType,
  userId,
  claimContentIds,
  claimId,
  userDocumentId,
  name,
  documentType,
  documentTypeOtherDescription,
}) => ({
  type: PIN_CLAIM_DOCUMENT,
  contentIds,
  contentType,
  userId,
  claimContentIds,
  claimId,
  userDocumentId,
  name,
  documentType,
  documentTypeOtherDescription,
});

export const addDocumentToClaim = ({
  claimId,
  claimContentIds,
  contentId,
}) => ({
  type: ADD_DOCUMENT_TO_CLAIM,
  claimId,
  claimContentIds,
  contentId,
});

export const removeDocumentFromClaim = ({
  claimId,
  claimContentIds,
  contentId,
}) => ({
  type: REMOVE_DOCUMENT_FROM_CLAIM,
  claimId,
  claimContentIds,
  contentId,
});
