import React from 'react';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { HeaderFlexContainer } from '..';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@leagueplatform/locales';
import {
  breakpoints,
  TooltipModal,
  Box,
  Responsive,
} from '@leagueplatform/ui-kit';
import { InfoBox } from 'common/components/info-box/info-box.view';
import PropTypes from 'prop-types';
import { HeadingFour } from '@leagueplatform/genesis-commons';

export const CantOptOut = ({ employerName, isFullyEmployerPaid }) => {
  const messageId = isFullyEmployerPaid
    ? 'CANT_OPT_OUT_INFO_EMPLOYER_PAID'
    : 'CANT_OPT_OUT_INFO';
  return (
    <Box>
      <Responsive breakpoint={breakpoints.lapAndAbove}>
        <HeaderFlexContainer>
          <HeadingFour as="h3">
            <FormattedMessage id="CANT_OPT_OUT_TITLE" />
          </HeadingFour>
        </HeaderFlexContainer>
      </Responsive>
      <InfoBox withIcon>
        <Responsive breakpoint={breakpoints.lapAndAbove}>
          <FormattedHTMLMessage id={messageId} values={{ employerName }} />
        </Responsive>
        <Responsive breakpoint={breakpoints.belowLap}>
          <TooltipModal
            label={<FormattedMessage id="CANT_OPT_OUT_TITLE" />}
            content={
              <FormattedHTMLMessage id={messageId} values={{ employerName }} />
            }
          />
        </Responsive>
      </InfoBox>
    </Box>
  );
};

CantOptOut.propTypes = {
  employerName: PropTypes.string.isRequired,
  isFullyEmployerPaid: PropTypes.bool,
};

CantOptOut.defaultProps = {
  isFullyEmployerPaid: false,
};
