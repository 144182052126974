import { takeLatest } from 'redux-saga/effects';
import { getGroupDashboards, getGroupDashboardURL } from 'common/services';
import { request } from 'common/websocket-redux';
import {
  GET_DASHBOARDS,
  GET_DASHBOARD_URL,
} from './employer-insights.action-types';

export function* getDashboards({ payload }) {
  yield request(GET_DASHBOARDS, getGroupDashboards(payload));
}

export function* getDashboardURL({ payload }) {
  yield request(GET_DASHBOARD_URL, getGroupDashboardURL(payload));
}

export function* employerInsightsSaga() {
  yield takeLatest(GET_DASHBOARDS.BASE, getDashboards);
  yield takeLatest(GET_DASHBOARD_URL.BASE, getDashboardURL);
}
