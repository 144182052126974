import { selectApp } from 'apps/employer-experience-v2/app.selectors';
import { createSelector } from 'reselect';

const selectPricingScenarioReport = createSelector(
  selectApp,
  app => app.employerPricingScenarioReport,
);

const selectReports = createSelector(
  selectPricingScenarioReport,
  pricingScenarioReport => pricingScenarioReport?.report,
);

export const selectRequestStatus = createSelector(
  selectPricingScenarioReport,
  pricingScenarioReport => pricingScenarioReport?.requestStatus,
);

export const selectContentId = createSelector(
  selectReports,
  reports => reports?.contentId,
);

export const selectFileName = createSelector(
  selectReports,
  reports => reports?.fileName,
);
