import {
  ON_GET_CHAT_TRANSCRIPTS,
  GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS,
  GET_SALESFORCE_CHAT_TRANSCRIPTS,
  RESET_CHAT_TRANSCRIPTS,
} from '../chat-page.action-types';
import { LIMITS } from '../constants';

export const chatTranscriptsInitialState = {
  isFirstPageLoading: false,
  hasFirstPageErrored: false,
  isNextPageLoading: false,
  hasNextPageErrored: false,
  areMoreAvailable: false,
  offset: 0,
  transcripts: null,
};

const areMoreTranscriptsAvailable = (state, transcripts) =>
  transcripts.length === LIMITS.CHAT_TRANSCRIPTS + state.offset;

export const chatTranscriptsReducer = (
  state = chatTranscriptsInitialState,
  action = {},
) => {
  switch (action.type) {
    case ON_GET_CHAT_TRANSCRIPTS:
      return {
        ...state,
        offset: action.payload.offset,
      };
    case GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS.STARTED:
      return {
        ...state,
        isFirstPageLoading: true,
      };
    case GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS.SUCCEEDED: {
      const transcripts = action.payload.info.transcripts ?? [];

      return {
        ...state,
        isFirstPageLoading: false,
        areMoreAvailable: areMoreTranscriptsAvailable(state, transcripts),
        transcripts,
      };
    }
    case GET_INITIAL_SALESFORCE_CHAT_TRANSCRIPTS.ERRORED:
      return {
        ...state,
        isFirstPageLoading: false,
        hasFirstPageErrored: true,
      };
    case GET_SALESFORCE_CHAT_TRANSCRIPTS.STARTED:
      return {
        ...state,
        isNextPageLoading: true,
      };
    case GET_SALESFORCE_CHAT_TRANSCRIPTS.SUCCEEDED: {
      const newTranscripts = action.payload.info.transcripts ?? [];
      const transcripts = [...(state.transcripts ?? []), ...newTranscripts];

      return {
        ...state,
        isNextPageLoading: false,
        hasNextPageErrored: false,
        areMoreAvailable: areMoreTranscriptsAvailable(state, transcripts),
        transcripts,
      };
    }
    case GET_SALESFORCE_CHAT_TRANSCRIPTS.ERRORED:
      return {
        ...state,
        isNextPageLoading: false,
        hasNextPageErrored: true,
      };
    case RESET_CHAT_TRANSCRIPTS:
      return { ...chatTranscriptsInitialState };
    default:
      return state;
  }
};
