import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TermsView } from './terms.view';
import {
  selectHasUserAcceptedTerms,
  selectIsRevisedTerms,
  selectUserDataIsLoading,
} from './terms.selectors';
import { setUserConfig, setUserMarketingOptIn } from './terms.actions';
import { selectIsMarketingOptedIn } from 'common/state/user/user.selectors';
import { selectOnboardingEnabledFlag } from 'common/state/featureFlags/onboarding-enabled-flag.selectors';

import { setOnboardingConfig } from '@leagueplatform/onboarding';

export const TermsContainer = () => {
  const dispatch = useDispatch();
  const areTermsAgreedTo = useSelector(selectHasUserAcceptedTerms);
  const isRevisedTerms = useSelector(selectIsRevisedTerms);
  const isMarketingOptedIn = useSelector(selectIsMarketingOptedIn);
  const isUserDataLoading = useSelector(selectUserDataIsLoading);
  const isOnboardingEnabled = useSelector(selectOnboardingEnabledFlag);

  const handleNextStepClick = (termsAgree, marketingOptIn) => {
    if (termsAgree) {
      dispatch(setUserConfig());
      dispatch(setUserMarketingOptIn({ marketingOptIn }));
    }
  };

  useEffect(() => {
    async function setShowOnboardingFlag() {
      // Need to put this request behind a feature flag
      try {
        await setOnboardingConfig({ showOnboarding: true });
      } catch (error) {
        // Allowing an empty catch so that function fails silently
        // eslint-disable-next-line no-empty
      }
    }
    if (isOnboardingEnabled) {
      setShowOnboardingFlag();
    }
  }, [isOnboardingEnabled]);

  return (
    !isUserDataLoading && (
      <TermsView
        onNextStepClick={handleNextStepClick}
        areTermsAgreedTo={areTermsAgreedTo}
        isMarketingOptedIn={isMarketingOptedIn}
        isRevisedTerms={isRevisedTerms}
      />
    )
  );
};
