import {
  EMPLOYEE_ROW_SELECTED,
  NEW_EMPLOYEE_FORM_SUBMITTED,
  BENEFIT_CLASS_INPUT_CHANGED,
  EDIT_EMPLOYEE_FORM_SUBMITTED,
  NEW_EMPLOYEE_VISITED,
  EDIT_EMPLOYEE_VISITED,
  CLEAR_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE_CONTRIBUTIONS,
  GET_USER_BENEFIT_CONTRIBUTIONS,
  GET_USER_DEDUCTIONS,
  UPDATE_USER_DEDUCTIONS,
  GET_USER_ADJUSTMENTS,
} from './types';

export const onEmployeeRowSelected = (userId, groupId, status, inviteURL) => ({
  type: EMPLOYEE_ROW_SELECTED,
  payload: { userId, groupId, status, inviteURL },
});

export const newEmployeeSubmitted = formValues => ({
  type: NEW_EMPLOYEE_FORM_SUBMITTED,
  payload: formValues,
});

export const editEmployeeSubmitted = formValues => ({
  type: EDIT_EMPLOYEE_FORM_SUBMITTED,
  payload: formValues,
});

export const benefitClassChanged = benefitClassId => ({
  type: BENEFIT_CLASS_INPUT_CHANGED,
  payload: { benefitClassId },
});

export const newEmployeeVisited = () => ({
  type: NEW_EMPLOYEE_VISITED,
});

export const editEmployeeVisited = (userId, groupId) => ({
  type: EDIT_EMPLOYEE_VISITED,
  payload: { userId, groupId },
});

export const clearEmployeeDetails = () => {
  return {
    type: CLEAR_EMPLOYEE_DETAILS,
  };
};

export const updateEmployeeContributions = payload => ({
  type: UPDATE_EMPLOYEE_CONTRIBUTIONS,
  payload,
});

export const getUserBenefitContributions = payload => ({
  type: GET_USER_BENEFIT_CONTRIBUTIONS.BASE,
  payload,
});

export const getUserDeductions = payload => ({
  type: GET_USER_DEDUCTIONS.BASE,
  payload,
});

export const updateUserDeductions = payload => ({
  type: UPDATE_USER_DEDUCTIONS.BASE,
  payload,
});

export const getUserAdjustments = payload => ({
  type: GET_USER_ADJUSTMENTS.BASE,
  payload,
});
