import { request } from 'common/websocket-redux';
import { fork, all, takeLatest } from 'redux-saga/effects';
import { getPurchaseAndClaimHistory } from 'common/services';
import { GET_PURCHASE_HISTORY } from './purchase-history-page.action-types';
import { LIMITS, PAYMENT_TYPES } from './purchase-history-page.constants';

export function* getPurchaseHistory({ payload: { userId, offset } }) {
  yield request(
    GET_PURCHASE_HISTORY,
    getPurchaseAndClaimHistory({
      userId,
      skip: offset,
      limit: LIMITS.PURCHASE_HISTORY,
      paymentTypes: [PAYMENT_TYPES.LEAGUE_CREDIT],
    }),
  );
}

export function* purchaseHistorySaga() {
  yield fork(all, [takeLatest(GET_PURCHASE_HISTORY.BASE, getPurchaseHistory)]);
}
