export const steps = {
  downloadTemplate: {
    index: 1,
    title: 'BULK_UPLOAD_DOWNLOAD_TEMPLATE_TITLE',
    description: 'BULK_UPLOAD_DOWNLOAD_TEMPLATE_DESC',
  },
  uploadCensus: {
    index: 2,
    title: 'BULK_UPLOAD_UPLOAD_CENSUS_TITLE',
    description: 'BULK_UPLOAD_UPLOAD_CENSUS_DESC',
  },
  saveImport: {
    index: 3,
    title: 'BULK_UPLOAD_SAVE_IMPORT_TITLE',
    description: 'BULK_UPLOAD_SAVE_IMPORT_DESC',
  },
};
