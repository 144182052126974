import React from 'react';
import { Flex } from '@leagueplatform/genesis-commons';
import { Spinner } from '@leagueplatform/ui-kit';

export const LoadingBox = () => (
  <Flex
    justifyContent="center"
    alignItems="center"
    backgroundColor="transparent"
    height={160}
    width={1}
    marginBottom={['one', 'one', 'one', 'two']}
  >
    <Spinner />
  </Flex>
);
