import React from 'react';
import {
  colour,
  fontSizeInPx,
  spaceInPx,
  vars,
  Box,
  Input,
  Text,
  QuestionIcon,
  Tooltip,
  TooltipContentV2,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledQuestionIcon = styled(QuestionIcon)`
  margin-left: ${spaceInPx(1)};
  border-radius: 50%;
  border: 1px solid ${colour('darkGrey')};
  cursor: pointer;
  &&& {
    width: 20px;
    height: 20px;
    padding: 4px;
    margin: 7px 0px 0px -2px;
  }
`;

export const Blurb = styled(Box)`
  border-top: 1px solid ${vars.colour.greyLighter};
  padding-top: ${spaceInPx(2)};
  margin: ${spaceInPx(2)} 0;
`;

export const StyledInput = styled(Input)`
  height: 3rem;
  width: 767px;
  margin: 0 0 16px 0;
  border: 1px solid ${vars.colour.greyLight};
  font-size: ${fontSizeInPx(2)};
  margin-bottom: 32px;
  &&& {
    padding: 0 ${spaceInPx(2)};
  }
`;

export const RedAsterisk = styled.span`
  color: ${vars.colour.red};
`;

// this overrides the last-of-type styling
// and prevents the text from appearing to jump up and down on toggle
export const FirstText = styled(Text)`
  &&& {
    margin-bottom: ${spaceInPx(2)};
  }
`;

export const LastText = styled(Text)`
  &&& {
    margin-bottom: ${spaceInPx(2)};
  }
`;

export const SectionHeading = ({ title, tooltipText }) => (
  <Flex align="center">
    <Text
      marginRight="genesis.half"
      marginBottom="genesis.none"
      fontSize={2}
      color="purpleDarkest"
      fontWeight="bold"
    >
      {title}
      {` `}
      <RedAsterisk>*</RedAsterisk>
    </Text>
    {tooltipText && (
      <Tooltip inline>
        <StyledQuestionIcon
          size="xs"
          color={vars.colour.darkGrey}
          icon="question"
        />
        <TooltipContentV2>{tooltipText}</TooltipContentV2>
      </Tooltip>
    )}
  </Flex>
);

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

SectionHeading.defaultProps = {
  tooltipText: '',
};
