import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { GenerateCSV } from './generate-csv.container';
import { EmployerReportsInfoBox } from 'apps/employer-experience/pages/EmployerDetails/employer-reports/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { BodyOne, Box, HeadingFour } from '@leagueplatform/genesis-commons';
import { REPORT } from './ui.reducer';

export const UsageReportsPagePresenter = ({
  visit,
  exit,
  detailedUsageIsLoading,
  detailedUsageIsReady,
  detailedUsageReportError,
  usageSummaryIsLoading,
  usageSummaryIsReady,
  usageSummaryReportError,
  lastChanged,
  ...props
}) => {
  // lifecycle
  useEffect(() => {
    visit();
    return () => {
      exit();
    };
  }, [visit, exit]);

  const { formatMessage } = useIntl();

  return (
    <Box>
      <HeadingFour>{formatMessage({ id: 'USAGE_REPORTS' })}</HeadingFour>
      <BodyOne marginTop="one">
        {formatMessage({ id: 'USAGE_REPORTS_COPY' })}
      </BodyOne>

      <GenerateCSV
        detailedUsageIsLoading={detailedUsageIsLoading}
        usageSummaryIsLoading={usageSummaryIsLoading}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
      <EmployerReportsInfoBox
        hasDateInput
        ready={
          lastChanged === REPORT.DETAILED_USAGE
            ? detailedUsageIsReady
            : usageSummaryIsReady
        }
        loading={
          lastChanged === REPORT.DETAILED_USAGE
            ? detailedUsageIsLoading
            : usageSummaryIsLoading
        }
        error={
          lastChanged === REPORT.DETAILED_USAGE
            ? detailedUsageReportError
            : usageSummaryReportError
        }
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
    </Box>
  );
};

UsageReportsPagePresenter.propTypes = {
  visit: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
  detailedUsageIsLoading: PropTypes.bool.isRequired,
  detailedUsageIsReady: PropTypes.bool.isRequired,
  detailedUsageReportError: PropTypes.string,
  usageSummaryIsLoading: PropTypes.bool.isRequired,
  usageSummaryIsReady: PropTypes.bool.isRequired,
  usageSummaryReportError: PropTypes.string,
  lastChanged: PropTypes.string.isRequired,
};

UsageReportsPagePresenter.defaultProps = {
  detailedUsageReportError: null,
  usageSummaryReportError: null,
};
