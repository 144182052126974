import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import ActiveInvoices from './active-invoices.container';
import { PendingFees } from './pending-fees';

export const InvoicesRouter = withRouter(({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        render={props => <ActiveInvoices {...props} />}
      />
      <Route
        exact
        path={`${match.url}/pending`}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        render={props => <PendingFees {...props} />}
      />
    </Switch>
  );
});

export default InvoicesRouter;
