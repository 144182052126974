import { useEffect } from 'react';

export const useLoadCoverageSummary = ({
  groupId,
  planId,
  loadCoverageSummary,
  loadEmployerBenefitPlans,
  userId,
  benefitClassId,
}) => {
  /* On load, we want to trigger loading plans so we can present a list of available plans */
  useEffect(() => {
    if (groupId) loadEmployerBenefitPlans({ groupId, benefitClassId });
  }, [groupId, loadEmployerBenefitPlans, benefitClassId]);

  /* fetch the coverage summary whenever a new plan is selected */
  useEffect(() => {
    if (planId) {
      loadCoverageSummary({ groupId, planId, userId });
    }
  }, [groupId, planId, userId, loadCoverageSummary]);
};
