import React from 'react';
import { fieldProps } from './field-shape';
import { TextInput } from '@leagueplatform/genesis-commons';

export const TextField = ({
  field: { name, ...field },
  disabled = false,
  readOnly = false,
  hasError = false,
  ...props
}) => {
  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...field}
      name={name}
      disabled={Boolean(disabled ?? readOnly)}
      error={hasError}
    />
  );
};

TextField.propTypes = fieldProps;
