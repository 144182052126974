import { REQUEST_QLE_SCHEMA } from './qle-screener.action-types';

export const initialState = {
  schema: null,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const qleScreenerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_QLE_SCHEMA.SUCCEEDED:
      return {
        ...state,
        schema: action.payload.info,
      };
    default:
      return state;
  }
};
