import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import { MultiSelect } from '@leagueplatform/ui-kit';
import {
  HeadingTwo,
  BodyOne,
  PrimaryButton,
  SecondaryButton,
  Label,
  LabelText,
} from '@leagueplatform/genesis-commons';

export const useCopyPlanHandlers = ({
  groupId,
  benefitPlanId,
  benefitClassId,
  benefitClasses,
  copyBenefitPlan,
  onClose,
}) => {
  const [destinationBenefitClassId, setDestinationBenefitClassId] =
    useState(benefitClassId);
  const benefitClassOptions = useMemo(
    () =>
      benefitClasses.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [benefitClasses],
  );
  const onSubmit = useCallback(() => {
    copyBenefitPlan({
      groupId,
      destinationBenefitClassId,
      originalBenefitPlanId: benefitPlanId,
    });
    onClose();
  }, [
    copyBenefitPlan,
    benefitPlanId,
    destinationBenefitClassId,
    groupId,
    onClose,
  ]);

  return {
    benefitClassOptions,
    destinationBenefitClassId,
    setDestinationBenefitClassId,
    onSubmit,
  };
};

export const CopyPlanPresenter = ({ onClose, benefitPlanName, ...props }) => {
  const {
    benefitClassOptions,
    destinationBenefitClassId,
    setDestinationBenefitClassId,
    onSubmit,
  } = useCopyPlanHandlers({
    ...props,
    onClose,
    benefitPlanName,
  });
  const { formatMessage } = useIntl();

  return (
    <Modal>
      <HeadingTwo>
        {formatMessage({ id: 'COPY_PLAN_HEADER' }, { benefitPlanName })}
      </HeadingTwo>
      <ModalContent width={600}>
        <BodyOne>
          {formatMessage({ id: 'COPY_PLAN_DESCRIPTION' }, { benefitPlanName })}
        </BodyOne>
        <Label marginTop="oneAndHalf">
          <LabelText>
            {formatMessage({ id: 'COPY_PLAN_BENEFIT_CLASS_SELECT_LABEL' })}
          </LabelText>
          <MultiSelect
            name="destination-benefit-class"
            options={benefitClassOptions}
            placeholder={formatMessage({ id: 'CHOOSE_A_BENEFIT_CLASS' })}
            hasError={false}
            isMulti={false}
            onChange={({ value } = {}) => {
              setDestinationBenefitClassId(value);
            }}
            value={benefitClassOptions.find(
              option => option.value === destinationBenefitClassId,
            )}
          />
        </Label>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit}>
          {formatMessage({ id: 'SUBMIT' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

CopyPlanPresenter.propTypes = {
  onClose: PropTypes.func.isRequired,
  benefitPlanName: PropTypes.string.isRequired,
};
