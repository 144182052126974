const REGEX_MOBILE_ALLOW_LIST = [
  /^\/member\/enroll/,
  /^\/app\/enroll/,
  /^\/app\/member\/coverage-summary/,
  /^\/app\/dependent\/profile/,
  /^\/app\/terms/,
  /^\/app\/?$/,
  /^\/$/,
];

export const isMobileAvailable = () => {
  const { pathname } = window.location;
  if (window.localStorage.getItem('hide_mobile_ui_block') === 'true')
    return true;
  return REGEX_MOBILE_ALLOW_LIST.some(rx => rx.test(pathname));
};
