import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  BodyOneSecondary,
  SubtitleOne,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';

export const SelectionWrapper = props => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    marginTop="half"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
export const SelectionTitle = props => <BodyOneSecondary flex="1" {...props} />;

export const SelectionValue = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <BodyOneSecondary textAlign="right" flex="1" {...props} />
);

const ValueWrapper = props => (
  <Flex
    flexDirection={{
      _: 'column',
      tablet: 'row',
    }}
    alignItems={{
      _: 'flex-end',
      tablet: 'center',
    }}
    textAlign="right"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const BenefitCost = ({ formattedCost, formattedPeriodType }) => {
  return (
    <ValueWrapper as="p">
      <SubtitleOne as="span">{formattedCost}</SubtitleOne>
      <BodyTwoSecondary as="span" marginLeft="quarter">
        {`/ ${formattedPeriodType}`}
      </BodyTwoSecondary>
    </ValueWrapper>
  );
};

BenefitCost.propTypes = {
  formattedCost: PropTypes.string.isRequired,
  formattedPeriodType: PropTypes.string.isRequired,
};

export const CoverageWithPeriodType = ({
  formattedCoverage,
  formattedCoveragePeriodType,
}) => {
  return (
    <ValueWrapper>
      <BodyOneSecondary>{formattedCoverage}</BodyOneSecondary>
      <BodyTwoSecondary marginLeft="quarter">
        {`/ ${formattedCoveragePeriodType}`}
      </BodyTwoSecondary>
    </ValueWrapper>
  );
};

CoverageWithPeriodType.propTypes = {
  formattedCoverage: PropTypes.string.isRequired,
  formattedCoveragePeriodType: PropTypes.string.isRequired,
};
