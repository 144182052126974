import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { withFieldHints } from './FieldHints';

export const Number = ({
  id,
  name,
  value,
  placeholder,
  readOnly,
  handleChange,
  handleBlur,
}) => (
  <Form.Input
    id={id}
    name={name}
    type="number"
    placeholder={placeholder}
    value={value}
    readOnly={readOnly}
    onBlur={handleBlur}
    onChange={handleChange}
    step="0.01"
  />
);

Number.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

Number.defaultProps = {
  id: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  value: '',
  placeholder: '',
};

export default withFieldHints(Number);
