import { useMemo } from 'react';

/**
 * @function useConditionalSchema A hook to leverage conditionally adding properties to a JSON Schema based
 * on the values on other properties. Example: If the value of `country` === `CA` then add a `province` property.
 * We leverage the JSON Schema's `allOf` https://json-schema.org/understanding-json-schema/reference/combining.html
 * in conjunction with if/then https://json-schema.org/understanding-json-schema/reference/conditionals.html#if-then-else
 *
 * @typedef {Object} JSONSchema
 * @param {JSONSchema} schema a JSON Schema that may or may not contain an allOf value
 * @param {Object} values formatted like Formik's `values` where each key represents a field name in the form
 * @returns {JSONSchema}
 */

export const compareMinimumDate = (fieldValue, minimum) => {
  /* The passed value can be a Date object or a formatted date string.
    Match the value (expected to be a date) being greater than or equal to the minimum (passed as number of
    milliseconds since Unix Epoch,
    see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime)
  */
  const dateValueAsTimestamp =
    fieldValue instanceof Date
      ? fieldValue.getTime()
      : new Date(fieldValue)?.getTime();
  return (
    Number.isFinite(dateValueAsTimestamp) && dateValueAsTimestamp >= minimum
  );
};

const computeFieldsWithDependencies = (schema, values = {}) => {
  const allOf = schema?.allOf;
  /* short circuit if and return untouched if no dependent fields */
  if (!allOf) return schema;

  const addedProperties = allOf.reduce(
    (computedProperties, { if: condition, then: subschema }) => {
      /* We want to ensure that ALL conditions in `if` are met in order to append the subschema in `then` */
      const allValuesMatched = Object.entries(condition.properties).every(
        ([
          conditionProperty,
          { const: expectedValue, not, minimum, format },
        ]) => {
          const fieldValue = values[conditionProperty];
          if (not) {
            /* A `not` clause works like you might expect and matches against values that don't the match condition */
            const negatingValue = not.const;
            return fieldValue !== negatingValue;
          }
          if (minimum && format === 'time') {
            return compareMinimumDate(fieldValue, minimum);
          }
          /**
           * JSON Schema has a `const` we can use to evaluate against specific values. In this case,
           * we use it to evaluate a current form value against the schema's `const` value.
           */
          return fieldValue === expectedValue;
        },
      );
      /**
       * We may also find new required fields in this subschema - here we want to append them to the
       * base schema's required fields.
       */
      const subschemaRequiredFields = subschema.required ?? [];
      return allValuesMatched
        ? {
            properties: {
              ...computedProperties.properties,
              ...subschema.properties,
            },
            required: [
              ...computedProperties.required,
              ...subschemaRequiredFields,
            ],
          }
        : computedProperties;
    },
    { properties: {}, required: [] },
  );

  const newSchema = {
    ...schema,
    properties: {
      ...schema.properties,
      ...addedProperties?.properties,
    },
    required: [...schema.required, ...(addedProperties?.required ?? [])],
  };

  return newSchema;
};

export const useConditionalSchema = ({
  schema: initialSchema,
  values = {},
}) => {
  const schema = useMemo(
    () => computeFieldsWithDependencies(initialSchema, values),
    [initialSchema, values],
  );
  return schema;
};
