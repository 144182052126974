import { createSelector } from 'reselect';
import { getActiveGroup } from '@leagueplatform/web-common';
import { CLAIM_URL } from '@leagueplatform/wallet';
import { CARD_STATUS, PILL_STATUS } from '@leagueplatform/wallet-api';
import pick from 'lodash/pick';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { selectUser } from 'common/state/user/user.selectors';
import { selectApp } from '../../state/selectors';

export const selectPage = createSelector(
  selectApp,
  app => app.pages.walletPage,
);

export const selectWallet = createSelector(
  selectPage,
  page => page && page.wallet,
);

export const selectError = createSelector(
  selectWallet,
  wallet => wallet && wallet.error,
);

export const selectCurrentCardBenefitId = createSelector(
  selectWallet,
  wallet => wallet && wallet.currentBenefitCardId,
);

export const selectBenefitCards = createSelector(
  selectWallet,
  wallet => wallet && wallet.benefitCards,
);

/**
 * For "active" cards we return cards with a status of active AND legacy cards (LSA, HSA) which have NO status
 */
export const selectActiveCards = createSelector(
  selectBenefitCards,
  benefitCards =>
    benefitCards.filter(
      /* @ts-ignore:disable-next-line */
      card => card.status === CARD_STATUS.ACTIVE || !card.status,
    ),
);

export const selectPastCards = createSelector(
  selectBenefitCards,
  benefitCards =>
    benefitCards.filter(
      /* @ts-ignore:disable-next-line */
      card => card.status === CARD_STATUS.INACTIVE_GRACE_PERIOD,
    ),
);

export const selectPendingCards = createSelector(
  selectBenefitCards,
  benefitCards =>
    benefitCards.filter(
      /* @ts-ignore:disable-next-line */
      card =>
        card.status === CARD_STATUS.CREATED ||
        card.status === CARD_STATUS.READY ||
        card.status === CARD_STATUS.IN_WAITING_PERIOD,
    ),
);

export const selectAvailableBenefitCardStatuses = createSelector(
  selectActiveCards,
  selectPastCards,
  selectPendingCards,
  (active, past, pending) => {
    const list = [];

    if (pending.length > 0) {
      list.push(PILL_STATUS.PENDING);
    }

    if (active.length > 0) {
      list.push(PILL_STATUS.ACTIVE);
    }

    if (past.length > 0) {
      list.push(PILL_STATUS.PAST);
    }

    return list;
  },
);

export const selectIsReady = createSelector(
  selectWallet,
  wallet => wallet && !wallet.isLoading,
);

export const selectWalletReady = createSelector(selectWallet, wallet => {
  return wallet?.ready;
});

export const selectBenefitCard = createSelector(
  selectBenefitCards,
  selectCurrentCardBenefitId,
  (benefitCards, benefitId) => {
    if (Array.isArray(benefitCards)) {
      return benefitCards.find(card => card.id === benefitId);
    }
    return [];
  },
);

export const selectCurrentCardIndex = createSelector(
  selectBenefitCards,
  selectCurrentCardBenefitId,
  (benefitCards, benefitId) => {
    if (Array.isArray(benefitCards)) {
      return benefitCards.findIndex(card => card.id === benefitId);
    }
    return null;
  },
);

export const selectCurrentCardActions = createSelector(
  selectBenefitCard,
  card => card && card.actions,
);

export const selectShowUserBenefitLiveBalances = createSelector(
  selectBenefitCard,
  card => card && card.showUserBenefitLiveBalances,
);

export const selectDependents = createSelector(
  selectBenefitCard,
  card => card && card.dependents,
);

export const selectSpendingAccount = createSelector(
  selectBenefitCard,
  card => card && card.spendingAccount,
);

export const selectBenefitCardType = createSelector(
  selectBenefitCard,
  card => card && card.type,
);

export const selectBenefitCardProductType = createSelector(
  selectBenefitCard,
  card => card && card.productType,
);

export const selectBenefitCardIdentifier = createSelector(
  selectBenefitCard,
  card => card && `${card.productType}-${card.id}`,
);

export const selectBenefitCardName = createSelector(
  selectBenefitCard,
  card => card && card.fullName,
);

export const selectBenefitShortName = createSelector(
  selectBenefitCard,
  card => card && card.shortName,
);

export const selectBenefitStartDate = createSelector(
  selectBenefitCard,
  card => card && card.startDate && new Date(card.startDate),
);

export const selectBenefitEndDate = createSelector(
  selectBenefitCard,
  card => card && card.endDate && new Date(card.endDate),
);

export const selectBenefitPlanName = createSelector(selectBenefitCard, card =>
  get(card, 'insurance.planName'),
);

export const selectBenefitCardHtml = createSelector(
  selectBenefitCard,
  card => card && card.cardHtml,
);

/**
 * @function selectBenefitCardDetails Return an object containing card details necessary for
 * providers and pharmacists. If none of the pieces of data are found, return `null`.
 *
 * Currently that is member id. Later it'll be Rx numbers.
 */
export const selectBenefitCardDetails = createSelector(
  selectBenefitCard,
  card => {
    // TODO: Eventually Rx numbers will be extracted from here
    const cardDetails = pick(card.insurance, [
      'memberPolicyNumber',
      'providerPolicyNumber',
      'rxBin',
      'rxCarrier',
      'rxGroup',
      'rxId',
      'rxPcn',
    ]);
    if ((!card && !card.insurance) || isEmpty(cardDetails)) return null;

    return cardDetails;
  },
);

export const selectBenefitCardNameTranslationId = createSelector(
  selectBenefitCard,
  card => card && card.nameTranslationId,
);

export const selectDependentsCardsLoaded = createSelector(
  selectWallet,
  wallet => wallet && wallet.dependentCardsLoaded,
);

export const selectEnrollmentEndDate = createSelector(
  selectWallet,
  wallet => wallet && wallet.enrollmentEndDate,
);

export const selectInEnrollmentPeriod = createSelector(
  selectWallet,
  wallet => wallet && wallet.inEnrollmentPeriod,
);

export const selectUserGroup = createSelector(selectUser, user =>
  getActiveGroup(get(user, 'groups')),
);

export const selectUserPlanEnrollment = createSelector(
  selectUserGroup,
  userGroup => get(userGroup, 'planEnrollment') || {},
);

export const selectIsFirstTimeEnrollment = createSelector(
  selectWallet,
  wallet => wallet && wallet.isFirstTimeEnrollment,
);

export const selectSelectionStatus = createSelector(
  selectWallet,
  wallet => wallet && wallet.selectionStatus,
);

export const selectCurrentDependentId = createSelector(
  selectWallet,
  wallet => wallet && wallet.currentDependentId,
);

export const selectDependentCards = createSelector(
  selectWallet,
  wallet => wallet && wallet.dependentCards,
);

export const selectDependentCard = createSelector(
  selectDependentCards,
  selectCurrentDependentId,
  selectCurrentCardBenefitId,
  (cards, dependentId, benefitId) => {
    if (Array.isArray(cards) && dependentId) {
      return cards.find(
        card =>
          card.dependentId === dependentId && card.benefitId === benefitId,
      );
    }
    return null;
  },
);

// Return only the benefit cards that have an action with CLAIM_URL
// And from those cards extract the required properties
export const selectSpendingAccountsFromActions = createSelector(
  selectBenefitCards,
  benefitCards =>
    /* @ts-ignore:disable-next-line */
    benefitCards.reduce((result, benefitCard) => {
      /* @ts-ignore:disable-next-line */
      const submitClaimAction = benefitCard?.actions?.find(action =>
        action.url?.includes(CLAIM_URL),
      );
      if (!submitClaimAction) return result;
      const requestedBenefitType = submitClaimAction.url.replace(CLAIM_URL, '');
      // Old benefits have only nameTranslationId while new benefits have only fullName
      const { fullName, nameTranslationId } = benefitCard;
      const amount =
        benefitCard.spendingAccount?.balance?.F ?? benefitCard.amount;
      const currency =
        benefitCard.currency || benefitCard.spendingAccount?.balance?.C;

      return [
        ...result,
        {
          // Pass the whole benefit card to the form
          ...benefitCard,
          requestedBenefitType,
          fullName,
          nameTranslationId,
          amount,
          currency,
        },
      ];
    }, []),
);
