import { GET_USER_REWARD_POINTS } from './health-rewards.actions';

export const initialState = {
  ready: false,
  hasError: false,
  data: {
    can_redeem: false,
    league_points: 0,
    current_tier: {},
    next_tier: {},
  },
};

const apply = {
  fetching: state => ({
    ...state,
    ready: false,
    hasError: false,
  }),
  error: state => ({
    ...state,
    ready: true,
    hasError: true,
  }),
  success: (state, action) => ({
    ...state,
    ready: true,
    data: {
      ...action?.payload?.info,
    },
  }),
};

export const healthRewardsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_REWARD_POINTS.STARTED:
      return apply.fetching(state);
    case GET_USER_REWARD_POINTS.ERRORED:
      return apply.error(state);
    case GET_USER_REWARD_POINTS.SUCCEEDED:
      return apply.success(state, action);
    default:
      return state;
  }
};
