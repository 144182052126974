import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { FirstText, RedAsterisk } from './shared.view';
import {
  fontSizeInPx,
  spaceInPx,
  vars,
  Box,
  Input,
} from '@leagueplatform/ui-kit';
import { ErrorMessage } from './error-message.view';

export const StyledInput = styled(Input)`
  height: 3rem;
  width: 767px;
  margin: 0 0 16px 0;
  border: 1px solid ${vars.colour.greyLight};
  font-size: ${fontSizeInPx(2)};
  &&& {
    padding: 0 ${spaceInPx(2)};
  }
`;

export const HREmailInput = ({
  value,
  name,
  handleChange,
  errors,
  submitted,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box mb={3}>
      <FirstText fontSize={2} color="purpleText" fontWeight="bold">
        {formatMessage({ id: 'HR_EMAIL' })}
        <RedAsterisk>*</RedAsterisk>
      </FirstText>
      <StyledInput
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={formatMessage({ id: 'SEPARATE_MULTIPLE_EMAIL' })}
      />
      {errors.qleApprovalEmails && submitted && (
        <ErrorMessage>{errors.qleApprovalEmails}</ErrorMessage>
      )}
    </Box>
  );
};

HREmailInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    qleApprovalEmails: PropTypes.string,
  }),
  submitted: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

HREmailInput.defaultProps = {
  value: '',
  errors: {},
  submitted: false,
};
