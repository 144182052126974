import { createRequestTypes } from 'common/websocket-redux';

export const ADD_BENEFIT_CLASS = 'benefits/ADD_BENEFIT_CLASS';

export const RENAME_BENEFIT_CLASS = 'benefits/RENAME_BENEFIT_CLASS';

export const REMOVE_BENEFIT_CLASS = 'benefits/REMOVE_BENEFIT_CLASS';

export const FETCH_PLANS = 'benefits/FETCH_PLANS';

export const SET_BENEFIT_CLASS = createRequestTypes(
  'service/SET_BENEFIT_CLASS',
);

export const REMOVE_BENEFIT_CLASS_SERVICE = createRequestTypes(
  'service/REMOVE_BENEFIT_CLASS',
);

export const GET_EMPLOYER_BENEFIT_PLAN = createRequestTypes(
  'service/GET_EMPLOYER_BENEFIT_PLAN',
);

export const GET_EMPLOYER_BENEFIT_PLANS = createRequestTypes(
  'service/GET_EMPLOYER_BENEFIT_PLANS',
);

export const GET_BENEFIT_TYPE_SCHEMAS = createRequestTypes(
  'service/GET_BENEFIT_TYPE_SCHEMAS',
);

export const GET_BENEFIT_DOCUMENTS = createRequestTypes(
  'service/GET_BENEFIT_DOCUMENTS',
);

export const GET_EMPLOYER_BENEFIT_PRICE_CONFIG = createRequestTypes(
  'service/GET_EMPLOYER_BENEFIT_PRICE_CONFIG',
);

export const SET_EMPLOYER_BENEFIT_PRICE_CONFIG = createRequestTypes(
  'service/SET_EMPLOYER_BENEFIT_PRICE_CONFIG',
);
