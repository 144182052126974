import { get } from 'lodash';
import { SET_FEE, RESET_STATUS } from './fees.action-types';

export const STATUSES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  WAITING: 'waiting',
};

export const initialState = {
  errors: null,
  loading: false,
  status: STATUSES.WAITING,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const feesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEE.STARTED:
      return { ...state, loading: true, status: STATUSES.PENDING };
    case SET_FEE.ERRORED:
      return {
        ...state,
        errors: get(action, 'payload.info.message.reason'),
        loading: false,
      };
    case SET_FEE.SUCCEEDED:
      return { ...state, loading: false, status: STATUSES.SUCCESS };
    case RESET_STATUS:
      return { ...state, status: STATUSES.WAITING };
    default:
      return state;
  }
};
