import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import {
  selectAddingEmployeeBenefit,
  selectUpdatingEmployeeBenefit,
} from './benefits.selectors';
import Submit from '../benefits/submit.view';

const withLoading = connect(state => ({
  loading:
    selectAddingEmployeeBenefit(state) || selectUpdatingEmployeeBenefit(state),
}));

export default withLoading(Submit);
