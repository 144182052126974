import PropTypes from 'prop-types';
import { SCHEMA_ENTITY_TYPES } from './json-schema-form.constants';

export const JSONSchemaDefinitionPropType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  properties: PropTypes.object,
  required: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
});

export const JSONSchemaPropType = PropTypes.shape({
  $schema: PropTypes.string,
  definitions: PropTypes.objectOf(JSONSchemaDefinitionPropType),
});

export const JSONschemaMetadataPropTypes = PropTypes.shape({
  fieldOrder: PropTypes.arrayOf(PropTypes.string),
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
});

export const JSONSchemaFieldPropType = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    value: PropTypes.any,
  }),
  form: PropTypes.object,
  propSchema: PropTypes.object,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
};

export const JSONSchemaFieldSchemaTypes = PropTypes.oneOf([
  SCHEMA_ENTITY_TYPES.OBJECT,
  SCHEMA_ENTITY_TYPES.STRING,
  SCHEMA_ENTITY_TYPES.ARRAY,
  SCHEMA_ENTITY_TYPES.BOOLEAN,
  SCHEMA_ENTITY_TYPES.NUMBER,
  SCHEMA_ENTITY_TYPES.INTEGER,
  SCHEMA_ENTITY_TYPES.FLOAT,
]);
