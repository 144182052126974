import { connect } from 'react-redux';
import { ActionsDropdown } from './v1';
import {
  reinstateEmployees,
  suspendEmployees,
  terminateEmployees,
  unscheduleEmployeesReinstatement,
  unscheduleEmployeesTermination,
  unscheduleEmployeesSuspension,
  setUserRoleInGroup,
} from './search.actions';
import { sendInviteEmail } from '../employee-profile/profile.actions';

const mapDispatchToProps = {
  reinstateEmployees,
  suspendEmployees,
  terminateEmployees,
  unscheduleEmployeesReinstatement,
  unscheduleEmployeesTermination,
  unscheduleEmployeesSuspension,
  setUserRoleInGroup,
  sendInviteEmail,
};

export default connect(null, mapDispatchToProps)(ActionsDropdown);
