/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/*
  @function - tells the websocket to fetch details about the vendor, mainly the portal_url to log into their site
  @param {string} vendor_id - id of the vendor, for example, Great West Life or Desjardins
  @return gets a message from the websocket that contains the portalUrl
*/
export const getSSOVendorDetails = ({ vendor_id }) =>
  call(websocketFetch, 'get_sso_vendor_details', { vendor_id });
