import { connect } from 'react-redux';
import UsageInstructions from './usage-instructions.view';
import { selectProviderBenefit } from '../benefit-wallet.selectors';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, withHandlers, withStateHandlers } from 'recompose';
import { get } from 'lodash';
import { submitUsageInstructions } from '../benefit-wallet.actions';
import { USAGE_INSTRUCTIONS } from '../constants';

export const getWalletActions = benefit =>
  get(benefit, 'provider_wallet_action.actions', []);

export const getUsageInstructions = benefit => {
  const actions = getWalletActions(benefit);
  return actions.find(
    a => a.type === USAGE_INSTRUCTIONS && !a.benefit_class_id,
  );
};

export const createUsageInstructions = value => ({
  benefit_class_id: null,
  index: -1,
  label: 'Instructions',
  primary: false,
  type: USAGE_INSTRUCTIONS,
  value,
});

export const createUsageInstructionsPayload = ({
  code,
  benefitType,
  walletActions,
  usageInstructions,
  groupId,
}) => {
  let updatedActions;

  const updatedUsageInstructions = usageInstructions
    ? { ...usageInstructions, value: code }
    : createUsageInstructions(code);

  if (code) {
    updatedActions = usageInstructions
      ? walletActions.map(action =>
          action.type === USAGE_INSTRUCTIONS && !action.benefit_class_id
            ? updatedUsageInstructions
            : action,
        )
      : [updatedUsageInstructions, ...walletActions];
  } else {
    updatedActions = walletActions.filter(
      action => action.type !== USAGE_INSTRUCTIONS && !action.benefit_class_id,
    );
  }

  return {
    providerId: groupId,
    benefitType,
    actions: updatedActions,
  };
};

const dispatchers = {
  submitUsageInstructions,
};

const withStore = connect(
  createStructuredSelector({
    providerBenefit: selectProviderBenefit,
  }),
  dispatchers,
);

const withLocalState = withStateHandlers(
  ({ providerBenefit }) => ({
    code: get(getUsageInstructions(providerBenefit), 'value', ''),
    isOpen: false,
  }),
  {
    setCode: () => value => ({
      code: value,
    }),
    toggleModal:
      ({ isOpen, code }) =>
      () => ({
        isOpen: code ? !isOpen : false,
      }),
  },
);

const withClickHandlers = withHandlers({
  onUpdateUsageInstructions:
    ({ code, providerBenefit, groupId, ...props }) =>
    () => {
      const payload = createUsageInstructionsPayload({
        code,
        benefitType: providerBenefit.id,
        walletActions: getWalletActions(providerBenefit),
        usageInstructions: getUsageInstructions(providerBenefit),
        groupId,
      });
      props.submitUsageInstructions(payload);
    },
  onClearUsageInstructions:
    ({ toggleModal, setCode, providerBenefit, groupId, ...props }) =>
    () => {
      if (get(getUsageInstructions(providerBenefit), 'value')) {
        const payload = createUsageInstructionsPayload({
          code: null,
          benefitType: providerBenefit.id,
          walletActions: getWalletActions(providerBenefit),
          usageInstructions: getUsageInstructions(providerBenefit),
          groupId,
        });
        props.submitUsageInstructions(payload);
      }
      toggleModal();
      setCode('');
    },
});

const withLifeCycle = lifecycle({
  componentDidUpdate(prevProps) {
    const { setCode, providerBenefit } = this.props;
    if (
      !prevProps.providerBenefit ||
      prevProps.providerBenefit.id !== providerBenefit.id
    ) {
      setCode(get(getUsageInstructions(providerBenefit), 'value'));
    }
  },
});

export default compose(
  withStore,
  withLocalState,
  withClickHandlers,
  withLifeCycle,
)(UsageInstructions);

/* eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue */
export const __test__ = {
  withClickHandlers,
};
