import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { LabelText } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '../../constants';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import ConditionalRenderWrappingTag from './conditional-render-wrapping-tag';

const ActionsConfirmationNoticeDateForm = ({
  noticeDateValue,
  onDateChange,
  displayInline,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConditionalRenderWrappingTag displayInline={displayInline}>
      <Form.Field fluid>
        <LabelText as="label" htmlFor="noticeDate">
          {formatMessage({ id: `NOTICE_DATE` })}
        </LabelText>
        <StyledDayPickerInput
          id="noticeDate"
          dayPickerProps={{
            enableOutsideDays: false,
          }}
          placeholder={DATE_FORMAT}
          format={DATE_FORMAT}
          value={noticeDateValue}
          name="noticeDate"
          onDayChange={onDateChange}
          confirmModal
        />
      </Form.Field>
    </ConditionalRenderWrappingTag>
  );
};

ActionsConfirmationNoticeDateForm.propTypes = {
  noticeDateValue: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  displayInline: PropTypes.bool.isRequired,
};

ActionsConfirmationNoticeDateForm.defaultProps = {
  noticeDateValue: '',
};

export default ActionsConfirmationNoticeDateForm;
