import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@leagueplatform/genesis-commons';
import { TranslatedEntityFormField } from 'common/json-schema-form/fields';
import { FieldWrapper } from './beneficiary-entity-form.view';

export const BeneficiaryAddressField = ({
  name,
  propSchema: { properties },
}) => {
  const renderProperties = Array.isArray(properties)
    ? properties
    : Object.entries(properties);

  return (
    <Flex flexWrap="wrap" width="100%">
      {renderProperties?.map(([propertyName, propertySchema], idx) => {
        const fieldName = `${name}.${propertyName}`;
        const isEven = idx % 2 === 0;
        return (
          <FieldWrapper isGrid isEven={isEven} key={propertyName}>
            <TranslatedEntityFormField
              name={fieldName}
              propSchema={propertySchema}
            />
          </FieldWrapper>
        );
      })}
    </Flex>
  );
};

BeneficiaryAddressField.propTypes = {
  name: PropTypes.string.isRequired,
  propSchema: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    properties: PropTypes.object,
  }).isRequired,
};
