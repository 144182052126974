import camelize from 'camelize';

export const formatReceiptData = data => {
  const { payment, providerProfile, validationForm } = camelize(data);

  // Break down payment
  const {
    createdTimestamp,
    invoiceId,
    paidByProfile,
    paidToProfile,
    serviceDetails,
    serviceTime,
    pricingDetails,
  } = payment || {};

  const { firstName, lastName } = paidByProfile || {};
  const clientName = firstName || lastName ? `${firstName} ${lastName}` : null;

  const { firstName: providerFirst, lastName: providerLast } =
    paidToProfile || {};
  const providerName = providerFirst
    ? `${providerFirst} ${providerLast || ''}`
    : null;

  const { location, provider } = serviceDetails || {};
  const { professionalDesignation, professionalAssociation } = provider || {};

  const { name, address1, address2, city, province, state, postal } =
    location || {};
  const address = location
    ? `${name ? `${name}, ` : ''}${address1 ? `${address1}, ` : ''}${
        address2 ? `${address2}, ` : ''
      }${city ? `${city}, ` : ''}${state ? `${state} ` : ''}${
        province ? `${province} ` : ''
      }${postal || ''}`
    : null;
  const { title } = serviceDetails || {};

  const {
    currency,
    base,
    discount,
    totalTaxValue,
    total,
    psaTotal,
    hsaTotal,
    personalPromoCodeTotal,
    amountOwing,
    benefits,
  } = pricingDetails || {};

  const charityBenefit = benefits?.find(b => b.paymentType === 'charity');
  const charityTotal = (charityBenefit || {}).amount;

  const showBreakdown =
    psaTotal > 0 ||
    hsaTotal > 0 ||
    personalPromoCodeTotal > 0 ||
    charityTotal > 0;

  // Break down providerProfile for phone number
  const phoneNumber = providerProfile?.info?.userProfile?.businessPhoneNumber;

  // Break down validationForm for license numbers
  const getLicenseNumbers = () => {
    const numbers = [];
    (validationForm?.info?.unregulatedCategories || []).forEach(item => {
      if (item.licenseNumber) {
        numbers.push(item.licenseNumber);
      }
    });
    (validationForm?.info?.regulatedCategories || []).forEach(item => {
      if (item.licenseNumber) {
        numbers.push(item.licenseNumber);
      }
    });
    const result = numbers.join(', ');
    return result;
  };

  const licenseNumber = provider?.licenseNumber || getLicenseNumbers() || null;

  return {
    createdTimestamp,
    invoiceId,
    serviceTime,
    clientName,
    providerName,
    address,
    title,
    currency,
    base,
    discount,
    totalTaxValue,
    total,
    amountOwing,
    showBreakdown,
    charityTotal,
    personalPromoCodeTotal,
    psaTotal,
    hsaTotal,
    phoneNumber,
    licenseNumber,
    professionalDesignation,
    professionalAssociation,
  };
};
