import React, { forwardRef } from 'react';
import { Combobox } from './Combobox';
import { HtmlSelect } from './HtmlSelect';
import { SelectProps } from './SelectTypes';

export const PHONE_MAX_WINDOW_HEIGHT = 896; // this is the height of the tallest phone in this list: https://mediag.com/blog/popular-screen-resolutions-designing-for-all/

/**
 * @deprecated  use `Select` from `genesis-core` instead
 */

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const mql = window?.matchMedia(`(hover: none) and (pointer: coarse)`);
    const SelectComponent = mql?.matches ?? false ? HtmlSelect : Combobox;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SelectComponent ref={ref} {...props} />;
  },
) as React.FunctionComponent<SelectProps>;

Select.displayName = 'Select';
