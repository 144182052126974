import { createSelector } from 'reselect';

const selectApp = state => state;

export const selectResources = createSelector(
  selectApp,
  state => state.resources,
);

export const selectInviteAccessReady = createSelector(
  selectResources,
  resources => resources.ready,
);

export const selectResourcesConfig = createSelector(
  selectResources,
  resources => resources.config,
);

export const selectAnonymousGroupId = createSelector(
  selectResourcesConfig,
  config => config?.anonymous_group_id,
);

export const selectConfigId = createSelector(
  selectResourcesConfig,
  config => config?.config_id,
);

export const selectConfigContent = createSelector(
  selectResourcesConfig,
  config => config?.content,
);

export const selectVerifying = createSelector(
  selectResources,
  resources => resources.verifying,
);

export const selectVerificationError = createSelector(
  selectResources,
  resources => resources.verificationError,
);

export const selectBeforeEnrollmentPeriod = createSelector(
  selectResources,
  resources => resources.beforeEnrollmentPeriod,
);

export const selectEnrollmentStartDate = createSelector(
  selectResources,
  resources => resources.enrollmentStartDate,
);

export const selectContentHeading = createSelector(
  selectConfigContent,
  content => content?.heading,
);

export const selectContentBanner = createSelector(
  selectConfigContent,
  content => content?.banner,
);

export const selectContentBody = createSelector(
  selectConfigContent,
  content => content?.body,
);

export const selectContentUniqueIdentifier = createSelector(
  selectConfigContent,
  content => content?.unique_identifiers ?? [],
);

export const selectContentBannerImgUrl = createSelector(
  selectConfigContent,
  content => content?.banner_img_url,
);
