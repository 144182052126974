import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const SET_USER_CERTIFICATION_MESSAGE_TYPE = 'set_user_certification';

interface SetUserCertificationTypes {
  user_id: string;
  certification_type: string;
  effective_date: string;
  expiry_date: string;
  amount: {
    F: number;
    C: string;
  };
}

export const setUserCertification = async (
  userCertification: SetUserCertificationTypes,
) =>
  SocketAsFetch.fetch({
    message_type: SET_USER_CERTIFICATION_MESSAGE_TYPE,
    info: {
      user_certification: userCertification,
    },
  });
