import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Form } from 'formik';
import {
  EntityFormFields,
  JSONSchemaDefinitionPropType,
  SCHEMA_ENTITY_TYPES,
} from 'common/json-schema-form';
import {
  PrimaryButton,
  DangerButton,
  Flex,
  HeadingTwo,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { FormValidationBanners } from './form-validation-banners/form-validation-banners.view';

export const EnrollmentConfigEntityForm = ({
  formTitle,
  metaTitle,
  schemaDefinition,
  isSubmitting,
  handleReset,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form>
      <HeadingTwo>{schemaDefinition?.title ?? formTitle}</HeadingTwo>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <FormValidationBanners formType={metaTitle} {...props} />
      <BodyTwoSecondary>{schemaDefinition.description}</BodyTwoSecondary>
      <EntityFormFields
        schemaDefinition={schemaDefinition}
        ignoreItemsOfSchemaTypes={[
          {
            type: SCHEMA_ENTITY_TYPES.ARRAY,
            if: {
              not: {
                'items.type': SCHEMA_ENTITY_TYPES.STRING,
              },
            },
          },
        ]}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
      <Flex marginTop="one" justifyContent="space-between">
        <DangerButton
          type="reset"
          disabled={isSubmitting}
          onClick={handleReset}
        >
          {formatMessage({ id: 'RESET' })}
        </DangerButton>
        <PrimaryButton type="submit" disabled={isSubmitting}>
          {formatMessage({ id: 'SAVE' })}
        </PrimaryButton>
      </Flex>
    </Form>
  );
};

EnrollmentConfigEntityForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  data: PropTypes.objectOf(PropTypes.any),
  formTitle: PropTypes.string,
  metaTitle: PropTypes.string,
  schemaDefinition: JSONSchemaDefinitionPropType,
  isSubmitting: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  schemaMetadata: PropTypes.objectOf(PropTypes.any),
};

EnrollmentConfigEntityForm.defaultProps = {
  data: {},
  formTitle: '',
  metaTitle: '',
  schemaDefinition: {},
  schemaMetadata: {},
};
