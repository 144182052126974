import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import errorIcon from 'apps/public/assets/error-icon.svg';
import {
  ErrorMessage as GenesisErrorMessage,
  Image,
} from '@leagueplatform/genesis-commons';

export const ErrorMessage = ({ errorMessage, errorId }) => {
  const { formatMessage } = useIntl();
  if (!errorMessage || typeof errorMessage !== 'string') {
    return null;
  }

  return (
    <GenesisErrorMessage id={errorId}>
      <Image
        src={errorIcon}
        height={10}
        alt={formatMessage({ id: 'ERROR' })}
        marginRight="quarter"
      />
      {errorMessage}
    </GenesisErrorMessage>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  errorId: PropTypes.string,
};

ErrorMessage.defaultProps = {
  errorMessage: undefined,
  errorId: undefined,
};
