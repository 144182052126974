import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { selectApp } from '../app.selectors';
import { EMPLOYEE_STATE_MAP } from 'common/constants';
import { selectEmployerId } from '../../employer-experience/pages/EmployerDetails/selectors';

export const calculateEmployeeStatus = (employee, employerId) => {
  if (employee) {
    const group = employee.groups.find(g => g.group_id === employerId);
    return group.state && EMPLOYEE_STATE_MAP[group.state];
  }
  return null;
};

export const selectEmployee = createSelector(
  selectApp,
  get('employeeProfile.data'),
);

export const selectEmployeeStatus = createSelector(
  [selectEmployee, selectEmployerId],
  (employee, employerId) => calculateEmployeeStatus(employee, employerId),
);

export const selectEmployeeUserId = createSelector(selectEmployee, employee =>
  employee ? employee.user_id : '',
);
