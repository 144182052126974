import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  useGetLandingScreenConfigs,
  LandingScreenConfig,
} from './queries/use-get-landing-screen-configs-query.hook';
import { useSetLandingScreenConfigMutation } from './queries/use-set-landing-screen-config-mutation.hook';

interface UseWalletLandingTemplateFormProps {
  groupId: string;
}

const formatConfigSelect = (configs: LandingScreenConfig[]) => {
  return configs.map(
    ({ config_name, contentful_reference_id }: LandingScreenConfig) => {
      return {
        label: config_name || '',
        value: contentful_reference_id || '',
      };
    },
  );
};

export const useWalletLandingTemplateForm = ({
  groupId,
}: UseWalletLandingTemplateFormProps) => {
  const [configOptions, setConfigOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const { data, isLoading } = useGetLandingScreenConfigs({ id: groupId });
  const { mutate } = useSetLandingScreenConfigMutation();

  useEffect(() => {
    if (data?.configs && data?.configs !== null) {
      setConfigOptions(formatConfigSelect(data.configs));
    }
  }, [data]);

  const defaultConfig = useMemo(() => {
    return configOptions.find(
      config => config.value === data?.current_config?.contentful_reference_id,
    )?.value;
  }, [configOptions, data?.current_config?.contentful_reference_id]);

  const onConfigSubmit = useCallback(
    (templateId: string) => {
      mutate({ contentful_reference_id: templateId, id: groupId });
    },
    [groupId, mutate],
  );

  return { configOptions, onConfigSubmit, defaultConfig, isLoading };
};
