import React from 'react';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { EntityFormField } from 'common/json-schema-form/fields';
import { selectGroupCurrency } from '../../selectors';
import { AmountByFieldWrapper } from './amount-by-field-wrapper.view';
import { MONEY_REF } from 'common/json-schema-form/json-schema-form.constants';

export const AmountByUserAttributePresenter = ({
  name,
  propSchema,
  groupCurrency,
  ...props
}) => {
  const itemProperties = propSchema?.items?.properties ?? {};
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <AmountByFieldWrapper {...props} {...propSchema} name={name}>
      {({ idx }) => (
        <>
          <EntityFormField
            name={`${name}.${idx}.name`}
            propSchema={itemProperties.name}
          />
          <EntityFormField
            name={`${name}.${idx}.user_attribute`}
            propSchema={itemProperties.user_attribute}
          />
          <EntityFormField
            name={`${name}.${idx}.value`}
            propSchema={{
              ...itemProperties.value,
              type: 'string',
            }}
          />
          <EntityFormField
            name={`${name}.${idx}.amount`}
            groupCurrency={groupCurrency}
            propSchema={{
              ...itemProperties.amount,
              $ref: MONEY_REF,
            }}
          />
        </>
      )}
    </AmountByFieldWrapper>
  );
};

AmountByUserAttributePresenter.propTypes = JSONSchemaFieldPropType;

export const AmountByUserAttribute = connect(state => ({
  groupCurrency: selectGroupCurrency(state),
}))(AmountByUserAttributePresenter);
