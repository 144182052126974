import {
  GET_TEMPLATED_GENERIC_BENEFITS,
  SET_TEMPLATED_GENERIC_BENEFIT,
  SHOW_FORM,
  HIDE_FORM,
} from './recommendation-ids/recommendation-ids.actions';

export const initialState = {
  recommendationIds: null,
  recommendationIdsFormVisible: false,
  recommendationIdsInitialValues: null,
  isCreatingNewId: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const walletToolingReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_TEMPLATED_GENERIC_BENEFITS.SUCCEEDED:
      return {
        ...state,
        recommendationIds: action.payload.info.templated_generic_benefits,
      };
    case SHOW_FORM:
      return {
        ...state,
        recommendationIdsFormVisible: true,
        recommendationIdsInitialValues: action.payload.initialValues,
        isCreatingNewId: action.payload.isCreatingNewId,
      };
    case HIDE_FORM:
    case SET_TEMPLATED_GENERIC_BENEFIT.SUCCEEDED:
      return {
        ...state,
        recommendationIdsFormVisible: false,
      };
    default:
      return state;
  }
};
