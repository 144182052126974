import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  HeadingThree,
  Overline,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import {
  breakpoints,
  TooltipModal,
  Tooltip,
  TooltipContent,
  Responsive,
  LightQuestionIcon,
} from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { INSURANCE_FORM_STATUS_TYPES } from './insurance-forms.constants';

/**
 * FormCard is used to display information about a downloadable or electronic
 * insurance form, e.g. Evidence of Insurability, Beneficiaries...
 *
 * Since the root element is a Genesis Box, you can use system props to add top and bottom
 * margins to allow it to fit your layout
 */

export const useFormTypeLabel = formType => {
  const { formatMessage } = useIntl();
  const formTypeLabel = useMemo(() => {
    switch (formType) {
      case INSURANCE_FORM_STATUS_TYPES.REQUIRED:
        return formatMessage({ id: 'REQUIRED_FORM' });
      case INSURANCE_FORM_STATUS_TYPES.RECOMMENDED:
        return formatMessage({ id: 'RECOMMENDED_FORM' });
      default:
        return formType;
    }
  }, [formType, formatMessage]);

  return formTypeLabel;
};

export const FormCard = ({
  title,
  tooltip,
  tooltipText,
  description,
  formType,
  children,
  productTypesId,
  ...systemProps
}) => {
  const formTypeLabel = useFormTypeLabel(formType);
  const FORM_DESCRIPTION_ID = productTypesId
    ? `form-description-id-${productTypesId}`
    : 'form-description-id';
  return (
    <Box
      padding={{
        _: 0,
        tablet: 'two',
      }}
      borderRadius="medium"
      borderWidth={[0, 0, 'thick']}
      borderStyle="solid"
      borderColor="interactive.background.disabled"
      backgroundColor="surface.background.primary"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...systemProps}
    >
      <Flex
        marginBottom="one"
        flexDirection={{
          _: 'column-reverse',
          tablet: 'row',
        }}
        alignItems={{
          _: 'left',
          tablet: 'center',
        }}
      >
        <Flex
          flexGrow={1}
          alignItems="center"
          justifyContent={{
            _: 'space-between',
            tablet: 'flex-start',
          }}
        >
          <HeadingThree
            marginRight="half"
            as="h2"
            aria-describedby={FORM_DESCRIPTION_ID}
          >
            {title}
          </HeadingThree>
          {tooltip && (
            <>
              <Responsive breakpoint={breakpoints.lapAndAbove}>
                <Tooltip tooltipText={tooltipText}>
                  <LightQuestionIcon altText={tooltipText} />
                  <TooltipContent
                    verticalPosition="top: 30px;"
                    borderRadius="5px"
                    center
                    top
                  >
                    <Box padding="one" minWidth={350}>
                      {tooltip}
                    </Box>
                  </TooltipContent>
                </Tooltip>
              </Responsive>
              <Responsive breakpoint={breakpoints.belowLap}>
                <TooltipModal
                  isFullScreen
                  label={<LightQuestionIcon altText={tooltipText} />}
                  content={tooltip}
                />
              </Responsive>
            </>
          )}
        </Flex>
        <Box
          marginBottom={{
            _: 'one',
            laptop: 'none',
          }}
        >
          <Overline
            backgroundColor="surface.background.secondary"
            borderRadius="pill"
            paddingY="quarter"
            paddingX="one"
            fontWeight="bold"
            textAlign="center"
            as="div"
            display="inline-block"
          >
            {formTypeLabel}
          </Overline>
        </Box>
      </Flex>
      {description && (
        <BodyOneSecondary id={FORM_DESCRIPTION_ID}>
          {description}
        </BodyOneSecondary>
      )}
      {children}
    </Box>
  );
};

FormCard.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  tooltipText: PropTypes.string,
  description: PropTypes.string,
  formType: PropTypes.oneOf([
    INSURANCE_FORM_STATUS_TYPES.RECOMMENDED,
    INSURANCE_FORM_STATUS_TYPES.REQUIRED,
  ]),
  children: PropTypes.node,
  productTypesId: PropTypes.string,
};

FormCard.defaultProps = {
  tooltip: null,
  tooltipText: null,
  description: null,
  formType: null,
  children: null,
  productTypesId: '',
};
