import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  TextInput,
  Checkbox,
  Flex,
  Label,
  ChoiceText,
  ErrorMessage,
} from '@leagueplatform/genesis-commons';
import { Field } from 'formik';
import { FieldWrapper } from './field-wrapper.view';
import { GridItem } from './grid-item.view';
import { DEPENDENT_TYPE_CHILD } from 'common/dependents/dependents-metadata';

const NATIONAL_IDENTIFICATION_NUMBER = 'national_identification_number';
const OMIT_NATIONAL_IDENTIFICATION_NUMBER =
  'omit_national_identification_number';

export function useOmitSSN(
  ssnValue = '',
  checked = false,
  setFieldValue = () => {},
) {
  const [isOmitChecked, setIsOmitChecked] = useState(checked);
  const [socialSecurityNumberValue, setSocialSecurityNumberValue] =
    useState(ssnValue);

  return {
    toggleOmitChecked: () => {
      const newSsnValue = isOmitChecked ? socialSecurityNumberValue : '';
      const isChecked = !isOmitChecked;
      setIsOmitChecked(isChecked);
      setSocialSecurityNumberValue(newSsnValue);
      setFieldValue(NATIONAL_IDENTIFICATION_NUMBER, newSsnValue);
      setFieldValue(OMIT_NATIONAL_IDENTIFICATION_NUMBER, isChecked);
    },
    setSocialSecurityNumberValue,
    socialSecurityNumberValue,
    isOmitChecked,
  };
}

export const OptionalSSNField = ({
  values,
  ninField,
  omitNINField,
  dependentType,
  disabled,
  setFieldValue,
  error: errorMessage,
  ...otherProps
}) => {
  const { formatMessage } = useIntl();
  const {
    isOmitChecked,
    toggleOmitChecked,
    socialSecurityNumberValue,
    setSocialSecurityNumberValue,
  } = useOmitSSN(
    values?.national_identification_number,
    values?.omit_national_identification_number,
    setFieldValue,
  );

  return (
    <GridItem width={[1, 1, 1 / 2]}>
      <FieldWrapper
        label={formatMessage({ id: ninField.label })}
        htmlFor={ninField.name}
        isRequired={ninField.required}
        tooltip={ninField.tooltip}
      >
        <Field name={NATIONAL_IDENTIFICATION_NUMBER}>
          {({ form, field }) => (
            <>
              <TextInput
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...otherProps}
                disabled={disabled || isOmitChecked}
                onChange={evt => {
                  const { value: evtValue } = evt.target;
                  setSocialSecurityNumberValue(evtValue);
                  form.setFieldValue(NATIONAL_IDENTIFICATION_NUMBER, evtValue);
                }}
                value={socialSecurityNumberValue}
                placeholder="000-00-0000"
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </>
          )}
        </Field>

        {omitNINField && (
          <Flex paddingTop="half">
            <Field name={omitNINField.name}>
              {({ field }) => (
                <Label display="flex">
                  <Checkbox
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...field}
                    checked={isOmitChecked}
                    onChange={() => {
                      toggleOmitChecked();
                    }}
                    disabled={disabled}
                  />
                  <ChoiceText marginLeft="threeQuarters">
                    {formatMessage(
                      { id: 'NO_DEPENDENT_SSN' },
                      {
                        dependentType:
                          dependentType === DEPENDENT_TYPE_CHILD
                            ? formatMessage({ id: 'CHILD_LOWERCASE' })
                            : formatMessage({ id: 'PARTNER_LOWERCASE' }),
                      },
                    )}
                  </ChoiceText>
                </Label>
              )}
            </Field>
          </Flex>
        )}
      </FieldWrapper>
    </GridItem>
  );
};

OptionalSSNField.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    national_identification_number: PropTypes.string,
    omit_national_identification_number: PropTypes.bool,
  }),
  ninField: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
  }).isRequired,
  omitNINField: PropTypes.shape({
    name: PropTypes.string,
  }),
  setFieldValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  dependentType: PropTypes.string.isRequired,
};

OptionalSSNField.defaultProps = {
  values: {},
  omitNINField: undefined,
  error: null,
  disabled: false,
};
