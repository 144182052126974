import { createRequestTypes } from 'common/websocket-redux';

export const GET_USER_PROFILE = createRequestTypes(
  'employer-experience/user-profile/GET_USER_PROFILE',
);

export const UPDATE_USER_PROFILE = createRequestTypes(
  'employer-experience/user-profile/UPDATE_USER_PROFILE',
);

export const GET_IDENTITY_PROVIDER_FOR_USER = createRequestTypes(
  'employer-experience/user-profile/GET_IDENTITY_PROVIDER_FOR_USER',
);

export const CHANGE_EMAIL = createRequestTypes(
  'employer-experience/user-profile/CHANGE_EMAIL',
);

export const CHANGE_PHONE_NUMBER = createRequestTypes(
  'employer-experience/user-profile/CHANGE_PHONE_NUMBER',
);

export const getUserProfile = userId => GET_USER_PROFILE.request({ userId });

export const getIdentityProviderForUser = userId =>
  GET_IDENTITY_PROVIDER_FOR_USER.request({ userId });

export const updateUserProfile = (userId, userProfile) =>
  UPDATE_USER_PROFILE.request({ userId, userProfile });

export const changeEmail = (userId, email) =>
  CHANGE_EMAIL.request({ userId, email });

export const changePhoneNumber = (
  userId,
  confirmPhoneNumber,
  newPhoneCountryCode,
) =>
  CHANGE_PHONE_NUMBER.request({
    userId,
    confirmPhoneNumber,
    newPhoneCountryCode,
  });
