import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

interface RespondStudentCertification {
  groupId: string;
  userId: string;
  dependentId: string;
  isFullTimeStudent: boolean;
}

export const RESPOND_STUDENT_CERTIFICATION_GROUP_MESSAGE_TYPE =
  'respond_student_certification';

export const respondStudentCertification = ({
  groupId,
  userId,
  dependentId,
  isFullTimeStudent,
}: RespondStudentCertification) =>
  SocketAsFetch.fetch({
    message_type: RESPOND_STUDENT_CERTIFICATION_GROUP_MESSAGE_TYPE,
    info: {
      group_id: groupId,
      user_id: userId,
      dependent_id: dependentId,
      is_full_time_student: isFullTimeStudent,
    },
  });
