import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Formik } from 'formik-legacy';
import { Message } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import {
  selectEmployeeSchema,
  selectEmployeeFormValues,
  selectEditFormLayoutGenerator,
  selectValidator,
  selectEmployeeSaveError,
  selectEmployeeStatus,
} from '../selectors';
import { editEmployeeSubmitted, benefitClassChanged } from '../actions';
import { omitSSNIfNotChanged } from 'apps/employer-experience-v2/edit-employee/validation';

const EditEmployeeForm = ({ errorMessage, ...props }) => (
  <Box>
    {errorMessage && <Message negative>{errorMessage}</Message>}
    {props.generateLayout(props)}
  </Box>
);

EditEmployeeForm.propTypes = {
  errorMessage: PropTypes.string,
  generateLayout: PropTypes.func.isRequired,
};

EditEmployeeForm.defaultProps = {
  errorMessage: null,
};

const withState = connect(
  createStructuredSelector({
    schema: selectEmployeeSchema,
    formValues: selectEmployeeFormValues,
    generateLayout: selectEditFormLayoutGenerator,
    validate: selectValidator,
    errorMessage: selectEmployeeSaveError,
    employeeStatus: selectEmployeeStatus,
  }),
  { editEmployeeSubmitted, benefitClassChanged },
);

const withFormikEnhancer = Formik({
  validate: (values, props) =>
    props.validate(omitSSNIfNotChanged(values, props.formValues)),
  validateOnChange: true,
  handleSubmit: (values, { props }) =>
    props.editEmployeeSubmitted(
      omitSSNIfNotChanged(values, props.formValues),
      props.groupId,
    ),
  mapPropsToValues: props => props.formValues,
});

export default compose(withState, withFormikEnhancer)(EditEmployeeForm);
