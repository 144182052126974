/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getYearToDateReport = ({
  user_ids,
  group_id,
  start_date,
  end_date,
  benefit_class_id,
  billing_division_id,
  pay_group,
}) =>
  call(websocketFetch, 'request_report', {
    type: 'payroll_ytd_report',
    request: {
      user_ids,
      group_id,
      start_date,
      end_date,
      benefit_class_id,
      billing_division_id,
      pay_group,
    },
  });
