export default {
  date: {
    input_type: 'date',
    position: 2,
    hidden: false,
    placeholder: 'Invoice issue time',
    label: 'Date',
    read_only: false,
    field_name: 'issue_time',
    json_type: 'string',
    go_type: 'time.Time',
    tooltip:
      'Enter the issue time that the invoice dry run(s) should be effective for',
  },
};
