import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function deleteDeductionCodes calls the backend to delete a deduction code
 * @param payload contains
 *    group_id - delete deduction codes contained in this group
 *    delete_all - boolean that indicates if we should delete all deduction codes for some group; can be ommited
 *    ids - list of deduction code ids to delete
 * @returns payload containing
 *    group_id
 *    deleted - array of:
 *        id
 *        code
 *        filter
 *            premium_type
 *            benefit_type
 *            benefit_plan_name
 *            dependent_coverage_level
 *            province_of_residence
 *            province_of_employment
 *            payroll_schedule_id
 */
export const deleteDeductionCodes = payload =>
  call(websocketFetch, 'delete_deduction_codes', payload);
