import { getSSOVendorDetails } from 'common/services';
import { request } from 'common/websocket-redux';
import { call, fork, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_SSO_VENDOR_DETAILS,
  GET_SSO_VENDOR_DETAILS,
} from './sso-error.action-types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestSSOVendorDetails(action) {
  yield request(
    REQUEST_SSO_VENDOR_DETAILS,
    getSSOVendorDetails({
      vendor_id: action.payload.vendorId,
    }),
  );
}

export function* showErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'SORRY_REQUEST_HAS_EXPIRED',
  });
}

export function* ssoErrorSaga() {
  yield fork(takeLatest, GET_SSO_VENDOR_DETAILS, requestSSOVendorDetails);
  yield fork(takeLatest, [REQUEST_SSO_VENDOR_DETAILS.ERRORED], showErrorToast);
}
