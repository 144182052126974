import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  getThemeValue,
  fontSizeInPx,
  StyledComponentRefWrapper,
} from '@leagueplatform/ui-kit';

export const TabLink = styled(StyledComponentRefWrapper(NavLink))`
  display: inline-block;
  margin-right: ${getThemeValue('space.genesis.one')}px;
  font-weight: normal;
  font-size: ${fontSizeInPx(2)};
  line-height: calc(${fontSizeInPx(2)} + 8px);
  color: ${getThemeValue('colors.neutral.gray')};
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  text-decoration: none;
  padding: ${getThemeValue('space.genesis.threeQuarters')}px 0;
  z-index: 1;

  &.active {
    color: ${getThemeValue('colors.purpleDarkest')};
    border-color: ${getThemeValue('colors.purple')};
    font-weight: 500;
  }
`;
