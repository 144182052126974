import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setPayrollSchedule calls the backend to update payroll schedule
 * @param {Object} payload must contain the entire document from the payroll_schedules collection
 * @param {string} group_id - group associated with this schedule
 * @param {string} schedule_name - friendly name of the pay schedule
 * @param {array} schedule - array of objects containing plan_period, pay_period, scheduled_on, pay_date info
 * @param {string} schedule_type - type of schedule (typically contributions)
 */
export const setPayrollSchedule = payload =>
  call(websocketFetch, 'set_payroll_schedule', {
    payroll_schedule: payload,
  });
