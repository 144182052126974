import PropTypes from 'prop-types';

export const ITEMS_PER_INFINITE_SCROLL = 20;

export const DATE_FORMAT = 'DD-MM-YYYY';

export const employeePropTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusUpdated: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  reinstatedDate: PropTypes.string.isRequired,
  suspendedDate: PropTypes.string.isRequired,
  suspendedType: PropTypes.string.isRequired,
};

export const FORM_TYPES = {
  copyInviteUrl: 'COPY_INVITE_URL',
  suspendConfirmation: 'SUSPENSION_CONFIRMATION',
  terminateConfirmation: 'TERMINATION_CONFIRMATION',
  reinstateConfirmation: 'REINSTATE_CONFIRMATION',
  unscheduleSuspend: 'UNSCHEDULE_SUSPENSION',
  unscheduleTerminate: 'UNSCHEDULE_TERMINATION',
  unscheduleReinstate: 'UNSCHEDULE_REINSTATE',
  addAdminRole: 'ADD_ADMIN_ROLE',
  removeAdminRole: 'REMOVE_ADMIN_ROLE',
  resetUserBenefits: 'RESET_USER_BENEFITS',
  sendInviteEmail: 'SEND_INVITE_EMAIL',
};

export const FORM_TYPES_ANALYTICS = {
  COPY_INVITE_URL: 'copy invite url',
  SUSPENSION_CONFIRMATION: 'suspend',
  TERMINATION_CONFIRMATION: 'terminate',
  REINSTATE_CONFIRMATION: 'reinstate',
  UNSCHEDULE_SUSPENSION: 'unschedule suspension',
  UNSCHEDULE_TERMINATION: 'unschedule termination',
  UNSCHEDULE_REINSTATE: 'unschedule reinstate',
  ADD_ADMIN_ROLE: 'add admin role',
  REMOVE_ADMIN_ROLE: 'remove admin role',
  RESET_USER_BENEFITS: 'reset user benefits',
  SEND_INVITE_EMAIL: 'send invite email',
};

export const EDIT_ADMIN_ROLE_TEXT = [
  'Visibility into all employee details',
  'Ability to invite new employees to the group',
  'Access to all reports & invoices',
  'Ability to view all benefits (if applicable)',
];

export const DATE_PICKER_MODAL = [
  FORM_TYPES.suspendConfirmation,
  FORM_TYPES.terminateConfirmation,
  FORM_TYPES.reinstateConfirmation,
];

export const UNSCHEDULE = 'unschedule';
export const CONFIRMATION = 'confirmation';
