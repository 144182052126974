import { combineReducers } from 'redux';
import { RE_REGISTER_PAY_PERIOD } from './edit-pay-calendar.types';

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const reRegisterStatus = (state = STATUS.IDLE, action = {}) => {
  switch (action?.type) {
    case RE_REGISTER_PAY_PERIOD.STARTED:
      return STATUS.LOADING;
    case RE_REGISTER_PAY_PERIOD.SUCCEEDED:
      return STATUS.SUCCESS;
    case RE_REGISTER_PAY_PERIOD.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

export const editPayCalendar = combineReducers({
  reRegisterStatus,
});
