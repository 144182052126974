import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import {
  selectDependentById,
  selectEmployeeProfile,
  selectGroupId,
} from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  saveDependent as saveDependentAction,
  terminateDependent as terminateDependentAction,
  getDependentAddQLEReasons,
  getDependentRelationshipTypes,
  getDependentTerminationTypeOptions,
} from '../store/dependent.actions';
import {
  selectDependentAddQLEReasons,
  selectEmployerCountryCode,
  selectDependentRelationshipTypes,
  selectIsDependentEditLoading,
  selectDependentTerminationTypeOptions,
  selectLoading,
} from '../store/dependent.selectors';

import { dependentSchema } from '../store/dependent.schema';

import { EditDependentPresenter } from './edit-dependent.view';

const mapStateToProps = createStructuredSelector({
  dependent: selectDependentById,
  addQLEReasons: selectDependentAddQLEReasons,
  employee: selectEmployeeProfile,
  groupId: selectGroupId,
  countryCode: selectEmployerCountryCode,
  relationshipTypes: selectDependentRelationshipTypes,
  terminationTypeOptions: selectDependentTerminationTypeOptions,
  preRequisitesLoading: selectIsDependentEditLoading,
  loading: selectLoading,
});

const mapDispatchToActions = {
  saveDependent: saveDependentAction,
  terminateDependent: terminateDependentAction,
  getQLEReasons: getDependentAddQLEReasons,
  getRelationshipTypes: getDependentRelationshipTypes,
  getDependentTerminationTypeOptions,
};

export const EditDependent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToActions,
  )(
    ({
      dependent,
      saveDependent,
      terminateDependent,
      groupId,
      employee: { user_id: employeeId },
      history,
      preRequisitesLoading,
      getQLEReasons,
      addQLEReasons,
      getRelationshipTypes,
      relationshipTypes,
      dependentsBaseUrl,
      getDependentTerminationTypeOptions: getTerminationOptions,
      terminationTypeOptions,
      ...rest
    }) => {
      useEffect(() => {
        getQLEReasons();
        getRelationshipTypes();
      }, [getQLEReasons, getRelationshipTypes]);

      useEffect(() => {
        if (terminationTypeOptions.length === 0) {
          getTerminationOptions();
        }
      }, [getTerminationOptions, terminationTypeOptions]);

      const { formatMessage } = useIntl();

      const onSubmit = values => {
        saveDependent({ dependent: values, groupId, userId: employeeId });
      };

      // Navigates up the nav stack (to dependents list)
      const onCancel = () => {
        history.push(`${dependentsBaseUrl}/${dependent.dependent_id}`);
      };

      const onRemove = ({ terminationDate, terminationReason }) => {
        terminateDependent({
          dependentId: dependent.dependent_id,
          employeeId,
          terminationDate,
          terminationReason,
        });
      };

      if (preRequisitesLoading) {
        return <LoadingIndicator />;
      }

      const augmentedFields = dependentSchema({
        addQLEReasons,
        formatMessage,
        relationshipTypes,
      });

      return (
        <EditDependentPresenter
          onSubmit={onSubmit}
          onCancel={onCancel}
          dependent={dependent}
          fields={augmentedFields}
          dependentsBaseUrl={dependentsBaseUrl}
          terminationTypeOptions={terminationTypeOptions}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...rest}
          onRemove={onRemove}
        />
      );
    },
  ),
);
