import { createRequestTypes } from 'common/websocket-redux';

export const SUBMIT_CLAIM = createRequestTypes('wallet/SUBMIT_CLAIM');
export function claimSubmitted(payload) {
  return {
    type: SUBMIT_CLAIM.BASE,
    payload,
  };
}

export const SUBMIT_CLAIM_VISITED = 'wallet/SUBMIT_CLAIM_VISITED';
export function submitClaimVisited(claimId) {
  return {
    type: SUBMIT_CLAIM_VISITED,
    payload: claimId,
  };
}

export const CLOSE_CLAIM_ERROR_MODAL = 'wallet/CLOSE_CLAIM_ERROR_MODAL';
export function closeClaimErrorModal() {
  return {
    type: CLOSE_CLAIM_ERROR_MODAL,
  };
}

export const CLOSE_CLAIM_SUCCESS_MODAL = 'wallet/CLOSE_CLAIM_SUCCESS_MODAL';
export function closeClaimSuccessModal() {
  return {
    type: CLOSE_CLAIM_SUCCESS_MODAL,
  };
}

export const GET_CLAIM = createRequestTypes('wallet/GET_CLAIM');
