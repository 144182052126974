import {
  EMPLOYER_INVOICES_INIT,
  EMPLOYER_INVOICES_LIFECYCLE,
  INVOICE_DRY_RUN,
  TOGGLE_INVOICE_STATUS,
  SAVE_INVOICE_STATUS,
} from './invoices.types';

export const initialState = {
  ready: false,
  dryRunLoading: false,
  invoiceDryRuns: null,
  invoicesTouched: false,
};

export const employerInvoicesUIReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case INVOICE_DRY_RUN.STARTED:
      return { ...state, dryRunLoading: true };
    case INVOICE_DRY_RUN.SUCCEEDED:
      return { ...state, invoiceDryRuns: action.payload, dryRunLoading: false };
    case INVOICE_DRY_RUN.ERRORED:
      return { ...state, ready: true, dryRunLoading: false };
    case EMPLOYER_INVOICES_INIT.SUCCEEDED:
      return { ...state, ready: true };
    case EMPLOYER_INVOICES_LIFECYCLE.EXITED:
      return initialState;
    case TOGGLE_INVOICE_STATUS.BASE:
      return { ...state, invoicesTouched: true };
    case SAVE_INVOICE_STATUS.SUCCEEDED:
      return { ...state, invoicesTouched: false };
    default:
      return state;
  }
};
