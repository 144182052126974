import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import EmployerIntegrations from './employer-integrations.view';
import { selectGroupId } from '../selectors';
import {
  selectUI,
  selectBenefitClassTypeformUrls,
  selectEmployerIntegrationFormValues,
} from './employer-integrations.selector';
import { Formik } from 'formik-legacy';
import { compose } from 'redux';
import {
  addBenefitClassTypeformUrls,
  getBenefitClassTypeformUrls,
  resetIntegrationFormUI,
} from './employer-integrations.actions';
import { map, isEqual } from 'lodash/fp';
import { lifecycle } from 'recompose';

const mapStateToProps = createStructuredSelector({
  typeform: selectBenefitClassTypeformUrls,
  ui: selectUI,
  groupId: selectGroupId,
  values: selectEmployerIntegrationFormValues,
});

export function benefitClassPayload(values, groupId) {
  const mapWithKey = map.convert({ cap: false });
  const benefitClasses = mapWithKey(
    (value, key) => ({
      id: key,
      typeform_url: value,
    }),
    values,
  );

  return {
    groupId,
    benefitClasses,
  };
}

const withFormikEnhancer = Formik({
  mapPropsToValues: props => {
    return props.values;
  },
  handleSubmit: (values, { props }) => {
    const payload = benefitClassPayload(values, props.groupId);
    props.addBenefitClassTypeformUrls(payload);
  },
});

const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.resetIntegrationFormUI();
    this.props.getBenefitClassTypeformUrls(this.props.groupId);
  },
  componentWillReceiveProps(nextProps) {
    // need to reset formik values when benefitClasses data changes
    if (
      !isEqual(
        this.props.typeform.benefitClasses,
        nextProps.typeform.benefitClasses,
      )
    ) {
      this.props.resetForm();
    }
  },
});

export default compose(
  connect(mapStateToProps, {
    getBenefitClassTypeformUrls,
    addBenefitClassTypeformUrls,
    resetIntegrationFormUI,
  }),
  withFormikEnhancer,
  withLifecycleActions,
)(EmployerIntegrations);
