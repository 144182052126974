import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Dropdown } from 'semantic-ui-react';
import { Box, FontAwesomeIcon } from '@leagueplatform/ui-kit';
import {
  BodyOne,
  HeadingThree,
  Flex,
  SubtitleOne,
  genesisStyled,
  BodyTwo,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { FormattedMessage } from 'react-intl';
import { useIntl } from '@leagueplatform/locales';
import { PremiumsReportTable } from './premiums-report-table';
import RequestPremiumsReport from './request-premiums-report.container';
import faSlidersH from '@fortawesome/fontawesome-free-solid/faSlidersH';
import {
  GROUP_PREMIUM_EXPORTS_REPORT,
  PAY_PERIOD_PREMIUM_EXPORTS_REPORT,
  DEFAULT_ITEMS_PER_PAGE,
} from './premiums-report.constants';
import { PAYROLL_FEATURE_FLAG } from 'apps/employer-experience/pages/EmployerDetails/Payroll/employer-payroll.types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const StyledDropdown = genesisStyled(Dropdown)`
  div[role="alert"] {
    margin-left: ${({ theme }) => theme.space.threeQuarters}px;
    font-size: ${({ theme }) => theme.fontSizes.body2}px !important;
  }
  i.dropdown.icon {
    margin: 0 !important;
  }
  i.dropdown.icon::before {
    font-size: ${({ theme }) => theme.fontSizes.body1}px !important;
  }
`;

const ButtonDropdown = genesisStyled(PrimaryButton)`
  padding: 0;

  .ui.dropdown {
    padding: ${({ theme }) => theme.space.threeQuarters}px ${({ theme }) =>
  theme.space.two}px;
  }

  .ui.dropdown > .menu {
    width: calc(100% + ${({ theme }) => 2 * theme.borderWidths.thin}px);
    left: ${({ theme }) => -theme.borderWidths.thin}px;
    top: calc(100% + ${({ theme }) => theme.space.half}px)
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledIcon = genesisStyled(props => <FontAwesomeIcon {...props} />)`
  cursor: pointer;
`;

const PayCalendarFilter = ({
  payCalendar,
  setPayCalendar,
  payrollSchedules,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Flex alignItems="center">
      <FontAwesomeIcon icon={faSlidersH} />
      <SubtitleOne marginX="half">
        {formatMessage({ id: 'PAY_CALENDARS' })}:
      </SubtitleOne>
      <Dropdown
        pointing="top right"
        text={payCalendar.schedule_name ?? formatMessage({ id: 'ALL' })}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setPayCalendar({})}>
            <BodyTwo>{formatMessage({ id: 'ALL' })}</BodyTwo>
          </Dropdown.Item>
          {payrollSchedules.map(schedule => (
            <Dropdown.Item
              onClick={() => setPayCalendar(schedule)}
              key={schedule.id}
            >
              <BodyTwo>{schedule.label}</BodyTwo>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Flex>
  );
};
PayCalendarFilter.propTypes = {
  payCalendar: PropTypes.shape({
    schedule_name: PropTypes.string,
  }).isRequired,
  setPayCalendar: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedules: PropTypes.arrayOf(PropTypes.object),
};
PayCalendarFilter.defaultProps = {
  payrollSchedules: [],
};

const onClickPayPeriodDropdown = ({
  payrollSchedules,
  setShowRequestReportModal,
  setRequestType,
  formatMessage,
}) => {
  if (!payrollSchedules.length) {
    toastActions.add({
      type: TOAST_STATUS.ERROR,
      text: formatMessage({
        id: 'ERROR_PAY_CALENDAR_DOES_NOT_EXIST',
      }),
    });
  } else {
    setShowRequestReportModal(true);
    setRequestType(PAY_PERIOD_PREMIUM_EXPORTS_REPORT);
  }
};

const RequestPayPeriodPremiumsReport = ({
  payrollSchedules,
  setShowRequestReportModal,
  setRequestType,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dropdown.Item
      onClick={() =>
        onClickPayPeriodDropdown({
          payrollSchedules,
          setShowRequestReportModal,
          setRequestType,
          formatMessage,
        })
      }
    >
      <BodyTwo>
        {formatMessage({ id: 'PAY_PERIOD_PREMIUM_EXPORTS_REPORT' })}
      </BodyTwo>
    </Dropdown.Item>
  );
};
RequestPayPeriodPremiumsReport.propTypes = {
  setShowRequestReportModal: PropTypes.func.isRequired,
  setRequestType: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedules: PropTypes.arrayOf(PropTypes.object),
};
RequestPayPeriodPremiumsReport.defaultProps = {
  payrollSchedules: [],
};

const PremiumsReportPage = ({
  getPremiumsReport,
  getChangeReport,
  previousReports,
  previousReportsLoading,
  previousReportsSkip: skip,
  onFetchReportsRequested,
  payrollSchedules,
  totalPreviousReports,
  featureFlags,
}) => {
  const { formatMessage } = useIntl();
  const [limit, setLimit] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [showRequestReportModal, setShowRequestReportModal] = useState(false);
  const [requestType, setRequestType] = useState(GROUP_PREMIUM_EXPORTS_REPORT);
  const [payCalendar, setPayCalendar] = useState({});

  const fetchCallback = useCallback(() => {
    onFetchReportsRequested({
      skip: 0,
      limit,
      ...(payCalendar.id && { payroll_schedule_id: payCalendar.id }),
    });
  }, [onFetchReportsRequested, limit, payCalendar]);

  // get the first page when limit/report filter changes
  useEffect(() => {
    fetchCallback();
  }, [fetchCallback, limit, payCalendar]);

  const nextPage = skip + limit;
  const previousPage = skip - limit;
  const pageMin = Math.min(totalPreviousReports, skip + 1);
  const pageMax =
    Math.min(totalPreviousReports, nextPage) || totalPreviousReports;
  const pagination = `${pageMin}-${pageMax} of ${totalPreviousReports}`;

  return (
    <Dimmer.Dimmable as={Box}>
      {showRequestReportModal && (
        <RequestPremiumsReport
          onClose={() => setShowRequestReportModal(false)}
          requestType={requestType}
          getPremiumsReport={getPremiumsReport}
          payrollSchedules={payrollSchedules}
        />
      )}
      <Flex alignItems="center">
        <Flex flexDirection="column" flexGrow={1}>
          <HeadingThree>
            <FormattedMessage id="PREMIUMS_HEADER" />
          </HeadingThree>
          <BodyOne color="onSurface.text.subdued">
            {formatMessage({ id: 'PREMIUMS_HEADER_COPY' })}
          </BodyOne>
        </Flex>
        <ButtonDropdown padding="two" flexShrink={0}>
          <Dropdown text={formatMessage({ id: 'REQUEST_REPORT' })}>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setShowRequestReportModal(true);
                  setRequestType(GROUP_PREMIUM_EXPORTS_REPORT);
                }}
              >
                <BodyTwo>
                  {formatMessage({ id: 'GROUP_PREMIUM_EXPORTS_REPORT' })}
                </BodyTwo>
              </Dropdown.Item>
              {featureFlags[PAYROLL_FEATURE_FLAG] && (
                <RequestPayPeriodPremiumsReport
                  payrollSchedules={payrollSchedules}
                  setShowRequestReportModal={setShowRequestReportModal}
                  setRequestType={setRequestType}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </ButtonDropdown>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom="oneAndHalf"
        marginTop="three"
      >
        <HeadingThree marginTop="three">
          {formatMessage({ id: 'HISTORY' })}
        </HeadingThree>
        {featureFlags[PAYROLL_FEATURE_FLAG] && (
          <PayCalendarFilter
            payCalendar={payCalendar}
            setPayCalendar={setPayCalendar}
            payrollSchedules={payrollSchedules}
          />
        )}
      </Flex>
      <PremiumsReportTable
        reports={previousReports}
        loading={previousReportsLoading}
        onFetchReportsRequested={onFetchReportsRequested}
        getChangeReport={getChangeReport}
        payrollSchedules={payrollSchedules}
      />
      <Flex justifyContent="flex-end" marginY="two">
        <Flex marginRight="oneAndHalf" alignItems="center">
          <BodyTwo>Rows per page:</BodyTwo>
          <StyledDropdown text={`${limit || formatMessage({ id: 'ALL' })}`}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setLimit(10)}>
                <BodyTwo>10</BodyTwo>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLimit(20)}>
                <BodyTwo>20</BodyTwo>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLimit(0)}>
                <BodyTwo>{formatMessage({ id: 'ALL' })}</BodyTwo>
              </Dropdown.Item>
            </Dropdown.Menu>
          </StyledDropdown>
        </Flex>
        <Flex alignItems="center">
          <StyledIcon
            icon="chevron-left"
            onClick={() =>
              skip &&
              onFetchReportsRequested({
                skip: previousPage,
                limit,
                ...(payCalendar.id && { payroll_schedule_id: payCalendar.id }),
              })
            }
          />
          <BodyTwo marginX="one">{pagination}</BodyTwo>
          <StyledIcon
            icon="chevron-right"
            onClick={() =>
              pageMax < totalPreviousReports &&
              onFetchReportsRequested({
                skip: nextPage,
                limit,
                ...(payCalendar.id && { payroll_schedule_id: payCalendar.id }),
              })
            }
          />
        </Flex>
      </Flex>
    </Dimmer.Dimmable>
  );
};

PremiumsReportPage.propTypes = {
  getPremiumsReport: PropTypes.func.isRequired,
  getChangeReport: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  previousReports: PropTypes.arrayOf(PropTypes.object),
  previousReportsLoading: PropTypes.string.isRequired,
  previousReportsSkip: PropTypes.number.isRequired,
  onFetchReportsRequested: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedules: PropTypes.arrayOf(PropTypes.object),
  totalPreviousReports: PropTypes.number.isRequired,
  featureFlags: PropTypes.shape({ [PAYROLL_FEATURE_FLAG]: PropTypes.bool }),
};

PremiumsReportPage.defaultProps = {
  payrollSchedules: [],
  featureFlags: {},
  previousReports: [],
};

export default PremiumsReportPage;
