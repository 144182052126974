import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_SPENDING_ACCOUNT_BALANCE_REPORT = createRequestTypes(
  'hr/employer-reports/GET_SPENDING_ACCOUNT_BALANCE_REPORT',
);

export const REQUEST_SPENDING_ACCOUNT_BALANCE_REPORT =
  'hr/employer-reports/REQUEST_SPENDING_ACCOUNT_BALANCE_REPORT';

export const SPENDING_ACCOUNT_BALANCE_REPORT_LIFECYCLE = createLifecycleTypes(
  'hr/employer-reports/SPENDING_ACCOUNT_BALANCE_REPORT_LIFECYCLE',
);
