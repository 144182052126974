import { BILLING_PREFERENCE_BY_DIVISION } from '../../constants';

export const validateBillingDivision = (billingPreference, value) => {
  if (
    billingPreference &&
    billingPreference.includes(BILLING_PREFERENCE_BY_DIVISION)
  ) {
    return value || value === '';
  }
  return true;
};

export const selectBillingDivisionValidator =
  ({ billingPreference }) =>
  values => {
    const isValid = validateBillingDivision(
      billingPreference,
      values.billing_division_id,
    );
    return isValid ? {} : { billing_division_id: ['is required'] };
  };
