import { takeLatest, select, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { selectGroupId } from 'apps/employer-experience-v2/employee-benefits/benefits.selectors';

import {
  getGroupInvoiceAdHocFees as getGroupInvoiceAdHocFeesService,
  removeGroupInvoiceAdjustment,
  getDryRun,
  requestIssueInvoice,
} from 'common/services';

import {
  GET_PENDING_FEES,
  REMOVE_PENDING_FEE,
  ISSUE_ADHOC_INVOICE,
  GET_ADHOC_INVOICE_PREVIEW,
} from './fees.action-types';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getPendingFees() {
  const groupId = yield select(selectGroupId);
  yield request(
    GET_PENDING_FEES,
    getGroupInvoiceAdHocFeesService({ group_id: groupId }),
  );
}

export function* removeFee({ payload }) {
  const groupId = yield select(selectGroupId);

  yield request(
    REMOVE_PENDING_FEE,
    removeGroupInvoiceAdjustment({ ...payload, group_id: groupId }),
  );
}

export function* removeFeeSuccess() {
  yield getPendingFees();
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'REMOVE_FEE_SUCCESS',
  });
}

export function* issueInvoice({ payload, params }) {
  yield request(ISSUE_ADHOC_INVOICE, requestIssueInvoice(payload));
  yield getPendingFees();

  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    content: params.content,
    shouldAutoClose: false,
  });
}

export function* getInvoicePreview() {
  const groupId = yield select(selectGroupId);
  yield request(
    GET_ADHOC_INVOICE_PREVIEW,
    getDryRun({ group_id: groupId, invoice_type: 'ad_hoc_fees' }),
  );
}

export function getInvoicePreviewSuccess({ payload }) {
  const newTab = window.open(
    getContentUrl(payload.info.invoices[0].content_reference_id),
    '_blank',
  );
  newTab.focus();
}

export function* pendingFeesSaga() {
  yield takeLatest(GET_PENDING_FEES.BASE, getPendingFees);
  yield takeLatest(REMOVE_PENDING_FEE.BASE, removeFee);
  yield takeLatest(REMOVE_PENDING_FEE.SUCCEEDED, removeFeeSuccess);
  yield takeLatest(ISSUE_ADHOC_INVOICE.BASE, issueInvoice);
  yield takeLatest(GET_ADHOC_INVOICE_PREVIEW.BASE, getInvoicePreview);
  yield takeLatest(
    GET_ADHOC_INVOICE_PREVIEW.SUCCEEDED,
    getInvoicePreviewSuccess,
  );
}
