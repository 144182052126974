import {
  EMPLOYER_PAYROLL_INIT,
  EMPLOYER_PAYROLL_REPORTS_LIFECYCLE,
} from './payroll-report.types';
import { GET_EMPLOYER_HR_DRIVERS } from '../../reports.types';

export const initialState = { invoices: null, hrDrivers: [] };

export const employerPayrollReportsReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case EMPLOYER_PAYROLL_INIT.SUCCEEDED:
      return { ...state, invoices: action.payload.invoices };
    case EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.EXITED:
      return initialState;
    case GET_EMPLOYER_HR_DRIVERS.SUCCEEDED:
      return {
        ...state,
        hrDrivers: action.payload.info.drivers,
      };
    default:
      return state;
  }
};
