import React from 'react';
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  DroppableList,
  DroppableListContainer,
} from '../enrollment-experience-config.styles';
import {
  AVAILABLE_BENEFIT_SETS_LIST,
  BENEFIT_SET_TYPE,
} from '../enrollment-experience-config.constants';
import { DraggableBenefitSet } from '../draggable-benefit-set/draggable-benefit-set.view';

// TODO: ON-7522
// Replace type of "any" when selector + type is created for available benefit sets
interface AvailableBenefitSetsListProps {
  availableBenefitSetsList: any[];
}

const AvailableBenefitSetsListPresenter = ({
  availableBenefitSetsList,
}: AvailableBenefitSetsListProps) => {
  return (
    <DroppableListContainer title="AVAILABLE_BENEFIT_SETS">
      <Droppable
        type={BENEFIT_SET_TYPE}
        droppableId={AVAILABLE_BENEFIT_SETS_LIST}
        key={AVAILABLE_BENEFIT_SETS_LIST}
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <DroppableList
            innerRef={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            data-testid={AVAILABLE_BENEFIT_SETS_LIST}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...provided.droppableProps}
          >
            {/* TODO: ON-7522 Populate list */}
            {/* Benefit Set droppable components must have a type of "set" to be droppable */}
            {availableBenefitSetsList.map((set: any, index: number) => (
              <DraggableBenefitSet key={set.id} set={set} index={index} />
            ))}

            {provided.placeholder}
          </DroppableList>
        )}
      </Droppable>
    </DroppableListContainer>
  );
};

export default AvailableBenefitSetsListPresenter;
