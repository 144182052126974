import { createRequestTypes } from 'common/websocket-redux';

export const SUBMIT_QLE_CONFIGURATION_FORM =
  'qle-configuration-form/SUBMIT_QLE_CONFIGURATION_FORM';

export const CANCEL_QLE_CONFIGURATION_FORM =
  'qle-configuration-form/CANCEL_QLE_CONFIGURATION_FORM';

export const SERVICE_SET_QLE_CONFIGURATION = createRequestTypes(
  'qle-configuration-form/SERVICE_SET_QLE_CONFIGURATION',
);
