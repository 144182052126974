/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { combineReducers } from 'redux';
import {
  GET_RATE_SHEET_EXTRACT,
  GET_USER_BENEFITS,
  GET_USER_BENEFIT_CONTRIBUTIONS,
  EMPLOYEE_TASA_DEDUCTIONS_LIFECYCLE,
  GET_REPORTS,
  GET_PAYROLL_FEATURE_FLAG,
  GET_REPORT_PRESETS,
  GET_PAYROLL_REPORT_CONFIGS,
} from './employer-payroll.types';
import { editPayCalendar } from './resources/edit-pay-calendar/edit-pay-calendar.reducer';
import { payrollConfig } from './resources/payroll-config/payroll-config.reducer';
import {
  deductionCodeEditorReducer,
  STATUS as DEDUCTION_CODE_STATUS,
} from './resources/deduction-codes/deduction-codes.reducer';
import { payrollDeductions, payrollAdjustments } from '../Employee/reducer';
import { yearToDateReport } from './year-to-date-report/year-to-date-report.reducer';
import { STATUS, craTypes } from './employer-payroll.constants';

export const rateSheetExtractState = {
  contentId: '',
  fileName: '',
  status: STATUS.IDLE,
};
// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const rateSheetExtract = (state = rateSheetExtractState, action) => {
  switch (action?.type) {
    case GET_RATE_SHEET_EXTRACT.STARTED:
      return {
        ...state,
        status: STATUS.LOADING,
      };
    case GET_RATE_SHEET_EXTRACT.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      const report_file = (
        action?.payload?.info?.report?.report_files ?? []
      ).find(file => file.mime_type === 'text/csv');
      return {
        ...state,
        fileName: report_file?.filename,
        contentId: report_file?.content_id,
        status: STATUS.SUCCESS,
      };
    case GET_RATE_SHEET_EXTRACT.ERRORED:
      return {
        ...state,
        status: STATUS.ERROR,
      };
    default:
      return state;
  }
};

export const benefitContributionsState = {
  contributionAccounts: [],
  contributions: [],
};
export const getCRABenefitType = benefitType => {
  const craType = craTypes.find(type => benefitType.includes(type));
  return `cra_${craType}`;
};
export const benefitContributions = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = benefitContributionsState,
  action,
) => {
  switch (action?.type) {
    case EMPLOYEE_TASA_DEDUCTIONS_LIFECYCLE.EXITED:
      return benefitContributionsState;
    case GET_USER_BENEFITS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const accounts = action.payload.info.benefits?.reduce(
        (
          acc,
          {
            id,
            parent: {
              benefit_start_date,
              benefit_end_date,
              full_name: benefitName,
              benefit_product_type,
              benefit_type,
            },
            employee_contribution: employeeContribution,
          },
        ) => {
          if (employeeContribution) {
            const benefitType =
              benefit_product_type === 'cra'
                ? getCRABenefitType(benefit_type)
                : benefit_product_type;
            return [
              ...acc,
              {
                ...employeeContribution,
                id,
                benefitName,
                benefit_start_date,
                benefit_end_date,
                value: `${benefitType},${id}`, // have it this way to handle select options with same benefitType
              },
            ];
          }
          return acc;
        },
        [],
      );
      return {
        ...state,
        contributionAccounts: accounts,
      };
    case GET_USER_BENEFIT_CONTRIBUTIONS.SUCCEEDED:
      return {
        ...state,
        contributions:
          action.payload.info.benefit_contributions?.contributions?.reverse() ??
          [],
      };
    default:
      return state;
  }
};

export const payrollState = {
  featureFlags: {},
  reports: [],
};
export const payroll = (state = payrollState, action = {}) => {
  switch (action?.type) {
    case GET_PAYROLL_FEATURE_FLAG.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const flagId = action.payload.info?.flag?.flag_id;
      if (!flagId) {
        return state;
      }
      return {
        ...state,
        featureFlags: {
          [flagId]:
            (action.payload.info?.flag?.on_for_groups ?? []).indexOf(
              action.payload.groupId,
            ) === -1,
        },
      };
    case GET_REPORTS.SUCCEEDED:
      return {
        ...state,
        reports: action.payload.info.reports,
      };
    default:
      return state;
  }
};

export const customReportsState = {
  availableColumns: [],
  savedPresets: [],
  isLoaded: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const customReports = (state = customReportsState, action) => {
  switch (action?.type) {
    case GET_REPORT_PRESETS.STARTED:
      return {
        ...state,
        isLoaded: false,
      };
    case GET_REPORT_PRESETS.SUCCEEDED:
      return {
        ...state,
        availableColumns: action?.payload?.info?.available_columns,
        savedPresets: action?.payload?.info?.presets,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export const payrollReportConfigsState = {
  payrollConfigs: [],
};

export const payrollReportConfigs = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = payrollReportConfigsState,
  action,
) => {
  switch (action?.type) {
    case GET_PAYROLL_REPORT_CONFIGS.SUCCEEDED:
      return {
        payrollConfigs: action?.payload?.info?.group_payroll_report_configs,
      };
    default:
      return state;
  }
};

export const employerPayrollInitialState = {
  benefitContributions: {
    contributionAccounts: [],
    contributions: [],
  },
  payroll: {
    featureFlags: {},
    reports: [],
  },
  rateSheetExtract: {
    contentId: '',
    fileName: '',
    status: 'idle',
  },
  payrollConfig: {
    exclusionsConfig: {
      properties: {},
      type: 'object',
    },
  },
  editPayCalendar: {
    reRegisterStatus: 'idle',
  },
  deductionCodeEditor: {
    deductionCodes: [],
    payrollSchedules: [],
    status: DEDUCTION_CODE_STATUS.IDLE,
  },
  payrollDeductions: {
    user_deductions: [],
  },
  yearToDateReport: {
    report: {
      contentId: '',
      fileName: '',
    },
    requestStatus: STATUS.IDLE,
  },
  payrollAdjustments: {
    user_adjustments: [],
  },
  customReports: {
    availableColumns: [],
    savedPresets: [],
    isLoaded: false,
  },
  payrollReportConfigs: {
    payrollConfigs: [],
  },
};

export const employerPayrollReducer = combineReducers({
  rateSheetExtract,
  benefitContributions,
  payroll,
  editPayCalendar,
  payrollConfig,
  deductionCodeEditor: deductionCodeEditorReducer,
  payrollDeductions,
  yearToDateReport,
  payrollAdjustments,
  customReports,
  payrollReportConfigs,
});
