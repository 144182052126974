/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React, { useState } from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { FontAwesomeIcon } from '@leagueplatform/ui-kit';
import {
  Dropdown,
  DropdownTrigger,
  DropdownItems,
  DropdownItem,
} from 'common/components/dropdown';
import { BodyTwo, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import faEllipsisH from '@fortawesome/fontawesome-free-solid/faEllipsisH';
import { ChangeReportModal } from './change-report-modal.view';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const DropdownCell = genesisStyled('td')({
  '&&& ': {
    overflow: 'visible',
    padding: '0',
  },
});

const reportTypeToMessageIdMap = {
  full: 'FULL_REPORT',
  change: 'CHANGE_REPORT',
};

const ImportDropdownTrigger = genesisStyled(DropdownTrigger)({
  padding: '0',
  border: 'none',
  background: 'none',
  height: 'auto',
  minWidth: '4rem',

  '&:hover:not([disabled])': {
    background: 'none',
  },
});

const ImportDropdownItems = genesisStyled(DropdownItems)({
  left: 'auto',
  right: '0',
  top: '0',
  padding: '0',
  zIndex: '99999',
  '&:before': {
    display: 'none',
  },
});

const ImportDropdownItem = genesisStyled(DropdownItem)(
  css({
    padding: 'one',
    '&:hover': css({
      backgroundColor: 'decorative.brand.primary.pastel',
    }),
  }),
);

const EmailText = genesisStyled(BodyTwo)({
  wordBreak: 'break-all',
});

const formatPayPeriod = ({ start_date, end_date }, formatMessage) => {
  if (
    start_date === '0001-01-01T00:00:00Z' &&
    end_date === '0001-01-01T00:00:00Z'
  ) {
    return formatMessage({ id: 'NA' });
  }
  const startDate = utcToZonedTime(start_date ?? new Date());
  const endDate = utcToZonedTime(end_date ?? new Date());
  const startDateString = format(
    startDate,
    `MMMM dd${startDate.getYear() !== endDate.getYear() ? ' yyyy' : ''}`,
  );
  const endDateString = format(endDate, 'MMMM dd yyyy');
  return `${startDateString} - ${endDateString}`;
};

const getPayCalendarLabel = (payCalendar, payPeriodLabel, formatMessage) => {
  if (payCalendar?.schedule_name) {
    const startYear = format(
      utcToZonedTime(payCalendar?.schedule[0]?.plan_period.start_date, 'UTC'),
      'yyyy',
    );
    const endYear = format(
      utcToZonedTime(payCalendar?.schedule[0]?.plan_period.end_date, 'UTC'),
      'yyyy',
    );

    return `${payCalendar.schedule_name} ${startYear}${
      endYear !== startYear ? ` ${endYear}` : ''
    }`;
  }
  return payPeriodLabel === formatMessage({ id: 'NA' })
    ? formatMessage({ id: 'GROUP_PREMIUM_EXPORTS_REPORT' })
    : formatMessage({ id: 'PAY_PERIOD_PREMIUM_EXPORTS_REPORT' });
};

export const PremiumsReportTableRow = ({
  report_id,
  date_created,
  user_id,
  premiums_content_id,
  sdr_content_id,
  type,
  internal_report_id,
  getChangeReport,
  changeReportLoading,
  payPeriod,
  payCalendar,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const payPeriodLabel = formatPayPeriod(payPeriod, formatMessage);
  const handleClick = contentId => window.open(getContentUrl(contentId));

  return (
    <tr>
      <td>
        <BodyTwo>
          {formatDate(new Date(date_created), {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            timeZoneName: 'short',
            timeZone: 'UTC',
          })}
        </BodyTwo>
      </td>
      <td>
        <EmailText>{user_id}</EmailText>
      </td>
      <td>
        <BodyTwo>
          {formatMessage({
            id: reportTypeToMessageIdMap[type],
          })}
        </BodyTwo>
      </td>
      <td>
        <BodyTwo>
          {getPayCalendarLabel(payCalendar, payPeriodLabel, formatMessage)}
        </BodyTwo>
      </td>
      <td>
        <BodyTwo>{payPeriodLabel}</BodyTwo>
      </td>
      <DropdownCell>
        <Dropdown>
          <ImportDropdownTrigger>
            <FontAwesomeIcon icon={faEllipsisH} />
          </ImportDropdownTrigger>
          <ImportDropdownItems>
            <ImportDropdownItem
              onClick={() => handleClick(premiums_content_id)}
            >
              <BodyTwo>{formatMessage({ id: 'DOWNLOAD_REPORT' })}</BodyTwo>
            </ImportDropdownItem>
            {sdr_content_id && (
              <ImportDropdownItem onClick={() => handleClick(sdr_content_id)}>
                <BodyTwo>
                  {formatMessage({ id: 'DOWNLOAD_SDR_REPORT' })}
                </BodyTwo>
              </ImportDropdownItem>
            )}
            {type === 'full' && (
              <ImportDropdownItem
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <BodyTwo>
                  {formatMessage({ id: 'REQUEST_CHANGE_REPORT' })}
                </BodyTwo>
              </ImportDropdownItem>
            )}
            <ImportDropdownItem>
              <CopyToClipboard text={report_id}>
                <BodyTwo
                  onClick={() => {
                    toastActions.add({
                      type: TOAST_STATUS.SUCCESS,
                      textId: 'REPORT_ID_CLIPBOARD',
                    });
                  }}
                >
                  {formatMessage({ id: 'COPY_REPORT_ID' })}
                </BodyTwo>
              </CopyToClipboard>
            </ImportDropdownItem>
          </ImportDropdownItems>
        </Dropdown>
        {showModal && (
          <ChangeReportModal
            reportId={report_id}
            internalReportId={internal_report_id}
            dateCreated={date_created}
            onClose={() => {
              setShowModal(false);
            }}
            getChangeReport={getChangeReport}
            loading={changeReportLoading}
          />
        )}
      </DropdownCell>
    </tr>
  );
};

PremiumsReportTableRow.propTypes = {
  date_created: PropTypes.string.isRequired,
  report_id: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
  premiums_content_id: PropTypes.string.isRequired,
  sdr_content_id: PropTypes.string,
  type: PropTypes.string.isRequired,
  internal_report_id: PropTypes.string.isRequired,
  getChangeReport: PropTypes.func.isRequired,
  changeReportLoading: PropTypes.bool,
  payPeriod: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }),
  payCalendar: PropTypes.shape({
    schedule_name: PropTypes.string,
    schedule: PropTypes.arrayOf(
      PropTypes.shape({
        plan_period: PropTypes.shape({
          start_date: PropTypes.string,
          end_date: PropTypes.string,
        }),
      }),
    ),
  }),
};

PremiumsReportTableRow.defaultProps = {
  changeReportLoading: false,
  payCalendar: {},
  payPeriod: {
    start_date: '0001-01-01T00:00:00Z',
    end_date: '0001-01-01T00:00:00Z',
  },
  sdr_content_id: '',
};
