import {
  CHANGE_USER_BENEFIT_CHANGE_ALLOCATION,
  RESET_CHANGE_ALLOCATION_STATUS,
} from './change-allocation.types';

export const changeUserBenefitAllocation = ({ benefitId, amount }) => ({
  type: CHANGE_USER_BENEFIT_CHANGE_ALLOCATION,
  payload: { benefitId, amount },
});

export const resetChangeAllocationStatus = () => ({
  type: RESET_CHANGE_ALLOCATION_STATUS,
});
