import { get, cloneDeep, concat } from 'lodash';
import { normalize, denormalize, schema } from 'normalizr';
import {
  GET_USER_BENEFIT_DOCUMENTS,
  EMPLOYEE_BENEFITS_PAGE_LIFECYCLE,
} from './benefits.types';

export const documentSchema = new schema.Entity('documents');

export const initialState = {
  data: normalize([], [documentSchema]),
};

const applyGetUserBenefitDocumentsSucceeded = (state, { payload }) => {
  const clonedData = cloneDeep(state.data);
  const denormalized = denormalize(
    clonedData.result,
    [documentSchema],
    clonedData.entities,
  );
  return {
    ...state,
    data: normalize(
      concat(denormalized, get(payload, 'info.documents') || []),
      [documentSchema],
    ),
  };
};

export const employeeBenefitDocumentsReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case GET_USER_BENEFIT_DOCUMENTS.SUCCEEDED:
      return applyGetUserBenefitDocumentsSucceeded(state, action);
    case EMPLOYEE_BENEFITS_PAGE_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
