import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Box,
  Flex,
  Text,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { FontAwesomeIcon } from '@leagueplatform/ui-kit';
import { splitToParagraphs } from '../helpers';
import { KEY } from '@leagueplatform/web-common';

// eslint-disable-next-line react/jsx-props-no-spreading
const ChevronIcon = genesisStyled(props => <FontAwesomeIcon {...props} />)(
  css({
    color: 'interactive.action.primary',
    marginLeft: 'half',
  }),
);

const IMAGE_WIDTH = 226;
export const InfoBox = ({ heading, body, image_url: imageUrl, onToggle }) => {
  const [boxOpen, setBoxOpen] = useState(false);
  const toggleBoxOpen = () => {
    onToggle(!boxOpen);
    setBoxOpen(!boxOpen);
  };

  const handleKeyUp = e => e.key === KEY.ENTER && toggleBoxOpen();

  return (
    <Box
      borderRadius="small"
      color="onSurface.text.primary"
      backgroundColor="highlight.background.subdued"
      aria-expanded={boxOpen}
      width={1}
    >
      <Flex
        as="button"
        type="button"
        tabIndex="0"
        justifyContent="space-between"
        alignItems="center"
        padding="one"
        cursor="pointer"
        backgroundColor="transparent"
        borderWidth="none"
        width={1}
        onClick={toggleBoxOpen}
        onKeyUp={handleKeyUp}
        hoverStyle={{
          color: 'onSurface.text.primary',
        }}
      >
        <Text fontWeight="medium" fontSize="body1">
          {heading}
        </Text>
        <ChevronIcon icon={`chevron-${boxOpen ? 'up' : 'down'}`} alt="" />
      </Flex>
      {boxOpen && (
        <Flex
          padding="one"
          alignItems="center"
          flexDirection={{ _: 'column', tablet: 'row' }}
        >
          {imageUrl && (
            <Image
              width={IMAGE_WIDTH}
              maxWidth={IMAGE_WIDTH}
              minWidth={IMAGE_WIDTH}
              src={imageUrl}
              alt={heading}
            />
          )}
          <Box margin={{ _: 'one', tablet: 0 }}>{splitToParagraphs(body)}</Box>
        </Flex>
      )}
    </Box>
  );
};

InfoBox.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image_url: PropTypes.string,
  onToggle: PropTypes.func,
};

InfoBox.defaultProps = {
  onToggle: () => {},
  image_url: undefined,
};
