import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const ADD_ADJUSTMENT_FORM_SUBMITTED =
  'admin/employer-adjustments/ADD_ADJUSTMENT_FORM_SUBMITTED';
export const ADD_ADJUSTMENT = createRequestTypes(
  'admin/employer-adjustments/ADD_ADJUSTMENT',
);

export const REQUEST_REMOVE_ADJUSTMENT =
  'admin/employer-adjustments/REQUEST_REMOVE_ADJUSTMENT';
export const REMOVE_ADJUSTMENT = createRequestTypes(
  'admin/employer-adjustments/REMOVE_ADJUSTMENT',
);

export const GET_ADJUSTMENTS = createRequestTypes(
  'admin/employer-adjustments/GET_ADJUSTMENTS',
);

export const EMPLOYER_ADJUSTMENTS_INIT = createRequestTypes(
  'admin/employer-adjustments/INIT',
);

export const EMPLOYER_ADJUSTMENTS_LIFECYCLE = createLifecycleTypes(
  'admin/employer-adjustments/EMPLOYER_ADJUSTMENTS_LIFECYCLE',
);
