import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectDetailedUsageIsLoading,
  selectDetailedUsageIsReady,
  selectDetailedUsageReportError,
  selectUsageSummaryIsLoading,
  selectUsageSummaryIsReady,
  selectUsageSummaryReportError,
  selectLastChanged,
  selectUserEmail,
} from './reports.selectors';
import { EMPLOYER_USAGE_REPORTS_LIFECYCLE } from './reports.types';
import { UsageReportsPagePresenter } from './usage-reports-page.view';

export const UsageReportsPage = connect(
  createStructuredSelector({
    detailedUsageIsLoading: selectDetailedUsageIsLoading,
    detailedUsageIsReady: selectDetailedUsageIsReady,
    detailedUsageReportError: selectDetailedUsageReportError,
    usageSummaryIsLoading: selectUsageSummaryIsLoading,
    usageSummaryIsReady: selectUsageSummaryIsReady,
    usageSummaryReportError: selectUsageSummaryReportError,
    lastChanged: selectLastChanged,
    email: selectUserEmail,
  }),
  {
    visit: EMPLOYER_USAGE_REPORTS_LIFECYCLE.visit,
    exit: EMPLOYER_USAGE_REPORTS_LIFECYCLE.exit,
  },
)(UsageReportsPagePresenter);
