import {
  GET_ADJUSTMENTS,
  EMPLOYER_ADJUSTMENTS_LIFECYCLE,
} from './adjustments.types';

export const initialState = null;

export const employerAdjustmentsReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case GET_ADJUSTMENTS.SUCCEEDED:
      return action.payload.info;
    case EMPLOYER_ADJUSTMENTS_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
