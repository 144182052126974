import {
  selectReportContentId,
  selectIsReportLoading,
  selectReportError,
  selectUserEmail,
} from './employer-reports.selectors';
import { createStructuredSelector } from 'reselect';
import { getEmployeeDemographicsReport } from './employer-reports.actions';
import { connect } from 'react-redux';
import { EmployeeDemographicsReport } from './employee-demographics-report.view';
import { EMPLOYEE_DEMOGRAPHICS_REPORT_LIFECYCLE } from './employer-reports.types';

const mapStateToProps = createStructuredSelector({
  contentId: selectReportContentId,
  loadingState: selectIsReportLoading,
  reportError: selectReportError,
  email: selectUserEmail,
});

const mapDispatchToProps = {
  getEmployeeDemographicsReport,
  visit: EMPLOYEE_DEMOGRAPHICS_REPORT_LIFECYCLE.visit,
};

export const EmployeeDemographicsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeDemographicsReport);
