/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { GET_USER_BENEFIT_FLEX_DOLLARS } from './update-flex-dollars.types';

export const initialState = {
  totalFlexDollars: null,
  loadedFlexDollars: null,
};

export const employeeUpdateFlexDollarsReducer = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_USER_BENEFIT_FLEX_DOLLARS.STARTED: {
      return initialState;
    }
    case GET_USER_BENEFIT_FLEX_DOLLARS.SUCCEEDED: {
      // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
      const { total_flex_dollars, loaded_flex_dollars } = action.payload?.info;
      return {
        ...state,
        totalFlexDollars: total_flex_dollars,
        loadedFlexDollars: loaded_flex_dollars,
      };
    }
    default:
      return state;
  }
};
