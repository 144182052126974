import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { updateGroupCategories as updateGroupCategoriesAction } from '../../actions';
import { selectUpdatingGroupCategories } from '../../selectors';
import { UpdateGroupCategoriesModalPresenter } from './UpdateGroupCategoriesModal.view';

export const UpdateGroupCategoriesModalContainer = ({
  updateGroupCategories,
  groupId,
  close,
  id: benefitId,
  processing,
  plan_id: planId,
  ...props
}) => {
  const prevProcessing = useRef(processing);

  useEffect(() => {
    if (!processing && prevProcessing.current === true) {
      close();
    }
    prevProcessing.current = processing;
  }, [processing, close]);

  const onFileUploaded = ({ contentId, name, type }) => {
    updateGroupCategories({
      contentId,
      name,
      type,
      groupId,
      benefitId,
      planId,
    });
  };

  return (
    <UpdateGroupCategoriesModalPresenter
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      onFileUploaded={onFileUploaded}
      close={close}
    />
  );
};

const mapDispatchToActions = {
  updateGroupCategories: updateGroupCategoriesAction,
};

const mapStateToProps = createStructuredSelector({
  processing: selectUpdatingGroupCategories,
});

export const UpdateGroupCategoriesModal = connect(
  mapStateToProps,
  mapDispatchToActions,
)(UpdateGroupCategoriesModalContainer);

UpdateGroupCategoriesModalContainer.propTypes = {
  close: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  updateGroupCategories: PropTypes.func.isRequired,
  plan_id: PropTypes.string.isRequired,
};

export default UpdateGroupCategoriesModal;
