import { eventChannel } from 'redux-saga';
import { startIdleTimer, cancelIdleTimer } from './idle-timer';

export const idleTimerChannel = ({ maxIdleTime }) => {
  return eventChannel(emitter => {
    startIdleTimer({
      maxIdleTime,
      onTimeout: () => emitter('IDLE_TIMER_EXPIRED'),
    });
    return () => cancelIdleTimer();
  });
};
