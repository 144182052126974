import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { bgImageContain } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import Loader from './loading.svg';
import LoaderAlt from './loading-alt.svg';

const LoaderImage = styled.span`
  background-image: url(${props => props.loading && props.loader});
  display: ${props => (props.loading ? 'inline-block' : 'none')};
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  ${bgImageContain()};
`;

const LoaderButton = ({ children, primary, loading, disabled, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={loading || disabled} primary={primary} {...props}>
      <LoaderImage loader={primary ? Loader : LoaderAlt} loading={loading} />
      {children}
    </Button>
  );
};

LoaderButton.defaultProps = {
  primary: false,
  loading: false,
  children: null,
  disabled: false,
};

LoaderButton.propTypes = {
  /** The loader image is different for a normal vs primary button */
  primary: PropTypes.bool,
  /** Whether the button is locked due to loading */
  loading: PropTypes.bool,
  /** Button will be disabled when it is in loading state */
  disabled: PropTypes.bool,
  /** The content to be displayed inside the button */
  children: PropTypes.node,
};

export { LoaderButton };
