import {
  OptionType,
  isOptions,
  isOptionGroup,
  isGroupedOptions,
} from './SelectTypes';

export const flattenOptions = (options: any) => {
  if (isOptions(options)) return options;
  if (isGroupedOptions(options)) {
    return options.reduce(
      (result: any, option) =>
        isOptionGroup(option)
          ? [...result, ...option.options]
          : [...result, option],
      [],
    );
  }
  return [];
};

export const optionHasValue = (value: any) => (option: OptionType) => {
  if (typeof value === 'undefined') return false;
  return option.value === value;
};
