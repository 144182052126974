import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_EMPLOYEE_USER_PROFILE = createRequestTypes(
  'employee/GET_EMPLOYEE_USER_PROFILE',
);
export const EMPLOYEE_PROFILE_LIFECYCLE = createLifecycleTypes(
  'employee-profile/EMPLOYEE_PROFILE_LIFECYCLE',
);

export const TERMINATE_EMPLOYEE = 'employee/TERMINATE_EMPLOYEE';
export const SUSPEND_EMPLOYEE = 'employee/SUSPEND_EMPLOYEE';
export const REINSTATE_EMPLOYEE = 'employee/REINSTATE_EMPLOYEE';

export const UNSCHEDULE_EMPLOYEES_SUSPENSION = createRequestTypes(
  'employee/UNSCHEDULE_EMPLOYEES_SUSPENSION',
);
export const UNSCHEDULE_EMPLOYEES_TERMINATION = createRequestTypes(
  'employee/UNSCHEDULE_EMPLOYEES_TERMINATION',
);
export const UNSCHEDULE_EMPLOYEES_REINSTATEMENT = createRequestTypes(
  'employee/UNSCHEDULE_EMPLOYEES_REINSTATEMENT',
);

export const DO_TERMINATE_EMPLOYEE = createRequestTypes(
  'service/DO_TERMINATE_EMPLOYEE',
);
export const DO_SUSPEND_EMPLOYEE = createRequestTypes(
  'service/DO_SUSPEND_EMPLOYEE',
);
export const DO_REINSTATE_EMPLOYEE = createRequestTypes(
  'service/DO_REINSTATE_EMPLOYEE',
);

export const SET_USER_ROLE_IN_GROUP = 'employee/SET_USER_ROLE_IN_GROUP';
export const DO_SET_USER_ROLE_IN_GROUP = createRequestTypes(
  'employee/SET_USER_ROLE_IN_GROUP',
);

export const SEND_INVITE_EMAIL = createRequestTypes(
  'employee/SEND_INVITE_EMAIL',
);

export const SHOULD_REFETCH_EMPLOYEE = createRequestTypes(
  'employee/SHOULD_REFETCH_EMPLOYEE',
);
