import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  Overline,
  BodyOne,
  Image,
} from '@leagueplatform/genesis-commons';
import {
  SelectionWrapper,
  SelectionTitle,
  SelectionValue,
  BenefitCost,
} from './selection-wrapper.view';
import checkmark from 'apps/public/assets/checkmark-in-green-circle.svg';
import { CoverageSummaryTooltip } from '../../coverage-summary-tooltip.view';
import { breakpoints, Responsive } from '@leagueplatform/ui-kit';
import { InfoBannerCard } from 'common/info-banner-card/info-banner-card.view';
import { EOITooltip } from 'common/insurance-forms/eoi-tooltip.view';

const CalloutWrapper = ({ children }) => (
  <Box
    backgroundColor="surface.background.secondary"
    borderRadius="small"
    paddingX="oneAndHalf"
    paddingY="one"
    position="relative"
  >
    <Box
      backgroundColor="success.icon"
      width="quarter"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      borderTopLeftRadius="small"
      borderBottomLeftRadius="small"
    />
    {children}
  </Box>
);

CalloutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const ApprovedCoverageTooltip = props => {
  const { formatMessage } = useIntl();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <CoverageSummaryTooltip {...props}>
      <BodyOne>{formatMessage({ id: 'APPROVED_COVERAGE_TOOLTIP' })}</BodyOne>
    </CoverageSummaryTooltip>
  );
};

const EOIBanner = ({ ...props }) => {
  const { formatMessage } = useIntl();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <InfoBannerCard {...props}>
      <BodyOne as="div">
        {formatMessage(
          { id: 'APPROVED_COVERAGE_EOI_BANNER' },
          {
            eoiTooltip: <EOITooltip />,
          },
        )}
      </BodyOne>
    </InfoBannerCard>
  );
};

export const ApprovedCoverage = ({
  formattedApprovedCoverage,
  formattedApprovedCost,
  formattedPeriodType,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <CalloutWrapper>
        <Flex>
          <Image src={checkmark} alt="" size={20} />
          <Overline as="h5" paddingX="half" color="onSurface.text.subdued">
            {formatMessage({ id: 'APPROVED_COVERAGE' })}
          </Overline>

          <Responsive breakpoint={breakpoints.lapAndAbove}>
            <ApprovedCoverageTooltip top left />
          </Responsive>

          <Responsive breakpoint={breakpoints.belowLap}>
            <ApprovedCoverageTooltip asModal />
          </Responsive>
        </Flex>

        <SelectionWrapper marginTop="one">
          <SelectionTitle>{formatMessage({ id: 'COST' })}</SelectionTitle>
          <BenefitCost
            formattedCost={formattedApprovedCost}
            formattedPeriodType={formattedPeriodType}
          />
        </SelectionWrapper>

        <SelectionWrapper>
          <SelectionTitle>{formatMessage({ id: 'COVERAGE' })}</SelectionTitle>
          <SelectionValue>{formattedApprovedCoverage}</SelectionValue>
        </SelectionWrapper>
      </CalloutWrapper>

      <EOIBanner marginTop="one" />
    </>
  );
};

ApprovedCoverage.propTypes = {
  formattedApprovedCoverage: PropTypes.string.isRequired,
  formattedApprovedCost: PropTypes.string.isRequired,
  formattedPeriodType: PropTypes.string.isRequired,
};
