import {
  EMPLOYER_INVOICES_INIT,
  EMPLOYER_INVOICES_LIFECYCLE,
  TOGGLE_INVOICE_STATUS,
  SAVE_INVOICE_STATUS,
  DOWNLOAD_EXTERNAL_INVOICE,
} from './invoices.types';
import {
  INVOICE_INVOICED_STATE,
  INVOICE_PARTIALLY_PAID_STATE,
  INVOICE_INVOICE_PENDING_STATE,
  INVOICE_SETTLED_STATE,
} from 'apps/employer-experience/pages/EmployerDetails/Billing/constants';

export const initialState = null;

const onSaveInvoiceStatusBase = state => {
  const newInvoices = state.map(invoice =>
    invoice.touched && invoice.invoice_state === INVOICE_PARTIALLY_PAID_STATE
      ? {
          ...invoice,
          invoice_state: INVOICE_INVOICE_PENDING_STATE, // pending maps to partially paid status in FE
        }
      : invoice,
  );

  return newInvoices;
};

const onSaveInvoiceStatusSuccess = state => {
  const newInvoices = state.map(invoice =>
    invoice.touched
      ? {
          ...invoice,
          invoice_state:
            invoice.invoice_state === INVOICE_INVOICE_PENDING_STATE
              ? INVOICE_PARTIALLY_PAID_STATE
              : invoice.invoice_state,
          touched: false,
        }
      : invoice,
  );

  return newInvoices;
};

const onToggleInvoiceStatusBase = (state, { payload }) => {
  let nextState;
  switch (payload.state) {
    case INVOICE_INVOICED_STATE:
      nextState = INVOICE_PARTIALLY_PAID_STATE;
      break;
    case INVOICE_PARTIALLY_PAID_STATE:
      nextState = INVOICE_SETTLED_STATE;
      break;
    default:
      nextState = INVOICE_INVOICED_STATE;
      break;
  }

  const newInvoices = state.map(invoice =>
    invoice.invoice_id === payload.invoiceId
      ? {
          ...invoice,
          invoice_state: nextState,
          touched: true,
        }
      : invoice,
  );

  return newInvoices;
};

export const employerInvoicesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case EMPLOYER_INVOICES_INIT.SUCCEEDED:
      return action.payload.invoices;
    case EMPLOYER_INVOICES_LIFECYCLE.EXITED:
      return initialState;
    case TOGGLE_INVOICE_STATUS.BASE:
      return onToggleInvoiceStatusBase(state, action);
    case SAVE_INVOICE_STATUS.BASE:
      return onSaveInvoiceStatusBase(state);
    case SAVE_INVOICE_STATUS.SUCCEEDED:
      return onSaveInvoiceStatusSuccess(state);
    case DOWNLOAD_EXTERNAL_INVOICE.BASE:
      return state?.map(invoice =>
        invoice.invoice_id === action.payload.record_id
          ? {
              ...invoice,
              is_loading: true,
              is_disabled: true,
            }
          : invoice,
      );
    case DOWNLOAD_EXTERNAL_INVOICE.ERRORED:
    case DOWNLOAD_EXTERNAL_INVOICE.SUCCEEDED:
      return state?.map(invoice =>
        invoice.invoice_id === action.payload.record_id
          ? {
              ...invoice,
              is_loading: false,
              is_disabled: false,
            }
          : invoice,
      );
    default:
      return state;
  }
};
