export const TOO_MUCH_MONEY_ALLOCATED = 'too_much_money_allocated';
export const NOT_ENOUGH_MONEY_ALLOCATED = 'not_enough_money_allocated';
export const RAMQ_ACKNOWLEDGEMENT_REQUIRED = 'ramq_acknowledgement_required';
export const USER_PROFILE_INVALID = 'user_profile_invalid';
export const USER_PROFILE_NOT_SAVED = 'user_profile_not_saved';
export const REQUIRED_BENEFIT_NOT_SELECTED = 'required_benefit_not_selected';
export const OPTIONAL_BENEFIT_NOT_SELECTED = 'optional_benefit_not_selected';
export const DEPENDENT_TOO_OLD = 'dependent_too_old';
export const DEPENDENT_FORM_INVALID = 'dependent_form_invalid';
export const DEPENDENT_FORM_NOT_SAVED = 'dependent_form_not_saved';
export const SELECTIONS_NOT_VALID = 'selections_not_valid';
export const NO_SELECTED_BUNDLE = 'no_selected_bundle';
export const OPT_OUT_REASON_REQUIRED = 'opt_out_reason_required';
export const SELECTION_REASON_REQUIRED = 'selection_reason_required';
export const BENEFIT_SELECTION_MISSING = 'benefit_selection_missing';
export const NO_SELECTION_ERROR = 'no_selection_error';
export const INVALID_SELECTION_ERROR = 'invalid_selection_error';

export const invalidSelectionErrorCodes = [
  TOO_MUCH_MONEY_ALLOCATED,
  NOT_ENOUGH_MONEY_ALLOCATED,
  RAMQ_ACKNOWLEDGEMENT_REQUIRED,
];

export const isInvalidSelectionErrorCode = code =>
  invalidSelectionErrorCodes.includes(code);

export const isInvalidSelectionErrorType = type =>
  type === INVALID_SELECTION_ERROR;

export const getSelectionErrorTypeForCode = code =>
  isInvalidSelectionErrorCode(code)
    ? INVALID_SELECTION_ERROR
    : NO_SELECTION_ERROR;

export const augmentError = error => ({
  ...error,
  type: getSelectionErrorTypeForCode(error.code),
});
