import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';

export const BenefitPlanOptions = ({
  toggleRenameBenefitPlan,
  removeBenefitPlan,
  openCopyPlan,
  openRenewPlan,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dropdown
      icon="ellipsis horizontal"
      text=" "
      className="dropdown-benefit-plan-options"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text={formatMessage({ id: 'COPY' })}
          onClick={openCopyPlan}
          data-testid="open-copy-plan"
        />
        <Dropdown.Divider />
        <Dropdown.Item
          text={formatMessage({ id: 'RENEW' })}
          onClick={openRenewPlan}
          data-testid="open-renew-plan"
        />
        <Dropdown.Divider />
        <Dropdown.Item
          text={formatMessage({ id: 'RENAME_BENEFITS_PLAN' })}
          onClick={toggleRenameBenefitPlan}
          data-testid="rename-benefit-plan"
        />
        <Dropdown.Divider />
        <Dropdown.Item
          className="dropdown-benefit-plan-options__negative"
          text={formatMessage({ id: 'REMOVE_BENEFITS_PLAN' })}
          onClick={removeBenefitPlan}
          data-testid="remove-benefit-plan"
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

BenefitPlanOptions.propTypes = {
  openCopyPlan: PropTypes.func.isRequired,
  openRenewPlan: PropTypes.func.isRequired,
  toggleRenameBenefitPlan: PropTypes.func.isRequired,
  removeBenefitPlan: PropTypes.func.isRequired,
};

export default BenefitPlanOptions;
