import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function renewBenefitPlan - this service is used to renew an employer
 * benefit plan and its plan options
 *
 * @param {object} options
 * @param {string} options.groupId the group ID for the currently enrolling group
 * @param {string} options.originaBenefitPlanId the id of the plan being renewed
 * @param {object} options.planPeriod the annual period of the plan
 */
export const renewBenefitPlan = ({
  groupId = null,
  originalBenefitPlanId = null,
  planPeriod = null,
} = {}) =>
  call(websocketFetch, 'renew_benefit_plan', {
    group_id: groupId,
    orig_benefit_plan_id: originalBenefitPlanId,
    plan_period: planPeriod,
  });
