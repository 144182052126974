import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingTwo,
  BodyOne,
  PrimaryButton,
  DangerButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { Modal, ModalButtons } from 'apps/employer-experience/ui-kit';

export const BenefitConfirmationModal = ({
  title,
  description,
  onCancel,
  onConfirm,
  isRemove,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Modal onClose={onCancel}>
        <HeadingTwo>{title}</HeadingTwo>
        <Box marginY="two">
          <BodyOne>{description}</BodyOne>
        </Box>
        <ModalButtons>
          <SecondaryButton onClick={onCancel} marginRight="one">
            {formatMessage({ id: 'CANCEL' })}
          </SecondaryButton>
          {isRemove ? (
            <DangerButton onClick={onConfirm}>
              {formatMessage({ id: 'REMOVE' })}
            </DangerButton>
          ) : (
            <PrimaryButton onClick={onConfirm}>
              {formatMessage({ id: 'CONFIRM' })}
            </PrimaryButton>
          )}
        </ModalButtons>
      </Modal>
    </div>
  );
};

BenefitConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isRemove: PropTypes.bool,
};

BenefitConfirmationModal.defaultProps = {
  isRemove: false,
};
