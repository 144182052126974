import SpendingAccountBalanceReport from './spending-account-balance-report.view';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectSpendingAccountBalanceReportReady,
  selectSpendingAccountBalanceReportError,
  selectSpendingAccountBalanceReportLoading,
} from './spending-account-balance-report.selectors';
import { getSpendingAccountBalanceReport } from './spending-account-balance-report.actions';
import { SPENDING_ACCOUNT_BALANCE_REPORT_LIFECYCLE } from './spending-account-balance-report.types';
import { selectUserEmail } from '../../reports.selectors';

const mapStateToProps = connect(
  createStructuredSelector({
    spendingAccountBalanceReportReady: selectSpendingAccountBalanceReportReady,
    spendingAccountBalanceReportError: selectSpendingAccountBalanceReportError,
    spendingAccountBalanceReportLoading:
      selectSpendingAccountBalanceReportLoading,
    email: selectUserEmail,
  }),
);

const mapDispatchToProps = connect(null, {
  getSpendingAccountBalanceReport,
  visit: SPENDING_ACCOUNT_BALANCE_REPORT_LIFECYCLE.visit,
});

export default compose(
  mapStateToProps,
  mapDispatchToProps,
)(SpendingAccountBalanceReport);
