import { createRequestTypes } from 'common/websocket-redux';

export const ADMIN_GET_EMPLOYEE_SCHEMA = createRequestTypes(
  'employee/ADMIN_GET_EMPLOYEE_SCHEMA',
);

export const ADMIN_GET_EMPLOYEE = createRequestTypes(
  'service/ADMIN_GET_EMPLOYEE',
);

export const EMPLOYEE_ROW_SELECTED = 'employee/EMPLOYEE_ROW_SELECTED';

export const NEW_EMPLOYEE_FORM_SUBMITTED =
  'employees/NEW_EMPLOYEE_FORM_SUBMITTED';

export const EDIT_EMPLOYEE_FORM_SUBMITTED =
  'employees/EDIT_EMPLOYEE_FORM_SUBMITTED';

export const ADMIN_SET_EMPLOYEE = createRequestTypes(
  'service/ADMIN_SET_EMPLOYEE',
);

export const CREATE_EMPLOYEE = createRequestTypes('employee/CREATE_EMPLOYEE');
export const UPDATE_EMPLOYEE = createRequestTypes('employee/UPDATE_EMPLOYEE');

export const GET_EDIT_RESOURCES = createRequestTypes(
  'employee/GET_EDIT_RESOURCES',
);

export const BENEFIT_CLASS_INPUT_CHANGED =
  'employee/BENEFIT_CLASS_INPUT_CHANGED';

export const NEW_EMPLOYEE_VISITED = 'employee/NEW_EMPLOYEE_VISITED';

export const EDIT_EMPLOYEE_VISITED = 'employee/EDIT_EMPLOYEE_VISITED';

export const CLEAR_EMPLOYEE_DETAILS = 'employee/CLEAR_EMPLOYEE_DETAILS';

export const GET_USER_BENEFIT_CONTRIBUTIONS = createRequestTypes(
  'services/GET_USER_BENEFIT_CONTRIBUTIONS',
);

export const UPDATE_EMPLOYEE_CONTRIBUTIONS =
  'services/UPDATE_EMPLOYEE_CONTRIBUTIONS';

export const GET_USER_DEDUCTIONS = createRequestTypes(
  'services/GET_USER_DEDUCTIONS',
);

export const UPDATE_USER_DEDUCTIONS = createRequestTypes(
  'services/UPDATE_USER_DEDUCTIONS',
);

export const GET_USER_ADJUSTMENTS = createRequestTypes(
  'services/GET_USER_ADJUSTMENTS',
);
