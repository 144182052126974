import { createRequestTypes } from 'common/websocket-redux';

/**
 * The user session is being authenticated with Auth0.
 *
 * AUTH0.[BASE | request()] – request authentication via auth0
 * AUTH0.[STARTED | start()] – The auth0 SDK will be configured and attempt to
 *   authenticate the user
 * ATH0.[SUCCEEDED | success()] – A valid user session was loaded by auth0. At
 *   this point we have valid user credentials but no user context. We can
 *   now configure an authenticated WebSocket and fetch context.
 * AUTH0.[ERRORED | error()] – No valid session found: the user should be
 *   redirected to sign-in
 */
export const AUTHENTICATE_SESSION = createRequestTypes(
  'auth0/AUTHENTICATE_SESSION',
);

/**
 * The user is being signed in. This is used in the Auth0 sign-in flow while
 * authenticating a user coming in from auth0's Universal Login
 */
export const AUTHENTICATE_AFTER_SIGN_IN = createRequestTypes(
  'auth0/AUTHENTICATE_AFTER_SIGN_IN',
);
