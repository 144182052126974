import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColourObject } from '@leagueplatform/ui-kit';

const StyledDropdownItems = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  transition: all 200ms ease-out;
  padding: 1.5rem 0;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
  background-color: ${({ theme }) => getColourObject(theme).white};
  filter: drop-shadow(
    0 1px 3px ${({ theme }) => getColourObject(theme).mediumGrey}
  );
  transform: translateY(${props => (props.isOpen ? '1.6rem' : '0.8rem')});

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 8rem;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-bottom: 10px solid ${({ theme }) => getColourObject(theme).white};
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
`;

const DropdownItems = props => {
  const { isOpen, onToggle, className } = props;
  return (
    <StyledDropdownItems
      isOpen={isOpen}
      onClick={onToggle}
      className={className}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </StyledDropdownItems>
  );
};

DropdownItems.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
};

DropdownItems.defaultProps = {
  isOpen: false,
  onToggle: null,
  className: null,
};

export default DropdownItems;
