import { APP_CONFIG } from 'app-config';
import { addMessageType } from 'common/websocket-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { inviteReducer } from './invite.reducer';
import { inviteSaga } from './invite.saga';

export const history = createBrowserHistory();
const router = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

function getMiddlewares() {
  const middlewares = [sagaMiddleware, router];
  if (APP_CONFIG.REACT_APP_REDUX_LOG === 'enabled') {
    const logger = createLogger({
      actionTransformer: addMessageType,
      collapsed: true,
      duration: true,
    });
    return compose(applyMiddleware(logger, ...middlewares));
  }
  return applyMiddleware(...middlewares);
}

export const store = createStore(inviteReducer, getMiddlewares());

sagaMiddleware.run(inviteSaga);
