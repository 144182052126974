import { BackgroundImage } from '@leagueplatform/genesis-commons';
import { styled, Box } from '@leagueplatform/genesis-core';
import React from 'react';
import PropTypes from 'prop-types';
import PDFIcon from 'apps/public/assets/pdf-icon.svg';
import { useIntl } from '@leagueplatform/locales';
import { getContentUrl } from '@leagueplatform/league-content-api';

const DocumentContainer = styled(Box, {
  display: 'flex',
  flex: '0 0 32%',
  height: '25rem',
  '@mobile': {
    flex: '0 1 auto',
    width: '25rem',
  },
});

const DocumentThumbnail = props => (
  <BackgroundImage
    as="button"
    borderRadius="small"
    backgroundColor="surface.background.secondary"
    backgroundSize="cover"
    border="none"
    cursor="pointer"
    focusStyle={{
      outline: 0,
      boxShadow: 'focusRing',
    }}
    height="100%"
    overflow="hidden"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const DocumentList = ({ documents, onToggleDocumentSelected }) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      as="ul"
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '$two',
        marginBottom: '$threeQuarters',
      }}
      role="list"
    >
      {documents.map(doc => (
        <DocumentContainer
          as="li"
          key={`claim-document-thumbnail-${doc.content_id}`}
        >
          {doc.content_type.includes('pdf') ? (
            <DocumentThumbnail
              aria-label={formatMessage(
                { id: 'OPEN_DOCUMENT_INSTRUCTION_HIDDEN' },
                { documentType: doc.content_type },
              )}
              as="a"
              backgroundSize="5rem"
              href={getContentUrl(doc.content_id)}
              rel="noopener noreferrer"
              src={PDFIcon}
              target="_blank"
            />
          ) : (
            <DocumentThumbnail
              aria-label={formatMessage(
                { id: 'CLAIM_DETAIL_EXPAND_DOCUMENT_HIDDEN' },
                { documentType: doc.content_type },
              )}
              onClick={() =>
                onToggleDocumentSelected(getContentUrl(doc.content_id))
              }
              src={getContentUrl(doc.content_id)}
            />
          )}
        </DocumentContainer>
      ))}
    </Box>
  );
};

DocumentList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      content_id: PropTypes.string,
      content_type: PropTypes.string,
    }),
  ).isRequired,
  onToggleDocumentSelected: PropTypes.func.isRequired,
};
