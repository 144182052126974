import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getColourObject } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { noop } from 'lodash';

const StyledButton = styled(Button)`
  flex-direction: row;
  background-color: ${({ inline, theme }) =>
    inline
      ? getColourObject(theme).white
      : getColourObject(theme).greyLightest};
  border-color: ${({ inline, theme }) =>
    inline
      ? getColourObject(theme).white
      : getColourObject(theme).greyLightest};
  color: ${({ inline, theme }) =>
    inline ? getColourObject(theme).purple : getColourObject(theme).darkGrey};

  min-width: 16rem;

  &:hover:not([disabled]) {
    border-color: ${({ inline, theme }) =>
      inline
        ? getColourObject(theme).white
        : getColourObject(theme).greyLighter};
    background-color: ${({ inline, theme }) =>
      inline
        ? getColourObject(theme).white
        : getColourObject(theme).greyLighter};
    color: ${({ inline, theme }) =>
      inline
        ? getColourObject(theme).purpleDark
        : getColourObject(theme).darkGrey};
  }
`;

const DropdownTrigger = props => {
  const { onClick, onToggle, children, inline, className } = props;
  return (
    <StyledButton
      onClick={() => {
        onClick();
        onToggle();
      }}
      inline={inline}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

DropdownTrigger.propTypes = {
  onToggle: PropTypes.func,
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

DropdownTrigger.defaultProps = {
  onToggle: null,
  inline: false,
  className: null,
  onClick: noop,
};

export default DropdownTrigger;
