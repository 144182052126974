import React from 'react';
import { compact, concat, get, last, map, isNull, merge } from 'lodash';
import { pipe, sortBy, filter, map as mapFP } from 'lodash/fp';
import camelize from 'camelize';
import { createSchemaParser } from '../parser';
import { explodeObject } from '../utilities';
import { SUBMIT_ATTEMPTED } from '../constants';
import {
  Text,
  Number,
  Date,
  Checkbox,
  Select,
  Form,
  Forms,
  Hidden,
  Submit,
  ValidFormField,
  MapObject,
  Masked,
} from './components';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import generateGroupedForm from './grouped-form';

export const SUBMIT_PATH = 'submit';

export const submitField = {
  inputType: 'submit',
  fieldName: SUBMIT_PATH,
  path: SUBMIT_PATH,
};

const defaultComponents = {
  text: Text,
  select: Select,
  date: Date,
  checkbox: Checkbox,
  forms: Forms,
  form: Form,
  hidden: Hidden,
  submit: Submit,
  mask: Masked,
};

const jsonTypeComponents = {
  number: Number,
};

const goTypeComponents = {
  map: MapObject,
};

export const createComponentAssociator = customComponents => fieldSpec => {
  const goType = get(fieldSpec, 'goType', '').includes('map') ? 'map' : '';
  const component = last(
    compact([
      Text,
      defaultComponents[fieldSpec.inputType],
      jsonTypeComponents[fieldSpec.jsonType],
      goTypeComponents[goType],
      customComponents[fieldSpec.path],
    ]),
  );
  return { ...fieldSpec, component };
};

/*
  Note: need to treat map object value differently since the value is flattened.
  The value will first be unflatten using explodeObject, and pass it on to MapObject component.

  i.e

  unflatten/exploded values prop (what should be passed onto MapObject component)
  values: {
    key1: { valA: 'test', valB: 'test1' }
  }

  flatten values prop
  values: {
    key1.valA: 'test',
    key2.valB: 'test1'
  }
*/

export const getMapObjectValue = (props, path) => {
  const value = get(explodeObject(get(props, 'values'), Object), path, {}); // if undefined then default to {}
  return isNull(value) ? {} : value; // if null, then default to {}
};

export const getValueFromFieldSpec = (props, { goType, path }) =>
  goType && goType.includes('map')
    ? getMapObjectValue(props, path)
    : get(props, ['values', path]);

export const createElementAssociator = props => fieldSpec => {
  // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
  const InputComponent = fieldSpec.component;
  const id = props?.values?.id || '';
  const fieldID = `${id}${fieldSpec.path}`;

  const inputProps = {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    id: fieldID,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    name: fieldSpec.path,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    label: fieldSpec.label,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    tooltip: fieldSpec.tooltip,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    placeholder: fieldSpec.placeholder,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    required: fieldSpec.required,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    options: fieldSpec.options,
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    readOnly: Boolean(fieldSpec.readOnly),
    value: getValueFromFieldSpec(props, fieldSpec),
    ...props,
  };
  // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
  const fieldErrors = get(props, ['errors', fieldSpec.path]);
  // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
  const fieldTouched = get(props, ['touched', fieldSpec.path]);
  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
  const submitAttempted = props.status === SUBMIT_ATTEMPTED;
  const element = (
    <ValidFormField
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      key={fieldID}
      errors={fieldErrors}
      touched={fieldTouched}
      submitAttempted={submitAttempted}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <InputComponent {...inputProps} />
    </ValidFormField>
  );
  return { ...fieldSpec, element };
};

export const createFormGenerator = ({
  schema,
  layout = {},
  customComponents = {},
  labelsToBeOmitted = [],
}) => {
  const parseSchema = createSchemaParser(labelsToBeOmitted);
  const fieldSpecs = pipe(parseSchema, sortBy('position'), camelize)(schema);
  const withSubmit = concat(fieldSpecs, submitField);
  const assignComponent = createComponentAssociator(customComponents);
  const withComponents = map(withSubmit, assignComponent);
  return props => {
    const assignElement = createElementAssociator(props);
    const withElements = map(withComponents, assignElement);
    return generateGroupedForm(withElements, props, layout);
  };
};

const filterFields = layout => schema =>
  pipe(
    // Left Join between layout (FE) x schema (BE)
    mapFP(layoutField =>
      merge(
        schema.find(schemaField => schemaField.path === layoutField.name) || {},
        layoutField,
      ),
    ),
    // Filter out fields that aren't present in the schema (BE)
    filter(field => !!field.field_name),
  )(layout.fields);

const addValues = values => fields =>
  fields.map(field => ({
    ...field,
    value: get(values, field.path),
  }));

export const createViewGeneratorV2 = ({ schema, layout, values }) => {
  const parseSchema = createSchemaParser([]);
  const fieldSpecs = pipe(
    parseSchema,
    filterFields(layout),
    addValues(values),
  )(schema);

  return fieldSpecs;
};
