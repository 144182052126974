/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { takeLatest, fork, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import {
  GET_QLE_SCHEMA,
  REQUEST_QLE_SCHEMA,
  SUBMIT_QLE,
  REQUEST_SET_QLE,
  SEND_QLE_SCREENER_ANALYTICS,
} from './qle-screener.action-types';
import {
  setQleRequest,
  getQleSchema,
  setQleEnrollment,
  getQleRequest,
} from 'common/services';
import { request } from 'common/websocket-redux';
import {
  goHomeAndShowGenericErrorMessage,
  handleQLERequestError,
} from '../QleRequest/qle-request.sagas';
import {
  SERVICE_GET_QLE_REQUEST,
  REQUEST_SET_QLE_ENROLLMENT,
} from '../QleRequest/qle-request.action-types';
import { push } from 'connected-react-router';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestQleSchema() {
  yield request(REQUEST_QLE_SCHEMA, getQleSchema());
}

export function* reportAnalytics({ payload }) {
  const { category, action, label } = payload.params;
  yield call(sendAnalyticsEvent, {
    category,
    action,
    label,
  });
}

// finish qle screener -> check mod flex -> handle NEX
export function* handleNex({ qleRequestId }) {
  try {
    const setQLEEnrollmentResponse = yield request(
      REQUEST_SET_QLE_ENROLLMENT,
      setQleEnrollment({ qleRequestId }),
    );

    if (!setQLEEnrollmentResponse.error) {
      const getQLERequestResponse = yield request(
        SERVICE_GET_QLE_REQUEST,
        getQleRequest({
          qleId: qleRequestId,
        }),
      );
      const allowNex = get(getQLERequestResponse, 'info.allow_nex', false);

      if (getQLERequestResponse.error) {
        const errorCode = get(getQLERequestResponse, 'payload.info.code');

        yield handleQLERequestError(errorCode);
      } else if (allowNex) {
        yield put(push('/enroll'));
      } else {
        yield goHomeAndShowGenericErrorMessage();
      }
    } else {
      yield goHomeAndShowGenericErrorMessage();
    }
  } catch (error) {
    yield goHomeAndShowGenericErrorMessage();
  }
}

/**
 * @function requestSetQle - Send member's QLE information. If successful, redirect to home page, update browser url, and display success toast message.
 * If error, display error toast message.
 *
 * After submission do one of the following:
 * If CA member and is open enrollment, redirect to `/app/enroll`
 * If CA member and after open enrollment, redirect to home page
 * If US member, redirect to home page.
 *
 * @param {object} action: contains selected qualifying life event `qualifyingLifeEventType` and all provided information in `questionnaireAnswers`
 * Also contains `isFirstTimeEnrollment` to be used to decide what to do after submission.
 */
export function* requestSetQle(action) {
  const {
    qualifyingLifeEventType,
    questionnaireAnswers,
    groupId,
    modularFlexEnrolment,
    isHrApprovalRequired,
  } = action.payload;

  const response = yield request(
    REQUEST_SET_QLE,
    setQleRequest({ qualifyingLifeEventType, questionnaireAnswers, groupId }),
  );

  if (!response.error) {
    reportAnalytics({
      payload: {
        params: {
          category: 'QLE Screener',
          action: 'QLE - Confirm',
        },
      },
    });
    if (isHrApprovalRequired) {
      yield call(toastActions.add, {
        type: TOAST_STATUS.SUCCESS,
        textId: 'INFORMATION_HAS_BEEN_SENT_FOR_HR_APPROVAL',
      });
      yield put(push('/member/home'));
    } else if (modularFlexEnrolment) {
      yield fork(handleNex, {
        qleRequestId: response.info.qualifying_life_event_id,
      });
    } else {
      const { qualifying_life_event_id } = response.info;
      yield call(toastActions.add, {
        type: TOAST_STATUS.SUCCESS,
        textId: 'INFORMATION_SUCCESSFULLY_SENT',
      });
      yield put(push(`/member/qle-request/${qualifying_life_event_id}`));
    }
  }
}

export function* requestSetQleErrored() {
  reportAnalytics({
    payload: {
      params: {
        category: 'QLE Screener',
        action: 'QLE - Decline',
      },
    },
  });
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'MISSING_QLE_DATA',
  });
}

export function* qleScreenerSagas() {
  yield fork(takeLatest, GET_QLE_SCHEMA, requestQleSchema);
  yield fork(takeLatest, SUBMIT_QLE, requestSetQle);
  yield fork(takeLatest, REQUEST_SET_QLE.ERRORED, requestSetQleErrored);
  yield fork(takeLatest, SEND_QLE_SCREENER_ANALYTICS, reportAnalytics);
}
