import React from 'react';
import { Spinner, Flex } from '@leagueplatform/genesis-commons';
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  DroppableList,
  DroppableListContainer,
} from '../enrollment-experience-config.styles';
import DraggableBenefitStep from '../draggable-benefit-step/draggable-benefit-step.view';
import { STEPS_LIST } from '../enrollment-experience-config.constants';
import {
  BenefitStep,
  EnrollmentStepItemType,
} from '../enrollment-design-options.types';

interface EnrollmentStepsListProps {
  stepsList: BenefitStep[];
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  activeEditingItemId?: string;
  setActiveEditingItem: (item: EnrollmentStepItemType) => void;
  refreshStepList: boolean;
}

const EnrollmentStepsListPresenter = ({
  stepsList,
  setActiveEditingItem,
  activeEditingItemId,
  refreshStepList,
}: EnrollmentStepsListProps) => {
  return (
    <DroppableListContainer title="ENROLLMENT_DESIGN_STEPS">
      <Droppable droppableId={STEPS_LIST} key={STEPS_LIST} type="step">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <DroppableList
            isDraggingOver={snapshot.isDraggingOver}
            backgroundColor={
              snapshot.isDraggingOver
                ? 'surface.background.secondary'
                : 'surface.background.primary'
            }
            innerRef={provided.innerRef}
            data-testid={STEPS_LIST}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...provided.droppableProps}
          >
            {/* TODO: ON-7523 Populate list */}
            {/* Steps droppable components must have a type of "steps" to be droppable */}
            {refreshStepList ? (
              <Flex
                position="relative"
                height="100px"
                justifyContent="center"
                alignItems="center"
              >
                <Spinner isLoading />
              </Flex>
            ) : (
              stepsList.map((step: any, index: number) => (
                <DraggableBenefitStep
                  activeEditingItemId={activeEditingItemId}
                  openEditingPanel={setActiveEditingItem}
                  key={step.id}
                  step={step}
                  index={index}
                />
              ))
            )}

            {provided.placeholder}
          </DroppableList>
        )}
      </Droppable>
    </DroppableListContainer>
  );
};

export default EnrollmentStepsListPresenter;
