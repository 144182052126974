import { useState, useEffect, useCallback } from 'react';

export const getLabels = benefits => {
  const benefitValueYear = [];
  const benefitValueVendor = [];
  const benefitValueName = [];
  const className = [];
  benefits.map(benefit => {
    if (benefit.initialYear) {
      benefitValueYear.push({
        label: benefit.initialYear,
        disabled: benefit.disabled,
      });
    }
    if (benefit.benefitVendorName) {
      benefitValueVendor.push({
        label: benefit.benefitVendorName,
        disabled: benefit.disabled,
      });
    }
    if (benefit.benefitName) {
      benefitValueName.push({
        label: benefit.benefitName,
        disabled: benefit.disabled,
      });
    }
    if (benefit.className) {
      className.push({
        label: benefit.className,
        disabled: benefit.disabled,
      });
    }
    return {
      benefitValueName,
      benefitValueVendor,
      benefitValueYear,
      className,
    };
  });

  const createLabelArr = benefitValue =>
    [...new Map(benefitValue.map(item => [item.label, item])).values()].sort();

  const benefitYear = createLabelArr(benefitValueYear);
  const benefitVendor = createLabelArr(benefitValueVendor);
  const benefitName = createLabelArr(benefitValueName);
  const classBenefitName = createLabelArr(className);

  return [
    { label: 'initialYear', options: benefitYear, name: 'Initial Year' },
    {
      label: 'benefitVendorName',
      options: benefitVendor,
      name: 'Benefit Vendor Name',
    },
    { label: 'benefitName', options: benefitName, name: 'Benefit Name' },
    { label: 'className', options: classBenefitName, name: 'Class Name' },
  ];
};

export const useExportCustomPlanFilterHook = (onChange, data, inputRef) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState({});
  const [search, setSearch] = useState('');

  const checkUncheckLabel = (selection, benefit) =>
    selection[benefit.label]?.includes(benefit.item);

  const getSelected = () =>
    Object.values(selectedLabels).filter(label => label.length > 0 && label);

  const enableDisableLabels = () => {
    const allLabels = getLabels(data).map(label => {
      const newOptions = label.options.map(option => {
        let disabled = false;

        const filteredData = data.filter(
          item => item[label.label] === option.label,
        );

        const checkMatches = filteredData.filter(item => {
          let count = 0;
          Object.keys(selectedLabels).map(key => {
            if (selectedLabels[key]?.includes(item[key])) count += 1;
            return key;
          });

          const selectionKeys = Object.values(selectedLabels).filter(
            value => value.length > 0,
          );

          return count === selectionKeys.length;
        });

        if (checkMatches.length <= 0) disabled = true;

        return { ...option, disabled };
      });
      return { ...label, options: newOptions };
    });

    return allLabels;
  };

  const selectItem = benefit => {
    const selection = { ...selectedLabels };
    if (selection[benefit.label]?.includes(benefit.item)) {
      selection[benefit.label] = selection[benefit.label].filter(
        label => label !== benefit.item,
      );
    } else {
      selection[benefit.label] = [
        ...(selection[benefit.label] || []),
        benefit.item,
      ];
    }

    setSelectedLabels(selection);
    onChange(selection);
  };

  const closeDropdown = useCallback(
    e => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsFilterOpen(false);
      }
    },
    [inputRef],
  );

  const labels = enableDisableLabels();

  const filteredLabels = labels?.map(title =>
    title.options.filter(option =>
      option.label.toLowerCase().includes(search.toLowerCase()),
    ),
  );

  useEffect(() => {
    document.addEventListener('click', closeDropdown, false);
    return () => {
      document.removeEventListener('click', closeDropdown, false);
    };
  }, [closeDropdown]);

  return {
    checkUncheckLabel,
    getSelected,
    selectedLabels,
    selectItem,
    setSelectedLabels,
    setIsFilterOpen,
    isFilterOpen,
    setSearch,
    filteredLabels,
    search,
    labels,
  };
};
