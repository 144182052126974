import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

export const CLAIM_UPDATE_BUTTON = {
  approved: 'Approve Claim',
  failed: 'Fail',
  more_information_needed: 'Request More Information',
  pending_external_review: 'Send for External Review',
  rejected: 'Reject Claim',
  submitted: 'Reset Claim',
  update: 'Update Claim',
};

export const SubmitButton = ({ loading, updateType, disabled }) => (
  <Button
    primary
    disabled={loading || disabled}
    className={`update-status-btn label-${updateType}`}
    loading={loading}
  >
    {CLAIM_UPDATE_BUTTON[updateType]}
  </Button>
);

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  updateType: PropTypes.string.isRequired,
};

SubmitButton.defaultProps = {
  loading: false,
  disabled: false,
};
