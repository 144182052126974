import React from 'react';
import PropTypes from 'prop-types';
import {
  css,
  Box,
  genesisStyled,
  Flex,
  Label,
  LabelText,
  BodyOne,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { DATE_FORMAT } from 'common/adaptive-forms';
import { Formik } from 'formik';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const formatDate = date => {
  const newDate = new Date(date.toDate());
  newDate.setUTCHours(0);
  newDate.setUTCMinutes(0);
  newDate.setUTCSeconds(0);
  return newDate.toISOString();
};

const StyledDateInput = genesisStyled(StyledDayPickerInput)(
  css({
    color: 'onSurface.text.primary',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'onSurface.border.default',
    fontFamily: 'GenesisMarkOffcPro',
    borderRadius: 'small',
    padding: 'one',
    width: '100%',
    lineHeight: 'body2',
    marginTop: 'half',
    '&::selection': {
      backgroundColor: 'surface.background.primary',
    },
  }),
);

const initialValues = {
  start_date: '',
  end_date: '',
  is_usage_summary: false,
};

const FormComponent = ({
  setFieldValue,
  detailedUsageIsLoading,
  usageSummaryIsLoading,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Flex marginTop="one">
        <Label flexGrow={1} marginRight="half">
          <LabelText>{formatMessage({ id: 'PROGRAM_START_DATE' })}</LabelText>
          <StyledDateInput
            onDayChange={value =>
              setFieldValue('start_date', formatDate(value))
            }
            readOnly
            format={DATE_FORMAT}
            placeholder={DATE_FORMAT}
          />
        </Label>
        <Label flexGrow={1} marginLeft="half">
          <LabelText>{formatMessage({ id: 'PROGRAM_END_DATE' })}</LabelText>
          <StyledDateInput
            onDayChange={value => setFieldValue('end_date', formatDate(value))}
            readOnly
            format={DATE_FORMAT}
            placeholder={DATE_FORMAT}
          />
        </Label>
      </Flex>

      <BodyOne marginY="one">
        {formatMessage(
          { id: 'NOTE_REPORTS_LOADING_COPY' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: text => <strong>{text}</strong> },
        )}
      </BodyOne>
      <Flex>
        <PrimaryButton
          isLoading={detailedUsageIsLoading}
          onClick={() => {
            setFieldValue('is_usage_summary', false);
            handleSubmit();
          }}
        >
          {formatMessage({ id: 'DOWNLOAD_DETAILED_USAGE_REPORT' })}
        </PrimaryButton>
        <PrimaryButton
          marginLeft="one"
          isLoading={usageSummaryIsLoading}
          onClick={() => {
            setFieldValue('is_usage_summary', true);
            handleSubmit();
          }}
        >
          {formatMessage({ id: 'DOWNLOAD_USAGE_SUMMARY_REPORT' })}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};
FormComponent.propTypes = {
  detailedUsageIsLoading: PropTypes.bool.isRequired,
  usageSummaryIsLoading: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const GenerateCSVForm = ({ getUsageReport, groupId, ...props }) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values =>
        getUsageReport({ ...values, group_id: groupId, isInHRAdminPortal })
      }
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      {formProps => <FormComponent {...props} {...formProps} />}
    </Formik>
  );
};
GenerateCSVForm.propTypes = {
  getUsageReport: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};
