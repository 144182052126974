import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react';

export class WithConfirm extends Component {
  // eslint-disable-next-line react/state-in-constructor -- FIXME: automatically added for existing issue
  state = { open: false, args: [], content: undefined };

  confirm = (...args) => {
    this.setState({ open: true, args });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.props.generateMessage) {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      const content = this.props.generateMessage(...args);
      this.setState({ content });
    }
  };

  reset = () => {
    this.setState({ open: false, content: undefined, args: [] });
  };

  handleConfirm = () => {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.onConfirm(...this.state.args);
    this.reset();
  };

  render() {
    return (
      <>
        {/* eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue */}
        {this.props.render(this.confirm)}
        <Confirm
          size="mini"
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          confirmButton={this.props.confirmButton}
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          content={this.state.content}
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          open={this.state.open}
          onCancel={this.reset}
          onConfirm={this.handleConfirm}
        />
      </>
    );
  }
}

WithConfirm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  confirmButton: PropTypes.string,
  generateMessage: PropTypes.func,
  render: PropTypes.func.isRequired,
};

WithConfirm.defaultProps = {
  confirmButton: undefined,
  generateMessage: undefined,
};
