import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UNICODE_DATE_FORMAT } from '@leagueplatform/web-common';
import { format } from 'date-fns';
import ActionsConfirmationEOBDateForm from './actions-confirmation-eob-date-form.view';

export const ActionsConfirmationEOBDateFormContainer = ({
  setActionEOBDate,
  displayInline,
}) => {
  const [eobDate, setEOBDate] = useState(null);

  const onDateChange = date => {
    setActionEOBDate(date ? date.toISOString() : date); // update parent prop actionEOBDate
    setEOBDate(date ? format(new Date(date), UNICODE_DATE_FORMAT) : date);
  };

  return (
    <ActionsConfirmationEOBDateForm
      eobDateValue={eobDate}
      onDateChange={onDateChange}
      displayInline={displayInline}
    />
  );
};

ActionsConfirmationEOBDateFormContainer.propTypes = {
  setActionEOBDate: PropTypes.func.isRequired,
  displayInline: PropTypes.bool,
};

ActionsConfirmationEOBDateFormContainer.defaultProps = {
  displayInline: false,
};
