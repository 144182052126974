import {
  sendAnalyticsEvent,
  sendAnalyticsPageView,
} from '@leagueplatform/analytics';
import { get } from 'lodash';

const EVENT_ENTITY_ID = 'will_call_events';
const INVITE_ACCESS = 'Invite Access';

export function reportPageVisit(action) {
  const getScreenName = () => {
    const { pathname } = window.location;
    if (pathname.includes('eligibility')) {
      return 'Invite Access - Eligibility Screen';
    }
    if (pathname.includes('lookup')) {
      return 'Invite Access - Verify Account';
    }
    if (pathname.includes('verify')) {
      if (pathname.includes('expired')) {
        return 'Invite Access - Invite Verification Expired';
      }
      if (pathname.includes('not-verified')) {
        return 'Invite Access - Invite Not Verified';
      }
      return 'Invite Access - Verify Invite';
    }
    return 'Invite Access - Other';
  };

  const groupIdsString = (
    action.payload?.info?.group_pre_enrollment_config?.group_ids ?? []
  ).join(','); // will call
  // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
  const { invitationId: invitationCode } = action?.meta; // invite verification

  return sendAnalyticsPageView(
    {
      category: INVITE_ACCESS,
      action: 'Visit',
      screen_name: getScreenName(),
      ...(invitationCode
        ? { invitation_code: invitationCode }
        : { group_ids: groupIdsString }),
    },
    EVENT_ENTITY_ID,
  );
}

export function reportIdentifierHintToggle(action) {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: get(action, 'payload.visible')
        ? 'View Unique Identifier Hint'
        : 'Hide Unique Identifier Hint',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportEligibilityTooltipOpened() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Open Eligibility Tooltip',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportEligibilityValue(isEligible) {
  let action;

  if (isEligible === null) {
    action = 'Error - No Eligibility Option Selected';
  }

  if (isEligible === true) {
    action = 'Yes I Am Eligible';
  }

  if (isEligible === false) {
    action = 'No I Am Not Eligible';
  }
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action,
    },
    EVENT_ENTITY_ID,
  );
}

export function reportVerifyAccount() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Verify Account',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportVerifyInvite(invitationCode) {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Verify Invite',
      invitation_code: invitationCode,
    },
    EVENT_ENTITY_ID,
  );
}

export function reportAccountFieldErrors(action) {
  const invitationCode = action.meta?.invitationId;
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Account Form Field Errors',
      label: action.payload,
      ...(invitationCode && { invitation_code: invitationCode }),
    },
    EVENT_ENTITY_ID,
  );
}

export function reportAccountFound() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Account Found',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportInviteVerified(invitationCode) {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Invite Verified',
      invitation_code: invitationCode,
    },
    EVENT_ENTITY_ID,
  );
}

export function reportAccountNotFoundError() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Account Not Found',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportInviteNotVerifiedError(invitationCode) {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Invite Not Verified',
      invitation_code: invitationCode,
    },
    EVENT_ENTITY_ID,
  );
}

export function reportOutOfEnrollmentError() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Attempt to verify outside enrollment period',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportSignInClick() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Sign In',
    },
    EVENT_ENTITY_ID,
  );
}

export function reportHelpEmailClick() {
  return sendAnalyticsEvent(
    {
      category: INVITE_ACCESS,
      action: 'Help Email Click',
    },
    EVENT_ENTITY_ID,
  );
}
