import { call, takeLatest, fork, select, put, all } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  EMPLOYER_BENEFITS_LIFECYCLE,
  EMPLOYER_BENEFITS_INIT,
} from './benefits.types';
import { GET_BENEFIT_TYPE_SCHEMAS } from '../benefits/benefits.types';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';

export function* getBenefitTypeSchemas(groupId) {
  yield request(GET_BENEFIT_TYPE_SCHEMAS, [
    websocketFetch,
    'get_benefit_type_schemas',
    {
      group_id: groupId,
    },
  ]);
}

export function* initialize() {
  try {
    yield put({ type: EMPLOYER_BENEFITS_INIT.STARTED });
    const groupId = yield select(selectCurrentUserGroupId);
    const [
      groupResponse,
      plansResponse,
      benefitsResponse,
      benefitDocumentsResponse,
    ] = yield all([
      call(websocketFetch, 'get_group', { group_id: groupId }),
      call(websocketFetch, 'get_employer_benefit_plans', { group_id: groupId }),
      call(websocketFetch, 'get_employer_benefits', { group_id: groupId }),
      call(websocketFetch, 'get_benefit_documents', { group_id: groupId }),
      call(getBenefitTypeSchemas, groupId),
    ]);

    yield put({
      type: EMPLOYER_BENEFITS_INIT.SUCCEEDED,
      payload: {
        group: groupResponse.info,
        plans: plansResponse.info.plans,
        benefits: benefitsResponse.info.benefits,
        benefitDocuments: benefitDocumentsResponse.info.documents,
      },
    });
  } catch (error) {
    yield put({ type: EMPLOYER_BENEFITS_INIT.ERRORED, error });
  }
}

export function* employerBenefitsSagas() {
  yield fork(takeLatest, EMPLOYER_BENEFITS_LIFECYCLE.VISITED, initialize);
}
