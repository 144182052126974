import { createRequestTypes } from 'common/websocket-redux';

export const DEPENDENT_PROFILE_FORM_SUBMITTED =
  'dex-profile/DEPENDENT_PROFILE_FORM_SUBMITTED';

export const SET_USER_PROFILE = createRequestTypes(
  'dex-profile/SET_USER_PROFILE',
);
export function dependentProfileFormSubmitted(values) {
  return {
    type: DEPENDENT_PROFILE_FORM_SUBMITTED,
    payload: values,
  };
}
