import { fork, put, takeLatest, call, select } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  GET_USER_DOCUMENTS,
  REQUEST_USER_DOCUMENTS,
  EDIT_DOCUMENT_FORM_SUBMITTED,
  REQUEST_SET_USER_DOCUMENT,
  USER_DOCUMENT_REMOVE_CONFIRMED,
  REQUEST_REMOVE_USER_DOCUMENT,
  UPLOAD_DOCUMENT_FORM_SUBMITTED,
  PIN_CLAIM_DOCUMENT,
  REQUEST_PIN_CLAIM_DOCUMENT,
  REQUEST_SET_CLAIM_DOCUMENTS,
  ADD_DOCUMENT_TO_CLAIM,
  REMOVE_DOCUMENT_FROM_CLAIM,
  REQUEST_UPLOAD_USER_DOCUMENT,
  REQUEST_SUBMIT_TO_STRIPE,
  GET_USER_FORM_LINK,
  GENERATE_USER_FORM_CONTENT_ID,
} from './user-documents.types';
import {
  setUserDocument,
  getUserDocuments,
  setClaimDocuments,
  removeUserDocument,
  submitToStripe,
} from 'common/services';
import { selectUser } from 'common/state/user/user.selectors';
import { userDocumentsAnalyticsSaga } from './user-documents-analytics.saga';
import { upload } from 'apps/upload/upload.saga';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

/**
 * Triggered when a member downloads a form via the Forms tab in member side's Docs and Forms.
 */
export function* requestFormLinks(action) {
  const { formType, benefitId } = action.payload;

  yield put(GET_USER_FORM_LINK.start());
  const user = yield select(selectUser);
  /*
    In the userDocumentsSaga, requestUserDocuments has an action type
    but not a userId, in this case, grab the userId from the redux state
  */
  const userId = action.userId || user.userId;
  try {
    const response = yield call(websocketFetch, 'get_benefit_form', {
      user_id: userId,
      form_type: formType,
      benefit_id: benefitId,
    });
    // Since we have to generate a new content id for forms each time we want to download them, there is no need to save them to store.
    // Thus render them in a new tab here.
    window.open(
      getContentUrl(response.info.content_id),
      '_blank',
      'noopener=true,noreferrer=true',
    );
    yield put(GET_USER_FORM_LINK.success(response, { benefit_id: benefitId }));
  } catch (error) {
    yield put(GET_USER_FORM_LINK.error(error));
  }
}

export function* requestUserDocuments(action) {
  yield request(REQUEST_USER_DOCUMENTS, getUserDocuments(action));
}

export function* requestSubmitToStripe(data) {
  try {
    yield submitToStripe({ ...data.payload });

    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'STRIPE_SUCCESS',
    });
  } catch (err) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'STRIPE_ERROR',
    });
  }
}

/**
 * 1. Takes a mixed list of existing and new files
 * 2. Uploads only the new files
 * 3. Returns a list of content IDs.
 * @param {Object[]} files - the mixed list of existing files (with `contentId` key) and new files (with a `file` key)
 * @param {string} [files[].contentId] - indicates an existing file
 * @param {File} [files[].file] - indicates a new file
 * @returns {String[]} a list of the new contentIds merged with the existing ones.
 */
export function* uploadNewFiles(files) {
  // Retrieve newly added files
  const newFiles = files.filter(currentFile => currentFile.name);

  // Retrieve existing content IDs
  const existingContentIds = files
    .filter(currentFile => currentFile.contentId)
    .map(currentFile => currentFile.contentId);

  REQUEST_UPLOAD_USER_DOCUMENT.start();
  const newContentIds = yield call(upload, newFiles);
  const contentIds = [...newContentIds, ...existingContentIds];
  REQUEST_UPLOAD_USER_DOCUMENT.success();

  return contentIds;
}

export function* requestUploadUserDocument(action) {
  const {
    userId,
    files,
    onUploadError,
    documentType,
    documentTypeOtherDescription,
    name,
    expirationDate,
    notes,
  } = action.payload;

  try {
    const contentIds = yield call(uploadNewFiles, files);

    yield put(REQUEST_SET_USER_DOCUMENT.start());
    yield setUserDocument({
      version: 2,
      content_ids: contentIds,
      user_id: userId,
      document_type: documentType,
      document_type_other_description: documentTypeOtherDescription,
      name,
      expiration_date: expirationDate,
      notes,
    });
    yield put(
      REQUEST_SET_USER_DOCUMENT.success(
        {
          documentType,
          documentTypeOtherDescription,
        },
        { upload: true },
      ),
    );

    // Call refresh of doc list to see newly added doc
    yield requestUserDocuments(userId);
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'SUPPORTING_DOC_ADDED',
    });
  } catch (error) {
    if (onUploadError) onUploadError(error);
    yield put(REQUEST_UPLOAD_USER_DOCUMENT.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_UPLOADING_DOC',
    });
  }
}

export function* requestRemoveUserDocument({
  userId,
  contentId,
  userDocumentId,
  isMember, // flag to control page refresh depending on context of deletion
}) {
  try {
    yield put(REQUEST_REMOVE_USER_DOCUMENT.start());
    const response = yield removeUserDocument({
      version: userDocumentId ? 2 : null,
      user_document_id: userDocumentId,
      user_id: userId,
      content_id: contentId,
    });
    yield put(REQUEST_REMOVE_USER_DOCUMENT.success(response));
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'SUPPORTING_DOC_REMOVED',
    });
    // Call refresh of doc list to see newly added doc
    if (isMember) yield requestUserDocuments(userId);
  } catch (error) {
    yield put(REQUEST_REMOVE_USER_DOCUMENT.error(error));
  }
}

export function* requestSetUserDocument({
  files,
  userId,
  documentType,
  documentTypeOtherDescription,
  name,
  expirationDate,
  notes,
  userDocumentId,
}) {
  try {
    yield put(REQUEST_SET_USER_DOCUMENT.start());
    const contentIds = yield call(uploadNewFiles, files);
    yield setUserDocument({
      version: 2,
      content_ids: contentIds,
      user_id: userId,
      document_type: documentType,
      document_type_other_description: documentTypeOtherDescription,
      name,
      expiration_date: expirationDate,
      notes,
      user_document_id: userDocumentId,
    });
    yield put(
      REQUEST_SET_USER_DOCUMENT.success(
        {
          contentIds,
          userId,
          documentType,
          documentTypeOtherDescription,
          name,
          expirationDate,
          notes,
        },
        { upload: false },
      ),
    );
    // Call refresh of doc list to see edited doc changes
    yield requestUserDocuments(userId);
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'DOC_SUCCESSFULLY_UPDATED',
    });
  } catch (error) {
    yield put(REQUEST_SET_USER_DOCUMENT.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_UPDATING_SUPP_DOC',
    });
  }
}

export function* requestPinClaimDocument({
  contentIds,
  contentType,
  userId,
  claimContentIds,
  claimId,
  userDocumentId,
  name,
  documentType,
  documentTypeOtherDescription,
}) {
  try {
    yield put(REQUEST_PIN_CLAIM_DOCUMENT.start());
    yield setUserDocument({
      version: 2,
      content_ids: contentIds,
      user_id: userId,
      document_type: documentType,
      document_type_other_description: documentTypeOtherDescription,
      name,
      original_claim_id: claimId,
      user_document_id: userDocumentId,
    });
    yield put(
      REQUEST_PIN_CLAIM_DOCUMENT.success({
        contentId: contentIds[0],
        contentIds,
        contentType,
        userId,
        claimContentIds,
        claimId,
        userDocumentId,
        name,
      }),
    );
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'CLAIM_DOC_PINNED',
    });
    yield request(REQUEST_USER_DOCUMENTS, getUserDocuments(userId));
  } catch (error) {
    yield put(REQUEST_PIN_CLAIM_DOCUMENT.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_PINNING',
    });
  }
}

export function* requestAddDocumentToClaim({
  claimId,
  claimContentIds,
  contentId,
  contentType,
}) {
  try {
    const contentIdsSet = new Set(claimContentIds);
    contentIdsSet.add(contentId);
    const contentIds = Array.from(contentIdsSet);
    yield put(REQUEST_SET_CLAIM_DOCUMENTS.start());
    yield setClaimDocuments({
      claim_id: claimId,
      content_ids: contentIds,
    });
    yield put(
      REQUEST_SET_CLAIM_DOCUMENTS.success({
        claimId,
        contentIds,
        contentId,
        contentType,
      }),
    );
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'DOC_ADDED_CLAIM',
    });
  } catch (error) {
    yield put(REQUEST_SET_CLAIM_DOCUMENTS.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_ADDING_DOC_CLAIM',
    });
  }
}

export function* requestRemoveDocumentFromClaim({
  claimId,
  claimContentIds,
  contentId,
  contentType,
}) {
  try {
    const contentIds = claimContentIds.filter(cId => cId !== contentId);
    yield put(REQUEST_SET_CLAIM_DOCUMENTS.start());
    yield setClaimDocuments({
      claim_id: claimId,
      content_ids: contentIds,
    });
    yield put(
      REQUEST_SET_CLAIM_DOCUMENTS.success({
        claimId,
        contentIds,
        contentId,
        contentType,
      }),
    );
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'SUPPORTING_DOC_REMOVED_CLAIM',
    });
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_ADDING_DOC_CLAIM',
    });
  }
}

export function* userDocumentsSaga() {
  yield fork(takeLatest, GET_USER_DOCUMENTS, requestUserDocuments);
  yield fork(
    takeLatest,
    USER_DOCUMENT_REMOVE_CONFIRMED,
    requestRemoveUserDocument,
  );
  yield fork(takeLatest, REQUEST_SUBMIT_TO_STRIPE, requestSubmitToStripe);
  yield fork(
    takeLatest,
    UPLOAD_DOCUMENT_FORM_SUBMITTED,
    requestUploadUserDocument,
  );
  yield fork(takeLatest, EDIT_DOCUMENT_FORM_SUBMITTED, requestSetUserDocument);
  yield fork(takeLatest, PIN_CLAIM_DOCUMENT, requestPinClaimDocument);
  yield fork(takeLatest, ADD_DOCUMENT_TO_CLAIM, requestAddDocumentToClaim);
  yield fork(
    takeLatest,
    REMOVE_DOCUMENT_FROM_CLAIM,
    requestRemoveDocumentFromClaim,
  );
  yield fork(userDocumentsAnalyticsSaga);
  yield fork(takeLatest, GENERATE_USER_FORM_CONTENT_ID, requestFormLinks);
}
