import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getCoverageSummary = ({ groupId, userId, source, planId }) =>
  call(websocketFetch, 'get_coverage_summary', {
    group_id: groupId,
    user_id: userId,
    source,
    plan_id: planId,
  });
