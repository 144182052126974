import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getPurchaseAndClaimHistory - calls `get_purchase_and_claim_history` for displaying user transactions in wallet.
 *
 * @arg {Object} options  - An object representing options. This gets desctructured automatically into:
 * @arg {string[]} options.paymentTypes  - an array of payment types as strings, ex 'hsa'
 * @arg {number} options.limit  - maximum number of records to fetch
 * @arg {number} options.skip number (optional) - number of records to offset from the result index 0. Less commonly used than passing limit + currentPage for pagination.
 * @arg {number} options.currentPage - number (optional) - if passed with limit can be used for pagination
 * @arg {string} options.userId - string (optional) - for admins viewing a member's wallet, pass their userId to get their transactions
 * @arg {string[]} options.alsoInclude - array (optional) - by default we include 'free_purchase' ($0 purchases) and 'credits' (top ups and allocations) but you can override that with your own array.
 *
 * @returns {object[]} - an array of claim, purchase, or credit objects in the format { claim: {...}} or { transaction: {...}} or { credit: {...}}
 */

export const getPurchaseAndClaimHistory = ({
  paymentTypes,
  limit,
  currentPage,
  skip,
  userId,
  alsoInclude,
}) =>
  call(websocketFetch, 'get_purchase_and_claim_history', {
    payment_types: paymentTypes,
    limit,
    skip: skip || limit * currentPage,
    also_include: alsoInclude || ['free_purchase', 'credits'],
    user_id: userId,
    version: 2,
  });
