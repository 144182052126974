import { identity, reduce, toString, mapValues } from 'lodash';
import { createSchemaParser } from './parser';

const casters = {
  boolean_string: toString,
};

export const cast = (value, type) => {
  const sourceType = typeof value;
  const key = `${sourceType}_${type}`;
  const typeCaster = casters[key] || identity;
  return typeCaster(value);
};

const fieldTypeReducer = (map, fieldSpec) => ({
  ...map,
  [fieldSpec.path]: fieldSpec.json_type,
});

export const createTypeCaster = schema => {
  const fieldSpecs = createSchemaParser([])(schema);
  const fieldTypes = reduce(fieldSpecs, fieldTypeReducer, {});
  return formValues =>
    mapValues(formValues, (value, fieldName) => {
      const type = fieldTypes[fieldName];
      return cast(value, type);
    });
};
