import { createRequestTypes } from 'common/websocket-redux';

export const ON_INIT_CHAT_WINDOW = 'chatWindow/ON_INIT_CHAT_WINDOW';
export const SET_WAIT_TIME = 'chatWindow/SET_WAIT_TIME';
export const SET_QUEUE_POSITION = 'chatWindow/SET_QUEUE_POSITION';
export const SET_CHAT_IS_ESTABLISHED = 'chatWindow/SET_CHAT_IS_ESTABLISHED';
export const SET_CHAT_HAS_FAILED_TO_LOAD =
  'chatWindow/SET_CHAT_HAS_FAILED_TO_LOAD';
export const OPEN_MODAL = 'chatWindow/OPEN_MODAL';
export const CLOSE_MODAL = 'chatWindow/CLOSE_MODAL';

export const CREATE_SALESFORCE_CHAT_MAP = createRequestTypes(
  'chatWindow/CREATE_SALESFORCE_CHAT_MAP',
);

export const GET_SALESFORCE_CONFIG = createRequestTypes(
  'chatWindow/GET_SALESFORCE_CONFIG',
);

export const GET_AGENT_CONFIG = createRequestTypes(
  'chatWindow/GET_AGENT_CONFIG',
);

export const GET_SALESFORCE_LIVEAGENT = createRequestTypes(
  'chatWindow/GET_SALESFORCE_LIVEAGENT',
);

export const initChatWindow = ({ agentType }) => ({
  type: ON_INIT_CHAT_WINDOW,
  payload: { agentType },
});

export const setWaitTime = waitTime => ({
  type: SET_WAIT_TIME,
  payload: waitTime,
});

export const setQueuePosition = queuePosition => ({
  type: SET_QUEUE_POSITION,
  payload: queuePosition,
});

export const setChatIsEstablished = ({ origin }) => ({
  type: SET_CHAT_IS_ESTABLISHED,
  payload: { origin },
});

export const setChatHasFailedToLoad = () => ({
  type: SET_CHAT_HAS_FAILED_TO_LOAD,
});

export const openModal = chatModal => ({
  type: OPEN_MODAL,
  payload: chatModal,
});

export const closeModal = () => ({ type: CLOSE_MODAL });
