import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getEmployerRequiredFields - fetches a list of field descriptions
 * that can be used to build a user profile form.
 *
 * @arg {string} groupId  - The employer group ID
 *
 * @returns {Object} - A redux-saga call effect
 */
export const getEmployerRequiredFields = ({ groupId }) =>
  call(websocketFetch, 'get_employer_required_fields', { group_id: groupId });
