import { ENVIRONMENT } from '@leagueplatform/core';

export const TEST2 = 'test2';
export const TEST3 = 'test3';
export const TEST4 = 'test4';
export const TEST5_CA = 'test5-ca';
export const TEST5_US = 'test5-us';
export const LOCALHOST = 'app.local.league.dev';
export const TESTPROD = 'app.testprod.league.com';

// This mapping maps REACT_APP_ENV values used for our B2B test environments (see https://github.com/EverlongProject/kubernetes-migration/search?q=REACT_APP_ENV)
// to corresponding values supported by @leagueplatform/core.
export const CUSTOM_ENV_MAPPING = {
  [TEST2]: ENVIRONMENT.STAGING,
  [TEST3]: ENVIRONMENT.STAGING,
  [TEST4]: ENVIRONMENT.STAGING,
  [TEST5_CA]: ENVIRONMENT.STAGING,
  [TEST5_US]: ENVIRONMENT.STAGING,
  testprod: ENVIRONMENT.PRODUCTION,
  'testprod-us': ENVIRONMENT.PRODUCTION,
};
