import { takeLatest, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import {
  GET_ENROLLMENT_DESIGN_OPTIONS,
  GET_ENROLLMENT_TEMPLATES,
  RESET_ENROLLMENT_DESIGN_OPTIONS,
  SET_ENROLLMENT_DESIGN_OPTIONS,
  SET_ENROLLMENT_TEMPLATES,
} from './enrollment-design-options.actions';
import {
  getEnrollmentDesignOptions,
  setEnrollmentDesignOptions,
  resetEnrollmentDesignOptions,
  getEnrollmentTemplates,
  setEnrollmentTemplates,
} from 'common/services';
import { GetEnrollmentDesignOptionsParams } from 'common/services/enrollment/get-enrollment-design-options.service';
import { SetEnrollmentDesignOptionsParams } from 'common/services/enrollment/set-enrollment-design-options.service';
import { SetEnrollmentTemplatesParams } from 'common/services/enrollment/set-enrollment-templates.service';
import { GetEnrollmentTemplatesParams } from 'common/services/enrollment/get-enrollment-templates.service';
import { ResetEnrollmentDesignOptionsParams } from 'common/services/enrollment/reset-enrollment-design-options.service';
import {
  toastActions,
  Toast,
  TOAST_STATUS,
} from '@leagueplatform/toast-messages';

interface GetEnrollmentDesignOptionsSagaProps {
  payload: GetEnrollmentDesignOptionsParams;
  type: string;
}

interface SetEnrollmentDesignOptionsSagaProps {
  payload: SetEnrollmentDesignOptionsParams;
  type: string;
}

interface ResetEnrollmentDesignOptionsSagaProps {
  payload: ResetEnrollmentDesignOptionsParams;
  meta: GetEnrollmentDesignOptionsParams;
  type: string;
}

interface RefetchEnrollmentDesignOptionsSagaProps {
  meta: GetEnrollmentDesignOptionsParams;
  type: string;
}
interface ResponseType {
  error?: boolean;
  message_type?: string;
}

interface GetEnrollmentTemplatesSagaProps {
  payload: GetEnrollmentTemplatesParams;
  type: string;
}

interface RefetchEnrollmentTemplatesSagaProps {
  meta: GetEnrollmentTemplatesParams;
  type: string;
}

interface SetEnrollmentTemplatesSagaProps {
  payload: SetEnrollmentTemplatesParams;
  meta: GetEnrollmentTemplatesParams;
  type: string;
}

export function* getEnrollmentDesignOptionsSaga({
  payload,
}: GetEnrollmentDesignOptionsSagaProps) {
  yield request(
    GET_ENROLLMENT_DESIGN_OPTIONS,
    getEnrollmentDesignOptions(payload),
    payload,
  );
}

export function* setEnrollmentDesignOptionsSaga({
  payload,
}: SetEnrollmentDesignOptionsSagaProps) {
  const response: ResponseType = yield request(
    SET_ENROLLMENT_DESIGN_OPTIONS,
    setEnrollmentDesignOptions(payload),
    payload,
  );

  const toastMessage: Toast = response?.error
    ? {
        type: TOAST_STATUS.ERROR,
        textId: 'ENROLLMENT_DESIGN_OPTIONS_SAVED_ERROR',
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        textId: 'ENROLLMENT_DESIGN_OPTIONS_SAVED_SUCCESS',
      };

  yield call(toastActions.add, toastMessage);
}

export function* resetEnrollmentDesignOptionsSaga({
  payload,
  meta,
}: ResetEnrollmentDesignOptionsSagaProps) {
  const response: ResponseType = yield request(
    RESET_ENROLLMENT_DESIGN_OPTIONS,
    resetEnrollmentDesignOptions(payload),
    meta,
  );

  const toastMessage: Toast = response?.error
    ? {
        type: TOAST_STATUS.ERROR,
        textId: 'ENROLLMENT_DESIGN_OPTIONS_RESET_ERROR',
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        textId: 'ENROLLMENT_DESIGN_OPTIONS_RESET_SUCCESS',
      };

  yield call(toastActions.add, toastMessage);
}

export function* refetchEnrollmentDesignOptionsSaga({
  meta,
}: RefetchEnrollmentDesignOptionsSagaProps) {
  yield request(
    GET_ENROLLMENT_DESIGN_OPTIONS,
    getEnrollmentDesignOptions(meta),
    meta,
  );
}

export function* getEnrollmentTemplatesSaga({
  payload,
}: GetEnrollmentTemplatesSagaProps) {
  yield request(
    GET_ENROLLMENT_TEMPLATES,
    getEnrollmentTemplates(payload),
    payload,
  );
}

export function* setEnrollmentTemplatesSaga({
  payload,
  meta,
}: SetEnrollmentTemplatesSagaProps) {
  const response: ResponseType = yield request(
    SET_ENROLLMENT_TEMPLATES,
    setEnrollmentTemplates(payload),
    meta,
  );

  const toastMessage: Toast = response?.error
    ? {
        type: TOAST_STATUS.ERROR,
        textId: 'ENROLLMENT_TEMPLATES_CREATED_ERROR',
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        textId: 'ENROLLMENT_TEMPLATES_CREATED_SUCCESS',
      };

  yield call(toastActions.add, toastMessage);
}

export function* refetchEnrollmentTemplatesSaga({
  meta,
}: RefetchEnrollmentTemplatesSagaProps) {
  yield request(GET_ENROLLMENT_TEMPLATES, getEnrollmentTemplates(meta), meta);
}

export function* enrollmentDesignSaga() {
  yield takeLatest(
    GET_ENROLLMENT_DESIGN_OPTIONS.BASE,
    getEnrollmentDesignOptionsSaga,
  );
  yield takeLatest(
    SET_ENROLLMENT_DESIGN_OPTIONS.BASE,
    setEnrollmentDesignOptionsSaga,
  );
  yield takeLatest(
    RESET_ENROLLMENT_DESIGN_OPTIONS.BASE,
    resetEnrollmentDesignOptionsSaga,
  );
  yield takeLatest(
    RESET_ENROLLMENT_DESIGN_OPTIONS.SUCCEEDED,
    refetchEnrollmentDesignOptionsSaga,
  );
  yield takeLatest(GET_ENROLLMENT_TEMPLATES.BASE, getEnrollmentTemplatesSaga);
  yield takeLatest(SET_ENROLLMENT_TEMPLATES.BASE, setEnrollmentTemplatesSaga);
  yield takeLatest(
    SET_ENROLLMENT_TEMPLATES.SUCCEEDED,
    refetchEnrollmentTemplatesSaga,
  );
}
