import { createRequestTypes } from 'common/websocket-redux';

export const GET_GROUP = createRequestTypes('employer/GET_GROUP');
export const EMPLOYER_DETAILS_LOADING = 'employer/LOADING';
export const EMPLOYER_DETAILS_READY = 'employer/READY';
export const EMPLOYER_BILLING_LOADING = 'billing/LOADING';
export const EMPLOYER_BILLING_READY = 'billing/READY';
export const CLEAR_EMPLOYER_DETAILS = 'employer/CLEAR_EMPLOYER_DETAILS';
export const FETCH_BENEFITS = 'employer/FETCH_BENEFITS';
export const GET_EMPLOYER_BENEFITS = createRequestTypes(
  'service/GET_EMPLOYER_BENEFITS',
);
export const EMPLOYER_VISITED = 'employer/EMPLOYER_VISITED';
