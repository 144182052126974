import { createSelector } from 'reselect';
import { INSURANCE_FORM_TYPES } from 'common/insurance-forms/insurance-forms.constants';
import { PRODUCT_TYPES } from 'common/benefits/metadata';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const selectInsuranceForms = app => app?.insuranceForms;

export const selectFormsIsReady = createSelector(
  selectInsuranceForms,
  forms => forms?.isReady ?? false,
);

export const selectInsuranceFormsLoadingByFormId = createSelector(
  selectInsuranceForms,
  forms => forms?.loadingByFormId ?? {},
);

export const selectBenefitFormContentId = createSelector(
  selectInsuranceForms,
  forms => forms?.contentByFormId ?? {},
);

export const selectBenefitFormList = createSelector(
  selectInsuranceForms,
  selectInsuranceFormsLoadingByFormId,
  selectBenefitFormContentId,
  (forms, loadingByFormId, contentByFormId) =>
    forms?.benefit_form_list?.map(form => ({
      ...form,
      isLoading: loadingByFormId?.[form.form_id] ?? false,
      url: getContentUrl(contentByFormId?.[form.form_id]),
    })) ?? [],
);

const matchFormsByType = type => form => form.form_type === type;

export const selectEOIForms = createSelector(selectBenefitFormList, forms =>
  forms.filter(matchFormsByType(INSURANCE_FORM_TYPES.EOI)),
);

export const selectBeneficiaryForms = createSelector(
  selectBenefitFormList,
  forms => forms.filter(matchFormsByType(INSURANCE_FORM_TYPES.BENEFICIARY)),
);

export const selectHasForms = createSelector(
  selectEOIForms,
  selectBeneficiaryForms,
  (eoiForms, beneficiaryForms) => [...eoiForms, ...beneficiaryForms].length > 0,
);

export const selectDigitalBeneficiaryForm = createSelector(
  selectInsuranceForms,
  insuranceForms => insuranceForms?.digitalBeneficiaryForm || {},
);

/* This selector is used in concert with checks against a member being
 a KPMG Equity or Income Partner to hide the EOI download link */
export const selectIsKPMGPartnerWithOptionalLTD = createSelector(
  (_, isKPMGPartner) => isKPMGPartner,
  selectEOIForms,
  (isKPMGPartner, eoiForms) =>
    isKPMGPartner &&
    eoiForms?.some(form =>
      form.benefit_product_types?.find(
        productType => productType === PRODUCT_TYPES.OPTIONAL_LTD,
      ),
    ),
);
