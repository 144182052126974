/* eslint react/no-typos: 0 */
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import { styled, HeadingText, Box } from '@leagueplatform/genesis-core';
import PropTypes from 'prop-types';
import React from 'react';
import { includes, uniqueId } from 'lodash';
import { Pill } from '@leagueplatform/ui-kit';
import {
  APPROVED,
  CANCELLED,
  MORE_INFORMATION_NEEDED,
  PAID,
  REJECTED,
  SUBMITTED,
  CLAIM_STATUS_TRANSLATIONS,
} from 'common/constants';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';

const mapStatusToColor = status => {
  switch (status) {
    case APPROVED:
      return 'blue';
    case PAID:
      return 'green';
    case MORE_INFORMATION_NEEDED:
      return 'orange';
    case CANCELLED:
      return 'grey';
    case REJECTED:
      return 'red';
    // this isn't needed but it's here to be explicit
    case SUBMITTED:
      return 'purple';
    default:
      return 'purple';
  }
};

const Row = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '$half',
});

const StyledSpan = styled('span', {
  maxWidth: '60%',
  textAlign: 'right',
});

export const Summary = ({ claim }) => {
  const { formatMessage, formatMoney, formatDate } = useIntl();
  useDocumentTitle(
    `${formatMessage({
      id: 'CLAIM_REF_NUMBER',
    })}${claim.reference_id}`,
  );

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }}
    >
      <Row>
        <HeadingText level="1" size="lg">
          {formatMessage({ id: 'CLAIM_REF_NUMBER' })}
          {claim.reference_id}
        </HeadingText>
        <Pill color={mapStatusToColor(claim.status)}>
          <VisuallyHidden>
            {formatMessage({ id: 'CLAIM_STATUS' })}{' '}
          </VisuallyHidden>
          {formatMessage({ id: CLAIM_STATUS_TRANSLATIONS[claim.status] })}
        </Pill>
      </Row>
      {claim.date_created && (
        <Row>
          {formatMessage({ id: 'SUBMITTED_DATE' })}
          <span>
            {formatDate(claim.date_created, {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </span>
        </Row>
      )}
      {claim.vendor && (
        <Row>
          {formatMessage({ id: 'VENDOR' })}
          <StyledSpan>{claim.vendor}</StyledSpan>
        </Row>
      )}
      {includes([APPROVED, PAID], claim.status) &&
        claim.policy_allocations &&
        claim.policy_allocations.map(allocation => {
          return (
            <Row key={uniqueId('flex-table-row-', allocation.type)}>
              {formatMessage({ id: `${allocation.type}_amount`.toUpperCase() })}
              <span>
                {formatMoney({
                  F: allocation.amount_approved,
                  C: claim.amount_approved.currency,
                })}
              </span>
            </Row>
          );
        })}
      {claim.amount_approved && (
        <Row>
          {formatMessage({ id: 'TOTAL_AMOUNT' })}
          <span>
            {claim.amount_paid
              ? formatMoney({
                  F: claim.amount_paid.value,
                  C: claim.amount_paid.currency,
                })
              : formatMoney({
                  F: claim.amount_approved.value,
                  C: claim.amount_approved.currency,
                })}
          </span>
        </Row>
      )}
    </Box>
  );
};

Summary.propTypes = {
  claim: PropTypes.shape({
    reference_id: PropTypes.string,
    status: PropTypes.string,
    date_created: PropTypes.date,
    vendor: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    policy_allocations: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    amount_approved: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    amount_paid: PropTypes.object,
  }),
};

Summary.defaultProps = {
  claim: {},
};
