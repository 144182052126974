import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import ActionsDropdown from './actions-dropdown.view';
import { connect } from 'react-redux';
import { selectFeatureFlags } from 'common/state/featureFlags/feature-flags.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { selectEmployerTimeZone } from 'apps/employer-experience/pages/EmployerDetails/selectors';

const mapStateToProps = state => ({
  canAddFunds: selectFeatureFlags(state)?.topUpSpendingAccount,
  canChangeAllocation: selectUserIsAdmin,
  employerTz: selectEmployerTimeZone(state),
});

export default compose(connect(mapStateToProps), injectIntl)(ActionsDropdown);
