import { request } from 'common/websocket-redux';
import {
  SET_USER_ACCEPTED_TERMS,
  SET_USER_MARKETING,
  SET_USER_MARKETING_OPT_IN,
  SET_USER_TERMS,
  UPDATE_USER_MEMBER_TERMS_FTUX_STEP,
} from './terms.actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { setPostFTUX } from 'common/services/enrollment';
import { setUserProfile } from 'common/services';
import { acceptMemberTerms } from 'common/services/accept_member_terms.service';
import { selectUser } from 'common/state/user/user.selectors';
import { push } from 'connected-react-router';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { LATEST_TERMS_VERSION } from './terms.constants';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { selectOnboardingEnabledFlag } from 'common/state/featureFlags/onboarding-enabled-flag.selectors';

export function* setUserTerms() {
  yield request(
    SET_USER_ACCEPTED_TERMS,
    acceptMemberTerms({ version: LATEST_TERMS_VERSION }),
  );
}

export function* updateMemberTermsPostFtuxStep() {
  let onboardingFlagEnabled = false;
  try {
    onboardingFlagEnabled = yield select(selectOnboardingEnabledFlag);
  } catch (e) {
    // Let it fail silently
  }
  const onboardingUrl = getModulePath(LEAGUE_MODULE_NAMES.onboarding);
  const rootUrl = '/';
  const user = yield select(selectUser);
  const memberPostFtux = user?.memberPostFtux || {};
  yield request(
    UPDATE_USER_MEMBER_TERMS_FTUX_STEP,
    setPostFTUX({ ...memberPostFtux, terms: true, started: true }),
  );
  if (onboardingFlagEnabled) {
    yield put(push(onboardingUrl));
  } else {
    yield put(push(rootUrl));
  }
}

export function* setUserMarketingOptIn(action) {
  yield request(
    SET_USER_MARKETING_OPT_IN,
    setUserProfile({
      opted_into_marketing_communications: action?.payload?.marketingOptIn,
    }),
  );
}

export function* showUserTermsErrorToast(error) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'USER_TERMS_UPDATE_FAILED',
    contentId: error?.payload?.info?.reason,
  });
}

export function* userTermsSaga() {
  yield takeLatest(
    [SET_USER_ACCEPTED_TERMS.ERRORED, SET_USER_MARKETING_OPT_IN.ERRORED],
    showUserTermsErrorToast,
  );
  yield takeLatest(
    SET_USER_ACCEPTED_TERMS.SUCCEEDED,
    updateMemberTermsPostFtuxStep,
  );
  yield takeLatest(SET_USER_TERMS, setUserTerms);
  yield takeLatest(SET_USER_MARKETING, setUserMarketingOptIn);
}
