import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { currencyPropType } from '@leagueplatform/web-common';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getUserBenefitFlexDollars as getUserBenefitFlexDollarsDispatch,
  setUserBenefitFlexDollars as setUserBenefitFlexDollarsDispatch,
} from './update-flex-dollars.actions';
import { UpdateFlexDollarsPresenter } from './update-flex-dollars.view';
import {
  selectTotalFlexDollars,
  selectLoadedFlexDollars,
} from './update-flex-dollars.selectors';
import { selectUserId, selectGroupId } from '../benefits.selectors';

const mapStateToProps = createStructuredSelector({
  groupId: selectGroupId,
  userId: selectUserId,
  totalFlexDollars: selectTotalFlexDollars,
  originalLoadedFlexDollars: selectLoadedFlexDollars,
});

const mapDispatchToProps = {
  getUserBenefitFlexDollars: getUserBenefitFlexDollarsDispatch,
  setUserBenefitFlexDollars: setUserBenefitFlexDollarsDispatch,
};

const UpdateFlexDollarsContainer = ({
  groupId,
  planId,
  userId,
  totalFlexDollars,
  originalLoadedFlexDollars,
  getUserBenefitFlexDollars,
  setUserBenefitFlexDollars,
  setIsModalOpen,
}) => {
  const [loadedFlexDollars, setLoadedFlexDollars] = useState(
    originalLoadedFlexDollars,
  );

  const onLoadedFlexDollarsChange = value => {
    setLoadedFlexDollars({ ...loadedFlexDollars, F: value });
  };

  const onSubmit = () => {
    setUserBenefitFlexDollars({
      groupId,
      planId,
      userId,
      loadedFlexDollars,
    });
    setIsModalOpen(false);
  };

  useEffect(() => {
    getUserBenefitFlexDollars({ groupId, planId, userId });
  }, [getUserBenefitFlexDollars, groupId, planId, userId]);

  useEffect(() => {
    setLoadedFlexDollars(originalLoadedFlexDollars);
  }, [originalLoadedFlexDollars]);

  return (
    <UpdateFlexDollarsPresenter
      totalFlexDollars={totalFlexDollars}
      loadedFlexDollars={loadedFlexDollars}
      onLoadedFlexDollarsChange={onLoadedFlexDollarsChange}
      onSubmit={onSubmit}
      setIsModalOpen={setIsModalOpen}
    />
  );
};

UpdateFlexDollarsContainer.propTypes = {
  groupId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  totalFlexDollars: currencyPropType.isRequired,
  originalLoadedFlexDollars: currencyPropType.isRequired,
  getUserBenefitFlexDollars: PropTypes.func.isRequired,
  setUserBenefitFlexDollars: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export const UpdateFlexDollars = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateFlexDollarsContainer);
