import { FORM_TYPES } from '../../constants';

export const getInvalidErrorMessage = formType => {
  switch (formType) {
    case FORM_TYPES.suspendConfirmation:
      return 'THIRTY_DAYS_LIMIT_SUSPEND_ERROR';
    case FORM_TYPES.reinstateConfirmation:
      return 'THIRTY_DAYS_LIMIT_REINSTATE_ERROR';
    default:
      return '';
  }
};

export const getEmptyDateErrorMessage = formType => {
  switch (formType) {
    case FORM_TYPES.terminateConfirmation:
      return 'EMPTY_DATE_TERMINATE_ERROR';
    case FORM_TYPES.suspendConfirmation:
      return 'EMPTY_DATE_SUSPEND_ERROR';
    case FORM_TYPES.reinstateConfirmation:
      return 'EMPTY_DATE_REINSTATE_ERROR';
    default:
      return '';
  }
};

export const getDatePickerLabel = formType => {
  switch (formType) {
    case FORM_TYPES.reinstateConfirmation:
      return 'SCHEDULE_REINSTATE_CONFIRMATION_LABEL';
    case FORM_TYPES.suspendConfirmation:
      return 'SCHEDULE_SUSPENSION_CONFIRMATION_LABEL';
    case FORM_TYPES.terminateConfirmation:
      return 'SCHEDULE_TERMINATION_CONFIRMATION_LABEL';
    default:
      return '';
  }
};
