import {
  SUBMITTED,
  MORE_INFORMATION_NEEDED,
  APPROVED,
  REJECTED,
  PENDING_EXTERNAL_REVIEW,
  CANCELLED,
} from 'common/constants';

const submittedOption = {
  value: SUBMITTED,
  text: 'Submitted',
};

const moreInfoOption = {
  value: MORE_INFORMATION_NEEDED,
  text: 'More Information Needed',
};

const approvedOption = {
  value: APPROVED,
  text: 'Approved',
};

const rejectedOption = {
  value: REJECTED,
  text: 'Rejected',
};

const externalReviewOption = {
  value: PENDING_EXTERNAL_REVIEW,
  text: 'Sent for External Review',
};

export const CLAIM_STATUS_OPTIONS = {
  [SUBMITTED]: [
    submittedOption,
    approvedOption,
    rejectedOption,
    moreInfoOption,
    externalReviewOption,
  ],
  [MORE_INFORMATION_NEEDED]: [moreInfoOption, approvedOption, rejectedOption],
  [REJECTED]: [rejectedOption, approvedOption, moreInfoOption],
  [APPROVED]: [approvedOption, submittedOption],
  [CANCELLED]: [submittedOption, externalReviewOption],
  [PENDING_EXTERNAL_REVIEW]: [
    externalReviewOption,
    approvedOption,
    rejectedOption,
  ],
};

export const EMPLOYEE_DEPENDENT_VALUE = '__EMPLOYEE__';
