import {
  GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV,
  GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV,
} from './reports.types';
import {
  EMPLOYER_PAYROLL_REPORTS_LIFECYCLE,
  EMPLOYER_PAYROLL_INIT,
} from './components/payroll-report';
import { combineReducers } from 'redux';

export const STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  READY: 'READY',
  ERROR: 'ERROR',
};

export const REPORT = {
  DETAILED_USAGE: 'DETAILED_USAGE',
  USAGE_SUMMARY: 'USAGE_SUMMARY',
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const usageSummaryError = (state = null, action) => {
  switch (action.type) {
    case EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.EXITED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.STARTED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.SUCCEEDED:
      return null;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.ERRORED:
      return action?.payload?.error ?? state;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const usageSummaryStatus = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.EXITED:
      return STATUS.IDLE;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.STARTED:
      return STATUS.LOADING;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.SUCCEEDED:
      return STATUS.READY;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const detailedUsageError = (state = null, action) => {
  switch (action.type) {
    case EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.EXITED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.STARTED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.SUCCEEDED:
      return null;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.ERRORED:
      return action?.payload?.error ?? state;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const detailedUsageStatus = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.EXITED:
      return STATUS.IDLE;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.STARTED:
      return STATUS.LOADING;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.SUCCEEDED:
      return STATUS.READY;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

const lifecycleInitialState = {
  payrollReady: false,
  lastChanged: REPORT.DETAILED_USAGE,
};
// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const lifecycle = (state = lifecycleInitialState, action) => {
  switch (action.type) {
    case EMPLOYER_PAYROLL_INIT.SUCCEEDED:
      return { ...state, payrollReady: true };
    case EMPLOYER_PAYROLL_REPORTS_LIFECYCLE.EXITED:
      return lifecycleInitialState;
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.STARTED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.SUCCEEDED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV.ERRORED:
      return { ...state, lastChanged: REPORT.DETAILED_USAGE };
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.STARTED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.SUCCEEDED:
    case GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV.ERRORED:
      return { ...state, lastChanged: REPORT.USAGE_SUMMARY };
    default:
      return state;
  }
};

export const employerReportsUIReducer = combineReducers({
  detailedUsageReport: combineReducers({
    error: detailedUsageError,
    status: detailedUsageStatus,
  }),
  usageSummaryReport: combineReducers({
    error: usageSummaryError,
    status: usageSummaryStatus,
  }),
  lifecycle,
});
