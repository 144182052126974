import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const MY_DOCUMENTS_LIFECYCLE = createLifecycleTypes(
  'documents/LIFECYCLE',
);

export const GET_USER_DOCUMENTS = 'documents/GET_USER_DOCUMENTS';
export const REQUEST_USER_DOCUMENTS = createRequestTypes(
  'documents/REQUEST_USER_DOCUMENTS',
);

export const USER_DOCUMENT_SET = 'documents/USER_DOCUMENT_SET';
export const REQUEST_SET_USER_DOCUMENT = createRequestTypes(
  'documents/SET_USER_DOCUMENT',
);

export const EDIT_USER_DOCUMENT = 'documents/EDIT_USER_DOCUMENT';
export const EDIT_DOCUMENT_FORM_SUBMITTED =
  'document/EDIT_DOCUMENT_FORM_SUBMITTED';
export const EDIT_USER_DOCUMENT_CANCELLED =
  'documents/EDIT_USER_DOCUMENT_CANCELLED';

export const CONFIRM_USER_DOCUMENT_REMOVAL =
  'documents/CONFIRM_USER_DOCUMENT_REMOVAL';
export const USER_DOCUMENT_REMOVE_CONFIRMED =
  'documents/USER_DOCUMENT_REMOVE_CONFIRMED';
export const USER_DOCUMENT_REMOVE_CANCELLED =
  'documents/USER_DOCUMENT_REMOVE_CANCELLED';
export const REQUEST_REMOVE_USER_DOCUMENT = createRequestTypes(
  'documents/REQUEST_REMOVE_USER_DOCUMENT',
);

export const RESET_USER_DOCUMENTS = 'documents/RESET_USER_DOCUMENTS';

export const OPEN_UPLOAD_MODAL = 'documents/OPEN_UPLOAD_MODAL';
export const CLOSE_UPLOAD_MODAL = 'documents/CLOSE_UPLOAD_MODAL';
export const UPLOAD_DOCUMENT_FORM_SUBMITTED =
  'documents/UPLOAD_DOCUMENT_FORM_SUBMITTED';
export const REQUEST_UPLOAD_USER_DOCUMENT = createRequestTypes(
  'documents/REQUEST_UPLOAD_USER_DOCUMENT',
);

export const REQUEST_SUBMIT_TO_STRIPE = 'documents/REQUEST_SUBMIT_TO_STRIPE';

export const OPEN_IMAGE_VIEWER = 'documents/OPEN_IMAGE_VIEWER';
export const CLOSE_IMAGE_VIEWER = 'documents/CLOSE_IMAGE_VIEWER';
export const SET_ACTIVE_INDEX = 'documents/SET_ACTIVE_INDEX';

export const PIN_CLAIM_DOCUMENT = 'documents/PIN_CLAIM_DOCUMENT';
export const REQUEST_PIN_CLAIM_DOCUMENT = createRequestTypes(
  'documents/REQUEST_PIN_CLAIM_DOCUMENT',
);

export const ADD_DOCUMENT_TO_CLAIM = 'documents/ADD_DOCUMENT_TO_CLAIM';
export const REMOVE_DOCUMENT_FROM_CLAIM =
  'documents/REMOVE_DOCUMENT_FROM_CLAIM';
export const REQUEST_SET_CLAIM_DOCUMENTS = createRequestTypes(
  'documents/REQUEST_SET_CLAIM_DOCUMENTS',
);
export const GET_USER_FORM_LINK = createRequestTypes(
  'documents/GET_USER_FORM_LINK',
);
export const FETCHED_ALL_USER_FORM_LINKS = 'FETCHED_ALL_USER_FORM_LINKS';
export const GENERATE_USER_FORM_CONTENT_ID =
  'documents/GENERATE_USER_FORM_CONTENT_ID';
