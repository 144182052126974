import React from 'react';
import PropTypes from 'prop-types';
import { SpendingAccountsView } from './spending-accounts.view';

export const SpendingAccountsContainer = ({ groupId, dashboards }) => {
  if (!dashboards || dashboards.length <= 0) return null;

  return <SpendingAccountsView dashboards={dashboards} groupId={groupId} />;
};

SpendingAccountsContainer.propTypes = {
  groupId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  dashboards: PropTypes.arrayOf(PropTypes.object),
};

SpendingAccountsContainer.defaultProps = {
  dashboards: [],
};
