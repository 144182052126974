import {
  endsWith,
  get,
  every,
  isUndefined,
  isEmpty,
  isNull,
  map,
  mapValues,
  omitBy,
} from 'lodash';
import { filter, groupBy, join } from 'lodash/fp';
import parseSchemaAsValidator from './parser';
import {
  isArray,
  isDefined,
  isBoolean,
  isNumber,
  isString,
  isLengthy,
  isEmail,
  isDate,
  dateIsBefore,
  dateIsAfter,
  hasMinLength,
  hasMaxLength,
  isAtLeast,
  isAtMost,
  belongsToCharacterSet,
  belongsToList,
  containsCharacters,
  isObjectId,
  hasPrefix,
} from './rules';

const ruleFns = {
  is_boolean: isBoolean,
  is_object: () => true,
  is_number: isNumber,
  is_string: isString,
  is_array: isArray,
  is_date: isDate,
  array_required: isLengthy,
  number_required: isDefined,
  number_min: isAtLeast,
  number_max: isAtMost,
  object_required: () => true,
  string_required: isLengthy,
  string_min: hasMinLength,
  string_max: hasMaxLength,
  string_charset: belongsToCharacterSet,
  string_contains: containsCharacters,
  string_email: isEmail,
  string_objectid: isObjectId,
  string_postal: isLengthy,
  string_prefix: hasPrefix,
  string_stringinlist: belongsToList,
  string_before: dateIsBefore,
  string_after: dateIsAfter,
};

export const getRuleFnByName = ruleName => {
  if (ruleName in ruleFns) return ruleFns[ruleName];
  // eslint-disable-next-line no-console
  console.warn(`"${ruleName}" validation rule not implemented`);
  return () => true;
};

export const withArgsAndHint = (args, hint, ruleFn) => value =>
  ruleFn(args, value) || hint;

export const getValidatorForRule = fieldRule => {
  const { rule: ruleName, args, hint } = fieldRule;
  const ruleFn = getRuleFnByName(ruleName);
  return [ruleName, withArgsAndHint(args, hint, ruleFn)];
};

export const makePathString = join('.');

export const groupValidatorsByPrecedence = groupBy(validator =>
  endsWith(validator[0], '_required') ? 'required' : 'other',
);

const satisfiesRequirementRules = results =>
  isEmpty(results) || every(results, result => result === true);

const isEmptyString = value =>
  typeof value === 'string' && value.replace(/\s/g, '').length === 0;

export const isEmptyOrUndefined = value =>
  isEmptyString(value) || isNull(value) || isUndefined(value);

export const createRuleSetValidator = ruleSet => {
  const validators = map(ruleSet, getValidatorForRule);
  const { required, other } = groupValidatorsByPrecedence(validators);
  // TODO: refactor this code
  // Written in a hurry to support precendnce of "required" vs other rules
  return value => {
    const requiredResult = map(required, validate => validate[1](value));
    const defined = !isEmptyOrUndefined(value);
    const ok = satisfiesRequirementRules(requiredResult);
    // Required but not provided; early return, ignore remaining rules
    if (!ok) return requiredResult;
    // Not required and not provided; skip everything
    if (!defined && ok) return [];
    // Otherwise return all "non-required" rules
    return map(other, validate => validate[1](value));
  };
};

export const createValidatorMap = schema => {
  const fieldRuleSetMap = parseSchemaAsValidator(schema);
  return mapValues(fieldRuleSetMap, createRuleSetValidator);
};

export const withOnlyErrors = resultMap =>
  mapValues(
    resultMap,
    filter(result => result !== true),
  );

export const withoutEmptyResults = resultMap => {
  const errorMap = withOnlyErrors(resultMap);
  return omitBy(errorMap, isEmpty);
};

export const validateResource = (validatorMap, resource) => {
  const resultMap = mapValues(validatorMap, (validator, fieldPath) => {
    const fieldValue = get(resource, fieldPath);
    return validator(fieldValue);
  });
  return withoutEmptyResults(resultMap);
};

export const createResourceValidator = schema => {
  const validatorMap = createValidatorMap(schema);
  return resource => validateResource(validatorMap, resource);
};
