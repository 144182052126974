import { useCallback } from 'react';
import { omitKeysWithEmptyValues, ADMIN } from '@leagueplatform/web-common';
import { FORM_TYPES, FORM_TYPES_ANALYTICS } from '../../constants';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { getHRAdminAnalytics } from 'common/utilities/get-hr-admin-analytics';

export const useConfirmActionsDropdown = (
  hideActionsConfirmation,
  actionEffectiveDate,
  reasonForTermination,
  noticeDate,
  eobDate,
  actionFormType,
  userId,
  groupId,
  setUserRoleInGroup,
  reinstateEmployees,
  suspendEmployees,
  terminateEmployees,
  unscheduleEmployeesReinstatement,
  unscheduleEmployeesSuspension,
  unscheduleEmployeesTermination,
  isAdmin,
) => {
  const onConfirmation = useCallback(() => {
    hideActionsConfirmation();

    if (!isAdmin) {
      const getparam = getHRAdminAnalytics();

      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HR_ADMIN,
        screen_name: getparam[0],
        sub_product_area: getparam[1] || null,
        detail: 'select action',
        sub_detail: FORM_TYPES_ANALYTICS[`${actionFormType}`],
      });
    }

    switch (actionFormType) {
      case FORM_TYPES.addAdminRole:
      case FORM_TYPES.removeAdminRole:
        return setUserRoleInGroup({
          userId,
          groupId,
          type: actionFormType,
          role: actionFormType === FORM_TYPES.addAdminRole ? ADMIN : 'member',
        });
      case FORM_TYPES.reinstateConfirmation:
        return reinstateEmployees({
          userIds: [userId],
          groupId,
          effectiveDate: actionEffectiveDate,
        });
      case FORM_TYPES.suspendConfirmation:
        return suspendEmployees(
          omitKeysWithEmptyValues({
            userIds: [userId],
            groupId,
            effectiveDate: actionEffectiveDate,
          }),
        );
      case FORM_TYPES.terminateConfirmation:
        return terminateEmployees(
          omitKeysWithEmptyValues({
            userIds: [userId],
            groupId,
            effectiveDate: actionEffectiveDate,
            reason: reasonForTermination,
            noticeDate,
            eobDate,
          }),
        );
      case FORM_TYPES.unscheduleReinstate:
        return unscheduleEmployeesReinstatement({
          userIds: [userId],
          groupId,
        });
      case FORM_TYPES.unscheduleSuspend:
        return unscheduleEmployeesSuspension({
          userIds: [userId],
          groupId,
        });
      case FORM_TYPES.unscheduleTerminate:
        return unscheduleEmployeesTermination({
          userIds: [userId],
          groupId,
        });
      default:
        return null;
    }
  }, [
    hideActionsConfirmation,
    actionEffectiveDate,
    reasonForTermination,
    noticeDate,
    eobDate,
    actionFormType,
    userId,
    groupId,
    setUserRoleInGroup,
    reinstateEmployees,
    suspendEmployees,
    terminateEmployees,
    unscheduleEmployeesReinstatement,
    unscheduleEmployeesSuspension,
    unscheduleEmployeesTermination,
    isAdmin,
  ]);

  return {
    onConfirmation,
    hideActionsConfirmation,
    setUserRoleInGroup,
    reinstateEmployees,
    suspendEmployees,
    terminateEmployees,
    unscheduleEmployeesReinstatement,
    unscheduleEmployeesSuspension,
    unscheduleEmployeesTermination,
  };
};
