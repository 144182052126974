/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { schema } from 'normalizr';

const benefit = new schema.Entity('benefits', undefined, {
  idAttribute: 'benefit_id',
  processStrategy: (value, parent) => ({
    ...value,
    setId: parent.id,
  }),
});

export const benefitSet = new schema.Entity(
  'benefitSets',
  {
    benefits: [benefit],
  },
  {
    processStrategy: (value, parent) => ({
      ...value,
      bundleId: parent.id,
    }),
  },
);

export const bundle = new schema.Entity('bundles', {
  benefit_sets: [benefitSet],
});

export const bundlesSchema = {
  bundles: [bundle],
};

export const planOptions = new schema.Entity(
  'planOptions',
  {
    bundles: [bundle],
  },
  {
    idAttribute: 'plan_id',
    processStrategy: ({ validation_warnings, ...rest }) => rest, // filter out the validation warnings
  },
);

export const planOptionsSchema = {
  planOptions,
};

export const employerBenefitSetSchema = [benefitSet];

export const benefitSetSchema = [benefitSet];
