import { request } from 'common/websocket-redux';
import { takeLatest } from 'redux-saga/effects';
import { getUserOptimumProfile } from 'common/services/get-user-optimum-profile.service';
import { GET_USER_OPTIMUM_PROFILE } from './user-optimum-profile.actions';

export function* requestUserOptimumProfile({ payload: { userId } }) {
  yield request(GET_USER_OPTIMUM_PROFILE, getUserOptimumProfile(userId), {
    userId,
  });
}

export function* userOptimumProfileSaga() {
  yield takeLatest(GET_USER_OPTIMUM_PROFILE.BASE, requestUserOptimumProfile);
}
