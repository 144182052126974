import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PAYROLL_BUSINESS_RULES,
  GET_PAYROLL_BUSINESS_RULES_SCHEMA,
  SET_PAYROLL_BUSINESS_RULES,
} from './payroll-config.types';
import { getPayrollBusinessRules } from 'common/services/payroll/get-payroll-business-rules.service';
import { setPayrollBusinessRules } from 'common/services/payroll/set-payroll-business-rules.service';
import { getPayrollBusinessRulesSchema } from 'common/services/payroll/get-payroll-business-rules-schema.service';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchPayrollBusinessRules({ payload }) {
  try {
    yield put(GET_PAYROLL_BUSINESS_RULES.start());
    const response = yield getPayrollBusinessRules(payload);
    yield put(GET_PAYROLL_BUSINESS_RULES.success(response));
  } catch (e) {
    yield put(GET_PAYROLL_BUSINESS_RULES.error(e.info.reason));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* fetchPayrollBusinessRulesSchema() {
  try {
    yield put(GET_PAYROLL_BUSINESS_RULES_SCHEMA.start());
    const response = yield getPayrollBusinessRulesSchema();
    yield put(GET_PAYROLL_BUSINESS_RULES_SCHEMA.success(response));
  } catch (e) {
    yield put(GET_PAYROLL_BUSINESS_RULES_SCHEMA.error(e.info.reason));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* updatePayrollBusinessRules({ payload }) {
  try {
    yield setPayrollBusinessRules(payload);
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'SAVED_BUSINESS_RULE',
    });
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* payrollConfigSaga() {
  yield takeLatest(GET_PAYROLL_BUSINESS_RULES.BASE, fetchPayrollBusinessRules);
  yield takeLatest(SET_PAYROLL_BUSINESS_RULES.BASE, updatePayrollBusinessRules);
  yield takeLatest(
    GET_PAYROLL_BUSINESS_RULES_SCHEMA.BASE,
    fetchPayrollBusinessRulesSchema,
  );
}
