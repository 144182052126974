import React from 'react';
import { useIntl } from '@leagueplatform/locales';

export const Logo = props => {
  const { formatMessage } = useIntl();
  return (
    <svg
      width="120px"
      height="37px"
      viewBox="0 0 120 37"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="title"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <title id="title">{formatMessage({ id: 'LEAGUE_LOGO' })}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Sidebar" transform="translate(-31.000000, -39.000000)">
          <g id="Logo" transform="translate(31.000000, 39.000000)">
            <path
              d="M83.5107678,27.1812845 C84.7187585,27.6714237 86.3096329,28.0160091 87.7728794,28.0160091 C90.0602911,28.0160091 91.548027,27.4282142 92.6326285,26.2647151 C93.5382683,25.2923423 93.9974461,23.940972 93.9974461,22.2468763 L93.9974461,11.4438193 L90.6357939,11.4438193 L90.6357939,12.8812197 C89.9500884,12.0623061 88.835817,11.3057061 87.2345817,11.3057061 C85.4233021,11.3057061 82.0644756,12.6435906 82.0644756,17.1352925 C82.0644756,21.6251344 85.4087026,22.8076996 87.3217078,22.8076996 C88.7081892,22.8076996 89.8573109,22.3901048 90.6805343,21.4595845 L90.6819471,22.0869068 C90.6819471,23.689392 89.6609241,24.8356852 87.9000363,24.8356852 C86.0741571,24.8356852 84.4526709,24.1832514 84.4526709,24.1832514 L83.5107678,27.1812845 Z M88.1275059,19.8868611 C86.6383571,19.8868611 85.598496,18.6996455 85.598496,17.0446122 C85.598496,15.3965542 86.6383571,14.2018982 88.1275059,14.2018982 C89.6274866,14.2018982 90.6357939,15.3184296 90.6357939,17.17668 C90.6357939,19.0349304 89.6274866,19.8868611 88.1275059,19.8868611 Z"
              id="Fill-4"
              fill="#250D53"
            />
            <polygon
              id="Fill-6"
              fill="#250D53"
              points="46.0606061 23.3196951 56.9979849 23.3196951 56.9979849 20.0459005 49.9162864 20.0459005 49.9162864 8.61645131 46.0606061 8.61645131"
            />
            <path
              d="M106.524804,11.4438193 L103.16692,11.4438193 L103.162681,18.6842996 C103.095335,19.6892243 102.104924,20.3728149 101.068831,20.3728149 C99.86084,20.3728149 98.9707416,19.6934096 98.9707416,18.5131694 L98.9707416,11.4438193 L95.612857,11.4438193 L95.612857,18.5610673 C95.612857,21.4312178 97.0836387,23.5675548 100.136347,23.5675548 C101.330209,23.5675548 102.332394,23.0709053 103.162681,22.2715228 L103.162681,23.31923 L106.524804,23.31923 L106.524804,11.4438193 Z"
              id="Fill-8"
              fill="#250D53"
            />
            <path
              d="M113.81212,11.2436249 C110.234301,11.2436249 107.735903,13.7673299 107.735903,17.3805946 C107.735903,21.0510577 110.521582,23.5175643 114.322161,23.5175643 C116.347724,23.5175643 117.808615,23.0548618 118.829638,22.5517019 L117.725257,19.8689575 C116.733904,20.2465599 115.762802,20.3804879 114.534089,20.3804879 C112.814174,20.3804879 111.515761,19.40672 111.224713,18.446438 L119.680177,18.446438 L119.680177,17.47174 C119.680177,13.6078254 117.306581,11.2436249 113.81212,11.2436249 Z M111.186566,15.9492396 C111.516703,14.7587688 112.409627,14.106335 113.719814,14.106335 C114.972074,14.106335 115.819787,14.7917858 116.017587,15.9492396 L111.186566,15.9492396 Z"
              id="Fill-10"
              fill="#250D53"
            />
            <path
              d="M75.4493488,11.2758048 C72.6170461,11.2758048 70.6461139,12.1342459 70.6461139,12.1342459 L71.5413928,15.0402035 C71.5413928,15.0402035 73.3135835,14.358473 75.4493488,14.358473 C76.6130701,14.358473 77.5092909,14.7751378 77.5092909,15.5210421 L77.5092909,15.7740172 L77.0482293,15.821915 L74.1839019,16.0697747 C71.5743594,16.3446061 70.0371735,17.7889819 70.0371735,19.8341734 C70.0371735,22.0216634 71.7081096,23.5497442 74.1010145,23.5497442 C75.495502,23.5497442 76.7114989,23.0186827 77.5092909,22.0774667 L77.5092909,23.3014195 L80.8709431,23.3014195 L80.8709431,16.1106972 C80.8709431,12.8880557 78.9621764,11.2758048 75.4493488,11.2758048 Z M77.5092909,18.2470341 L77.5092909,18.5320961 C77.5092909,19.7867406 76.4317537,20.7326068 75.0028867,20.7326068 C74.057216,20.7326068 73.5146798,20.3466339 73.5146798,19.6742039 C73.5146798,19.0826888 73.9771542,18.6897404 74.8135642,18.5688332 L77.5092909,18.2470341 Z"
              id="Fill-12"
              fill="#250D53"
            />
            <path
              d="M63.5718564,11.2436249 C59.9940374,11.2436249 57.4956395,13.7673299 57.4956395,17.3805946 C57.4956395,21.0510577 60.2813179,23.5175643 64.0818969,23.5175643 C66.1074596,23.5175643 67.5683513,23.0548618 68.5893743,22.5517019 L67.4849929,19.8689575 C66.4936398,20.2465599 65.5225377,20.3804879 64.2938251,20.3804879 C62.5739101,20.3804879 61.2754966,19.40672 60.9844486,18.446438 L69.4399128,18.446438 L69.4399128,17.47174 C69.4399128,13.6078254 67.0663169,11.2436249 63.5718564,11.2436249 Z M60.9463015,15.9492396 C61.2764385,14.7587688 62.1693627,14.106335 63.4795499,14.106335 C64.7318101,14.106335 65.5795229,14.7917858 65.7773225,15.9492396 L60.9463015,15.9492396 Z"
              id="Fill-14"
              fill="#250D53"
            />
            <polygon
              id="Fill-1"
              fill="#501CD2"
              points="12.5874126 0 12.5874126 23.7762238 36.3636364 23.7762238 36.3636364 13.7457662 22.6178701 13.7457662 22.6178701 0"
            />
            <polygon
              id="Fill-2"
              fill="#01C3A2"
              points="0 23.7762238 9.79020979 23.7762238 9.79020979 13.7457662 0 13.7457662"
            />
            <polygon
              id="Fill-3"
              fill="#01C3A2"
              points="12.5874126 36.3636364 22.3776224 36.3636364 22.3776224 26.5734266 12.5874126 26.5734266"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
