import jstz from 'jstz';
import { call } from 'redux-saga/effects';
import { websocketFetch } from './fetch';
import { getCurrentLocale } from '@leagueplatform/locales';

export const configureConnection = ({
  timezone = jstz.determine().name(),
  clientPlatform = 'web',
  apiVersion = 7,
  locale = getCurrentLocale(),
} = {}) =>
  call(
    websocketFetch,
    'configure_connection',
    {
      tz_name: timezone,
      client_platform: clientPlatform,
      locale,
    },
    {
      api_version: apiVersion,
    },
  );
