export const scriptFetch = src =>
  new Promise((resolve, reject) => {
    // Check if the script has already been added to the DOM, if so, remove it
    // so it can get re-initialized
    const existingScript = document.getElementById(src);
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.id = src;
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', e => {
      script.remove();
      reject(e);
    });

    // Add script to document body
    document.body.appendChild(script);
  });
