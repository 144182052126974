import { cloneDeep } from 'lodash';
import {
  SET_GROUP_CREDIT_CONTRACT,
  SET_GROUP_BILLING_CONTACT,
  SET_INVOICE_PAYMENT,
  SET_GROUP_SUBSCRIPTION_RATES,
  RESET_UPDATE_STATUS,
  SET_GROUP_BILLING_PREFERENCE,
  GET_EMPLOYER_OUTSTANDING_BALANCE,
} from './types';
import { EMPLOYER_BILLING_LOADING, EMPLOYER_BILLING_READY } from '../types';
import {
  SET_BILLING_DIVISION,
  REMOVE_BILLING_DIVISION,
} from '../../../../employer-experience-v2/employer-divisions/employer-divisions.types';

const applyAddBillingDivision = (state, { payload }) => {
  const matchingIdIndex = state.employer.billingDivisions.findIndex(
    division =>
      division.billingDivisionId ===
      payload.info.billing_division.billing_division_id,
  );
  const billingDivisions =
    matchingIdIndex >= 0
      ? state.employer.billingDivisions.map((division, idx) =>
          idx === matchingIdIndex
            ? { ...division, name: payload.info.billing_division.name }
            : division,
        )
      : [
          ...state.employer.billingDivisions,
          {
            billingDivisionId:
              payload.info.billing_division.billing_division_id,
            name: payload.info.billing_division.name,
          },
        ];

  return {
    ...state,
    employer: {
      ...state.employer,
      billingDivisions,
    },
  };
};

const applyRemoveBillingDivision = (state, { payload }) => {
  return {
    ...state,
    employer: {
      ...state.employer,
      billingDivisions: state.employer.billingDivisions.filter(
        division =>
          division.billingDivisionId !== payload.info.billing_division_id,
      ),
    },
  };
};

const SUCCESS = 'success';
const START = 'start';
const ERROR = 'error';

const applySetGroupCreditContractSuccess = state => {
  return {
    ...state,
    billingUpdateStatus: {
      ...state.billingUpdateStatus,
      prepayment: SUCCESS,
    },
  };
};

const applySetGroupCreditContractStart = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.prepayment = START;
  return newState;
};

const applySetGroupCreditContractError = state => {
  const newState = cloneDeep(state);
  newState.employer.errorSetGroupCreditContract = true;
  newState.billingUpdateStatus.prepayment = ERROR;
  return newState;
};

const applySetBillingContactError = state => {
  const newState = cloneDeep(state);
  newState.employer.errorSetBillingContact = true;
  newState.billingUpdateStatus.contact = ERROR;
  return newState;
};

const applySetBillingContactSuccess = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.contact = 'success';
  return newState;
};

const applySetBillingContactStart = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.contact = 'start';
  return newState;
};

const applySetInvoicePaymentStart = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.payment = 'start';
  return newState;
};

const applySetInvoicePaymentSuccess = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.payment = 'success';
  return newState;
};

const applySetInvoicePaymentError = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.payment = ERROR;
  newState.employer.errorSetInvoicePaymentError = true;
  return newState;
};

const applySetBillingPreferenceStart = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.billingPreference = 'start';
  return newState;
};

const applySetBillingPreferenceSucceeded = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.billingPreference = 'success';
  return newState;
};

const applySetBillingPreferenceError = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.billingPreference = ERROR;
  newState.employer.errorSetBillingPreferenceError = true;
  return newState;
};

const applySetSubRatesStart = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.fees = 'start';
  return newState;
};

const applySetSubRatesSuccess = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.fees = 'success';
  return newState;
};

const applySetSubRatesError = state => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus.fees = ERROR;
  return newState;
};

const resetUpdateStatus = (state, action) => {
  const newState = cloneDeep(state);
  newState.billingUpdateStatus[action.payload] = 'initial';
  return newState;
};

const applyGetEmployerOutstandingBalanceStarted = state => {
  return {
    ...state,
    employerOutstandingBalanceReady: false,
    employerOutstandingBalanceError: false,
  };
};

const applyGetEmployerOutstandingBalanceSucceeded = (state, action) => {
  return {
    ...state,
    employerOutstandingBalance: action.payload.outstandingBalance,
    employerOutstandingBalanceReady: true,
    employerOutstandingBalanceError: false,
  };
};

const applyGetEmployerOutstandingBalanceErrored = (state, action) => {
  return {
    ...state,
    employerOutstandingBalanceReady: true,
    employerOutstandingBalanceError: true,
    reason: action.error,
  };
};

export const billingReducer = (state, action) => {
  if (!action) return state;
  switch (action.type) {
    case EMPLOYER_BILLING_LOADING:
      return { ...state, billingReady: false };
    case EMPLOYER_BILLING_READY:
      return { ...state, billingReady: true };
    case SET_BILLING_DIVISION.SUCCEEDED:
      return applyAddBillingDivision(state, action);
    case REMOVE_BILLING_DIVISION.SUCCEEDED:
      return applyRemoveBillingDivision(state, action);
    case SET_GROUP_BILLING_PREFERENCE.STARTED:
      return applySetBillingPreferenceStart(state, action);
    case SET_GROUP_BILLING_PREFERENCE.SUCCEEDED:
      return applySetBillingPreferenceSucceeded(state, action);
    case SET_GROUP_BILLING_PREFERENCE.ERRORED:
      return applySetBillingPreferenceError(state, action);
    case SET_GROUP_CREDIT_CONTRACT.STARTED:
      return applySetGroupCreditContractStart(state, action);
    case SET_GROUP_CREDIT_CONTRACT.SUCCEEDED:
      return applySetGroupCreditContractSuccess(state, action);
    case SET_GROUP_CREDIT_CONTRACT.ERRORED:
      return applySetGroupCreditContractError(state, action);
    case SET_GROUP_BILLING_CONTACT.STARTED:
      return applySetBillingContactStart(state, action);
    case SET_GROUP_BILLING_CONTACT.SUCCEEDED:
      return applySetBillingContactSuccess(state, action);
    case SET_GROUP_BILLING_CONTACT.ERRORED:
      return applySetBillingContactError(state, action);
    case SET_INVOICE_PAYMENT.STARTED:
      return applySetInvoicePaymentStart(state, action);
    case SET_INVOICE_PAYMENT.SUCCEEDED:
      return applySetInvoicePaymentSuccess(state, action);
    case SET_INVOICE_PAYMENT.ERRORED:
      return applySetInvoicePaymentError(state, action);
    case SET_GROUP_SUBSCRIPTION_RATES.STARTED:
      return applySetSubRatesStart(state, action);
    case SET_GROUP_SUBSCRIPTION_RATES.SUCCEEDED:
      return applySetSubRatesSuccess(state, action);
    case SET_GROUP_SUBSCRIPTION_RATES.ERRORED:
      return applySetSubRatesError(state, action);
    case RESET_UPDATE_STATUS:
      return resetUpdateStatus(state, action);
    case GET_EMPLOYER_OUTSTANDING_BALANCE.STARTED:
      return applyGetEmployerOutstandingBalanceStarted(state);
    case GET_EMPLOYER_OUTSTANDING_BALANCE.SUCCEEDED:
      return applyGetEmployerOutstandingBalanceSucceeded(state, action);
    case GET_EMPLOYER_OUTSTANDING_BALANCE.ERRORED:
      return applyGetEmployerOutstandingBalanceErrored(state, action);
    default:
      return state;
  }
};
