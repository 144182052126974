import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getDependents = ({
  groupId,
  userId = null,
  includeSuspended = false,
  sortByActionableDexInvitationStatus = false,
}) =>
  call(websocketFetch, 'get_dependents', {
    user_id: userId,
    group_id: groupId,
    include_suspended: includeSuspended,
    sort_by_actionable_dex_invitation_status:
      sortByActionableDexInvitationStatus,
  });
