import { cloneDeep, forEach, get, mapKeys, reduce, keyBy } from 'lodash';
import { get as prop } from 'lodash/fp';

import {
  createValidator,
  createFormGenerator,
  createFormValueFormatter,
} from 'common/adaptive-forms';
import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import Submit from './submit.view';

export const selectEmployeeBenefitsData = state =>
  get(state, 'apps.employer-experience.employeeBenefits.data', {});

export const selectEmployeeBenefitsClasses = state =>
  get(state, 'apps.employer-experience.employeeClasses.classes', {});

export const selectEmployeeBenefitsEntities = createSelector(
  selectEmployeeBenefitsData,
  data => get(data, 'entities.benefits', {}),
);

export const selectEmployeeBenefitsIds = createSelector(
  selectEmployeeBenefitsData,
  data => data.result || [],
);

export const selectEmployeeBenefits = createSelector(
  selectEmployeeBenefitsEntities,
  benefits => benefits,
);

export const selectEmployerBenefitPlans = state =>
  state.apps['employer-experience']?.employeeBenefits?.plans?.entities?.plans ??
  {};

export const selectAllStatusesEmployerBenefitPlans = state => {
  return (
    state.apps['employer-experience']?.employeeBenefits?.allStatusesPlans
      ?.entities?.plans ?? {}
  );
};

export const selectEmployeeBenefitSummaries = state =>
  get(
    state,
    'apps.employer-experience.employeeBenefits.summaries.entities.summaries',
    {},
  );

export const selectEmployeeClasses = createSelector(
  selectEmployeeBenefitsClasses,
  classes => {
    const classesArr = [];
    mapKeys(classes, (value, key) => {
      const obj = {
        text: value,
        value: key,
        key,
      };
      classesArr.push(obj);
    });
    return classesArr;
  },
);

export const selectUploadedDocument = prop(
  'apps.employer-experience.ui.employeeBenefits.benefitDocument',
);

export const selectUserReady = state =>
  get(state, 'apps.employer-experience.employeeProfile.data');

export const selectUserCatalogue = state =>
  get(
    state,
    'apps.employer-experience.employeeCatalogue.data.entities.catalogue',
    {},
  );

export const selectGroupId = state =>
  get(state, 'apps.employer-experience.pages.employerDetails.employer.groupId');

export const selectLoadingBenefits = prop(
  'apps.employer-experience.ui.employeeBenefits.fetchingUserBenefits',
);

export const selectEmployeeBenefitsError = prop(
  'apps.employer-experience.ui.employeeBenefits.error',
);

export const selectAddingEmployeeBenefit = prop(
  'apps.employer-experience.ui.employeeBenefits.adding',
);
export const selectAddedEmployeeBenefit = prop(
  'apps.employer-experience.ui.employeeBenefits.added',
);

export const selectUpdatingEmployeeBenefit = prop(
  'apps.employer-experience.ui.employeeBenefits.updating',
);
export const selectUpdatedEmployeeBenefit = prop(
  'apps.employer-experience.ui.employeeBenefits.updated',
);

export const selectRemovingEmployeeBenefit = prop(
  'apps.employer-experience.ui.employeeBenefits.removing',
);
export const selectRemovedEmployeeBenefit = prop(
  'apps.employer-experience.ui.employeeBenefits.removed',
);

export const selectUserId = prop(
  'apps.employer-experience.employeeProfile.data.user_id',
);

export const selectEmployeeProfile = prop(
  'apps.employer-experience.employeeProfile.data.user_profile',
);

export const selectEmployeeBenefitClass = createSelector(
  [prop('apps.employer-experience.employeeProfile.data.groups'), selectGroupId],
  (groups, groupId) =>
    groups && groups.length > 0 && groupId
      ? groups.find(group => group.group_id === groupId).benefit_class_id
      : null,
);

export const selectEmployeeDependentClass = createSelector(
  [prop('apps.employer-experience.employeeProfile.data.groups'), selectGroupId],
  (groups, groupId) =>
    groups && groups.length > 0 && groupId
      ? groups.find(group => group.group_id === groupId).dependent_class
      : null,
);

export const selectChangedEmployeeBenefitClass = prop(
  'apps.employer-experience.ui.employeeBenefits.changedClass',
);

export const selectChangedEmployeeBenefitClassMessage = prop(
  'apps.employer-experience.ui.employeeBenefits.changedClassMessage',
);

export const selectUserBenefitDocuments = state =>
  get(
    state,
    'apps.employer-experience.employeeBenefitDocuments.data.entities.documents',
    {},
  );
export const selectUserBenefitDocumentsReady = state =>
  get(
    state,
    'apps.employer-experience.ui.employeeBenefits.userBenefitDocumentsReady',
  );

export const selectLoading = createSelector(
  [selectLoadingBenefits, selectUserBenefitDocumentsReady],
  (loadingBenefits, documentsReady) => loadingBenefits && !documentsReady,
);

export const mapPropsToValues = props =>
  createFormValueFormatter(props.schema)(props.values);

export const selectLayout =
  name =>
  ({ layouts }) =>
    layouts[name];

export const selectUserBenefitTypesSchemas = state =>
  get(state, 'apps.employer-experience.benefitTypesSchemas.users', {});

export const selectUserBenefitSchema = name =>
  createSelector(selectUserBenefitTypesSchemas, schemas => schemas[name]);

export const createValidatorSelector = name =>
  createSelector(selectUserBenefitSchema(name), createValidator);

export const createFormGeneratorSelector = (
  benefitType,
  layoutName,
  customComponents = {},
  customSchemaSpecs = {},
  labelsToBeOmitted = [],
) =>
  createSelector(
    [selectUserBenefitSchema(benefitType), selectLayout(layoutName)],
    (schema, layout) => {
      return createFormGenerator({
        schema: { ...schema, ...customSchemaSpecs },
        layout,
        customComponents: {
          submit: Submit,
          ...customComponents,
        },
        labelsToBeOmitted,
      });
    },
  );

export const selectEmployeeBenefitsWithDependentCoverage = createSelector(
  selectEmployeeBenefits,
  selectEmployeeDependentClass,
  (benefits, employeeDependentClass) => {
    const byType = cloneDeep(benefits);
    forEach(byType, benefit => {
      if (benefit.parent.benefit_type.indexOf('league_spending_') === 0) {
        byType[benefit.id] = {
          ...benefit,
          dependent_class_coverage: employeeDependentClass,
        };
      }
    });

    return byType;
  },
);

export const selectSelectedEmployeeClass = createSelector(
  selectChangedEmployeeBenefitClass,
  selectEmployeeBenefitClass,
  (newClass, currentClass) => {
    return newClass || currentClass;
  },
);

export const selectBenefitsGroupedByPlanId = createSelector(
  selectEmployeeBenefitsWithDependentCoverage,
  benefits => {
    return Object.values(benefits).reduce((list, benefit) => {
      const planId = benefit?.parent?.plan_id || 'unknown';
      return {
        ...list,
        [planId]: [...(list[planId] || []), benefit],
      };
    }, {});
  },
);

export const selectGroupedPlansWithBenefits = createSelector(
  selectAllStatusesEmployerBenefitPlans,
  selectBenefitsGroupedByPlanId,
  selectSelectedEmployeeClass,
  (plans, indexedBenefitsByPlan, selectedClass) => {
    const indexedPlansAndBenefits = reduce(
      indexedBenefitsByPlan,
      (list, indexedBenefits, planId) => {
        return {
          ...list,
          [planId]: {
            classId: plans?.[planId]?.benefit_class_id || 'unknown',
            planId,
            planName: plans?.[planId]?.name || 'Unknown Plan',
            benefits: indexedBenefits,
          },
        };
      },
      {},
    );

    // Here we add to the list the Plans that have no Benefits,
    // but are related to the current selected Class of the Employee.
    Object.values(plans).forEach(plan => {
      if (
        !indexedPlansAndBenefits[plan.id] &&
        plan.benefit_class_id === selectedClass
      ) {
        indexedPlansAndBenefits[plan.id] = {
          classId: plan.benefit_class_id,
          planId: plan.id,
          planName: plan.name,
          benefits: [],
        };
      }
    });

    return indexedPlansAndBenefits;
  },
);

export const sortGroupedBenefits = (groupedBenefits, selectedClass) => {
  // Orders the list to move the groups related to the selected Class
  // to the beginning
  return groupedBenefits.sort((a, b) => {
    if (a.classId === selectedClass && b.classId !== selectedClass) {
      return -1;
    }
    if (a.classId !== selectedClass && b.classId === selectedClass) {
      return 1;
    }
    return 0;
  });
};

export const selectEmployeeGroupedBenefits = createSelector(
  selectEmployeeClasses,
  selectGroupedPlansWithBenefits,
  selectSelectedEmployeeClass,
  (classes, indexedPlansAndBenefits, selectedClass) => {
    const indexedClasses = keyBy(classes, 'key');
    const groupedBenefits = reduce(
      indexedPlansAndBenefits,
      (list, indexedGroup) => {
        const existingClass = indexedClasses[indexedGroup.classId];
        const updatedGroup = {
          ...indexedGroup,
          className: existingClass?.text || 'Unknown Class',
        };

        return [...list, updatedGroup];
      },
      [],
    );

    return sortGroupedBenefits(groupedBenefits, selectedClass);
  },
);
