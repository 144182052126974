import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_DASHBOARD_URL } from '../employer-insights.action-types';
import {
  selectDashboardURLById,
  selectIsLoadingDashboardURL,
} from '../employer-insights.selectors';

export const useInsightsDashboardURL = ({ dashboardId, groupId }) => {
  const isLoadingDashboardURL = useSelector(selectIsLoadingDashboardURL);

  const dashboardURL = useSelector(state =>
    selectDashboardURLById(state, dashboardId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId && dashboardId) {
      dispatch(GET_DASHBOARD_URL.request({ groupId, dashboardId }));
    }
  }, [dispatch, groupId, dashboardId]);

  return {
    isLoadingDashboardURL,
    dashboardURL,
  };
};
