import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import { noop } from 'lodash';
import styled from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { FormattedMessage } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import {
  vars,
  Box,
  Input,
  Textarea,
  MultipleImageInput,
  Modal,
  Select,
  Text,
  Image,
} from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { DATE_FORMAT } from 'common/adaptive-forms';
import ERROR_ICON from 'common/ui-kit/assets/error-icon.svg';
import { UserDocumentForm } from 'common/components/user-documents/user-document-forms/user-document-forms.container';
import {
  isImageContentType,
  OTHER,
  IDENTIFICATION,
} from '@leagueplatform/web-common';
import { getContentUrl } from '@leagueplatform/league-content-api';
import PDFIcon from 'apps/public/assets/pdf-icon.svg';

const TextInput = styled(Input)`
  height: 3rem;
  width: 100%;
  margin: 0;
  border: 1px solid ${vars.colour.greyLight};
  font-size: 1rem;
  ${({ hasError }) => hasError && `border-color: ${vars.colour.red};`};

  &:focus {
    background-image: none;
  }
`;

const DocTypeInput = styled(Select)`
  && {
    margin: 0;

    ${({ hasError }) => hasError && `border-color: ${vars.colour.red};`};

    .rrs {
      .rrs__label {
        height: 3rem;
        margin: 0;
        padding: 0.9rem 3.6rem 0 1.6rem;
        line-height: 1.15rem;

        .svg-inline--fa {
          position: absolute;
          right: 1.6rem;
          top: 40%;
          color: ${vars.colour.greyLight};
          transition: all 200ms ease-out;
        }
      }
      .rrs__label__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
        color: ${vars.colour.black};
      }
      .rrs__options {
        top: 3rem;

        .rrs__option {
          padding: 1rem;
          font-weight: 300;
          min-height: 3.5rem;
        }
      }
    }
  }
`;

const ExpiryDatePicker = styled(DayPickerInput)`
  width: 100%;
  height: 3rem;
  padding: 1rem;
  margin: 0;
  border: 1px solid ${vars.colour.greyLight};
  font-family: ${vars.text.fontFamily};
  background-position: right 1rem top 50%;
  background-size: 2rem;
  background-repeat: no-repeat;
  ${({ haserror }) =>
    haserror &&
    `border-color: ${vars.colour.red}; background-image: url(${ERROR_ICON});`};
  &:focus {
    background-image: none;
  }
`;

const NotesTextarea = styled(Textarea)`
  min-height: 3rem;
  border-radius: 0;
  border: 1px solid ${vars.colour.greyLight};
`;

const Label = styled.label`
  font-weight: 500;
  margin: 1rem 0 0 0;
  display: block;
  ${({ required }) =>
    required &&
    `
  &:after{
    content: ' *';
    color: ${vars.colour.red};
  }
  `};

  ${({ nomargin }) => nomargin && `margin: 0;`};
`;

const LabelNote = styled.span`
  font-size: 0.9em;
  font-weight: 300;
  font-style: italic;
  margin-left: 0.3rem;
`;

const FormButton = styled(Button)`
  border-radius: 5px;
  height: 3rem;
  font-size: 1.2rem;
  min-width: auto;
  width: auto;
  margin-left: 1rem;
  padding: 0.5rem 1.5rem;
`;

const CancelButton = styled(FormButton)`
  background-color: ${vars.colour.greyLighter};
  border-color: ${vars.colour.greyLighter};
  color: ${vars.colour.purpleText};
  &:hover:not([disabled]) {
    background-color: ${vars.colour.greyLight};
    border-color: ${vars.colour.greyLight};
    color: ${vars.colour.purpleText};
  }
`;

const WarningButton = styled(FormButton)`
  background-color: ${vars.colour.red};
  border-color: ${vars.colour.red};
  color: ${vars.colour.white};
  &:hover:not([disabled]) {
    background-color: ${vars.colour.darkRed};
    border-color: ${vars.colour.darkRed};
    color: ${vars.colour.white};
  }
`;

const StripeButton = styled(FormButton)`
  margin: 1.2rem 0 0;
`;

const DeleteButtonBox = styled(Box)`
  flex-grow: 1;
`;

const StyledBox = styled(Box)`
  flex-flow: row nowrap;
  line-height: 14px;
`;

const StyledBody = styled(Modal.Body)`
  padding: 24px;
`;

const CenteredImage = styled(Image).attrs({
  height: '200px',
  width: '200px',
})`
  margin: 0 auto 1.6rem auto;
`;

const FileBox = styled(Flex)`
  width: 100px;
  height: 100px;
  border: 1px solid ${vars.colour.greyLighter};
  border-radius: 3px;
  margin: 0.5rem;
  margin-left: 0;
  justify-content: center;
  align-items: center;
`;

export const AdminFormPresenter = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFile,
  handleBlur,
  handleSubmit,
  documentTypes,
  dateError,
  userId,
  contentId,
  checkDate,
  closeUploadModal,
  cancelEditUserDocument,
  confirmRemoveUserDocument,
  intl,
  currentUserDocument,
  requestSubmitToStripe,
  viewFile,
  isPinningClaimDocument, // When pinning claim document from claims tool do not show image input
  contentUrl, // Only given when a claim document is pinned. Used to show document preview to confirm the right document is chosen
}) => {
  return (
    <Modal.Container>
      <Modal.Header>
        <Modal.HeaderTitle>
          <FormattedMessage
            id={
              currentUserDocument
                ? 'DOCUMENT_INFORMATION'
                : 'UPLOAD_SUPPORTING_DOCUMENT'
            }
          />
        </Modal.HeaderTitle>
      </Modal.Header>
      <StyledBody>
        <StyledBox>
          {isPinningClaimDocument && <CenteredImage url={contentUrl} />}
          {/* `isPinningClaimDocument` is only true when trying to add user document from claims tool. Image uploader should be hidden. */}
          {currentUserDocument && (
            <Box mb={2}>
              <Text fontWeight={500}>Document Files</Text>
              <Flex wrap>
                {currentUserDocument.uploadedFiles.map((file, index) => {
                  const fileUrl = getContentUrl(file.contentId);
                  return (
                    <a
                      key={file.contentId}
                      href={fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FileBox>
                        {isImageContentType(file.contentType) ? (
                          <Image width="100px" height="100px" url={fileUrl} />
                        ) : (
                          <>
                            <Image url={PDFIcon} width="50px" height="50px" />
                            <Text color="neutral.grayDark">{index + 1}</Text>
                          </>
                        )}
                      </FileBox>
                    </a>
                  );
                })}
              </Flex>
            </Box>
          )}

          {currentUserDocument || isPinningClaimDocument ? (
            <Text>
              <FormattedMessage id="SUPPORTING_DOCUMENTS_CAN_BE_THINGS" />
            </Text>
          ) : (
            <MultipleImageInput
              previewOnChange
              onFilePreviewSuccess={multiInputData => {
                const formatFiles = multiInputData.map(file => {
                  return file?.file || file;
                });
                setFieldValue('files', formatFiles);
              }}
              width="324px"
              height="160px"
              setFile={setFile}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              intl={intl}
              viewFile={viewFile}
              isEditing={!!currentUserDocument}
              documentType={values.documentType}
            />
          )}
          {values.documentType === IDENTIFICATION && (
            <StripeButton
              secondary
              onClick={() => {
                requestSubmitToStripe(currentUserDocument);
              }}
            >
              <FormattedMessage id="SUBMIT_TO_STRIPE" />
            </StripeButton>
          )}
        </StyledBox>

        <Flex>
          <Box width={1 / 2} pr={1}>
            <Label required for="name">
              <FormattedMessage id="DOCUMENT_NAME" />
            </Label>
            <TextInput
              name="name"
              value={values.name}
              onChange={e => setFieldValue('name', e.target.value)}
              onBlur={handleBlur}
              hasError={touched.name && errors.name}
            />
          </Box>

          <Box width={1 / 2}>
            <Label required for="documentType">
              <FormattedMessage id="DOCUMENT_TYPE" />
            </Label>
            <DocTypeInput
              name="documentType"
              options={documentTypes}
              noSelectionLabel={' '}
              selectedValue={values.documentType}
              onChange={e => {
                const { value } = e;
                setFieldValue('documentType', value);
                if (value !== OTHER) {
                  setFieldValue('documentTypeOtherDescription', '');
                }
              }}
              hasError={touched.documentType && errors.documentType}
            />
          </Box>
        </Flex>

        <Flex>
          <Box width={1 / 2} pr={1}>
            <Label for="expirationDate" nomargin>
              <FormattedMessage id="EXPIRY_DATE" />
            </Label>
            <ExpiryDatePicker
              autoComplete="off"
              name="expirationDate"
              placeholder={moment().format(DATE_FORMAT)}
              format={DATE_FORMAT}
              value={
                values.expirationDate
                  ? moment(values.expirationDate).format(DATE_FORMAT)
                  : null
              }
              onDayChange={value => {
                setFieldValue('expirationDate', value ? value.toJSON() : null);
              }}
              onBlur={e => checkDate(e.target.value)}
              haserror={touched.expirationDate && dateError ? 1 : undefined}
            />
          </Box>
          {values.documentType === OTHER && (
            <Box width={1 / 2}>
              <Label required for="documentTypeOtherDescription" nomargin>
                <FormattedMessage id="WHAT_TYPE" />
              </Label>
              <TextInput
                name="documentTypeOtherDescription"
                value={values.documentTypeOtherDescription}
                onChange={e =>
                  setFieldValue('documentTypeOtherDescription', e.target.value)
                }
                onBlur={handleBlur}
                hasError={
                  touched.documentTypeOtherDescription &&
                  errors.documentTypeOtherDescription
                }
              />
            </Box>
          )}
        </Flex>

        <Flex flexDirection="column">
          <Label for="notes">
            <FormattedMessage id="NOTES" />
            <LabelNote>
              <FormattedMessage id="VISIBLE_TO_MEMBER" />
            </LabelNote>
          </Label>
          <NotesTextarea
            name="notes"
            value={values.notes}
            onChange={e => setFieldValue('notes', e.target.value)}
          />
        </Flex>
      </StyledBody>
      <Modal.Footer>
        {currentUserDocument && (
          <DeleteButtonBox>
            <WarningButton
              onClick={() => confirmRemoveUserDocument(userId, contentId)}
            >
              <FormattedMessage id="DELETE" />
            </WarningButton>
          </DeleteButtonBox>
        )}
        <CancelButton
          onClick={
            currentUserDocument ? cancelEditUserDocument : closeUploadModal
          }
        >
          <FormattedMessage id="CANCEL" />
        </CancelButton>
        <FormButton primary onClick={handleSubmit}>
          <FormattedMessage
            id={currentUserDocument ? 'SAVE_CHANGES' : 'UPLOAD_DOCUMENT'}
          />
        </FormButton>
      </Modal.Footer>
    </Modal.Container>
  );
};

AdminFormPresenter.propTypes = {
  contentId: PropTypes.string,
  touched: PropTypes.shape({
    documentType: PropTypes.bool,
    name: PropTypes.bool,
    documentTypeOtherDescription: PropTypes.bool,
    expirationDate: PropTypes.bool,
  }),
  currentUserDocument: PropTypes.shape({
    documentType: PropTypes.string,
    name: PropTypes.string,
    documentTypeOtherDescription: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    uploadedFiles: PropTypes.array,
  }),
  setFile: PropTypes.func.isRequired,
  cancelEditUserDocument: PropTypes.func.isRequired,
  dateError: PropTypes.bool.isRequired,
  checkDate: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  confirmRemoveUserDocument: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  values: PropTypes.shape({
    documentType: PropTypes.string,
    name: PropTypes.string,
    expirationDate: PropTypes.string,
    notes: PropTypes.string,
    documentTypeOtherDescription: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    documentType: PropTypes.bool,
    name: PropTypes.bool,
    documentTypeOtherDescription: PropTypes.bool,
  }),
  closeUploadModal: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  intl: intlShape.isRequired,
  requestSubmitToStripe: PropTypes.func,
  viewFile: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  isPinningClaimDocument: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  contentUrl: PropTypes.string,
};

AdminFormPresenter.defaultProps = {
  contentId: null,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  currentUserDocument: null,
  requestSubmitToStripe: noop,
  viewFile: noop,
};

export const AdminForm = compose(UserDocumentForm)(AdminFormPresenter);
