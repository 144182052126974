import React from 'react';
import {
  spaceInPx,
  vars,
  Text,
  Box,
  ICONS,
  Icon,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { intlShape } from '@leagueplatform/locales';
import PropTypes from 'prop-types';
import QLEConfigurationCard from '../qle-configuration-card/qle-configuration-card.container';
import styled from 'styled-components';
import { HREmailInput } from '../hr-email-input/hr-email-input.view';

const StyledForm = styled.form`
  display: flex;
  margin: ${spaceInPx(3)} 0;
`;

const BenefitClassItem = styled(Flex)`
  border-style: solid;
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  margin-top: 3px;
`;

/**
 * In charge of:
 * 1) Displaying HR admin email input
 * 2) List all benefit classes
 * 3) Saving a reference to selected benefit class,
 */
export const BenefitClassSelection = ({
  match,
  history,
  benefitClasses,
  handleSubmit,
  errors,
  touched,
  handleChange,
  handleBlur,
  values,
  setIsEditHREmailEnabled,
  isEditHREmailEnabled,
  intl,
}) => {
  const { formatMessage } = intl;
  const goToConfigurationTable = ({ benefitClassId }) => {
    // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
    history.push(`${match.url}/${benefitClassId}`);
  };

  return (
    <QLEConfigurationCard paddingAroundContent showBreadcrumbs={false}>
      <Box color="navyDark">
        <Text fontSize={2} lineHeight="24px" mb={3}>
          {formatMessage({ id: 'ONLY_CERTAIN_LIFE_EVENTS_QUALIFY_A_MEMBER' })}
        </Text>
        <Text fontWeight="bold" mb={1}>
          {formatMessage({ id: 'HR_EMAILS_FOR_QLE_APPROVALS' })}
        </Text>
        <hr />
        <Text mt={1} color="darkGray">
          {formatMessage({ id: 'AUTOMATIC_EMAIL_SENT_TO_HE_EMAILS_PROVIDED' })}
        </Text>
        <StyledForm autoComplete="off">
          <HREmailInput
            value={values.qleApprovalEmails}
            touched={touched}
            errors={errors}
            intl={intl}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            isEditHREmailEnabled={isEditHREmailEnabled}
            setIsEditHREmailEnabled={setIsEditHREmailEnabled}
          />
        </StyledForm>
        <Flex flexDirection="column">
          {benefitClasses.map(({ name, id }) => {
            return (
              <BenefitClassItem
                key={id}
                p="24px"
                align="center"
                justify="space-between"
                borderColor="greyLighter"
                borderRadius="4px"
                borderWidth="1px"
                onClick={() => {
                  goToConfigurationTable({ benefitClassId: id });
                }}
              >
                <Text lineHeight={4} fontSize={2} fontWeight="bold">
                  {name}
                </Text>
                <StyledIcon
                  icon={ICONS.RIGHT_CHEVRON}
                  size="1.2rem"
                  stroke={vars.colour.greyDark}
                />
              </BenefitClassItem>
            );
          })}
        </Flex>
      </Box>
    </QLEConfigurationCard>
  );
};

BenefitClassSelection.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  benefitClasses: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    qleApprovalEmails: PropTypes.string,
  }),
  touched: PropTypes.shape({
    qleApprovalEmails: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    qleApprovalEmails: PropTypes.string,
  }).isRequired,
  setIsEditHREmailEnabled: PropTypes.func.isRequired,
  isEditHREmailEnabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

BenefitClassSelection.defaultProps = {
  benefitClasses: [],
  errors: {},
};
