import React from 'react';
import PropTypes from 'prop-types';
import { Modal, CloseButton } from 'apps/employer-experience/ui-kit';
import { Box } from '@leagueplatform/genesis-commons';
import { Coverage } from '@leagueplatform/wallet';

const BenefitCoverageCategoryModal = ({
  onClose,
  targetUserId,
  targetBenefitId,
  onCategorySelect,
}) => (
  <div className="BenefitCoverage">
    <Modal onClose={onClose}>
      <CloseButton top="8px" right="8px" onClick={onClose} />
      <Box padding="half" overflowY="scroll" maxHeight="100%">
        <Coverage
          embedded
          userId={targetUserId}
          benefitId={targetBenefitId}
          onCategorySelect={id => {
            onCategorySelect(id);
            onClose();
          }}
        />
      </Box>
    </Modal>
  </div>
);

BenefitCoverageCategoryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  targetUserId: PropTypes.string.isRequired,
  targetBenefitId: PropTypes.string.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
};

export default BenefitCoverageCategoryModal;
