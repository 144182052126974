import {
  REQUEST_REMOVE_USER_BENEFIT,
  UPDATE_USER_BENEFIT_STATUS,
  UPDATE_ALL_USER_BENEFITS_STATUS,
  USER_BENEFIT_CLASS_CHANGED,
  REQUEST_ADJUST_BENEFIT_END_DATE,
} from './benefits.types';

export const removeBenefit = payload => ({
  type: REQUEST_REMOVE_USER_BENEFIT,
  payload,
});

export const adjustBenefitEndDate = payload => ({
  type: REQUEST_ADJUST_BENEFIT_END_DATE,
  payload,
});

export const updateBenefitStatus = payload => ({
  type: UPDATE_USER_BENEFIT_STATUS,
  payload,
});

export const updateAllBenefitStatus = payload => ({
  type: UPDATE_ALL_USER_BENEFITS_STATUS,
  payload,
});

export const setUserBenefitClass = payload => {
  return {
    type: USER_BENEFIT_CLASS_CHANGED,
    payload,
  };
};
