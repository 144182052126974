import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Generate a new pass code for Interac account.
 *
 * @param {string} paymentCredentialsId: Interac payment credentials id
 */
export const generateInteracCode = ({ paymentCredentialsId }) =>
  call(websocketFetch, 'generate_interac_code', {
    payment_credentials_id: paymentCredentialsId,
  });
