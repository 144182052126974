import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import { colour, spaceInPx, Box, VisuallyHidden } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { KEY } from '@leagueplatform/web-common';

const StyledCarousel = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  min-height: 700px;
`;

const Dots = styled(Flex)`
  margin-top: ${spaceInPx(3)};
  align-items: center;
`;

const Dot = styled(Box)`
  background-color: ${colour('neutral.gray')};
  border-radius: 50%;
  height: ${spaceInPx(1)};
  width: ${spaceInPx(1)};
  margin: 0 ${spaceInPx(1)};
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      height: 12px;
      width: 12px;
      background-color: ${colour('purple')};
    `};
`;

// Interval is configurable and set to 5 seconds by default (see default props below)
export const Carousel = ({ children, interval }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isIn, setIsIn] = useState(true);
  const nextIndex = useRef(null);
  const autoPlay = useRef(null);

  useEffect(() => {
    autoPlay.current = setTimeout(() => {
      setIsIn(false);
      nextIndex.current = (activeIndex + 1) % children.length;
    }, interval);
    return () => clearTimeout(autoPlay.current);
  }, [activeIndex, children, interval]);

  const onDotClick = index => {
    setIsIn(true);
    nextIndex.current = index;
    setActiveIndex(index);
    clearTimeout(autoPlay.current);
  };

  // The Transition component provides the children with four states:
  // entering -> entered -> exiting -> exited to make conditional transitions
  return (
    <StyledCarousel>
      <Transition
        in={isIn}
        timeout={1500}
        unmountOnExit
        appear
        onExited={() => {
          setActiveIndex(nextIndex.current);
          clearTimeout(autoPlay.current);
          setIsIn(true);
        }}
      >
        {state =>
          React.cloneElement(children[activeIndex], {
            state,
          })
        }
      </Transition>
      <Dots>
        {children.map((child, index) => (
          <Dot
            onClick={() => onDotClick(index)}
            onKeyDown={({ key }) => {
              if (key === KEY.ENTER) {
                onDotClick(index);
              }
            }}
            role="button"
            key={child.props.heading}
            active={index === activeIndex}
            aria-pressed={index === activeIndex}
            tabIndex="0"
          >
            <VisuallyHidden>{child.props.heading}</VisuallyHidden>
          </Dot>
        ))}
      </Dots>
    </StyledCarousel>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  interval: PropTypes.number,
};

Carousel.defaultProps = {
  interval: 5000,
};
