import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  setGroup,
  getGroup,
  GET_GROUP_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';

export const useNetSuiteId = ({ groupId }) => {
  const { data, isLoading } = useQuery([GET_GROUP_MESSAGE_TYPE, groupId], () =>
    getGroup(groupId),
  );

  const queryClient = useQueryClient();

  const contentMutation = useMutation(
    values => setGroup({ groupId, group: values }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_GROUP_MESSAGE_TYPE, groupId]);
        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          textId: 'SAVED_NETSUITE_ID',
        });
      },
      onError: error => {
        const errorObj = JSON.parse(error.message);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR',
          content: errorObj?.info?.reason,
        });
      },
    },
  );

  const onSubmitNetSuiteId = values => {
    const group = {
      netsuite_id: values.netSuiteId,
    };
    contentMutation.mutate(group);
  };

  return {
    groupData: data?.group,
    onSubmitNetSuiteId,
    isLoading,
  };
};
