import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ADMIN, CLAIMS_AGENT, SUPER_ADMIN } from '@leagueplatform/web-common';
import {
  onlyAdmin,
  onlyClaimsAgent,
  onlyHealthAdmin,
  onlyRoles,
} from 'common/state/user/role-based-redirect.hocs';
import { BenefitPreviewPage } from 'apps/member-experience/member-experience-routes.view';
import {
  FLAGS_ROOT,
  flagDetailRoute,
} from 'apps/employer-experience/pages/flags/flags.route-helpers';
import { TENANT_ID } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectUserTenantId } from 'common/state/user/user.selectors';
import {
  selectUserIsHealthAdmin,
  selectUserIsAdmin,
  selectUserIsClaimsAgent,
} from 'common/state/user/role.selectors';
import { WalletRoutes } from '@leagueplatform/wallet';

const onlyAdminOrClaimsAgent = onlyRoles([ADMIN, CLAIMS_AGENT, SUPER_ADMIN]);

const AsyncEmployerAdminApp = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/league-admin-app.container'
  ),
);

const AsyncClaims = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/Claims'
  ),
);

export const ClaimsPage = onlyClaimsAgent(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncClaims {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncClaimDetails = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/claim-details/claim-details.hoc'
  ),
);

export const ClaimDetailsPage = onlyClaimsAgent(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncClaimDetails {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncEmployers = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/Employers'
  ),
);

export const EmployersPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployers {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncEmployerDetails = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/EmployerDetails/'
  ),
);

export const EmployerDetailsPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployerDetails {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncEditPayContainer = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/EmployerDetails/Payroll/EditPayCalendar/'
  ),
);

export const EditPayContainerPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEditPayContainer {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncScheduledReports = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/EmployerDetails/Payroll/scheduled-reports/scheduled-reports.container'
  ),
);

export const ScheduledReportsPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncScheduledReports {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncNewEmployer = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/EmployerDetails/Profile/NewEmployer'
  ),
);

export const NewEmployerPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncNewEmployer {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncEmployeeSearch = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/employee-search/page.container'
  ),
);

export const EmployeeSearchPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployeeSearch {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncFinanceTools = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/FinanceTools'
  ),
);

export const FinanceToolsPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncFinanceTools {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncScriptHelpers = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/ScriptHelpers'
  ),
);

export const ScriptHelpersPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncScriptHelpers {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncMerchLists = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/MerchLists'
  ),
);

export const MerchListsPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncMerchLists {...props} />
  </AsyncEmployerAdminApp>
));

export const AsyncFlagsList = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/flags/flags-list'
  ),
);

export const FlagsListPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncFlagsList {...props} />
  </AsyncEmployerAdminApp>
));

export const AsyncFlagDetail = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/flags/flag-detail'
  ),
);

export const FlagDetailPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncFlagDetail {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncHealthConciergeTooling = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/HealthConciergeTooling'
  ),
);
// Standalone page for HC nurses, so it doesn't require the sidebar from AsyncEmployerAdminApp
export const HealthConciergeTooling = onlyHealthAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncHealthConciergeTooling {...props} />
));

const AsyncWalletTooling = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employeer-admin' */ 'apps/employer-experience/pages/WalletTooling'
  ),
);

export const WalletToolingPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncWalletTooling {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncMemberTooling = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/MemberTooling'
  ),
);

export const MemberToolingPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncMemberTooling {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncBenefitSelectionsTool = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/benefit-selections-tool/benefit-selections-tool.container'
  ),
);

export const BenefitSelectionToolPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncBenefitSelectionsTool {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncAllocationTool = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/allocation-tool/allocation-tool.container'
  ),
);

export const AllocationToolPage = onlyAdmin(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncAllocationTool {...props} />
  </AsyncEmployerAdminApp>
));

const AsyncYourProfile = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience/pages/your-profile/your-profile.container'
  ),
);

export const YourProfilePage = onlyAdminOrClaimsAgent(props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <AsyncEmployerAdminApp {...props}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncYourProfile {...props} />
  </AsyncEmployerAdminApp>
));

export const AdminRoutes = () => {
  const userTenantId = useSelector(selectUserTenantId);
  const isAdmin = useSelector(selectUserIsAdmin);
  const isHealthAdmin = useSelector(selectUserIsHealthAdmin);
  const isClaimsAgent = useSelector(selectUserIsClaimsAgent);

  return (
    <Switch>
      {isAdmin && userTenantId === TENANT_ID.PC_HEALTH && (
        <Route exact path="/admin">
          <Redirect to="/admin/member-tooling" />
        </Route>
      )}
      {isAdmin && userTenantId === TENANT_ID.LEAGUE && (
        <Route exact path="/admin">
          <Redirect to="/admin/employers" />
        </Route>
      )}
      {isHealthAdmin && (
        <Route exact path="/admin">
          <Redirect to="/admin/health-concierge-tooling" />
        </Route>
      )}
      {isClaimsAgent && (
        <Route exact path="/admin">
          <Redirect to="/admin/claims" />
        </Route>
      )}

      {/* Redirect to support a legacy WR route */}
      <Redirect from="/admin/wallet/user/:userId" to="/admin/wallet/:userId" />

      <Route path="/admin/wallet/:userId" component={WalletRoutes} />
      <Route
        exact
        path="/admin/benefit-preview/:benefitId/:groupId"
        component={BenefitPreviewPage}
      />
      <Route exact path="/admin/claims" component={ClaimsPage} />
      <Route exact path="/admin/claims/:claimId" component={ClaimDetailsPage} />
      <Route exact path="/admin/employers" component={EmployersPage} />
      <Route exact path="/admin/employers/new" component={NewEmployerPage} />
      <Route
        exact
        path="/admin/employers/:groupId/payroll/scheduled-reports/:payrollScheduleId/:payPeriod"
        component={ScheduledReportsPage}
      />
      <Route
        exact
        path="/admin/employers/:groupId/payroll/:scheduleId/edit-schedule"
        component={EditPayContainerPage}
      />
      <Route path="/admin/employers/:groupId" component={EmployerDetailsPage} />
      <Route path="/admin/finance" component={FinanceToolsPage} />
      <Route path="/admin/script-helpers" component={ScriptHelpersPage} />
      <Route path="/admin/wallet-tooling" component={WalletToolingPage} />
      <Route path="/admin/merch-lists" component={MerchListsPage} />
      <Route path={FLAGS_ROOT} exact component={FlagsListPage} />
      <Route path={flagDetailRoute} exact component={FlagDetailPage} />
      <Route exact path="/admin/employees" component={EmployeeSearchPage} />
      <Route
        path="/admin/health-concierge-tooling/:userId?/:tab?"
        component={HealthConciergeTooling}
        strict
      />

      {/* Redirect to support a legacy WR route */}
      <Redirect
        from="/admin/profile/:userId"
        to="/admin/member-tooling/profile/:userId"
      />

      <Route
        strict
        path="/admin/member-tooling/profile/:userId?"
        component={MemberToolingPage}
      />
      <Route
        strict
        path="/admin/member-tooling"
        component={MemberToolingPage}
      />
      <Route
        strict
        path="/admin/benefit-selections/:userId/:groupId/:planId?/:benefitType?"
        component={BenefitSelectionToolPage}
      />
      <Route
        strict
        path="/admin/allocation-dry-run/:userId?/:planId?/:groupId?"
        component={AllocationToolPage}
      />
      <Route strict path="/admin/your-profile" component={YourProfilePage} />
      <Redirect to="/admin" />
    </Switch>
  );
};
