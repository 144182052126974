import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function inviteDependent Send service call to `invite_dependent`.
 * @param {string} dependentId required
 * @param {string} email required param - invite email will be sent to this address
 */
export const inviteDependent = ({ dependentId, email }) =>
  call(websocketFetch, 'invite_dependent', {
    dependent_id: dependentId,
    email,
  });
