import React from 'react';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { DocumentsUploaderField } from 'common/json-schema-form/fields/documents-uploader-field.view';
import { uploadFormDocuments } from '../enrollment-config.actions';

export const DocumentsUploaderContainer = ({ form, ...props }) => {
  const {
    field: { value, name },
  } = props;
  return (
    <DocumentsUploaderField
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      documents={value || []}
      setFieldValue={form.setFieldValue}
      name={name}
    />
  );
};

DocumentsUploaderContainer.propTypes = JSONSchemaFieldPropType;

export const DocumentsUploader = connect(null, {
  uploadDocument: uploadFormDocuments,
})(DocumentsUploaderContainer);
