import { createRequestTypes } from 'common/websocket-redux';

export const GET_DEPENDENT_TERMINATION_TYPE_OPTIONS = createRequestTypes(
  'admin/GET_DEPENDENT_TERMINATION_TYPE_OPTIONS',
);

export const GET_DEPENDENT_ADD_QLE_REASONS = createRequestTypes(
  'admin/GET_DEPENDENT_ADD_QLE_REASONS',
);

export const GET_DEPENDENT_RELATIONSHIP_TYPES = createRequestTypes(
  'admin/GET_DEPENDENT_RELATIONSHIP_TYPES',
);

export const TERMINATE_DEPENDENT = createRequestTypes(
  'admin/TERMINATE_DEPENDENT',
);

export const SET_DEPENDENT = createRequestTypes('admin/SET_DEPENDENT');
