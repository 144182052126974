import { connect } from 'react-redux';
import WalletActions from './wallet-actions.view';
import {
  selectProviderBenefit,
  selectBenefitWalletActions,
  selectPrimaryAction,
  selectMoreActions,
} from '../benefit-wallet.selectors';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withStateHandlers, lifecycle } from 'recompose';
import {
  setWalletActions,
  removeWalletAction,
} from '../benefit-wallet.actions';
import { clone, get, omit, find, findIndex } from 'lodash';
import { SUCCESS } from '../constants';

const withActionsStateHandlers = withStateHandlers(
  ({ moreActions }) => ({
    moreActions,
    openModal: false,
    actionToBeRemoved: null,
  }),
  {
    addMoreAction:
      ({ moreActions }, { benefitWalletActions, addActionDraft }) =>
      () => {
        const list =
          moreActions.length > 0 ? moreActions : benefitWalletActions;
        const lastIndex = list.reduce(
          (max, action) => Math.max(max, action.index),
          list[0].index,
        );

        const newActionCard = {
          index: lastIndex + 1,
          locked: false,
        };
        addActionDraft(newActionCard);

        return {
          moreActions: [...moreActions, newActionCard],
        };
      },
    setMoreActionsList:
      (_, { draftActions, moreActions }) =>
      () => ({
        moreActions: [...moreActions, ...draftActions],
      }),
    toggleModal:
      ({ openModal }) =>
      () => ({
        openModal: !openModal,
      }),
    setActionToBeRemoved: () => actionToBeRemoved => ({
      actionToBeRemoved,
    }),
  },
);

const withDraftState = withStateHandlers(() => ({ draftActions: [] }), {
  addActionDraft:
    ({ draftActions }) =>
    newActionCard => ({
      draftActions: [...draftActions, newActionCard],
    }),
  removeActionDraft:
    ({ draftActions }) =>
    actionCard => ({
      draftActions: draftActions.filter(
        action => action.index !== actionCard.index,
      ),
    }),
  clearActionDraftList: () => () => ({
    draftActions: [],
  }),
});

const withActionsUpdate = lifecycle({
  componentWillReceiveProps({
    providerBenefit,
    benefitWalletActions,
    setMoreActionsList,
    clearActionDraftList,
    removeActionDraft,
  }) {
    if (get(providerBenefit, 'id') !== get(this.props.providerBenefit, 'id')) {
      // switch to a different benefit, clear draft list
      clearActionDraftList();
    }

    if (benefitWalletActions !== this.props.benefitWalletActions) {
      const successAction = benefitWalletActions.find(
        action => action.status === SUCCESS,
      );
      if (successAction) removeActionDraft(successAction);
      setMoreActionsList();
    }
  },
});

const withActionsHandlers = withHandlers({
  onSaveActionCard: props => newAction => {
    const providerId = props.groupId;
    const benefitType = props.providerBenefit.id;
    const actions = clone(
      get(props.providerBenefit.provider_wallet_action, 'actions', []),
    );
    const actionIndex = findIndex(actions, ['key', newAction.key]);

    if (actions.length === 0 || actionIndex < 0) {
      actions.push(omit(newAction, ['locked', 'key', 'status', 'edited']));
    } else {
      actions[actionIndex] = {
        ...omit(actions[actionIndex], ['locked', 'key', 'status', 'edited']),
        label: newAction.label,
        type: newAction.type,
        value: newAction.value,
      };
    }

    props.setWalletActions(
      { providerId, benefitType, actions },
      `${newAction.type}-${newAction.index}`,
    );
  },
  onRemoveActionCard: props => () => {
    const providerId = props.groupId;
    const benefitType = props.providerBenefit.id;
    let actions = clone(
      get(props.providerBenefit.provider_wallet_action, 'actions', []),
    ).filter(action => action.index !== props.actionToBeRemoved.index);

    if (props.actionToBeRemoved.primary) {
      // primary action that just got added wont have index
      actions = actions.filter(action => !action.primary);
    }

    props.removeWalletAction({
      providerId,
      benefitType,
      actions,
    });
  },
  onResetActionCard: props => actionCard => {
    if (find(props.draftActions, ['index', actionCard.index])) {
      // action is in unsaved actions list, remove from unsaved list
      props.removeActionDraft(actionCard);
      props.setMoreActionsList();
    }
  },
});

const withState = connect(
  createStructuredSelector({
    providerBenefit: selectProviderBenefit,
    benefitWalletActions: selectBenefitWalletActions,
    primaryAction: selectPrimaryAction,
    moreActions: selectMoreActions,
  }),
  { setWalletActions, removeWalletAction },
);

export default compose(
  withState,
  withDraftState,
  withActionsStateHandlers,
  withActionsHandlers,
  withActionsUpdate,
)(WalletActions);

/* eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue */
export const __test__ = {
  withActionsStateHandlers,
  withActionsHandlers,
  withDraftState,
};
