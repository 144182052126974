import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getClaims = ({ userId, order, limit = null, statuses }) =>
  call(websocketFetch, 'get_claims', {
    user_id: userId,
    order,
    limit,
    statuses,
  });
