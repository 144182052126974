import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { ADMIN_GET_EMPLOYEE_SCHEMA_MESSAGE_TYPE } from '@leagueplatform/admin-api';

export const adminGetEmployeeSchema = ({
  groupId,
  benefitClassId,
  userId,
  adminEditable,
}) =>
  call(websocketFetch, ADMIN_GET_EMPLOYEE_SCHEMA_MESSAGE_TYPE, {
    group_id: groupId,
    benefit_class_id: benefitClassId,
    user_id: userId,
    admin_editable: adminEditable || false,
  });
