/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React, { forwardRef, useState } from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import css from '@styled-system/css';
import {
  Box,
  BoxProps,
  GenesisTheme,
  TextInput,
} from '@leagueplatform/genesis-commons';
import { optionHasValue, flattenOptions } from './utilities';

import {
  SelectProps,
  OptionType,
  GroupedOptionsType,
  isGroupedOptions,
} from './SelectTypes';
import { DropdownIndicator } from './SelectComponents';

const getTextColor = ({ hasValue = false, isDisabled = false }) => {
  if (isDisabled) return 'interactive.action.disabled';
  return hasValue ? 'onSurface.text.primary' : 'onSurface.text.subdued';
};

interface SelectComponentProps extends BoxProps {
  theme?: GenesisTheme;
}

type SelectComponent = React.FunctionComponent<SelectComponentProps>;

const Select: SelectComponent = styled(TextInput)`
  appearance: none;
`;

const Option = (props: Record<string, unknown>) => (
  <Box as="option" {...props} />
);

export const useHtmlSelect = ({
  value,
  defaultValue,
  isDisabled,
  options = [],
  isClearable = false,
  onChange: onChangeProp = (e: React.ChangeEvent<HTMLInputElement>) => e,
}: SelectProps): SelectProps => {
  const [hasValue, setHasValue] = useState(Boolean(value || defaultValue));

  const color = getTextColor({
    hasValue,
    isDisabled,
  });

  const flattenedOptions = flattenOptions(options);
  const defaultOption = flattenedOptions.find(optionHasValue(defaultValue));
  const selectedOption = flattenedOptions.find(optionHasValue(value));

  /**
   * Since they are used interchangeably, we need to match the onChange
   * signatures to the ones implemented in Combobox (aka react-select). The main difference
   * is that Combobox expects to receive an option object with label/value properties
   */
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = evt?.currentTarget?.value;
    const selectedEventOption = flattenedOptions.find(
      optionHasValue(eventValue),
    );
    setHasValue(Boolean(eventValue)); // will set to false if undefined
    onChangeProp(selectedEventOption);
  };

  const backgroundColor = isDisabled
    ? 'interactive.background.disabled'
    : 'interactive.background.default';

  return {
    color,
    onChange,
    backgroundColor,
    isDisabled,
    options,
    isClearable: isClearable && hasValue,
    value: selectedOption?.value,
    defaultValue: defaultOption?.value ?? '',
  };
};

type HtmlSelectOptionsProps = {
  options: OptionType[];
};

const HtmlSelectOptions = ({ options }: HtmlSelectOptionsProps) => {
  return (
    <>
      {options.map(({ value, label, isDisabled }) => (
        <Option
          key={`${JSON.stringify(value)}-${label}`}
          value={value}
          disabled={isDisabled}
        >
          {label}
        </Option>
      ))}
    </>
  );
};

type HtmlSelectGroupedOptionsProps = {
  options: GroupedOptionsType;
};

const HtmlSelectGroupedOptions = ({
  options,
}: HtmlSelectGroupedOptionsProps) => {
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
      {options.map(({ label, options }) =>
        label ? (
          <Box as="optgroup" label={label} key={String(label)}>
            <HtmlSelectOptions options={options} />
          </Box>
        ) : (
          <HtmlSelectOptions options={options} key={String(options[0].label)} />
        ),
      )}
    </>
  );
};

export const HtmlSelect = forwardRef<HTMLInputElement, SelectProps>(
  ({ placeholder = 'Select...', width, margin, ...props }, ref) => {
    const { options, isClearable, isDisabled, ...selectProps } =
      useHtmlSelect(props);

    return (
      <Box
        className="Genesis-HtmlSelect-container"
        position="relative"
        width={width}
        margin={margin}
      >
        <Select
          className="Genesis-HtmlSelect"
          as="select"
          display="flex"
          alignItems="center"
          paddingRight="twoAndHalf"
          paddingY="none"
          data-testid="html-select"
          ref={ref}
          disabled={isDisabled}
          css={css({
            position: 'relative',
          })}
          {...props}
          {...selectProps}
        >
          <Option value="" disabled={isClearable ? undefined : true}>
            {placeholder}
          </Option>
          {isGroupedOptions(options) ? (
            <HtmlSelectGroupedOptions options={options} />
          ) : (
            <HtmlSelectOptions options={options} />
          )}
        </Select>
        <DropdownIndicator
          css={{
            pointerEvents: 'none',
          }}
          transform="translateY(-50%)"
          position="absolute"
          top="50%"
          right="half"
        />
      </Box>
    );
  },
) as React.FunctionComponent<SelectProps>;

HtmlSelect.displayName = 'HtmlSelect';
