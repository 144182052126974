import React from 'react';
import { keyframes } from 'styled-components';
import { Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import { useValidationStatus } from './use-validation-status.hook';

const pulse = keyframes`
  0% {
    opacity: 0.5;
    scale: 1;
  }
  50% {
    opacity: 1;
    scale: 1.2;
  }
  100% {
    opacity: 0.5;
    scale: 1;
  }
`;

const Dot = genesisStyled(Flex)`
  transform-origin: 50%;
  animation-duration: 1s;
  animation-name: ${({ loading }) => loading && pulse};
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

export const TreeNodeValidationStatusIndicator = props => {
  const { statusColor, filled, loading } = useValidationStatus(props);
  return statusColor ? (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      width={16}
      marginLeft="quarter"
    >
      <Dot
        loading={loading}
        size={6}
        borderWidth="thin"
        borderRadius="circle"
        borderColor={statusColor}
        backgroundColor={filled || loading ? statusColor : 'transparent'}
      />
    </Flex>
  ) : null;
};
