import { get } from 'lodash/fp';
import { selectApp } from 'apps/member-experience/state/selectors';
import { createSelector } from 'reselect';

export const selectRoot = createSelector(selectApp, get('pages.claims'));

export const selectReady = createSelector(selectRoot, get('ready'));

export const selectClaims = createSelector(selectRoot, get('claims'));

/**
 * The `options` state mirrors get_claims parameters so far as they
 * are relevant to the UI. get_claims expects an array of statuses.
 * Because the UI only supports filtering by one status at a time, we
 * select the first and only element.
 */
export const selectStatusFilter = createSelector(
  selectRoot,
  get('options.statuses.0'),
);

export const selectNeedsMoreInfo = createSelector(
  selectRoot,
  get('needsMoreInfo'),
);
