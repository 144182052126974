import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { withFieldHints } from './FieldHints';
import { DATE_FORMAT } from '../../constants';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';

export const ReadOnlyDate = ({
  name,
  value,
  placeholder,
  readOnly,
  handleChange,
  handleBlur,
}) => {
  const formattedDateValue = value
    ? moment(value).utc().format(DATE_FORMAT)
    : '';

  return (
    <div className="ui input">
      <Form.Input
        name="displayDate"
        placeholder={placeholder}
        value={formattedDateValue}
        readOnly={readOnly}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <input value={value} name={name} type="hidden" />
    </div>
  );
};

ReadOnlyDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

ReadOnlyDate.defaultProps = {
  value: '',
  placeholder: '',
};

export default withFieldHints(ReadOnlyDate);
