import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Flex, Box, ErrorMessage } from '@leagueplatform/genesis-commons';
import { TreeNavigation } from './tree-navigation';
import { JSONSchemaDefinitionPropType } from 'common/json-schema-form/prop-types';
import { JSONSchemaFormContainer } from 'common/json-schema-form/json-schema-form.container';
import { SCHEMA_ENTITY_TYPES } from 'common/json-schema-form/json-schema-form.constants';
import { EnrollmentConfigEntityForm } from './enrollment-config-entity-form.view';
import { EnrollmentConfigCollectionForm } from './enrollment-config-collection-form.view';
import { MissingPlanOptions } from './missing-plan-options.view';
import { BlockToggleNodeModal } from './block-toggle-node-modal.view';

export const RenderFields = formProps => {
  // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
  return formProps.schemaDefinition.type === SCHEMA_ENTITY_TYPES.ARRAY ? (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <EnrollmentConfigCollectionForm {...formProps} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <EnrollmentConfigEntityForm {...formProps} />
  );
};

export const EnrollmentConfigPresenter = ({
  schema,
  formData,
  submissionError,
  showMissingPlanOptions,
  setHasUnsavedChanges,
  ...props
}) => {
  return (
    <>
      {submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <BlockToggleNodeModal {...props} />
      <Box>
        <Flex flexDirection="row">
          <Box width={2 / 5}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
            <TreeNavigation {...props} />
          </Box>
          <Box marginLeft="two" width={3 / 5}>
            {!isEmpty(schema) && formData && (
              <Box
                borderWidth="thin"
                borderColor="onSurface.border.subdued"
                borderStyle="solid"
                borderRadius="small"
                padding="one"
              >
                <JSONSchemaFormContainer
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...props}
                  schema={schema}
                  data={formData}
                >
                  {({ dirty, ...formProps }) => {
                    setHasUnsavedChanges(dirty);
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    return <RenderFields {...formProps} dirty={dirty} />;
                  }}
                </JSONSchemaFormContainer>
              </Box>
            )}
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
            {showMissingPlanOptions && <MissingPlanOptions {...props} />}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

EnrollmentConfigPresenter.propTypes = {
  schema: JSONSchemaDefinitionPropType,
  formData: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  ]),
  submissionError: PropTypes.string,
  showMissingPlanOptions: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func.isRequired,
};

EnrollmentConfigPresenter.defaultProps = {
  schema: undefined,
  formData: undefined,
  showMissingPlanOptions: false,
  submissionError: null,
};
