import { mapProps } from 'recompose';

export const injectBenefitClassIdToAddUpdateBenefit = mapProps(
  ({ addUpdateBenefit, ...passThroughProps }) => ({
    ...passThroughProps,
    addUpdateBenefit: benefit => {
      return addUpdateBenefit(benefit, passThroughProps.benefitClassId);
    },
  }),
);
