import { takeLatest } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { TOP_UP_USER_BENEFIT, DO_TOP_UP_USER_BENEFIT } from './add-funds.types';

export function* topUpUserBenefit({ payload }) {
  const { benefitId, amount } = payload;
  yield request(DO_TOP_UP_USER_BENEFIT, [
    websocketFetch,
    'top_up_user_benefit',
    {
      benefit_id: benefitId,
      amount,
    },
  ]);
}

export function* employeeAddFundsSaga() {
  yield takeLatest(TOP_UP_USER_BENEFIT, topUpUserBenefit);
}
