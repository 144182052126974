import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HeadingFour,
  BodyTwoSecondary,
  Image,
  Flex,
  BodyOne,
  genesisStyled,
  css,
  QuietButton,
  Link,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import HEART_PAGE from 'apps/public/assets/heart-page.svg';

const LookerSpacing = genesisStyled(Box)`
  padding: 0 10px 0 10px;
  @media screen and (min-width: 1135px) {
    padding: 0 15px 0 15px;
  }
  @media screen and (min-width: 1570px) {
    padding: 0 60px 0 60px;
  }
`;

const ReportItem = genesisStyled(Flex)(
  css({
    position: 'relative',
    '&:not(:first-child)::before': {
      display: 'block',
      content: '""',
      top: '3px',
      backgroundColor: 'onSurface.border.subdued',
      height: '1px',
      width: '100%',
      position: 'absolute',
    },
  }),
);

export const InsightsReportsView = ({ options, onSelect }) => {
  const { formatMessage } = useIntl();
  const handleSelect = report => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    onSelect(report, 'reports');
  };

  return (
    <LookerSpacing>
      <Box
        as="section"
        border="1px solid"
        borderColor="onSurface.border.subdued"
        borderRadius="large"
        padding="oneAndHalf"
        boxShadow="rgb(0 0 0 / 11%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 1px 4px;"
      >
        <Flex justifyContent="space-between">
          <Box paddingBottom="one">
            <HeadingFour>{formatMessage({ id: 'REPORTS' })}</HeadingFour>
            <BodyTwoSecondary paddingTop="half">
              {formatMessage({ id: 'REPORTS_SECTION_DESCRIPTION' })}
            </BodyTwoSecondary>
          </Box>
          <Image src={HEART_PAGE} role="presentation" size="full" />
        </Flex>
        <Box as="ul">
          {options?.options.map(report => (
            <ReportItem
              key={report.id}
              as="li"
              paddingTop="one"
              paddingBottom="half"
            >
              <BodyOne paddingRight="half">{report.label}</BodyOne>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
              <Link
                aria-label={report.label}
                aria-labelledby={report.id}
                as={QuietButton}
                onClick={() => handleSelect(report)}
              >
                {formatMessage({ id: 'VIEW' })}
              </Link>
            </ReportItem>
          ))}
        </Box>
      </Box>
    </LookerSpacing>
  );
};

InsightsReportsView.propTypes = {
  options: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};
