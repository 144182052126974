import { call, takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import {
  GET_WALLET_TEMPLATES_FOR_GROUP,
  CREATE_TEMPLATE_SELECTED,
} from './wallet-templates.actions';
import { getWalletTemplatesForGroup } from './get-wallet-templates-for-group.service';
import { setWalletTemplateForGroup } from './set-wallet-template-for-group.service';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

/**
 * The set_wallet_template_for_group service will create a new template on
 * the Contentful CMS and return the URL to edit that template on the exter
 */
export function* createTemplateAndRedirect(action) {
  try {
    const { url } = (yield setWalletTemplateForGroup(action.payload)).info;
    yield call(window.open, url, '_blank', 'noopener=yes, noreferrer=yes');
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR_CREATING_WALLET_TEMPLATE',
    });
  }
}

export function* walletTemplatesSaga(groupId) {
  yield request(
    GET_WALLET_TEMPLATES_FOR_GROUP,
    getWalletTemplatesForGroup(groupId),
  );
  yield takeLatest(CREATE_TEMPLATE_SELECTED, createTemplateAndRedirect);
}
