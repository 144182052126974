import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  PrimaryButton,
  LabelText,
  genesisStyled,
  Image,
  css,
  HeadingOne,
  Flex,
  Box,
} from '@leagueplatform/genesis-commons';
import { colour, fontSizeInPx, media, Label } from '@leagueplatform/ui-kit';
import Spinner from './assets/loading.png';

export const PageHeading = props => (
  <HeadingOne
    marginBottom="two"
    fontSize={['heading2', 'heading1']}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const FlexForm = props => (
  <Flex
    flexWrap="wrap"
    flexDirection={{ phone: 'column', tablet: 'row' }}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const InputFieldGroup = genesisStyled(Box)`
  width: 50%;
  margin-bottom: 24px;

  &:nth-of-type(odd) {
    padding-right: 12px;
  }
  &:nth-of-type(even) {
    padding-left: 12px;
  }

  ${media.belowTablet`
    width: 100%;

    &:nth-of-type(odd) {
      padding-right: 0;
    }
    &:nth-of-type(even) {
      padding-left: 0;
    }
  `};
`;

export const StyledLabel = styled(Label)`
  display: inline-block;
  margin: 0 0 12px 0;
  font-size: ${fontSizeInPx(1)};
  line-height: 1.3;
  color: ${colour('neutral.gray')};

  &:after {
    content: '*';
    color: ${colour('red')};
    font-size: ${fontSizeInPx(0)};
    font-weight: normal;
    margin-left: 3px;
  }
`;

export const StyledLabelText = genesisStyled(LabelText)(
  css({
    '::after': {
      display: 'inline-block',
      content: '"*"',
      color: 'onSurface.text.critical',
    },
  }),
);

const SpinnerIcon = genesisStyled(Image)`
  animation: rotate 1s ease-in-out infinite;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;

export const ResponsiveButton = ({
  loading,
  children,
  loadingText,
  ...props
}) => {
  const [minWidth, setMinWidth] = useState();
  const buttonRef = useRef();

  useLayoutEffect(() => {
    const buttonWidth = buttonRef?.current?.clientWidth;
    if (!minWidth || minWidth < buttonWidth) setMinWidth(buttonWidth);
  }, [buttonRef, setMinWidth, minWidth]);

  return (
    <PrimaryButton
      marginTop="twoAndHalf"
      paddingX="oneAndHalf"
      width={{
        _: 1,
        tablet: 'auto',
      }}
      minWidth={minWidth}
      ref={buttonRef}
      color={loading ? 'interactive.action.primary' : 'primary.text.default'}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      {loading ? (
        <SpinnerIcon src={Spinner} size="oneAndHalf" alt={loadingText} />
      ) : (
        children
      )}
    </PrimaryButton>
  );
};

ResponsiveButton.propTypes = {
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ResponsiveButton.defaultProps = {
  loadingText: 'Loading',
  loading: false,
};
