import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import { format, parseISO } from 'date-fns';
import { Form, Header, Label, Message } from 'semantic-ui-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  getCurrencyForCountry,
  getSymbolForCurrency,
} from '@leagueplatform/web-common';
import {
  COUNTRY_OPTIONS,
  PROVINCE_STATE_OPTIONS,
  CANADA,
  APPROVED,
} from 'common/constants';
import { VendorSelect } from './vendor-select.view';
import { toNumber } from '../settlement.utilities';
import { handleReadOnly } from './handle-read-only.hoc';
import { PolicyCard } from './policy-card.view';
import styled from 'styled-components';

const EditAllocationsLink = styled.a`
  cursor: pointer;
`;

export const DateSelect = handleReadOnly(DayPickerInput);

const getAllocationButtonText = isAdjusting => {
  return isAdjusting
    ? 'Cancel edit claim allocations'
    : 'Edit claim allocations';
};

export const ClaimFormFields = ({
  values,
  funds,
  isOldBenefitsModelUser,
  categoryOptions,
  dependentOptions,
  vendorOptions,
  vendorQueryChanged,
  saveValuesRequested,
  setFieldValue,
  readOnly,
  canEditPolicyAllocations,
  canReadjustAllocationAmounts,
  editAllocations,
  stopEditingAllocations,
  isAdjustingApprovedAllocations,
  openSelectCoverageCategory,
  closeSelectCoverageCategory,
  setTargetBenefitId,
  dateCreated,
  initialClaimStatus,
  benefitNames,
}) => (
  <>
    <Header as="h2">Details</Header>
    {values.external_id && (
      <Form.Input
        width={8}
        label="Alegeus Claim ID"
        name="external_id"
        disabled={readOnly}
        value={values.external_id}
      />
    )}
    {dependentOptions && (
      <Form.Select
        name="dependent_id"
        label="Dependent"
        value={values.dependent_id}
        selection
        width={8}
        disabled={readOnly}
        options={dependentOptions}
        onChange={(e, { value }) => setFieldValue('dependent_id', value)}
      />
    )}
    <Form.Field width={8} disabled={readOnly}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
      <label htmlFor="vendor">Vendor</label>
      <VendorSelect
        name="vendor"
        value={values.vendor}
        options={vendorOptions}
        onChange={vendor => setFieldValue('vendor', vendor)}
        onVendorQueryChange={vendorQueryChanged}
        readOnly={readOnly}
      />
    </Form.Field>
    <Header as="h2">Receipt</Header>
    <Form.Field width={6} disabled={readOnly}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
      <label htmlFor="expense_date">Service Date</label>
      <DateSelect
        name="expense_date"
        readOnly={initialClaimStatus === APPROVED}
        onDayChange={date => {
          const dateString = date ? date.toISOString() : null;
          setFieldValue('expense_date', dateString);
          saveValuesRequested({
            claim_id: values.claim_id,
            expense_date: dateString,
          });
        }}
        value={
          values.expense_date
            ? format(parseISO(values.expense_date), 'dd-MM-yyyy')
            : null
        }
        format="DD-MM-YYYY"
      />
    </Form.Field>
    <Form.Group>
      <Form.Field width={6}>
        <Form.Select
          label="Country"
          search
          selection
          value={values.country}
          onChange={(e, { value }) => {
            setFieldValue('country', value);
            setFieldValue('currency', getCurrencyForCountry(value));
          }}
          options={COUNTRY_OPTIONS}
          disabled={readOnly}
        />
      </Form.Field>
      {values.country === CANADA && (
        <Form.Field width={6}>
          <Form.Select
            label="Province"
            search
            selection
            disabled={readOnly || !values.country}
            value={values.province}
            onChange={(e, { value }) => setFieldValue('province', value)}
            options={PROVINCE_STATE_OPTIONS[values.country] || []}
          />
        </Form.Field>
      )}
    </Form.Group>
    <Form.Input
      label="Total Requested Amount"
      type="number"
      step="0.01"
      value={values.amount_claimed}
      labelPosition="right"
      width={6}
      disabled={readOnly}
      onChange={(event, { value }) => {
        setFieldValue('amount_claimed', toNumber(value));
      }}
    >
      <Label basic>{getSymbolForCurrency(values.currency)}</Label>
      <input disabled />
      <Label basic>{values.currency}</Label>
    </Form.Input>
    <Header as="h2">Coverage</Header>
    {values.expense_date && isEmpty(funds) && (
      <Message>
        There are no benefits that cover the <strong>Service Date</strong>{' '}
        entered.
      </Message>
    )}

    {canReadjustAllocationAmounts && (
      <Form.Field width={12}>
        {!isOldBenefitsModelUser ? (
          <EditAllocationsLink
            tabIndex="0"
            role="button"
            onClick={
              isAdjustingApprovedAllocations
                ? stopEditingAllocations
                : editAllocations
            }
          >
            {getAllocationButtonText(isAdjustingApprovedAllocations)}
          </EditAllocationsLink>
        ) : (
          <Message color="yellow">
            Edit allocation tool does not support old benefits model users.
          </Message>
        )}
      </Form.Field>
    )}
    {values.expense_date ? (
      map(funds, policy => (
        <PolicyCard
          key={`${policy.id}-${policy.type}`}
          values={values}
          setFieldValue={setFieldValue}
          type={policy.type}
          categoryOptions={categoryOptions[policy.policyKind]}
          available={policy.available}
          startDate={policy.startDate}
          endDate={policy.endDate}
          graceStartDate={policy.graceStartDate}
          graceEndDate={policy.graceEndDate}
          isAdjustingApprovedAllocations={isAdjustingApprovedAllocations}
          editAllocations={editAllocations}
          stopEditingAllocations={stopEditingAllocations}
          canReadjustAllocationAmounts={canReadjustAllocationAmounts}
          readOnly={!canEditPolicyAllocations}
          openSelectCoverageCategory={openSelectCoverageCategory}
          closeSelectCoverageCategory={closeSelectCoverageCategory}
          setTargetBenefitId={setTargetBenefitId}
          benefitId={policy.userBenefitId || policy.policyKind} // benefit ID for new benefits, policyKind for old benefits
          dateCreated={dateCreated}
          benefitNames={benefitNames}
        />
      ))
    ) : (
      <Message>
        Please provide <strong>Service Date</strong> to see available coverage.
      </Message>
    )}
  </>
);

ClaimFormFields.propTypes = {
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userBenefitId: PropTypes.string,
      policyKind: PropTypes.string,
      type: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      available: PropTypes.number,
      allocated: PropTypes.number,
    }),
  ).isRequired,
  isOldBenefitsModelUser: PropTypes.bool.isRequired,
  categoryOptions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
  dependentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  vendorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  vendorQueryChanged: PropTypes.func.isRequired,
  saveValuesRequested: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  canEditPolicyAllocations: PropTypes.bool,
  initialClaimStatus: PropTypes.string,
  canReadjustAllocationAmounts: PropTypes.bool.isRequired,
  editAllocations: PropTypes.func.isRequired,
  isAdjustingApprovedAllocations: PropTypes.bool.isRequired,
  stopEditingAllocations: PropTypes.func.isRequired,
  openSelectCoverageCategory: PropTypes.func.isRequired,
  closeSelectCoverageCategory: PropTypes.func.isRequired,
  setTargetBenefitId: PropTypes.func.isRequired,
  dateCreated: PropTypes.string.isRequired,
  benefitNames: PropTypes.shape({}),
};

ClaimFormFields.defaultProps = {
  readOnly: false,
  canEditPolicyAllocations: true,
  dependentOptions: null,
  initialClaimStatus: undefined,
  benefitNames: {},
};
