import { GET_HEALTH_ACTIVITIES } from './health-activities-page.actions';

export const healthActivitiesInitialState = {
  activities: [],
  ready: false,
  hasError: false,
  errorMsg: undefined,
};

export const healthActivities = (
  state = healthActivitiesInitialState,
  action = {},
) => {
  switch (action.type) {
    case GET_HEALTH_ACTIVITIES.STARTED:
      return { ...state, ready: false };
    case GET_HEALTH_ACTIVITIES.ERRORED:
      return {
        ...state,
        ready: true,
        hasError: true,
        errorMsg: action?.payload?.info?.reason ?? 'Something went wrong...',
      };
    case GET_HEALTH_ACTIVITIES.SUCCEEDED:
      return {
        ...state,
        ready: true,
        activities: action?.payload?.info?.completed_activity_items ?? [],
      };
    default:
      return state;
  }
};
