export default {
  employeeUSLTD: {
    name: 'employeeUSLTD',
    groups: [
      {
        title: 'Member Policy Details',
        fields: [
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Employer Policy Details',
        fields: [
          {
            name: 'parent.benefit_start_date',
            span: 6,
          },
          {
            name: 'parent.benefit_end_date',
            span: 6,
          },
          {
            name: 'parent.policy_id',
            span: 6,
          },
          {
            name: 'parent.documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'parent.prevent_default_allocation',
            span: 4,
          },
          {
            name: 'parent.opt_out',
            span: 4,
          },
          {
            name: 'parent.benefit_waiting_period_months',
            span: 4,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'parent.benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'parent.auto_renew',
            span: 6,
          },
        ],
      },
      {
        title: 'Description of Coverage',
        fields: [
          {
            name: 'parent.network_name',
            span: 6,
          },

          {
            name: 'parent.physical_card',
            span: 6,
          },

          {
            name: 'parent.in_network_deductible_single',
            span: 6,
          },

          {
            name: 'parent.in_network_deductible_family',
            span: 6,
          },

          {
            name: 'parent.in_network_embedded_or_non-embedded_deductible',
            span: 6,
          },

          {
            name: 'parent.in_network_out-of-pocket_maximum_single',
          },

          {
            name: 'parent.in_network_out-of-pocket_maximum_family',
            span: 6,
          },

          {
            name: 'parent.in_network_outpatient_surgery_facility_fee',
            span: 6,
          },

          {
            name: 'parent.in_network_outpatient_surgery_physician',
            span: 6,
          },

          {
            name: 'parent.in_network_preventive_care',
            span: 6,
          },

          {
            name: 'parent.in_network_primary_care_visit',
            span: 6,
          },

          {
            name: 'parent.in_network_rehabilitation_services',
            span: 6,
          },

          {
            name: 'parent.in_network_retail_non-preferred_brand',
            span: 6,
          },

          {
            name: 'parent.in_network_retail_non-preferred_generic',
            span: 6,
          },

          {
            name: 'parent.in_network_retail_non-preferred_specialty',
            span: 6,
          },

          {
            name: 'parent.in_network_retail_preferred_brand',
            span: 6,
          },

          {
            name: 'parent.in_network_retail_preferred_generic',
            span: 6,
          },

          {
            name: 'parent.in_network_retail_preferred_specialty',
            span: 6,
          },

          {
            name: 'parent.in_network_rx_deductible_family',
            span: 6,
          },

          {
            name: 'parent.in_network_rx_deductible_single',
            span: 6,
          },

          {
            name: 'parent.in_network_skilled_nursing',
            span: 6,
          },

          {
            name: 'parent.in_network_specialist_visit',
            span: 6,
          },

          {
            name: 'parent.in_network_urgent_care',
            span: 6,
          },
          {
            name: 'parent.op_out',
            span: 6,
          },

          {
            name: 'parent.out_of_network_child_dental',
            span: 6,
          },
          {
            name: 'parent.out_of_network_child_glasses',
            span: 6,
          },
          {
            name: 'parent.out_of_network_child_vision',
            span: 6,
          },
          {
            name: 'parent.out_of_network_childbirth_and_delivery_facility_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_childbirth_and_delivery_professional_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_deductible_family',
            span: 6,
          },
          {
            name: 'parent.out_of_network_deductible_single',
            span: 6,
          },
          {
            name: 'parent.out_of_network_durable_medical_equipment',
            span: 6,
          },
          {
            name: 'parent.out_of_network_emergency_medical_transportation',
            span: 6,
          },
          {
            name: 'parent.out_of_network_emergency_room_visit',
            span: 6,
          },
          {
            name: 'parent.out_of_network_habilitation_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_home_health_care',
            span: 6,
          },
          {
            name: 'parent.out_of_network_hospice_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_imaging',
            span: 6,
          },
          {
            name: 'parent.out_of_network_inpatient_physician/surgery_fee',
            span: 6,
          },
          {
            name: 'parent.out_of_network_lab_and_x-ray_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mail_non-preferred_brand',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mail_non-preferred_generic',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mail_non-preferred_specialty',
            span: 6,
          },
          {
            name: 'parent.out_of_network_maternal_office_visits',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mental_behavioural_inpatient_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mental_behavioural_outpatient_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_out-of-pocket_maximum_family',
            span: 6,
          },
          {
            name: 'parent.out_of_network_out-of-pocket_maximum_single',
            span: 6,
          },
          {
            name: 'parent.out_of_network_outpatient_surgery_facility_fee',
            span: 6,
          },
          {
            name: 'parent.out_of_network_outpatient_surgery_physician/surgeon_fee',
            span: 6,
          },
          {
            name: 'parent.out_of_network_preventive_care',
            span: 6,
          },
          {
            name: 'parent.out_of_network_primary_care_visit',
            span: 6,
          },
          {
            name: 'parent.out_of_network_rehabilitation_services',
            span: 6,
          },
          {
            name: 'parent.out_of_network_retail_non-preferred_brand',
            span: 6,
          },
          {
            name: 'parent.out_of_network_retail_non-preferred_generic',
            span: 6,
          },
          {
            name: 'parent.out_of_network_retail_non-preferred_specialty',
            span: 6,
          },
          {
            name: 'parent.oout_of_network_retail_preferred_brand',
            span: 6,
          },
          {
            name: 'parent.out_of_network_retail_preferred_generic',
            span: 6,
          },
          {
            name: 'parent.out_of_network_retail_preferred_specialty',
            span: 6,
          },
          {
            name: 'parent.out_of_network_rx_deductible_family',
            span: 6,
          },
          {
            name: 'parent.out_of_network_rx_deductible_single',
            span: 6,
          },
          {
            name: 'parent.out_of_network_skilled_nursing',
            span: 6,
          },
          {
            name: 'parent.out_of_network_specialist_visit',
            span: 6,
          },
          {
            name: 'parent.out_of_network_urgent_care',
            span: 6,
          },
          {
            name: 'parent.plan_id',
            span: 6,
          },
          {
            name: 'parent.policy_id',
            span: 6,
          },
          {
            name: 'parent.preferred_medical_network_available',
            span: 6,
          },
          {
            name: 'parent.prevent_default_allocation',
            span: 6,
          },
          {
            name: 'parent.in_network_lab_and_x-ray_services',
            span: 6,
          },
          {
            name: 'parent.in_network_imaging',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_preferred_generic',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_non-preferred_generic',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_preferred_brand',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_non-preferred_brand',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_preferred_speciality',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_non-preferred_speciality',
            span: 6,
          },
          {
            name: 'parent.in_network_outpatient_surgery_physician/surgeon_fee',
            span: 6,
          },
          {
            name: 'parent.in_network_inpatient_facility_fee',
            span: 6,
          },
          {
            name: 'parent.in_network_inpatient_physician/surgery_fee',
            span: 6,
          },
          {
            name: 'parent.in_network_emergency_room_visit',
            span: 6,
          },
          {
            name: 'parent.in_network_emergency_medical_transportation',
            span: 6,
          },
          {
            name: 'parent.in_network_mental_behavioural_outpatient_services',
            span: 6,
          },
          {
            name: 'parent.in_network_mental_behavioural_inpatient_services',
            span: 6,
          },
          {
            name: 'parent.in_network_maternal_office_visits',
            span: 6,
          },
          {
            name: 'parent.in_network_childbirth_and_delivery_professional_services',
            span: 6,
          },
          {
            name: 'parent.in_network_childbirth_and_delivery_facility_services',
            span: 6,
          },
          {
            name: 'parent.in_network_home_health_care',
            span: 6,
          },
          {
            name: 'parent.in_network_habilitation_services',
            span: 6,
          },
          {
            name: 'parent.in_network_durable_medical_equipment',
            span: 6,
          },
          {
            name: 'parent.in_network_hospice_services',
            span: 6,
          },
          {
            name: 'parent.in_network_child_dental',
            span: 6,
          },
          {
            name: 'parent.in_network_child_vision',
            span: 6,
          },
          {
            name: 'parent.in_network_child_glasses',
            span: 6,
          },
          {
            name: 'parent.out_of_network_retail_preferred_brand',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mail_preferred_generic',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mail_preferred_specialty',
            span: 6,
          },
          {
            name: 'parent.out_of_network_inpatient_facility_fee',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_preferred_specialty',
            span: 6,
          },
          {
            name: 'parent.in_network_mail_non-preferred_specialty',
            span: 6,
          },
          {
            name: 'parent.out_of_network_mail_preferred_brand',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_percent_of_salary',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_graded_schedule_percent_first',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_graded_schedule_upper_first',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_graded_schedule_percent_second',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_graded_schedule_percent_remainder',
            span: 6,
          },
          {
            name: 'parent.monthly_minimum_benefit_amount',
            span: 6,
          },
          {
            name: 'parent.monthly_maximum_benefit_amount',
            span: 6,
          },
          {
            name: 'parent.no_evidence_maximum',
            span: 6,
          },
          {
            name: 'parent.elimination_period_days',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_flat_amount',
            span: 6,
          },
          {
            name: 'monthly_benefit_flat_amount',
            span: 6,
          },
          {
            name: 'parent.monthly_benefit_graded_schedule_upper_second',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'id',
            span: 4,
          },
          {
            name: 'user_id',
            span: 4,
          },
          {
            name: 'parent.status',
            span: 4,
          },
          {
            name: 'grace_period_start_date',
            span: 4,
          },
          {
            name: 'inactive_date',
            span: 4,
          },
          {
            name: 'reinstated_date',
            span: 4,
          },
          {
            name: 'parent.provider_id',
            span: 4,
          },
          {
            name: 'parent.vendor',
            span: 4,
          },
          {
            name: 'parent.benefit_type',
            span: 4,
          },
          {
            name: 'parent.benefit_product_type',
            span: 4,
          },
          {
            name: 'parent.id',
            span: 4,
          },
          {
            name: 'parent.benefit_status',
            span: 4,
          },
          {
            name: 'parent.provider_type',
            span: 4,
          },
          {
            name: 'parent.plan_id',
            span: 4,
          },
          {
            name: 'parent.benefit_vendor_id',
            span: 4,
          },
          {
            name: 'parent.renewed_from',
            span: 4,
          },
          {
            name: 'dependent_coverage',
            span: 6,
          },
          {
            name: 'parent.dependent_classes',
            span: 12,
          },
        ],
      },
    ],
  },
};
