import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { JSONSchemaFieldPropType } from '../prop-types';
import { Select } from 'common/form-fields/Select/Select';
import { FieldWrapper } from './field-wrapper.view';
import {
  mapValuesToOptions,
  mapOneOfValuesToOptions,
} from '@leagueplatform/ui-kit';

export const SelectField = ({
  field,
  field: { name } = {},
  form,
  propSchema: { readOnly, enum: options, oneOf, ...propSchema },
  autoComplete,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const getSelectOptions = () => {
    if (options) return mapValuesToOptions(options);
    if (oneOf) return mapOneOfValuesToOptions(oneOf);
    return [];
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        disabled={readOnly}
        hasError={!!form.errors[name]}
        onBlur={({ value }) => {
          form.handleBlur({ target: { value, name } });
        }}
        onChange={evt => {
          const { value = '' } = evt ?? {};
          form.setFieldValue(name, value ?? evt);
        }}
        options={getSelectOptions()}
        placeholder={formatMessage({ id: 'SELECT_ONE' })}
        autoComplete={autoComplete}
      />
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  ...JSONSchemaFieldPropType,
  isClearable: PropTypes.bool,
};

SelectField.defaultProps = {
  isClearable: true,
};
