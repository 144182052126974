import { all, takeEvery } from 'redux-saga/effects';
import { analytics } from 'common/utilities';
import {
  DELETE_DIRECT_DEPOSIT,
  REQUEST_DELETE_DIRECT_DEPOSIT,
} from './delete-direct-deposit.types';
import { ANALYTICS_CANCEL_DELETING_ACCOUNT } from './delete-direct-deposit-analytics.types';

export function* deleteDirectDepositAnalytics() {
  yield all([
    takeEvery(
      DELETE_DIRECT_DEPOSIT,
      analytics.bankingInfo.confirmDeleteAccount,
    ),
    takeEvery(
      REQUEST_DELETE_DIRECT_DEPOSIT.ERRORED,
      analytics.bankingInfo.errorDeletingAccount,
    ),
    takeEvery(
      ANALYTICS_CANCEL_DELETING_ACCOUNT,
      analytics.bankingInfo.cancelDeletingAccount,
    ),
  ]);
}
