import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HeadingThree,
  BodyOne,
  Flex,
  Label,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { useIntl } from '@leagueplatform/locales';
import { PayrollDeductionsTable } from './payroll-deductions-table.view';

export const PayrollDeductionsPresenter = ({
  userDeductions,
  benefitTypeOptions,
  planPeriodOptions,
  payPeriodOptions,
  benefitTypeValue,
  setBenefitValue,
  planPeriodValue,
  setPlanPeriodValue,
  payPeriodValue,
  setPayPeriodValue,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Box>
        <HeadingThree>
          {formatMessage({ id: 'PAYROLL_DEDUCTIONS' })}
        </HeadingThree>
        <BodyOne color="onSurface.text.subdued">
          {formatMessage({ id: 'PAYROLL_DEDUCTIONS_COPY' })}
        </BodyOne>
      </Box>
      <Label minWidth="45%">
        <LabelText marginY="quarter">
          {formatMessage({ id: 'PLAN_PERIOD' })}
        </LabelText>
        <Select
          options={planPeriodOptions}
          value={planPeriodValue}
          onChange={event => {
            setPlanPeriodValue(event.value);
            setPayPeriodValue(undefined);
            setBenefitValue(undefined);
          }}
        />
      </Label>
      <Flex justifyContent="space-between" marginY="oneAndHalf">
        <Label minWidth="45%">
          <LabelText marginY="quarter">
            {formatMessage({ id: 'PAY_PERIOD' })}
          </LabelText>
          <Select
            isClearable
            key={planPeriodValue}
            options={payPeriodOptions}
            value={payPeriodValue}
            onChange={event => setPayPeriodValue(event?.value ?? undefined)}
          />
        </Label>
        <Label minWidth="45%">
          <LabelText marginY="quarter">
            {formatMessage({ id: 'BENEFIT_TYPE' })}
          </LabelText>
          <Select
            isClearable
            key={planPeriodValue}
            options={benefitTypeOptions}
            value={benefitTypeValue}
            onChange={event => setBenefitValue(event?.value ?? undefined)}
          />
        </Label>
      </Flex>
      <PayrollDeductionsTable payrollDeductions={userDeductions} />
    </Box>
  );
};

PayrollDeductionsPresenter.propTypes = {
  userDeductions: PropTypes.arrayOf(
    PropTypes.shape({
      benefit_type: PropTypes.string.isRequired,
      plan_period: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string,
      }),
    }),
  ),
  benefitTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  planPeriodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  payPeriodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  benefitTypeValue: PropTypes.string,
  setBenefitValue: PropTypes.func.isRequired,
  planPeriodValue: PropTypes.string,
  setPlanPeriodValue: PropTypes.func.isRequired,
  payPeriodValue: PropTypes.string,
  setPayPeriodValue: PropTypes.func.isRequired,
};

PayrollDeductionsPresenter.defaultProps = {
  userDeductions: [],
  benefitTypeOptions: [],
  planPeriodOptions: [],
  payPeriodOptions: [],
  benefitTypeValue: undefined,
  planPeriodValue: undefined,
  payPeriodValue: undefined,
};
