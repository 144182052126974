import { concat, difference, flow, keys, isArray, map, reduce } from 'lodash';
import { createSchemaParser } from '../parser';

const validateRule = fieldRule => {
  const undefinedProperties = difference(['rule', 'hint'], keys(fieldRule));
  if (undefinedProperties.length)
    throw new Error(
      `Field rule property must be defined: ${undefinedProperties.join(', ')}`,
    );
  return fieldRule;
};

// Rule arguments are formatted inconsistently
// in the schema and require normalization
const flattenRuleArgs = fieldRule => {
  const args = fieldRule.args || {};
  const flatArgs = isArray(args) ? args[0] : args;
  return { ...fieldRule, args: flatArgs };
};

const normalizeRules = flow(validateRule, flattenRuleArgs);

const makeJsonTypeRule = spec => ({
  rule: `is_${spec.json_type}`,
  hint: `must be of type ${spec.json_type}`,
});

const getValidationRules = spec => {
  const typeRule = makeJsonTypeRule(spec);
  const givenRules = spec.validation_rules || [];
  const typedGivenRules = map(givenRules, rule => ({
    ...rule,
    rule: `${spec.json_type}_${rule.rule}`,
  }));
  const rules = concat(typeRule, typedGivenRules);
  return map(rules, normalizeRules);
};

const parseSchemaAsValidator = schema => {
  const fieldSpecs = createSchemaParser([])(schema);
  return reduce(
    fieldSpecs,
    (validatorMap, fieldSpec) => {
      const key = fieldSpec.path;
      const rules = getValidationRules(fieldSpec);
      return {
        ...validatorMap,
        [key]: rules,
      };
    },
    {},
  );
};

export default parseSchemaAsValidator;
