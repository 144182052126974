import { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';

export const useYearToDateReport = ({ billingDivisions, benefitClasses }) => {
  const { formatMessage } = useIntl();
  const [billingDivisionsValue, setBillingDivisionsValue] = useState(undefined);
  const [benefitClassesValue, setBenefitClassesValue] = useState(undefined);
  const [payGroupValue, setPayGroupValue] = useState();
  const [startDateValue, setStartDate] = useState();
  const [endDateValue, setEndDate] = useState();
  const [userIDs, setUserIDs] = useState([]);
  const billingDivisionsOptions = billingDivisions?.map(billingDivision => ({
    label: billingDivision.name,
    value: billingDivision.billingDivisionId,
  }));
  const benefitClassesOptions = benefitClasses?.map(benefitClass => ({
    label: benefitClass.name,
    value: benefitClass.id,
  }));
  const payGroupOptions = [
    {
      label: formatMessage({ id: 'MONTHLY' }),
      value: 'Monthly',
    },
    {
      label: formatMessage({ id: 'SEMI_MONTHLY' }),
      value: 'Semi-Monthly',
    },
    {
      label: formatMessage({ id: 'WEEKLY' }),
      value: 'Weekly',
    },
    {
      label: formatMessage({ id: 'BI_WEEKLY' }),
      value: 'Bi-Weekly',
    },
  ];

  return {
    payGroupOptions,
    billingDivisionsOptions,
    benefitClassesOptions,
    billingDivisionsValue,
    benefitClassesValue,
    payGroupValue,
    userIDs,
    startDateValue,
    endDateValue,
    setEndDate,
    setBillingDivisionsValue,
    setBenefitClassesValue,
    setPayGroupValue,
    setUserIDs,
    setStartDate,
  };
};
