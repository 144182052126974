import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Flex,
  genesisStyled,
  Spinner,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import { selectGroupId } from '../selectors';
import { useEmployerLooker } from './use-employer-looker.hook';

export const IFrame = genesisStyled.iframe`
  min-height: 800px;
  width: 100%
`;

export const EmployerLookerPresenter = props => {
  const { lookerURL, loading, error } = useEmployerLooker(props);

  return (
    <>
      {error && (
        <BodyOne color="onSurface.text.critical" fontWeight="bold">
          {`Error: ${error.info?.reason}`}
        </BodyOne>
      )}
      <Flex
        borderStyle="solid"
        borderWidth="thin"
        background="transparent"
        width="100%"
        height="800px"
      >
        {loading ? (
          <Spinner isLoading margin="auto" data-testid="loading-spinner" />
        ) : (
          <IFrame src={lookerURL} title="Looker Dashboard" />
        )}
      </Flex>
    </>
  );
};

export const EmployerLooker = connect(
  createStructuredSelector({
    groupId: selectGroupId,
  }),
)(EmployerLookerPresenter);
