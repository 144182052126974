import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form } from 'formik';
import {
  JSONSchemaDefinitionPropType,
  JSONschemaMetadataPropTypes,
  JSONSchemaFieldSchemaTypes,
} from './prop-types';
import { EntityFormField, TranslatedEntityFormField } from './fields';
import {
  alphaSortFields,
  makeSortByFieldOrder,
  getObjectNotationPropertyName,
} from './field-sorters';

const includeItemsOfType = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  ignoreItemsOfTypes = [],
  { type, ...propSchema },
) => {
  if (ignoreItemsOfTypes.length === 0) return true;

  /**
   * Iterate through all the ignore types. There are two branches:
   *
   * 1. simple ignore items have just a `type` property. In this case, only compare this type
   * to propSchema.type. If they match, return false.
   *
   * 2. complex ignore types have an optional `if` clause, which is a JSON Schema subschema and
   * can be used to perform additional matching. Currently only supports `not` where an example
   * rule would be: `{ type: 'array', if: { not: { 'items.type': 'string'}}` which reads as
   * ignore propSchema with type "array" if the propSchema's `items.type` value is not `string`
   */

  return ignoreItemsOfTypes.every(({ type: ignoreType, if: condition }) => {
    const isNotIgnoreType = type !== ignoreType;
    if (!condition) return isNotIgnoreType; // there is no condition, ignore all items with this type

    /**
     * We also allow conditional overriding of the ignored type using a JSON Schema subschema.
     * By passing a rule whereby we say a property of the ignored type can be included when certain
     * conditions are met.
     *
     * ex:
     *
     * const ignoreItemsOfType = { type: 'array', if: { not: { 'items.type': 'string'}};
     * const propSchema = { type: 'array', items: { type: 'string }}
     *
     * would allow this propSchema to be included because its value for `items.type` are equal to
     * the key/value in the `not` clause
     */

    const [conditionKey, conditionValue] = Object.entries(
      condition?.not ?? {},
    )[0];
    return isNotIgnoreType || get(propSchema, conditionKey) === conditionValue;
  });
};

/**
 * @function applySchemaMetadata sorts the Object.entries of a schema
 *
 * Takes a schema and sorts it alphabetically first then, if provided, based on the fieldOrder
 * property of the metadata. Note that the signature of the sort functions accepts the iterated
 * value from Object.entries, which is an array of [key, value]
 *
 * @param {JSONSchemaDefinitionPropType} schemaDefinition the schema
 * @param {object} schemaDefinition.properties the schema
 * @param {JSONschemaMetadataPropTypes} schemaMetadata use properties
 * @param {[]string} schemaMetadata.fieldOrder an array of property names used to custom order properties
 * @param {[]string} schemaMetadata.readOnlyFields an array of property names used to set fields as readOnly
 * @param {[]string} schemaMetadata.hiddenFields an array of property names used to hide from the form
 * @param {object} schemaMetadata.fieldOverrides an object keyed by field name that corresponds to the UI component you want to render
 *
 * @returns {[]Object.entries} [propertyName, value]
 */

export const applySchemaMetadata = (
  { properties } = {},
  {
    fieldOrder = [],
    readOnlyFields = [],
    hiddenFields = [],
    fieldOverrides = {},
    schemaOverrides = {},
  } = {},
  ignoreItemsOfSchemaTypes = [],
  parentPropertyName = '',
) => {
  const entries = Object.entries(properties)
    .filter(
      ([propertyName]) =>
        !hiddenFields.includes(
          getObjectNotationPropertyName(propertyName, parentPropertyName),
        ),
    ) // filter out any hidden fields
    .filter(
      ([propertyName, propSchema]) =>
        includeItemsOfType(ignoreItemsOfSchemaTypes, propSchema) ||
        (fieldOverrides?.[
          getObjectNotationPropertyName(propertyName, parentPropertyName)
        ] ??
          false),
    )
    .sort(alphaSortFields) // we always at least want to sort the properties alphabetically
    .sort(makeSortByFieldOrder(fieldOrder, parentPropertyName)); // optionally, check for a defined field order and also sort on that
  if (
    readOnlyFields.length === 0 &&
    Object.keys(fieldOverrides).length === 0 &&
    Object.keys(schemaOverrides).length === 0
  )
    return entries;
  return entries.map(([propertyName, value]) => {
    const fullPropertyName = getObjectNotationPropertyName(
      propertyName,
      parentPropertyName,
    );
    const readOnly = readOnlyFields.includes(fullPropertyName);
    const fieldComponent = fieldOverrides[fullPropertyName];
    const additionalSchema = schemaOverrides[fullPropertyName];
    return [
      propertyName,
      {
        ...value,
        readOnly,
        fieldComponent,
        ...(value.properties && {
          properties: applySchemaMetadata(
            value,
            {
              readOnlyFields,
              fieldOrder,
              hiddenFields,
              fieldOverrides,
              schemaOverrides,
            },
            ignoreItemsOfSchemaTypes,
            fullPropertyName,
          ),
        }),
        ...(additionalSchema || null),
      },
    ];
  });
};

export const useSchemaMetadata = ({
  schemaDefinition,
  schemaMetadata,
  ignoreItemsOfSchemaTypes,
}) => {
  const enhancedFields = useMemo(
    () =>
      applySchemaMetadata(
        schemaDefinition,
        schemaMetadata,
        ignoreItemsOfSchemaTypes,
      ),
    [schemaMetadata, schemaDefinition, ignoreItemsOfSchemaTypes],
  );

  return enhancedFields;
};

export const useIsRequired = ({ schemaDefinition }) => {
  const isRequired = useCallback(
    name => schemaDefinition?.required?.includes(name),
    [schemaDefinition],
  );
  return isRequired;
};

export const EntityFormFields = props => {
  const fields = useSchemaMetadata(props);
  const isRequired = useIsRequired(props);
  return fields.map(([name, propSchema]) => (
    <EntityFormField
      name={name}
      propSchema={propSchema}
      isRequired={isRequired(name)}
      key={name}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  ));
};

const entityFormFieldsPropTypes = {
  schemaDefinition: JSONSchemaDefinitionPropType,
  schemaMetadata: JSONschemaMetadataPropTypes,
  ignoreItemsOfSchemaTypes: PropTypes.arrayOf(JSONSchemaFieldSchemaTypes),
};

const entityFormFieldsDefaultProps = {
  schemaDefinition: {},
  schemaMetadata: {},
  ignoreItemsOfSchemaTypes: [],
};

EntityFormFields.propTypes = entityFormFieldsPropTypes;
EntityFormFields.defaultProps = entityFormFieldsDefaultProps;

export const TranslatedEntityFormFields = props => {
  const fields = useSchemaMetadata(props);
  const isRequired = useIsRequired(props);
  return fields.map(([name, propSchema]) => (
    <TranslatedEntityFormField
      name={name}
      propSchema={propSchema}
      isRequired={isRequired(name)}
      key={name}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  ));
};

TranslatedEntityFormFields.propTypes = entityFormFieldsPropTypes;
TranslatedEntityFormFields.defaultProps = entityFormFieldsDefaultProps;

export const EntityForm = ({ children, ...props }) => {
  return <Form>{React.cloneElement(children, props)}</Form>;
};

EntityForm.propTypes = {
  children: PropTypes.node.isRequired,
};
