import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from '@leagueplatform/locales';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Flex, Box, Label, LabelText } from '@leagueplatform/genesis-commons';
import { selectEmployerTzName } from '../../selectors';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { AnnualPeriodDate } from 'apps/employer-experience/ui-kit/annual-period-date.view';

const getDateForValue = date => (date instanceof Date ? date : new Date(date));

export const AnnualPeriodPresenter = ({
  field,
  form,
  propSchema,
  employerTzName,
}) => {
  const { formatMessage } = useIntl();
  const { name } = field;
  const value = field?.value ?? {};
  const [startDateProperty, endDateProperty] = propSchema?.properties ?? [];
  const [, startDateSchema] = startDateProperty;
  const [, endDateSchema] = endDateProperty;
  return (
    <Flex
      flexDirection="column"
      as="fieldset"
      padding="half"
      borderWidth="thin"
      borderColor="interactive.background.disabled"
      borderRadius="small"
    >
      <LabelText as="legend">{propSchema.title}</LabelText>
      <Flex flexDirection="row">
        <Box width="100%">
          <Label>
            <LabelText>{startDateSchema.title}</LabelText>
            <AnnualPeriodDate
              value={value && getDateForValue(value.start_date)}
              setDateValue={momentDate => {
                form.setFieldValue(`${name}.start_date`, momentDate.toDate());
              }}
              name={`${name}.start_date`}
              employerTzName={employerTzName}
              defaultHour={0}
              defaultMinute={0}
            />
          </Label>
        </Box>
      </Flex>
      <Flex flexDirection="row">
        <Box>
          <Label>
            <LabelText>{endDateSchema.title}</LabelText>
            <AnnualPeriodDate
              value={value && getDateForValue(value.end_date)}
              setDateValue={momentDate => {
                form.setFieldValue(`${name}.end_date`, momentDate.toDate());
              }}
              name={`${name}.end_date`}
              defaultHour={23}
              defaultMinute={59}
              employerTzName={employerTzName}
            />
          </Label>
        </Box>
      </Flex>
      <Box marginY="one">
        {formatMessage(
          { id: 'EMPLOYER_TIME_ZONE' },
          {
            timezone: moment.tz(employerTzName).zoneAbbr(),
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            strong: msg => <strong>{msg}</strong>,
          },
        )}
      </Box>
    </Flex>
  );
};

AnnualPeriodPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  employerTzName: PropTypes.string.isRequired,
};

export const AnnualPeriod = connect(
  createStructuredSelector({
    employerTzName: selectEmployerTzName,
  }),
)(AnnualPeriodPresenter);
