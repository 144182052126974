import { takeLatest, put, call } from 'redux-saga/effects';
import {
  getStatementOfAccount,
  getEnrollmentSelectionsReport,
} from 'common/services';
import {
  REQUEST_GET_STATEMENT_OF_ACCOUNT,
  GET_STATEMENT_OF_ACCOUNT,
  REQUEST_ENROLLMENT_SELECTION_REPORT,
  GET_ENROLLMENT_SELECTION_REPORT,
} from './finance-reports.types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestStatementOfAccount() {
  try {
    yield put(GET_STATEMENT_OF_ACCOUNT.start());
    const response = yield getStatementOfAccount();
    const contentId = response?.info;
    yield put(GET_STATEMENT_OF_ACCOUNT.success(contentId));
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'GET_STATEMENT_OF_ACCOUNT_ERROR',
    });
    yield put(GET_STATEMENT_OF_ACCOUNT.error());
  }
}

export function* requestEnrollmentSelectionReport({ payload }) {
  try {
    yield put(GET_ENROLLMENT_SELECTION_REPORT.start());
    const response = yield getEnrollmentSelectionsReport(payload);
    const contentId = response?.info;
    yield put(GET_ENROLLMENT_SELECTION_REPORT.success(contentId));
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: error.info?.reason,
    });
    yield put(GET_ENROLLMENT_SELECTION_REPORT.error());
  }
}

export function* financeToolsReportsSaga() {
  yield takeLatest(REQUEST_GET_STATEMENT_OF_ACCOUNT, requestStatementOfAccount);
  yield takeLatest(
    REQUEST_ENROLLMENT_SELECTION_REPORT,
    requestEnrollmentSelectionReport,
  );
}
