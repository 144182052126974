export const BENEFIT_STATUS_OPTED_OUT = 'opted_out';
export const BENEFIT_STATUS_CREATED = 'created';
export const BENEFIT_STATUS_READY = 'ready';
export const BENEFIT_STATUS_ACTIVE = 'active';
export const BENEFIT_STATUS_GRACE_PERIOD = 'grace_period';
export const BENEFIT_STATUS_INACTIVE = 'inactive';
export const BENEFIT_STATUS_INACTIVE_GRACE_PERIOD = 'inactive_grace_period';

export const CAN_BE_REMOVED = [BENEFIT_STATUS_READY, BENEFIT_STATUS_CREATED];

export const BENEFIT_STATUS_COLORS = {};
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_OPTED_OUT] = 'violet';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_CREATED] = 'orange';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_READY] = 'green';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_ACTIVE] = 'teal';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_GRACE_PERIOD] = 'yellow';
BENEFIT_STATUS_COLORS[BENEFIT_STATUS_INACTIVE] = 'red';

export const EMPLOYER_BENEFIT_STATUS_COLORS = {};
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_OPTED_OUT] = 'violet';
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_CREATED] = 'orange';
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_READY] = 'blue';
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_ACTIVE] = 'teal';
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_GRACE_PERIOD] = 'yellow';
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_INACTIVE] = 'red';
EMPLOYER_BENEFIT_STATUS_COLORS[BENEFIT_STATUS_INACTIVE_GRACE_PERIOD] = 'red';

export const BENEFIT_STATUS_MAP = {};
BENEFIT_STATUS_MAP[BENEFIT_STATUS_OPTED_OUT] = 'BENEFIT_OPTED_OUT';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_CREATED] = 'BENEFIT_CREATED';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_READY] = 'BENEFIT_READY';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_ACTIVE] = 'BENEFIT_ACTIVE';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_GRACE_PERIOD] = 'BENEFIT_GRACE_PERIOD';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE] = 'BENEFIT_INACTIVE';
BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE_GRACE_PERIOD] =
  'BENEFIT_INACTIVE_GRACE_PERIOD';

export const EMPLOYER_BENEFIT_STATUS_MAP = {};
EMPLOYER_BENEFIT_STATUS_MAP[BENEFIT_STATUS_OPTED_OUT] = 'BENEFIT_OPTED_OUT';
EMPLOYER_BENEFIT_STATUS_MAP[BENEFIT_STATUS_CREATED] = 'BENEFIT_DRAFT';
EMPLOYER_BENEFIT_STATUS_MAP[BENEFIT_STATUS_READY] = 'BENEFIT_PUBLISHED';
EMPLOYER_BENEFIT_STATUS_MAP[BENEFIT_STATUS_ACTIVE] = 'BENEFIT_ACTIVE';
EMPLOYER_BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE] = 'BENEFIT_INACTIVE';
EMPLOYER_BENEFIT_STATUS_MAP[BENEFIT_STATUS_INACTIVE_GRACE_PERIOD] =
  'BENEFIT_INACTIVE';

export const BENEFIT_STATUS_MAP_COPY = {
  [BENEFIT_STATUS_OPTED_OUT]: 'Opted Out',
  [BENEFIT_STATUS_CREATED]: 'Created',
  [BENEFIT_STATUS_READY]: 'Ready',
  [BENEFIT_STATUS_ACTIVE]: 'Active',
};

export const EMPLOYEE_BENEFITS_BULK_STATUS_TRANSITIONS = [
  BENEFIT_STATUS_READY,
  BENEFIT_STATUS_ACTIVE,
  BENEFIT_STATUS_GRACE_PERIOD,
  BENEFIT_STATUS_INACTIVE,
];

export const EMPLOYEE_BENEFIT_STATUS_TRANSITIONS = {};

EMPLOYEE_BENEFIT_STATUS_TRANSITIONS[BENEFIT_STATUS_CREATED] = [
  BENEFIT_STATUS_READY,
  BENEFIT_STATUS_OPTED_OUT,
];

EMPLOYEE_BENEFIT_STATUS_TRANSITIONS[BENEFIT_STATUS_READY] = [
  BENEFIT_STATUS_OPTED_OUT,
];

EMPLOYEE_BENEFIT_STATUS_TRANSITIONS[BENEFIT_STATUS_ACTIVE] = [
  BENEFIT_STATUS_OPTED_OUT,
];

EMPLOYEE_BENEFIT_STATUS_TRANSITIONS[BENEFIT_STATUS_OPTED_OUT] = [
  BENEFIT_STATUS_READY,
];

export const DATE_FORMAT = 'DD-MM-YYYY';
export const HSA_BENEFIT_TYPE = 'league_spending_hsa';
export const REMOVE_BENEFIT = 'remove';
export const ADJUST_BENEFIT_END_DATE = 'adjust';
