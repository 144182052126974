export default {
  gwlDrugs: {
    name: 'gwlDrugs',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'benefit_year',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'drug_plan_type',
            span: 6,
          },

          {
            name: 'dispensing_fee_cap_amount',
            span: 6,
          },

          {
            name: 'drug_reimbursement_percent',
            span: 6,
          },

          {
            name: 'drug_reimbursement_percent_costco',
            span: 6,
          },

          {
            name: 'preventative_vaccines_included',
            span: 6,
          },

          {
            name: 'erectile_dysfunction_included',
            span: 6,
          },

          {
            name: 'per_prescription_deductible',
            span: 6,
          },

          {
            name: 'per_prescription_deductible_equals_dispensing_fee',
            span: 6,
          },

          {
            name: 'drug_maximum_applies',
            span: 6,
          },

          {
            name: 'drug_maximum_amount',
            span: 6,
          },

          {
            name: 'out_of_pocket_maximum_overall',
            span: 6,
          },

          {
            name: 'out_of_pocket_maximum_per_prescription',
            span: 6,
          },

          {
            name: 'fertility_drug_maximum',
            span: 6,
          },

          {
            name: 'smoking_cessation_maximum',
            span: 6,
          },
        ],
      },
    ],
  },
};
