import { MESSAGE_SENT, MESSAGE_RECEIVED } from './types';

export const addMessageType = action => {
  switch (action.type) {
    case MESSAGE_SENT:
    case MESSAGE_RECEIVED:
      return {
        ...action,
        type: `${action.type} ${action.payload?.message_type}`,
      };
    default:
      return action;
  }
};
