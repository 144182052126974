import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import BenefitClass, { benefitClassShape } from '../BenefitClass';
import AddBenefitClass from '../AddBenefitClass';
import {
  ImportPlanDesignModal,
  ExportPlanDesignModal,
  ExportCustomPlanModal,
} from '../PlanDesign';
import {
  colour,
  fontSizeInPx,
  spaceInPx,
  Box,
  FontAwesomeIcon,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import {
  Dropdown,
  DropdownTrigger,
  DropdownItems,
  DropdownItem,
} from 'common/components/dropdown';

const ImportDropdownTrigger = styled(DropdownTrigger)`
  padding: 0;
  border: none;
  background: none;
  color: ${colour('purple')};
  justify-content: flex-end;

  svg {
    margin-left: ${spaceInPx(1)};
  }

  &:hover:not([disabled]) {
    background: none;
    color: ${colour('purple')};
  }
`;

const ImportDropdownItems = styled(DropdownItems)`
  top: 24px;
  right: 0;
  padding: ${spaceInPx(1)} 0;
  z-index: 99999;

  &:before {
    display: none;
  }

  div {
    padding: ${spaceInPx(2)};
    font-size: ${fontSizeInPx(1)};
    color: ${colour('purpleDarkest')};
    &:hover {
      background: ${colour('purpleLightest')};
    }
  }
`;

export const BenefitsOverview = ({
  benefitClasses,
  loading,
  addBenefitClass,
  addingBenefitClass,
  renameBenefitClass,
  errorAddingBenefitClass,
  errorRenamingBenefitClass,
  renamingBenefitClass,
  isLastBenefitClass,
  removeBenefitClass,
  fetchPlans,
  addBenefitError,
  addBenefitSuccess,
  importPlanDesign,
  exportPlanDesign,
  groupId,
  planDesignRequestStatus,
  resetPlanDesignRequestStatus,
  filteredEmployerBenefits,
}) => {
  const [isImportModalVisible, setImportModalVisibility] = useState(false);
  const toggleImportModal = () =>
    setImportModalVisibility(!isImportModalVisible);
  const [isExportModalVisible, setExportModalVisibility] = useState(false);
  const toggleExportModal = () =>
    setExportModalVisibility(!isExportModalVisible);

  const [isCustomExportModalVisible, setCustomExportModalVisibility] =
    useState(false);
  const toggleCustomExportModal = () =>
    setCustomExportModalVisibility(!isCustomExportModalVisible);
  const { formatMessage } = useIntl();

  return (
    <Dimmer.Dimmable as={Box} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Flex flexDirection="row">
        <Box mb={3}>{formatMessage({ id: 'SETUP_YOUR_BENEFITS_INTRO' })}</Box>
        <Dropdown>
          <ImportDropdownTrigger>
            {formatMessage({ id: 'ACTIONS' })}
            <FontAwesomeIcon icon="chevron-down" />
          </ImportDropdownTrigger>
          <ImportDropdownItems>
            <DropdownItem onClick={toggleImportModal}>
              {formatMessage({ id: 'IMPORT_PLAN_DESIGN' })}
            </DropdownItem>
            <DropdownItem onClick={toggleExportModal}>
              {formatMessage({ id: 'EXPORT_PLAN_DESIGN' })}
            </DropdownItem>
            <DropdownItem onClick={toggleCustomExportModal}>
              {formatMessage({ id: 'EXPORT_CUSTOM_PLAN' })}
            </DropdownItem>
          </ImportDropdownItems>
        </Dropdown>
      </Flex>
      {isImportModalVisible && (
        <ImportPlanDesignModal
          onClose={() => {
            toggleImportModal();
            resetPlanDesignRequestStatus();
          }}
          onConfirmSuccessfulImport={() => window.location.reload()}
          onSubmit={importPlanDesign}
          groupId={groupId}
          planDesignRequestStatus={planDesignRequestStatus}
        />
      )}
      {isExportModalVisible && (
        <ExportPlanDesignModal
          onClose={() => {
            toggleExportModal();
            resetPlanDesignRequestStatus();
          }}
          onSubmit={exportPlanDesign}
          groupId={groupId}
          planDesignRequestStatus={planDesignRequestStatus}
        />
      )}
      {isCustomExportModalVisible && (
        <ExportCustomPlanModal
          onClose={() => {
            toggleCustomExportModal();
            resetPlanDesignRequestStatus();
          }}
          onSubmit={exportPlanDesign}
          groupId={groupId}
          planDesignRequestStatus={planDesignRequestStatus}
          filteredEmployerBenefits={filteredEmployerBenefits}
        />
      )}
      {addBenefitSuccess && (
        <Message positive>
          {formatMessage({ id: 'NEW_BENEFIT_SUCCESS_MESSAGE' })}
        </Message>
      )}
      {addBenefitError && (
        <Message negative>
          {formatMessage({ id: 'NEW_BENEFIT_ERROR_MESSAGE' })}
        </Message>
      )}
      {!loading &&
        benefitClasses.map(({ name, id }) => (
          <BenefitClass
            name={name}
            id={id}
            key={id}
            renameBenefitClass={renameBenefitClass}
            errorRenamingBenefitClass={errorRenamingBenefitClass}
            renamingBenefitClass={renamingBenefitClass}
            isLastBenefitClass={isLastBenefitClass}
            removeBenefitClass={removeBenefitClass}
            fetchPlans={fetchPlans}
          />
        ))}
      <AddBenefitClass
        addBenefitClass={addBenefitClass}
        errorAddingBenefitClass={errorAddingBenefitClass}
        addingBenefitClass={addingBenefitClass}
      />
    </Dimmer.Dimmable>
  );
};

BenefitsOverview.propTypes = {
  benefitClasses: PropTypes.arrayOf(PropTypes.shape(benefitClassShape)),
  loading: PropTypes.bool.isRequired,
  errorAddingBenefitClass: PropTypes.bool.isRequired,
  addingBenefitClass: PropTypes.bool.isRequired,
  errorRenamingBenefitClass: PropTypes.bool.isRequired,
  renamingBenefitClass: PropTypes.string.isRequired,
  renameBenefitClass: PropTypes.func.isRequired,
  addBenefitClass: PropTypes.func.isRequired,
  isLastBenefitClass: PropTypes.func.isRequired,
  removeBenefitClass: PropTypes.func.isRequired,
  fetchPlans: PropTypes.func.isRequired,
  addBenefitError: PropTypes.bool,
  addBenefitSuccess: PropTypes.bool.isRequired,
  importPlanDesign: PropTypes.func.isRequired,
  exportPlanDesign: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  planDesignRequestStatus: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
    errorReason: PropTypes.string,
  }).isRequired,
  resetPlanDesignRequestStatus: PropTypes.func.isRequired,
  filteredEmployerBenefits: PropTypes.shape({}),
};

BenefitsOverview.defaultProps = {
  benefitClasses: undefined,
  addBenefitError: false,
  filteredEmployerBenefits: null,
};
