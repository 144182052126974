import React from 'react';
import PropTypes from 'prop-types';
import { Link, Image, Text } from '@leagueplatform/genesis-commons';
import FILE_DOWNLOAD from 'apps/public/assets/file-download.svg';

export const DownloadLink = ({ href, rel, children, fontSize, ...props }) => (
  <Link
    href={href}
    target="_blank"
    rel={rel}
    width="fit-content"
    display="flex"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    <Image
      src={FILE_DOWNLOAD}
      role="presentation"
      size="oneAndHalf"
      marginRight="half"
    />
    <Text
      as="span"
      color="interactive.action.primary"
      fontWeight="medium"
      fontSize={fontSize}
    >
      {children}
    </Text>
  </Link>
);

DownloadLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  rel: PropTypes.string,
  fontSize: PropTypes.string,
};

DownloadLink.defaultProps = {
  children: null,
  rel: 'noopener noreferrer',
  fontSize: 'body1',
};
