import React, { useState, useEffect } from 'react';
import {
  BodyOne,
  Spinner,
  BodyOneSecondary,
  PrimaryButton,
  HeadingTwo,
  Box,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { Table } from 'semantic-ui-react';
import { BackLink } from 'common/components/back-link/back-link.component';
import { useIntl } from '@leagueplatform/locales';
import type { Formatter } from '@leagueplatform/locales';
import { FieldToggle } from './field-toggle.component';
import { useFieldSettings } from './use-field-settings.hook';
import { EmployeeFormField } from './employee-form-field.type';

const TableLayout = genesisStyled(Table)`
  &.ui.table tbody td,
  &.ui.table tbody td {
    white-space: normal;
  }
`;

const strong: Formatter = text => <strong>{text}</strong>;

export const FieldSettings = () => {
  const { formatMessage } = useIntl();
  const {
    groupId,
    nonSystemFields,
    isLoading,
    isError,
    mutation,
    submitFields,
  } = useFieldSettings();

  const [changedFields, setChangedFields] =
    useState<EmployeeFormField[]>(nonSystemFields);

  useEffect(() => {
    if (nonSystemFields.length !== changedFields.length) {
      setChangedFields(nonSystemFields);
    }
  }, [nonSystemFields, changedFields]);

  const toggleField = (field: EmployeeFormField) => {
    const newList = changedFields.map(item =>
      item.field_name === field.field_name
        ? {
            ...field,
            hidden: !field.hidden,
          }
        : item,
    );
    setChangedFields(newList);
  };

  return (
    <>
      <BackLink
        to={`/admin/employers/${groupId}/employees`}
        label={formatMessage({ id: 'BACK_TO_EMPLOYEES' })}
      />

      {isLoading && <Spinner isLoading height="two" />}

      {isError && (
        <BodyOneSecondary>
          {formatMessage({ id: 'PLEASE_TRY_AGAIN_SHORTLY' })}
        </BodyOneSecondary>
      )}

      {changedFields && (
        <>
          <HeadingTwo marginBottom="half">
            {formatMessage({ id: 'FIELD_SETTINGS_TITLE' })}
          </HeadingTwo>
          <BodyOne>{formatMessage({ id: 'FIELD_SETTINGS_SUBHEADER' })}</BodyOne>
          <BodyOne marginTop="half">
            {formatMessage({ id: 'FIELD_SETTINGS_NOTE' }, { strong })}
          </BodyOne>
          <Box marginBottom="two" marginX="one" as="ul">
            <BodyOne as="li">
              &#x2022; {formatMessage({ id: 'FIELD_SETTINGS_LIST_ONE' })}
            </BodyOne>
            <BodyOne as="li">
              &#x2022; {formatMessage({ id: 'FIELD_SETTINGS_LIST_TWO' })}
            </BodyOne>
          </Box>
          <TableLayout basic="very" padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {formatMessage({ id: 'NAME' })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {formatMessage({ id: 'DESCRIPTION' })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {formatMessage({ id: 'VISIBILITY' })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {changedFields.map(field => (
                <FieldToggle
                  key={field.field_name}
                  field={field}
                  onChange={toggleField}
                />
              ))}
            </Table.Body>
            <PrimaryButton
              marginTop="one"
              onClick={() => submitFields(changedFields)}
              disabled={mutation.isLoading}
            >
              {formatMessage({
                id: 'SAVE',
              })}
            </PrimaryButton>
          </TableLayout>
        </>
      )}
    </>
  );
};
