import { pipe } from 'lodash/fp';
import { map } from 'lodash';
import { explodeObject } from 'common/adaptive-forms';
import { normalizePolicyKind } from '@leagueplatform/web-common';
import { EMPLOYEE_DEPENDENT_VALUE } from './settlement.constants';

export const handleDependent = claim => ({
  ...claim,
  dependent_id:
    claim.dependent_id === EMPLOYEE_DEPENDENT_VALUE ? null : claim.dependent_id,
});

export const serializeTaxes = taxes =>
  map(taxes, (value, name) => ({ name, value }));

export const serializePolicyAllocations = allocations =>
  map(allocations, (allocation, policyKind) => ({
    type: normalizePolicyKind(policyKind),
    amount_approved: allocation.amount_approved,
    category_id: allocation.category_id,
    taxes: serializeTaxes(allocation.taxes),
  }));

export const assignPolicyAllocations = claim => ({
  ...claim,
  policy_allocations: serializePolicyAllocations(claim.policy_allocations),
});

export const prepareClaimPayload = pipe(
  explodeObject,
  handleDependent,
  assignPolicyAllocations,
);
