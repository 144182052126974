import React from 'react';
import PropTypes from 'prop-types';
import { Form, Label } from 'semantic-ui-react';

const ValidFormField = ({
  children,
  errors,
  touched,
  span,
  submitAttempted,
  ...props
}) => {
  const hasErrors = errors.length > 0;
  const showErrors = hasErrors && (touched || submitAttempted);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <Form.Field width={span} error={showErrors} {...props}>
      {children}
      {showErrors && (
        <Label className="subtext">
          {showErrors ? errors.join('; ') : ' '}
        </Label>
      )}
    </Form.Field>
  );
};

ValidFormField.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string), // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  submitAttempted: PropTypes.bool,
  span: PropTypes.number,
};

ValidFormField.defaultProps = {
  errors: [],
  touched: false,
  span: undefined,
  submitAttempted: false,
};

export default ValidFormField;
