import { GET_PAYMENT_PAYOUT_DETAILS } from './payment-payout-details.actions';

export const payoutDetails = (state = { success: false }, action = {}) => {
  switch (action.type) {
    case GET_PAYMENT_PAYOUT_DETAILS.SUCCEEDED:
      return {
        success: true,
        ...action.payload.info.payouts,
      };
    default:
      return state;
  }
};
