import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { FieldWrapper } from 'common/json-schema-form/fields';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { selectAvailableBenefitsForCurrentBundle } from '../enrollment-config.selectors';
import { Select } from 'common/form-fields/Select/Select';

export const BenefitIdSelectPresenter = ({
  field: { name, value } = {},
  form,
  propSchema,
  benefitOptions,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <Select
        name={name}
        options={benefitOptions}
        placeholder="Choose a benefit"
        disabled={propSchema.readOnly}
        hasError={form.errors[name]}
        isClearable
        onChange={evt => {
          const { value: val } = evt ?? {};
          form.setFieldValue(name, val || evt);
        }}
        value={value}
      />
    </FieldWrapper>
  );
};

BenefitIdSelectPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  benefitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

BenefitIdSelectPresenter.defaultProps = {
  benefitOptions: [],
};

const getBenefitLabel = ({
  benefit_full_name: fullName,
  benefit_name: name,
}) => {
  return fullName !== name ? `${fullName} (${name})` : fullName;
};

const mapStateToProps = state => {
  const benefits = selectAvailableBenefitsForCurrentBundle(state);
  return {
    benefitOptions: Object.values(benefits).map(benefit => ({
      label: getBenefitLabel(benefit),
      value: benefit.benefit_id,
    })),
  };
};

export const BenefitIdSelect = connect(mapStateToProps)(
  BenefitIdSelectPresenter,
);
