import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
  selectEmployees,
  selectLoading,
  selectError,
  selectHasMoreResults,
  selectHasEmptyResults,
  selectEmployeesServiceStatus,
  selectQuery,
} from './selectors';
import {
  selectEmployerId,
  selectEmployerHasSpendingAccounts,
  selectEmployerHasTopupableSpendingAccounts,
} from '../selectors';
import {
  onSearchParamsChanged,
  nextSearchPageRequested,
  resetEmployeesState,
  employeesVisited,
} from './actions';
import { onEmployeeRowSelected } from '../Employee/actions';
import Page from './components/Page';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';

const mapStateToProps = createStructuredSelector({
  employees: selectEmployees,
  loading: selectLoading,
  error: selectError,
  hasMoreResults: selectHasMoreResults,
  hasEmptyResults: selectHasEmptyResults,
  employerId: selectEmployerId,
  employeesServiceStatus: selectEmployeesServiceStatus,
  isAdmin: selectUserIsAdmin,
  searchText: selectQuery,
  hasSpendingAccounts: selectEmployerHasSpendingAccounts,
  hasTopupableSpendingAccounts: selectEmployerHasTopupableSpendingAccounts,
});

const dispatchers = {
  onSearchParamsChanged,
  onEndOfListReached: nextSearchPageRequested,
  onEmployeeRowSelected,
  resetEmployeesState,
  employeesVisited,
};

export default compose(connect(mapStateToProps, dispatchers))(Page);
