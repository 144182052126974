import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { map } from 'lodash';
import { mapEmployerBenefitDocumentToPayload } from './operations';
import {
  addEmployerBenefitDocument,
  updateEmployerBenefitDocument,
} from './actions';
import {
  selectEmployerBenefitDocumentsEntities,
  selectAddingEmployerBenefitDocuments,
  selectGroupId,
} from './selectors';
import Documents from './presenter';
import { selectEmployerBenefitDocuments } from 'apps/employer-experience-v2/employer-benefits/benefits.selectors';

const mapStateToProps = (state, { value, setFieldValue, name }) => ({
  groupId: selectGroupId(state),
  addingEmployerBenefitDocuments: selectAddingEmployerBenefitDocuments(state),
  employerBenefitDocuments: selectEmployerBenefitDocumentsEntities(state),
  benefitDocuments: selectEmployerBenefitDocuments(state),
  setFieldValue,
  name,
  documents: value,
});

export default compose(
  connect(mapStateToProps, {
    addEmployerBenefitDocument,
    updateEmployerBenefitDocument,
  }),
  withHandlers({
    addEmployerBenefitDocument: props => benefitDocument => {
      props.addEmployerBenefitDocument({
        contentId: benefitDocument.contentId,
        documents: map([benefitDocument], mapEmployerBenefitDocumentToPayload),
        groupId: props.groupId,
        benefitId: props.benefitId,
      });
    },
    updateEmployerBenefitDocument: props => benefitDocument => {
      props.updateEmployerBenefitDocument({
        contentId: benefitDocument.content.content_id,
        documents: [benefitDocument],
        groupId: props.groupId,
        benefitId: props.benefitId,
      });
    },
  }),
)(Documents);
