import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useIntl } from '@leagueplatform/locales';
import {
  Flex,
  Box,
  BodyOne,
  BodyTwoSecondary,
  Link,
} from '@leagueplatform/genesis-commons';
import { ResponsiveButton, PageHeading } from '../elements';
import { SidebarLayout } from '../sidebar-layout.view';
import { StepPage } from '../step-page.view';
import { TextField } from './text-field.view';
import { DateField } from './date-field.view';
import { UniqueIdentifierFields } from './unique-identifier-field.view';
import { splitToParagraphs } from '../../helpers';

export const VerifyAccount = ({
  heading,
  banner,
  body,
  verificationError,
  verifying,
  beforeEnrollmentPeriod,
  enrollmentStartDate,
  errors,
  handleSubmit,
  uniqueIdentifierFields,
  // Analytics dispatchers
  reportFormErrors,
  helpEmailSelected,
  ready,
  ...props
}) => {
  const { formatMessage } = useIntl();
  if (!ready) return null;

  return (
    <SidebarLayout>
      <StepPage>
        <PageHeading marginBottom="one">{heading}</PageHeading>
        {splitToParagraphs(body)}
        {banner && (
          <Box fontWeight="medium" marginTop="half" marginBottom="oneAndHalf">
            {banner}
          </Box>
        )}
        <BodyTwoSecondary marginTop="twoAndHalf" marginBottom="threeQuarters">
          {formatMessage({
            id: 'ALL_FIELDS_ARE_REQUIRED_UNLESS_STATED_AS_OPTIONAL',
          })}
        </BodyTwoSecondary>
        <Form>
          <Flex flexWrap="wrap" flexDirection={['column', 'row']}>
            <TextField
              name="firstName"
              label={formatMessage({ id: 'FIRST_NAME' })}
            />
            <TextField
              name="lastName"
              label={formatMessage({ id: 'LAST_NAME' })}
            />
            <DateField
              name="dateOfBirth"
              label={formatMessage({ id: 'DATE_OF_BIRTH' })}
            />
            <TextField
              name="emailAddress"
              label={formatMessage({ id: 'EMAIL_ADDRESS' })}
            />
            {uniqueIdentifierFields && (
              <UniqueIdentifierFields
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...props}
                uniqueIdentifierFields={uniqueIdentifierFields}
              />
            )}
          </Flex>

          {verificationError && (
            <Box marginTop="oneAndHalf" role="alert">
              <BodyOne color="onSurface.text.critical" marginBottom="one">
                <FormattedMessage id="WE_COULDNT_FIND_YOU" />
              </BodyOne>
              <BodyOne color="onSurface.text.critical">
                <FormattedMessage
                  id="WE_COULDNT_FIND_YOU_DESCRIPTION"
                  values={{
                    emailLink: (
                      <Link
                        onClick={helpEmailSelected}
                        color="onSurface.text.critical"
                        href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}
                      >
                        <FormattedMessage id="HELP_EMAIL" />
                      </Link>
                    ),
                  }}
                />
              </BodyOne>
            </Box>
          )}
          {beforeEnrollmentPeriod && (
            <BodyOne
              marginTop="oneAndHalf"
              color="onSurface.text.critical"
              role="alert"
            >
              {enrollmentStartDate ? (
                <FormattedMessage
                  id="YOURE_A_TAD_EARLY"
                  values={{
                    enrollmentStartDate: (
                      <FormattedDate
                        value={enrollmentStartDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                      />
                    ),
                  }}
                />
              ) : (
                <FormattedMessage id="YOURE_A_TAD_EARLY_NO_DATE" />
              )}
            </BodyOne>
          )}
          <ResponsiveButton
            type="submit"
            onClick={e => {
              if (!verifying) {
                reportFormErrors(errors);
                handleSubmit(e);
              }
            }}
            loading={verifying}
            loadingText={formatMessage({ id: 'LOADING' })}
          >
            {formatMessage({ id: 'FIND_ACCOUNT' })}
          </ResponsiveButton>
        </Form>
      </StepPage>
    </SidebarLayout>
  );
};

VerifyAccount.propTypes = {
  heading: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  uniqueIdentifierFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),
  verificationError: PropTypes.bool,
  verifying: PropTypes.bool,
  beforeEnrollmentPeriod: PropTypes.bool,
  enrollmentStartDate: PropTypes.instanceOf(Date),
  reportFormErrors: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  errors: PropTypes.objectOf(PropTypes.any),
  identifierHintToggled: PropTypes.func.isRequired,
  helpEmailSelected: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
};

VerifyAccount.defaultProps = {
  uniqueIdentifierFields: [],
  beforeEnrollmentPeriod: false,
  enrollmentStartDate: null,
  verificationError: false,
  verifying: false,
  errors: {},
};
