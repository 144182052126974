import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash';

export const ConnectFieldSelectors = compose(
  connect((state, { propSchema, ...props }) => {
    const selectors = get(propSchema, 'meta.selectors', {});
    return Object.entries(selectors).reduce(
      (allSelectors, [propName, selector]) => ({
        ...allSelectors,
        [propName]: props[propName] ?? selector(state),
      }),
      {},
    );
  }),
);
