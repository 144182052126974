import { createRequestTypes } from 'common/websocket-redux';

export const SET_USER_TERMS = 'userTerms/SET_USER_TERMS';
export const SET_USER_MARKETING = 'userTerms/SET_USER_MARKETING';

export const UPDATE_USER_MEMBER_TERMS_FTUX_STEP = createRequestTypes(
  'userTerms/UPDATE_USER_MEMBER_TERMS_FTUX_STEP',
);

export const SET_USER_ACCEPTED_TERMS = createRequestTypes(
  'userTerms/SET_USER_ACCEPTED_TERMS',
);

export const SET_USER_MARKETING_OPT_IN = createRequestTypes(
  'userTerms/SET_USER_MARKETING_OPT_IN',
);

export const setUserConfig = () => ({
  type: SET_USER_TERMS,
});

export const setUserMarketingOptIn = isMarketingOptIn => ({
  type: SET_USER_MARKETING,
  payload: isMarketingOptIn,
});
