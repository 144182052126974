/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  GET_BENEFICIARY_DETAILS,
  SET_BENEFICIARIES,
  SET_BENEFICIARY_DESIGNATIONS,
  ADD_BENEFICIARY_DESIGNATION,
  REMOVE_BENEFICIARY_DESIGNATION,
  SET_DESIGNATION_SHARE_PERCENTAGE,
  SET_REVOCABLE_DESIGNATION,
} from './beneficiaries.action-types';
import { getBeneficiaryEntityValue } from './beneficiaries.utils';
import { DESIGNATION_TYPES } from 'common/beneficiaries/beneficiaries.constants';

export const initialState = {};

const handleAddBeneficiaryDesignation = (
  { designations },
  {
    productType,
    designationType,
    beneficiaryId: beneficiary_id,
    beneficiaryType: beneficiary_type,
  },
) =>
  designations.map(designation => {
    if (designation.benefit_product_type !== productType) return designation;
    const addedBeneficiary = { beneficiary_id, beneficiary_type };
    return {
      ...designation,
      primary_beneficiaries:
        designationType === DESIGNATION_TYPES.PRIMARY
          ? [...(designation.primary_beneficiaries ?? []), addedBeneficiary]
          : designation.primary_beneficiaries,
      contingent_beneficiaries:
        designationType === DESIGNATION_TYPES.CONTINGENT
          ? [...(designation.contingent_beneficiaries ?? []), addedBeneficiary]
          : designation.contingent_beneficiaries,
    };
  });

const filterByBeneficiaryId = beneficiaryId => beneficiary =>
  beneficiary.beneficiary_id !== beneficiaryId;

const handleRemoveBeneficiaryDesignation = (
  { designations },
  { productType, beneficiaryId, designationType },
) =>
  designations.map(designation => {
    if (designation.benefit_product_type !== productType) return designation;
    return {
      ...designation,
      primary_beneficiaries:
        designationType === DESIGNATION_TYPES.PRIMARY
          ? designation.primary_beneficiaries.filter(
              filterByBeneficiaryId(beneficiaryId),
            )
          : designation.primary_beneficiaries,
      contingent_beneficiaries:
        designationType === DESIGNATION_TYPES.CONTINGENT
          ? designation.contingent_beneficiaries.filter(
              filterByBeneficiaryId(beneficiaryId),
            )
          : designation.contingent_beneficiaries,
    };
  });

const mapSharePercentToDesignation =
  ({ beneficiaryId, sharePercentage }) =>
  beneficiary => {
    if (beneficiary.beneficiary_id !== beneficiaryId) return beneficiary;
    return {
      ...beneficiary,
      share_percentage: sharePercentage,
    };
  };

const handleSetSharePercentage = (
  { designations },
  { productType, designationType, ...payload },
) =>
  designations.map(designation => {
    if (designation.benefit_product_type !== productType) return designation;
    return {
      ...designation,
      primary_beneficiaries:
        designationType === DESIGNATION_TYPES.PRIMARY
          ? designation.primary_beneficiaries.map(
              mapSharePercentToDesignation(payload),
            )
          : designation.primary_beneficiaries,
      contingent_beneficiaries:
        designationType === DESIGNATION_TYPES.CONTINGENT
          ? designation.contingent_beneficiaries.map(
              mapSharePercentToDesignation(payload),
            )
          : designation.contingent_beneficiaries,
    };
  });

const handleSetRevocableBeneficiaries = ({ designations }, payload) =>
  designations.map(designation => {
    return {
      ...designation,
      revocable: payload,
    };
  });

/**
 * when a member submits their beneficiaries there is one of two conditions:
 * 1. they are managing beneficiaries from the beneficiaries tab
 * 2. they have created a beneficiary to add inline to their designations
 *
 * Case 1 is ignored here and the designations are returned untouched
 *
 * To determine if this is case 2, we need to inspect the action's `meta`
 * for the presence of a productType and designationType. There are present
 * if the original setBeneficiaries action was invoked on the route
 * `member/beneficiaries/add/{productType}/{designationType}`
 *
 * See: beneficiary-form.view.js
 *
 * We also have to do a bit of wrangling to determine which beneficiary was just
 * added, because `set_beneficiaries` just takes an array and the response doesn't
 * give us any info about which beneficiary is new.
 *
 */

const handleAddCreatedBeneficiaryToDesignation = (state, action) => {
  if (action?.meta?.productType) {
    // Get a list of the beneficiaries in state before `set_beneficiaries`
    const existingBeneficiariesIds =
      state.beneficiaries?.map(
        beneficiary => getBeneficiaryEntityValue(beneficiary)?.id,
      ) ?? [];

    // Find the beneficiary in the response that didn't exist before, this is our added one
    const addedBeneficiary =
      action?.payload?.info?.beneficiary_details?.beneficiaries?.find(
        beneficiary =>
          !existingBeneficiariesIds.includes(
            getBeneficiaryEntityValue(beneficiary).id,
          ),
      ) ?? {};

    // note: beneficiary data shape: { [beneficiaryType(person|organization)]: { first_name, etc }}
    const [beneficiaryType, beneficiary] = Object.entries(addedBeneficiary)[0];

    return handleAddBeneficiaryDesignation(state, {
      ...action.meta, // productType and designationType
      beneficiaryType,
      beneficiaryId: beneficiary.id,
    });
  }

  return state.designations;
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const beneficiariesReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_BENEFICIARY_DETAILS.SUCCEEDED:
    case SET_BENEFICIARY_DESIGNATIONS.SUCCEEDED:
      return action?.payload?.info?.beneficiary_details;
    case ADD_BENEFICIARY_DESIGNATION:
      return {
        ...state,
        designations: handleAddBeneficiaryDesignation(state, action.payload),
      };
    case REMOVE_BENEFICIARY_DESIGNATION:
      return {
        ...state,
        designations: handleRemoveBeneficiaryDesignation(state, action.payload),
      };
    case SET_DESIGNATION_SHARE_PERCENTAGE:
      return {
        ...state,
        designations: handleSetSharePercentage(state, action.payload),
      };
    case SET_BENEFICIARIES.SUCCEEDED:
      return {
        ...state,
        ...action?.payload?.info?.beneficiary_details,
        designations: handleAddCreatedBeneficiaryToDesignation(state, action),
      };
    case SET_REVOCABLE_DESIGNATION:
      return {
        ...state,
        designations: handleSetRevocableBeneficiaries(state, action.payload),
      };
    default:
      return state;
  }
};
