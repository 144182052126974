import { compose } from 'redux';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import { EMPLOYER_BILLING_DIVISIONS_LIFECYCLE } from './employer-divisions.types';

export const withDispatchers = connect(null, {
  visit: EMPLOYER_BILLING_DIVISIONS_LIFECYCLE.visit,
  exit: EMPLOYER_BILLING_DIVISIONS_LIFECYCLE.exit,
});

export const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.visit(this.props);
  },
  componentWillUnmount() {
    this.props.exit(this.props);
  },
});

export const withLifecycleInitializer = compose(
  withDispatchers,
  withLifecycleActions,
);
