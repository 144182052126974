import { get, set } from 'lodash/fp';
import { normalize, schema } from 'normalizr';
import { GET_EMPLOYER_BENEFITS } from './benefits.types';

const catalogueSchema = new schema.Entity(
  'catalogue',
  {},
  {
    idAttribute: 'id',
    processStrategy: (entity, parent) =>
      set(
        'areMultiple',
        parent.filter(
          benefit =>
            benefit.benefit_type === entity.benefit_type &&
            benefit.full_name === entity.full_name,
        ).length > 1,
        entity,
      ),
  },
);

export const initialState = {
  data: normalize([], [catalogueSchema]),
};

const applyGetEmployerBenefitsSucceeded = (state, { payload }) => {
  let benefits = get('info.benefits', payload) || [];
  benefits = benefits.filter(benefit => benefit.benefit_status !== 'inactive');
  const data = normalize(benefits, [catalogueSchema]);

  return {
    ...state,
    data,
  };
};

export const employeeCatalogueReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMPLOYER_BENEFITS.SUCCEEDED:
      return applyGetEmployerBenefitsSucceeded(state, action);
    default:
      return state;
  }
};
