import { ImportPaySchedulePresenter } from './import-pay-schedule.view';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getEmployerBenefitPlans } from '../enrollment-config/enrollment-config.actions';
import { selectPlanPeriodsFromPlans } from './employer-payroll.selectors';
import { selectGroupId } from '../selectors';

export const ImportPaySchedule = connect(
  createStructuredSelector({
    planPeriods: selectPlanPeriodsFromPlans,
    groupId: selectGroupId,
  }),
  {
    getEmployerBenefitPlans,
  },
)(ImportPaySchedulePresenter);
