/**
 * Error codes returned from `get_qle_request` service.
 * These are used to differentiate between invalid vs
 * expired QLE requests.
 *
 * Note: `invalid_request` is the default backend error code
 * that is commonly sent on error. This is treated as "invalid QLE".
 */
export const EXPIRED = 'expired';
export const INVALID_REQUEST = 'invalid_request';
