import {
  ADD_BENEFIT_PLAN,
  RENAME_BENEFIT_PLAN,
  REMOVE_BENEFIT_PLAN,
  COPY_BENEFIT_PLAN,
  FETCH_CATALOGUE,
  FETCH_BENEFITS,
  FETCH_BENEFIT_TYPE_SCHEMAS,
  RENEW_BENEFIT_PLAN,
} from './types';

export const renameBenefitPlan = payload => ({
  type: RENAME_BENEFIT_PLAN,
  payload,
});

export const removeBenefitPlan = payload => ({
  type: REMOVE_BENEFIT_PLAN,
  payload,
});

export const addBenefitPlan = payload => ({
  type: ADD_BENEFIT_PLAN,
  payload,
});

export const copyBenefitPlan = payload => ({
  type: COPY_BENEFIT_PLAN.BASE,
  payload,
});

export const renewBenefitPlan = payload => ({
  type: RENEW_BENEFIT_PLAN.BASE,
  payload,
});

export const fetchCatalogue = payload => ({
  type: FETCH_CATALOGUE,
  payload,
});

export const fetchBenefits = payload => ({
  type: FETCH_BENEFITS,
  payload,
});

export const fetchBenefitTypeSchemas = payload => ({
  type: FETCH_BENEFIT_TYPE_SCHEMAS,
  payload,
});
