import { FETCH_INVITE, FETCH_DEPENDENT_INVITE } from './invite.actions';

export const initialState = {
  ready: false,
  error: false,
};

export const inviteReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_INVITE.SUCCEEDED:
    case FETCH_DEPENDENT_INVITE.SUCCEEDED:
      return {
        ...state,
        ready: true,
        error: false,
        info: action.payload?.info,
      };
    case FETCH_INVITE.ERRORED:
    case FETCH_DEPENDENT_INVITE.ERRORED:
      return {
        ...state,
        ready: true,
        error: true,
        info: action.payload?.info,
      };
    default:
      return state;
  }
};
