import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { selectAddingBenefit } from '../selectors';
import { SUBMIT_ATTEMPTED } from 'common/adaptive-forms';
import { selectBenefitCreationSuccess } from './selectors';

class Submit extends Component {
  constructor(props) {
    super(props);
    this.onSubmittedBenefits = this.onSubmittedBenefits.bind(this);
    this.state = {
      submittedBenefits: false,
    };
  }

  onSubmittedBenefits() {
    this.setState({ submittedBenefits: true });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setStatus(SUBMIT_ATTEMPTED);
  }

  render() {
    const { loading, success } = this.props;
    let stateMessage = null;
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (!loading && this.state.submittedBenefits) {
      stateMessage = success ? (
        <Message positive>
          <FormattedMessage id="BENEFIT_SAVED" />
        </Message>
      ) : (
        <Message negative>
          <FormattedMessage id="BENEFIT_ERROR" />
        </Message>
      );
    }

    return (
      <div>
        {stateMessage}
        <Button
          type="submit"
          value="Submit"
          color="violet"
          className="button--benefit-submit"
          loading={loading}
          onClick={this.onSubmittedBenefits}
          style={{ marginTop: '2rem' }}
        >
          <FormattedMessage id="SAVE_BENEFIT" />
        </Button>
      </div>
    );
  }
}

Submit.propTypes = {
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: selectAddingBenefit(state),
  success: selectBenefitCreationSuccess(state),
});

export default compose(connect(mapStateToProps))(Submit);
