import {
  BULK_UPLOAD_SELECTED,
  SEARCH_PARAMS_CHANGED,
  NEXT_SEARCH_RESULTS_PAGE_REQUESTED,
  TERMINATE_EMPLOYEES,
  SUSPEND_EMPLOYEES,
  REINSTATE_EMPLOYEES,
  EMPLOYEE_ROW_SELECTED,
  UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  UNSCHEDULE_EMPLOYEES_SUSPENSION,
  UNSCHEDULE_EMPLOYEES_TERMINATION,
  SET_USER_ROLE_IN_GROUP,
} from './search.types';

export const onEmployeeRowSelected = (userId, groupId) => ({
  type: EMPLOYEE_ROW_SELECTED,
  payload: { userId, groupId },
});

export const onBulkUploadSelected = () => ({ type: BULK_UPLOAD_SELECTED });

export const onSearchParamsChanged = params => ({
  type: SEARCH_PARAMS_CHANGED,
  payload: params,
});

export const nextSearchPageRequested = () => ({
  type: NEXT_SEARCH_RESULTS_PAGE_REQUESTED,
});

export const terminateEmployees = payload => ({
  type: TERMINATE_EMPLOYEES,
  payload,
});

export const suspendEmployees = payload => ({
  type: SUSPEND_EMPLOYEES,
  payload,
});

export const reinstateEmployees = payload => ({
  type: REINSTATE_EMPLOYEES,
  payload,
});

export const unscheduleEmployeesSuspension = payload => ({
  type: UNSCHEDULE_EMPLOYEES_SUSPENSION,
  payload,
});

export const unscheduleEmployeesTermination = payload => ({
  type: UNSCHEDULE_EMPLOYEES_TERMINATION,
  payload,
});

export const unscheduleEmployeesReinstatement = payload => ({
  type: UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  payload,
});

export const setUserRoleInGroup = ({ userId, groupId, role }) => ({
  type: SET_USER_ROLE_IN_GROUP,
  payload: {
    userId,
    groupId,
    role,
  },
});
