import {
  CLEAR_EMPLOYER_DETAILS,
  FETCH_BENEFITS,
  EMPLOYER_VISITED,
} from './types';

export const clearEmployerDetails = () => {
  return {
    type: CLEAR_EMPLOYER_DETAILS,
  };
};

export const fetchBenefits = payload => {
  return {
    type: FETCH_BENEFITS,
    payload,
  };
};

export const visitEmployer = payload => {
  return {
    type: EMPLOYER_VISITED,
    payload,
  };
};
