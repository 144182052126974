import { createRequestTypes } from 'common/websocket-redux';

export const ENROLLMENT_CONFIG_VISITED =
  'enrollment-config/ENROLLMENT_CONFIG_VISITED';

export const GET_BENEFIT_PLAN_OPTIONS = createRequestTypes(
  'service/GET_BENEFIT_PLAN_OPTIONS',
);

export const SET_BENEFIT_PLAN_OPTIONS = createRequestTypes(
  'enrollment-config/SET_BENEFIT_PLAN_OPTIONS',
);

export const CREATE_BENEFIT_PLAN_OPTIONS = createRequestTypes(
  'enrollment-config/CREATE_BENEFIT_PLAN_OPTIONS',
);

export const GET_BENEFIT_PLAN_OPTIONS_SCHEMA = createRequestTypes(
  'service/GET_BENEFIT_PLAN_OPTIONS_SCHEMA',
);

export const FETCH_BENEFIT_PLAN_OPTIONS =
  'enrollment-config/FETCH_BENEFIT_PLAN_OPTIONS';

export const BENEFIT_PLAN_OPTIONS_NOT_FOUND =
  'enrollment-config/BENEFIT_PLAN_OPTIONS_NOT_FOUND';

export const SET_CURRENT_PLAN_ID = 'enrollment-config/SET_CURRENT_PLAN_ID';

export const SET_CURRENT_BUNDLE_PATH =
  'enrollment-config/SET_CURRENT_BUNDLE_PATH';

export const UPLOAD_FORM_DOCUMENTS = createRequestTypes(
  'enrollment-config/UPLOAD_FORM_DOCUMENTS',
);

export const FORM_VALUES_RESET = 'enrollment-config/FORM_VALUES_RESET';

export const START_ENROLLMENT = createRequestTypes(
  'enrollment-config/START_ENROLLMENT',
);

export const VALIDATE_BENEFIT_PLAN_CONFIG = createRequestTypes(
  'enrollment-config/VALIDATE_BENEFIT_PLAN_CONFIG',
);
