import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import { tween, easing } from 'popmotion';
import { Box, Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import { colour, ClickEater, Icon, ICONS } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { useIntl } from '@leagueplatform/locales';

export const drawerWidth = 520;

export const drawerHeaderHeight = 60;

const tweenTransition = props =>
  tween({ ...props, ease: easing.easeInOut, duration: 250 });

const Backdrop = posed(genesisStyled(Box)`
  width: 100vw;
  height: 100vh;
  background: ${colour('backdrop')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`)({
  open: {
    opacity: 1,
    transition: tweenTransition,
  },
  closed: {
    opacity: 0,
    transition: tweenTransition,
  },
});

const Sidebar = posed(genesisStyled(Flex)`
  height: 100vh;
  width: 100vw;
  max-width: ${drawerWidth}px;
  background: white;
  position: fixed;
  top: 0;
  right: -${drawerWidth}px;
  opacity: 0;
  flex-direction: column;
`)({
  open: {
    opacity: 1,
    right: 0,
    transition: tweenTransition,
  },
  closed: {
    opacity: 0,
    right: drawerWidth * -1,
    transition: tweenTransition,
  },
});

export const DrawerHeader = styled(props => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    backgroundColor="highlight.background.subdued"
    padding="oneAndHalf"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
))`
  height: ${drawerHeaderHeight}px;
`;

export const CloseDrawerButton = styled(props => {
  const { formatMessage } = useIntl();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <Button aria-label={formatMessage({ id: 'CLOSE' })} {...props}>
      <Icon
        size="small"
        icon={ICONS.CLOSE}
        id="icon-close"
        title="close"
        role="img"
      />
    </Button>
  );
})`
  border: none;
  background: transparent;
  height: 40px;
  width: 40px;
  margin-right: -10px;
  padding: 0;
  border-radius: 50%;
`;

export const Drawer = ({ isOpen, onClose, children, ...props }) => {
  const poseState = isOpen ? 'open' : 'closed';

  return (
    <Backdrop onClick={onClose} pose={poseState} isOpen={isOpen}>
      <ClickEater>
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <Sidebar {...props} pose={poseState}>
          {children}
        </Sidebar>
      </ClickEater>
    </Backdrop>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

Drawer.defaultProps = {
  isOpen: true,
  onClose: () => {},
  children: null,
};
