import moment from 'moment-timezone';

export const startOfDayInTimeZone = (date, timeZone) => {
  const midnight = moment(date).format('YYYY-MM-DD 00:00');
  return moment.tz(midnight, timeZone);
};

export const dayInTimeZone = (date, timeZone) => {
  const tzTime = moment.tz(date, timeZone);
  const midday = moment(tzTime).format('YYYY-MM-DD 12:00');
  return moment(midday);
};
