import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const BenefitClassIdSelect = ({
  name,
  label,
  value,
  options,
  handleBlur,
  setFieldValue,
  benefitClassChanged,
  employeeStatus,
}) => (
  <Form.Select
    search
    name={name}
    label={label}
    placeholder={label}
    options={options}
    value={value}
    onBlur={handleBlur}
    disabled={employeeStatus === 'active'}
    onChange={(event, data) => {
      benefitClassChanged(data.value);
      setFieldValue(data.name, data.value);
    }}
  />
);

BenefitClassIdSelect.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  benefitClassChanged: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  employeeStatus: PropTypes.string.isRequired,
};

BenefitClassIdSelect.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  value: '',
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  employeeStatus: '',
};

export default BenefitClassIdSelect;
