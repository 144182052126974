import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectBenefitPlanList } from 'common/benefit-plans';
import { useIntl } from '@leagueplatform/locales';
import { Formik, Form } from 'formik';
import { ResetUserBenefitsPresenter } from './reset-user-benefits.view';
import moment from 'moment-timezone';
import { CLEAR_SELECTION } from './constants';

const convertToTorontoTZ = time => {
  const torontoTZOffset = -300;
  const momentTime = moment(time);
  const userTZOffset = momentTime.utcOffset();
  const timeDifferenceInMinutes = userTZOffset - torontoTZOffset;
  momentTime.add(timeDifferenceInMinutes, 'minutes');
  return momentTime.utc().toDate();
};

export const ResetUserBenefits = ({
  resetEmployeeUserBenefits,
  userId,
  groupId,
  onClose,
  initialValues,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const plans = useSelector(selectBenefitPlanList);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validate={values => {
        const errors = {};
        if (!values.enrollment_end_date) {
          errors.enrollment_end_date = formatMessage({
            id: 'ENROLLMENT_END_DATE_IS_REQUIRED',
          });
        }
        if (!values.operation_type) {
          errors.operation_type = formatMessage({
            id: 'YOU_MUST_SELECT_A_RESET_TYPE',
          });
        }
        return errors;
      }}
      onSubmit={({
        enrollment_end_date: enrollmentEndDateUTC,
        operation_type: operationType,
        plan_id: planId,
        enrollment_type: enrollmentType,
      }) => {
        const enrollmentEndDate = convertToTorontoTZ(enrollmentEndDateUTC);
        resetEmployeeUserBenefits({
          userId,
          groupId,
          planId,
          enrollmentEndDate,
          enrollmentType,
          clearSelection: operationType === CLEAR_SELECTION,
        });

        onClose();
      }}
    >
      {formProps => (
        <Form data-testid="rest-user-benefits-form">
          <ResetUserBenefitsPresenter
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...formProps}
            plans={plans}
            onClose={onClose}
          />
        </Form>
      )}
    </Formik>
  );
};

ResetUserBenefits.propTypes = {
  onClose: PropTypes.func.isRequired,
  resetEmployeeUserBenefits: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  employerTz: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    enrollment_end_date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    operation_type: PropTypes.string,
    plan_id: PropTypes.string,
    enrollment_type: PropTypes.string,
  }),
};

ResetUserBenefits.defaultProps = {
  initialValues: {
    enrollment_end_date: '',
    operation_type: '',
    plan_id: '',
    enrollment_type: '',
  },
};
