import { useQueryClient, useMutation, useQuery } from 'react-query';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

import {
  getGroup,
  GET_GROUP_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import {
  getUserCertifications,
  GET_USER_CERTIFICATION_MESSAGE_TYPE,
} from './query/get_user_certifications';
import { setUserCertification } from './query/set_user_certification';

export const useFormUserCertification = (userId, employeeGroupId) => {
  const { data, isLoading, isError } = useQuery(
    [GET_USER_CERTIFICATION_MESSAGE_TYPE, userId],
    () => getUserCertifications(userId),
  );
  const groupData = useQuery([GET_GROUP_MESSAGE_TYPE, employeeGroupId], () =>
    getGroup(employeeGroupId),
  );

  const currency = groupData?.data?.group?.currency;
  const queryClient = useQueryClient();

  const contentMutation = useMutation(values => setUserCertification(values), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        GET_USER_CERTIFICATION_MESSAGE_TYPE,
        userId,
      ]);
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'SAVED_CREDITS',
      });
    },
    onError: error => {
      const errorObj = JSON.parse(error.message);
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR',
        content: errorObj?.info?.reason,
      });
    },
  });

  const onSubmitSetUserCertification = values => {
    const userCertification = {
      user_id: userId,
      certification_type: values.certificationType,
      effective_date: values.effectiveDate,
      expiry_date: values.expiryDate,
      amount: {
        F: Number(values.setAmount),
        C: currency || 'CAD',
      },
    };
    contentMutation.mutate(userCertification);
  };

  return {
    onSubmitSetUserCertification,
    userCertificationData: data?.user_certifications?.[0],
    isLoading,
    isError,
  };
};
