import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { get, values } from 'lodash/fp';

export const selectEmployerDetails = get(
  'apps.employer-experience.pages.employerDetails',
);

export const selectEmployer = createSelector(
  selectEmployerDetails,
  get('employer'),
);

export const selectImportPlanDesignRequestStatus = createSelector(
  selectEmployerDetails,
  get('planDesignRequestStatus'),
);

export const selectGroupId = createSelector(selectEmployer, get('groupId'));
export const selectActiveGroupId = get('groups.activeGroupId');

export const selectEmployerName = createSelector(
  selectEmployer,
  get('group.name'),
);

export const selectGroupCurrency = createSelector(
  selectEmployer,
  get('group.currency'),
);

export const selectGroupCountryCode = createSelector(
  selectEmployer,
  employer => employer?.group?.countryCode,
);
export const selectEmployerId = createSelector(selectEmployer, get('groupId'));

export const selectEmployerTimeZone = createSelector(
  selectEmployer,
  get('tzName'),
);

export const selectIsReady = createSelector(
  selectEmployerDetails,
  get('ready'),
);

// DEPRECATED – remains for backward compatability
export const selectIsLoading = createSelector(selectIsReady, ready => !ready);

export const selectEmployerHasSpendingAccounts = createSelector(
  selectEmployer,
  get('hasSpendingAccounts'),
);

export const selectEmployerBenefits = createSelector(
  selectEmployerDetails,
  get('employerBenefits.entities.benefits'),
);

export const selectFilteredEmployerBenefits = createSelector(
  selectEmployerBenefits,
  (benefits = {}) => {
    const getInitialYear = benefitStartDate =>
      String(new Date(benefitStartDate).getUTCFullYear());
    const getBenefitName = (planName, fullName) =>
      planName && planName !== fullName
        ? `${fullName} | ${planName}`
        : fullName;

    const allBenefits = benefits && Object.values(benefits);

    const sortBenefits = (a, b) => {
      if (a.initialYear > b.initialYear) return -1;
      if (a.initialYear < b.initialYear) return 1;

      if (a.className && b.className) {
        if (a.className > b.className) return 1;
        if (a.className < b.className) return -1;
      } else if (a.className || b.className) {
        return !a.className - !b.className;
      }
      return a;
    };

    if (allBenefits.length > 0) {
      const addBenefits = allBenefits.map(benefit => {
        const initialYear = getInitialYear(benefit.benefitStartDate);
        const benefitName = getBenefitName(benefit.planName, benefit.fullName);
        return { ...benefit, initialYear, benefitName };
      });

      return addBenefits.sort(sortBenefits);
    }

    return allBenefits;
  },
);

export const selectEmployerHasTopupableSpendingAccounts = createSelector(
  selectEmployerBenefits,
  (benefits = {}) => {
    return !!values(benefits).find(b => b.benefit && b.benefit.canTopUp);
  },
);

export const selectEmployerStatus = createSelector(
  selectEmployerDetails,
  get('employer.group.employer.status'),
);

export const selectEnrollmentDates = createSelector(
  selectEmployerDetails,
  get('employer.group.employer.benefitsAnnualEnrollmentPeriod'),
);

export const selectEmployerTzName = createSelector(
  selectEmployerDetails,
  get('employer.tzName'),
);

export const selectInitialNewEmployeeEnrollmentDuration = createSelector(
  selectEmployerDetails,
  get('employer.group.employer.newEmployeeEnrollmentDurationDays'),
);

export const selectInitialEnrollmentStartDate = createSelector(
  selectEnrollmentDates,
  selectEmployerTzName,
  (dates, tzName) =>
    dates && dates.startDate && moment.tz(dates.startDate, tzName),
);

export const selectInitialEnrollmentEndDate = createSelector(
  selectEnrollmentDates,
  selectEmployerTzName,
  (dates, tzName) => dates && dates.endDate && moment.tz(dates.endDate, tzName),
);

export const selectBillingDivisions = createSelector(
  selectEmployer,
  get('billingDivisions'),
);
