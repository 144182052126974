import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { ClaimDetails } from './claim-details.component';
import {
  selectClaim,
  selectClaimHistory,
  selectReady,
  selectClaimDocuments,
} from './state/claim-details.selectors';
import {
  visited,
  exited,
  onToggleDocumentSelected,
  editClaimSelected,
  cancelClaimSelected,
  chatClaimSelected,
} from './state/claim-details.actions';

const withConnect = connect(
  createStructuredSelector({
    claim: selectClaim,
    claimHistory: selectClaimHistory,
    documents: selectClaimDocuments,
  }),
  {
    onToggleDocumentSelected,
    editClaimSelected,
    cancelClaimSelected,
    chatClaimSelected,
  },
);

export const ClaimDetailsContainer = compose(
  connect(state => ({ ready: selectReady(state) }), { visited, exited }),
  lifecycle({
    componentDidMount() {
      this.props.visited({ claimId: this.props.match.params.claimId });
    },
    componentWillUnmount() {
      this.props.exited();
    },
  }),
  withConnect,
)(ClaimDetails);

ClaimDetailsContainer.displayName = 'ClaimDetailsContainer';
