import React from 'react';
import { theme } from 'theme';
import { AnimatedLeagueLogo } from './common/animated-league-logo/animated-league-logo.component';

export const appUiConfig = {
  theme,
  components: {
    loading: { element: <AnimatedLeagueLogo size={50} /> },
  },
  features: {
    pointsEnabled: true,
  },
};
