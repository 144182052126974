import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingTwo,
  Box,
  Flex,
  Spinner,
  Link,
  BodyOne,
  BodyOneSecondary,
  SubtitleOne,
  DangerButton,
  SubtleButton,
} from '@leagueplatform/genesis-commons';
import { Modal, ModalButtons } from 'apps/employer-experience/ui-kit';
import { STATUSES } from './plan-enrollment.constants';
import { SHORT_WEEKDAY_DATE_FORMAT } from '@leagueplatform/web-common';

const ItemContainer = props => (
  <Flex
    marginY="one"
    padding="oneAndHalf"
    borderRadius="small"
    borderWidth="thin"
    borderColor="onSurface.border.subdued"
    justifyContent="space-between"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const PlanEnrollmentItem = ({
  enrollment_details: {
    enrollment_end_date: enrollmentEndDate,
    enrollment_type: enrollmentType,
    enrollment_status: enrollmentStatus,
  },
}) => {
  const { formatDate, formatMessage } = useIntl();

  return (
    <ItemContainer>
      <Box>
        <SubtitleOne>{formatMessage({ id: 'TYPE' })}</SubtitleOne>
        <BodyOne fontWeight="bold">{enrollmentType}</BodyOne>
      </Box>
      <Box>
        <SubtitleOne>
          {formatMessage({ id: 'ENROLLMENT_END_DATE' })}
        </SubtitleOne>
        <BodyOne fontWeight="bold">
          {formatDate(enrollmentEndDate, {
            ...SHORT_WEEKDAY_DATE_FORMAT,
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
          })}
        </BodyOne>
      </Box>
      <Box>
        <SubtitleOne>{formatMessage({ id: 'STATUS' })}</SubtitleOne>
        <BodyOne fontWeight="bold">{enrollmentStatus}</BodyOne>
      </Box>
    </ItemContainer>
  );
};

const enrollmentDetailsShape = PropTypes.shape({
  enrollment_end_date: PropTypes.string,
  enrollment_type: PropTypes.string,
  enrollment_status: PropTypes.string,
});

PlanEnrollmentItem.propTypes = {
  enrollment_details: enrollmentDetailsShape.isRequired,
};

const ConfirmSavePlanEnrollmentsModal = ({ onCancelClick, onConfirmClick }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal onClose={onCancelClick}>
      <HeadingTwo>
        {formatMessage({ id: 'CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_TITLE' })}
      </HeadingTwo>
      <Box marginY="oneAndHalf">
        <BodyOne marginTop="one">
          {formatMessage({
            id: 'CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_MESSAGE',
          })}
        </BodyOne>
      </Box>
      <ModalButtons justifyContent="space-between">
        <SubtleButton onClick={onCancelClick}>
          {formatMessage({ id: 'CANCEL' })}
        </SubtleButton>
        <DangerButton onClick={onConfirmClick}>
          {formatMessage({ id: 'YES_REMOVE' })}
        </DangerButton>
      </ModalButtons>
    </Modal>
  );
};

ConfirmSavePlanEnrollmentsModal.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

const usePlanEnrollments = ({
  planEnrollments: initialPlanEnrollments,
  removePlanEnrollment,
}) => {
  const [planEnrollments, setPlanEnrollments] = useState(null);
  const [isRemovingPlanEnrollment, setIsRemovingPlanEnrollment] =
    useState(false);

  const onRemoveClick = () => setIsRemovingPlanEnrollment(true);
  const onCancelClick = () => setIsRemovingPlanEnrollment(false);

  const onConfirmClick = () => {
    removePlanEnrollment();
    setIsRemovingPlanEnrollment(false);
  };

  useEffect(() => {
    setPlanEnrollments(initialPlanEnrollments);
  }, [initialPlanEnrollments]);

  return {
    planEnrollments,
    removePlanEnrollment,
    isRemovingPlanEnrollment,
    onRemoveClick,
    onCancelClick,
    onConfirmClick,
  };
};

export const PlanEnrollmentPresenter = ({
  status,
  benefitPlan,
  onBackClick,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    planEnrollments,
    isRemovingPlanEnrollment,
    onRemoveClick,
    ...planEnrollmentProps
  } = usePlanEnrollments(props);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
      <Link onClick={onBackClick}>
        {formatMessage({ id: 'BACK_TO_PREVIOUS' })}
      </Link>
      <HeadingTwo marginTop="one" marginBottom="two">
        {formatMessage(
          { id: 'USER_PLAN_ENROLLMENTS_TITLE' },
          { planName: benefitPlan?.name },
        )}
      </HeadingTwo>
      {status === STATUSES.LOADING && (
        <ItemContainer borderWidth={0} justifyContent="center">
          <Spinner isLoading />
        </ItemContainer>
      )}
      {status === STATUSES.READY &&
        (planEnrollments?.length > 0 ? (
          planEnrollments.map((planEnrollment, index) => (
            <PlanEnrollmentItem
              key={planEnrollment.plan_id}
              index={index}
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...planEnrollmentProps}
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...planEnrollment}
            />
          ))
        ) : (
          <BodyOneSecondary>
            {formatMessage({ id: 'NO_PLAN_ENROLLMENT' })}
          </BodyOneSecondary>
        ))}
      <Flex justifyContent="flex-end" marginTop="three">
        <DangerButton onClick={onRemoveClick}>
          {formatMessage({ id: 'REMOVE' })}
        </DangerButton>
      </Flex>

      {isRemovingPlanEnrollment && (
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <ConfirmSavePlanEnrollmentsModal {...planEnrollmentProps} />
      )}
    </>
  );
};

PlanEnrollmentPresenter.propTypes = {
  planEnrollments: PropTypes.arrayOf(
    PropTypes.shape({
      enrollment_details: enrollmentDetailsShape,
    }),
  ),
  status: PropTypes.string.isRequired,
  benefitPlan: PropTypes.shape({
    name: PropTypes.string,
  }),
  onBackClick: PropTypes.func.isRequired,
};

PlanEnrollmentPresenter.defaultProps = {
  benefitPlan: null,
  planEnrollments: [],
};
