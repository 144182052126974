import {
  BodyTwo,
  Box,
  genesisStyled,
  Image,
  Link,
  Overline,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import ChevronRight from 'apps/public/assets/chevron-right.svg';
import {
  CLAIM_STATUS_TRANSLATIONS,
  MORE_INFORMATION_NEEDED,
  SUBMITTED,
  APPROVED,
  REJECTED,
  PAID,
} from 'common/constants';
import { ClaimDocumentsThumbnail } from '../documents-thumbnail/documents-thumbnail.component';
import { claimShape } from '../../types/types';
import {
  StatusBadge,
  STATUS_OPTIONS,
} from '@leagueplatform/web-common-components';

const noDataPlaceholder = '---';

const CLAIM_TABLE_CAPTION_ID = 'claims-heading-id';

const Bold = styled.strong`
  font-weight: 500;
`;

const TableCell = props => (
  <BodyTwo
    as="td"
    borderBottomColor="onSurface.border.subdued"
    borderBottomStyle="solid"
    borderBottomWidth="thin"
    padding="one"
    verticalAlign="middle"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const StyledTableCell = genesisStyled(TableCell)`
  white-space: nowrap;
`;

const TableHeadingCell = props => (
  <Overline
    as="th"
    borderBottomColor="onSurface.border.subdued"
    borderBottomStyle="solid"
    borderBottomWidth="thin"
    color="onSurface.text.subdued"
    padding="one"
    textAlign="left"
    verticalAlign="middle"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const mapClaimStatus = claimStatus => {
  switch (claimStatus) {
    case SUBMITTED:
      return STATUS_OPTIONS.HIGHLIGHT;
    case MORE_INFORMATION_NEEDED:
      return STATUS_OPTIONS.WARNING;
    case APPROVED:
      return STATUS_OPTIONS.HIGHLIGHT;
    case REJECTED:
      return STATUS_OPTIONS.CRITICAL;
    case PAID:
      return STATUS_OPTIONS.SUCCESS;
    default:
      return STATUS_OPTIONS.DISABLED;
  }
};

export const ClaimsTable = ({ claims, history, onSelectClaim }) => {
  const { formatDate, formatMessage, formatNumber } = useIntl();
  return (
    <Box as="table" width={1}>
      <VisuallyHidden as="caption" id={CLAIM_TABLE_CAPTION_ID}>
        {formatMessage({ id: 'CLAIM_TABLE_CAPTION' })}
      </VisuallyHidden>
      <thead>
        <Box as="tr">
          <TableHeadingCell>
            <VisuallyHidden>
              {formatMessage({ id: 'DOCUMENT_THUMBNAIL_HIDDEN' })}
            </VisuallyHidden>
          </TableHeadingCell>
          <TableHeadingCell minWidth={136}>
            {formatMessage({ id: 'LAST_UPDATED' })}
          </TableHeadingCell>
          <TableHeadingCell minWidth={100}>
            {formatMessage({ id: 'CLAIM_REF_NUMBER' })}
          </TableHeadingCell>
          <TableHeadingCell maxWidth={200}>
            {formatMessage({ id: 'VENDOR' })}
          </TableHeadingCell>
          <TableHeadingCell>{formatMessage({ id: 'AMOUNT' })}</TableHeadingCell>
          <TableHeadingCell>{formatMessage({ id: 'STATUS' })}</TableHeadingCell>
          <TableHeadingCell>
            <VisuallyHidden>
              {formatMessage({ id: 'CLAIMS_TABLE_READ_MORE_HIDDEN' })}
            </VisuallyHidden>
          </TableHeadingCell>
        </Box>
      </thead>
      <tbody>
        {claims.map(
          ({
            id,
            reference,
            status,
            vendor,
            amount,
            currency,
            documents,
            lastUpdateTime,
          }) => {
            const claimUrl = `/member/claims/${id}`;
            const badgeStatus = mapClaimStatus(status);

            return (
              <Box
                as="tr"
                key={id}
                onClick={() => {
                  history.push(claimUrl);
                  onSelectClaim(id); // For claimsAnalyticsSaga
                }}
                cursor="pointer"
                hoverStyle={{
                  backgroundColor: 'surface.background.secondary',
                }}
              >
                <TableCell>
                  {documents.length > 0 && (
                    <ClaimDocumentsThumbnail
                      contentId={documents[0].contentId}
                      contentType={documents[0].contentType}
                      hasMultipleDocuments={documents && documents.length > 1}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {lastUpdateTime &&
                    formatDate(lastUpdateTime, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                </TableCell>
                <StyledTableCell>{reference}</StyledTableCell>
                <TableCell>
                  <Bold>{vendor || noDataPlaceholder}</Bold>
                </TableCell>
                <TableCell>
                  {amount
                    ? formatNumber(amount, {
                        currency,
                        currencyDisplay: 'symbol',
                        minimumFractionDigits: 2,
                        style: 'currency',
                      })
                    : noDataPlaceholder}
                </TableCell>
                <TableCell>
                  <StatusBadge
                    status={badgeStatus}
                    label={formatMessage({
                      id: CLAIM_STATUS_TRANSLATIONS[status],
                    })}
                    displayIcon
                  />
                </TableCell>
                <TableCell>
                  <Link
                    as={RouterLink}
                    to={claimUrl}
                    aria-label={formatMessage(
                      { id: 'VIEW_CLAIM_LABEL_HIDDEN' },
                      { vendor, referenceId: reference },
                    )}
                  >
                    <Image height={12} src={ChevronRight} alt="" />
                  </Link>
                </TableCell>
              </Box>
            );
          },
        )}
      </tbody>
    </Box>
  );
};

ClaimsTable.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.shape(claimShape)).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onSelectClaim: PropTypes.func.isRequired,
};
