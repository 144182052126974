import { selectUserIsHRAdmin } from 'common/state/user/role.selectors';
import { useSelector } from 'react-redux';
import { NEW_HR_ADMIN_LINK } from '@leagueplatform/web-common';

export const useIsInHRAdminPortal = () => {
  const userIsAdmin = useSelector(selectUserIsHRAdmin);
  // checking the URL as the user can be HR Admin but logged into the member portal
  const param = window.location.pathname;
  return userIsAdmin && param.includes(NEW_HR_ADMIN_LINK);
};
