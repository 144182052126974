import { get } from 'lodash/fp';
import { filter } from 'lodash';
import { createSelector } from 'reselect';
import { selectEmployerDetails } from '../selectors';

export const selectUploadStatus = createSelector(
  selectEmployerDetails,
  ({ bulkUploading }) => get(bulkUploading, bulkUploading, null),
);

export const selectUploadedEmployees = createSelector(
  selectEmployerDetails,
  ({ bulkAddEmployees }) => bulkAddEmployees.employees || [],
);

export const selectUploadedEmployeeErrors = createSelector(
  selectEmployerDetails,
  ({ bulkAddEmployees }) => {
    const errors = [];
    // When the API call fails, the error is sent back as an obj -> changes it to array
    if (
      typeof bulkAddEmployees.errors === 'object' &&
      bulkAddEmployees.errors.constructor === Object
    ) {
      errors.push(bulkAddEmployees.errors.reason);
      return errors;
    }
    return bulkAddEmployees.errors || [];
  },
);

export const selectErroredEmployees = createSelector(
  selectUploadedEmployees,
  employees => filter(employees, employee => !employee.user_id),
);

export const selectSuccessfullyAddedEmployeesCount = createSelector(
  selectUploadedEmployees,
  selectErroredEmployees,
  (all, errored) => all.length - errored.length,
);
