import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { FeedbackCard } from '@leagueplatform/web-common-components';
import { TitleDropdown } from 'common/insight-dashboards/title-dropdown/title-dropdown.view';
import { InsightsReportsView } from 'common/insight-dashboards/insights-reports/insights-reports.view';
import { DashboardContainer } from 'common/insight-dashboards/dashboard/dashboard.container';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
  SPENDING_ACCOUNT_SURVEY_STRING,
} from '../employer-insights.constants';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';
import { useInsightsDashboardFeedback } from '../use-insights-dashboard-feedback.hook';

export const SpendingAccountsView = ({ dashboards, groupId }) => {
  const firstOption = dashboards?.[0]?.options?.[0];
  const [selectedItem, setSelectedItem] = useState(firstOption);
  const filteredReports = dashboards.find(item => item.type === 'reports');
  const isReportsSelected =
    filteredReports?.options.find(item => item.id === selectedItem.id) ?? false;
  const isInHRAdminPortal = useIsInHRAdminPortal();
  const { formatMessage } = useIntl();

  const handleSelect = (item, view) => {
    if (isInHRAdminPortal) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'spending accounts',
        detail: `select ${item.title.toLowerCase()} from ${view} section`,
      });
    }
    setSelectedItem(item);
  };

  const feedbackUrl = useInsightsDashboardFeedback(
    groupId,
    SPENDING_ACCOUNT_SURVEY_STRING,
  );

  return (
    <Box>
      <FeedbackCard
        title={formatMessage({
          id: 'SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER_TITLE',
        })}
        subtitle={formatMessage({
          id: 'SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER',
        })}
        minMaxTextColor="onSurface.text.primary"
        feedbackUrl={feedbackUrl}
      />
      <TitleDropdown
        options={dashboards}
        selectedItem={selectedItem}
        onSelect={handleSelect}
      />
      <DashboardContainer dashboardId={selectedItem?.id} groupId={groupId} />
      {!isReportsSelected && (
        <InsightsReportsView
          options={filteredReports}
          onSelect={handleSelect}
        />
      )}
    </Box>
  );
};

SpendingAccountsView.propTypes = {
  dashboards: PropTypes.arrayOf(PropTypes.objectOf),
  groupId: PropTypes.string.isRequired,
};

SpendingAccountsView.defaultProps = {
  dashboards: [],
};
