import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Header, Dimmer, Loader } from 'semantic-ui-react';

import { Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import {
  TabPanel,
  TabList,
  TabContent,
  TabLink,
} from 'apps/employer-experience/ui-kit/tabs';
import { useIntl } from '@leagueplatform/locales';
import { BillingRoutes } from './billing.routes';

const Billing = ({ ready, match, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <Dimmer.Dimmable as={Box} dimmed={!ready}>
      <Dimmer active={!ready} inverted>
        <Loader />
      </Dimmer>
      <Flex align="center" justify="space-between">
        <Header as="h2">
          <FormattedMessage id="BILLING" />
          <Header.Subheader>
            <FormattedMessage id="BILLING_SUBHEADER" />
          </Header.Subheader>
        </Header>
      </Flex>
      {ready ? (
        <TabPanel>
          <TabList>
            <TabLink to={`${match.url}/preferences`}>
              {formatMessage({ id: 'PREFERENCES' })}
            </TabLink>
            <TabLink to={`${match.url}/subscription-fees`}>
              {formatMessage({ id: 'SUBSCRIPTION_FEES' })}
            </TabLink>
            <TabLink to={`${match.url}/pre-payments`}>
              {formatMessage({ id: 'PRE_PAYMENTS' })}
            </TabLink>
            <TabLink to={`${match.url}/contact`}>
              {formatMessage({ id: 'CONTACT' })}
            </TabLink>
            <TabLink to={`${match.url}/adjustments`}>
              {formatMessage({ id: 'ADJUSTMENTS' })}
            </TabLink>
            <TabLink to={`${match.url}/invoices`}>
              {formatMessage({ id: 'INVOICES' })}
            </TabLink>
            <TabLink to={`${match.url}/divisions`}>
              {formatMessage({ id: 'DIVISIONS' })}
            </TabLink>
            <TabLink to={`${match.url}/fees`}>
              {formatMessage({ id: 'FEES' })}
            </TabLink>
          </TabList>
          <TabContent>
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
            <BillingRoutes match={match} {...rest} />
          </TabContent>
        </TabPanel>
      ) : null}
    </Dimmer.Dimmable>
  );
};

Billing.defaultProps = {
  benefits: {},
};

Billing.propTypes = {
  ready: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  billingPreference: PropTypes.string.isRequired,
  invoiceAsPayment: PropTypes.bool.isRequired,
  groupCreditContracts: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  billingContact: PropTypes.string.isRequired,
  benefits: PropTypes.shape({
    entities: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
      benefits: PropTypes.object,
    }),
    result: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default injectIntl(Billing);
