import { GetEnrollmentDesignOptionsParams } from 'common/services/enrollment/get-enrollment-design-options.service';
import { GetEnrollmentTemplatesParams } from 'common/services/enrollment/get-enrollment-templates.service';
import { ResetEnrollmentDesignOptionsParams } from 'common/services/enrollment/reset-enrollment-design-options.service';
import { SetEnrollmentDesignOptionsParams } from 'common/services/enrollment/set-enrollment-design-options.service';
import { SetEnrollmentTemplatesParams } from 'common/services/enrollment/set-enrollment-templates.service';
import { createRequestTypes } from 'common/websocket-redux';

export const GET_ENROLLMENT_DESIGN_OPTIONS = createRequestTypes(
  'enrollment-experience-config/GET_ENROLLMENT_DESIGN_OPTIONS',
);

export const SET_ENROLLMENT_DESIGN_OPTIONS = createRequestTypes(
  'enrollment-experience-config/SET_ENROLLMENT_DESIGN_OPTIONS',
);

export const RESET_ENROLLMENT_DESIGN_OPTIONS = createRequestTypes(
  'enrollment-experience-config/RESET_ENROLLMENT_DESIGN_OPTIONS',
);

export const getEnrollmentDesignOptions = (
  payload: GetEnrollmentDesignOptionsParams,
) => GET_ENROLLMENT_DESIGN_OPTIONS.request(payload, null);

export const setEnrollmentDesignOptions = (
  payload: SetEnrollmentDesignOptionsParams,
) => SET_ENROLLMENT_DESIGN_OPTIONS.request(payload, null);

export const resetEnrollmentDesignOptions = (
  payload: ResetEnrollmentDesignOptionsParams,
  meta: GetEnrollmentDesignOptionsParams,
) => RESET_ENROLLMENT_DESIGN_OPTIONS.request(payload, meta);

export const GET_ENROLLMENT_TEMPLATES = createRequestTypes(
  'enrollment-experience-config/GET_ENROLLMENT_TEMPLATES',
);

export const SET_ENROLLMENT_TEMPLATES = createRequestTypes(
  'enrollment-experience-config/SET_ENROLLMENT_TEMPLATES',
);

export const getEnrollmentTemplates = (payload: GetEnrollmentTemplatesParams) =>
  GET_ENROLLMENT_TEMPLATES.request(payload, null);

export const setEnrollmentTemplates = (
  payload: SetEnrollmentTemplatesParams,
  meta: GetEnrollmentTemplatesParams,
) => SET_ENROLLMENT_TEMPLATES.request(payload, meta);
