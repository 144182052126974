import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Header, Message } from 'semantic-ui-react';
import { Box, Text } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import BenefitClass from './class.view';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_CONTACT,
  ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
} from '../constants';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

const Page = ({ benefitClasses, isAdmin }) => {
  const link = 'help@league.com?Subject=Benefits%20change%20request';

  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'BENEFITS' }));
  useEffect(() => {
    if (!isAdmin) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: null,
      });
    }
  }, [isAdmin]);

  const sendAnalytics = () => {
    if (!isAdmin) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_BENEFITS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: null,
        detail: ANALYTICS_EVENT_HR_ADMIN_CONTACT,
        link,
      });
    }
  };

  return (
    <Dimmer.Dimmable as={Box}>
      <Header as="h1">
        <Header.Content>
          <FormattedMessage id="BENEFITS" />
        </Header.Content>
      </Header>
      <Message info>
        <Message.Header>
          <FormattedMessage id="WANT_TO_MAKE_A_CHANGE" />
        </Message.Header>
        <Text>
          <FormattedMessage id="WE_CAN_HELP" />
          <a
            href={`mailto:${link}`}
            className="message-purple__link"
            onClick={sendAnalytics}
          >
            {' '}
            <FormattedMessage id="HELP_EMAIL" />
          </a>
        </Text>
      </Message>
      {benefitClasses?.length ? (
        benefitClasses.map(benefitClass => (
          <BenefitClass
            key={benefitClass.id}
            benefitClass={benefitClass}
            readOnly={!isAdmin}
          />
        ))
      ) : (
        <Message info>
          <Message.Header>
            <FormattedMessage id="NO_BENEFITS_MESSAGE" />
          </Message.Header>
        </Message>
      )}
    </Dimmer.Dimmable>
  );
};

Page.propTypes = {
  benefitClasses:
    PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
  isAdmin: PropTypes.bool,
};

Page.defaultProps = {
  isAdmin: undefined,
};
export default Page;
