import React from 'react';
import 'raf/polyfill';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { MemberConfiguredProvider } from 'store/member-configured-provider';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { branch, compose, renderComponent } from 'recompose';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { ScrollContextComponent } from 'common/components/scroll-context/scroll-context.view';
import { AriaLiveRegion } from 'common/components/aria-live-region/aria-live-region.container';
import { DownloadPage } from 'apps/download/routes';
import { MemberRoutes } from 'apps/member-experience/member-experience-routes.view';
import { DependentProfileForm } from 'apps/enroll-dependent/dependent-profile-form/dependent-profile-form.view';
import { LoginPage, NoMatch } from 'apps/public/public-routes.view';
import { AdminRoutes } from 'apps/employer-experience/league-admin-app-routes.view';
import { HRAdminRoutes } from 'apps/employer-experience-v2/routes';

const withLoader = compose(
  connect(state => ({
    isBooting: get(state, 'application.booting'),
  })),
  branch(props => props.isBooting, renderComponent(LoadingIndicator)),
);

const BootableConnectedRouter = withLoader(ConnectedRouter);

export const AllExperiences = () => {
  const history = useHistory();

  useScrollTopOnLocationChange();
  return (
    <MemberConfiguredProvider>
      <BootableConnectedRouter history={history}>
        <>
          <ScrollContextComponent>
            <Switch>
              <Route path="/member" component={MemberRoutes} />
              <Route
                path="/dependent/profile"
                component={DependentProfileForm}
              />
              <Route path="/admin">
                <AdminRoutes />
              </Route>
              <Route path="/manage">
                <HRAdminRoutes />
              </Route>
              <Route path="/download/:contentId" component={DownloadPage} />
              {/* Public Routes */}
              <Route exact path="/sign-in" component={LoginPage} />
              <Route component={NoMatch} />
            </Switch>
          </ScrollContextComponent>
          <AriaLiveRegion />
        </>
      </BootableConnectedRouter>
    </MemberConfiguredProvider>
  );
};
