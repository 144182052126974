import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BenefitClassOptions from './BenefitClassOptions';
import RemoveBenefitClassConfirmation from './RemoveBenefitClassConfirmation';
import { Plans } from './Plans';
import BenefitLabel from './BenefitLabel';
import { Accordion, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import AddRenameInput from '../AddRenameInput';

class BenefitClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canRename: false,
      renaming: false,
      hasError: false,
      benefitClassName: props.name,
      confirmRemove: false,
      active: false,
    };

    this.onRenameBenefitClass = this.onRenameBenefitClass.bind(this);
    this.toggleRenameBenefitClass = this.toggleRenameBenefitClass.bind(this);
    this.onBenefitClassNameChange = this.onBenefitClassNameChange.bind(this);
    this.onRemoveBenefitClass = this.onRemoveBenefitClass.bind(this);
    this.removeBenefitClass = this.removeBenefitClass.bind(this);
    this.onTitleClick = this.onTitleClick.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps({
    errorRenamingBenefitClass,
    renamingBenefitClass,
    name,
  }) {
    if (
      errorRenamingBenefitClass &&
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.renamingBenefitClass === this.props.id
    ) {
      this.setState(() => ({
        renaming: false,
        hasError: true,
        benefitClassName: name,
      }));
    } else if (
      !renamingBenefitClass &&
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.renamingBenefitClass === this.props.id
    ) {
      this.setState(() => ({
        renaming: false,
        benefitClassName: name,
        canRename: false,
        hasError: false,
      }));
    }
  }

  async onTitleClick() {
    await this.setState(previousState => ({ active: !previousState.active }));
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.active) {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.fetchPlans({ benefitClassId: this.props.id });
    }
  }

  onRenameBenefitClass() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    const value = this.state.benefitClassName;

    if (value.trim()) {
      this.setState(() => ({
        renaming: true,
        hasError: false,
        benefitClassName: value,
      }));
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.renameBenefitClass({
        name: value,
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        id: this.props.id,
      });
    } else {
      this.setState(() => ({ hasError: true }));
    }
  }

  onBenefitClassNameChange(e, { value }) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.hasError && value) {
      this.setState(() => ({ benefitClassName: value, hasError: false }));
    } else {
      this.setState(() => ({ benefitClassName: value }));
    }
  }

  onRemoveBenefitClass() {
    this.setState({ confirmRemove: true });
  }

  toggleRenameBenefitClass() {
    this.setState(previousState => ({ canRename: !previousState.canRename }));
  }

  removeBenefitClass() {
    this.setState({ confirmRemove: false });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.removeBenefitClass({ id: this.props.id });
  }

  render() {
    const {
      canRename,
      hasError,
      benefitClassName,
      renaming,
      confirmRemove,
      active,
    } = this.state;
    const { name, intl, isLastBenefitClass, id } = this.props;

    return (
      <Accordion fluid styled className="accordion--benefit-class">
        <Accordion.Title active={active} onClick={this.onTitleClick}>
          <Icon name="dropdown" />
          {!canRename ? (
            <BenefitLabel type="class" name={name} intl={intl} />
          ) : (
            <AddRenameInput
              hasError={hasError}
              name={benefitClassName}
              onChangeHandler={this.onBenefitClassNameChange}
              onSubmitHandler={this.onRenameBenefitClass}
              shouldLoad={renaming}
              contentId={intl.formatMessage({ id: 'RENAME_BENEFITS_CLASS' })}
              placeholderId={intl.formatMessage({
                id: 'RENAME_BENEFITS_CLASS_PLACEHOLDER',
              })}
              toggle={this.toggleRenameBenefitClass}
            />
          )}
          <BenefitClassOptions
            toggleRenameBenefitClass={this.toggleRenameBenefitClass}
            removeBenefitClass={this.onRemoveBenefitClass}
          />
        </Accordion.Title>
        <Accordion.Content active={active}>
          <Plans benefitClassId={id} />
        </Accordion.Content>
        <RemoveBenefitClassConfirmation
          open={confirmRemove}
          close={() => this.setState({ confirmRemove: false })}
          benefitClassName={benefitClassName}
          removeBenefitClass={this.removeBenefitClass}
          isLastBenefitClass={isLastBenefitClass}
        />
      </Accordion>
    );
  }
}

export const benefitClassShape = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export const propTypes = {
  ...benefitClassShape,
  intl: intlShape,
  errorRenamingBenefitClass: PropTypes.bool.isRequired,
  renamingBenefitClass: PropTypes.string.isRequired,
  renameBenefitClass: PropTypes.func.isRequired,
  isLastBenefitClass: PropTypes.func.isRequired,
  removeBenefitClass: PropTypes.func.isRequired,
  fetchPlans: PropTypes.func.isRequired,
};

BenefitClass.defaultProps = {
  intl: undefined,
};
BenefitClass.propTypes = propTypes;

export default injectIntl(BenefitClass);
