import React from 'react';
import PropTypes from 'prop-types';
import {
  vars,
  Text,
  Box,
  Toggle,
  CheckBox,
  Tooltip,
  TooltipContentV2,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { useIntl } from '@leagueplatform/locales';
import {
  StyledInput,
  Blurb,
  RedAsterisk,
  StyledQuestionIcon,
  LastText,
} from './shared.view';
import styled from 'styled-components';

const InlineText = styled(Text)`
  display: inline-block;
  width: 90%;
  vertical-align: middle;
`;

const StyledCheckbox = styled(CheckBox)`
  display: inline-block;
  width: 36px;
  vertical-align: middle;
  & label {
    cursor: pointer;
  }
`;

const SetManageDependents = ({
  allowToAddDependents,
  allowToRemoveDependents,
  handleChange,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  /**
   * Hack for Formik v2.0.^ and Checkboxes https://github.com/jaredpalmer/formik/issues/1024:
   * Formik handles checkboxes strangely. For some reason Formik is reading Checkbox'
   * `e.target.value` instead of `e.target.checked`. Work around this by copying and modifying the event object.
   * How handle change works an input's value: https://github.com/jaredpalmer/formik/blob/master/src/Formik.tsx#L521
   *
   * An alternative solution would be to pass a `value` prop to checkboxes. This would require
   * the ui-kit component to be updated and making sure things aren't busted around the app. Note
   * that the there are 2 version of Formik used, so make sure to test that as well!
   * Banking info uses an old version for example.
   */
  const customCheckBoxOnChangeHandler = e => {
    const modifiedEvent = { ...e };
    modifiedEvent.target.value = e.target.checked;

    handleChange(modifiedEvent);
  };

  return (
    <Flex align="center" mt={2}>
      <Box display="inline-block" width="50%">
        <StyledCheckbox
          display="inline-block"
          id="allowToAddDependents"
          name="allowToAddDependents"
          onChange={customCheckBoxOnChangeHandler}
          checked={allowToAddDependents}
          disabled={disabled}
        />
        <InlineText fontSize={2} color="purpleDarkest">
          {formatMessage({ id: 'ALLOW_MEMBERS_TO_CHANGE_DEPENDENTS' })}{' '}
          <strong>{formatMessage({ id: 'ADD_CAPS' })}</strong>{' '}
          {formatMessage({ id: 'LOWERCASE_DEPENDENTS' })}
        </InlineText>
      </Box>
      <Box display="inline-block" width="50%">
        <StyledCheckbox
          display="inline-block"
          id="allowToRemoveDependents"
          name="allowToRemoveDependents"
          onChange={customCheckBoxOnChangeHandler}
          disabled={disabled}
          checked={allowToRemoveDependents}
        />
        <InlineText fontSize={2} color="purpleDarkest">
          {formatMessage({ id: 'ALLOW_MEMBERS_TO_CHANGE_DEPENDENTS' })}{' '}
          <strong>{formatMessage({ id: 'REMOVE_CAPS' })}</strong>{' '}
          {formatMessage({ id: 'LOWERCASE_DEPENDENTS' })}
        </InlineText>
      </Box>
    </Flex>
  );
};

SetManageDependents.propTypes = {
  handleChange: PropTypes.func.isRequired,
  allowToRemoveDependents: PropTypes.bool.isRequired,
  allowToAddDependents: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const SetTypeForm = ({ typeformUrl, handleChange, disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <LastText fontSize={2} fontWeight="bold" color="purpleText" my={2}>
        {formatMessage({ id: 'TYPEFORM_URL_CAPS' })}
        <RedAsterisk>*</RedAsterisk>
      </LastText>
      <StyledInput
        name="typeformUrl"
        type="text"
        id="typeformUrl"
        onChange={handleChange}
        value={typeformUrl}
        disabled={disabled}
        placeholder={formatMessage({ id: 'PASTE_TYPEFORM_URL_HERE' })}
      />
    </>
  );
};

SetTypeForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  typeformUrl: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

SetTypeForm.defaultProps = {
  typeformUrl: '',
};

export const Elections = ({
  handleChange,
  setFieldValue,
  values,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const {
    typeformUrl,
    allowToRemoveDependents,
    allowToAddDependents,
    enableManageDependents,
    enableTypeform,
  } = values;

  const toggleAndClearManageDependents = () => {
    const newEnableManageDependents = !enableManageDependents;
    setFieldValue('enableManageDependents', newEnableManageDependents);
    if (!newEnableManageDependents) {
      setFieldValue('allowToRemoveDependents', false);
      setFieldValue('allowToAddDependents', false);
    }
  };

  const toggleAndClearTypeForm = () => {
    const newEnableTypeForm = !enableTypeform;
    setFieldValue('enableTypeform', newEnableTypeForm);
    if (!newEnableTypeForm) {
      setFieldValue('typeformUrl', '');
    }
  };

  return (
    <Box mt={2}>
      <Text fontSize={2} fontWeight="bold" color="purpleDarkest">
        {formatMessage({ id: 'CUSTOM_CONFIGURATION' })}
      </Text>
      <Blurb>
        <Text fontSize={2} color="greyDark">
          {formatMessage({ id: 'CUSTOM_CONFIGURATION_BLURB' })}
        </Text>
      </Blurb>
      <Flex align="center" pb={2}>
        <Text
          fontSize={2}
          color="purpleDarkest"
          mb={2}
          mr={1}
          fontWeight="bold"
        >
          {formatMessage({ id: 'ENABLE_MANAGE_DEPENDENTS' })}
        </Text>
        <Tooltip inline>
          <StyledQuestionIcon
            size="xs"
            color={vars.colour.darkGrey}
            icon="question"
          />
          <TooltipContentV2>
            {formatMessage({ id: 'ENABLE_MANAGE_DEPENDENTS_TOOLTIP' })}
          </TooltipContentV2>
        </Tooltip>
      </Flex>
      <Toggle
        disabled={disabled}
        onChange={toggleAndClearManageDependents}
        checked={enableManageDependents}
        mb={2}
      >
        {formatMessage({ id: 'YES' })}
      </Toggle>
      {enableManageDependents && (
        <SetManageDependents
          disabled={disabled}
          allowToAddDependents={allowToAddDependents}
          allowToRemoveDependents={allowToRemoveDependents}
          handleChange={handleChange}
        />
      )}
      <Flex align="center" pb={2} pt={2}>
        <Text
          fontSize={2}
          color="purpleDarkest"
          mt={3}
          mr={1}
          fontWeight="bold"
        >
          {formatMessage({ id: 'ENABLE_TYPEFORM' })}
        </Text>
        <Tooltip inline>
          <StyledQuestionIcon
            size="xs"
            color={vars.colour.darkGrey}
            icon="question"
          />
          <TooltipContentV2>
            {formatMessage({ id: 'ENABLE_TYPEFORM_TOOLTIP' })}
          </TooltipContentV2>
        </Tooltip>
      </Flex>
      <Toggle
        disabled={disabled}
        onChange={toggleAndClearTypeForm}
        checked={enableTypeform}
      >
        {formatMessage({ id: 'YES' })}
      </Toggle>
      {enableTypeform && (
        <SetTypeForm
          disabled={disabled}
          typeformUrl={typeformUrl}
          handleChange={handleChange}
        />
      )}
    </Box>
  );
};

Elections.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    typeformUrl: PropTypes.string,
    allowToRemoveDependents: PropTypes.bool,
    allowToAddDependents: PropTypes.bool,
    enableManageDependents: PropTypes.bool,
    enableTypeform: PropTypes.bool,
  }),
  disabled: PropTypes.bool.isRequired,
};

Elections.defaultProps = {
  values: {
    typeformUrl: '',
    allowToRemoveDependents: false,
    allowToAddDependents: false,
    enableManageDependents: false,
    enableTypeform: false,
  },
};
