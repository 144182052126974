import React, {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  HeadingThree,
  SubtitleOne,
  BodyOne,
  BodyTwo,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { currencyPropType } from '@leagueplatform/web-common';
import { usePayrollDeduction } from 'apps/hooks/use-payroll-deduction/use-payroll-deduction.hook';
import { useIntl } from '@leagueplatform/locales';
import { ViewBreakdown } from './view-breakdown.view';
import { LeftoverBenefitText } from './leftover-flex-benefit-text.view';
import {
  AdditionalItem,
  AdditionalItemTitleRow,
  AdditionalItemTitle,
} from './additional-item.view';
import {
  CoverageSummaryTooltip,
  CoverageSummaryModalTooltip,
  CoverageSummaryHoverTooltip,
} from '../coverage-summary-tooltip.view';
import ClipboardIcon from './clipboard-icon.png';
import { PERIOD_TYPE_TRANSLATION_IDS } from 'common/benefits/metadata';

const payrollDeductionColumnWidth = 284;
const rightContainerContentBufferSize = 170;

const TotalBreakdownContainer = genesisStyled(Flex)`
  background-image: url(${ClipboardIcon});
  background-repeat: no-repeat;
  background-position: right 32px top 36px;
  background-size: 64px 78px;
`;

const getGradient = (to, endColour, centerColour) => `
linear-gradient(
  to ${to},
  ${endColour},
  ${centerColour},
  ${centerColour},
  ${endColour}
 )
`;

const AdditionalItems = genesisStyled(Flex)`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    ${({
      orientation,
      theme: {
        colors: {
          surface: {
            background: { primary },
          },
          onSurface: {
            border: { subdued },
          },
        },
      },
    }) => {
      switch (orientation) {
        case 'vertical':
          return css({
            height: '1px',
            width: '100%',
            backgroundImage: getGradient('right', primary, subdued),
          });
        case 'horizontal':
          return css({
            width: '1px',
            height: '200%',
            top: '-50%',
            backgroundImage: getGradient('top', primary, subdued),
          });
        default:
          return ``;
      }
    }}
  }
`;

const useTotalBreakdownAmounts = ({
  perPayPeriodCosts,
  benefitClassId,
  benefitClasses,
  employerName: company,
  totalFlex,
  totalPay,
  allocatedFlex,
  flexDollarTermId,
  leftoverFlexBenefits,
  benefitSetsWithEOIExpired,
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const { payrollDeduction, payGroup } = usePayrollDeduction({
    perPayPeriodCosts,
    benefitClassId,
    benefitClasses,
    company,
    benefitSetsWithEOIExpired,
  });
  const flexDollarTerm = useMemo(
    () => formatMessage({ id: `${flexDollarTermId}` }),
    [formatMessage, flexDollarTermId],
  );
  const flexDollarTermCapitalized = useMemo(
    () => formatMessage({ id: `${flexDollarTermId}_CAPITALIZED` }),
    [formatMessage, flexDollarTermId],
  );
  const flexDollarTermWithoutDollars = useMemo(
    () => formatMessage({ id: `${flexDollarTermId}_WITH_DOLLARS` }),
    [formatMessage, flexDollarTermId],
  );

  const payrollDeductionTitle = formatMessage({
    id: 'YOUR_PAYROLL_DEDUCTION',
  });
  const flexibleDollars = formatMessage({ id: 'FLEXIBLE_DOLLARS' });

  const payrollDeductionTooltip = (
    <>
      <BodyOne marginBottom="one">
        {formatMessage(
          { id: 'YOUR_PAYROLL_DEDUCTION_TOOLTIP' },
          { payGroup: payGroup.toLowerCase() },
        )}
      </BodyOne>
      <BodyOne>
        {formatMessage({
          id: 'YOUR_PAYROLL_DEDUCTION_TOOLTIP_NOTE',
        })}
      </BodyOne>
    </>
  );

  const payrollDeductionText =
    payGroup === PERIOD_TYPE_TRANSLATION_IDS.Monthly
      ? `${payrollDeduction}/${PERIOD_TYPE_TRANSLATION_IDS.month}`
      : `${payrollDeduction}/${payGroup}`;

  const annualAmount = totalPay && formatMoney(totalPay);

  const annualAmountTitle = formatMessage({ id: 'YOU_PAY_ANNUALLY' });

  const annualAmountText = payrollDeduction && totalPay && (
    <SubtitleOne as="p">{formatMoney(totalPay)}</SubtitleOne>
  );

  const annualAmountTooltip = (
    <BodyOne>
      {formatMessage({
        id: 'YOU_PAY_ANNUALLY_TOOLTIP',
      })}
    </BodyOne>
  );

  const flexAmountText = !!totalFlex && (
    <SubtitleOne as="span" fontWeight="regular">
      <strong>{formatMoney(totalFlex)}</strong> / {formatMoney(allocatedFlex)}
    </SubtitleOne>
  );

  const flexAmountTitle = formatMessage(
    {
      id:
        flexDollarTerm === flexibleDollars
          ? 'FLEXIBLE_DOLLARS_USED_WITH_TERM'
          : 'WELLNESS_POOL_USED_WITH_TERM',
    },
    { flexDollarTerm: flexDollarTermCapitalized },
  );

  const flexAmountTooltip = (
    <>
      <BodyOne>
        {formatMessage(
          {
            id: 'FLEXIBLE_DOLLARS_USED_TOOLTIP',
          },
          { flexDollarTerm: flexDollarTermWithoutDollars },
        )}
      </BodyOne>
      <LeftoverBenefitText
        flexDollarTerm={flexDollarTerm}
        leftoverFlexBenefits={leftoverFlexBenefits}
      />
    </>
  );

  const primaryTooltip = payrollDeduction
    ? payrollDeductionTooltip
    : annualAmountTooltip;

  const primaryTitle = payrollDeduction
    ? payrollDeductionTitle
    : annualAmountTitle;

  const annualAmountTooltipAltText = formatMessage({
    id: 'YOU_PAY_ANNUALLY_TOOLTIP_ALTTEXT',
  });

  const primaryTooltipAltText = payrollDeduction
    ? formatMessage({ id: 'YOUR_PAYROLL_DEDUCTION_TOOLTIP_ALTTEXT' })
    : annualAmountTooltipAltText;

  const primaryAmount = payrollDeduction ? payrollDeductionText : annualAmount;

  const hasPayrollDeduction = !!payrollDeduction;
  const hasAdditionalItems = Boolean(
    (annualAmountText && hasPayrollDeduction) || flexAmountText,
  );

  return {
    primaryTooltip,
    primaryTooltipAltText,
    primaryTitle,
    primaryAmount,
    annualAmountText,
    annualAmountTitle,
    annualAmountTooltip,
    annualAmountTooltipAltText,
    flexAmountText,
    flexAmountTooltip,
    flexAmountTitle,
    hasPayrollDeduction,
    hasAdditionalItems,
  };
};

export const TotalBreakdown = ({
  showCostsPerPayPeriod,
  withTooltips,
  hasInfoBox,
  ...props
}) => {
  const {
    primaryTooltip,
    primaryTooltipAltText,
    primaryTitle,
    primaryAmount,
    annualAmountText,
    annualAmountTitle,
    annualAmountTooltip,
    annualAmountTooltipAltText,
    flexAmountText,
    flexAmountTooltip,
    flexAmountTitle,
    hasPayrollDeduction,
    hasAdditionalItems,
  } = useTotalBreakdownAmounts(props);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [additionalItemsWidth, setAdditionalItemsWidth] = useState();
  const containerRef = useRef();
  const additionalItemsRef = useRef();

  const measureWidths = useCallback(() => {
    const containerWidth = containerRef?.current?.clientWidth ?? 0;
    const availableSpace =
      containerWidth -
      rightContainerContentBufferSize -
      payrollDeductionColumnWidth;

    setShouldCollapse(Boolean(availableSpace <= additionalItemsWidth));
  }, [containerRef, setShouldCollapse, additionalItemsWidth]);

  useLayoutEffect(() => {
    if (additionalItemsWidth !== undefined)
      window.addEventListener('resize', measureWidths);
    return () => window.removeEventListener('resize', measureWidths);
  }, [measureWidths, additionalItemsWidth]);

  useLayoutEffect(() => {
    const width = additionalItemsRef?.current?.clientWidth;
    setAdditionalItemsWidth(width);
    measureWidths();
  }, [measureWidths]);

  const showAnnualAmount =
    annualAmountText && hasPayrollDeduction && !showCostsPerPayPeriod;

  return (
    <div ref={containerRef}>
      <TotalBreakdownContainer
        borderColor="surface.background.secondary"
        borderTopLeftRadius={[0, 0, 0, 'medium']}
        borderTopRightRadius={[0, 0, 0, 'medium']}
        borderWidth="thick"
        borderStyle="solid"
        borderLeftWidth={['none', 'none', 'none', 'thick']}
        borderRightWidth={['none', 'none', 'none', 'thick']}
        backgroundColor="surface.background.primary"
        padding="two"
        minHeight="160px"
        borderBottomLeftRadius={hasInfoBox ? 0 : [0, 0, 0, 'medium']}
        borderBottomRightRadius={hasInfoBox ? 0 : [0, 0, 0, 'medium']}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      >
        <Flex alignItems="center" width={shouldCollapse ? '100%' : 'auto'}>
          <Flex
            width={shouldCollapse ? '100%' : 'auto'}
            flexDirection="column"
            paddingRight={shouldCollapse ? 0 : 'four'}
          >
            <Box flexGrow={1} flexShrink={0}>
              <Flex flexDirection="row" alignItems="center" marginBottom="half">
                <BodyTwo
                  color="onSurface.text.subdued"
                  marginRight="half"
                  as="h2"
                >
                  {primaryTitle}
                </BodyTwo>
                {withTooltips && (
                  <CoverageSummaryTooltip
                    top
                    left
                    asModal={shouldCollapse}
                    altText={primaryTooltipAltText}
                  >
                    {primaryTooltip}
                  </CoverageSummaryTooltip>
                )}
              </Flex>
              <HeadingThree as="span">{primaryAmount}</HeadingThree>
            </Box>
            {hasAdditionalItems && shouldCollapse && (
              <ViewBreakdown initiallyCollapsed>
                <div>
                  <AdditionalItems
                    orientation="vertical"
                    width="100%"
                    marginTop="oneAndHalf"
                    paddingTop="oneAndHalf"
                    flexWrap="wrap"
                  >
                    {showAnnualAmount && (
                      <AdditionalItem width={1}>
                        <AdditionalItemTitleRow>
                          <AdditionalItemTitle>
                            {annualAmountTitle}
                          </AdditionalItemTitle>
                          {withTooltips && (
                            <CoverageSummaryModalTooltip>
                              {annualAmountTooltip}
                            </CoverageSummaryModalTooltip>
                          )}
                        </AdditionalItemTitleRow>
                        {annualAmountText}
                      </AdditionalItem>
                    )}
                    {flexAmountText && (
                      <AdditionalItem
                        width={1}
                        marginTop={annualAmountText ? 'oneAndHalf' : 0}
                      >
                        <AdditionalItemTitleRow>
                          <AdditionalItemTitle>
                            {flexAmountTitle}
                          </AdditionalItemTitle>
                          {withTooltips && (
                            <CoverageSummaryModalTooltip>
                              {flexAmountTooltip}
                            </CoverageSummaryModalTooltip>
                          )}
                        </AdditionalItemTitleRow>
                        {flexAmountText}
                      </AdditionalItem>
                    )}
                  </AdditionalItems>
                </div>
              </ViewBreakdown>
            )}
          </Flex>
          {hasAdditionalItems && (
            <div ref={additionalItemsRef}>
              {!shouldCollapse && (
                <AdditionalItems orientation="horizontal" maxWidth="460px">
                  {showAnnualAmount && (
                    <AdditionalItem marginLeft="four">
                      <AdditionalItemTitleRow>
                        <AdditionalItemTitle>
                          {annualAmountTitle}
                        </AdditionalItemTitle>
                        {withTooltips && (
                          <CoverageSummaryHoverTooltip
                            top
                            right
                            altText={annualAmountTooltipAltText}
                          >
                            {annualAmountTooltip}
                          </CoverageSummaryHoverTooltip>
                        )}
                      </AdditionalItemTitleRow>
                      {annualAmountText}
                    </AdditionalItem>
                  )}
                  {flexAmountText && (
                    <AdditionalItem marginLeft="two">
                      <AdditionalItemTitleRow>
                        <AdditionalItemTitle>
                          {flexAmountTitle}
                        </AdditionalItemTitle>
                        {withTooltips && (
                          <CoverageSummaryHoverTooltip
                            top
                            right
                            altText={`${flexAmountTitle} tooltip`}
                          >
                            {flexAmountTooltip}
                          </CoverageSummaryHoverTooltip>
                        )}
                      </AdditionalItemTitleRow>
                      {flexAmountText}
                    </AdditionalItem>
                  )}
                </AdditionalItems>
              )}
            </div>
          )}
        </Flex>
      </TotalBreakdownContainer>
    </div>
  );
};

TotalBreakdown.propTypes = {
  totalFlex: currencyPropType,
  totalPay: currencyPropType,
  allocatedFlex: currencyPropType,
  perPayPeriodCosts: PropTypes.shape({
    payroll_deduction: currencyPropType,
    pay_group: PropTypes.string,
  }).isRequired,
  flexDollarTermId: PropTypes.string.isRequired,
  benefitClassId: PropTypes.string.isRequired,
  benefitClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.stirng,
    }),
  ).isRequired,
  employerName: PropTypes.string.isRequired,
  showCostsPerPayPeriod: PropTypes.bool,
  withTooltips: PropTypes.bool,
  hasInfoBox: PropTypes.bool,
  leftoverFlexBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      benefitName: PropTypes.string,
      percent: PropTypes.number,
    }),
  ),
};

TotalBreakdown.defaultProps = {
  totalFlex: null,
  totalPay: null,
  allocatedFlex: null,
  leftoverFlexBenefits: [],
  showCostsPerPayPeriod: false,
  withTooltips: true,
  hasInfoBox: false,
};
