import React from 'react';
import PropTypes from 'prop-types';
import { vars, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Accordion, Icon, Label } from 'semantic-ui-react';
import ConfirmModal from './confirmation-modal.container';
import Options from './options.view';
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
  BENEFIT_STATUS_MAP,
  EMPLOYER_BENEFIT_STATUS_COLORS,
  EMPLOYER_BENEFIT_STATUS_MAP,
  BENEFIT_STATUS_COLORS,
  HSA_BENEFIT_TYPE,
  BENEFIT_STATUS_INACTIVE_GRACE_PERIOD,
  REMOVE_BENEFIT,
  ADJUST_BENEFIT_END_DATE,
} from './constants';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { AddFunds } from '../../employer-experience/pages/add-funds/add-funds.container';
import styled from 'styled-components';
import { getHRAdminAnalytics } from 'common/utilities/get-hr-admin-analytics';

const BenefitLeftWrapper = styled(Flex)`
  align-items: center;
  padding-right: 0.2rem;
  max-width: 70%;
  min-width: 0;
`;

const OverflowContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
`;

const CarrierNameWrapper = styled.span`
  font-weight: 100;
  opacity: 0.5;
`;

export const BenefitPeriodLabel = styled.span`
  height: 2rem;
  margin-top: 0.25rem;
  margin-left: 0.625rem;
  background-color: ${vars.colour.offWhite};
  color: ${vars.colour.navyDark};
  font-size: 0.9rem;
  padding: 0.2rem 0.75rem 0 0.75rem;
`;

const StyledMeta = styled.div`
  margin-left: 1.9rem;
`;

const BenefitNameFlex = styled(Flex)`
  flex-direction: column;
  min-width: 0;
`;

const PlanNameWrapper = styled.span`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1rem;
`;

const getStatusLabel = (userBenefitsView, benefitStatus) => {
  if (userBenefitsView) {
    return (
      <Label
        size="small"
        className={`label-benefit-status label-benefit-status-${
          BENEFIT_STATUS_COLORS[benefitStatus] || 'default'
        }`}
      >
        <Icon name="circle" />
        {BENEFIT_STATUS_MAP[benefitStatus] ? (
          <FormattedMessage id={BENEFIT_STATUS_MAP[benefitStatus]} />
        ) : (
          benefitStatus
        )}
      </Label>
    );
  }

  return (
    <Label
      size="small"
      className={`label-benefit-status label-benefit-status-${
        EMPLOYER_BENEFIT_STATUS_COLORS[benefitStatus] || 'default'
      }`}
    >
      <Icon name="circle" />
      {EMPLOYER_BENEFIT_STATUS_MAP[benefitStatus] ? (
        <FormattedMessage id={EMPLOYER_BENEFIT_STATUS_MAP[benefitStatus]} />
      ) : (
        benefitStatus
      )}
    </Label>
  );
};

export const BenefitWrapper = ({
  fullName,
  vendorName,
  networkName,
  isAdmin,
  readOnly,
  benefit,
  active,
  toggleActive,
  confirmOpen,
  closeConfirmation,
  showConfirmation,
  children,
  meta,
  isSpendingAccount,
  addFundsConfirmationVisible,
  showAddFundsConfirmation,
  closeAddFundsConfirmation,
  canTopUpSpendingAccount,
  employeeName,
  confirmType,
  userId,
  groupId,
  benefitType,
  benefitStatus,
  benefitEndDate,
  userBenefitsView,
  policyEndDate,
  onConfirm,
  benefitPlanName,
  expirationDate,
}) => {
  const toggleAccordion = () => {
    toggleActive();
    if (!isAdmin) {
      const getparam = getHRAdminAnalytics();

      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HR_ADMIN,
        screen_name: getparam[0],
        sub_product_area: getparam[1] || null,
        detail: `toggle ${fullName} accordion`,
        sub_detail: !active ? 'expand' : 'collapse',
      });
    }
  };

  return (
    <Accordion styled fluid className="employee-benefit accordion--benefit">
      <Accordion.Title active={active} onClick={toggleAccordion}>
        <Flex justify="space-between">
          <BenefitLeftWrapper>
            <Icon fitted name="dropdown" />
            <BenefitNameFlex>
              <OverflowContainer>
                <FormattedMessage
                  id="BENEFIT_NAME"
                  values={{ name: fullName }}
                />
                <CarrierNameWrapper>
                  <FormattedMessage
                    id="BENEFIT_VENDOR_NAME"
                    values={{ vendorName: vendorName || 'League' }}
                  />
                  {networkName ? `(${networkName})` : ''}
                </CarrierNameWrapper>
              </OverflowContainer>
              {benefitPlanName && (
                <PlanNameWrapper>{benefitPlanName}</PlanNameWrapper>
              )}
            </BenefitNameFlex>
          </BenefitLeftWrapper>
          <Flex align="center">
            {benefitStatus && getStatusLabel(userBenefitsView, benefitStatus)}
            {!userBenefitsView &&
              benefitStatus === BENEFIT_STATUS_INACTIVE_GRACE_PERIOD && (
                <BenefitPeriodLabel>
                  <Icon name="clock" color="red" />
                  <FormattedMessage id="IN_GRACE_PERIOD" />
                </BenefitPeriodLabel>
              )}
            {!userBenefitsView &&
              benefitStatus !== BENEFIT_STATUS_INACTIVE_GRACE_PERIOD &&
              benefitEndDate && (
                <BenefitPeriodLabel>
                  <Icon name="calendar" />
                  <FormattedMessage
                    id="ENDS_DATE"
                    values={{
                      date: (
                        <FormattedDate
                          value={benefitEndDate}
                          month="short"
                          year="numeric"
                        />
                      ),
                    }}
                  />
                </BenefitPeriodLabel>
              )}
            {!readOnly && (
              <Options
                isAdmin={isAdmin}
                canAddFunds={
                  isSpendingAccount &&
                  canTopUpSpendingAccount &&
                  benefitType !== HSA_BENEFIT_TYPE
                }
                status={benefit.status}
                isSpendingAccount={isSpendingAccount}
                onStatusChange={status => showConfirmation(status)}
                removeBenefit={() => showConfirmation(REMOVE_BENEFIT)}
                addFunds={() => showAddFundsConfirmation()}
                adjustEndDate={() => showConfirmation(ADJUST_BENEFIT_END_DATE)}
              />
            )}
            {addFundsConfirmationVisible && (
              <AddFunds
                userId={userId}
                name={employeeName}
                groupId={groupId}
                benefitId={benefit.id}
                onClose={() => closeAddFundsConfirmation()}
              />
            )}
            {!readOnly && (
              <Options
                isAdmin={isAdmin}
                canAddFunds={
                  isSpendingAccount &&
                  canTopUpSpendingAccount &&
                  benefitType !== HSA_BENEFIT_TYPE
                }
                status={benefit.status}
                isSpendingAccount={isSpendingAccount}
                onStatusChange={status => showConfirmation(status)}
                removeBenefit={() => showConfirmation(REMOVE_BENEFIT)}
                addFunds={() => showAddFundsConfirmation()}
                adjustEndDate={() => showConfirmation(ADJUST_BENEFIT_END_DATE)}
                expirationDate={expirationDate}
              />
            )}
            {addFundsConfirmationVisible && (
              <AddFunds
                userId={userId}
                name={employeeName}
                groupId={groupId}
                benefitId={benefit.id}
                onClose={() => closeAddFundsConfirmation()}
              />
            )}
          </Flex>
          <StyledMeta>{meta}</StyledMeta>
        </Flex>
      </Accordion.Title>
      <Accordion.Content active={active}>{children}</Accordion.Content>
      {confirmOpen && (
        <ConfirmModal
          confirmType={confirmType}
          fullName={fullName}
          benefit={benefit}
          policyEndDate={policyEndDate}
          onConfirm={payload => onConfirm(payload)}
          closeConfirmation={() => closeConfirmation()}
        />
      )}
    </Accordion>
  );
};

BenefitWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  benefit: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  vendorName: PropTypes.string,
  networkName: PropTypes.string,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  toggleActive: PropTypes.func.isRequired,
  confirmOpen: PropTypes.bool.isRequired,
  closeConfirmation: PropTypes.func.isRequired,
  showConfirmation: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  meta: PropTypes.node,
  isSpendingAccount: PropTypes.bool,
  addFundsConfirmationVisible: PropTypes.bool.isRequired,
  showAddFundsConfirmation: PropTypes.func.isRequired,
  closeAddFundsConfirmation: PropTypes.func.isRequired,
  canTopUpSpendingAccount: PropTypes.bool,
  employeeName: PropTypes.string,
  confirmType: PropTypes.string,
  userId: PropTypes.string,
  groupId: PropTypes.string,
  benefitType: PropTypes.string,
  benefitStatus: PropTypes.string,
  benefitEndDate: PropTypes.string,
  userBenefitsView: PropTypes.bool,
  policyEndDate: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  benefitPlanName: PropTypes.string,
  expirationDate: PropTypes.string,
};

BenefitWrapper.defaultProps = {
  readOnly: false,
  isAdmin: undefined,
  meta: undefined,
  isSpendingAccount: undefined,
  canTopUpSpendingAccount: false,
  employeeName: '',
  vendorName: '',
  networkName: '',
  confirmType: '',
  userId: null,
  groupId: null,
  benefitType: null,
  benefitStatus: null,
  benefitEndDate: null,
  policyEndDate: null,
  userBenefitsView: undefined,
  benefitPlanName: null,
  expirationDate: null,
};
