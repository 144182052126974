import { createSelector } from 'reselect';
import { selectValidationPathMap } from '../enrollment-config.selectors';
import { getHighestSeverity } from '../plan-options-validation/plan-options-validation.utils';
import {
  VALIDATION_STATUS_TYPES,
  BENEFIT_CLASS,
  BENEFIT_PLAN,
} from '../enrollment-config.constants';
import { matchPlanIdRegex } from '../enrollment-config.regexes';

const selectEmployerExperienceApp = state =>
  state?.apps?.['employer-experience'];

// Need to redefine these locally to prevent a circular dependency when importing from ../enrollment-config.selectors
export const selectPlanOptionsEntities = createSelector(
  selectEmployerExperienceApp,
  app => app?.employerBenefitPlanOptions?.entities ?? {},
);

export const selectPlanOptionsValidation = createSelector(
  selectEmployerExperienceApp,
  app => app?.enrollmentConfig?.planOptionsValidation ?? {},
);

// Return either the highest warning severity in any plan in this class or `valid`
const getValidationStatus = validationWarnings =>
  validationWarnings?.length > 0
    ? getHighestSeverity(validationWarnings)
    : VALIDATION_STATUS_TYPES.VALID;

const getBenefitClassStatus = (planValidationById, children) => {
  const allClassPlansValidated = children.every(child =>
    Object.keys(planValidationById).includes(child.planId),
  );
  // If still awaiting validation of one or more plans in the class, return a null status
  if (!allClassPlansValidated) return null;
  // create a flat array of all validation issues in all plans in this class
  const allPlansValidation = children?.reduce(
    (all, { planId: pId }) => [
      ...all,
      ...(planValidationById?.[pId]?.flattened ?? []),
    ],
    [],
  );

  return getValidationStatus(allPlansValidation);
};

const getHighestSeverityChildStatus = (validationPathMap, treePath) => {
  const childValidationStatuses = Object.entries(validationPathMap)
    .filter(([path]) => {
      if (path.indexOf(treePath) === 0) {
        const treePaths = treePath.split('.');
        // truncate the validation path at the same depth of the node's treePath
        const paths = path.split('.').slice(0, treePaths.length);
        // It's possible to get a partial match using just the index of the node's treePath
        // ex: `children.0.children1` would match paths:
        // `children.0.children11.children.1` and `children.0.children.1.children.99`
        // so we need to do an additional check to make sure the last part of the paths match
        // in this case, `1` and `11`.
        return treePaths[treePaths.length - 1] === paths[paths.length - 1];
      }

      return false;
    })
    .map(([, severity]) => ({ severity }));

  return getValidationStatus(childValidationStatuses);
};

export const selectPathValidationSeverity = createSelector(
  (_, node) => node,
  selectValidationPathMap,
  selectPlanOptionsValidation,
  selectPlanOptionsEntities,
  (
    {
      // this is the tree node
      treePath,
      entityType,
      entityDataPath,
      children,
      planId: nodePlanId, // only present on Benefit Plan nodes
    },
    // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
    validationPathMap = {},
    planValidationById,
    entities,
  ) => {
    if (entityType === BENEFIT_CLASS) {
      return getBenefitClassStatus(planValidationById, children);
    }

    // Check the entity for an exact path match and return that status if found
    const nodeStatus = validationPathMap?.[treePath];
    if (nodeStatus) return nodeStatus;

    // grab the plan ID from the node if present, otherwise extract from the data path
    const planId = entityDataPath
      ? nodePlanId ?? entityDataPath.match(matchPlanIdRegex)?.[1]
      : null;

    // This is kind of a special case to show the `Bundles` node as loading until we have the
    // actual plan data because otherwise we can't tell the status
    if (!planId || !entities?.planOptions?.[planId]) return null;

    // If the plan has no matching validations for its path, return the highest severity of any validation for this plan
    if (entityType === BENEFIT_PLAN) {
      return getValidationStatus(planValidationById?.[planId]?.flattened);
    }

    // Lastly, we will find any validation errors that have a treePath that is a direct descendent of this node's treePath
    return getHighestSeverityChildStatus(validationPathMap, treePath);
  },
);
