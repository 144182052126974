import { connect } from 'react-redux';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { Formik } from 'formik-legacy';
import { createStructuredSelector } from 'reselect';
import FinanceInvoicingDiagnostics from './finance-invoicing-diagnostics.view';
import {
  getInvoicingDiagnostics,
  clearInvoicingDiagnostics,
} from '../../finance-invoicing.actions';
import {
  selectInvoicingDiagnosticsLoading,
  selectInvoicingDiagnosticsContentId,
} from '../../../selectors';
import { INVOICING_DIAGNOSTIC_DATE } from '../../../constants';

const mapStateToProps = connect(
  createStructuredSelector({
    invoicingDiagnosticsLoading: selectInvoicingDiagnosticsLoading,
    invoicingDiagnosticsContentId: selectInvoicingDiagnosticsContentId,
  }),
  {
    getInvoicingDiagnostics,
    clearInvoicingDiagnostics,
  },
);

const withForm = Formik({
  mapPropsToValues: () => ({
    [INVOICING_DIAGNOSTIC_DATE]: '',
  }),
  handleSubmit: (values, formikBag) => {
    const date = values[INVOICING_DIAGNOSTIC_DATE]
      ? values[INVOICING_DIAGNOSTIC_DATE].toISOString()
      : null;
    formikBag.props.getInvoicingDiagnostics(date);
  },
});

const clearInvoiceHandler = connect((state, props) => ({
  handleClearInvoiceDiagnostics: () => {
    props.clearInvoicingDiagnostics();
    props.closeClearInvoiceDiagnostics();
  },
}));

const withHandlersInvoice = withHandlers({
  handleChange:
    ({ setFieldValue }) =>
    (fieldName, value) => {
      setFieldValue(fieldName, value);
    },
});

const withClearInvoiceDiagnosticsConfirmation = withStateHandlers(
  () => ({ clearInvoiceDiagnosticsVisible: false }),
  {
    showClearInvoiceDiagnostics: () => () => ({
      clearInvoiceDiagnosticsVisible: true,
    }),
    closeClearInvoiceDiagnostics: () => () => ({
      clearInvoiceDiagnosticsVisible: false,
    }),
  },
);

export default compose(
  mapStateToProps,
  withForm,
  withHandlersInvoice,
  withClearInvoiceDiagnosticsConfirmation,
  clearInvoiceHandler,
)(FinanceInvoicingDiagnostics);
