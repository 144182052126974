import { takeLatest, put, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { push } from 'connected-react-router';
import {
  listFeatureFlags,
  getFeatureFlag,
  setFeatureFlag,
} from 'common/services';
import {
  GET_FEATURE_FLAGS_LIST,
  GET_FEATURE_FLAG,
  REQUEST_LIST_FEATURE_FLAGS,
  REQUEST_GET_FEATURE_FLAG,
  FEATURE_FLAG_SET,
  REQUEST_SET_FEATURE_FLAG,
} from './flags.action-types';
import { FLAGS_ROOT } from './flags.route-helpers';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestListFeatureFlags() {
  yield request(REQUEST_LIST_FEATURE_FLAGS, listFeatureFlags());
}

export function* requestGetFeatureFlag(action) {
  yield request(REQUEST_GET_FEATURE_FLAG, getFeatureFlag(action.payload));
}

export function* requestSetFeatureFlag(action) {
  yield put(REQUEST_SET_FEATURE_FLAG.start());
  try {
    const response = yield setFeatureFlag(action.payload);
    yield put(REQUEST_SET_FEATURE_FLAG.success(response, action.payload));
    yield put(push(FLAGS_ROOT));
    yield call(toastActions.add, {
      textId: 'FEATURE_FLAG_UPDATED',
      type: TOAST_STATUS.SUCCESS,
    });
  } catch (error) {
    yield put(REQUEST_SET_FEATURE_FLAG.error(error));
    yield call(toastActions.add, {
      text: error.info.reason,
      type: TOAST_STATUS.ERROR,
    });
  }
}

export function* adminFeatureFlagsSaga() {
  yield takeLatest(
    [GET_FEATURE_FLAGS_LIST, REQUEST_SET_FEATURE_FLAG.SUCCEEDED],
    requestListFeatureFlags,
  );
  yield takeLatest(GET_FEATURE_FLAG, requestGetFeatureFlag);
  yield takeLatest(FEATURE_FLAG_SET, requestSetFeatureFlag);
}
