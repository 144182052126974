import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const readjustBenefitAmount = ({
  userId,
  claimId,
  sourceBenefitId,
  destBenefitId,
  destCategoryId,
  amount,
  realOperation = false,
}) =>
  call(websocketFetch, 'readjust_benefit_amount', {
    amount,
    real_operation: realOperation,
    user_id: userId,
    claim_id: claimId,
    source_benefit_id: sourceBenefitId,
    dest_benefit_id: destBenefitId,
    dest_category_id: destCategoryId,
  });
