import { takeLatest, fork, call, put } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { explodeObject } from 'common/adaptive-forms';
import {
  ADD_BENEFIT,
  UPDATE_BENEFIT,
  SET_EMPLOYER_BENEFITS,
  REMOVE_BENEFIT,
  REMOVE_EMPLOYER_BENEFIT,
  RENEW_BENEFIT,
  RENEW_EMPLOYER_BENEFIT,
  UPDATE_GROUP_CATEGORIES,
  REMOVE_GROUP_CATEGORIES,
} from './types';
import { employerBenefitDocumentsSaga } from './BenefitTypes/Documents/saga';
import { renewEmployerBenefits } from 'common/services';
import {
  updateGroupCategories as updateGroupCategoriesService,
  removeGroupCategories as removeGroupCategoriesService,
} from './Benefit/benefit.service';
import { fetchBenefits } from '../../plans.saga';
import { fetchPlans } from '../../../../saga';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* removeBenefit(action) {
  const { groupId, benefitId } = action.payload;
  yield request(REMOVE_EMPLOYER_BENEFIT, [
    websocketFetch,
    'remove_employer_benefit',
    {
      group_id: groupId,
      benefit_id: benefitId,
    },
  ]);
}

export function* addUpdateBenefit({ payload }) {
  const benefit = payload.benefit.benefit
    ? explodeObject(payload.benefit.benefit, Object)
    : explodeObject(payload.benefit, Object);

  yield fork(request, SET_EMPLOYER_BENEFITS, [
    websocketFetch,
    'set_employer_benefits',
    {
      group_id: benefit.provider_id,
      benefits: [benefit],
    },
  ]);

  yield call(fetchPlans, {
    payload: {
      groupId: benefit.provider_id,
      benefitClassId: payload.benefitClassId,
    },
  });

  yield call(fetchBenefits, {
    payload: {
      benefitClassId: payload.benefitClassId,
      benefitPlanId: benefit.plan_id,
      groupId: benefit.provider_id,
    },
  });
}

export function* renewBenefit(action) {
  const { payload } = action;
  yield request(
    RENEW_EMPLOYER_BENEFIT,
    renewEmployerBenefits({ benefitIds: payload.benefitIds }),
  );

  yield call(fetchBenefits, action);
}

export function* updateGroupCategories({ payload }) {
  try {
    const { groupId, contentId, benefitId, planId } = payload;

    yield updateGroupCategoriesService({
      category_file_content_id: contentId,
      content_file_type: 'csv',
      benefit_id: benefitId,
      inclusionary_groups: [groupId],
    });

    yield call(fetchBenefits, {
      payload: {
        benefitPlanId: planId,
        groupId,
      },
    });

    yield put(
      UPDATE_GROUP_CATEGORIES.success({ groupId, benefitPlanId: planId }),
    );
  } catch (error) {
    yield put(UPDATE_GROUP_CATEGORIES.error(error));
  }
}

export function* updateGroupCategoriesSuccess() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'UPDATE_GROUP_CATEGORIES_SUCCESS',
  });
}

export function* updateGroupCategoriesErrored({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: payload.info.reason,
    shouldAutoClose: false,
  });
}

export function* requestRemoveGroupCategories({ payload }) {
  yield request(
    REMOVE_GROUP_CATEGORIES,
    removeGroupCategoriesService({
      benefit_id: payload?.benefitId,
    }),
  );
}

export function* requestRemoveGroupCategoriesSuccess() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'REMOVE_GROUP_CATEGORIES_SUCCESS',
  });
}

export function* requestRemoveGroupCategoriesErrored({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: payload.info.reason,
    shouldAutoClose: false,
  });
}

export function* employerPlanBenefitsSaga() {
  yield takeLatest(REMOVE_BENEFIT, removeBenefit);
  yield takeLatest(ADD_BENEFIT, addUpdateBenefit);
  yield takeLatest(UPDATE_BENEFIT, addUpdateBenefit);
  yield takeLatest(RENEW_BENEFIT, renewBenefit);
  yield takeLatest(UPDATE_GROUP_CATEGORIES.BASE, updateGroupCategories);
  yield takeLatest(
    UPDATE_GROUP_CATEGORIES.SUCCEEDED,
    updateGroupCategoriesSuccess,
  );
  yield takeLatest(
    UPDATE_GROUP_CATEGORIES.ERRORED,
    updateGroupCategoriesErrored,
  );
  yield takeLatest(REMOVE_GROUP_CATEGORIES.BASE, requestRemoveGroupCategories);
  yield takeLatest(
    REMOVE_GROUP_CATEGORIES.SUCCEEDED,
    requestRemoveGroupCategoriesSuccess,
  );
  yield takeLatest(
    REMOVE_GROUP_CATEGORIES.ERRORED,
    requestRemoveGroupCategoriesErrored,
  );
  yield fork(employerBenefitDocumentsSaga);
}
