import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { EmployerReportsTabs } from './employer-reports-tabs.view';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import {
  selectCanShowPayrollReport,
  selectCanShowSpendingAccountBalanceReport,
  selectDigitalBeneficiariesEnabled,
} from './reports.selectors';
import { selectFeatureFlags } from 'common/state/featureFlags/feature-flags.selectors';
import { EMPLOYER_REPORTS_LIFECYCLE } from './reports.types';

export default connect(
  createStructuredSelector({
    groupId: selectCurrentUserGroupId,
    canShowPayrollReports: selectCanShowPayrollReport,
    canShowSpendingAccountBalanceReport:
      selectCanShowSpendingAccountBalanceReport,
    digitalBeneficiariesEnabled: selectDigitalBeneficiariesEnabled,
    featureFlags: selectFeatureFlags,
  }),
  {
    visit: EMPLOYER_REPORTS_LIFECYCLE.visit,
    exit: EMPLOYER_REPORTS_LIFECYCLE.exit,
  },
)(EmployerReportsTabs);
