import camelize from 'camelize';
import { get } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  REQUEST_SET_USER_DOCUMENT,
  REQUEST_USER_DOCUMENTS,
  REQUEST_REMOVE_USER_DOCUMENT,
  RESET_USER_DOCUMENTS,
  REQUEST_SET_CLAIM_DOCUMENTS,
  REQUEST_PIN_CLAIM_DOCUMENT,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  OPEN_IMAGE_VIEWER,
  CLOSE_IMAGE_VIEWER,
  REQUEST_UPLOAD_USER_DOCUMENT,
  CONFIRM_USER_DOCUMENT_REMOVAL,
  USER_DOCUMENT_REMOVE_CANCELLED,
  EDIT_USER_DOCUMENT,
  EDIT_USER_DOCUMENT_CANCELLED,
  SET_ACTIVE_INDEX,
  REQUEST_SUBMIT_TO_STRIPE,
} from './user-documents.types';

const initialState = {
  isEditingUserDocument: false,
  isRemovingUserDocument: false,
  currentDocumentId: null,
  isUploading: false,
  isUploadModalOpen: false,
  isRequesting: false,
  error: null,
  isImageViewerOpen: false,
  activeIndex: null,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const userDocumentsUIReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case REQUEST_SUBMIT_TO_STRIPE:
      return {
        ...state,
        currentDocumentId: null,
        isEditingUserDocument: false,
        isImageViewerOpen: false,
      };
    case REQUEST_USER_DOCUMENTS.STARTED:
    case REQUEST_REMOVE_USER_DOCUMENT.STARTED:
    case REQUEST_SET_CLAIM_DOCUMENTS.STARTED:
    case REQUEST_PIN_CLAIM_DOCUMENT.STARTED:
    case REQUEST_SET_USER_DOCUMENT.STARTED:
      return {
        ...state,
        isRequesting: true,
        isImageViewerOpen: false,
      };
    case REQUEST_USER_DOCUMENTS.SUCCEEDED:
    case REQUEST_SET_CLAIM_DOCUMENTS.SUCCEEDED:
    case REQUEST_PIN_CLAIM_DOCUMENT.SUCCEEDED:
    case REQUEST_SET_USER_DOCUMENT.SUCCEEDED:
    case REQUEST_USER_DOCUMENTS.CANCELLED:
    case REQUEST_SET_CLAIM_DOCUMENTS.CANCELLED:
    case REQUEST_PIN_CLAIM_DOCUMENT.CANCELLED:
    case REQUEST_SET_USER_DOCUMENT.CANCELLED:
      return {
        ...state,
        currentDocumentId: null,
        isEditingUserDocument: false,
        isRemovingUserDocument: false,
        isRequesting: false,
      };
    case REQUEST_USER_DOCUMENTS.ERRORED:
    case REQUEST_UPLOAD_USER_DOCUMENT.ERRORED:
    case REQUEST_PIN_CLAIM_DOCUMENT.ERRORED:
    case REQUEST_SET_USER_DOCUMENT.ERRORED:
    case REQUEST_SET_CLAIM_DOCUMENTS.ERRORED:
    case REQUEST_REMOVE_USER_DOCUMENT.ERRORED:
      return {
        ...state,
        isRequesting: false,
        error: camelize(get(action, 'payload.info')),
      };
    case OPEN_UPLOAD_MODAL:
      return {
        ...state,
        isUploadModalOpen: true,
        isImageViewerOpen: false,
      };
    case CLOSE_UPLOAD_MODAL:
      return {
        ...state,
        isUploadModalOpen: false,
      };
    case OPEN_IMAGE_VIEWER:
      return {
        ...state,
        isImageViewerOpen: true,
        activeIndex: action.activeIndex,
      };
    case SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.activeIndex,
      };
    case CLOSE_IMAGE_VIEWER:
      return {
        ...state,
        isImageViewerOpen: false,
        activeIndex: null,
      };
    case REQUEST_UPLOAD_USER_DOCUMENT.STARTED:
      return {
        ...state,
        isUploading: true,
      };
    case REQUEST_UPLOAD_USER_DOCUMENT.CANCELLED:
    case REQUEST_UPLOAD_USER_DOCUMENT.SUCCEEDED:
      return {
        ...state,
        isUploading: false,
        isUploadModalOpen: false,
      };
    case CONFIRM_USER_DOCUMENT_REMOVAL:
      return {
        ...state,
        currentDocumentId: action.contentId,
        isRemovingUserDocument: true,
      };
    case REQUEST_REMOVE_USER_DOCUMENT.SUCCEEDED:
    case REQUEST_REMOVE_USER_DOCUMENT.CANCELLED:
      return {
        ...state,
        currentDocumentId: null,
        isRemovingUserDocument: false,
        isRequesting: false,
      };
    case USER_DOCUMENT_REMOVE_CANCELLED:
      return {
        ...state,
        currentDocumentId: null,
        isRemovingUserDocument: false,
      };
    case EDIT_USER_DOCUMENT:
      return {
        ...state,
        currentDocumentId: action.contentId,
        isEditingUserDocument: true,
        isImageViewerOpen: false,
      };
    case EDIT_USER_DOCUMENT_CANCELLED:
      return {
        ...state,
        currentDocumentId: null,
        isEditingUserDocument: false,
        isImageViewerOpen: false,
      };
    case RESET_USER_DOCUMENTS:
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
