import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectReportContentId,
  selectIsReportLoading,
  selectReportError,
  selectUserEmail,
} from './employer-reports.selectors';
import { getEmployerBenefitsReport } from './employer-reports.actions';
import { EMPLOYER_BENEFITS_REPORT_LIFECYCLE } from './employer-reports.types';
import { BenefitsReport } from './benefits-report.view';

const mapStateToProps = createStructuredSelector({
  contentId: selectReportContentId,
  loading: selectIsReportLoading,
  error: selectReportError,
  email: selectUserEmail,
});

const mapDispatchToProps = {
  getEmployerBenefitsReport,
  visit: EMPLOYER_BENEFITS_REPORT_LIFECYCLE.visit,
};

export const BenefitsReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BenefitsReport);
