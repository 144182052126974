import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  eligibilitySelected as _eligibilitySelected,
  eligibilityTooltipOpened as _eligibilityTooltipOpened,
  ELIGIBILITY_PAGE_LIFECYCLE,
} from '../../invite-access.actions';
import {
  selectAnonymousGroupId,
  selectInviteAccessReady,
  selectResourcesConfig,
} from '../../invite-access.selectors';
import { Eligibility } from './eligibility.view';

export const EligibilityContainer = props => {
  const dispatch = useDispatch();

  const { employerIdentifier } = useParams();
  const anonymousGroupId = useSelector(selectAnonymousGroupId);
  const ready = useSelector(selectInviteAccessReady);
  const config = useSelector(selectResourcesConfig);

  // lifecycle
  useEffect(() => {
    dispatch(ELIGIBILITY_PAGE_LIFECYCLE.visit({ employerIdentifier }));
    return () =>
      dispatch(ELIGIBILITY_PAGE_LIFECYCLE.exit({ employerIdentifier }));
  }, [dispatch, employerIdentifier]);

  const eligibilitySelected = useCallback(
    isEligible => {
      dispatch(
        _eligibilitySelected({
          isEligible,
          anonymousGroupId,
          employerIdentifier,
        }),
      );
    },
    [dispatch, anonymousGroupId, employerIdentifier],
  );

  const eligibilityTooltipOpened = useCallback(() => {
    dispatch(_eligibilityTooltipOpened());
  }, [dispatch]);

  return ready && config ? (
    <Eligibility
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      eligibilitySelected={eligibilitySelected}
      eligibilityTooltipOpened={eligibilityTooltipOpened}
    />
  ) : null;
};
