import {
  INVOICE_SETTLED_STATE,
  INVOICE_PARTIALLY_PAID_STATE,
  INVOICE_PAID_IN_FULL_STATE,
  CREDITMEMO_FULLY_APPLIED_STATE,
} from './constants';
import { colour } from '@leagueplatform/ui-kit';

const parseAsNumber = value =>
  value === 'Infinity' ? 1000000000 : Number(value);

export const mapSubscriptionRates = subs => {
  const backendConversion = [];
  subs.forEach(sub => {
    backendConversion.push({
      monthly_price: parseAsNumber(sub.rate),
      pepy_from: parseAsNumber(sub.lower),
      pepy_to: parseAsNumber(sub.upper),
    });
  });
  return backendConversion;
};

export const getInvoiceStatusTextId = state => {
  switch (state) {
    case INVOICE_PARTIALLY_PAID_STATE:
      return 'INVOICE_STATUS_PARTIALLY_PAID';
    case INVOICE_SETTLED_STATE:
    case INVOICE_PAID_IN_FULL_STATE:
    case CREDITMEMO_FULLY_APPLIED_STATE:
      return 'PAID';
    default:
      return 'INVOICE_STATUS_OUTSTANDING';
  }
};

export const getInvoiceStatusColour = state => {
  switch (state) {
    case INVOICE_PARTIALLY_PAID_STATE:
      return colour('flushOrange');
    case INVOICE_SETTLED_STATE:
    case INVOICE_PAID_IN_FULL_STATE:
    case CREDITMEMO_FULLY_APPLIED_STATE:
      return colour('moneyGreen');
    default:
      return colour('red');
  }
};
