import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import {
  selectEmployeeProfile,
  selectGroupId,
} from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import {
  saveDependent as saveDependentAction,
  getDependentAddQLEReasons,
  getDependentRelationshipTypes,
} from '../store/dependent.actions';
import {
  selectIsDependentAddLoading,
  selectEmployerCountryCode,
  selectDependentAddQLEReasons,
  selectDependentRelationshipTypes,
  selectLoading,
} from '../store/dependent.selectors';

import { dependentSchema } from '../store/dependent.schema';

import { AddDependentPresenter } from './add-dependent.view';
import { useIntl } from '@leagueplatform/locales';

const mapStateToProps = createStructuredSelector({
  addQLEReasons: selectDependentAddQLEReasons,
  employee: selectEmployeeProfile,
  relationshipTypes: selectDependentRelationshipTypes,
  countryCode: selectEmployerCountryCode,
  groupId: selectGroupId,
  preRequisitesLoading: selectIsDependentAddLoading,
  loading: selectLoading,
});

const mapDispatchToActions = {
  saveDependent: saveDependentAction,
  getQLEReasons: getDependentAddQLEReasons,
  getRelationshipTypes: getDependentRelationshipTypes,
};

export const AddDependent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToActions,
  )(
    ({
      dependent,
      saveDependent,
      groupId,
      employee,
      history,
      preRequisitesLoading,
      getQLEReasons,
      addQLEReasons,
      getRelationshipTypes,
      relationshipTypes,
      dependentsBaseUrl,
      ...rest
    }) => {
      useEffect(() => {
        getQLEReasons();
        getRelationshipTypes();
      }, [getQLEReasons, getRelationshipTypes]);

      const { formatMessage } = useIntl();

      const onSubmit = values => {
        saveDependent({ dependent: values, groupId, userId: employee.user_id });
      };

      const onCancel = () => {
        history.push(`${dependentsBaseUrl}`);
      };

      if (preRequisitesLoading) {
        return <LoadingIndicator />;
      }

      const augmentedFields = dependentSchema({
        addQLEReasons,
        formatMessage,
        relationshipTypes,
      });

      return (
        <AddDependentPresenter
          onSubmit={onSubmit}
          onCancel={onCancel}
          dependent={dependent}
          fields={augmentedFields}
          dependentsBaseUrl={dependentsBaseUrl}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...rest}
        />
      );
    },
  ),
);
