import {
  VISITED,
  EXITED,
  CLAIM_SELECTED,
  OPTIONS_CHANGED,
} from './claims.action-types';

export const visited = payload => ({
  type: VISITED,
  payload,
});

export const exited = payload => ({
  type: EXITED,
  payload,
});

export const claimSelected = claimId => ({
  type: CLAIM_SELECTED,
  payload: { claimId },
});

export const optionsChanged = options => ({
  type: OPTIONS_CHANGED,
  payload: { options },
});
