import React from 'react';
import { Box } from '@leagueplatform/ui-kit';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { EditEmployerForm } from './Profile';
import { Benefits } from './Benefits';
import EmployeeDetails from './EmployeeDetails';
import Billing from './Billing/billing.container';
import Integrations from './employer-integrations/employer-integrations.container';
import { EmployerInsights } from './employer-reports/employer-insights.component';
import Payroll from './Payroll/employer-payroll.container';
import { EmployerLooker } from './employer-looker/employer-looker.view';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { TabList, TabLink } from 'apps/employer-experience/ui-kit/tabs';

const EmployerTabs = ({ match, employerName }) => {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <TabList>
        <TabLink to={`${match.url}/profile`}>
          {formatMessage({ id: 'PROFILE' })}
        </TabLink>
        <TabLink to={`${match.url}/benefits`}>
          {formatMessage({ id: 'BENEFITS' })}
        </TabLink>
        <TabLink to={`${match.url}/integrations`}>
          {formatMessage({ id: 'INTEGRATIONS' })}
        </TabLink>
        <TabLink to={`${match.url}/billing`}>
          {formatMessage({ id: 'BILLING' })}
        </TabLink>
        <TabLink to={`${match.url}/employees`}>
          {formatMessage({ id: 'EMPLOYEES' })}
        </TabLink>
        <TabLink to={`${match.url}/reports`}>
          {formatMessage({ id: 'REPORTS' })}
        </TabLink>
        <TabLink to={`${match.url}/payroll`}>
          {formatMessage({ id: 'PAYROLL' })}
        </TabLink>
      </TabList>
      <Switch>
        <Route
          exact
          path={`${match.url}/profile`}
          component={EditEmployerForm}
        />
        <Route path={`${match.path}/benefits`}>
          <Benefits employerName={employerName} />
        </Route>
        <Route
          exact
          path={`${match.url}/integrations`}
          component={Integrations}
        />
        <Route path={`${match.url}/billing`} component={Billing} />
        <Route path={`${match.path}/employees`} component={EmployeeDetails} />
        <Route
          exact
          path={`${match.url}/reports`}
          component={EmployerInsights}
        />
        <Route exact path={`${match.url}/payroll/:tab?`} component={Payroll} />
        <Route exact path={`${match.url}/looker`} component={EmployerLooker} />
        <Redirect to={`/admin/employers/${match.params.groupId}/profile`} />
      </Switch>
    </Box>
  );
};

EmployerTabs.propTypes = {
  match: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  employerName: PropTypes.string,
};

EmployerTabs.defaultProps = {
  match: {},
  employerName: null,
};

export default withRouter(EmployerTabs);
