import { createSelector } from 'reselect';
import { PLAN_STATUSES } from 'common/constants';
import { getPlanByStatus } from './benefit-plans.utils';

export const selectBenefitPlans = state => state?.benefitPlans;

export const selectBenefitPlansById = createSelector(
  selectBenefitPlans,
  benefitPlans => benefitPlans?.byId,
);

export const selectBenefitPlanIds = createSelector(
  selectBenefitPlans,
  benefitPlans => benefitPlans?.allIds ?? [],
);

export const selectBenefitPlanList = createSelector(
  selectBenefitPlanIds,
  selectBenefitPlansById,
  (ids, byId) =>
    ids.reduce((plans, id) => {
      const plan = byId?.[id];
      return plan ? [...plans, plan] : plans;
    }, []),
);

export const selectActiveBenefitPlan = createSelector(
  selectBenefitPlanList,
  plans => plans.find(getPlanByStatus(PLAN_STATUSES.ACTIVE)),
);
