import { call, all, put } from 'redux-saga/effects';
import { get, reduce } from 'lodash';
import { request } from 'common/websocket-redux';
import {
  getClaim,
  getClaims,
  getUserProfile,
  getDependents,
  getUserBenefitCategories,
  getUserDocuments,
  getGroup as _getGroup,
} from 'common/services';
import { claimResourcesLoaded } from '../claim-details.actions';
import { REQUEST_USER_DOCUMENTS } from 'common/components/user-documents/user-documents.types';
import { GET_USER_HISTORY } from '../user-history/user-history.types';
import { getActiveEmployerGroup } from '@leagueplatform/web-common';

const parseUserId = claim => get(claim, 'info.user_id');

const parseAgentId = claim => get(claim, 'info.claim_agent');

export function* getGroup(groupId) {
  const response = yield _getGroup(groupId);
  return {
    ...get(response, 'info.group', {}),
    group_id: get(response, 'info.group_id'),
  };
}

export const parseGroupId = user => {
  const groups = get(user, 'info.groups');
  return getActiveEmployerGroup(groups)?.group_id ?? null;
};

const parseFunds = claim => get(claim, 'info.available_funds');

export const parseDuplicateClaimWarning = message => {
  try {
    const isDuplicate =
      message.message_type === 'fail' &&
      message.info.code === 'potential_duplicate_claim';
    if (isDuplicate) {
      const info = message.info.info.duplicate_claims[0];
      const originalClaim = message.info.message.info;
      return {
        claimId: info.claim_id,
        duplicateClaimId: info.duplicate_claim_id,
        duplicateClaimReferenceId: info.duplicate_claim_reference_id,
        claimForUpdate: originalClaim,
      };
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getCategories = (userId, funds) =>
  all(
    reduce(
      funds,
      (calls, fund) => ({
        ...calls,
        [fund.policy_kind]: getUserBenefitCategories({
          userId,
          benefitId: fund.user_benefit_id || fund.policy_kind,
          version: 1,
        }),
      }),
      {},
    ),
  );

export function* getClaimResources(claimId) {
  const claim = yield getClaim(claimId);
  const userId = parseUserId(claim);
  const claimAgentId = parseAgentId(claim);
  const funds = parseFunds(claim);
  const { categories, user, agent, documents } = yield all({
    categories: getCategories(userId, funds),
    user: getUserProfile(userId),
    agent: getUserProfile(claimAgentId),
    documents: getUserDocuments(userId),
  });

  if (documents) {
    yield put(REQUEST_USER_DOCUMENTS.success(documents));
  }

  const groupId = parseGroupId(user);

  const { dependents, group } = groupId
    ? yield all({
        dependents: getDependents({ groupId, userId }),
        group: call(getGroup, groupId),
      })
    : {
        dependents: [],
        group: null,
      };

  yield put(claimResourcesLoaded(claim));
  yield request(GET_USER_HISTORY, getClaims({ userId }));
  return {
    claim,
    agent,
    categories,
    user,
    group,
    dependents,
  };
}
