import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@leagueplatform/locales';
import {
  breakpoints,
  DottedTooltipModal,
  TooltipHeader,
  Responsive,
} from '@leagueplatform/ui-kit';
import {
  Label,
  LabelText,
  Box,
  Flex,
  Text,
  ErrorMessage,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';

const HeaderFlex = genesisStyled(Flex)(
  css({
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 'threeQuarters',
    lineHeight: '20px',
  }),
);

export const FieldWrapper = ({
  label,
  isRequired,
  tooltip,
  tooltipValues,
  children,
  htmlFor,
  error,
}) => {
  return (
    <Label htmlFor={htmlFor} id={`${htmlFor}_label`} marginTop="two">
      <HeaderFlex>
        <Flex>
          <LabelText>{label}</LabelText>
          {isRequired && <Text color="onSurface.text.critical">*</Text>}
        </Flex>

        {tooltip && (
          <>
            <Responsive breakpoint={breakpoints.lapAndAbove}>
              <TooltipHeader
                tooltipLink={<FormattedMessage id="WHY_DO_YOU_ASK" />}
                tooltipText={
                  <Box minWidth={350} fontSize="body2">
                    <FormattedHTMLMessage id={tooltip} values={tooltipValues} />
                  </Box>
                }
                verticalPosition="bottom: 3rem;"
                pointerPosition={50}
                inline
              />
            </Responsive>
            <Responsive breakpoint={breakpoints.belowLap}>
              <DottedTooltipModal
                label={<FormattedMessage id="WHY_DO_YOU_ASK" />}
                content={
                  <FormattedHTMLMessage id={tooltip} values={tooltipValues} />
                }
                inline
              />
            </Responsive>
          </>
        )}
      </HeaderFlex>
      <Box>{children}</Box>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Label>
  );
};

FieldWrapper.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  tooltipValues: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  ]),
};

FieldWrapper.defaultProps = {
  isRequired: false,
  tooltip: null,
  tooltipValues: {},
  error: null,
};
