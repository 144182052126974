import { createRequestTypes } from 'common/websocket-redux';

export const LOAD_DEDUCTION_CODE_EDITOR = createRequestTypes(
  'service/LOAD_DEDUCTION_CODES_EDITOR',
);

export const GET_DEDUCTION_CODES = createRequestTypes(
  'service/GET_DEDUCTION_CODES',
);

export const SET_DEDUCTION_CODES = createRequestTypes(
  'service/SET_DEDUCTION_CODES',
);

export const DELETE_DEDUCTION_CODES = createRequestTypes(
  'service/DELETE_DEDUCTION_CODES',
);

export const IMPORT_DEDUCTION_CODES = createRequestTypes(
  'service/IMPORT_DEDUCTION_CODES',
);

export const EXPORT_DEDUCTION_CODES = createRequestTypes(
  'service/EXPORT_DEDUCTION_CODES',
);
