import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { selectApp } from '../app.selectors';
import { selectEmployer } from '../../employer-experience/pages/EmployerDetails/selectors';

export const selectUI = createSelector(selectApp, get('ui.employerDivisions'));

export const selectEditing = createSelector(selectUI, get('editingId'));

export const selectEditedName = createSelector(selectUI, get('editedName'));

export const selectGroupBillingDivisions = createSelector(
  selectEmployer,
  get('billingDivisions'),
);
