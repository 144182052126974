import { createRequestTypes } from 'common/websocket-redux';

export const CREATE_EMPLOYER = createRequestTypes('service/CREATE_EMPLOYER');
export const UPDATE_EMPLOYER = createRequestTypes('service/UPDATE_EMPLOYER');
export const CREATE_EMPLOYER_SCHEMA = createRequestTypes(
  'service/CREATE_EMPLOYER_SCHEMA',
);
export const NEW_EMPLOYER_VISITED = 'profile/NEW_EMPLOYER_VISITED';
export const NEW_EMPLOYER_FORM_SUBMITTED =
  'profile/NEW_EMPLOYER_FORM_SUBMITTED';
export const EDIT_EMPLOYER_VISITED = 'profile/EDIT_EMPLOYER_VISITED';
export const EDIT_EMPLOYER_FORM_INIT_STARTED =
  'profile/EDIT_EMPLOYER_FORM_INIT_STARTED';
export const EDIT_EMPLOYER_FORM_INIT_COMPLETED =
  'profile/EDIT_EMPLOYER_FORM_INIT_COMPLETED';
export const EDIT_EMPLOYER_FORM_SUBMITTED =
  'profile/EDIT_EMPLOYER_FORM_SUBMITTED';

export const UPDATE_GROUP = createRequestTypes('employer/UPDATE_GROUP');
export const PUBLISH_EMPLOYER = createRequestTypes('service/PUBLISH_EMPLOYER');
export const ACTIVATE_EMPLOYER = createRequestTypes(
  'service/ACTIVATE_EMPLOYER',
);
export const SUSPEND_EMPLOYER = createRequestTypes('service/SUSPEND_EMPLOYER');

export const PUBLISH_EMPLOYER_SELECTED = 'employer/PUBLISH_EMPLOYER_SELECTED';
export const ACTIVATE_EMPLOYER_SELECTED = 'employer/ACTIVATE_EMPLOYER_SELECTED';
export const SUSPEND_EMPLOYER_SELECTED = 'employer/SUSPEND_EMPLOYER_SELECTED';
