import { put, take } from 'redux-saga/effects';
import { get } from 'lodash';
import { MESSAGE_RECEIVED } from './types';
import { send } from './actions';
import uuid from 'js-uuid';

export const identifyResponse = messageId => action =>
  action.type === MESSAGE_RECEIVED && action.payload.message_id === messageId;

export const isError = action => {
  const messageType = get(action, 'payload.message_type');
  const code = get(action, 'payload.info.code');
  return (
    action.type === MESSAGE_RECEIVED &&
    (['fail', 'server_error'].includes(messageType) ||
      code === 'invalid_request')
  );
};

export function* websocketFetch(
  messageType,
  info = {},
  settings = {},
  id = uuid.v4(),
) {
  const identify = identifyResponse(id);
  yield put(send(messageType, info, id, settings));
  const response = yield take(identify);
  if (isError(response)) throw response.payload;
  return response.payload;
}
