import { all, takeLatest } from 'redux-saga/effects';
import { analytics } from 'common/utilities';
import { GET_USER_PAYMENT_CREDENTIALS } from 'apps/member-experience/resources/payment-credentials/payment-credentials.actions';
import { ANALYTICS_COPY_INTERAC_CODE } from './banking-info-analytics.types';
import { VISITED } from './banking-info.actions';
import { get } from 'lodash';

export function* bankingInfoAnalyticsSaga() {
  yield all([
    takeLatest(
      ANALYTICS_COPY_INTERAC_CODE,
      analytics.bankingInfo.copyInteracCode,
    ),
    takeLatest(VISITED, analytics.pageViews.bankingInfo.list),
    takeLatest(GET_USER_PAYMENT_CREDENTIALS.SUCCEEDED, ({ payload }) => {
      const credentials = get(payload, 'info.credentials');

      if (!credentials) {
        analytics.pageViews.bankingInfo.emptyState();
      }
    }),
  ]);
}
