export type EnrollmentTemplateStatus =
  (typeof EnrollmentTemplateStatuses)[keyof typeof EnrollmentTemplateStatuses];

export const EnrollmentTemplateStatuses = {
  NO_TEMPLATE: 'NO_TEMPLATE',
  LIVE: 'LIVE',
  INACTIVE: 'INACTIVE',
} as const;

export type EnrollmentBannerType =
  (typeof EnrollmentBannerTypes)[keyof typeof EnrollmentBannerTypes];

export const EnrollmentBannerTypes = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
} as const;
