import {
  CREATE_DIRECT_DEPOSIT_CANCELLED,
  CREATE_DIRECT_DEPOSIT_CONFIRMED,
  OPEN_CONFIRMATION_MODAL,
} from './direct-deposit-account.types';

// Note: Params only for analytics
export const createDirectDepositCancelled = ({ isEditing, accountType }) => ({
  type: CREATE_DIRECT_DEPOSIT_CANCELLED,
  payload: {
    isEditing,
    accountType,
  },
});

// Note: Params only for analytics
export const createDirectDepositConfirmed = ({ isEditing, accountType }) => ({
  type: CREATE_DIRECT_DEPOSIT_CONFIRMED,
  payload: {
    isEditing,
    accountType,
  },
});

export const openConfirmationModal = credentials => ({
  type: OPEN_CONFIRMATION_MODAL,
  payload: {
    credentials,
  },
});
