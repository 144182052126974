import { useMemo } from 'react';
import { useTheme } from '@leagueplatform/genesis-commons';
import { VALIDATION_STATUS_TYPES } from '../enrollment-config.constants';

export const useValidationStatus = ({ status, validationWarnings }) => {
  const theme = useTheme();
  const statusColor = useMemo(() => {
    switch (status) {
      case VALIDATION_STATUS_TYPES.VALID:
        return theme.colors.success.icon;
      case VALIDATION_STATUS_TYPES.WARNING:
        return theme.colors.warning.icon;
      case VALIDATION_STATUS_TYPES.ERROR:
        return theme.colors.critical.icon;
      default:
        return theme.colors.tertiary.border.default;
    }
  }, [status, theme]);

  const loading = useMemo(() => status === null, [status]);

  const filled = useMemo(
    () =>
      status === VALIDATION_STATUS_TYPES.VALID ||
      validationWarnings?.length > 0,
    [validationWarnings, status],
  );

  return {
    statusColor,
    loading,
    filled,
  };
};
