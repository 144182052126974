import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export type ResetEnrollmentDesignOptionsParams = {
  planId: string;
};

export const resetEnrollmentDesignOptions = ({
  planId,
}: ResetEnrollmentDesignOptionsParams) =>
  call(websocketFetch, 'reset_enrollment_design_options', {
    plan_id: planId,
  });
