/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { takeLatest } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { GET_BENEFIT_SELECTIONS_DIFF } from './benefit-selections-tool.actions';

export function* fetchBenefitSelectionsDiff({
  payload: {
    userId: user_id,
    groupId: group_id,
    planId: plan_id,
    benefitType: benefit_type,
  },
}) {
  yield request(GET_BENEFIT_SELECTIONS_DIFF, [
    websocketFetch,
    'get_benefit_selections_diff',
    {
      user_id,
      group_id,
      plan_id,
      benefit_type,
    },
  ]);
}

export function* benefitSelectionsToolSaga() {
  yield takeLatest(
    GET_BENEFIT_SELECTIONS_DIFF.BASE,
    fetchBenefitSelectionsDiff,
  );
}
