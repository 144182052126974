import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export type GetBenefitPlanOptionsParams = {
  groupId: string | null;
  planId: string | null;
};

export const getBenefitPlanOptions = ({
  groupId = null,
  planId = null,
}: GetBenefitPlanOptionsParams) =>
  call(websocketFetch, 'get_benefit_plan_options', {
    group_id: groupId,
    plan_id: planId,
  });
