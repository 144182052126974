/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 *
 * @function getEmployeeDemographicsReport - fetches employee demographics report
 * based on the group id given
 *
 * @arg {object} payload - redux action passed from calling saga
 *
 * @arg {string} payload.groupId - group id used to generate demographics report
 */
export const getEmployeeDemographicsReport = ({ groupId: group_id }) =>
  call(websocketFetch, 'request_report', {
    type: 'demographics_report',
    request: {
      group_id,
    },
  });
