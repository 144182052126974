import { TOP_UP_USER_BENEFIT, RESET_TOP_UP_STATUS } from './add-funds.types';

export const topUpUserBenefit = ({ benefitId, amount }) => ({
  type: TOP_UP_USER_BENEFIT,
  payload: { benefitId, amount },
});

export const resetTopUpStatus = () => ({
  type: RESET_TOP_UP_STATUS,
});
