import styled from 'styled-components';
import { vars } from '@leagueplatform/ui-kit';

const { purple, purpleDark, greyLight } = vars.colour;

export const AddButton = styled('button')`
  font-weight: bold;
  border: none;
  background-color: transparent;
  font-size: 0;
  cursor: pointer;

  > span {
    color: ${purple};
    display: inline-block;
    font-size: 1.6rem;
    line-height: 3.2rem;
    vertical-align: middle;
    transition: all 200ms ease-out;
  }

  &::before {
    color: white;
    content: '+';
    font-size: 1.6rem;
    display: inline-block;
    background-color: ${purple};
    line-height: 1;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 1.5rem;
    width: 2.2rem;
    padding-bottom: 0.4rem;
    padding-top: 0.2rem;
    transition: all 200ms ease-out;
  }

  &:disabled {
    > span {
      color: ${greyLight};
    }

    &::before {
      background-color: ${greyLight};
    }
  }

  &:hover:not([disabled]) {
    > span {
      color: ${purpleDark};
    }

    &::before {
      background-color: ${purpleDark};
    }
  }
`;
