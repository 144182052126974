import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { selectApp } from '../app.selectors';
import { selectEmployer } from '../../employer-experience/pages/EmployerDetails/selectors';

export const selectInvoices = createSelector(
  selectApp,
  get('employerInvoices'),
);

export const selectInvoiceById = createSelector(
  (_, { invoiceId }) => invoiceId,
  selectInvoices,
  (invoiceId, invoices) =>
    invoices.find(invoice => invoice.invoice_id === invoiceId),
);

export const selectTouchedInvoices = createSelector(selectInvoices, invoices =>
  invoices.filter(invoice => invoice.touched),
);

export const selectEmployerBillingDivisions = createSelector(
  selectEmployer,
  get('billingDivisions'),
);

export const selectEmployerBillingPreference = createSelector(
  selectEmployer,
  get('billingPreference'),
);

/* UI */

export const selectUI = createSelector(selectApp, get('ui.employerInvoices'));

export const selectReady = createSelector(selectUI, get('ready'));

export const selectDryRunLoading = createSelector(
  selectUI,
  get('dryRunLoading'),
);

export const selectDryRuns = createSelector(selectUI, get('invoiceDryRuns'));

export const selectInvoicesTouched = createSelector(
  selectUI,
  get('invoicesTouched'),
);
