import { combineReducers } from 'redux';
import { GET_CARE_PROVIDER_APPOINTMENT_HISTORY } from './care-provider-appointment-history.actions';
import { makeStatusReducer } from 'common/request/status.reducer';

export const initialState = {
  appointments: null,
};

const appointments = (state = initialState.appointments, action = {}) => {
  switch (action.type) {
    case GET_CARE_PROVIDER_APPOINTMENT_HISTORY.BASE:
      return initialState.appointments;
    case GET_CARE_PROVIDER_APPOINTMENT_HISTORY.SUCCEEDED:
      return action.payload.info.data ?? [];
    default:
      return state;
  }
};

const status = makeStatusReducer(GET_CARE_PROVIDER_APPOINTMENT_HISTORY);

export const careProviderAppointmentHistoryReducer = combineReducers({
  appointments,
  status,
});
