import React, { useEffect, useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import { BenefitWrapperContainer } from '../benefits/benefit-wrapper.container';
import Benefit from './benefit.view';
import AddBenefit from '../benefits/add-benefit/add-benefit.view';
import PropTypes from 'prop-types';
import { AccountSummary } from './account-summary.view';
import { AccountOverview } from '../account-overview.view';
import { genesisStyled, Flex, Box } from '@leagueplatform/genesis-commons';
import { Tooltip, TooltipContentV2 } from '@leagueplatform/ui-kit';
import { QuestionIcon } from 'apps/employer-experience/components/question-icon.component';
import GroupedBenefitOptions from './grouped-benefit-options.view';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { getHRAdminAnalytics } from 'common/utilities/get-hr-admin-analytics';

const ExplanationLabel = genesisStyled.div`
  padding-bottom: 1rem;
`;

const ToolTipLabel = genesisStyled.span`
  font-size: 1rem;
  font-weight: 300;
`;

const TooltipContentV2Wrap = genesisStyled(Flex)`
  flex-direction: column;
  white-space: initial;
`;

const renderAccountSummary = (id, benefitSummaries) => {
  const benefit = benefitSummaries[id];

  return benefit && benefit.is_spending_account ? (
    <AccountSummary
      balance={benefit.amount_available}
      allocated={benefit.amount_allocated}
    />
  ) : null;
};

const isBenefitSpendingAccount = (id, benefitSummaries) => {
  return benefitSummaries[id] && benefitSummaries[id].is_spending_account;
};

const renderAccountOverview = (id, benefitSummaries) => {
  const benefit = benefitSummaries[id];

  return benefit && benefit.is_spending_account ? (
    <AccountOverview body={benefit.coverage} />
  ) : null;
};

const usePopupTranslatedText = () => {
  const { formatMessage } = useIntl();

  const explanationLabelText = formatMessage({ id: 'PLANS_EXPLANATION' });
  const examplesText = formatMessage({ id: 'EXAMPLES' });
  const plansExampleText = formatMessage({ id: 'PLANS_EXAMPLE' });

  return {
    explanationLabelText,
    examplesText,
    plansExampleText,
  };
};
const GroupedBenefits = ({
  addNewBenefit,
  adjustBenefitEndDate,
  benefits,
  benefitSummaries,
  catalogue,
  classId,
  className,
  featureFlags,
  firstName,
  initialActiveState,
  isAdmin,
  lastName,
  planId,
  planName,
  removeBenefit,
  selectedClass,
  updateBenefitStatus,
  openUpdateFlexDollarsModal,
}) => {
  const [activeState, setActiveState] = useState(initialActiveState);
  const { explanationLabelText, examplesText, plansExampleText } =
    usePopupTranslatedText();

  useEffect(() => {
    setActiveState(initialActiveState);
  }, [initialActiveState]);

  const sendAnalytics = (subDetail, detail, textDetail) => {
    if (!isAdmin) {
      const getparam = getHRAdminAnalytics();
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.HR_ADMIN,
        screen_name: getparam[0],
        sub_product_area: getparam[1] || null,
        detail,
        sub_detail: subDetail,
        text_detail: textDetail,
      });
    }
  };

  const toggleAccordion = () => {
    setActiveState(!activeState);
    const subDetail = !activeState ? 'expand' : 'collapse';
    const detail = `toggle ${planName} ${className} accordion`;
    sendAnalytics(subDetail, detail, null);
  };

  return (
    <>
      <Accordion.Title active={activeState} onClick={toggleAccordion}>
        <Flex alignItems="center">
          <Icon fitted name="dropdown" />
          {className && <span>{className} - </span>}
          {planName && <span>{planName} </span>}
          <ToolTipLabel
            onMouseEnter={() =>
              sendAnalytics(null, 'hover for info', explanationLabelText)
            }
          >
            <Box marginX="half">
              <Tooltip>
                <QuestionIcon />
                <TooltipContentV2>
                  <TooltipContentV2Wrap>
                    <ExplanationLabel>{explanationLabelText}</ExplanationLabel>
                    <strong>{examplesText}</strong>
                    {plansExampleText}
                  </TooltipContentV2Wrap>
                </TooltipContentV2>
              </Tooltip>
            </Box>
          </ToolTipLabel>
        </Flex>
        {isAdmin && (
          <GroupedBenefitOptions
            planId={planId}
            openUpdateFlexDollarsModal={openUpdateFlexDollarsModal}
          />
        )}
      </Accordion.Title>
      <Accordion.Content active={activeState}>
        {benefits &&
          benefits.map(benefit => (
            <BenefitWrapperContainer
              fullName={benefit.parent.full_name}
              vendorName={benefit.benefit_vendor_name}
              networkName={benefit.parent.network_name}
              benefitPlanName={benefit.parent.plan_name}
              benefit={benefit}
              key={benefit.id}
              removeBenefit={removeBenefit}
              updateBenefitStatus={updateBenefitStatus}
              isAdmin={isAdmin}
              meta={renderAccountSummary(benefit.id, benefitSummaries)}
              isSpendingAccount={isBenefitSpendingAccount(
                benefit.id,
                benefitSummaries,
              )}
              canTopUpSpendingAccount={featureFlags.topUpSpendingAccount}
              employeeName={`${firstName} ${lastName}`}
              adjustBenefitEndDate={adjustBenefitEndDate}
            >
              {renderAccountOverview(benefit.id, benefitSummaries)}
              <Benefit
                key={benefit.id}
                benefitType={benefit.parent.benefit_type}
                values={benefit}
              />
            </BenefitWrapperContainer>
          ))}

        {isAdmin && classId === selectedClass && (
          <AddBenefit catalogue={catalogue} addNewBenefit={addNewBenefit} />
        )}
      </Accordion.Content>
    </>
  );
};

GroupedBenefits.propTypes = {
  addNewBenefit: PropTypes.func.isRequired,
  removeBenefit: PropTypes.func.isRequired,
  updateBenefitStatus: PropTypes.func.isRequired,
  adjustBenefitEndDate: PropTypes.func.isRequired,
  catalogue:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  benefitSummaries:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  selectedClass: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  featureFlags: PropTypes.shape({
    topUpSpendingAccount: PropTypes.bool,
  }),
  employee: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  benefits: PropTypes.array.isRequired,
  classId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  initialActiveState: PropTypes.bool.isRequired,
  planId: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  openUpdateFlexDollarsModal: PropTypes.func.isRequired,
};

GroupedBenefits.defaultProps = {
  firstName: '',
  lastName: '',
  selectedClass: '',
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  employeeClass: '',
  featureFlags: {
    topUpSpendingAccount: false,
  },
  employee: {
    first_name: '',
    last_name: '',
  },
};

export default GroupedBenefits;
