import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_USER_BENEFITS = createRequestTypes(
  'service/GET_USER_BENEFITS',
);

export const GET_USER_BENEFIT_SUMMARIES = createRequestTypes(
  'service/GET_USER_BENEFIT_SUMMARIES',
);

export const GET_EMPLOYER_BENEFITS = createRequestTypes(
  'service/GET_EMPLOYER_BENEFITS',
);

export const ADD_EMPLOYEE_BENEFIT_DOCUMENT = createRequestTypes(
  'service/ADD_EMPLOYEE_BENEFIT_DOCUMENT',
);

export const SET_USER_BENEFIT_DOCUMENTS = createRequestTypes(
  'service/SET_USER_BENEFIT_DOCUMENTS',
);

export const SET_USER_BENEFIT_CLASS = createRequestTypes(
  'service/SET_USER_BENEFIT_CLASS',
);

export const USER_BENEFIT_CLASS_CHANGED = 'employee/USER_BENEFIT_CLASS_CHANGED';

export const GET_USER_BENEFIT_CLASSES = createRequestTypes(
  'service/GET_USER_BENEFIT_CLASSES',
);

export const GET_USER_BENEFIT_DOCUMENTS = createRequestTypes(
  'service/GET_USER_BENEFIT_DOCUMENTS',
);

export const UPLOAD_BENEFIT_DOCUMENT_FILE_REQUESTED =
  'documents/UPLOAD_BENEFIT_DOCUMENT_FILE_REQUESTED';

export const ADD_USER_BENEFITS = createRequestTypes(
  'service/ADD_USER_BENEFITS',
);

export const UPDATE_USER_BENEFITS = createRequestTypes(
  'service/UPDATE_USER_BENEFITS',
);

export const SET_USER_BENEFITS_STATUS_READY = createRequestTypes(
  'service/SET_USER_BENEFITS_STATUS_READY',
);

export const REQUEST_REMOVE_USER_BENEFIT =
  'benefits/REQUEST_REMOVE_USER_BENEFIT';

export const REMOVE_USER_BENEFIT = createRequestTypes(
  'service/REMOVE_USER_BENEFIT',
);

export const REQUEST_ADJUST_BENEFIT_END_DATE =
  'benefits/REQUEST_ADJUST_BENEFIT_END_DATE';

export const ADJUST_BENEFIT_END_DATE = createRequestTypes(
  'service/ADJUST_BENEFIT_END_DATE',
);

export const UPDATE_USER_BENEFIT_STATUS = 'benefits/UPDATE_USER_BENEFIT_STATUS';
export const UPDATE_ALL_USER_BENEFITS_STATUS =
  'benefits/UPDATE_ALL_USER_BENEFITS_STATUS';

export const EMPLOYEE_BENEFITS_PAGE_LIFECYCLE = createLifecycleTypes(
  'employee-benefits/EMPLOYEE_BENEFITS_PAGE',
);

export const GET_BENEFIT_TYPE_SCHEMAS = createRequestTypes(
  'service/GET_BENEFIT_TYPE_SCHEMAS',
);

export const FETCHED_ALL_USER_BENEFIT_DOCUMENTS =
  'benefits/FETCHED_ALL_USER_BENEFIT_DOCUMENTS';

export const GET_EMPLOYER_BENEFIT_PLANS = createRequestTypes(
  'service/GET_EMPLOYER_BENEFIT_PLANS',
);

export const GET_ALL_STATUSES_EMPLOYER_BENEFIT_PLANS = createRequestTypes(
  'service/GET_ALL_STATUSES_EMPLOYER_BENEFIT_PLANS',
);
