import React from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';
import { handleReadOnly } from './handle-read-only.hoc';

export const VendorSelect = handleReadOnly(
  ({ value, options, onVendorQueryChange, onChange }) => (
    <Search
      value={value}
      onSearchChange={(event, data) => {
        onChange(data.value);
        onVendorQueryChange(data.value);
      }}
      onResultSelect={(event, data) => {
        onChange(data.result.value);
      }}
      results={options}
      showNoResults={false}
    />
  ),
);

VendorSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, title: PropTypes.string }),
  ).isRequired,
  onVendorQueryChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

VendorSelect.defaultProps = {
  value: '',
};
