import { createRequestTypes } from 'common/websocket-redux';

export const GET_QLE_REQUEST = 'qle-request/GET_QLE_REQUEST';
export const SERVICE_GET_QLE_REQUEST = createRequestTypes(
  'qle-request/SERVICE_GET_QLE_REQUEST',
);
export const HANDLE_NEX = 'qle-request/HANDLE_NEX';
export const REQUEST_SET_QLE_ENROLLMENT = createRequestTypes(
  'qle-request/REQUEST_SET_QLE_ENROLLMENT',
);
