import { pick } from 'lodash';
import { GET_GROUP } from './actions';
import { EMPLOYEE_DETAILS_INIT } from './employee-details/employee-details.types';
import { EMPLOYEE_SEARCH_INIT } from './employee-search';
import { EMPLOYER_BENEFITS_INIT } from './employer-benefits';
import { EMPLOYER_INVOICES_INIT } from './employer-invoices';
import { EMPLOYER_PAYROLL_INIT } from './employer-reports/components/payroll-report';

const initialState = null;

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP.SUCCEEDED:
      return {
        ...state,
        ...action.payload.info,
      };
    case EMPLOYER_BENEFITS_INIT.SUCCEEDED:
    case EMPLOYEE_SEARCH_INIT.SUCCEEDED:
    case EMPLOYER_PAYROLL_INIT.SUCCEEDED:
    case EMPLOYER_INVOICES_INIT.SUCCEEDED:
      return {
        ...state,
        ...pick(action.payload.group, ['group_id', 'benefit_classes']),
      };
    case EMPLOYEE_DETAILS_INIT.SUCCEEDED:
      return {
        ...state,
        ...pick(action.payload, ['group_id', 'benefit_classes']),
      };
    default:
      return state;
  }
};
