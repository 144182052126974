export const BENEFIT_CLASSES = 'Benefit Classes';
export const BENEFIT_CLASS = 'benefit-class';
export const BENEFIT_PLAN = 'benefit-plan';
export const ROOT = 'root';
export const DOCUMENTS = 'documents';
export const SET_IDS = 'set_ids';
export const BENEFIT_ID = 'benefit_id';
export const SET_ID = 'set_id';
export const SCHEMA_PROPERTY_NAMES = {
  DOCUMENTS,
  SET_IDS,
};

const MYSELF = 'myself';
const MYSELF_SPOUSE = 'myself_spouse';
const MYSELF_CHILDREN = 'myself_children';
const MYSELF_SPOUSE_CHILDREN = 'myself_spouse_children';
const MYSELF_ONE_CHILD = 'myself_one_child';

export const DEPENDENT_COVERAGE_OPTIONS = [
  MYSELF,
  MYSELF_SPOUSE,
  MYSELF_CHILDREN,
  MYSELF_SPOUSE_CHILDREN,
  MYSELF_ONE_CHILD,
];

const ALL = 'all';
const SINGLE = 'single';
const COUPLE = 'couple';
const FAMILY = 'family';

export const DEPENDENT_CLASS_OPTIONS = [ALL, SINGLE, COUPLE, FAMILY];

// Validation
export const WARNING_SEVERITY_ERROR = 'error';
export const WARNING_SEVERITY_WARNING = 'warning';

export const WARNING_SEVERITY = {
  ERROR: WARNING_SEVERITY_ERROR,
  WARNING: WARNING_SEVERITY_WARNING,
};

const VALIDATION_STATUS_TYPE_VALID = 'valid';
const VALIDATION_STATUS_TYPE_WARNING = 'warning';
const VALIDATION_STATUS_TYPE_ERROR = 'error';

export const VALIDATION_STATUS_TYPES = {
  VALID: VALIDATION_STATUS_TYPE_VALID,
  WARNING: VALIDATION_STATUS_TYPE_WARNING,
  ERROR: VALIDATION_STATUS_TYPE_ERROR,
};
