/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const updateFaqLinks = ({
  faq_links_file_content_id,
  content_file_type,
}) => {
  const info = {
    faq_links_file_content_id,
    content_file_type,
  };
  return call(websocketFetch, 'update_faq_links', info);
};
