import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { DEPENDENT_RELATIONSHIP_MAP } from 'common/constants';
import { useIntl } from '@leagueplatform/locales';
import styled from 'styled-components';
import { Box } from '@leagueplatform/genesis-commons';
import {
  Heading,
  Icon,
  ICONS,
  colour,
  spaceInPx,
  fontSizeInPx,
  getThemeValue,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { MALE, FEMALE } from 'common/dependents/dependents-metadata';
import { StatusLabel } from './components/StatusLabel.view';
import { DATE_FORMAT, SHORT_DATE_FORMAT } from '@leagueplatform/web-common';
import { snakeCase } from 'lodash';
import {
  Breadcrumb,
  BreadcrumbLinkItem,
  BreadcrumbItem,
} from 'apps/employer-experience/ui-kit';
import { TextAction } from '@leagueplatform/genesis-core';
import { StudentCertificationView } from './student-certification/student-certification.component';
import { useQuery } from 'react-query';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  getUserProfile,
} from '@leagueplatform/user-profile-api';

const PencilIcon = styled(Icon)`
  stroke: ${colour('purple')};
  max-height: 19px;
  margin-bottom: ${spaceInPx(1)};
`;

const KeyValueList = styled.dl`
  display: flex;
  flex-wrap: wrap;
`;

const Key = styled.dt`
  flex: 0 1 250px;
  white-space: nowrap;
  font-size: ${fontSizeInPx(1)};
  color: ${colour('onSurface.text.subdued')};
  margin-bottom: ${getThemeValue('space.genesis.threeQuarters')}px;
`;

const Value = styled.dd`
  flex: 1 1 calc(100% - 250px);
  margin-right: auto;
  font-weight: 500;
  font-size: ${fontSizeInPx(1)};
  letter-spacing: -0.2px;
  color: ${colour('purpleDarkest')};
  margin-bottom: ${getThemeValue('space.genesis.threeQuarters')}px;
`;

const StyledHeading = styled(Heading)`
  && {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const DependentView = withRouter(
  ({ dependent, dependentsBaseUrl, match, showEdit, userId, groupId }) => {
    const { formatMessage, formatDate } = useIntl();
    const relationshipType =
      DEPENDENT_RELATIONSHIP_MAP[dependent.relationship_type];
    const [showStudentCertificationModal, setStudentCertificationModal] =
      useState(false);
    const { data } = useQuery([GET_USER_PROFILE_MESSAGE_TYPE, userId], () =>
      getUserProfile(userId),
    );
    const userCurrentGroup = data?.groups?.find(
      current => current.group_id === groupId,
    );
    const selectedDependent = userCurrentGroup?.dependents?.find(
      selected => selected.dependent_id === dependent.dependent_id,
    );
    const hasActiveStudentFlow = selectedDependent?.has_active_student_flow;

    return (
      <>
        <Box paddingX="half" overflowY="auto">
          <Breadcrumb>
            <BreadcrumbLinkItem to={dependentsBaseUrl}>
              {formatMessage({ id: 'DEPENDENTS' })}
            </BreadcrumbLinkItem>
            <BreadcrumbItem>
              {dependent.first_name} {dependent.last_name}
            </BreadcrumbItem>
          </Breadcrumb>
          <Box marginTop="oneAndHalf" marginBottom="twoAndHalf">
            <Flex justify="space-between" align="top">
              <Box>
                <StyledHeading level={3}>
                  {dependent.first_name} {dependent.last_name}
                </StyledHeading>
                {dependent.pendingSuspension && (
                  <StatusLabel backgroundColor="none" padding={0}>
                    {formatMessage(
                      {
                        id: 'DEPENDENT_TERMINATION_PENDING',
                      },
                      {
                        terminationDate: formatDate(
                          dependent.remove_qualifying_life_event_date,
                          {
                            ...SHORT_DATE_FORMAT,
                            timeZone: 'utc',
                          },
                        ),
                      },
                    )}
                  </StatusLabel>
                )}
              </Box>
              <Box>
                <Flex>
                  {hasActiveStudentFlow && (
                    <TextAction
                      icon="illustrativeStudentRecertification"
                      css={{
                        marginRight: '$two',
                        '&:hover': {
                          textDecoration: 'none',
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                      }}
                      onClick={() => {
                        setStudentCertificationModal(true);
                      }}
                    >
                      {formatMessage({ id: 'STUDENT_RECERTIFICATION' })}
                    </TextAction>
                  )}
                  {showEdit && (
                    <Link to={`${match.url}/edit`}>
                      <PencilIcon icon={ICONS.PENCIL} fill="white" small />
                      {formatMessage({ id: 'EDIT' })}
                    </Link>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Heading level={4}>{formatMessage({ id: 'BASIC_INFO' })}</Heading>
          <KeyValueList>
            <Key>{formatMessage({ id: 'LEGAL_FIRST_NAME' })}</Key>
            <Value>{dependent.first_name}</Value>
            <Key>{formatMessage({ id: 'LAST_NAME' })}</Key>
            <Value>{dependent.last_name}</Value>
            <Key>{formatMessage({ id: 'DATE_OF_BIRTH' })}</Key>
            <Value>
              {formatDate(dependent.date_of_birth, {
                ...DATE_FORMAT,
                timeZone: 'utc',
              })}
            </Value>
            <Key>{formatMessage({ id: 'SEX' })}</Key>
            <Value>
              {dependent.gender === MALE && formatMessage({ id: 'MALE' })}
              {dependent.gender === FEMALE && formatMessage({ id: 'FEMALE' })}
            </Value>
            <Key>{formatMessage({ id: 'SSN' })}</Key>
            <Value>{dependent.national_identification_number}</Value>
            <Key>{formatMessage({ id: 'USES_TOBACCO' })}</Key>
            <Value>
              {dependent.tobacco_user === true && formatMessage({ id: 'YES' })}
              {dependent.tobacco_user === false && formatMessage({ id: 'NO' })}
            </Value>
            <Key>{formatMessage({ id: 'RELATIONSHIP_TYPE' })}</Key>
            <Value>
              {relationshipType && formatMessage({ id: relationshipType })}
            </Value>
          </KeyValueList>
          <Heading level={4}>
            {formatMessage({ id: 'DEPENDENT_DETAILS' })}
          </Heading>
          <KeyValueList>
            <Key>{formatMessage({ id: 'RELATIONSHIP_EFFECTIVE_DATE' })}</Key>
            <Value>
              {dependent.partnership_effective_date &&
                formatDate(dependent.partnership_effective_date, {
                  ...DATE_FORMAT,
                  timeZone: 'utc',
                })}
            </Value>
            <Key>{formatMessage({ id: 'ADD_QLE_TYPE' })}</Key>
            <Value>
              {dependent.add_qualifying_life_event_reason &&
                formatMessage({
                  id: snakeCase(
                    dependent.add_qualifying_life_event_reason,
                  ).toUpperCase(),
                })}
            </Value>
            <Key>{formatMessage({ id: 'ADD_QLE_DATE' })}</Key>
            <Value>
              {dependent.add_qualifying_life_event_date &&
                formatDate(dependent.add_qualifying_life_event_date, {
                  ...DATE_FORMAT,
                  timeZone: 'utc',
                })}
            </Value>
            <Key>{formatMessage({ id: 'REMOVE_QLE_DATE' })}</Key>
            <Value>
              {dependent.remove_qualifying_life_event_date &&
                formatDate(dependent.remove_qualifying_life_event_date, {
                  ...DATE_FORMAT,
                  timeZone: 'utc',
                })}
            </Value>
            <Key>{formatMessage({ id: 'VERIFICATION_STATUS' })}</Key>
            <Value>
              {dependent.verification_status &&
                formatMessage({
                  id: dependent.verification_status.toUpperCase(),
                })}
            </Value>
            <Key>
              {formatMessage({ id: 'VERIFICATION_STATUS_EFFECTIVE_DATE' })}
            </Key>
            <Value>
              {dependent.verification_status_effective_date &&
                formatDate(dependent.verification_status_effective_date, {
                  ...DATE_FORMAT,
                  timeZone: 'utc',
                })}
            </Value>
            {dependent.relationship_type === 'child' && (
              <>
                <Key>{formatMessage({ id: 'CHILD_OF_DOMESTIC_PARTNER' })}</Key>
                <Value>
                  {dependent.child_of_domestic_partner === true &&
                    formatMessage({ id: 'YES' })}
                  {dependent.child_of_domestic_partner === false &&
                    formatMessage({ id: 'NO' })}
                </Value>
              </>
            )}
          </KeyValueList>
        </Box>
        {showStudentCertificationModal && (
          <StudentCertificationView
            dependent={dependent}
            userId={userId}
            onClose={() => setStudentCertificationModal(false)}
            groupId={groupId}
          />
        )}
      </>
    );
  },
);

DependentView.propTypes = {
  dependent: PropTypes.shape({
    dependent_id: PropTypes.string.isRequired,
    relationship_type: PropTypes.string.isRequired,
    date_of_birth: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    national_identification_number: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    tobacco_user: PropTypes.bool,
    pendingSuspension: PropTypes.bool,
  }).isRequired,
  dependentsBaseUrl: PropTypes.string.isRequired,
  showEdit: PropTypes.bool,
};

DependentView.defaultProps = {
  showEdit: false,
};
