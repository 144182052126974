import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link } from 'react-router-dom';
import { SecondaryButton } from '@leagueplatform/genesis-commons';
import { getBeneficiariesUrl } from '../beneficiaries.utilities';

export const AddBeneficiaryButton = props => {
  const { formatMessage } = useIntl();
  return (
    <SecondaryButton
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      marginTop="oneAndHalf"
      as={Link}
      to={getBeneficiariesUrl('designations')}
      style={{
        textDecoration: 'none',
      }}
    >
      {formatMessage({ id: 'ADD_BENEFICIARY' })}
    </SecondaryButton>
  );
};
