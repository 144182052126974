import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Heading, Message, ErrorMessage, Text } from '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import styled from 'styled-components';
import TypeformIntegration, {
  TypeformShape,
  TypeformValuesShape,
} from './typeform.view';

// NOTE: we are temporarily override ui-kit sizing here, we're planning to unify semantics/ui-kit base unit at the end of the spring
const SaveIntegrationButton = styled(LoaderButton)`
  height: 3rem;
  font-size: 1rem;
  margin-top: 3rem;
  border-radius: 0.2rem;
`;

const EmployerIntegrations = ({
  typeform,
  setFieldValue,
  handleSubmit,
  ui,
  values,
}) => {
  const { isSaving, error } = ui;
  return (
    <form onSubmit={handleSubmit}>
      <Heading level={4}>
        <FormattedMessage id="CONFIGURE_THIRD_PARTY_INTEGRATIONS" />
      </Heading>
      <Text>
        <FormattedMessage id="ENABLE_THIRD_PARTY_INTEGRATIONS_BY_FILLING_IN_FIELDS" />
      </Text>
      <TypeformIntegration
        typeform={typeform}
        setFieldValue={setFieldValue}
        values={values}
      />
      {error === false && (
        <Message mt={3}>
          <FormattedMessage id="INTEGRATIONS_CONFIGURATION_SAVED" />
        </Message>
      )}
      {error && <ErrorMessage mt={3}>{error}</ErrorMessage>}
      <SaveIntegrationButton loading={isSaving} primary type="submit">
        Save Changes
      </SaveIntegrationButton>
    </form>
  );
};

EmployerIntegrations.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    isSaving: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  typeform: TypeformShape.isRequired,
  values: TypeformValuesShape.isRequired,
};

EmployerIntegrations.defaultProps = {};

export default EmployerIntegrations;
