import { takeLatest, takeEvery, fork, all } from 'redux-saga/effects';
import {
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS_READ,
  MARK_UNREAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_COUNT,
  GET_UNREAD_NOTIICATION_COUNT,
} from './notifications.types';
import { postToWR } from 'common/utilities';
import { request, websocketFetch } from 'common/websocket-redux';
import { getUnreadNotificationCount } from 'common/services';

export function* getNotifications({ limit, before, after }) {
  yield request(GET_NOTIFICATIONS, [
    websocketFetch,
    'get_notifications',
    { limit, before, after },
  ]);
}

export function* getUnreadNotifications() {
  yield request(GET_UNREAD_NOTIICATION_COUNT, getUnreadNotificationCount());
}

export function* setNotificationsRead({ notificationIds }) {
  yield request(SET_NOTIFICATIONS_READ, [
    websocketFetch,
    'set_notifications_read',
    {
      notification_ids: notificationIds,
    },
  ]);
}

/* notify the WR iFrame that it needs to recheck unread count */
export function onNotificationsMarkReadSucceeded() {
  postToWR('notificationsMarkedRead');
}

export function* watchMarkUnreadNotifications() {
  yield takeEvery(MARK_UNREAD_NOTIFICATIONS, setNotificationsRead);
}

export function* watchLoadNotifications() {
  yield takeLatest(LOAD_NOTIFICATIONS, getNotifications);
}

export function* watchNotificationsCount() {
  yield takeEvery(LOAD_NOTIFICATIONS_COUNT, getUnreadNotifications);
}

export function* watchMarkReadSucceeded() {
  yield takeEvery(
    SET_NOTIFICATIONS_READ.SUCCEEDED,
    onNotificationsMarkReadSucceeded,
  );
}

export function* notificationsSaga() {
  yield all([
    fork(watchMarkUnreadNotifications),
    fork(watchLoadNotifications),
    fork(watchMarkReadSucceeded),
    fork(watchNotificationsCount),
  ]);
}
