import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { createStructuredSelector } from 'reselect';
import { NetSuiteInvoiceReportPresenter } from './netsuite-invoice-report.view';
import { getNetSuiteInvoiceReport } from '../../finance-invoicing.actions';
import {
  selectNetSuiteInvoiceReportLoading,
  selectNetSuiteInvoiceReportContentId,
} from '../../../selectors';
import { format, subMonths, parseISO } from 'date-fns';

const toLowerUTCDate = date =>
  new Date(
    `${format(parseISO(date), 'yyyy-MM-dd')}T00:00:00.000Z`,
  ).toISOString();
const toUpperUTCDate = date =>
  new Date(
    `${format(parseISO(date), 'yyyy-MM-dd')}T23:59:59.999Z`,
  ).toISOString();
const getToday = () => new Date();
const getMonthBefore = () => subMonths(new Date(), 1);

const mapStateToProps = createStructuredSelector({
  isLoading: selectNetSuiteInvoiceReportLoading,
  contentId: selectNetSuiteInvoiceReportContentId,
});

const mapDispatchToProps = {
  dispatchRequest: getNetSuiteInvoiceReport,
};

const getInitialValues = () => ({
  fromDate: getMonthBefore().toISOString(),
  toDate: getToday().toISOString(),
});

export const NetSuiteInvoiceReportContainer = ({
  isLoading,
  contentId,
  dispatchRequest,
}) => {
  // onSubmit converts fromDate to the start of the day in UTC and
  // toDate to the end of the day in UTC. Ex.:
  // fromDate as Oct 1, 2020 -> 2020-10-01T00:00:00.000Z
  // toDate as Oct 31, 2020 -> 2020-10-31T23:59:59.999Z
  const onSubmit = ({ fromDate, toDate }) => {
    dispatchRequest({
      fromDate: toLowerUTCDate(fromDate),
      toDate: toUpperUTCDate(toDate),
    });
  };
  return (
    <Formik initialValues={getInitialValues()} onSubmit={onSubmit}>
      {formProps => (
        <NetSuiteInvoiceReportPresenter
          isLoading={isLoading}
          contentId={contentId}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...formProps}
        />
      )}
    </Formik>
  );
};
NetSuiteInvoiceReportContainer.propTypes = {
  isLoading: PropTypes.bool,
  contentId: PropTypes.string,
  dispatchRequest: PropTypes.func.isRequired,
};
NetSuiteInvoiceReportContainer.defaultProps = {
  contentId: null,
  isLoading: false,
};

export const NetSuiteInvoiceReport = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NetSuiteInvoiceReportContainer);
