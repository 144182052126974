import { all, takeEvery, call } from 'redux-saga/effects';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { ANALYTICS_EVENT_NAME_ADMIN } from 'apps/employer-experience-v2/employer-reports/reports.constants';
import {
  TOGGLE_DOCUMENT_SELECTED,
  EDIT_CLAIM_SELECTED,
  CANCEL_CLAIM_SELECTED,
  OPEN_CHAT_SELECTED,
} from './claim-details.actions';

const categories = {
  CLAIMS_HISTORY_LIST: 'Claims History List',
  CLAIMS_HISTORY_EMPTY_STATE: 'Claims History Empty State',
  CLAIMS_HISTORY_DETAIL: 'Claims History Detail',
};

function* viewClaimDocuments() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_DETAIL,
      action: 'View Claim Documents',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

function* editClaim() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_DETAIL,
      action: 'Edit Claim',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

function* cancelClaim() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_DETAIL,
      action: 'Cancel Claim',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

function* speakWithAgent() {
  yield call(
    sendAnalyticsEvent,
    {
      category: categories.CLAIMS_HISTORY_DETAIL,
      action: 'Speak with Agent',
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

export function* claimDetailsAnalyticsSaga() {
  yield all([
    takeEvery(TOGGLE_DOCUMENT_SELECTED, viewClaimDocuments),
    takeEvery(EDIT_CLAIM_SELECTED, editClaim),
    takeEvery(CANCEL_CLAIM_SELECTED, cancelClaim),
    takeEvery(OPEN_CHAT_SELECTED, speakWithAgent),
  ]);
}
