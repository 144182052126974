import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const setClaim = ({
  claimId,
  requestedBenefitType,
  amountClaimed,
  currency,
  description,
  contentIds,
}) =>
  call(websocketFetch, 'set_claim', {
    claim_id: claimId,
    content_ids: contentIds,
    description,
    amount_claimed: { value: amountClaimed, currency },
    requested_benefit_type: requestedBenefitType,
  });
