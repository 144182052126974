import cookie from 'react-cookies';
import { AUTHENTICATE_CONNECTION } from 'common/websocket-redux';
import { SIGN_OUT } from '../auth.actions';
import { getCurrentLocale } from '@leagueplatform/locales';

export const initialState = {
  authenticated: false,
  isSigningOut: false,
  isSigningIn: false,
};

const applyRequestAuthenticate = state => ({
  ...state,
  isSigningIn: true,
});

const applyAuthenticated = (state, { payload }) => {
  /**
   * some Canadian users have their browser default locale set to en-US, as a result they will see CA$ in
   * FormattedNumber instead of just $, we're rectifying this by overriding the US part with CA if their default
   * stored currency is CAD
   * */
  let locale = getCurrentLocale();
  if (
    payload.info.currency === 'CAD' &&
    ['en', 'fr'].indexOf(locale.split('-')[0]) > -1
  ) {
    locale = `${locale.split('-')[0]}-CA`;
  }

  return {
    ...initialState,
    authenticated: true,
    // Remove conditional cookie load
    sessionId: payload.info.session_id || cookie.load('SID2'),
    // User properties may not be required
    // and are available in currentUser state
    userId: payload.info.user_id || cookie.load('U'),
    role: payload.info.role || cookie.load('R'),
    locale,
  };
};

const applyRequestSignOut = state => ({
  ...state,
  isSigningOut: true,
});

const applyError = (state, { payload }) => ({
  ...state,
  ...payload,
  isSigningOut: false,
  isSigningIn: false,
});

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTHENTICATE_CONNECTION.STARTED:
      return applyRequestAuthenticate(state);
    case AUTHENTICATE_CONNECTION.ERRORED:
      return applyError(state, action);
    case AUTHENTICATE_CONNECTION.SUCCEEDED:
      return applyAuthenticated(state, action);
    case SIGN_OUT.STARTED:
      return applyRequestSignOut(state);
    case SIGN_OUT.SUCCEEDED:
      return initialState;
    default:
      return state;
  }
};
