import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { vars, Box } from '@leagueplatform/ui-kit';
import { getOptionId, OTHER } from '@leagueplatform/web-common';

const InfoItem = styled(Box)`
  cursor: pointer;
`;

const Text = styled(Box)`
  line-height: 1.6;
  color: ${vars.colour.purpleDark};
  display: inline-block;
`;

const Label = styled(Text)`
  font-weight: 500;
  margin-right: 0.25rem;
`;

const Value = styled(Text)`
  text-overflow: ellipsis;
  font-weight: 300;
`;

export const UserDocumentInfo = ({
  documentType,
  documentTypeOtherDescription,
  expirationDate,
  onClick,
}) => (
  <div>
    {documentType && (
      <InfoItem justify="center" flexDirection="column" onClick={onClick}>
        <Label>
          <FormattedMessage id="DOCUMENT_TYPE" />
        </Label>
        <Value>
          {getOptionId(documentType) ? (
            <FormattedMessage id={getOptionId(documentType)} />
          ) : (
            <span>{documentType}</span>
          )}
        </Value>
      </InfoItem>
    )}

    {documentType === OTHER && (
      <InfoItem justify="center" flexDirection="column" onClick={onClick}>
        <Value>{documentTypeOtherDescription}</Value>
      </InfoItem>
    )}

    {expirationDate && (
      <InfoItem justify="center" flexDirection="column" onClick={onClick}>
        <Label>
          <FormattedMessage id="EXPIRES" />
        </Label>
        <Value>
          <FormattedDate
            value={new Date(expirationDate)}
            year="numeric"
            month="short"
            day="numeric"
          />
        </Value>
      </InfoItem>
    )}
  </div>
);

UserDocumentInfo.propTypes = {
  documentType: PropTypes.string,
  documentTypeOtherDescription: PropTypes.string,
  expirationDate: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

UserDocumentInfo.defaultProps = {
  expirationDate: null,
  documentType: null,
  documentTypeOtherDescription: null,
};
