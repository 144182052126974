import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  HeadingFour,
  BodyOne,
  BodyTwoSecondary,
  Image,
  Link,
} from '@leagueplatform/genesis-commons';
import { Toggle } from '@leagueplatform/ui-kit';
import Logo from 'apps/public/assets/contentful-logo.png';
import InfoIcon from 'apps/public/assets/circle-info-icon.svg';
import ErrorIcon from 'apps/public/assets/error-circle.svg';
import { TEMPLATE_STATUS } from './wallet-template-utils';
import { TemplatePair } from './parts/template-pair.view';

export const ContentfulToggleView = ({
  name,
  value,
  walletTemplates: unfilteredTemplates,
  benefitId,
  setFieldValue,
  onCreateTemplateSelected,
  values,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { formatMessage } = useIntl();

  /* walletTemplates should be an array of pairs (arrays of 2 elements).
    Sometimes, the API will return an array of double pairs (arrays of 4 elements),
    where one pair will have the wrong vendor_id. It's easier to filter them out
    in the front-end than in the back-end.
  */
  const vendorId = values?.benefit_vendor_id;
  const walletTemplates = unfilteredTemplates
    ? unfilteredTemplates.reduce((result, curr) => {
        const filtered = curr.filter(
          template => template.vendor_id === vendorId,
        );
        if (filtered.length > 1) result.push(filtered);
        return result;
      }, [])
    : [];

  const listLength = walletTemplates?.length || 0;
  const isMulticard = listLength > 1;
  const firstPair = walletTemplates && walletTemplates[0];
  const remainingPairs = isMulticard ? walletTemplates.slice(1) : [];

  /* Show error banner if any of these conditions are met:
    no data returned from backend,
    data returned but both no_template, both inactive, one no_template and one inactive
  */
  const showErrorBanner =
    !walletTemplates ||
    (walletTemplates?.length > 0 &&
      walletTemplates.some(pair => {
        const first = pair && pair[0].status;
        const second = pair && pair[1].status;
        return (
          (first === TEMPLATE_STATUS.NO_TEMPLATE ||
            first === TEMPLATE_STATUS.INACTIVE) &&
          (second === TEMPLATE_STATUS.NO_TEMPLATE ||
            second === TEMPLATE_STATUS.INACTIVE)
        );
      }));

  return (
    <Box marginY="two">
      <HeadingFour marginBottom="oneAndHalf">
        {formatMessage({ id: 'BENEFIT_WALLET_CONTENT' })}
      </HeadingFour>
      <Box
        borderRadius="medium"
        borderStyle="solid"
        borderWidth="thin"
        borderColor="onSurface.border.subdued"
        paddingY="oneAndHalf"
      >
        <Flex justifyContent="space-between" marginX="oneAndHalf">
          <Image src={Logo} width={127} height={27} alt="Contentful logo" />
          <Toggle
            onChange={e =>
              setFieldValue(name, e.target.checked ? 'true' : 'false')
            }
            checked={value === 'true'}
          >
            {name}
          </Toggle>
        </Flex>
        <BodyTwoSecondary
          marginTop="one"
          marginX="oneAndHalf"
          fontWeight="regular"
        >
          {formatMessage({ id: 'CONTENTFUL_DESCRIPTION' })}
        </BodyTwoSecondary>
        {value === 'true' && (
          <Box marginTop="one">
            {showErrorBanner ? (
              <Flex
                marginX="oneAndHalf"
                padding="threeQuarters"
                alignItems="center"
                backgroundColor="critical.background.subdued"
                borderStyle="solid"
                borderWidth="thin"
                borderColor="critical.border.default"
                borderRadius="small"
              >
                <Image
                  src={ErrorIcon}
                  size={26}
                  marginRight="one"
                  role="presentation"
                />
                <BodyOne>
                  {formatMessage({
                    id: isMulticard
                      ? 'CONTENTFUL_ERROR_BANNER_MULTICARD'
                      : 'CONTENTFUL_ERROR_BANNER',
                  })}
                </BodyOne>
              </Flex>
            ) : (
              <Flex
                marginX="oneAndHalf"
                padding="threeQuarters"
                alignItems="center"
                backgroundColor="highlight.background.subdued"
                borderStyle="solid"
                borderWidth="thin"
                borderColor="highlight.border.default"
                borderRadius="small"
              >
                <Image
                  src={InfoIcon}
                  size={26}
                  marginRight="one"
                  role="presentation"
                />
                <BodyOne>
                  {formatMessage({ id: 'CONTENTFUL_INFO_BANNER' })}
                </BodyOne>
              </Flex>
            )}
            {walletTemplates && (
              <>
                {firstPair && (
                  <TemplatePair
                    key={firstPair[0]?.benefit_type}
                    pair={firstPair}
                    isFirstPair
                    isLastPair={listLength === 1}
                    benefitId={benefitId}
                    onCreateTemplateSelected={onCreateTemplateSelected}
                    listLength={listLength}
                  />
                )}
                {remainingPairs &&
                  isExpanded &&
                  remainingPairs.map((pair, index) => (
                    <TemplatePair
                      key={pair[0]?.benefit_type}
                      isLastPair={index === listLength}
                      pair={pair}
                      benefitId={benefitId}
                      onCreateTemplateSelected={onCreateTemplateSelected}
                      listLength={listLength}
                    />
                  ))}
                {isMulticard && (
                  <Box
                    textAlign="center"
                    marginTop={isExpanded && 'oneAndHalf'}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
                    <Link
                      as="p"
                      onClick={() => setIsExpanded(!isExpanded)}
                      display="inline-block"
                    >
                      {isExpanded ? '- ' : '+ '}
                      {formatMessage({
                        id: isExpanded
                          ? 'CLOSE_BENEFIT_LIST'
                          : 'EXPAND_BENEFIT_LIST',
                      })}{' '}
                      ({listLength.toString()})
                    </Link>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

ContentfulToggleView.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  walletTemplates: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        benefit_product_type: PropTypes.string,
        benefit_type: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        template_type: PropTypes.string,
        url: PropTypes.string,
        vendor_id: PropTypes.string,
      }),
    ),
  ),
  benefitId: PropTypes.string.isRequired,
  onCreateTemplateSelected: PropTypes.func.isRequired,
  values: PropTypes.shape({
    benefit_vendor_id: PropTypes.string,
  }),
};

ContentfulToggleView.defaultProps = {
  values: null,
  walletTemplates: null,
  value: 'false',
};
