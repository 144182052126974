import styled, { css } from 'styled-components';
import { vars, Select as BaseSelect } from '@leagueplatform/ui-kit';

export const Select = styled(BaseSelect)`
  && {
    margin: 0;

    ${({ hasError }) =>
      hasError &&
      css`
        border-color: ${vars.colour.red};
      `};

    .rrs {
      .rrs__button {
        height: 45px;
      }

      .rrs__label {
        height: 3rem;
        margin: 0;
        padding: 0.9rem 3.6rem 0 1.6rem;
        line-height: 1.15rem;

        .svg-inline--fa {
          position: absolute;
          right: 1.6rem;
          top: calc(50% - 6px);
          color: ${vars.colour.greyLight};
          transition: all 200ms ease-out;
        }
      }
      .rrs__label__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
        color: ${vars.colour.black};
      }
      .rrs__options {
        top: 3rem;

        .rrs__option {
          padding: 1rem;
          font-weight: 300;
          min-height: 3.5rem;
        }
      }
    }
  }
`;
