import { get } from 'lodash';
import {
  GET_BENEFIT_TYPE_SCHEMAS,
  GET_USER_BENEFIT_TYPE_SCHEMAS,
} from './benefits.types';

export const initialState = {
  employer: {},
  users: {},
};

const applyGetBenefitTypeSchemasSucceeded = (state, { payload }) => ({
  ...state,
  employer: get(payload, 'info.benefit_type_schemas') || {},
});

const applyGetUserBenefitTypeSchemasSucceeded = (state, { payload }) => ({
  ...state,
  users: get(payload, 'info.user_benefit_type_schemas') || {},
});

export const benefitTypesSchemasReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case GET_BENEFIT_TYPE_SCHEMAS.SUCCEEDED:
      return applyGetBenefitTypeSchemasSucceeded(state, action);
    case GET_USER_BENEFIT_TYPE_SCHEMAS.SUCCEEDED:
      return applyGetUserBenefitTypeSchemasSucceeded(state, action);
    default:
      return state;
  }
};
