import { combineReducers } from 'redux';
import claims from 'apps/employer-experience/pages/Claims/state';
import claimDetails from 'apps/employer-experience/pages/claim-details';
import {
  claimSettlementReducer,
  claimSettlementUIReducer,
} from 'apps/employer-experience/pages/claim-details/settlement';
import { claimPaymentUIReducer } from 'apps/employer-experience/pages/claim-details/payment';
import { employerDetailsReducer } from 'apps/employer-experience/pages/EmployerDetails';
import { employeeDependentReducer } from 'apps/employer-experience/pages/EmployerDetails/Employee/components/employee-dependents/dependent/store/dependent.reducer';
import { feesReducer } from 'apps/employer-experience/pages/EmployerDetails/Billing/components/Fees/store/fees.reducer';
import { pendingFeesReducer } from 'apps/employer-experience-v2/employer-invoices/pending-fees/store/fees.reducer';

import {
  benefitPlanOptionsReducer as employerBenefitPlanOptions,
  enrollmentConfigReducer as enrollmentConfig,
} from 'apps/employer-experience/pages/EmployerDetails/enrollment-config/enrollment-config.reducer';
// eslint-disable-next-line import/extensions -- FIXME: automatically added for existing issue
import { enrollmentDesignReducer } from '../pages/EmployerDetails/enrollment-design/enrollment-experience-config/enrollment-design-options.reducer.ts';
import { employerBenefitBundlesByPlan } from './employer-bundles-by-plan/employer-bundles-by-plan.reducer';
import { employersReducer } from 'apps/employer-experience/pages/Employers';
import { employeesReducer } from 'apps/employer-experience/pages/EmployerDetails/Employees/reducer';
import { employeeBenefitsReducer } from 'apps/employer-experience-v2/employee-benefits/benefits.reducer';
import { employeeUpdateFlexDollarsReducer } from 'apps/employer-experience-v2/employee-benefits/update-flex-dollars/update-flex-dollars.reducer';
import { employeeBenefitDocumentsReducer } from 'apps/employer-experience-v2/employee-benefits/user-benefit-documents.reducer';
import { employerBenefitDocumentsReducer } from 'apps/employer-experience-v2/employer-benefits/employer-benefit-documents.reducer';
import { employeeCatalogueReducer } from 'apps/employer-experience-v2/employee-benefits/user-catalogue.reducer';
import { employeeClassesReducer } from 'apps/employer-experience-v2/employee-benefits/benefits-class.reducer';
import { employeeBenefitsUIReducer } from 'apps/employer-experience-v2/employee-benefits/ui.reducer';
import { employeeProfileUIReducer } from 'apps/employer-experience-v2/employee-profile/ui.reducer';
import { employerBenefitsUIReducer } from 'apps/employer-experience-v2/employer-benefits/ui.reducer';
import { benefitTypesSchemasReducer } from 'apps/employer-experience-v2/benefits/benefit-types-schemas.reducer';
import { employeeProfileReducer } from 'apps/employer-experience-v2/employee-profile/profile.reducer';
import { groupReducer } from 'apps/employer-experience-v2/group.reducer';
import { employerDivisionsUIReducer } from 'apps/employer-experience-v2/employer-divisions';
import {
  employerAdjustmentsReducer,
  employerAdjustmentsUIReducer,
} from 'apps/employer-experience-v2/employer-adjustments';
import {
  employerPayrollReportsReducer,
  employerSpendingAccountBalanceReportReducer,
  employerReportsUIReducer,
  payrollReducers,
  employerCarrierAuditsReportReducer,
  employerDependentsReportReducer,
} from 'apps/employer-experience-v2/employer-reports';
import {
  employerBenefitsReducer,
  employerBenefitPlansReducer,
} from 'apps/employer-experience-v2/employer-benefits';
import {
  employerInvoicesReducer,
  employerInvoicesUIReducer,
} from 'apps/employer-experience-v2/employer-invoices';
import {
  employeeSearchReducer,
  employeeSearchUIReducer,
} from 'apps/employer-experience-v2/employee-search';
import { editEmployeeUIReducer } from 'apps/employer-experience-v2/edit-employee';
import {
  employerProfileReducer,
  employerProfileUIReducer,
} from 'apps/employer-experience-v2/employer-profile';
import { SIGN_OUT } from 'apps/auth/auth.actions';
import { employerIntegrationsReducer } from 'apps/employer-experience/pages/EmployerDetails/employer-integrations/employer-integrations.reducer';
import { employerReportsReducer } from 'apps/employer-experience/pages/EmployerDetails/employer-reports/employer-reports.reducer';

import { editEmployerIntegrationsUIReducer } from 'apps/employer-experience/pages/EmployerDetails/employer-integrations/employer-integrations.ui.reducer';
import { changeAllocationReducer } from '../pages/change-allocation';
import { employeeAddFundsReducer } from '../pages/add-funds/add-funds.reducer';
import { employeeSpendingAccountsReducer } from '../employee-spending-accounts/employee-spending-accounts.reducer';
import { userDocumentsUIReducer } from 'common/components/user-documents/user-documents-ui.reducer';
import { userDocumentsResourcesReducer } from 'common/components/user-documents/user-documents-resources.reducer';

import { financeToolsReportsUIReducer } from '../pages/FinanceTools/finance-reports/finance-reports.reducer';
import { financeToolsInvoicingUIReducer } from '../pages/FinanceTools/finance-invoicing/finance-invoicing.reducer';

import { flagsReducer } from '../pages/flags/flags.reducer';

import { scriptHelpersReducer } from '../pages/ScriptHelpers/script-helpers.reducer';
import { walletToolingReducer } from '../pages/WalletTooling/wallet-tooling.reducer';
import { healthConciergeReducer } from '../pages/HealthConciergeTooling/health-concierge-tooling.reducer';
import { benefitPreviewReducer } from '../../member-experience/pages/wallet/benefit-preview';
import { qleConfigurationReducer } from '../pages/EmployerDetails/Benefits/QLEConfiguration/qle-configuration.reducer';
import { memberToolingReducer } from 'apps/employer-experience/pages/MemberTooling/member-tooling.reducer';
import { employerPricingScenarioReportReducer } from 'apps/employer-experience/pages/EmployerDetails/Payroll/pricing-config-qa-report';
import { careProviderAppointmentHistoryReducer } from 'apps/employer-experience/pages/care-provider-history/care-provider-appointment-history/care-provider-appointment-history.reducer';
import { walletTemplatesReducer } from '../pages/EmployerDetails/wallet-templates/wallet-templates.reducer';
import { userProfileReducer } from 'apps/employer-experience/resources/user-profile/user-profile.reducer';
import { userOptimumProfileReducer } from 'apps/employer-experience/resources/user-optimum-profile/user-optimum-profile.reducer';
import { mapleUserReducer } from 'apps/employer-experience/resources/maple-user/maple-user.reducer';
import { userVaccinationWorkflowReducer } from 'apps/employer-experience/resources/user-vaccination-workflow/user-vaccination-workflow.reducer';
import { dependentReducer } from 'apps/employer-experience-v2/employee-dependents/employee-dependents.reducer';
import { planEnrollmentReducer } from 'apps/employer-experience/pages/EmployerDetails/Employee/components/plan-enrollment/plan-enrollment.reducer';
import { employerInsightsReducer } from 'common/insight-dashboards/employer-insights.reducer';

const initialState = {
  name: 'employer-experience',
  type: 'private',
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SIGN_OUT.SUCCEEDED:
      return initialState;
    default:
      return state;
  }
};

const pages = combineReducers({
  claimDetails,
  claims,
  employees: employeesReducer,
  employerDetails: employerDetailsReducer,
  employers: employersReducer,
  qleConfiguration: qleConfigurationReducer, // Ideally this would be in `employerDetailsReducer` but that is a reducer aggregate the needs to be refactored before adding anything new
});

export default combineReducers({
  benefitPreview: benefitPreviewReducer,
  benefitTypesSchemas: benefitTypesSchemasReducer,
  careProviderAppointmentHistory: careProviderAppointmentHistoryReducer,
  claimSettlement: claimSettlementReducer,
  employeeAddFunds: employeeAddFundsReducer,
  employeeBenefitDocuments: employeeBenefitDocumentsReducer,
  employeeBenefits: employeeBenefitsReducer,
  employeeUpdateFlexDollars: employeeUpdateFlexDollarsReducer,
  employeeCatalogue: employeeCatalogueReducer,
  employeeClasses: employeeClassesReducer,
  employeeDependents: employeeDependentReducer,
  employeeProfile: employeeProfileReducer,
  employeeSearch: employeeSearchReducer,
  employeeSpendingAccounts: employeeSpendingAccountsReducer,
  employerAdjustments: employerAdjustmentsReducer,
  employerBenefitBundlesByPlan,
  employerBenefitDocuments: employerBenefitDocumentsReducer,
  employerBenefitPlanOptions,
  employerBenefitPlans: employerBenefitPlansReducer,
  employerBenefits: employerBenefitsReducer,
  employerIntegrations: employerIntegrationsReducer,
  employerInvoices: employerInvoicesReducer,
  employerPayrollReports: employerPayrollReportsReducer,
  employerPremiumsReport: payrollReducers,
  employerCarrierAuditsReport: employerCarrierAuditsReportReducer,
  employerDependentsReport: employerDependentsReportReducer,
  employerPricingScenarioReport: employerPricingScenarioReportReducer,
  employerProfile: employerProfileReducer,
  employerReports: employerReportsReducer,
  employerSpendingAccountBalanceReport:
    employerSpendingAccountBalanceReportReducer,
  enrollmentConfig,
  enrollmentExperienceConfig: enrollmentDesignReducer,
  featureFlags: flagsReducer,
  feesReducer,
  group: groupReducer,
  healthConciergeTooling: healthConciergeReducer,
  memberTooling: memberToolingReducer,
  info: reducer,
  pages,
  pendingFees: pendingFeesReducer,
  userPlanEnrollment: planEnrollmentReducer,
  scriptHelpers: scriptHelpersReducer,
  walletTemplates: walletTemplatesReducer,
  walletTooling: walletToolingReducer,
  ui: combineReducers({
    changeAllocation: changeAllocationReducer,
    claimPayment: claimPaymentUIReducer,
    claimSettlement: claimSettlementUIReducer,
    editEmployee: editEmployeeUIReducer,
    editEmployerIntegrations: editEmployerIntegrationsUIReducer,
    employeeBenefits: employeeBenefitsUIReducer,
    employeeProfile: employeeProfileUIReducer,
    employeeSearch: employeeSearchUIReducer,
    employerAdjustments: employerAdjustmentsUIReducer,
    employerBenefits: employerBenefitsUIReducer,
    employerDivisions: employerDivisionsUIReducer,
    employerInvoices: employerInvoicesUIReducer,
    employerProfile: employerProfileUIReducer,
    employerReports: employerReportsUIReducer,
    financeToolsInvoicing: financeToolsInvoicingUIReducer,
    financeToolsReports: financeToolsReportsUIReducer,
    userDocuments: userDocumentsUIReducer,
  }),
  userDocuments: userDocumentsResourcesReducer,
  userProfile: userProfileReducer,
  userOptimumProfile: userOptimumProfileReducer,
  mapleUser: mapleUserReducer,
  userVaccinationWorkflow: userVaccinationWorkflowReducer,
  dependents: dependentReducer,
  employerInsights: employerInsightsReducer,
});
