import { createRequestTypes } from 'common/websocket-redux';

export const GET_USER_PROFILE = createRequestTypes('user/GET_USER_PROFILE');
export const ON_GET_USER_PROFILE = 'user/ON_GET_USER_PROFILE';
export const ON_SET_NOTIFICATION_SETTING =
  'notificationSettings/ON_SET_USER_NOTIFICATION_SETTING';
export const SET_USER_NOTIFICATION_SETTING = createRequestTypes(
  'notificationSettings/SET_USER_NOTIFICATION_SETTING',
);

export const REQUEST_USER_CONFIG = createRequestTypes(
  'user/REQUEST_USER_CONFIG',
);
export const GET_USER_CONFIG = 'user/GET_USER_CONFIG';
