import { createRequestTypes } from 'common/websocket-redux';

export const GET_CLAIM_HISTORY = createRequestTypes(
  'service/GET_CLAIM_HISTORY',
);

export const GET_CLAIM_NOTES = createRequestTypes('service/GET_CLAIM_NOTES');

export const SET_CLAIM_NOTE = createRequestTypes('service/SET_CLAIM_NOTE');

export const CLAIM_NOTE_SUBMITTED = 'pages:claim-details/CLAIM_NOTE_SUBMITTED';
