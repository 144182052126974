import { useMemo } from 'react';
import { buildYup } from 'schema-to-yup';
import { useIntl } from '@leagueplatform/locales';

// Returns a flat array of the names of all fields that are required in the whole schema structure
export const flattenSchemaRequiredFields = ({ required, properties }) => {
  return [
    ...required,
    ...Object.values(properties)
      .filter(propSchema => propSchema?.properties)
      .reduce(
        (childReqPropArray, propSchema) => [
          ...childReqPropArray,
          ...flattenSchemaRequiredFields(propSchema),
        ],
        [],
      ),
  ];
};

/**
 * buildYup expects a custom error messages object in the format:
 * {
 *    fieldName: {
 *      required: 'error message'
 *    }
 * }
 */

export const buildErrorMessages = (
  schema,
  requiredErrorMessage,
  configErrMessages = {},
) => {
  const requiredFieldNames = flattenSchemaRequiredFields(schema);
  return requiredFieldNames.reduce(
    (acc, name) => ({
      ...acc,
      [name]: {
        ...configErrMessages[name],
        required: requiredErrorMessage,
      },
    }),
    configErrMessages,
  );
};

// There is a bug in schema-to-yup that prevents isDate from ever returning true and
// always types dates as "string". Passing this custom type definition solves that so
// that we can properly validate min and max dates

const typeDefConf = {
  getProps: entry => entry.properties,
  getType: entry => entry.type,
  getName: entry => entry.name || entry.title,
  getConstraints: entry => entry,
  isString: entry =>
    entry.type === 'string' && !typeDefConf.hasDateFormat(entry),
  isArray: entry => entry.type === 'array',
  isBoolean: entry => entry.type === 'boolean',
  isInteger: entry => entry.type === 'integer',
  hasDateFormat: entry => ['date', 'date-time'].includes(entry?.value?.format),
  isDate: entry => entry.type === 'string' && typeDefConf.hasDateFormat(entry),
  isNumber: entry => entry.type === 'number' || typeDefConf.isInteger(entry),
  isObject: entry => entry.type === 'object',
  isRequired: entry => entry.required,
};

export const useValidationSchema = ({ schema, config = {} }) => {
  const { formatMessage } = useIntl();
  const requiredFieldError = useMemo(
    () => formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' }),
    [formatMessage],
  );
  const errMessages = useMemo(
    () => ({
      ...buildErrorMessages(schema, requiredFieldError, config.errMessages),
    }),
    [schema, config.errMessages, requiredFieldError],
  );
  const validationSchema = useMemo(
    () =>
      buildYup(schema, {
        ...typeDefConf,
        ...config,
        errMessages,
      }),
    [schema, errMessages, config],
  );

  return validationSchema;
};
