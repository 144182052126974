import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  Box,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';

const TRANSITION_SPEED = '500ms';

const ViewBreakdownContainer = genesisStyled(Box)`
  position: relative;
`;

const Content = genesisStyled(Box)`
  overflow: hidden;
  display: none;
`;

export const AccordionTitle = genesisStyled(Flex)(
  css({
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      borderColor: 'interactive.focus.outer',
      outline: 'none',
    },
    '&[aria-expanded="true"]': {
      '> svg': {
        transform: 'rotate(-90deg)',
      },
      '+ div': {
        display: 'block',
      },
    },
  }),
);

export const Chevron = genesisStyled(Icon)`
  fill: currentColor;
  transition: transform ${TRANSITION_SPEED} ease;
  transform: rotate(90deg);
`;

export const ViewBreakdown = ({ children, initiallyCollapsed }) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(!initiallyCollapsed);

  return (
    <ViewBreakdownContainer mt={2}>
      <AccordionTitle
        onClick={() => setIsExpanded(!isExpanded)}
        marginBottom={isExpanded ? 'half' : 0}
        alignItems="center"
        color="decorative.brand.primary.default"
        backgroundColor="surface.background.primary"
        borderStyle="solid"
        borderColor="transparent"
        as="button"
        aria-expanded={isExpanded}
      >
        <Text marginRight="half" fontWeight="medium">
          {formatMessage({ id: 'VIEW_BREAKDOWN' })}
        </Text>
        <Chevron icon={ICONS.CHEVRON_RIGHT} size={20} aria-hidden />
      </AccordionTitle>
      <Content>{children}</Content>
    </ViewBreakdownContainer>
  );
};

ViewBreakdown.propTypes = {
  initiallyCollapsed: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ViewBreakdown.defaultProps = {
  initiallyCollapsed: false,
};
