import React from 'react';
import PropTypes from 'prop-types';

const Hidden = ({ name, value }) => (
  <input type="hidden" name={name} value={value} />
);

Hidden.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Hidden.defaultProps = {
  value: undefined,
};

export default Hidden;
