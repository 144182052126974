/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { has } from 'lodash';
import { flow, isEmpty, negate } from 'lodash/fp';
import { createSelector } from 'reselect';
import {
  getPeriodTypeForBenefit,
  PERIOD_TYPES,
  CONTRIBUTOR_TYPES,
  EMPLOYEE_CONTRIBUTION_TYPES,
  PERIOD_TYPE_TRANSLATION_IDS,
} from 'common/benefits/metadata';
import {
  isContributionSet,
  getEnrollmentSteps,
  getGenericEnrollmentSteps,
  normalizeBenefitSetFromSteps,
  EAP_SLUG,
  BENEFIT_TYPES,
  getGESteps,
} from 'common/benefit-sets';
import { getCoverageTypeTranslationId } from 'common/dependents/dependents-metadata';
import { selectUser } from 'common/state/user/user.selectors';
import {
  selectBenefitPlanList,
  selectActiveBenefitPlan,
} from 'common/benefit-plans';
import { getActiveEmployerGroup } from '@leagueplatform/web-common';
import { getStepIndex } from 'common/benefit-sets/enrollment-steps';
import { selectIsKPMG } from 'apps/enrollment/enrollment-selectors/group.selectors';
import { normalizeBenefitSetFromStepsWithContent } from 'common/benefit-sets/get-benefit-sets.service';

const money = ({ C, F = 0, M = F * 100 } = {}) => ({ C, F, M });

const zeroDollars = ({ groupCurrency }) => money({ C: groupCurrency });

const hasMoney = amount => (amount?.F ?? 0) !== 0;

const benefitIsWaived = ({ opt_out: waived } = {}) => waived === true;

const benefitIsUnselected = ({ opt_out: waived } = {}) => waived === null;

const isEOIRequired = benefitSummary =>
  Boolean(benefitSummary.requires_evidence_of_insurability);

const isEOIExpired = benefitSummary =>
  Boolean(benefitSummary.evidence_of_insurability_expired);

// Dang special case for monthly employee contribution.
// For details see https://everlong.atlassian.net/browse/ON-3448
const getEmployeeMonthlyCost = amounts => {
  const employeeMonthlyCost = (amounts || []).find(
    ({ contributor_type: type, contribution_period: period }) =>
      type === CONTRIBUTOR_TYPES.EMPLOYEE && period === PERIOD_TYPES.MONTH,
  );
  return employeeMonthlyCost?.amount;
};

export const getCost = ({
  cost,
  pay_period_cost: payPeriodCost,
  showCostsPerPayPeriod = false,
  amounts,
}) => {
  const source = showCostsPerPayPeriod ? payPeriodCost : cost;
  const employeeMonthlyCost = getEmployeeMonthlyCost(amounts);
  if (employeeMonthlyCost) return employeeMonthlyCost;
  const flexDollars = source?.flex_dollar;
  if (hasMoney(flexDollars)) return flexDollars;

  return source?.payroll_deduction;
};

// return the approved cost of the benefit
const getApprovedCost = ({
  cost,
  pay_period_cost: payPeriodCost,
  showCostsPerPayPeriod = false,
}) => {
  const source = showCostsPerPayPeriod ? payPeriodCost : cost;
  return source?.approved_cost;
};

const identAmountIntlMessage = amount => [
  { id: 'IDENT_AMOUNT' },
  { amount: amount || ' ' },
];

const identValueIntlMessage = value => [
  { id: 'IDENT_VALUE' },
  { value: value || ' ' },
];

const amountIntlMessage = amount => identValueIntlMessage(amount);

// if benefit requires evidence of insurability (EOI)
// then add approvedCoverageMessage and approvedCostMessage
const getApprovedCoverageAndCost = benefitSummary => ({
  approvedCoverageMessage: amountIntlMessage(
    benefitSummary?.approved_coverage_amount ?? zeroDollars(benefitSummary),
  ),
  approvedCostMessage: amountIntlMessage(
    getApprovedCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

const getBenefitCostMessageWithEOI = benefitSummary => {
  if (isEOIExpired(benefitSummary)) {
    return amountIntlMessage(
      getApprovedCost(benefitSummary) || zeroDollars(benefitSummary),
    );
  }

  return amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  );
};

const getBenefitCoverageMessageWithEOI = benefitSummary => {
  if (isEOIExpired(benefitSummary)) {
    return identAmountIntlMessage(
      benefitSummary?.approved_coverage_amount ?? zeroDollars(benefitSummary),
    );
  }

  return identValueIntlMessage(
    benefitSummary?.selection?.coverage_volume_option?.selection_option,
  );
};

/* IMPORTANT NOTES:
  - coverageMessage: the coverage amount of a volume benefit, e.g $10,000 coverage
  - selectionMessage: the Plan Selection of a benefit, e.g Basic, Core Dental
  - dependentCoverageId: the dependent coverage of a benefit, e.g Myself and Spouse
  - costMessage: the total cost of a benefit, or the amount that will be allocated to the account
  - approvedCoverageMessage: the approved coverage amount for the benefit that does not require EOI
  - approvedCostMessage: the cost of the approved coverage amount

  For contribution benefit:
  - employeeAllocation: the total amount that will be allocated to the benefit by employee
  - employerAllocation: the total amount that will be allocated to the benefit by employer
  - totalAllocation: the sum of employee and employer allocation,
    or the total cost of the benefit (if still using deprecated amount)

  For more details, see use-formatted-coverage-summary-messages.hook.js and the component selection layer
  to see how these messages are being used
*/

// benefit that's not volume, dependent or contribution, e.g EAP
// by default should have selectionMessage
const mapBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  selectionMessage: identValueIntlMessage(benefitSummary?.benefit_name),
  costMessage: getBenefitCostMessageWithEOI(benefitSummary),
});

/* Disablity benefit such as STD and LTD
   coverageMessage: the coverage amount per period
   coveragePeriodTypeId: the translation id of the coverage period
      STD: $<amount> / Week
      LTD: $<amount> / Month
*/
const mapDisabilityBenefit = benefitSummary => {
  const volumeAmount =
    benefitSummary?.coverage_volume_per_period?.volume_amount ?? null;
  const periodTypeId =
    PERIOD_TYPE_TRANSLATION_IDS?.[
      benefitSummary?.coverage_volume_per_period?.period
    ] ?? null;
  const shouldShowCoverageVolume =
    volumeAmount?.F > 1 && !benefitSummary?.hideDisabilityVolume;

  return {
    ...benefitSummary,
    id: benefitSummary?.benefit_id,
    coverageMessage: shouldShowCoverageVolume
      ? identAmountIntlMessage(volumeAmount)
      : null,
    coveragePeriodTypeId: shouldShowCoverageVolume ? periodTypeId : null,
    selectionMessage: identValueIntlMessage(benefitSummary?.benefit_name),
    costMessage: getBenefitCostMessageWithEOI(benefitSummary),
    ...(isEOIRequired(benefitSummary) &&
      getApprovedCoverageAndCost(benefitSummary)),
  };
};

// benefit with waived selection
/*
  volume (w and w/o dependent coverage): coverageMessage
  non volume: selectionMessage
*/
const mapWaivedBenefit = ({ hasVolumeCoverage, benefitSummary }) => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: hasVolumeCoverage ? [{ id: 'WAIVED' }] : null,
  selectionMessage: hasVolumeCoverage ? null : [{ id: 'WAIVED' }],
  costMessage: amountIntlMessage(zeroDollars(benefitSummary)),
});

// benefit with unselected selection
/*
  volume (w and w/o dependent coverage): coverageMessage
  non volume: selectionMessage
*/
const mapUnselectedBenefit = ({ hasVolumeCoverage, benefitSummary }) => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: hasVolumeCoverage ? [{ id: 'NO_SELECTION_MADE' }] : null,
  selectionMessage: hasVolumeCoverage ? null : [{ id: 'NO_SELECTION_MADE' }],
  costMessage: amountIntlMessage(zeroDollars(benefitSummary)),
});

// return the sum amount in the amounts array
export const getSumAmounts = amounts =>
  amounts.reduce((sum, amount) => {
    const proratedAmount = amount?.proration?.prorated_amount;
    const value = proratedAmount ? proratedAmount?.F : amount?.amount?.F;
    return sum + value;
  }, 0);

// Return the amounts to be allocated per each contributor type
// by default, set employee and employer allocation to be null
const getContributionSelection = benefitSummary => {
  const { amounts, groupCurrency } = benefitSummary;

  let employeeAllocation = amountIntlMessage(zeroDollars({ groupCurrency }));
  let employerAllocation = amountIntlMessage(zeroDollars({ groupCurrency }));
  let totalAmount =
    benefitSummary?.cost?.total_cost ?? zeroDollars({ groupCurrency });

  if (Array.isArray(amounts)) {
    const employeeAmounts = amounts?.filter(amount =>
      EMPLOYEE_CONTRIBUTION_TYPES.includes(amount?.contributor_type),
    );
    const employerAmounts = amounts?.filter(
      amount => amount?.contributor_type === CONTRIBUTOR_TYPES.EMPLOYER,
    );

    const employeeSumAmount = getSumAmounts(employeeAmounts);
    const employerSumAmount = getSumAmounts(employerAmounts);
    const totalSumAmount = employeeSumAmount + employerSumAmount;

    // we want to show employeeAllocation or employerAllocation only when
    // they are configured, so they are defined in the amounts array
    employeeAllocation =
      employeeAmounts.length > 0
        ? amountIntlMessage(money({ C: groupCurrency, F: employeeSumAmount }))
        : null;
    employerAllocation =
      employerAmounts.length > 0
        ? amountIntlMessage(money({ C: groupCurrency, F: employerSumAmount }))
        : null;
    totalAmount = money({ C: groupCurrency, F: totalSumAmount });
  }

  return {
    employeeAllocation,
    employerAllocation,
    totalAllocation: amountIntlMessage(totalAmount),
  };
};

// Tax Advantanged Account benefit
// should have employeeAllocation, employerAllocation defined
// in here, selectionMessage, coverageMessage and dependentCoverageId should be undefined
const mapTaxAdvantagedAccountBenefit = benefitSummary => {
  const { employeeAllocation, employerAllocation } =
    getContributionSelection(benefitSummary);

  return {
    ...benefitSummary,
    id: benefitSummary?.benefit_id,
    employeeAllocation,
    employerAllocation,
    totalAllocation: null,
    costMessage: amountIntlMessage(
      getCost({ ...benefitSummary, showCostsPerPayPeriod: false }) ||
        zeroDollars(benefitSummary),
    ),
  };
};

// Spending Account benefit
// should have only totalAllocation defined, and employee/employer allocation null
// in here, selectionMessage, coverageMessage and dependentCoverageId should be undefined
const mapSpendingAccountBenefit = benefitSummary => {
  const { totalAllocation } = getContributionSelection(benefitSummary);

  return {
    ...benefitSummary,
    id: benefitSummary?.benefit_id,
    employeeAllocation: null,
    employerAllocation: null,
    totalAllocation,
    costMessage: amountIntlMessage(
      getCost({ ...benefitSummary, showCostsPerPayPeriod: false }) ||
        zeroDollars(benefitSummary),
    ),
  };
};

// Volume benefit, with dependent coverage
// should have coverageMessage and dependentCoverageId
const mapVolumeAndDependentBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  dependentCoverageId:
    getCoverageTypeTranslationId(
      benefitSummary?.selection?.dependent_coverage?.value,
    ) ?? 'NO_DEPENDENT_SELECTION',
  coverageMessage: getBenefitCoverageMessageWithEOI(benefitSummary),
  selectionMessage: null,
  costMessage: getBenefitCostMessageWithEOI(benefitSummary),
  ...(isEOIRequired(benefitSummary) &&
    getApprovedCoverageAndCost(benefitSummary)),
});

// Volume benefit (no dependent coverage)
// should only have coverageMessage
const mapVolumeBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: getBenefitCoverageMessageWithEOI(benefitSummary),
  selectionMessage: null,
  costMessage: getBenefitCostMessageWithEOI(benefitSummary),
  ...(isEOIRequired(benefitSummary) &&
    getApprovedCoverageAndCost(benefitSummary)),
});

// Insured benefit with dependent coverage
// should only have selectionMessage
const mapDependentBenefit = benefitSummary => ({
  ...benefitSummary,
  id: benefitSummary?.benefit_id,
  coverageMessage: null,
  dependentCoverageId:
    getCoverageTypeTranslationId(
      benefitSummary?.selection?.dependent_coverage?.value,
    ) ?? 'NO_DEPENDENT_SELECTION',
  selectionMessage: identValueIntlMessage(benefitSummary?.benefit_name),
  costMessage: amountIntlMessage(
    getCost(benefitSummary) || zeroDollars(benefitSummary),
  ),
});

// Try to map the benefit summary according to its own type
// to get the selection/coverage/cost message
export const mapBenefitSummaryToSelection = benefitSummary => {
  const hasDependentCoverage = has(
    benefitSummary,
    'selection.dependent_coverage',
  );
  const hasVolumeCoverage = has(
    benefitSummary,
    'selection.coverage_volume_option.selection_option',
  );

  const isTaxAdvantagedAcccountBenefit =
    benefitSummary.setTypeId === BENEFIT_TYPES.contributions;
  const isSpendingAccountBenefit =
    benefitSummary.setTypeId === BENEFIT_TYPES.spending_accounts;

  const isDisabilityBenefit =
    benefitSummary.setTypeId?.includes(BENEFIT_TYPES.std) ||
    benefitSummary.setTypeId?.includes(BENEFIT_TYPES.ltd);

  if (isTaxAdvantagedAcccountBenefit) {
    return mapTaxAdvantagedAccountBenefit(benefitSummary);
  }
  if (isSpendingAccountBenefit) {
    return mapSpendingAccountBenefit(benefitSummary);
  }
  if (benefitIsWaived(benefitSummary)) {
    return mapWaivedBenefit({
      hasVolumeCoverage,
      benefitSummary,
    });
  }
  if (benefitIsUnselected(benefitSummary)) {
    return mapUnselectedBenefit({
      hasVolumeCoverage,
      benefitSummary,
    });
  }
  if (isDisabilityBenefit) {
    return mapDisabilityBenefit(benefitSummary);
  }
  if (hasVolumeCoverage && hasDependentCoverage) {
    return mapVolumeAndDependentBenefit(benefitSummary);
  }
  if (hasVolumeCoverage) {
    return mapVolumeBenefit(benefitSummary);
  }
  if (hasDependentCoverage) {
    return mapDependentBenefit(benefitSummary);
  }
  return mapBenefit(benefitSummary);
};

// filter out waived benefits from the benefits list
/* Example
  1. if Dental Core is selected instead of Buy-Up, filter out Buy-Up from the benefits list in the set
  2. if all of them are waived, then put one benefit in the list with benefit_name = null
*/
const removeWaivedBenefits = set => {
  const benefits = set?.benefits ?? [];
  // if the set is contributions, it we don't want to leave behind a "waived" benefit for a set with all benefits waived.
  if (isContributionSet(set.typeId))
    return {
      ...set,
      benefits: benefits.filter(negate(benefitIsWaived)),
    };
  // if there is one or fewer benefits just return them all
  if (benefits.length <= 1) return set;
  // else ...
  return {
    ...set,
    // if every benefit is waived, return the first benefit so we have
    // something to show as the waived step, but blank out the name
    benefits: benefits.every(benefitIsWaived)
      ? [{ ...benefits[0], benefit_name: null }]
      : // otherwise filter down to only the opted in benefits (those that are not waived!)
        benefits.filter(negate(benefitIsWaived)),
  };
};

// filter out unselected benefits from the benefits list
/* Example
  1. if Dental Core is selected instead of Buy-Up, filter out Buy-Up from the benefits list in the set
  2. if all of them are unselected, then put one benefit with opt_out = null and benefit_name = null
     in the benefits list
*/
const removeUnselectedBenefits = set => {
  const benefits = set?.benefits ?? [];
  // if there is one or fewer benefits,
  // or if it's a contribution set
  // just return them all,
  if (benefits.length <= 1 || isContributionSet(set.typeId)) return set;

  return {
    ...set,
    // when all benefits in a set are unselected;
    // map the first benefit as the sole, unselected benefit in the set.
    benefits: benefits.every(benefitIsUnselected)
      ? [{ ...benefits[0], benefit_name: null, opt_out: null }]
      : // otherwise remove unselected benefits
        benefits.filter(negate(benefitIsUnselected)),
  };
};

// selections should be free from waived and unselected benefits
export const flattenStepBenefitSets = sets => {
  const selections = [];
  const addTypeIdToBenefits = set => ({
    ...set,
    benefits: set.benefits.map(benefit => ({
      ...benefit,
      setId: set.id,
      setTypeId: set.typeId,
    })),
  });
  (sets || []).forEach(
    flow(
      removeWaivedBenefits,
      removeUnselectedBenefits,
      addTypeIdToBenefits,
      set => set.benefits && selections.push(...set.benefits),
    ),
  );
  return selections;
};

// period type for per pay period cost
const addPeriodType = ({ product_type, payGroupPeriodTypeId, ...rest }) => ({
  ...rest,
  product_type,
  payGroupPeriodTypeId,
  periodType: getPeriodTypeForBenefit({ product_type, payGroupPeriodTypeId }),
});

// the main flow with filters to convert the summary to have some important
// message ids and objects to be used in the coverage summary component
export const mapStepToViewModel =
  ({
    groupCurrency = 'USD',
    showCostsPerPayPeriod = false,
    payGroupPeriodTypeId,
    hideDisabilityVolume,
  } = {}) =>
  step => {
    const addProps = bennie => {
      return {
        ...bennie,
        groupCurrency,
        showCostsPerPayPeriod,
        payGroupPeriodTypeId,
        hideDisabilityVolume,
      };
    };

    const selections = flattenStepBenefitSets(step.benefitSets).map(
      flow(addProps, addPeriodType, mapBenefitSummaryToSelection),
    );
    return { ...step, selections };
  };

const makeStep = set => ({
  ...set,
  benefitSets: [set],
});

const stepHasSelections = step => step?.selections?.length > 0;

export const benefitSetSingleBenefitHasPayrollDeduction = set =>
  (set?.benefits?.[0]?.cost?.payroll_deduction?.F ?? 0) > 0;

function getGenericEnrollmentStepIndex(steps, item) {
  return steps.findIndex(
    element =>
      // If generic step, then ID's will match
      element.id === item.id ||
      // If legacy step, we need to match url to the id
      element.url === item.id,
  );
}

// @TODO - remove this after GE and consume only mapSummarySetsToStepsGE
// this is the entry point selector to generate data for the coverage summary
// and what should included in each experience coverage summary selector
export const mapSummarySetsToSteps = (
  sets,
  groupCurrency,
  showCostsPerPayPeriod,
  payGroupPeriodTypeId,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  summarySteps = [],
  hideDisabilityVolume,
) => {
  const normalizedSets = Object.values(sets || {}).map(
    normalizeBenefitSetFromSteps(summarySteps),
  );
  // EAP is not shown on enrollment step nav but is shown in coverage summary if the payroll deduction is greater than $0
  const eapSet = normalizedSets.find(({ typeId }) => typeId === EAP_SLUG);

  const genericEnrollmentSteps = getGenericEnrollmentSteps(
    normalizedSets,
    [],
    summarySteps,
  );

  const isGenericEnrollment = !isEmpty(genericEnrollmentSteps);

  const steps = {
    ...getEnrollmentSteps(normalizedSets),
    ...genericEnrollmentSteps,
    ...(benefitSetSingleBenefitHasPayrollDeduction(eapSet) && {
      [EAP_SLUG]: makeStep(eapSet),
    }),
  };

  return Object.values(steps)
    .map(
      mapStepToViewModel({
        groupCurrency,
        showCostsPerPayPeriod,
        payGroupPeriodTypeId,
        hideDisabilityVolume,
      }),
    )
    .filter(stepHasSelections)
    .sort((a, b) => {
      // To maintain order from get_coverage_summary "steps" array when there is a basic step in get_coverage_summary "steps" array
      if (isGenericEnrollment) {
        const firstItemIndex = getGenericEnrollmentStepIndex(summarySteps, a);
        const secondItemIndex = getGenericEnrollmentStepIndex(summarySteps, b);
        // This will append any step not in get_coverage_summary "steps" array at the end of the steps list
        if (firstItemIndex > -1 && secondItemIndex > -1)
          return secondItemIndex > firstItemIndex ? -1 : 1;
        return firstItemIndex === -1 ? 1 : -1;
      }

      // To maintain order from enrollmentSteps
      const firstItemStepIndex = getStepIndex(a.id);
      const secondItemStepIndex = getStepIndex(b.id);
      if (firstItemStepIndex > secondItemStepIndex) return 1;
      if (firstItemStepIndex < secondItemStepIndex) return -1;
      return 0;
    });
};

export const mapSummarySetsToStepsGE = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  sets = [],
  groupCurrency,
  showCostsPerPayPeriod,
  payGroupPeriodTypeId,
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  summarySteps = [],
  hideDisabilityVolume,
) => {
  const normalizedSets = sets.map(
    normalizeBenefitSetFromStepsWithContent(summarySteps),
  );
  // EAP is not shown on enrollment step nav but is shown in coverage summary if the payroll deduction is greater than $0
  const eapSet = normalizedSets.find(({ typeId }) => typeId === EAP_SLUG);

  const genericEnrollmentSteps = getGESteps(normalizedSets, [], summarySteps);

  const steps = {
    ...genericEnrollmentSteps,
    ...(benefitSetSingleBenefitHasPayrollDeduction(eapSet) && {
      [EAP_SLUG]: makeStep(eapSet),
    }),
  };

  return Object.values(steps)
    .map(
      mapStepToViewModel({
        groupCurrency,
        showCostsPerPayPeriod,
        payGroupPeriodTypeId,
        hideDisabilityVolume,
      }),
    )
    .filter(stepHasSelections);
};

const selectSummaries = state => state?.summaries;

export const selectSummariesByPlanId = createSelector(
  selectSummaries,
  summaries => summaries?.byId,
);

export const selectCurrentPlanId = createSelector(
  selectSummaries,
  summaries => summaries?.currentPlanId,
);

export const selectCoverageSummaryByPlanId = createSelector(
  selectCurrentPlanId,
  selectSummariesByPlanId,
  (planId, byId) => byId?.[planId],
);

export const selectSummaryBenefitSets = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.benefitSets ?? [],
);

export const selectSummaryBenefitSteps = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.steps ?? [],
);

export const selectCost = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.cost,
);

export const selectTotalCost = createSelector(
  selectCost,
  cost => cost?.totalCost,
);

export const selectTotalFlex = createSelector(
  selectCost,
  cost => cost?.totalFlex,
);

export const selectTotalPay = createSelector(
  selectCost,
  cost => cost?.totalPay,
);

export const selectApprovedTotalPay = createSelector(
  selectCost,
  cost => cost?.approvedTotalPay,
);

export const selectEmployerContribution = createSelector(
  selectCoverageSummaryByPlanId,
  cost => cost?.employerContribution,
);

export const selectPayPeriodTotalCost = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.payPeriodTotalCost ?? {},
);

export const selectAllocatedFlex = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.allocatedFlex ?? {},
);

export const selectEmployerPaysAllCosts = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.employerPaysAllCosts ?? false,
);

export const selectShowCostsPerPayPeriod = createSelector(
  state => state?.resources?.showCostsPerPayPeriod,
  selectCoverageSummaryByPlanId,
  (resourcesShowCostsPerPayPeriod, summary) =>
    resourcesShowCostsPerPayPeriod ?? summary?.showCostsPerPayPeriod ?? false,
);

export const selectShowEmployerCosts = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.showEmployerCosts ?? false,
);

export const selectIsCoverageSummaryLoaded = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.isLoaded ?? false,
);

export const selectInitialBenefitPlanId = createSelector(
  selectBenefitPlanList,
  selectActiveBenefitPlan,
  (plans, activePlan) => (activePlan ?? plans[0])?.id,
);

export const selectBenefitSetsWithEOIExpired = createSelector(
  selectSummaryBenefitSets,
  benefitSets => {
    return benefitSets?.filter(benefitSet =>
      benefitSet.benefits?.some(
        ({ evidence_of_insurability_expired }) =>
          evidence_of_insurability_expired === true,
      ),
    );
  },
);

export const selectTotalOutOfPocketPay = createSelector(
  selectTotalPay,
  selectApprovedTotalPay,
  selectBenefitSetsWithEOIExpired,
  (totalPay, approvedTotalPay, benefitSets) => {
    return benefitSets?.length > 0 ? approvedTotalPay : totalPay;
  },
);

export const selectCurrentUserBenefitClassId = createSelector(
  selectUser,
  user => {
    const groups = user?.groups ?? [];
    return getActiveEmployerGroup(groups)?.benefitClassId;
  },
);

export const selectHideDisabilityVolume = createSelector(
  selectIsKPMG,
  isKPMG => isKPMG,
);
