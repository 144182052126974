import { withInitializer } from './initializer.view';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
  selectEmployees,
  selectHasMoreResults,
  selectHasEmptyResults,
  selectLoading,
} from './search.selectors';
import {
  selectEmployerHasSpendingAccounts,
  selectEmployerHasTopupableSpendingAccounts,
} from './v1';
import {
  onSearchParamsChanged,
  nextSearchPageRequested,
  onBulkUploadSelected,
  onEmployeeRowSelected,
} from './search.actions';
import Page from './page.view';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';

const dispatchers = {
  onBulkUploadSelected,
  onSearchParamsChanged,
  onEndOfListReached: nextSearchPageRequested,
  onEmployeeRowSelected,
};

const withState = connect(
  createStructuredSelector({
    employees: selectEmployees,
    hasMoreResults: selectHasMoreResults,
    hasEmptyResults: selectHasEmptyResults,
    hasSpendingAccounts: selectEmployerHasSpendingAccounts,
    hasTopupableSpendingAccounts: selectEmployerHasTopupableSpendingAccounts,
    isAdmin: selectUserIsAdmin,
    loading: selectLoading,
  }),
  dispatchers,
);

export default compose(withInitializer, withState)(Page);
