export const updateDependentsList = (dependentList, dependentToUpdate) => {
  const dependentToUpdateWithValidation = dependentToUpdate;
  dependentToUpdateWithValidation.skip_validation = false;
  const list = dependentList.map(dependent => {
    if (dependent.dependent_id === dependentToUpdate.dependent_id) {
      return dependentToUpdateWithValidation;
    }
    const skipValidationDependent = dependent;
    skipValidationDependent.skip_validation = true;
    return skipValidationDependent;
  });

  if (!dependentToUpdate.dependent_id) {
    list.push(dependentToUpdateWithValidation);
  }

  return list;
};
