const REQUIRED = 'required';
const RECOMMENDED = 'recommended';

export const INSURANCE_FORM_STATUS_TYPES = {
  REQUIRED,
  RECOMMENDED,
};

const EOI = 'eoi';
const BENEFICIARY = 'beneficiary';

export const INSURANCE_FORM_TYPES = {
  EOI,
  BENEFICIARY,
};

export const UPLOAD_DOCUMENTS_LINK = {
  CA: 's/article/How-do-I-add-documents-to-my-account-1570651100077',
  US: 's/article/How-do-I-add-documents-to-my-account',
  KPMG: 's/article/360001384546',
};

// Analytics
export const ACTION_DOWNLOAD_EOI_FORM = 'download eoi form';
export const ACTION_DOWNLOAD_BENEFICIARIES_FORM = 'download beneficiaries form';
export const ACTION_DOWNLOAD_DIGITAL_BENEFICIARY_FORM =
  'download digital beneficiary form';
