import { useFetch } from 'common/hooks/use-fetch/use-fetch.hook';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const useExportBenefitPlanOptions = ({ planId }) => {
  const { fetch, loading, error } = useFetch();

  const downloadExport = async () => {
    const response = await fetch({
      messageType: 'export_benefit_plan_options',
      info: {
        plan_id: planId,
      },
    });
    const contentId = response?.content_id;
    if (contentId) {
      const url = getContentUrl(contentId);
      window.open(url, '_blank', 'noopener=yes, noreferrer=yes');
    }
  };

  return {
    downloadExport,
    loading,
    error,
  };
};
