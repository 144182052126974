import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Button, Segment, Container, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import {
  selectUploadedEmployeeErrors,
  selectErroredEmployees,
  selectSuccessfullyAddedEmployeesCount,
} from './selectors';
import Page from './Page';

const withState = connect(state => ({
  errors: selectUploadedEmployeeErrors(state),
  successfullyAddedEmployeesCount: selectSuccessfullyAddedEmployeesCount(state),
  erroredEmployees: selectErroredEmployees(state),
}));

const SaveImport = ({
  errors,
  stepBackward,
  erroredEmployees,
  successfullyAddedEmployeesCount,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Page {...rest}>
    <Segment attached padded="very">
      <Container>
        {errors.length ? (
          <Message error>
            <Message.Header>
              <FormattedMessage id="BULK_UPLOAD_ERROR_HEADER" />
            </Message.Header>
            <Message.Content>
              <FormattedMessage
                id="BULK_UPLOAD_ERROR_COPY"
                values={{
                  erroredEmployeesCount: erroredEmployees.length,
                  errorsCount: errors.length,
                }}
              />
            </Message.Content>
            <Message.List items={errors} />
          </Message>
        ) : null}
        {successfullyAddedEmployeesCount ? (
          <Message success>
            <Message.Header>
              <FormattedMessage id="BULK_UPLOAD_SUCCESS_HEADER" />
            </Message.Header>
            <Message.Content>
              <FormattedMessage
                id="BULK_UPLOAD_SUCCESS_COPY"
                values={{ successfullyAddedEmployeesCount }}
              />
            </Message.Content>
          </Message>
        ) : null}
      </Container>
    </Segment>
    <Segment attached padded clearing>
      <Button floated="left" onClick={() => stepBackward(1)}>
        <FormattedMessage id="BULK_UPLOAD_BACK_BTN_LABEL" />
      </Button>
      <Button floated="right" color="violet">
        <FormattedMessage id="BULK_UPLOAD_FINISH_BTN_LABEL" />
      </Button>
    </Segment>
  </Page>
);

SaveImport.propTypes = {
  stepBackward: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  erroredEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
    }),
  ).isRequired,
  successfullyAddedEmployeesCount: PropTypes.number.isRequired,
};

export default compose(withState)(SaveImport);
