import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ActionsConfirmationDateFormContainer } from './actions-confirmation-date-form.container';
import { ActionsConfirmationNoticeDateFormContainer } from './actions-confirmation-notice-date-form.container';
import { ActionsConfirmationEOBDateFormContainer } from './actions-confirmation-eob-date-form.container';
import ActionsConfirmationTerminationCodeDropdownContainer from './actions-confirmation-termination-code-dropdown.container';
import { EDIT_ADMIN_ROLE_TEXT, FORM_TYPES } from '../../constants';
import { Modal, ModalButtons } from 'apps/employer-experience/ui-kit';
import { colour } from '@leagueplatform/ui-kit';
import {
  Box,
  HeadingTwo,
  PrimaryButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { noop } from 'lodash';

const OL = styled.ol`
  margin-top: 16px;
  padding-left: 32px;
  list-style: numeric;
`;

const Link = styled.a`
  color: ${colour('purple')};
`;

const getHeaderText = (formType, name) => {
  switch (formType) {
    case FORM_TYPES.suspendConfirmation:
      return (
        <FormattedMessage
          id="SUSPENSION_CONFIRMATION_HEADER"
          values={{ name }}
        />
      );
    case FORM_TYPES.terminateConfirmation:
      return (
        <FormattedMessage
          id="TERMINATION_CONFIRMATION_HEADER"
          values={{ name }}
        />
      );
    case FORM_TYPES.reinstateConfirmation:
      return (
        <FormattedMessage
          id="REINSTATE_CONFIRMATION_HEADER"
          values={{ name }}
        />
      );
    case FORM_TYPES.addAdminRole:
      return <FormattedMessage id="ADD_ADMIN_ROLE_HEADER" values={{ name }} />;
    case FORM_TYPES.removeAdminRole:
      return (
        <FormattedMessage id="REMOVE_ADMIN_ROLE_HEADER" values={{ name }} />
      );
    case FORM_TYPES.unscheduleSuspend:
      return <FormattedMessage id="UNSCHEDULE_SUSPENSION_HEADER" />;
    case FORM_TYPES.unscheduleTerminate:
      return <FormattedMessage id="UNSCHEDULE_TERMINATION_HEADER" />;
    case FORM_TYPES.unscheduleReinstate:
      return <FormattedMessage id="UNSCHEDULE_REINSTATE_HEADER" />;
    case FORM_TYPES.copyInviteUrl:
      return <FormattedMessage id="COPY_INVITE_URL_CONFIRMATION_HEADER" />;
    default:
      return null;
  }
};

const getDatePickerForm = (
  formType,
  setActionEffectiveDate,
  setActionInvalidDate,
  isAdmin,
  dateIsEmpty,
  displayInline = false,
) => {
  return (
    <ActionsConfirmationDateFormContainer
      setActionInvalidDate={setActionInvalidDate}
      setActionEffectiveDate={setActionEffectiveDate}
      formType={formType}
      isAdmin={isAdmin}
      dateIsEmpty={dateIsEmpty}
      displayInline={displayInline}
    />
  );
};

const getContent = (
  formType,
  name,
  setActionEffectiveDate,
  setActionInvalidDate,
  setReasonForTermination,
  isAdmin,
  dateIsEmpty,
  url,
  reasonIsEmpty,
  setActionNoticeDate,
  setActionEOBDate,
) => {
  switch (formType) {
    case FORM_TYPES.suspendConfirmation:
      return (
        <Box>
          <Box marginBottom="two">
            <FormattedMessage
              id="SUSPENSION_CONFIRMATION_DESCRIPTION"
              values={{ name }}
            />
          </Box>
          <Form>
            <Form.Group widths="two">
              {getDatePickerForm(
                formType,
                setActionEffectiveDate,
                setActionInvalidDate,
                isAdmin,
                dateIsEmpty,
                true,
              )}
            </Form.Group>
          </Form>
        </Box>
      );
    case FORM_TYPES.terminateConfirmation:
      return (
        <Box>
          <Box marginBottom="two">
            <FormattedMessage
              id="TERMINATION_CONFIRMATION_DESCRIPTION"
              values={{ name }}
            />
          </Box>
          <Form>
            <Form.Group widths="equal">
              {getDatePickerForm(
                formType,
                setActionEffectiveDate,
                setActionInvalidDate,
                isAdmin,
                dateIsEmpty,
                true,
              )}
              <ActionsConfirmationTerminationCodeDropdownContainer
                reasonIsEmpty={reasonIsEmpty}
                onChangeReason={setReasonForTermination}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <ActionsConfirmationNoticeDateFormContainer
                setActionNoticeDate={setActionNoticeDate}
                displayInline
              />
              <ActionsConfirmationEOBDateFormContainer
                setActionEOBDate={setActionEOBDate}
                displayInline
              />
            </Form.Group>
          </Form>
        </Box>
      );
    case FORM_TYPES.reinstateConfirmation:
      return (
        <Box>
          <Box marginBottom="two">
            <FormattedMessage
              id="REINSTATE_CONFIRMATION_DESCRIPTION"
              values={{ name }}
            />
          </Box>
          {getDatePickerForm(
            formType,
            setActionEffectiveDate,
            setActionInvalidDate,
            isAdmin,
            dateIsEmpty,
          )}
        </Box>
      );
    case FORM_TYPES.addAdminRole:
      return (
        <Box marginY="one">
          <FormattedMessage id="ADD_ADMIN_ROLE_DESCRIPTION" values={{ name }} />
          <Box
            marginTop="one"
            className="modal-employees-action-confirmation__secondary"
          >
            <FormattedMessage id="ADD_ADMIN_ROLE_TEXT" />
            <OL>
              {EDIT_ADMIN_ROLE_TEXT.map(text => (
                <li key={text}>{text}</li>
              ))}
            </OL>
          </Box>
        </Box>
      );
    case FORM_TYPES.removeAdminRole:
      return (
        <Box marginY="one">
          <FormattedMessage
            id="REMOVE_ADMIN_ROLE_DESCRIPTION"
            values={{ name }}
          />
          <Box
            marginTop="one"
            className="modal-employees-action-confirmation__secondary"
          >
            <FormattedMessage id="REMOVE_ADMIN_ROLE_TEXT" />
            <OL>
              {EDIT_ADMIN_ROLE_TEXT.map(text => (
                <li>{text}</li>
              ))}
            </OL>
          </Box>
        </Box>
      );
    case FORM_TYPES.unscheduleSuspend:
      return <FormattedMessage id="UNSCHEDULE_SUSPENSION_DESCRIPTION" />;
    case FORM_TYPES.unscheduleTerminate:
      return <FormattedMessage id="UNSCHEDULE_TERMINATION_DESCRIPTION" />;
    case FORM_TYPES.unscheduleReinstate:
      return <FormattedMessage id="UNSCHEDULE_REINSTATE_DESCRIPTION" />;
    case FORM_TYPES.copyInviteUrl:
      return (
        <FormattedMessage
          id="COPY_INVITE_URL_CONFIRMATION_CONTENT"
          values={{
            url: <Link href={url}>{url}</Link>,
          }}
        />
      );
    default:
      return null;
  }
};

const getButtonText = formType => {
  switch (formType) {
    case FORM_TYPES.suspendConfirmation:
      return <FormattedMessage id="SUSPENSION_CONFIRMATION_BUTTON" />;
    case FORM_TYPES.terminateConfirmation:
      return <FormattedMessage id="TERMINATION_CONFIRMATION_BUTTON" />;
    case FORM_TYPES.reinstateConfirmation:
      return <FormattedMessage id="REINSTATE_CONFIRMATION_BUTTON" />;
    case FORM_TYPES.addAdminRole:
      return <FormattedMessage id="ADD_ADMIN_ROLE_BUTTON" />;
    case FORM_TYPES.removeAdminRole:
      return <FormattedMessage id="REMOVE_ADMIN_ROLE_BUTTON" />;
    case FORM_TYPES.copyInviteUrl:
      return <FormattedMessage id="COPY_INVITE_URL_CONFIRMATION_BTN" />;
    default:
      return <FormattedMessage id="CONFIRM_UNSCHEDULE" />;
  }
};

const ActionsConfirmation = ({
  name,
  onClose,
  onConfirm,
  setActionEffectiveDate,
  setActionInvalidDate,
  setReasonForTermination,
  actionInvalidDate,
  formType,
  typeId,
  isAdmin,
  dateIsEmpty,
  reasonIsEmpty,
  url,
  setActionNoticeDate,
  setActionEOBDate,
}) => (
  <Modal
    onClose={onClose}
    className={`modal-employees-action-confirmation action-modal-${typeId}`}
  >
    <HeadingTwo>{getHeaderText(formType, name)}</HeadingTwo>
    <Box
      className="modal-employees-action-confirmation__content"
      marginTop="one"
      marginBottom="oneAndHalf"
    >
      {getContent(
        formType,
        name,
        setActionEffectiveDate,
        setActionInvalidDate,
        setReasonForTermination,
        isAdmin,
        dateIsEmpty,
        url,
        reasonIsEmpty,
        setActionNoticeDate,
        setActionEOBDate,
      )}
    </Box>
    <ModalButtons>
      {formType !== FORM_TYPES.copyInviteUrl && (
        <SecondaryButton onClick={onClose} marginRight="one">
          <FormattedMessage id="CANCEL" />
        </SecondaryButton>
      )}
      <PrimaryButton disabled={actionInvalidDate} onClick={onConfirm}>
        {getButtonText(formType)}
      </PrimaryButton>
    </ModalButtons>
  </Modal>
);

ActionsConfirmation.propTypes = {
  name: PropTypes.string,
  setActionEffectiveDate: PropTypes.func.isRequired,
  setReasonForTermination: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  setActionInvalidDate: PropTypes.func.isRequired,
  actionInvalidDate: PropTypes.bool,
  formType: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  reasonIsEmpty: PropTypes.bool.isRequired,
  dateIsEmpty: PropTypes.bool,
  typeId: PropTypes.string,
  url: PropTypes.string,
  setActionNoticeDate: PropTypes.func.isRequired,
  setActionEOBDate: PropTypes.func.isRequired,
};

ActionsConfirmation.defaultProps = {
  actionInvalidDate: false,
  dateIsEmpty: false,
  name: null,
  url: null,
  typeId: null,
  onConfirm: noop(),
};

export default ActionsConfirmation;
