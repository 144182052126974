import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectApp = get('apps.employer-experience');

export const selectFinanceToolsReportingUI = createSelector(
  selectApp,
  get('ui.financeToolsReports'),
);
export const selectFinanceToolsInvoicingUI = createSelector(
  selectApp,
  get('ui.financeToolsInvoicing'),
);

export const selectStatementOfAccountLoading = createSelector(
  selectFinanceToolsReportingUI,
  get('statementOfAccountLoading'),
);

export const selectStatementOfAccountContentId = createSelector(
  selectFinanceToolsReportingUI,
  get('statementOfAccountContentId'),
);

export const selectInvoicingDiagnosticsLoading = createSelector(
  selectFinanceToolsInvoicingUI,
  get('invoicingDiagnosticsLoading'),
);

export const selectInvoicingDiagnosticsContentId = createSelector(
  selectFinanceToolsInvoicingUI,
  get('invoicingDiagnosticsContentId'),
);

export const selectClearInvoicingDiagnosticsLoading = createSelector(
  selectFinanceToolsInvoicingUI,
  get('clearInvoicingDiagnosticsLoading'),
);

export const selectEnrollmentSelectionsReportLoading = createSelector(
  selectFinanceToolsReportingUI,
  get('enrollmentSelectionsReportLoading'),
);

export const selectEnrollmentSelectionsReportContentId = createSelector(
  selectFinanceToolsReportingUI,
  get('enrollmentSelectionsReportContentId'),
);

export const selectNetSuiteInvoiceReportLoading = createSelector(
  selectFinanceToolsInvoicingUI,
  get('netSuiteInvoiceReportLoading'),
);

export const selectNetSuiteInvoiceReportContentId = createSelector(
  selectFinanceToolsInvoicingUI,
  get('netSuiteInvoiceReportContentId'),
);
