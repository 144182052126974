import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SalesforceSyncContent } from './salesforce-sync.view';
import {
  fetchGroupVendors as fetchGroupVendorsAction,
  addVendorsToProductSync as addVendorsToProductSyncAction,
} from './salesforce-sync.actions';
import {
  selectGroupVendors,
  selectGroupVendorSync,
  selectIsSaving,
  selectCreateCasesForEmployeeCOBRA,
} from './salesforce-sync.selectors';

import { selectGroupId } from 'apps/employer-experience/pages/EmployerDetails/selectors';

const mapStateToProps = createStructuredSelector({
  groupId: selectGroupId,
  groupVendors: selectGroupVendors,
  groupVendorSync: selectGroupVendorSync,
  isSaving: selectIsSaving,
  createCasesForEmployeeCOBRA: selectCreateCasesForEmployeeCOBRA,
});

const mapDispatchToProps = {
  fetchGroupVendors: fetchGroupVendorsAction,
  addVendorsToProductSync: addVendorsToProductSyncAction,
};

export const SalesforceSyncTab = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    groupId,
    fetchGroupVendors,
    groupVendors,
    groupVendorSync,
    addVendorsToProductSync,
    isSaving,
    createCasesForEmployeeCOBRA,
  }) => {
    const [selectedVendorIds, setSelectedVendorIds] = useState([]);
    const [
      shouldCreateCasesForEmployeeCOBRA,
      setShouldCreateCasesForEmployeeCOBRA,
    ] = useState(createCasesForEmployeeCOBRA);

    // "groupVendorSync" is updated again some seconds later when
    // "benefit_type_schemas_get" message is received, making any changes
    // to the selected options to be reset.
    useEffect(() => {
      setSelectedVendorIds(groupVendorSync);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      fetchGroupVendors({ groupId });
    }, [groupId, fetchGroupVendors]);

    const selectedVendorsList = useMemo(() => {
      if (!selectedVendorIds) return [];
      return groupVendors.filter(vendor =>
        selectedVendorIds.find(vendorId => vendor.value === vendorId),
      );
    }, [groupVendors, selectedVendorIds]);

    const onSelectionChange = vendors => {
      setSelectedVendorIds(vendors ? vendors.map(vendor => vendor.value) : []);
    };

    const onCreateCasesForEmployeeCOBRAChange = value => {
      setShouldCreateCasesForEmployeeCOBRA(value);
    };

    const onSaveVendors = () => {
      addVendorsToProductSync({
        groupId,
        vendorIds: selectedVendorIds,
        createCasesForEmployeeCOBRA: shouldCreateCasesForEmployeeCOBRA,
      });
    };

    return (
      <SalesforceSyncContent
        vendorsList={groupVendors}
        selectedVendorsList={selectedVendorsList}
        onSelectionChange={onSelectionChange}
        onSaveVendors={onSaveVendors}
        isSaveVendorsLoading={isSaving}
        shouldCreateCasesForEmployeeCOBRA={shouldCreateCasesForEmployeeCOBRA}
        onCreateCasesForEmployeeCOBRAChange={
          onCreateCasesForEmployeeCOBRAChange
        }
      />
    );
  },
);

SalesforceSyncTab.propTypes = {
  groupId: PropTypes.string.isRequired,
  fetchGroupVendors: PropTypes.func,
  groupVendors: PropTypes.arrayOf(
    PropTypes.shape({
      vendor_id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};
