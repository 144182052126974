import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

// If given paymentCredentialsId, service will update instead of create
export const setStripeProfile = ({
  accountNumber,
  paymentCredentialsId = null,
  payeeDefault = null,
}) =>
  call(websocketFetch, 'set_stripe_profile', {
    account_number: accountNumber,
    payment_credentials_id: paymentCredentialsId,
    default: payeeDefault,
  });
