import { QLE_MASTER_LIST } from '../qle-master-list';
import { matchPath } from 'react-router-dom';
import { fullQLERoutePattern } from '../qle-configuration.constants';

/**
 * @function makeQLEConfigBreadcrumbLinks Return array of links to be used by Breadcrumbs links.
 *
 * Reads the following from the URL:
 * TODO: Update this when selectors selecting selected benefit class and qle's are merged
 * - groupId
 * - benefitClassId
 * - qleType
 * - qleConfigurationId
 *
 * @param {string} pathname react router's path used to extract groupId, benefitClassId, qleType, qleConfigurationId
 * @param {function} formatMessage used to return translated strings for QLE types and QLE landing page
 * @param {object[]} benefitClasses used in conjuction with benefitClassId to find selected benefit type, and show it's name
 */
export const makeQLEConfigBreadcrumbLinks = ({
  pathname,
  formatMessage,
  benefitClasses = [],
}) => {
  let links = [];
  // Extract all the necessary info from the url
  const match = matchPath(pathname, {
    path: fullQLERoutePattern,
  });

  if (match) {
    const {
      params: { groupId, benefitClassId, qleType, qleConfigurationId },
    } = match;
    // `landingPath` is the only route that always exists. The others may not exist depending on what page they are on
    const landingPath = `/admin/employers/${groupId}/benefits/qle-config`;
    const benefitClassPath = `${landingPath}/${benefitClassId}`;
    const qleTypePath = `${benefitClassPath}/${qleType}${
      qleConfigurationId ? `/${qleConfigurationId}` : ''
    }`;

    // TODO: Replace this when the equivalent selector is merged
    // Needed to get the benefit classes' name
    const selectedBenefitClass = benefitClasses.find(
      benefitClass => benefitClass.id === benefitClassId,
    );

    links = [
      {
        children: formatMessage({ id: 'QLE_CONFIGURATIONS' }),
        to: landingPath,
        key: 'landing',
      },
      ...(benefitClassId
        ? [
            {
              children: selectedBenefitClass.name,
              to: benefitClassPath,
              key: 'benefit-class',
            },
          ]
        : []),
      ...(qleType
        ? [
            {
              children: formatMessage({
                id: QLE_MASTER_LIST[qleType],
              }),
              to: qleTypePath,
              key: 'qle-type',
            },
          ]
        : []),
    ];
  }

  return links;
};
