import { request } from 'common/websocket-redux';
import { takeLatest, all, fork } from 'redux-saga/effects';
import { employeeBenefitsSagas } from 'apps/employer-experience-v2/employee-benefits/benefits.sagas';
import { benefitDocumentsSagas } from 'apps/employer-experience-v2/employee-benefits/documents.sagas';
import { employeeProfileSagas } from 'apps/employer-experience-v2/employee-profile/profile.sagas';
import { employerBenefitsSagas } from 'apps/employer-experience-v2/employer-benefits/benefits.saga';
import { employerInvoicesSagas } from 'apps/employer-experience-v2/employer-invoices/invoices.saga';
import { employerAdjustmentsSagas } from 'apps/employer-experience-v2/employer-adjustments/adjustments.sagas';
import { employerDivisionsSagas } from 'apps/employer-experience-v2/employer-divisions/employer-divisions.sagas';
import { employerReportsSagas } from 'apps/employer-experience-v2/employer-reports/reports.sagas';
import { updateFlexDollarsSagas } from 'apps/employer-experience-v2/employee-benefits/update-flex-dollars/update-flex-dollars.sagas';
import { pendingFeesSaga } from 'apps/employer-experience-v2/employer-invoices/pending-fees/store/fees.sagas';
import { employeeSearchSaga } from 'apps/employer-experience-v2/employee-search/search.saga';
import { employerProfileSaga } from 'apps/employer-experience-v2/employer-profile/employer-profile.saga';
import { dependentSaga } from 'apps/employer-experience-v2/employee-dependents/employee-dependents.saga';
import { employerInsightsSaga } from 'common/insight-dashboards/employer-insights.saga';
import { GET_GROUP } from './actions';
import { getGroup } from 'common/services';

export function* getGroupRequest({ payload }) {
  yield request(GET_GROUP, getGroup(payload));
}

export function* initialize() {
  yield takeLatest(GET_GROUP.BASE, getGroupRequest);
}

export function* hrAdminSaga() {
  yield all([
    fork(initialize),
    fork(updateFlexDollarsSagas),
    fork(employeeBenefitsSagas),
    fork(employerBenefitsSagas),
    fork(benefitDocumentsSagas),
    fork(employeeProfileSagas),
    fork(employeeSearchSaga),
    fork(employerProfileSaga),
    fork(employerInvoicesSagas),
    fork(employerAdjustmentsSagas),
    fork(employerDivisionsSagas),
    fork(employerReportsSagas),
    fork(pendingFeesSaga),
    fork(dependentSaga),
    fork(employerInsightsSaga),
  ]);
}
