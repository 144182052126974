import { withStateHandlers, withProps, compose, withHandlers } from 'recompose';
import { trim, isEmpty, filter, map } from 'lodash';
import InvoiceTable from './invoice-table.view';
import {
  BILLING_PREFERENCE_BY_DIVISION,
  INVOICE_ITEM_KEY,
  DRYRUN_INVOICE_ITEM_KEY,
  SELECT_ALL_DIVISIONS_ID,
} from 'apps/employer-experience/pages/EmployerDetails/Billing/constants';
import { injectIntl } from 'react-intl';

const withState = withProps(
  ({ intl, isDryRun, billingPreference, invoices }) => ({
    itemIdKey: isDryRun ? DRYRUN_INVOICE_ITEM_KEY : INVOICE_ITEM_KEY,
    showBillingDivision: billingPreference === BILLING_PREFERENCE_BY_DIVISION,
    invoices: map(invoices, invoice => ({
      ...invoice,
      billing_division_name: isEmpty(trim(invoice.billing_division_name))
        ? intl.formatMessage({ id: 'NONE' })
        : trim(invoice.billing_division_name),
    })),
  }),
);

const withBillingDivisions = withProps(({ invoices }) => {
  const names = invoices.map(invoice => trim(invoice.billing_division_name));
  const uniqueNames = names.sort().filter((item, pos, ary) => {
    return !pos || item !== ary[pos - 1];
  });
  return {
    billingDivisions: uniqueNames.map((name, index) => ({
      billingDivisionId: `${index}`,
      name,
    })),
  };
});

const withOnDownloadCsv = withHandlers({
  onDownloadCsv:
    ({ downloadInvoiceCsv }) =>
    e => {
      e.stopPropagation();
      downloadInvoiceCsv();
    },
  onDownloadExternalInvoice:
    ({ downloadExternalInvoice }) =>
    (e, payload) => {
      e.stopPropagation();
      downloadExternalInvoice(payload);
    },
});

const withSelectedDivision = withStateHandlers(
  () => ({ selectedDivisionId: SELECT_ALL_DIVISIONS_ID }),
  {
    onDivisionSelect: () => key => ({
      selectedDivisionId: key,
    }),
  },
);

const withFilteredInvoices = withProps(
  ({ showBillingDivision, selectedDivisionId, invoices, billingDivisions }) =>
    // only filter if option 'All' is not selected
    showBillingDivision &&
    selectedDivisionId !== SELECT_ALL_DIVISIONS_ID && {
      invoices: filter(
        invoices,
        invoice =>
          billingDivisions.find(
            division => division.billingDivisionId === selectedDivisionId,
          )?.name === invoice.billing_division_name,
      ),
    },
);

const withToggleInvoiceStatus = withHandlers({
  onToggleInvoiceStatus:
    ({ toggleInvoiceStatus }) =>
    (evt, value) => {
      evt.stopPropagation();
      toggleInvoiceStatus(value);
    },
  onSaveInvoiceStatus:
    ({ saveInvoiceStatus }) =>
    () => {
      saveInvoiceStatus();
    },
});

export default compose(
  injectIntl,
  withState,
  withBillingDivisions,
  withOnDownloadCsv,
  withSelectedDivision,
  withFilteredInvoices,
  withToggleInvoiceStatus,
)(InvoiceTable);
