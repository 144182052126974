import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Header } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import { EmployeesHeader, SearchForm, Table, employeePropTypes } from './v1';
import ActionsDropdown from './actions-dropdown.container';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES } from '../constants';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';

const Page = ({
  employees,
  loading,
  error,
  hasMoreResults,
  hasEmptyResults,
  onBulkUploadSelected,
  onSearchParamsChanged,
  onEndOfListReached,
  onEmployeeRowSelected,
  employerId,
  hasSpendingAccounts,
  hasTopupableSpendingAccounts,
  isAdmin,
}) => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'EMPLOYEES' }));
  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      screen_name: ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
      product_area: PRODUCT_AREA.HR_ADMIN,
      sub_product_area: null,
    });
  }, []);

  return (
    <Box>
      <EmployeesHeader
        onBulkUploadSelected={onBulkUploadSelected}
        isAdmin={isAdmin}
      />
      <SearchForm
        loading={loading}
        onSearchParamsChanged={onSearchParamsChanged}
        isAdmin={isAdmin}
      />
      <Divider hidden />

      {hasEmptyResults ? (
        <div>
          <Header as="h3">
            <FormattedMessage id="NO_RESULTS" />
          </Header>
        </div>
      ) : (
        <div>
          <Table
            ActionsDropdown={ActionsDropdown}
            loading={loading}
            error={error}
            employees={employees}
            onEndOfListReached={onEndOfListReached}
            hasMoreResults={hasMoreResults}
            onEmployeeRowSelected={onEmployeeRowSelected}
            employerId={employerId}
            hasSpendingAccounts={hasSpendingAccounts}
            hasTopupableSpendingAccounts={hasTopupableSpendingAccounts}
          />
        </div>
      )}
    </Box>
  );
};

Page.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.shape(employeePropTypes)).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  hasMoreResults: PropTypes.bool.isRequired,
  hasEmptyResults: PropTypes.bool.isRequired,
  onBulkUploadSelected: PropTypes.func.isRequired,
  onSearchParamsChanged: PropTypes.func.isRequired,
  onEndOfListReached: PropTypes.func.isRequired,
  onEmployeeRowSelected: PropTypes.func.isRequired,
  employerId: PropTypes.string,
  hasSpendingAccounts: PropTypes.bool.isRequired,
  hasTopupableSpendingAccounts: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

Page.defaultProps = {
  employerId: undefined,
  loading: false,
  error: false,
};

export default Page;
