export default {
  spendingAccountBenefit: {
    name: 'spendingAccountBenefit',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Allocation Settings',
        fields: [
          {
            name: 'benefit.allocation_frequency',
            span: 6,
          },
          {
            name: 'benefit.prorate_late_enrollment',
            span: 6,
          },
          {
            name: 'benefit.funds_grant_at_activation_date',
            span: 12,
          },
          {
            name: 'benefit.funds_grant_start_date',
            span: 6,
          },
        ],
      },
    ],
  },
};
