import React, { useState, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import { useIntl } from '@leagueplatform/locales';
import { isEmpty, get, set } from 'lodash';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { VerifyAccount } from './verify-account.view';
import { isEmail } from 'common/adaptive-forms/validation/rules';
import {
  accountFieldsInvalidated,
  submitVerificationForm,
  identifierHintToggled,
  helpEmailSelected,
  VERIFICATION_PAGE_LIFECYCLE,
} from '../../invite-access.actions';
import {
  transformIdentifier,
  createUniqueIdentifersValidator,
  extractUniqueIdentifiers,
  setUniqueIdentifiersInitialValues,
  mergeUniqueIdentifierValuesWithFields,
} from './unique-identifiers/unique-indentifier-fields';
import {
  selectBeforeEnrollmentPeriod,
  selectConfigId,
  selectContentBanner,
  selectContentBody,
  selectContentHeading,
  selectContentUniqueIdentifier,
  selectEnrollmentStartDate,
  selectInviteAccessReady,
  selectVerificationError,
  selectVerifying,
} from 'apps/invite-access/invite-access.selectors';

export const isValidDateOfBirth = (dateOfBirth = {}) => {
  const { year, month, day } = dateOfBirth;
  // Check if all 3 values are input
  if (!year || !month || !day) return false;
  // Check if a valid date, e.g. doesn't include 55th of January
  // moment compares months to an array, so needs to start at 0, not 1
  if (!moment([year, month - 1, day]).isValid()) return false;
  // Require full-length year because we send the raw number to the backend
  if (year.toString().length < 4) return false;
  // Check if the date is not in the future
  if (new Date() < moment([year, month - 1, day])) return false;
  return true;
};

const withState = connect(
  state => ({
    ready: selectInviteAccessReady(state),
    verifying: selectVerifying(state),
    verificationError: selectVerificationError(state),
    beforeEnrollmentPeriod: selectBeforeEnrollmentPeriod(state),
    enrollmentStartDate: selectEnrollmentStartDate(state),
    heading: selectContentHeading(state),
    banner: selectContentBanner(state),
    body: selectContentBody(state),
    uniqueIdentifiers: selectContentUniqueIdentifier(state),
    groupConfigId: selectConfigId(state),
  }),
  {
    accountFieldsInvalidated,
    submitVerificationForm,
    identifierHintToggled,
    helpEmailSelected,
  },
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    uniqueIdentifierFields:
      stateProps.uniqueIdentifiers?.map(transformIdentifier),
  }),
);

const mapPropsToValues = props => {
  const uniqueIdentifiers = setUniqueIdentifiersInitialValues(
    props.uniqueIdentifiers,
  );

  return {
    ...uniqueIdentifiers,
    firstName: '',
    lastName: '',
    dateOfBirth: undefined,
    emailAddress: '',
  };
};

/**
 * transforms the unique identifiers schema validation rules
 * error messages into more user-friendly and translated error messages
 * to display to the user.
 */

export const useTranslatedUniqueIdentifiers = ({ uniqueIdentifiers }) => {
  const { formatMessage } = useIntl();
  const getTranslatedHint = ({ args, rule }) => {
    switch (rule) {
      case 'max':
        return formatMessage(
          { id: 'UNIQUE_IDENTIFIER_MAX_LENGTH_ERROR' },
          { length: args.size },
        );
      case 'min':
        return formatMessage(
          { id: 'UNIQUE_IDENTIFIER_MIN_LENGTH_ERROR' },
          { length: args.size },
        );
      case 'required':
        return formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      default:
        return formatMessage({ id: 'PLEASE_ENTER_A_VALID_VALUE' });
    }
  };

  const translatedUniqueIdentifiers = uniqueIdentifiers.map(
    uniqueIdentifier => ({
      ...uniqueIdentifier,
      schema: {
        ...uniqueIdentifier.schema,
        validation_rules: uniqueIdentifier.schema.validation_rules.map(
          rule => ({
            ...rule,
            hint: getTranslatedHint(rule),
          }),
        ),
      },
    }),
  );

  return createUniqueIdentifersValidator(translatedUniqueIdentifiers);
};

export const useOnSubmit = ({
  submitVerificationForm: submitForm,
  employerIdentifier,
  groupConfigId,
  uniqueIdentifierFields,
  invitationId,
}) => {
  return useCallback(
    ({ uniqueIdentifiers: uniqueIdentifierValues, ...values }) => {
      const uniqueIdentifiers = extractUniqueIdentifiers(
        mergeUniqueIdentifierValuesWithFields(
          uniqueIdentifierValues,
          uniqueIdentifierFields,
        ),
      );
      submitForm({
        ...values,
        uniqueIdentifiers,
        employerIdentifier,
        groupConfigId,
        invitationId,
      });
    },
    [
      submitForm,
      employerIdentifier,
      groupConfigId,
      uniqueIdentifierFields,
      invitationId,
    ],
  );
};

export const useInitialValues = props => {
  const [initialValues] = useState(mapPropsToValues(props));
  return initialValues;
};

export const useValidate = ({
  validateUniqueIdentifiers: validator,
  uniqueIdentifierFields,
}) => {
  const { formatMessage } = useIntl();
  const validateUniqueIdentifiers = useCallback(
    uniqueIdentifiers => {
      return mergeUniqueIdentifierValuesWithFields(
        uniqueIdentifiers,
        uniqueIdentifierFields,
      )
        ?.reduce((errors, uniqueIdentifier) => {
          const path = uniqueIdentifier.name;
          const value = get(uniqueIdentifier, path);
          const error = validator({
            [path]: value,
          });
          return isEmpty(error) ? errors : [...errors, error];
        }, [])
        .reduce((acc, error, index) => {
          const [path, errorValue] = Object.entries(error)[0];
          return set(acc, `uniqueIdentifiers.${index}.${path}`, errorValue);
        }, {});
    },
    [validator, uniqueIdentifierFields],
  );

  return useCallback(
    values => {
      const {
        firstName,
        lastName,
        dateOfBirth,
        emailAddress,
        uniqueIdentifiers,
      } = values;
      const errors = {};

      if (!firstName)
        errors.firstName = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      if (!lastName)
        errors.lastName = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      if (!dateOfBirth) {
        errors.dateOfBirth = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      } else if (!isValidDateOfBirth(dateOfBirth)) {
        errors.dateOfBirth = formatMessage({
          id: 'INVALID_DATE_ERROR_MESSAGE',
        });
      }
      if (!emailAddress) {
        errors.emailAddress = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      } else if (!isEmail(null, emailAddress)) {
        errors.emailAddress = formatMessage({
          id: 'PLEASE_ENTER_A_VALID_EMAIL',
        });
      }

      const uniqueIdentifierErrors =
        validateUniqueIdentifiers(uniqueIdentifiers);

      return {
        ...errors,
        ...uniqueIdentifierErrors,
      };
    },
    [validateUniqueIdentifiers, formatMessage],
  );
};

const useReportFormErrors = ({
  accountFieldsInvalidated: reportFormErrors,
  uniqueIdentifierFields,
  invitationId,
}) => {
  return useCallback(
    errors => {
      if (!isEmpty(errors)) {
        const { uniqueIdentifiers: uniqueIdentifierErrors, ...literalFields } =
          errors;
        const flattenedUniqueIdentifierErrors = uniqueIdentifierErrors.map(
          identifier => {
            const errorField = uniqueIdentifierFields.find(field =>
              get(identifier, field.name),
            );
            return errorField.name;
          },
        );
        const invalidFields = [
          ...Object.keys(literalFields),
          ...flattenedUniqueIdentifierErrors,
        ].join(', ');
        reportFormErrors(invalidFields, invitationId);
      }
    },
    [reportFormErrors, uniqueIdentifierFields, invitationId],
  );
};

export const VerifyAccountWrapper = props => {
  const dispatch = useDispatch();
  /**
   * invitationId is an optional route param, used in the invite verification
   * process. It can otherwise be expected to be `undefined`.
   */
  const { employerIdentifier, invitationId } = useParams();
  const allProps = {
    ...props,
    employerIdentifier,
    invitationId,
  };

  const initalValues = useInitialValues(allProps);
  const onSubmit = useOnSubmit(allProps);
  const validateUniqueIdentifiers = useTranslatedUniqueIdentifiers(allProps);
  const validate = useValidate({
    ...allProps,
    validateUniqueIdentifiers,
  });
  const reportFormErrors = useReportFormErrors(allProps);

  // lifecycle
  useEffect(() => {
    dispatch(VERIFICATION_PAGE_LIFECYCLE.visit({ employerIdentifier }));
    return () => VERIFICATION_PAGE_LIFECYCLE.exit({ employerIdentifier });
  }, [dispatch, employerIdentifier]);

  return (
    <Formik
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initalValues}
      validateOnMount
    >
      {formProps => (
        <VerifyAccount
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...allProps}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...formProps}
          reportFormErrors={reportFormErrors}
        />
      )}
    </Formik>
  );
};

export const VerifyAccountContainer = withState(VerifyAccountWrapper);
