import { useQueryClient, useQuery, useMutation } from 'react-query';
import {
  getGroup,
  GET_GROUP_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import { setGroupSubscriptionRate } from './query/set-group-subscription-rate';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export const useSubscriptionFees = groupId => {
  const { data, isLoading } = useQuery([GET_GROUP_MESSAGE_TYPE, groupId], () =>
    getGroup(groupId),
  );

  const queryClient = useQueryClient();
  const contentMutation = useMutation(
    values => setGroupSubscriptionRate(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_GROUP_MESSAGE_TYPE, groupId]);
        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          textId: 'SAVED_SUBSCRIPTION_FEES',
        });
      },
      onError: error => {
        const errorObj = JSON.parse(error.message);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR',
          content: errorObj?.info?.reason,
        });
      },
    },
  );

  const onSubmitSubscriptionFees = values => {
    const parsedFees = values?.platformFeesPerBenefitClass.reduce(
      (accum, fee) => ({ ...accum, [fee.id]: Number(fee.value) }),
      {},
    );
    const info = {
      group_id: groupId,
      platform_fees_for_invited_users: values.platformFeesForInvitedUsers,
      subscription_rate_pepm:
        values.chargeSubscriptionFeeType === 'pepm'
          ? Number(values.subscriptionRatePepm)
          : undefined,
      platform_fees_per_benefit_class:
        values.chargeSubscriptionFeeType === 'per benefit class'
          ? parsedFees
          : null,
    };
    contentMutation.mutate(info);
  };

  return {
    subscriptionFeeData: data,
    onSubmitSubscriptionFees,
    isLoading,
  };
};
