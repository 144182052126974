import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withProps } from 'recompose';
import Document from '../benefits/documents/document.view';
import Documents from '../benefits/documents/documents.view';
import { uploadFile as uploadBenefitDocument } from '../benefits/documents/documents.actions';
import { resetUploadedDocuments } from './ui.actions';
import {
  selectUploadedDocument,
  selectEmployerBenefitDocuments,
} from './benefits.selectors';
import { getContentUrl } from '@leagueplatform/league-content-api';

const withDocumentUrl = withProps(
  ({ employerBenefitDocuments, documentId }) => {
    return {
      documentUrl: employerBenefitDocuments[documentId]
        ? getContentUrl(
            get(employerBenefitDocuments[documentId], 'content.content_id'),
          )
        : null,
    };
  },
);

const EmployerDocument = withDocumentUrl(Document);

EmployerDocument.propTypes = {
  employerBenefitDocuments:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  documentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
};

const EmployerDocuments = props => (
  <Documents
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
    renderDocument={documentProps => (
      <EmployerDocument
        key={documentProps.documentId}
        documentId={documentProps.documentId}
        title={documentProps.title}
        // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
        employerBenefitDocuments={props.employerBenefitDocuments}
        onRemoveDocument={documentProps.onRemoveDocument}
      />
    )}
  />
);

EmployerDocuments.propTypes = {
  employerBenefitDocuments:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
};

const withState = connect(
  state => ({
    uploadedDocument: selectUploadedDocument(state),
    employerBenefitDocuments: selectEmployerBenefitDocuments(state),
  }),
  { uploadFile: uploadBenefitDocument, resetUploadedDocuments },
);

export default withState(EmployerDocuments);
