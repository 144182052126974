/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import JsonEditMode from './json-edit-mode.view';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  submitBenefitPriceConfig,
  errorParsingJson,
} from '../pricing-configuration.actions';
import PropTypes from 'prop-types';
import {
  selectSelectedBenefitIdForPricingConfig,
  selectSelectedBenefitPrice,
  selectPricingConfigLoading,
} from '../../selectors';
import { selectGroupId } from '../../../selectors';
import React, { useState, useEffect, useCallback } from 'react';

const PROVIDER_TYPE = 'employer';

// Data model helper functions
// TODO: We don't care to show these fields. Should backend use DTO to hide these fields? We will repopulate these on the SET call
export const cleanJsonForEdit = benefitPrice => {
  if (!benefitPrice) {
    return JSON.stringify({}, null, 2);
  }
  const {
    version,
    benefit_id,
    id,
    provider_id,
    provider_type,
    managed_by_benefit,
    ...benefitPriceJson
  } = benefitPrice;
  return JSON.stringify(benefitPriceJson, null, 2);
};

export const createBenefitPricePayload = ({ code, benefitId, groupId }) => {
  const editedJson = JSON.parse(code);
  editedJson.benefit_id = benefitId;
  editedJson.provider_id = groupId;
  editedJson.provider_type = PROVIDER_TYPE;
  return {
    provider_id: groupId,
    provider_type: PROVIDER_TYPE,
    benefit_prices: [editedJson],
  };
};

export const JsonEditModeContainer = ({
  initialJson = {},
  benefitId,
  loading,
  submitBenefitPrice,
  jsonError,
  groupId,
}) => {
  const [code, setCode] = useState(initialJson);
  useEffect(() => {
    setCode(cleanJsonForEdit(initialJson));
  }, [initialJson, benefitId]);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const toggleSaveModal = useCallback(() => {
    setIsSaveModalOpen(!isSaveModalOpen);
  }, [isSaveModalOpen, setIsSaveModalOpen]);

  const onUpdateBenefitPriceConfiguration = () => {
    try {
      const payload = createBenefitPricePayload({ code, benefitId, groupId });
      submitBenefitPrice(payload);
      toggleSaveModal();
    } catch (e) {
      const errorObject = {
        info: {
          reason: e.message,
        },
      };
      jsonError(errorObject);
      toggleSaveModal();
    }
  };

  return (
    <JsonEditMode
      code={code}
      setCode={setCode}
      loading={loading}
      benefitId={benefitId}
      onUpdateBenefitPriceConfiguration={onUpdateBenefitPriceConfiguration}
      toggleSaveModal={toggleSaveModal}
      isSaveModalOpen={isSaveModalOpen}
    />
  );
};

JsonEditModeContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  initialJson: PropTypes.objectOf(PropTypes.any),
  benefitId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  submitBenefitPrice: PropTypes.func.isRequired,
  jsonError: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};
JsonEditModeContainer.defaultProps = {
  benefitId: null,
  initialJson: {},
};

export const JsonEdit = connect(
  createStructuredSelector({
    benefitId: selectSelectedBenefitIdForPricingConfig,
    initialJson: selectSelectedBenefitPrice,
    loading: selectPricingConfigLoading,
    groupId: selectGroupId,
  }),
  {
    submitBenefitPrice: submitBenefitPriceConfig,
    jsonError: errorParsingJson,
  },
)(JsonEditModeContainer);
