import { useSelector } from 'react-redux';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { selectEmployerName } from 'apps/employer-experience-v2/coverage-summary/coverage-summary.selectors';
import { selectUserId, selectFullName } from 'common/state/user/user.selectors';
import { selectUserEmail } from './employer-insights.selectors';
import { selectEmployerName as selectEmployerNameAdmin } from 'apps/employer-experience/pages/EmployerDetails/selectors';

export const useInsightsDashboardFeedback = (groupId, surveyString) => {
  const isAdmin = useSelector(selectUserIsAdmin);

  const userId = useSelector(selectUserId);
  const userEmail = useSelector(selectUserEmail);
  const employerNameAdmin = useSelector(selectEmployerNameAdmin);
  const employerName = useSelector(selectEmployerName);
  const userFullName = useSelector(selectFullName);
  const userFullNameAdmin = 'League Admin';

  const params = {
    group_id: groupId,
    group_name: isAdmin ? employerNameAdmin : employerName,
    user_id: userId,
    user_email: userEmail,
    user_full_name: isAdmin ? userFullNameAdmin : userFullName,
  };

  const queryParams = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  return `https://leagueca.typeform.com/to/${surveyString}?${queryParams}&platform=web&prefilled_answer=`;
};
