import { combineReducers } from 'redux';
import {
  GET_DEDUCTION_CODES,
  SET_DEDUCTION_CODES,
  DELETE_DEDUCTION_CODES,
  IMPORT_DEDUCTION_CODES,
  LOAD_DEDUCTION_CODE_EDITOR,
} from './deduction-codes.types';

const listHasNoItemId =
  list =>
  ({ id }) =>
    !list?.some(item => item.id === id);

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const deductionCodesReducer = (state = [], action = {}) => {
  switch (action?.type) {
    case LOAD_DEDUCTION_CODE_EDITOR.STARTED:
      return [];
    case LOAD_DEDUCTION_CODE_EDITOR.SUCCEEDED:
      return (
        action?.payload?.deductionCodesResponse?.info?.deduction_codes ?? []
      );
    case GET_DEDUCTION_CODES.SUCCEEDED:
      return action?.payload?.info?.deduction_codes ?? [];
    case SET_DEDUCTION_CODES.SUCCEEDED:
    case IMPORT_DEDUCTION_CODES.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const updatedItems = action?.payload?.deductionCodes ?? [];
      return [...state.filter(listHasNoItemId(updatedItems)), ...updatedItems];
    case DELETE_DEDUCTION_CODES.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const deletedItems = action?.payload?.info?.deleted ?? [];
      return state.filter(listHasNoItemId(deletedItems));
    default:
      return state;
  }
};

const payrollSchedulesReducer = (state = [], action = {}) => {
  switch (action?.type) {
    case LOAD_DEDUCTION_CODE_EDITOR.STARTED:
      return [];
    case LOAD_DEDUCTION_CODE_EDITOR.SUCCEEDED:
      return (
        action?.payload?.payrollSchedulesResponse?.info?.payroll_schedules ?? []
      );
    default:
      return state;
  }
};

const statusReducer = (state = STATUS.IDLE, action = {}) => {
  switch (action?.type) {
    case LOAD_DEDUCTION_CODE_EDITOR.STARTED:
      return STATUS.LOADING;
    case LOAD_DEDUCTION_CODE_EDITOR.SUCCEEDED:
      return STATUS.SUCCESS;
    case LOAD_DEDUCTION_CODE_EDITOR.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

export const deductionCodeEditorReducer = combineReducers({
  deductionCodes: deductionCodesReducer,
  payrollSchedules: payrollSchedulesReducer,
  status: statusReducer,
});
