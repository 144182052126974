import { useMutation } from 'react-query';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
const message_type = 'set_landing_screen_config';

interface UseSetLandingScreenConfigQueryOptions {
  id: string;
  contentful_reference_id: string;
}

// TODO: This should be moved into @leagueplatform/wallet-api
const setLandingScreenConfig = async (
  info: UseSetLandingScreenConfigQueryOptions,
) => {
  return SocketAsFetch.fetch({
    message_type,
    info,
  });
};

export const useSetLandingScreenConfigMutation = () => {
  return useMutation<
    UseSetLandingScreenConfigQueryOptions,
    Error,
    UseSetLandingScreenConfigQueryOptions
  >(
    ({ id, contentful_reference_id }) =>
      setLandingScreenConfig({ id, contentful_reference_id }),
    {
      onSuccess: () => {
        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          textId: 'BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_TITLE',
          contentId:
            'BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_DESCRIPTION',
        });
      },
      onError: error => {
        const errorObj = JSON.parse(error.message);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR',
          content: errorObj?.info?.reason,
        });
      },
    },
  );
};
