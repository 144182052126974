import { takeLatest, select, call, put, fork, all } from 'redux-saga/effects';
import { filter, get } from 'lodash';
import { request, websocketFetch } from 'common/websocket-redux';
import { ADMIN_GET_EMPLOYEE_SCHEMA_MESSAGE_TYPE } from '@leagueplatform/admin-api';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import { explodeObject } from 'common/adaptive-forms';
import {
  GET_USER_BENEFITS,
  GET_EMPLOYER_BENEFITS,
  UPDATE_USER_BENEFITS,
  ADD_USER_BENEFITS,
  REQUEST_REMOVE_USER_BENEFIT,
  REMOVE_USER_BENEFIT,
  EMPLOYEE_BENEFITS_PAGE_LIFECYCLE,
  SET_USER_BENEFIT_DOCUMENTS,
  GET_USER_BENEFIT_CLASSES,
  SET_USER_BENEFIT_CLASS,
  USER_BENEFIT_CLASS_CHANGED,
  UPDATE_USER_BENEFIT_STATUS,
  SET_USER_BENEFITS_STATUS_READY,
  GET_USER_BENEFIT_DOCUMENTS,
  FETCHED_ALL_USER_BENEFIT_DOCUMENTS,
  GET_USER_BENEFIT_SUMMARIES,
  GET_ALL_STATUSES_EMPLOYER_BENEFIT_PLANS,
  REQUEST_ADJUST_BENEFIT_END_DATE,
  ADJUST_BENEFIT_END_DATE,
} from './benefits.types';
import {
  BENEFIT_STATUS_READY,
  BENEFIT_STATUS_OPTED_OUT,
} from '../benefits/constants';
import {
  GET_BENEFIT_TYPE_SCHEMAS,
  GET_USER_BENEFIT_TYPE_SCHEMAS,
} from '../benefits/benefits.types';
import { BENEFIT_SUBMITTED } from '../benefits/benefit.types';
import {
  selectUserId,
  selectGroupId,
  selectEmployeeBenefitClass,
} from './benefits.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import { DO_TOP_UP_USER_BENEFIT } from '../../employer-experience/pages/add-funds/add-funds.types';
import {
  getUserBenefits,
  getUserBenefitSummaries,
  setUserBenefitsOptedOut,
  setUserBenefitEndDate,
  fetchUserBenefitTypeSchemas,
} from 'common/services';
import { GET_EMPLOYEE_USER_PROFILE } from '../employee-profile/profile.types';
import { PLAN_STATUSES } from 'common/constants';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchUserBenefitDocuments(benefitId) {
  yield request(GET_USER_BENEFIT_DOCUMENTS, [
    websocketFetch,
    'get_user_benefit_documents',
    {
      benefit_id: benefitId,
    },
  ]);
}

export function* getUserBenefitsFn({ userId, groupId }) {
  yield put({ type: GET_USER_BENEFITS.STARTED });
  const response = yield getUserBenefits({
    userId,
    groupId,
  });
  yield put({ type: GET_USER_BENEFITS.SUCCEEDED, payload: response });
}

export function* fetchUserBenefits(userId) {
  const isAdmin = yield select(selectUserIsAdmin);
  const groupId = yield select(
    isAdmin ? selectGroupId : selectCurrentUserGroupId,
  );

  try {
    const response = yield call(getUserBenefitsFn, { userId, groupId });
    yield request(
      GET_USER_BENEFIT_SUMMARIES,
      getUserBenefitSummaries({
        userIds: [userId],
        groupId,
      }),
    );

    const benefits = get(response, 'info.benefits') || [];
    yield all(benefits.map(({ id }) => call(fetchUserBenefitDocuments, id)));
    yield put({ type: FETCHED_ALL_USER_BENEFIT_DOCUMENTS });
  } catch (error) {
    yield put({ type: GET_USER_BENEFITS.ERRORED, payload: error });
  }
}

export function* fetchPlans(groupId) {
  yield request(GET_ALL_STATUSES_EMPLOYER_BENEFIT_PLANS, [
    websocketFetch,
    'get_employer_benefit_plans',
    {
      group_id: groupId,
      benefit_plan_statuses: [
        PLAN_STATUSES.CREATED,
        PLAN_STATUSES.ENROLLING,
        PLAN_STATUSES.ACTIVE,
        PLAN_STATUSES.EXPIRED,
      ],
    },
  ]);
}

export function* fetchUserCatalogue(groupId, newClassId) {
  const benefitClassId = yield select(selectEmployeeBenefitClass);
  yield request(GET_EMPLOYER_BENEFITS, [
    websocketFetch,
    'get_employer_benefits',
    {
      group_id: groupId,
      benefit_class_id: newClassId || benefitClassId,
    },
  ]);
}

export function* setUserBenefitDocuments({ benefitId, documents = [] }) {
  yield request(SET_USER_BENEFIT_DOCUMENTS, [
    websocketFetch,
    'set_user_benefit_documents',
    {
      benefit_id: benefitId,
      documents: filter(documents, document => document.content),
    },
  ]);
}

export function* setUserBenefits(benefits, type) {
  const userId = yield select(selectUserId);
  const groupId = yield select(selectGroupId);

  let success;
  let error;
  try {
    yield put({ type: type.STARTED });
    const response = yield call(websocketFetch, 'set_user_benefits', {
      user_id: userId,
      benefits,
    });
    success = response;
    yield put({ type: type.SUCCEEDED, payload: response });
    yield request(
      GET_USER_BENEFIT_TYPE_SCHEMAS,
      fetchUserBenefitTypeSchemas({
        userId,
        groupId,
      }),
    );
  } catch (err) {
    error = err;
    yield put({ type: type.ERRORED, payload: err });
  } finally {
    return { success, error }; /* eslint no-unsafe-finally: 0 */
  }
}

export function* addUserBenefit(benefit) {
  yield call(setUserBenefits, [benefit], ADD_USER_BENEFITS);
}

export function* updateUserBenefit(benefit) {
  yield call(setUserBenefits, [benefit], UPDATE_USER_BENEFITS);
}

export function* removeBenefit({ payload }) {
  const userId = yield select(selectUserId);
  try {
    yield put({ type: REMOVE_USER_BENEFIT.STARTED });
    yield call(websocketFetch, 'remove_user_benefit', {
      user_id: userId,
      benefit_id: payload.benefitId,
    });
    yield put({
      type: REMOVE_USER_BENEFIT.SUCCEEDED,
      payload: { removed: payload.benefitId },
    });
    yield call(fetchUserBenefits, userId);
  } catch (error) {
    yield put({ type: REMOVE_USER_BENEFIT.ERRORED, payload: error });
  }
}

export function* adjustBenefitEndDate({ payload }) {
  const { benefitName, benefitId, newEndDate, userId, groupId } = payload;
  try {
    yield put({ type: ADJUST_BENEFIT_END_DATE.STARTED });
    yield setUserBenefitEndDate({
      benefitId,
      newEndDate,
    });

    yield put({ type: ADJUST_BENEFIT_END_DATE.SUCCEEDED });
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'ADJUST_END_DATE_SUCCESS_MESSAGE',
      values: { benefitName },
    });
    yield call(getUserBenefitsFn, { userId, groupId });
  } catch (error) {
    yield put({ type: ADJUST_BENEFIT_END_DATE.ERRORED, payload: error });
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: error.info.reason,
    });
  }
}

export function* fetchUserBenefitClasses(groupId) {
  yield request(GET_USER_BENEFIT_CLASSES, [
    websocketFetch,
    ADMIN_GET_EMPLOYEE_SCHEMA_MESSAGE_TYPE,
    { group_id: groupId },
  ]);
}

export function* setUserBenefitClass(action) {
  const userId = yield select(selectUserId);
  const groupId = yield select(selectGroupId);
  const payload = {
    group_id: groupId,
    benefit_class_id: action.payload,
    user_ids: [userId],
    version: 3,
  };

  try {
    yield put({
      type: SET_USER_BENEFIT_CLASS.STARTED,
    });
    const response = yield call(
      websocketFetch,
      'set_user_benefit_class',
      payload,
    );
    yield put({
      type: SET_USER_BENEFIT_CLASS.SUCCEEDED,
      payload: { ...response, payload, changedClass: action.payload },
    });
    yield all([
      call(fetchUserBenefits, userId),
      call(fetchPlans, groupId),
      call(fetchUserCatalogue, groupId, response.info.benefit_class_id),
      put(GET_EMPLOYEE_USER_PROFILE.request({ userId })),
    ]);
  } catch (error) {
    yield put({ type: SET_USER_BENEFIT_CLASS.ERRORED, payload: error });
  }
}

export function* fetchBenefitTypeSchemas(groupId) {
  yield request(GET_BENEFIT_TYPE_SCHEMAS, [
    websocketFetch,
    'get_benefit_type_schemas',
    {
      group_id: groupId,
    },
  ]);
}

export function* handleBenefitSubmission(payload) {
  const benefit = explodeObject(decamelizeKeysDeep(payload.values), Object);
  if (payload.values.id) {
    yield call(updateUserBenefit, benefit);
  } else {
    yield call(addUserBenefit, benefit);
  }
}

export function* updateBenefitStatusReady(benefitIds) {
  const userId = yield select(selectUserId);
  try {
    yield put({ type: SET_USER_BENEFITS_STATUS_READY.STARTED });
    const response = yield call(websocketFetch, 'set_user_benefits_ready', {
      user_benefit_ids: benefitIds,
    });
    yield put({
      type: SET_USER_BENEFITS_STATUS_READY.SUCCEEDED,
      payload: response,
    });
    yield call(fetchUserBenefits, userId);
  } catch (error) {
    yield put({ type: SET_USER_BENEFITS_STATUS_READY.ERRORED, payload: error });
  }
}

export function* updateBenefitStatusOptedOut(benefitIds) {
  const userId = yield select(selectUserId);
  try {
    yield put({ type: SET_USER_BENEFITS_STATUS_READY.STARTED });
    const response = yield setUserBenefitsOptedOut({
      userBenefitIds: benefitIds,
    });
    yield put({
      type: SET_USER_BENEFITS_STATUS_READY.SUCCEEDED,
      payload: response,
    });
    yield call(fetchUserBenefits, userId);
  } catch (error) {
    yield put({ type: SET_USER_BENEFITS_STATUS_READY.ERRORED, payload: error });
  }
}

export function* updateBenefitStatus({ payload }) {
  if (payload.status === BENEFIT_STATUS_READY) {
    yield call(updateBenefitStatusReady, [payload.id]);
  } else if (payload.status === BENEFIT_STATUS_OPTED_OUT) {
    yield call(updateBenefitStatusOptedOut, [payload.id]);
  }
}

export function* fetchBenefitsPageData(action) {
  let userId = yield select(selectUserId);
  let groupId = yield select(selectGroupId);

  if (!userId) {
    userId = action.payload.props.userId;
  }

  if (!groupId) {
    groupId = yield select(selectCurrentUserGroupId);
  }

  yield all([
    call(fetchBenefitTypeSchemas, groupId),
    request(
      GET_USER_BENEFIT_TYPE_SCHEMAS,
      fetchUserBenefitTypeSchemas({
        userId,
        groupId,
      }),
    ),
    call(fetchUserBenefitClasses, groupId),
    call(fetchUserBenefits, userId),
    call(fetchUserCatalogue, groupId),
    call(fetchPlans, groupId),
  ]);
}

export function* reloadUserBenefits() {
  const userId = yield select(selectUserId);
  yield call(fetchUserBenefits, userId);
}

export function* employeeBenefitsSagas() {
  yield takeLatest(UPDATE_USER_BENEFIT_STATUS, updateBenefitStatus);
  yield takeLatest(REQUEST_REMOVE_USER_BENEFIT, removeBenefit);
  yield takeLatest(REQUEST_ADJUST_BENEFIT_END_DATE, adjustBenefitEndDate);
  yield takeLatest(BENEFIT_SUBMITTED, handleBenefitSubmission);
  yield takeLatest(
    EMPLOYEE_BENEFITS_PAGE_LIFECYCLE.VISITED,
    fetchBenefitsPageData,
  );
  yield fork(takeLatest, USER_BENEFIT_CLASS_CHANGED, setUserBenefitClass);
  yield takeLatest(DO_TOP_UP_USER_BENEFIT.SUCCEEDED, reloadUserBenefits);
}
