import {
  lifecycle,
  branch,
  renderComponent,
  compose,
  withProps,
} from 'recompose';
import {
  visitEditWallet,
  clearBenefitWalletDetais,
  selectProviderBenefit,
} from './benefit-wallet.actions';
import { connect } from 'react-redux';
import BenefitWalletTabs from './benefit-wallet-tabs.view';
import {
  selectProviderBenefits,
  selectProviderBenefitsReady,
} from './benefit-wallet.selectors';
import { createStructuredSelector } from 'reselect';
import { Loader } from 'semantic-ui-react';
import React from 'react';
import { selectGroupId } from '../../selectors';
import { map } from 'lodash';

const dispatchers = {
  visitEditWallet,
  clearBenefitWalletDetais,
  selectProviderBenefit,
};

const withState = connect(
  createStructuredSelector({
    providerBenefits: selectProviderBenefits,
    ready: selectProviderBenefitsReady,
    groupId: selectGroupId,
  }),
  dispatchers,
);

const withLoadingSpinner = branch(
  props => !props.ready,
  renderComponent(() => <Loader active inline="centered" />),
);

const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.visitEditWallet({ groupId: this.props.groupId });
  },
  componentWillUnmount() {
    this.props.clearBenefitWalletDetais();
  },
});

const withBenefitsList = withProps(({ providerBenefits }) => ({
  providerBenefitsList: providerBenefits
    ? map(providerBenefits, (pb, id) => {
        return {
          text: `${pb.vendor_name} ${pb.full_name}`,
          value: id,
        };
      })
    : [],
}));

export default compose(
  withState,
  withLifecycleActions,
  withLoadingSpinner,
  withBenefitsList,
)(BenefitWalletTabs);
