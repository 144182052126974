import { call, takeLatest, fork, select, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { request, websocketFetch } from 'common/websocket-redux';
import { callWithDelay } from 'common/utilities/call-with-delay';
import { selectUserGroupId } from 'common/state/user/user-group.selectors';
import {
  selectOffset,
  selectQuery,
  selectIncludeSuspended,
} from './search.selectors';
import { ITEMS_PER_INFINITE_SCROLL, GET_USERS_IN_GROUP } from './v1';
import {
  EMPLOYEE_SEARCH_INIT,
  SEARCH_PARAMS_CHANGED,
  NEXT_SEARCH_RESULTS_PAGE_REQUESTED,
  EMPLOYEE_ROW_SELECTED,
  EMPLOYEE_SEARCH_LIFECYCLE,
  REINSTATE_EMPLOYEES,
  DO_REINSTATE_EMPLOYEES,
  SUSPEND_EMPLOYEES,
  DO_SUSPEND_EMPLOYEES,
  TERMINATE_EMPLOYEES,
  DO_TERMINATE_EMPLOYEES,
  UNSCHEDULE_EMPLOYEES_SUSPENSION,
  DO_UNSCHEDULE_EMPLOYEES_SUSPENSION,
  UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  UNSCHEDULE_EMPLOYEES_TERMINATION,
  DO_UNSCHEDULE_EMPLOYEES_TERMINATION,
  SET_USER_ROLE_IN_GROUP,
  DO_SET_USER_ROLE_IN_GROUP,
} from './search.types';
import {
  GET_TERMINATION_CODES,
  DO_GET_TERMINATION_CODES,
} from 'apps/employer-experience/pages/EmployerDetails/Employees/types';
import { GET_GROUP } from 'apps/employer-experience/pages/EmployerDetails/types';
import { DO_TOP_UP_USER_BENEFIT } from 'apps/employer-experience/pages/add-funds/add-funds.types';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { fetchBenefits } from 'apps/employer-experience/pages/EmployerDetails/saga';

export function* getUsersInGroup(payload, offset) {
  const groupId = yield select(selectUserGroupId);
  const info = {
    group_id: groupId,
    query: payload.query,
    offset,
    limit: ITEMS_PER_INFINITE_SCROLL,
    include_suspended: payload.includeSuspended,
  };

  return yield request(
    GET_USERS_IN_GROUP,
    [websocketFetch, 'get_users_in_group', info],
    info,
  );
}

export function* newQuery(action) {
  yield call(getUsersInGroup, action.payload, 0);
}

export function* getNextPage() {
  const query = yield select(selectQuery);
  const includeSuspended = yield select(selectIncludeSuspended);
  const offset = yield select(selectOffset);
  const nextOffset = offset + ITEMS_PER_INFINITE_SCROLL;

  const payload = {
    query,
    includeSuspended,
  };

  yield call(getUsersInGroup, payload, nextOffset);
}

export function* getGroup(groupId) {
  const response = yield request(GET_GROUP, [
    websocketFetch,
    'get_group',
    {
      group_id: groupId,
    },
  ]);
  return response.info;
}

export function* navigateToProfile(action) {
  const { userId } = action.payload;
  yield put(push(`/manage/employees/${userId}`));
}

export function* reinstateEmployees({ payload }) {
  const { groupId, userIds, effectiveDate } = payload;
  yield request(DO_REINSTATE_EMPLOYEES, [
    websocketFetch,
    'reinstate_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* suspendEmployees({ payload }) {
  const { groupId, userIds, effectiveDate } = payload;
  yield request(DO_SUSPEND_EMPLOYEES, [
    websocketFetch,
    'suspend_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* getTerminationCodes() {
  yield request(DO_GET_TERMINATION_CODES, [
    websocketFetch,
    'get_suspension_reason_options',
  ]);
}

export function* terminateEmployees({ payload }) {
  const { groupId, userIds, effectiveDate, reason, noticeDate, eobDate } =
    payload;
  yield request(DO_TERMINATE_EMPLOYEES, [
    websocketFetch,
    'terminate_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
      termination_reason: reason,
      suspended_notice_date: noticeDate,
      suspended_benefits_extension_date: eobDate,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* unscheduleEmployeesSuspension({ payload }) {
  const { groupId, userIds } = payload;
  yield request(DO_UNSCHEDULE_EMPLOYEES_SUSPENSION, [
    websocketFetch,
    'cancel_suspend_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* unscheduleEmployeesTermination({ payload }) {
  const { groupId, userIds } = payload;
  yield request(DO_UNSCHEDULE_EMPLOYEES_TERMINATION, [
    websocketFetch,
    'cancel_terminate_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* unscheduleEmployeesReinstatement({ payload }) {
  const { groupId, userIds } = payload;
  yield request(DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT, [
    websocketFetch,
    'cancel_reinstate_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* setUserRoleInGroup({ payload }) {
  const { groupId, userId, role } = payload;

  yield request(DO_SET_USER_ROLE_IN_GROUP, [
    websocketFetch,
    'set_user_role_in_group',
    {
      group_id: groupId,
      user_id: userId,
      role,
    },
  ]);
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* resetEmployeesList() {
  const query = yield select(selectQuery);
  yield call(getUsersInGroup, query, 0);
}

export function* initialize() {
  try {
    yield put({ type: EMPLOYEE_SEARCH_INIT.STARTED });
    const groupId = yield select(selectUserGroupId);
    const group = yield call(getGroup, groupId);
    const users = yield call(getUsersInGroup, '', 0);
    yield call(fetchBenefits, groupId); // fetch benefits to determine if employer has topupable spending accounts
    yield put({
      type: EMPLOYEE_SEARCH_INIT.SUCCEEDED,
      payload: { group, users },
    });
  } catch (error) {
    yield put({ type: EMPLOYEE_SEARCH_INIT.ERRORED, error });
  }
}

export function* employeeSearchSaga() {
  yield fork(takeLatest, EMPLOYEE_SEARCH_LIFECYCLE.VISITED, initialize);
  yield fork(takeLatest, SEARCH_PARAMS_CHANGED, callWithDelay, newQuery);
  yield fork(takeLatest, NEXT_SEARCH_RESULTS_PAGE_REQUESTED, getNextPage);
  yield fork(takeLatest, EMPLOYEE_ROW_SELECTED, navigateToProfile);
  yield fork(takeLatest, REINSTATE_EMPLOYEES, reinstateEmployees);
  yield fork(takeLatest, SUSPEND_EMPLOYEES, suspendEmployees);
  yield fork(takeLatest, TERMINATE_EMPLOYEES, terminateEmployees);
  yield fork(
    takeLatest,
    UNSCHEDULE_EMPLOYEES_TERMINATION,
    unscheduleEmployeesTermination,
  );
  yield fork(
    takeLatest,
    UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
    unscheduleEmployeesReinstatement,
  );
  yield fork(
    takeLatest,
    UNSCHEDULE_EMPLOYEES_SUSPENSION,
    unscheduleEmployeesSuspension,
  );
  yield fork(takeLatest, SET_USER_ROLE_IN_GROUP, setUserRoleInGroup);
  yield fork(takeLatest, DO_TOP_UP_USER_BENEFIT.SUCCEEDED, resetEmployeesList);
  yield takeLatest(GET_TERMINATION_CODES, getTerminationCodes);
}
