/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { concat, omit, get as prop } from 'lodash';
import {
  get,
  map,
  reduce,
  mapValues,
  pipe,
  sortBy,
  some,
  uniq,
  uniqBy,
} from 'lodash/fp';
import {
  getCurrencyForCountry,
  normalizePolicyKind,
} from '@leagueplatform/web-common';
import {
  GET_CLAIM_SETTLEMENT_RESOURCES,
  GET_VENDORS,
  UPDATE_CLAIM,
  SAVE_PARTIAL_CLAIM,
} from './settlement.types';
import {
  REQUEST_SET_CLAIM_DOCUMENTS,
  REQUEST_PIN_CLAIM_DOCUMENT,
} from 'common/components/user-documents/user-documents.types';
import { EMPLOYEE_DEPENDENT_VALUE } from './settlement.constants';

export const initialState = {
  dependents: null,
  initialClaimStatus: null,
  initialFormValues: null,
  funds: [],
  vendors: [],
};

export const makeTaxMap = reduce(
  (taxMap, tax) => ({ ...taxMap, [tax.name]: tax.value }),
  {},
);

export const normalizeAllocation = allocation => ({
  amount_approved: allocation.amount_approved,
  category_id: allocation.category_id,
  taxes: makeTaxMap(allocation.taxes),
});

export const makeAllocationMap = reduce(
  (allocationMap, allocation) => ({
    ...allocationMap,
    [allocation.type]: {
      amount_approved: allocation.amount_approved,
      category_id: allocation.category_id,
      taxes: makeTaxMap(allocation.taxes),
    },
  }),
  {},
);

export const normalizeFunds = pipe(
  map(fund => ({
    type: normalizePolicyKind(fund.policy_kind),
    policyKind: fund.policy_kind,
    startDate: fund.policy_start_date,
    endDate: fund.policy_end_date,
    available: fund.available_amount,
    allocated: fund.allocated_amount,
    id: fund.policy_id || fund.user_benefit_id,
    userBenefitId: fund.user_benefit_id,
    graceStartDate: fund.policy_grace_start_date,
    graceEndDate: fund.policy_grace_end_date,
  })),
  sortBy(['type', 'endDate']),
);

export const flattenCategoryTree = reduce((flatCategories, category) => {
  const subcategories = category.sub ? flattenCategoryTree(category.sub) : [];
  return concat(flatCategories, omit(category, 'sub'), subcategories);
}, []);

export const normalizeCategories = mapValues(
  pipe(
    get('info.categories'),
    flattenCategoryTree,
    uniqBy('unique_id'),
    map(category => ({
      name: category.category,
      id: category.unique_id,
    })),
    sortBy('name'),
  ),
);

/*
  One part of the app has access only to policy allocation type, the other only to benefit_type,
  so for convenience provide full name under both keys, e.g.
  {lsa: "Lifestyle Spending Account", league_spending_lsa: "Lifestyle Spending Account"}
*/

export const extractNamesFromCategories = categories => {
  return categories
    ? Object.entries(categories).reduce((result, category) => {
        const type = category[0];
        const { benefit_type, full_name } = category[1].info;
        return {
          ...result,
          [benefit_type]: full_name,
          [type]: full_name,
        };
      }, {})
    : {};
};

export const normalizeDependents = pipe(
  get('info.dependents'),
  map(dependent => ({
    id: dependent.dependent_id,
    name: `${dependent.first_name} ${dependent.last_name}`,
  })),
  sortBy('name'),
);

export const flattenFormValues = (claim, groupId) => ({
  amount_claimed: get('amount_claimed.value')(claim),
  claim_id: claim.claim_id,
  country: claim.country,
  currency: getCurrencyForCountry(claim.country),
  dependent_id: claim.dependent_id || EMPLOYEE_DEPENDENT_VALUE,
  expense_date: claim.expense_date,
  external_id: claim.external_id,
  external_vendor_id: claim.external_vendor_id,
  group_id: groupId,
  province: claim.province,
  status: claim.status,
  vendor: claim.vendor,
  message: null,
  policy_allocations: makeAllocationMap(claim.policy_allocations),
});

export const normalizePayments = transaction =>
  transaction
    ? {
        amount: transaction.amount,
        paymentId: transaction.payment_id,
        paymentStatus: transaction.payment_status,
        transactionNumber: transaction.transaction_number,
      }
    : null;

export const normalizeUser = ({ info }) => {
  const profile = get('user_profile')(info);
  return {
    userId: info.user_id,
    displayName: `${profile.first_name} ${profile.last_name}`,
  };
};

export const fundsHavePolicyId = some(get('policy_id'));

export const groupCountryCode = group => {
  let countryCode = group.country_code;
  if (!countryCode) {
    (group.locations || []).forEach(location => {
      if (!location.type) {
        countryCode = location.country;
      }
    });
  }
  return countryCode ? countryCode.toLowerCase() : '';
};

export const normalizeUserGroup = group => ({
  groupId: group.group_id,
  name: group.name,
  district: groupCountryCode(group),
  currency: group.currency,
});

const normalizePolicyAllocations = map(allocation => ({
  ...allocation,
  type: allocation.type === 'psa' ? 'lsa' : allocation.type,
}));

export const normalizeClaimData = claim => {
  return {
    availableFunds: claim.available_funds || [],
    referenceId: claim.reference_id,
    status: claim.status,
    claimId: claim.claim_id,
    dateEnrolled: claim.date_enrolled,
    description: claim.description,
    vendor: claim.vendor,
    vendorId: claim.vendor_id,
    expenseDate: claim.expense_date,
    dateCreated: claim.date_created,
    amountClaimed: claim.amount_claimed,
    externalId: claim.external_id, // Alegeus id
    country: claim.country,
    provinceState: claim.province,
  };
};

const normalizeClaimDocuments = claimDocuments => {
  return claimDocuments.map(doc => ({
    contentId: doc.content_id,
    contentType: doc.content_type,
  }));
};

export const setResources = (state, action) => {
  const { claim, dependents, categories, agent, user, group } = action.payload;

  return {
    ...state,
    claim: normalizeClaimData(claim.info),
    initialClaimStatus: claim.info.status,
    requestedBenefitType: claim.info.requested_benefit_type,
    claimDescription: claim.info.description,
    referenceId: claim.info.reference_id,
    dateCreated: claim.info.date_created,
    initialFormValues: flattenFormValues(claim.info, group && group.group_id),
    policyAllocations: normalizePolicyAllocations(
      claim.info.policy_allocations,
    ),
    dependents: normalizeDependents(dependents),
    funds: normalizeFunds(claim.info.available_funds),
    isOldBenefitsModelUser: fundsHavePolicyId(claim.info.available_funds),
    categories: normalizeCategories(categories),
    benefitNames: extractNamesFromCategories(categories),
    payment: normalizePayments(claim.info.payment_transaction),
    claimDocuments: normalizeClaimDocuments(
      prop(claim, 'info.claim_documents', []),
    ),
    claimUserGroup: group ? normalizeUserGroup(group) : {},
    claimUser: normalizeUser(user),
    claimAgent: normalizeUser(agent),
  };
};

const getUniqueNames = pipe(map(get('name')), uniq);

export const setVendors = (state, action) => {
  const searchResults = action.payload.info.vendors;
  const vendors = getUniqueNames(searchResults);
  return { ...state, vendors };
};

const setClaim = (state, action) => ({
  ...state,
  categories: normalizeCategories(action.payload.categories),
  funds: normalizeFunds(action.payload.info.available_funds),
});

/* Handle adding a supporting document to claims */
export const setClaimDocuments = (state, { payload }) => {
  const { claimId, contentIds, contentId, contentType } = payload;
  return {
    ...state,
    claimDocuments: contentIds.map(cId => {
      const isAddedSupportingDoc = cId === contentId; // Is newly added supporting document
      const existingContent = state.claimDocuments.find(
        c => c.contentId === cId,
      ); // was a document that existed in the claim previously

      return {
        contentId: cId,
        contentIds,
        contentType: isAddedSupportingDoc
          ? contentType
          : existingContent.contentType,
        originalClaimId: isAddedSupportingDoc ? claimId : null,
      };
    }),
  };
};

export const setClaimDocumentPinned = (state, { payload }) => {
  const { claimId, contentIds } = payload;
  const docToUpdate = state.claimDocuments.find(
    c => c.contentId === contentIds[0],
  );
  const claimDocuments = state.claimDocuments.slice();
  if (docToUpdate) {
    return {
      ...state,
      claimDocuments: claimDocuments.map(item => {
        if (item.contentId === contentIds[0]) {
          return {
            ...item,
            contentIds,
            originalClaimId: claimId,
          };
        }
        return item;
      }),
    };
  }

  return state;
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const claimSettlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLAIM.SUCCEEDED:
    case GET_CLAIM_SETTLEMENT_RESOURCES.SUCCEEDED:
      return setResources(state, action);
    case GET_VENDORS.SUCCEEDED:
      return setVendors(state, action);
    case SAVE_PARTIAL_CLAIM.SUCCEEDED:
      return setClaim(state, action);
    case REQUEST_SET_CLAIM_DOCUMENTS.SUCCEEDED:
      return setClaimDocuments(state, action);
    case REQUEST_PIN_CLAIM_DOCUMENT.SUCCEEDED:
      return setClaimDocumentPinned(state, action);
    default:
      return state;
  }
};
