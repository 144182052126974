import {
  concat,
  find,
  get,
  isUndefined,
  partial,
  startCase,
  matchesProperty,
  map as mapCollection,
} from 'lodash';
import { get as prop, omit, pick, pipe, some } from 'lodash/fp';

export const validate = fieldSpec => {
  if (!fieldSpec.field_name)
    throw new Error(`required property field_name not set on field:
    ${JSON.stringify(fieldSpec)}`);
  return fieldSpec;
};

export const makeOptionsFromValidationRules = field => {
  if (!field.validation_rules) return [];
  const stringInListRule = find(
    field.validation_rules,
    vr => vr.rule === 'stringinlist',
  );
  const optionValues = get(stringInListRule, 'args.list');
  if (!optionValues) return [];
  return mapCollection(optionValues, value => ({ value, text: value }));
};

export const parseOptions = schemaOptions =>
  mapCollection(schemaOptions, (text, value) => ({ text, value }));

export const fieldIsRequired = pipe(
  prop('validation_rules'),
  some(matchesProperty('rule', 'required')),
);

export const assignRequired = fieldSpec =>
  fieldIsRequired(fieldSpec) ? { ...fieldSpec, required: true } : fieldSpec;

export const makeSelectOptions = fieldSpec => {
  if (fieldSpec.select_options) return parseOptions(fieldSpec.select_options);
  if (fieldSpec.input_type === 'select')
    return makeOptionsFromValidationRules(fieldSpec);
  return [];
};

export const assignSelectOptions = fieldSpec => {
  if (fieldSpec.input_type !== 'select') return fieldSpec;
  return {
    ...fieldSpec,
    options: makeSelectOptions(fieldSpec),
  };
};

export const setHiddenInputType = fieldSpec => {
  if (fieldSpec.hidden) return { ...fieldSpec, input_type: 'hidden' };
  return fieldSpec;
};

export const assignPath = (parent, spec) => {
  const path = parent
    ? concat(parent.path, spec.field_name)
    : [spec.field_name];
  return { ...spec, path };
};

export const assignLabel = (parent, spec) => {
  const specLabel = spec.label ? spec.label : startCase(spec.field_name);
  const label = parent ? concat(parent.label, specLabel) : specLabel;
  return { ...spec, label };
};

export const assignPosition = (parent, field) => {
  const fieldPosition = isUndefined(field.position) ? Infinity : field.position;
  const position = parent
    ? concat(parent.position, fieldPosition)
    : [fieldPosition];
  return { ...field, position };
};

const keepFormSchemaProps = pick([
  'field_name',
  'hidden',
  'input_type',
  'go_type',
  'json_type',
  'label',
  'object',
  'path',
  'placeholder',
  'position',
  'read_only',
  'select_options',
  'tooltip',
  'validation_rules',
]);

const omitUnneededProps = omit(['hidden', 'select_options']);

export const transformSchemaField = parent =>
  pipe(
    validate,
    keepFormSchemaProps,
    partial(assignPath, parent),
    partial(assignLabel, parent),
    partial(assignPosition, parent),
    assignRequired,
    assignSelectOptions,
    setHiddenInputType,
    omitUnneededProps,
  );
