import { fork, put, take } from 'redux-saga/lib/effects';
import { BOOT } from './types';
import { hrAdminSaga } from 'apps/employer-experience-v2/hr-admin.saga';
import { userSaga } from './user/user.saga';
import { userSettingsSagas } from './user-settings/user-settings.saga';
import { geolocationSaga } from './geolocation/saga';
import { featureFlagsSaga } from './featureFlags/feature-flags.saga';
import { memberExperienceSaga } from 'apps/member-experience/state/saga';
import { coverageSummarySaga } from 'common/coverage-summary';
import { groupsSaga } from 'common/state/groups/groups.saga';
import { leagueAdminSaga } from 'apps/employer-experience/league-admin.saga';
import { isInWidgetRunnerFrame } from 'common/utilities/widget-runner-helpers';
import { websocketSaga, AUTHENTICATE_CONNECTION } from 'common/websocket-redux';
import { authSaga, authenticateWebSocketMessage } from 'apps/auth/auth.saga';
import { userTermsSaga } from 'apps/terms/terms.saga';
import { privacyLockSaga } from 'common/privacy-lock/privacy-lock.saga';
import { APP_CONFIG } from 'app-config';

export function* listenForAuthenticateSucceeded() {
  yield take(AUTHENTICATE_CONNECTION.SUCCEEDED);
  yield fork(privacyLockSaga);
  yield fork(memberExperienceSaga);
  yield fork(hrAdminSaga);
  yield fork(leagueAdminSaga);
  yield fork(geolocationSaga);
  yield fork(coverageSummarySaga);
  yield fork(groupsSaga);
  yield fork(userTermsSaga);
}

export function* rootSaga() {
  yield put({
    type: BOOT.STARTED,
    payload: {
      isInWidgetRunner: isInWidgetRunnerFrame(),
    },
  });
  yield fork(websocketSaga, {
    onBeforeSend: authenticateWebSocketMessage,
    websocketUrl: APP_CONFIG.REACT_APP_API_URL,
  });
  yield fork(userSaga);
  yield fork(featureFlagsSaga);
  yield fork(userSettingsSagas);
  yield fork(authSaga);
  yield fork(listenForAuthenticateSucceeded);
  yield take(AUTHENTICATE_CONNECTION.SUCCEEDED);
  yield put({ type: BOOT.SUCCEEDED });
}
