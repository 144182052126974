import { selectGroupId } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectClassId,
  selectEnrollmentDesignTemplates,
  selectEnrollmentDesignTemplatesLoading,
} from '../../enrollment-design.selectors';
import {
  getEnrollmentTemplates,
  setEnrollmentTemplates,
} from '../enrollment-design-options.actions';
import {
  BenefitSet,
  BenefitSetGroup,
  EnrollmentStepItemType,
  EnrollmentTemplateTypes,
} from '../enrollment-design-options.types';
import EditingPanelPresenter from './editing-panel.view';
import {
  BENEFIT_SET_TYPE,
  BENEFIT_GROUP_TYPE,
  TEMPLATE_GROUP,
  TEMPLATE_CLASS,
  TEMPLATE_SET,
} from '../enrollment-experience-config.constants';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

interface EditingPanelContainerProps {
  activeEditingItem: EnrollmentStepItemType | null;
  onCreateGroupClick: (stepId: string) => void;
  clearActiveEditingId: () => void;
  deleteActiveStepOrGroup: () => void;
  formProps: any;
}

export const EditingPanelContainer = ({
  activeEditingItem,
  ...props
}: EditingPanelContainerProps) => {
  const templates = useSelector(selectEnrollmentDesignTemplates);
  const templatesLoading = useSelector(selectEnrollmentDesignTemplatesLoading);
  const groupId = useSelector(selectGroupId);
  const benefitClassId = useSelector(selectClassId);
  const dispatch = useDispatch();

  const getTemplateType = () => {
    switch ((activeEditingItem as BenefitSet | BenefitSetGroup)?.type) {
      case BENEFIT_SET_TYPE:
        return EnrollmentTemplateTypes.SET;
      case BENEFIT_GROUP_TYPE:
        return EnrollmentTemplateTypes.GROUP;
      default:
        return EnrollmentTemplateTypes.STEP;
    }
  };

  useEffect(() => {
    if (activeEditingItem && benefitClassId) {
      dispatch(
        getEnrollmentTemplates({
          groupId,
          benefitClassId,
          templateTypes: [getTemplateType()],
          templateId: activeEditingItem.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
  }, [activeEditingItem, groupId, benefitClassId]);

  const createContentfulTemplate = (type: string) => {
    const meta = {
      groupId,
      benefitClassId: benefitClassId ?? '',
      templateTypes: [getTemplateType()],
      templateId: activeEditingItem?.id ?? '',
    };
    switch (type) {
      case TEMPLATE_GROUP:
        dispatch(
          setEnrollmentTemplates(
            {
              groupId,
              templateType: getTemplateType(),
            },
            meta,
          ),
        );
        break;
      case TEMPLATE_CLASS:
        dispatch(
          setEnrollmentTemplates(
            {
              groupId,
              benefitClassId,
              templateType: getTemplateType(),
            },
            meta,
          ),
        );
        break;
      case TEMPLATE_SET:
        dispatch(
          setEnrollmentTemplates(
            {
              groupId,
              benefitClassId,
              templateType: getTemplateType(),
              templateId: activeEditingItem?.id,
            },
            meta,
          ),
        );
        break;
      default:
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ENROLLMENT_TEMPLATES_CREATED_ERROR',
        });
        break;
    }
  };

  return (
    <EditingPanelPresenter
      activeEditingItem={activeEditingItem}
      createContentfulTemplate={createContentfulTemplate}
      templates={templates || []}
      templatesLoading={templatesLoading}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

export default EditingPanelContainer;
