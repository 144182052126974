import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const RECOMMENDATION_IDS_LIFECYCLE = createLifecycleTypes(
  'recommendation-ids/LIFECYCLE',
);
export const GET_TEMPLATED_GENERIC_BENEFITS = createRequestTypes(
  'recommendation-ids/GET_TEMPLATED_GENERIC_BENEFITS',
);
export const SHOW_FORM = 'recommendation-ids/SHOW_FORM';
export const HIDE_FORM = 'recommendation-ids/HIDE_FORM';

export function showForm(payload) {
  return {
    type: SHOW_FORM,
    payload,
  };
}

export function hideForm() {
  return {
    type: HIDE_FORM,
  };
}

export const SET_TEMPLATED_GENERIC_BENEFIT = createRequestTypes(
  'recommendation-ids/SET_TEMPLATED_GENERIC_BENEFIT',
);

export function setTemplatedGenericBenefit(payload) {
  return {
    type: SET_TEMPLATED_GENERIC_BENEFIT.BASE,
    payload,
  };
}
