import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withLifecycleInitializer } from './initializer.view';
import EmployerDivisionsPage from './employer-divisions-page.view';
import {
  removeBillingDivision,
  submitAddBillingDivisionForm,
  toggleEditDivision,
  editBillingDivisionName,
} from './employer-divisions.actions';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import {
  selectEditing,
  selectGroupBillingDivisions,
  selectEditedName,
} from './employer-divisions.selectors';

const withState = connect(
  createStructuredSelector({
    isAdmin: selectUserIsAdmin,
    editingId: selectEditing,
    editedName: selectEditedName,
    billingDivisions: selectGroupBillingDivisions,
  }),
  {
    removeBillingDivision,
    submitAddBillingDivisionForm,
    toggleEditDivision,
    editBillingDivisionName,
  },
);

export const EmployerDivisions = compose(
  withLifecycleInitializer,
  withState,
)(EmployerDivisionsPage);
