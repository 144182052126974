import { connect } from 'react-redux';
import ActionsDropdown from './actions-dropdown/actions-dropdown.container';
import {
  reinstateEmployees,
  suspendEmployees,
  terminateEmployees,
  unscheduleEmployeesSuspension,
  unscheduleEmployeesTermination,
  unscheduleEmployeesReinstatement,
  setUserRoleInGroup,
} from '../actions';
import { resetEmployeeUserBenefits } from './reset-user-benefits/reset-user-benefits.action-creators';
import { requestInitiateQLE } from 'common/initiate-qle-reenrollment';
import { sendInviteEmail } from 'apps/employer-experience-v2/employee-profile/profile.actions';

const mapDispatchToProps = {
  reinstateEmployees,
  suspendEmployees,
  terminateEmployees,
  unscheduleEmployeesSuspension,
  unscheduleEmployeesTermination,
  unscheduleEmployeesReinstatement,
  setUserRoleInGroup,
  resetEmployeeUserBenefits,
  requestInitiateQLE,
  sendInviteEmail,
};

export default connect(null, mapDispatchToProps)(ActionsDropdown);
