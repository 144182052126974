// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import { useLeagueWSQuery } from '@leagueplatform/api';

const queryName = 'get_landing_screen_configs';

export interface LandingScreenConfig {
  contentful_reference_id: string;
  config_name: string;
  domain_type: string;
}

export interface CurrentLandingScreenConfig {
  id: string;
  domain_type: string;
  config_name: string;
  contentful_reference_id: string;
}

interface LandingScreenConfigs {
  current_config: CurrentLandingScreenConfig | null;
  configs: LandingScreenConfig[];
}

interface UseGetLandingScreenConfigsProps {
  id: string;
}

// TODO: This should be moved into @leagueplatform/wallet-api
export const useGetLandingScreenConfigs = ({
  id,
}: UseGetLandingScreenConfigsProps) =>
  useLeagueWSQuery<LandingScreenConfigs>([queryName, id], {
    message_type: queryName,
    info: { domain_types: ['Wallet'], id },
  });
