// The documented analytics can be found here:
// https://docs.google.com/spreadsheets/d/1mQ331DW4gy1ng7mwKIk3pFs2gC_dYSPoWhZrHTbU-jA/edit?usp=sharing
import { AGENT_TYPES } from './constants';

export const LABELS = {
  [AGENT_TYPES.CUSTOMER_CARE]: 'Customer Care',
  [AGENT_TYPES.REGISTERED_NURSE]: 'Registered Nurse',
  [AGENT_TYPES.CARE_NAVIGATOR]: 'Care Navigator',
};

export const ACTIONS = {
  START_CHAT: 'Start Chat',
  SEND_OFFLINE_MESSAGE: 'Send Offline Message',
  CANCEL_CHAT: 'Cancel Chat',
  CLOSE_CHAT: 'Close Chat',
  SEND_EMAIL_ON_ERROR: 'Send Email On Error',
};

export const generateChatEvent = (agentType, action) => ({
  category: 'Chat',
  label: LABELS[agentType],
  action,
});
