import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Flex, BodyOne, LabelText } from '@leagueplatform/genesis-commons';
import { Backdrop, Modal, ClickEater, CheckBox } from '@leagueplatform/ui-kit';
import {
  ErrorMessage,
  ModalButton,
  ModalLoaderButton,
  LinkBox,
} from './modal-elements';

export const ExportPlanDesignModal = ({
  onClose,
  onSubmit,
  planDesignRequestStatus,
  groupId,
}) => {
  const [exportBenefitIds, setExportBenefitIds] = useState(false);
  const onInputChange = e => setExportBenefitIds(e.target.checked);
  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
  const { loading, success, error, errorReason, exportedSheetUrl } =
    planDesignRequestStatus;
  const { formatMessage } = useIntl();

  return (
    <Backdrop onClose={onClose}>
      <Modal.Container>
        <ClickEater>
          <Modal.Header>
            <Modal.HeaderTitle>
              {formatMessage({ id: 'EXPORT_PLAN_DESIGN' })}
            </Modal.HeaderTitle>
          </Modal.Header>
          <Modal.Body>
            {success ? (
              <>
                <BodyOne>
                  {formatMessage({ id: 'EXPORT_PLAN_DESIGN_SUCCESS' })}
                </BodyOne>
                <LinkBox url={exportedSheetUrl} />
              </>
            ) : (
              <>
                <BodyOne>
                  {formatMessage({ id: 'EXPORT_PLAN_DESIGN_DESCRIPTION' })}
                </BodyOne>
                <Flex alignItems="center" marginTop="one">
                  <CheckBox
                    id="export-checkbox"
                    name="export-checkbox"
                    onChange={onInputChange}
                    checked={exportBenefitIds}
                    disabled={loading}
                  />
                  <LabelText as="label" htmlFor="export-checkbox" mt={0}>
                    {formatMessage({ id: 'EXPORT_BENEFIT_IDS' })}
                  </LabelText>
                </Flex>
                {error && <ErrorMessage>{errorReason}</ErrorMessage>}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {success ? (
              <ModalButton primary onClick={onClose}>
                {formatMessage({ id: 'CLOSE' })}
              </ModalButton>
            ) : (
              <>
                <ModalButton onClick={onClose} disabled={loading}>
                  {formatMessage({ id: 'CANCEL' })}
                </ModalButton>
                <ModalLoaderButton
                  loading={loading}
                  primary
                  onClick={() => onSubmit({ groupId, exportBenefitIds })}
                >
                  {formatMessage({ id: 'EXPORT_PLAN_DESIGN' })}
                </ModalLoaderButton>
              </>
            )}
          </Modal.Footer>
        </ClickEater>
      </Modal.Container>
    </Backdrop>
  );
};

ExportPlanDesignModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  planDesignRequestStatus: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
    errorReason: PropTypes.string,
  }).isRequired,
};
