import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES } from '../../../../../employer-experience-v2/constants';
import {
  PrimaryButton,
  SecondaryButton,
  Flex,
  Box,
  genesisStyled,
} from '@leagueplatform/genesis-commons';

const ButtonAsLink = genesisStyled(PrimaryButton)`
  &:hover {
    color: ${({ theme }) => theme.colors.primary.text.default};
  }
`;

const EmployeeHeader = ({ groupId, isAdmin }) => {
  const { formatMessage } = useIntl();

  const sendAnalytics = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: ANALYTICS_EVENT_HR_ADMIN_EMPLOYEES,
      product_area: PRODUCT_AREA.HR_ADMIN,
      sub_product_area: null,
      detail: 'add employee',
    });
  };

  return (
    <Flex justifyContent="space-between">
      <Header as="h1">{formatMessage({ id: 'EMPLOYEES' })}</Header>
      <Box>
        {isAdmin && (
          <>
            <SecondaryButton
              as={Link}
              to={`/admin/employers/${groupId}/employees/field-settings`}
              marginRight="one"
            >
              {formatMessage({ id: 'FIELD_SETTINGS' })}
            </SecondaryButton>
            <SecondaryButton
              as={Link}
              to={`/admin/employers/${groupId}/employees/bulk-upload`}
              marginRight="one"
            >
              {formatMessage({ id: 'BULK_UPLOAD' })}
            </SecondaryButton>
          </>
        )}

        <ButtonAsLink
          as={Link}
          to={
            isAdmin
              ? `/admin/employers/${groupId}/employees/add`
              : '/manage/employees/new'
          }
          onClick={!isAdmin && sendAnalytics}
        >
          {formatMessage({ id: 'ADD_EMPLOYEE' })}
        </ButtonAsLink>
      </Box>
    </Flex>
  );
};

EmployeeHeader.propTypes = {
  groupId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default EmployeeHeader;
