import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DEFAULT_TIME_ZONE } from 'common/constants';
import { ReadOnlyDate } from './ReadOnlyDate';
import { DATE_FORMAT } from '../../constants';
import { withFieldHints } from './FieldHints';
import { startOfDayInTimeZone, dayInTimeZone } from '../../utilities';

const Date = ({
  id,
  value,
  name,
  timeZone,
  setFieldValue,
  placeholder,
  setFieldTouched,
  readOnly,
}) => {
  const defaultValue = value
    ? dayInTimeZone(value.split('T')[0], timeZone) // Using `.split()` as a temporary fix for EMP-2128, should re-evaluate whether or not we need to use time zones at all here
    : null;

  const [dateValue, setDateValue] = useState(defaultValue);

  const handleDateChange = date => {
    setDateValue(date);
    let employerTzDate = null;
    if (date) {
      employerTzDate = startOfDayInTimeZone(date, timeZone).toISOString();
    }
    setFieldValue(name, employerTzDate);
  };

  const formattedDateValue = dateValue ? dateValue.format(DATE_FORMAT) : '';

  if (readOnly) {
    return <ReadOnlyDate {...{ name, value, placeholder, readOnly }} />;
  }

  return (
    <DayPickerInput
      placeholder={placeholder}
      format={DATE_FORMAT}
      value={formattedDateValue}
      id={id}
      name={name}
      onDayChange={handleDateChange}
      onBlur={() => setFieldTouched(name, true)}
      autoComplete="off"
    />
  );
};

Date.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  timeZone: PropTypes.string,
};

Date.defaultProps = {
  id: undefined,
  value: '',
  placeholder: '',
  timeZone: DEFAULT_TIME_ZONE,
};

export default compose(withFieldHints)(Date);
