import { createRequestTypes } from 'common/websocket-redux';

export const GET_HEALTH_ACTIVITIES = createRequestTypes(
  'healthConciergeTooling/GET_HEALTH_ACTIVITIES',
);

export const getHealthActivities = ({ userId, skip, limit }) => ({
  type: GET_HEALTH_ACTIVITIES.BASE,
  payload: { userId, skip, limit },
});
