import { getCoverageSummary as _getCoverageSummary } from 'common/services/enrollment';

export function* getCoverageSummary(payload) {
  const coverageSummaryResponse = yield _getCoverageSummary(payload);
  const coverageSummary = coverageSummaryResponse?.info ?? {};
  const paymentOptions = coverageSummary?.payment_options;
  const employerPaysAllCosts = paymentOptions?.employer_pays_all_costs ?? false;
  const showCostsPerPayPeriod =
    paymentOptions?.show_costs_per_pay_period ?? false;

  const showEmployerCosts = paymentOptions?.show_employer_costs ?? false;

  return yield {
    ...coverageSummary,
    employerPaysAllCosts,
    showCostsPerPayPeriod,
    showEmployerCosts,
  };
}
