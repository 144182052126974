import React from 'react';
import {
  Box,
  Flex,
  Image,
  HeadingTwo,
  BodyOneSecondary,
  Link,
} from '@leagueplatform/genesis-commons';
import Contract from 'apps/public/assets/contract.svg';
import { useIntl } from '@leagueplatform/locales';

export const RevisedTermsMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <Flex
      marginX="auto"
      marginY="three"
      padding="two"
      backgroundColor="surface.background.secondary"
      borderRadius="small"
      maxWidth={835}
    >
      <Image
        display={{ _: 'none', tablet: 'block' }}
        src={Contract}
        width="auto"
        marginRight="oneAndHalf"
        alt=""
      />
      <Box textAlign="left">
        <HeadingTwo marginBottom="half">
          {formatMessage({ id: 'WEVE_REVISED_OUR_TERMS' })}
        </HeadingTwo>
        <BodyOneSecondary marginBottom="half">
          {formatMessage({ id: 'TO_SERVE_YOU_BETTER' })}
        </BodyOneSecondary>
        <BodyOneSecondary>
          {formatMessage(
            { id: 'CONTACT_TC_EMAIL' },
            {
              helpEmail: (
                <Link href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}>
                  {formatMessage({ id: 'HELP_EMAIL' })}
                </Link>
              ),
            },
          )}
        </BodyOneSecondary>
      </Box>
    </Flex>
  );
};
