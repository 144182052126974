import {
  ENROLLMENT_CONFIG_VISITED,
  FETCH_BENEFIT_PLAN_OPTIONS,
  SET_CURRENT_PLAN_ID,
  SET_CURRENT_BUNDLE_PATH,
  SET_BENEFIT_PLAN_OPTIONS,
  CREATE_BENEFIT_PLAN_OPTIONS,
  UPLOAD_FORM_DOCUMENTS,
  FORM_VALUES_RESET,
  START_ENROLLMENT,
} from './enrollment-config.types';
import { GET_EMPLOYER_BENEFIT_PLANS } from '../Benefits/types';

export const visitEnrollmentConfig = payload => ({
  type: ENROLLMENT_CONFIG_VISITED,
  payload,
});

export const fetchBenefitPlanOptions = payload => ({
  type: FETCH_BENEFIT_PLAN_OPTIONS,
  payload,
});

export const setBenefitPlanOptions = payload => ({
  type: SET_BENEFIT_PLAN_OPTIONS.BASE,
  payload,
});

export const createBenefitPlanOptions = planId => ({
  type: CREATE_BENEFIT_PLAN_OPTIONS.BASE,
  payload: {
    planId,
  },
});

export const setCurrentPlanId = planId => ({
  type: SET_CURRENT_PLAN_ID,
  payload: {
    planId,
  },
});

export const setCurrentBundlePath = bundlePath => ({
  type: SET_CURRENT_BUNDLE_PATH,
  payload: {
    bundlePath,
  },
});

export const uploadFormDocuments = payload => ({
  type: UPLOAD_FORM_DOCUMENTS.BASE,
  payload,
});

export const getEmployerBenefitPlans = payload => {
  return {
    type: GET_EMPLOYER_BENEFIT_PLANS.BASE,
    payload,
  };
};

export const formValuesReset = () => ({ type: FORM_VALUES_RESET });

export const startEnrollment = payload => ({
  type: START_ENROLLMENT.BASE,
  payload,
});
