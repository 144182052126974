import { omit } from 'lodash';

// QLE Types
export const NOT_APPLICABLE_HSA_ONLY = 'not_applicable_hsa_only';

export const ANALYTICS = {
  categories: {
    USAGE_REPORT: 'Usage Report',
    INVOICES: 'Invoices',
    REPORTS: 'reports',
  },
};

export const DAYFORCE_DRIVER = 'dayforce_rest_hr';
export const TYPING_DEBOUNCE_INTERVAL = 250;

// A claim's status can be any one of these values
export const STARTED = 'started';
export const SUBMITTED_FOR_PREAPPROVAL = 'submitted_for_preapproval';
export const PREAPPROVAL_MORE_INFORMATION_NEEDED =
  'preapproval_more_information_needed';
export const PREAPPROVED = 'preapproved';
export const PREAPPROVAL_REJECTED = 'preapproval_rejected';
export const MORE_INFORMATION_NEEDED = 'more_information_needed';
export const PENDING_EXTERNAL_REVIEW = 'pending_external_review';
export const SUBMITTED = 'submitted';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const CANCELLED = 'cancelled';
export const PAID = 'paid';
export const RESET = 'reset';
export const UPDATE = 'update';

// A special option value representing claims of any status
export const VIEW_ALL = 'VIEW_ALL';
// Translation keys for claim statuses
export const CLAIM_STATUS_TRANSLATIONS = {
  [STARTED]: 'STARTED',
  [SUBMITTED_FOR_PREAPPROVAL]: 'SUBMITTED_FOR_PREAPPROVAL',
  [PREAPPROVAL_MORE_INFORMATION_NEEDED]: 'PREAPPROVAL_MORE_INFORMATION_NEEDED',
  [PREAPPROVED]: 'PREAPPROVED',
  [PREAPPROVAL_REJECTED]: 'PREAPPROVAL_REJECTED',
  [MORE_INFORMATION_NEEDED]: 'MORE_INFORMATION_NEEDED',
  [PENDING_EXTERNAL_REVIEW]: 'PENDING_EXTERNAL_REVIEW',
  [SUBMITTED]: 'SUBMITTED',
  [APPROVED]: 'APPROVED',
  [REJECTED]: 'REJECTED',
  [CANCELLED]: 'CANCELLED',
  [PAID]: 'PAID',
};

export const FRONTEND_CLAIM_STATUS_TYPES = [
  SUBMITTED,
  APPROVED,
  REJECTED,
  CANCELLED,
  PAID,
  MORE_INFORMATION_NEEDED,
];

export const EMPLOYER_STATUSES = {
  TERMINATED: 'terminated',
  SUSPENDED: 'suspended',
  READY: 'ready',
  DRAFT: 'draft',
  ACTIVE: 'active',
  PUBLISHED: 'published',
};

export const EMPLOYER_STATUS_TYPE_MAP = {
  active: 'ACTIVE',
  suspended: 'SUSPENDED',
  ready: 'READY',
  draft: 'DRAFT',
};

export const CLAIM_STATUS_TYPE_FILTER_MAP = omit(CLAIM_STATUS_TRANSLATIONS, [
  'started',
  'submitted_for_preapproval',
  'preapproval_more_information_needed',
  'preapproved',
  'preapproval_rejected',
]);

export const ASCENDING = 'asc';
export const DESCENDING = 'des';

export const DEFAULT_TIME_ZONE = 'America/Toronto';

export const ALBERTA = 'Alberta';
export const BRITISH_COLUMBIA = 'British Columbia';
export const MANITOBA = 'Manitoba';
export const NEW_BRUNSWICK = 'New Brunswick';
export const NEWFOUNDLAND_LABRADOR = 'Newfoundland and Labrador';
export const NOVA_SCOTIA = 'Nova Scotia';
export const ONTARIO = 'Ontario';
export const PRINCE_EDWARD_ISLAND = 'Prince Edward Island';
export const QUEBEC = 'Quebec';
export const SASKATCHEWAN = 'Saskatchewan';
export const NORTHWEST_TERRITORIES = 'Northwest Territories';
export const NUNAVUT = 'Nunavut';
export const YUKON = 'Yukon';
export const YUKON_TERRITORIES = 'Yukon Territories';
const ALABAMA = 'Alabama';
const ALASKA = 'Alaska';
const AMERICAN_SAMOA = 'American Samoa';
const ARIZONA = 'Arizona';
const ARKANSAS = 'Arkansas';
const CALIFORNIA = 'California';
const COLORADO = 'Colorado';
const CONNECTICUT = 'Connecticut';
const DELAWARE = 'Delaware';
const DISTRICT_OF_COLUMBIA = 'District of Columbia';
const FLORIDA = 'Florida';
const GEORGIA = 'Georgia';
const GUAM = 'Guam';
const HAWAII = 'Hawaii';
const IDAHO = 'Idaho';
const ILLINOIS = 'Illinois';
const INDIANA = 'Indiana';
const IOWA = 'Iowa';
const KANSAS = 'Kansas';
const KENTUCKY = 'Kentucky';
const LOUISIANA = 'Louisiana';
const MAINE = 'Maine';
const MARYLAND = 'Maryland';
const MASSACHUSETTS = 'Massachusetts';
const MICHIGAN = 'Michigan';
const MINNESOTA = 'Minnesota';
const MISSISSIPPI = 'Mississippi';
const MISSOURI = 'Missouri';
const MONTANA = 'Montana';
const NEBRASKA = 'Nebraska';
const NEVADA = 'Nevada';
const NEW_HAMPSHIRE = 'New Hampshire';
const NEW_JERSEY = 'New Jersey';
const NEW_MEXICO = 'New Mexico';
const NEW_YORK = 'New York';
const NORTH_CAROLINA = 'North Carolina';
const NORTH_DAKOTA = 'North Dakota';
const OHIO = 'Ohio';
const OKLAHOMA = 'Oklahoma';
const OREGON = 'Oregon';
const PENNSYLVANIA = 'Pennsylvania';
const PUERTO_RICO = 'Puerto Rico';
const RHODE_ISLAND = 'Rhode Island';
const SOUTH_CAROLINA = 'South Carolina';
const SOUTH_DAKOTA = 'South Dakota';
const TENNESSEE = 'Tennessee';
const TEXAS = 'Texas';
const US_VIRGIN_ISLANDS = 'US Virgin Islands';
const UTAH = 'Utah';
const VERMONT = 'Vermont';
const VIRGINIA = 'Virginia';
const WASHINGTON = 'Washington';
const WEST_VIRGINIA = 'West Virginia';
const WISCONSIN = 'Wisconsin';
const WYOMING = 'Wyoming';

// Abbreviated province and state names
const AB = 'AB';
const BC = 'BC';
const MB = 'MB';
const NB = 'NB';
const NL = 'NL';
const NS = 'NS';
const NT = 'NT';
const NU = 'NU';
const ON = 'ON';
const PE = 'PE';
const QC = 'QC';
const SK = 'SK';
const YT = 'YT';
const AL = 'AL';
const AK = 'AK';
const AS = 'AS';
const AZ = 'AZ';
const AR = 'AR';
const CA = 'CA';
const CO = 'CO';
const CT = 'CT';
const DE = 'DE';
const DC = 'DC';
const FL = 'FL';
const GA = 'GA';
const GU = 'GU';
const HI = 'HI';
const ID = 'ID';
const IL = 'IL';
const IN = 'IN';
const IA = 'IA';
const KS = 'KS';
const KY = 'KY';
const LA = 'LA';
const ME = 'ME';
const MD = 'MD';
const MA = 'MA';
const MI = 'MI';
const MN = 'MN';
const MS = 'MS';
const MO = 'MO';
const MT = 'MT';
const NE = 'NE';
const NV = 'NV';
const NH = 'NH';
const NJ = 'NJ';
const NM = 'NM';
const NY = 'NY';
const NC = 'NC';
const ND = 'ND';
const OH = 'OH';
const OK = 'OK';
const OR = 'OR';
const PA = 'PA';
const PR = 'PR';
const RI = 'RI';
const SC = 'SC';
const SD = 'SD';
const TN = 'TN';
const TX = 'TX';
const UT = 'UT';
const VI = 'VI';
const VT = 'VT';
const VA = 'VA';
const WA = 'WA';
const WV = 'WV';
const WI = 'WI';
const WY = 'WY';

export const provinceStateShortToLong = {
  [AB]: ALBERTA,
  [BC]: BRITISH_COLUMBIA,
  [MB]: MANITOBA,
  [NB]: NEW_BRUNSWICK,
  [NL]: NEWFOUNDLAND_LABRADOR,
  [NS]: NOVA_SCOTIA,
  [NT]: NORTHWEST_TERRITORIES,
  [NU]: NUNAVUT,
  [ON]: ONTARIO,
  [PE]: PRINCE_EDWARD_ISLAND,
  [QC]: QUEBEC,
  [SK]: SASKATCHEWAN,
  [YT]: YUKON,
  [AL]: ALABAMA,
  [AK]: ALASKA,
  [AS]: AMERICAN_SAMOA,
  [AZ]: ARIZONA,
  [AR]: ARKANSAS,
  [CA]: CALIFORNIA,
  [CO]: COLORADO,
  [CT]: CONNECTICUT,
  [DE]: DELAWARE,
  [DC]: DISTRICT_OF_COLUMBIA,
  [FL]: FLORIDA,
  [GA]: GEORGIA,
  [GU]: GUAM,
  [HI]: HAWAII,
  [ID]: IDAHO,
  [IL]: ILLINOIS,
  [IN]: INDIANA,
  [IA]: IOWA,
  [KS]: KANSAS,
  [KY]: KENTUCKY,
  [LA]: LOUISIANA,
  [ME]: MAINE,
  [MD]: MARYLAND,
  [MA]: MASSACHUSETTS,
  [MI]: MICHIGAN,
  [MN]: MINNESOTA,
  [MS]: MISSISSIPPI,
  [MO]: MISSOURI,
  [MT]: MONTANA,
  [NE]: NEBRASKA,
  [NV]: NEVADA,
  [NH]: NEW_HAMPSHIRE,
  [NJ]: NEW_JERSEY,
  [NM]: NEW_MEXICO,
  [NY]: NEW_YORK,
  [NC]: NORTH_CAROLINA,
  [ND]: NORTH_DAKOTA,
  [OH]: OHIO,
  [OK]: OKLAHOMA,
  [OR]: OREGON,
  [PA]: PENNSYLVANIA,
  [PR]: PUERTO_RICO,
  [RI]: RHODE_ISLAND,
  [SC]: SOUTH_CAROLINA,
  [SD]: SOUTH_DAKOTA,
  [TN]: TENNESSEE,
  [TX]: TEXAS,
  [UT]: UTAH,
  [VI]: US_VIRGIN_ISLANDS,
  [VT]: VERMONT,
  [VA]: VIRGINIA,
  [WA]: WASHINGTON,
  [WV]: WEST_VIRGINIA,
  [WI]: WISCONSIN,
  [WY]: WYOMING,
};

export const PROVINCE_CODES = {
  AB,
  BC,
  MB,
  NB,
  NL,
  NS,
  NT,
  NU,
  ON,
  PE,
  QC,
  SK,
  YT,
};

export const CANADA = 'CA';
export const CANADA_LONG_FORM = 'Canada';
export const UNITED_STATES = 'US';
export const IRELAND = 'IE';
export const US = UNITED_STATES;

export const COUNTRY_OPTIONS = [
  { value: CANADA, text: 'Canada', flag: 'ca' },
  {
    value: UNITED_STATES,
    text: 'United States of America',
    flag: 'us',
  },
  {
    value: IRELAND,
    text: 'Ireland',
    flag: 'ie',
  },
];

/* Short forms for province and state are deprecated */
export const PROVINCE_STATE_OPTIONS = {
  [CANADA]: [
    { value: AB, text: ALBERTA },
    { value: BC, text: BRITISH_COLUMBIA },
    { value: MB, text: MANITOBA },
    { value: NB, text: NEW_BRUNSWICK },
    { value: NL, text: NEWFOUNDLAND_LABRADOR },
    { value: NS, text: NOVA_SCOTIA },
    { value: NT, text: NORTHWEST_TERRITORIES },
    { value: NU, text: NUNAVUT },
    { value: ON, text: ONTARIO },
    { value: PE, text: PRINCE_EDWARD_ISLAND },
    { value: QC, text: QUEBEC },
    { value: SK, text: SASKATCHEWAN },
    { value: YT, text: YUKON },
  ],
  [UNITED_STATES]: [
    { value: AL, text: ALABAMA },
    { value: AK, text: ALASKA },
    { value: AZ, text: ARIZONA },
    { value: AR, text: ARKANSAS },
    { value: AS, text: AMERICAN_SAMOA },
    { value: CA, text: CALIFORNIA },
    { value: CO, text: COLORADO },
    { value: CT, text: CONNECTICUT },
    { value: DE, text: DELAWARE },
    { value: DC, text: DISTRICT_OF_COLUMBIA },
    { value: FL, text: FLORIDA },
    { value: GA, text: GEORGIA },
    { value: GU, text: GUAM },
    { value: HI, text: HAWAII },
    { value: ID, text: IDAHO },
    { value: IL, text: ILLINOIS },
    { value: IN, text: INDIANA },
    { value: IA, text: IOWA },
    { value: KS, text: KANSAS },
    { value: KY, text: KENTUCKY },
    { value: LA, text: LOUISIANA },
    { value: ME, text: MAINE },
    { value: MD, text: MARYLAND },
    { value: MA, text: MASSACHUSETTS },
    { value: MI, text: MICHIGAN },
    { value: MN, text: MINNESOTA },
    { value: MS, text: MISSISSIPPI },
    { value: MO, text: MISSOURI },
    { value: MT, text: MONTANA },
    { value: NE, text: NEBRASKA },
    { value: NV, text: NEVADA },
    { value: NH, text: NEW_HAMPSHIRE },
    { value: NJ, text: NEW_JERSEY },
    { value: NM, text: NEW_MEXICO },
    { value: NY, text: NEW_YORK },
    { value: NC, text: NORTH_CAROLINA },
    { value: ND, text: NORTH_DAKOTA },
    { value: OH, text: OHIO },
    { value: OK, text: OKLAHOMA },
    { value: OR, text: OREGON },
    { value: PA, text: PENNSYLVANIA },
    { value: PR, text: PUERTO_RICO },
    { value: RI, text: RHODE_ISLAND },
    { value: SC, text: SOUTH_CAROLINA },
    { value: SD, text: SOUTH_DAKOTA },
    { value: TN, text: TENNESSEE },
    { value: TX, text: TEXAS },
    { value: UT, text: UTAH },
    { value: VI, text: US_VIRGIN_ISLANDS },
    { value: VT, text: VERMONT },
    { value: VA, text: VIRGINIA },
    { value: WA, text: WASHINGTON },
    { value: WV, text: WEST_VIRGINIA },
    { value: WI, text: WISCONSIN },
    { value: WY, text: WYOMING },
  ],
};

export const COUNTRY_TO_PROVINCE = {
  [CANADA]: [
    ALBERTA,
    BRITISH_COLUMBIA,
    MANITOBA,
    NEW_BRUNSWICK,
    NEWFOUNDLAND_LABRADOR,
    NOVA_SCOTIA,
    NORTHWEST_TERRITORIES,
    NUNAVUT,
    ONTARIO,
    PRINCE_EDWARD_ISLAND,
    QUEBEC,
    SASKATCHEWAN,
    YUKON_TERRITORIES,
  ],
  [UNITED_STATES]: [
    ALABAMA,
    ALASKA,
    AMERICAN_SAMOA,
    ARIZONA,
    ARKANSAS,
    CALIFORNIA,
    COLORADO,
    CONNECTICUT,
    DELAWARE,
    DISTRICT_OF_COLUMBIA,
    FLORIDA,
    GEORGIA,
    GUAM,
    HAWAII,
    IDAHO,
    ILLINOIS,
    INDIANA,
    IOWA,
    KANSAS,
    KENTUCKY,
    LOUISIANA,
    MAINE,
    MARYLAND,
    MASSACHUSETTS,
    MICHIGAN,
    MINNESOTA,
    MISSISSIPPI,
    MISSOURI,
    MONTANA,
    NEBRASKA,
    NEVADA,
    NEW_HAMPSHIRE,
    NEW_JERSEY,
    NEW_MEXICO,
    NEW_YORK,
    NORTH_CAROLINA,
    NORTH_DAKOTA,
    OHIO,
    OKLAHOMA,
    OREGON,
    PENNSYLVANIA,
    PUERTO_RICO,
    RHODE_ISLAND,
    SOUTH_CAROLINA,
    SOUTH_DAKOTA,
    TENNESSEE,
    TEXAS,
    US_VIRGIN_ISLANDS,
    UTAH,
    VERMONT,
    VIRGINIA,
    WASHINGTON,
    WEST_VIRGINIA,
    WISCONSIN,
    WYOMING,
  ],
};

export const PROVINCES = {
  ALBERTA,
  BRITISH_COLUMBIA,
  MANITOBA,
  NEW_BRUNSWICK,
  NEWFOUNDLAND_LABRADOR,
  NOVA_SCOTIA,
  NORTHWEST_TERRITORIES,
  NUNAVUT,
  ONTARIO,
  PRINCE_EDWARD_ISLAND,
  QUEBEC,
  SASKATCHEWAN,
  YUKON_TERRITORIES,
};

export const STATES = {
  ALABAMA,
  ALASKA,
  AMERICAN_SAMOA,
  ARIZONA,
  ARKANSAS,
  CALIFORNIA,
  COLORADO,
  CONNECTICUT,
  DELAWARE,
  DISTRICT_OF_COLUMBIA,
  FLORIDA,
  GEORGIA,
  GUAM,
  HAWAII,
  IDAHO,
  ILLINOIS,
  INDIANA,
  IOWA,
  KANSAS,
  KENTUCKY,
  LOUISIANA,
  MAINE,
  MARYLAND,
  MASSACHUSETTS,
  MICHIGAN,
  MINNESOTA,
  MISSISSIPPI,
  MISSOURI,
  MONTANA,
  NEBRASKA,
  NEVADA,
  NEW_HAMPSHIRE,
  NEW_JERSEY,
  NEW_MEXICO,
  NEW_YORK,
  NORTH_CAROLINA,
  NORTH_DAKOTA,
  OHIO,
  OKLAHOMA,
  OREGON,
  PENNSYLVANIA,
  PUERTO_RICO,
  RHODE_ISLAND,
  SOUTH_CAROLINA,
  SOUTH_DAKOTA,
  TENNESSEE,
  TEXAS,
  US_VIRGIN_ISLANDS,
  UTAH,
  VERMONT,
  VIRGINIA,
  WASHINGTON,
  WEST_VIRGINIA,
  WISCONSIN,
  WYOMING,
};

export const ALLOCATION_OPTIONS = [
  { value: 'full-amount', text: 'one time' },
  { value: 'monthly', text: 'monthly' },
  { value: 'quarterly', text: 'Every 3 months' },
  { value: 'bi-annual', text: 'Every 6 months' },
];

export const ROLLOVER_OPTIONS = [
  { value: 'no-rollover', text: 'No Rollover' },
  { value: 'unspent-amount', text: 'Unspent Amount' },
  { value: 'unclaimed-expense', text: 'Unclaimed Expense' },
];

export const WAITING_PERIOD_OPTIONS = [
  { value: 'none', text: 'None, benefits start on date of hire' },
  { value: 'days', text: '# of days after date of hire' },
  {
    value: 'days_round_to_month',
    text: '# of days after date of hire, round up to the start of the next month',
  },
];

export const EMPLOYEE_STATE_MAP = {
  initial: 'invited',
  active: 'active',
  suspended: 'suspended',
  terminated: 'terminated',
  extension_of_benefits: 'extension_of_benefits',
};

export const EMPLOYEE_STATE_NAMES = {
  invited: 'INVITED',
  active: 'ACTIVE',
  suspended: 'SUSPENDED',
  terminated: 'TERMINATED',
  extension_of_benefits: 'EXTENSION_OF_BENEFITS',
};

export const DEPENDENT_RELATIONSHIP_MAP = {
  spouse: 'SPOUSE',
  common_law: 'COMMON_LAW',
  child: 'CHILD',
  parent: 'PARENT',
  grandparent: 'GRANDPARENT',
  grandchild: 'GRANDCHILD',
  uncle_aunt: 'UNCLE_AUNT',
  nephew_niece: 'NEPHEW_NIECE',
  sibling: 'SIBLING',
  other: 'OTHER',
  domestic_partnership: 'DOMESTIC_PARTNERSHIP',
  ineligible_child: 'INELIGIBLE_CHILD',
};

export const EOI = 'eoi';
export const BENEFICIARY = 'beneficiary';

export const PILL_RADIUS = '1000px';

export const ENROLLMENT_TYPE_ANNUAL = 'annual';
export const ENROLLMENT_TYPE_QLE = 'qle';
export const ENROLLMENT_TYPE_NEW_HIRE = 'new_hire';
export const ENROLLMENT_TYPE_ACTIVATION = 'activation';
export const ENROLLMENT_TYPE_BCC = 'benefit_class_change';

export const ENROLLMENT_TYPES = {
  ANNUAL: ENROLLMENT_TYPE_ANNUAL,
  NEW_HIRE: ENROLLMENT_TYPE_NEW_HIRE,
  QLE: ENROLLMENT_TYPE_QLE,
  ACTIVATION: ENROLLMENT_TYPE_ACTIVATION,
  BCC: ENROLLMENT_TYPE_BCC,
};

export const PLAN_ENROLLMENT_STATUSES = {
  OPEN: 'open',
  STARTED: 'started',
  PAUSED: 'paused',
  SUBMITTED: 'submitted',
  CLOSED: 'closed',
};

const PLAN_STATUS_CREATED = 'created';
const PLAN_STATUS_ACTIVE = 'active';
const PLAN_STATUS_ENROLLING = 'enrolling';
const PLAN_STATUS_EXPIRED = 'expired';

export const PLAN_STATUSES = {
  CREATED: PLAN_STATUS_CREATED,
  ACTIVE: PLAN_STATUS_ACTIVE,
  ENROLLING: PLAN_STATUS_ENROLLING,
  EXPIRED: PLAN_STATUS_EXPIRED,
};

/* Currencies */
const USD = 'USD';
const CAD = 'CAD';
const EUR = 'EUR';
const GBP = 'GBP';

export const CURRENCY_TYPES = {
  USD,
  CAD,
  EUR,
  GBP,
};

export const CURRENCY_SYMBOLS = {
  [USD]: '$',
  [CAD]: '$',
  [EUR]: '€',
  [GBP]: '£',
};

export const GROUP_TYPES = {
  EMPLOYER: 'employer',
  BUSINESS_PROFILE: 'business_profile',
  USER_GROUP: 'user_group',
};

export const defaultFlexDollarTermId = 'FLEXIBLE_DOLLARS';
export const defaultFlexDollarTerm = 'flexible dollars';

export const APP_ID = 'league-b2b-web';

export const TENANT_ID = {
  LEAGUE: 'league',
  PC_HEALTH: 'pchealth',
};

export const ROOT_NODE_ID = 'root';
export const PORTAL_NODE_IDS = {
  MODAL_PORTAL: 'modal-portal',
};

export const UTC = 'UTC';
export const MODAL_TITLE = 'modal-title';
export const MODAL_DESCRIPTION = 'modal-description';

export const CONSUMER = 'consumer';
export const ADMIN = 'admin';
export const HR_ADMIN = 'hr_admin';
export const HEALTH_ADMIN = 'health_admin';
export const CLAIMS_AGENT = 'claims_agent';
export const SUPER_ADMIN = 'super_admin';

// Supported Locale List for b2b
export const SUPPORTED_LOCALE_LIST = ['en', 'fr', 'es'];
