import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import { withFieldHints } from './FieldHints';
import { cloneDeep, set, setWith } from 'lodash';
import { flattenObject } from '../../utilities';

export const generateRows = values => {
  /*
      Note: we want to create five empty rows when value = {}
      and replace with values from rows if value != {}
  */
  const rows = Object.keys(values).map((key, index) => ({
    key,
    value: values[key],
    index,
  }));

  const objectList = [];
  /*
    the rows length should just be temporarily set to 5 by default if the value is < 5.
    in the next phase, there'll be add/remove button to add/remove row.
  */
  const length = rows.length < 5 ? 5 : rows.length;
  for (let i = 0; i < length; i += 1) {
    const row = rows[i] || {
      key: '',
      value: '',
      index: i,
    };
    objectList.push(row);
  }

  return objectList;
};

export class MapObject extends PureComponent {
  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFieldOnBlur = this.handleFieldOnBlur.bind(this);
    this.updateValues = this.updateValues.bind(this);

    this.state = {
      objectList: generateRows(props.value),
    };
  }

  // eslint-disable-next-line react/sort-comp -- FIXME: automatically added for existing issue
  updateValues() {
    /*
      Note: Even though the input field is updated, the values prop is not updated, since values prop
      cannot handle object, and the keys are dynamic in this component (i.e can be edited and changed)

      To update the values prop, we want to:
      1. Filter the map object from objectList state, and only include the ones with keys.
      2. Update the [name] map object with the new object from the objectList state.
      3. Filter the map object field from values.
      4. Flatten the map object, since values prop in old version of Formik, cannot handle object.
      5. Call setValues() from Formik, with the merged values, to update the values prop.
    */

    const { values, setValues, name } = this.props;

    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    const updatedMapObject = this.state.objectList
      .filter(obj => obj.key)
      .reduce((acc, obj) => set(acc, `${obj.key.toString()}`, obj.value), {});
    const flattenMapObject = flattenObject({ [name]: updatedMapObject });

    const valuesWithNamePropOmitted = Object.keys(values)
      .filter(key => !key.includes(name))
      .reduce((acc, key) => setWith(acc, key, values[key], Object), {});

    setValues({
      ...flattenObject(valuesWithNamePropOmitted),
      ...flattenMapObject,
    });
  }

  handleFieldChange(e, index, type) {
    /*
      Note: Update key or value in objectList state
    */
    // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate -- FIXME: automatically added for existing issue
    const objectList = cloneDeep(this.state.objectList);
    objectList[index][type] = e.target.value;

    this.setState({ objectList });
  }

  handleFieldOnBlur(e) {
    this.updateValues();
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.handleBlur(e); // call props handleBlur
  }

  render() {
    const { readOnly } = this.props;
    const { objectList } = this.state;
    return (
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>Key</Grid.Column>
          <Grid.Column>Value</Grid.Column>
        </Grid.Row>
        {objectList.map(({ index, key, value }) => (
          <Grid.Row key={index}>
            <Grid.Column>
              <Form.Input
                key={`${index}-key`}
                name={`${index}-key`}
                readOnly={readOnly}
                value={key}
                onBlur={this.handleFieldOnBlur}
                onChange={e => this.handleFieldChange(e, index, 'key')}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                key={`${index}-value`}
                name={`${index}-value`}
                readOnly={readOnly}
                value={value}
                onBlur={this.handleFieldOnBlur}
                onChange={e => this.handleFieldChange(e, index, 'value')}
              />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    );
  }
}

MapObject.propTypes = {
  value: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setValues: PropTypes.func.isRequired,
};

MapObject.defaultProps = {
  value: {},
  values: {},
};

export default withFieldHints(MapObject);
