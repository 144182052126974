import React from 'react';
import { Label, LabelText } from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import { useIntl } from '@leagueplatform/locales';
import { usePlanYearSelectOptions } from './use-plan-year-select.hook';

export const PlanYearSelect = props => {
  const { formatMessage } = useIntl();
  const planYearOptions = usePlanYearSelectOptions(props);

  return (
    <Label>
      <LabelText marginBottom="quarter">
        {formatMessage({ id: 'PLAN_YEAR' })}
      </LabelText>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        name="plan-year-select"
        width={275}
        height={48}
        options={planYearOptions}
      />
    </Label>
  );
};
