import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/genesis-commons';
import { DashboardView } from './dashboard.view';
import { Loading } from 'common/insight-dashboards/loading.view';
import { useInsightsDashboardURL } from './use-insights-dashboard-url.hook';

export const DashboardContainer = ({ dashboardId, groupId }) => {
  const [isLoadingView, setIsLoadingView] = useState(true);
  const { isLoadingDashboardURL, dashboardURL } = useInsightsDashboardURL({
    dashboardId,
    groupId,
  });

  useEffect(() => {
    // reset state to display loading while rendering new url
    setIsLoadingView(true);
  }, [dashboardId]);

  const handleOnLoad = useCallback(() => setIsLoadingView(false), []);

  return (
    <Box position="relative" marginTop="quarter">
      {(isLoadingDashboardURL || isLoadingView) && (
        <Box position="absolute" top={0} left={0} right={0}>
          <Loading />
        </Box>
      )}
      <DashboardView url={dashboardURL} onLoad={handleOnLoad} />
    </Box>
  );
};

DashboardContainer.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};
