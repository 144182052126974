/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { takeLatest, select, fork, all } from 'redux-saga/lib/effects';

import { request, AUTHENTICATE_CONNECTION } from 'common/websocket-redux';
import { setDimension, identify } from '@leagueplatform/analytics';

import {
  GET_USER_PROFILE,
  ON_GET_USER_PROFILE,
  ON_SET_NOTIFICATION_SETTING,
  SET_USER_NOTIFICATION_SETTING,
  REQUEST_USER_CONFIG,
  GET_USER_CONFIG,
} from './user.types';

import { selectUser } from './user.selectors';
import { selectUserGroupId } from './user-group.selectors';
import { selectUserIsHRAdmin } from './role.selectors';

import { getUserProfile, setUserProfile } from 'common/services';
import { getUserConfig } from 'common/services/get-user-config.service';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { call } from 'redux-saga/effects';

const HR_ADMIN_GA_DIMENSION = {
  NAME: 'dimension1',
  VALUE: 'hr-admin',
};

export function* requestUserProfile(action = {}) {
  const { user_id } = (action.payload || {}).info || {};
  yield request(GET_USER_PROFILE, getUserProfile({ user_id }));
}

export function* requestUserConfig() {
  yield request(REQUEST_USER_CONFIG, getUserConfig());
}

export function* applyAnalytics() {
  const user = yield select(selectUser);
  const groupId = yield select(selectUserGroupId);

  identify({
    id: user?.userId,
    employer_group_id: groupId,
    tenant_id: user?.tenantId,
  });

  const userIsHRAdmin = yield select(selectUserIsHRAdmin);
  if (userIsHRAdmin) {
    setDimension(HR_ADMIN_GA_DIMENSION.NAME, HR_ADMIN_GA_DIMENSION.VALUE);
  }
}

export function* setUserNotificationSettings({ payload: updatedSetting }) {
  yield request(
    SET_USER_NOTIFICATION_SETTING,
    setUserProfile({
      notification_preferences: {
        ...updatedSetting,
      },
    }),
  );
}

export function* showErrorToast(action) {
  if (action.type === REQUEST_USER_CONFIG.ERRORED) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'SORRY_ERROR_ONLOAD_PLEASE_REFRESH',
    });
  }
}

export function* userSaga() {
  yield all([
    fork(
      takeLatest,
      [AUTHENTICATE_CONNECTION.SUCCEEDED, ON_GET_USER_PROFILE],
      requestUserProfile,
    ),
    fork(takeLatest, AUTHENTICATE_CONNECTION.SUCCEEDED, requestUserConfig),
    fork(takeLatest, GET_USER_PROFILE.SUCCEEDED, applyAnalytics),
    fork(takeLatest, ON_SET_NOTIFICATION_SETTING, setUserNotificationSettings),
    fork(takeLatest, GET_USER_CONFIG, requestUserConfig),
    fork(takeLatest, [REQUEST_USER_CONFIG.ERRORED], showErrorToast),
  ]);
}
