import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DependentsReportsPresenter } from './dependents-report.view';
import { getDependentsReport } from './dependents-report.actions';
import { selectDependentsReportLoading } from './dependents-report.selectors';

const mapStateToProps = createStructuredSelector({
  dependentsReportLoading: selectDependentsReportLoading,
});

const mapDispatchToProps = {
  getDependentsReport,
};

export const DependentsReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DependentsReportsPresenter);
