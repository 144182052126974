/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
/* eslint-disable jsx-a11y/label-has-associated-control -- FIXME: automatically added for existing issue */
import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Message } from 'semantic-ui-react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import {
  Box,
  PrimaryButton,
  SecondaryButton,
  HeadingTwo,
  ErrorMessage,
} from '@leagueplatform/genesis-commons';
import { Modal, ModalButtons } from '../../ui-kit';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const StyledForm = styled(Form)`
  .field > label.form-label {
    font-weight: 500;
  }
`;

export const AddFundsForm = ({
  accounts,
  values,
  setFieldValue,
  handleSubmit,
  errors,
  onClose,
  topUpStatus,
  name,
  userId,
  groupId,
  getEmployeeSpendingAccounts,
  resetTopUpStatus,
}) => {
  useLayoutEffect(() => {
    resetTopUpStatus();
  }, [resetTopUpStatus]);

  useEffect(() => {
    getEmployeeSpendingAccounts({
      userId,
      groupId,
    });
  }, [userId, groupId, getEmployeeSpendingAccounts]);

  useEffect(() => {
    if (topUpStatus?.pending === false && topUpStatus?.error === false) {
      resetTopUpStatus();
      onClose();
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'ADD_FUNDS_SUCCESS',
        values: {
          userName: name,
          balance: (
            <FormattedNumber
              value={topUpStatus?.balance.F}
              style="currency" // eslint-disable-line react/style-prop-object
              currencyDisplay="symbol"
              currency={topUpStatus?.balance.C}
              minimumFractionDigits={2}
            />
          ),
        },
      });
    }
  }, [topUpStatus, resetTopUpStatus, onClose, name]);

  return (
    <Modal
      onClose={onClose}
      className="modal-employees-action-confirmation action-modal-add-funds"
    >
      <HeadingTwo>
        <FormattedMessage id="ADD_FUNDS_EMPLOYEE" values={{ employee: name }} />
      </HeadingTwo>
      <Box>
        <FormattedMessage
          id="THESE_FUNDS_ARE_ONE_TIME_ALLOCATION"
          values={{
            linkToCustomerSupport: (
              <a href="mailto:help@league.com">
                <FormattedMessage id="CONTACT_CUSTOMER_SUPPORT" />
              </a>
            ),
          }}
        />
      </Box>
      <Box
        marginY="oneAndHalf"
        className="modal-employees-action-confirmation__content"
      >
        <StyledForm>
          <Form.Field>
            <label className="form-label" htmlFor="account">
              <FormattedMessage id="ACCOUNT" />
            </label>
            <Form.Input
              name="account"
              type="text"
              value={accounts.find(o => o.id === values.benefitId)?.fullName}
              disabled
            />
            {errors && errors.benefitId && (
              <ErrorMessage>{errors.benefitId}</ErrorMessage>
            )}
          </Form.Field>
          <Form.Field>
            <label className="form-label" htmlFor="fundsToBeAdded">
              <FormattedMessage id="FUNDS_TO_BE_ADDED" />
            </label>
            <Form.Input
              icon="dollar"
              iconPosition="left"
              name="fundsToBeAdded"
              type="text"
              placeholder="0"
              value={values.amount}
              onChange={(_, data) => setFieldValue('amount', data.value)}
            />
            {errors && errors.amount && (
              <ErrorMessage>{errors.amount}</ErrorMessage>
            )}
          </Form.Field>
        </StyledForm>
        {topUpStatus?.error && <Message negative>{topUpStatus.error}</Message>}
      </Box>
      <ModalButtons>
        <SecondaryButton onClick={onClose} marginRight="one">
          <FormattedMessage id="CANCEL" />
        </SecondaryButton>
        <PrimaryButton onClick={handleSubmit}>
          <FormattedMessage id="UPDATE" />
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

AddFundsForm.propTypes = {
  values: PropTypes.shape({
    benefitId: PropTypes.string,
    amount: PropTypes.string,
  }),
  errors: PropTypes.shape({
    benefitId: PropTypes.string,
    amount: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      benefitName: PropTypes.string,
      benefitId: PropTypes.string,
    }),
  ),
  topUpStatus: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    balance: PropTypes.object,
    pending: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  benefitId: PropTypes.string,
  name: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  getEmployeeSpendingAccounts: PropTypes.func.isRequired,
  resetTopUpStatus: PropTypes.func.isRequired,
};

AddFundsForm.defaultProps = {
  values: {
    benefitId: '',
    amount: '',
  },
  errors: {},
  accounts: [],
  benefitId: undefined,
};
