import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@leagueplatform/genesis-commons';
import { PlanContextMenu } from './plan-context-menu';
import { TreeNodeValidationStatusIndicator } from './tree-node-validation-status-indicator.view';
import { useTreeNodeHeaderDisplayState } from './use-tree-node-header-display-state.hook';

export const TreeNodeHeader = ({
  terminal,
  node: { name, ...nodeRest },
  ...props
}) => {
  const { showStatusIndicator, showPlanContextMenu } =
    useTreeNodeHeaderDisplayState({
      name,
      ...nodeRest,
      ...props,
    });
  return (
    <Flex
      flexGrow={1}
      display="inline-flex"
      color="onSurface.text.primary"
      justifyContent="center"
      height="100%"
    >
      <Flex
        alignItems="center"
        fontSize="body2"
        lineHeight="body1"
        flexGrow={1}
        paddingLeft={terminal ? 'half' : 0}
      >
        {showStatusIndicator && (
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          <TreeNodeValidationStatusIndicator {...props} {...nodeRest} />
        )}
        {name}
      </Flex>
      {showPlanContextMenu && <PlanContextMenu />}
    </Flex>
  );
};

TreeNodeHeader.propTypes = {
  terminal: PropTypes.bool.isRequired,
  node: PropTypes.objectOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      entityType: PropTypes.string,
      name: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
  ).isRequired,
};
