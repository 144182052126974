import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useIntl } from '@leagueplatform/locales';
import { Link, Text } from '@leagueplatform/ui-kit';
import { SidebarLayout } from '../sidebar-layout.view';
import { StepPage } from '../step-page.view';
import { PageHeading, ResponsiveButton } from '../elements';
import { getSignInUrl } from 'apps/auth/auth.utils';
import { Error } from '../error.view';

export const AccountAlreadyActive = ({
  signInSelected,
  helpEmailSelected,
  config,
}) => {
  const { formatMessage } = useIntl();

  return config ? (
    <SidebarLayout>
      <StepPage>
        <PageHeading>
          {formatMessage({ id: 'YOUR_ACCOUNT_IS_ALREADY_ACTIVE' })}
        </PageHeading>
        <Text>{formatMessage({ id: 'LOOKS_LIKE_YOU_ALREADY_ACTIVATED' })}</Text>
        <Text>
          {formatMessage({ id: 'IF_THIS_WASNT_YOU' })}{' '}
          <Link
            onClick={helpEmailSelected}
            color="red"
            href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}
          >
            <FormattedMessage id="HELP_EMAIL" />
          </Link>
        </Text>
        <ResponsiveButton
          primary
          mt={24}
          onClick={() => {
            signInSelected();
            window.location = getSignInUrl();
          }}
        >
          {formatMessage({ id: 'SIGN_IN' })}
        </ResponsiveButton>
      </StepPage>
    </SidebarLayout>
  ) : (
    <Error />
  );
};

AccountAlreadyActive.propTypes = {
  signInSelected: PropTypes.func.isRequired,
  helpEmailSelected: PropTypes.func.isRequired,
  config: PropTypes.shape({
    url_slug: PropTypes.string,
  }),
};

AccountAlreadyActive.defaultProps = {
  config: null,
};
