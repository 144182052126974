import { combineReducers } from 'redux';
import { makeStatusReducer } from 'common/request/status.reducer';
import { STATUS } from 'common/request/status.constants';
import {
  GET_IDENTITY_PROVIDER_FOR_USER,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBER,
} from './user-profile.actions';

export const initialState = {
  fetchStatus: STATUS.IDLE,
  fetchIdentityProviderStatus: STATUS.IDLE,
  updateStatus: STATUS.IDLE,
  entities: {},
};

const entities = (state = initialState.entities, action = {}) => {
  switch (action.type) {
    case GET_IDENTITY_PROVIDER_FOR_USER.SUCCEEDED:
    case GET_USER_PROFILE.SUCCEEDED:
      return {
        ...state,
        [action.meta.userId]: {
          ...state[action.meta.userId],
          ...action.payload.info,
        },
      };
    case UPDATE_USER_PROFILE.SUCCEEDED:
      return {
        ...state,
        [action.meta.userId]: {
          ...state[action.meta.userId],
          user_profile: action.payload.info,
        },
      };

    case CHANGE_EMAIL.SUCCEEDED:
      return {
        ...state,
        [action.meta.userId]: {
          ...state[action.meta.userId],
          email: action.meta.email,
        },
      };

    case CHANGE_PHONE_NUMBER.SUCCEEDED:
      return {
        ...state,
        [action.meta.userId]: {
          ...state[action.meta.userId],
          phoneNumber: action.meta.confirmPhoneNumber,
        },
      };
    default:
      return state;
  }
};

export const userProfileReducer = combineReducers({
  fetchStatus: makeStatusReducer(GET_USER_PROFILE),
  fetchIdentityProviderStatus: makeStatusReducer(
    GET_IDENTITY_PROVIDER_FOR_USER,
  ),
  updateStatus: makeStatusReducer(UPDATE_USER_PROFILE),
  entities,
});
