import { createSelector } from 'reselect';
import { DAYFORCE_DRIVER, CANADA } from 'common/constants';
import { selectEmployerDetails } from '../selectors';
import { selectUser } from 'common/state/user/user.selectors';

export const selectApp = state => state?.apps?.['employer-experience'];

export const selectEmployerReports = createSelector(
  selectApp,
  app => app?.employerReports,
);

export const selectReportContentId = createSelector(
  selectEmployerReports,
  employerReports => employerReports?.reportContentId,
);

export const selectCountryCode = createSelector(
  selectEmployerDetails,
  details => details?.employer?.group?.countryCode,
);

export const selectDigitalBeneficiariesEnabled = createSelector(
  selectEmployerDetails,
  details =>
    details?.employer?.group?.employer?.digitalBeneficiariesEnabled ?? false,
);

export const selectCanShowPayrollReport = createSelector(
  selectApp,
  selectCountryCode,
  ({ employerPayrollReports }, countryCode) => {
    const isDayforce = employerPayrollReports?.hrDrivers?.some(
      driver => driver.driver_type === DAYFORCE_DRIVER,
    );
    return isDayforce || countryCode === CANADA;
  },
);

export const selectIsReportLoading = createSelector(
  selectEmployerReports,
  employerReports => employerReports?.loadingState,
);

export const selectReportError = createSelector(
  selectEmployerReports,
  employerReports => employerReports?.reportError,
);

export const selectUserEmail = createSelector(selectUser, user => user?.email);
