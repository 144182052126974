import React from 'react';
import PropTypes from 'prop-types';
import { DocumentsUploader } from 'common/components/documents-uploader/documents-uploader.container';
import { Required } from './required.view';
import { JSONSchemaFieldPropType } from '../prop-types';
import {
  Box,
  Label,
  Flex,
  LabelText,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { ErrorMessage } from './error-message.view';

export const DocumentsUploaderField = ({
  field,
  documents,
  setFieldValue,
  uploadDocument,
  sessionId,
  propSchema,
  isRequired,
  ...props
}) => {
  return (
    <Box width={1} marginY="one">
      <Label htmlFor={field.name}>
        <Flex>
          <LabelText>{propSchema.title || field.name}</LabelText>
          {isRequired && <Required />}
        </Flex>
        {propSchema.description && (
          <BodyTwoSecondary marginBottom="half">
            {propSchema.description}
          </BodyTwoSecondary>
        )}
      </Label>
      <DocumentsUploader
        id={field.name}
        documents={documents}
        onChange={updatedDocList => setFieldValue(field.name, updatedDocList)}
        uploadDocument={uploadDocument}
        sessionId={sessionId}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ErrorMessage {...props} />
    </Box>
  );
};

DocumentsUploaderField.propTypes = {
  ...JSONSchemaFieldPropType,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content_type: PropTypes.string,
      content_id: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  setFieldValue: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
};

DocumentsUploaderField.defaultProps = {
  documents: [],
};
