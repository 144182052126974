export const BILLING_PREFERENCE_CONSOLIDATED = 'consolidated';
export const BILLING_PREFERENCE_BY_DIVISION = 'by-division';
export const DEFAULT_BILLING_DIVISION_VALUE = 'default';
export const DEFAULT_BILLING_DIVISION_ID = '';
export const BILLING_PREFERENCE_CONSOLIDATED_MESSAGE =
  'Billing preference is consolidated';
export const INVOICE_ITEM_KEY = 'invoice_id';
export const DRYRUN_INVOICE_ITEM_KEY = 'content_reference_id';
export const INVOICE_SETTLED_STATE = 'settled';
export const INVOICE_INVOICED_STATE = 'invoiced';
export const INVOICE_PARTIALLY_PAID_STATE = 'partially_paid';
export const SELECT_ALL_DIVISIONS_ID = 'ALL_DIVISIONS';
export const INVOICE_INVOICE_PENDING_STATE = 'invoice_pending';
export const INVOICE_OPEN_STATE = 'Open';
export const INVOICE_PAID_IN_FULL_STATE = 'Paid In Full';
export const INVOICE_PENDING_APPROVAL_STATE = 'Pending Approval';
export const INVOICE_REJECTED_STATE = 'Rejected';
export const INVOICE_VOIDED_STATE = 'Voided';
export const CREDITMEMO_OPEN_STATE = 'Open';
export const CREDITMEMO_FULLY_APPLIED_STATE = 'Fully Applied';
export const CREDITMEMO_VOIDED_STATE = 'Voided';
