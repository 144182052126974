import { takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { importPlanDesign, exportPlanDesign } from 'common/services';
import {
  REQUEST_IMPORT_PLAN_DESIGN,
  IMPORT_PLAN_DESIGN,
  REQUEST_EXPORT_PLAN_DESIGN,
  EXPORT_PLAN_DESIGN,
} from './plan-design.actions';

export function* importPlanDesignRequest({ payload }) {
  const { spreadsheetUrl, groupId } = payload;
  yield request(
    IMPORT_PLAN_DESIGN,
    importPlanDesign({ spreadsheetUrl, groupId }),
  );
}

export function* exportPlanDesignRequest({ payload }) {
  const { groupId, benefitIds, exportBenefitIds } = payload;
  yield request(
    EXPORT_PLAN_DESIGN,
    exportPlanDesign({ groupId, benefitIds, exportBenefitIds }),
  );
}

export function* planDesignSaga() {
  yield takeLatest(REQUEST_IMPORT_PLAN_DESIGN, importPlanDesignRequest);
  yield takeLatest(REQUEST_EXPORT_PLAN_DESIGN, exportPlanDesignRequest);
}
