import React from 'react';
import { Text } from '@leagueplatform/genesis-commons';

const PageHead = props => (
  <Text
    as="h1"
    fontSize={{
      _: 'heading3',
      laptop: 'heading1',
    }}
    fontWeight="bold"
    marginY="oneAndHalf"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export { PageHead };
