import React from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colour, StyledComponentRefWrapper } from '@leagueplatform/ui-kit';

import { Box, SubtitleOne } from '@leagueplatform/genesis-commons';

const Link = styled(({ active, ...rest }) => (
  <Box
    display="inline-flex"
    paddingY="quarter"
    paddingX="one"
    borderRadius="pill"
    active={active}
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...rest}
  />
))`
  ${({ active }) =>
    active &&
    css`
      background-color: ${colour('purpleLightest')};
    `};
`;

const StyledRRLink = styled(StyledComponentRefWrapper(RRLink))`
  text-decoration: none;
`;

export const PillLink = ({ active, ...rest }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue
  <Link active={active} marginRight="half">
    <SubtitleOne>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <StyledRRLink {...rest} />
    </SubtitleOne>
  </Link>
);

PillLink.propTypes = {
  active: PropTypes.bool,
};

PillLink.defaultProps = {
  active: false,
};

export default PillLink;
