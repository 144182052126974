import {
  GET_AUDIT_FLOWS,
  GET_CARRIER_AUDITS_REPORT,
} from './carrier-audits-report.types';

export const getCarrierAuditsReport = payload => ({
  type: GET_CARRIER_AUDITS_REPORT.BASE,
  payload,
});

export const getAuditFlows = () => ({
  type: GET_AUDIT_FLOWS.BASE,
});
