import { takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getQuickActions } from 'common/services/member-experience/get-quick-actions.service';
import { LOAD_QUICK_ACTIONS } from './quick-actions.actions';

export function* loadQuickActionsSaga() {
  yield request(LOAD_QUICK_ACTIONS, getQuickActions());
}

export function* quickActionsSaga() {
  yield takeLatest(LOAD_QUICK_ACTIONS.BASE, loadQuickActionsSaga);
}
