import { createSelector } from 'reselect';

const selectAriaLiveRegionState = state => state.ariaLiveRegion;

export const selectAssertiveMessage = createSelector(
  selectAriaLiveRegionState,
  state => state.assertiveMessage,
);

export const selectPoliteMessage = createSelector(
  selectAriaLiveRegionState,
  state => state.politeMessage,
);
