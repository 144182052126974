import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import onClickOutside from 'react-onclickoutside';

class AddRenameInput extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.toggle();
  }

  render() {
    const {
      hasError,
      name,
      onChangeHandler,
      onSubmitHandler,
      shouldLoad,
      contentId,
      placeholderId,
    } = this.props;

    return (
      <Input
        error={hasError}
        className="input--league-right-action"
        value={name}
        onChange={onChangeHandler}
        action={{
          content: contentId,
          onClick: onSubmitHandler,
          loading: shouldLoad,
        }}
        placeholder={placeholderId}
      />
    );
  }
}

AddRenameInput.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  contentId: PropTypes.string.isRequired,
  placeholderId: PropTypes.string.isRequired,
  shouldLoad: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default onClickOutside(AddRenameInput);
