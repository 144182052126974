import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { connect } from 'react-redux';
import { ScrollContextConsumer } from 'common/components/scroll-context/scroll-context.view';

const Submit = ({ loading, isAdmin }) =>
  isAdmin && (
    <ScrollContextConsumer>
      {({ scrollTo }) => (
        <Button
          type="submit"
          value="Submit"
          color="violet"
          className="button--benefit-submit"
          style={{ marginTop: '2rem' }}
          loading={loading}
          onClick={() => scrollTo()}
        >
          <FormattedMessage id="SAVE_BENEFIT" />
        </Button>
      )}
    </ScrollContextConsumer>
  );

const mapStateToProps = state => ({
  isAdmin: selectUserIsAdmin(state),
});

Submit.propTypes = {
  loading: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
};

Submit.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps)(Submit);
