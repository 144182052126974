import { createRequestTypes } from 'common/websocket-redux';

export const BENEFIT_PREVIEW_REQUESTED = 'BENEFIT_PREVIEW_REQUESTED';

export function requestBenefitPreview({ benefitId, groupId }) {
  return {
    type: BENEFIT_PREVIEW_REQUESTED,
    payload: { benefitId, groupId },
  };
}

export const GET_BENEFIT_CARD_PREVIEW = createRequestTypes(
  'GET_BENEFIT_CARD_PREVIEW',
);
