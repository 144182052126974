import {
  REQUEST_GET_STATEMENT_OF_ACCOUNT,
  REQUEST_ENROLLMENT_SELECTION_REPORT,
} from './finance-reports.types';

export const getStatementOfAccount = () => ({
  type: REQUEST_GET_STATEMENT_OF_ACCOUNT,
});

export const getEnrollmentSelectionReport = payload => ({
  type: REQUEST_ENROLLMENT_SELECTION_REPORT,
  payload,
});
