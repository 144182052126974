export const benefitProductTypes = [
  'medical',
  'medical_services_and_supplies',
  'healthcare',
  'drug',
  'paramedical',
  'critical_illness',
  'best_doctors',
  'vision',
  'dental',
  'life_and_add',
  'eap',
  'std',
  'ltd',
  'dca',
  'lsa',
  'hsa',
  'hra',
  'health_savings_account',
  'flexible_spending_account',
  'pet',
  'travel',
  'charity',
];
