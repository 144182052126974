import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getTerminationCodes } from '../../actions';
import { selectTerminationCodes } from '../../selectors';
import { ActionsConfirmationTerminationCodeDropdown } from './actions-confirmation-termination-code-dropdown.view';

export const ActionsConfirmationTerminationCodeDropdownContainerComponent = ({
  options,
  getCodes,
  reasonIsEmpty,
  onChangeReason,
}) => {
  useEffect(() => {
    getCodes();
  }, [getCodes]);

  return (
    <ActionsConfirmationTerminationCodeDropdown
      options={options}
      reasonIsEmpty={reasonIsEmpty}
      onChange={onChangeReason}
    />
  );
};

ActionsConfirmationTerminationCodeDropdownContainerComponent.propTypes = {
  getCodes: PropTypes.func.isRequired,
  onChangeReason: PropTypes.func.isRequired,
  options: PropTypes.shape,
  reasonIsEmpty: PropTypes.bool,
};

ActionsConfirmationTerminationCodeDropdownContainerComponent.defaultProps = {
  reasonIsEmpty: false,
  options: [],
};

const ActionsConfirmationTerminationCodeDropdownContainer = connect(
  createStructuredSelector({
    options: selectTerminationCodes,
  }),
  { getCodes: getTerminationCodes },
)(ActionsConfirmationTerminationCodeDropdownContainerComponent);

export default ActionsConfirmationTerminationCodeDropdownContainer;
