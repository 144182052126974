import { all, fork } from 'redux-saga/lib/effects';
import { claimsSaga } from 'apps/employer-experience/pages/Claims/state/saga';
import { claimDetailsSaga } from 'apps/employer-experience/pages/claim-details/claim-details.saga';
import { employerDetailsSaga } from 'apps/employer-experience/pages/EmployerDetails';
import { feesSaga } from 'apps/employer-experience/pages/EmployerDetails/Billing/components/Fees/store/fees.sagas';
import { newEmployerSaga } from 'apps/employer-experience/pages/EmployerDetails/Profile';
import { employersSaga } from 'apps/employer-experience/pages/Employers';
import { financeToolsReportsSaga } from 'apps/employer-experience/pages/FinanceTools/finance-reports';
import { financeToolsInvoicingSaga } from 'apps/employer-experience/pages/FinanceTools/finance-invoicing';
import { healthConciergeSaga } from 'apps/employer-experience/pages/HealthConciergeTooling/health-concierge-tooling.saga';
import { memberToolingSaga } from 'apps/employer-experience/pages/MemberTooling/member-tooling.saga';
import { adminFeatureFlagsSaga } from 'apps/employer-experience/pages/flags/flags.saga';
import { employeeAddFundsSaga } from 'apps/employer-experience/pages/add-funds/add-funds.saga';
import { employeeSpendingAccountsSagas } from 'apps/employer-experience/employee-spending-accounts/employee-spending-accounts.saga';
import { userBenefitChangeAllocationSaga } from 'apps/employer-experience/pages/change-allocation';
import { scriptHelpersSaga } from 'apps/employer-experience/pages/ScriptHelpers/script-helpers.saga';
import { uploadFaqLinksSaga } from 'apps/employer-experience/pages/WalletTooling/upload-faq-links/upload-faq-links.saga';
import { recommendationIdsSaga } from 'apps/employer-experience/pages/WalletTooling/recommendation-ids/recommendation-ids.saga';
import { employerPayrollSaga } from 'apps/employer-experience/pages/EmployerDetails/Payroll/employer-payroll.saga';
import { careProviderAppointmentHistorySaga } from 'apps/employer-experience/pages/care-provider-history/care-provider-appointment-history/care-provider-appointment-history.saga';
import { benefitSelectionsToolSaga } from 'apps/employer-experience/pages/benefit-selections-tool/benefit-selections-tool.saga';
import { allocationToolSaga } from 'apps/employer-experience/pages/allocation-tool/allocation-tool.saga';
import { userProfileSaga } from 'apps/employer-experience/resources/user-profile/user-profile.saga';
import { userOptimumProfileSaga } from 'apps/employer-experience/resources/user-optimum-profile/user-optimum-profile.saga';
import { mapleUserSaga } from 'apps/employer-experience/resources/maple-user/maple-user.saga';
import { userVaccinationWorkflowSaga } from 'apps/employer-experience/resources/user-vaccination-workflow/user-vaccination-workflow.saga';

export function* leagueAdminSaga() {
  yield all([
    fork(claimsSaga),
    fork(claimDetailsSaga),
    fork(employersSaga),
    fork(employerDetailsSaga),
    fork(feesSaga),
    fork(newEmployerSaga),
    fork(employeeAddFundsSaga),
    fork(adminFeatureFlagsSaga),
    fork(employeeSpendingAccountsSagas),
    fork(userBenefitChangeAllocationSaga),
    fork(financeToolsReportsSaga),
    fork(financeToolsInvoicingSaga),
    fork(healthConciergeSaga),
    fork(memberToolingSaga),
    fork(scriptHelpersSaga),
    fork(uploadFaqLinksSaga),
    fork(recommendationIdsSaga),
    fork(employerPayrollSaga),
    fork(careProviderAppointmentHistorySaga),
    fork(benefitSelectionsToolSaga),
    fork(allocationToolSaga),
    fork(userProfileSaga),
    fork(userOptimumProfileSaga),
    fork(mapleUserSaga),
    fork(userVaccinationWorkflowSaga),
  ]);
}
