/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { GET_SPENDING_ACCOUNT_BALANCE_REPORT } from './spending-account-balance-report.types';
import { put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { getSpendingAccountBalanceReport } from 'common/services';
import {
  REPORT_GENERIC_ERROR,
  REPORT_NO_DATA_ERROR,
  REPORT_NO_DATA_ERROR_REASON,
} from '../../reports.constants';
import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
  ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
  ANALYTICS_EVENT_DOWNLOAD_FAILURE,
} from '../../../constants';

export function* requestSpendingAccountBalanceReport({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;
  const group_id = get(rest, 'groupId');

  try {
    yield put(GET_SPENDING_ACCOUNT_BALANCE_REPORT.start());
    const reportDate = get(rest, 'reportDate');
    const response = yield getSpendingAccountBalanceReport({
      group_id,
      effective_date: reportDate,
    });
    const url = getContentUrl(get(response, 'info.content_id'));
    window.location.assign(url);
    yield put(GET_SPENDING_ACCOUNT_BALANCE_REPORT.success());

    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'spending account balance',
        detail: 'generate spending account balance report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'spending account balance',
        detail: 'generate spending account balance report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
    const err =
      get(error, 'info.reason') === REPORT_NO_DATA_ERROR_REASON
        ? REPORT_NO_DATA_ERROR
        : REPORT_GENERIC_ERROR;
    yield put(GET_SPENDING_ACCOUNT_BALANCE_REPORT.error({ error: err }));
  }
}
