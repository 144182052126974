/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { JSONSchemaFieldPropType } from '../prop-types';
import { TextInput } from '@leagueplatform/genesis-commons';
import { FieldWrapper } from './field-wrapper.view';
import { useIntl } from '@leagueplatform/locales';

export const InputField = ({
  propSchema: { readOnly, examples, ...propSchema },
  type,
  field,
  autoComplete,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { errorMessage, name } = props;
  const errorId = errorMessage ? `${name}-error-id` : undefined;
  const formattedErrorMessage =
    errorMessage &&
    propSchema?.title &&
    errorMessage?.includes(formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' }))
      ? formatMessage({ id: 'FIELD_REQUIRED' }, { field: propSchema?.title })
      : errorMessage;

  return (
    <FieldWrapper
      {...propSchema}
      {...props}
      errorId={errorId}
      errorMessage={formattedErrorMessage}
    >
      <TextInput
        {...field}
        disabled={readOnly}
        error={Boolean(errorMessage)}
        type={type}
        placeholder={examples?.[0]}
        aria-required={propSchema?.required || undefined}
        aria-label={propSchema?.name || propSchema?.title}
        aria-describedby={errorId}
        autoComplete={autoComplete}
      />
    </FieldWrapper>
  );
};

InputField.propTypes = JSONSchemaFieldPropType;
