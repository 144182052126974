import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getUserBenefits,
  getUserBenefitContributions,
} from '../../../Payroll/employer-payroll.actions';
import { EmployeeDeductionsPresenter } from './employee-deductions.view';
import {
  selectContributionAccounts,
  selectContributions,
} from '../../../Payroll/employer-payroll.selectors';
import { selectGroupId } from '../../../selectors';
import { EMPLOYEE_TASA_DEDUCTIONS_LIFECYCLE } from '../../../Payroll/employer-payroll.types';

export const EmployeeDeductions = connect(
  createStructuredSelector({
    groupId: selectGroupId,
    contributionAccounts: selectContributionAccounts,
    contributions: selectContributions,
  }),
  {
    getUserBenefits,
    getUserBenefitContributions,
    exit: EMPLOYEE_TASA_DEDUCTIONS_LIFECYCLE.exit,
  },
)(EmployeeDeductionsPresenter);
