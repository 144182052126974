import { SET_CURRENT_GEOLOCATION } from './types';

const initialState = {
  latitude: null,
  longitude: null,
};

const applyCurrentGeolocation = (state, { coordinates }) => {
  const { latitude, longitude } = coordinates;
  return {
    ...state,
    latitude,
    longitude,
  };
};

export const geolocationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_GEOLOCATION:
      return applyCurrentGeolocation(state, action);
    default:
      return state;
  }
};
