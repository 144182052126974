import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RateSheetExtractPresenter } from './rate-sheet-extract.view';
import {
  selectEmployerBenefitClassTree,
  selectRateSheetExtractStatus,
  selectRateSheetExtractContentId,
  selectRateSheetExtractFileName,
} from '../employer-payroll.selectors';
import { getRateSheetExtract } from '../employer-payroll.actions';
import { getEmployerBenefitPlans } from '../../enrollment-config/enrollment-config.actions';
import { selectGroupId } from '../../selectors';

export const RateSheetExtract = connect(
  createStructuredSelector({
    structuredEmployeeBenefits: selectEmployerBenefitClassTree,
    status: selectRateSheetExtractStatus,
    contentId: selectRateSheetExtractContentId,
    fileName: selectRateSheetExtractFileName,
    groupId: selectGroupId,
  }),
  { getRateSheetExtract, getEmployerBenefitPlans },
)(RateSheetExtractPresenter);
