import { createRequestTypes } from 'common/websocket-redux';

export const VISITED = 'claim-details/VISITED';
export const EXITED = 'claim-details/EXITED';
export const INITIALIZE = createRequestTypes('claim-details/INITIALIZE');
export const CANCEL_CLAIM = createRequestTypes('claim-details/CANCEL_CLAIM');
export const TOGGLE_DOCUMENT_SELECTED =
  'claim-details/TOGGLE_DOCUMENT_SELECTED';
export const EDIT_CLAIM_SELECTED = 'claims/EDIT_CLAIM_SELECTED';
export const CANCEL_CLAIM_SELECTED = 'claims/CANCEL_CLAIM_SELECTED';
export const OPEN_CHAT_SELECTED = 'claims/OPEN_CHAT_SELECTED';

export const visited = payload => ({ type: VISITED, payload });

export const exited = payload => ({ type: EXITED, payload });

export const editClaimSelected = claimId => ({
  type: EDIT_CLAIM_SELECTED,
  payload: { claimId },
});

export const cancelClaimSelected = claimId => ({
  type: CANCEL_CLAIM_SELECTED,
  payload: { claimId },
});

export const chatClaimSelected = () => ({
  type: OPEN_CHAT_SELECTED,
});

export const onToggleDocumentSelected = selectedImageUrl => ({
  type: TOGGLE_DOCUMENT_SELECTED,
  payload: { selectedImageUrl },
});
