import { take, fork, takeLatest, call, put } from 'redux-saga/effects';
import {
  websocketSaga,
  CONFIGURE_CONNECTION,
  request,
  websocketFetch,
} from 'common/websocket-redux';
import {
  INVITE_PAGE_LIFECYCLE,
  DEPENDENT_INVITE_PAGE_LIFECYCLE,
  FETCH_INVITE,
  FETCH_DEPENDENT_INVITE,
  CREATE_ACCOUNT_GROUP_INVITE,
  CREATE_ACCOUNT_DEPENDENT_INVITE,
} from './invite.actions';
import { redirectToSignUpSaga } from 'apps/auth/auth0/auth0.saga';
import { DEPENDENT_INVITE_ERROR_STATUS } from './invite.constants';
import { APP_CONFIG } from 'app-config';

// GROUP INVITE
export const getGroupForInvitationCode = inviteId =>
  call(websocketFetch, 'get_group_for_invitation_code', { code: inviteId });

export function* initializeGroupInvite(action) {
  yield take(CONFIGURE_CONNECTION.SUCCEEDED);

  const { inviteId } = action?.payload?.props || {};
  yield request(FETCH_INVITE, getGroupForInvitationCode(inviteId));
}

export function* createAccountGroupInviteRequested(action) {
  const { inviteId } = action.payload;

  yield takeLatest(FETCH_INVITE.SUCCEEDED, redirectToSignUpSaga, action); // connect to auth0 only when invite is valid

  yield request(FETCH_INVITE, getGroupForInvitationCode(inviteId));
}

export function* groupInviteSaga() {
  yield takeLatest(INVITE_PAGE_LIFECYCLE.VISITED, initializeGroupInvite);
  yield takeLatest(
    CREATE_ACCOUNT_GROUP_INVITE.BASE,
    createAccountGroupInviteRequested,
  );
}

/** DEPENDENT INVITE SAGA */
export const getDependentInvite = inviteId =>
  call(websocketFetch, 'get_dependent_invite', { invite_code: inviteId });

export function* initializeDependentInvite(action) {
  yield take(CONFIGURE_CONNECTION.SUCCEEDED);

  const { inviteId } = action?.payload?.props || {};
  yield request(FETCH_DEPENDENT_INVITE, getDependentInvite(inviteId));
}

export function* createAccountDependentInviteRequested(action) {
  const { inviteId } = action.payload;
  yield put(FETCH_DEPENDENT_INVITE.start());

  try {
    const response = yield getDependentInvite(inviteId);
    yield put(FETCH_DEPENDENT_INVITE.success(response));

    // go to auth0 only when invite is not expired or redeemed
    if (!DEPENDENT_INVITE_ERROR_STATUS.includes(response.info.status)) {
      yield call(redirectToSignUpSaga, action);
    }
  } catch (err) {
    yield put(FETCH_DEPENDENT_INVITE.error(err));
  }
}

export function* dependentInviteSaga() {
  yield takeLatest(
    DEPENDENT_INVITE_PAGE_LIFECYCLE.VISITED,
    initializeDependentInvite,
  );
  yield takeLatest(
    CREATE_ACCOUNT_DEPENDENT_INVITE.BASE,
    createAccountDependentInviteRequested,
  );
}

export function* inviteSaga() {
  yield fork(websocketSaga, { websocketUrl: APP_CONFIG.REACT_APP_API_URL });
  yield fork(groupInviteSaga);
  yield fork(dependentInviteSaga);
}
