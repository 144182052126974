import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colour, Box } from '@leagueplatform/ui-kit';

const radioInnerHeight = '20px';
const radioCheckedHeight = '1.4rem';
const borderWidth = '2px';
const radioDimension = '24px'; // radioInnerHeight + 2* borderWidth

export const Wrapper = styled(Box)`
  height: ${radioDimension};
  width: ${radioDimension};
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  left: -9999px;
`;

export const RadioPresenter = styled(Box)`
  position: relative;
  margin: 0;
  line-height: ${radioInnerHeight};
  cursor: pointer;
  height: ${radioDimension};
  width: ${radioDimension};
  border-radius: 50%;

  ${({ disabled }) => disabled && `cursor: auto;`};

  ${/* sc-selector */ RadioButton}:focus + & {
    box-shadow: 0 0 0 3px ${colour('purpleLight')};
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${radioInnerHeight};
    height: ${radioInnerHeight};
    border: ${borderWidth} solid ${colour('greyLight')};
    border-radius: 100%;
    background: ${colour('white')};

    ${({ disabled }) =>
      disabled &&
      css`
        border-color: ${colour('greyLighter')};
        opacity: 0.5;
      `};

    ${({ error }) =>
      error &&
      css`
        border-color: ${colour('red')};
        background-color: ${colour('redLightest')};
      `};
  }

  &:after {
    content: '';
    width: ${radioCheckedHeight};
    height: ${radioCheckedHeight};
    background: ${colour('purple')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    opacity: 0;

    ${({ checked }) => checked && `opacity: 1;`};

    ${({ error }) =>
      error &&
      css`
        border-color: ${colour('red')};
        background-color: ${colour('red')};
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${colour('greyLight')};
      `};

    ${({ disabled, checked }) => checked && disabled && `opacity: 0.5;`};
  }
`;

export const EnrollmentRadio = ({
  checked,
  onClick,
  name,
  id,
  error,
  value,
  disabled,
  className,
  ...radioProps
}) => (
  <Wrapper className={className}>
    <RadioButton
      name={name}
      checked={checked}
      onClick={() => onClick(value)}
      id={id}
      disabled={disabled}
      value={value}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...radioProps}
    />
    <RadioPresenter
      checked={checked}
      error={error}
      disabled={disabled}
      onClick={() => onClick(value)}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...radioProps}
    />
  </Wrapper>
);

EnrollmentRadio.propTypes = {
  checked: PropTypes.oneOfType([
    PropTypes.oneOf(['true', 'false']),
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.oneOf(['true', 'false']),
    PropTypes.bool,
  ]),
  onClick: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

EnrollmentRadio.defaultProps = {
  checked: false,
  disabled: false,
  error: false,
  value: '',
  onClick: () => {},
  name: '',
  id: '',
  className: '',
};
