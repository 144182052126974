import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { vars, leagueFont } from '@leagueplatform/ui-kit';

const { greyLight, greyLightest, purple, white } = vars.colour;

const SelectButton = styled.button`
  ${leagueFont({ medium: true })};
  width: 100%;
  font-size: 1.6rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 1px solid ${greyLight};
  background-color: ${greyLightest};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  outline: none;
  ${({ active }) =>
    active &&
    css`
      background-color: ${purple};
      color: ${white};
      font-weight: 500;
    `};
`;

export const Button = props => (
  /* eslint-disable-next-line react/destructuring-assignment, react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */
  <SelectButton {...props}>{props.children}</SelectButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
};
