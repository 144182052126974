import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createHistory from 'history/createBrowserHistory';
import { addMessageType } from 'common/websocket-redux';
import { APP_CONFIG } from 'app-config';

const historyConfig = APP_CONFIG.REACT_APP_ROOT_ROUTE
  ? { basename: APP_CONFIG.REACT_APP_ROOT_ROUTE }
  : {};
const historyDefault = createHistory(historyConfig);
const sagaMiddleware = createSagaMiddleware();

function getMiddlewares(history) {
  const router = routerMiddleware(history);
  const middlewares = [sagaMiddleware, router];

  if (APP_CONFIG.REACT_APP_REDUX_LOG === 'enabled') {
    const logger = createLogger({
      actionTransformer: addMessageType,
      collapsed: true,
      duration: true,
    });
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
    return composeEnhancers(applyMiddleware(logger, ...middlewares));
  }

  return applyMiddleware(...middlewares);
}

export const createConfiguredStore = ({
  reducer,
  history = historyDefault,
  saga,
}) => {
  const store = createStore(reducer, getMiddlewares(history));
  sagaMiddleware.run(saga);
  return store;
};
