import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Container, Form, Header, Item, Message } from 'semantic-ui-react';
import { renderNothing } from 'recompose';
import {
  APPROVED,
  MORE_INFORMATION_NEEDED,
  PENDING_EXTERNAL_REVIEW,
  REJECTED,
  SUBMITTED,
  UPDATE,
} from 'common/constants';
import { CLAIM_STATUS_OPTIONS } from '../settlement.constants';
import { ClaimFormFields } from './form-fields.view';
import { MessageFormFields } from './message-form-fields.view';
import { SubmitButton } from './submit-button.view';
import { ConfirmAllocationAmounts } from './confirm-allocation-modal.view';
import { DuplicateClaimModal } from './duplicate-claim-modal.view';
import BenefitCoverageCategoryModal from '../benefit-coverage-category-modal/benefit-coverage-category-modal.view';

export const FieldsForUpdateType = props => {
  // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
  switch (props.updateType) {
    case REJECTED:
    case MORE_INFORMATION_NEEDED:
    case PENDING_EXTERNAL_REVIEW:
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      return <MessageFormFields {...props} />;
    case UPDATE:
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      return <ClaimFormFields {...props} />;
    case APPROVED:
      return [
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <ClaimFormFields key="claim" {...props} />,
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <MessageFormFields key="message" {...props} />,
      ];
    case SUBMITTED:
    default:
      return renderNothing();
  }
};

FieldsForUpdateType.propTypes = {
  updateType: PropTypes.string.isRequired,
};

export const SettlementForm = ({
  error,
  updating,
  initialClaimStatus,
  initialAllocations,
  categoryOptions,
  dependentOptions,
  vendorOptions,
  saveValuesRequested,
  vendorQueryChanged,
  funds,
  isOldBenefitsModelUser,
  values,
  canSubmit,
  canEditPolicyAllocations,
  updateType,
  handleSubmit,
  setFieldValue,
  custCareNote,
  claimDescription,
  duplicateClaim,
  approveDuplicateClaim,
  rejectDuplicateClaim,
  cancelClaimApproval,
  resolvingDuplicate,
  canReadjustAllocationAmounts,
  isAdjustingApprovedAllocations,
  editAllocations,
  stopEditingAllocations,
  closeConfirmEditedAllocations,
  isConfirmingEditedAllocations,
  updatedAllocationsSubmitted,
  isSelectingCoverageCategory,
  claimUserId,
  openSelectCoverageCategory,
  closeSelectCoverageCategory,
  targetBenefitId,
  setTargetBenefitId,
  handleSelectCategory,
  dateCreated,
  benefitNames,
}) => (
  <Container className="claim-settlement">
    <Item.Group className="claim-note">
      {error && (
        <Message negative>
          <Message.Header>Claim Update Failed</Message.Header>
          {error}
        </Message>
      )}
      {claimDescription && (
        <Message warning>
          <Message.Header as="h4">Latest Note from Member</Message.Header>
          {claimDescription}
        </Message>
      )}
      {custCareNote && (
        <Message info>
          <Message.Header as="h4">
            Latest Note from Customer Care
          </Message.Header>
          {custCareNote}
        </Message>
      )}
    </Item.Group>

    <Form onSubmit={handleSubmit}>
      <Header as="h2">
        <FormattedMessage id="STATUS" />
      </Header>
      <Form.Select
        name="status"
        value={values.status}
        search
        selection
        width={8}
        options={CLAIM_STATUS_OPTIONS[initialClaimStatus]}
        onChange={(event, { value }) => setFieldValue('status', value)}
      />
      <FieldsForUpdateType
        updateType={updateType}
        values={values}
        funds={funds}
        isOldBenefitsModelUser={isOldBenefitsModelUser}
        categoryOptions={categoryOptions}
        dependentOptions={dependentOptions}
        vendorOptions={vendorOptions}
        saveValuesRequested={saveValuesRequested}
        vendorQueryChanged={vendorQueryChanged}
        setFieldValue={setFieldValue}
        canEditPolicyAllocations={canEditPolicyAllocations}
        initialClaimStatus={initialClaimStatus}
        canReadjustAllocationAmounts={canReadjustAllocationAmounts}
        editAllocations={editAllocations}
        stopEditingAllocations={stopEditingAllocations}
        isAdjustingApprovedAllocations={isAdjustingApprovedAllocations}
        claimUserId={claimUserId}
        openSelectCoverageCategory={openSelectCoverageCategory}
        closeSelectCoverageCategory={closeSelectCoverageCategory}
        setTargetBenefitId={setTargetBenefitId}
        dateCreated={dateCreated}
        benefitNames={benefitNames}
      />
      {isAdjustingApprovedAllocations && !canSubmit && (
        <Message negative>
          Updated allocation amount total must have been edited, match previous
          total, have a selected Category, and not exceed any available balances
        </Message>
      )}
      <SubmitButton updateType={updateType} loading={updating} />
    </Form>
    {duplicateClaim && (
      <DuplicateClaimModal
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...duplicateClaim}
        resolvingDuplicate={resolvingDuplicate}
        onApprove={() => approveDuplicateClaim(values.claim_id)}
        onReject={() => rejectDuplicateClaim(values.claim_id)}
        onCancel={cancelClaimApproval}
      />
    )}
    {isConfirmingEditedAllocations && (
      <ConfirmAllocationAmounts
        newAllocations={values}
        initialAllocations={initialAllocations}
        onConfirm={handleSubmit}
        isOpen={isConfirmingEditedAllocations}
        onComplete={updatedAllocationsSubmitted}
        onClose={closeConfirmEditedAllocations}
        benefitNames={benefitNames}
      />
    )}
    {isSelectingCoverageCategory && (
      <BenefitCoverageCategoryModal
        onClose={closeSelectCoverageCategory}
        targetUserId={claimUserId}
        targetBenefitId={targetBenefitId}
        onCategorySelect={handleSelectCategory}
      />
    )}
  </Container>
);

SettlementForm.propTypes = {
  error: PropTypes.string,
  updating: PropTypes.bool,
  initialClaimStatus: PropTypes.string.isRequired,
  categoryOptions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
  dependentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  vendorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  saveValuesRequested: PropTypes.func.isRequired,
  vendorQueryChanged: PropTypes.func.isRequired,
  funds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      available: PropTypes.number,
      allocated: PropTypes.number,
    }),
  ).isRequired,
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  canSubmit: PropTypes.bool.isRequired,
  canEditPolicyAllocations: PropTypes.bool.isRequired,
  updateType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  custCareNote: PropTypes.string,
  claimDescription: PropTypes.string,
  duplicateClaim: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  resolvingDuplicate: PropTypes.bool,
  approveDuplicateClaim: PropTypes.func.isRequired,
  rejectDuplicateClaim: PropTypes.func.isRequired,
  cancelClaimApproval: PropTypes.func.isRequired,
  canReadjustAllocationAmounts: PropTypes.bool.isRequired,
  isAdjustingApprovedAllocations: PropTypes.bool.isRequired,
  editAllocations: PropTypes.func.isRequired,
  stopEditingAllocations: PropTypes.func.isRequired,
  closeConfirmEditedAllocations: PropTypes.func.isRequired,
  isConfirmingEditedAllocations: PropTypes.bool.isRequired,
  updatedAllocationsSubmitted: PropTypes.func.isRequired,
  initialAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      amountApproved: PropTypes.number,
    }),
  ),
  isOldBenefitsModelUser: PropTypes.bool.isRequired,
  claimUserId: PropTypes.string.isRequired,
  isSelectingCoverageCategory: PropTypes.bool.isRequired,
  openSelectCoverageCategory: PropTypes.func.isRequired,
  closeSelectCoverageCategory: PropTypes.func.isRequired,
  targetBenefitId: PropTypes.string.isRequired,
  setTargetBenefitId: PropTypes.func.isRequired,
  handleSelectCategory: PropTypes.func.isRequired,
  dateCreated: PropTypes.string.isRequired,
  benefitNames: PropTypes.shape({}),
};

SettlementForm.defaultProps = {
  error: null,
  updating: false,
  custCareNote: null,
  claimDescription: null,
  duplicateClaim: null,
  resolvingDuplicate: false,
  initialAllocations: [],
  dependentOptions: null,
  benefitNames: {},
};
