import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Formik } from 'formik-legacy';
import { combineValidators } from 'common/adaptive-forms';
import {
  selectGroupId,
  selectGroupCurrency,
  selectEmployerTimeZone,
} from '../../../../../../selectors';
import { validateDateRange } from 'apps/employer-experience-v2/benefits/utilities';
import {
  selectBenefitsClassName,
  mapPropsToValues,
  createValidatorSelector,
  createFormGeneratorSelector,
  createFormFormatterSelector,
} from './selectors';
import { selectWalletTemplates } from 'apps/employer-experience/pages/EmployerDetails/wallet-templates/wallet-templates.selectors';
import { addUpdateBenefit } from '../actions';
import { injectBenefitClassIdToAddUpdateBenefit } from './inject-benefit-class-id.hoc';

const DefaultBenefit = props => props.generateLayout(props);

DefaultBenefit.propTypes = {
  generateLayout: PropTypes.func.isRequired,
};

const mapStateToProps = (
  state,
  { benefitClassId, benefitPlanId, benefitType, layoutName, customComponents },
) => {
  return {
    benefitClassId,
    benefitPlanId,
    groupId: selectGroupId(state),
    groupCurrency: selectGroupCurrency(state),
    timeZone: selectEmployerTimeZone(state),
    benefitClassName: selectBenefitsClassName(state, benefitClassId),
    walletTemplates: selectWalletTemplates(state, benefitType),
    validate: createValidatorSelector(benefitType)(state),
    generateLayout: createFormGeneratorSelector(
      benefitType,
      layoutName,
      customComponents,
    )(state),
    prepareFormValues: createFormFormatterSelector(benefitType)(state),
  };
};

const withFormikEnhancer = Formik({
  validate: (values, props) =>
    combineValidators(props.validate, validateDateRange)(values),
  handleSubmit: (values, { props }) => props.addUpdateBenefit(values),
  mapPropsToValues,
  validateOnChange: true,
});

export default compose(
  connect(mapStateToProps, { addUpdateBenefit }),
  injectBenefitClassIdToAddUpdateBenefit,
  withFormikEnhancer,
)(DefaultBenefit);
