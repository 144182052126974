import React, { useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import AddBenefitPlan from './AddBenefitPlan';
import { BenefitPlan } from './BenefitPlan';
import { selectBenefitPlansForClass } from './selectors';

export const usePlanHandlers = ({
  fetchCatalogue: fetchCatalogueAction,
  fetchBenefitTypeSchemas: fetchBenefitTypeSchemasAction,
  fetchBenefits: fetchBenefitsAction,
  addBenefitPlan: addBenefitPlanAction,
  renameBenefitPlan: renameBenefitPlanAction,
  removeBenefitPlan: removeBenefitPlanAction,
  benefitClassId,
  groupId,
  structure,
  allBenefitPlanEntities,
  loading,
}) => {
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const benefitPlans = useMemo(() => {
    return selectBenefitPlansForClass(
      structure,
      benefitClassId,
      allBenefitPlanEntities,
    );
  }, [structure, benefitClassId, allBenefitPlanEntities]);
  const fetchCatalogue = useCallback(
    () => fetchCatalogueAction({ groupId }),
    [fetchCatalogueAction, groupId],
  );
  const fetchBenefitTypeSchemas = useCallback(
    () => fetchBenefitTypeSchemasAction({ groupId }),
    [fetchBenefitTypeSchemasAction, groupId],
  );
  const fetchBenefits = useCallback(
    ({ id: benefitPlanId }) =>
      fetchBenefitsAction({ benefitClassId, groupId, benefitPlanId }),
    [fetchBenefitsAction, benefitClassId, groupId],
  );
  const addBenefitPlan = useCallback(
    ({ name }) => {
      addBenefitPlanAction({
        name,
        groupId,
        benefitClassId,
        providerType: 'employer',
        providerId: groupId,
      });
    },
    [groupId, benefitClassId, addBenefitPlanAction],
  );
  const renameBenefitPlan = useCallback(
    ({ id, name }) => {
      renameBenefitPlanAction({
        name,
        id,
        groupId,
        benefitClassId,
        providerType: 'employer',
        providerId: groupId,
      });
    },
    [renameBenefitPlanAction, groupId, benefitClassId],
  );
  const removeBenefitPlan = useCallback(
    ({ id: benefitPlanId }) => {
      removeBenefitPlanAction({ benefitPlanId, groupId });
    },
    [removeBenefitPlanAction, groupId],
  );

  /* we want to track the initial load only since the plans are reloaded after
     each change to a benefit, which causes the current accordion state to reset unless
     we check against it loading once vs resetting loading state whenever that changes
  */

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else if (isLoading) {
      setIsInitiallyLoaded(true);
      setIsLoading(false);
    }
  }, [loading, isLoading, setIsInitiallyLoaded, setIsLoading]);

  return {
    benefitClassId,
    benefitPlans,
    fetchBenefits,
    fetchCatalogue,
    fetchBenefitTypeSchemas,
    addBenefitPlan,
    renameBenefitPlan,
    removeBenefitPlan,
    isInitiallyLoaded,
    loading,
  };
};

export const Plans = ({
  errorAddingBenefitPlan,
  addingBenefitPlan,
  errorRenamingBenefitPlan,
  renamingBenefitPlan,
  isLastBenefitPlan,
  ...props
}) => {
  const {
    benefitPlans,
    addBenefitPlan,
    isInitiallyLoaded,
    loading,
    ...planProps
  } = usePlanHandlers(props);
  return (
    <Dimmer.Dimmable
      as={Container}
      dimmed={loading}
      className="container--benefit-plans"
    >
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      {isInitiallyLoaded &&
        benefitPlans.map(({ name, id, planPeriod }) => (
          <BenefitPlan
            name={name}
            id={id}
            key={id}
            errorRenamingBenefitPlan={errorRenamingBenefitPlan}
            renamingBenefitPlan={renamingBenefitPlan}
            isLastBenefitPlan={isLastBenefitPlan}
            planPeriod={planPeriod}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...planProps}
          />
        ))}
      <AddBenefitPlan
        addBenefitPlan={addBenefitPlan}
        errorAddingBenefitPlan={errorAddingBenefitPlan}
        addingBenefitPlan={addingBenefitPlan}
      />
    </Dimmer.Dimmable>
  );
};

Plans.propTypes = {
  loading: PropTypes.bool.isRequired,
  errorAddingBenefitPlan: PropTypes.bool.isRequired,
  addingBenefitPlan: PropTypes.bool.isRequired,
  errorRenamingBenefitPlan: PropTypes.bool.isRequired,
  renamingBenefitPlan: PropTypes.string.isRequired,
  isLastBenefitPlan: PropTypes.bool.isRequired,
  benefitClassId: PropTypes.string.isRequired,
};
