import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectActiveDependents,
  selectInactiveDependents,
} from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { EmployeeDependentsView } from 'common/employee-dependents/employee-dependents.view';

const useEmployeeDependentsContainer = ({ groupId }) => {
  const activeDependents = useSelector(state =>
    selectActiveDependents(state, { groupId }),
  );
  const inactiveDependents = useSelector(state =>
    selectInactiveDependents(state, { groupId }),
  );

  return {
    activeDependents,
    inactiveDependents,
  };
};

export const EmployeeDependents = props => {
  const employeeDependentsViewProps = useEmployeeDependentsContainer(props);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <EmployeeDependentsView {...employeeDependentsViewProps} showAdd />;
};
