import React from 'react';
import { splitAtNewlines } from '@leagueplatform/web-common';
import { BodyOne } from '@leagueplatform/genesis-commons';

// Split string on newline and put each line into a new paragraph
export const splitToParagraphs = (text = '') =>
  splitAtNewlines(text).map(line => (
    <BodyOne key={line} marginY="half">
      {line}
    </BodyOne>
  ));

// Check if Internet Explorer, using Semantic UI's method
export const isIE = 'ActiveXObject' in window;
