import { ScheduledReportsPresenter } from './employer-scheduled-reports.view';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectPayrollSchedulesByPlanPeriod } from './employer-payroll.selectors';

export const ScheduledReports = connect(
  createStructuredSelector({
    payrollSchedules: selectPayrollSchedulesByPlanPeriod,
  }),
  {},
)(ScheduledReportsPresenter);
