import camelize from 'camelize';
import { combineReducers } from 'redux';
import { LOAD_QUICK_ACTIONS } from './quick-actions.actions';

export const data = (state = {}, action = {}) => {
  switch (action.type) {
    case LOAD_QUICK_ACTIONS.SUCCEEDED:
      return camelize(action?.payload?.info?.quick_actions_main_page) ?? {};

    default:
      return state;
  }
};

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const status = (state = STATUS.IDLE, action = {}) => {
  switch (action?.type) {
    case LOAD_QUICK_ACTIONS.STARTED:
      return STATUS.LOADING;
    case LOAD_QUICK_ACTIONS.SUCCEEDED:
      return STATUS.SUCCESS;
    case LOAD_QUICK_ACTIONS.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

export const quickActions = combineReducers({
  data,
  status,
});
