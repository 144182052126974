import { connect } from 'react-redux';
import {
  selectGroupId,
  selectIsBillingReady,
  selectPaymentMethod,
  selectGroupCreditContract,
  selectGroupBillingContact,
  selectEmployerBenefits,
  selectGroupBillingPreference,
  selectEmployerOutstandingBalance,
  selectEmployerOutstandingBalanceReady,
  selectEmployerOutstandingBalanceError,
} from './selectors';
import Billing from './billing.view';
import { selectGroupCurrency } from '../selectors';
import { adminGetEmployerOutstandingBalance } from './actions';
import { compose, lifecycle } from 'recompose';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  groupId: selectGroupId(state),
  ready: selectIsBillingReady(state),
  invoiceAsPayment: selectPaymentMethod(state),
  billingPreference: selectGroupBillingPreference(state),
  groupCreditContracts: selectGroupCreditContract(state),
  billingContact: selectGroupBillingContact(state),
  benefits: selectEmployerBenefits(state),
  outstandingBalance: selectEmployerOutstandingBalance(state),
  outstandingBalanceReady: selectEmployerOutstandingBalanceReady(state),
  outstandingBalanceError: selectEmployerOutstandingBalanceError(state),
  currency: selectGroupCurrency(state),
  isAdmin: selectUserIsAdmin(state),
});

const withState = connect(mapStateToProps, {
  adminGetEmployerOutstandingBalance,
});

const withLifecycle = lifecycle({
  componentDidMount() {
    if (this.props.isAdmin) {
      this.props.adminGetEmployerOutstandingBalance({
        groupId: this.props.groupId,
      });
    }
  },
});

export default compose(withState, withLifecycle, withRouter)(Billing);
