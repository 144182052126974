import { normalize } from 'normalizr';
import { LOAD_EMPLOYER_BENEFIT_PLANS } from './benefit-plans.actions';
import { benefitPlansSchema } from './benefit-plans.schemas';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const benefitPlansReducer = (state = {}, action) => {
  switch (action?.type) {
    case LOAD_EMPLOYER_BENEFIT_PLANS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const { entities, result } = normalize(
        action.payload.info,
        benefitPlansSchema,
      );
      return {
        ...state,
        byId: entities.plans,
        allIds: result.plans,
      };
    default:
      return state;
  }
};
