import {
  ADD_BENEFIT_CLASS,
  RENAME_BENEFIT_CLASS,
  REMOVE_BENEFIT_CLASS,
  FETCH_PLANS,
} from './types';

export const addBenefitClass = payload => ({
  type: ADD_BENEFIT_CLASS,
  payload,
});

export const renameBenefitClass = payload => ({
  type: RENAME_BENEFIT_CLASS,
  payload,
});

export const removeBenefitClass = payload => ({
  type: REMOVE_BENEFIT_CLASS,
  payload,
});

export const fetchPlans = payload => ({
  type: FETCH_PLANS,
  payload,
});
