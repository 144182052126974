import { concat } from 'lodash';
import camelize from 'camelize';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  GET_GROUPS,
  SEARCH_TEXT_CHANGED,
  SEARCH_PAGE_REQUESTED,
} from './types';

const initialState = {
  loading: false,
  error: false,
  employers: [],
};

const shouldReplaceEmployers = ({ type, meta }) =>
  type === GET_GROUPS.SUCCEEDED &&
  meta &&
  (meta.type === LOCATION_CHANGE || meta.type === SEARCH_TEXT_CHANGED);

const shouldAppendEmployers = ({ type, meta }) =>
  type === GET_GROUPS.SUCCEEDED && meta && meta.type === SEARCH_PAGE_REQUESTED;

const replaceEmployers = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  employers: camelize(action.payload.info.groups),
});

const appendEmployers = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  employers: concat(state.employers, camelize(action.payload.info.groups)),
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employersReducer = (state = initialState, action) => {
  if (!action) return state;
  if (shouldReplaceEmployers(action)) return replaceEmployers(state, action);
  if (shouldAppendEmployers(action)) return appendEmployers(state, action);
  switch (action.type) {
    case GET_GROUPS.STARTED:
      return { ...state, loading: true };
    case GET_GROUPS.ERRORED:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
