import React from 'react';
import NewEmployeeForm from '../Employee/NewEmployeeForm';
import Employees from '../Employees';
import BulkAddEmployees from '../BulkAddEmployees';
import { FieldSettings } from '../FieldSettings/field-settings.component';
import { EmployeeRouter } from '../Employee/employee-router.view';
import PropTypes from 'prop-types';
import { ScrollContextConsumer } from 'common/components/scroll-context/scroll-context.view';
import { Switch, Route, Redirect } from 'react-router-dom';

/* istanbul ignore next */

const EmployeeDetails = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/add`}
      render={() => (
        <ScrollContextConsumer>
          {({ scrollTo }) => <NewEmployeeForm scrollTo={scrollTo} />}
        </ScrollContextConsumer>
      )}
    />
    <Route
      exact
      path={`${match.url}/bulk-upload`}
      component={BulkAddEmployees}
    />
    <Route exact path={`${match.url}/list`} component={Employees} />
    <Route
      exact
      path={`${match.path}/field-settings`}
      component={FieldSettings}
    />
    <Route path={`${match.url}/:employeeId`} component={EmployeeRouter} />
    {/* eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue */}
    <Route component={() => <Redirect to={`${match.url}/list`} />} />
  </Switch>
);

EmployeeDetails.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default EmployeeDetails;
