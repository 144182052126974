import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { userReducer as user } from 'common/state/user/user.reducer';
import { uiReducer } from './ui-reducer';
import { userSettingsReducer as userSettings } from 'common/state/user-settings/user-settings.reducer';
import { geolocationReducer as geolocation } from 'common/state/geolocation/reducers';
import { featureFlagsReducer as featureFlags } from 'common/state/featureFlags/feature-flags.reducers';
import { authReducer } from 'apps/auth/auth.reducer';
import { coverageSummaryReducer as summaries } from 'common/coverage-summary';
import { benefitPlansReducer as benefitPlans } from 'common/benefit-plans';
import { insuranceFormsReducer as insuranceForms } from 'common/insurance-forms/insurance-forms.reducer';
import { groupsReducer as groups } from 'common/state/groups/groups.reducer';
import { officesReducer as offices } from 'common/state/offices/offices.reducer';
import { ariaLiveRegionReducer as ariaLiveRegion } from 'common/components/aria-live-region/aria-live-region.reducer';
import { benefitSelectionsDiffReducer as benefitSelectionsDiff } from 'apps/employer-experience/pages/benefit-selections-tool/benefit-selections-diff.reducer';
import { allocationToolReducer as allocationDryRun } from 'apps/employer-experience/pages/allocation-tool/allocation-tool.reducer';
import { application } from 'common/state/application/application.reducers';
import layouts from 'common/state/layouts/reducers';
import apps from 'apps/state';

export const createRootReducer = history =>
  combineReducers({
    application,
    apps,
    ariaLiveRegion,
    authentication: authReducer,
    benefitPlans,
    benefitSelectionsDiff,
    allocationDryRun,
    featureFlags,
    geolocation,
    groups,
    insuranceForms,
    layouts,
    offices,
    router: connectRouter(history),
    summaries,
    user,
    userSettings,
    ui: uiReducer,
  });

export default createRootReducer;
