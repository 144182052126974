import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon, Text } from '@leagueplatform/ui-kit';
import { ERROR, SUCCESS } from '../constants';

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledIcon = styled(props => <FontAwesomeIcon {...props} />)`
  margin: 0 0.3rem;
`;

// eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
export const StatusMessage = ({ status, message }) =>
  status && (
    <Text>
      {status === ERROR && <StyledIcon color="red" icon="times-circle" />}
      {status === SUCCESS && <StyledIcon color="green" icon="check-circle" />}
      <span>{message}</span>
    </Text>
  );

StatusMessage.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props -- FIXME: automatically added for existing issue
  visible: PropTypes.bool,
  status: PropTypes.string,
};

StatusMessage.defaultProps = {
  status: null,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  message: '',
};
