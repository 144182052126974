import React from 'react';
import PropTypes from 'prop-types';
import {
  getPolicyName,
  getUpdatedAllocationAmounts,
} from '../settlement.utilities';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  HeadingTwo,
  HeadingFour,
  BodyOne,
  PrimaryButton,
  SecondaryButton,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { vars, Box } from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const AccountTypeColumn = genesisStyled(Box)`
  flex-grow: 1;
`;

const AllocationsSummaryHeader = genesisStyled(Flex)`
  text-transform: uppercase;
  font-size: 90%;
`;

const AllocationRow = genesisStyled(Flex)`
  border-top: 1px solid ${vars.colour.greyLighter};
  padding: 0.5rem 0;
`;

const PreviousAmount = genesisStyled(Box)`
  text-decoration: line-through;
`;

const NewAmount = genesisStyled(Box)`
  font-weight: 500;
`;

const AllocationItem = ({ type, benefitNames, previousAmount, amount }) => (
  <>
    <AccountTypeColumn>{getPolicyName(type, benefitNames)}</AccountTypeColumn>
    <PreviousAmount mr={1}>${previousAmount}</PreviousAmount>
    <Box>&rarr;</Box>
    <NewAmount ml={1}>${amount}</NewAmount>
  </>
);

AllocationItem.propTypes = {
  type: PropTypes.string.isRequired,
  previousAmount: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  benefitNames: PropTypes.shape({}),
};

AllocationItem.defaultProps = {
  benefitNames: {},
};

const getTransformedAmounts = (initialAllocations, newAllocations) => {
  const updatedAllocations = getUpdatedAllocationAmounts(
    initialAllocations,
    newAllocations,
  );
  return updatedAllocations.map(updated => {
    const initial = initialAllocations.find(
      initialPolicy => initialPolicy.type === updated.type,
    );
    return {
      ...updated,
      previousAmountApproved: initial.amountApproved || 0,
    };
  });
};

export const ConfirmAllocationAmounts = ({
  isOpen,
  initialAllocations,
  newAllocations,
  onClose,
  onConfirm,
  onComplete,
  benefitNames,
}) => (
  <Modal open={isOpen} onClose={onClose} closeIcon>
    <HeadingTwo>Readjust Allocation Amounts</HeadingTwo>
    <ModalContent>
      <Box ml={3} mr={3}>
        <HeadingFour>Please confirm the new amounts</HeadingFour>
        <BodyOne>
          Please confirm the updated values.{' '}
          <strong>
            It may not be possible to adjust allocations on a claim that has
            already been paid
          </strong>
        </BodyOne>
        <AllocationsSummaryHeader pb={1}>
          <AccountTypeColumn>Type</AccountTypeColumn>
          <Box>Amount</Box>
        </AllocationsSummaryHeader>
        {getTransformedAmounts(initialAllocations, newAllocations).map(
          alloc => (
            <AllocationRow key={alloc.type}>
              <AllocationItem
                key={alloc.type}
                type={alloc.type}
                previousAmount={alloc.previousAmountApproved}
                amount={alloc.amountApproved}
                benefitNames={benefitNames}
              />
            </AllocationRow>
          ),
        )}
      </Box>
    </ModalContent>
    <ModalButtons>
      <SecondaryButton onClick={onClose} marginRight="one">
        Cancel
      </SecondaryButton>
      <PrimaryButton
        onClick={evt => {
          onConfirm(evt);
          onComplete();
        }}
      >
        Submit
      </PrimaryButton>
    </ModalButtons>
  </Modal>
);

ConfirmAllocationAmounts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  newAllocations: PropTypes.shape({
    claim_id: PropTypes.string,
    user_id: PropTypes.string,
    'policy_allocations.psa.amount_approved': PropTypes.number,
    'policy_allocations.hsa.amount_approved': PropTypes.number,
  }).isRequired,
  initialAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      policyKind: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  benefitNames: PropTypes.shape({}),
};

ConfirmAllocationAmounts.defaultProps = {
  benefitNames: {},
};
