import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  HeadingTwo,
  Box,
  SubtleButton,
  BodyTwo,
  Image,
  Flex,
} from '@leagueplatform/genesis-commons';
import { Modal, ModalButtons } from 'apps/employer-experience/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { FileUpload } from 'apps/upload/file-upload.view';
import WarningCircle from 'apps/public/assets/warning-icon-orange.svg';

export const UpdateGroupCategoriesModalPresenter = ({
  benefitName,
  onFileUploaded,
  close,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Modal onClose={close}>
        <HeadingTwo>
          {formatMessage({ id: 'UPDATE_GROUP_CATEGORIES' }, { benefitName })}
        </HeadingTwo>
        <Box marginY="oneAndHalf">
          <FileUpload width={1} onSuccess={onFileUploaded} />
          <BodyOne marginTop="one">
            {formatMessage({ id: 'UPDATE_GROUP_CATEGORIES_DESCRIPTION' })}
          </BodyOne>
          <Flex
            backgroundColor="warning.background.subdued"
            marginTop="one"
            paddingY="half"
            paddingX="one"
            borderStyle="solid"
            borderWidth="thin"
            borderColor="warning.border.default"
            borderRadius="medium"
            alignItems="center"
          >
            <Image
              src={WarningCircle}
              role="presentation"
              height="oneAndHalf"
              width="oneAndHalf"
              marginRight="one"
            />
            <BodyTwo>Any unsaved benefit changes will be lost.</BodyTwo>
          </Flex>
        </Box>
        <ModalButtons>
          <SubtleButton onClick={close}>
            {formatMessage({ id: 'CANCEL' })}
          </SubtleButton>
        </ModalButtons>
      </Modal>
    </div>
  );
};

UpdateGroupCategoriesModalPresenter.propTypes = {
  benefitName: PropTypes.string.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default UpdateGroupCategoriesModalPresenter;
