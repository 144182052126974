import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { JSONSchemaDefinitionPropType } from '../prop-types';
import { Field } from 'formik';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { RenderFieldByPropSchema } from './render-field-by-prop-schema';

export const EntityFormField = ({
  name,
  propSchema,
  isRequired = false,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const errorMessage = get(form.errors, name, null); // name can be in dot notation for nested fields
        const fieldProps = {
          field,
          form,
          name,
          propSchema,
          isRequired,
          errorMessage,
          ...props,
        };
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        return <RenderFieldByPropSchema {...fieldProps} />;
      }}
    </Field>
  );
};

EntityFormField.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  propSchema: JSONSchemaDefinitionPropType,
};

EntityFormField.defaultProps = {
  isRequired: false,
  propSchema: {},
};
