import { fork, takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getCareProviderAppointmentHistory } from './care-provider-appointment-history.service';
import { GET_CARE_PROVIDER_APPOINTMENT_HISTORY } from './care-provider-appointment-history.actions';

export function* requestCareProviderAppointmentHistory({
  payload: { userId },
}) {
  yield request(
    GET_CARE_PROVIDER_APPOINTMENT_HISTORY,
    getCareProviderAppointmentHistory({
      userId,
    }),
  );
}

export function* careProviderAppointmentHistorySaga() {
  yield fork(
    takeLatest,
    GET_CARE_PROVIDER_APPOINTMENT_HISTORY.BASE,
    requestCareProviderAppointmentHistory,
  );
}
