import { takeLatest } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  CHANGE_USER_BENEFIT_CHANGE_ALLOCATION,
  DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION,
} from './change-allocation.types';

export function* changeUserBenefitAllocation({ payload }) {
  const { benefitId, amount } = payload;
  yield request(DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION, [
    websocketFetch,
    'set_user_benefit_allocation',
    {
      benefit_id: benefitId,
      amount,
    },
  ]);
}

export function* userBenefitChangeAllocationSaga() {
  yield takeLatest(
    CHANGE_USER_BENEFIT_CHANGE_ALLOCATION,
    changeUserBenefitAllocation,
  );
}
