import {
  selectReportContentId,
  selectIsReportLoading,
  selectReportError,
  selectUserEmail,
} from './employer-reports.selectors';
import { createStructuredSelector } from 'reselect';
import { getEmployerEnrolmentProgressReport } from './employer-reports.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { EnrolmentProgressReport } from './enrolment-progress-report.view';
import { ENROLMENT_PROGRESS_REPORT_LIFECYCLE } from './employer-reports.types';

const mapStateToProps = connect(
  createStructuredSelector({
    contentId: selectReportContentId,
    loadingState: selectIsReportLoading,
    reportError: selectReportError,
    email: selectUserEmail,
  }),
);

const withActions = connect(null, {
  getEmployerEnrolmentProgressReport,
  visit: ENROLMENT_PROGRESS_REPORT_LIFECYCLE.visit,
});

export const EnrolmentProgressContainer = compose(
  mapStateToProps,
  withActions,
)(EnrolmentProgressReport);
