import React from 'react';
import { useDispatch } from 'react-redux';
import { requestCSVGeneration } from './reports.actions';
import { GenerateCSVForm } from './generate-csv.view';

const useGenerateCSV = props => {
  const dispatch = useDispatch();
  const getUsageReport = data => dispatch(requestCSVGeneration(data));

  return {
    ...props,
    getUsageReport,
  };
};

export const GenerateCSV = props => {
  const generateCSVProps = useGenerateCSV(props);
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <GenerateCSVForm {...generateCSVProps} />;
};
