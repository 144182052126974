import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  branch,
  lifecycle,
  renderComponent,
  withState,
  withHandlers,
} from 'recompose';
import { Loader } from 'semantic-ui-react';
import { Formik } from 'formik-legacy';
import { injectIntl } from 'react-intl';
import {
  selectProfileIsUpdating,
  selectIsEditEmployerFormReady,
  selectEmployerCreationError,
  selectFormattedFormValues,
  selectValidator,
  selectServiceStatus,
  selectEmployerStatus,
  selectEmployerSuspendStatus,
  selectEditFormSchema,
  selectEditFormGenerator,
} from '../selectors';
import { selectEmployerName } from '../../selectors';
import {
  editEmployerVisited,
  editEmployerSubmitted,
  publishEmployer,
  activateEmployer,
  suspendEmployer,
} from '../actions';
import Form from './Form';
import PropTypes from 'prop-types';

const withStates = connect(
  createStructuredSelector({
    profileIsUpdating: selectProfileIsUpdating,
    error: selectEmployerCreationError,
    companyName: selectEmployerName,
    formValues: selectFormattedFormValues,
    validate: selectValidator,
    serviceStatus: selectServiceStatus,
    employerStatus: selectEmployerStatus,
    suspended: selectEmployerSuspendStatus,
    schema: selectEditFormSchema,
    generateForm: selectEditFormGenerator,
  }),
  {
    editEmployerSubmitted,
    publishEmployer,
    activateEmployer,
    suspendEmployer,
  },
);

const withFormikEnhancer = Formik({
  validate: (values, props) => props.validate(values),
  handleSubmit: (values, { props }) => props.editEmployerSubmitted(values),
  mapPropsToValues: props => props.formValues,
});

const withReadyState = connect(
  state => ({ ready: selectIsEditEmployerFormReady(state) }),
  { editEmployerVisited },
);

const FormContainer = props => {
  // eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment -- FIXME: automatically added for existing issue
  return <Form {...props}>{props.generateForm(props)}</Form>;
};

const withInitializer = lifecycle({
  componentDidMount() {
    this.props.editEmployerVisited();
  },
});

const withLoadingSpinner = branch(
  props => !props.ready,
  renderComponent(() => <Loader active inline="centered" />),
);

const withConfirmationState = compose(
  withState('isConfirmationVisible', 'setConfirmationVisibility', false),
  withHandlers({
    toggleVisibility:
      ({ setConfirmationVisibility, isConfirmationVisible }) =>
      () =>
        setConfirmationVisibility(!isConfirmationVisible),
  }),
);

FormContainer.propTypes = {
  generateForm: PropTypes.func.isRequired,
};

export const EditEmployerForm = compose(
  withReadyState,
  withInitializer,
  withLoadingSpinner,
  withStates,
  withConfirmationState,
  withFormikEnhancer,
  injectIntl,
)(FormContainer);
