import {
  GET_EMPLOYEE_SPENDING_ACCOUNTS,
  RESET_EMPLOYEE_SPENDING_ACCOUNTS,
} from './employee-spending-accounts.types';

export const getEmployeeSpendingAccounts = ({ userId, groupId }) => ({
  type: GET_EMPLOYEE_SPENDING_ACCOUNTS,
  payload: {
    userId,
    groupId,
  },
});

export const resetEmployeeSpendingAccounts = () => ({
  type: RESET_EMPLOYEE_SPENDING_ACCOUNTS,
});
