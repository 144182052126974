import { takeLatest, fork, put, all, call } from 'redux-saga/effects';
import { GET_CURRENT_GEOLOCATION } from 'common/state/geolocation/types';
import { getActiveGroup } from '@leagueplatform/web-common';
import { INIT_GEOLOCATION, GET_GROUP } from './types';
import { homeFeedSaga } from '../pages/HomeFeed/home-feed.saga';
import { notificationsSaga } from '../pages/notifications/notifications.saga';
import { walletSagas } from '../pages/wallet/wallet.sagas';
import { benefitPreviewSaga } from '../pages/wallet/benefit-preview';
import { rewardsPageSaga } from '../pages/Rewards/rewards-page.saga';
import { parentCategoryPageSagas } from '../pages/Solutions/ParentCategoryPage/parent-category-page.saga';
import { claimsSaga } from '../pages/claims/claim-history';
import { claimDetailsSaga } from '../pages/claims/claim-details';
import { submitClaimSaga } from '../pages/claims/submit-claim/state/submit-claim.saga';
import { bankingInfoSagas } from '../pages/banking-info/banking-info.sagas';
import { directDepositAccountSagas } from '../pages/banking-info/direct-deposit-account/direct-deposit-account.sagas';
import { beneficiariesSaga } from '../pages/beneficiaries/beneficiaries.saga';
import { getGroup as _getGroup } from 'common/services';
import { request } from 'common/websocket-redux';
import { isDesktop } from '@leagueplatform/analytics';
import { setURLToRedirectAfterLogin } from 'apps/auth/auth.utils';
import { GET_USER_PROFILE } from 'common/state/user/user.types';
import { get } from 'lodash';
import { qleScreenerSagas } from '../pages/QleScreener/qle-screener.sagas';
import { manageDependentsSaga } from '../pages/manage-dependents/manage-dependents.sagas';
import { qleRequestSagas } from '../pages/QleRequest/qle-request.sagas';
import { chatSagas } from '../pages/Chat/chat-page.saga';
import { chatWindowSagas } from '../pages/Chat/ChatWindow/chat-window.saga';
import { insuranceFormsSaga } from 'common/insurance-forms/insurance-forms.saga';
import { isMobileAvailable } from './is-mobile-available';
import { quickActionsSaga } from './quick-actions/quick-actions.saga';
import { enrollDependentSaga } from 'apps/enroll-dependent/enroll-dependent.saga';
import { reactHostSaga } from 'apps/member-experience/components/AppMember/react-host/react-host.saga';
import { paymentCredentialsSaga } from 'apps/member-experience/resources/payment-credentials/payment-credentials.saga';
import { userProfileSaga } from '../pages/UserProfile/profile-page.saga';
import { healthRewardsSaga } from '../resources/rewards/health-rewards.saga';

export function* setGettingGeolocation() {
  yield put({ type: GET_CURRENT_GEOLOCATION });
}

export function* watchGeolocationInit() {
  yield takeLatest(INIT_GEOLOCATION, setGettingGeolocation);
}

export function* getGroup(action) {
  const groups = get(action, 'payload.info.groups');
  const groupId = get(getActiveGroup(groups), 'group_id');
  if (groupId) {
    yield request(GET_GROUP, _getGroup(groupId));
  }
}

export function* memberExperienceSaga() {
  if (!isDesktop(navigator.userAgent) && !isMobileAvailable()) {
    yield call(setURLToRedirectAfterLogin);

    window.location = '/download-app';
  } else {
    yield all([
      fork(homeFeedSaga),
      fork(notificationsSaga),
      fork(watchGeolocationInit),
      fork(walletSagas),
      fork(submitClaimSaga),
      fork(benefitPreviewSaga),
      fork(rewardsPageSaga),
      fork(healthRewardsSaga),
      fork(parentCategoryPageSagas),
      fork(claimsSaga),
      fork(claimDetailsSaga),
      fork(bankingInfoSagas),
      fork(directDepositAccountSagas),
      fork(takeLatest, GET_USER_PROFILE.SUCCEEDED, getGroup),
      fork(qleScreenerSagas),
      fork(manageDependentsSaga),
      fork(qleRequestSagas),
      fork(chatSagas),
      fork(chatWindowSagas),
      fork(quickActionsSaga),
      fork(enrollDependentSaga),
      fork(reactHostSaga),
      beneficiariesSaga(),
      insuranceFormsSaga(),
      fork(paymentCredentialsSaga),
      fork(userProfileSaga),
    ]);
  }
}
