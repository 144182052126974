/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { request, websocketFetch } from 'common/websocket-redux';
import { call } from 'redux-saga/effects';
import { GET_HEALTH_ACTIVITIES } from './health-activities-page.actions';

export const getHealthActivitiesForAdmin = ({ userId: user_id, limit, skip }) =>
  call(
    websocketFetch,
    'admin_get_user_completed_activities_and_points_earned',
    {
      user_id,
      limit,
      skip,
    },
  );

export function* requestHealthActivities({ payload }) {
  yield request(GET_HEALTH_ACTIVITIES, getHealthActivitiesForAdmin(payload));
}
