import {
  EMPLOYER_ADJUSTMENTS_LIFECYCLE,
  EMPLOYER_ADJUSTMENTS_INIT,
  ADD_ADJUSTMENT,
} from './adjustments.types';

export const initialState = {
  ready: false,
  adding: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerAdjustmentsUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYER_ADJUSTMENTS_INIT.SUCCEEDED:
      return { ...state, ready: true };
    case EMPLOYER_ADJUSTMENTS_LIFECYCLE.EXITED:
      return initialState;
    case ADD_ADJUSTMENT.STARTED:
      return { ...state, adding: true };
    case ADD_ADJUSTMENT.SUCCEEDED:
      return { ...state, adding: false };
    default:
      return state;
  }
};
