import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/genesis-commons';
import { INVITE_STATUS } from '../invite.constants';

export const useDependentInviteError = ({ inviteStatus }) => {
  const { formatMessage } = useIntl();

  const getErrorMessageTitle = () => {
    switch (inviteStatus) {
      case INVITE_STATUS.EXPIRED:
        return formatMessage({ id: 'YOUR_INVITE_HAS_EXPIRED' });
      case INVITE_STATUS.REDEEMED:
        return formatMessage({ id: 'YOUR_INVITE_WAS_REDEEMED' });
      default:
        return formatMessage({ id: 'INVITE_ERROR_SOMETHING_WENT_WRONG' });
    }
  };

  const getErrorMessageNote = () => {
    switch (inviteStatus) {
      case INVITE_STATUS.EXPIRED:
        return formatMessage({ id: 'INVITE_EXPIRED_NOTE' });
      case INVITE_STATUS.REDEEMED:
        return formatMessage({ id: 'INVITE_REDEEMED_NOTE' });
      default:
        return formatMessage(
          { id: 'INVITE_ERROR_NOTE' },
          { a: text => <Link href="mailto:help@league.com">{text}</Link> },
        );
    }
  };

  return {
    errorMessageTitle: getErrorMessageTitle(),
    errorMessageNote: getErrorMessageNote(),
  };
};
