import React, { useState } from 'react';
import { FORM_TYPES } from '../../constants';
import { UNICODE_DATE_FORMAT } from '@leagueplatform/web-common';
import {
  getInvalidErrorMessage,
  getEmptyDateErrorMessage,
  getDatePickerLabel,
} from './actions-dropdown.selectors';
import ActionsConfirmationDateForm from './actions-confirmation-date-form.view';
import { subDays, format } from 'date-fns';

const useActionsConfirmationDateForm = ({
  formType,
  setActionInvalidDate,
  setActionEffectiveDate,
}) => {
  const [invalidDate, setInvalidDate] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState('');

  const thirtyDaysAgo = subDays(new Date(), 30);

  const handleInvalidDate = date => {
    const isDateInvalid = date.isBefore(thirtyDaysAgo);
    setActionInvalidDate(isDateInvalid); // update parent prop actionInvalidDate to disable confirm button
    setInvalidDate(isDateInvalid);
  };

  const onDateChange = date => {
    let dateString = null;
    if (date) {
      // only do validation of > 30 days in the past for suspend employee
      if (
        formType !== FORM_TYPES.reinstateConfirmation &&
        formType !== FORM_TYPES.terminateConfirmation
      ) {
        handleInvalidDate(date);
      }
      dateString = date.toISOString();
    }
    setActionEffectiveDate(dateString); // update parent prop actionEffectiveDate
    setEffectiveDate(date);
  };

  return {
    datePickerLabel: getDatePickerLabel(formType),
    invalidDateErrorMsg: getInvalidErrorMessage(formType),
    emptyDateErrorMsg: getEmptyDateErrorMessage(formType),
    effectiveDateValue: effectiveDate
      ? format(new Date(effectiveDate), UNICODE_DATE_FORMAT)
      : '',
    invalidDate,
    onDateChange,
  };
};

export const ActionsConfirmationDateFormContainer = props => {
  const actionsConfirmationProps = useActionsConfirmationDateForm(props);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <ActionsConfirmationDateForm {...props} {...actionsConfirmationProps} />
  );
};
