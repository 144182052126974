import { cloneDeep, concat, set } from 'lodash';
import { normalize, denormalize, schema } from 'normalizr';
import {
  EMPLOYER_BENEFITS_LIFECYCLE,
  EMPLOYER_BENEFITS_INIT,
} from './benefits.types';

import { GET_BENEFIT_DOCUMENTS } from '../../employer-experience/pages/EmployerDetails/Benefits/types';
import { SET_EMPLOYER_BENEFIT_DOCUMENT } from '../../employer-experience/pages/EmployerDetails/Benefits/BenefitClass/Plans/BenefitPlan/Benefits/BenefitTypes/Documents/types';

export const documentSchema = new schema.Entity('documents');

export const initialState = {
  data: normalize([], [documentSchema]),
};

const applyGetEmployerBenefitDocumentsSucceeded = (state, { payload }) => {
  const clonedData = cloneDeep(state.data);
  const denormalized = denormalize(
    clonedData.result,
    [documentSchema],
    clonedData.entities,
  );
  return {
    ...state,
    data: normalize(concat(denormalized, payload.benefitDocuments || []), [
      documentSchema,
    ]),
  };
};

const applySetEmployerBenefitDocumentSucceeded = (state, { payload }) => {
  const document = payload.info?.documents[0];
  return set(
    cloneDeep(state),
    ['data', 'entities', 'documents', document.id],
    document,
  );
};

export const employerBenefitDocumentsReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case GET_BENEFIT_DOCUMENTS.SUCCEEDED:
      return applyGetEmployerBenefitDocumentsSucceeded(state, {
        payload: {
          benefitDocuments: action.payload.info.documents,
        },
      });
    case EMPLOYER_BENEFITS_INIT.SUCCEEDED:
      return applyGetEmployerBenefitDocumentsSucceeded(state, action);
    case SET_EMPLOYER_BENEFIT_DOCUMENT.SUCCEEDED:
      return applySetEmployerBenefitDocumentSucceeded(state, action);
    case EMPLOYER_BENEFITS_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
