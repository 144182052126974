import { useIntl } from '@leagueplatform/locales';

export const useFilterOptions = ({ payrollSchedules }) => {
  const { formatMessage } = useIntl();

  const filterOptions = [
    {
      label: formatMessage({ id: 'TYPE_OF_PREMIUM' }),
      fieldName: 'premium_type',
      isSelectable: true,
      values: [
        { label: 'employee_cost', value: 'employee_cost' },
        { label: 'employer_cost', value: 'employer_cost' },
        { label: 'pre_tax_amount', value: 'pre_tax_amount' },
        { label: 'post_tax_amount', value: 'post_tax_amount' },
        { label: 'imputed_income', value: 'imputed_income' },
        {
          label: 'quebec_taxable_benefit_amount',
          value: 'quebec_taxable_benefit_amount',
        },
        { label: 'medical_waiver_credit', value: 'medical_waiver_credit' },
        { label: 'flex_cost', value: 'flex_cost' },
      ],
    },
    {
      label: formatMessage({ id: 'BENEFIT_TYPE' }),
      fieldName: 'benefit_type',
      isSelectable: false,
    },
    {
      label: formatMessage({ id: 'BENEFIT_PLAN_NAME' }),
      fieldName: 'benefit_plan_name',
      isSelectable: false,
    },
    {
      label: formatMessage({ id: 'DEPENDENT_COVERAGE_LEVEL' }),
      fieldName: 'dependent_coverage_level',
      isSelectable: true,
      values: [
        { label: 'myself', value: 'myself' },
        { label: 'myself_spouse', value: 'myself_spouse' },
        { label: 'myself_domesticpartner', value: 'myself_domesticpartner' },
        { label: 'myself_one_child', value: 'myself_one_child' },
        { label: 'myself_children', value: 'myself_children' },
        {
          label: 'myself_domesticpartnerchild',
          value: 'myself_domesticpartnerchild',
        },
        {
          label: 'myself_domesticpartnerchildren',
          value: 'myself_domesticpartnerchildren',
        },
        {
          label: 'myself_domesticpartnerchild_children',
          value: 'myself_domesticpartnerchild_children',
        },
        {
          label: 'myself_domesticpartnerchildren_children',
          value: 'myself_domesticpartnerchildren_children',
        },
        {
          label: 'myself_domesticpartner_domesticpartnerchild',
          value: 'myself_domesticpartner_domesticpartnerchild',
        },
        {
          label: 'myself_domesticpartner_domesticpartnerchildren',
          value: 'myself_domesticpartner_domesticpartnerchildren',
        },
        {
          label: 'myself_domesticpartner_children',
          value: 'myself_domesticpartner_children',
        },
        { label: 'myself_spouse_children', value: 'myself_spouse_children' },
        {
          label: 'myself_domesticpartner_children_domesticpartnerchild',
          value: 'myself_domesticpartner_children_domesticpartnerchild',
        },
        {
          label: 'myself_domesticpartner_children_domesticpartnerchildren',
          value: 'myself_domesticpartner_children_domesticpartnerchildren',
        },
      ],
    },
    {
      label: formatMessage({ id: 'PROVINCE_OF_RESIDENCE' }),
      fieldName: 'province_of_residence',
      isSelectable: false,
    },
    {
      label: formatMessage({ id: 'PROVINCE_OF_EMPLOYMENT' }),
      fieldName: 'province_of_employment',
      isSelectable: false,
    },
    payrollSchedules && {
      label: formatMessage({ id: 'PAY_CALENDAR' }),
      fieldName: 'payroll_schedule_id',
      isSelectable: true,
      values: payrollSchedules.map(schedule => ({
        label: schedule.schedule_name,
        value: schedule.id,
      })),
    },
  ];

  const getFilterOptionByFieldName = (options, fieldName) =>
    options.find(option => option.fieldName === fieldName);

  const getLabelByFilterOptionAndValue = (filterOption, value) => {
    if (filterOption.isSelectable) {
      return (
        filterOption.values.find(item => value === item.value)?.label ?? value
      );
    }
    return value;
  };

  return {
    filterOptions,
    getFilterOptionByFieldName,
    getLabelByFilterOptionAndValue,
  };
};
