import { select, put, fork, takeLatest, call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { selectSessionId } from 'apps/auth/selectors';
import { selectGroupId } from './benefits.selectors';
import { ADD_EMPLOYEE_BENEFIT_DOCUMENT } from './benefits.types';
import { UPLOAD_BENEFIT_DOCUMENT_FILE_REQUESTED } from '../benefits/documents/documents.types';
import { APP_CONFIG } from 'app-config';

const getContentUploadUrl = sessionId =>
  `${APP_CONFIG.REACT_APP_CONTENT_SERVER_URL}/contentsave/${sessionId}`;

export function* upload(action) {
  const { file } = action.payload;
  const sessionId = yield select(selectSessionId);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);
  const uploadUrl = getContentUploadUrl(sessionId);
  const request = new Request(uploadUrl, {
    method: 'POST',
    body: formData,
  });

  try {
    yield put({ type: ADD_EMPLOYEE_BENEFIT_DOCUMENT.STARTED });
    const response = yield call(fetch, request);
    const data = yield response.json();
    const referenceId = data.reference_id;
    const groupId = yield select(selectGroupId);
    const payload = yield call(
      websocketFetch,
      'set_employer_benefit_documents',
      {
        group_id: groupId,
        documents: [
          {
            type: 'content_reference',
            content: {
              title: file.name,
              content_id: referenceId,
              content_type: file.type,
            },
          },
        ],
      },
    );
    yield put({ type: ADD_EMPLOYEE_BENEFIT_DOCUMENT.SUCCEEDED, payload });
  } catch (error) {
    yield put({ type: ADD_EMPLOYEE_BENEFIT_DOCUMENT.ERRORED, payload: error });
  }
}

export function* benefitDocumentsSagas() {
  yield fork(takeLatest, UPLOAD_BENEFIT_DOCUMENT_FILE_REQUESTED, upload);
}
