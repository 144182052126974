import { takeLatest } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  ADD_EMPLOYER_BENEFIT_DOCUMENT,
  UPDATE_EMPLOYER_BENEFIT_DOCUMENT,
  SET_EMPLOYER_BENEFIT_DOCUMENT,
} from './types';

export function* addEmployerBenefitDocument({ payload }) {
  const { groupId, documents } = payload;
  yield request(SET_EMPLOYER_BENEFIT_DOCUMENT, [
    websocketFetch,
    'set_employer_benefit_documents',
    {
      group_id: groupId,
      documents,
    },
  ]);
}

export function* employerBenefitDocumentsSaga() {
  yield takeLatest(
    [ADD_EMPLOYER_BENEFIT_DOCUMENT, UPDATE_EMPLOYER_BENEFIT_DOCUMENT],
    addEmployerBenefitDocument,
  );
}
