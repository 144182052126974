import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { FieldHints } from './FieldHints';

export const Checkbox = ({
  name,
  value,
  label,
  readOnly,
  required,
  tooltip,
  setFieldValue,
  handleBlur,
}) => (
  <Form.Checkbox
    name={name}
    readOnly={readOnly}
    defaultChecked={value}
    label={
      <FieldHints
        htmlFor={name}
        label={label}
        tooltip={tooltip}
        required={required}
      />
    }
    onBlur={handleBlur}
    // eslint-disable-next-line no-shadow -- FIXME: automatically added for existing issue
    onChange={(event, { name, checked }) => setFieldValue(name, !!checked)} // eslint-disable-line no-shadow
  />
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  required: false,
  tooltip: null,
};

export default Checkbox;
