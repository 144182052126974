import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import ActionsConfirmationTerminationCodeDropdown from '../actions-dropdown/actions-confirmation-termination-code-dropdown.container';
import { DateInputForm } from './DateInputForm';

export const TerminationForm = props => {
  return (
    <Form>
      <Form.Group widths="equal">
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <DateInputForm {...props} />
        <ActionsConfirmationTerminationCodeDropdown
          onChangeReason={val => {
            // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
            props.update({ reason: val });
          }}
        />
      </Form.Group>
    </Form>
  );
};

TerminationForm.propTypes = {
  update: PropTypes.func.isRequired,
};

export default TerminationForm;
