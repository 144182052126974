import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { BodyOne, Link } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { InfoBannerCard } from 'common/info-banner-card/info-banner-card.view';
import { currencyPropType } from '@leagueplatform/web-common';
import { EOITooltip } from 'common/insurance-forms/eoi-tooltip.view';

export const EvidenceOfInsurabilityBanner = ({
  benefitName,
  noEvidenceMaximum,
  eoiArticleLink,
  ...props
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const noEvidenceMaximumValue =
    noEvidenceMaximum?.F > 0 ? formatMoney(noEvidenceMaximum) : null;

  const bannerContentId = useMemo(() => {
    return noEvidenceMaximumValue
      ? 'EVIDENCE_OF_INSURABILITY_BANNER_TEXT_WITH_NEM'
      : 'EVIDENCE_OF_INSURABILITY_BANNER_TEXT';
  }, [noEvidenceMaximumValue]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <InfoBannerCard {...props}>
      <BodyOne as="div" display="inline">
        {formatMessage(
          {
            id: bannerContentId,
          },
          {
            benefitName,
            eoi: <EOITooltip />,
            noEvidenceMaximumValue,
          },
        )}
      </BodyOne>
      <Link
        href={eoiArticleLink}
        target="_blank"
        marginLeft="quarter"
        aria-label={formatMessage({ id: 'LEARN_MORE_ABOUT_EOI' })}
      >
        {formatMessage({ id: 'LEARN_MORE' })}
      </Link>
    </InfoBannerCard>
  );
};

EvidenceOfInsurabilityBanner.propTypes = {
  benefitName: PropTypes.string.isRequired,
  noEvidenceMaximum: currencyPropType.isRequired,
  eoiArticleLink: PropTypes.string.isRequired,
};
