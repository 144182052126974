import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setQLETypeAndId } from '../qle-configuration.action-creators';
import {
  selectQLEConfigurations,
  selectSelectedBenefitClass,
} from '../qle-configuration.selectors';
import { createStructuredSelector } from 'reselect';
import { QLEConfigurationTable } from './qle-configuration-table.view';

const withProps = connect(
  createStructuredSelector({
    qleConfigurations: selectQLEConfigurations,
    benefitClass: selectSelectedBenefitClass,
  }),
  {
    setQLETypeAndId,
  },
);

export default compose(withProps)(QLEConfigurationTable);
