import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getRateSheetExtracts calls the backend to fetch rate sheet extract report
 * @param {string} groupId - group Id
 * @param {string} planStatus - benefit plan status to select (optional)
 * @param {string[]} classIds - array of benefit classes for the group (optional)
 * @param {string[]} benefitIds - array of selected benefit ids for the group (optional)
 */
export const getRateSheetExtracts = ({
  groupId,
  planStatus,
  classIds,
  benefitIds,
}) =>
  call(websocketFetch, 'request_report', {
    type: 'rate_sheet_extract',
    request: {
      group_id: groupId,
      plan_status: planStatus,
      benefit_class_ids: classIds,
      employer_benefit_ids: benefitIds,
    },
  });
