import {
  GET_EMPLOYER_BENEFIT_PRICE_CONFIG,
  SET_EMPLOYER_BENEFIT_PRICE_CONFIG,
} from '../types';
import { get } from 'lodash';
import {
  FETCH_BENEFIT_PRICE_BY_ID,
  FETCH_BENEFIT_PRICES_CSV,
  CLEAR_BENEFIT_PRICE_CONFIG_STATE,
} from './pricing-configuration.types';

export const initialState = {
  pricingConfiguration: {
    selectedBenefitId: null,
    loading: false,
    benefitPriceConfiguration: null,
    csvContentId: null,
    loadingBenefitPricesToCsv: false,
  },
};

const setEmployerBenefitPriceConfig = (state, { payload }) => {
  const benefitPrice = get(payload, 'info.benefit_price', {});
  return {
    ...state,
    pricingConfiguration: {
      ...state.pricingConfiguration,
      loading: false,
      benefitPriceConfiguration: benefitPrice,
    },
  };
};

const setBenefitPriceToCsvContentId = (state, { payload }) => {
  const contentId = get(payload, 'info.content_id') || '';
  return {
    ...state,
    pricingConfiguration: {
      ...state.pricingConfiguration,
      loadingBenefitPricesToCsv: false,
      csvContentId: contentId,
    },
  };
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const pricingConfigurationReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_EMPLOYER_BENEFIT_PRICE_CONFIG.STARTED:
      return {
        ...state,
        pricingConfiguration: {
          ...state.pricingConfiguration,
          loading: true,
        },
      };
    case GET_EMPLOYER_BENEFIT_PRICE_CONFIG.ERRORED:
      return {
        ...state,
        pricingConfiguration: {
          ...state.pricingConfiguration,
          loading: false,
        },
      };
    case GET_EMPLOYER_BENEFIT_PRICE_CONFIG.SUCCEEDED:
      return setEmployerBenefitPriceConfig(state, action);
    case SET_EMPLOYER_BENEFIT_PRICE_CONFIG.STARTED:
      return state;
    case SET_EMPLOYER_BENEFIT_PRICE_CONFIG.ERRORED:
      return state;
    case SET_EMPLOYER_BENEFIT_PRICE_CONFIG.SUCCEEDED:
      return state;
    case FETCH_BENEFIT_PRICE_BY_ID:
      return {
        ...state,
        pricingConfiguration: {
          ...state.pricingConfiguration,
          selectedBenefitId: action.payload,
        },
      };
    case FETCH_BENEFIT_PRICES_CSV.STARTED:
      return {
        ...state,
        pricingConfiguration: {
          ...state.pricingConfiguration,
          loadingBenefitPricesToCsv: true,
          csvContentId: '',
        },
      };
    case FETCH_BENEFIT_PRICES_CSV.SUCCEEDED:
      return setBenefitPriceToCsvContentId(state, action);
    case FETCH_BENEFIT_PRICES_CSV.ERRORED:
      return {
        ...state,
        pricingConfiguration: {
          ...state.pricingConfiguration,
          loadingBenefitPricesToCsv: false,
        },
      };
    case CLEAR_BENEFIT_PRICE_CONFIG_STATE:
      return {
        ...state,
        pricingConfiguration: initialState.pricingConfiguration,
      };
    default:
      return state;
  }
};
