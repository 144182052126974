import {
  CLAIM_APPROVAL_CANCELLED,
  CLAIM_FORM_SUBMITTED,
  DUPLICATE_CLAIM_APPROVED,
  DUPLICATE_CLAIM_REJECTED,
  SAVE_CLAIM_VALUES_REQUESTED,
  EXPENSE_DATE_CHANGED,
  VENDOR_QUERY_CHANGED,
  UPDATE_CLAIM_ALLOCATION_AMOUNTS,
} from './settlement.types';

export const submitClaimForm = (values, updateType) => ({
  type: CLAIM_FORM_SUBMITTED,
  payload: { values, updateType },
});
export const approveDuplicateClaim = claimId => ({
  type: DUPLICATE_CLAIM_APPROVED,
  payload: { claimId },
});
export const rejectDuplicateClaim = claimId => ({
  type: DUPLICATE_CLAIM_REJECTED,
  payload: { claimId },
});
export const cancelClaimApproval = () => ({
  type: CLAIM_APPROVAL_CANCELLED,
});
export const vendorQueryChanged = query => ({
  type: VENDOR_QUERY_CHANGED,
  payload: { query },
});
export const expenseDateChanged = (claimId, expenseDate) => ({
  type: EXPENSE_DATE_CHANGED,
  payload: { claimId, expenseDate },
});
export const saveValuesRequested = values => ({
  type: SAVE_CLAIM_VALUES_REQUESTED,
  payload: { values },
});
export const submitUpdatedAllocationAmounts = (
  userId,
  initialAllocations,
  values,
) => ({
  type: UPDATE_CLAIM_ALLOCATION_AMOUNTS,
  payload: { userId, initialAllocations, values },
});
