import { APP_CONFIG } from 'app-config';
import { routerMiddleware } from 'connected-react-router';
import { addMessageType } from 'common/websocket-redux';
import createHistory from 'history/createBrowserHistory';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

export const history = createHistory();
const router = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

function getMiddlewares() {
  const middlewares = [sagaMiddleware, router];

  if (APP_CONFIG.REACT_APP_REDUX_LOG === 'enabled') {
    const logger = createLogger({
      actionTransformer: addMessageType,
      collapsed: true,
      duration: true,
    });
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
    return composeEnhancers(applyMiddleware(logger, ...middlewares));
  }

  return applyMiddleware(...middlewares);
}

export const createConfiguredStore = ({ reducer, saga }) => {
  const store = createStore(reducer, getMiddlewares());
  sagaMiddleware.run(saga);
  return store;
};
