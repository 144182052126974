import { reduce, setWith } from 'lodash';

/**
 * Added `setWithCustomizer` param here, for being able to specify how to handle objects of `pathMap`,
 * and can be left unspecified to preserve original `explodeObject` functionality.
 */

export const explodeObject = (pathMap, setWithCustomizer) =>
  reduce(
    pathMap,
    (object, value, path) => setWith(object, path, value, setWithCustomizer),
    {},
  );
