import { get } from 'lodash';
import {
  GET_STATEMENT_OF_ACCOUNT,
  GET_ENROLLMENT_SELECTION_REPORT,
} from './finance-reports.types';

export const initialState = {
  statementOfAccountLoading: false,
  statementOfAccountContentId: null,
  enrollmentSelectionsReportLoading: false,
  enrollmentSelectionsReportContentId: null,
};

const setStatementOfAccountContentId = (state, action) => {
  return {
    ...state,
    statementOfAccountLoading: false,
    statementOfAccountContentId: get(action, 'payload.content_id', null),
  };
};

const setEnrollmentSelectionsReportContentId = (state, action) => {
  return {
    ...state,
    enrollmentSelectionsReportLoading: false,
    enrollmentSelectionsReportContentId:
      action.payload?.report?.report_files?.[0]?.content_id ?? null,
  };
};

export const financeToolsReportsUIReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case GET_STATEMENT_OF_ACCOUNT.STARTED:
      return { ...state, statementOfAccountLoading: true };
    case GET_STATEMENT_OF_ACCOUNT.SUCCEEDED:
      return setStatementOfAccountContentId(state, action);
    case GET_STATEMENT_OF_ACCOUNT.ERRORED:
      return { ...state, statementOfAccountLoading: false };
    case GET_ENROLLMENT_SELECTION_REPORT.STARTED:
      return {
        ...state,
        enrollmentSelectionsReportLoading: true,
        enrollmentSelectionsReportContentId: null,
      };
    case GET_ENROLLMENT_SELECTION_REPORT.SUCCEEDED:
      return setEnrollmentSelectionsReportContentId(state, action);
    case GET_ENROLLMENT_SELECTION_REPORT.ERRORED:
      return { ...state, enrollmentSelectionsReportLoading: false };
    default:
      return state;
  }
};
