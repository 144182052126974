import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fontSizeInPx, spaceInPx } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import { Box, Link, genesisStyled } from '@leagueplatform/genesis-commons';

export const ModalButton = styled(Button)`
  font-size: ${fontSizeInPx(2)};
  height: 100%;
  padding: 12px 24px;
  margin-left: ${spaceInPx(2)};
  border: none;
`;

export const ModalLoaderButton = styled(LoaderButton)`
  font-size: ${fontSizeInPx(2)};
  height: 100%;
  padding: 12px 24px;
  margin-left: ${spaceInPx(2)};
  border: none;
`;

export const ErrorMessage = props => (
  <Box
    padding="threeQuarters"
    marginTop="two"
    borderRadius="small"
    borderWidth="thin"
    borderStyle="solid"
    borderColor="critical.border.default"
    backgroundColor="critical.background.subdued"
    color="critical.icon"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const StyledBox = genesisStyled(props => (
  <Box
    borderRadius="medium"
    marginTop="two"
    backgroundColor="surface.background.secondary"
    padding="one"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
))`
  overflow-wrap: break-word;
`;

export const LinkBox = ({ url }) => (
  <StyledBox>
    <Link href={url} target="_blank" rel="noopener noreferrer">
      {url}
    </Link>
  </StyledBox>
);

LinkBox.propTypes = {
  url: PropTypes.string.isRequired,
};
