import {
  EMPLOYER_BILLING_DIVISIONS_LIFECYCLE,
  SET_BILLING_DIVISION,
  REMOVE_BILLING_DIVISION,
  DONE_BILLING_DIVISION_EDIT,
  START_BILLING_DIVISION_EDIT,
  EDIT_BILLING_DIVISION_NAME,
} from './employer-divisions.types';

export const initialState = {
  editingId: null,
  editedName: '',
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerDivisionsUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BILLING_DIVISION.SUCCEEDED:
      return initialState;
    case REMOVE_BILLING_DIVISION.SUCCEEDED:
      return initialState;
    case START_BILLING_DIVISION_EDIT:
      return {
        ...state,
        editingId: action.payload.id,
        editedName: action.payload.name,
      };
    case EDIT_BILLING_DIVISION_NAME:
      return { ...state, editedName: action.payload.name };
    case DONE_BILLING_DIVISION_EDIT:
      return initialState;
    case EMPLOYER_BILLING_DIVISIONS_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
