export const TEMPLATE_STATUS = {
  NO_TEMPLATE: 'NO_TEMPLATE',
  LIVE: 'LIVE',
  INACTIVE: 'INACTIVE',
};

/**
 * A benefit content template from our Contentful CMS is either
 * a `DEFAULT` template or a customized `GROUP` specific template.
 */
export const TEMPLATE_TYPES = {
  DEFAULT: 'DEFAULT',
  GROUP: 'GROUP',
};

export const getColors = status => {
  switch (status) {
    case TEMPLATE_STATUS.NO_TEMPLATE:
      return {
        circle: 'critical.icon',
        background: 'critical.background.subdued',
      };
    case TEMPLATE_STATUS.LIVE:
      return {
        circle: 'success.icon',
        background: 'success.background.subdued',
      };
    case TEMPLATE_STATUS.INACTIVE:
    default:
      return {
        circle: 'onSurface.text.subdued',
        background: 'interactive.background.disabled',
      };
  }
};
