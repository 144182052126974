import { connect } from 'react-redux';
import { DeductionCodeTablePresenter } from './deduction-code-table.view';
import {
  deleteDeductionCodes,
  exportDeductionCodes,
} from '../../resources/deduction-codes/deduction-codes.actions';
import {
  selectDeductionCodes,
  selectPayrollSchedules,
} from '../../resources/deduction-codes/deduction-codes.selectors';
import { createStructuredSelector } from 'reselect';

export const DeductionCodeTable = connect(
  createStructuredSelector({
    deductionCodes: selectDeductionCodes,
    payrollSchedules: selectPayrollSchedules,
  }),
  {
    deleteDeductionCodes,
    exportDeductionCodes,
  },
)(DeductionCodeTablePresenter);
