import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  PrimaryButton,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { TextEditor } from '../TextEditor';

const SelectWrapper = genesisStyled(Box)(
  css({
    '& .Genesis-Select-MenuList': {
      maxHeight: '400px',
      overflow: 'auto',
    },
  }),
);

export function HtmlContentEditor({
  value,
  templateVariableOptions,
  onChange,
}) {
  const { formatMessage } = useIntl();
  const [selectedTemplateVariable, setSelectedTemplateVariable] = useState('');
  return (
    <>
      <Flex paddingBottom="half" width={1} justifyContent="space-between">
        {templateVariableOptions?.length > 0 && (
          <Flex width={1 / 2}>
            <SelectWrapper width={1}>
              <Select
                name="variable"
                placeholder={formatMessage({ id: 'SELECT_A_VARIABLE' })}
                options={templateVariableOptions}
                value={selectedTemplateVariable}
                onChange={selected => {
                  setSelectedTemplateVariable(selected.value);
                }}
              />
            </SelectWrapper>
            <PrimaryButton
              disabled={!selectedTemplateVariable}
              marginLeft="half"
              onClick={() => {
                onChange(`${value}\n\n<p>{{.${selectedTemplateVariable}}}</p>`);
              }}
            >
              {formatMessage({ id: 'INSERT' })}
            </PrimaryButton>
          </Flex>
        )}
      </Flex>
      <TextEditor code={value} setCode={onChange} />
    </>
  );
}

HtmlContentEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  templateVariableOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

HtmlContentEditor.defaultProps = {
  value: '',
  templateVariableOptions: null,
};
