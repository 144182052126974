/* eslint-disable max-classes-per-file */
import {
  BENEFIT_GROUP_TYPE,
  BENEFIT_SET_TYPE,
} from './enrollment-experience-config.constants';

export interface EnrollmentDesignOptionsState {
  loading: boolean;
  saving: boolean;
  error?: string;
  enrollmentDesignOptions?: {
    group_id: string;
    plan_id: string;
    steps: BenefitStep[];
    availableBenefitSets: string[];
  };
  contentfulTemplates: {
    loading: boolean;
    error?: string;
    enrollment_step_model?: [];
    benefit_set_group_model?: [];
    benefit_set_model?: [];
  };
  validationErrors?: ValidationError[];
}

export interface ContentfulTemplate {
  default: boolean;
  country: string[];
  group_id: string;
  url: string;
  status: string;
  benefit_class_id: string;
  enrollment_content_id: string;
  content_title: string;
}

export type ValidationError = {
  code: string;
  severity: string;
};

export type Contentful = {
  benefit_class: string;
  custom: string;
  default: string;
  group: string;
};

export type BenefitSetLayoutType =
  (typeof BenefitSetLayoutTypes)[keyof typeof BenefitSetLayoutTypes];

export const BenefitSetLayoutTypes = {
  COMPARISON: 'comparison',
  CONTRIBUTION: 'contribution',
  VOLUME: 'volume',
  BASIC: 'basic',
} as const;

export interface BenefitSet {
  content?: string | null;
  id: string;
  layout?: BenefitSetLayoutType | null;
  name: string;
  type: typeof BENEFIT_SET_TYPE;
}

export class BenefitSetGroup {
  content?: string | null;

  id: string;

  name: string;

  sets: Array<BenefitSet>;

  type: typeof BENEFIT_GROUP_TYPE;

  constructor(
    name: string,
    id?: string,
    sets?: Array<BenefitSet>,
    content?: string,
  ) {
    this.id = id ?? `tmp_${new Date().getTime()}`;
    this.content = content || 'Test content'; // @TODO remove hardcoding content when API is fixed
    this.name = name;
    this.type = BENEFIT_GROUP_TYPE;
    this.sets = sets ?? [];
  }
}

export class BenefitStep {
  id: string = '';

  content?: string | null;

  name: string;

  sets: Array<BenefitSet | BenefitSetGroup>;

  url: string | undefined;

  constructor(
    name: string,
    id?: string,
    sets?: Array<BenefitSet | BenefitSetGroup>,
    url?: string,
    content?: string,
  ) {
    this.id = id ?? `tmp_${new Date().getTime()}`;
    this.content = content || 'Test content'; // @TODO remove hardcoding content when API is fixed
    this.name = name;
    this.sets = sets ?? [];
    this.url = url;
  }
}

export type EnrollmentStepItemType = BenefitStep | BenefitSetGroup | BenefitSet;

export type EnrollmentTemplateType =
  (typeof EnrollmentTemplateTypes)[keyof typeof EnrollmentTemplateTypes];

export const EnrollmentTemplateTypes = {
  STEP: 'enrollment_step_model',
  GROUP: 'benefit_set_group_model',
  SET: 'benefit_set_model',
} as const;
