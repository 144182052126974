import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { EMPLOYER_STATUSES } from 'common/constants';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';

const ChangesMessage = props => {
  const { suspendedType } = props;
  const suspendedDate = new Date(props.suspendedDate);
  const reinstatedDate = new Date(props.reinstatedDate);
  const today = new Date();
  let message = null;
  if (suspendedDate > today) {
    if (
      suspendedType === EMPLOYER_STATUSES.TERMINATED ||
      suspendedType === EMPLOYER_STATUSES.SUSPENDED
    ) {
      message = (
        <span
          className="title-cell__negative-change"
          data-scheduled-date={moment(suspendedDate).format('DD-MM-YYYY')}
        >
          <span>Scheduled to be {suspendedType} on </span>
          <FormattedDate
            value={suspendedDate}
            year="numeric"
            month="short"
            day="2-digit"
          />
        </span>
      );
      return message;
    }
  } else if (reinstatedDate > today) {
    message = (
      <span
        className="title-cell__positive-change"
        data-scheduled-date={moment(reinstatedDate).format('DD-MM-YYYY')}
      >
        <span>Scheduled to be reinstated on </span>
        <FormattedDate
          value={reinstatedDate}
          year="numeric"
          month="short"
          day="2-digit"
        />
      </span>
    );
    return message;
  }
  return message;
};

ChangesMessage.propTypes = {
  suspendedType: PropTypes.string.isRequired,
  suspendedDate: PropTypes.string.isRequired,
  reinstatedDate: PropTypes.string.isRequired,
};

export default ChangesMessage;
