import { assign, clone } from 'lodash';
import { CLAIMS_PER_PAGE } from '../constants';
import { ASCENDING, DESCENDING } from 'common/constants';
import { locationChangedTo } from 'common/utilities/url';
import { getDirectionFromSortOrder } from './selectors';
import { SIGN_OUT } from 'apps/auth/auth.actions';
import {
  FETCH_AGENTS,
  FETCH_CLAIMS,
  ON_PAGE_NUMBER_SELECTED,
  ON_SORT_ORDER_CHANGED,
  ON_FILTER_CHANGED,
} from './types';

const defaultParams = {
  query: null,
  claim_agent: null,
  statuses: [],
  limit: CLAIMS_PER_PAGE,
  order: { [ASCENDING]: true, type: 'date_created' },
  skip: 0,
};

export const initialState = {
  isLoading: false,
  pageNumber: 1,
  agents: [],
  params: clone(defaultParams),
};

const resetParams = state => ({
  ...state,
  params: clone(defaultParams),
});

const applyFetching = state => ({
  ...state,
  isLoading: true,
});

const applySetClaims = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: null,
    data: {
      allClaims: action.payload.info,
    },
  };
};

const applyErrorFetchingClaims = (state, action) => {
  const error = action.payload;
  return {
    ...state,
    isLoading: false,
    error,
  };
};

const applySetAgents = (state, action) => ({
  ...state,
  agents: action.payload.info.agents,
});

const updateFilters = (state, { payload: { field, value } }) => ({
  ...state,
  params: { ...state.params, [field]: value, skip: 0 },
});

export const invertSortDirection = direction => {
  switch (direction) {
    case ASCENDING:
      return DESCENDING;
    case DESCENDING:
    default:
      return ASCENDING;
  }
};

export const updateSortOrder = (state, action) => {
  const {
    payload: { field },
  } = action;
  const orderType = state.params.order.type;
  const direction = getDirectionFromSortOrder(state.params.order);

  const isToggling = field === orderType;

  const nextDirection = isToggling ? invertSortDirection(direction) : ASCENDING;

  const nextOrder = {
    type: field,
    [nextDirection]: true,
  };

  return {
    ...state,
    params: assign({}, state.params, { order: nextOrder, skip: 0 }),
  };
};

export const updatePageNumber = (state, action) => {
  const pageNumber = action.payload.number;
  const skip = (pageNumber - 1) * CLAIMS_PER_PAGE;
  return {
    ...state,
    params: assign({}, state.params, { skip }),
  };
};

const didNavigateHere = locationChangedTo('/admin/claims');

const reducer = (state = initialState, action = {}) => {
  if (didNavigateHere(action)) {
    return resetParams(state, action);
  }
  switch (action.type) {
    case FETCH_CLAIMS.STARTED:
      return applyFetching(state);
    case FETCH_CLAIMS.ERRORED:
      return applyErrorFetchingClaims(state, action);
    case FETCH_CLAIMS.SUCCEEDED:
      return applySetClaims(state, action);
    case FETCH_AGENTS.SUCCEEDED:
      return applySetAgents(state, action);
    case ON_FILTER_CHANGED:
      return updateFilters(state, action);
    case ON_SORT_ORDER_CHANGED:
      return updateSortOrder(state, action);
    case ON_PAGE_NUMBER_SELECTED:
      return updatePageNumber(state, action);
    case SIGN_OUT.SUCCEEDED:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
