import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withInitializer } from './initializer.container';
import {
  selectInvoices,
  selectDryRuns,
  selectEmployerBillingDivisions,
  selectEmployerBillingPreference,
  selectDryRunLoading,
  selectInvoicesTouched,
} from './invoices.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import {
  invoiceDryRun,
  downloadExternalInvoice,
  downloadInvoiceCsv,
  toggleInvoiceStatus,
  saveInvoiceStatus,
} from './invoices.actions';
import ActiveInvoices from './active-invoices.view';
import {
  selectEmployerOutstandingBalance,
  selectEmployerOutstandingBalanceReady,
  selectEmployerOutstandingBalanceError,
  selectGroupId,
} from 'apps/employer-experience/pages/EmployerDetails/Billing/selectors';
import { selectGroupCurrency } from '../app.selectors';
import { selectEmployerInsights } from 'common/insight-dashboards/employer-insights.selectors';

const withProps = connect(
  createStructuredSelector({
    invoices: selectInvoices,
    invoiceDryRuns: selectDryRuns,
    isAdmin: selectUserIsAdmin,
    dryRunLoading: selectDryRunLoading,
    billingDivisions: selectEmployerBillingDivisions,
    billingPreference: selectEmployerBillingPreference,
    outstandingBalance: selectEmployerOutstandingBalance,
    outstandingBalanceReady: selectEmployerOutstandingBalanceReady,
    outstandingBalanceError: selectEmployerOutstandingBalanceError,
    currency: selectGroupCurrency,
    invoicesTouched: selectInvoicesTouched,
    groupId: selectGroupId,
    employerInsights: selectEmployerInsights,
  }),
  {
    downloadInvoiceCsv,
    downloadExternalInvoice,
    invoiceDryRun,
    toggleInvoiceStatus,
    saveInvoiceStatus,
  },
);

export default compose(withInitializer, withProps)(ActiveInvoices);
