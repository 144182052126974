import React from 'react';
import PropTypes from 'prop-types';
import { Container, Icon, Label } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import { compose, withStateHandlers, branch, renderComponent } from 'recompose';
import { keys, partialRight, filter, flow, mapKeys } from 'lodash';
import { WithConfirm } from 'common/components/with-confirm';
import Catalogue from './catalogue.view';

const withCatalogueState = withStateHandlers(
  () => ({
    showCatalogue: false,
  }),
  {
    onClose: () => () => ({ showCatalogue: false }),
    toggleCatalogue:
      ({ showCatalogue }) =>
      () => ({
        showCatalogue: !showCatalogue,
      }),
  },
);

const withEmptyCatalogue = branch(
  ({ catalogue, benefits }) => {
    const byType = mapKeys(benefits, value => value.benefitType);
    const benefitsFilter = partialRight(filter, type => !byType[type]);
    const remaining = flow([keys, benefitsFilter])(catalogue);
    return !remaining.length;
  },
  renderComponent(() => <div />),
);

const AddBenefit = ({
  toggleCatalogue,
  catalogue,
  addNewBenefit,
  onClose,
  showCatalogue,
}) => (
  <div>
    <Container className="container--add-benefit">
      <Label onClick={toggleCatalogue}>
        <Icon fitted name="add" />
        <FormattedMessage id="ADD_BENEFIT" />
      </Label>
    </Container>
    <WithConfirm
      // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
      generateMessage={({ benefitProduct }) => (
        <Box p={3} style={{ lineHeight: 1.5 }}>
          Are you sure you want to add{' '}
          <b>
            {benefitProduct.description} by {benefitProduct.benefit_vendor_name}{' '}
            {benefitProduct.network_name
              ? `(${benefitProduct.network_name})`
              : ''}
          </b>{' '}
          to this employee&apos;s benefit plan?
        </Box>
      )}
      confirmButton="Add Benefit"
      onConfirm={({ benefitProduct }) => addNewBenefit(benefitProduct)}
      content="Add benefit to employee?"
      render={confirmedAction =>
        showCatalogue && (
          <Catalogue
            catalogue={catalogue}
            onChange={confirmedAction}
            onClose={onClose}
          />
        )
      }
    />
  </div>
);

AddBenefit.propTypes = {
  toggleCatalogue: PropTypes.func.isRequired,
  catalogue:
    PropTypes.object.isRequired /* eslint react/forbid-prop-types: 0 */,
  addNewBenefit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showCatalogue: PropTypes.bool.isRequired,
};

export default compose(withCatalogueState, withEmptyCatalogue)(AddBenefit);
