import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, TextField, Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { CONTRIBUTOR_TYPES } from 'common/benefits';
import editIcon from 'apps/public/assets/edit-icon.svg';
import checkmark from 'common/ui-kit/assets/checked.png';
import close from 'apps/public/assets/close.svg';

export const EditableAmount = ({
  contribution,
  contributionUpdates,
  setContributionUpdates,
  editable,
  setEditable,
  save,
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const [value, setValue] = useState(contribution?.amount?.F);

  return editable ? (
    <Flex alignItems="center">
      <TextField
        id="EDITABLE_AMOUNT"
        value={value}
        display="inline-block"
        onChange={evt => {
          // eslint-disable-next-line no-restricted-globals -- FIXME: automatically added for existing issue
          if (!isNaN(evt.currentTarget.value)) {
            setValue(evt.currentTarget.value);
            setContributionUpdates({
              ...contributionUpdates,
              // hash the pay period so we know which contribution we are updating
              [Object.values(contribution.pay_period).join('')]: {
                ...contribution,
                amount: parseFloat(evt.currentTarget.value),
                contributor: CONTRIBUTOR_TYPES.EMPLOYEE,
                override: true,
              },
            });
          }
        }}
      />
      <Image
        src={checkmark}
        size="one"
        marginLeft="one"
        aria-label={formatMessage({ id: 'SAVE' })}
        onClick={() => {
          setEditable(false);
          save();
        }}
      />
      <Image
        src={close}
        size="one"
        marginLeft="one"
        aria-label={formatMessage({ id: 'CANCEL' })}
        onClick={() => setEditable(false)}
      />
    </Flex>
  ) : (
    <Flex alignItems="center">
      {formatMoney({
        F: contribution.amount.F,
        C: contribution.amount.C,
      })}
      <Image
        src={editIcon}
        size="one"
        marginLeft="one"
        aria-label={formatMessage({ id: 'EDIT' })}
        onClick={() => setEditable(true)}
      />
    </Flex>
  );
};

const contributionPropType = PropTypes.shape({
  amount: PropTypes.shape({
    F: PropTypes.number,
    C: PropTypes.string,
  }),
  pay_period: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }),
});

EditableAmount.propTypes = {
  contribution: contributionPropType.isRequired,
  contributionUpdates: PropTypes.objectOf(contributionPropType).isRequired,
  setContributionUpdates: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  setEditable: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};
