/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  takeLatest,
  fork,
  all,
  select,
  call,
  put,
  take,
} from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { scriptFetch } from './script/fetch';
import { createPostMessageChannel } from 'common/utilities/post-message-helpers';
import {
  getChatAvailability,
  getSalesforceConfig,
  createSalesforceChatMap,
} from 'common/services';
import { selectUserId } from 'common/state/user/user.selectors';
import {
  selectChatConfig,
  selectAgentConfig,
  selectLiveagentParams,
} from './chat-window.selectors';
import {
  ON_INIT_CHAT_WINDOW,
  CREATE_SALESFORCE_CHAT_MAP,
  GET_SALESFORCE_CONFIG,
  GET_AGENT_CONFIG,
  GET_SALESFORCE_LIVEAGENT,
  setWaitTime,
  setQueuePosition,
  setChatIsEstablished,
  setChatHasFailedToLoad,
  openModal,
  closeModal,
} from './chat-window.actions';
import {
  ARIA_LIVE_MESSAGES_BY_EVENT,
  CHAT_EVENTS,
  CHAT_MODALS_BY_EVENT,
} from './chat-window.constants';
import {
  configureLiveagentDetails,
  checkLiveagentAvailability,
  startLiveagentChat,
  resetPrivacyLock,
} from './chat-window.utils';
import { generateChatEvent } from '../chat-page.analytics';
import {
  announcePoliteMessage,
  announceAssertiveMessage,
} from 'common/components/aria-live-region/aria-live-region.actions';
import { setChatMaxIdleTime } from 'common/privacy-lock/privacy-lock.actions';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { captureError } from '@leagueplatform/observability';

export function* requestCreateChatMap({ payload: { agentType } }) {
  const userId = yield select(selectUserId);

  yield request(
    CREATE_SALESFORCE_CHAT_MAP,
    createSalesforceChatMap(userId, agentType),
  );
}

export function* requestSalesforceConfig() {
  yield request(GET_SALESFORCE_CONFIG, getSalesforceConfig());
}

export function* requestAgentConfig() {
  yield request(GET_AGENT_CONFIG, getChatAvailability());
}

export function* getSalesforceLiveagent(url) {
  yield call(scriptFetch, url);
}

export function* requestDeploymentScript() {
  const { deployment_script_url } = yield select(selectChatConfig);

  yield request(
    GET_SALESFORCE_LIVEAGENT,
    getSalesforceLiveagent(deployment_script_url),
  );
}

export function* configureLiveagent() {
  const { button_id } = yield select(selectAgentConfig);
  const configureLiveagentParams = yield select(selectLiveagentParams);

  try {
    configureLiveagentDetails(configureLiveagentParams);
    yield call(checkLiveagentAvailability, button_id);
    startLiveagentChat(button_id);
  } catch (e) {
    yield put(setChatHasFailedToLoad());
    captureError(e);
  }
}

export function* dispatchSetChatMaxIdleTime() {
  yield put(setChatMaxIdleTime());
}

export function* watchMessageChannel({ payload: { agentType } }) {
  const messageChannel = yield call(createPostMessageChannel);

  // eslint-disable-next-line no-restricted-syntax, no-labels -- FIXME: automatically added for existing issue
  eventLoop: while (true) {
    const event = yield take(messageChannel);

    switch (event?.data?.type) {
      case CHAT_EVENTS.CHAT_CLOSED:
        // eslint-disable-next-line no-labels -- FIXME: automatically added for existing issue
        break eventLoop;
      case CHAT_EVENTS.SALESFORCE_ANALYTIC_EVENT:
        yield call(
          sendAnalyticsEvent,
          generateChatEvent(agentType, event.data.payload.action),
        );
        break;
      case CHAT_EVENTS.WAIT_TIME_UPDATE:
        yield put(setWaitTime(event.data.payload.time));
        break;
      case CHAT_EVENTS.QUEUE_UPDATE:
        yield put(setQueuePosition(event.data.payload.position));
        break;
      case CHAT_EVENTS.CHAT_ACTIVITY:
        yield call(dispatchSetChatMaxIdleTime);
        resetPrivacyLock();
        break;
      case CHAT_EVENTS.OPEN_END_CHAT_MODAL:
      case CHAT_EVENTS.OPEN_CHAT_CONNECTION_LOST_MODAL:
      case CHAT_EVENTS.OPEN_CHAT_FAILED_MODAL:
      case CHAT_EVENTS.OPEN_CHAT_RECONNECTING_MODAL:
        yield put(openModal(CHAT_MODALS_BY_EVENT[event.data.type]));
        break;
      case CHAT_EVENTS.CLOSE_CHAT_MODAL:
        yield put(closeModal());
        break;
      case CHAT_EVENTS.SALESFORCE_EVENT: {
        switch (event.data.payload?.name) {
          case CHAT_EVENTS.CHAT_REQUEST_FAILED:
            yield put(setChatHasFailedToLoad());
            break;
          case CHAT_EVENTS.CHAT_START:
            yield put(setChatIsEstablished({ origin: event.origin }));
            break;
          case CHAT_EVENTS.AGENT_CHAT_MESSAGE:
            yield put(announcePoliteMessage(''));
            yield call(dispatchSetChatMaxIdleTime);
            resetPrivacyLock();
            break;
          case CHAT_EVENTS.AGENT_TYPING_UPDATE:
            if (event.data.payload?.event?.isAgentTyping) {
              yield put(
                announcePoliteMessage(
                  ARIA_LIVE_MESSAGES_BY_EVENT[event.data.payload.name],
                ),
              );
            }
            yield call(dispatchSetChatMaxIdleTime);
            resetPrivacyLock();
            break;
          case CHAT_EVENTS.AGENT_CHAT_END:
          case CHAT_EVENTS.FILE_TRANSFER_REQUESTED:
          case CHAT_EVENTS.FILE_TRANSFER_SUCCESS:
          case CHAT_EVENTS.FILE_TRANSFER_CANCELED:
            yield put(
              announcePoliteMessage(
                ARIA_LIVE_MESSAGES_BY_EVENT[event.data.payload.name],
              ),
            );
            yield call(dispatchSetChatMaxIdleTime);
            resetPrivacyLock();
            break;
          case CHAT_EVENTS.FILE_TRANSFER_FAILURE:
            yield put(
              announceAssertiveMessage(
                ARIA_LIVE_MESSAGES_BY_EVENT[event.data.payload.name],
              ),
            );
            break;
          default:
        }
        break;
      }
      default:
    }
  }

  messageChannel.close();
  window.close();
}

export function* chatWindowSagas() {
  yield fork(all, [
    takeLatest(ON_INIT_CHAT_WINDOW, watchMessageChannel),
    // The below sagas are chained so that each success triggers the next saga
    takeLatest(ON_INIT_CHAT_WINDOW, requestCreateChatMap),
    takeLatest(CREATE_SALESFORCE_CHAT_MAP.SUCCEEDED, requestSalesforceConfig),
    takeLatest(GET_SALESFORCE_CONFIG.SUCCEEDED, requestAgentConfig),
    takeLatest(GET_AGENT_CONFIG.SUCCEEDED, requestDeploymentScript),
    takeLatest(GET_SALESFORCE_LIVEAGENT.SUCCEEDED, configureLiveagent),
    takeLatest(GET_SALESFORCE_LIVEAGENT.SUCCEEDED, dispatchSetChatMaxIdleTime),
  ]);
}
