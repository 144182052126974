import {
  IMPORT_PLAN_DESIGN,
  EXPORT_PLAN_DESIGN,
  REQUEST_IMPORT_PLAN_DESIGN,
  REQUEST_EXPORT_PLAN_DESIGN,
  RESET_PLAN_DESIGN_REQUEST_STATUS,
} from './plan-design.actions';

const initialState = {
  planDesignRequestStatus: {
    success: false,
    exportedSheetUrl: '',
    loading: false,
    error: false,
    errorReason: '',
  },
};

export const planDesignReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_IMPORT_PLAN_DESIGN:
    case REQUEST_EXPORT_PLAN_DESIGN:
      return {
        ...state,
        planDesignRequestStatus: {
          loading: true,
        },
      };
    case IMPORT_PLAN_DESIGN.SUCCEEDED:
      return {
        ...state,
        planDesignRequestStatus: {
          loading: false,
          success: true,
        },
      };
    case EXPORT_PLAN_DESIGN.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const exportedSheetUrl = action.payload.info.sheet_url;
      return {
        ...state,
        planDesignRequestStatus: {
          exportedSheetUrl,
          loading: false,
          success: true,
        },
      };
    case IMPORT_PLAN_DESIGN.ERRORED:
    case EXPORT_PLAN_DESIGN.ERRORED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const errorReason = action.payload.info.reason;
      return {
        ...state,
        planDesignRequestStatus: {
          loading: false,
          error: true,
          errorReason,
        },
      };
    case RESET_PLAN_DESIGN_REQUEST_STATUS:
      return {
        ...state,
        planDesignRequestStatus: {
          success: false,
          loading: false,
          error: false,
          errorReason: '',
        },
      };
    default:
      return state;
  }
};
