import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DeductionCodeForm } from './deduction-code-form.container';
import { PAGE_STATE } from './constants';
import { DeductionCodeTable } from './deduction-code-table.container';
import { Loader } from 'semantic-ui-react';
import { STATUS } from '../../resources/deduction-codes/deduction-codes.reducer';

export const DeductionCodesPresenter = ({
  groupId,
  status,
  loadDeductionCodeEditor,
  setDeductionCodes,
}) => {
  const [currentCode, setCurrentCode] = useState({});
  const [pageState, setPageState] = useState(PAGE_STATE.SHOWING);

  const fetchCallback = useCallback(() => {
    loadDeductionCodeEditor({ groupId });
  }, [loadDeductionCodeEditor, groupId]);

  useEffect(() => {
    loadDeductionCodeEditor({ groupId });
  }, [fetchCallback, loadDeductionCodeEditor, groupId]);

  if (status === STATUS.LOADING) {
    return <Loader active inline="centered" />;
  }

  // alternate table and edit views within the same tab
  if (pageState !== PAGE_STATE.SHOWING) {
    return (
      <DeductionCodeForm
        currentCode={currentCode}
        pageState={pageState}
        setPageState={setPageState}
        groupId={groupId}
        onSubmit={payload => {
          setDeductionCodes(payload);
        }}
      />
    );
  }
  return (
    <DeductionCodeTable
      setCurrentCode={setCurrentCode}
      setPageState={setPageState}
      groupId={groupId}
    />
  );
};

DeductionCodesPresenter.propTypes = {
  groupId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  loadDeductionCodeEditor: PropTypes.func.isRequired,
  setDeductionCodes: PropTypes.func.isRequired,
};
