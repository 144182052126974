import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const EMPLOYER_PROFILE_LIFECYCLE = createLifecycleTypes(
  'hr/employer-profile/LIFECYCLE',
);
export const EMPLOYER_PROFILE_INIT = createRequestTypes(
  'hr/employer-profile/INITIALIZE',
);
export const FORM_SUBMITTED = 'hr/employer-profile/FORM_SUBMITTED';
export const UPDATE_EMPLOYER = createRequestTypes(
  'hr/employer-profile/UPDATE_EMPLOYER',
);
