import {
  FETCH_BENEFIT_PLANS_FOR_PRICING_CONFIG,
  FETCH_BENEFIT_PRICE_BY_ID,
  SUBMIT_BENEFIT_PRICE_CONFIG,
  ERROR_PARSING_JSON,
  REQUEST_BENEFIT_PRICES_CSV,
  CLEAR_BENEFIT_PRICE_CONFIG_STATE,
} from './pricing-configuration.types';

export const fetchPlansForPricingConfiguration = payload => ({
  type: FETCH_BENEFIT_PLANS_FOR_PRICING_CONFIG,
  payload,
});

export const fetchBenefitPriceById = payload => ({
  type: FETCH_BENEFIT_PRICE_BY_ID,
  payload,
});

export const submitBenefitPriceConfig = payload => ({
  type: SUBMIT_BENEFIT_PRICE_CONFIG,
  payload,
});

export const errorParsingJson = payload => ({
  type: ERROR_PARSING_JSON,
  payload,
});

export const fetchBenefitPricesAsCsv = payload => ({
  type: REQUEST_BENEFIT_PRICES_CSV,
  payload,
});

export const clearBenefitPriceConfigState = payload => ({
  type: CLEAR_BENEFIT_PRICE_CONFIG_STATE,
  payload,
});
