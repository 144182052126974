import React from 'react';
import PropTypes from 'prop-types';
import { currencyPropType } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  Box,
  HeadingTwo,
  BodyOne,
  PrimaryButton,
  SecondaryButton,
  Label,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { CurrencyInput } from 'common/components/currency-input/currency-input.view';

export const UpdateFlexDollarsPresenter = ({
  totalFlexDollars,
  loadedFlexDollars,
  onLoadedFlexDollarsChange,
  onSubmit,
  setIsModalOpen,
}) => {
  const { formatMessage, formatMoney } = useIntl();

  return (
    <Modal>
      <HeadingTwo>{formatMessage({ id: 'UPDATE_FLEX_DOLLARS' })}</HeadingTwo>
      <ModalContent width={500}>
        <BodyOne>
          {formatMessage({ id: 'UPDATE_FLEX_DOLLARS_DESCRIPTION' })}
        </BodyOne>
        <BodyOne marginTop="oneAndHalf">
          <strong>
            {formatMessage({ id: 'UPDATE_FLEX_DOLLARS_TOTAL_FLEX_LABEL' })}:
          </strong>{' '}
          {formatMoney(totalFlexDollars)}
        </BodyOne>
        <Box marginTop="oneAndHalf">
          <Label>
            <LabelText marginBottom="quarter">
              {formatMessage({ id: 'UPDATE_FLEX_DOLLARS_LOADED_FLEX_LABEL' })}
            </LabelText>
            <CurrencyInput
              id="loaded-flex-value"
              value={loadedFlexDollars.F}
              currency={loadedFlexDollars.C}
              onChange={(e, value) => {
                onLoadedFlexDollarsChange(value.sanitizedValue);
              }}
            />
          </Label>
        </Box>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton
          marginRight="one"
          onClick={() => setIsModalOpen(false)}
        >
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit}>
          {formatMessage({ id: 'UPDATE' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

UpdateFlexDollarsPresenter.propTypes = {
  totalFlexDollars: currencyPropType.isRequired,
  loadedFlexDollars: currencyPropType.isRequired,
  onLoadedFlexDollarsChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
