import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  useLocation,
  matchPath,
  Redirect,
} from 'react-router-dom';
import {
  BENEFIT_SET_CONFIG_PATH,
  ENROLLMENT_EXPERIENCE_CONFIG_PATH,
  PathToStepMap,
  Step,
  Steps,
} from './enrollment-design.constants';
// @TODO change this to index import after NEDT release
import { EnrollmentConfig } from '../enrollment-config/enrollment-config-nedt.container';
import { PlanSelectionContainer } from './plan-selection';
import { EnrollmentExperienceConfig } from './enrollment-experience-config';
import { EnrollmentDesignHeader } from './enrollment-design-header/enrollment-design-header.view';
import { useSelector, useDispatch } from 'react-redux';
import { selectPlanId } from './enrollment-design.selectors';
import { getEmployerBenefitPlans } from '../enrollment-config/enrollment-config.actions';

type EnrollmentDesignRouteParams = {
  groupId: string;
  planId: string;
};

const EnrollmentDesignRoutes = () => {
  const [activeStep, setActiveStep] = useState<Step>(Steps.STEP1);
  const { path, url } = useRouteMatch();
  const { groupId } = useParams<EnrollmentDesignRouteParams>();
  const location = useLocation();
  const selectedPlanId = useSelector(selectPlanId);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(PathToStepMap).forEach(stepPath => {
      const matchRoute = matchPath(location?.pathname, {
        path: `${path}${stepPath}`,
        exact: true,
      });
      if (matchRoute?.isExact) {
        setActiveStep(PathToStepMap[stepPath]);
      }
    });
  }, [location?.pathname, path]);

  useEffect(() => {
    dispatch(getEmployerBenefitPlans({ groupId }));
  }, [groupId, dispatch]);

  const commonRouteProps = {
    groupId,
    selectedPlanId,
  };

  return (
    <>
      <EnrollmentDesignHeader
        activeStep={activeStep}
        url={url}
        selectedPlanId={selectedPlanId}
      />
      <Switch>
        <Route exact path={`${path}`}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
          <PlanSelectionContainer {...commonRouteProps} />
        </Route>
        <Route exact path={`${path}${BENEFIT_SET_CONFIG_PATH}`}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
          <EnrollmentConfig {...commonRouteProps} />
        </Route>
        <Route exact path={`${path}${ENROLLMENT_EXPERIENCE_CONFIG_PATH}`}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
          <EnrollmentExperienceConfig {...commonRouteProps} />
        </Route>
        <Route>
          <Redirect to={url} />
        </Route>
        <Route>
          <Redirect to={url} />
        </Route>
      </Switch>
    </>
  );
};

export default EnrollmentDesignRoutes;
