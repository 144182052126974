import {
  GET_REWARD_LEVELS,
  GET_LIFEMARKET_PRODUCTS,
} from './rewards-page.actions';
import camelize from 'camelize';

export const initialState = {
  isLoading: true,
  hasError: false,
  rewardLevels: {
    currency: '',
    levels: [],
  },
  lifemarket: {
    isLoading: false,
    data: [],
  },
};

const applyFetching = state => ({
  ...state,
  isLoading: true,
});

const applyError = state => ({
  ...state,
  isLoading: false,
  hasError: true,
});

const applyRewardLevels = (state, action) => {
  const rewardLevelsData = action?.payload?.info?.league_points_reward_levels;
  const levels = rewardLevelsData.map(
    ({ league_points: points, league_credit: value }) => ({
      points,
      value: value?.F ?? 0,
    }),
  );

  return {
    ...state,
    rewardLevels: {
      currency: rewardLevelsData[0]?.league_credit?.C,
      levels,
    },
  };
};

const applyLifemarketProducts = (state, action) => {
  const lifemarketProducts = camelize(action?.payload?.info);
  return {
    ...state,
    isLoading: false,
    lifemarket: {
      data: {
        ...lifemarketProducts,
      },
    },
  };
};

export const rewardsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_REWARD_LEVELS.STARTED:
      return applyFetching(state);
    case GET_REWARD_LEVELS.SUCCEEDED:
      return applyRewardLevels(state, action);
    case GET_REWARD_LEVELS.ERRORED:
      return applyError(state, action);
    case GET_LIFEMARKET_PRODUCTS.STARTED:
      return applyFetching(state);
    case GET_LIFEMARKET_PRODUCTS.SUCCEEDED:
      return applyLifemarketProducts(state, action);
    case GET_LIFEMARKET_PRODUCTS.ERRORED:
      return applyError(state, action);
    default:
      return state;
  }
};
