import { DateUtils } from 'react-day-picker';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import { ConfirmModal } from './confirmation-modal.view';
import { compose, withStateHandlers, withProps, withHandlers } from 'recompose';
import { ADJUST_BENEFIT_END_DATE } from './constants';

const withInvalidDateHandler = withStateHandlers(
  ({ invalidDate = false, emptyDateError = false }) => ({
    invalidDate,
    emptyDateError,
  }),
  {
    setInvalidDate:
      (_, { formattedPolicyEndDate }) =>
      date => ({
        invalidDate: DateUtils.isDayAfter(
          date.toDate(),
          formattedPolicyEndDate,
        ),
      }),
    setEmptyDateError: () => emptyDateError => ({
      emptyDateError,
    }),
  },
);

const withModalHandler = withHandlers({
  submitConfirmation:
    ({ confirmType, onConfirm, newEndDate, setEmptyDateError }) =>
    () => {
      if (confirmType === ADJUST_BENEFIT_END_DATE && !newEndDate) {
        // set empty state error message
        setEmptyDateError(true);
      } else {
        setEmptyDateError(false);
        const payload = {
          newEndDate:
            confirmType === ADJUST_BENEFIT_END_DATE ? newEndDate : null,
        };
        onConfirm(payload);
      }
    },
});

const withDatePickerHandler = withStateHandlers(
  ({ newEndDate = null }) => ({ newEndDate }),
  {
    onDateChange:
      (_, { setInvalidDate, setEmptyDateError }) =>
      date => {
        if (date) setInvalidDate(date);
        setEmptyDateError(false);
        return {
          newEndDate: date,
        };
      },
  },
);

const withDatePickerProps = withProps(({ policyEndDate }) => ({
  formattedPolicyEndDate: moment(policyEndDate).toDate(),
}));

export default compose(
  withDatePickerProps,
  withInvalidDateHandler,
  withDatePickerHandler,
  withModalHandler,
)(ConfirmModal);
