// for ssn, we don't want this field to go through validation if value has not changed
export const omitSSNIfNotChanged = (values, oldValues) => {
  if (
    values.national_identification_number &&
    values.national_identification_number ===
      oldValues.national_identification_number
  ) {
    return {
      ...values,
      national_identification_number: undefined,
    };
  }

  return values;
};
