import { get } from 'lodash';
import { invertMap } from '@leagueplatform/web-common';

export const MALE = 'Male';
export const FEMALE = 'Female';
export const MARRIED = 'married';
export const SPOUSE = 'spouse';
export const COMMON_LAW = 'common_law';
export const DOMESTIC_PARTNERSHIP = 'domestic_partnership';
export const CHILD = 'child';
export const GRANDCHILD = 'grandchild';
export const GRANDPARENT = 'grandparent';
export const NEPHEW_NIECE = 'nephew_niece';
export const PARENT = 'parent';
export const SIBLING = 'sibling';
export const UNCLE_AUNT = 'uncle_aunt';
export const INELIGIBLE_CHILD = 'ineligible_child';
export const OTHER = 'other';

export const DEPENDENT_TYPE_SPOUSE = 'spouse';
export const DEPENDENT_TYPE_CHILD = 'child';
export const DEPENDENT_TYPE_FAMILY_MEMBER = 'family_member';

/* dependent coverage values */
export const MYSELF = 'myself';
export const MYSELF_SPOUSE = 'myself_spouse';
export const MYSELF_CHILDREN = 'myself_children';
export const MYSELF_ONE_CHILD = 'myself_one_child';
export const MYSELF_SPOUSE_CHILDREN = 'myself_spouse_children';
export const MYSELF_DOMESTICPARTNER = 'myself_domesticpartner';
export const MYSELF_DOMESTICPARTNERCHILD = 'myself_domesticpartnerchild';
export const MYSELF_DOMESTICPARTNERCHILDREN = 'myself_domesticpartnerchildren';
export const MYSELF_DOMESTICPARTNERCHILD_CHILDREN =
  'myself_domesticpartnerchild_children';
export const MYSELF_DOMESTICPARTNERCHILDREN_CHILDREN =
  'myself_domesticpartnerchildren_children';
export const MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILD =
  'myself_domesticpartner_domesticpartnerchild';
export const MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILDREN =
  'myself_domesticpartner_domesticpartnerchildren';
export const MYSELF_DOMESTICPARTNER_CHILDREN =
  'myself_domesticpartner_children';
export const MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILD =
  'myself_domesticpartner_children_domesticpartnerchild';
export const MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILDREN =
  'myself_domesticpartner_children_domesticpartnerchildren';

export const DEPENDENT_COVERAGE_OPTIONS = {
  MYSELF,
  MYSELF_SPOUSE,
  MYSELF_CHILDREN,
  MYSELF_ONE_CHILD,
  MYSELF_SPOUSE_CHILDREN,
};

export const dependentTypeLocaleMap = {
  [DEPENDENT_TYPE_SPOUSE]: 'PARTNER',
  [DEPENDENT_TYPE_CHILD]: 'CHILD',
  [DEPENDENT_TYPE_FAMILY_MEMBER]: 'FAMILY_MEMBER',
};

export const dependentTypeToRelationshipType = {
  [DEPENDENT_TYPE_SPOUSE]: [SPOUSE, MARRIED, COMMON_LAW, DOMESTIC_PARTNERSHIP],
  [DEPENDENT_TYPE_CHILD]: [CHILD],
  [DEPENDENT_TYPE_FAMILY_MEMBER]: [
    GRANDCHILD,
    GRANDPARENT,
    NEPHEW_NIECE,
    OTHER,
    PARENT,
    SIBLING,
    UNCLE_AUNT,
    INELIGIBLE_CHILD,
  ],
};

const relationshipTypeToDependentType = invertMap(
  dependentTypeToRelationshipType,
);

export const getDependentType = relationshipType =>
  get(relationshipTypeToDependentType, relationshipType);

const fieldsToOmitForDependentType = {
  [DEPENDENT_TYPE_FAMILY_MEMBER]: ['student', 'over_age_disabled'],
};

/**
 * @function fieldsToOmitForDependentType - Given a dependent type, determine
 * which fields should be omitted from the form schema.
 * @param {string} dependentType
 * @return {array} a list of field names
 */
export const getFieldsToOmitForDependentType = dependentType =>
  get(fieldsToOmitForDependentType, dependentType, []);

export const getCoverageTypeTranslationId = type => {
  switch (type) {
    case MYSELF:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF';
    case MYSELF_SPOUSE:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE';
    case MYSELF_SPOUSE_CHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE_CHILDREN';
    case MYSELF_ONE_CHILD:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_ONE_CHILD';
    case MYSELF_CHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_CHILDREN';
    case MYSELF_DOMESTICPARTNER:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER';
    case MYSELF_DOMESTICPARTNERCHILD:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD';
    case MYSELF_DOMESTICPARTNERCHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN';
    case MYSELF_DOMESTICPARTNERCHILD_CHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD_CHILDREN';
    case MYSELF_DOMESTICPARTNERCHILDREN_CHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN_CHILDREN';
    case MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILD:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILD';
    case MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILDREN';
    case MYSELF_DOMESTICPARTNER_CHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN';
    case MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILD:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILD';
    case MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILDREN:
      return 'DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILDREN';
    default:
      return type;
  }
};

export const getThirdPersonCoverageTypeTranslationId = type => {
  switch (type) {
    case MYSELF:
      return 'FOR_YOU';
    case MYSELF_SPOUSE:
      return 'FOR_YOU_SPOUSE';
    case MYSELF_SPOUSE_CHILDREN:
      return 'FOR_YOU_SPOUSE_CHILDREN';
    case MYSELF_ONE_CHILD:
      return 'FOR_YOU_ONE_CHILD';
    case MYSELF_CHILDREN:
      return 'FOR_YOU_CHILDREN';
    default:
      return type;
  }
};
