import React from 'react';
import PropTypes from 'prop-types';
import { Flex, BodyTwo } from '@leagueplatform/genesis-commons';

export const AdditionalItem = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Flex flexDirection="column" {...props}>
    {children}
  </Flex>
);

AdditionalItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AdditionalItemTitleRow = ({ children }) => (
  <Flex flexDirection="row" alignItems="center" marginBottom="half">
    {children}
  </Flex>
);

AdditionalItemTitleRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AdditionalItemTitle = ({ children }) => (
  <BodyTwo color="onSurface.text.subdued" marginRight="half" as="h2">
    {children}
  </BodyTwo>
);

AdditionalItemTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
