import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'apps/employer-experience/ui-kit';
import { ConfirmationModal, Backdrop, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@leagueplatform/locales';

export const ClearInvoicingDiagnosticsComponent = ({
  closeClearInvoiceDiagnostics,
  handleClearInvoiceDiagnostics,
}) => (
  <Backdrop>
    <ConfirmationModal
      headerText={<FormattedMessage id="CLEAR_INVOICING_DIAGNOSTICS" />}
      footerContent={
        <Flex>
          <Button onClick={closeClearInvoiceDiagnostics} confirmModal cancel>
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button onClick={handleClearInvoiceDiagnostics} confirmModal primary>
            <FormattedMessage id="CONFIRM" />
          </Button>
        </Flex>
      }
    >
      <Box>
        <FormattedHTMLMessage id="CLEAR_INVOICE_DIAGNOSTICS_CONFIRMATION" />
      </Box>
    </ConfirmationModal>
  </Backdrop>
);

ClearInvoicingDiagnosticsComponent.propTypes = {
  handleClearInvoiceDiagnostics: PropTypes.func.isRequired,
  closeClearInvoiceDiagnostics: PropTypes.func.isRequired,
};
