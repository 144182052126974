import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import { FieldWrapper } from './field-wrapper.view';
import { GridItem } from './grid-item.view';
import { SelectField } from './select-field.view';
import { TextField } from './text-field.view';
import { DateField } from './date-field.view';
import { MultiSelectField } from './multiselect-field.view';
import { RadioField } from './radio-field.view';

// Input types
const TEXT = 'text';
const SELECT = 'select';
const MULTISELECT = 'multiselect';
const RADIO = 'radio';
const DATE = 'date';
const HIDDEN = 'hidden';

export const InputField = ({
  inputType,
  name,
  label,
  required,
  tooltip,
  tooltipValues,
  getError,
  expand,
  ...otherProps
}) => {
  if (!inputType) return null;
  if (inputType === HIDDEN)
    return (
      <Field name={name}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        {({ field }) => <input {...field} type="hidden" />}
      </Field>
    );

  const error = getError ? getError(name) : otherProps.error;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <GridItem width={expand ? 1 : [1, 1, 1 / 2]} {...otherProps}>
      <FieldWrapper
        label={<FormattedMessage id={label} />}
        isRequired={required}
        tooltip={tooltip}
        tooltipValues={tooltipValues}
        error={error}
        htmlFor={name}
      >
        <Field name={name}>
          {({ field, form }) => {
            const fieldProps = {
              ...otherProps,
              field,
              form,
              label,
              hasError: Boolean(error),
              id: name,
            };
            switch (inputType) {
              case TEXT:
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                return <TextField {...fieldProps} />;
              case SELECT:
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                return <SelectField {...fieldProps} />;
              case MULTISELECT:
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                return <MultiSelectField {...fieldProps} />;
              case DATE:
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                return <DateField {...fieldProps} />;
              case RADIO:
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                return <RadioField {...fieldProps} />;
              default:
                return null;
            }
          }}
        </Field>
      </FieldWrapper>
    </GridItem>
  );
};

InputField.propTypes = {
  inputType: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipValues: PropTypes.any, // eslint-disable-line
  getError: PropTypes.func,
  expand: PropTypes.bool,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  inputType: undefined,
  name: undefined,
  label: undefined,
  required: false,
  tooltip: null,
  tooltipValues: undefined,
  expand: false,
  disabled: false,
  getError: undefined,
};

InputField.displayName = 'InputField';
