import {
  EMPLOYEE_SEARCH_INIT,
  EMPLOYEE_SEARCH_LIFECYCLE,
} from './search.types';

const initialState = {
  ready: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employeeSearchUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_SEARCH_INIT.SUCCEEDED:
      return { ...state, ready: true };
    case EMPLOYEE_SEARCH_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
