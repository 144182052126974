import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, get, isEmpty } from 'lodash';
import { Container, Dimmer } from 'semantic-ui-react';
import AddBenefit from './AddBenefit';
import { SearchCatalogue } from './search-catalogue/search-catalogue.view';
import { Benefit } from './Benefit';
import { vars, Box } from '@leagueplatform/ui-kit';
import styled from 'styled-components';

const GroupBenefit = styled(Box)`
  color: white;
  height: 2rem;
  background-color: ${vars.colour.grey};
  padding-left: 16px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 2rem;
`;

export class Benefits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCatalogue: false,
    };
    this.elRef = {};
    this.addedBenefit = {};
  }

  componentDidUpdate() {
    if (!isEmpty(this.addedBenefit)) {
      this.elRef[this.addedBenefit.benefitType].scrollIntoView('alignToTop');
      this.addedBenefit = {};
    }
  }

  render() {
    const {
      loading,
      catalogue,
      catalogueOptions,
      removeBenefit,
      removeGroupCategories,
      benefitPlanId,
      benefitClassId,
      groupId,
      addingBenefit,
      addUnsavedBenefit,
      removeUnsavedBenefit,
      renewBenefit,
      benefitsByProductType,
      formValues,
      isCatalogueEmpty,
      addUpdateBenefit,
    } = this.props;
    const { showCatalogue } = this.state;

    return (
      <Dimmer.Dimmable
        as={Container}
        dimmed={loading}
        className="container--benefits"
      >
        {map(
          benefitsByProductType,
          (benefitsByTypeList, benefitProductType) => (
            <div key={benefitProductType}>
              <GroupBenefit> {benefitProductType} </GroupBenefit>
              {map(benefitsByTypeList, (benefits, benefitType) =>
                map(benefits, benefit => {
                  const values = get(formValues, [benefitType, benefit.id], {});
                  return (
                    <div
                      ref={el => {
                        this.elRef[benefitType] = el;
                      }}
                      key={benefit.id || benefit.unsavedId}
                    >
                      <Benefit
                        name={benefit.fullName}
                        benefitVendorName={benefit.benefitVendorName}
                        id={benefit.id}
                        groupId={groupId}
                        unsavedId={benefit.unsavedId}
                        removeUnsavedBenefit={removeUnsavedBenefit}
                        removeBenefit={removeBenefit}
                        removeGroupCategories={removeGroupCategories}
                        benefitPlanId={benefitPlanId}
                        benefitClassId={benefitClassId}
                        benefitType={benefitType}
                        values={values}
                        addingBenefit={addingBenefit}
                        renewBenefit={renewBenefit}
                        benefitStatus={benefit.benefitStatus}
                        benefitEndDate={benefit.benefitEndDate}
                        addUpdateBenefit={addUpdateBenefit}
                        benefitPlanName={benefit.planName}
                      />
                    </div>
                  );
                }),
              )}
            </div>
          ),
        )}
        {!isCatalogueEmpty && (
          <AddBenefit
            showCatalogue={showCatalogue}
            shouldShowCatalogue={show => this.setState({ showCatalogue: show })}
          />
        )}
        {showCatalogue && !isCatalogueEmpty && (
          <SearchCatalogue
            catalogue={catalogue}
            catalogueOptions={catalogueOptions}
            onChange={({ product }) => {
              addUnsavedBenefit(product);
              this.addedBenefit = product;
            }}
            shouldShowCatalogue={show => this.setState({ showCatalogue: show })}
            showCatalogue={showCatalogue}
          />
        )}
      </Dimmer.Dimmable>
    );
  }
}

Benefits.propTypes = {
  loading: PropTypes.bool.isRequired,
  catalogue: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  catalogueOptions: PropTypes.arrayOf(
    PropTypes.shape({
      productType: PropTypes.string,
      description: PropTypes.string,
      fullName: PropTypes.string,
      benefitVendorName: PropTypes.string,
    }),
  ).isRequired,
  removeBenefit: PropTypes.func.isRequired,
  removeGroupCategories: PropTypes.func.isRequired,
  benefitClassId: PropTypes.string.isRequired,
  benefitPlanId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  addingBenefit: PropTypes.bool.isRequired,
  addUnsavedBenefit: PropTypes.func.isRequired,
  removeUnsavedBenefit: PropTypes.func.isRequired,
  renewBenefit: PropTypes.func.isRequired,
  addUpdateBenefit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isCatalogueEmpty: PropTypes.bool.isRequired,
  benefitsByProductType: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
