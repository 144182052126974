import { call, fork, takeLatest, all } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { getBenefitDocuments, getEmployerBenefitPlans } from 'common/services';
import {
  ADD_BENEFIT_CLASS,
  RENAME_BENEFIT_CLASS,
  SET_BENEFIT_CLASS,
  REMOVE_BENEFIT_CLASS,
  REMOVE_BENEFIT_CLASS_SERVICE,
  GET_EMPLOYER_BENEFIT_PLANS,
  FETCH_PLANS,
  GET_BENEFIT_TYPE_SCHEMAS,
  GET_BENEFIT_DOCUMENTS,
} from './types';
import {
  COPY_BENEFIT_PLAN,
  RENEW_BENEFIT_PLAN,
} from './BenefitClass/Plans/types';
import { walletTemplatesSaga } from '../wallet-templates/wallet-templates.saga';

export function* getBenefitTypeSchemas(groupId) {
  yield request(GET_BENEFIT_TYPE_SCHEMAS, [
    websocketFetch,
    'get_benefit_type_schemas',
    { group_id: groupId },
  ]);
}

export function* setBenefitClass(action) {
  const { groupId, name } = action.payload;
  yield request(SET_BENEFIT_CLASS, [
    websocketFetch,
    'set_benefit_class',
    {
      group_id: groupId,
      benefit_class: { name },
    },
  ]);
}

export function* renameBenefitClass(action) {
  const { groupId, name, id } = action.payload;
  yield request(SET_BENEFIT_CLASS, [
    websocketFetch,
    'set_benefit_class',
    {
      group_id: groupId,
      benefit_class: { name, id },
    },
  ]);
}

export function* removeBenefitClass(action) {
  const { groupId, benefitClassId } = action.payload;
  yield request(REMOVE_BENEFIT_CLASS_SERVICE, [
    websocketFetch,
    'remove_benefit_class',
    {
      group_id: groupId,
      benefit_class_id: benefitClassId,
    },
  ]);
}

export function* fetchPlans(action) {
  const { groupId, benefitClassId } = action.payload;

  yield all([
    request(
      GET_EMPLOYER_BENEFIT_PLANS,
      getEmployerBenefitPlans({ groupId, benefitClassIds: [benefitClassId] }),
      {
        groupId,
        benefitClassId,
      },
    ),
    request(GET_BENEFIT_DOCUMENTS, getBenefitDocuments({ groupId })),
  ]);
}

export function* fetchBenefitPlans({ groupId, benefitClassId }) {
  yield request(
    GET_EMPLOYER_BENEFIT_PLANS,
    getEmployerBenefitPlans({ groupId, benefitClassIds: [benefitClassId] }),
    {
      groupId,
      benefitClassId,
    },
  );
}

export function* onBenefitPlanCopied(action) {
  const { groupId, destinationBenefitClassId: benefitClassId } =
    action?.meta ?? {};
  yield call(fetchBenefitPlans, { groupId, benefitClassId });
}

export function* onBenefitPlanRenewed(action) {
  const { groupId, benefitClassId } = action?.meta ?? {};

  yield call(fetchBenefitPlans, { groupId, benefitClassId });
}

export function* employerBenefitsSaga(groupId) {
  yield takeLatest(ADD_BENEFIT_CLASS, setBenefitClass);
  yield takeLatest(FETCH_PLANS, fetchPlans);
  yield takeLatest(RENAME_BENEFIT_CLASS, renameBenefitClass);
  yield takeLatest(REMOVE_BENEFIT_CLASS, removeBenefitClass);
  yield takeLatest(COPY_BENEFIT_PLAN.SUCCEEDED, onBenefitPlanCopied);
  yield takeLatest(RENEW_BENEFIT_PLAN.SUCCEEDED, onBenefitPlanRenewed);
  yield fork(getBenefitTypeSchemas, groupId);
  yield fork(walletTemplatesSaga, groupId);
}
