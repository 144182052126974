import { PropTypes } from 'prop-types';

export const treeNodePropType = {
  active: PropTypes.bool,
  children: PropTypes.array,
  entityData: PropTypes.any,
  entityDataPath: PropTypes.string,
  name: PropTypes.string,
  schemaRef: PropTypes.string,
  toggled: PropTypes.bool,
  treePath: PropTypes.string,
};
