import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getEmployeeSpendingAccounts,
  selectEmployeeSpendingAccounts,
  resetEmployeeSpendingAccounts,
  selectEmployeeSpendingAccountsReady,
} from '../../employee-spending-accounts';
import { ChangeAllocationPresenter } from './change-allocation.view';
import * as changeAllocationActions from './change-allocation.actions';
import {
  selectChangeAllocationStatus,
  selectChangeAllocationComplete,
} from './change-allocation.selectors';

const mapStateToProps = createStructuredSelector({
  accounts: selectEmployeeSpendingAccounts,
  ready: selectEmployeeSpendingAccountsReady,
  changeAllocationStatus: selectChangeAllocationStatus,
  completed: selectChangeAllocationComplete,
});

const mapDispatchToProps = {
  getSpendingAccounts: getEmployeeSpendingAccounts,
  resetSpendingAccounts: resetEmployeeSpendingAccounts,
  ...changeAllocationActions,
};

const ChangeAllocation = ({
  changeUserBenefitAllocation,
  benefitId: account,
  ...props
}) => (
  <Formik
    initialValues={{
      account,
      amount: '',
    }}
    validateOnChange={false}
    validate={values => {
      const errors = {};

      if (!Number(values.newFunds)) {
        errors.newFunds = 'required';
      }

      if (values.account === undefined) {
        errors.account = 'required';
      }

      return errors;
    }}
    onSubmit={({ account: benefitId, newFunds: amount }) =>
      changeUserBenefitAllocation({
        benefitId,
        amount,
      })
    }
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    {formProps => <ChangeAllocationPresenter {...props} {...formProps} />}
  </Formik>
);

ChangeAllocation.propTypes = {
  benefitId: PropTypes.string,
  changeUserBenefitAllocation: PropTypes.func.isRequired,
};

ChangeAllocation.defaultProps = {
  benefitId: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAllocation);
