import { Box, Image, QuietButton } from '@leagueplatform/genesis-commons';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Modal } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import CLOSE_ICON from 'apps/public/assets/close.svg';
import { onToggleDocumentSelected as _onToggleDocumentSelected } from '../../state/claim-details.actions';
import {
  selectIsModalOpen,
  selectSelectedImageUrl,
} from '../../state/claim-details.selectors';

export const DocumentModalPresenter = ({
  isModalOpen,
  selectedImageUrl,
  onToggleDocumentSelected,
}) => {
  const { formatMessage } = useIntl();

  return (
    isModalOpen && (
      <Modal.Wrapper onClose={onToggleDocumentSelected}>
        <Box
          position="absolute"
          right="5vw"
          top="5vw"
          role="dialog"
          aria-modal
          aria-label={formatMessage({ id: 'DOCUMENT_THUMBNAIL_MODAL' })}
        >
          <QuietButton
            aria-label={formatMessage({ id: 'CLOSE' })}
            borderColor="onSurface.border.subdued"
            borderRadius="circle"
            borderStyle="solid"
            borderWidth="thin"
            onClick={onToggleDocumentSelected}
            paddingX="none"
            paddingY="none"
            size="two"
          >
            <Image src={CLOSE_ICON} role="presentation" size="threeQuarters" />
          </QuietButton>
        </Box>
        <Box>
          <Image
            alt={formatMessage({ id: 'DOCUMENT_THUMBNAIL_HIDDEN' })}
            maxHeight="90vh"
            maxWidth="80vw"
            src={selectedImageUrl}
          />
        </Box>
      </Modal.Wrapper>
    )
  );
};

DocumentModalPresenter.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  selectedImageUrl: PropTypes.string,
  onToggleDocumentSelected: PropTypes.func.isRequired,
};

DocumentModalPresenter.defaultProps = {
  selectedImageUrl: null,
};

const withConnect = connect(
  createStructuredSelector({
    isModalOpen: selectIsModalOpen,
    selectedImageUrl: selectSelectedImageUrl,
  }),
  {
    onToggleDocumentSelected: _onToggleDocumentSelected,
  },
);

export const DocumentModal = compose(withConnect)(DocumentModalPresenter);
