import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/ui-kit';

import { Button } from 'common/components/button/button.view';

import { Flex } from 'common/components/primitives.view';

export const MissingPlanOptions = ({
  activeNode,
  createBenefitPlanOptions,
  benefitPlan,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      p={3}
      my={1}
      bg="greyLightest"
      borderRadius={2}
      flexDirection="column"
      align="center"
      justify="center"
    >
      <Box mb={2}>
        {formatMessage(
          { id: 'MISSING_PLAN_OPTIONS' },
          { planName: benefitPlan.name },
        )}
      </Box>
      <Button onClick={() => createBenefitPlanOptions(activeNode.planId)}>
        {formatMessage({ id: 'START_PLAN_CONFIGURATION' })}
      </Button>
    </Flex>
  );
};

MissingPlanOptions.propTypes = {
  activeNode: PropTypes.shape({
    planId: PropTypes.string,
  }).isRequired,
  createBenefitPlanOptions: PropTypes.func.isRequired,
  benefitPlan: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
