import { isArray } from 'lodash';
import { call, cancelled, put } from 'redux-saga/effects';

/**
 *
 * @param {string} TYPE
 * @returns Object {
 *    BASE: "[TYPE]",
 *    STARTED: "[TYPE]_STARTED",
 *    SUCCEEDED: "[TYPE]_SUCCEEDED",
 *    ERRORED: "[TYPE]_ERRORED",
 *    CANCELLED: "[TYPE]_CANCELLED",
 *    request,
 *    start,
 *    success,
 *    error,
 *    cancel,
 * }
 */
export function createRequestTypes(TYPE) {
  const BASE = TYPE;
  const STARTED = `${TYPE}_STARTED`;
  const SUCCEEDED = `${TYPE}_SUCCEEDED`;
  const ERRORED = `${TYPE}_ERRORED`;
  const CANCELLED = `${TYPE}_CANCELLED`;
  return {
    BASE,
    STARTED,
    SUCCEEDED,
    ERRORED,
    CANCELLED,
    /**
     * Dispatch this action to signify you want to start a request
     * @param {*} payload
     * @param {*} meta
     * @returns Redux Action `{ type: [TYPE], payload, meta }`
     */
    request: (payload, meta) => ({
      type: BASE,
      payload,
      meta,
    }),
    /**
     * Dispatch this action before starting the request
     * @param {*} meta
     * @returns Redux Action `{ type: [TYPE].STARTED, meta }`
     */
    start: meta => ({
      type: STARTED,
      meta,
    }),
    /**
     * Dispatch this action when the request returns successfully
     * @param {*} payload
     * @param {*} meta
     * @returns Redux Action `{ type: [TYPE].SUCCEEDED, payload, meta }`
     */
    success: (payload, meta) => ({
      type: SUCCEEDED,
      payload,
      meta,
    }),
    /**
     * Dispatch this action when the request responds with an error
     * @param {*} caught
     * @param {*} meta
     * @returns Redux Action `{ type: [TYPE].ERRORED, error: true, payload: caught, meta }`
     */
    error: (caught, meta) => ({
      type: ERRORED,
      error: true,
      payload: caught,
      meta,
    }),
    /**
     * Dispatch this action to signal that the request has been cancelled
     * @param {*} meta
     * @returns Redux Action `{ type: [TYPE].CANCELLED, meta }`
     */
    cancel: meta => ({
      type: CANCELLED,
      meta,
    }),
  };
}

export const coerce = callable =>
  isArray(callable) ? call(...callable) : callable;

export function* requestSaga(type, callable, meta) {
  const { start, success, error, cancel } = type;
  yield put(start(meta));
  try {
    const payload = yield coerce(callable);
    yield put(success(payload, meta));
    return payload;
  } catch (caught) {
    return yield put(error(caught, meta));
  } finally {
    if (yield cancelled()) {
      yield put(cancel(meta));
    }
  }
}

export function request(type, callable, meta) {
  return call(requestSaga, type, callable, meta);
}
