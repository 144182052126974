import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function exportDeductionCodes calls the backend to export deduction codes
 * @param payload of type:
 *   {
 *     group_id: string;
 *   }
 * @returns object of type:
 *   {
 *     content_id: string;
 *   }
 */
export const exportDeductionCodes = payload =>
  call(websocketFetch, 'export_deduction_codes', payload);
