import React from 'react';
import PropTypes from 'prop-types';
import AddDivision from './employer-divisions-add.view';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dimmer, Header } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import {
  // eslint-disable-next-line import/no-named-default
  default as Divisions,
  DivisionItem as Division,
} from './employer-divisions-list.view';

const EmployerDivisionsPage = ({
  isAdmin,
  groupId,
  billingDivisions,
  editingId,
  editedName,
  toggleEditDivision,
  removeBillingDivision,
  submitAddBillingDivisionForm,
  editBillingDivisionName,
}) => (
  <Dimmer.Dimmable as={Box}>
    <Header as="h4">
      <FormattedMessage id="DIVISIONS" />
    </Header>
    <Divisions
      isAdmin={isAdmin}
      list={billingDivisions}
      renderItem={({ billingDivisionId, name }) => (
        <Division
          key={billingDivisionId}
          id={billingDivisionId}
          isAdmin={isAdmin}
          name={name}
          editingId={editingId}
          onNameEdit={editBillingDivisionName}
          editedName={editedName}
          toggleEditDivision={(value, payload) =>
            toggleEditDivision(value, payload)
          }
          saveBillingDivisionEdit={isDefault =>
            submitAddBillingDivisionForm({
              default: isDefault,
              name: editedName,
              billing_division_id: billingDivisionId,
              group_id: groupId,
            })
          }
          removeBillingDivision={() =>
            removeBillingDivision({
              billing_division_id: billingDivisionId,
              group_id: groupId,
            })
          }
        />
      )}
    />
    {isAdmin && (
      <AddDivision groupId={groupId} billingDivisions={billingDivisions} />
    )}
  </Dimmer.Dimmable>
);

EmployerDivisionsPage.defaultProps = {
  editedName: '',
  editingId: null,
};

EmployerDivisionsPage.propTypes = {
  removeBillingDivision: PropTypes.func.isRequired,
  billingDivisions:
    PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
  submitAddBillingDivisionForm: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  editingId: PropTypes.string,
  editedName: PropTypes.string,
  editBillingDivisionName: PropTypes.func.isRequired,
  toggleEditDivision: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default injectIntl(EmployerDivisionsPage);
