import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, ClickEater } from '@leagueplatform/ui-kit';
import { KEY } from '@leagueplatform/web-common';
import {
  Flex,
  HeadingThree,
  Label,
  LabelText,
  TextInput,
  Image,
  PrimaryButton,
  QuietButton,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import CloseIcon from 'apps/public/assets/close.svg';
import { WarningMessage } from './warning-message.view';

export const EditDocumentModal = ({ hideModal, onSubmit, documentData }) => {
  const { documentId, displayTitle, title, contentId } = documentData;
  const [displayTitleValue, setDisplayTitleValue] = useState(displayTitle);

  return (
    <Backdrop onClose={hideModal}>
      <ClickEater>
        <Flex
          width="620px"
          borderRadius="medium"
          backgroundColor="surface.background.primary"
          padding="twoAndHalf"
          maxHeight="100%"
          minHeight="auto"
          flexDirection="column"
          position="relative"
        >
          <Flex
            tabIndex="0"
            size="twoAndHalf"
            position="absolute"
            top="16px"
            right="16px"
            alignItems="center"
            justifyContent="center"
            borderRadius="circle"
            hoverStyle={{
              backgroundColor: 'surface.background.secondary',
            }}
            cursor="pointer"
            role="button"
            onClick={hideModal}
            onKeyUp={e => e.key === KEY.ENTER && hideModal()}
          >
            <Image src={CloseIcon} size={14} alt="cancel" />
          </Flex>
          <HeadingThree marginBottom="one" marginRight="one">
            Edit Benefit Document
          </HeadingThree>
          <BodyOneSecondary>File name: {title}</BodyOneSecondary>
          <Label marginTop="one">
            <LabelText marginBottom="quarter" color="onSurface.text.primary">
              Wallet Action Title
            </LabelText>
            <TextInput
              autoFocus
              name="display_title"
              value={displayTitleValue}
              onChange={e => setDisplayTitleValue(e.target.value)}
              placeholder="Name that will appear in Wallet Actions"
            />
          </Label>
          <WarningMessage />
          <Flex justifyContent="flex-end" marginTop="two">
            <QuietButton marginRight="one" onClick={hideModal}>
              Cancel
            </QuietButton>
            <PrimaryButton
              onClick={e => {
                e.preventDefault();
                onSubmit({
                  documentId,
                  displayTitle: displayTitleValue,
                  title,
                  contentId,
                });
                hideModal();
              }}
              type="submit"
            >
              Update
            </PrimaryButton>
          </Flex>
        </Flex>
      </ClickEater>
    </Backdrop>
  );
};

EditDocumentModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  documentData: PropTypes.shape({
    documentId: PropTypes.string,
    displayTitle: PropTypes.string,
    title: PropTypes.string,
    contentId: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

EditDocumentModal.defaultProps = {
  documentData: {},
};
