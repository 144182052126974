import { APP_CONFIG } from 'app-config';

/**
 * Construct paths given an employer identifier.
 * i.e. Given "shoppers" return "/lookup/shoppers/account-active"
 */
export const getVerificationPath = employerIdentifier =>
  `/lookup/${employerIdentifier}`;

export const getAccountActivePath = employerIdentifier =>
  `/lookup/${employerIdentifier}/account-active`;

export const getInviteVerificationPath = (employerIdentifier, invitationId) =>
  `/verify/${employerIdentifier}/invite/${invitationId}`;

export function getLeagueStartUrl({ email, invitationCode }) {
  const emailParam = email ? `?email=${encodeURIComponent(email)}` : '';
  return `${APP_CONFIG.REACT_APP_WR_URL}/start/${invitationCode}${emailParam}`;
}
