import { combineReducers } from 'redux';
import { makeStatusReducer } from 'common/request/status.reducer';
import { STATUS } from 'common/request/status.constants';
import { GET_MAPLE_USER } from './maple-user.actions';

export const initialState = {
  status: STATUS.IDLE,
  entities: {},
};

const entities = (state = initialState.entities, action = {}) => {
  switch (action.type) {
    case GET_MAPLE_USER.SUCCEEDED:
      return { ...state, [action.meta.userId]: action.payload.info };
    default:
      return state;
  }
};

export const mapleUserReducer = combineReducers({
  status: makeStatusReducer(GET_MAPLE_USER),
  entities,
});
