import React from 'react';
import PropTypes from 'prop-types';
import { TranslatedEntityFormField } from 'common/json-schema-form/fields';
import { FieldWrapper } from './beneficiary-entity-form.view';
import { useIntl } from '@leagueplatform/locales';
import { HeadingThree, Flex, BodyTwo } from '@leagueplatform/genesis-commons';

export const BeneficiaryTrusteeField = ({
  name,
  propSchema: { properties },
}) => {
  const { formatMessage } = useIntl();
  const renderProperties = Array.isArray(properties)
    ? properties
    : Object.entries(properties);

  return (
    <Flex flexWrap="wrap" width="100%">
      <HeadingThree as="h2" marginTop="three" marginBottom="threeQuarters">
        {formatMessage({ id: 'TRUSTEE_APPOINTMENT' })}
      </HeadingThree>
      <BodyTwo marginBottom="oneAndHalf">
        {formatMessage({ id: 'TRUSTEE_APPOINTMENT_DESCRIPTION' })}
      </BodyTwo>
      {renderProperties?.map(([propertyName, propertySchema], id) => {
        const fieldName = `${name}.${propertyName}`;
        const isEven = id % 2 === 0;
        return (
          <FieldWrapper isGrid isEven={isEven} key={propertyName}>
            <TranslatedEntityFormField
              name={fieldName}
              propSchema={propertySchema}
            />
          </FieldWrapper>
        );
      })}
    </Flex>
  );
};

BeneficiaryTrusteeField.propTypes = {
  name: PropTypes.string.isRequired,
  propSchema: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    properties: PropTypes.object,
  }).isRequired,
};
