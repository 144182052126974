import { createRequestTypes } from 'common/websocket-redux';

export const GET_INVOICING_DIAGNOSTICS = createRequestTypes(
  'finance-tools/GET_INVOICING_DIAGNOSTICS',
);

export const REQUEST_GET_INVOICING_DIAGNOSTICS =
  'finance-tools/REQUEST_GET_INVOICING_DIAGNOSTICS';

export const CLEAR_INVOICING_DIAGNOSTICS = createRequestTypes(
  'finance-tools/CLEAR_INVOICING_DIAGNOSTICS',
);

export const REQUEST_CLEAR_INVOICING_DIAGNOSTICS =
  'finance-tools/REQUEST_CLEAR_INVOICING_DIAGNOSTICS';

export const GET_NETSUITE_INVOICE_REPORT = createRequestTypes(
  'finance-tools/GET_NETSUITE_INVOICE_REPORT',
);

export const REQUEST_GET_NETSUITE_INVOICE_REPORT =
  'finance-tools/REQUEST_GET_NETSUITE_INVOICE_REPORT';
