/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { useIntl } from '@leagueplatform/locales';
import { PLAN_STATUSES } from 'common/constants';
import { getPlanByStatus } from 'common/benefit-plans';

const DATE_FORMAT = {
  year: 'numeric',
  month: 'short',
}; // e.g Jan 2020

export const usePlanYearSelectOptions = ({ benefitPlans }) => {
  const { formatMessage, formatDate } = useIntl();

  const getPlanPeriodOption = ({ plan_period, id }) => {
    const planStartDate = new Date(plan_period?.start_date);
    const planEndDate = new Date(plan_period?.end_date);
    const formattedStartDate = formatDate(planStartDate, DATE_FORMAT);
    const formattedEndDate = formatDate(planEndDate, DATE_FORMAT);

    return {
      label: `${formattedStartDate} - ${formattedEndDate}`,
      value: id,
    };
  };

  const upcomingPlan = benefitPlans.find(
    getPlanByStatus(PLAN_STATUSES.ENROLLING),
  );
  const currentlyEnrolledPlan = benefitPlans.find(
    getPlanByStatus(PLAN_STATUSES.ACTIVE),
  );

  const planYearOptions = [];
  if (upcomingPlan) {
    planYearOptions.push({
      label: formatMessage({ id: 'UPCOMING_PLAN' }),
      options: [getPlanPeriodOption(upcomingPlan)],
    });
  }
  if (currentlyEnrolledPlan) {
    planYearOptions.push({
      label: formatMessage({ id: 'CURRENT_PLAN' }),
      options: [getPlanPeriodOption(currentlyEnrolledPlan)],
    });
  }

  return planYearOptions;
};
