import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { SidebarLayout } from '../sidebar-layout.view';
import { StepPage } from '../step-page.view';
import {
  Box,
  Text,
  IconTooltipModal,
  Tooltip,
  TooltipContentV2,
  Responsive,
  Radio,
  Label,
  breakpoints,
  colour,
  fontSizeInPx,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { EnrollmentRadioBox } from 'apps/enrollment/ui-kit';
import {
  PageHeading,
  FlexForm,
  InputFieldGroup,
  StyledLabel,
  ResponsiveButton,
} from '../elements';
import { BodyTwo, Link, genesisStyled } from '@leagueplatform/genesis-commons';

const Callout = styled(Box)`
  margin: 8px 0 40px 0;
  line-height: 1.5;
  font-weight: 500;
`;

const TooltipAnchorText = styled(Text)`
  border-bottom: 2px dotted ${colour('mediumGrey')};
  color: ${colour('purpleDarkest')};
  font-size: 14px;
`;

const TooltipText = styled(Box)`
  font-size: 14px;
  padding: 12px;
  width: 350px;
  color: ${colour('neutral.grayDark')};

  p {
    margin-top: 0;
  }
`;

const StyledLink = genesisStyled(Link)`
  font-size: 14px;
`;
const StyledRadioBox = styled(EnrollmentRadioBox)`
  .radio-button {
    padding: 20px 20px 20px 27px;
    background-color: transparent;
    border: 1px solid ${colour('neutral.grayLighter')};

    [class*='StyledLabel'] {
      font-size: ${fontSizeInPx(1)};
      white-space: nowrap;
    }

    &.error {
      [type='radio'] + label:before {
        border-color: ${colour('red')};
      }
    }
  }
`;

export const Eligibility = ({
  eligibilitySelected,
  eligibilityTooltipOpened,
}) => {
  const { formatMessage, formatHTMLMessage } = useIntl();

  const [isEligible, setIsEligible] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  function handleSubmit() {
    setSubmitClicked(true);
    eligibilitySelected(isEligible);
  }

  return (
    <SidebarLayout>
      <StepPage>
        <PageHeading>
          {formatMessage({ id: 'SAY_HELLO_TO_LEAGUE' })}
        </PageHeading>
        <Text>{formatMessage({ id: 'IF_ELIGIBLE_FOR_BENEFITS' })}</Text>
        <Callout>{formatMessage({ id: 'IF_YOURE_NOT_SURE' })}</Callout>
        <Flex justify="space-between" align="baseline" width={1}>
          <StyledLabel htmlFor="eligible">
            {formatMessage({ id: 'ARE_YOU_ELIGIBLE_FOR_BENEFITS' })}
          </StyledLabel>
          <Responsive breakpoint={breakpoints.tabletAndAbove}>
            <Tooltip onHover={eligibilityTooltipOpened} inline>
              <TooltipAnchorText color="purple">
                {formatMessage({ id: 'WHAT_DOES_THIS_MEAN' })}
              </TooltipAnchorText>
              <TooltipContentV2 right>
                <TooltipText>
                  {formatHTMLMessage({ id: 'YOU_ARE_ELIGIBLE_IF' })}
                </TooltipText>
              </TooltipContentV2>
            </Tooltip>
          </Responsive>
          <Responsive breakpoint={breakpoints.belowTablet}>
            <IconTooltipModal
              onClick={event => {
                if (event.target.className.includes('Button'))
                  eligibilityTooltipOpened();
              }}
              label={formatMessage({ id: 'WHAT_DOES_THIS_MEAN' })}
              content={formatHTMLMessage({ id: 'YOU_ARE_ELIGIBLE_IF' })}
              inline
            />
          </Responsive>
        </Flex>
        <FlexForm mb={2}>
          <InputFieldGroup>
            <StyledRadioBox>
              <Radio
                name="eligible"
                id="eligible_yes"
                onChange={() => setIsEligible(true)}
                checked={isEligible === true}
                error={submitClicked && isEligible === null}
              >
                <Label htmlFor="eligible_yes">
                  {formatMessage({ id: 'YES_I_AM_ELIGIBLE' })}
                </Label>
              </Radio>
            </StyledRadioBox>
          </InputFieldGroup>
          <InputFieldGroup>
            <StyledRadioBox>
              <Radio
                name="eligible"
                id="eligible_no"
                onChange={() => setIsEligible(false)}
                checked={isEligible === false}
                error={submitClicked && isEligible === null}
              >
                <Label htmlFor="eligible_no">
                  {formatMessage({ id: 'NO_I_AM_NOT_ELIGIBLE' })}
                </Label>
              </Radio>
            </StyledRadioBox>
          </InputFieldGroup>
        </FlexForm>
        {submitClicked && isEligible === null && (
          <Box mt={0} mb={24} color="red">
            <Text m={0}>
              {formatMessage({ id: 'SELECT_ONE_OF_THE_OPTIONS' })}
            </Text>
          </Box>
        )}
        {/* eslint-disable-next-line react/jsx-no-bind -- FIXME: automatically added for existing issue */}
        <ResponsiveButton primary onClick={handleSubmit}>
          {formatMessage({ id: 'CONTINUE' })}
        </ResponsiveButton>
        <Flex mt={24} justify="flex-start" align="center">
          <BodyTwo>{formatMessage({ id: 'ALREADY_A_LEAGUE_MEMBER' })} </BodyTwo>
          <StyledLink href="/sign-in" marginLeft="one">
            {formatMessage({ id: 'SIGN_IN_HERE' })}
          </StyledLink>
        </Flex>
      </StepPage>
    </SidebarLayout>
  );
};

Eligibility.propTypes = {
  eligibilitySelected: PropTypes.func.isRequired,
  eligibilityTooltipOpened: PropTypes.func.isRequired,
};
