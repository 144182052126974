import { get } from 'lodash';
import {
  GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
  GET_DEPENDENT_ADD_QLE_REASONS,
  GET_DEPENDENT_RELATIONSHIP_TYPES,
  SET_DEPENDENT,
} from './dependent.action-types';

export const initialState = {
  dependentTerminationTypeOptions: [],
  dependentAddQLEReasons: [],
  relationshipTypes: [],
  errors: null,
  loading: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employeeDependentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPENDENT_TERMINATION_TYPE_OPTIONS.SUCCEEDED:
      return {
        ...state,
        dependentTerminationTypeOptions: action.payload.info.reason_options,
      };

    case GET_DEPENDENT_ADD_QLE_REASONS.SUCCEEDED:
      return {
        ...state,
        dependentAddQLEReasons: action.payload.info.reason_options,
      };
    case GET_DEPENDENT_RELATIONSHIP_TYPES.SUCCEEDED:
      return {
        ...state,
        relationshipTypes: action.payload.info.relationship_types,
      };
    case SET_DEPENDENT.STARTED:
      return { ...state, loading: true };
    case SET_DEPENDENT.ERRORED:
      return {
        ...state,
        errors: get(action, 'payload.info.message.reason'),
        loading: false,
      };
    case SET_DEPENDENT.SUCCEEDED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
