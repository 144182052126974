import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  PrimaryButton,
  SecondaryButton,
  HeadingThree,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';

import { Modal, Backdrop, ClickEater } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';

export const ConfirmIssueInvoiceModal = ({ fees, onClose, onSubmit }) => {
  const { formatMessage, formatMoney } = useIntl();

  return (
    <Backdrop>
      <Modal.Container>
        <ClickEater>
          <Modal.Header>
            <HeadingThree>
              {formatMessage({ id: 'ISSUE_INVOICE_MODAL_TITLE' })}
            </HeadingThree>
          </Modal.Header>

          <Modal.Body>
            <table width="100%">
              <thead>
                <Box as="th" marginBottom="quarter">
                  <SubtitleOne>{formatMessage({ id: 'FEE_TYPE' })}</SubtitleOne>
                </Box>
                <Box as="th" marginBottom="quarter">
                  <SubtitleOne>{formatMessage({ id: 'FEE_RATE' })}</SubtitleOne>
                </Box>
                <Box as="th" marginBottom="quarter">
                  <SubtitleOne>
                    {formatMessage({ id: 'PENDING_FEE_UNITS' })}
                  </SubtitleOne>
                </Box>
                <Box as="th" marginBottom="quarter">
                  <SubtitleOne>{formatMessage({ id: 'TERM' })}</SubtitleOne>
                </Box>
              </thead>
              <tbody>
                {fees.map(fee => (
                  <tr key={fee.id}>
                    <td>{formatMessage({ id: fee.fee_type.toUpperCase() })}</td>
                    <td>{formatMoney({ F: fee.rate })}</td>
                    <td>{fee.units}</td>
                    <td>
                      {fee.term
                        ? `${fee.term} ${formatMessage({
                            id: fee.term_units.toUpperCase(),
                          })}`
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <SecondaryButton onClick={onClose}>
              {formatMessage({ id: 'CANCEL' })}
            </SecondaryButton>
            <PrimaryButton onClick={onSubmit} marginLeft="one">
              {formatMessage({ id: 'ISSUE_INVOICE' })}
            </PrimaryButton>
          </Modal.Footer>
        </ClickEater>
      </Modal.Container>
    </Backdrop>
  );
};

ConfirmIssueInvoiceModal.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
