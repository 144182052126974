import {
  GET_ADHOC_INVOICE_PREVIEW,
  GET_PENDING_FEES,
  ISSUE_ADHOC_INVOICE,
  REMOVE_PENDING_FEE,
} from './fees.action-types';

export const getPendingFees = () => {
  return {
    type: GET_PENDING_FEES.BASE,
  };
};

export const getAdhocInvoicePreview = () => {
  return {
    type: GET_ADHOC_INVOICE_PREVIEW.BASE,
  };
};

export const removePendingFee = payload => {
  return {
    type: REMOVE_PENDING_FEE.BASE,
    payload,
  };
};

export const issueAdhocInvoice = (payload, params) => {
  return {
    type: ISSUE_ADHOC_INVOICE.BASE,
    payload,
    params,
  };
};
