import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Form } from 'semantic-ui-react';
import { map, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

export const DivisionItem = ({
  id,
  isAdmin,
  name,
  editingId,
  editedName,
  saveBillingDivisionEdit,
  toggleEditDivision,
  removeBillingDivision,
  onNameEdit,
}) => (
  <Table.Row>
    {editingId === id ? (
      <Table.Cell>
        <Form autoComplete="off">
          <Form.Field>
            <Form.Input
              name="name"
              onChange={(e, { value }) => onNameEdit({ name: value, id })}
              value={editedName}
            />
          </Form.Field>
        </Form>
      </Table.Cell>
    ) : (
      <Table.Cell>
        {name}
        {isEmpty(id) && (
          <span>
            <i> (Default)</i>
          </span>
        )}
      </Table.Cell>
    )}
    {isAdmin && (
      <Table.Cell>
        {editingId === id ? (
          <span>
            <Icon
              name="check"
              title="Save change"
              link
              onClick={() => saveBillingDivisionEdit(isEmpty(id))}
            />
            <Icon
              name="x"
              title="Cancel change"
              link
              onClick={() => toggleEditDivision(false, { name, id: null })}
            />
          </span>
        ) : (
          <span>
            <Icon
              name="edit"
              title="Edit name"
              link
              onClick={() => toggleEditDivision(true, { name, id })}
            />
            {!isEmpty(id) && (
              <span>
                <Icon
                  name="trash outline"
                  title="Remove division"
                  link
                  onClick={() => removeBillingDivision(id)}
                />
              </span>
            )}
          </span>
        )}
      </Table.Cell>
    )}
  </Table.Row>
);

DivisionItem.defaultProps = {
  editingId: null,
  editedName: '',
};

DivisionItem.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  removeBillingDivision: PropTypes.func.isRequired,
  saveBillingDivisionEdit: PropTypes.func.isRequired,
  onNameEdit: PropTypes.func.isRequired,
  editedName: PropTypes.string,
  editingId: PropTypes.string,
  toggleEditDivision: PropTypes.func.isRequired,
};

const DivisionsList = ({ list, renderItem, isAdmin }) => (
  <Table basic="very" padded>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="BILLING_DIVISIONS" />
        </Table.HeaderCell>
        {isAdmin && (
          <Table.HeaderCell>
            <FormattedMessage id="ACTION" />
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {map(list, ({ billingDivisionId, name }) =>
        renderItem({
          billingDivisionId,
          name,
        }),
      )}
    </Table.Body>
  </Table>
);

DivisionsList.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
};

export default DivisionsList;
