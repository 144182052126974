import { useCallback } from 'react';

export const useGetDigitalBeneficiaryForm = ({
  loadDigitalBeneficiaryForm,
  groupId,
  planId,
}) => {
  return useCallback(
    () => loadDigitalBeneficiaryForm({ groupId, planId }),
    [loadDigitalBeneficiaryForm, groupId, planId],
  );
};
