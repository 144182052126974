// TODO ID-89: once we can make an Auth-Token-based content request make sessionId only available when isAuth0Enabled flag is false
import { createSelector } from 'reselect';

export { selectSessionId } from './auth-classic/auth-classic.selectors';

export const selectAuthentication = state => state?.authentication;

export const selectIsAuthenticated = createSelector(
  selectAuthentication,
  auth => auth?.authenticated,
);

export const selectUserId = createSelector(
  selectAuthentication,
  auth => auth?.userId,
);

export const selectUserLocale = createSelector(
  selectAuthentication,
  auth => auth?.locale,
);

// TODO move this to common/state/groups based on state.groups.activeGroupId
export const selectCurrentUserGroupId = state =>
  state?.apps?.memberExperience?.info?.group?.group_id;
