import { get } from 'lodash/fp';
import { find } from 'lodash';
import { createSelector } from 'reselect';
import { selectApp } from '../app.selectors';
import { DAYFORCE_DRIVER } from 'common/constants';
import { selectFeatureFlags } from 'common/state/featureFlags/feature-flags.selectors';
import { selectEmployerDetails } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { selectUser } from 'common/state/user/user.selectors';
import { STATUS } from './ui.reducer';

export const selectUI = createSelector(selectApp, get('ui.employerReports'));

export const selectDetailedUsageIsLoading = createSelector(
  selectUI,
  ui => ui?.detailedUsageReport?.status === STATUS.LOADING,
);
export const selectDetailedUsageIsReady = createSelector(
  selectUI,
  ui => ui?.detailedUsageReport?.status === STATUS.READY,
);
export const selectDetailedUsageReportError = createSelector(
  selectUI,
  ui => ui?.detailedUsageReport?.error,
);
export const selectUsageSummaryIsLoading = createSelector(
  selectUI,
  ui => ui?.usageSummaryReport?.status === STATUS.LOADING,
);
export const selectUsageSummaryIsReady = createSelector(
  selectUI,
  ui => ui?.usageSummaryReport?.status === STATUS.READY,
);
export const selectUsageSummaryReportError = createSelector(
  selectUI,
  ui => ui?.usageSummaryReport?.error,
);

export const selectCanShowPayrollReport = createSelector(
  selectApp,
  selectFeatureFlags,
  (app, featureFlags) =>
    !!find(
      app?.employerPayrollReports?.hrDrivers,
      driver => driver.driver_type === DAYFORCE_DRIVER,
    ) || !!featureFlags?.forceShowPayrollReport,
);

// only show if group has spending account
export const selectCanShowSpendingAccountBalanceReport = createSelector(
  selectEmployerDetails,
  ({ employer }) => employer?.hasSpendingAccounts ?? false,
);

export const selectDigitalBeneficiariesEnabled = createSelector(
  selectEmployerDetails,
  details =>
    details?.employer?.group?.employer?.digitalBeneficiariesEnabled ?? false,
);

export const selectPayrollReady = createSelector(
  selectUI,
  ui => ui?.lifecycle?.payrollReady,
);

export const selectLastChanged = createSelector(
  selectUI,
  ui => ui?.lifecycle?.lastChanged,
);

export const selectUserEmail = createSelector(selectUser, get('email'));
