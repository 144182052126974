import React from 'react';
import styled, { css } from 'styled-components';
import { vars } from '@leagueplatform/ui-kit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { getAppSupportedLocale, DEFAULT_LOCALE } from '@leagueplatform/locales';
import { SUPPORTED_LOCALE_LIST as supportedLocaleList } from 'common/constants';

const confirmModalRules = css`
  padding: 0.6rem;
  width: 19rem;
`;

const errorRules = css`
  border: 1px solid ${vars.colour.red};
  outline: none;
`;

const language = getAppSupportedLocale(DEFAULT_LOCALE, supportedLocaleList);
const getDaysForLocale = () => {
  const formatDays = new Intl.DateTimeFormat(language, {
    weekday: 'short',
    timeZone: 'UTC',
  });
  const daysArr = [];
  for (let day = 0; day < 7; day += 1) {
    const testDate = new Date(0, 0, day, 0, 0, 0);
    daysArr.push(formatDays.format(testDate));
  }
  return daysArr;
};

const getMonthsForLocale = () => {
  const formatMonth = new Intl.DateTimeFormat(language, {
    month: 'long',
    timeZone: 'UTC',
  });
  const monthsArr = [];
  for (let month = 0; month < 12; month += 1) {
    // using day 2 to solve for a safari bug
    const testDate = new Date(0, month, 2, 0, 0, 0);
    monthsArr.push(formatMonth.format(testDate));
  }
  return monthsArr;
};

export const StyledDayPickerInput = styled(({ ...rest }) => {
  return (
    <DayPickerInput
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...rest}
      dayPickerProps={{
        months: getMonthsForLocale(),
        weekdaysShort: getDaysForLocale(),
        ...rest.dayPickerProps,
      }}
    />
  );
})`
  ${({ confirmModal }) => confirmModal && confirmModalRules} ${({ error }) =>
    error && errorRules};
`;
