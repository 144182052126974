import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectReady, selectError, selectFirstName } from './invite.selectors';
import { InviteView } from './invite.view';
import {
  INVITE_PAGE_LIFECYCLE,
  createAccountGroupInvite,
} from './invite.actions';

export const InviteContainer = props => {
  const dispatch = useDispatch();

  const { inviteId } = useParams();

  const ready = useSelector(selectReady);
  const error = useSelector(selectError);
  const firstName = useSelector(selectFirstName);

  const allProps = {
    ...props,
    ready,
    error,
    firstName,
  };

  // lifecycle
  useEffect(() => {
    dispatch(INVITE_PAGE_LIFECYCLE.visit({ inviteId }));
  }, [dispatch, inviteId]);

  const onCreateAccount = useCallback(() => {
    dispatch(createAccountGroupInvite({ inviteId }));
  }, [dispatch, inviteId]);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <InviteView {...allProps} onCreateAccount={onCreateAccount} />;
};
