import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '@leagueplatform/genesis-commons';
import {
  colour,
  fontSizeInPx,
  spaceInPx,
  leagueFont,
  Heading,
  FontAwesomeIcon,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { useIntl } from '@leagueplatform/locales';

import { DependentForm } from '../components';
import { RemoveDependentModal } from '../remove-dependent-modal.container';
import {
  Breadcrumb,
  BreadcrumbLinkItem,
  BreadcrumbItem,
} from 'apps/employer-experience/ui-kit';
import { Divider } from '../../components/divider.view';

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
`;

const RemoveDependentButton = styled.button`
  background-color: ${colour('white')};
  font-size: ${fontSizeInPx(2)};
  color: ${colour('red')};
  border: 0;
  ${leagueFont({ medium: true })};

  svg {
    margin-right: ${spaceInPx(1)};
  }

  :hover {
    cursor: pointer;
  }
`;

export const EditDependentPresenter = ({
  dependent,
  dependentsBaseUrl,
  onSubmit,
  onCancel,
  onRemove,
  fields,
  countryCode,
  terminationTypeOptions,
  loading,
}) => {
  const { formatMessage } = useIntl();
  const [showRemoveDependentModal, setShowRemoveDependentModal] =
    useState(false);
  return (
    <>
      {showRemoveDependentModal && (
        <RemoveDependentModal
          dependentName={`${dependent.first_name} ${dependent.last_name}`}
          terminationOptions={terminationTypeOptions}
          onClose={() => setShowRemoveDependentModal(false)}
          onRemove={onRemove}
        />
      )}
      <Box paddingX="half" overflowY="auto">
        <Breadcrumb>
          <BreadcrumbLinkItem to={dependentsBaseUrl}>
            {formatMessage({ id: 'DEPENDENTS' })}
          </BreadcrumbLinkItem>
          <BreadcrumbLinkItem
            to={`${dependentsBaseUrl}/${dependent.dependent_id}`}
          >
            {dependent.first_name} {dependent.last_name}
          </BreadcrumbLinkItem>
          <BreadcrumbItem>{formatMessage({ id: 'EDIT' })} </BreadcrumbItem>
        </Breadcrumb>
        <Box marginTop="oneAndHalf" marginBottom="twoAndHalf">
          <Flex justify="space-between" align="center">
            <StyledHeading level={3}>
              {formatMessage({ id: 'EDIT' })} {dependent.first_name}{' '}
              {dependent.last_name}
            </StyledHeading>
            {!dependent.pendingSuspension && !dependent.suspended && (
              <RemoveDependentButton
                disabled={!terminationTypeOptions.length}
                transparent
                onClick={() => setShowRemoveDependentModal(true)}
              >
                <FontAwesomeIcon icon="trash-alt" />
                {formatMessage({ id: 'REMOVE_DEPENDENT' })}
              </RemoveDependentButton>
            )}
          </Flex>
        </Box>
        <StyledHeading level="4">
          {formatMessage({ id: 'BASIC_INFO' })}
        </StyledHeading>
        <Divider bottomBorder pt={2} />
        <DependentForm
          initialValues={dependent}
          onSubmit={onSubmit}
          onCancel={onCancel}
          fields={fields}
          countryCode={countryCode}
          loading={loading}
        />
      </Box>
    </>
  );
};

EditDependentPresenter.propTypes = {
  dependent: PropTypes.shape({
    dependent_id: PropTypes.string.isRequired,
    relationship_type: PropTypes.string.isRequired,
    date_of_birth: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    national_identification_number: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    tobacco_user: PropTypes.bool,
    pendingSuspension: PropTypes.bool,
    suspended: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  fields: PropTypes.shape(PropTypes.any).isRequired,
  dependentsBaseUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  terminationTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};
