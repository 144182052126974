import { isEmpty } from 'lodash';
import {
  sendAnalyticsEvent,
  sendAnalyticsPageView,
} from '@leagueplatform/analytics';
import { postMessage } from 'common/utilities/post-message-helpers';

const isInIframe = () => window !== window.parent;

const hasWrHostSearchParam = () => /isapp=1/.test(window.location.search);

export const isInWidgetRunnerFrame = () =>
  isInIframe() && hasWrHostSearchParam();

export const postToWR = (type, payload = {}, targetWindow = window.top) => {
  postMessage(targetWindow, { type, payload, source: 'postToWR' });
};

export const sendAnalyticEvent = payload => {
  sendAnalyticsEvent(payload);
};

export const sendAnalyticPageView = ({ title, target }) => {
  sendAnalyticsPageView({
    path: window.location.pathname,
    title,
    target,
  });
};

const categories = {
  HOMESCREEN: 'Homescreen',
  NOTIFICATIONS: 'Notifications',
  HEALTH_GOALS: 'Health Goals',
  COVERAGE_SUMMARY: 'Coverage Summary',
  DOCUMENTS: 'My Documents',
  CLAIMS_HISTORY_LIST: 'Claims History List',
  CLAIMS_HISTORY_EMPTY_STATE: 'Claims History Empty State',
  CLAIMS_HISTORY_DETAIL: 'Claims History Detail',
  bankingInfo: {
    LIST: 'Banking Info List',
    EMPTY_STATE: 'Banking Info Empty State',
    ADD_ACCOUNT_LIST: 'Banking Info Add Account List',
    ACCOUNT_DETAIL: 'Banking Info Account Detail',
    ADD_BANK_ACCOUNT: 'Add Bank Account',
    EDIT_BANK_ACCOUNT: 'Edit Bank Account',
    INTERAC_CODE: 'Interac Code',
    DELETE_ACCOUNT: 'Delete Account',
  },
};

/**
 * TODO: If you're working with a component that still uses this utility,
 * please take the opportunity to refactor the component to capture analytics
 * using @leagueplatform/analytics functions instead
 */
export const analytics = {
  pageViews: {
    qle: {
      overview() {
        sendAnalyticPageView({
          title: 'QLE Overview',
        });
      },
    },
    bankingInfo: {
      list() {
        sendAnalyticPageView({
          title: categories.bankingInfo.LIST,
        });
      },
      emptyState() {
        sendAnalyticPageView({
          title: categories.bankingInfo.EMPTY_STATE,
        });
      },
      addAccountList() {
        sendAnalyticPageView({
          title: categories.bankingInfo.ADD_ACCOUNT_LIST,
        });
      },
      accountDetail() {
        sendAnalyticPageView({
          title: categories.bankingInfo.ACCOUNT_DETAIL,
        });
      },
    },
    home() {
      sendAnalyticPageView({
        title: 'Home',
      });
    },

    notifications() {
      sendAnalyticPageView({
        title: 'Notifications',
      });
    },

    health: {
      healthPage() {
        sendAnalyticPageView({
          title: 'Health',
        });
      },

      articles() {
        sendAnalyticPageView({
          title: 'Articles',
        });
      },

      goals() {
        sendAnalyticPageView({
          title: 'Health - Goals',
        });
      },

      programSelection() {
        sendAnalyticPageView({
          title: 'View Program Selection',
        });
      },

      currentGoal() {
        sendAnalyticPageView({
          title: 'View Current Goal',
        });
      },

      goalCompleted() {
        sendAnalyticPageView({
          title: 'View Goal Completed Screen',
        });
      },

      programCompleted() {
        sendAnalyticPageView({
          title: 'View Program Completed Screen',
        });
      },
    },

    coverageSummary(productName) {
      sendAnalyticPageView({
        title: `Coverage Summary - ${productName}`,
      });
    },

    documents: {
      myDocumentsList() {
        sendAnalyticPageView({
          title: 'My Documents - List View',
        });
      },

      myDocumentsAdd() {
        sendAnalyticPageView({
          title: 'My Documents - Add Document',
        });
      },

      myDocumentsEdit() {
        sendAnalyticPageView({
          title: 'My Documents - Edit Document',
        });
      },
    },
  },
  bankingInfo: {
    cancelDeletingAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { accountType } = payload;

      sendAnalyticEvent({
        category: categories.bankingInfo.DELETE_ACCOUNT,
        action: 'Cancel Deleting Account',
        label: accountType,
      });
    },

    copyInteracCode() {
      sendAnalyticEvent({
        category: categories.bankingInfo.INTERAC_CODE,
        action: 'Copy Interac Code',
      });
    },
    errorSubmitAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Error Submit Account',
        label: accountType,
      });
    },

    backendErrorValidatingAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Backend Error Validating Account',
        label: accountType,
      });
    },

    frontendErrorValidatingAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Frontend Error Validating Account',
        label: accountType,
      });
    },

    viewHelpPopup({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'View Help Popup',
        label: accountType,
      });
    },

    validateAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Validate Account',
        label: accountType,
      });
    },

    selectAccountType({ payload }) {
      if (isEmpty(payload)) return;

      sendAnalyticEvent({
        category: categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Select Account Type',
        label: payload.accountType,
      });
    },

    errorDeletingAccount({ payload }) {
      if (isEmpty(payload)) return;

      sendAnalyticEvent({
        category: categories.bankingInfo.DELETE_ACCOUNT,
        action: 'Error Deleting Account',
        label: payload.accountType,
      });
    },

    confirmDeleteAccount({ payload }) {
      if (isEmpty(payload)) return;

      sendAnalyticEvent({
        category: categories.bankingInfo.DELETE_ACCOUNT,
        action: 'Confirm Delete Account',
        label: payload.accountType,
      });
    },

    errorGeneratingNewInteracCode() {
      sendAnalyticEvent({
        category: categories.bankingInfo.INTERAC_CODE,
        action: 'Error Generating New Interac Code',
      });
    },

    generateNewInteracCode() {
      sendAnalyticEvent({
        category: categories.bankingInfo.INTERAC_CODE,
        action: 'Generate New Interac Code',
      });
    },

    confirmSubmitAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Confirm Submit Account',
        label: accountType,
      });
    },

    cancelSubmitAccount({ payload }) {
      if (isEmpty(payload)) return;

      const { isEditing, accountType } = payload;

      sendAnalyticEvent({
        category: isEditing
          ? categories.bankingInfo.EDIT_BANK_ACCOUNT
          : categories.bankingInfo.ADD_BANK_ACCOUNT,
        action: 'Cancel Submit Account',
        label: accountType,
      });
    },
  },

  homescreen: {
    bannerClick(url) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Homescreen Banner',
        label: url,
      });
    },

    bannerClickNoImage(url) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Homescreen Banner - No Image',
        label: url,
      });
    },

    viewFeaturedProduct(serviceId) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'View Featured Product',
        label: serviceId,
      });
    },

    viewArticle(url) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Read More',
        label: url,
      });
    },

    viewMoreMarketplace() {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'View More',
        label: 'Marketplace',
      });
    },

    viewMoreContent() {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'View More',
        label: 'Content',
      });
    },

    viewMoreHealthPrograms() {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'View All',
        label: 'Health Programs',
      });
    },

    markGoalComplete(programName, goalName) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Mark Goal as Complete',
        label: `${programName} - ${goalName}`,
      });
    },

    skipGoal(programName, goalName) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Skip Goal',
        label: `${programName} - ${goalName}`,
      });
    },

    clickRecommendedProgram(programName) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Select Recommended Health Program',
        label: programName,
      });
    },

    clickCategoryCard(programName) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Select Health Program Category',
        label: programName,
      });
    },

    quickAction(action) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Select Quick Action',
        label: action,
      });
    },

    helpTriageQuestion(question) {
      sendAnalyticEvent({
        category: categories.HOMESCREEN,
        action: 'Select Question',
        label: question,
      });
    },
  },

  notifications: {
    viewNotification(type) {
      sendAnalyticEvent({
        category: categories.NOTIFICATIONS,
        action: 'View Notification',
        label: type,
      });
    },
  },

  healthGoals: {
    selectAProgram(programName, isRecommended = false) {
      const action = isRecommended
        ? 'Select Recommended Health Program'
        : 'Select a Program';
      sendAnalyticEvent({
        action,
        category: categories.HEALTH_GOALS,
        label: programName,
      });
    },

    enrollInProgram(programName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'Enroll in Program',
        label: programName,
      });
    },

    leaveProgram(programName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'Leave Program',
        label: programName,
      });
    },

    viewCurrentProgramDetails(programName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'View Current Program Details',
        label: programName,
      });
    },

    markGoalComplete(programName, goalName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'Mark Goal as Complete',
        label: `${programName} - ${goalName}`,
      });
    },

    redeemPoints(pointsValue) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'Redeem Points',
        label: pointsValue,
      });
    },

    skipGoal(programName, goalName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'Skip Goal',
        label: `${programName} - ${goalName}`,
      });
    },

    viewGoal(programName, goalName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'View Goal',
        label: `${programName} - ${goalName}`,
      });
    },

    viewTheme(programName, themeName) {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'View Theme',
        label: `${programName} - ${themeName}`,
      });
    },

    chatWithConcierge() {
      sendAnalyticEvent({
        category: categories.HEALTH_GOALS,
        action: 'Chat With Health Concierge Banner',
      });
    },
  },

  documents: {
    uploadDocument(documentType) {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Upload Document',
        label: documentType,
      });
    },

    editDocument(documentType) {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Edit Document',
        label: documentType,
      });
    },

    customDocumentTypeSet(documentTypeOtherDescription) {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Custom Type Set',
        label: documentTypeOtherDescription,
      });
    },

    documentsEditOrUploadAttempt() {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Attempt',
        label: 'upload|edit',
      });
    },

    documentsApiCallFail(errorMessage) {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Fail',
        label: errorMessage,
      });
    },

    documentsApiCallSuccess() {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Success',
        label: 'upload|edit',
      });
    },

    documentsEditOrUploadCancel() {
      sendAnalyticEvent({
        category: categories.DOCUMENTS,
        action: 'Cancel',
        label: 'upload|edit',
      });
    },
  },

  claimsHistory: {
    filter(status) {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_LIST,
        action: 'Filter',
        label: status,
      });
    },

    viewDetails() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_LIST,
        action: 'View Details',
      });
    },

    submitClaim() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_EMPTY_STATE,
        action: 'Submit Claim',
      });
    },

    viewAllClaims() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_EMPTY_STATE,
        action: 'View all claims',
      });
    },

    viewClaimDocuments() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_DETAIL,
        action: 'View Claim Documents',
      });
    },

    cancelClaim() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_DETAIL,
        action: 'Cancel Claim',
      });
    },

    editClaim() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_DETAIL,
        action: 'Edit Claim',
      });
    },

    speakWithAgent() {
      sendAnalyticEvent({
        category: categories.CLAIMS_HISTORY_DETAIL,
        action: 'Speak with Agent',
      });
    },
  },
};
