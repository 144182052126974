import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { JSONSchemaDefinitionPropType } from '../prop-types';
import { Field } from 'formik';
import { RenderFieldByPropSchema } from './render-field-by-prop-schema';
import { useIntl } from '@leagueplatform/locales';

export const TranslatedEntityFormField = ({
  name,
  propSchema: { title, description, ...propSchema },
  isRequired = false,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Field name={name}>
      {({ field, form }) => {
        const errorText = get(form.errors, name, null); // name can be in dot notation for nested fields
        const errorMessage = errorText && `${errorText} `;
        const translatedPropSchema = {
          ...propSchema,
          ...(title && { title: formatMessage({ id: title }) }),
          ...(description && {
            description: formatMessage({ id: description }),
          }),
        };
        const fieldProps = {
          field,
          form,
          name,
          isRequired,
          errorMessage,
          propSchema: translatedPropSchema,
          ...props,
        };
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        return <RenderFieldByPropSchema {...fieldProps} />;
      }}
    </Field>
  );
};

TranslatedEntityFormField.propTypes = {
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  propSchema: JSONSchemaDefinitionPropType,
};

TranslatedEntityFormField.defaultProps = {
  isRequired: false,
  propSchema: {},
};
