import React, { useEffect } from 'react';
import { Heading, Text } from '@leagueplatform/ui-kit';
import { EmployerReportsInfoBox, EmployerReportsLoaderButton } from './ui-kit';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const EnrolmentReportDownload = styled(EmployerReportsLoaderButton)`
  margin: 1rem 0;
`;

const NoteText = styled.strong`
  margin-right: 0.5rem;
`;

export const EnrolmentReport = ({
  groupId,
  loadingState,
  getEmployerEnrolmentReport,
  contentId,
  reportError,
  email,
  visit,
}) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();

  // lifecycle
  useEffect(() => {
    visit();
  }, [visit]);
  return (
    <>
      <Heading level={5}>
        <FormattedMessage id="ENROLLMENT_REPORT" />
      </Heading>
      <Text>
        <FormattedMessage id="DOWNLOAD_A_CSV" />
      </Text>
      <Text>
        <NoteText>
          <FormattedMessage id="NOTE" />:
        </NoteText>
        <FormattedMessage id="REPORTS_LOADING_NOTE_COPY" />
      </Text>
      <EnrolmentReportDownload
        primary
        type="submit"
        loading={loadingState}
        onClick={() => getEmployerEnrolmentReport(groupId, isInHRAdminPortal)}
      >
        <FormattedMessage id="DOWNLOAD_ENROLLMENT_REPORT" />
      </EnrolmentReportDownload>
      <EmployerReportsInfoBox
        loading={loadingState}
        error={reportError}
        ready={!!contentId}
        email={email}
      />
    </>
  );
};

EnrolmentReport.propTypes = {
  groupId: PropTypes.string,
  loadingState: PropTypes.bool.isRequired,
  getEmployerEnrolmentReport: PropTypes.func.isRequired,
  contentId: PropTypes.string,
  reportError: PropTypes.string,
  email: PropTypes.string.isRequired,
  visit: PropTypes.func.isRequired,
};

EnrolmentReport.defaultProps = {
  groupId: null,
  contentId: null,
  reportError: null,
};
