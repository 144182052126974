/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Message, Loader, Dimmer } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { AccountOverview } from 'apps/employer-experience-v2/account-overview.view';
import {
  Box,
  HeadingTwo,
  PrimaryButton,
  SecondaryButton,
  genesisStyled,
} from '@leagueplatform/genesis-commons';
import { Modal, ModalButtons } from '../../ui-kit';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const StyledForm = genesisStyled(Form)`
  & label {
    font-weight: 500 !important;
  }
`;

const DimmerContainer = genesisStyled(Box)`
  position: relative;
`;

const useCoverageSummary = ({ values, accounts }) => {
  const selectedAccount =
    values.account && accounts
      ? accounts.find(o => o.id === values.account)
      : undefined;

  return {
    coverageSummary: selectedAccount?.summary?.coverage ?? '',
    currentFunds: selectedAccount?.summary?.amountAvailable?.F ?? 0,
  };
};

export const ChangeAllocationPresenter = ({
  ready,
  values,
  accounts,
  setFieldValue,
  handleSubmit,
  errors,
  changeAllocationStatus,
  benefitId,
  completed,
  onClose,
  resetSpendingAccounts,
  resetForm,
  resetChangeAllocationStatus,
  getSpendingAccounts,
  userId,
  groupId,
}) => {
  const { coverageSummary, currentFunds } = useCoverageSummary({
    values,
    accounts,
  });

  const handleClose = useCallback(() => {
    resetForm();
    resetSpendingAccounts();
    resetChangeAllocationStatus();
    onClose();
  }, [resetForm, resetSpendingAccounts, resetChangeAllocationStatus, onClose]);

  useEffect(() => {
    getSpendingAccounts({ userId, groupId });
  }, [getSpendingAccounts, userId, groupId]);

  useEffect(() => {
    if (completed === true) {
      handleClose();
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'SUCCESSFULLY_UPDATED_ALLOCATION',
      });
    }
  }, [completed, handleClose]);

  return (
    <Modal onClose={handleClose} maxHeight="100%">
      <Box>
        <HeadingTwo>
          <FormattedMessage id="CHANGE_ALLOCATION_DETAILS" />
        </HeadingTwo>
      </Box>
      <Box overflowY="auto" overflowX="hidden" marginY="oneAndHalf">
        {!ready ? (
          <DimmerContainer minHeight={200} minWidth={600}>
            <Dimmer active inverted>
              <Loader active size="large" />
            </Dimmer>
          </DimmerContainer>
        ) : (
          <>
            <Box marginTop="half" marginBottom="oneAndHalf">
              <FormattedMessage id="CHANGES_WILL_AFFECT_OVERALL_POLICY" />
            </Box>
            <StyledForm>
              <Form.Group>
                <Form.Field width={8} required>
                  <label htmlFor="account">
                    <FormattedMessage id="ACCOUNT" />
                  </label>
                  <Form.Select
                    name="account"
                    options={accounts.map(o => ({
                      text: o.fullName,
                      value: o.id,
                    }))}
                    disabled={!!benefitId}
                    defaultValue={values.account}
                    error={!!errors.account}
                    onChange={(e, data) => setFieldValue('account', data.value)}
                  />
                </Form.Field>
                <Form.Field width={4}>
                  <label htmlFor="currentFunds">
                    <FormattedMessage id="CURRENT_FUNDS" />
                  </label>
                  <Form.Input
                    name="currentFunds"
                    type="number"
                    placeholder="$"
                    value={currentFunds}
                    disabled
                  />
                </Form.Field>
                <Form.Field width={4} required>
                  <label htmlFor="newFunds">
                    <FormattedMessage id="NEW_FUNDS" />
                  </label>
                  <Form.Input
                    name="newFunds"
                    type="number"
                    step="0.01"
                    placeholder="$"
                    value={values.newFunds}
                    error={!!errors.newFunds}
                    onChange={(_, data) =>
                      setFieldValue('newFunds', Number(data.value))
                    }
                  />
                </Form.Field>
              </Form.Group>
            </StyledForm>
            {changeAllocationStatus.error && (
              <Message negative>{changeAllocationStatus.error}</Message>
            )}
            <AccountOverview body={coverageSummary} />
          </>
        )}
      </Box>
      <ModalButtons>
        <SecondaryButton onClick={() => handleClose()} marginRight="one">
          <FormattedMessage id="CANCEL" />
        </SecondaryButton>
        <PrimaryButton onClick={handleSubmit}>
          <FormattedMessage id="UPDATE" />
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

ChangeAllocationPresenter.propTypes = {
  ready: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    newFunds: PropTypes.string,
    account: PropTypes.string,
  }),
  errors: PropTypes.shape({
    newFunds: PropTypes.string,
    account: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  benefitId: PropTypes.string,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      benefitName: PropTypes.string,
      benefitId: PropTypes.string,
    }),
  ),
  changeAllocationStatus: PropTypes.shape({
    pending: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  completed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resetSpendingAccounts: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  resetChangeAllocationStatus: PropTypes.func.isRequired,
  getSpendingAccounts: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

ChangeAllocationPresenter.defaultProps = {
  values: {
    benefitId: null,
    amount: '',
    note: null,
  },
  benefitId: undefined,
  errors: {},
  accounts: [],
};

ChangeAllocationPresenter.displayName = 'ChangeAllocationPresenter';
