/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getBeneficiariesReport = ({ groupId: group_id }) =>
  call(websocketFetch, 'request_report', {
    type: 'beneficiaries_report',
    request: {
      group_id,
    },
  });
