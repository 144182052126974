import camelize from 'camelize';
import { get, pick, findIndex, has, set, cloneDeep } from 'lodash';
import { SET_DEFAULT_ACCOUNT } from './banking-info.actions';
import {
  SET_VALIDATED_DIRECT_DEPOSIT_ACCOUNT_INFO,
  REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT,
  RESET_TO_INITIAL_STATE,
  REQUEST_GENERATE_INTERAC_CODE,
} from './direct-deposit-account/account-info-form/account-info-form.types';
import { REQUEST_DELETE_DIRECT_DEPOSIT } from './direct-deposit-account/delete-direct-deposit/delete-direct-deposit.types';
import { OPEN_CONFIRMATION_MODAL } from './direct-deposit-account/direct-deposit-account.types';
import { INTERAC_PAYMENT } from './payment-credential-keys';

const initialUiState = {
  error: null,
  paymentCredentialValidation: {
    valid: null,
    code: null,
    message: null,
  },
  modals: {
    confirmationModal: {
      isShown: false,
      credentials: {},
    },
  },
};

export const initialState = {
  ...initialUiState,
  userPaymentCredentials: null,
};

/**
 * Return object with updated Interac code in state's userPaymentCredentials. Also update
 * it in selectedAccount iff it is an Interac account
 *
 * @param {object} state: current state
 * @param {string} newCode: new Interac code
 */
export const returnUpdatedInteracCode = (
  { userPaymentCredentials: currentUserPaymentCredentials },
  newCode,
) => {
  const interacIdx = findIndex(
    currentUserPaymentCredentials,
    paymentCredentials => has(paymentCredentials, INTERAC_PAYMENT),
  );

  return {
    userPaymentCredentials: set(
      cloneDeep(currentUserPaymentCredentials),
      `[${interacIdx}].${INTERAC_PAYMENT}.code`,
      newCode,
    ),
  };
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const bankingInfoReducer = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST_DELETE_DIRECT_DEPOSIT.ERRORED:
    case REQUEST_GENERATE_INTERAC_CODE.ERRORED:
      return {
        ...state,
        error: camelize(get(action, 'payload.info.error')),
      };
    case SET_VALIDATED_DIRECT_DEPOSIT_ACCOUNT_INFO:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const paymentCredentialValidation = pick(action.payload, [
        'valid',
        'code',
        'message',
      ]);

      return {
        ...state,
        paymentCredentialValidation,
      };
    case OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        modals: {
          confirmationModal: {
            isShown: true,
            credentials: get(action.payload, 'credentials'),
          },
        },
      };
    case REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.SUCCEEDED:
    case REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.CANCELLED:
    case REQUEST_CREATE_DIRECT_DEPOSIT_ACCOUNT.ERRORED:
      return {
        ...state,
        modals: {
          confirmationModal: {
            isShown: false,
            credentials: {},
          },
        },
      };
    case RESET_TO_INITIAL_STATE:
      return {
        ...state,
        ...initialUiState,
      };
    case REQUEST_GENERATE_INTERAC_CODE.SUCCEEDED:
      return {
        ...state,
        ...returnUpdatedInteracCode(state, get(action, 'payload.info.code')),
      };
    case SET_DEFAULT_ACCOUNT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
