import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectEmployeeName,
  selectEmployeeStatus,
  selectEmployeeUserId,
  selectEmployeeEmail,
  selectEmployeeSuspendedDate,
  selectEmployeeSuspendedType,
  selectEmployeeCanReinstate,
} from './employee-details.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { selectReady } from 'apps/employer-experience-v2/edit-employee/edit-employee.selectors';
import { Loader } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import {
  selectEmployeeInviteURL,
  selectGroupId,
  selectEmployeeGroupRole,
  selectEmployeeInvitationUrl,
  selectEmployeeGetError,
  selectEmployeeProfileReady,
} from '../selectors';
import { selectEmployerHasSpendingAccounts } from '../../selectors';
import { editEmployeeVisited, clearEmployeeDetails } from '../actions';
import { EmployeeTabs } from './EmployeeTabs';

const mapStateToProps = createStructuredSelector({
  employeeName: selectEmployeeName,
  employeeInviteURL: selectEmployeeInviteURL,
  employeeStatus: selectEmployeeStatus,
  employeeUserId: selectEmployeeUserId,
  groupId: selectGroupId,
  employeeEmail: selectEmployeeEmail,
  groupRole: selectEmployeeGroupRole,
  hasSpendingAccounts: selectEmployerHasSpendingAccounts,
  invitationUrl: selectEmployeeInvitationUrl,
  isAdmin: selectUserIsAdmin,
  employeeGetError: selectEmployeeGetError,
  employeeProfileReady: selectEmployeeProfileReady,
  ready: selectReady,
  suspendedDate: selectEmployeeSuspendedDate,
  suspendedType: selectEmployeeSuspendedType,
  employeeCanReinstate: selectEmployeeCanReinstate,
});

const mapDispatchToProps = {
  editEmployeeVisited,
  clearEmployeeDetails,
};

export const EmployeeTabsContainer = props => {
  const {
    employeeProfileReady,
    ready,
    employeeId,
    groupId,
    clearEmployeeDetails: dispatchClearEmployeeDetails,
    editEmployeeVisited: dispatchEditEmployeeVisited,
  } = props;

  useEffect(() => {
    dispatchEditEmployeeVisited(employeeId, groupId);
    return dispatchClearEmployeeDetails;
  }, [
    employeeId,
    groupId,
    dispatchEditEmployeeVisited,
    dispatchClearEmployeeDetails,
  ]);

  return employeeProfileReady && ready ? (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <EmployeeTabs {...props} />
  ) : (
    <Loader active inline="centered" />
  );
};

EmployeeTabsContainer.defaultProps = {
  ready: false,
  employeeProfileReady: false,
};

EmployeeTabsContainer.propTypes = {
  ready: PropTypes.bool,
  clearEmployeeDetails: PropTypes.func.isRequired,
  editEmployeeVisited: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  employeeProfileReady: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeTabsContainer);
