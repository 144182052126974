import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV = createRequestTypes(
  'hr/employer-reports/GET_EMPLOYER_PURCHASE_AND_CLAIM_HISTORY_CSV',
);

export const GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV = createRequestTypes(
  'hr/employer-reports/GET_EMPLOYER_PURCHASE_AND_CLAIM_SUMMARY_CSV',
);

export const GET_EMPLOYER_HR_DRIVERS = createRequestTypes(
  'hr/employer-reports/GET_EMPLOYER_HR_DRIVERS',
);

export const EMPLOYER_USAGE_REPORTS_LIFECYCLE = createLifecycleTypes(
  'hr/employer-reports/EMPLOYER_USAGE_REPORTS_LIFECYCLE',
);

export const GENERATE_CSV_FORM_SUBMITTED =
  'hr/employer-reports/GENERATE_CSV_FORM_SUBMITTED';

export const EMPLOYER_REPORTS_LIFECYCLE = createLifecycleTypes(
  'hr/employer-reports/EMPLOYER_REPORTS_LIFECYCLE',
);

export const GET_GROUP = createRequestTypes('employer/GET_GROUP');
