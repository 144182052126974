import { has } from 'lodash';
import {
  GET_BENEFIT_PLAN_OPTIONS,
  VALIDATE_BENEFIT_PLAN_CONFIG,
} from '../enrollment-config.types';
import { BENEFIT_ID, SET_ID } from '../enrollment-config.constants';

export const initialState = {};

export const getWarningsByKey = (warnings, key) => {
  return warnings
    .filter(warning => has(warning, key))
    .reduce((acc, warning) => {
      const keyId = warning?.[key];

      return {
        ...acc,
        [keyId]: acc?.[keyId] ? [...acc[keyId], warning] : [warning],
      };
    }, {});
};

export const mapValidationWarnings = (planValidationWarnings = []) => {
  const planWarningsForBenefitAndSet =
    planValidationWarnings?.filter(
      warning => has(warning, BENEFIT_ID) || has(warning, SET_ID),
    ) ?? [];
  // get warnings for benefit level
  const benefitWarnings = getWarningsByKey(
    planWarningsForBenefitAndSet,
    BENEFIT_ID,
  );

  // get warnings for benefit set level
  const setWarnings = getWarningsByKey(planWarningsForBenefitAndSet, SET_ID);

  // get warnings for plan level
  const planWarnings =
    planValidationWarnings?.filter(
      warning => !planWarningsForBenefitAndSet.includes(warning),
    ) ?? [];

  return {
    benefitWarnings,
    setWarnings,
    planWarnings,
    flattened: planValidationWarnings ?? [],
  };
};

const applyGetBenefitPlanOptions = (state, action) => {
  const { plan_id: planId, validation_warnings: validationWarnings } =
    action?.payload?.info ?? {};
  return {
    ...state,
    [planId]: mapValidationWarnings(validationWarnings),
  };
};
const applyValidateBenefitPlanConfig = (state, action) => {
  // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
  const { planId } = action?.meta;
  const { validation_warnings: validationWarnings } =
    action?.payload?.info ?? {};
  return {
    ...state,
    [planId]: mapValidationWarnings(validationWarnings),
  };
};
// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const planOptionsValidationReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_BENEFIT_PLAN_OPTIONS.SUCCEEDED:
      return applyGetBenefitPlanOptions(state, action);
    case VALIDATE_BENEFIT_PLAN_CONFIG.SUCCEEDED:
      return applyValidateBenefitPlanConfig(state, action);
    default:
      return state;
  }
};
