import React, { useState, useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import { TreeNavigationPresenter } from './tree-navigation.view';
import { ROOT } from '../enrollment-config.constants';
import { selectPlanOptionsLoading } from '../enrollment-config.selectors';

const getNodeInTree = (tree, nodePath) =>
  nodePath === ROOT ? tree : get(tree, nodePath, {});

const getParentNodeInTree = (tree, node) => {
  const treePathParts = get(node, 'treePath', '').split('.');
  const parentPath = treePathParts
    .slice(0, treePathParts.length - 2) // remove the last two path parts and rebuild the path to get the parent node path
    .join('.');

  // If there is a parent node, return it. `root`, for example, will not have a parent.
  return parentPath.length > 0 ? getNodeInTree(tree, parentPath) : null;
};

export const useExpandedNodes = () => {
  const [expandedNodePaths, setExpandedNodePaths] = useState([]);

  const addExpandedNode = (treePaths, treePath) =>
    treePaths.indexOf(treePath) > -1 ? treePaths : [...treePaths, treePath];

  const updateExpandedNodePaths = (isExpanded, nodeTreePaths = []) => {
    const newExpandedNodePaths = nodeTreePaths.reduce((newPaths, path) => {
      return isExpanded
        ? addExpandedNode(newPaths, path)
        : newPaths.filter(treePath => treePath !== path);
    }, expandedNodePaths);

    setExpandedNodePaths(newExpandedNodePaths);
  };

  return {
    expandedNodePaths,
    updateExpandedNodePaths,
  };
};

export const useTreeData = ({
  tree,
  expandedNodePaths,
  updateExpandedNodePaths,
  activeNode,
  setActiveNode,
  planOptionsLoading,
}) => {
  const [treeData, setTreeData] = useState(tree);
  const [previousActiveParentNode, setPreviousActiveParentNode] =
    useState(null);
  // update treeState when there's changes for data
  useEffect(() => {
    if (activeNode) {
      // get tree node that was previously active. This can be different than activeNode when activeNode
      // is not actually in the tree, i.e. is a "new entity" form
      const activeNodeInTree = getNodeInTree(tree, activeNode.treePath);
      if (!isEmpty(activeNodeInTree) && !activeNodeInTree.active) {
        setActiveNode(activeNodeInTree); // update activeNode with the latest node data
      }
      /*
        We need to handle a special case where the activeNode is being set by
        an item in CollectionForm and manually expand either the parent + child or
        just child depending on the state of the parent node
      */
      const activeNodeParent = getParentNodeInTree(tree, activeNode); // should always be defined
      const activeNodeInTreeParent = getParentNodeInTree(
        tree,
        activeNodeInTree,
      ); // only defined if activeNode is a node in the tree
      if (activeNode.fromCollectionView) {
        if (!activeNodeInTreeParent) {
          /*
            When triggered from the 'new entity' in collection form, activeNodeInTree has no parent since it's not
            in the tree. In all other cases, activeNodeInTreeParent will be defined, so this is our test for deciding
            if the action comes from a 'new entity' form or a click on a collection form item
          */
          activeNodeParent.active = true;
          setPreviousActiveParentNode(activeNodeParent); // save the active parent cause we need to manually unset its active state later
        } else if (activeNodeParent && !activeNodeParent.toggled) {
          // collection form item and parent node is not expanded
          activeNodeParent.toggled = true;
          activeNodeInTree.toggled = true;
          updateExpandedNodePaths(true, [
            activeNodeParent.treePath,
            activeNodeInTree.treePath,
          ]);
        } else {
          // collection form item and parent node is already expanded
          activeNodeInTree.toggled = true;
          updateExpandedNodePaths(true, [activeNodeInTree.treePath]);
        }
      } else if (
        previousActiveParentNode &&
        previousActiveParentNode.treePath !== activeNodeInTree.treePath
      ) {
        // we navigated away from 'new' collection form via save or tree
        const previousActiveParentNodeInTree = getNodeInTree(
          tree,
          previousActiveParentNode.treePath,
        );
        if (previousActiveParentNodeInTree) {
          previousActiveParentNodeInTree.active = false;
        }
      } else {
        // normal navigation via tree, reset the previous active node cause we don't need it. This is to mitigate a bug
        // where if clicking on a tree node from the 'new entity' form, the old active node would not be deactivated
        setPreviousActiveParentNode(null);
      }

      setTreeData(tree);
    }
  }, [
    tree,
    setTreeData,
    activeNode,
    setActiveNode,
    updateExpandedNodePaths,
    previousActiveParentNode,
  ]);

  useEffect(() => {
    /*
      for each node in expandedNodePaths, find the node in the tree
      according to the path and set node.toggled = true
    */
    expandedNodePaths.forEach(activeNodeTreePath => {
      // find the node in the tree
      const node = getNodeInTree(tree, activeNodeTreePath);
      /*
        toggle the node and set it to active if it's previously active
        i.e the node is the last active cursor
      */
      node.toggled = true;
      // reset loading state of the node
      if (node.loading) {
        node.loading = false;
      }
    });
    setTreeData(tree);
  }, [tree, expandedNodePaths, activeNode, planOptionsLoading, setTreeData]);

  return {
    treeData,
    setTreeData,
  };
};

export const useTreeNavigationHooks = ({
  treeData: tree,
  onToggleTreeNode,
  planOptionsLoading,
  activeNode,
  setActiveNode,
}) => {
  const { updateExpandedNodePaths, expandedNodePaths } = useExpandedNodes();

  const { treeData, setTreeData } = useTreeData({
    tree,
    expandedNodePaths,
    updateExpandedNodePaths,
    activeNode,
    setActiveNode,
    planOptionsLoading,
  });

  const onToggle = (node, isExpanded) => {
    const handleToggle = ({ node: treeNode }) => {
      if (treeNode.children) {
        // eslint-disable-next-line no-param-reassign
        treeNode.toggled = isExpanded;
      }
      // update local states
      updateExpandedNodePaths(isExpanded, [treeNode.treePath]); // add/remove node from expanded list
      setActiveNode(treeNode);
    };

    // parent container callback function
    if (onToggleTreeNode) {
      onToggleTreeNode({ node, handleToggle });
    } else {
      handleToggle({ node });
    }
  };

  return {
    treeData,
    expandedNodePaths,
    onToggle,
    setTreeData,
  };
};

export const TreeNavigationContainer = props => {
  const { treeData, onToggle } = useTreeNavigationHooks({ ...props });
  const allProps = {
    ...props,
    treeData,
    onToggle,
  };
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <TreeNavigationPresenter {...allProps} />;
};

const mapStateToProps = state => ({
  planOptionsLoading: selectPlanOptionsLoading(state),
});

export const TreeNavigation = connect(mapStateToProps)(TreeNavigationContainer);
