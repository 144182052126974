export const MISSING_DATE_ERROR = 'MISSING_DATE_ERROR';
export const FUTURE_DATE_ERROR = 'FUTURE_DATE_ERROR';
export const REPORT_NO_DATA_ERROR = 'REPORT_NO_DATA_ERROR';
export const REPORT_GENERIC_ERROR = 'REPORT_GENERIC_ERROR';
export const REPORT_NO_DATA_ERROR_REASON = 'report contains no data';

/* Analytics Event Constants */
export const ANALYTICS_EVENT_NAME_ADMIN = 'ga_admin_event';
export const ANALYTICS_EVENT_CATEGORY_REPORTS = 'reports';
export const ANALYTICS_EVENT_REQUEST_REPORT = 'requested_report';
export const ANALYTICS_EVENT_REPORT_ERROR = 'error_on_report_generation';
export const ANALYTICS_EVENT_REPORT_SUCCESS = 'report_generation_succeeded';
export const ANALYTICS_EVENT_LABEL_PAYROLL = 'payroll';
export const ANALYTICS_EVENT_LABEL_PREMIUMS = 'premiums';
export const ANALYTICS_EVENT_LABEL_CARRIER_AUDITS = 'carrier audits';
