import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { BILLING_PREFERENCE_CONSOLIDATED_MESSAGE } from '../../constants';

const SelectBillingDivision = ({
  name,
  label,
  isConsolidated,
  fieldValue,
  options,
  setFieldTouched,
  setValue,
}) => (
  <Form.Select
    label={label}
    name={name}
    value={fieldValue}
    placeholder={isConsolidated ? BILLING_PREFERENCE_CONSOLIDATED_MESSAGE : ''}
    options={options}
    onBlur={setFieldTouched}
    onChange={(event, data) => setValue(name, data.value)}
    disabled={isConsolidated}
  />
);

SelectBillingDivision.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isConsolidated: PropTypes.bool.isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

SelectBillingDivision.defaultProps = {
  fieldValue: '',
};

export default SelectBillingDivision;
