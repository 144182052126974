import { get } from 'lodash';
import {
  CLEAR_INVOICING_DIAGNOSTICS,
  GET_INVOICING_DIAGNOSTICS,
  GET_NETSUITE_INVOICE_REPORT,
} from './finance-invoicing.types';

export const initialState = {
  invoicingDiagnosticsLoading: false,
  invoicingDiagnosticsContentId: null,
  clearInvoicingDiagnosticsLoading: false,
  netSuiteInvoiceReportLoading: false,
  netSuiteInvoiceReportContentId: null,
};

const setInvoicingDiagnosticsContentId = (state, action) => {
  return {
    ...state,
    invoicingDiagnosticsLoading: false,
    invoicingDiagnosticsContentId: get(action, 'payload.content_id', null),
  };
};

const setNetSuiteInvoiceReportContentId = (state, { payload = null }) => {
  return {
    ...state,
    netSuiteInvoiceReportLoading: false,
    netSuiteInvoiceReportContentId: payload,
  };
};

export const financeToolsInvoicingUIReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    // Get invoicing diagnostics
    case GET_INVOICING_DIAGNOSTICS.STARTED:
      return {
        ...state,
        invoicingDiagnosticsLoading: true,
        invoicingDiagnosticsContentId: null,
      };
    case GET_INVOICING_DIAGNOSTICS.SUCCEEDED:
      return setInvoicingDiagnosticsContentId(state, action);
    case GET_INVOICING_DIAGNOSTICS.ERRORED:
      return { ...state, invoicingDiagnosticsLoading: false };
    // Clear invoicing diagnostics
    case CLEAR_INVOICING_DIAGNOSTICS.STARTED:
      return { ...state, clearInvoicingDiagnosticsLoading: true };
    case CLEAR_INVOICING_DIAGNOSTICS.SUCCEEDED:
      return { ...state, clearInvoicingDiagnosticsLoading: false };
    case CLEAR_INVOICING_DIAGNOSTICS.ERRORED:
      return { ...state, clearInvoicingDiagnosticsLoading: false };
    // Get NetSuite invoice report
    case GET_NETSUITE_INVOICE_REPORT.STARTED:
      return {
        ...state,
        netSuiteInvoiceReportLoading: true,
        netSuiteInvoiceReportContentId: null,
      };
    case GET_NETSUITE_INVOICE_REPORT.SUCCEEDED:
      return setNetSuiteInvoiceReportContentId(state, action);
    case GET_NETSUITE_INVOICE_REPORT.ERRORED:
      return { ...state, netSuiteInvoiceReportLoading: false };
    default:
      return state;
  }
};
