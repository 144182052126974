import { connect } from 'react-redux';
import { clearEmployerDetails, visitEmployer } from './actions';
import {
  selectIsReady,
  selectEmployerName,
  selectEmployerStatus,
} from './selectors';
import { EmployerDetails } from './employer-details.view';

const mapStateToProps = state => ({
  ready: selectIsReady(state),
  employerName: selectEmployerName(state),
  employerStatus: selectEmployerStatus(state),
});

export * from './saga';
export * from './reducer';

export default connect(mapStateToProps, {
  clearEmployerDetails,
  visitEmployer,
})(EmployerDetails);
