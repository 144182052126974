import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@leagueplatform/genesis-commons';
import { Label } from '@leagueplatform/ui-kit';
import { ToggleField } from './toggle-field.view';
import { SelectField } from './select-field.view';
import { InputField } from './input-field.view';
import { DateField } from './date-field.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { ObjectField } from './object-field.view';
import { MultiSelectField } from './multi-select-field.view';
import { MoneyField } from './money-field.view';
import { MONEY_REF, SCHEMA_ENTITY_TYPES } from '../json-schema-form.constants';

export const renderObjectField = wrapperProps => {
  switch (wrapperProps.propSchema.$ref) {
    case MONEY_REF:
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      return <MoneyField {...wrapperProps} />;
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      return <ObjectField {...wrapperProps} />;
  }
};

function getAutoComplete(name) {
  switch (name) {
    case 'first_name':
      return 'given-name';
    case 'last_name':
      return 'family-name';
    case 'middle_name':
      return 'additional-name';
    default:
      return 'on';
  }
}

export const RenderFieldByPropSchema = ({ name, propSchema, ...props }) => {
  const autoComplete = getAutoComplete(name);
  const wrapperProps = {
    name,
    propSchema,
    autoComplete,
    ...props,
  };

  const OverrideComponent = propSchema.fieldComponent;
  if (OverrideComponent) {
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    return <OverrideComponent {...wrapperProps} />;
  }

  switch (propSchema.type) {
    case SCHEMA_ENTITY_TYPES.STRING:
      if (propSchema.enum || propSchema.oneOf)
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        return <SelectField {...wrapperProps} />;
      if (propSchema.format === 'date-time')
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        return <DateField {...wrapperProps} />;
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      return <InputField {...wrapperProps} type="text" />;
    case SCHEMA_ENTITY_TYPES.NUMBER:
    case SCHEMA_ENTITY_TYPES.INTEGER:
    case SCHEMA_ENTITY_TYPES.FLOAT:
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      return <InputField {...wrapperProps} type="number" />;
    case SCHEMA_ENTITY_TYPES.BOOLEAN:
      if (propSchema.oneOf) {
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        return <SelectField {...wrapperProps} />;
      }
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <ToggleField {...wrapperProps}>
          <Label>{propSchema.title}</Label>
        </ToggleField>
      );
    case SCHEMA_ENTITY_TYPES.OBJECT:
      return renderObjectField(wrapperProps);
    case SCHEMA_ENTITY_TYPES.ARRAY:
      if (propSchema?.items?.type === SCHEMA_ENTITY_TYPES.STRING) {
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        return <MultiSelectField {...wrapperProps} />;
      }
      return null;
    default:
      return (
        <ErrorMessage>
          Unsupported Field Type: {`name: ${name}, type: ${propSchema.type}`}
        </ErrorMessage>
      );
  }
};

RenderFieldByPropSchema.propTypes = {
  name: PropTypes.string.isRequired,
  propSchema: PropTypes.shape({
    fieldComponent: PropTypes.elementType,
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    enum: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    oneOf: PropTypes.array,
    name: PropTypes.string,
    format: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
  renderObjectField: PropTypes.func,
};

RenderFieldByPropSchema.defaultProps = {
  renderObjectField: undefined,
};
