import { useCallback } from 'react';

export const useGetUserBenefitForm = ({
  loadUserBenefitForm,
  form_id: formId,
  userId,
  groupId,
  planId,
}) => {
  return useCallback(
    () => loadUserBenefitForm({ userId, formId, groupId, planId }),
    [loadUserBenefitForm, formId, userId, groupId, planId],
  );
};
