import { combineReducers } from 'redux';
import { GET_ALLOCATION_DRY_RUN } from './allocation-tool.actions';
import { STATUSES } from './allocation-tool.constants';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const trace = (state = [], action) => {
  switch (action?.type) {
    case GET_ALLOCATION_DRY_RUN.SUCCEEDED:
      return action?.payload?.info?.dry_run_result?.trace;
    case GET_ALLOCATION_DRY_RUN.STARTED:
      return [];
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const map = (state = {}, action) => {
  switch (action?.type) {
    case GET_ALLOCATION_DRY_RUN.SUCCEEDED:
      return action?.payload?.info?.dry_run_result?.plan_allocation_dry_run_map;
    case GET_ALLOCATION_DRY_RUN.STARTED:
      return {};
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const totalAllocatedUserBenefits = (state = [], action) => {
  switch (action?.type) {
    case GET_ALLOCATION_DRY_RUN.SUCCEEDED:
      return action?.payload?.info?.dry_run_result
        ?.total_allocated_user_benefits;
    case GET_ALLOCATION_DRY_RUN.STARTED:
      return [];
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const status = (state = STATUSES.READY, action) => {
  switch (action?.type) {
    case GET_ALLOCATION_DRY_RUN.SUCCEEDED:
    case GET_ALLOCATION_DRY_RUN.CANCELLED:
      return STATUSES.READY;
    case GET_ALLOCATION_DRY_RUN.STARTED:
      return STATUSES.ACTIVE;
    case GET_ALLOCATION_DRY_RUN.ERRORED:
      return STATUSES.ERROR;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const employerTzName = (state = null, action) => {
  switch (action?.type) {
    case GET_ALLOCATION_DRY_RUN.SUCCEEDED:
      return action.meta.employerTzName;
    case GET_ALLOCATION_DRY_RUN.STARTED:
      return null;
    default:
      return state;
  }
};

export const allocationToolReducer = combineReducers({
  trace,
  map,
  totalAllocatedUserBenefits,
  status,
  employerTzName,
});
