import React, { useState, useEffect } from 'react';
import { Box, Text, colour } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { FormattedMessage } from 'react-intl';
import { HeadingFour } from '@leagueplatform/genesis-commons';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import PropTypes from 'prop-types';
import { MISSING_DATE_ERROR, FUTURE_DATE_ERROR } from '../../reports.constants';
import {
  EmployerReportsDayPicker,
  EmployerReportsLoaderButton,
  EmployerReportsInfoBox,
} from 'apps/employer-experience/pages/EmployerDetails/employer-reports/ui-kit';
import { NoteText } from 'apps/employer-experience/ui-kit/report-elements.view';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const StyledDayPicker = styled(EmployerReportsDayPicker)`
  margin: 0.5rem 0;
`;

const ErrorText = styled(Text)`
  color: ${colour('red')};
`;

const StyledButton = styled(EmployerReportsLoaderButton)`
  margin-top: 1rem;
`;

const SpendingAccountBalanceReport = ({
  spendingAccountBalanceReportError: reportError,
  spendingAccountBalanceReportLoading: reportLoading,
  spendingAccountBalanceReportReady: reportReady,
  getSpendingAccountBalanceReport: getReport,
  email,
  visit,
  groupId,
}) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();

  useEffect(() => {
    visit();
  }, [visit]);
  const [reportDate, setDate] = useState(null);
  const [dateErr, setDateErr] = useState('');
  const handleDayChange = inputDate => {
    // if date cannot be parsed, set to empty
    try {
      const now = moment();
      let requestDate = inputDate.endOf('day');
      // if same day, then set to current time
      if (now.isSame(requestDate, 'day')) {
        requestDate = now;
      }
      // cannot select future date
      if (requestDate.isAfter(now)) {
        setDateErr(FUTURE_DATE_ERROR);
        return;
      }
      setDateErr('');
      setDate(requestDate);
    } catch (err) {
      setDate(null);
    }
  };

  const handleSubmit = () => {
    if (dateErr) return;
    if (!reportDate) {
      setDateErr(MISSING_DATE_ERROR);
      return;
    }
    getReport({
      reportDate: reportDate.toISOString(),
      groupId,
      isInHRAdminPortal,
    });
  };
  return (
    <Form>
      <Form.Group>
        <Form.Field>
          <Flex align="flex-start">
            <HeadingFour>
              <FormattedMessage id="SPENDING_ACCOUNT_BALANCE_REPORT" />
            </HeadingFour>
          </Flex>
          <Text>
            <FormattedMessage id="SPENDING_ACCOUNT_BALANCE_REPORT_COPY" />
          </Text>
          <Box my={1}>
            <strong>
              <FormattedMessage id="DATE" />
            </strong>
            <StyledDayPicker
              error={!!dateErr}
              handleDayChange={handleDayChange}
            />
            {dateErr && (
              <ErrorText>
                {dateErr === FUTURE_DATE_ERROR ? (
                  <FormattedMessage id="REPORT_FUTURE_DATE_ERROR_COPY" />
                ) : (
                  <FormattedMessage id="REPORT_DATE_REQUIRED_ERROR_COPY" />
                )}
              </ErrorText>
            )}
          </Box>
          <NoteText>
            <FormattedMessage id="NOTE" />:
          </NoteText>
          <FormattedMessage id="REPORTS_LOADING_NOTE_COPY" />
          <StyledButton loading={reportLoading} primary onClick={handleSubmit}>
            <FormattedMessage id="REQUEST_REPORT" />
          </StyledButton>
          <EmployerReportsInfoBox
            loading={reportLoading}
            ready={reportReady}
            error={reportError}
            email={email}
            hasDateInput
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

SpendingAccountBalanceReport.propTypes = {
  spendingAccountBalanceReportReady: PropTypes.bool,
  spendingAccountBalanceReportError: PropTypes.string,
  spendingAccountBalanceReportLoading: PropTypes.bool,
  getSpendingAccountBalanceReport: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  visit: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

SpendingAccountBalanceReport.defaultProps = {
  spendingAccountBalanceReportReady: false,
  spendingAccountBalanceReportError: null,
  spendingAccountBalanceReportLoading: false,
};

export default SpendingAccountBalanceReport;
