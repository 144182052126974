import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  Flex,
  genesisStyled,
  HeadingFour,
  Image,
  BodyOneSecondary,
  Box,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import PIGGY_BANK_ICON from 'apps/public/assets/piggy-bank-icon.svg';
import { currencyPropType } from '@leagueplatform/web-common';
import {
  breakpoints,
  genesisFocus,
  Icon,
  ICONS,
  Responsive,
} from '@leagueplatform/ui-kit';

const transitionDuration = '500ms';

export const Chevron = genesisStyled(Icon)`
  fill: currentColor;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
  transition-duration: ${transitionDuration};
  transition-timing-function: ease;
`;

const COLLAPSIBLE_SECTION_ID = 'collapsible-section-employer-cost';
const COLLAPSIBLE_BUTTON_ID = 'collapsible-button-employer-cost';

export const EmployerCostBanner = ({
  totalFlex,
  flexDollarTermId,
  employerCostAmount,
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  const bannerContent = totalFlex
    ? formatMessage(
        { id: 'EMPLOYER_COST_BANNER_WITH_FLEX_DOLLAR' },
        {
          flexDollarTerm: formatMessage({ id: `${flexDollarTermId}` }),
          amount: formatMoney(employerCostAmount),
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          strong: text => <strong>{text}</strong>,
        },
      )
    : formatMessage(
        { id: 'EMPLOYER_COST_BANNER_NO_FLEX_DOLLAR' },
        {
          amount: formatMoney(employerCostAmount),
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          strong: text => <strong>{text}</strong>,
        },
      );

  return (
    <Flex
      borderWidth="thick"
      borderStyle="solid"
      padding="two"
      backgroundColor="surface.background.primary"
      borderColor="onSurface.border.subdued"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={{
        _: 0,
        laptop: 'medium',
      }}
      borderLeftWidth={{
        _: 'none',
        laptop: 'thick',
      }}
      borderRightWidth={{
        _: 'none',
        laptop: 'thick',
      }}
    >
      <Responsive breakpoint={breakpoints.tabletAndAbove}>
        <Flex flexDirection="column">
          <HeadingFour as="h2">
            {formatMessage({ id: 'EMPLOYER_COST_BANNER_HEADER' })}
          </HeadingFour>
          <BodyOneSecondary marginTop="half">{bannerContent}</BodyOneSecondary>
        </Flex>
        <Image src={PIGGY_BANK_ICON} alt="" size={56} marginLeft="one" />
      </Responsive>
      <Responsive breakpoint={breakpoints.belowTablet}>
        <Flex flexDirection="column">
          <Flex justifyContent="space-between">
            <HeadingFour as="h2">
              {formatMessage({ id: 'EMPLOYER_COST_BANNER_HEADER' })}
            </HeadingFour>
            <Image src={PIGGY_BANK_ICON} alt="" size={56} marginLeft="one" />
          </Flex>
          <Flex
            onClick={() => setIsExpanded(!isExpanded)}
            flexDirection="column"
          >
            <Flex
              as="button"
              border="none"
              background="transparent"
              color="interactive.action.primary"
              alignItems="center"
              focusStyle={genesisFocus}
              width="fit-content"
              aria-expanded={isExpanded}
              aria-controls={COLLAPSIBLE_SECTION_ID}
              id={COLLAPSIBLE_BUTTON_ID}
            >
              <BodyOne
                color="interactive.action.primary"
                fontWeight="medium"
                marginRight="quarter"
              >
                {formatMessage({ id: 'LEARN_MORE' })}
              </BodyOne>
              <Chevron
                icon={ICONS.CHEVRON_RIGHT}
                isExpanded={isExpanded}
                size={20}
                aria-hidden
              />
            </Flex>

            {isExpanded && (
              <Box
                as="section"
                id={COLLAPSIBLE_SECTION_ID}
                aria-labelledby={COLLAPSIBLE_BUTTON_ID}
              >
                <BodyOneSecondary marginTop="threeQuarters">
                  {bannerContent}
                </BodyOneSecondary>
              </Box>
            )}
          </Flex>
        </Flex>
      </Responsive>
    </Flex>
  );
};

EmployerCostBanner.propTypes = {
  totalFlex: currencyPropType,
  flexDollarTermId: PropTypes.string,
  employerCostAmount: currencyPropType.isRequired,
};

EmployerCostBanner.defaultProps = {
  totalFlex: null,
  flexDollarTermId: 'FLEXIBLE_DOLLARS',
};
