export const SETTLED = 'settled';
export const PENDING_TRANSFER = 'pending_transfer';
export const VOIDED_BY_LEAGUE = 'voided_by_league';
export const CLOSED = 'closed';

export const PAYMENT_STATUSES = [
  SETTLED,
  PENDING_TRANSFER,
  VOIDED_BY_LEAGUE,
  CLOSED,
];
