import React from 'react';
import { Heading, Text } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import { Tab, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { useEmployerReportsTabs } from './use-employer-reports.hook';

const SegmentWrapper = styled(Segment)`
  padding-top: 0 !important;
  .ui.basic.segment.active.tab {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

const TabWrapper = styled(Tab)`
  .ui.pointing.secondary.menu {
    padding-top: 0;
    padding-left: 14px;
    background-color: #f7f7f7;

    display: flex;
    flex-wrap: wrap;
    .item {
      margin-top: 14px;
    }
  }
`;

export const EmployerReports = props => {
  const tabs = useEmployerReportsTabs(props);

  return (
    <div>
      <div>
        <Heading level={3}>
          <FormattedMessage id="REPORTS" />
        </Heading>
        <Text>
          <FormattedMessage id="DOWNLOAD_THE_LATEST" />
        </Text>
      </div>
      <SegmentWrapper>
        <TabWrapper menu={{ secondary: true, pointing: true }} panes={tabs} />
      </SegmentWrapper>
    </div>
  );
};
