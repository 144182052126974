import React from 'react';
import PropTypes from 'prop-types';
import { Item, Card, Form } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ValidFormField } from 'common/adaptive-forms';
import { AllocationSummary } from './allocation-summary';
import { Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import {
  FundsAllocationDisplay,
  AmountBox,
  DependentClassDescription,
  DescriptionContainer,
  CurrencyLabelContainer,
  CurrencySignLabelContainer,
  StyledInput,
} from './dependent-ruleset.ui';

const All = ({
  all,
  currency,
  setAmount,
  ruleSet,
  handleBlur,
  errors,
  touched,
  readOnly,
  allocationFrequency,
  benefitStartDate,
  benefitEndDate,
}) => {
  return (
    <FundsAllocationDisplay className="container--benefit-dependent-rules">
      <Card fluid>
        <div className="card-header">
          <AllocationSummary
            allocationFrequency={allocationFrequency}
            benefitStartDate={benefitStartDate}
            benefitEndDate={benefitEndDate}
          />
        </div>
        <Card.Content style={{ marginLeft: 0 }}>
          <Item>
            <Flex p={3} pb={0}>
              <Box flex={3}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- FIXME: automatically added for existing issue */}
                <label htmlFor="single">
                  <strong>
                    <FormattedMessage id="ALLOCATION_CLASS_LABEL" />
                  </strong>
                </label>
              </Box>
              <Box flex={2}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- FIXME: automatically added for existing issue */}
                <label htmlFor="single">
                  <strong>
                    <FormattedMessage id="FUNDS_PER_POLICY_LABEL" />
                  </strong>
                </label>
              </Box>
            </Flex>
            <DescriptionContainer>
              <DependentClassDescription>
                <FormattedMessage id="ALL_EMPLOYEES_CLASS_DESCRIPTION" />
              </DependentClassDescription>
              <AmountBox>
                <ValidFormField errors={errors.single} touched={touched.single}>
                  <Form.Group>
                    <CurrencySignLabelContainer basic>
                      $
                    </CurrencySignLabelContainer>
                    <StyledInput
                      name="all"
                      type="number"
                      step="0.01"
                      value={all}
                      onChange={(event, { name, value }) => {
                        setAmount({ name, value, ruleSet });
                      }}
                      onBlur={handleBlur}
                      disabled={readOnly}
                    />
                    <CurrencyLabelContainer basic>
                      {currency}
                    </CurrencyLabelContainer>
                  </Form.Group>
                </ValidFormField>
              </AmountBox>
            </DescriptionContainer>
          </Item>
        </Card.Content>
      </Card>
    </FundsAllocationDisplay>
  );
};

All.propTypes = {
  all: PropTypes.number,
  currency: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  ruleSet: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  allocationFrequency: PropTypes.string,
  benefitStartDate: PropTypes.string,
  benefitEndDate: PropTypes.string,
};

All.defaultProps = {
  all: 0,
  errors: {},
  touched: {},
  allocationFrequency: null,
  benefitStartDate: null,
  benefitEndDate: null,
};

export default injectIntl(All);
