import { createRequestTypes } from 'common/websocket-redux';

export const GET_USERS_IN_GROUP = createRequestTypes(
  'service/GET_USERS_IN_GROUP',
);

export const SEARCH_PARAMS_CHANGED = 'employees/SEARCH_PARAMS_CHANGED';
export const NEXT_SEARCH_RESULTS_PAGE_REQUESTED =
  'employees/NEXT_SEARCH_RESULTS_PAGE_REQUESTED';

export const TERMINATE_EMPLOYEES = 'employees/TERMINATE_EMPLOYEES';
export const SUSPEND_EMPLOYEES = 'employees/SUSPEND_EMPLOYEES';
export const REINSTATE_EMPLOYEES = 'employees/REINSTATE_EMPLOYEES';
export const GET_TERMINATION_CODES = 'employees/GET_TERMINATION_CODES';
export const UNSCHEDULE_EMPLOYEES_SUSPENSION =
  'employees/UNSCHEDULE_EMPLOYEES_SUSPENSION';
export const UNSCHEDULE_EMPLOYEES_TERMINATION =
  'employees/UNSCHEDULE_EMPLOYEES_TERMINATION';
export const UNSCHEDULE_EMPLOYEES_REINSTATEMENT =
  'employees/UNSCHEDULE_EMPLOYEES_REINSTATEMENT';

export const DO_TERMINATE_EMPLOYEES = createRequestTypes(
  'service/DO_TERMINATE_EMPLOYEES',
);
export const DO_SUSPEND_EMPLOYEES = createRequestTypes(
  'service/DO_SUSPEND_EMPLOYEES',
);
export const DO_REINSTATE_EMPLOYEES = createRequestTypes(
  'service/DO_REINSTATE_EMPLOYEES',
);

export const DO_GET_TERMINATION_CODES = createRequestTypes(
  'service/GET_TERMINATION_CODES',
);

export const DO_UNSCHEDULE_EMPLOYEES_SUSPENSION = createRequestTypes(
  'service/DO_UNSCHEDULE_EMPLOYEES_SUSPENSION',
);
export const DO_UNSCHEDULE_EMPLOYEES_TERMINATION = createRequestTypes(
  'service/DO_UNSCHEDULE_EMPLOYEES_TERMINATION',
);
export const DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT = createRequestTypes(
  'service/DO_UNSCHEDULE_EMPLOYEES_REINSTATEMENT',
);

export const RESET_EMPLOYEES_STATE = 'employees/RESET_EMPLOYEES_STATE';
export const EMPLOYEES_VISITED = 'employees/EMPLOYEES_VISITED';

export const SET_USER_ROLE_IN_GROUP = 'employees/SET_USER_ROLE_IN_GROUP';
export const DO_SET_USER_ROLE_IN_GROUP = createRequestTypes(
  'service/DO_SET_USER_ROLE_IN_GROUP',
);
