import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  HeadingFour,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import {
  breakpoints,
  colour,
  spaceInPx,
  Icon,
  ICONS,
  Divider,
  Responsive,
} from '@leagueplatform/ui-kit';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { defaultFlexDollarTermId } from 'common/constants';
import { useFormattedInsuredCoverageSummaryMessages } from '../../use-formatted-coverage-summary-messages.hook';
import { InsuredBenefitsSummarySelection } from './insured-benefits-summary-selection.view';
import { BenefitIcon } from 'common/benefits/benefit-icon.view';
import { BenefitCost } from './selection-wrapper.view';

const BenefitSelectionCost = props => {
  const { formattedCost, formattedPeriodType } =
    useFormattedInsuredCoverageSummaryMessages(props);
  return (
    <BenefitCost
      formattedCost={formattedCost}
      formattedPeriodType={formattedPeriodType}
    />
  );
};

export const Chevron = styled(Icon).attrs({
  icon: ICONS.CHEVRON_RIGHT,
})`
  display: flex;
  margin-left: ${spaceInPx(1)};
  opacity: 0.7;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'};
  transition-duration: 0.5s;
  transition-timing-function: ease;
  color: ${colour('greyLight')};
`;

const useStepSelections = (selections = [], benefitSets = []) => {
  const eligibleBenefitSelections = selections.filter(selection =>
    benefitSets.some(set => set.id === selection.setId && !set.ineligible),
  );
  return {
    singleSelection:
      eligibleBenefitSelections?.length === 1
        ? eligibleBenefitSelections?.[0]
        : null,
    multipleBenefits: eligibleBenefitSelections?.length > 1,
    eligibleBenefitSelections,
  };
};

export const InsuredBenefitsSummaryStep = ({
  name,
  translatedName,
  icon,
  selections,
  showCostsPerPayPeriod,
  flexDollarTermId,
  shortName,
  id,
  employerPaysAllCosts,
  benefitSets,
}) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  const { singleSelection, multipleBenefits, eligibleBenefitSelections } =
    useStepSelections(selections, benefitSets);
  const showCost = Boolean(singleSelection && !employerPaysAllCosts);

  const selectionProps = {
    showCostsPerPayPeriod,
    flexDollarTermId,
  };

  const formattedName =
    (shortName || name) && formatMessage({ id: shortName || name });

  const benefitName = translatedName || formattedName || '';

  return (
    <Flex
      flexDirection="column"
      flex="1 1 auto"
      marginBottom={multipleBenefits ? 'none' : 'threeQuarters'}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsExpanded(!isExpanded)}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...multipleBenefits}
      >
        <Flex
          alignItems="center"
          marginBottom={multipleBenefits ? 'threeQuarters' : 'none'}
        >
          <BenefitIcon benefitType={id} src={icon} />
          <HeadingFour as="h3">{benefitName}</HeadingFour>
          {multipleBenefits && (
            <BodyTwoSecondary marginLeft="half">
              {formatMessage(
                { id: 'NUMBER_OF_BENEFITS' },
                { number: eligibleBenefitSelections?.length },
              )}
            </BodyTwoSecondary>
          )}
        </Flex>
        <Responsive breakpoint={breakpoints.tabletAndAbove}>
          {showCost && (
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            <BenefitSelectionCost {...singleSelection} {...selectionProps} />
          )}
        </Responsive>
      </Flex>
      <Box
        marginLeft={['none', 'none', 'four']}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...multipleBenefits}
      >
        {/* ONE BENEFIT */}
        {singleSelection && (
          <InsuredBenefitsSummarySelection
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...singleSelection}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...selectionProps}
          />
        )}

        {/* MULTIPLE BENEFITS */}
        {multipleBenefits && (
          <ul>
            {eligibleBenefitSelections.map((selection, idx) => {
              const { title } = benefitSets.find(
                set => set.id === selection.setId,
              );
              return (
                <li key={selection.id}>
                  <InsuredBenefitsSummarySelection
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...selection}
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...selectionProps}
                    title={title}
                    employerPaysAllCosts={employerPaysAllCosts}
                    showBenefitName
                    multipleBenefits
                  />
                  {idx < eligibleBenefitSelections.length - 1 && (
                    <Divider my={20} bottomBorder />
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </Box>
    </Flex>
  );
};

InsuredBenefitsSummaryStep.propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  benefitSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string,
  showCostsPerPayPeriod: PropTypes.bool.isRequired,
  flexDollarTermId: PropTypes.string,
  employerPaysAllCosts: PropTypes.bool,
  translatedName: PropTypes.string,
  icon: PropTypes.string,
};

InsuredBenefitsSummaryStep.defaultProps = {
  employerPaysAllCosts: false,
  flexDollarTermId: defaultFlexDollarTermId,
  shortName: null,
  translatedName: null,
  icon: null,
  benefitSets: [],
};
