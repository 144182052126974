import {
  Box,
  genesisStyled,
  css,
  Image,
  VisuallyHidden,
} from '@leagueplatform/genesis-commons';
import React from 'react';
import PropTypes from 'prop-types';
import { pulse } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import MultipleDocumentsIcon from './multiple-documents-icon.png';
import PDFIcon from './pdf-icon.svg';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { theme } from 'theme';

const Thumbnail = genesisStyled(Box)`
  ${({ isImageContentType, imageUrl }) =>
    isImageContentType
      ? css`
          background: url(${imageUrl}) center/cover;
          animation: ${pulse};
        `
      : css`
          background: url(${PDFIcon}) no-repeat center/3.7rem
            ${theme.colors.surface.background.secondary};
        `}
`;

export const ClaimDocumentsThumbnail = ({
  contentId,
  contentType,
  hasMultipleDocuments,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box height={80} width={72} position="relative">
      {hasMultipleDocuments && (
        <Image
          alt={formatMessage({ id: 'DOCUMENT_MULTIPLE_HIDDEN' })}
          height={19}
          position="absolute"
          right={5}
          src={MultipleDocumentsIcon}
          top={5}
          width={19}
          zIndex={9999}
        />
      )}
      <Thumbnail
        height="100%"
        imageUrl={getContentUrl(contentId)}
        isImageContentType={contentType.includes('image')}
        width="100%"
      />
      {contentType.includes('pdf') && (
        <VisuallyHidden
          role="img"
          aria-label={formatMessage({ id: 'DOCUMENT_THUMBNAIL_PDF_HIDDEN' })}
        />
      )}
    </Box>
  );
};

ClaimDocumentsThumbnail.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  hasMultipleDocuments: PropTypes.bool.isRequired,
};
