/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { useState } from 'react';

export const useFilteredPayrollSchedules = (payrollSchedules = []) => {
  const [planPeriodsFilter, setPlanPeriodsFilter] = useState([]);
  const filteredPayrollSchedules = payrollSchedules.filter(({ plan_periods }) =>
    plan_periods.some(
      period => !planPeriodsFilter.length || planPeriodsFilter.includes(period),
    ),
  );

  return {
    setPlanPeriodsFilter,
    filteredPayrollSchedules,
  };
};
