import { DangerButton } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import React from 'react';
import { EnrollmentStepItemType } from '../enrollment-design-options.types';
import EditingPanelHeaderTitle from './editing-panel-header-title.view';

interface EditingPanelGroupHeaderProps {
  activeEditingItem: EnrollmentStepItemType | null;
  clearActiveEditingId: () => void;
  deleteActiveStepOrGroup: () => void;
}

const EditingPanelGroupHeader = ({
  activeEditingItem,
  clearActiveEditingId,
  deleteActiveStepOrGroup,
}: EditingPanelGroupHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <EditingPanelHeaderTitle
        activeEditingItem={activeEditingItem}
        title="ENROLLMENT_DESIGN_GROUP"
        clearActiveEditingId={clearActiveEditingId}
      />

      <DangerButton onClick={deleteActiveStepOrGroup} marginBottom="one">
        {formatMessage({ id: 'DELETE_ENROLLMENT_GROUP' })}
      </DangerButton>
    </>
  );
};

export default EditingPanelGroupHeader;
