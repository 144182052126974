import React from 'react';
import PropTypes from 'prop-types';
import { Box, BodyTwoSecondary } from '@leagueplatform/genesis-commons';
import { TooltipHeader } from '@leagueplatform/ui-kit';

export const TemplateTooltip = ({ children }) => (
  <TooltipHeader
    tooltipLink={
      <BodyTwoSecondary
        marginLeft="quarter"
        borderRadius="circle"
        borderStyle="solid"
        borderWidth="thin"
        borderColor="onSurface.text.subdued"
        size={18}
        textAlign="center"
        lineHeight={1.2}
      >
        ?
      </BodyTwoSecondary>
    }
    tooltipText={
      <Box fontSize="body2" color="onSurface.text.primary" minWidth={200}>
        {children}
      </Box>
    }
    inline
    bottom
    center
    right={false}
    verticalPosition="bottom: 30px"
  />
);

TemplateTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};
