import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spaceInPx, Text, FontAwesomeIcon } from '@leagueplatform/ui-kit';

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledIcon = styled(props => <FontAwesomeIcon {...props} />)`
  margin-right: ${spaceInPx(1)};
`;

const StyledText = styled(Text)`
  &&& {
    margin-bottom: ${spaceInPx(2)};
  }
`;

export const ErrorMessage = ({ children }) => (
  <StyledText color="red" mt={-spaceInPx(1)}>
    <StyledIcon icon="exclamation-circle" />
    {children}
  </StyledText>
);

ErrorMessage.propTypes = {
  children: PropTypes.string.isRequired,
};
