import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Box, HeadingThree } from '@leagueplatform/genesis-commons';
import { Divider } from '@leagueplatform/ui-kit';
import { InsuredBenefitsSummaryStep } from './insured-benefits-summary-step.view';
import { defaultFlexDollarTermId } from 'common/constants';

export const InsuredBenefitsSummary = ({
  summarySteps,
  showCostsPerPayPeriod,
  flexDollarTermId,
  ...props
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <HeadingThree marginBottom="two" as="h2">
        {formatMessage({ id: 'INSURED_BENEFIT_DETAILS' })}
      </HeadingThree>
      <ul>
        {summarySteps &&
          summarySteps.map((step, idx) => {
            return (
              <li key={step.id}>
                <InsuredBenefitsSummaryStep
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...props}
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...step}
                  showCostsPerPayPeriod={showCostsPerPayPeriod}
                  flexDollarTermId={flexDollarTermId}
                />
                {idx < summarySteps.length - 1 && (
                  <Divider my={20} bottomBorder />
                )}
              </li>
            );
          })}
      </ul>
    </Box>
  );
};

InsuredBenefitsSummary.propTypes = {
  summarySteps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // TODO: change back to required in phase 4
    }),
  ).isRequired,
  showCostsPerPayPeriod: PropTypes.bool.isRequired,
  flexDollarTermId: PropTypes.string,
};

InsuredBenefitsSummary.defaultProps = {
  flexDollarTermId: defaultFlexDollarTermId,
};
