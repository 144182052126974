import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { KEY } from '@leagueplatform/web-common';
import { UsageReportsPage } from './usage-reports-page.container';
import PayrollReportsPage from './components/payroll-report';
import {
  BenefitsReportContainer,
  EmployeeDemographicsContainer,
  EnrolmentContainer,
  EnrolmentProgressContainer,
} from 'apps/employer-experience/pages/EmployerDetails/employer-reports';
import { BeneficiariesReport } from './beneficiaries-report/beneficiaries-report.container';
import { Dimmer, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import SpendingAccountBalanceReport from './components/spending-account-balance-report';
import { DependentsReportsContainer } from './components/dependents-report';
import { Box, Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';

export const useEmployerReportsPanes = ({
  groupId,
  canShowPayrollReports,
  canShowSpendingAccountBalanceReport,
  digitalBeneficiariesEnabled,
  featureFlags,
}) => {
  const { formatMessage } = useIntl();
  const panes = [];

  if (featureFlags?.enrolmentReportsTab !== false) {
    panes.push({
      menuItem: formatMessage({ id: 'ENROLLMENT' }),
      component: <EnrolmentContainer groupId={groupId} />,
    });
  }

  panes.push({
    menuItem: formatMessage({ id: 'ENROLLMENT_PROGRESS' }),
    component: <EnrolmentProgressContainer groupId={groupId} />,
  });

  if (featureFlags?.hideUsageReportTab !== true) {
    panes.push({
      menuItem: formatMessage({ id: 'USAGE' }),
      component: <UsageReportsPage groupId={groupId} />,
    });
  }

  if (canShowSpendingAccountBalanceReport) {
    panes.push({
      menuItem: formatMessage({ id: 'SPENDING_ACCOUNT_BALANCE' }),
      component: <SpendingAccountBalanceReport groupId={groupId} />,
    });
  }

  if (canShowPayrollReports) {
    panes.push({
      menuItem: formatMessage({ id: 'PAYROLL' }),
      component: <PayrollReportsPage groupId={groupId} />,
    });
  }

  if (digitalBeneficiariesEnabled) {
    panes.push({
      menuItem: formatMessage({ id: 'BENEFICIARIES' }),
      component: <BeneficiariesReport groupId={groupId} />,
    });
  }

  panes.push({
    menuItem: formatMessage({ id: 'EMPLOYEE_DEMOGRAPHICS' }),
    component: <EmployeeDemographicsContainer groupId={groupId} />,
  });

  panes.push({
    menuItem: formatMessage({ id: 'BENEFITS' }),
    component: <BenefitsReportContainer groupId={groupId} />,
  });

  panes.push({
    menuItem: formatMessage({ id: 'DEPENDENTS' }),
    component: <DependentsReportsContainer groupId={groupId} />,
  });

  return panes;
};

const Tab = genesisStyled(Box)(({ active, keyboardFocus }) =>
  css({
    cursor: 'pointer',
    '&:after': {
      display: active ? 'block' : 'none',
      content: '""',
      height: '4px',
      width: '100%',
      backgroundColor: 'primary.background.default',
      borderRadius: 'pill',
      position: 'absolute',
      bottom: -2,
      left: 0,
      transition: 'all 200ms linear',
    },
    '&:hover': {
      color: 'interactive.action.hovered',
      '&:after': {
        display: 'block',
        backgroundColor: !active && 'interactive.background.disabled',
      },
    },
    '&:focus': {
      outline: !keyboardFocus && 'none',
    },
  }),
);

export const EmployerReportsTabs = ({ visit, exit, ...props }) => {
  const panes = useEmployerReportsPanes(props);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [keyboardFocus, setKeyboardFocus] = useState(false);

  useEffect(() => {
    visit();
    return () => exit();
  }, [visit, exit]);

  const handleClick = (index, label) => {
    setActiveTabIndex(index);
    sendAnalyticsEvent({
      category: 'HR Admin Report Page Tabs',
      action: 'View Tab',
      label,
    });
  };

  return (
    <Dimmer.Dimmable>
      <Header as="h1">
        <FormattedMessage id="REPORTS" />
      </Header>

      {panes.length === 0 ? (
        <Header as="h5">
          <FormattedMessage id="NO_REPORTS_AVAILABLE" />
        </Header>
      ) : (
        <div>
          <Flex
            as="ul"
            height={65}
            borderBottomWidth="thin"
            borderBottomColor="onSurface.border.subdued"
            backgroundColor="surface.background.primary"
          >
            {panes.map((pane, i) => {
              const active = i === activeTabIndex;
              return (
                <Tab
                  tabIndex="0"
                  keyboardFocus={keyboardFocus}
                  as="li"
                  aria-selected={active}
                  panes={pane}
                  key={pane.menuItem}
                  onClick={() => {
                    handleClick(i, pane.menuItem);
                    setKeyboardFocus(false);
                  }}
                  onKeyUp={e => {
                    if (e.key === KEY.ENTER) handleClick(i, pane.menuItem);
                    setKeyboardFocus(true);
                  }}
                  active={active}
                  position="relative"
                  display="flex"
                  alignItems="center"
                  marginRight={20}
                  color={
                    active ? 'onSurface.text.primary' : 'onSurface.text.subdued'
                  }
                >
                  {pane.menuItem}
                </Tab>
              );
            })}
          </Flex>
          <Box marginTop="two">{panes[activeTabIndex].component}</Box>
        </div>
      )}
    </Dimmer.Dimmable>
  );
};

EmployerReportsTabs.propTypes = {
  visit: PropTypes.func,
  exit: PropTypes.func,
};

EmployerReportsTabs.defaultProps = {
  visit: () => {},
  exit: () => {},
};
