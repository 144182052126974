/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function benefitAllocationDryRun - this service is used to duplicate an employer benefit
 * plan and its plan options
 *
 * @arg {string} userId the user ID
 * @arg {string} groupId the user's target group ID
 * @arg {string} planId the user's target benefit plan ID
 * @arg {string} timestamp an optional timestamp to run the dry run at a specific datetime
 *
 * @returns {object}
 */

export const benefitAllocationDryRun = ({
  userId: user_id,
  groupId: group_id,
  planId: plan_id,
  timestamp,
} = {}) =>
  call(websocketFetch, 'benefit_allocation_dry_run', {
    user_id,
    group_id,
    plan_id,
    timestamp,
  });
