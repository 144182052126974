import React from 'react';
import PropTypes from 'prop-types';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompose';
import { Form } from 'semantic-ui-react';

const enhance = compose(
  onlyUpdateForKeys(['value', 'readOnly']),
  setDisplayName('CategorySelect'),
);

export const CategorySelect = enhance(
  ({ value, options, readOnly, openSelectCoverageCategory }) => (
    <Form.Select
      className="claim-settlement__category-dropdown"
      label="Categories"
      search
      selectOnNavigation={false}
      disabled={readOnly}
      value={value}
      options={options}
      onClick={openSelectCoverageCategory}
    />
  ),
);

CategorySelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  readOnly: PropTypes.bool,
  openSelectCoverageCategory: PropTypes.func.isRequired,
};

CategorySelect.defaultProps = {
  value: null,
  readOnly: false,
};
