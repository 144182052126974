/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { ErrorMessage, VisuallyHidden } from '@leagueplatform/genesis-commons';
import { ACTION_DOWNLOAD_DIGITAL_BENEFICIARY_FORM } from 'common/insurance-forms/insurance-forms.constants';
import { useGetDigitalBeneficiaryForm } from 'common/insurance-forms/use-get-digital-beneficiary-form.hook';
import { LoadingSecondaryButton } from 'common/insurance-forms/loading-secondary-button.view';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const DownloadDigitalBeneficiariesButton = ({
  contentId,
  error,
  isReady,
  sendAnalytics,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [hasCalledGetForm, setHasCalledGetForm] = useState(false);
  const [formContentId, setFormContentId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const showErrorMsg = error && isReady && !formContentId;

  const getDigitalBeneficiaryForm = useGetDigitalBeneficiaryForm(props);

  useEffect(() => {
    setIsLoading(!isReady);
    // We store the contentId locally so the user gets the same form during the same page view
    // However, if they leave the page and come back we want to generate a new form as the information may have changed
    setFormContentId(contentId);
  }, [isReady, contentId]);

  useEffect(() => {
    // We want to check if we've already called the get form function to avoid
    // opening the form on page load if the content ID is already in state
    if (formContentId && hasCalledGetForm) {
      openForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContentId]);

  const openForm = () => {
    if (sendAnalytics) sendAnalytics(ACTION_DOWNLOAD_DIGITAL_BENEFICIARY_FORM);
    window.open(
      getContentUrl(contentId),
      '_blank',
      'noopener=true,noreferrer=true',
    );
  };

  const downloadForm = () => {
    // Check for contentId, if no ID we need to call the API
    // We only want to call the API on click as the API generates a new form each time it is called
    if (formContentId && hasCalledGetForm) {
      openForm();
    } else {
      setHasCalledGetForm(true);
      getDigitalBeneficiaryForm();
    }
  };

  return (
    <>
      <LoadingSecondaryButton
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        marginTop="oneAndHalf"
        marginLeft={{ _: 'none', laptop: 'oneAndHalf' }}
        width={{
          _: 1,
          tablet: 'inherit',
        }}
        onClick={downloadForm}
        isLoading={isLoading}
      >
        {formatMessage({ id: 'VIEW_FORM' })}
        <VisuallyHidden>
          {formatMessage({ id: 'OPENS_IN_A_NEW_TAB' })}
        </VisuallyHidden>
      </LoadingSecondaryButton>

      {showErrorMsg && (
        <ErrorMessage
          marginTop="oneAndHalf"
          marginLeft={{ _: 'none', laptop: 'oneAndHalf' }}
          fontWeight="bold"
        >
          {formatMessage({
            id: 'INSURANCE_ERROR_PREPARING_FORMS',
          })}
        </ErrorMessage>
      )}
    </>
  );
};

DownloadDigitalBeneficiariesButton.propTypes = {
  contentId: PropTypes.string,
  error: PropTypes.bool,
  isReady: PropTypes.bool,
  sendAnalytics: PropTypes.func.isRequired,
};

DownloadDigitalBeneficiariesButton.defaultProps = {
  contentId: null,
  error: false,
  isReady: true,
};
