import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  ErrorMessage,
  css,
  genesisStyled,
} from '@leagueplatform/genesis-commons';

import ErrorIcon from 'common/ui-kit/assets/error-icon.svg';
import { Label, CheckBox } from '@leagueplatform/ui-kit';
import { MaskedNINInput } from 'apps/employer-experience/ui-kit';

import { useIntl } from '@leagueplatform/locales';

const NATIONAL_IDENTIFICATION_NUMBER = 'national_identification_number';
const OMIT_NATIONAL_IDENTIFICATION_NUMBER =
  'omit_national_identification_number';

const StyledLabel = genesisStyled(Label)(
  css({
    marginTop: 'two',
    marginBottom: 'half',
  }),
);

const CustomFieldWrapper = genesisStyled(Box)`
  display: block;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 50%;
    padding-left: 1.6rem;
  }

  > div {
    max-width: initial;
  }

  input {
    font-size: ${({ theme }) => theme.fontSizes.body1}px;
    height: 45px;
    text-align: left;
    padding: 0 ${({ theme }) => theme.space.one}px;
    background-position: right 1rem top 50%;
    background-size: 20px;
    background-repeat: no-repeat;
    border: 1px solid ${({ theme }) => theme.colors.onSurface.border.subdued};

    ${({ hasError }) =>
      hasError &&
      css({
        backgroundImage: `url(${ErrorIcon}) !important`,
        borderColor: 'critical.border.default',
      })}
    &[disabled] {
      color: ${({ theme }) => theme.colors.onSurface.text.subdued};
      background-color: ${({ theme }) =>
        theme.colors.surface.background.secondary};
    }
    &:focus {
      border-color: ${({ theme }) =>
        theme.colors.decorative.brand.primary.default};
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.onSurface.text.subdued};
    }
  }
`;

export function useOmitSSN(checked = false) {
  const [isOmitChecked, setIsOmitChecked] = useState(checked);

  return {
    toggleOmitChecked: () => {
      setIsOmitChecked(!isOmitChecked);
    },
    isOmitChecked,
  };
}

export const MaskedNINField = ({
  hasSubmitted,
  touched,
  error,
  label,
  name,
  disabled,
  dependentType,
  setFieldValue,
  omitCheckedValue,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { isOmitChecked, toggleOmitChecked } = useOmitSSN(omitCheckedValue);

  return (
    <CustomFieldWrapper hasError={(hasSubmitted || touched) && error}>
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        lineHeight="2rem"
      >
        <StyledLabel inline htmlFor={name}>
          {formatMessage({ id: label })}
        </StyledLabel>
      </Flex>
      <MaskedNINInput
        name={name}
        id={name}
        onChange={e => {
          setFieldValue(NATIONAL_IDENTIFICATION_NUMBER, e.target.value);
        }}
        disabled={isOmitChecked || disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...rest}
      />
      {error && (
        <ErrorMessage marginY="half" fontSize="heading3">
          {error}
        </ErrorMessage>
      )}
      <Flex paddingTop="half">
        <CheckBox
          name={OMIT_NATIONAL_IDENTIFICATION_NUMBER}
          id={OMIT_NATIONAL_IDENTIFICATION_NUMBER}
          checked={isOmitChecked}
          onChange={({ target: { checked } }) => {
            toggleOmitChecked();
            setFieldValue('omit_national_identification_number', checked);

            if (checked) {
              setFieldValue(NATIONAL_IDENTIFICATION_NUMBER, '');
            }
          }}
          disabled={disabled}
        />
        {formatMessage(
          { id: 'NO_DEPENDENT_SSN' },
          {
            dependentType:
              dependentType === 'child'
                ? formatMessage({ id: 'CHILD_LOWERCASE' })
                : formatMessage({ id: 'PARTNER_LOWERCASE' }),
          },
        )}
      </Flex>
    </CustomFieldWrapper>
  );
};

MaskedNINField.propTypes = {
  hasSubmitted: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  dependentType: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  omitCheckedValue: PropTypes.bool,
};

MaskedNINField.defaultProps = {
  hasSubmitted: false,
  touched: false,
  error: '',
  label: '',
  name: '',
  disabled: false,
  dependentType: '',
  omitCheckedValue: false,
};
