import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Get configuration for groups requiring their employers to verify their identity before enrollment begins.
 * The response includes explanatory text and form field schema for employee identity verification.
 *
 * @arg {string} urlSlug - A short identifier for an employer appropriate for use in a URL, i.e. "sdm" for Shoppers Drug Mart
 */
export const getGroupPreEnrollmentConfig = ({ urlSlug }) =>
  call(websocketFetch, 'get_group_pre_enrollment_config', {
    url_slug: urlSlug,
  });
