export default {
  lifeworksEAP: {
    name: 'lifeworksEAP',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Credentials',
        fields: [
          {
            name: 'login',
            span: 6,
          },
          {
            name: 'password',
            span: 6,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'type',
            span: 6,
          },
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'opt_out',
            span: 6,
          },

          {
            name: 'auto_renew',
            span: 6,
          },
          {
            name: 'prevent_default_allocation',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'id',
            span: 6,
          },
          {
            name: 'benefit_type',
            span: 6,
          },
          {
            name: 'product_type',
            span: 6,
          },
          {
            name: 'benefit_id',
            span: 6,
          },
          {
            name: 'benefit_status',
            span: 6,
          },
          {
            name: 'benefit_product_type',
            span: 6,
          },
          {
            name: 'benefit_vendor_type',
            span: 6,
          },
          {
            name: 'benefit_vendor_id',
            span: 6,
          },
          {
            name: 'provider_type',
            span: 6,
          },
          {
            name: 'provider_id',
            span: 6,
          },
          {
            name: 'plan_id',
            span: 6,
          },
          {
            name: 'full_name',
            span: 6,
          },
          {
            name: 'benefit_waiting_period_months',
            span: 6,
          },
          {
            name: 'renewed_from',
            span: 6,
          },
        ],
      },
    ],
  },
};
