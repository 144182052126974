import React from 'react';
import { connect } from 'react-redux';
import { Flex, genesisStyled } from '@leagueplatform/genesis-commons';
import { decorators, theme } from 'react-treebeard';
import { selectPathValidationSeverity } from './tree-navigation.selectors';

const ContainerWrapper = genesisStyled(Flex)`
  cursor: pointer;
`;

export class TreeNodeContainerPresenter extends decorators.Container {
  render() {
    const {
      style,
      terminal,
      onClick,
      node,
      decorators: Decorators,
      ...props
    } = this.props;
    return (
      <ContainerWrapper
        onClick={onClick}
        ref={ref => {
          this.clickableRef = ref;
        }}
        width={1}
        style={theme.node}
        borderRadius="small"
        backgroundColor={
          node.active ? 'decorative.brand.primary.pastel' : undefined
        }
        justifyContent="center"
        marginBottom="quarter"
        padding="quarter"
      >
        <Flex display="inline-flex" justifyContent="center">
          {!terminal && this.renderToggle()}
        </Flex>
        <Decorators.Header
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
          node={node}
          terminal={terminal}
          style={style.header}
        />
      </ContainerWrapper>
    );
  }
}

export const TreeNodeContainer = connect((state, { node }) => {
  return {
    status: selectPathValidationSeverity(state, node),
  };
})(TreeNodeContainerPresenter);
