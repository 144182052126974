import { call, select, fork, all, takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getEmployerAuditReport, getAuditFlows } from 'common/services';
import {
  ANALYTICS_EVENT_REPORT_SUCCESS,
  ANALYTICS_EVENT_NAME_ADMIN,
  ANALYTICS_EVENT_CATEGORY_REPORTS,
  ANALYTICS_EVENT_LABEL_CARRIER_AUDITS,
} from '../../reports.constants';
import {
  GET_CARRIER_AUDITS_REPORT,
  GET_AUDIT_FLOWS,
} from './carrier-audits-report.types';
import { selectGroupId } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { selectUserEmail } from '../../reports.selectors';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestCarrierAuditsReport({ payload }) {
  const groupId = yield select(selectGroupId);
  const userEmail = yield select(selectUserEmail);
  const params = { ...payload, groupId };
  yield call(toastActions.add, {
    type: TOAST_STATUS.INFO,
    textId: 'PREMIUMS_EXPORT_REQUESTED_COPY',
    values: {
      email: userEmail,
    },
  });
  const response = yield request(
    GET_CARRIER_AUDITS_REPORT,
    getEmployerAuditReport(params),
    params,
  );
  const contentId =
    response?.info?.report?.report_files?.[0]?.content_id ?? null;
  if (contentId) {
    window.open(getContentUrl(contentId), '_blank');
  }
}

export function* requestCarrierAuditsReportSuccess({ meta }) {
  yield call(
    sendAnalyticsEvent,
    {
      category: ANALYTICS_EVENT_CATEGORY_REPORTS,
      action: ANALYTICS_EVENT_REPORT_SUCCESS,
      label: ANALYTICS_EVENT_LABEL_CARRIER_AUDITS,
      group_id: meta.groupId,
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
}

export function* requestCarrierAuditsReportError() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'PREMIUMS_EXPORT_ERROR_COPY',
  });
}

export function* requestAuditFlows() {
  yield request(GET_AUDIT_FLOWS, getAuditFlows());
}

export function* carrierAuditReportsSagas() {
  yield fork(all, [
    takeLatest(GET_CARRIER_AUDITS_REPORT.BASE, requestCarrierAuditsReport),
    takeLatest(
      GET_CARRIER_AUDITS_REPORT.SUCCEEDED,
      requestCarrierAuditsReportSuccess,
    ),
    takeLatest(
      GET_CARRIER_AUDITS_REPORT.ERRORED,
      requestCarrierAuditsReportError,
    ),
    takeLatest(GET_AUDIT_FLOWS.BASE, requestAuditFlows),
  ]);
}
