import {
  GET_EMPLOYER_ENROLMENT_REPORT,
  GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT,
  GET_EMPLOYEE_DEMOGRAPHICS_REPORT,
  GET_EMPLOYER_BENEFITS_REPORT,
  GET_EMPLOYER_HR_DRIVERS,
  GET_BENEFICIARIES_REPORT,
  ENROLMENT_REPORT_LIFECYCLE,
  ENROLMENT_PROGRESS_REPORT_LIFECYCLE,
  EMPLOYEE_DEMOGRAPHICS_REPORT_LIFECYCLE,
  EMPLOYER_BENEFITS_REPORT_LIFECYCLE,
} from './employer-reports.types';
import { get } from 'lodash';

const defaultReportState = {
  reportContentId: null,
  loadingState: false,
  reportError: null,
};

export const initialState = {
  ...defaultReportState,
  hrDrivers: [],
};

export const applyContentIDForReport = (state, action) => ({
  ...state,
  ...defaultReportState,
  reportContentId: action.payload,
});

const handleError = (state, action) => ({
  ...state,
  ...defaultReportState,
  reportError: get(action, 'payload.error'),
});

const handleVisit = state => ({
  ...state,
  ...defaultReportState,
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerReportsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_EMPLOYER_ENROLMENT_REPORT.STARTED:
      return {
        ...state,
        ...defaultReportState,
        loadingState: true,
      };
    case GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT.STARTED:
    case GET_EMPLOYEE_DEMOGRAPHICS_REPORT.STARTED:
    case GET_EMPLOYER_BENEFITS_REPORT.STARTED:
    case GET_BENEFICIARIES_REPORT.STARTED:
      return {
        ...state,
        loadingState: true,
      };
    case GET_EMPLOYER_ENROLMENT_REPORT.SUCCEEDED:
    case GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT.SUCCEEDED:
    case GET_EMPLOYEE_DEMOGRAPHICS_REPORT.SUCCEEDED:
    case GET_EMPLOYER_BENEFITS_REPORT.SUCCEEDED:
    case GET_BENEFICIARIES_REPORT.SUCCEEDED:
      return applyContentIDForReport(state, action);
    case GET_EMPLOYER_HR_DRIVERS.SUCCEEDED:
      return {
        ...state,
        hrDrivers: action.payload.info.drivers,
      };
    case GET_EMPLOYER_ENROLMENT_REPORT.ERRORED:
    case GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT.ERRORED:
    case GET_EMPLOYEE_DEMOGRAPHICS_REPORT.ERRORED:
    case GET_EMPLOYER_BENEFITS_REPORT.ERRORED:
      return handleError(state, action);
    case ENROLMENT_REPORT_LIFECYCLE.VISITED:
    case ENROLMENT_PROGRESS_REPORT_LIFECYCLE.VISITED:
    case EMPLOYEE_DEMOGRAPHICS_REPORT_LIFECYCLE.VISITED:
    case EMPLOYER_BENEFITS_REPORT_LIFECYCLE.VISITED:
      return handleVisit(state);
    default:
      return state;
  }
};
