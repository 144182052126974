/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { isEmpty, noop } from 'lodash';
import { withProps } from 'recompose';
import { DATE_FORMAT, dayInTimeZone } from 'common/adaptive-forms';
import { DEFAULT_TIME_ZONE } from 'common/constants';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@leagueplatform/ui-kit';
import { FormattedMessage } from 'react-intl';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const DownloadLink = styled.a`
  font-weight: bold;
  font-size: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
  margin-right: 0.6rem;
`;

const CenteredColumn = styled(Table.Cell)`
  text-align: center;
`;

const withDates = withProps(props => ({
  billing_period_start: isEmpty(props.billing_period_start)
    ? ''
    : dayInTimeZone(props.billing_period_start, DEFAULT_TIME_ZONE).format(
        DATE_FORMAT,
      ),
  billing_period_end: isEmpty(props.billing_period_end)
    ? ''
    : dayInTimeZone(props.billing_period_end, DEFAULT_TIME_ZONE).format(
        DATE_FORMAT,
      ),
  timestamp: dayInTimeZone(props.timestamp, DEFAULT_TIME_ZONE).format(
    DATE_FORMAT,
  ),
}));

const PayrollRow = ({
  billing_period_start,
  billing_period_end,
  onReportDownload,
  timestamp,
  contentReferences,
}) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();
  const onDownload = () =>
    onReportDownload({
      isInHRAdminPortal,
    });

  return (
    <Table.Row>
      <Table.Cell>
        {!isEmpty(billing_period_start) && !isEmpty(billing_period_end) ? (
          <FormattedMessage
            id="PAYROLL_BILLING_DATE_RANGE"
            values={{
              startDate: billing_period_start,
              endDate: billing_period_end,
            }}
          />
        ) : null}
      </Table.Cell>
      <Table.Cell>{timestamp}</Table.Cell>
      <CenteredColumn>
        <DownloadLink
          onClick={onDownload}
          target="_blank"
          href={
            contentReferences.dayforce
              ? getContentUrl(contentReferences.dayforce)
              : getContentUrl(contentReferences.ca_payroll)
          }
        >
          <FontAwesomeIcon icon="download" />
        </DownloadLink>
      </CenteredColumn>
    </Table.Row>
  );
};

PayrollRow.propTypes = {
  billing_period_start: PropTypes.string,
  billing_period_end: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  contentReferences: PropTypes.shape({
    ca_payroll: PropTypes.string,
    dayforce: PropTypes.string,
  }),
  onReportDownload: PropTypes.func,
};

PayrollRow.defaultProps = {
  billing_period_start: '',
  billing_period_end: '',
  onReportDownload: noop,
  contentReferences: {
    pdf: null,
    csv: null,
  },
};

export default withDates(PayrollRow);
