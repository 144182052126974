import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function registerBenefitContribution calls the backend to override benefit contribution
 * a valid payload must at least contain user benefit id OR plan period, user id,
 * group id and contribution benefit type
 * @param payload contains
 *    {string} amount - amount contributed to benefit
 *    {string} contribution_source - where the contribution was deducted from
 *    {string} effective_date - date in which contribution was made
 *    {object} plan_period - plan period of the benefit
 *    {string} user_benefit_id - unique identifier of user and benefit type
 *    optional {object} pay_period - start_date and end_date for the contribution
 */
export const registerBenefitContribution = payload =>
  call(websocketFetch, 'register_benefit_contribution', payload);
