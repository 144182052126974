import React from 'react';
import { JSONSchemaFieldPropType } from '../prop-types';
import { DateInput } from '@leagueplatform/ui-kit';
import { FieldGroupWrapper } from './field-group-wrapper.view';

export const DateField = ({
  form,
  propSchema: { readOnly, ...propSchema },
  type,
  field,
  id,
  autoComplete,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <FieldGroupWrapper {...propSchema} {...props}>
    <DateInput
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...field}
      id={id}
      fit
      disabled={readOnly}
      hasError={Boolean(form.errors[field.name])}
      type={type}
      autoComplete={autoComplete}
      onChange={(_, { date }) => {
        if (date) {
          const utcDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
          );
          form.setFieldValue(field.name, utcDate);
        }
      }}
    />
  </FieldGroupWrapper>
);

DateField.propTypes = JSONSchemaFieldPropType;
