import { createSelector } from 'reselect';
import { selectAuthentication } from '../selectors';

export const selectIsAuthenticating = createSelector(
  selectAuthentication,
  auth => auth?.isAuthenticating,
);

export const selectAccessToken = createSelector(
  selectAuthentication,
  auth => auth?.accessToken,
);
