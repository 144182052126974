import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const EMPLOYER_BENEFITS_LIFECYCLE = createLifecycleTypes(
  'hr/employer-benefits/EMPLOYER_BENEFITS_LIFECYCLE',
);

export const EMPLOYER_BENEFITS_INIT = createRequestTypes(
  'hr/employer-benefits/INIT',
);

export const GET_EMPLOYER_BENEFIT_DOCUMENTS = createRequestTypes(
  'hr/emoloyer-benefits/GET_EMPLOYER_BENEFIT_DOCUMENTS',
);
