import styled from 'styled-components';
import { colour, media, radiusInPx, spaceInPx } from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

export const EnrollmentCheckBox = styled(Flex)`
  .checkbox {
    width: 100%;
  }
  .icon {
    margin-right: ${spaceInPx(2)};
  }
  label {
    cursor: pointer;
    padding: ${spaceInPx(3)};
    background-color: ${colour('greyLightest')};
    border-radius: ${radiusInPx(2)};
    flex-grow: 1;
    line-height: 2rem;
    &:hover {
      background-color: ${colour('purpleLightest')};
    }
    span:nth-child(1) {
      padding-left: 1.5rem;
    }
    ${media.belowTablet`
      padding: ${spaceInPx(3)} 24px;
    `};
  }
  input:disabled + label {
    pointer-events: none;
  }
`;
