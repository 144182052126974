import { REQUEST_SSO_VENDOR_DETAILS } from './sso-error.action-types';
import { CONFIGURE_CONNECTION } from 'common/websocket-redux';

export const initialState = {
  portalUrl: '', // temporary until backend is done
  ready: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const ssoErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SSO_VENDOR_DETAILS.STARTED:
      return {
        ...state,
      };
    case REQUEST_SSO_VENDOR_DETAILS.SUCCEEDED:
      return {
        ...state,
        portalUrl: action.payload.info.portal_url, // Store raw schema. Normalizing happens in selector.
      };
    case REQUEST_SSO_VENDOR_DETAILS.ERRORED:
      return {
        ...state,
      };
    case CONFIGURE_CONNECTION.SUCCEEDED:
      return {
        ...state,
        ready: true,
      };
    default:
      return state;
  }
};
