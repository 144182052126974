import { createSelector } from 'reselect';
import { get as prop } from 'lodash/fp';
import { selectEmployerDetails } from '../selectors';
import layout from 'layouts/addEditEmployer';
import {
  createValidator,
  createFormGenerator,
  flattenObject,
  createFormValueFormatter,
} from 'common/adaptive-forms';

export const selectIsEditEmployerFormReady = createSelector(
  selectEmployerDetails,
  prop('editEmployerFormReady'),
);

export const selectIsNewEmployerFormReady = createSelector(
  selectEmployerDetails,
  prop('newEmployerFormReady'),
);
export const selectProfileIsUpdating = createSelector(
  selectEmployerDetails,
  prop('profileIsUpdating'),
);

export const selectEmployerCreationError = createSelector(
  selectEmployerDetails,
  prop('error'),
);

export const selectEmployerSchemaReady = createSelector(
  selectEmployerDetails,
  prop('employerSchemaReady'),
);

export const selectEmployerFormValues = createSelector(
  selectEmployerDetails,
  details => {
    const employer = details.employerForm;
    return flattenObject(employer);
  },
);

export const selectServiceStatus = createSelector(
  selectEmployerDetails,
  prop('employerServiceStatus'),
);

export const selectFormSchema = createSelector(
  selectEmployerDetails,
  prop('employerFormFieldsSchema'),
);

export const selectEditFormSchema = createSelector(
  selectEmployerDetails,
  prop('employerFormSchema'),
);
export const selectValidator = createSelector(
  selectFormSchema,
  createValidator,
);

export const selectEmployerStatus = createSelector(
  selectEmployerDetails,
  data => data.employer.group.employer.status,
);

export const selectEmployerSuspendStatus = createSelector(
  selectEmployerDetails,
  data => data.employer.suspended,
);

export const selectFormGenerator = createSelector(selectFormSchema, schema =>
  createFormGenerator({ schema, layout: layout.addEditEmployer }),
);

export const selectEditFormGenerator = createSelector(
  selectEditFormSchema,
  schema => createFormGenerator({ schema, layout: layout.addEditEmployer }),
);

export const selectFormValueFormatter = createSelector(
  selectEditFormSchema,
  createFormValueFormatter,
);

export const selectFormattedFormValues = createSelector(
  [selectFormValueFormatter, selectEmployerFormValues],
  (format, formValues) => format(formValues),
);
