export default {
  lfVoluntaryLifeADD: {
    name: 'lfVoluntaryLifeADD',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'accidental_death_and_dismemberment_included',
            span: 6,
          },

          {
            name: 'benefit_schedule_earnings_percent',
            span: 6,
          },

          {
            name: 'no_evidence_maximum',
            span: 6,
          },

          {
            name: 'benefit_maximum',
            span: 6,
          },

          {
            name: 'benefit_schedule_flat_amount',
            span: 6,
          },

          {
            name: 'dependent_life_included',
            span: 6,
          },

          {
            name: 'dependent_life_benefit_amount_spouse',
            span: 6,
          },

          {
            name: 'dependent_life_benefit_amount_child',
            span: 6,
          },

          {
            name: 'optional_life_for_employee_included',
            span: 6,
          },

          {
            name: 'optional_life_for_spouse_included',
            span: 6,
          },

          {
            name: 'optional_life_for_child_included',
            span: 6,
          },
        ],
      },
    ],
  },
};
