import { createSelector } from 'reselect';
import { selectUser } from 'common/state/user/user.selectors';
import { map } from 'lodash';
import { get } from 'lodash/fp';

const formatDashboards = dashboards =>
  map(dashboards, (item, index) => ({
    [index]: item?.map(dashboard => ({
      type: dashboard.type,
      label: dashboard.label,
      options: dashboard.items,
    })),
  }))?.reduce((accum, item) => ({ ...accum, ...item }), {});

const selectGroup = state => state?.apps['employer-experience']?.group;

export const selectEmployerInsights = state =>
  state?.apps['employer-experience']?.employerInsights;

export const selectDashboards = createSelector(
  state => state?.apps['employer-experience']?.employerInsights,
  ({ dashboards }) => {
    if (!dashboards) return {};
    return formatDashboards(dashboards);
  },
);

const selectDashboardURLs = createSelector(
  selectEmployerInsights,
  ({ dashboardURLs }) => dashboardURLs,
);

export const selectDashboardURLById = createSelector(
  (_, dashboardId) => dashboardId,
  selectDashboardURLs,
  (dashboardId, dashboardURLs) => dashboardURLs?.[dashboardId],
);

export const selectIsLoadingDashboard = createSelector(
  selectEmployerInsights,
  ({ loadingDashboards }) => loadingDashboards ?? false,
);

export const selectIsLoadingDashboardURL = createSelector(
  selectEmployerInsights,
  ({ loadingDashboardURL }) => loadingDashboardURL ?? false,
);

export const selectCanShowSpendingAccountBalanceReport = createSelector(
  selectGroup,
  details => details?.has_spending_accounts ?? false,
);

export const selectDigitalBeneficiariesEnabled = createSelector(
  selectGroup,
  details => details?.group?.employer?.digital_beneficiaries_enabled ?? false,
);

export const selectUserEmail = createSelector(selectUser, get('email'));
