import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEnrollmentDesignOptions,
  resetEnrollmentDesignOptions,
  setEnrollmentDesignOptions,
} from './enrollment-design-options.actions';
import EnrollmentExperienceConfigPresenter from './enrollment-experience-config.view';
import {
  selectEnrollmentDesignSteps,
  selectAvailableBenefitSets,
  selectSavingEnrollmentDesignOptions,
  selectEnrollmentDesignStepsUrls,
} from '../enrollment-design.selectors';
import { selectCurrentPlanId } from '../../enrollment-config';
import {
  setCurrentPlanId,
  fetchBenefitPlanOptions,
} from '../../enrollment-config/enrollment-config.actions';
import { BenefitStep } from './enrollment-design-options.types';
import { formatStepListForRequest } from './utils';
import { Toast, toastActions } from '@leagueplatform/toast-messages';

interface EnrollmentExperienceConfigContainerProps {
  selectedPlanId: string;
  groupId: string;
}

export const EnrollmentExperienceConfigContainer = ({
  selectedPlanId,
  groupId,
  ...props
}: EnrollmentExperienceConfigContainerProps) => {
  const enrollmentSteps = useSelector(selectEnrollmentDesignSteps);
  const currentPlanId = useSelector(selectCurrentPlanId);
  const availableBenefitSets = useSelector(selectAvailableBenefitSets);
  const enrollmentStepUrls = useSelector(selectEnrollmentDesignStepsUrls);
  const isSaving = useSelector(selectSavingEnrollmentDesignOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    // TEMP FIX: setEnrollmentDesignOptions needs to be run the first time temporarily
    // Uncomment when you need to initialize design options for a plan
    // dispatch(
    //   setEnrollmentDesignOptions({
    //     groupId,
    //     planId: selectedPlanId,
    //     steps: {},
    //   }),
    // );
    dispatch(getEnrollmentDesignOptions({ groupId, planId: selectedPlanId }));
    dispatch(fetchBenefitPlanOptions({ groupId, planId: selectedPlanId }));
  }, [dispatch, selectedPlanId, groupId]);

  useEffect(() => {
    if (currentPlanId !== selectedPlanId)
      dispatch(setCurrentPlanId(selectedPlanId));
  }, [currentPlanId, selectedPlanId, dispatch]);

  const showToast = (toastDetail: Toast) => {
    toastActions.add(toastDetail);
  };

  const saveEnrollmentSteps = (steps: BenefitStep[]) => {
    dispatch(
      setEnrollmentDesignOptions({
        groupId,
        planId: selectedPlanId,
        steps: formatStepListForRequest(steps),
      }),
    );
  };

  const resetEnrollmentSteps = () => {
    dispatch(
      resetEnrollmentDesignOptions(
        {
          planId: selectedPlanId,
        },
        {
          planId: selectedPlanId,
          groupId,
        },
      ),
    );
  };

  return (
    <EnrollmentExperienceConfigPresenter
      groupId={groupId}
      selectedPlanId={selectedPlanId}
      enrollmentSteps={enrollmentSteps}
      enrollmentStepUrls={enrollmentStepUrls}
      availableBenefitSets={availableBenefitSets}
      showToast={showToast}
      isSaving={isSaving}
      saveEnrollmentSteps={saveEnrollmentSteps}
      resetEnrollmentSteps={resetEnrollmentSteps}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

export default EnrollmentExperienceConfigContainer;
