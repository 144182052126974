import { createSelector } from 'reselect';

const selectApp = state => state;

export const selectReady = createSelector(selectApp, state => state.ready);
export const selectError = createSelector(selectApp, state => state.error);
export const selectFirstName = createSelector(
  selectApp,
  state => state?.info?.invitation?.first_name,
);
