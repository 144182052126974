import {
  ADD_EMPLOYER_BENEFIT_DOCUMENT,
  UPDATE_EMPLOYER_BENEFIT_DOCUMENT,
} from './types';

export const addEmployerBenefitDocument = payload => ({
  type: ADD_EMPLOYER_BENEFIT_DOCUMENT,
  payload,
});

export const updateEmployerBenefitDocument = payload => ({
  type: UPDATE_EMPLOYER_BENEFIT_DOCUMENT,
  payload,
});
