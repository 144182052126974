import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { JSONSchemaFieldPropType } from '../prop-types';
import { FieldWrapper } from './field-wrapper.view';
import {
  MultiSelect,
  mapValuesToOptions,
  mapOneOfValuesToOptions,
} from '@leagueplatform/ui-kit';

export const MultiSelectField = ({
  field,
  field: { name, value: fieldValue } = {},
  form,
  propSchema: { readOnly, enum: options, oneOf, ...propSchema },
  ...props
}) => {
  const { formatMessage } = useIntl();
  const selectedValues = fieldValue || [];
  const selectOptions = useMemo(() => {
    if (options) return mapValuesToOptions(options);
    if (oneOf) return mapOneOfValuesToOptions(oneOf);
    return [];
  }, [options, oneOf]);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <MultiSelect
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        disabled={readOnly}
        hasError={!!form.errors[name]}
        onBlur={({ value }) => {
          form.handleBlur({ target: { value, name } });
        }}
        onChange={selectedOptions => {
          const selectedOptionsArray = Array.isArray(selectedOptions)
            ? selectedOptions
            : [];
          form.setFieldValue(
            name,
            selectedOptionsArray.map(option => option.value),
          );
        }}
        value={selectOptions.filter(option =>
          selectedValues.includes(option.value),
        )}
        options={selectOptions}
        placeholder={formatMessage({ id: 'SELECT_ONE' })}
      />
    </FieldWrapper>
  );
};

MultiSelectField.propTypes = {
  ...JSONSchemaFieldPropType,
  isClearable: PropTypes.bool,
};

MultiSelectField.defaultProps = {
  isClearable: true,
};
