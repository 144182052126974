import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Input, Text } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TypeformURLInput = styled(Input)`
  height: 3rem;
`;

const TypeformIntegration = ({ typeform, values, setFieldValue }) => {
  const { benefitClasses = [] } = typeform;
  return (
    <div>
      <h4>Typeform</h4>
      <Text>
        <FormattedMessage id="COPY_PASTE_TYPEFORM_URL" />
      </Text>
      <div>
        <Flex>
          <Box flex={2}>
            <strong>
              <FormattedMessage id="BENEFIT_CLASS" />
            </strong>
          </Box>
          <Box flex={3}>
            <strong>
              <FormattedMessage id="TYPEFORM_URL" />
            </strong>
          </Box>
        </Flex>
        {benefitClasses.map(benefitClass => (
          <Flex mt={2} key={benefitClass.id}>
            <Box flex={2}>{benefitClass.name}</Box>
            <Box flex={3}>
              <TypeformURLInput
                fit
                name="typeform_url"
                value={values[benefitClass.id] || ''}
                onChange={e => setFieldValue(benefitClass.id, e.target.value)}
              />
            </Box>
          </Flex>
        ))}
      </div>
    </div>
  );
};

export const TypeformShape = PropTypes.shape({
  benefitClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      typeformUrl: PropTypes.string,
    }),
  ),
});

export const TypeformValuesShape = PropTypes.shape({
  groupId: PropTypes.string,
});

TypeformIntegration.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: TypeformValuesShape.isRequired,
  typeform: TypeformShape.isRequired,
};

export default TypeformIntegration;
