import React, { useEffect, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import Logo from 'apps/public/assets/contentful-logo.png';
import {
  BodyOne,
  BodyTwoSecondary,
  Box,
  Image,
} from '@leagueplatform/genesis-commons';
import { ContentfulTemplates } from './contentful-templates.view';
import { Banner } from './contentful-integration.styles';
import { EnrollmentBannerTypes } from './contentful-integration.types';

interface ContentfulIntegrationProps {
  templates: any[];
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  isLoading?: boolean;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  selectedTemplate?: string;
  createContentfulTemplate: (type: string) => void;
  onChange: (value: string | null) => void;
  type: string;
}

export const ContentfulIntegration = ({
  templates,
  isLoading = false,
  selectedTemplate,
  createContentfulTemplate,
  onChange,
  type,
}: ContentfulIntegrationProps) => {
  const { formatMessage } = useIntl();

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    selectedTemplate || null,
  );

  useEffect(() => {
    onChange(selectedTemplateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId]);

  return (
    <Box
      borderRadius="medium"
      borderStyle="solid"
      borderWidth="thin"
      borderColor="onSurface.border.subdued"
      paddingY="oneAndHalf"
    >
      <Image
        marginX="oneAndHalf"
        src={Logo}
        width={127}
        height={27}
        role="presentation"
      />

      <BodyTwoSecondary
        marginTop="one"
        marginX="oneAndHalf"
        fontWeight="regular"
      >
        {formatMessage({ id: 'ENROLLMENT_CONTENTFUL_DESCRIPTION' })}
      </BodyTwoSecondary>

      <Box marginTop="one" marginX="oneAndHalf">
        {!isLoading && templates.length === 0 && (
          <Banner type={EnrollmentBannerTypes.ERROR}>
            <BodyOne>
              {formatMessage({
                id: 'ENROLLMENT_CONTENTFUL_ERROR_BANNER',
              })}
            </BodyOne>
          </Banner>
        )}
      </Box>

      <ContentfulTemplates
        templates={templates}
        isChecked
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        createContentfulTemplate={createContentfulTemplate}
        type={type}
      />
    </Box>
  );
};
