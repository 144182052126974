import { createRequestTypes } from 'common/websocket-redux';

export const GET_BENEFIT_FORM_LIST = createRequestTypes(
  'insurance-forms/GET_BENEFIT_FORM_LIST',
);

export const GET_USER_BENEFIT_FORM = createRequestTypes(
  'insurance-forms/GET_USER_BENEFIT_FORM',
);

export const GET_DIGITAL_BENEFICIARY_FORM = createRequestTypes(
  'insurance-forms/GET_DIGITAL_BENEFICIARY_FORM',
);

export const loadBenefitFormList = payload => ({
  type: GET_BENEFIT_FORM_LIST.BASE,
  payload,
});

export const loadUserBenefitForm = payload => ({
  type: GET_USER_BENEFIT_FORM.BASE,
  payload,
});

export const loadDigitalBeneficiaryForm = payload => ({
  type: GET_DIGITAL_BENEFICIARY_FORM.BASE,
  payload,
});
