import React from 'react';
import PropTypes from 'prop-types';
import { genesisStyled, Flex } from '@leagueplatform/genesis-commons';
import PullingPlug from './assets/ic-pulling-plug.svg';
import { StateMessage } from '@leagueplatform/ui-kit';

const ErrorContainer = genesisStyled(Flex)`
  -webkit-overflow-scrolling: touch;
  @media print {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

export const ErrorMessage = ({ title, message, children }) => {
  return (
    <ErrorContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      top={0}
      left={0}
      position="fixed"
      overflow="auto"
    >
      <StateMessage
        title={title}
        message={message}
        icon={PullingPlug}
        iconSize="250px"
      >
        {children}
      </StateMessage>
    </ErrorContainer>
  );
};

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

ErrorMessage.defaultProps = {
  children: null,
};
