import React, { useEffect } from 'react';
import { Text } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { HeadingFour } from '@leagueplatform/genesis-commons';
import { EmployerReportsInfoBox, EmployerReportsLoaderButton } from './ui-kit';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NoteText } from 'apps/employer-experience/ui-kit/report-elements.view';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

const EnrolmentProgressReportDownload = styled(EmployerReportsLoaderButton)`
  margin: 1rem 0;
`;

export const EnrolmentProgressReport = ({
  groupId,
  loadingState,
  getEmployerEnrolmentProgressReport,
  contentId,
  reportError,
  email,
  visit,
}) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();

  // lifecycle
  useEffect(() => {
    visit();
  }, [visit]);

  return (
    <>
      <Flex align="flex-start">
        <HeadingFour>
          <FormattedMessage id="ENROLLMENT_PROGRESS_REPORT" />
        </HeadingFour>
      </Flex>
      <Text>
        <FormattedMessage id="ENROLLMENT_PROGRESS_REPORT_DESCRIPTION" />
      </Text>
      <Text>
        <NoteText>
          <FormattedMessage id="NOTE" />:
        </NoteText>
        <FormattedMessage id="REPORTS_LOADING_NOTE_COPY" />
      </Text>
      <EnrolmentProgressReportDownload
        primary
        type="submit"
        loading={loadingState}
        onClick={() =>
          getEmployerEnrolmentProgressReport(groupId, isInHRAdminPortal)
        }
      >
        <FormattedMessage id="DOWNLOAD_ENROLLMENT_PROGRESS_REPORT" />
      </EnrolmentProgressReportDownload>
      <EmployerReportsInfoBox
        loading={loadingState}
        error={reportError}
        ready={!!contentId}
        email={email}
      />
    </>
  );
};

EnrolmentProgressReport.propTypes = {
  groupId: PropTypes.string,
  loadingState: PropTypes.bool.isRequired,
  getEmployerEnrolmentProgressReport: PropTypes.func.isRequired,
  contentId: PropTypes.string,
  reportError: PropTypes.string,
  email: PropTypes.string.isRequired,
  visit: PropTypes.func.isRequired,
};

EnrolmentProgressReport.defaultProps = {
  groupId: null,
  contentId: null,
  reportError: null,
};
