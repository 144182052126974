import { createRequestTypes } from 'common/websocket-redux';

export const LOAD_EMPLOYER_BENEFIT_PLANS = createRequestTypes(
  'benefit-plans/LOAD_EMPLOYER_BENEFIT_PLANS',
);

export const loadEmployerBenefitPlans = payload => ({
  type: LOAD_EMPLOYER_BENEFIT_PLANS.BASE,
  payload,
});
