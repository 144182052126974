import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_BENEFIT_TYPE_SCHEMAS = createRequestTypes(
  'service/GET_BENEFIT_TYPE_SCHEMAS',
);

export const GET_USER_BENEFIT_TYPE_SCHEMAS = createRequestTypes(
  'service/GET_USER_BENEFIT_TYPE_SCHEMAS',
);

export const BENEFIT_LIFECYCLE = createLifecycleTypes('benefits/BENEFIT');
