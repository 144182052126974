import { connect } from 'react-redux';
import { DeductionCodesPresenter } from './deduction-codes.view';
import {
  loadDeductionCodeEditor,
  setDeductionCodes,
} from '../../resources/deduction-codes/deduction-codes.actions';
import { selectStatus } from '../../resources/deduction-codes/deduction-codes.selectors';
import { createStructuredSelector } from 'reselect';

export const DeductionCodeContainer = connect(
  createStructuredSelector({
    status: selectStatus,
  }),
  {
    loadDeductionCodeEditor,
    setDeductionCodes,
  },
)(DeductionCodesPresenter);
