import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon, Label } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import AddRenameInput from '../AddRenameInput';

class AddBenefitClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canAdd: false,
      adding: false,
      benefitClassName: '',
      hasError: false,
    };

    this.toggleAddBenefitClass = this.toggleAddBenefitClass.bind(this);
    this.onAddBenefitClass = this.onAddBenefitClass.bind(this);
    this.onBenefitClassNameChange = this.onBenefitClassNameChange.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps({ errorAddingBenefitClass, addingBenefitClass }) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (errorAddingBenefitClass && this.props.addingBenefitClass) {
      this.setState(() => ({ adding: false, hasError: true }));
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    } else if (!addingBenefitClass && this.props.addingBenefitClass) {
      this.setState(() => ({
        adding: false,
        benefitClassName: '',
        canAdd: false,
        hasError: false,
      }));
    }
  }

  onAddBenefitClass() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    const value = this.state.benefitClassName;

    if (value.trim()) {
      this.setState(() => ({
        adding: true,
        hasError: false,
        benefitClassName: value,
      }));
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.addBenefitClass({ name: value });
    } else {
      this.setState(() => ({ hasError: true }));
    }
  }

  onBenefitClassNameChange(e, { value }) {
    const newValue = value;
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.hasError && newValue) {
      this.setState(() => ({ benefitClassName: newValue, hasError: false }));
    } else {
      this.setState(() => ({ benefitClassName: newValue }));
    }
  }

  toggleAddBenefitClass() {
    this.setState(previousState => ({ canAdd: !previousState.canAdd }));
  }

  render() {
    const { canAdd, adding, benefitClassName, hasError } = this.state;
    const { intl } = this.props;

    return (
      <Container className="container--add-benefit-class">
        {!canAdd ? (
          <Label onClick={this.toggleAddBenefitClass}>
            <Icon fitted name="add" />
            <FormattedMessage id="ADD_BENEFITS_CLASS" />
          </Label>
        ) : (
          <AddRenameInput
            hasError={hasError}
            name={benefitClassName}
            onChangeHandler={this.onBenefitClassNameChange}
            onSubmitHandler={this.onAddBenefitClass}
            shouldLoad={adding}
            contentId={intl.formatMessage({
              id: 'ADD_BENEFITS_CLASS',
            })}
            placeholderId={intl.formatMessage({
              id: 'ADD_BENEFITS_CLASS_PLACEHOLDER',
            })}
            toggle={this.toggleAddBenefitClass}
          />
        )}
      </Container>
    );
  }
}

AddBenefitClass.propTypes = {
  intl: intlShape.isRequired,
  addBenefitClass: PropTypes.func.isRequired,
  errorAddingBenefitClass: PropTypes.bool.isRequired,
  addingBenefitClass: PropTypes.bool.isRequired,
};

export const WrappedComponent = AddBenefitClass;

export default injectIntl(AddBenefitClass);
