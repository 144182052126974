import { createRequestTypes } from 'common/websocket-redux';

/* Types */

export const GET_USER_PLAN_ENROLLMENT = createRequestTypes(
  'admin/GET_USER_PLAN_ENROLLMENT',
);

export const REMOVE_USER_PLAN_ENROLLMENT = createRequestTypes(
  'admin/REMOVE_USER_PLAN_ENROLLMENT',
);

/* Creators */

export const getUserPlanEnrollment = ({ userId, groupId }) =>
  GET_USER_PLAN_ENROLLMENT.request({
    groupId,
    userId,
  });

export const removeUserPlanEnrollment = ({ userId, groupId, planId }) =>
  REMOVE_USER_PLAN_ENROLLMENT.request({
    groupId,
    userId,
    planId,
  });
