import React from 'react';
import { takeLatest, put, call } from 'redux-saga/effects';
import { Link } from '@leagueplatform/genesis-commons';
import { RE_REGISTER_PAY_PERIOD } from './edit-pay-calendar.types';
import { reRegisterPayPeriod } from 'common/services/payroll/re-register-pay-period.service';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_ACTION_RE_REGISTER_FAILED,
  ANALYTICS_EVENT_ACTION_RE_REGISTER_SUCCEEDED,
  ANALYTICS_EVENT_CATEGORY_PAYROLL,
  ANALYTICS_EVENT_LABEL_RE_REGISTER_BUTTON,
  ANALYTICS_EVENT_NAME_ADMIN,
} from './constants';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* callReRegisterPayPeriod({ payload }) {
  try {
    yield put(RE_REGISTER_PAY_PERIOD.start());
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'RE_REGISTER_START',
    });
    const response = yield reRegisterPayPeriod(payload);

    const { groupId, reason, description } = payload;
    yield call(
      sendAnalyticsEvent,
      {
        action: ANALYTICS_EVENT_ACTION_RE_REGISTER_SUCCEEDED,
        label: ANALYTICS_EVENT_LABEL_RE_REGISTER_BUTTON,
        category: ANALYTICS_EVENT_CATEGORY_PAYROLL,
        group_id: groupId,
        reason,
        description,
      },
      ANALYTICS_EVENT_NAME_ADMIN,
    );

    yield put(RE_REGISTER_PAY_PERIOD.success(response));
    yield call(toastActions.add, {
      shouldAutoClose: false,
      type: TOAST_STATUS.SUCCESS,
      textId: 'RE_REGISTER_SUCCESS',
      values: {
        a: msg => (
          <Link
            href={`/app/admin/employers/${payload.groupId}/payroll/scheduled-reports`}
          >
            {msg}
          </Link>
        ),
      },
    });
  } catch (e) {
    const { groupId, reason, description } = payload;
    yield call(
      sendAnalyticsEvent,
      {
        action: ANALYTICS_EVENT_ACTION_RE_REGISTER_FAILED,
        label: ANALYTICS_EVENT_LABEL_RE_REGISTER_BUTTON,
        category: ANALYTICS_EVENT_CATEGORY_PAYROLL,
        group_id: groupId,
        reason,
        description,
      },
      ANALYTICS_EVENT_NAME_ADMIN,
    );
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* editPayCalendarSaga() {
  yield takeLatest(RE_REGISTER_PAY_PERIOD.BASE, callReRegisterPayPeriod);
}
