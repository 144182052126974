import React from 'react';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import PropTypes from 'prop-types';
import { vars, Box } from '@leagueplatform/ui-kit';

const DayPickerWrapper = styled(Box)`
  max-width: 16.5rem;

  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }

  .DayPickerInput input {
    padding: 0.875rem;
    ${({ hasError }) =>
      hasError && `border-color: ${vars.colour.red} !important;`};
  }
`;

export const EmployerReportsDayPicker = ({
  error,
  handleDayChange,
  placeholder,
}) => {
  const { formatMessage } = useIntl();
  return (
    <DayPickerWrapper hasError={error}>
      <StyledDayPickerInput
        placeholder={placeholder || formatMessage({ id: 'CHOOSE_DATE' })}
        onDayChange={date => handleDayChange(date)}
      />
    </DayPickerWrapper>
  );
};

EmployerReportsDayPicker.propTypes = {
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  handleDayChange: PropTypes.func.isRequired,
};

EmployerReportsDayPicker.defaultProps = {
  error: false,
  placeholder: null,
};
