import React from 'react';
import {
  HeadingText,
  ParagraphText,
  Modal,
  Radio,
  StackLayout,
  InputStatusMessage,
  StatusBanner,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Form, Formik } from 'formik';
import { useStudentCertification } from './use-student-certification.hook';
import { useValidationSchema } from 'common/json-schema-form';
import formStudentCertificationSchema from './form-student-certification.schema.json';
import { PrimaryButton } from '@leagueplatform/genesis-commons';

export interface DependentInfoData {
  first_name: string;
  last_name: string;
  dependent_id: string;
}

export interface StudentCertificationViewProps {
  dependent: DependentInfoData;
  userId: string;
  groupId: String;
  onClose: () => void;
}

export const StudentCertificationView = ({
  dependent,
  userId,
  groupId,
  onClose,
}: StudentCertificationViewProps) => {
  const { formatMessage } = useIntl();
  const options = [
    {
      label: formatMessage({ id: 'YES' }),
      value: true,
    },
    {
      label: formatMessage({ id: 'NO' }),
      value: false,
    },
  ];
  const dependentId = dependent?.dependent_id;
  const { onSubmitRespondStudentCertification } = useStudentCertification({
    userId,
    dependentId,
    groupId,
  });
  const initialValues = {
    isFullTimeStudent: undefined,
  };
  const isBooleanField = options.some(
    option => typeof option.value === 'boolean',
  );
  const validationSchema = useValidationSchema({
    schema: formStudentCertificationSchema,
  });
  const checkErrors = (errors: any) => Object.keys(errors).length > 0;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        onSubmitRespondStudentCertification(values);
        onClose();
      }}
      enableReinitialize
    >
      {({ values, handleSubmit, setFieldValue, errors, touched }) => (
        <Form>
          <Modal.Root onOpenChange={() => onClose()} open>
            <Modal.Content>
              <StackLayout spacing="$threeQuarters">
                <HeadingText level="1" size="xl">
                  {formatMessage({ id: 'STUDENT_RECERTIFICATION' })}
                </HeadingText>
                <ParagraphText>
                  {formatMessage({ id: 'STUDENT_RECERTIFICATION_DESCRIPTION' })}
                </ParagraphText>
                <ParagraphText>
                  {formatMessage(
                    { id: 'CONFIRM_DEPENDENT_STATUS' },
                    {
                      firstName: dependent?.first_name,
                      lastName: dependent?.last_name,
                    },
                  )}
                </ParagraphText>
              </StackLayout>
              {values.isFullTimeStudent !== undefined && (
                <StatusBanner status="info" css={{ marginTop: '$half' }}>
                  <ParagraphText size="sm">
                    {values.isFullTimeStudent
                      ? formatMessage({
                          id: 'STUDENT_RECERTIFICATION_YES_INFO',
                        })
                      : formatMessage({
                          id: 'STUDENT_RECERTIFICATION_NO_INFO',
                        })}
                  </ParagraphText>
                </StatusBanner>
              )}
              <StackLayout
                orientation="horizontal"
                css={{ marginTop: '$half' }}
              >
                {options?.map((option, idx) => (
                  <Radio
                    id={`radio-${idx}`}
                    key={option.label}
                    value={String(option.value)}
                    name="isFullTimeStudent"
                    onChange={({ target }) => {
                      const newValue = isBooleanField
                        ? target.value === 'true'
                        : target.value;
                      setFieldValue('isFullTimeStudent', newValue);
                    }}
                    label={option.label}
                    aria-describedby={
                      touched.isFullTimeStudent && errors.isFullTimeStudent
                        ? 'response-error-message'
                        : undefined
                    }
                  />
                ))}
              </StackLayout>
              {touched.isFullTimeStudent && errors.isFullTimeStudent && (
                <InputStatusMessage
                  inputStatus="error"
                  id="response-error-message"
                >
                  {errors.isFullTimeStudent}
                </InputStatusMessage>
              )}
              <PrimaryButton
                onClick={handleSubmit}
                type="submit"
                disabled={checkErrors(errors)}
                marginTop="oneAndHalf"
              >
                {formatMessage({ id: 'CONFIRM' })}
              </PrimaryButton>
            </Modal.Content>
          </Modal.Root>
        </Form>
      )}
    </Formik>
  );
};
