import {
  EDIT_BENEFIT_WALLET_VISITED,
  PROVIDER_BENEFIT_SELECTED,
  BENEFIT_WALLET_DETAILS_CLEARED,
  USAGE_INSTRUCTIONS_SUBMITTED,
  BENEFIT_WALLET_ACTIONS_SUBMITTED,
  REMOVE_BENEFIT_WALLET_ACTIONS_REQUESTED,
  WALLET_OVERVIEW_SUBMITTED,
} from './benefit-wallet.types';

import {
  USAGE_INSTRUCTIONS,
  OVERVIEW,
  REMOVE_WALLET_ACTION,
} from './constants';

export const visitEditWallet = payload => ({
  type: EDIT_BENEFIT_WALLET_VISITED,
  payload,
});

export const clearBenefitWalletDetais = () => ({
  type: BENEFIT_WALLET_DETAILS_CLEARED,
});

export const setWalletActions = (info, key) => ({
  type: BENEFIT_WALLET_ACTIONS_SUBMITTED,
  payload: {
    info,
    key,
  },
});

export const removeWalletAction = payload => ({
  type: REMOVE_BENEFIT_WALLET_ACTIONS_REQUESTED,
  payload,
  meta: { actionType: REMOVE_WALLET_ACTION },
});

export const selectProviderBenefit = payload => ({
  type: PROVIDER_BENEFIT_SELECTED,
  payload,
});

export const submitUsageInstructions = payload => ({
  type: USAGE_INSTRUCTIONS_SUBMITTED,
  payload,
  meta: { actionType: USAGE_INSTRUCTIONS },
});

export const submitWalletOverview = payload => ({
  type: WALLET_OVERVIEW_SUBMITTED,
  payload,
  meta: { actionType: OVERVIEW },
});
