import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function reRegisterPayPeriod calls the backend to re-register pay period and re-run reports
 * @param {string} payrollScheduleId id of the schedule from which you are re-registering
 * @param {string} payPeriod pay period to re-register, must match the pay period in schedule
 */
export const reRegisterPayPeriod = ({ payrollScheduleId, payPeriod }) =>
  call(websocketFetch, 'register_payroll_deductions_and_contributions', {
    payroll_schedule_id: payrollScheduleId,
    pay_period: payPeriod,
  });
