import React, { useState } from 'react';
import 'raf/polyfill';
import { Provider } from 'react-redux';
import reducer from 'common/state/reducer';
import { rootSaga } from 'common/state/root.saga';
import { createConfiguredStore } from './store';
import { useHistory } from 'react-router-dom';

export const MemberConfiguredProvider = props => {
  const history = useHistory();

  // Note: this `useState` pattern is to ensure only 1 instance of the store is created
  // and prevents the member saga from leaking into the other experiences
  const [store] = useState(() =>
    createConfiguredStore({
      reducer: reducer(history),
      history,
      saga: rootSaga,
    }),
  );

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <Provider store={store} {...props} />;
};
