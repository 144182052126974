import {
  EMPLOYER_BENEFITS_INIT,
  EMPLOYER_BENEFITS_LIFECYCLE,
} from './benefits.types';
import { GET_EMPLOYER_BENEFITS } from 'apps/employer-experience/pages/EmployerDetails/types';
import { GET_EMPLOYER_BENEFIT_PLANS } from 'apps/employer-experience/pages/EmployerDetails/Benefits/types';

export const initialState = {
  ready: false,
  employerBenefitDocumentsReady: false,
  isLoading: {
    employerBenefitsByPlanId: {},
    plansByBenefitClassId: {},
  },
};

const getUpdatedIsLoadingSlice = (isLoadingState, type, id, isLoading) => ({
  [type]: {
    ...isLoadingState[type],
    [id]: isLoading,
  },
});

const updateIsLoading = (state, type, id, isLoading) => ({
  ...state,
  isLoading: {
    ...state.isLoading,
    ...getUpdatedIsLoadingSlice(state, type, id, isLoading),
  },
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerBenefitsUIReducer = (state = initialState, action) => {
  switch (action?.type) {
    case EMPLOYER_BENEFITS_INIT.SUCCEEDED:
      return { ...state, ready: true, employerBenefitDocumentsReady: true };
    case GET_EMPLOYER_BENEFITS.STARTED:
      return updateIsLoading(
        state,
        'employerBenefitsByPlanId',
        action?.meta?.benefitPlanId,
        true,
      );
    case GET_EMPLOYER_BENEFITS.SUCCEEDED:
    case GET_EMPLOYER_BENEFITS.ERRORED:
    case GET_EMPLOYER_BENEFITS.CANCELLED:
      return updateIsLoading(
        state,
        'employerBenefitsByPlanId',
        action?.meta?.benefitPlanId,
        false,
      );
    case GET_EMPLOYER_BENEFIT_PLANS.STARTED:
      return updateIsLoading(
        state,
        'plansByBenefitClassId',
        action?.meta?.benefitClassId,
        true,
      );
    case GET_EMPLOYER_BENEFIT_PLANS.SUCCEEDED:
    case GET_EMPLOYER_BENEFIT_PLANS.ERRORED:
    case GET_EMPLOYER_BENEFIT_PLANS.CANCELLED:
      return updateIsLoading(
        state,
        'plansByBenefitClassId',
        action?.meta?.benefitClassId,
        false,
      );
    case EMPLOYER_BENEFITS_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
