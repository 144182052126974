import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getUserBenefitForm = ({ userId, formId, groupId, planId } = {}) =>
  call(websocketFetch, 'get_user_benefit_form', {
    user_id: userId,
    group_id: groupId,
    form_id: formId,
    plan_id: planId,
  });
