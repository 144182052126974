import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $RefParser from 'json-schema-ref-parser';
import { get, cloneDeep } from 'lodash';
import { Formik } from 'formik';
// import { buildYup } from 'json-schema-to-yup';
import { JSONSchemaPropType } from './prop-types';
import { ROOT_REF, SCHEMA_ENTITY_TYPES } from './json-schema-form.constants';

const isObjectType = schemaProp =>
  schemaProp.type === SCHEMA_ENTITY_TYPES.OBJECT;
/**
 * Takes a JSON Pointer and returns a path string usable in lodash functions like get and set.
 *
 * @param {string} jsonPointer - a JSON Pointer (rfc 6901) https://json-schema.org/latest/relative-json-pointer.html
 *
 * @return {string} a Lodash friendly object path string (https://lodash.com/docs/4.17.15#get)
 *
 * @example
 *    lodashPathFromJSONPointer("#/Foo/Bar"); //=> "foo.bar"
 */
export const lodashPathFromJSONPointer = (jsonPointer = '') =>
  jsonPointer
    .replace(/^#/, '') // remove leading "#"
    .replace(/^\//, '') // remove leading "#"
    .replace(/\//g, '.'); // replace "/" with "."

export const defaultSchemaDefinition = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: SCHEMA_ENTITY_TYPES.OBJECT,
  properties: {},
};

/**
 * Given a schema and a $ref JSON Pointer, returns the desired definition from the schema
 *
 * @param {object} schema - a JSON Schema containing a schema definition (https://json-schema.org)
 * @param {string} $ref - a JSON Pointer path to the desired schema definition (https://json-schema.org/latest/relative-json-pointer.html)
 *
 * @returns {object} the desired schema definition (or the root definition if no $ref)
 */
export const getSchemaDefinitionForRef = (schema, $ref) => {
  if (!schema) return defaultSchemaDefinition;
  if (!$ref || $ref === ROOT_REF) return schema;
  return get(schema, lodashPathFromJSONPointer($ref));
};

const mapDataToSchemaProperties = (properties = {}, data = {}) => {
  const mappedData = {};
  Object.keys(properties).forEach(
    // eslint-disable-next-line no-return-assign
    key => (mappedData[key] = data[key]),
  );
  return mappedData;
};

/**
 * @function enhanceSchemaProperties when we dereference the schema, we lose those valuable $ref properties
 * that are used in the form to conditionally render special UI elements (i.e. MoneyInput). Will
 * recursively iterate through any child properties!
 *
 * @param {object} properties the dereferenced schema properties
 * @param {object} schemaDef the schema scoped to the current $ref
 * @param {object} rootSchema  the root schema
 * @param {object} schemaMetadata some
 *
 * @returns {object} the passed dereferenced schema with metadata and properties enhanced with $refs
 */
export const enhanceSchemaProperties = (
  properties = {},
  schemaDef = {},
  rootSchema = {},
  schemaMetadata = {},
) => {
  return Object.entries(properties).reduce(
    (dereferenced, [propertyName, value]) => {
      let fromSchemaDef = schemaDef.properties
        ? schemaDef.properties[propertyName]
        : null;
      const { $ref } = fromSchemaDef || {};
      if ($ref) {
        fromSchemaDef = {
          ...fromSchemaDef,
          ...getSchemaDefinitionForRef(rootSchema, $ref),
        };
      }

      return {
        ...dereferenced,
        [propertyName]: {
          ...value,
          $ref,
          meta: schemaMetadata[$ref],
          ...(value.properties && {
            properties: enhanceSchemaProperties(
              value.properties,
              fromSchemaDef,
              rootSchema,
              schemaMetadata,
            ),
          }),
        },
      };
    },
    {},
  );
};

/**
 * @typedef {object} JSONSchemaFormContainerProps
 * @prop {string} $ref - a JSON Pointer to the schemaDefinition for the entity in JSON
 *       path format. Used to search the schema prop (https://json-schema.org/latest/relative-json-pointer.html)
 * @prop {element} children
 * @prop {object} data - the data for the entity
 * @prop {object} schema - JSON Schema containing the entity definition. See https://json-schema.org
 *
 * @extends {Component<Props>}
 */
/**
 * @typedef {object} JSONSchemaFormContainerReturnProps
 * @prop {string} $ref - (pass-through prop) the JSON Path to the schema definition
 * @prop {object} data - the keys from the schemaDefinition.properties
 *       mapped to the value of the same key in JSONSchemaFormContainerProps.data or null
 * @prop {object} schema - (pass-through prop) JSON Schema containing the entity definition
 * @prop {object} schemaDefinition - the specific schema definition selected out
 *       from schema via the $ref path
 *
 * @extends {Component<Props>}
 */
/**
 * Takes a schema, a $ref to a definition in that schema and some data and maps
 * it to the necessary Formik props to render a Formik form. This container only
 * maps a single level of a schema definition: it does not handle child schemas
 *
 * @example
 *
 *    <JSONSchemaFormContainer
 *      $ref="#/definitions/Example"
 *      schema={someJSONSchema}
 *      data={someValueObject}
 *    >
 *      <Form />
 *    </JSONSchemaFormContainer>
 *
 * @param {JSONSchemaFormContainerProps} props
 *
 * @returns {JSONSchemaFormContainerReturnProps} an element with necessary props
 *          to render a Formik form for the given schema and data.
 */
export const JSONSchemaFormContainer = ({
  activeNode,
  $ref,
  data,
  schema,
  schemaMetadata,
  children,
  ...props
}) => {
  const [schemaDefinition, setSchemaDefinition] = useState();
  // const [validationSchema, setValidationSchema] = useState();

  useEffect(() => {
    async function getDereferencedSchema(schemaToDereference) {
      // $RefParser will mutate the schema we pass it so we need to make a copy since
      // we still need the dereferenced schema for the enhanceDereferencedSchema step

      const schemaCopy = cloneDeep({
        ...schemaToDereference,
        definitions: schema.definitions,
      });

      // The $ref field borks buildYup so we need to build the validation schema before adding that back in

      const dereferencedSchema = await $RefParser.dereference(schemaCopy);
      // setValidationSchema(buildYup(dereferencedSchema));

      // The dereferenced schema is missing the $ref property we need to handle rendering custom components
      // in the form. We also want to decorate these properties with schemaMetadata
      const enhancedDereferencedSchemaProperties = enhanceSchemaProperties(
        dereferencedSchema.properties,
        schemaToDereference,
        schema,
        schemaMetadata,
      );

      setSchemaDefinition({
        ...dereferencedSchema,
        properties: enhancedDereferencedSchemaProperties,
      });
    }

    const referencedSchema = getSchemaDefinitionForRef(schema, $ref);

    if (isObjectType(referencedSchema)) {
      getDereferencedSchema(referencedSchema);
    } else {
      setSchemaDefinition(referencedSchema);
    }
  }, [schema, $ref, schemaMetadata]);

  if (schemaDefinition) {
    const mappedData = isObjectType(schemaDefinition)
      ? mapDataToSchemaProperties(schemaDefinition.properties, data)
      : data;
    const meta =
      schemaMetadata[
        schemaDefinition.type === SCHEMA_ENTITY_TYPES.ARRAY &&
        schemaDefinition.items.$ref
          ? schemaDefinition.items.$ref
          : $ref
      ];

    const nodeName = activeNode?.name ?? '';
    const metaTitle = meta?.title;
    const schemaTitle = schemaDefinition?.title;
    const formTitle =
      schemaTitle || (metaTitle && !nodeName.includes('New'))
        ? `${metaTitle}: ${nodeName}`
        : nodeName;

    const formProps = {
      ...props,
      activeNode,
      $ref,
      data: mappedData,
      schema,
      schemaDefinition,
      schemaMetadata: meta,
      formTitle,
      metaTitle,
      // ...(validationSchema && { validationSchema }),
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      <Formik enableReinitialize initialValues={formProps.data} {...formProps}>
        {({ ...formBag }) => {
          return children && typeof children === 'function'
            ? children({ ...formBag, ...formProps })
            : null;
        }}
      </Formik>
    );
  }

  return <div>Loading...</div>;
};

JSONSchemaFormContainer.propTypes = {
  activeNode: PropTypes.shape({
    name: PropTypes.string,
  }),
  $ref: PropTypes.string,
  children: PropTypes.element,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  schema: JSONSchemaPropType,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  schemaMetadata: PropTypes.objectOf(PropTypes.any),
};

JSONSchemaFormContainer.defaultProps = {
  activeNode: {},
  schemaMetadata: {},
  $ref: undefined,
  children: null,
  data: undefined,
  schema: undefined,
};
