import React from 'react';
import { Box } from '@leagueplatform/genesis-commons';

export const Divider = () => (
  <Box
    borderStyle="solid"
    borderWidth={0.5}
    borderColor="onSurface.border.subdued"
    marginTop="one"
  />
);
