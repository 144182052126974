import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const FieldWrapper = ({ span, fieldName, children }) => (
  <Grid.Column
    width={span}
    className={`layout-field layout-field-${fieldName}`}
  >
    {children}
  </Grid.Column>
);

FieldWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fieldName: PropTypes.string.isRequired,
  span: PropTypes.number.isRequired,
};

export default FieldWrapper;
