import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  BodyOne,
  HeadingTwo,
  DangerButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';

class RemoveBenefitPlanConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.onRemoveBenefitPlan = this.onRemoveBenefitPlan.bind(this);
    this.close = this.close.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- FIXME: automatically added for existing issue
  componentWillReceiveProps({ open }) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    if (this.state.open !== open) {
      this.setState({ open });
    }
  }

  onRemoveBenefitPlan() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.removeBenefitPlan();
  }

  close() {
    this.setState({ open: false });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.close();
  }

  render() {
    const { open } = this.state;
    const { benefitPlanName, isLastBenefitPlan } = this.props;

    return (
      <div>
        <Modal open={open} onClose={this.close}>
          <HeadingTwo>
            <FormattedMessage
              id="REMOVE_BENEFIT_PLAN_CONFIRMATION_HEADER"
              values={{ benefitPlanName }}
            />
          </HeadingTwo>
          <ModalContent>
            {!isLastBenefitPlan ? (
              <BodyOne>
                <FormattedMessage
                  id="REMOVE_BENEFIT_PLAN_CONFIRMATION_COPY"
                  values={{ benefitPlanName }}
                />
              </BodyOne>
            ) : (
              <BodyOne>
                <FormattedMessage
                  id="REMOVE_LAST_BENEFIT_PLAN_ERROR_COPY"
                  values={{ benefitPlanName }}
                />
              </BodyOne>
            )}
          </ModalContent>
          <ModalButtons>
            <SecondaryButton onClick={this.close}>
              <FormattedMessage id="REMOVE_BENEFIT_PLAN_CONFIRMATION_CANCEL_BTN" />
            </SecondaryButton>
            {!isLastBenefitPlan ? (
              <DangerButton onClick={this.onRemoveBenefitPlan} marginLeft="one">
                <FormattedMessage id="REMOVE_BENEFIT_PLAN_CONFIRMATION_REMOVE_BTN" />
              </DangerButton>
            ) : null}
          </ModalButtons>
        </Modal>
      </div>
    );
  }
}

RemoveBenefitPlanConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  benefitPlanName: PropTypes.string.isRequired,
  removeBenefitPlan: PropTypes.func.isRequired,
  isLastBenefitPlan: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default RemoveBenefitPlanConfirmation;
