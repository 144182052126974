/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { useState, useEffect, useCallback } from 'react';
import { KEY } from '@leagueplatform/web-common';

export const useTitleDropdown = (
  tabArray,
  mainButtonRef,
  defaultTabIndex = 0,
) => {
  const [focusedTabIndex, setFocusedTabIndex] = useState(defaultTabIndex);
  const [dropdownIsOpen, setIsDropdownOpen] = useState(false);

  // inside trap below
  const indexUp = useCallback(() => {
    if (focusedTabIndex !== tabArray.length - 1) {
      setFocusedTabIndex(focusedTabIndex + 1);
    } else {
      setFocusedTabIndex(0);
    }
  }, [focusedTabIndex, tabArray]);

  const indexDown = useCallback(() => {
    if (focusedTabIndex !== 0) {
      setFocusedTabIndex(focusedTabIndex - 1);
    } else {
      setFocusedTabIndex(tabArray.length - 1);
    }
  }, [focusedTabIndex, tabArray]);

  const handleArrowKeyDown = useCallback(
    ({ key }) => {
      switch (key) {
        case KEY.ARROW_UP:
        case KEY.ARROW_LEFT:
          indexDown();
          break;
        case KEY.ARROW_DOWN:
        case KEY.ARROW_RIGHT:
          indexUp();
          break;
        case KEY.ENTER:
        case KEY.ESC:
        case KEY.TAB:
          closeDropdown();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: automatically added for existing issue
    [indexUp, indexDown],
  );

  // container toggle and outside listeners below
  const closeDropdown = useCallback(() => {
    setIsDropdownOpen(false);
    mainButtonRef.current.focus();
  }, [mainButtonRef]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!dropdownIsOpen);
    if (dropdownIsOpen) mainButtonRef.current.focus();
  };

  const toggleDropdownKeyDown = event => {
    setFocusedTabIndex(-1);
    const { key } = event;
    if (key === KEY.ESC || key === KEY.TAB) closeDropdown();
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      if (dropdownIsOpen) {
        closeDropdown();
      }
    };

    const handleOutsideKey = e => {
      const { key } = e;
      if (dropdownIsOpen && (key === KEY.ESC || key === KEY.TAB)) {
        closeDropdown();
      }
      const currentEl = document.activeElement === mainButtonRef.current;
      if (currentEl && key === KEY.ARROW_DOWN) {
        setIsDropdownOpen(true);
        handleArrowKeyDown(e);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    window.addEventListener('keyup', handleOutsideKey);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('keyup', handleOutsideKey);
    };
  }, [closeDropdown, dropdownIsOpen, handleArrowKeyDown, mainButtonRef]);

  return {
    handleArrowKeyDown,
    focusedTabIndex,
    toggleDropdownKeyDown,
    dropdownIsOpen,
    toggleDropdown,
  };
};
