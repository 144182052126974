import { BenefitStep } from 'apps/employer-experience/pages/EmployerDetails/enrollment-design/enrollment-experience-config/enrollment-design-options.types';
// import { LAYOUT_TYPES } from "apps/enrollment/enrollment.constants";
import {
  Benefit,
  BenefitSelection,
  BenefitSet,
  EnrollmentStep,
} from 'common/types/enrollment-types';
import { keyBy } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { withLinkedBenefitSets } from 'common/benefit-sets';

const getBenefitSetIdsFromStep = (step: BenefitStep) => {
  return step.sets.reduce((setArr: string[], set: any) => {
    if (set.sets && set.sets.length) {
      setArr.push(...set.sets.map((groupSet: BenefitSet) => groupSet.id));
    } else setArr.push(set.id);

    return setArr;
  }, []);
};

/* This function support creating consumable steps from steps we get from 'get_enrollment_steps' and support all layouts
  We can replace the currently used getEnrollmentSteps function with this one once we migrate all the pickers to use GE
 */
// @TODO - rename this function to getEnrollmentSteps after GE release
export function getGESteps(
  benefitSets: Array<BenefitSet> = [],
  benefitSelections: Array<BenefitSelection> = [],
  enrollmentSteps: Array<any> = [],
): Array<EnrollmentStep> {
  const benefitSetsById = keyBy(benefitSets, 'id');
  const benefitSelectionById = keyBy(benefitSelections, 'benefit_id');

  const genericEnrollmentSteps = enrollmentSteps.reduce(
    (steps: any, step: any) => {
      const stepBenefitSetsById = getBenefitSetIdsFromStep(step);
      const stepBenefitSets = benefitSets.filter((set: any) =>
        stepBenefitSetsById.includes(set.id),
      );
      const stepHasEligibleBenefits = stepBenefitSets.some(set =>
        set.benefits.some(
          (benefit: Benefit) =>
            (benefitSelectionById?.[benefit.benefit_id]?.ineligible ??
              false) !== true,
        ),
      );

      if (stepBenefitSets.length > 0 && stepHasEligibleBenefits) {
        const enhancedBenefitSets = stepBenefitSets.map(set =>
          withLinkedBenefitSets(set, benefitSetsById),
        );

        const { title, name, icon, url } = step;

        return {
          ...steps,
          [step.url]: {
            id: url,
            url,
            translatedName: title, // Used for coverage summary benefit set title
            name, // Displayed step name in side navigation
            title, // Displayed as title on a step screen
            icon,
            benefitSets: enhancedBenefitSets,
          },
        };
      }
      return steps;
    },
    {},
  );

  return { ...genericEnrollmentSteps };
}
