import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Box,
  PrimaryButton,
  SecondaryButton,
  Flex,
  BodyOne,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';

import { useIntl } from '@leagueplatform/locales';

import { PendingFeesTable, ConfirmIssueInvoiceModal } from './components';

const EmptyState = () => {
  const { formatMessage } = useIntl();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      backgroundColor="surface.background.secondary"
      height={120}
    >
      <SubtitleOne color="onSurface.text.primary">
        {formatMessage({ id: 'NO_PENDING_FEES' })}
      </SubtitleOne>
    </Flex>
  );
};

export const PendingFeesComponent = ({
  pendingFees,
  removePendingFee,
  issueAdhocInvoice,
  getAdhocInvoicePreview,
  billingDivisions,
}) => {
  const { formatMessage } = useIntl();
  const [showInvoiceConfirmationModal, setShowInvoiceConfirmationModal] =
    useState(false);

  const issueInvoice = () => {
    issueAdhocInvoice();
    setShowInvoiceConfirmationModal(false);
  };

  return (
    <Box>
      <BodyOne marginBottom="two">
        {formatMessage({ id: 'PENDING_FEES_INFO' })}
      </BodyOne>
      <Box>
        {isEmpty(pendingFees) ? (
          <EmptyState />
        ) : (
          <Box>
            <PendingFeesTable
              records={pendingFees}
              removeFee={removePendingFee}
              billingDivisions={billingDivisions}
            />
            <Flex marginTop="oneAndHalf">
              <SecondaryButton
                marginRight="one"
                onClick={getAdhocInvoicePreview}
              >
                {formatMessage({ id: 'PREVIEW_INVOICE' })}
              </SecondaryButton>
              <PrimaryButton onClick={setShowInvoiceConfirmationModal}>
                {formatMessage({ id: 'ISSUE_INVOICE' })}
              </PrimaryButton>
            </Flex>
          </Box>
        )}
      </Box>
      {showInvoiceConfirmationModal && (
        <ConfirmIssueInvoiceModal
          fees={pendingFees}
          onSubmit={issueInvoice}
          onClose={() => setShowInvoiceConfirmationModal(false)}
        />
      )}
    </Box>
  );
};

PendingFeesComponent.propTypes = {
  pendingFees: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)).isRequired,
  billingDivisions: PropTypes.arrayOf(PropTypes.shape(PropTypes.any))
    .isRequired,
  removePendingFee: PropTypes.func.isRequired,
  issueAdhocInvoice: PropTypes.func.isRequired,
  getAdhocInvoicePreview: PropTypes.func.isRequired,
};

export default PendingFeesComponent;
