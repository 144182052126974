import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Header, Grid } from 'semantic-ui-react';

const Group = ({ title, children }) => (
  <div className={`layout-group layout-group-${title}`}>
    {title === undefined ? (
      <Header as="h4" className="layout-group-header">
        Primary Fields
      </Header>
    ) : (
      <Header
        as="h4"
        className="layout-group-header"
        style={{ marginBottom: 0 }}
      >
        {title}
      </Header>
    )}
    <Grid stackable className="layout-group-container">
      {children}
    </Grid>
    <Divider hidden />
  </div>
);

Group.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};

Group.defaultProps = {
  title: undefined,
};

export default Group;
