import { Backdrop, ClickEater, media } from '@leagueplatform/ui-kit';
import {
  DESKTOP_NAV_HEIGHT,
  MOBILE_NAV_HEIGHT,
} from '../navigation/navigation.constants';
import styled from 'styled-components';

export const StyledBackdrop = styled(Backdrop)`
  top: ${DESKTOP_NAV_HEIGHT};
  height: calc(100vh - ${DESKTOP_NAV_HEIGHT});
  ${media.belowDesktop`
    height: calc(100vh - ${MOBILE_NAV_HEIGHT});
    top: ${MOBILE_NAV_HEIGHT};
  `};
`;

export const FullHeightClickEater = styled(ClickEater)`
  height: 100%;
  display: flex;
  align-items: center;
`;
