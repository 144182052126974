import { each, get, has } from 'lodash';
import { lifecycle, withProps } from 'recompose';
import {
  TAXES_BY_PROVINCE,
  TAX_TYPES,
  isDateWithinRange,
} from '@leagueplatform/web-common';
import { getPolicyName, toNumber } from '../settlement.utilities';

export const withAllocationHelpers = withProps(
  ({ type, benefitNames, values, setFieldValue }) => ({
    name: getPolicyName(type, benefitNames),
    taxTypes: TAXES_BY_PROVINCE[values.province],
    getTaxValue: taxType =>
      get(values, `policy_allocations.${type}.taxes.${taxType}`),
    setTaxValue: (name, value) =>
      setFieldValue(
        `policy_allocations.${type}.taxes.${name}`,
        toNumber(value),
      ),
    getAmountApproved: () =>
      get(values, `policy_allocations.${type}.amount_approved`),
    setAmountApproved: value =>
      setFieldValue(
        `policy_allocations.${type}.amount_approved`,
        toNumber(value),
      ),
    getCategoryId: () => get(values, `policy_allocations.${type}.category_id`),
    setCategoryId: value =>
      setFieldValue(`policy_allocations.${type}.category_id`, value),
  }),
);

export const withTaxReset = lifecycle({
  componentWillReceiveProps(nextProps) {
    const { type, setFieldValue, values } = this.props;
    const { values: nextValues } = nextProps;
    const { province } = values;
    const nextProvince = nextValues.province;
    if (province === nextProvince) return;
    each(TAX_TYPES, taxType => {
      const field = `policy_allocations.${type}.taxes.${taxType}`;
      if (has(values, field)) setFieldValue(field, null);
    });
  },
});

export const withIsWithinGracePeriod = withProps(
  ({ graceStartDate, graceEndDate, dateCreated }) => ({
    isWithinGracePeriod: isDateWithinRange(
      graceStartDate,
      graceEndDate,
      dateCreated,
    ),
  }),
);
