import React from 'react';
import {
  HeadingFour,
  Label,
  LabelText,
  genesisStyled,
  css,
  Box,
} from '@leagueplatform/genesis-commons';
import {
  StyledDayPickerInput,
  DownloadLinkCSV,
} from 'apps/employer-experience/ui-kit';
import '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { format } from 'date-fns';

const DATE_FORMAT = 'MMMM d, yyyy';

const StyledDateInput = genesisStyled(StyledDayPickerInput)(
  css({
    backgroundColor: 'surface.background.primary',
    color: 'onSurface.text.primary',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'onSurface.border.default',
    fontFamily: 'GenesisMarkOffcPro',
    borderRadius: 'small',
    padding: 'one',
    lineHeight: 'body2',
    width: '100%',
    marginTop: 'half',

    '&[disabled=""]': {
      backgroundColor: 'surface.background.secondary',
      color: 'interactive.action.disabled',
    },
  }),
);

const DateBox = genesisStyled(Box)`
  &&& .DayPickerInput {
    width: 100%;
  }
`;

export const DateInputGroup = ({
  label,
  fieldValue,
  setFieldValue,
  values,
  disabled,
}) => {
  return (
    <Label paddingBottom="one">
      <LabelText>{label}</LabelText>
      <DateBox>
        <StyledDateInput
          data-testid={fieldValue}
          disabled={disabled}
          format={DATE_FORMAT}
          onDayChange={value =>
            setFieldValue(fieldValue, new Date(value).toISOString())
          }
          value={
            values[fieldValue]
              ? format(new Date(values[fieldValue]), DATE_FORMAT)
              : ''
          }
          autoComplete="off"
          readOnly
          placeholder={DATE_FORMAT}
        />
      </DateBox>
    </Label>
  );
};
DateInputGroup.propTypes = {
  label: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
};
DateInputGroup.defaultProps = {
  disabled: false,
};

export const NetSuiteInvoiceReportPresenter = ({
  isLoading,
  contentId,
  handleSubmit,
  setFieldValue,
  values,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <HeadingFour paddingTop="three" marginY="one">
        {formatMessage({ id: 'NETSUITE_INVOICE_REPORT' })}
      </HeadingFour>
      <DateInputGroup
        label={formatMessage({ id: 'NETSUITE_INVOICE_REPORT_FROM_DATE' })}
        fieldValue="fromDate"
        setFieldValue={setFieldValue}
        values={values}
        disabled={isLoading}
      />
      <DateInputGroup
        label={formatMessage({ id: 'NETSUITE_INVOICE_REPORT_TO_DATE' })}
        fieldValue="toDate"
        setFieldValue={setFieldValue}
        values={values}
        disabled={isLoading}
      />
      <LoaderButton
        data-testid="loader"
        loading={isLoading}
        disabled={!values.fromDate || !values.toDate}
        primary
        onClick={handleSubmit}
        type="submit"
      >
        {formatMessage({ id: 'REQUEST_REPORT' })}
      </LoaderButton>
      {contentId && (
        <>
          {formatMessage({ id: 'DOWNLOAD' })}
          <DownloadLinkCSV contentId={contentId} />
        </>
      )}
    </Box>
  );
};
NetSuiteInvoiceReportPresenter.propTypes = {
  contentId: PropTypes.string,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
  }).isRequired,
};
NetSuiteInvoiceReportPresenter.defaultProps = {
  contentId: null,
  isLoading: false,
};
