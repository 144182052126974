export const makeRef = entityName => `#/definitions/${entityName}`;
export const makePropertyRef = propertyName => `#/properties/${propertyName}`;

export const ROOT_REF = '#';
export const MONEY_REF = makeRef('Money');

export const ARRAY = 'array';
export const OBJECT = 'object';
export const STRING = 'string';
export const NUMBER = 'number';
export const INTEGER = 'integer';
export const FLOAT = 'float';
export const BOOLEAN = 'boolean';

export const SCHEMA_ENTITY_TYPES = {
  OBJECT,
  ARRAY,
  STRING,
  NUMBER,
  INTEGER,
  FLOAT,
  BOOLEAN,
};
