/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Icon } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';
import { formatCurrency } from '@leagueplatform/web-common';

class SubscriptionRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows,
      lower: formatCurrency(props.row.lower),
      upper:
        props.row.upper !== 'Infinity'
          ? formatCurrency(props.row.upper)
          : props.row.upper,
      rate: formatCurrency(props.row.rate),
      id: props.id,
      isChecked: props.isChecked,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(data) {
    const { id } = this.state;
    const rows = cloneDeep(this.state.rows);
    const objKey = Object.keys(data);
    if (data[objKey] !== '') {
      rows[id][objKey] = data[objKey];
    }
    this.setState({
      rows,
      ...data,
    });
  }

  handleBlur() {
    const { id } = this.state;
    const rows = cloneDeep(this.state.rows);
    // eslint-disable-next-line no-restricted-globals -- FIXME: automatically added for existing issue
    if (rows[id].rate < 0 || isNaN(parseFloat(rows[id].rate))) {
      rows[id].rate = '0'; // by default change to 0
    }
    if (id === 0 && rows[id].lower === '0') {
      // First row's lower must be at least 0.01
      rows[id].lower = formatCurrency(0.01);
    }
    if (id > 0) {
      // Check that the current lower is higher than prev row's lower
      if (rows[id].lower > rows[id - 1].lower) {
        // Make prev row's upper 0.01 less than current row's lower
        rows[id - 1].upper = formatCurrency(parseFloat(rows[id].lower) - 0.01);
      }
    }
    this.setState({ rows });
    this.props.handleUpdate(rows);
  }

  render() {
    const { rows, lower, upper, rate, id, isChecked } = this.state;
    const { error } = this.props;
    return (
      <Grid.Row>
        <Grid.Column width={3}>
          <Form.Input
            icon="dollar"
            iconPosition="left"
            step="any"
            value={lower}
            onChange={(e, { value }) => this.handleChange({ lower: value })}
            onBlur={() => this.handleBlur()}
            readOnly={!isChecked}
            name="lower"
            error={error}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <Form.Input
            icon="dollar"
            iconPosition="left"
            value={upper}
            readOnly
            name="upper"
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <Form.Input
            icon="dollar"
            iconPosition="left"
            step="any"
            value={rate}
            onChange={(e, { value }) => this.handleChange({ rate: value })}
            onBlur={() => this.handleBlur()}
            readOnly={!isChecked}
            name="rate"
          />
        </Grid.Column>
        <Grid.Column width={3}>
          {rows.length > 1 && isChecked ? (
            <Icon
              name="trash outline"
              link
              size="large"
              onClick={e => this.props.removeRow(e, id)}
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
    );
  }
}

SubscriptionRow.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      lower: PropTypes.string,
      upper: PropTypes.string,
      rate: PropTypes.string,
    }),
  ).isRequired,
  row: PropTypes.shape({
    lower: PropTypes.string,
    upper: PropTypes.string,
    rate: PropTypes.string,
  }).isRequired,
  id: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

SubscriptionRow.defaultProps = {
  error: false,
};

export default SubscriptionRow;
