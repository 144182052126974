import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box } from '@leagueplatform/genesis-commons';
import { Button } from 'apps/employer-experience/ui-kit';
import { UpdateContentModal } from '../update-content-modal.view';
import { HtmlContentEditor } from '../HtmlContentEditor/html-content-editor.view';

import { Title, ButtonContainer, EmptyState } from '../common-components';

const UsageInstructions = ({
  providerBenefit,
  code,
  setCode,
  onClearUsageInstructions,
  onUpdateUsageInstructions,
  toggleModal,
  isOpen,
}) => {
  return !providerBenefit ? (
    <EmptyState>
      <FormattedMessage id="USAGE_INSTRUCTIONS_EMPTY" />
    </EmptyState>
  ) : (
    <>
      <Title>
        <FormattedMessage
          id="USAGE_INSTRUCTIONS_TITLE"
          values={{
            product: providerBenefit.full_name,
            carrier: providerBenefit.vendor_name,
          }}
        />
      </Title>
      <Box marginTop="two">
        <HtmlContentEditor value={code} onChange={setCode} />
      </Box>
      <ButtonContainer>
        <Button confirmModal primary onClick={onUpdateUsageInstructions}>
          <FormattedMessage id="UPDATE" />
        </Button>
        <Button confirmModal cancel onClick={toggleModal}>
          <FormattedMessage id="CLEAR" />
        </Button>
      </ButtonContainer>
      <UpdateContentModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        onUpdateContent={onClearUsageInstructions}
        modalHeader="USAGE_INSTRUCTIONS_ARE_YOU_SURE"
        confirmButtonId="CLEAR"
      >
        <FormattedMessage id="USAGE_INSTRUCTIONS_GONE_FOREVER" />
      </UpdateContentModal>
    </>
  );
};

export default UsageInstructions;

UsageInstructions.propTypes = {
  providerBenefit: PropTypes.shape({
    full_name: PropTypes.string,
    vendor_name: PropTypes.string,
  }),
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  onClearUsageInstructions: PropTypes.func.isRequired,
  onUpdateUsageInstructions: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

UsageInstructions.defaultProps = {
  providerBenefit: null,
};
