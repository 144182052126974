import { createRequestTypes } from 'common/websocket-redux';

export const GET_NOTIFICATIONS = createRequestTypes(
  'notifications/GET_NOTIFICATIONS',
);

export const GET_UNREAD_NOTIICATION_COUNT = createRequestTypes(
  'notifications/GET_UNREAD_NOTIICATION_COUNT',
);

export const SET_NOTIFICATIONS_READ = createRequestTypes(
  'notifications/SET_NOTIFICATIONS_READ',
);
export const LOAD_NOTIFICATIONS = 'notifications/LOAD_NOTIFICATIONS';
export const LOAD_NOTIFICATIONS_COUNT =
  'notifications/LOAD_NOTIFICATIONS_COUNT';
export const MARK_UNREAD_NOTIFICATIONS =
  'notifications/MARK_UNREAD_NOTIFICATIONS';
export const SET_FETCH_LIMIT = 'notifications/SET_FETCH_LIMIT';
