import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import { planOptionsSchema } from 'common/benefit-sets';
import { planOptionsValidationReducer } from './plan-options-validation/plan-options-validation.reducer';
import {
  GET_BENEFIT_PLAN_OPTIONS,
  SET_BENEFIT_PLAN_OPTIONS,
  CREATE_BENEFIT_PLAN_OPTIONS,
  GET_BENEFIT_PLAN_OPTIONS_SCHEMA,
  SET_CURRENT_PLAN_ID,
  SET_CURRENT_BUNDLE_PATH,
  FORM_VALUES_RESET,
  BENEFIT_PLAN_OPTIONS_NOT_FOUND,
} from './enrollment-config.types';

const initialState = { entities: {}, submissionError: null };

const updateEmployerBenefitSets = (state, { info }, responseSchema) => {
  const normalized = normalize(info, responseSchema);
  const {
    entities: { benefitSets, bundles, benefits, planOptions },
  } = normalized;
  return {
    entities: {
      ...state.entities,
      benefitSets: {
        ...state.entities.benefitSets,
        ...benefitSets,
      },
      benefits: {
        ...state.entities.benefits,
        ...benefits,
      },
      bundles: {
        ...state.entities.bundles,
        ...bundles,
      },
      planOptions: {
        ...state.entities.planOptions,
        ...planOptions,
      },
    },
  };
};

export const benefitPlanOptionsReducer = (
  state = initialState,
  { type, payload } = {},
) => {
  switch (type) {
    case GET_BENEFIT_PLAN_OPTIONS.STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_BENEFIT_PLAN_OPTIONS.SUCCEEDED:
      return {
        ...updateEmployerBenefitSets(
          state,
          {
            info: {
              planOptions: payload.info,
            },
          },
          planOptionsSchema,
        ),
        loading: false,
      };

    case GET_BENEFIT_PLAN_OPTIONS.ERRORED:
      return {
        ...state,
        loading: false,
      };

    case SET_BENEFIT_PLAN_OPTIONS.ERRORED:
      return {
        ...state,
        submissionError: payload.info.reason,
      };

    case FORM_VALUES_RESET:
      return {
        ...state,
        submissionError: null,
      };

    default:
      return state;
  }
};

const benefitPlanOptionsSchema = (state = {}, { type, payload } = {}) => {
  switch (type) {
    case GET_BENEFIT_PLAN_OPTIONS_SCHEMA.SUCCEEDED:
      return payload.info;

    default:
      return state;
  }
};

const currentPlanId = (state = null, { type, payload } = {}) => {
  switch (type) {
    case SET_CURRENT_PLAN_ID:
      return payload.planId;
    default:
      return state;
  }
};

const currentBundlePath = (state = null, { type, payload } = {}) => {
  switch (type) {
    case SET_CURRENT_BUNDLE_PATH:
      return payload.bundlePath;
    default:
      return state;
  }
};

const missingPlanOptions = (state = [], { type, payload, meta } = {}) => {
  switch (type) {
    case BENEFIT_PLAN_OPTIONS_NOT_FOUND:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const missingSet = new Set([...state, payload.planId]);
      return Array.from(missingSet);
    case CREATE_BENEFIT_PLAN_OPTIONS.SUCCEEDED:
      return state.filter(planId => planId !== meta.plan_id);
    default:
      return state;
  }
};

export const enrollmentConfigReducer = combineReducers({
  planOptionsValidation: planOptionsValidationReducer,
  benefitPlanOptionsSchema,
  currentPlanId,
  currentBundlePath,
  missingPlanOptions,
});
