import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Formik, Form } from 'formik';
import {
  Box,
  TextField,
  PrimaryButton,
  LabelText,
  Flex,
  Label,
  ErrorMessage,
  TextInput,
  BodyOneSecondary,
  Spinner,
} from '@leagueplatform/genesis-commons';
import styled from 'styled-components';
import { leagueFont } from '@leagueplatform/ui-kit';
import { DATE_FORMAT } from 'common/adaptive-forms';
import { useFormUserCertification } from './use-form-medical-waiver.hook';
import PropTypes from 'prop-types';
import formMedicalWaiverSchema from './form-medical-wavier.schema.json';
import { useValidationSchema } from 'common/json-schema-form';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { getFormattedDate } from 'apps/employer-experience-v2/employee-profile/utils/get-formatted-date.util';

const DayPickerWrapper = styled.div`
  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }

  .DayPickerInput input {
    padding: 0.82rem;
    width: 100%;
    ${leagueFont()};
  }
`;

export const CertificationCredits = ({ userId, employeeGroupId }) => {
  const { formatMessage } = useIntl();
  const {
    onSubmitSetUserCertification,
    userCertificationData,
    isLoading,
    isError,
  } = useFormUserCertification(userId, employeeGroupId);

  const validationSchema = useValidationSchema({
    schema: formMedicalWaiverSchema,
  });

  const initialValues = {
    userId,
    certificationType: 'medical_waiver_credit',
    effectiveDate: userCertificationData?.effective_date || '',
    expiryDate: userCertificationData?.expiry_date || '',
    setAmount: userCertificationData?.amount?.F,
  };

  const checkErrors = errors => Object.keys(errors).length > 0;

  if (isLoading) return <Spinner isLoading={isLoading} />;

  if (isError)
    return (
      <BodyOneSecondary>
        {formatMessage({ id: 'PLEASE_TRY_AGAIN_SHORTLY' })}
      </BodyOneSecondary>
    );

  return (
    <Box>
      <Formik
        marginTop="two"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmitSetUserCertification(values);
        }}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form>
            <Box>
              <TextField
                marginBottom="half"
                name="certificationType"
                label={formatMessage({ id: 'CERTIFICATION_TYPE' })}
                onChange={handleChange}
                placeholder="Medical Waiver Credit"
                disabled
              />
              <Label>
                <Flex justifyContent="space-between" marginY="oneAndHalf">
                  <Label minWidth="49%" zIndex="1">
                    <LabelText>
                      {formatMessage({ id: 'EFFECTIVE_DATE' })}
                    </LabelText>
                    <DayPickerWrapper>
                      <DayPickerInput
                        name="effectiveDate"
                        value={getFormattedDate(values.effectiveDate)}
                        component={TextInput}
                        format={DATE_FORMAT}
                        onDayChange={date => {
                          let dateISO = '';
                          if (date) {
                            dateISO = date.toISOString();
                          }
                          setFieldValue('effectiveDate', dateISO);
                        }}
                      />
                    </DayPickerWrapper>
                    {touched.effectiveDate && errors.effectiveDate && (
                      <ErrorMessage>{errors.effectiveDate}</ErrorMessage>
                    )}
                  </Label>
                  <Label minWidth="49%" zIndex="1">
                    <LabelText>
                      {formatMessage({ id: 'CREDITS_EXPIRY_DATE' })}
                    </LabelText>
                    <DayPickerWrapper minWidth="49%">
                      <DayPickerInput
                        name="expiryDate"
                        value={getFormattedDate(values.expiryDate)}
                        component={TextInput}
                        format={DATE_FORMAT}
                        onDayChange={date => {
                          let dateISO = '';
                          if (date) {
                            dateISO = date.toISOString();
                          }
                          setFieldValue('expiryDate', dateISO);
                        }}
                      />
                      {new Date(values.expiryDate) <=
                        new Date(values.effectiveDate) &&
                      !!values.expiryDate ? (
                        <ErrorMessage>
                          {formatMessage({ id: 'VALIDATION_ERROR_MSG' })}
                        </ErrorMessage>
                      ) : (
                        ''
                      )}
                      {touched.expiryDate && errors.expiryDate && (
                        <ErrorMessage>{errors.expiryDate}</ErrorMessage>
                      )}
                    </DayPickerWrapper>
                  </Label>
                </Flex>
              </Label>
            </Box>

            <TextField
              marginBottom="half"
              name="setAmount"
              type="number"
              label={formatMessage({ id: 'AMOUNT' })}
              value={values?.setAmount}
              placeholder="$"
              onChange={evt => {
                setFieldValue('setAmount', evt.target.value);
              }}
            />
            {touched.setAmount && errors.setAmount && (
              <ErrorMessage>{errors.setAmount}</ErrorMessage>
            )}

            <PrimaryButton
              marginY="two"
              onClick={handleSubmit}
              type="submit"
              disabled={
                checkErrors(errors) ||
                (new Date(values.expiryDate) <=
                  new Date(values.effectiveDate) &&
                  !!values.expiryDate)
              }
            >
              {formatMessage({ id: 'SET_CREDITS' })}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

CertificationCredits.propTypes = {
  userId: PropTypes.string.isRequired,
  employeeGroupId: PropTypes.string.isRequired,
};
