import { createSelector, createStructuredSelector } from 'reselect';
import { intersection, join, keys, map } from 'lodash';
import {
  ASCENDING,
  DESCENDING,
  CLAIM_STATUS_TRANSLATIONS,
} from 'common/constants';

const selectApp = state => {
  return state.apps['employer-experience'];
};

export const selectPage = createSelector(selectApp, app => app.pages.claims);

export const selectData = createSelector(selectPage, page => page && page.data);

export const selectClaims = createSelector(selectData, data =>
  data ? data.allClaims.claims : [],
);

export const selectIsLoading = createSelector(
  selectPage,
  page => page.isLoading,
);

export const selectAgents = createSelector(selectPage, page => page.agents);

export const flattenClaim = claim => {
  let firstName = '';
  let lastName = '';
  let email = '';
  let userId = '';
  let agentName = '';
  if (claim.person) {
    if (claim.person.first_name) {
      firstName = claim.person.first_name;
    }
    if (claim.person.last_name) {
      lastName = claim.person.last_name;
    }
    if (claim.person.email) {
      email = claim.person.email;
    }
    if (claim.person.user_id) {
      userId = claim.person.user_id;
    }
  }
  if (claim.agent) {
    agentName = `${claim.agent.first_name} ${claim.agent.last_name}`;
  }
  return {
    claimId: claim.claim_id,
    referenceId: claim.reference_id,
    person: {
      fullName: `${firstName} ${lastName}`,
      email,
      userId,
    },
    status: claim.status,
    statusTranslationKey: CLAIM_STATUS_TRANSLATIONS[claim.status],
    submitted: new Date(claim.date_created),
    modified: new Date(claim.date_created),
    modifiedBy: agentName,
  };
};

export const selectTabularClaims = createSelector(selectClaims, claims =>
  map(claims, flattenClaim),
);

export const selectParams = createSelector(selectPage, claims => claims.params);

export const selectSortOrder = createSelector(
  selectParams,
  params => params.order,
);

export const selectSortField = createSelector(
  selectSortOrder,
  order => order.type,
);

export const getDirectionFromSortOrder = sortOrder => {
  return join(intersection([ASCENDING, DESCENDING], keys(sortOrder)));
};

export const selectSortDirection = createSelector(
  selectSortOrder,
  getDirectionFromSortOrder,
);

export const selectSortProps = createStructuredSelector({
  sortField: selectSortField,
  sortDirection: selectSortDirection,
});
