import { createSelector } from 'reselect';

export const selectUserSettings = state => state.userSettings?.settings;

/**
 * @function selectShowDependentsMenuItem Return `show_dependents_menu_item` value from user settings.
 * This controls should be used to control navigation to "Dependents" in member side.
 */
export const selectShowDependentsMenuItem = createSelector(
  selectUserSettings,
  settings => settings?.showDependentsMenuItem,
);

export const selectLifemarketUrl = createSelector(
  selectUserSettings,
  settings => settings?.marketplaceUrl,
);

export const selectTermsEmbeddedUrl = createSelector(
  selectUserSettings,
  settings => `${settings?.termsUrl}?isapp=1`,
);
