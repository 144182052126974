import { takeLatest, fork, all, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { updateFaqLinks as updateFaqLinksService } from 'common/services';
import { UPLOAD_FAQ_LINKS } from './upload-faq-links.action-types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* sendFeedbackToast({ error, payload }) {
  if (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: `Error! ${error.message || payload.info.reason}`,
    });
  } else {
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      text: `Success! FAQ links have been uploaded`,
    });
  }
}

export function* uploadFaqLinks(action) {
  const {
    payload: { contentId },
  } = action;
  yield request(
    UPLOAD_FAQ_LINKS,
    updateFaqLinksService({
      faq_links_file_content_id: contentId,
      content_file_type: 'csv',
    }),
  );
}

export function* uploadFaqLinksSaga() {
  yield fork(all, [
    takeLatest(UPLOAD_FAQ_LINKS.BASE, uploadFaqLinks),
    takeLatest(
      [UPLOAD_FAQ_LINKS.SUCCEEDED, UPLOAD_FAQ_LINKS.ERRORED],
      sendFeedbackToast,
    ),
  ]);
}
