import { REQUEST_GET_HEALTH_SOLUTION_CATEGORY } from './parent-category-page.action-types';
import camelize from 'camelize';

export const initialState = {
  isLoading: false,
  isError: false,
  id: '',
  title: '',
  description: '',
  resources: [],
};

export const applyParentCategory = (state, action) => {
  const data = camelize(action.payload.info);
  const { id, title, description, resources } = data;

  return {
    ...state,
    isLoading: false,
    id,
    title,
    description,
    resources,
  };
};

export const parentCategoryPageReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case REQUEST_GET_HEALTH_SOLUTION_CATEGORY.STARTED:
      return {
        ...state,
        isLoading: true,
      };

    case REQUEST_GET_HEALTH_SOLUTION_CATEGORY.SUCCEEDED:
      return applyParentCategory(state, action);
    case REQUEST_GET_HEALTH_SOLUTION_CATEGORY.ERRORED:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};
