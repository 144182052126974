import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getEmployerBenefitPlans = ({
  groupId,
  benefitClassIds,
  statuses,
}) =>
  call(websocketFetch, 'get_employer_benefit_plans', {
    group_id: groupId,
    benefit_class_ids: benefitClassIds,
    statuses,
  });
