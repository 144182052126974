import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ActionsDropdown from '../../employer-experience/pages/EmployerDetails/Employees/components/actions-dropdown/actions-dropdown.container';
import {
  reinstateEmployee,
  suspendEmployee,
  terminateEmployee,
  setUserRoleInGroup,
  sendInviteEmail,
  unscheduleEmployeesSuspension,
  unscheduleEmployeesTermination,
  unscheduleEmployeesReinstatement,
} from './profile.actions';
import { selectGroupInvitationId } from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { resetEmployeeUserBenefits } from 'apps/employer-experience/pages/EmployerDetails/Employees/components/reset-user-benefits';
import { requestInitiateQLE } from 'common/initiate-qle-reenrollment';

const mapDispatchToProps = {
  reinstateEmployees: reinstateEmployee,
  suspendEmployees: suspendEmployee,
  terminateEmployees: terminateEmployee,
  sendInviteEmail,
  setUserRoleInGroup,
  resetEmployeeUserBenefits,
  requestInitiateQLE,
  unscheduleEmployeesSuspension,
  unscheduleEmployeesTermination,
  unscheduleEmployeesReinstatement,
};

const mapStateToProps = createStructuredSelector({
  invitationId: selectGroupInvitationId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsDropdown);
