export const TYPE_OPTIONS = [
  {
    text: '',
    value: '',
    disabled: true,
  },
  {
    text: 'URL',
    value: 'url',
  },
  {
    text: 'Phone Number',
    value: 'phone_call',
  },
];

export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_SELECT = 'select';
export const USAGE_INSTRUCTIONS = 'usage_instructions';
export const OVERVIEW = 'overview';
export const COVERAGE_SUMMARY = 'coverage_summary';
export const REMOVE_WALLET_ACTION = 'remove_wallet_action';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const URL = 'url';
export const PHONE_CALL = 'phone_call';
export const NUMERIC_PLUS_PATTERN = /^[0-9+]*$/; // eslint-disable-line no-useless-escape
export const URL_PATTERN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; // eslint-disable-line no-useless-escape

export const actionTypesList = [USAGE_INSTRUCTIONS, OVERVIEW, COVERAGE_SUMMARY];
