import { takeLatest } from 'redux-saga/effects';
import {
  AUTHENTICATE_CONNECTION,
  request,
  websocketFetch,
} from 'common/websocket-redux';
import { GET_FEATURE_FLAGS } from './feature-flags.actions';

export function* fetchFeatureFlags() {
  yield request(GET_FEATURE_FLAGS, [websocketFetch, 'get_user_feature_flags']);
}

export function* featureFlagsSaga() {
  // Websocket fetch works only after authentication has succeeded
  yield takeLatest(AUTHENTICATE_CONNECTION.SUCCEEDED, fetchFeatureFlags);
}
