import React from 'react';
import { spaceInPx, theme, Icon, ICONS } from '@leagueplatform/ui-kit';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BreadcrumbLinkItem } from './breadcrumb-link-item.view';

const BreadcrumbIcon = styled(Icon)`
  margin: 0 ${spaceInPx(1)};
`;

const BreadcrumbList = styled.ol`
  display: flex;
`;

export const Breadcrumb = ({ children }) => (
  <BreadcrumbList>
    {React.Children.map(children, child => (
      <li>
        {child}
        {child.type === BreadcrumbLinkItem && (
          <BreadcrumbIcon
            icon={ICONS.RIGHT_CHEVRON}
            size="1rem"
            stroke={theme.colors.purple}
          />
        )}
      </li>
    ))}
  </BreadcrumbList>
);

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};
