import { createSelector } from 'reselect';
import { get, find } from 'lodash';
import { selectEmployerDetails } from '../../selectors';
import { actionTypesList } from './constants';

export const selectProviderBenefits = createSelector(
  selectEmployerDetails,
  employerDetails => get(employerDetails, 'providerBenefits'),
);

export const selectProviderBenefitsReady = createSelector(
  selectEmployerDetails,
  employerDetails => get(employerDetails, 'benefitWalletReady'),
);

export const selectProviderBenefit = createSelector(
  selectEmployerDetails,
  employerDetails => get(employerDetails, 'selectedProviderBenefit'),
);

export const selectBenefitWalletActions = createSelector(
  selectProviderBenefit,
  providerBenefit =>
    get(providerBenefit, 'provider_wallet_action.actions', []).filter(
      action => !actionTypesList.includes(action.type),
    ),
);

export const selectPrimaryAction = createSelector(
  selectBenefitWalletActions,
  benefitWalletActions =>
    find(benefitWalletActions, { benefit_class_id: '', primary: true }),
);

export const selectMoreActions = createSelector(
  selectBenefitWalletActions,
  benefitWalletActions =>
    benefitWalletActions.filter(
      ({ primary, benefit_class_id: benefitClassId }) =>
        !primary && !benefitClassId,
    ),
);

export const selectBenefitTypesSchemas = state =>
  state.apps['employer-experience']?.benefitTypesSchemas?.employer;

export const selectTemplateVariableOptions = createSelector(
  selectBenefitTypesSchemas,
  selectProviderBenefit,
  (schemas, benefit) => {
    if (!schemas || !benefit) return [];
    const schema = schemas[benefit.id];
    if (!schema) return [];
    return Object.keys(schema).map(variable => ({
      label: variable,
      value: variable,
    }));
  },
);
