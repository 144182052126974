import {
  GET_BENEFIT_CLASS_TYPEFORM_URLS,
  ADD_BENEFIT_CLASS_TYPEFORM_URLS,
} from './employer-integrations.types';
import camelize from 'camelize';
import { mapValues, keyBy, compose, get, map, assocPath } from 'lodash/fp';

export const initialState = {
  typeform: {},
};

export const applyResetIntegrationForm = () => ({
  ...initialState,
});

export const applyBenefitClassTypeformUrls = (state, { payload }) => {
  const benefitClasses = compose(
    camelize,
    get('info.benefit_classes'),
  )(payload);

  return assocPath('typeform.benefitClasses', benefitClasses, state);
};

// when user makes change to the typeform urls, update the redux state to reflect the change
export const updateBenefitClassTypeformUrls = (state, { payload }) => {
  const typeformUrlDict = compose(
    mapValues('typeform_url'),
    keyBy('id'),
  )(payload.benefitClasses);

  const mapWithUrl = o => ({
    ...o,
    typeformUrl: typeformUrlDict[o.id],
  });

  const benefitClasses = compose(
    map(mapWithUrl),
    get('typeform.benefitClasses'),
  )(state);

  return assocPath('typeform.benefitClasses', benefitClasses, state);
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerIntegrationsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_BENEFIT_CLASS_TYPEFORM_URLS.STARTED:
      return applyResetIntegrationForm();
    case GET_BENEFIT_CLASS_TYPEFORM_URLS.SUCCEEDED:
      return applyBenefitClassTypeformUrls(state, action);
    case ADD_BENEFIT_CLASS_TYPEFORM_URLS:
      return updateBenefitClassTypeformUrls(state, action);
    default:
      return state;
  }
};
