import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';

const BenefitClassOptions = ({
  toggleRenameBenefitClass,
  removeBenefitClass,
  intl,
}) => (
  <Dropdown
    pointing="top right"
    icon="ellipsis horizontal"
    text=" "
    className="dropdown-benefit-class-options"
  >
    <Dropdown.Menu>
      <Dropdown.Item
        text={intl.formatMessage({ id: 'RENAME_BENEFITS_CLASS' })}
        onClick={toggleRenameBenefitClass}
      />
      <Dropdown.Divider />
      <Dropdown.Item
        className="dropdown-benefit-class-options__negative"
        text={intl.formatMessage({ id: 'REMOVE_BENEFITS_CLASS' })}
        onClick={removeBenefitClass}
      />
    </Dropdown.Menu>
  </Dropdown>
);

BenefitClassOptions.propTypes = {
  toggleRenameBenefitClass: PropTypes.func.isRequired,
  removeBenefitClass: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(BenefitClassOptions);
