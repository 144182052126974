import React from 'react';
import { JSONSchemaFieldPropType } from 'common/json-schema-form';
import { useIntl } from '@leagueplatform/locales';
import {
  Label,
  Flex,
  Box,
  BodyTwo,
  useTheme,
  LabelText,
} from '@leagueplatform/genesis-commons';
import {
  DateInput,
  Tooltip,
  TooltipContent,
  LightQuestionIcon,
} from '@leagueplatform/ui-kit';
import { selectUserHomeCountry } from 'common/state/user/user.selectors';
import { useSelector } from 'react-redux';
import { FieldWrapper } from './beneficiary-entity-form.view';
import { CANADA } from 'common/constants';
import { ErrorMessage } from 'common/json-schema-form/fields/error-message.view';

export const BeneficiaryTooltipDOB = ({
  propSchema: { readOnly, title },
  type,
  id,
  field,
  form,
  name,
  autoComplete,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const strong = msg => <strong>{msg}</strong>;
  const country = useSelector(selectUserHomeCountry);

  const tooltipText = formatMessage(
    { id: 'GENERIC_TOOLTIP_NAME' },
    { name: `${formatMessage({ id: 'DATE_OF_BIRTH' })}` },
  );
  const errorId = `${name}-error-id`;
  const errorMessage =
    form.errors[name] &&
    title &&
    form.errors[name]?.includes(formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' }))
      ? formatMessage({ id: 'FIELD_REQUIRED' }, { field: title })
      : form.errors[name];

  return (
    <>
      <FieldWrapper>
        <Label as="fieldset" marginRight="quarter" htmlFor={name}>
          <Flex marginTop="one">
            <LabelText color="onSurface.text.subdued">
              {formatMessage({ id: 'DATE_OF_BIRTH' })}
            </LabelText>

            {country === CANADA && (
              <Tooltip>
                <Box px={1}>
                  <LightQuestionIcon altText={tooltipText} />
                </Box>
                <TooltipContent
                  pointerPosition={10}
                  verticalPosition="top: 3.2rem;"
                  borderRadius={`${theme.radii.small}px`}
                  top
                >
                  <Box height={125} width={600} fontSize="body2">
                    <BodyTwo padding="quarter">
                      {formatMessage(
                        { id: 'DATE_OF_BIRTH_TOOLTIP_QUEBEC' },
                        { strong },
                      )}
                      <br />
                      {formatMessage(
                        { id: 'DATE_OF_BIRTH_TOOLTIP_NON_QUEBEC' },
                        { strong },
                      )}
                    </BodyTwo>
                  </Box>
                </TooltipContent>
              </Tooltip>
            )}
          </Flex>

          <DateInput
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...field}
            id={id}
            fit
            disabled={readOnly}
            hasError={Boolean(form.errors[name])}
            type={type}
            errorMessageId={errorId}
            autoComplete={autoComplete}
            onChange={(_, { date }) => {
              if (date) {
                const utcDate = new Date(
                  date.getUTCFullYear(),
                  date.getUTCMonth(),
                  date.getUTCDate(),
                );
                form.setFieldValue(field.name, utcDate);
              }
            }}
          />
        </Label>
      </FieldWrapper>
      {Boolean(form.errors[name]) && (
        <ErrorMessage errorMessage={errorMessage} errorId={errorId} />
      )}
    </>
  );
};

BeneficiaryTooltipDOB.propTypes = JSONSchemaFieldPropType;
