/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { postToWR } from 'common/utilities';
import {
  DigitalBeneficiariesCardWrapper,
  YourBeneficiaries,
  AddBeneficiaryButton,
} from 'common/beneficiaries/digital-beneficiaries-card';
import { getBeneficiariesUrl } from 'common/beneficiaries/beneficiaries.utilities';
import { APP_CONFIG } from 'app-config';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { DownloadDigitalBeneficiariesButton } from 'common/beneficiaries/digital-beneficiaries-card/download-digital-beneficiaries-button.view';
import { Flex } from '@leagueplatform/genesis-commons';

const beneficiariesPath = getBeneficiariesUrl('designations');
const memberBeneficiariesUrl = `${APP_CONFIG.REACT_APP_WR_URL}${beneficiariesPath}`;

export const DigitalBeneficiariesCard = ({
  beneficiaries,
  isInWidgetRunnerFrame,
  digitalBeneficiaryForm,
  ...props
}) => {
  const totalBeneficiaries = beneficiaries.length;

  const handleClickInEnrollment = () => {
    sendAddBeneficiaryAnalytics();
    window.location.href = memberBeneficiariesUrl;
  };
  const handleClickInMemberApp = () => {
    sendAddBeneficiaryAnalytics();
    postToWR('goToUrl', { path: beneficiariesPath });
  };

  const handleClick = isInWidgetRunnerFrame
    ? handleClickInMemberApp
    : handleClickInEnrollment;

  const sendAddBeneficiaryAnalytics = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ENROLLMENT,
      screen_name: 'documents & forms',
      detail: 'add beneficiary',
    });
  };

  return (
    <DigitalBeneficiariesCardWrapper>
      {totalBeneficiaries > 0 && (
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <YourBeneficiaries totalBeneficiaries={totalBeneficiaries} {...props} />
      )}
      <Flex
        flexDirection={{ _: 'column', laptop: 'row' }}
        alignItems={{ _: 'none', laptop: 'center' }}
        justifyContent={{ _: 'center', laptop: 'flex-start' }}
      >
        <AddBeneficiaryButton
          onClick={handleClick}
          data-tracking-id="next-steps_add-beneficiary-button"
        />
        {totalBeneficiaries > 0 && (
          <DownloadDigitalBeneficiariesButton
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...digitalBeneficiaryForm}
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
          />
        )}
      </Flex>
    </DigitalBeneficiariesCardWrapper>
  );
};

DigitalBeneficiariesCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  isInWidgetRunnerFrame: PropTypes.bool,
  digitalBeneficiaryForm: PropTypes.shape({
    contentId: PropTypes.string,
    error: PropTypes.bool,
    isReady: PropTypes.bool,
  }).isRequired,
};

DigitalBeneficiariesCard.defaultProps = {
  beneficiaries: [],
  isInWidgetRunnerFrame: false,
};
