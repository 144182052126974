import {
  EMPLOYER_BENEFITS_INIT,
  EMPLOYER_BENEFITS_LIFECYCLE,
} from './benefits.types';

export const initialState = null;

export const employerBenefitPlansReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case EMPLOYER_BENEFITS_INIT.SUCCEEDED:
      return action.payload.plans;
    case EMPLOYER_BENEFITS_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
