/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getPricingScenarioReport = ({ group_id, user_ids }) =>
  call(websocketFetch, 'request_report', {
    type: 'pricing_config_qa_report',
    request: {
      group_id,
      user_ids,
    },
  });
