/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { concat, isEmpty } from 'lodash';
import { GET_USERS_IN_GROUP } from './v1';
import { EMPLOYEE_SEARCH_LIFECYCLE } from './search.types';

export const initialState = {
  users: [],
  query: '',
  offset: 0,
  total: 0,
  hasMoreResults: true,
  include_suspended: true,
};

export const handleEmptyResults = (state, action) => ({
  ...state,
  users: [],
  hasMoreResults: false,
  total: 0,
  query: action.meta.query,
  offset: action.meta.offset,
  include_suspended: action.meta.include_suspended,
});

export const handleResults = (state, action) => {
  const { query, offset, limit, include_suspended } = action.meta;

  const availableResults = action.payload.info.filtered_total;
  const remainingResults = availableResults - (offset + limit);
  const hasMoreResults = remainingResults > 0;

  const userResults = action.payload.info.users;

  const isNewQuery = query !== state.query || offset === 0;
  const users = isNewQuery ? userResults : concat(state.users, userResults);

  return {
    ...state,
    users,
    query,
    offset,
    hasMoreResults,
    total: availableResults,
    include_suspended,
  };
};

export const updateEmployees = (state, action) =>
  isEmpty(action.payload.info.users)
    ? handleEmptyResults(state, action)
    : handleResults(state, action);

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employeeSearchReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_USERS_IN_GROUP.SUCCEEDED:
      return updateEmployees(state, action);
    case EMPLOYEE_SEARCH_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
