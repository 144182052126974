import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'common/components/primitives.view';
import { getColourObject } from '@leagueplatform/ui-kit';

const StyledDropdownItem = styled(Flex)`
  cursor: pointer;
  white-space: nowrap;
  padding: 1.6rem 2.8rem;
  font-weight: 500;
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `};

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    ${({ disabled, theme }) =>
      !disabled && `background-color: ${getColourObject(theme).greyLighter};`};
  }
`;

const DropdownItem = ({ ...props }) => {
  const { children } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledDropdownItem className="dropdown-item" {...props}>
      {children}
    </StyledDropdownItem>
  );
};

DropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropdownItem;
