import { createRequestTypes } from 'common/websocket-redux';

export const REQUEST_GET_STATEMENT_OF_ACCOUNT =
  'admin/employer-billing/REQUEST_GET_STATEMENT_OF_ACCOUNT';

export const GET_STATEMENT_OF_ACCOUNT = createRequestTypes(
  'services/GET_STATEMENT_OF_ACCOUNT',
);

export const REQUEST_ENROLLMENT_SELECTION_REPORT =
  'admin/finance-tools/REQUEST_ENROLLMENT_SELECTION_REPORT';

export const GET_ENROLLMENT_SELECTION_REPORT = createRequestTypes(
  'admin/finance-tools/GET_ENROLLMENT_SELECTION_REPORT',
);
