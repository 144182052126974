import { get } from 'lodash';
import { getBenefitSelection as _getBenefitSelection } from 'common/services/enrollment';
import { isRecoverableGetBenefitSelectionError } from '../utils';

export function* getBenefitSelection({ groupId, userId, planId }) {
  try {
    const response = yield _getBenefitSelection({ groupId, userId, planId });
    return get(response, 'info');
  } catch (error) {
    // see utils/utils.js:isRecoverableGetBenefitSelectionError for documentation
    if (isRecoverableGetBenefitSelectionError(error)) return {};
    throw error;
  }
}
