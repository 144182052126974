import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import { BenefitPlanOptions } from './BenefitPlanOptions/benefit-plan-options.view';
import RemoveBenefitPlanConfirmation from './RemoveBenefitPlanConfirmation';
import { Benefits } from './Benefits';
import BenefitLabel from '../../BenefitLabel';
import AddRenameInput from '../../../AddRenameInput';
import { CopyPlan } from './copy-plan.container';
import { RenewPlan } from './renew-plan.container';

export const useBenefitPlanProps = ({
  id,
  name,
  errorRenamingBenefitPlan,
  renamingBenefitPlan,
  fetchBenefits,
  fetchCatalogue,
  renameBenefitPlan,
  removeBenefitPlan,
  hasCatalogue,
}) => {
  const [canRename, setCanRename] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [hasError, setHasError] = useState(errorRenamingBenefitPlan);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [isCopyingPlan, setIsCopyingPlan] = useState(false);
  const [isRenewingPlan, setIsRenewingPlan] = useState(false);
  const [active, setActive] = useState(false);
  const [hasFetchedBenefits, setHasFetchedBenefits] = useState(false);
  const [hasFetchedCatalogue, setHasFetchedCatalogue] = useState(hasCatalogue);
  const [benefitPlanName, setBenefitPlanName] = useState(name);

  useEffect(() => {
    if (errorRenamingBenefitPlan && renamingBenefitPlan === id) {
      setIsRenaming(false);
      setBenefitPlanName(name);
      setHasError(true);
    } else if (!errorRenamingBenefitPlan && renamingBenefitPlan === id) {
      setIsRenaming(false);
      setBenefitPlanName(name);
      setHasError(false);
      setCanRename(false);
    }
  }, [renamingBenefitPlan, errorRenamingBenefitPlan, id, name]);

  const onTitleClick = useCallback(() => {
    const willBeActive = !active;
    setActive(willBeActive);
    if (willBeActive && !hasFetchedCatalogue) {
      fetchCatalogue();
      setHasFetchedCatalogue(true);
    }
    if (willBeActive && !hasFetchedBenefits) {
      fetchBenefits({ id });
      setHasFetchedBenefits(true);
    }
  }, [
    active,
    setActive,
    hasFetchedBenefits,
    hasFetchedCatalogue,
    setHasFetchedBenefits,
    fetchCatalogue,
    fetchBenefits,
    id,
  ]);

  const onRenameBenefitPlan = useCallback(() => {
    const value = benefitPlanName;
    if (value?.trim()?.length > 0) {
      setIsRenaming(true);
      setHasError(false);
      setBenefitPlanName(value);
      renameBenefitPlan({
        name: value,
        id,
      });
    } else {
      setHasError(true);
    }
  }, [
    benefitPlanName,
    setIsRenaming,
    setHasError,
    setBenefitPlanName,
    id,
    renameBenefitPlan,
  ]);

  const onBenefitPlanNameChange = useCallback(
    (_, { value }) => {
      if (hasError && value) {
        setBenefitPlanName(value);
        setHasError(false);
      } else {
        setBenefitPlanName(value);
      }
    },
    [hasError, setBenefitPlanName, setHasError],
  );

  const toggleRenameBenefitPlan = useCallback(() => {
    setCanRename(!canRename);
  }, [canRename, setCanRename]);

  const openCopyPlan = useCallback(
    () => setIsCopyingPlan(true),
    [setIsCopyingPlan],
  );

  const openRenewPlan = useCallback(
    () => setIsRenewingPlan(true),
    [setIsRenewingPlan],
  );

  const confirmRemoveBenefitPlan = useCallback(() => {
    setConfirmRemove(true);
  }, [setConfirmRemove]);

  const onRemoveBenefitPlan = useCallback(() => {
    setConfirmRemove(false);
    removeBenefitPlan({ id });
  }, [setConfirmRemove, removeBenefitPlan, id]);

  return {
    active,
    canRename,
    hasError,
    benefitPlanName,
    isRenaming,
    confirmRemove,
    setConfirmRemove,
    isCopyingPlan,
    setIsCopyingPlan,
    isRenewingPlan,
    setIsRenewingPlan,
    onTitleClick,
    onRenameBenefitPlan,
    onBenefitPlanNameChange,
    toggleRenameBenefitPlan,
    openCopyPlan,
    openRenewPlan,
    confirmRemoveBenefitPlan,
    onRemoveBenefitPlan,
  };
};
export const BenefitPlan = ({
  id,
  name,
  benefitClassId,
  isLastBenefitPlan,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    active,
    canRename,
    hasError,
    benefitPlanName,
    isRenaming,
    confirmRemove,
    setConfirmRemove,
    isCopyingPlan,
    setIsCopyingPlan,
    isRenewingPlan,
    setIsRenewingPlan,
    onTitleClick,
    onRenameBenefitPlan,
    onBenefitPlanNameChange,
    toggleRenameBenefitPlan,
    openCopyPlan,
    openRenewPlan,
    confirmRemoveBenefitPlan,
    onRemoveBenefitPlan,
  } = useBenefitPlanProps({
    ...props,
    benefitClassId,
    id,
    name,
  });

  return (
    <Accordion fluid styled className="accordion--benefit-plan">
      <Accordion.Title active={active} onClick={onTitleClick}>
        <Icon name="dropdown" />
        {!canRename ? (
          <BenefitLabel type="plan" name={name} />
        ) : (
          <AddRenameInput
            hasError={hasError}
            name={benefitPlanName}
            onChangeHandler={onBenefitPlanNameChange}
            onSubmitHandler={onRenameBenefitPlan}
            shouldLoad={isRenaming}
            contentId={formatMessage({ id: 'RENAME_BENEFITS_PLAN' })}
            placeholderId={formatMessage({
              id: 'RENAME_BENEFITS_PLAN_PLACEHOLDER',
            })}
            toggle={toggleRenameBenefitPlan}
          />
        )}

        <BenefitPlanOptions
          toggleRenameBenefitPlan={toggleRenameBenefitPlan}
          removeBenefitPlan={confirmRemoveBenefitPlan}
          openCopyPlan={openCopyPlan}
          openRenewPlan={openRenewPlan}
        />
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Benefits benefitClassId={benefitClassId} benefitPlanId={id} />
      </Accordion.Content>
      <RemoveBenefitPlanConfirmation
        open={confirmRemove}
        close={() => setConfirmRemove(false)}
        benefitPlanName={benefitPlanName}
        removeBenefitPlan={onRemoveBenefitPlan}
        isLastBenefitPlan={isLastBenefitPlan}
      />
      {isCopyingPlan && (
        <CopyPlan
          benefitPlanId={id}
          benefitPlanName={name}
          benefitClassId={benefitClassId}
          onClose={() => setIsCopyingPlan(false)}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      )}
      {isRenewingPlan && (
        <RenewPlan
          benefitPlanId={id}
          benefitPlanName={name}
          benefitClassId={benefitClassId}
          onClose={() => setIsRenewingPlan(false)}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      )}
    </Accordion>
  );
};

BenefitPlan.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  benefitClassId: PropTypes.string.isRequired,
  errorRenamingBenefitPlan: PropTypes.bool.isRequired,
  renamingBenefitPlan: PropTypes.string.isRequired,
  renameBenefitPlan: PropTypes.func.isRequired,
  isLastBenefitPlan: PropTypes.bool.isRequired,
  removeBenefitPlan: PropTypes.func.isRequired,
  fetchCatalogue: PropTypes.func.isRequired,
  fetchBenefits: PropTypes.func.isRequired,
};
