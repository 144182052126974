import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DependentLink } from './components/DependentLink.view';
import { spaceInPx, Text } from '@leagueplatform/ui-kit';

const TitleText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.6px;

  && {
    margin-bottom: ${({ isActive }) => (isActive ? 0 : spaceInPx(2))};
  }
`;

const List = styled.ul`
  margin-bottom: ${spaceInPx(3)};
`;

const ListItem = styled.li`
  margin-top: ${spaceInPx(2)};
`;

export const DependentList = withRouter(
  ({ dependentList, title, match, isActive }) => {
    return (
      <>
        <TitleText
          fontSize={0}
          fontWeight="bold"
          color="neutral.grayDark"
          isActive={isActive}
        >
          {title}
        </TitleText>

        <List>
          {map(dependentList, dependent => {
            return (
              <ListItem key={dependent.dependent_id}>
                <DependentLink
                  to={`${match.url}/${dependent.dependent_id}`}
                  title={`${dependent.first_name} ${dependent.last_name}`}
                  relationshipType={dependent.relationship_type}
                  pendingSuspensionDate={
                    dependent.pendingSuspension
                      ? dependent.remove_qualifying_life_event_date
                      : null
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  },
);

DependentList.propTypes = {
  dependentList: PropTypes.arrayOf(
    PropTypes.shape({
      dependent_id: PropTypes.string,
      relationship_type: PropTypes.string,
      date_of_birth: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

DependentList.defaultProps = {
  isActive: false,
};
