import { get } from 'lodash';

const fallbackBenefitNames = {
  psa: 'Lifestyle Spending Account',
  lsa: 'Lifestyle Spending Account',
  league_spending_lsa: 'Lifestyle Spending Account',
  hsa: 'Health Spending Account',
  league_spending_hsa: 'Health Spending Account',
  fsa: 'Fitness Spending Account',
  league_spending_fsa: 'Fitness Spending Account',
  charity: 'Charitable Giving Account',
  league_spending_charity: 'Charitable Giving Account',
};

export const getPolicyName = (type, benefitNames) => {
  const names = { ...fallbackBenefitNames, ...benefitNames };
  return names[type] || type.toUpperCase();
};

export const toNumber = value => (value || value === 0 ? Number(value) : null);

export const getUpdatedAllocationAmounts = (policies, values) =>
  policies.map(allocation => {
    const allocationAmount =
      values[`policy_allocations.${allocation.type}.amount_approved`] || 0;
    const categoryId =
      values[`policy_allocations.${allocation.type}.category_id`];
    return {
      ...allocation,
      categoryId,
      amountApproved: allocationAmount,
      amountAdjusted: Math.abs(
        allocationAmount - get(allocation, 'amountApproved', 0),
      ),
      wasUpdated: allocation.amountApproved !== allocationAmount,
    };
  });
