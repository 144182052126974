import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Dimmer } from 'semantic-ui-react';
import { HeadingTwo } from '@leagueplatform/genesis-commons';
import { Box } from '@leagueplatform/ui-kit';
import FinanceReportingStatementOfAccount from './components/StatementOfAccount';
import EnrollmentSelectionReportContainer from './components/EnrollmentSelectionReport';
import { TabPanel, TabContent } from 'apps/employer-experience/ui-kit/tabs';

const FinanceReporting = () => {
  const { formatMessage } = useIntl();
  return (
    <Dimmer.Dimmable as={Box}>
      <HeadingTwo marginBottom="one">
        {formatMessage({ id: 'FINANCE_REPORTS' })}
      </HeadingTwo>
      <TabPanel>
        <TabContent>
          <FinanceReportingStatementOfAccount />
          <EnrollmentSelectionReportContainer />
        </TabContent>
      </TabPanel>
    </Dimmer.Dimmable>
  );
};

export default FinanceReporting;
