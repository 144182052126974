import { theme } from '@leagueplatform/ui-kit';

export const treeStyle = {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: 'transparent',
      margin: theme.space[0],
      padding: theme.space[0],
      fontFamily: theme.text.fontFamily,
    },
    node: {
      base: {
        position: 'relative',
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline-block',
          height: '24px',
          width: '24px',
        },
        wrapper: {
          position: 'absolute',
          display: 'flex',
          height: '24px',
          width: '24px',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
        },
        height: 8,
        width: 6,
        arrow: {
          fill: '#C0C0C0',
          strokeWidth: 0,
        },
      },
      loading: {
        color: theme.colors.greyDark,
        fontWeight: theme.fontWeight.bold,
      },
    },
  },
};
