import { takeLatest, put, call } from 'redux-saga/effects';
import { first } from 'lodash/fp';
import { get } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  resetUserEnrollmentPeriod,
  clearUserBenefitSelection,
  setUserQLEEnrollment,
} from 'common/services';
import {
  GET_EMPLOYEE_USER_PROFILE,
  TERMINATE_EMPLOYEE,
  SUSPEND_EMPLOYEE,
  REINSTATE_EMPLOYEE,
  DO_TERMINATE_EMPLOYEE,
  DO_SUSPEND_EMPLOYEE,
  DO_REINSTATE_EMPLOYEE,
  SET_USER_ROLE_IN_GROUP,
  DO_SET_USER_ROLE_IN_GROUP,
  SEND_INVITE_EMAIL,
  UNSCHEDULE_EMPLOYEES_SUSPENSION,
  UNSCHEDULE_EMPLOYEES_TERMINATION,
  UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  SHOULD_REFETCH_EMPLOYEE,
} from './profile.types';
import {
  EMPLOYEE_USER_BENEFITS_RESET,
  REQUEST_EMPLOYEE_RESET_USER_BENEFITS,
} from 'apps/employer-experience/pages/EmployerDetails/Employees/components/reset-user-benefits';
import {
  REQUEST_USER_INITIATE_QLE_REENROLLMENT,
  SET_USER_QLE_ENROLLMENT,
} from 'common/initiate-qle-reenrollment';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const employeePathPattern = /^\/manage\/employees\/(.{32})$/;

export const isEmployeePathname = path => employeePathPattern.test(path);

const captureEmployeeId = path => {
  const matches = path.match(employeePathPattern);
  return matches.length === 2 ? matches[1] : null;
};

export const didNavigateToEmployee = action =>
  action.type === LOCATION_CHANGE &&
  isEmployeePathname(action.payload.location.pathname);

export function* getEmployee({ payload }) {
  const { userId } = payload;
  yield request(GET_EMPLOYEE_USER_PROFILE, [
    websocketFetch,
    'get_user_profile',
    { user_id: userId },
  ]);
}

export function* terminateEmployee({ payload }) {
  const { groupId, userIds, effectiveDate, reason, eobDate, noticeDate } =
    payload;
  const userId = first(userIds);
  yield request(DO_TERMINATE_EMPLOYEE, [
    websocketFetch,
    'terminate_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
      termination_reason: reason,
      suspended_notice_date: noticeDate,
      suspended_benefits_extension_date: eobDate,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* suspendEmployee({ payload }) {
  const { groupId, userIds, effectiveDate } = payload;
  const userId = first(userIds);
  yield request(DO_SUSPEND_EMPLOYEE, [
    websocketFetch,
    'suspend_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* reinstateEmployee({ payload }) {
  const { groupId, userIds, effectiveDate } = payload;
  const userId = first(userIds);
  yield request(DO_REINSTATE_EMPLOYEE, [
    websocketFetch,
    'reinstate_employee',
    {
      group_id: groupId,
      user_ids: userIds,
      effective_date: effectiveDate,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* setUserRoleInGroup({ payload }) {
  const { groupId, userId, role } = payload;
  yield request(DO_SET_USER_ROLE_IN_GROUP, [
    websocketFetch,
    'set_user_role_in_group',
    {
      group_id: groupId,
      user_id: userId,
      role,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* requestSendInviteEmail({ payload }) {
  const { invitationId } = payload;
  yield request(SEND_INVITE_EMAIL, [
    websocketFetch,
    'resend_group_invitation_email',
    {
      invitation_id: invitationId,
    },
  ]);
}

export function* showSendInviteEmailSuccessToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'RESEND_INVITE_EMAIL_SUCCESS',
  });
}

export function* showSendInviteEmailErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'RESEND_INVITE_EMAIL_ERROR',
  });
}

export function* requestResetUserBenefits({ payload }) {
  const { clearSelection, enrollmentEndDate } = payload;
  try {
    yield put(REQUEST_EMPLOYEE_RESET_USER_BENEFITS.start(payload)); // before request call
    const resetEnrollmentPeriodResponse = yield resetUserEnrollmentPeriod(
      payload,
    ); // response from server
    let clearSelectionResponse;
    if (clearSelection) {
      clearSelectionResponse = yield clearUserBenefitSelection(payload);
    }
    yield put(
      REQUEST_EMPLOYEE_RESET_USER_BENEFITS.success(
        {
          resetEnrollmentPeriodResponse,
          clearSelectionResponse,
        },
        {
          clearSelection,
          enrollmentEndDate,
        },
      ),
    );
  } catch (error) {
    yield put(REQUEST_EMPLOYEE_RESET_USER_BENEFITS.error(error));
  }
}

export function* showResetUserBenefitSelectionToast(action) {
  const { error, meta } = action;
  const type = error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS;
  let textId;
  if (error) {
    textId = 'USER_BENEFITS_RESET_FAILED';
  } else {
    textId = meta.clearSelection
      ? 'USER_BENEFITS_RESET_CLEAR_SELECTION_SUCCEEDED'
      : 'USER_BENEFITS_RESET_MAINTAIN_SELECTION_SUCCEEDED';
  }
  yield call(toastActions.add, {
    type,
    textId,
    values: {
      enrollmentEndDate: meta?.enrollmentEndDate?.toString(),
    },
  });
}

export function* requestInitiateQLE({ payload }) {
  const { employeeName } = payload;
  yield request(SET_USER_QLE_ENROLLMENT, setUserQLEEnrollment(payload), {
    employeeName,
  });
}

export function* showInitiateQLESuccessToast({ meta }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'INITIATE_QLE_SUCCESS_TOAST',
    values: {
      employeeName: get(meta, 'employeeName', ''),
    },
  });
}

export function* showInitiateQLEErrorToast({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'INITIATE_QLE_ERROR_TOAST',
    values: {
      reason: get(payload, 'info.reason', ''),
    },
  });
}

export function* unscheduleEmployeesSuspension({ payload }) {
  const { groupId, userIds } = payload;
  const userId = first(userIds);
  yield request(UNSCHEDULE_EMPLOYEES_SUSPENSION, [
    websocketFetch,
    'cancel_suspend_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* unscheduleEmployeesTermination({ payload }) {
  const { groupId, userIds } = payload;
  const userId = first(userIds);
  yield request(UNSCHEDULE_EMPLOYEES_TERMINATION, [
    websocketFetch,
    'cancel_terminate_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* unscheduleEmployeesReinstatement({ payload }) {
  const { groupId, userIds } = payload;
  const userId = first(userIds);
  yield request(UNSCHEDULE_EMPLOYEES_REINSTATEMENT, [
    websocketFetch,
    'cancel_reinstate_employee_request',
    {
      group_id: groupId,
      user_ids: userIds,
    },
  ]);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId }));
  yield put(SHOULD_REFETCH_EMPLOYEE.success(true));
}

export function* initialize(action) {
  const employeeId = captureEmployeeId(action.payload.location.pathname);
  yield put(GET_EMPLOYEE_USER_PROFILE.request({ userId: employeeId }));
}

export function* employeeProfileSagas() {
  yield takeLatest(didNavigateToEmployee, initialize);
  yield takeLatest(TERMINATE_EMPLOYEE, terminateEmployee);
  yield takeLatest(SUSPEND_EMPLOYEE, suspendEmployee);
  yield takeLatest(REINSTATE_EMPLOYEE, reinstateEmployee);
  yield takeLatest(SET_USER_ROLE_IN_GROUP, setUserRoleInGroup);
  yield takeLatest(EMPLOYEE_USER_BENEFITS_RESET, requestResetUserBenefits);
  yield takeLatest(
    [
      REQUEST_EMPLOYEE_RESET_USER_BENEFITS.SUCCEEDED,
      REQUEST_EMPLOYEE_RESET_USER_BENEFITS.ERRORED,
    ],
    showResetUserBenefitSelectionToast,
  );
  yield takeLatest(REQUEST_USER_INITIATE_QLE_REENROLLMENT, requestInitiateQLE);
  yield takeLatest(
    SET_USER_QLE_ENROLLMENT.SUCCEEDED,
    showInitiateQLESuccessToast,
  );
  yield takeLatest(SET_USER_QLE_ENROLLMENT.ERRORED, showInitiateQLEErrorToast);
  yield takeLatest(SEND_INVITE_EMAIL.BASE, requestSendInviteEmail);
  yield takeLatest(
    SEND_INVITE_EMAIL.SUCCEEDED,
    showSendInviteEmailSuccessToast,
  );
  yield takeLatest(SEND_INVITE_EMAIL.ERRORED, showSendInviteEmailErrorToast);
  yield takeLatest(
    UNSCHEDULE_EMPLOYEES_SUSPENSION.BASE,
    unscheduleEmployeesSuspension,
  );
  yield takeLatest(
    UNSCHEDULE_EMPLOYEES_TERMINATION.BASE,
    unscheduleEmployeesTermination,
  );
  yield takeLatest(
    UNSCHEDULE_EMPLOYEES_REINSTATEMENT.BASE,
    unscheduleEmployeesReinstatement,
  );
  yield takeLatest(GET_EMPLOYEE_USER_PROFILE.BASE, getEmployee);
}
