import { get, invert } from 'lodash';
import { STATES, PROVINCES } from 'common/constants';

import {
  MALE as DEPENDENTS_MALE,
  FEMALE as DEPENDENTS_FEMALE,
} from 'common/dependents/dependents-metadata';

// Field groups
export const PROFILE = 'profile';
export const ADDRESS = 'address';
export const SPOUSE_DEPENDENT = 'spouse';
export const OTHER_DEPENDENT = 'other';

// Input types
export const TEXT = 'text';
export const SELECT = 'select';
export const MULTISELECT = 'multiselect';
export const RADIO = 'radio';
export const CHECKBOX = 'checkbox';
export const DATE = 'date';
export const HIDDEN = 'hidden';
export const OPTIONAL_SSN = 'optional_ssn';

// Field names use to manipulate various data
export const COUNTRY = 'country';
export const NAME = 'name';
export const LOCATIONS = 'locations';
export const HOME = 'home';
export const POSTAL_CODE = 'postal_code';
export const DATE_OF_BIRTH = 'date_of_birth';
export const NATIONAL_IDENTIFICATION_NUMBER = 'national_identification_number';
export const OMIT_NATIONAL_IDENTIFICATION_NUMBER =
  'omit_national_identification_number';
export const POSTAL = 'postal';
export const GENDER_IDENTITY = 'gender_identity';
export const PRONOUNS = 'pronouns';

// Dependent classes
export const DEPENDENT_CLASS_SPOUSE = 'spouse';
export const DEPENDENT_CLASS_OTHER = 'other';
export const DEPENDENT_RELATIONSHIP_TYPE_CHILD = 'child';

// we get `postal_code` from the schema but `set_user_profile` requires `postal` :(
export const fieldNameUpdates = {
  [POSTAL_CODE]: POSTAL,
};

export const fieldNameResets = invert(fieldNameUpdates);

export const overwriteFieldNames = name => get(fieldNameUpdates, name, name);

export const getOriginalFieldNames = name => get(fieldNameResets, name, name);

export const userProfileFields = [
  {
    name: 'first_name',
    inputType: TEXT,
    label: 'LEGAL_FIRST_NAME',
    group: PROFILE,
  },
  {
    name: 'last_name',
    inputType: TEXT,
    label: 'LAST_NAME',
    group: PROFILE,
  },
  {
    name: 'preferred_first_name',
    inputType: TEXT,
    label: 'WHAT_SHOULD_WE_CALL_YOU',
    group: PROFILE,
    placeholder: 'PREFERRED_NAME_PLACEHOLDER',
    tooltip: 'PREFERRED_NAME_TOOLTIP',
  },
  {
    name: 'date_of_birth',
    inputType: DATE,
    label: 'DATE_OF_BIRTH',
    group: PROFILE,
    shouldUpdateCosts: true,
  },
  {
    name: 'sex',
    inputType: RADIO,
    label: 'SEX',
    tooltip: 'PROFILE_SEX_TOOLTIP',
    group: PROFILE,
    shouldUpdateCosts: true,
  },
  {
    name: 'gender_identity',
    inputType: MULTISELECT,
    label: 'GENDER_IDENTITY',
    tooltip: 'GENDER_IDENTITY_TOOLTIP',
    group: PROFILE,
  },
  {
    name: 'pronouns',
    inputType: MULTISELECT,
    label: 'PRONOUNS',
    tooltip: 'PRONOUNS_TOOLTIP',
    group: PROFILE,
  },
  {
    name: 'locale',
    inputType: RADIO,
    label: 'LANGUAGE_PREFERENCE',
    tooltip: 'PROFILE_LOCALE_TOOLTIP',
    group: PROFILE,
    choices: ['en', 'fr'],
  },
  {
    // Unused at the moment
    name: 'social_security_number',
    inputType: TEXT,
    label: 'SSN',
    group: PROFILE,
  },
  {
    // Label localizes to SIN for Canada and SSN for US, see getLocalizedLabel
    name: NATIONAL_IDENTIFICATION_NUMBER,
    inputType: TEXT,
    label: 'SIN_OR_SSN',
    tooltip: 'PROFILE_SSN_TOOLTIP',
    group: PROFILE,
  },
  {
    name: 'tobacco_user',
    inputType: RADIO,
    label: 'DO_YOU_USE_TOBACCO',
    tooltip: 'PROFILE_TOBACCO_USER_TOOLTIP',
    group: PROFILE,
    shouldUpdateCosts: true,
  },
  {
    name: 'registered_indian_act',
    inputType: RADIO,
    label: 'ARE_YOU_REGISTERED_INDIAN_ACT',
    tooltip: 'PROFILE_INDIAN_ACT_TOOLTIP',
    group: PROFILE,
  },

  // Address
  {
    name: 'address1',
    inputType: TEXT,
    label: 'ADDRESS_LINE_1',
    group: ADDRESS,
  },
  {
    name: 'address2',
    inputType: TEXT,
    label: 'ADDRESS_LINE_2',
    group: ADDRESS,
  },
  {
    name: 'city',
    inputType: TEXT,
    label: 'CITY',
    euLabel: 'CITY_TOWN',
    group: ADDRESS,
  },
  {
    name: 'province',
    inputType: SELECT,
    label: 'PROVINCE',
    euLabel: 'PROVINCE_COUNTY',
    group: ADDRESS,
  },
  {
    // For US
    name: 'state',
    inputType: SELECT,
    label: 'STATE',
    group: ADDRESS,
  },
  {
    // Also for US, need to conditionally change to zip code
    // This is being done in getLocalizedLabel down below
    name: 'postal_code',
    inputType: TEXT,
    label: 'POSTAL_CODE',
    group: ADDRESS,
  },
  { name: 'country', inputType: HIDDEN, label: 'COUNTRY', group: ADDRESS },
];

export const spouseDependentFields = [
  {
    name: 'dependent_id',
    inputType: HIDDEN,
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'first_name',
    inputType: TEXT,
    label: 'LEGAL_FIRST_NAME',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'last_name',
    inputType: TEXT,
    label: 'LAST_NAME',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'date_of_birth',
    inputType: DATE,
    label: 'DATE_OF_BIRTH',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'gender',
    inputType: RADIO,
    label: 'SEX',
    tooltip: 'DEPENDENTS_SEX_TOOLTIP',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: NATIONAL_IDENTIFICATION_NUMBER,
    inputType: TEXT,
    label: 'SSN',
    tooltip: 'DEPENDENTS_SSN_TOOLTIP',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'relationship',
    inputType: TEXT,
    label: 'RELATIONSHIP',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'relationship_type',
    inputType: SELECT,
    label: 'RELATIONSHIP',
    tooltip: 'DEPENDENTS_PARTNER_RELATIONSHIP_TYPE_TOOLTIP',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'partnership_effective_date',
    inputType: DATE,
    label: 'RELATIONSHIP_EFFECTIVE_DATE',
    tooltip: 'DEPENDENTS_PARTNER_EFFECTIVE_DATE_TOOLTIP',
    group: SPOUSE_DEPENDENT,
    nullableString: true,
  },
  {
    name: 'tobacco_user',
    inputType: RADIO,
    label: 'DOES_YOUR_PARTNER_USE_TOBACCO',
    tooltip: 'DEPENDENTS_TOBACCO_USER_TOOLTIP',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'add_qualifying_life_event',
    inputType: SELECT,
    label: 'QUALIFYING_LIFE_EVENT',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'add_qualifying_life_event_date',
    inputType: DATE,
    label: 'EFFECTIVE_DATE',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: 'add_qualifying_life_event_reason',
    inputType: TEXT,
    label: 'REASON_FOR_ADDING',
    group: SPOUSE_DEPENDENT,
  },
  {
    name: OMIT_NATIONAL_IDENTIFICATION_NUMBER,
    inputType: CHECKBOX,
    group: SPOUSE_DEPENDENT,
  },
];

export const otherDependentFields = [
  {
    name: 'dependent_id',
    inputType: 'hidden',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'first_name',
    inputType: TEXT,
    label: 'LEGAL_FIRST_NAME',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'last_name',
    inputType: TEXT,
    label: 'LAST_NAME',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'date_of_birth',
    inputType: DATE,
    label: 'DATE_OF_BIRTH',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'gender',
    inputType: RADIO,
    label: 'SEX',
    group: OTHER_DEPENDENT,
  },
  {
    name: NATIONAL_IDENTIFICATION_NUMBER,
    inputType: OPTIONAL_SSN,
    label: 'SSN',
    tooltip: 'DEPENDENTS_SSN_TOOLTIP',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'relationship',
    inputType: TEXT,
    label: 'RELATIONSHIP',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'relationship_type',
    inputType: SELECT,
    label: 'RELATIONSHIP',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'partnership_effective_date',
    inputType: DATE,
    label: 'RELATIONSHIP_EFFECTIVE_DATE',
    group: OTHER_DEPENDENT,
    nullableString: true,
  },
  {
    name: 'student',
    inputType: RADIO,
    label: 'IS_DEPENDENT_FULL_TIME_STUDENT',
    tooltip: 'DEPENDENTS_CHILD_FULL_TIME_STUDENT_TOOLTIP',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'over_age_disabled',
    inputType: RADIO,
    label: 'IS_DEPENDENT_DISABLED',
    tooltip: 'DEPENDENTS_CHILD_DISABLED_TOOLTIP',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'add_qualifying_life_event',
    inputType: SELECT,
    label: 'QUALIFYING_LIFE_EVENT',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'add_qualifying_life_event_date',
    inputType: DATE,
    label: 'EFFECTIVE_DATE',
    group: OTHER_DEPENDENT,
  },
  {
    name: 'add_qualifying_life_event_reason',
    inputType: TEXT,
    label: 'REASON_FOR_ADDING',
    group: OTHER_DEPENDENT,
  },
  {
    name: OMIT_NATIONAL_IDENTIFICATION_NUMBER,
    inputType: CHECKBOX,
    group: OTHER_DEPENDENT,
  },
  {
    name: 'child_of_domestic_partner',
    inputType: SELECT,
    label: 'RELATIONSHIP',
    group: OTHER_DEPENDENT,
    tooltip: 'CHILD_OF_DOMESTIC_PARTNER_TOOLTIP',
  },
];

// Select list label translation IDs
const YES_TRANSLATION_ID = 'YES';
const NO_TRANSLATION_ID = 'NO';
const MALE_TRANSLATION_ID = 'MALE';
const FEMALE_TRANSLATION_ID = 'FEMALE';
const MARRIED_TRANSLATION_ID = 'MARRIED';
const SINGLE_TRANSLATION_ID = 'SINGLE';
const COMMON_LAW_TRANSLATION_ID = 'COMMON_LAW';
const SPOUSE_TRANSLATION_ID = 'SPOUSE';
const DOMESTIC_PARTNERSHIP_TRANSLATION_ID = 'DOMESTIC_PARTNERSHIP';
const CHILD_TRANSLATION_ID = 'CHILD';
const GRANDCHILD_TRANSLATION_ID = 'GRANDCHILD';
const GRANDPARENT_TRANSLATION_ID = 'GRANDPARENT';
const INELIGIBLE_CHILD_TRANSLATION_ID = 'INELIGIBLE_CHILD';
const NEPHEW_NIECE_TRANSLATION_ID = 'NEPHEW_NIECE';
const OTHER_TRANSLATION_ID = 'OTHER';
const PARENT_TRANSLATION_ID = 'PARENT';
const SIBLING_TRANSLATION_ID = 'SIBLING';
const UNCLE_AUNT_TRANSLATION_ID = 'UNCLE_AUNT';
const ENGLISH_TRANSLATION_ID = 'ENGLISH';
const FRENCH_TRANSLATION_ID = 'FRENCH';
const ALBERTA_TRANSLATION_ID = 'ALBERTA';
const BRITISH_COLUMBIA_TRANSLATION_ID = 'BRITISH_COLUMBIA';
const MANITOBA_TRANSLATION_ID = 'MANITOBA';
const NEW_BRUNSWICK_TRANSLATION_ID = 'NEW_BRUNSWICK';
const NEWFOUNDLAND_LABRADOR_TRANSLATION_ID = 'NEWFOUNDLAND_LABRADOR';
const NOVA_SCOTIA_TRANSLATION_ID = 'NOVA_SCOTIA';
const ONTARIO_TRANSLATION_ID = 'ONTARIO';
const PRINCE_EDWARD_ISLAND_TRANSLATION_ID = 'PRINCE_EDWARD_ISLAND';
const QUEBEC_TRANSLATION_ID = 'QUEBEC';
const SASKATCHEWAN_TRANSLATION_ID = 'SASKATCHEWAN';
const NORTHWEST_TERRITORIES_TRANSLATION_ID = 'NORTHWEST_TERRITORIES';
const NUNAVUT_TRANSLATION_ID = 'NUNAVUT';
const YUKON_TRANSLATION_ID = 'YUKON';
const DEPENDENT_LOST_COVERAGE_TRANSLATION_ID = 'DEPENDENT_LOST_COVERAGE';
const MARRIAGE_TRANSLATION_ID = 'MARRIAGE';
const BECAME_COMMON_LAW_TRANSLATION_ID = 'BECAME_COMMON_LAW';
const BIRTH_TRANSLATION_ID = 'BIRTH';
const ADOPTION_TRANSLATION_ID = 'ADOPTION';
const ALABAMA_TRANSLATION_ID = 'ALABAMA';
const ALASKA_TRANSLATION_ID = 'ALASKA';
const AMERICAN_SAMOA_TRANSLATION_ID = 'AMERICAN_SAMOA';
const ARIZONA_TRANSLATION_ID = 'ARIZONA';
const ARKANSAS_TRANSLATION_ID = 'ARKANSAS';
const CALIFORNIA_TRANSLATION_ID = 'CALIFORNIA';
const COLORADO_TRANSLATION_ID = 'COLORADO';
const CONNECTICUT_TRANSLATION_ID = 'CONNECTICUT';
const DELAWARE_TRANSLATION_ID = 'DELAWARE';
const DISTRICT_OF_COLUMBIA_TRANSLATION_ID = 'DISTRICT_OF_COLUMBIA';
const FLORIDA_TRANSLATION_ID = 'FLORIDA';
const GEORGIA_TRANSLATION_ID = 'GEORGIA';
const GUAM_TRANSLATION_ID = 'GUAM';
const HAWAII_TRANSLATION_ID = 'HAWAII';
const IDAHO_TRANSLATION_ID = 'IDAHO';
const ILLINOIS_TRANSLATION_ID = 'ILLINOIS';
const INDIANA_TRANSLATION_ID = 'INDIANA';
const IOWA_TRANSLATION_ID = 'IOWA';
const KANSAS_TRANSLATION_ID = 'KANSAS';
const KENTUCKY_TRANSLATION_ID = 'KENTUCKY';
const LOUISIANA_TRANSLATION_ID = 'LOUISIANA';
const MAINE_TRANSLATION_ID = 'MAINE';
const MARYLAND_TRANSLATION_ID = 'MARYLAND';
const MASSACHUSETTS_TRANSLATION_ID = 'MASSACHUSETTS';
const MICHIGAN_TRANSLATION_ID = 'MICHIGAN';
const MINNESOTA_TRANSLATION_ID = 'MINNESOTA';
const MISSISSIPPI_TRANSLATION_ID = 'MISSISSIPPI';
const MISSOURI_TRANSLATION_ID = 'MISSOURI';
const MONTANA_TRANSLATION_ID = 'MONTANA';
const NEBRASKA_TRANSLATION_ID = 'NEBRASKA';
const NEVADA_TRANSLATION_ID = 'NEVADA';
const NEW_HAMPSHIRE_TRANSLATION_ID = 'NEW_HAMPSHIRE';
const NEW_JERSEY_TRANSLATION_ID = 'NEW_JERSEY';
const NEW_MEXICO_TRANSLATION_ID = 'NEW_MEXICO';
const NEW_YORK_TRANSLATION_ID = 'NEW_YORK';
const NORTH_CAROLINA_TRANSLATION_ID = 'NORTH_CAROLINA';
const NORTH_DAKOTA_TRANSLATION_ID = 'NORTH_DAKOTA';
const OHIO_TRANSLATION_ID = 'OHIO';
const OKLAHOMA_TRANSLATION_ID = 'OKLAHOMA';
const OREGON_TRANSLATION_ID = 'OREGON';
const PENNSYLVANIA_TRANSLATION_ID = 'PENNSYLVANIA';
const PUERTO_RICO_TRANSLATION_ID = 'PUERTO_RICO';
const RHODE_ISLAND_TRANSLATION_ID = 'RHODE_ISLAND';
const SOUTH_CAROLINA_TRANSLATION_ID = 'SOUTH_CAROLINA';
const SOUTH_DAKOTA_TRANSLATION_ID = 'SOUTH_DAKOTA';
const TENNESSEE_TRANSLATION_ID = 'TENNESSEE';
const TEXAS_TRANSLATION_ID = 'TEXAS';
const UTAH_TRANSLATION_ID = 'UTAH';
const US_VIRGIN_ISLANDS_TRANSLATION_ID = 'US_VIRGIN_ISLANDS';
const VERMONT_TRANSLATION_ID = 'VERMONT';
const VIRGINIA_TRANSLATION_ID = 'VIRGINIA';
const WASHINGTON_TRANSLATION_ID = 'WASHINGTON';
const WEST_VIRGINIA_TRANSLATION_ID = 'WEST_VIRGINIA';
const WISCONSIN_TRANSLATION_ID = 'WISCONSIN';
const WYOMING_TRANSLATION_ID = 'WYOMING';

/**
 * Here are the strings we get from the get_employer_required_fields service
 * in the `choices` property of select fields. They are not values, but labels,
 * so we need to map them to their associated values in order to construct
 * lists of options for select inputs.
 */
export const YES = 'Yes';
export const NO = 'No';
export const OUI = 'Oui';
export const NON = 'Non';
const MALE = 'male';
const FEMALE = 'female';
const EN_CA = 'en-CA';
const FR_CA = 'fr-CA';
const MARRIED = 'Married';
const SINGLE = 'Single';
const COMMON_LAW = 'Common Law';
const COMMON_DASH_LAW = 'Common-Law';
const SPOUSE = 'Spouse';
const DOMESTIC_PARTNERSHIP = 'Domestic Partnership';
const CHILD = 'Child';
const GRANDCHILD = 'Grandchild';
const GRANDPARENT = 'Grandparent';
const INELIGIBLE_CHILD = 'Ineligible Child';
const NEPHEW_NIECE = 'Nephew or Niece';
const OTHER = 'Other';
const PARENT = 'Parent';
const SIBLING = 'Sibling';
const UNCLE_AUNT = 'Uncle or Aunt';
// QLE fields
const DEPENDENT_LOST_COVERAGE = 'Dependent lost coverage';
const MARRIAGE = 'Marriage';
const BECAME_COMMON_LAW = 'Became common-law';
const BIRTH = 'Birth';
const ADOPTION = 'Adoption';

export const SPOUSE_DEPENDENT_TYPE = 'spouse';
export const OTHER_DEPENDENT_TYPE = 'other';

const fieldLabelMap = {
  [YES]: YES_TRANSLATION_ID,
  [NO]: NO_TRANSLATION_ID,
  [MALE]: MALE_TRANSLATION_ID,
  [DEPENDENTS_MALE]: MALE_TRANSLATION_ID,
  [FEMALE]: FEMALE_TRANSLATION_ID,
  [DEPENDENTS_FEMALE]: FEMALE_TRANSLATION_ID,
  [MARRIED]: MARRIED_TRANSLATION_ID,
  [SINGLE]: SINGLE_TRANSLATION_ID,
  [COMMON_LAW]: COMMON_LAW_TRANSLATION_ID,
  [COMMON_DASH_LAW]: COMMON_LAW_TRANSLATION_ID,
  [SPOUSE]: SPOUSE_TRANSLATION_ID,
  [DOMESTIC_PARTNERSHIP]: DOMESTIC_PARTNERSHIP_TRANSLATION_ID,
  [CHILD]: CHILD_TRANSLATION_ID,
  [INELIGIBLE_CHILD]: INELIGIBLE_CHILD_TRANSLATION_ID,
  [GRANDCHILD]: GRANDCHILD_TRANSLATION_ID,
  [GRANDPARENT]: GRANDPARENT_TRANSLATION_ID,
  [NEPHEW_NIECE]: NEPHEW_NIECE_TRANSLATION_ID,
  [OTHER]: OTHER_TRANSLATION_ID,
  [PARENT]: PARENT_TRANSLATION_ID,
  [SIBLING]: SIBLING_TRANSLATION_ID,
  [UNCLE_AUNT]: UNCLE_AUNT_TRANSLATION_ID,
  [EN_CA]: ENGLISH_TRANSLATION_ID,
  [FR_CA]: FRENCH_TRANSLATION_ID,
  [PROVINCES.ALBERTA]: ALBERTA_TRANSLATION_ID,
  [PROVINCES.BRITISH_COLUMBIA]: BRITISH_COLUMBIA_TRANSLATION_ID,
  [PROVINCES.MANITOBA]: MANITOBA_TRANSLATION_ID,
  [PROVINCES.NEW_BRUNSWICK]: NEW_BRUNSWICK_TRANSLATION_ID,
  [PROVINCES.NEWFOUNDLAND_LABRADOR]: NEWFOUNDLAND_LABRADOR_TRANSLATION_ID,
  [PROVINCES.NOVA_SCOTIA]: NOVA_SCOTIA_TRANSLATION_ID,
  [PROVINCES.ONTARIO]: ONTARIO_TRANSLATION_ID,
  [PROVINCES.PRINCE_EDWARD_ISLAND]: PRINCE_EDWARD_ISLAND_TRANSLATION_ID,
  [PROVINCES.SASKATCHEWAN]: SASKATCHEWAN_TRANSLATION_ID,
  [PROVINCES.QUEBEC]: QUEBEC_TRANSLATION_ID,
  [PROVINCES.NORTHWEST_TERRITORIES]: NORTHWEST_TERRITORIES_TRANSLATION_ID,
  [PROVINCES.YUKON_TERRITORIES]: YUKON_TRANSLATION_ID,
  [PROVINCES.NUNAVUT]: NUNAVUT_TRANSLATION_ID,
  [DEPENDENT_LOST_COVERAGE]: DEPENDENT_LOST_COVERAGE_TRANSLATION_ID,
  [MARRIAGE]: MARRIAGE_TRANSLATION_ID,
  [BECAME_COMMON_LAW]: BECAME_COMMON_LAW_TRANSLATION_ID,
  [BIRTH]: BIRTH_TRANSLATION_ID,
  [ADOPTION]: ADOPTION_TRANSLATION_ID,
  [STATES.ALABAMA]: ALABAMA_TRANSLATION_ID,
  [STATES.ALASKA]: ALASKA_TRANSLATION_ID,
  [STATES.AMERICAN_SAMOA]: AMERICAN_SAMOA_TRANSLATION_ID,
  [STATES.ARIZONA]: ARIZONA_TRANSLATION_ID,
  [STATES.ARKANSAS]: ARKANSAS_TRANSLATION_ID,
  [STATES.CALIFORNIA]: CALIFORNIA_TRANSLATION_ID,
  [STATES.COLORADO]: COLORADO_TRANSLATION_ID,
  [STATES.CONNECTICUT]: CONNECTICUT_TRANSLATION_ID,
  [STATES.DELAWARE]: DELAWARE_TRANSLATION_ID,
  [STATES.DISTRICT_OF_COLUMBIA]: DISTRICT_OF_COLUMBIA_TRANSLATION_ID,
  [STATES.FLORIDA]: FLORIDA_TRANSLATION_ID,
  [STATES.GEORGIA]: GEORGIA_TRANSLATION_ID,
  [STATES.GUAM]: GUAM_TRANSLATION_ID,
  [STATES.HAWAII]: HAWAII_TRANSLATION_ID,
  [STATES.IDAHO]: IDAHO_TRANSLATION_ID,
  [STATES.ILLINOIS]: ILLINOIS_TRANSLATION_ID,
  [STATES.INDIANA]: INDIANA_TRANSLATION_ID,
  [STATES.IOWA]: IOWA_TRANSLATION_ID,
  [STATES.KANSAS]: KANSAS_TRANSLATION_ID,
  [STATES.KENTUCKY]: KENTUCKY_TRANSLATION_ID,
  [STATES.LOUISIANA]: LOUISIANA_TRANSLATION_ID,
  [STATES.MAINE]: MAINE_TRANSLATION_ID,
  [STATES.MARYLAND]: MARYLAND_TRANSLATION_ID,
  [STATES.MASSACHUSETTS]: MASSACHUSETTS_TRANSLATION_ID,
  [STATES.MICHIGAN]: MICHIGAN_TRANSLATION_ID,
  [STATES.MINNESOTA]: MINNESOTA_TRANSLATION_ID,
  [STATES.MISSISSIPPI]: MISSISSIPPI_TRANSLATION_ID,
  [STATES.MISSOURI]: MISSOURI_TRANSLATION_ID,
  [STATES.MONTANA]: MONTANA_TRANSLATION_ID,
  [STATES.NEBRASKA]: NEBRASKA_TRANSLATION_ID,
  [STATES.NEVADA]: NEVADA_TRANSLATION_ID,
  [STATES.NEW_HAMPSHIRE]: NEW_HAMPSHIRE_TRANSLATION_ID,
  [STATES.NEW_JERSEY]: NEW_JERSEY_TRANSLATION_ID,
  [STATES.NEW_MEXICO]: NEW_MEXICO_TRANSLATION_ID,
  [STATES.NEW_YORK]: NEW_YORK_TRANSLATION_ID,
  [STATES.NORTH_CAROLINA]: NORTH_CAROLINA_TRANSLATION_ID,
  [STATES.NORTH_DAKOTA]: NORTH_DAKOTA_TRANSLATION_ID,
  [STATES.OHIO]: OHIO_TRANSLATION_ID,
  [STATES.OKLAHOMA]: OKLAHOMA_TRANSLATION_ID,
  [STATES.OREGON]: OREGON_TRANSLATION_ID,
  [STATES.PENNSYLVANIA]: PENNSYLVANIA_TRANSLATION_ID,
  [STATES.PUERTO_RICO]: PUERTO_RICO_TRANSLATION_ID,
  [STATES.RHODE_ISLAND]: RHODE_ISLAND_TRANSLATION_ID,
  [STATES.SOUTH_CAROLINA]: SOUTH_CAROLINA_TRANSLATION_ID,
  [STATES.SOUTH_DAKOTA]: SOUTH_DAKOTA_TRANSLATION_ID,
  [STATES.TENNESSEE]: TENNESSEE_TRANSLATION_ID,
  [STATES.TEXAS]: TEXAS_TRANSLATION_ID,
  [STATES.US_VIRGIN_ISLANDS]: US_VIRGIN_ISLANDS_TRANSLATION_ID,
  [STATES.UTAH]: UTAH_TRANSLATION_ID,
  [STATES.VERMONT]: VERMONT_TRANSLATION_ID,
  [STATES.VIRGINIA]: VIRGINIA_TRANSLATION_ID,
  [STATES.WASHINGTON]: WASHINGTON_TRANSLATION_ID,
  [STATES.WEST_VIRGINIA]: WEST_VIRGINIA_TRANSLATION_ID,
  [STATES.WISCONSIN]: WISCONSIN_TRANSLATION_ID,
  [STATES.WYOMING]: WYOMING_TRANSLATION_ID,
};

const optionToValueMap = {
  [YES]: true,
  [NO]: false,
  [OUI]: true,
  [NON]: false,
  [MARRIED]: 'married',
  [SINGLE]: 'single',
  [COMMON_LAW]: 'common_law',
  [COMMON_DASH_LAW]: 'common_law',
  [SPOUSE]: 'spouse',
  [DOMESTIC_PARTNERSHIP]: 'domestic_partnership',
  [CHILD]: 'child',
  [GRANDCHILD]: 'grandchild',
  [GRANDPARENT]: 'grandparent',
  [NEPHEW_NIECE]: 'nephew_niece',
  [PARENT]: 'parent',
  [SIBLING]: 'sibling',
  [UNCLE_AUNT]: 'uncle_aunt',
  [OTHER]: 'other',
  // QLE fields
  [DEPENDENT_LOST_COVERAGE]: 'dependent_lost_coverage',
  [MARRIAGE]: 'marriage',
  [BECAME_COMMON_LAW]: 'became-common-law',
  [BIRTH]: 'birth',
  [ADOPTION]: 'adoption',
};

export const getOptionValue = value => get(optionToValueMap, value, value);

export const getSelectOptionTextId = label => get(fieldLabelMap, label, label);

export const getFieldNamesForType = type =>
  userProfileFields
    .filter(field => field.group === type)
    .map(field => field.name);
