import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getBenefitContributions = ({
  userId,
  contributionBenefitTypes,
  contributor,
}) =>
  call(websocketFetch, 'get_benefit_contributions', {
    user_id: userId,
    contribution_benefit_types: contributionBenefitTypes,
    contributor,
  });
