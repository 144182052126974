import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Header, Grid, Form } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';

const StyledInput = styled(Form.Input)`
  width: 16rem;
`;

const PlatformFee = ({
  value: platformFee,
  error,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  const [isChecked, setIsChecked] = useState(!!platformFee);
  const handleToggle = e => {
    e.preventDefault();
    // if current state is checked, future state will be unchecked
    // set value to 0
    if (isChecked) {
      setFieldValue('platformFees', 0);
    }
    setIsChecked(!isChecked);
    setFieldValue('changed', true);
  };
  return (
    <Box>
      <Header as="h5" className="has-toggle">
        <FormattedMessage id="PLATFORM_FEE" />
        <Checkbox checked={isChecked} toggle onClick={handleToggle} />
      </Header>
      <Grid columns={2} className="billing-fees-grid">
        <Grid.Row>
          <Grid.Column className="billing-gridColumn">
            <FormattedMessage id="RATE" />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              icon="dollar"
              iconPosition="left"
              type="number"
              step="any"
              name="platformFees"
              value={isChecked ? platformFee : 0}
              onChange={(_, { name, value }) =>
                handleChange(name, Number(value))
              }
              onBlur={handleBlur}
              readOnly={!isChecked}
              error={error}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Box>
  );
};

PlatformFee.propTypes = {
  value: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default PlatformFee;
