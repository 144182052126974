import React from 'react';
import styled from 'styled-components';
import { LoadingIndicator as LoadingIndicatorPresenter } from '@leagueplatform/web-common-components';

const LoadingIndicatorContainer = styled.div`
  position: relative;
`;

export const LoadingIndicator = () => (
  <LoadingIndicatorContainer>
    <LoadingIndicatorPresenter />
  </LoadingIndicatorContainer>
);
