/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
import React from 'react';
import {
  fontSizeInPx,
  spaceInPx,
  vars,
  Table,
  Text,
  Pill,
  Box,
} from '@leagueplatform/ui-kit';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  QLE_MASTER_LIST,
  QLE_MASTER_LIST_PROP_TYPES,
} from '../qle-master-list';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import styled from 'styled-components';
import { INFINITY, UNSET } from '../qle-configuration.constants';
import QLEConfigurationCard from '../qle-configuration-card/qle-configuration-card.container';

const TableHeaderCell = styled.th`
  font-size: ${fontSizeInPx(1)};
  line-height: 20px;

  &&& {
    padding: ${spaceInPx(1)} ${spaceInPx(2)};
  }
`;

const TableHead = styled.thead`
  height: 40px;
  &&& {
    text-transform: none;
  }

  tr {
    background-color: ${vars.colour.greyDark};
    color: ${vars.colour.white};
  }
`;

const TableBody = styled.tbody`
  color: ${vars.colour.purpleDarkest};

  tr {
    td {
      padding: 20px ${spaceInPx(2)};
      &&& {
        text-overflow: initial;
      }
    }

    &:nth-child(even) {
      background-color: ${vars.colour.greyLightest};
    }

    &:hover {
      background-color: ${vars.colour.purpleLightest};
      cursor: pointer;
    }
  }
`;

const StyledPill = styled(Pill)`
  background-color: ${vars.colour.lightMediumGrey};
  height: 24px;
  text-overflow: none;
  &:before {
    font-size: 2rem;
  }
`;

/**
 * Return `td` containing add and remove Manage Dependents configuration. If not configured,
 * return `-`.
 *
 */
export const ManageDependentsConfiguration = ({
  allowToAddDependents,
  allowToRemoveDependents,
}) => {
  const { formatMessage } = useIntl();
  let configuration = '-';

  if (allowToAddDependents && allowToRemoveDependents) {
    configuration = `${formatMessage({ id: 'ADD' })}, ${formatMessage({
      id: 'REMOVE',
    })}`;
  } else if (allowToAddDependents) {
    configuration = formatMessage({ id: 'ADD' });
  } else if (allowToRemoveDependents) {
    configuration = formatMessage({ id: 'REMOVE' });
  }

  return <td>{configuration}</td>;
};

ManageDependentsConfiguration.propTypes = {
  allowToAddDependents: PropTypes.bool,
  allowToRemoveDependents: PropTypes.bool,
};

ManageDependentsConfiguration.defaultProps = {
  allowToAddDependents: false,
  allowToRemoveDependents: false,
};

/**
 * Return `<td>` containing configuration key id based on qleConfiguration's
 * flags for NEX, Typeform or Manage Dependents. Return `-` if not configured.
 *
 * Configuration to Display Name:
 * Nex -> 'Nex'
 * Typeform -> 'Typeform'
 * Manage Dependents -> 'None', there is a separate Manage Dependents only column
 *
 * Typeform and Manage Dependents can be configured for the same time, don't show
 * 'Typeform, None', since Manage Dependents column exists.
 * @param {qleConfiguration} contains flags for NEX, Typeform and Manage dependents
 */
export const QLEConfigurationDisplay = ({
  allowNex,
  typeformUrl,
  allowToAddDependents,
  allowToRemoveDependents,
}) => {
  const { formatMessage } = useIntl();
  let configuration = '-';

  if (allowNex) {
    configuration = formatMessage({ id: 'NEX' });
  } else if (typeformUrl) {
    configuration = formatMessage({ id: 'TYPEFORM' });
  } else if (allowToAddDependents || allowToRemoveDependents) {
    configuration = formatMessage({ id: 'NONE' });
  }

  return <td>{configuration}</td>;
};

QLEConfigurationDisplay.propTypes = {
  allowNex: PropTypes.bool,
  typeformUrl: PropTypes.string,
  allowToAddDependents: PropTypes.bool,
  allowToRemoveDependents: PropTypes.bool,
};

QLEConfigurationDisplay.defaultProps = {
  allowNex: false,
  typeformUrl: '',
  allowToAddDependents: false,
  allowToRemoveDependents: false,
};

/**
 * Return `<td>` containing days after and days before QLE event date configuration for a QLE.
 * If both are not configured, return only hyphens.
 * If configured show values, where `-1` is infinity.
 */
export const DateRangeConfiguration = ({
  daysAfterQleEventDate,
  daysBeforeQleEventDate,
}) => {
  let formattedDaysAfterQleEventDate = '-';
  let formattedDaysBeforeQleEventDate = '-';
  const isConfiguredQLE =
    daysAfterQleEventDate !== UNSET || daysBeforeQleEventDate !== UNSET;

  if (isConfiguredQLE) {
    // `\u221E` is infinity in unicode
    formattedDaysAfterQleEventDate =
      daysAfterQleEventDate === INFINITY ? '\u221E' : daysAfterQleEventDate;
    formattedDaysBeforeQleEventDate =
      daysBeforeQleEventDate === INFINITY ? '\u221E' : daysBeforeQleEventDate;
  }

  return (
    <td>{`${formattedDaysAfterQleEventDate} | ${formattedDaysBeforeQleEventDate}`}</td>
  );
};

DateRangeConfiguration.propTypes = {
  daysAfterQleEventDate: PropTypes.number,
  daysBeforeQleEventDate: PropTypes.number,
};

DateRangeConfiguration.defaultProps = {
  daysAfterQleEventDate: UNSET,
  daysBeforeQleEventDate: UNSET,
};

/**
 * Return status pill for on/off state.
 */
export const StatusPill = ({ suspended }) => {
  const { formatMessage } = useIntl();

  return (
    <StyledPill color={suspended ? 'grey' : 'blue'}>
      <Text m="0" fontSize={0}>
        {formatMessage({
          id: suspended ? 'OFF_CAPS' : 'ON_CAPS',
        })}
      </Text>
    </StyledPill>
  );
};

StatusPill.propTypes = {
  suspended: PropTypes.bool,
};

StatusPill.defaultProps = {
  suspended: true,
};

const EmphasizedText = ({ children }) => (
  <Text fontWeight="bold" color="purpleDarkest">
    {children}
  </Text>
);

EmphasizedText.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UnconfiguredQLE = ({ onClick, qleType }) => {
  const { formatMessage } = useIntl();

  return (
    <tr onClick={onClick}>
      <td>
        <StatusPill />
      </td>
      <td>
        <EmphasizedText>
          {formatMessage({ id: QLE_MASTER_LIST[qleType] })}
        </EmphasizedText>
      </td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

UnconfiguredQLE.propTypes = {
  onClick: PropTypes.func.isRequired,
  qleType: QLE_MASTER_LIST_PROP_TYPES.isRequired, // This must be one of the keys from the master list!
};

export const ConfiguredQLE = ({ qleConfiguration, onClick, qleType }) => {
  const { formatMessage } = useIntl();

  return (
    <tr onClick={onClick}>
      <td>
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <StatusPill {...qleConfiguration} />
      </td>
      <td>
        <EmphasizedText>
          {formatMessage({ id: QLE_MASTER_LIST[qleType] })}
        </EmphasizedText>
      </td>
      <td>
        {qleConfiguration.needsHrApproval
          ? formatMessage({
              id: qleConfiguration.needsHrApproval ? 'YES' : 'NO',
            })
          : '-'}
      </td>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <QLEConfigurationDisplay {...qleConfiguration} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ManageDependentsConfiguration {...qleConfiguration} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <DateRangeConfiguration {...qleConfiguration} />
      <td>
        <Text m="0" fontSize={1}>
          {moment(qleConfiguration.createdAt).format('DD MMM, YYYY') || '-'}
        </Text>
        <Text m="0" color="greyDark" fontSize={0}>
          {moment(qleConfiguration.createdAt).format('hh:mm A') || '-'}
        </Text>
      </td>
    </tr>
  );
};

const qleConfigurationPropTypes = PropTypes.shape({
  allowNex: PropTypes.bool,
  allowToAddDependents: PropTypes.bool,
  allowToRemoveDependents: PropTypes.bool,
  benefitClassId: PropTypes.string,
  createdAt: PropTypes.string,
  daysBeforeQleEventDate: PropTypes.number,
  daysAfterQleEventDate: PropTypes.number,
  groupId: PropTypes.string,
  needsHrApproval: PropTypes.bool,
  qleConfigurationId: PropTypes.string,
  qualifyingLifeEventType: PropTypes.string,
  typeformUrl: PropTypes.string,
  suspended: PropTypes.bool,
});

ConfiguredQLE.propTypes = {
  qleConfiguration: qleConfigurationPropTypes.isRequired,
  onClick: PropTypes.func.isRequired,
  qleType: QLE_MASTER_LIST_PROP_TYPES.isRequired, // This must be one of the keys from the master list!
};

const CapitalizedText = styled(Text)`
  text-transform: uppercase;
`;

const CustomMarginBottomText = styled(Text)`
  &&& {
    margin-bottom: 24px;
  }
`;

export const QLEConfigurationTable = ({
  qleConfigurations,
  history,
  match,
  setQLETypeAndId,
  benefitClass,
}) => {
  const { formatMessage } = useIntl();
  if (!qleConfigurations || !benefitClass) {
    return <LoadingIndicator />;
  }

  return (
    <QLEConfigurationCard>
      <Box px={3} mt={3}>
        <CapitalizedText
          fontSize={1}
          fontWeight="bold"
          lineHeight="16px"
          color="greyDark"
          mb="4px"
        >
          {formatMessage({ id: 'QLE_CONFIGURATIONS' })}
        </CapitalizedText>
        <Text
          fontSize={4}
          color="purpleText"
          fontWeight="bold"
          lineHeight="26px"
          mb={1}
        >
          {benefitClass.name}
        </Text>
        <CustomMarginBottomText fontSize={2} lineHeight="24px">
          {formatMessage({ id: 'INDIVIDUALLY_CONFIGURE_QLES' })}
        </CustomMarginBottomText>
      </Box>
      <Table>
        <TableHead>
          <tr>
            <TableHeaderCell width="10%">
              {formatMessage({ id: 'STATUS' })}
            </TableHeaderCell>
            <TableHeaderCell width="30%">
              {formatMessage({ id: 'QLE_TYPE' })}
            </TableHeaderCell>
            <TableHeaderCell width="8%">
              {formatMessage({ id: 'HR' })}
            </TableHeaderCell>
            <TableHeaderCell>
              {formatMessage({ id: 'ELECTIONS' })}
            </TableHeaderCell>
            <TableHeaderCell>
              {formatMessage({ id: 'DEPENDENTS' })}
            </TableHeaderCell>
            <TableHeaderCell width="8%">
              {formatMessage({ id: 'NUM_DAYS' })}
            </TableHeaderCell>
            <TableHeaderCell>
              {formatMessage({ id: 'LAST_UPDATED' })}
            </TableHeaderCell>
          </tr>
        </TableHead>
        <TableBody>
          {Object.keys(QLE_MASTER_LIST).map(qleType => {
            const qleConfiguration = qleConfigurations.find(
              ({ qualifyingLifeEventType }) =>
                qualifyingLifeEventType === qleType,
            );

            const goToConfigurationForm = () => {
              if (qleConfiguration) {
                setQLETypeAndId({
                  qleType,
                  qleConfigurationId: qleConfiguration.qleConfigurationId,
                });
              }

              // If a configured QLE is selected, include the config's id in the url to support refresh and returning to form
              history.push(
                // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                `${match.url}/${qleType}${
                  qleConfiguration
                    ? `/${qleConfiguration.qleConfigurationId}`
                    : ''
                }`,
              );
            };

            if (!qleConfiguration) {
              return (
                <UnconfiguredQLE
                  key={qleType}
                  qleType={qleType}
                  onClick={goToConfigurationForm}
                />
              );
            }

            return (
              <ConfiguredQLE
                key={qleType}
                qleConfiguration={qleConfiguration}
                qleType={qleType}
                onClick={goToConfigurationForm}
              />
            );
          })}
        </TableBody>
      </Table>
    </QLEConfigurationCard>
  );
};

QLEConfigurationTable.propTypes = {
  qleConfigurations: PropTypes.arrayOf(qleConfigurationPropTypes),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  setQLETypeAndId: PropTypes.func.isRequired,
  benefitClass: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

QLEConfigurationTable.defaultProps = {
  qleConfigurations: [],
  benefitClass: null,
};
