import { GET_USER_REWARDS_HISTORY } from './rewards-history-page.action-types';
import { LIMITS } from './rewards-history-page.constants';

export const initialState = {
  isLoading: false,
  hasErrored: false,
  areMoreAvailable: false,
  offset: 0,
  transactions: null,
};

const isMoreAvailable = (state, transactions) =>
  transactions.length === LIMITS.REWARDS_HISTORY + state.offset;

export const rewardsHistoryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_REWARDS_HISTORY.BASE:
      if (action.payload.offset === 0) {
        return { ...initialState };
      }

      return {
        ...state,
        offset: action.payload.offset,
      };
    case GET_USER_REWARDS_HISTORY.STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_REWARDS_HISTORY.SUCCEEDED: {
      const newTransactions = action.payload.info.transactions ?? [];
      const transactions = [...(state.transactions ?? []), ...newTransactions];

      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        areMoreAvailable: isMoreAvailable(state, transactions),
        transactions,
      };
    }
    case GET_USER_REWARDS_HISTORY.ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
      };
    default:
      return state;
  }
};
