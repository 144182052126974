import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { SIGN_OUT } from 'apps/auth/auth.actions';
import { idleTimerChannel } from './idle-timer-channel';

import { APP_CONFIG } from 'app-config';

import {
  selectUserIsClaimsAgent,
  selectUserIsHealthAdmin,
} from 'common/state/user/role.selectors';
import { GET_USER_PROFILE } from '../state/user/user.types';
import { FIFTEEN_MINUTES, THIRTY_MINUTES } from './privacy-lock.constants';
import {
  RESET_MAX_IDLE_TIME,
  SET_CHAT_MAX_IDLE_TIME,
} from './privacy-lock.actions';
import { setMaxIdleTime } from './idle-timer';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { captureError } from '@leagueplatform/observability';

const timeoutAnalyticsParams = {
  category: 'Privacy Lock',
  action: 'Session Timeout',
  label: 'Client initiated',
};
const parseBool = bool => {
  switch (bool) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return Boolean(bool);
  }
};

export const getIsPrivacyLockEnabled = () =>
  typeof APP_CONFIG.REACT_APP_IS_PRIVACY_LOCK_ENABLED === 'undefined'
    ? true
    : parseBool(APP_CONFIG.REACT_APP_IS_PRIVACY_LOCK_ENABLED);

export function* getConfigMaxIdleTime() {
  // Claims Agent selector also checks for ADMIN role
  const isClaimsAgent = yield select(selectUserIsClaimsAgent);
  const isHealthAdmin = yield select(selectUserIsHealthAdmin);
  const userShouldUseAdminPrivacyLockTimer = isClaimsAgent || isHealthAdmin;

  if (userShouldUseAdminPrivacyLockTimer) {
    return typeof APP_CONFIG.REACT_APP_ADMIN_PRIVACY_LOCK_MAX_IDLE_TIME ===
      'undefined'
      ? THIRTY_MINUTES
      : parseInt(APP_CONFIG.REACT_APP_ADMIN_PRIVACY_LOCK_MAX_IDLE_TIME, 10);
  }
  return typeof APP_CONFIG.REACT_APP_PRIVACY_LOCK_MAX_IDLE_TIME === 'undefined'
    ? FIFTEEN_MINUTES // check if number
    : parseInt(APP_CONFIG.REACT_APP_PRIVACY_LOCK_MAX_IDLE_TIME, 10);
}

export function* setChatMaxIdleTime() {
  const maxChatIdleTime =
    typeof APP_CONFIG.REACT_APP_CHAT_PRIVACY_LOCK_MAX_IDLE_TIME === 'undefined'
      ? THIRTY_MINUTES
      : parseInt(APP_CONFIG.REACT_APP_CHAT_PRIVACY_LOCK_MAX_IDLE_TIME, 10);
  yield call(setMaxIdleTime, maxChatIdleTime);
}

export function* signOutUser() {
  yield put(SIGN_OUT.request({ timeout: 1 }));
}

export function* resetMaxIdleTime() {
  const maxIdleTime = yield call(getConfigMaxIdleTime);
  yield call(setMaxIdleTime, maxIdleTime);
}

export function* initIdleTimer() {
  try {
    const enabled = getIsPrivacyLockEnabled();
    if (enabled) {
      yield take(GET_USER_PROFILE.SUCCEEDED);
      const maxIdleTime = yield call(getConfigMaxIdleTime);
      const channel = yield call(idleTimerChannel, {
        maxIdleTime,
      });

      yield take(channel);
      yield call(signOutUser);
      yield call(sendAnalyticsEvent, timeoutAnalyticsParams);
    }
  } catch (error) {
    captureError(new Error(`Privacy Lock has failed: ${error}`));
  }
}

export function* privacyLockSaga() {
  yield takeLatest(RESET_MAX_IDLE_TIME, resetMaxIdleTime);
  yield takeLatest(SET_CHAT_MAX_IDLE_TIME, setChatMaxIdleTime);
  yield call(initIdleTimer);
}
