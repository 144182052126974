import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getQleRequest - calls `get_qle_request` for displaying user transactions in wallet.
 *
 * @arg {string} qleId  - used to fetch information related to the QLE request by a member
 */

export const getQleRequest = ({ qleId }) =>
  call(websocketFetch, 'get_qle_request', {
    qle_request_id: qleId,
  });
