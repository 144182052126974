import { createRequestTypes } from 'common/websocket-redux';

export const GET_WALLET = createRequestTypes('wallet/GET_WALLET');
export const GET_WALLET_DEPENDENT_CARDS = createRequestTypes(
  'wallet/GET_WALLET_DEPENDENT_CARDS',
);
export const GET_TRANSACTIONS = createRequestTypes('wallet/GET_TRANSACTIONS');
export const REDEEM_PROMO_CODE = createRequestTypes('wallet/REDEEM_PROMO_CODE');
export const GET_LEAGUE_CREDIT_BALANCE = createRequestTypes(
  'wallet/GET_LEAGUE_CREDIT_BALANCE',
);
export const LOAD_WALLET = 'wallet/LOAD_WALLET';
export const LOAD_WALLET_DEPENDENT_CARDS = 'wallet/LOAD_WALLET_DEPENDENT_CARDS';
export const BENEFIT_ID_UPDATED = 'wallet/BENEFIT_ID_UPDATED';
export const PROMO_CODE_REDEEMED = 'wallet/PROMO_CODE_REDEEMED';
export const LOAD_LEAGUE_CREDIT_BALANCE = 'wallet/LOAD_LEAGUE_CREDIT_BALANCE';
export const MESSAGE_DISMISSED = 'wallet/MESSAGE_DISMISSED';
export const VIEW_DEPENDENT_CARD = 'wallet/VIEW_DEPENDENT_CARD';
export const CLOSE_VIEW_DEPENDENT_CARD = 'wallet/CLOSE_VIEW_DEPENDENT_CARD';
export const EMAIL_CUSTOMER_SUPPORT = 'wallet/EMAIL_CUSTOMER_SUPPORT';
export const EMAIL_LOCATION_OPENED = 'wallet/EMAIL_LOCATION_OPENED';
