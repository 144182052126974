import { createRequestTypes } from 'common/websocket-redux';

export const ADD_EMPLOYER_BENEFIT_DOCUMENT =
  'benefits/ADD_EMPLOYER_BENEFIT_DOCUMENT';

export const UPDATE_EMPLOYER_BENEFIT_DOCUMENT =
  'benefits/UPDATE_EMPLOYER_BENEFIT_DOCUMENT';

export const SET_EMPLOYER_BENEFIT_DOCUMENT = createRequestTypes(
  'service/SET_EMPLOYER_BENEFIT_DOCUMENT',
);
