import { createValidator } from 'common/adaptive-forms';
import { set, get } from 'lodash';

export const GROUP_MEMBERSHIP_FIELD = 'group_membership_field';
const USER_PROFILE_FIELD = 'user_profile_field';

export function getIdentifierType(identifier) {
  return identifier.group_membership_field
    ? GROUP_MEMBERSHIP_FIELD
    : USER_PROFILE_FIELD;
}

export function getIdentifierName(identifier) {
  const type = getIdentifierType(identifier);
  return `${type}.${identifier[type]}`;
}

/**
 * Convert an identifier into a format that makes it
 * a bit easier to work together with input forms.
 */

export const transformIdentifier = ({
  schema,
  more_info: moreInfo,
  ...identifier
}) => {
  const type = getIdentifierType(identifier);
  const accessor = identifier[type];

  return {
    ...schema,
    moreInfo,
    type,
    accessor,
    name: getIdentifierName(identifier),
  };
};

/**
 * The backend unique identifiers shape needs to be modified to work with the existing validator.
 */
export function createUniqueIdentifersValidator(uniqueIdentifiers) {
  const normalized = uniqueIdentifiers.reduce((schema, identifier) => {
    const name = getIdentifierName(identifier);
    return { ...schema, [name]: { ...identifier.schema, field_name: name } };
  }, {});
  return createValidator(normalized);
}

export const mergeUniqueIdentifierValuesWithFields = (values, fields) =>
  values.map((value, index) => ({ ...fields[index], ...value }));

export const flattenNestedFields = identifier => ({
  [identifier.type]: {
    field: identifier.accessor,
    value: get(identifier, identifier.name),
  },
});

/**
 * uniqueIdentifiers need to be passed as an array of nested objects like so:
 * [{group_membership_field: {field: “employer_issued_employee_id”, value: “00004568”}}]
 *
 * but they come in from formik as objects like so:
 * {group_membership_field: { employer_issued_employee_id: “00004568” }}
 * or
 * [{group_membership_field: { custom_fields: { life_member_id: "“00004568”"}}}]
 */

export const extractUniqueIdentifiers = uniqueIdentifiers =>
  uniqueIdentifiers?.map(flattenNestedFields);

/**
 * In order for Formik to validate empty fields on Submit only,
 * and not after a field has been touched/blured out,
 * we need to provide initial values in the form.
 * Take uniqueIdentifiers array from the group_config schema
 * and return them as field names with empty strings
 */

export const setUniqueIdentifiersInitialValues = identifiers => {
  return {
    uniqueIdentifiers: identifiers?.map(identifier => {
      return set({}, getIdentifierName(identifier), '');
    }),
  };
};
