import { selectResourcesConfig } from 'apps/invite-access/invite-access.selectors';
import { connect } from 'react-redux';
import { signInSelected, helpEmailSelected } from '../../invite-access.actions';
import { AccountAlreadyActive } from './account-already-active.view';

export const AccountAlreadyActiveContainer = connect(
  state => ({
    config: selectResourcesConfig(state),
  }),
  {
    signInSelected,
    helpEmailSelected,
  },
)(AccountAlreadyActive);
