import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from '@leagueplatform/locales';
import { Box, BodyOne } from '@leagueplatform/genesis-commons';
import { SidebarLayout } from '../sidebar-layout.view';
import { StepPage } from '../step-page.view';
import { PageHeading, ResponsiveButton } from '../elements';
import { useVerifyAccount } from './use-verify-account.hook';
import { INVITE_NOT_VERIFIED_PAGE_LIFECYCLE } from 'apps/invite-access/invite-access.actions';

export const InviteNotVerified = () => {
  const { formatMessage } = useIntl();
  const { onVerifyClick } = useVerifyAccount();
  const { employerIdentifier } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(INVITE_NOT_VERIFIED_PAGE_LIFECYCLE.visit({ employerIdentifier }));
    return () =>
      dispatch(INVITE_NOT_VERIFIED_PAGE_LIFECYCLE.exit({ employerIdentifier }));
  }, [dispatch, employerIdentifier]);

  return (
    <SidebarLayout>
      <StepPage>
        <Box maxWith={960}>
          <PageHeading>
            {formatMessage({ id: 'NOT_VERIFIED_HEADING' })}
          </PageHeading>
          <BodyOne marginTop="two">
            {formatMessage({ id: 'NOT_VERIFIED_TEXT' })}
          </BodyOne>
          <ResponsiveButton type="button" onClick={onVerifyClick}>
            {formatMessage({ id: 'START_VERIFICATION' })}
          </ResponsiveButton>
        </Box>
      </StepPage>
    </SidebarLayout>
  );
};
