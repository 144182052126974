import React from 'react';
import styled from 'styled-components';
import { vars, Box, Heading } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { ROOT_PATH } from './paths';
import { FormattedMessage } from 'react-intl';
import { Card } from './ui-kit/card.view';

const BackButton = styled(Button)`
  margin: 0 auto;
`;

export const ErrorMessage = () => (
  <Flex
    justify="center"
    align="center"
    style={{
      minHeight: '100vh',
      backgroundColor: vars.colour.greyLightest,
    }}
  >
    <Card width={1 / 2} marginY={0} paddingY="two" textAlign="center">
      <Heading level={2} style={{ marginTop: 0 }}>
        <FormattedMessage id="INTERNAL_ERROR_TITLE" />
      </Heading>
      <Box my={3}>
        <FormattedMessage id="INTERNAL_ERROR_PROMPT" />
      </Box>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */}
      <a href={ROOT_PATH}>
        <BackButton primary>
          <FormattedMessage id="INTERNAL_ERROR_CTA" />
        </BackButton>
      </a>
    </Card>
  </Flex>
);
