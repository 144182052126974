import { NEW_HR_ADMIN_LINK } from '@leagueplatform/web-common';

export const getHRAdminAnalytics = () => {
  const params = window.location.pathname;

  // remove any ids, and the duplicate or trailing slashes
  const newParam = params
    ?.replace(/[a-z]*\d+[a-z]*/gi, '')
    .replace(/\/+/g, '/')
    .replace(/\/+$/, '');

  const split = newParam.replace(NEW_HR_ADMIN_LINK, '').split('/');
  split.shift();

  const finalItem = split.map(str => str.replace('-', ' '));
  return finalItem;
};
