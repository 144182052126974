import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  LabelText,
  Box,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { ErrorMessage } from './error-message.view';
import { Required } from './required.view';

export const FieldGroupWrapper = ({
  title,
  description,
  isRequired,
  name,
  children,
  ...props
}) => (
  <Box width={1} marginY="one">
    <Label as="fieldset">
      <LabelText display="flex" width={1} as="legend">
        {title || name}
        {isRequired && <Required />}
      </LabelText>

      {description && (
        <BodyTwoSecondary marginBottom="half">{description}</BodyTwoSecondary>
      )}
      {children}
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ErrorMessage {...props} />
    </Label>
  </Box>
);

FieldGroupWrapper.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  isFieldGroup: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FieldGroupWrapper.defaultProps = {
  title: null,
  description: null,
  isRequired: false,
  isFieldGroup: false,
};
