import { takeLatest } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  FETCH_BENEFIT_CLASS_TYPEFORM_URLS,
  GET_BENEFIT_CLASS_TYPEFORM_URLS,
  ADD_BENEFIT_CLASS_TYPEFORM_URLS,
  SET_BENEFIT_CLASS_TYPEFORM_URLS,
} from './employer-integrations.types';

export function* getBenefitClassTypeformUrls(action) {
  const { groupId } = action.payload;
  yield request(GET_BENEFIT_CLASS_TYPEFORM_URLS, [
    websocketFetch,
    'get_benefit_class_typeform_urls',
    {
      group_id: groupId,
    },
  ]);
}

export function* setBenefitClassTypeformUrls(action) {
  const { groupId, benefitClasses } = action.payload;
  yield request(SET_BENEFIT_CLASS_TYPEFORM_URLS, [
    websocketFetch,
    'set_benefit_class_typeform_urls',
    {
      group_id: groupId,
      benefit_classes: benefitClasses,
    },
  ]);
}

export function* employerIntegrationsSaga() {
  yield takeLatest(
    FETCH_BENEFIT_CLASS_TYPEFORM_URLS,
    getBenefitClassTypeformUrls,
  );
  yield takeLatest(
    ADD_BENEFIT_CLASS_TYPEFORM_URLS,
    setBenefitClassTypeformUrls,
  );
}
