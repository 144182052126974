import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

const ScrollContext = React.createContext({});

const debouncedScroll = debounce(window.scrollTo, 500);

const scrollTo = (top = 0, left = 0) => {
  debouncedScroll({
    top,
    left,
    behavior: 'smooth',
  });
};

const contextValue = { scrollTo };

export const ScrollContextComponent = ({ children }) => {
  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};

ScrollContextComponent.propTypes = {
  children: PropTypes.element.isRequired,
};

export const ScrollContextConsumer = ScrollContext.Consumer;
