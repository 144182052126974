export default {
  employeeGWLMedical: {
    name: 'employeeGWLMedical',
    groups: [
      {
        title: 'Member Policy Details',
        fields: [
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Employer Policy Details',
        fields: [
          {
            name: 'parent.benefit_start_date',
            span: 6,
          },
          {
            name: 'parent.benefit_end_date',
            span: 6,
          },
          {
            name: 'parent.policy_id',
            span: 6,
          },
          {
            name: 'parent.documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'parent.prevent_default_allocation',
            span: 4,
          },
          {
            name: 'parent.opt_out',
            span: 4,
          },
          {
            name: 'parent.benefit_waiting_period_months',
            span: 4,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'parent.benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'parent.auto_renew',
            span: 6,
          },
        ],
      },
      {
        title: 'Description of Coverage',
        fields: [
          {
            name: 'parent.accidental_dental_included',
            span: 6,
          },
          {
            name: 'parent.ambulance_included',
            span: 6,
          },
          {
            name: 'parent.deductible_family',
            span: 6,
          },
          {
            name: 'parent.deductible_individual',
            span: 6,
          },
          {
            name: 'parent.deductible_individual_combined',
            span: 6,
          },
          {
            name: 'parent.deductible_single',
            span: 6,
          },
          {
            name: 'parent.diagnostic_health_services_included',
            span: 6,
          },
          {
            name: 'parent.eye_exam_glasses_and_contacts_combined_maximum',
            span: 6,
          },
          {
            name: 'parent.eye_exam_included',
            span: 6,
          },
          {
            name: 'parent.glasses_and_contacts_interval_months',
            span: 6,
          },
          {
            name: 'parent.glasses_and_contacts_maximum',
            span: 6,
          },
          {
            name: 'parent.hearing_aids_included',
            span: 6,
          },
          {
            name: 'parent.home_nursing_maximum',
            span: 6,
          },
          {
            name: 'parent.hospital_room_type',
            span: 6,
          },
          {
            name: 'parent.laser_surgery_included',
            span: 6,
          },
          {
            name: 'parent.maximum_amount',
            span: 6,
          },
          {
            name: 'parent.maximum_applies',
            span: 6,
          },
          {
            name: 'parent.medical_supplies_included',
            span: 6,
          },
          {
            name: 'parent.orthopaedic_shoes_and_orthotics_combined_interval_months',
            span: 6,
          },
          {
            name: 'parent.orthopaedic_shoes_and_orthotics_combined_maximum',
            span: 6,
          },
          {
            name: 'parent.orthopaedic_shoes_interval_months',
            span: 6,
          },
          {
            name: 'parent.orthopaedic_shoes_maximum',
            span: 6,
          },
          {
            name: 'parent.orthotics_interval_months',
            span: 6,
          },
          {
            name: 'parent.orthotics_maximum',
            span: 6,
          },
          {
            name: 'parent.prescribed_leg_and_knee_brace_interval_months',
            span: 6,
          },
          {
            name: 'parent.prescribed_leg_and_knee_brace_maximum',
            span: 6,
          },
          {
            name: 'parent.reimbursement_percent',
            span: 6,
          },
          {
            name: 'parent.speech_aids_included',
            span: 6,
          },
          {
            name: 'parent.vision_care_included',
            span: 6,
          },
          {
            name: 'parent.vision_care_reimbursement_percent',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'id',
            span: 6,
          },
          {
            name: 'user_id',
            span: 6,
          },
          {
            name: 'grace_period_start_date',
            span: 6,
          },
          {
            name: 'inactive_date',
            span: 6,
          },
          {
            name: 'reinstated_date',
            span: 6,
          },
          {
            name: 'parent.provider_type',
            span: 6,
          },
          {
            name: 'parent.provider_id',
            span: 6,
          },
          {
            name: 'parent.vendor',
            span: 6,
          },
          {
            name: 'parent.benefit_type',
            span: 6,
          },
          {
            name: 'parent.benefit_product_type',
            span: 6,
          },
          {
            name: 'parent.id',
            span: 6,
          },
          {
            name: 'parent.benefit_status',
            span: 6,
          },
          {
            name: 'parent.plan_id',
            span: 6,
          },
          {
            name: 'parent.benefit_vendor_id',
            span: 6,
          },
          {
            name: 'parent.renewed_from',
            span: 6,
          },
          {
            name: 'parent.dependent_classes',
            span: 12,
          },
        ],
      },
    ],
  },
};
