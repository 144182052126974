import { concat, flatMap, isObjectLike, join, tail, values } from 'lodash';
import { map, omit, pipe, pullAll } from 'lodash/fp';
import { transformSchemaField } from './transform-schema-field';
import { sortByPosition } from './sort-fields';

const hasChildren = fieldSpec =>
  fieldSpec.json_type === 'object' && isObjectLike(fieldSpec.object);

export const traverse = (
  schemaField,
  parentSpec = { path: [], label: [], position: [] },
  fields = [],
) => {
  const spec = transformSchemaField(parentSpec)(schemaField);
  return hasChildren(spec)
    ? flatMap(values(schemaField.object), subSpec =>
        traverse(subSpec, spec, fields),
      )
    : concat(fields, [spec]);
};

export const withRoot = spec => ({
  field_name: '_ROOT_',
  json_type: 'object',
  object: spec,
});

export const assignflatLabel = spec => ({
  ...spec,
  label: join(tail(spec.label), ' '),
});

export const assignflatPath = spec => ({
  ...spec,
  path: join(tail(spec.path), '.'),
});

export const trimPosition = spec => ({
  ...spec,
  position: tail(spec.position),
});

export const trimRootProps = pipe(
  assignflatLabel,
  assignflatPath,
  trimPosition,
);

const omitLabel = labelsToBeOmitted => spec => ({
  ...spec,
  label: pullAll(labelsToBeOmitted, spec.label),
});

const omitProps = omit(['position']);

export const createSchemaParser = labelsToBeOmitted =>
  pipe(
    withRoot,
    traverse,
    map(omitLabel(labelsToBeOmitted)),
    map(trimRootProps),
    sortByPosition,
    map(omitProps),
  );
