import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  TextInput,
  Label,
  ErrorMessage,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { InputFieldGroup } from '../elements';

export const TextField = ({ name, label }) => (
  <InputFieldGroup>
    <Field name={name}>
      {({ field, meta: { error, touched } }) => {
        const hasError = Boolean(touched && error);
        return (
          <>
            <Label>
              <LabelText as="span">{label}</LabelText>
              <TextInput
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...field}
                id={name}
                error={hasError}
                aria-invalid={hasError || undefined}
                aria-describedby={`${name}_error`}
                required
              />
            </Label>
            {error && touched && (
              <ErrorMessage as="span" role="alert" id={`${name}_error`}>
                {error}
              </ErrorMessage>
            )}
          </>
        );
      }}
    </Field>
  </InputFieldGroup>
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
