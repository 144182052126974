import { takeLatest } from 'redux-saga/effects';
import { getUserPaymentCredentials } from './get-user-payment-credentials.service';
import { GET_USER_PAYMENT_CREDENTIALS } from './payment-credentials.actions';
import { request } from 'common/websocket-redux';

export function* requestUserPaymentCredentials() {
  yield request(GET_USER_PAYMENT_CREDENTIALS, getUserPaymentCredentials());
}

export function* paymentCredentialsSaga() {
  yield takeLatest(
    GET_USER_PAYMENT_CREDENTIALS.BASE,
    requestUserPaymentCredentials,
  );
}
