import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import AddEditAdjustment from './add-edit-adjustment.view';
import {
  // eslint-disable-next-line import/no-named-default
  default as Adjustments,
  PaymentAdjustmentItem as Adjustment,
} from '../payments-adjustments-list.view';
import { injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import { find, get } from 'lodash';
import { BILLING_PREFERENCE_CONSOLIDATED } from 'apps/employer-experience/pages/EmployerDetails/Billing/constants';

const EmployerAdjustmentsPage = ({
  groupId,
  adjustments,
  adding,
  intl,
  removeAdjustment,
  billingDivisions,
  billingPreference,
}) => (
  <Dimmer.Dimmable as={Box}>
    <AddEditAdjustment loading={adding} groupId={groupId} />
    <Adjustments
      list={adjustments}
      emptyMessage={intl.formatMessage({ id: 'NO_ADJUSTMENTS' })}
      isConsolidated={billingPreference === BILLING_PREFERENCE_CONSOLIDATED}
      renderItem={({
        id,
        amount,
        billingDivisionId,
        note,
        date,
        isConsolidated,
      }) => (
        <Adjustment
          key={id}
          id={id}
          amount={amount}
          billingDivision={get(
            find(billingDivisions, ['billingDivisionId', billingDivisionId]),
            'name',
          )}
          note={note}
          date={date}
          remove={() => removeAdjustment({ id, group_id: groupId })}
          isConsolidated={isConsolidated}
        />
      )}
    />
  </Dimmer.Dimmable>
);

EmployerAdjustmentsPage.propTypes = {
  intl: intlShape.isRequired,
  groupId: PropTypes.string.isRequired,
  adding: PropTypes.bool.isRequired,
  removeAdjustment: PropTypes.func.isRequired,
  adjustments:
    PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
  billingDivisions:
    PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
  billingPreference: PropTypes.string.isRequired,
};

export default injectIntl(EmployerAdjustmentsPage);
