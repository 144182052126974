/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { createSelector } from 'reselect';
import {
  selectEmployerDetails,
  selectEmployerBenefits,
  selectEmployerTimeZone,
} from '../selectors';
import { selectBenefitPlanEntities } from '../Benefits/BenefitClass/Plans/selectors';
import { selectPayrollSchedulesWithPlanPeriods } from 'apps/employer-experience-v2/employer-reports/components/premiums-report/premiums-report.selectors';
import { format, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const selectEmployerPayroll = createSelector(
  selectEmployerDetails,
  employerDetails => employerDetails?.employerPayroll ?? {},
);

export const selectRateSheetExtract = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.rateSheetExtract,
);

export const selectRateSheetExtractStatus = createSelector(
  selectRateSheetExtract,
  rateSheetExtract => rateSheetExtract?.status,
);

export const selectRateSheetExtractContentId = createSelector(
  selectRateSheetExtract,
  rateSheetExtract => rateSheetExtract?.contentId,
);

export const selectRateSheetExtractFileName = createSelector(
  selectRateSheetExtract,
  rateSheetExtract => rateSheetExtract?.fileName,
);

const getPlanPeriodString = (plan, timeZone) => {
  if (!plan?.planPeriod) {
    return '';
  }
  const planStart = format(
    utcToZonedTime(plan.planPeriod.startDate, timeZone),
    'yyyy',
  );
  const planEnd = format(
    // sub one day to keep jan 1 2020 to jan 1 2021 as "2020" plan year
    subDays(utcToZonedTime(plan.planPeriod.endDate, timeZone), 1),
    'yyyy',
  );

  return `${planStart}${planStart !== planEnd ? ` - ${planEnd}` : ''}`;
};

const sortByName = (a, b) => {
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1;
  }
  if (a.name.toUpperCase() < b.name.toUpperCase()) {
    return -1;
  }
  return 0;
};

// group benefits by benefit class name
const getBenefitsByBenefitClass = benefits =>
  benefits.reduce((benefitClassMap, benefit) => {
    const benefitList = [
      ...(benefitClassMap[benefit.className] ?? []),
      benefit,
    ];
    return { ...benefitClassMap, [benefit.className]: benefitList };
  }, {});

// group benefits by benefit class name -> benefit plan period
const getBenefitsByBenefitClassAndPeriod = (
  benefitsByClass,
  benefitPlans,
  timeZone,
) => {
  const benefitsByClassAndPeriod = {};
  Object.keys(benefitsByClass).forEach(className => {
    benefitsByClassAndPeriod[className] = benefitsByClass[className].reduce(
      (benefitPeriodMap, benefit) => {
        const planPeriod = getPlanPeriodString(
          benefitPlans[benefit.planId],
          timeZone,
        );

        const benefitList = [...(benefitPeriodMap[planPeriod] ?? []), benefit];
        return { ...benefitPeriodMap, [planPeriod]: benefitList };
      },
      {},
    );
  });

  return benefitsByClassAndPeriod;
};

const getTreeFromBenefitClassAndPeriodMap = benefitsByClassAndPeriod =>
  Object.entries(benefitsByClassAndPeriod).map(([className, planPeriodMap]) => {
    return {
      name: className === 'undefined' ? 'Default' : className,
      hasCheckBox: true,
      children: Object.entries(planPeriodMap).map(
        ([planPeriod, planPeriodBenefits]) => {
          return {
            name: `${
              className === 'undefined' ? 'Default' : className
            } ${planPeriod}`,
            hasCheckBox: true,
            children: planPeriodBenefits.map(benefit => ({
              name: benefit.name,
              benefitId: benefit.id,
              hasCheckBox: true,
            })),
          };
        },
      ),
    };
  });

const addLabelAndId = ([id, benefit]) => {
  const employerBenefitLabel = [
    benefit.productName,
    benefit.planName,
    benefit.className ?? 'Default',
    benefit.fullName,
  ]
    .filter(item => item)
    .join(' ');

  return {
    ...benefit,
    id,
    name: employerBenefitLabel,
  };
};

export const selectEmployerBenefitClassTree = createSelector(
  selectEmployerBenefits,
  selectBenefitPlanEntities,
  selectEmployerTimeZone,
  (benefits, benefitPlans, timeZone) => {
    // need to wait for benefitPlans
    if (Object.keys(benefitPlans).length === 0) {
      return {
        name: 'Benefit Classes',
        toggled: true,
        children: [],
      };
    }

    const benefitsWithLabels = Object.entries(benefits)
      .map(addLabelAndId)
      .sort(sortByName);
    const benefitsByBenefitClass =
      getBenefitsByBenefitClass(benefitsWithLabels);
    const benefitsByClassAndPeriod = getBenefitsByBenefitClassAndPeriod(
      benefitsByBenefitClass,
      benefitPlans,
      timeZone,
    );

    return {
      name: 'Benefit Classes',
      toggled: true,
      children: getTreeFromBenefitClassAndPeriodMap(benefitsByClassAndPeriod),
    };
  },
);

export const selectBenefitContributions = createSelector(
  selectEmployerPayroll,
  employerPayroll => {
    return employerPayroll?.benefitContributions;
  },
);

export const selectContributionAccounts = createSelector(
  selectBenefitContributions,
  selectEmployerTimeZone,
  (benefitContributions, timeZone) =>
    benefitContributions?.contributionAccounts?.map(
      ({ benefitName, benefit_start_date, benefit_end_date, ...account }) => {
        const startDate = format(
          utcToZonedTime(benefit_start_date, timeZone),
          'MMM d, yyyy',
        );
        const endDate = format(
          utcToZonedTime(benefit_end_date, timeZone),
          'MMM d, yyyy',
        );
        return {
          ...account,
          label: `${benefitName} ${startDate} - ${endDate}`,
        };
      },
    ) ?? [],
);

export const selectContributionsUser = createSelector(
  selectBenefitContributions,
  benefitContributions => benefitContributions?.user ?? {},
);

export const selectContributions = createSelector(
  selectBenefitContributions,
  benefitContributions => benefitContributions?.contributions ?? [],
);

export const selectPlanPeriodsFromPlans = createSelector(
  selectBenefitPlanEntities,
  selectEmployerTimeZone,
  (benefitPlans, timeZone) => {
    const uniqueBenefitPlansObject = Object.values(benefitPlans)
      .filter(({ planPeriod }) => Boolean(planPeriod))
      .reduce((acc, { planPeriod }) => {
        const startDate = format(
          utcToZonedTime(planPeriod.startDate, timeZone),
          'MMM d, yyyy',
        );
        const endDate = format(
          utcToZonedTime(planPeriod.endDate, timeZone),
          'MMM d, yyyy',
        );
        const label = `${startDate} - ${endDate}`;
        acc[label] = {
          label,
          planPeriod: {
            start_date: planPeriod.startDate,
            end_date: planPeriod.endDate,
          },
          value: label,
        };
        return acc;
      }, {});
    return Object.values(uniqueBenefitPlansObject);
  },
);

export const selectPayrollSchedulesByPlanPeriod = createSelector(
  selectPayrollSchedulesWithPlanPeriods,
  payrollSchedules => {
    const schedulesByPlanPeriod = {};
    payrollSchedules.forEach(schedule => {
      schedule.plan_periods.forEach(planPeriod => {
        const years = planPeriod.split(' - ').map(date => date.slice(-4));
        const planYear = `${years[0]}${
          years[0] !== years[1] ? ` - ${years[1]}` : ''
        }`;

        schedulesByPlanPeriod[planYear] = schedulesByPlanPeriod[planYear]
          ? [...schedulesByPlanPeriod[planYear], schedule]
          : [schedule];
      });
    });
    return Object.entries(schedulesByPlanPeriod).map(
      ([planYear, schedules]) => ({ schedules, planYear }),
    );
  },
);

export const selectPayPeriodByLabel = createSelector(
  (_, { payrollScheduleId, payPeriod }) => ({ payrollScheduleId, payPeriod }),
  selectPayrollSchedulesWithPlanPeriods,
  ({ payrollScheduleId, payPeriod }, payrollSchedules) => {
    const payCalendar = payrollSchedules.find(
      schedule => schedule.id === payrollScheduleId,
    );
    if (!payCalendar) {
      return undefined;
    }
    // return the matching pay period if there is a schedule
    return {
      ...payCalendar.schedule.find(period => period.label === payPeriod),
      schedule_name: payCalendar.schedule_name,
    };
  },
);

export const selectPayrollState = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll.payroll ?? {},
);

export const selectReports = createSelector(
  selectPayrollState,
  employerPayroll => employerPayroll?.reports,
);

export const selectPayrollFeatureFlags = createSelector(
  selectPayrollState,
  payroll => payroll.featureFlags,
);

export const selectCustomReportsAvailableColumns = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.customReports?.availableColumns,
);
export const selectSavedPresets = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.customReports?.savedPresets,
);

export const selectPayrollReportConfigs = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.payrollReportConfigs?.payrollConfigs,
);

export const selectLoadedStatus = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.customReports?.isLoaded,
);
