import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { QLEConfiguration } from './qle-configuration.view';
import { connect } from 'react-redux';
import {
  getQLEConfigurations,
  getEmployerQLEApprovalEmails,
  setBenefitClassId,
} from './qle-configuration.action-creators';
import { selectGroupId } from '../../selectors';
import { createStructuredSelector } from 'reselect';
import { selectQLEApprovalEmails } from './qle-configuration.selectors';

const withState = connect(
  createStructuredSelector({
    groupId: selectGroupId,
    qleApprovalEmails: selectQLEApprovalEmails,
  }),
  {
    getQLEConfigurations,
    getEmployerQLEApprovalEmails,
    setBenefitClassId,
  },
);

export default compose(withRouter, withState)(QLEConfiguration);
