import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export const MessageFormFields = props => (
  <div className="claim-settlement__message">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- FIXME: automatically added for existing issue */}
    <label htmlFor="message">
      <Icon name="warning circle" />
      <FormattedMessage id="CLAIM_NOTE" />
    </label>
    <Form.TextArea
      name="message"
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      onChange={(e, { value }) => props.setFieldValue('message', value)}
      autoHeight
      rows={4}
    />
  </div>
);

MessageFormFields.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};
