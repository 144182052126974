import { connect } from 'react-redux';
import { selectIsInWidgetRunner } from 'common/state/application/application.selectors';
import { InsuranceFormsPresenter } from './insurance-forms.view';
import {
  selectEOIForms,
  selectBeneficiaryForms,
  selectIsKPMGPartnerWithOptionalLTD,
  selectDigitalBeneficiaryForm,
  selectBenefitFormContentId,
} from './insurance-forms.selectors';
import {
  loadUserBenefitForm,
  loadDigitalBeneficiaryForm,
} from './insurance-forms.actions';
import { selectIsShopify } from 'apps/enrollment/enrollment-selectors/group.selectors';

export const InsuranceForms = connect(
  (state, { isKPMGPartner = false }) => {
    return {
      eoiForms: selectEOIForms(state),
      beneficiaryForms: selectBeneficiaryForms(state),
      digitalBeneficiaryForm: selectDigitalBeneficiaryForm(state),
      isInWidgetRunnerFrame: selectIsInWidgetRunner(state),
      isKPMGPartnerWithOptionalLTD: selectIsKPMGPartnerWithOptionalLTD(
        state,
        isKPMGPartner,
      ),
      isShopify: selectIsShopify(state),
      fromContent: selectBenefitFormContentId(state),
    };
  },
  {
    loadUserBenefitForm,
    loadDigitalBeneficiaryForm,
  },
)(InsuranceFormsPresenter);
