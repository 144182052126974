import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import {
  Box,
  BodyOne,
  Flex,
  genesisStyled,
  css,
  Label as GenesisLabel,
  LabelText,
} from '@leagueplatform/genesis-commons';
import {
  Tooltip,
  TooltipContentV2,
  Label,
  Toggle,
} from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { InlineFlex } from 'common/components/primitives.view';
import { CurrencyInput } from 'common/components/currency-input/currency-input.view';
import ErrorIcon from 'common/ui-kit/assets/error-icon.svg';

import { useIntl } from '@leagueplatform/locales';

import { InputField } from 'apps/employer-experience/ui-kit/form-fields';

import { NEGATIVE_FEE_TYPES } from '../../store/fees.schema';
import { QuestionIcon } from 'apps/employer-experience/components/question-icon.component';

const SubmitButton = genesisStyled(Button)(
  css({
    marginLeft: 'one',
  }),
);

const Required = genesisStyled(InlineFlex)(
  css({
    color: 'onSurface.text.critical',
    marginLeft: 'quarter',
  }),
);

const HeaderFlex = genesisStyled(Flex)(
  css({
    marginBottom: 'threeQuarters',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  }),
);

const CustomFieldWrapper = genesisStyled(Box)(({ hasError }) =>
  css({
    display: 'block',
    paddingLeft: 'one',
    width: '50%',

    '> div': {
      maxWidth: 'initial',
      border: 'none',
    },

    input: {
      fontSize: 'body1',
      height: '45px',
      textAlign: 'left',
      paddingX: 'one',
      backgroundPosition: 'right 1rem top 50%',
      borderRadius: 'small',
      borderWidth: 'thin',
      borderStyle: 'solid',
      borderColor: hasError
        ? 'critical.border.default'
        : 'onSurface.border.default',
      backgroundSize: '20px',
      backgroundRepeat: 'no-repeat',
      backgroundImage: hasError && `url(${ErrorIcon}) !important`,
      '&:focus': {
        borderColor: 'interactive.focus.outer',
      },
      '&::placeholder': {
        color: 'onSurface.text.subdued',
      },
    },
  }),
);

const UnitFieldWrapper = genesisStyled(Box)`
  position: relative;
  width: 50%;
  & > ${InputField}:first-of-type {
    width: 100%;
  }
`;

const RateLabel = genesisStyled(Label)(
  css({
    color: 'onSurface.text.subdued',
    marginTop: 'two',
    marginBottom: 'none',
  }),
);

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const TermContainer = genesisStyled(props => <Flex {...props} />)`
  padding-left: 16px;
  & > ${InputField}:first-of-type {
    width: 22%;
    & > div:first-of-type {
      padding-right: 0;
    }
  }

  & > ${InputField}:last-of-type {
    flex-grow: 1;

    & > div:first-of-type {
      padding-right: 0;
      padding-left: 16px;
      width: 100%;

      & > div:first-of-type {
        padding-right: 0;
      }

      & label > div:first-of-type {
        visibility: hidden;
      }
    }
  }
`;

const TooltipWrapper = genesisStyled(Box)(
  css({
    top: 'twoAndHalf',
    position: 'absolute',
  }),
);

export const AdhocBillingPresenter = ({ fields, onSubmit, currency }) => {
  const { formatMessage } = useIntl();

  const validate = values => {
    const errors = {};

    Object.keys(fields).forEach(name => {
      const field = fields[name];
      const hasEmptyValue =
        isNil(values[name]) ||
        (Number.isNaN(values[name]) && isEmpty(values[name]));
      let showError = false;

      if (name === 'date') {
        if (values[name] === undefined) {
          showError = true;
        }
      } else if (field.required && hasEmptyValue) {
        showError = true;
      }

      if (showError) {
        errors[name] = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
      }
    });

    // Compound field validation
    if (
      (values.term && !values.term_units) ||
      (values.term_units && !values.term)
    ) {
      errors.term_units = formatMessage({
        id: 'ADHOC_FEE_MULTIPART_TERM_ERROR',
      });
    }

    return errors;
  };

  return (
    <Box paddingX="half">
      <Formik initialValues={{}} onSubmit={onSubmit} validate={validate}>
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          setFieldValue,
          submitCount,
        }) => {
          const hasSubmitted = submitCount > 0;

          return (
            <form data-testid="form" onSubmit={handleSubmit}>
              <Flex flexWrap="wrap">
                <InputField
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...fields.fee_type}
                  error={(hasSubmitted || touched.fee_type) && errors.fee_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <CustomFieldWrapper
                  hasError={(hasSubmitted || touched.rate) && errors.rate}
                >
                  <HeaderFlex>
                    <RateLabel>
                      {formatMessage({ id: 'FEE_RATE' })}
                      <Required>*</Required>
                    </RateLabel>
                    <Tooltip>
                      <QuestionIcon />
                      <TooltipContentV2 center>
                        <BodyOne>
                          {formatMessage({ id: fields.rate.tooltip })}
                        </BodyOne>
                      </TooltipContentV2>
                    </Tooltip>
                  </HeaderFlex>
                  <CurrencyInput
                    value={values.rate}
                    onChange={(e, amount) => {
                      setFieldValue('rate', amount.sanitizedValue, true);
                    }}
                    currency={currency}
                    isNegativeAllowed={NEGATIVE_FEE_TYPES.includes(
                      values.fee_type,
                    )}
                  />
                </CustomFieldWrapper>
                <UnitFieldWrapper>
                  <InputField
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...fields.units}
                    error={(hasSubmitted || touched.units) && errors.units}
                    onChange={handleChange}
                  />
                  <TooltipWrapper right="1.6rem">
                    <Tooltip>
                      <QuestionIcon />
                      <TooltipContentV2 center>
                        <BodyOne>
                          {formatMessage({ id: 'ADHOC_FEE_UNIT_TOOLTIP' })}
                        </BodyOne>
                      </TooltipContentV2>
                    </Tooltip>
                  </TooltipWrapper>
                </UnitFieldWrapper>
                <TermContainer flexGrow="1">
                  <InputField
                    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                    {...fields.term}
                    error={(hasSubmitted || touched.term) && errors.term}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Box position="relative">
                    <InputField
                      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                      {...fields.term_units}
                      error={
                        (hasSubmitted || touched.term_units) &&
                        errors.term_units
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TooltipWrapper right="0">
                      <Tooltip>
                        <QuestionIcon />
                        <TooltipContentV2 center>
                          <BodyOne>
                            {formatMessage({ id: 'ADHOC_FEE_TERM_TOOLTIP' })}
                          </BodyOne>
                        </TooltipContentV2>
                      </Tooltip>
                    </TooltipWrapper>
                  </Box>
                </TermContainer>
                <InputField
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...fields.date}
                  error={(hasSubmitted || touched.date) && errors.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputField
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...fields.end_date}
                  error={(hasSubmitted || touched.end_date) && errors.end_date}
                  onChange={handleChange}
                />
                <InputField
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...fields.billing_division_id}
                  error={
                    (hasSubmitted || touched.billing_division_id) &&
                    errors.billing_division_id
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputField
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...fields.note}
                  error={(hasSubmitted || touched.note) && errors.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <GenesisLabel marginTop="two" htmlFor="exclude_taxes">
                  <LabelText paddingBottom="half">Exclude Taxes</LabelText>
                  <Toggle
                    name="exclude_taxes"
                    checked={values.exclude_taxes}
                    onChange={() =>
                      setFieldValue('exclude_taxes', !values.exclude_taxes)
                    }
                  />
                </GenesisLabel>
              </Flex>

              <Flex justifyContent="flex-end" marginTop="two">
                <SubmitButton type="submit" primary>
                  {formatMessage({ id: 'SUBMIT' })}
                </SubmitButton>
              </Flex>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

AdhocBillingPresenter.propTypes = {
  fields: PropTypes.shape(PropTypes.objectOf(PropTypes.any)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};
