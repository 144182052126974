import React from 'react';
import PropTypes from 'prop-types';
import { colour, vars, Input, Icon, ICONS } from '@leagueplatform/ui-kit';
import {
  Dropdown,
  DropdownItems,
  DropdownItem,
  DropdownTrigger,
} from 'common/components/dropdown';
import styled from 'styled-components';
import { map, uniqueId, get, find } from 'lodash';
import { FormattedMessage } from 'react-intl';

const DropdownButton = styled(DropdownTrigger)`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  min-width: 3rem;
  height: 1.6rem;

  &,
  &:hover:not([disabled]) {
    background-color: ${colour('white')};
    border: none;
    color: ${colour('navyDark')};
  }

  svg {
    margin-left: 0.3rem;
    ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'};
    transition: all 200ms ease-out;
  }

  .selected-division-name {
    margin-left: 0.3rem;
  }
`;

export const SearchDivision = styled(Input)`
  width: 14rem;
  height: 2.2rem;
  margin: 0 0.5rem;
  padding: 0.3rem 0.5rem;
  border-color: ${colour('greyLighter')};
  font-size: 1rem;
  padding-left: 2rem;
  transition: none;

  &::placeholder {
    color: ${colour('greyLight')};
  }
  &::-ms-placeholder {
    color: ${colour('greyLight')};
  }
  &::-moz-placeholder {
    color: ${colour('greyLight')};
  }
  &:-moz-placeholder {
    color: ${colour('greyLight')};
  }

  &:focus {
    border-color: ${colour('greyLighter')};
  }
`;

const DropdownPopover = styled(DropdownItems)`
  max-width: 15rem;
  max-height: 12rem;
  padding: 0.5rem 0;
  z-index: 3;

  svg {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
`;

const DropdownOptions = styled.div`
  margin-top: 0.5rem;
  max-height: 8.5rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

const DropdownOption = styled(DropdownItem)`
  span {
    white-space: normal;
  }
  min-height: 2.5rem;
  width: 100%;
  padding: 0.2rem 1rem;
  font-weight: ${({ selected }) =>
    selected ? vars.fontWeight.medium : vars.fontWeight.regular};

  &:hover {
    background-color: ${colour('greyLightest')};
  }
`;

const SelectDivision = ({
  divisionOptions,
  selectedDivisionId,
  onSelect,
  onSearch,
  visibleOptions,
  divisionSearchInput,
  onClearSearch,
  intl,
}) => (
  <Dropdown>
    <DropdownButton onClick={onClearSearch}>
      <span>
        <FormattedMessage id="INVOICE_BILLING_DIVISION" />:
      </span>
      <span className="selected-division-name">
        {get(
          find(divisionOptions, ['billingDivisionId', selectedDivisionId]),
          'name',
        )}
      </span>
      <Icon size="0.7rem" icon={ICONS.CARET_DOWN} fill={vars.colour.navyDark} />
    </DropdownButton>
    <DropdownPopover>
      <SearchDivision
        placeholder={intl.formatMessage({
          id: 'SEARCH',
        })}
        value={divisionSearchInput}
        onClick={e => e.stopPropagation()} // prevent dropdown from closing
        onChange={e => onSearch(e.target.value)}
      />
      <Icon size="1.25rem" icon={ICONS.SEARCH} fill={vars.colour.greyLight} />
      <DropdownOptions>
        {map(
          divisionOptions,
          ({ name, billingDivisionId }) =>
            get(visibleOptions, billingDivisionId, false) && (
              <DropdownOption
                onClick={() => onSelect(billingDivisionId)}
                key={uniqueId()}
                selected={selectedDivisionId === billingDivisionId}
              >
                <span>{name}</span>
              </DropdownOption>
            ),
        )}
      </DropdownOptions>
    </DropdownPopover>
  </Dropdown>
);

SelectDivision.propTypes = {
  divisionOptions:
    PropTypes.array.isRequired /* eslint react/forbid-prop-types: 0 */,
  visibleOptions: PropTypes.shape().isRequired,
  selectedDivisionId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  divisionSearchInput: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
};

export default SelectDivision;
