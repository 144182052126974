import { get } from 'lodash/fp';
import {
  UPDATE_CLAIM,
  APPROVE_CLAIM,
  GET_CLAIM_SETTLEMENT_RESOURCES,
  DUPLICATE_CLAIM_IDENTIFIED,
  RESOLVE_DUPLICATE_CLAIM,
} from './settlement.types';

export const initialState = {
  ready: null,
  error: null,
  success: null,
  updating: null,
  duplicateClaim: null,
  resolvingDuplicate: false,
  updatedAt: 0,
};

const getErrorMessage = get('payload.info.reason');

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const claimSettlementUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLAIM_SETTLEMENT_RESOURCES.STARTED:
      return {
        ...state,
        ready: false,
      };
    case GET_CLAIM_SETTLEMENT_RESOURCES.SUCCEEDED:
      return {
        ...state,
        ready: true,
        error: null,
      };
    case UPDATE_CLAIM.STARTED:
      return {
        ...state,
        updating: true,
        duplicateClaim: null,
      };
    case UPDATE_CLAIM.SUCCEEDED:
      return {
        ...state,
        success: true,
        updating: false,
        error: null,
        updatedAt: Date.now(),
      };
    case UPDATE_CLAIM.ERRORED:
    case GET_CLAIM_SETTLEMENT_RESOURCES.ERRORED:
      return {
        ...state,
        ready: true,
        updating: false,
        success: false,
        error: getErrorMessage(action),
        updatedAt: Date.now(),
      };
    case DUPLICATE_CLAIM_IDENTIFIED:
      return {
        ...state,
        duplicateClaim: action.payload,
      };
    case RESOLVE_DUPLICATE_CLAIM.STARTED:
      return {
        ...state,
        resolvingDuplicate: true,
      };
    case RESOLVE_DUPLICATE_CLAIM.SUCCEEDED:
      return {
        ...state,
        resolvingDuplicate: false,
      };
    case APPROVE_CLAIM.CANCELLED:
      return {
        ...state,
        duplicateClaim: null,
      };
    default:
      return state;
  }
};
