import { createRequestTypes } from 'common/websocket-redux';
import { GET_USER_REWARD_POINTS as GET_REWARD_POINTS } from 'apps/member-experience/resources/rewards/health-rewards.actions';

export const GET_USER_REWARD_POINTS = GET_REWARD_POINTS;

export const requestUserRewardPoints = userId => ({
  type: GET_USER_REWARD_POINTS.BASE,
  payload: { user_id: userId },
});

export const GET_REWARD_LEVELS = createRequestTypes(
  'userRewards/GET_REWARD_LEVELS',
);

export const SET_REDEEM_POINTS = createRequestTypes(
  'userRewards/SET_REDEEM_POINTS',
);

export const REDEEM_POINTS_CLICKED = 'userRewards/REDEEM_POINTS_CLICKED';

export const redeemPointsClicked = points => ({
  type: REDEEM_POINTS_CLICKED,
  payload: { points },
});

export const GET_LIFEMARKET_PRODUCTS = createRequestTypes(
  'userRewards/GET_LIFEMARKET_PRODUCTS',
);
