/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { request, websocketFetch } from 'common/websocket-redux';
import { getUserProfile } from 'common/services';

import {
  selectDependents,
  selectEmployeeProfile,
  selectGroupId,
} from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';
import { GET_EMPLOYEE_USER_PROFILE } from 'apps/employer-experience-v2/employee-profile/profile.types';

import {
  setDependents,
  getDependentQLEReasonOptions,
  getDependentRelationshipTypes,
} from '../services';

import {
  GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
  GET_DEPENDENT_ADD_QLE_REASONS,
  GET_DEPENDENT_RELATIONSHIP_TYPES,
  TERMINATE_DEPENDENT,
  SET_DEPENDENT,
} from './dependent.action-types';
import { updateDependentsList } from './utils';
import { selectEmployeeDependentsPath } from './dependent.selectors';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getDependentTerminationTypeOptions() {
  yield request(
    GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
    getDependentQLEReasonOptions({ event_type: 'remove' }),
  );
}

export function* getDependentAddQLEReasons() {
  yield request(
    GET_DEPENDENT_ADD_QLE_REASONS,
    getDependentQLEReasonOptions({ event_type: 'add' }),
  );
}

export function* getDependentRelationshipTypeOptions() {
  const groupId = yield select(selectGroupId);
  yield request(
    GET_DEPENDENT_RELATIONSHIP_TYPES,
    getDependentRelationshipTypes({ group_id: groupId }),
  );
}

export function* terminateDependent({
  payload: { dependentId, terminationReason, terminationDate, employeeId },
}) {
  yield request(TERMINATE_DEPENDENT, [
    websocketFetch,
    'remove_dependent',
    {
      user_id: employeeId,
      version: 1,
      dependent_id: dependentId,
      qualifying_life_event_reason: terminationReason,
      benefits_termination_date: terminationDate,
    },
  ]);
}

export function* navigateToDependentsListAndRefresh() {
  const redirectPath = yield select(selectEmployeeDependentsPath);
  const { user_id } = yield select(selectEmployeeProfile);

  // Redirect
  yield put(push(redirectPath));

  // Update dependents / profile
  yield request(GET_EMPLOYEE_USER_PROFILE, getUserProfile(user_id));
}

export function* showTerminateDependentSuccessToast() {
  yield navigateToDependentsListAndRefresh();
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'DEPENDENT_REMOVED',
  });
}

export function* showTerminateDependentErrorToast() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'DEPENDENT_REMOVED_ERROR',
  });
}

export function* saveDependentRequest({
  payload: { dependent, groupId, userId },
}) {
  const dependentList = yield select(state =>
    selectDependents(state, { groupId }),
  );
  const dependents = updateDependentsList(dependentList, dependent);
  yield request(
    SET_DEPENDENT,
    setDependents({ dependents, group_id: groupId, user_id: userId }),
  );
}

// redirect and toast
export function* saveDependentSucceeded() {
  yield navigateToDependentsListAndRefresh();

  // toast 🍻
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'DEPENDENT_UPDATED',
  });
}

export function* saveDependentErrored({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: payload.info.reason,
    shouldAutoClose: false,
  });
}

export function* dependentSaga() {
  yield takeLatest(
    GET_DEPENDENT_TERMINATION_TYPE_OPTIONS.BASE,
    getDependentTerminationTypeOptions,
  );
  yield takeLatest(
    GET_DEPENDENT_ADD_QLE_REASONS.BASE,
    getDependentAddQLEReasons,
  );
  yield takeLatest(
    GET_DEPENDENT_RELATIONSHIP_TYPES.BASE,
    getDependentRelationshipTypeOptions,
  );

  yield takeLatest(TERMINATE_DEPENDENT.BASE, terminateDependent);
  yield takeLatest(
    TERMINATE_DEPENDENT.SUCCEEDED,
    showTerminateDependentSuccessToast,
  );
  yield takeLatest(
    TERMINATE_DEPENDENT.ERRORED,
    showTerminateDependentErrorToast,
  );
  yield takeLatest(SET_DEPENDENT.BASE, saveDependentRequest);
  yield takeLatest(SET_DEPENDENT.SUCCEEDED, saveDependentSucceeded);
  yield takeLatest(SET_DEPENDENT.ERRORED, saveDependentErrored);
}
