import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import FinanceReportsStatementOfAccount from './finance-reports-statement-of-account.view';
import { getStatementOfAccount } from '../../finance-reports.actions';
import {
  selectStatementOfAccountLoading,
  selectStatementOfAccountContentId,
} from '../../../selectors';

const withProps = connect(
  createStructuredSelector({
    statementOfAccountLoading: selectStatementOfAccountLoading,
    statementOfAccountContentId: selectStatementOfAccountContentId,
  }),
  {
    getStatementOfAccount,
  },
);

export default compose(withProps)(FinanceReportsStatementOfAccount);
