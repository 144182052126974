import { createRequestTypes } from 'common/websocket-redux';

export const GET_PENDING_FEES = createRequestTypes('admin/GET_PENDING_FEES');

export const REMOVE_PENDING_FEE = createRequestTypes(
  'admin/REMOVE_PENDING_FEE',
);

export const ISSUE_ADHOC_INVOICE = createRequestTypes(
  'admin/ISSUE_ADHOC_INVOICE',
);

export const GET_ADHOC_INVOICE_PREVIEW = createRequestTypes(
  'admin/GET_ADHOC_INVOICE_PREVIEW',
);
