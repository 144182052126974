import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function copyBenefitPlan - this service is used to duplicated an employer benefit
 * plan and its plan options
 *
 * @arg {string} groupId  the group ID for the currently enrolling group
 * @arg {string} originalBenefitPlanId the id of the plan being copied
 * @arg {string} destinationBenefitClassId the id of the benefit class to copy the plan intos
 *
 * @returns {object[]}
 */

export const copyBenefitPlan = ({
  groupId = null,
  originalBenefitPlanId = null,
  destinationBenefitClassId = null,
} = {}) =>
  call(websocketFetch, 'copy_benefit_plan', {
    group_id: groupId,
    orig_benefit_plan_id: originalBenefitPlanId,
    destination_benefit_class_id: destinationBenefitClassId,
  });
