import { all, call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export function* getResourcesForEditEmployer(groupId) {
  const [employerResponse, schemaResponse] = yield all([
    call(websocketFetch, 'get_employer', { group_id: groupId }),
    call(websocketFetch, 'get_create_employer_schema'),
  ]);
  return {
    employer: employerResponse.info.employer,
    schema: schemaResponse.info,
  };
}
