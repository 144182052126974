/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash/fp';

import {
  selectDependentById,
  selectEmployeeProfile,
  selectGroupId,
} from 'apps/employer-experience/pages/EmployerDetails/Employee/selectors';

export const selectEmployeeDependents = get(
  'apps.employer-experience.employeeDependents',
);

export const selectDependentTerminationTypeOptions = createSelector(
  selectEmployeeDependents,
  employeeDependents =>
    employeeDependents.dependentTerminationTypeOptions.map(
      ({ type: value, name: text }) => ({
        text,
        value,
      }),
    ),
);

export const selectDependentAddQLEReasons = createSelector(
  selectEmployeeDependents,
  employeeDependents =>
    employeeDependents.dependentAddQLEReasons.map(
      ({ type: value, name: text }) => ({
        text,
        value,
      }),
    ),
);

export const selectLoading = createSelector(
  selectEmployeeDependents,
  ({ loading }) => loading,
);

export const selectEmployeeDependentsPath = createSelector(
  selectEmployeeProfile,
  selectGroupId,
  ({ user_id }, groupId) =>
    `/admin/employers/${groupId}/employees/${user_id}/dependents`,
);

export const selectDependentRelationshipTypes = createSelector(
  selectEmployeeDependents,
  ({ relationshipTypes }) => {
    return relationshipTypes;
  },
);

export const selectEmployerCountryCode = get(
  'apps.employer-experience.pages.employerDetails.employer.group.countryCode',
);

export const selectIsDependentEditLoading = createSelector(
  selectDependentRelationshipTypes,
  selectDependentAddQLEReasons,
  selectDependentById,
  (relationshipTypes, QLEReasons, dependent) => {
    return (
      isEmpty(QLEReasons) || isEmpty(relationshipTypes) || isEmpty(dependent)
    );
  },
);

export const selectIsDependentAddLoading = createSelector(
  selectDependentRelationshipTypes,
  selectDependentAddQLEReasons,
  (relationshipTypes, QLEReasons) => {
    return isEmpty(QLEReasons) || isEmpty(relationshipTypes);
  },
);
