import { get, cloneDeep, concat, filter, map, includes } from 'lodash';
import { normalize, denormalize, schema } from 'normalizr';
import {
  GET_USER_BENEFITS,
  GET_USER_BENEFIT_SUMMARIES,
  ADD_USER_BENEFITS,
  UPDATE_USER_BENEFITS,
  EMPLOYEE_BENEFITS_PAGE_LIFECYCLE,
  GET_EMPLOYER_BENEFIT_PLANS,
  GET_ALL_STATUSES_EMPLOYER_BENEFIT_PLANS,
} from './benefits.types';

export const planSchema = new schema.Entity('plans');
export const benefitSchema = new schema.Entity('benefits');
export const summariesSchema = new schema.Entity(
  'summaries',
  {},
  {
    idAttribute: 'user_benefit_id',
  },
);

export const initialState = {
  data: normalize([], [benefitSchema]),
  summaries: normalize([], [benefitSchema]),
};

const appendBenefits = (data, benefits = []) => {
  const clonedData = cloneDeep(data);
  const denormalized = denormalize(
    clonedData.result,
    [benefitSchema],
    clonedData.entities,
  );

  const ids = map(benefits, 'id');
  const benefitsNotUpdated = filter(
    denormalized,
    ({ id }) => !includes(ids, id),
  );
  return normalize(concat(benefitsNotUpdated, benefits), [benefitSchema]);
};

const applyGetUserBenefitsSucceeded = (state, { payload }) => ({
  ...state,
  data: normalize(get(payload, 'info.benefits') || [], [benefitSchema]),
});

const applyAddUpdateUserBenefitsSucceeded = (state, { payload }) => ({
  ...state,
  data: appendBenefits(state.data, get(payload, 'info.benefits') || []),
});

const applyGetUserBenefitSummarieSucceeded = (state, { payload }) => ({
  ...state,
  summaries: normalize(get(payload, 'info.benefit_summaries') || [], [
    summariesSchema,
  ]),
});

const applyGetEmployerBenefitPlansSucceeded = (state, { payload }) => ({
  ...state,
  plans: normalize(get(payload, 'info.plans') || [], [planSchema]),
});

const applyGetAllStatusesEmployerBenefitPlansSucceeded = (
  state,
  { payload },
) => ({
  ...state,
  allStatusesPlans: normalize(get(payload, 'info.plans') || [], [planSchema]),
});

export const employeeBenefitsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_BENEFIT_SUMMARIES.SUCCEEDED:
      return applyGetUserBenefitSummarieSucceeded(state, action);
    case GET_USER_BENEFITS.SUCCEEDED:
      return applyGetUserBenefitsSucceeded(state, action);
    case ADD_USER_BENEFITS.SUCCEEDED:
    case UPDATE_USER_BENEFITS.SUCCEEDED:
      return applyAddUpdateUserBenefitsSucceeded(state, action);
    case EMPLOYEE_BENEFITS_PAGE_LIFECYCLE.EXITED:
      return initialState;
    case GET_EMPLOYER_BENEFIT_PLANS.SUCCEEDED:
      return applyGetEmployerBenefitPlansSucceeded(state, action);
    case GET_ALL_STATUSES_EMPLOYER_BENEFIT_PLANS.SUCCEEDED:
      return applyGetAllStatusesEmployerBenefitPlansSucceeded(state, action);
    default:
      return state;
  }
};
