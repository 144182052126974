import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserDeductions } from '../../selectors';
import PropTypes from 'prop-types';

import { PayrollDeductionsPresenter } from './payroll-deductions.view';
import { getUserAdjustments, getUserDeductions } from '../../actions';
import { usePayrollDeductions } from './use-payroll-deductions.hook';

export const PayrollDeductions = ({ employeeGroupId, userId }) => {
  const userDeductions = useSelector(selectUserDeductions);
  const dispatch = useDispatch();
  const payrollDeductionsProps = usePayrollDeductions({ userDeductions });

  useEffect(() => {
    dispatch(
      getUserDeductions({
        userId,
        groupId: employeeGroupId,
      }),
    );
  }, [dispatch, employeeGroupId, userId]);

  useEffect(() => {
    dispatch(
      getUserAdjustments({
        userId,
        groupId: employeeGroupId,
      }),
    );
  }, [dispatch, employeeGroupId, userId]);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <PayrollDeductionsPresenter {...payrollDeductionsProps} />;
};

PayrollDeductions.propTypes = {
  userId: PropTypes.string.isRequired,
  employeeGroupId: PropTypes.string.isRequired,
};
