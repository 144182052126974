import {
  ADD_ADJUSTMENT_FORM_SUBMITTED,
  REQUEST_REMOVE_ADJUSTMENT,
} from './adjustments.types';

export const submitForm = adjustment => ({
  type: ADD_ADJUSTMENT_FORM_SUBMITTED,
  payload: adjustment,
});

export const removeAdjustment = payload => ({
  type: REQUEST_REMOVE_ADJUSTMENT,
  payload,
});
