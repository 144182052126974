import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EmployerReportsLoaderButton } from 'apps/employer-experience/pages/EmployerDetails/employer-reports/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingFour,
  Text,
  Flex,
  Label,
  LabelText,
  ErrorMessage,
  Box,
  genesisStyled,
  css,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import { Formik } from 'formik';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import { FileUpload } from 'apps/upload/file-upload.view';

const DayPickerWrapper = genesisStyled(Box)(({ hasError }) =>
  css({
    '.DayPicker-wrapper': css({
      padding: '0.7rem 0',
    }),
    '.DayPicker-Day': css({
      padding: '0.2rem',
    }),
    '.DayPickerInput input': css({
      height: 'three',
      paddingY: 'threeQuarters',
      paddingX: 'one',
      width: '100%',
      borderRadius: 'small',
      color: 'decorative.brand.primary.darkest',
      borderColor: hasError
        ? 'critical.border.default'
        : 'tertiary.border.default',
      borderStyle: 'solid',
      borderWidth: 'thin',
      fontFamily: 'inherit',
    }),
  }),
);

const DATE_FORMAT = 'DD/MM/YYYY';

export const CarrierAuditsReportsPresenter = ({
  getCarrierAuditsReport,
  carrierAuditReportLoading,
  auditFlows,
}) => {
  const { formatMessage } = useIntl();

  const [fileName, setFileName] = useState('');

  const initialValues = {
    flowType: '',
    contentId: '',
    requestDate: '',
  };

  const validate = values => {
    const errors = {};

    if (!values.flowType) {
      errors.flowType = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
    }

    if (!values.contentId) {
      errors.contentId = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
    }

    if (!values.requestDate) {
      errors.requestDate = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
    }
    if (!values.requestDate?.isValid?.()) {
      errors.requestDate = formatMessage({
        id: 'INVALID_DATE_ERROR_MESSAGE',
      });
    }

    return errors;
  };

  const onSubmit = values => {
    getCarrierAuditsReport({
      ...values,
      requestDate: values.requestDate.toISOString(),
    });
  };

  return (
    <>
      <Flex marginBottom="one">
        <HeadingFour>
          {formatMessage({ id: 'CARRIER_AUDITS_REPORT' })}
        </HeadingFour>
      </Flex>
      <Text marginBottom="one">
        {formatMessage({ id: 'CARRIER_AUDITS_REPORTS_DESCRIPTION' })}
      </Text>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ values, handleSubmit, setFieldValue, errors, touched }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Flex flexWrap="wrap" marginBottom="one">
                <Box marginBottom="one" width={[1, 1, 1 / 2]} pr={[0, 0, 10]}>
                  <Label>
                    <Box marginBottom="quarter">
                      <LabelText>
                        {formatMessage({ id: 'FLOW_TYPE' })}
                      </LabelText>
                    </Box>
                    <Select
                      options={auditFlows}
                      value={values.flowType}
                      onChange={({ value }) => setFieldValue('flowType', value)}
                      error={touched.flowType && errors.flowType}
                    />
                  </Label>
                  {touched.flowType && errors.flowType && (
                    <ErrorMessage marginTop="quarter">
                      {errors.flowType}
                    </ErrorMessage>
                  )}
                </Box>

                <Box marginBottom="one" width={[1, 1, 1 / 2]} pl={[0, 0, 10]}>
                  <Label>
                    <Box marginBottom="quarter">
                      <LabelText>
                        {formatMessage({ id: 'REQUEST_DATE' })}
                      </LabelText>
                    </Box>
                    <DayPickerWrapper
                      hasError={touched.requestDate && errors.requestDate}
                    >
                      <StyledDayPickerInput
                        placeholder={formatMessage({
                          id: 'DMY_DATE_FIELD_PLACEHOLDER',
                        })}
                        format={DATE_FORMAT}
                        name="requestDate"
                        onDayChange={date => setFieldValue('requestDate', date)}
                        error={touched.requestDate && errors.requestDate}
                      />
                    </DayPickerWrapper>
                  </Label>
                  {touched.requestDate && errors.requestDate && (
                    <ErrorMessage marginTop="quarter">
                      {errors.requestDate}
                    </ErrorMessage>
                  )}
                </Box>

                <Box marginBottom="one" width="100%">
                  <Label>
                    <LabelText marginBottom="one">
                      {formatMessage({ id: 'DOCUMENT' })}
                    </LabelText>
                  </Label>
                  <FileUpload
                    title={formatMessage({ id: 'CHOOSE_FILE' })}
                    onSuccess={({ name, contentId }) => {
                      setFieldValue('contentId', contentId);
                      setFileName(name);
                    }}
                  />
                  <BodyOneSecondary display="inline" marginLeft="one">
                    {fileName}
                  </BodyOneSecondary>

                  {touched.contentId && errors.contentId && (
                    <ErrorMessage marginTop="quarter">
                      {errors.contentId}
                    </ErrorMessage>
                  )}
                </Box>
              </Flex>

              <EmployerReportsLoaderButton
                primary
                type="submit"
                loading={carrierAuditReportLoading}
              >
                {formatMessage({ id: 'REQUEST_REPORT' })}
              </EmployerReportsLoaderButton>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

CarrierAuditsReportsPresenter.propTypes = {
  getCarrierAuditsReport: PropTypes.func.isRequired,
  carrierAuditReportLoading: PropTypes.bool,
  auditFlows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CarrierAuditsReportsPresenter.defaultProps = {
  carrierAuditReportLoading: false,
};
