import { isProduction } from '@leagueplatform/core';
import { useIntl } from '@leagueplatform/locales';
import { Text } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { NumberOfDays } from './number-of-days.view';

const AmountTitle = styled(Text)`
  &&& {
    margin: 16px 0;
  }
`;

export const EnterDaysLimit = ({
  before,
  after,
  value,
  name,
  handleChange,
  disabled,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl();
  let message;

  if (before) {
    message = formatMessage({ id: 'NUMBER_OF_DAYS_ALLOWED_PRECEDING' });
  }

  if (after) {
    message = formatMessage({ id: 'NUMBER_OF_DAYS_ALLOWED_FOLLOWING' });
  }

  if (before && after) {
    if (!isProduction()) {
      // eslint-disable-next-line
      console.warn(`before and after props cannot both be set`);
    }
    return null;
  }

  return (
    <>
      <AmountTitle fontSize={2} color="purpleText" fontWeight="bold">
        {message}
      </AmountTitle>
      <NumberOfDays
        name={name}
        value={value}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        disabled={disabled}
      />
    </>
  );
};

EnterDaysLimit.propTypes = {
  before: PropTypes.bool,
  after: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

EnterDaysLimit.defaultProps = {
  value: 0,
  before: false,
  after: false,
};
