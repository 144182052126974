import { GET_GROUP } from 'common/state/groups/groups.actions';
import { normalize, schema } from 'normalizr';
import { getPayloadInfo } from 'common/websocket-redux';

const officeSchema = new schema.Entity(
  'office',
  {},
  {
    idAttribute: 'office_id',
  },
);

export const initialState = normalize([], [officeSchema]);

const updateOffices = action => {
  const info = getPayloadInfo(action);
  return normalize(info?.offices ?? [], [officeSchema]);
};

export const offices = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_GROUP.SUCCEEDED:
      return updateOffices(action);

    default:
      return state;
  }
};

export const officesReducer = offices;
