import { createRequestTypes } from 'common/websocket-redux';

export const GET_CARE_PROVIDER_APPOINTMENT_HISTORY = createRequestTypes(
  'care-provider-appointment-history/GET_CARE_PROVIDER_APPOINTMENT_HISTORY',
);

export const getCareProviderAppointmentHistory = ({ userId }) => {
  return {
    type: GET_CARE_PROVIDER_APPOINTMENT_HISTORY.BASE,
    payload: { userId },
  };
};
