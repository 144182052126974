import { takeLatest, fork, all } from 'redux-saga/effects';
import { GET_GROUP, GET_GROUP_BY_ID } from './groups.actions';
import { GET_USER_PROFILE } from 'common/state/user/user.types';
import { getGroup as getGroupService } from 'common/services';
import { request } from 'common/websocket-redux';
import { getActiveGroup } from '@leagueplatform/web-common';

export function* getGroup(action) {
  const groups = action?.payload?.info?.groups;
  const groupId = getActiveGroup(groups)?.group_id;

  if (groupId) {
    yield request(GET_GROUP, getGroupService(groupId));
  }
}

export function* getGroupById({ payload: { groupId } }) {
  yield request(GET_GROUP_BY_ID, getGroupService(groupId));
}

export function* groupsSaga() {
  yield all([
    fork(takeLatest, GET_USER_PROFILE.SUCCEEDED, getGroup),
    fork(takeLatest, GET_GROUP_BY_ID.BASE, getGroupById),
  ]);
}
