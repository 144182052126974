import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, lifecycle, setDisplayName } from 'recompose';
import { Formik } from 'formik-legacy';
import { Box } from '@leagueplatform/ui-kit';
import { combineValidators } from 'common/adaptive-forms';
import { validateDateRange } from './utilities';
import { benefitSubmitted } from './benefit.actions';
import { BENEFIT_LIFECYCLE } from './benefits.types';
import {
  mapPropsToValues,
  createValidatorSelector,
  createFormGeneratorSelector,
  selectBenefitSchema,
} from './benefits.selectors';
import { selectGroupCurrency } from '../app.selectors';

const Default = props => (
  <Box className="container--benefit-product">
    {/* eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue */}
    {props.generateLayout(props)}
  </Box>
);

Default.propTypes = {
  generateLayout: PropTypes.func.isRequired,
};

const mapStateToProps = (
  state,
  { benefitType, layoutName, customComponents, customSchemaSpecs },
) => {
  return {
    validate: createValidatorSelector(benefitType)(state),
    generateLayout: createFormGeneratorSelector(
      benefitType,
      layoutName,
      customComponents,
      customSchemaSpecs,
    )(state),
    schema: selectBenefitSchema(benefitType)(state),
    groupCurrency: selectGroupCurrency(state),
  };
};

const withFormikEnhancer = Formik({
  validate: (values, { validate }) =>
    combineValidators(validate, validateDateRange)(values),
  handleSubmit: (values, { props }) => {
    props.benefitSubmitted(values);
  },
  mapPropsToValues,
});

const withInitializer = lifecycle({
  componentDidMount() {
    this.props.visit();
  },

  componentWillUnmount() {
    this.props.exit();
  },
});

export default compose(
  setDisplayName('BenefitDefault'),
  connect(mapStateToProps, {
    benefitSubmitted,
    visit: BENEFIT_LIFECYCLE.visit,
    exit: BENEFIT_LIFECYCLE.exit,
  }),
  withFormikEnhancer,
  withInitializer,
)(Default);
