import { LOCATION_CHANGE } from 'connected-react-router';

export const mapObjectToURLSearchParams = params => {
  const searchParams = new URLSearchParams();
  // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
  for (const [key, value] of Object.entries(params ?? {})) {
    searchParams.append(key, value);
  }
  return searchParams;
};

export const locationChangedTo = path => action =>
  action.type === LOCATION_CHANGE &&
  action.payload?.location?.pathname === path;

const whiteSpaceRe = /^\s*$/;

export function parseUrl(url) {
  if (!url || whiteSpaceRe.test(url)) {
    throw new Error(`Error in parseUrl: could not parse ${url} as url`);
  }

  const a = document.createElement('a');
  a.href = url;
  const {
    protocol, // "http:"
    host, // "example.com:3000"
    hostname, // "example.com"
    port, // "3000"
    pathname, // "/pathname/"
    hash, // "#hash"
    search, // "?search=test"
    href, // http://example.com
    origin, // http://example.com:3000
  } = a;

  return {
    protocol,
    host,
    hostname,
    port,
    pathname,
    hash,
    search,
    href,
    origin,
  };
}

export const isAbsoluteUrl = url => url.toLowerCase().startsWith('http');
