import { createRequestTypes } from 'common/websocket-redux';

export const ADD_BENEFIT_PLAN = 'benefits/ADD_BENEFIT_PLAN';

export const RENAME_BENEFIT_PLAN = 'benefits/RENAME_BENEFIT_PLAN';

export const REMOVE_BENEFIT_PLAN = 'benefits/REMOVE_BENEFIT_PLAN';

export const COPY_BENEFIT_PLAN = createRequestTypes(
  'benefits/COPY_BENEFIT_PLAN',
);

export const RENEW_BENEFIT_PLAN = createRequestTypes(
  'benefits/RENEW_BENEFIT_PLAN',
);

export const FETCH_CATALOGUE = 'benefits/FETCH_CATALOGUE';

export const FETCH_BENEFIT_TYPE_SCHEMAS = 'benefits/FETCH_BENEFIT_TYPE_SCHEMAS';

export const FETCH_BENEFITS = 'benefits/FETCH_BENEFITS';

export const SET_BENEFIT_PLAN = createRequestTypes(
  'service/SET_EMPLOYER_BENEFIT_PLAN',
);

export const GET_LEAGUE_BENEFITS = createRequestTypes(
  'service/GET_LEAGUE_BENEFITS',
);

export const REMOVE_BENEFIT_PLAN_SERVICE = createRequestTypes(
  'service/REMOVE_BENEFIT_PLAN',
);

export const GET_EMPLOYER_BENEFITS = createRequestTypes(
  'service/GET_EMPLOYER_BENEFITS',
);

export const GET_BENEFIT_TYPE_SCHEMAS = createRequestTypes(
  'service/GET_BENEFIT_TYPE_SCHEMAS',
);
