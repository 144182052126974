import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Spinner,
  genesisStyled,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useSelector } from 'react-redux';
import { asyncUpload } from './upload.saga';
import { selectSessionId } from 'apps/auth/auth-classic/auth-classic.selectors';

const useUploadFile = ({ onSuccess, onError }) => {
  const [isUploading, setIsUploading] = useState(false);
  // if auth0 is enabled we do not have a session ID and this will be undefined. This is expected by `asyncUpload`
  const sessionId = useSelector(selectSessionId);
  const uploadFile = useCallback(
    async file => {
      setIsUploading(true);
      try {
        const { name, type, size } = file;
        const response = await asyncUpload([file], sessionId);
        const contentId = response?.[0];
        onSuccess({ contentId, name, type, size });
      } catch (error) {
        if (onError) onError(error);
      } finally {
        setIsUploading(false);
      }
    },
    [onSuccess, onError, sessionId],
  );

  return { uploadFile, isUploading };
};

/**
 * @component FileUpload - looks like Genesis SecondaryButton and can take 4 props:
 * @props
 *  @function onSuccess - callback to handle successful upload. It returns referenceId, name, type, size for your use
 *  @function onError - optional callback to handle upload error
 *  @string title - optional text for your button. Otherwise the button says "Upload"
 *  @GenesisWidth width - optional width for your button. Otherwise it's set to "initial"
 * @example
 * <FileUpload
 *   title={formatMessage({ id: 'CHOOSE_FILE' })}
 *   width="five"
 *   onError={setIsErrorModalVisible(true)}
 *   onSuccess={({ name, referenceId }) => {
 *     setFieldValue('contentId', referenceId);
 *     setFileName(name);
 *   }}
 * />
 */

const InvisibleInput = genesisStyled('input')`
  display: none;
`;

export const FileUpload = ({ title, onSuccess, onError, width, isPublic }) => {
  const { formatMessage } = useIntl();
  const { uploadFile, isUploading } = useUploadFile({
    onSuccess,
    onError,
  });
  const handleUpload = useCallback(
    e => {
      const file = e.currentTarget.files[0];
      file.isPublic = isPublic;
      uploadFile(file);
    },
    [uploadFile, isPublic],
  );

  return (
    <SecondaryButton as="label" width={width || 'initial'}>
      <InvisibleInput
        type="file"
        onChange={handleUpload}
        // Trick input to trigger change if the same file is selected twice
        onClick={e => {
          e.target.value = null;
        }}
      />
      {isUploading ? (
        <Spinner isLoading paddingX="two" paddingY="threeQuarters" />
      ) : (
        title || formatMessage({ id: 'UPLOAD' })
      )}
    </SecondaryButton>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  isPublic: PropTypes.string,
};

FileUpload.defaultProps = {
  title: null,
  onError: null,
  width: 'initial',
  isPublic: '',
};

export default FileUpload;
