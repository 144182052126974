import { GET_DEPENDENTS_REPORT } from './dependents-report.types';

export const initialState = {
  dependentsReportLoading: false,
};

export const employerDependentsReportReducer = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_DEPENDENTS_REPORT.STARTED:
      return {
        ...state,
        dependentsReportLoading: true,
      };
    case GET_DEPENDENTS_REPORT.SUCCEEDED:
    case GET_DEPENDENTS_REPORT.ERRORED:
      return {
        ...state,
        dependentsReportLoading: false,
      };
    default:
      return state;
  }
};
