import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { PillLink } from 'apps/employer-experience/ui-kit';
import { isTabActive } from 'apps/employer-experience/utilities';
import { Box } from '@leagueplatform/genesis-commons';

import { useIntl } from '@leagueplatform/locales';

import { InvoicesRouter } from './invoices-router';

export const InvoicesPage = withRouter(
  ({ match, location, showPendingToggle }) => {
    const { formatMessage } = useIntl();
    return (
      <>
        {showPendingToggle && (
          <Box marginBottom="one">
            <PillLink
              to={`${match.url}`}
              name="active"
              active={isTabActive(match, location, undefined)}
            >
              {formatMessage({ id: 'ACTIVE' })}
            </PillLink>
            <PillLink
              to={`${match.url}/pending`}
              name="pending"
              active={isTabActive(match, location, 'pending')}
              marginRight="oneAndHalf"
            >
              {formatMessage({ id: 'PENDING_FEES' })}
            </PillLink>
          </Box>
        )}
        <InvoicesRouter />
      </>
    );
  },
);

// TODO -- clean up when ready
InvoicesPage.propTypes = {
  showPendingToggle: PropTypes.bool,
};

InvoicesPage.defaultProps = {
  showPendingToggle: true,
};

export default InvoicesPage;
