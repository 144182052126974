/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { combineReducers } from 'redux';
import {
  AUTHENTICATE_CONNECTION,
  REAUTHENTICATE_CONNECTION,
} from 'common/websocket-redux';
import { getCurrentLocale } from '@leagueplatform/locales';
import { SIGN_OUT } from '../auth.actions';
import { AUTHENTICATE_SESSION } from './auth0.actions';
import { AUTH0_CLAIM_USER_ID } from './auth0.constants';

export const initialState = {
  accessToken: null,
  authenticated: false,
  error: null,
  isAuthenticating: false,
  locale: getCurrentLocale(),
  userId: null,
};

export const auth0Reducer = combineReducers({
  accessToken,
  authenticated,
  error,
  isAuthenticating,
  locale,
  userId,
});

function accessToken(state = initialState.accessToken, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_CONNECTION.STARTED:
    case REAUTHENTICATE_CONNECTION.STARTED:
      return action.meta?.jwt;
    case AUTHENTICATE_SESSION.ERRORED:
      return null;
    default:
      return state;
  }
}
function authenticated(state = initialState.authenticated, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_CONNECTION.SUCCEEDED:
    case REAUTHENTICATE_CONNECTION.SUCCEEDED:
      return true;
    case SIGN_OUT.SUCCEEDED:
    case AUTHENTICATE_CONNECTION.ERRORED:
    case REAUTHENTICATE_CONNECTION.ERRORED:
    case AUTHENTICATE_SESSION.ERRORED:
      return false;
    default:
      return state;
  }
}

function userId(state = initialState.userId, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_SESSION.SUCCEEDED:
      return action?.payload?.user?.[AUTH0_CLAIM_USER_ID] ?? null;
    default:
      return state;
  }
}

function locale(state = initialState.locale, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_CONNECTION.SUCCEEDED:
    case REAUTHENTICATE_CONNECTION.SUCCEEDED:
      return action.payload?.info?.locale ?? state;
    default:
      return state;
  }
}

function isAuthenticating(state = initialState.isAuthenticating, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_CONNECTION.STARTED:
    case REAUTHENTICATE_CONNECTION.STARTED:
      return true;
    case AUTHENTICATE_CONNECTION.SUCCEEDED:
    case REAUTHENTICATE_CONNECTION.SUCCEEDED:
    case AUTHENTICATE_CONNECTION.ERRORED:
    case REAUTHENTICATE_CONNECTION.ERRORED:
    case AUTHENTICATE_CONNECTION.CANCELLED:
    case REAUTHENTICATE_CONNECTION.CANCELLED:
      return false;
    default:
      return state;
  }
}

function error(state = initialState.error, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_CONNECTION.ERRORED:
    case REAUTHENTICATE_CONNECTION.ERRORED:
    case AUTHENTICATE_SESSION.ERRORED:
      return action.payload;
    case AUTHENTICATE_CONNECTION.SUCCEEDED:
    case REAUTHENTICATE_CONNECTION.SUCCEEDED:
    case AUTHENTICATE_SESSION.SUCCEEDED:
      return null;
    default:
      return state;
  }
}
