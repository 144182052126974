export default {
  viewEditEmployee: {
    name: 'viewEditEmployee',
    groups: [
      {
        position: 0,
        title: 'Basic Info',
        fields: [
          {
            name: 'benefit_class_id',
            position: 0,
            span: 6,
          },
          {
            name: 'user_id',
            position: 1,
            span: 6,
          },
          {
            name: 'first_name',
            position: 2,
            span: 4,
          },
          {
            name: 'last_name',
            position: 3,
            span: 4,
          },
          {
            name: 'preferred_first_name',
            position: 4,
            span: 4,
          },
          {
            name: 'email',
            position: 5,
            span: 4,
          },
          {
            name: 'phone_number',
            position: 6,
            span: 4,
          },
          {
            name: 'invitation_code',
            position: 6,
            span: 4,
          },
          {
            name: 'date_of_birth',
            position: 6,
            span: 4,
          },
          {
            name: 'sex',
            position: 7,
            span: 4,
          },
        ],
      },
      {
        position: 1,
        title: 'Address',
        fields: [
          {
            name: 'address.address1',
            position: 0,
            span: 6,
          },
          {
            name: 'address.address2',
            position: 1,
            span: 6,
          },
          {
            name: 'address.city',
            position: 2,
            span: 6,
          },
          {
            name: 'address.province',
            position: 2,
            span: 6,
          },
          {
            name: 'address.country',
            position: 2,
            span: 6,
          },
          {
            name: 'address.postal_code',
            position: 2,
            span: 6,
          },
          {
            name: 'office_location',
            position: 2,
            span: 6,
          },
          {
            name: 'billing_division',
            position: 2,
            span: 6,
          },
        ],
      },
      {
        position: 2,
        title: 'Job Details',
        fields: [
          {
            name: 'title',
            position: 0,
            span: 6,
          },
          {
            name: 'employer_issued_employee_id',
            position: 1,
            span: 6,
          },
          {
            name: 'currency',
            position: 2,
            span: 6,
          },
          {
            name: 'date_of_hire',
            position: 4,
            span: 6,
          },
          {
            name: 'hrs_worked_per_week',
            position: 5,
            span: 6,
          },
          {
            name: 'province_of_employment',
            position: 6,
            span: 6,
          },
          {
            name: 'employment_status',
            position: 7,
            span: 6,
          },
          {
            name: 'annual_earnings',
            position: 8,
            span: 6,
          },
          {
            name: 'annual_earnings_effective_date',
            position: 9,
            span: 6,
          },
          {
            name: 'annual_earnings_for_pooled_benefits',
            position: 10,
            span: 6,
          },
          {
            name: 'employee_leave',
            position: 11,
            span: 6,
          },
          {
            name: 'employee_leave_start_date',
            position: 12,
            span: 6,
          },
        ],
      },
      {
        position: 3,
        title: 'Supervisor Details',
        fields: [
          {
            name: 'supervisor.employee_id',
            position: 1,
            span: 6,
          },
          {
            name: 'supervisor.email',
            position: 2,
            span: 6,
          },
          {
            name: 'supervisor.name',
            position: 3,
            span: 6,
          },
          {
            name: 'supervisor.phone_number',
            position: 4,
            span: 6,
          },
        ],
      },
      {
        position: 4,
        title: 'HR Manager Details',
        fields: [
          {
            name: 'hr_manager.email',
            position: 1,
            span: 6,
          },
          {
            name: 'hr_manager.name',
            position: 12,
            span: 6,
          },
          {
            name: 'hr_manager.phone_number',
            position: 3,
            span: 6,
          },
        ],
      },
      {
        position: 5,
        title: 'Default Settings',
        fields: [
          {
            name: 'benefits_start_date',
            position: 0,
            span: 6,
          },
        ],
      },
    ],
  }, // layout
};
