import React, { useEffect, useState } from 'react';
import ActionsConfirmation from './actions-confirmation.view';
import PropTypes from 'prop-types';
import {
  UNSCHEDULE,
  CONFIRMATION,
  DATE_PICKER_MODAL,
  FORM_TYPES,
} from '../../constants';
import { startsWith } from 'lodash';
import { omitKeysWithEmptyValues } from '@leagueplatform/web-common';
import { useActionsConfirmationReducer } from './use-actions-confirmation.reducer';

const useActionsConfirmation = ({
  setEffectiveDate,
  setReason,
  setNoticeDate,
  setEOBDate,
  formType,
  onActionConfirmedClick,
}) => {
  const [actionInvalidDate, setActionInvalidDate] = useState(false);

  const {
    effectiveDate,
    noticeDate,
    EOBDate,
    dateIsEmpty,
    reasonIsEmpty,
    reasonForTermination,
    handleActionNoticeDate,
    handleActionEOBDate,
    handleActionEffectiveDate,
    setReasonForTermination,
    setDateIsEmpty,
    setReasonIsEmpty,
  } = useActionsConfirmationReducer();

  useEffect(() => {
    if (EOBDate) setEOBDate(EOBDate); // set and update parent prop
    if (noticeDate) setNoticeDate(noticeDate); // set and update parent prop
    if (effectiveDate) setEffectiveDate(effectiveDate); // set and update parent prop
    if (reasonForTermination) setReason(reasonForTermination); // set and update parent prop
  }, [
    EOBDate,
    setEOBDate,
    setNoticeDate,
    noticeDate,
    effectiveDate,
    setEffectiveDate,
    setReason,
    reasonForTermination,
  ]);

  const onConfirm = () => {
    let isValid = true;

    if (DATE_PICKER_MODAL.includes(formType) && !effectiveDate) {
      setDateIsEmpty(true);
      isValid = false;
    }

    if (
      formType === FORM_TYPES.terminateConfirmation &&
      !reasonForTermination
    ) {
      setReasonIsEmpty(true);
      isValid = false;
    }

    if (isValid) {
      onActionConfirmedClick(
        omitKeysWithEmptyValues({
          actionEffectiveDate: effectiveDate,
          reasonForTermination,
          actionNoticeDate: noticeDate,
          actionEOBDate: EOBDate,
        }),
      );
    }
  };

  const typeId = startsWith(formType, UNSCHEDULE) ? UNSCHEDULE : CONFIRMATION;

  return {
    setActionEffectiveDate: handleActionEffectiveDate,
    setActionNoticeDate: handleActionNoticeDate,
    setActionEOBDate: handleActionEOBDate,
    onConfirm,
    setActionInvalidDate,
    setReasonForTermination,
    actionInvalidDate,
    typeId,
    dateIsEmpty,
    reasonIsEmpty,
  };
};

export const ActionsConfirmationContainer = props => {
  const actionsConfirmationProps = useActionsConfirmation(props);

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <ActionsConfirmation {...props} {...actionsConfirmationProps} />;
};

ActionsConfirmationContainer.propTypes = {
  setEffectiveDate: PropTypes.func.isRequired,
  setReason: PropTypes.func.isRequired,
  setNoticeDate: PropTypes.func.isRequired,
  setEOBDate: PropTypes.func.isRequired,
  formType: PropTypes.string,
  onActionConfirmedClick: PropTypes.func.isRequired,
};

ActionsConfirmationContainer.defaultProps = {
  formType: '',
};
