import React from 'react';
import PropTypes from 'prop-types';
import { ConnectFieldSelectors } from './connect-field-selectors.hoc';
import { get } from 'lodash';
import {} from '@leagueplatform/ui-kit';
import { TextInput, Flex, Box } from '@leagueplatform/genesis-commons';
import { JSONSchemaFieldPropType } from '../prop-types';
import { CURRENCY_SYMBOLS } from 'common/constants';
import { FieldWrapper } from './field-wrapper.view';

const CurrencySignLabelContainer = props => (
  <Flex
    alignItems="center"
    justifyContent="center"
    paddingY="half"
    paddingX="one"
    backgroundColor="interactive.background.default"
    borderTopLeftRadius="medium"
    borderBottomLeftRadius="medium"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const CurrencyLabelContainer = props => (
  <CurrencySignLabelContainer
    borderTopLeftRadius={0}
    borderBottomLeftRadius={0}
    borderTopRightRadius="medium"
    borderBottomRightRadius="medium"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const MoneyFieldPresenter = ({
  form,
  field,
  groupCurrency,
  propSchema,
  ...props
}) => {
  const currency = get(field, 'value.C', groupCurrency);
  const savedValue = get(field, 'value.M');
  const inputValue = savedValue ? savedValue / 100 : '';
  const setMoneyKey = e =>
    form.setFieldValue(field.name, {
      C: currency,
      M: Math.round(Number(e.target.value) * 100),
    });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <Box flex={2} alignSelf="center">
        <Flex>
          <CurrencySignLabelContainer>
            {CURRENCY_SYMBOLS[currency]}
          </CurrencySignLabelContainer>
          <TextInput
            borderRadius="none"
            type="number"
            step=".01"
            value={inputValue}
            onChange={e => setMoneyKey(e)}
          />
          <CurrencyLabelContainer basic>{currency}</CurrencyLabelContainer>
        </Flex>
      </Box>
    </FieldWrapper>
  );
};

MoneyFieldPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  groupCurrency: PropTypes.string.isRequired,
};

export const MoneyField = ConnectFieldSelectors(MoneyFieldPresenter);
