import { takeLatest, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';

import { addGroupInvoiceAdjustment } from '../services';

import { SET_FEE } from './fees.action-types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* saveFeeRequest({ payload, params }) {
  const values = {
    ...payload.fee,
    rate: Number(payload.fee.rate),
    units: Number(payload.fee.units),
    term: Number(payload.fee.term),
    group_id: payload.groupId,
  };

  yield request(SET_FEE, addGroupInvoiceAdjustment(values));
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,

    content: params.content,
    shouldAutoClose: false,
  });
}

export function* saveFeeErrored({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: payload.info.reason,

    shouldAutoClose: false,
  });
}

export function* feesSaga() {
  yield takeLatest(SET_FEE.BASE, saveFeeRequest);
  yield takeLatest(SET_FEE.ERRORED, saveFeeErrored);
}
