import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { selectEmployerDetails } from '../../../../../../../selectors';

export const selectGroupId = createSelector(
  selectEmployerDetails,
  get('employer.groupId'),
);

export const selectEmployerBenefitDocumentsEntities = createSelector(
  selectEmployerDetails,
  get('employerBenefitDocuments.entities.benefitDocuments'),
);

export const selectAddingEmployerBenefitDocuments = createSelector(
  selectEmployerDetails,
  get('employerBenefitDocuments.addingEmployerBenefitDocument'),
);
