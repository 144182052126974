import {
  DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION,
  RESET_CHANGE_ALLOCATION_STATUS,
} from './change-allocation.types';

export const initialState = {
  pending: false,
  error: undefined,
  completed: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const changeAllocationReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION.STARTED:
      return {
        pending: true,
        error: undefined,
        completed: false,
      };
    case DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION.SUCCEEDED:
      return {
        pending: false,
        error: undefined,
        completed: true,
      };
    case DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION.ERRORED:
      return {
        pending: false,
        error: action.payload.info.reason,
        completed: false,
      };
    case RESET_CHANGE_ALLOCATION_STATUS:
      return initialState;
    default:
      return state;
  }
};
