import { useIntl } from '@leagueplatform/locales';

/**
 * A custom hook with a function that will return formatted strings
 * For example:
 * if there is only one string in the list: String1
 * if there are two strings in the list: String1 and String2
 * if there are more than two strings in the list: String1, String2, and String3
 * Use the second parameter when each string in the array needs to include an english article "a" e.g. a String1, a String2, and a String3.
 * When using the second parameter, the en.json string should be in this format
 * "A_STRING": "a {placeholder}"
 * @param {string[]} stringsList - An array of strings
 * @param {object} messageDescriptor - An object containing the string id and value
 * @param {string} messageDescriptor.id - The formatMessage ID
 * @param {string} messageDescriptor.value - The formatMessage placeholder e.g. a {benefitName}
 */

export const useFormattedStringsList = () => {
  const { formatMessage } = useIntl();

  const getFormattedStringsList = (stringsList, messageDescriptor) => {
    const getFormattedMessage = value => {
      return messageDescriptor
        ? formatMessage(
            { id: messageDescriptor.id },
            { [messageDescriptor.value]: value },
          )
        : value;
    };

    if (Array.isArray(stringsList)) {
      const messages = stringsList.map(getFormattedMessage);
      const listLength = messages.length;
      const exceptLast = messages.slice(0, listLength - 1);
      const lastString = messages[listLength - 1];
      const and = formatMessage({ id: 'AND' });

      if (listLength === 1) {
        return messages[0];
      }
      if (listLength === 2) {
        return `${messages[0]} ${and} ${messages[1]}`;
      }
      if (listLength > 2) {
        return `${exceptLast.join(', ')}, ${and} ${lastString}`;
      }
    }

    return '';
  };

  return {
    getFormattedStringsList,
  };
};
