import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { useFetch } from 'common/hooks/use-fetch/use-fetch.hook';
import { ConfirmationModal, Backdrop } from '@leagueplatform/ui-kit';
import { Button } from '../../employer-experience/ui-kit';
import {
  Flex,
  BodyOne,
  Box,
  ErrorMessage,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const List = genesisStyled(props => <Box as="ul" {...props} />)(
  css({
    listStyleType: 'disc',
    marginTop: 'one',
    marginLeft: 'oneAndHalf',
    '& em': {
      fontStyle: 'italic',
    },
  }),
);

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const ListItem = props => <BodyOne {...props} as="li" marginBottom="half" />;

const HRAdminClassChangeMessage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <BodyOne>
        {formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_1' })}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_2' })}
        </ListItem>
        <ListItem>
          {formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_3' })}
        </ListItem>
        <ListItem>
          {formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_4' })}
        </ListItem>
      </List>
    </>
  );
};

const LeagueAdminClassChangeRequiresSelectionsMessage = ({ reason }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_BULLET_1' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { strong: msg => <strong>{msg}</strong> },
          )}
        </ListItem>
      </List>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_2' },
            { change_reason: reason },
          )}
        </ListItem>
      </List>
    </>
  );
};

LeagueAdminClassChangeRequiresSelectionsMessage.propTypes = {
  reason: PropTypes.string.isRequired,
};

const LeagueAdminClassChangeAutoAllocateMessage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_1' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_2' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
        <ListItem>
          {formatMessage({
            id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_3',
          })}
        </ListItem>
        <ListItem>
          {formatMessage({
            id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_4',
          })}
        </ListItem>
        <ListItem>
          {formatMessage({
            id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_5',
          })}
        </ListItem>
        <ListItem>
          {formatMessage({
            id: 'CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_6',
          })}
        </ListItem>
      </List>
    </>
  );
};

const LeagueAdminClassChangeBCCEnrollmentMessage = ({ reason }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_1' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_2' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_3' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_4' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
      </List>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_2' },
            { change_reason: reason },
          )}
        </ListItem>
      </List>
    </>
  );
};

LeagueAdminClassChangeBCCEnrollmentMessage.propTypes = {
  reason: PropTypes.string.isRequired,
};

const LeagueAdminClassChangeUserNotEnrolledMessage = ({ reason }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_BULLET_1' },
            // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
            { em: msg => <em>{msg}</em> },
          )}
        </ListItem>
      </List>
      <BodyOne>
        {formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_1' },
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          { strong: msg => <strong>{msg}</strong> },
        )}
      </BodyOne>
      <List>
        <ListItem>
          {formatMessage(
            { id: 'CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_2' },
            { change_reason: reason },
          )}
        </ListItem>
      </List>
    </>
  );
};

LeagueAdminClassChangeUserNotEnrolledMessage.propTypes = {
  reason: PropTypes.string.isRequired,
};

const LeagueAdminClassChangeErrorMessage = ({ reason }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <BodyOne color="onSurface.text.critical" marginBottom="one">
        {formatMessage({
          id: 'CHANGE_EMPLOYEE_CLASS_ERROR_MESSAGE',
        })}
      </BodyOne>
      <ErrorMessage>{reason}</ErrorMessage>
    </>
  );
};

LeagueAdminClassChangeErrorMessage.propTypes = {
  reason: PropTypes.string.isRequired,
};

const LeageAdminClassChangeMessage = ({ changeType, changeReason, error }) => {
  const { formatMessage } = useIntl();

  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  if (error) return <LeagueAdminClassChangeErrorMessage {...error?.info} />;

  switch (changeType) {
    case 'manual_selection':
      return (
        <LeagueAdminClassChangeRequiresSelectionsMessage
          reason={changeReason}
        />
      );
    case 'auto_allocation':
      return <LeagueAdminClassChangeAutoAllocateMessage />;
    case 'bcc_enrollment':
      return (
        <LeagueAdminClassChangeBCCEnrollmentMessage reason={changeReason} />
      );
    case 'user_not_enrolled':
      return (
        <LeagueAdminClassChangeUserNotEnrolledMessage reason={changeReason} />
      );
    default:
      return (
        <LeagueAdminClassChangeErrorMessage
          reason={formatMessage({
            id: 'CHANGE_EMPLOYEE_CLASS_ERROR_UNDEFINED_CHANGE_TYPE_MESSAGE',
          })}
        />
      );
  }
};

LeageAdminClassChangeMessage.propTypes = {
  changeType: PropTypes.string,
  changeReason: PropTypes.string,
  error: PropTypes.shape({
    info: PropTypes.shape({
      reason: PropTypes.string,
    }),
  }),
};

LeageAdminClassChangeMessage.defaultProps = {
  changeType: '',
  changeReason: '',
  error: null,
};

export const useGetBenefitClassChangeType = ({
  employeeUserId,
  targetBenefitClassId,
  isLeagueAdmin,
}) => {
  const [lastCheckedBenefitClassId, setLastCheckedBenefitClassId] =
    useState(null);
  const [changeType, setChangeType] = useState(null);
  const [changeReason, setChangeReason] = useState(null);
  const { fetch, loading, error } = useFetch();

  const getBenefitClassChangeType = useCallback(async () => {
    const response = await fetch({
      messageType: 'get_benefit_class_change_type',
      info: {
        user_id: employeeUserId,
        target_benefit_class_id: targetBenefitClassId,
      },
    });

    if (response) {
      setChangeType(response?.results[0]?.change_type);
      setChangeReason(response?.results[0]?.reason);
    }
  }, [
    employeeUserId,
    targetBenefitClassId,
    setChangeType,
    setChangeReason,
    fetch,
  ]);

  useEffect(() => {
    if (
      isLeagueAdmin &&
      targetBenefitClassId &&
      targetBenefitClassId !== lastCheckedBenefitClassId
    ) {
      setLastCheckedBenefitClassId(targetBenefitClassId);
      getBenefitClassChangeType();
    }
  }, [
    isLeagueAdmin,
    targetBenefitClassId,
    lastCheckedBenefitClassId,
    getBenefitClassChangeType,
  ]);

  return {
    changeType,
    changeReason,
    loading,
    error,
  };
};

export const ChangeBenefitClassModal = ({
  employee,
  isOpen,
  toggleModal,
  handleConfirm,
  handleCancel,
  isAdmin: isLeagueAdmin,
  targetBenefitClassId,
  employeeUserId,
}) => {
  const { formatMessage } = useIntl();
  const { changeType, changeReason, loading, error } =
    useGetBenefitClassChangeType({
      isLeagueAdmin,
      employeeUserId,
      targetBenefitClassId,
    });

  const isHRAdmin = !isLeagueAdmin;

  return isOpen ? (
    <Backdrop onClose={toggleModal}>
      <ConfirmationModal
        headerText={formatMessage(
          { id: 'CHANGE_EMPLOYEE_CLASS_HEADER' },
          { employee: `${employee.first_name} ${employee.last_name}` },
        )}
        footerContent={
          <Flex>
            <Button confirmModal cancel onClick={handleCancel}>
              {formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_CANCEL' })}
            </Button>
            <Button confirmModal primary onClick={handleConfirm}>
              {formatMessage({ id: 'CHANGE_EMPLOYEE_CLASS_CONFIRM' })}
            </Button>
          </Flex>
        }
      >
        {isHRAdmin && <HRAdminClassChangeMessage />}
        {isLeagueAdmin &&
          (loading ? (
            formatMessage({ id: 'CHECKING_CLASS_CHANGE_TYPE' })
          ) : (
            <LeageAdminClassChangeMessage
              changeType={changeType}
              changeReason={changeReason}
              error={error}
            />
          ))}
      </ConfirmationModal>
    </Backdrop>
  ) : null;
};

ChangeBenefitClassModal.propTypes = {
  employee: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  targetBenefitClassId: PropTypes.string,
  employeeUserId: PropTypes.string.isRequired,
};

ChangeBenefitClassModal.defaultProps = {
  isAdmin: false,
  targetBenefitClassId: null,
};
