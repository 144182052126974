import { createSelector } from 'reselect';
import { get, negate } from 'lodash/fp';
import {
  isBlueCatUsId,
  isFinning,
  isKPMG,
  isShopify,
} from 'apps/utils/employer-utils';
import { HSA } from '../../../common/benefits';

const selectResources = get('resources');
export const selectGroup = createSelector(selectResources, get('group'));
export const selectGroupId = createSelector(selectGroup, group => group?.id);
export const selectEmployerName = createSelector(
  selectGroup,
  group => group?.name,
);
export const selectIsKPMG = createSelector(selectEmployerName, name =>
  isKPMG(name),
);
export const selectIsShopify = createSelector(selectEmployerName, name =>
  isShopify(name),
);
export const selectIsBlueCatUS = createSelector(selectGroupId, id =>
  isBlueCatUsId(id),
);
export const selectIsFinning = createSelector(selectEmployerName, name =>
  isFinning(name),
);

export const selectCurrency = createSelector(
  selectGroup,
  group => group?.currency,
);

export const selectHelpDeskLink = createSelector(
  selectGroup,
  group => group?.helpDeskLink,
);

export const selectEmployer = createSelector(
  selectGroup,
  group => group?.group?.employer,
);

export const selectEmployerCountryCode = createSelector(
  selectGroup,
  group => group?.countryCode,
);

export const selectIsUpdatedViaIntegration = createSelector(
  selectGroup,
  group => group?.isUpdatedViaIntegration ?? false,
);

export const selectImmediatelyAllocateNewHireSubmission = createSelector(
  selectGroup,
  group => group?.immediatelyAllocateNewHireSubmission ?? false,
);

export const selectIsDomesticPartnershipEnabled = createSelector(
  selectEmployer,
  negate(employer => employer?.domestic_partnership_disabled),
);

export const selectEmployerTzName = createSelector(
  selectGroup,
  group => group?.employerTzName,
);

export const selectBenefitClasses = createSelector(
  selectGroup,
  group => group?.benefitClasses,
);

export const selectIsAnonymous = createSelector(
  selectGroup,
  group => group?.isAnonymous,
);

export const selectIsDependentNINRequired = createSelector(
  selectEmployer,
  employer => employer?.dependent_nin_required ?? false,
);

/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
export const selectIsCoverageHidden = createSelector(
  (_, { product_type }) => product_type,
  selectIsFinning,
  selectIsKPMG,
  selectIsShopify,
  (productType, finning, kpmg, shopify) =>
    finning || (productType === HSA && (shopify || kpmg)),
);
