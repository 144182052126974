import { selectApp } from 'apps/employer-experience-v2/app.selectors';
import { createSelector } from 'reselect';

const selectDependentsReport = createSelector(
  selectApp,
  ({ employerDependentsReport }) => employerDependentsReport,
);

export const selectDependentsReportLoading = createSelector(
  selectDependentsReport,
  ({ dependentsReportLoading }) => dependentsReportLoading,
);
