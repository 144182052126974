import React from 'react';
import {
  genesisStyled,
  Box,
  HeadingOne,
  HeadingTwo,
  BodyOne,
  BodyTwo,
  Link,
  Spinner,
} from '@leagueplatform/genesis-commons';
import { useBillingInsights } from './use-billing-insights.hook';
import { useIntl } from 'react-intl';
import { BackLink } from 'common/components/back-link/back-link.component';
import { BILLING_INSIGHTS_DASHBOARD_ID } from 'common/insight-dashboards/employer-insights.constants';

const IFrame = genesisStyled.iframe`
  height: 800px;
  width: 100%
`;

const dashboardId = BILLING_INSIGHTS_DASHBOARD_ID;

type BillingInsightsProps = {
  groupId: string;
  isAdmin?: boolean;
  match: {
    params: {
      year: string;
      month: string;
    };
  };
};

const BillingInsights = ({
  groupId,
  isAdmin,
  match: {
    params: { year, month },
  },
}: BillingInsightsProps) => {
  const { formatMessage } = useIntl();
  const { dashboardData, isLoading } = useBillingInsights(
    groupId,
    dashboardId,
    year,
    month,
  );

  if (isLoading) {
    return <Spinner isLoading />;
  }
  return (
    <Box>
      <HeadingOne marginBottom="two">
        {formatMessage({ id: 'BILLING' })}
      </HeadingOne>
      <HeadingTwo marginBottom="one">
        {formatMessage({ id: 'INVOICE_DATA' })}
      </HeadingTwo>
      <BodyTwo marginBottom="threeQuarters">
        {formatMessage({ id: 'BILLINGS_INSIGHTS_MESSAGE' })}
      </BodyTwo>
      <BackLink
        to={
          isAdmin
            ? `/admin/employers/${groupId}/billing/invoices`
            : `/manage/billing`
        }
        label={formatMessage({ id: 'BACK_TO_INVOICES' })}
      />
      <IFrame src={dashboardData?.dashboard_url} title="looker-iframe" />
      <BodyOne marginY="one">
        {formatMessage(
          { id: 'INVOICES_HELP_KB_MESSAGE' },
          {
            link: (
              <Link
                rel="noopener noreferrer"
                href={formatMessage({ id: 'KNOWLEDGE_BASE_URL' })}
                target="_blank"
                fontWeight="bold"
              >
                {formatMessage({
                  id: 'KNOWLEDGE_BASE',
                })}
              </Link>
            ),
          },
        )}
      </BodyOne>
      <BodyOne marginY="one">
        {formatMessage(
          { id: 'INVOICES_HELP_EMAIL_MESSAGE' },
          {
            link: (
              <Link
                href={`mailto:${formatMessage({
                  id: 'BILLING_EMAIL',
                })}`}
                fontWeight="bold"
              >
                {formatMessage({
                  id: 'BILLING_EMAIL',
                })}
              </Link>
            ),
          },
        )}
      </BodyOne>
    </Box>
  );
};

export default BillingInsights;
