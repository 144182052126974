import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getBenefitPrices - gets benefit prices for enrollment experience
 *
 * There are more possible keys than the ones listed below but these are the ones that we care about in the new
 * enrollment experience
 *
 * @arg {string} userId  - The employee user ID
 * @arg {string} providerId  - This is typically passed as groupId but providerId is a newer paradigm that
 *
 * @returns {Object} - An object
 */

export const getBenefitPrices = ({ userId, providerId, planId }) =>
  call(websocketFetch, 'get_benefit_prices', {
    user_id: userId,
    provider_id: providerId,
    plan_id: planId,
  });
