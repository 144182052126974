import { Image } from '@leagueplatform/genesis-commons';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { VIEW_ALL } from 'common/constants';
import WarningIcon from 'apps/public/assets/warning-icon-orange.svg';
import { ClaimsTable } from './components/claims-table/claims-table.component';
import { claimShape } from './types/types';
import {
  Select,
  Box,
  styled,
  keyframes,
  HeadingText,
  Button,
  ParagraphText,
} from '@leagueplatform/genesis-core';

const CLAIM_TABLE_CAPTION_ID = 'claims-heading-id';

export const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const FadeInBox = styled(Box, {
  animation: `${fadeIn} 0.4s ease`,
});

export const ClaimsPage = ({
  claims,
  statusOptions,
  statusFilter,
  onSelectClaim,
  onChangeStatus,
  needsMoreInfo,
  ready,
  history,
}) => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'CLAIMS' }));

  return (
    <Box
      css={{
        marginTop: '$three',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1000px',
        paddingBottom: '$four',
        paddingLeft: '$two',
        paddingRight: '$two',
      }}
    >
      <Box
        css={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <HeadingText
            id={CLAIM_TABLE_CAPTION_ID}
            level="1"
            size="xxl"
            css={{
              fontWeight: 'normal',
              marginTop: '$one',
              marginBottom: '$one',
            }}
          >
            {formatMessage({ id: 'CLAIMS' })}
          </HeadingText>
          {claims && (
            <Box>
              <Select
                aria-label={formatMessage({ id: 'VIEW_CLAIM_SELECT_LABEL' })}
                defaultValue={VIEW_ALL}
                disabled={!ready}
                onChange={event => {
                  onChangeStatus(event.target.value);
                }}
                options={statusOptions}
                selectedValue={statusFilter}
              />
            </Box>
          )}
        </Box>
        <div aria-live="polite">
          {needsMoreInfo && (
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '$warningBackgroundSubdued',
                borderColor: '$warningBorderDefault',
                borderRadius: '$medium',
                borderStyle: 'solid',
                borderWidth: '$thin',
                paddingLeft: '$one',
                paddingRight: '$one',
                paddingTop: '$threeQuarters',
                paddingBottom: '$threeQuarters',
              }}
            >
              <Box marginRight="one">
                <Image
                  height={20}
                  role="presentation"
                  src={WarningIcon}
                  width={20}
                />
              </Box>
              {formatMessage({ id: 'CLAIM_LIST_NEEDS_MORE_INFO' })}
            </Box>
          )}
          {claims && claims.length > 0 && (
            <FadeInBox
              css={{ marginTop: '$one', overflowX: 'scroll' }}
              role="region"
              aria-labelledby={CLAIM_TABLE_CAPTION_ID}
              tabindex="0"
            >
              <ClaimsTable
                claims={claims}
                onSelectClaim={onSelectClaim}
                history={history}
              />
            </FadeInBox>
          )}
          {claims && claims.length === 0 && statusFilter && (
            <FadeInBox
              css={{
                backgroundColor: '$surfaceBackgroundSecondary',
                marginTop: '$none',
                marginBottom: '$none',
                paddingLeft: '$two',
                paddingRight: '$two',
                paddingTop: '$three',
                paddingBottom: '$three',
                textAlign: 'center',
              }}
            >
              <ParagraphText>
                {formatMessage(
                  { id: 'YOU_DONT_HAVE_CLAIMS' },
                  {
                    status: formatMessage({
                      id: statusFilter?.toUpperCase(),
                    }).toUpperCase(),
                  },
                )}
              </ParagraphText>
              <Button
                priority="primary"
                css={{
                  marginTop: '$one',
                  marginBottom: '$one',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                onClick={() => onChangeStatus(VIEW_ALL)}
              >
                {formatMessage({ id: 'VIEW_ALL_CLAIMS' })}
              </Button>
            </FadeInBox>
          )}
          {claims && claims.length === 0 && !statusFilter && ready && (
            <FadeInBox
              css={{
                backgroundColor: '$surfaceBackgroundSecondary',
                marginTop: '$none',
                marginBottom: '$none',
                paddingLeft: '$two',
                paddingRight: '$two',
                paddingTop: '$three',
                paddingBottom: '$three',
                textAlign: 'center',
              }}
            >
              <HeadingText level="2" size="lg">
                {formatMessage({ id: 'NO_CLAIMS' })}
              </HeadingText>
              <ParagraphText>
                {formatMessage({ id: 'NO_CLAIMS_INFO' })}
              </ParagraphText>
              <Button
                as={Link}
                priority="primary"
                to="/member/wallet"
                css={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '$one',
                  marginBottom: '$one',
                  textDecoration: 'none',
                }}
              >
                {formatMessage({ id: 'SUBMIT_CLAIM' })}
              </Button>
            </FadeInBox>
          )}
        </div>
      </Box>
    </Box>
  );
};

ClaimsPage.displayName = 'ClaimsPage';

ClaimsPage.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.shape(claimShape)).isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  statusFilter: PropTypes.string,
  needsMoreInfo: PropTypes.bool,
  onSelectClaim: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  ready: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ClaimsPage.defaultProps = {
  statusFilter: null,
  needsMoreInfo: false,
  ready: false,
};
