import {
  ADD_BENEFIT_CLASS_TYPEFORM_URLS,
  FETCH_BENEFIT_CLASS_TYPEFORM_URLS,
  RESET_INTEGRATION_FORM_UI,
} from './employer-integrations.types';

export const getBenefitClassTypeformUrls = groupId => ({
  type: FETCH_BENEFIT_CLASS_TYPEFORM_URLS,
  payload: {
    groupId,
  },
});

export const addBenefitClassTypeformUrls = payload => ({
  type: ADD_BENEFIT_CLASS_TYPEFORM_URLS,
  payload,
});

export const resetIntegrationFormUI = () => ({
  type: RESET_INTEGRATION_FORM_UI,
});
