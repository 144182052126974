import { isDev, isProduction, isStaging } from '@leagueplatform/core';

/**
 * Creates cookie options that match those used to create cookies in WidgetRunner.
 * This ensures cookies are created in the same origin as those in WidgetRunner
 *
 * @returns object with path and domain set based on environment
 */
export const getCookieOptions = () => {
  const baseOptions = { path: '/' };

  if (isProduction()) {
    return {
      ...baseOptions,
      domain: '.league.com',
    };
  }

  if (isStaging() || isDev()) {
    return {
      ...baseOptions,
      domain: '.league.dev',
    };
  }

  return baseOptions;
};
