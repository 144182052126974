import { cloneDeep } from 'lodash';
import {
  BenefitStep,
  BenefitSet,
  BenefitSetGroup,
  EnrollmentStepItemType,
} from './enrollment-design-options.types';
import { DraggableLocation } from 'react-beautiful-dnd';
import {
  DROPPABLE_TYPES,
  STEPS_LIST,
  AVAILABLE_BENEFIT_SETS_LIST,
  BENEFIT_SET_TYPE,
} from './enrollment-experience-config.constants';
import { normalize, schema, denormalize } from 'normalizr';

/**
 * @param  {any[]} list  - This can be array of anything whose elements are to be shuffled
 * @param  {number} sourceIndex - Element Index to be moved
 * @param  {number} destinationIndex - Element Index to be moved at
 */
export const reorderElements = (
  list: any[],
  sourceIndex: number,
  destinationIndex: number,
) => {
  const result = cloneDeep(list);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

/**
 * Function to return custom drag/drop ids with type of list or element it is used for
 * @param  {string} id - Id of respective draggable/droppable
 * @param  {string} type - Type of respective draggable/droppable - 'step' | 'group'
 */
export const createDragDropId = (id: string, type: string) => `${type}-${id}`;

export const isDroppableListStep = (id: string = '') =>
  id.includes(DROPPABLE_TYPES.STEP);

export const isDroppableListGroup = (id: string = '') =>
  id.includes(DROPPABLE_TYPES.GROUP);

export const isSourceAndDestinationDroppableDifferent = (
  sourceId: string,
  destinationId: string,
) => {
  return (
    (sourceId.includes(DROPPABLE_TYPES.GROUP) &&
      destinationId.includes(DROPPABLE_TYPES.STEP)) ||
    (destinationId.includes(DROPPABLE_TYPES.GROUP) &&
      sourceId.includes(DROPPABLE_TYPES.STEP))
  );
};

export const getIdFromSlug = (slug: string) => slug.split('-')[1];

type DropResult = {
  source: DraggableLocation;
  destination: DraggableLocation;
  draggableId?: string;
};

/**
 * Function to re-order Benefit sets and Benefit set groups within a single step or to
 * move between the steps
 *
 * @param  {BenefitStep[]} stepsList - List of benefit steps
 * @param  {DropResult} dropResult - {source, destination} object containing source and
 * destination information of the droppables in which the active draggable is being dragged.
 */
export const reorderBenefitSetsOrGroups = (
  list: BenefitStep[],
  { source, destination }: DropResult,
): BenefitStep[] => {
  const sourceStepIndex = list.findIndex(
    step => getIdFromSlug(source.droppableId) === step.id,
  );
  const destinationStepIndex = list.findIndex(
    step => getIdFromSlug(destination?.droppableId) === step.id,
  );

  if (source?.droppableId === destination?.droppableId) {
    // eslint-disable-next-line no-param-reassign
    list[sourceStepIndex].sets = reorderElements(
      list[sourceStepIndex]?.sets,
      source.index,
      destination.index,
    );
  } else {
    const activeSetOrGroup = list[sourceStepIndex]?.sets[source?.index];
    list[sourceStepIndex]?.sets.splice(source?.index, 1);
    // eslint-disable-next-line no-param-reassign
    list[destinationStepIndex].sets = list[destinationStepIndex]?.sets ?? [];
    list[destinationStepIndex]?.sets.splice(
      destination?.index,
      0,
      activeSetOrGroup,
    );
  }
  return list;
};

// Schema for normalize List of benefit steps into steps and sets
const set = new schema.Entity('sets');
set.define({ sets: [set] });
const step = new schema.Entity('steps', {
  sets: [set],
});

export const getNormalizedSetList = (list: any[]) => {
  return normalize(list, [step]);
};

export const getDenormalizedSetList = (list: any[], entities: any) => {
  return denormalize(list, [step], entities);
};

/**
 * Function to reorder benefit sets within the same group to within different groups
 * @param  {BenefitStep[]} stepsList - List of benefit steps
 * @param  {DropResult} dropResult - Drop result from onDragEnd event of react-beautiful-dnd
 */
export const reorderBenefitSetsInGroup = (
  list: BenefitStep[],
  { source, destination }: DropResult,
): BenefitStep[] => {
  const normalizedSet = getNormalizedSetList(list);
  const {
    entities: { sets, steps },
    result,
  } = normalizedSet;

  const sourceId = getIdFromSlug(source.droppableId);
  const destinationId = getIdFromSlug(destination.droppableId);

  if (source?.droppableId === destination?.droppableId) {
    sets[sourceId].sets = [
      ...reorderElements(sets[sourceId].sets, source.index, destination.index),
    ];
    return getDenormalizedSetList(result, { steps, sets });
  }
  const activeBenefitSet = sets[sourceId].sets[source.index];
  sets[destinationId].sets = sets[destinationId].sets ?? [];
  sets[destinationId].sets.splice(destination?.index, 0, activeBenefitSet);
  sets[sourceId].sets.splice(source.index, 1);
  return getDenormalizedSetList(result, { steps, sets });
};

export const reorderBenefitSetsBetweenGroupAndStep = (
  list: BenefitStep[],
  { source, destination, draggableId }: DropResult,
) => {
  const normalizedSet = getNormalizedSetList(list);
  const {
    entities: { sets = {}, steps },
    result,
  } = normalizedSet;

  // To handle scenario where same group is being dragged inside it.
  if (
    destination.droppableId === draggableId ||
    (isDroppableListGroup(destination.droppableId) &&
      isDroppableListGroup(draggableId))
  )
    return list;

  const sourceId = getIdFromSlug(source.droppableId);
  const destinationId = getIdFromSlug(destination.droppableId);

  // Moving Benefit set from a Step to a Group
  if (
    isDroppableListStep(source.droppableId) &&
    isDroppableListGroup(destination.droppableId)
  ) {
    steps[sourceId].sets.splice(source.index, 1);
    const soureStepIndex = list.findIndex(_step => _step.id === sourceId);
    sets[destinationId].sets = sets[destinationId].sets ?? [];
    sets[destinationId].sets.splice(
      destination.index,
      0,
      list[soureStepIndex].sets[source.index],
    );
    return getDenormalizedSetList(result, { steps, sets });
  }
  // Moving Benefit set from a Group to a Step

  const activeBenefitSetId = sets[sourceId].sets[source.index];
  sets[sourceId].sets.splice(source.index, 1);
  if (activeBenefitSetId) {
    steps[destinationId].sets = steps[destinationId].sets ?? [];
    steps[destinationId].sets.splice(destination.index, 0, activeBenefitSetId);
  }
  return getDenormalizedSetList(result, { steps, sets });
};

export const reorderElementsInStepsList = (
  stepsList: BenefitStep[],
  dropResult: DropResult,
) => {
  let list = cloneDeep(stepsList);
  let refreshList = false;
  const { source, destination, draggableId } = dropResult;

  if (
    source?.droppableId === STEPS_LIST &&
    source?.droppableId === destination?.droppableId
  ) {
    list = reorderElements(stepsList, source.index, destination.index);
  }
  //  Check if benefit set/group elements are being re-ordered are
  //  within the steps list by checking the droppable list Ids to contain "step".
  else if (
    isDroppableListStep(source.droppableId) &&
    isDroppableListStep(destination.droppableId)
  ) {
    // @TODO - look for reason why group droppables won't work when moved between steps
    if (
      isDroppableListGroup(draggableId) &&
      source.droppableId !== destination.droppableId
    )
      refreshList = true;

    list = reorderBenefitSetsOrGroups(list, dropResult);
  }
  //  Check if benefit set elements are being re-ordered are
  //  within the group list by checking the droppable list Ids to contain "group".
  else if (
    isDroppableListGroup(source.droppableId) &&
    isDroppableListGroup(destination.droppableId)
  ) {
    list = reorderBenefitSetsInGroup(list, dropResult);
  }
  //  Check if benefit set elements are being moved from group to step or vice-versa
  else if (
    isSourceAndDestinationDroppableDifferent(
      source.droppableId,
      destination.droppableId,
    )
  ) {
    list = reorderBenefitSetsBetweenGroupAndStep(list, dropResult);
  }
  return { list, refreshList };
};

/**
 * Function to move Benefit sets between Available Benefit Sets List and Steps list.
 * @param  {BenefitSet[]} availableBenefitSetsList - List of available benefit sets
 * @param  {BenefitStep[]} stepsList - List of steps
 * @param  {DropResult} {
 * source - Source droppable
 * destination - Destination droppable
 * draggableId - Draggable Id
 * }
 */
export const moveBenefitSetsBetweenLists = (
  availableBenefitSetsList: BenefitSet[],
  stepsList: BenefitStep[],
  { source, destination, draggableId }: DropResult,
) => {
  const availableBenefitSetsListClone = cloneDeep(availableBenefitSetsList);
  const stepsListClone = cloneDeep(stepsList);
  const normalizedSet = getNormalizedSetList(stepsListClone);
  const {
    entities: { sets = {}, steps },
    result,
  } = normalizedSet;

  if (source.droppableId === AVAILABLE_BENEFIT_SETS_LIST) {
    const destinationId = getIdFromSlug(destination.droppableId);

    const activeSourceSet = availableBenefitSetsListClone[source.index];

    // Adding benefit set to the list so as to denomalize the respective set
    sets[activeSourceSet.id] = activeSourceSet;
    if (isDroppableListGroup(destination.droppableId)) {
      sets[destinationId].sets = sets[destinationId].sets ?? [];
      sets[destinationId].sets.splice(destination.index, 0, activeSourceSet.id);
    } else {
      steps[destinationId].sets = steps[destinationId].sets ?? [];
      steps[destinationId].sets.splice(
        destination.index,
        0,
        activeSourceSet.id,
      );
    }
    availableBenefitSetsListClone.splice(source.index, 1);
  } else {
    const sourceId = getIdFromSlug(source.droppableId);

    let activeDestinationSet;

    if (isDroppableListGroup(source.droppableId)) {
      const activeDestinationSetId = sets[sourceId].sets[source.index];
      activeDestinationSet = sets[activeDestinationSetId];
      sets[sourceId].sets.splice(source.index, 1);
    } else if (!isDroppableListGroup(draggableId)) {
      const activeDestinationSetId = steps[sourceId].sets[source.index];
      activeDestinationSet = sets[activeDestinationSetId];
      steps[sourceId].sets.splice(source.index, 1);
    }
    if (activeDestinationSet) {
      availableBenefitSetsListClone.splice(
        destination.index,
        0,
        activeDestinationSet,
      );
    }
  }
  const updatedStepsList = getDenormalizedSetList(result, { sets, steps });
  return {
    updatedAvailableBenefitSetsList: availableBenefitSetsListClone,
    updatedStepsList,
  };
};

export const formatStepListForRequest = (stepsList: BenefitStep[]) => {
  return stepsList.map((benefitStep: BenefitStep) => {
    const stepObj = benefitStep;
    // TODO refactor so deletion of optional property is not necessary
    // @ts-expect-error
    if (stepObj.id.includes('tmp')) delete stepObj.id;
    if (stepObj?.sets) {
      stepObj.sets = stepObj?.sets.map(
        (benefitSet: BenefitSet | BenefitSetGroup) => {
          const tempItem = benefitSet;
          // TODO refactor so deletion of optional property is not necessary
          // @ts-expect-error
          if (benefitSet.id.includes('tmp')) delete tempItem.id;
          return tempItem;
        },
      );
    }
    return stepObj;
  });
};

export const removeMatchedElementFromList = (
  arr: BenefitStep[],
  idToRemove: string | undefined,
) => {
  let removedItem: any;
  arr.forEach((e: any, i) => {
    if (e.id === idToRemove) {
      arr.splice(i, 1);
      removedItem = e;
    }
    if (e.sets) {
      const item = removeMatchedElementFromList(e.sets, idToRemove);
      removedItem = item.removedItem || removedItem;
    }
  });
  return { arr, removedItem };
};

export const updateMatchedElementInList = (
  arr: BenefitStep[],
  itemToUpdate: EnrollmentStepItemType,
) => {
  arr.forEach((e, i) => {
    if (e.id === itemToUpdate.id) {
      // eslint-disable-next-line no-param-reassign
      arr[i] = {
        ...e,
        ...itemToUpdate,
      };
    }
    if (e?.sets) {
      // @ts-ignore
      updateMatchedElementInList(e?.sets, itemToUpdate);
    }
    return e;
  });
  return arr;
};

export const getMatchedElementInList = (
  arr: BenefitStep[],
  idToMatch: string,
) => {
  let matchedItem: any;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME: automatically added for existing issue
  arr.forEach((e: any, i) => {
    if (e.id === idToMatch) {
      matchedItem = e;
    }
    if (e.sets) {
      const item = getMatchedElementInList(e.sets, idToMatch);
      matchedItem = item || matchedItem;
    }
  });
  return matchedItem;
};

export const deleteGroupOrStepFromList = (
  arr: BenefitStep[],
  idToRemove: string | undefined,
) => {
  const { arr: updatedList, removedItem } = removeMatchedElementFromList(
    cloneDeep(arr),
    idToRemove,
  );

  const benefitSets: BenefitSet[] = [];
  removedItem?.sets?.forEach((benefitSet: BenefitSet | BenefitSetGroup) => {
    if (benefitSet.type === BENEFIT_SET_TYPE) {
      benefitSets.push(benefitSet as BenefitSet);
    } else {
      (benefitSet as BenefitSetGroup).sets.forEach(
        (_benefitSet: BenefitSet) => {
          benefitSets.push(_benefitSet);
        },
      );
    }
  });
  return { updatedList, removedBenefitSets: benefitSets };
};
