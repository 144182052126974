export const genderIdentityOptions = [
  {
    label: 'MAN',
    value: 'man',
  },
  {
    label: 'WOMAN',
    value: 'woman',
  },
  {
    label: 'AGENDER',
    value: 'agender',
  },
  {
    label: 'BIGENDER',
    value: 'bigender',
  },
  {
    label: 'GENDERQUEER',
    value: 'genderqueer',
  },
  {
    label: 'GENDERQUEER_NONCOMFORMING',
    value: 'genderqueer-nonconforming',
  },
  {
    label: 'INTERSEX',
    value: 'intersex',
  },
  {
    label: 'NON_BINARY',
    value: 'non-binary',
  },
  {
    label: 'TRANSGENDER_MAN',
    value: 'transgender-man',
  },
  {
    label: 'TRANSGENDER_WOMAN',
    value: 'transgender-woman',
  },
  {
    label: 'TWO_SPIRIT',
    value: 'two-spirit',
  },
];

export const pronounOptions = [
  {
    label: 'HE_HIM',
    value: 'he-him',
  },
  {
    label: 'SHE_HER',
    value: 'she-her',
  },
  {
    label: 'THEY_THEM',
    value: 'they-them',
  },
  {
    label: 'XE_XEM',
    value: 'xe-xem',
  },
  {
    label: 'ZE_ZIM',
    value: 'ze-zim',
  },
];

export const countryOptions = [
  { value: 'CA', label: 'CANADA' },
  { value: 'US', label: 'UNITED_STATES' },
  { value: 'IE', label: 'IRELAND' },
];
