import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import {
  StyledDayPickerInput,
  Modal,
  ModalButtons,
  WhiteSpacePreWrap,
} from 'apps/employer-experience/ui-kit';
import {
  Flex,
  Box,
  HeadingTwo,
  Label,
  LabelText,
  BodyOne,
  ErrorMessage,
  PrimaryButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import TimePicker from 'react-time-picker';
import { CLEAR_SELECTION, MAINTAIN_SELECTION } from './constants';
import { DATEPICKER_DATE_FORMAT } from '@leagueplatform/web-common';
import { ENROLLMENT_TYPES } from 'common/constants';

const DayPickerWrapper = styled.div`
  margin-top: 0.5rem;
  max-width: 22rem;

  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }

  .DayPickerInput input {
    padding: 0.875rem;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  .react-time-picker__inputGroup__input {
    line-height: 3rem;
    height: initial;
  }
`;

const enrollmentTypeOptions = [
  {
    label: ENROLLMENT_TYPES.ANNUAL,
    value: ENROLLMENT_TYPES.ANNUAL,
  },
  {
    label: ENROLLMENT_TYPES.NEW_HIRE,
    value: ENROLLMENT_TYPES.NEW_HIRE,
  },
  {
    label: ENROLLMENT_TYPES.QLE,
    value: ENROLLMENT_TYPES.QLE,
  },
  {
    label: ENROLLMENT_TYPES.BCC,
    value: ENROLLMENT_TYPES.BCC,
  },
];

export const ResetUserBenefitsPresenter = ({
  onClose,
  values,
  errors,
  handleSubmit,
  setFieldValue,
  employeeName,
  plans,
}) => {
  const { formatMessage } = useIntl();
  const operationTypeOptions = [
    {
      label: formatMessage({ id: 'RESET_USER_BENEFITS_AND_CLEAR_SELECTION' }),
      value: CLEAR_SELECTION,
    },
    {
      label: formatMessage({
        id: 'RESET_USER_BENEFITS_AND_MAINTAIN_SELECTION',
      }),
      value: MAINTAIN_SELECTION,
    },
  ];
  const planOptions = plans?.map(plan => ({
    label: `${plan.name} (${plan.status})`,
    value: plan.id,
  }));
  return (
    <Modal onClose={onClose}>
      <HeadingTwo>
        {formatMessage(
          { id: 'RESET_BENEFIT_SELECTION_CONFIRM_HEADER' },
          { employeeName },
        )}
      </HeadingTwo>
      <Box marginY="two">
        <WhiteSpacePreWrap>
          <BodyOne>
            {formatMessage({
              id: 'RESET_USER_BENEFIT_SELECTION_SPLAINER_TEXT',
            })}
          </BodyOne>
        </WhiteSpacePreWrap>
        <Box marginY="one">
          <Label htmlFor="select-plan">
            <LabelText>
              {formatMessage({ id: 'BENEFIT_PLAN_OPTIONAL' })}
            </LabelText>
          </Label>
          <Select
            id="select-plan"
            data-testid="select-plan-id"
            value={values.plan_id}
            options={planOptions}
            onChange={({ value }) => setFieldValue('plan_id', value)}
            placeholder={formatMessage({ id: 'SELECT_ONE' })}
          />
        </Box>
        <Box marginY="one">
          <Label htmlFor="select-enrollment-type">
            <LabelText>
              {formatMessage({ id: 'ENROLLMENT_TYPE_OPTIONAL' })}
            </LabelText>
          </Label>
          <Select
            id="select-enrollment-type"
            value={values.enrollment_type}
            options={enrollmentTypeOptions}
            onChange={({ value }) => setFieldValue('enrollment_type', value)}
            placeholder={formatMessage({ id: 'SELECT_ONE' })}
          />
        </Box>
        <Box marginY="one">
          <Label htmlFor="select-reset-type">
            <LabelText>{formatMessage({ id: 'RESET_TYPE' })}</LabelText>
          </Label>
          <Select
            id="select-reset-type"
            data-testid="operation-type-field"
            name="operation_type"
            value={values.operation_type}
            options={operationTypeOptions}
            onChange={({ value }) => setFieldValue('operation_type', value)}
            placeholder={formatMessage({ id: 'SELECT_ONE' })}
          />
          {errors.operation_type && (
            <ErrorMessage>{errors.operation_type}</ErrorMessage>
          )}
        </Box>
        <Box marginY="one">
          <Label htmlFor="enrollment_end_date">
            <LabelText>
              {formatMessage({ id: 'ENROLLMENT_END_DATE' })}
            </LabelText>
            <Flex alignItems="center">
              <Box>
                <DayPickerWrapper>
                  <StyledDayPickerInput
                    dayPickerProps={{
                      enableOutsideDays: false,
                      disabledDays: [
                        {
                          before: moment(new Date()).toDate(),
                        },
                      ],
                    }}
                    placeholder={DATEPICKER_DATE_FORMAT}
                    format={DATEPICKER_DATE_FORMAT}
                    value={
                      values.enrollment_end_date &&
                      moment(values.enrollment_end_date).format(
                        DATEPICKER_DATE_FORMAT,
                      )
                    }
                    name="enrollment_end_date"
                    onDayChange={value => {
                      const oldDate =
                        values.enrollment_end_date || value.toDate(); // type is date
                      const newDate = moment(
                        new Date(
                          value.year(),
                          value.month(),
                          value.date(),
                          oldDate.getHours(),
                          oldDate.getMinutes(),
                          0,
                        ),
                      );
                      if (value.isValid()) {
                        setFieldValue(
                          'enrollment_end_date',
                          newDate.utc().toDate(),
                        );
                      }
                    }}
                  />
                </DayPickerWrapper>
              </Box>
              <Box marginRight="quarter" marginTop="one">
                {formatMessage({ id: 'TIME_SELECTOR_TEXT_1' })}

                <StyledTimePicker
                  clockIcon={null}
                  clearIcon={null}
                  disableClock
                  value={
                    values.enrollment_end_date &&
                    moment(values.enrollment_end_date).format('H:mm:ss')
                  }
                  onChange={value => {
                    const oldDate = values.enrollment_end_date || new Date(); // type is date
                    const newTime = value ? value.split(':') : ['12', '0', '0'];
                    const newDate = moment(
                      new Date(
                        oldDate.getFullYear(),
                        oldDate.getMonth(),
                        oldDate.getDate(),
                        ...newTime,
                      ),
                    );
                    setFieldValue(
                      'enrollment_end_date',
                      newDate.utc().toDate(),
                    );
                  }}
                />
                {formatMessage({ id: 'TIME_SELECTOR_TEXT_2' })}
              </Box>
            </Flex>
          </Label>
          {errors.enrollment_end_date && (
            <ErrorMessage>{errors.enrollment_end_date}</ErrorMessage>
          )}
        </Box>
      </Box>
      <ModalButtons>
        <Flex h="100%" align="center" justify="flex-end">
          <SecondaryButton onClick={onClose} marginRight="one">
            {formatMessage({ id: 'CANCEL' })}
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSubmit}
            type="submit"
            data-testid="submit-button"
          >
            {formatMessage({ id: 'RESET_USER_BENEFITS' })}
          </PrimaryButton>
        </Flex>
      </ModalButtons>
    </Modal>
  );
};

ResetUserBenefitsPresenter.propTypes = {
  employeeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    enrollment_end_date: PropTypes.string,
    operation_type: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    enrollment_end_date: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    operation_type: PropTypes.string,
    plan_id: PropTypes.string,
    enrollment_type: PropTypes.string,
  }).isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      plan_id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

ResetUserBenefitsPresenter.defaultProps = {
  plans: [],
};

ResetUserBenefitsPresenter.displayName = 'ResetUserBenefitSelectionPresenter';
