import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, TextField, Image } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import editIcon from 'apps/public/assets/edit-icon.svg';
import checkmark from 'common/ui-kit/assets/checked.png';
import close from 'apps/public/assets/close.svg';

export const EditableAmount = ({ value, onStartEditing, onSave, onCancel }) => {
  const { formatMessage } = useIntl();
  const [newValue, setNewValue] = useState(value);
  const [editable, setEditable] = useState(false);

  return editable ? (
    <Flex alignItems="center">
      <TextField
        id="PAYROLL_DEDUCATIONS_EDITABLE_AMOUNT"
        value={newValue}
        display="inline-block"
        onChange={evt => {
          setNewValue(evt.currentTarget.value);
        }}
      />
      <Image
        src={checkmark}
        size="one"
        marginLeft="one"
        aria-label={formatMessage({ id: 'SAVE' })}
        onClick={() => {
          setEditable(false);
          const val = parseFloat(newValue) || 0.0;
          onSave(val);
        }}
      />
      <Image
        src={close}
        size="one"
        marginLeft="one"
        aria-label={formatMessage({ id: 'CANCEL' })}
        onClick={() => {
          setEditable(false);
          onCancel();
        }}
      />
    </Flex>
  ) : (
    <Flex alignItems="center">
      ${value}
      <Image
        src={editIcon}
        size="one"
        marginLeft="one"
        aria-label={formatMessage({ id: 'EDIT' })}
        onClick={() => {
          setEditable(true);
          setNewValue(value);
          onStartEditing();
        }}
      />
    </Flex>
  );
};

EditableAmount.propTypes = {
  value: PropTypes.number.isRequired,
  onStartEditing: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
