import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  BodyOne,
  SubtitleOne,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import { useIntl } from '@leagueplatform/locales';
import { usePayrollDeductionsRow } from './use-payroll-deductions.hook';
import { EditableAmount } from './payroll-deductions-editable-amount.view';

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const Row = props => <Box as="tr" borderBottomWidth="thin" {...props} />;

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const HeaderCell = props => <SubtitleOne as="th" {...props} />;

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const RowCell = props => <BodyOne as="td" paddingY="half" {...props} />;

const PayrollDeductionsRow = props => {
  const {
    selectOptions,
    value,
    options,
    payPeriod,
    updateUserDeduction,
    setValue,
    costType,
    benefitType,
  } = usePayrollDeductionsRow(props);
  const { formatMessage } = useIntl();
  const [disabled, setDisabled] = useState(false);
  const StyledRowCell = genesisStyled(RowCell)(
    css({
      wordBreak: 'break-all',
      paddingRight: 'three',
    }),
  );

  return (
    <Row>
      <RowCell>{payPeriod}</RowCell>
      <StyledRowCell>{benefitType}</StyledRowCell>
      <RowCell>
        <Select
          aria-label={formatMessage({ id: 'COST_TYPE' })}
          minWidth="30%"
          defaultValue={options[0].value}
          options={options}
          onChange={e => selectOptions(e)}
          disabled={disabled}
        />
      </RowCell>
      <RowCell paddingLeft="four">
        <EditableAmount
          value={value}
          onStartEditing={() => {
            setDisabled(true);
          }}
          onSave={val => {
            updateUserDeduction(costType, val);
            setValue(val);
            setDisabled(false);
          }}
          onCancel={() => {
            setDisabled(false);
          }}
        />
      </RowCell>
    </Row>
  );
};

PayrollDeductionsRow.propTypes = {
  payrollDeduction: PropTypes.shape({
    pay_period: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    }),
    employee_cost: PropTypes.shape({
      total: PropTypes.number,
    }),
  }).isRequired,
};

export const PayrollDeductionsTable = ({ payrollDeductions }) => {
  const { formatMessage } = useIntl();
  return (
    <Box as="table" width={1} style={{ tableLayout: 'fixed' }}>
      <thead>
        <Row>
          <HeaderCell minWidth="35%">
            {formatMessage({ id: 'PAY_PERIOD' })}
          </HeaderCell>
          <HeaderCell>{formatMessage({ id: 'BENEFIT_TYPE' })}</HeaderCell>
          <HeaderCell>{formatMessage({ id: 'COST_TYPE' })}</HeaderCell>
          <HeaderCell paddingLeft="four" paddingY="half">
            {formatMessage({ id: 'TOTAL_AMOUNT' })}
          </HeaderCell>
        </Row>
      </thead>
      <tbody>
        {payrollDeductions?.length ? (
          payrollDeductions.map(payrollDeduction => (
            <PayrollDeductionsRow
              key={`${payrollDeduction.benefit_type}-${payrollDeduction.pay_period.start_date}-${payrollDeduction.pay_period.end_date}`}
              payrollDeduction={payrollDeduction}
            />
          ))
        ) : (
          <Row>
            <BodyOne as="td" paddingY="two" colSpan="4" textAlign="center">
              {formatMessage({ id: 'NO_PAYROLL_DEDUCTIONS' })}
            </BodyOne>
          </Row>
        )}
      </tbody>
    </Box>
  );
};

PayrollDeductionsTable.propTypes = {
  payrollDeductions: PropTypes.arrayOf(
    PropTypes.shape({
      user_benefit_id: PropTypes.string,
    }),
  ),
};

PayrollDeductionsTable.defaultProps = {
  payrollDeductions: [],
};
