import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function createSalesforceOfflineCase - creates an offline case for a user
 * when they need to get in touch with an agent on the chat page (only works
 * for customer care currently)
 *
 * @arg {string} userId - The userId of the current user
 * @arg {string} subject - Information provided by the current user
 * @arg {string} agentType - Agent type that the case should be created for
 *
 * @return {Object} - A redux-saga call effect
 */
export const createSalesforceOfflineCase = ({ userId, subject, agentType }) =>
  call(websocketFetch, 'create_salesforce_offline_case', {
    user_id: userId,
    agent_type: agentType,
    subject,
  });
