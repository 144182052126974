import { MESSAGE_SENT } from './types';

export const send = (messageType, info, messageId, settings = {}) => ({
  type: MESSAGE_SENT,
  payload: {
    ...settings,
    message_type: messageType,
    info,
    message_id: messageId,
  },
});
