import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { set } from 'lodash/fp';
import { useConfirm } from '@leagueplatform/web-common';
import { EnrollmentConfigPresenter } from './enrollment-config.view';
import {
  visitEnrollmentConfig as visitEnrollmentConfigAction,
  fetchBenefitPlanOptions as fetchBenefitPlanOptionsAction,
  setBenefitPlanOptions as setBenefitPlanOptionsAction,
  setCurrentPlanId as setCurrentPlanIdAction,
  setCurrentBundlePath as setCurrentBundlePathAction,
  formValuesReset as formValuesResetAction,
  createBenefitPlanOptions as createBenefitPlanOptionsAction,
  getEmployerBenefitPlans as getEmployerBenefitPlansAction,
} from './enrollment-config.actions';
import {
  selectDenormalizedPlanOptions,
  selectBenefitPlanOptionsSchema,
  selectMappedBenefitClassesTreeData,
  selectSubmissionError,
  selectCurrentPlanId,
  selectCurrentBundlePath,
  selectMissingPlanOptions,
} from './enrollment-config.selectors';
import { selectBenefitPlans } from '../Benefits/BenefitClass/Plans/selectors';
import { BENEFIT_PLAN } from './enrollment-config.constants';
import { schemaMetadata } from './schema-metadata';
import { useActiveNode } from './tree-navigation';
import { JSONSchemaDefinitionPropType } from 'common/json-schema-form/prop-types';
import { getSchemaDefinitionForRef } from 'common/json-schema-form/json-schema-form.container';
import {
  matchPlanIdRegex,
  capturePlanUpdatePath,
  matchBundlePath,
} from './enrollment-config.regexes';
import {
  Flex,
  HeadingThree,
  Link,
  useTheme,
} from '@leagueplatform/genesis-commons';
import { NavLink } from 'react-router-dom';
import { Icon, ICONS } from '@leagueplatform/ui-kit';

const parseFormDataPath = pathString => {
  const path = pathString || '';
  const planId = get(path.match(matchPlanIdRegex), 1);
  const updatePath = get(path.match(capturePlanUpdatePath), 1);
  const bundlePath = get(path.match(matchBundlePath), 0);
  return [planId, updatePath, bundlePath];
};

export const EnrollmentConfigContainer = ({
  benefitPlanOptions,
  benefitPlanOptionsSchema,
  benefitPlans,
  missingPlanOptions,
  data,
  fetchBenefitPlanOptions,
  formValuesReset,
  groupId,
  setBenefitPlanOptions,
  setCurrentPlanId,
  setCurrentBundlePath,
  visitEnrollmentConfig,
  currentPlanId,
  currentBundlePath,
  getEmployerBenefitPlans,
  ...props
}) => {
  const theme = useTheme();
  const [formDataPath, setFormDataPath] = useState('');
  const [formData, setFormData] = useState(data);
  const [$ref, set$Ref] = useState(null);
  const [validationWarnings, setValidationWarnings] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { setIsConfirming: setIsConfirmingDataChangedModal, ...confirmProps } =
    useConfirm();
  const { activeNode, setActiveNode } = useActiveNode({
    setFormData,
    setFormDataPath,
    set$Ref,
    setValidationWarnings,
  });
  useEffect(() => {
    visitEnrollmentConfig();
  }, [visitEnrollmentConfig]);
  useEffect(() => {
    const [planId, , bundlePath] = parseFormDataPath(formDataPath);
    if (planId && planId !== currentPlanId) {
      setCurrentPlanId(planId);
    }
    if (bundlePath && bundlePath !== currentBundlePath) {
      setCurrentBundlePath(bundlePath);
    }
  }, [
    formDataPath,
    setCurrentPlanId,
    setCurrentBundlePath,
    currentPlanId,
    currentBundlePath,
  ]);
  useEffect(() => {
    getEmployerBenefitPlans({
      groupId,
      currentPlanId,
    });
  }, [groupId, currentPlanId, getEmployerBenefitPlans]);

  const onToggleTreeNode = ({ node, handleToggle }) => {
    if (hasUnsavedChanges) {
      // show modal as a warning for the user to save the form
      setIsConfirmingDataChangedModal(true);
    } else {
      handleToggle({ node });
      if (node.entityType === BENEFIT_PLAN && !node.entityData) {
        // eslint-disable-next-line no-param-reassign
        node.loading = true;
        fetchBenefitPlanOptions({
          groupId,
          planId: node.planId,
        });
      }
    }
  };

  const getPlanForPath = dataPath => {
    const [planId, updatePath] = parseFormDataPath(dataPath);
    const plan = benefitPlanOptions[planId];
    if (!plan) {
      throw new Error(
        `No Benefit Plan Options Found For plan_id: ${planId} in ${benefitPlanOptions}`,
      );
    }
    return [plan, updatePath];
  };

  /**
   * @function onSubmit this is passed into the JSONSchemaForm > EntityForm's Formik implementation
   * see https://jaredpalmer.com/formik/docs/api/formik#onsubmit-values-values-formikbag-formikbag-void
   *
   * @param {} nextData forms current values which we pass to setBenefitPlanOptions to save values to the server
   * @param {formikBag} FormikBag see: https://jaredpalmer.com/formik/docs/api/withformik#the-formikbag
   */

  const onSubmit = (nextData, { setSubmitting }) => {
    try {
      const [previousPlanOptions, updatePath] = getPlanForPath(formDataPath);
      const nextPlanOptions = updatePath
        ? set(updatePath, nextData, previousPlanOptions)
        : nextData;

      setBenefitPlanOptions(nextPlanOptions);
      setHasUnsavedChanges(false);
    } catch {
      setSubmitting(false);
    }
  };

  /**
   * @function onReset this is passed into the JSONSchemaForm > EntityForm's Formik implementation
   * see: https://jaredpalmer.com/formik/docs/api/formik#onreset-values-values-formikbag-formikbag-void
   *
   * @param {*} _ forms current values, we don't care about this ATM!
   * @param {formikBag} FormikBag see: https://jaredpalmer.com/formik/docs/api/withformik#the-formikbag
   */

  const onReset = () => {
    formValuesReset(); // this is a redux action to clear any form errors
  };

  const onAddNode = () => {
    const { children, entityDataPath, treePath, schemaRef } = activeNode;
    const schemaDefinition = getSchemaDefinitionForRef(
      benefitPlanOptionsSchema,
      schemaRef,
    );
    const index = get(children, 'length', 0);
    const newNode = {
      treePath: `${treePath}.children.${index}`,
      entityDataPath: `${entityDataPath}.${index}`,
      entityData: [],
      schemaRef: get(schemaDefinition, 'items.$ref'),
      name: `New ${schemaDefinition.title}`,
      fromCollectionView: true,
    };
    setActiveNode(newNode);
  };

  const onRemoveNode = node => {
    const [previousPlanOptions, updatePath] = getPlanForPath(
      node.entityDataPath,
    );
    const splitPath = updatePath.split('.');
    const indexToRemove = Number(splitPath.pop());
    const siblings = get(previousPlanOptions, splitPath);

    const siblingsWithoutItem = siblings.filter(
      (_, idx) => idx !== indexToRemove,
    );

    const nextPlanOptions = set(
      splitPath,
      siblingsWithoutItem,
      previousPlanOptions,
    );

    setBenefitPlanOptions(nextPlanOptions);
  };

  const activePlanId = get(activeNode, 'planId');
  const showMissingPlanOptions = missingPlanOptions.includes(activePlanId);
  const benefitPlan =
    activePlanId && benefitPlans.find(plan => plan.id === activePlanId);

  return (
    <>
      <Flex justifyContent="flex-end" marginBottom="one">
        <Icon
          size={24}
          icon={ICONS.STAR_FILLED}
          colour={theme.colors.warning.icon}
        />
        <Link
          marginLeft="quarter"
          as={NavLink}
          to={`/admin/employers/${groupId}/benefits/new-enrollment-design`}
        >
          <FormattedMessage id="SWITCH_TO_NEW_ENROLLMENT_DESIGN_TOOL" />
        </Link>
      </Flex>

      <HeadingThree as="h2" marginBottom="one">
        <FormattedMessage id="ENROLLMENT_CONFIGURATION" />
      </HeadingThree>

      <EnrollmentConfigPresenter
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...confirmProps}
        formData={formData}
        onRemoveNode={onRemoveNode}
        onSubmit={onSubmit}
        onReset={onReset}
        onToggleTreeNode={onToggleTreeNode}
        schema={benefitPlanOptionsSchema}
        schemaMetadata={schemaMetadata}
        $ref={$ref}
        set$Ref={set$Ref}
        setFormData={setFormData}
        setFormDataPath={setFormDataPath}
        activeNode={activeNode}
        setActiveNode={setActiveNode}
        onAddNode={onAddNode}
        setHasUnsavedChanges={setHasUnsavedChanges}
        showMissingPlanOptions={showMissingPlanOptions}
        benefitPlan={benefitPlan}
        validationWarnings={validationWarnings}
      />
    </>
  );
};

EnrollmentConfigContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  benefitPlanOptions: PropTypes.objectOf(PropTypes.object),
  benefitPlanOptionsSchema: JSONSchemaDefinitionPropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  benefitPlans: PropTypes.arrayOf(PropTypes.any),
  missingPlanOptions: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  data: PropTypes.objectOf(PropTypes.any),
  fetchBenefitPlanOptions: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  setBenefitPlanOptions: PropTypes.func.isRequired,
  formValuesReset: PropTypes.func.isRequired,
  setCurrentPlanId: PropTypes.func.isRequired,
  setCurrentBundlePath: PropTypes.func.isRequired,
  visitEnrollmentConfig: PropTypes.func.isRequired,
  currentPlanId: PropTypes.string,
  currentBundlePath: PropTypes.string,
  getEmployerBenefitPlans: PropTypes.func,
};

EnrollmentConfigContainer.defaultProps = {
  benefitPlanOptions: undefined,
  benefitPlans: [],
  missingPlanOptions: [],
  data: null,
  currentPlanId: undefined,
  currentBundlePath: undefined,
  getEmployerBenefitPlans: () => {},
};

const mapStateToProps = state => ({
  benefitPlanOptions: selectDenormalizedPlanOptions(state),
  benefitPlanOptionsSchema: selectBenefitPlanOptionsSchema(state),
  benefitPlans: selectBenefitPlans(state),
  missingPlanOptions: selectMissingPlanOptions(state),
  treeData: selectMappedBenefitClassesTreeData(state),
  submissionError: selectSubmissionError(state),
  currentPlanId: selectCurrentPlanId(state),
  currentBundlePath: selectCurrentBundlePath(state),
});

const mapDispatchToProps = {
  fetchBenefitPlanOptions: fetchBenefitPlanOptionsAction,
  visitEnrollmentConfig: visitEnrollmentConfigAction,
  setBenefitPlanOptions: setBenefitPlanOptionsAction,
  setCurrentPlanId: setCurrentPlanIdAction,
  setCurrentBundlePath: setCurrentBundlePathAction,
  formValuesReset: formValuesResetAction,
  createBenefitPlanOptions: createBenefitPlanOptionsAction,
  getEmployerBenefitPlans: getEmployerBenefitPlansAction,
};

export const EnrollmentConfig = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnrollmentConfigContainer);
