import { useIntl } from '@leagueplatform/locales';
import {
  LIFE_SLUG,
  ACCIDENT_SLUG,
  DEPENDENT_LIFE_SLUG,
  LIFE_AND_ADD_SLUG,
} from 'common/benefit-sets';
import { PERIOD_TYPE_TRANSLATION_IDS } from 'common/benefits/metadata';

export const useFormattedInsuredCoverageSummaryMessages = ({
  costMessage,
  dependentCoverageId,
  coverageMessage,
  coveragePeriodTypeId,
  selectionMessage,
  approvedCoverageMessage,
  approvedCostMessage,
  showCostsPerPayPeriod,
  periodType,
  setTypeId,
  approved_coverage_amount: approvedCoverageAmount,
  dependent_coverage_amount: dependentCoverageAmount,
  opt_out: optOut,
  product_type: productType,
  requested_coverage_amount: requestedCoverageAmount,
}) => {
  const { formatMessage, formatMoney } = useIntl();

  // Set these variables to be null by default in the beginning
  let formattedCoverage = null; // coverage of the benefit (for volume benefit)
  let formattedCost = null; // cost of the benefit selection
  let formattedSelection = null; // 'Plan Selection' text (for non volume benefit)
  let formattedApprovedCoverage = null; // the approved coverage amount that does not require EOI
  let formattedApprovedCost = null; // the cost of the approved coverage amount
  let formattedDependentCoverages = null; // the cost of the approved coverage amount

  /* COVERAGE */
  // this is the coverage of the benefit, and will be shown only for volume benefit
  // Example: $10,000 coverage

  if (coverageMessage) {
    const [coverageId, { value, amount } = {}] = coverageMessage || [];
    const amountValue = amount ? formatMoney(amount) : null;
    formattedCoverage =
      coverageId && formatMessage(coverageId, { value, amount: amountValue });
  }

  if (
    !optOut &&
    (setTypeId === LIFE_SLUG ||
      productType === ACCIDENT_SLUG ||
      setTypeId === LIFE_AND_ADD_SLUG)
  ) {
    if (approvedCoverageMessage) {
      formattedCoverage = requestedCoverageAmount
        ? formatMoney(requestedCoverageAmount)
        : null;
    } else {
      formattedCoverage = approvedCoverageAmount
        ? formatMoney(approvedCoverageAmount)
        : null;
    }
  }

  if (!optOut && setTypeId === DEPENDENT_LIFE_SLUG) {
    formattedCoverage = null;
    formattedDependentCoverages = dependentCoverageAmount
      ? Object.entries(dependentCoverageAmount).map(([key, value]) => ({
          name: formatMessage({ id: key.toUpperCase() }),
          value: formatMoney(value),
        }))
      : formatMessage({ id: 'PENDING_COVERAGE_APPROVAL' });
  }

  /* COVERAGE PERIOD TYPE */
  // For STD/LTD, the coverage amount is shown with period type
  // Example: $1000 / Week
  const formattedCoveragePeriodType = coveragePeriodTypeId
    ? formatMessage({ id: coveragePeriodTypeId })
    : null;

  /* DEPENDENT COVERAGE */
  // only when the benefit has dependent coverage, can be for volume and non volume benefit
  // Example: Myself and my spouse
  const formattedDependentCoverage = dependentCoverageId
    ? formatMessage({ id: dependentCoverageId })
    : null;

  /* SELECTION */
  // this is the plan selection of the benefit, and will be shown only for non volume benefit
  // Example: Core
  if (selectionMessage) {
    const [selectionId, selectionValues = {}] = selectionMessage || [];
    formattedSelection =
      selectionId && formatMessage(selectionId, selectionValues);
  }

  /* COST */
  // this is the cost of the benefit selected
  // Example: $1.00
  if (costMessage) {
    const [costId, { value: costValue }] = costMessage;
    formattedCost = formatMessage(costId, {
      value: formatMoney(costValue),
    });
  }

  /* APPROVED COVERAGE */
  // this is the coverage amount that is approved for volume/disability benefit
  // when user selected coverage that is > than approved and requires EOI
  // Example: $90,000
  if (approvedCoverageMessage) {
    const [coverageId, { value: approvedAmount } = {}] =
      approvedCoverageMessage || [];
    formattedApprovedCoverage =
      coverageId &&
      formatMessage(coverageId, { value: formatMoney(approvedAmount) });
  }

  /* APPROVED COST */
  // this is the cost of the approved coverage amount
  // Example: $10.00
  if (approvedCostMessage) {
    const [costId, { value: costValue }] = approvedCostMessage;
    formattedApprovedCost = formatMessage(costId, {
      value: formatMoney(costValue),
    });
  }

  /* PERIOD TYPE */
  // this is to show the period type for each benefit cost
  // by default it should show '/Year', but if showCostsPerPayPeriod flag is true,
  // then it should show the pay group as the period type
  // Example: {cost} / Semi-Monthly or {cost} / Year
  const defaultCostPeriodType = formatMessage({ id: 'YEAR' });

  const costPeriodType =
    periodType === PERIOD_TYPE_TRANSLATION_IDS.Monthly
      ? formatMessage({ id: PERIOD_TYPE_TRANSLATION_IDS.month })
      : formatMessage({ id: periodType || PERIOD_TYPE_TRANSLATION_IDS.month });

  const formattedPeriodType = showCostsPerPayPeriod
    ? costPeriodType
    : defaultCostPeriodType;

  return {
    formattedDependentCoverage,
    formattedCoverage,
    formattedCoveragePeriodType,
    formattedSelection,
    formattedCost,
    formattedPeriodType,
    formattedApprovedCoverage,
    formattedDependentCoverages,
    formattedApprovedCost,
  };
};

export const useFormattedContributionSummaryMessages = ({
  employeeAllocation,
  totalAllocation,
  employerAllocation,
}) => {
  const { formatMessage, formatMoney } = useIntl();

  let formattedEmployeeAllocation = null;
  let formattedEmployerAllocation = null;
  let formattedTotalAllocation = null;

  /* EMPLOYEE/PERSONAL ALLOCATION */
  if (employeeAllocation) {
    const [costId, { value: costValue }] = employeeAllocation;
    formattedEmployeeAllocation = formatMessage(costId, {
      value: formatMoney(costValue),
    });
  }

  /* EMPLOYER ALLOCATION */
  if (employerAllocation) {
    const [costId, { value: costValue }] = employerAllocation;
    formattedEmployerAllocation = formatMessage(costId, {
      value: formatMoney(costValue),
    });
  }

  /* TOTAL ALLOCATION */
  if (totalAllocation) {
    const [costId, { value: costValue }] = totalAllocation;
    formattedTotalAllocation = formatMessage(costId, {
      value: formatMoney(costValue),
    });
  }

  return {
    formattedEmployeeAllocation,
    formattedEmployerAllocation,
    formattedTotalAllocation,
  };
};
