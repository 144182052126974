/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, ClickEater } from '@leagueplatform/ui-kit';

const DropdownContainer = styled(Box)`
  position: relative;
`;

class Dropdown extends Component {
  constructor() {
    super();
    this.state = { isOpen: false };
    this.onToggle = this.onToggle.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidMount() {
    if (this.handleDocumentClick)
      window.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    if (this.handleDocumentClick)
      window.removeEventListener('click', this.handleDocumentClick);
  }

  onToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleDocumentClick() {
    if (this.state.isOpen) {
      this.onToggle();
    }
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        isOpen: this.state.isOpen,
        onToggle: this.onToggle,
      });
    });
    return (
      <ClickEater>
        <DropdownContainer>{children}</DropdownContainer>
      </ClickEater>
    );
  }
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Dropdown;
