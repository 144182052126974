import { get } from 'lodash/fp';
import { selectApp } from 'apps/member-experience/state/selectors';
import { createSelector } from 'reselect';

export const selectRoot = createSelector(selectApp, get('pages.claimDetails'));

export const selectReady = createSelector(selectRoot, get('ready'));

export const selectClaim = createSelector(selectRoot, get('claim'));

export const selectIsModalOpen = createSelector(selectRoot, get('isModalOpen'));

export const selectSelectedImageUrl = createSelector(
  selectRoot,
  get('selectedImageUrl'),
);

export const selectClaimHistory = createSelector(
  selectRoot,
  get('claimHistory'),
);

export const selectClaimDocuments = createSelector(selectClaim, claim =>
  claim && Array.isArray(claim.claim_documents) ? claim.claim_documents : [],
);
