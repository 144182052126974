import { createRequestTypes } from 'common/websocket-redux';

export const GET_QLE_SCHEMA = 'qle-screener/GET_QLE_SCHEMA';

export const REQUEST_QLE_SCHEMA = createRequestTypes(
  'qle-screener/REQUEST_QLE_SCHEMA',
);

export const SUBMIT_QLE = 'qle-screener/SUBMIT_QLE';

export const REQUEST_SET_QLE = createRequestTypes(
  'qle-screener/REQUEST_SET_QLE',
);

export const SEND_QLE_SCREENER_ANALYTICS =
  'qle-screener/SEND_QLE_SCREENER_ANALYTICS';
