import { isProduction } from '@leagueplatform/core';

export const buildTypeformUrl = surveyString => {
  return `https://leagueca.typeform.com/to/${surveyString}`;
};

export const NPS_SURVEY_TYPES = {
  DEFAULT: 'default',
  SAME_MEDICAL_PLAN: 'same-medical-plan',
  CHANGED_MEDICAL_PLAN: 'changed-medical-plan',
};

// Testing survey URLs
export const TEST_NPS_SURVEY_URL = buildTypeformUrl('gOlJH9');
export const TEST_NPS_SAME_PLAN_SURVEY_URL = buildTypeformUrl('BZQuzEIY');
export const TEST_NPS_NEW_PLAN_SURVEY_URL = buildTypeformUrl('xmf6w48u');

export const TEST_RISK_PERSONA_NPS_URL = buildTypeformUrl('uHYOJKWd');
export const TEST_RISK_PERSONA_SAME_PLAN_NPS_URL = buildTypeformUrl('GfbZyYt6');
export const TEST_RISK_PERSONA_NEW_PLAN_NPS_URL = buildTypeformUrl('fqTz0rvR');

// Production survey URLs
// New League Users OR Existing with 1 medical plan option
export const ENGLISH_NPS_DEFAULT_SURVEY_URL = buildTypeformUrl('rn6axO');
// Existing League Users - Choosing Same Plan
export const ENGLISH_NPS_SAME_PLAN_SURVEY_URL = buildTypeformUrl('eyQlI4Cs');
// Existing League Users - Choosing New Plan
export const ENGLISH_NPS_NEW_PLAN_SURVEY_URL = buildTypeformUrl('BztGzCF7');

/** Employers with risk persona enabled */
// New League Users OR Existing with 1 medical plan option
export const ENGLISH_RISK_PERSONA_NPS_DEFAULT_SURVEY_URL =
  buildTypeformUrl('rgHkY8Gk');
// Existing League Users - Choosing Same Plan
export const ENGLISH_RISK_PERSONA_NPS_SAME_PLAN_SURVEY_URL =
  buildTypeformUrl('yWb4lXgF');
// Existing League Users - Choosing New Plan
export const ENGLISH_RISK_PERSONA_NPS_NEW_PLAN_SURVEY_URL =
  buildTypeformUrl('qiOBm8mt');

// TODO - Add French NPS survey URL mappings once SAME_MEDICAL_PLAN and CHANGED_MEDICAL_PLAN URLs are available.
export const FRENCH_NPS_SURVEY_URL = buildTypeformUrl('jFIW0u');

const STAGING_TEST_NPS_SURVEY_URL = {
  [NPS_SURVEY_TYPES.DEFAULT]: TEST_NPS_SURVEY_URL,
  [NPS_SURVEY_TYPES.SAME_MEDICAL_PLAN]: TEST_NPS_SAME_PLAN_SURVEY_URL,
  [NPS_SURVEY_TYPES.CHANGED_MEDICAL_PLAN]: TEST_NPS_NEW_PLAN_SURVEY_URL,
};

const STAGING_RISK_PERSONA_NPS_SURVEY_URL = {
  [NPS_SURVEY_TYPES.DEFAULT]: TEST_RISK_PERSONA_NPS_URL,
  [NPS_SURVEY_TYPES.SAME_MEDICAL_PLAN]: TEST_RISK_PERSONA_SAME_PLAN_NPS_URL,
  [NPS_SURVEY_TYPES.CHANGED_MEDICAL_PLAN]: TEST_RISK_PERSONA_NEW_PLAN_NPS_URL,
};

const PROD_ENGLISH_NPS_SURVEY_URLS = {
  [NPS_SURVEY_TYPES.DEFAULT]: ENGLISH_NPS_DEFAULT_SURVEY_URL,
  [NPS_SURVEY_TYPES.SAME_MEDICAL_PLAN]: ENGLISH_NPS_SAME_PLAN_SURVEY_URL,
  [NPS_SURVEY_TYPES.CHANGED_MEDICAL_PLAN]: ENGLISH_NPS_NEW_PLAN_SURVEY_URL,
};

const PROD_RISK_PERSONA_ENGLISH_NPS_SURVEY_URLS = {
  [NPS_SURVEY_TYPES.DEFAULT]: ENGLISH_RISK_PERSONA_NPS_DEFAULT_SURVEY_URL,
  [NPS_SURVEY_TYPES.SAME_MEDICAL_PLAN]:
    ENGLISH_RISK_PERSONA_NPS_SAME_PLAN_SURVEY_URL,
  [NPS_SURVEY_TYPES.CHANGED_MEDICAL_PLAN]:
    ENGLISH_RISK_PERSONA_NPS_NEW_PLAN_SURVEY_URL,
};

export const getNPSSurveyURL = ({
  isFrench,
  userId,
  groupId,
  employerName,
  enrollmentType,
  surveyType,
  isEmployerWithRiskPersona,
}) => {
  let NPS_SURVEY_URL = isEmployerWithRiskPersona
    ? STAGING_RISK_PERSONA_NPS_SURVEY_URL[surveyType]
    : STAGING_TEST_NPS_SURVEY_URL[surveyType];

  if (isProduction()) {
    if (isFrench) {
      NPS_SURVEY_URL = FRENCH_NPS_SURVEY_URL;
    } else if (isEmployerWithRiskPersona) {
      NPS_SURVEY_URL = PROD_RISK_PERSONA_ENGLISH_NPS_SURVEY_URLS[surveyType];
    } else {
      NPS_SURVEY_URL = PROD_ENGLISH_NPS_SURVEY_URLS[surveyType];
    }
  }

  return `${NPS_SURVEY_URL}?user_id=${userId}&group_id=${groupId}&company_name=${employerName}&enroll_type=${enrollmentType}`;
};
