export default {
  gwlParamedical: {
    name: 'gwlParamedical',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'benefit_year',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },

          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'max_amount',
            span: 6,
          },

          {
            name: 'max_applied_to',
            span: 6,
          },

          {
            name: 'per_visit_max',
            span: 6,
          },

          {
            name: 'per_visit_max_reasonable_and_customary',
            span: 6,
          },

          {
            name: 'reimbursement_percent',
            span: 6,
          },

          {
            name: 'prescription_required',
            span: 6,
          },

          {
            name: 'acupuncturist_included',
            span: 6,
          },

          {
            name: 'audiologist_included',
            span: 6,
          },

          {
            name: 'athletic_therapist_or_physiotherapist_included',
            span: 6,
          },

          {
            name: 'chiropractor_included',
            span: 6,
          },

          {
            name: 'dietician_included',
            span: 6,
          },

          {
            name: 'massage_therapist_included',
            span: 6,
          },

          {
            name: 'naturopath_included',
            span: 6,
          },

          {
            name: 'occupational_therapist_included',
            span: 6,
          },

          {
            name: 'osteopath_included',
            span: 6,
          },

          {
            name: 'podiatrist_included',
            span: 6,
          },

          {
            name: 'psychologist_or_social_worker_included',
            span: 6,
          },

          {
            name: 'speech_therapist_included',
            span: 6,
          },
        ],
      },
    ],
  },
};
