import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  BodyOne,
  Box,
  Flex,
  HeadingFour,
  Label,
  Link,
  Image,
  SecondaryButton,
  BodyTwoSecondary,
  Overline,
} from '@leagueplatform/genesis-commons';
import LinkIcon from 'apps/public/assets/external-link-icon.svg';
import PlusIcon from 'apps/public/assets/plus-icon.svg';
import InfoIcon from 'apps/public/assets/circle-info-icon.svg';
import ErrorIcon from 'apps/public/assets/error-circle.svg';
import WarningIcon from 'apps/public/assets/warning-icon-orange.svg';
import {
  EnrollmentBannerType,
  EnrollmentBannerTypes,
  EnrollmentTemplateStatus,
  EnrollmentTemplateStatuses,
} from './contentful-integration.types';
import { TooltipHeader } from '@leagueplatform/ui-kit';

export const TemplateContainer = ({ ...props }) => (
  <Box
    borderTopStyle="solid"
    borderTopColor="onSurface.border.subdued"
    borderTopWidth="thin"
    padding="oneAndHalf"
    position="relative"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

interface TemplateHeaderProps {
  title: string;
  tooltip: string;
  value: string;
  isChecked: boolean;
  onChange: () => void;
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  disabled?: boolean;
}

export const TemplateHeader = ({
  title,
  tooltip,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME: automatically added for existing issue
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME: automatically added for existing issue
  isChecked,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME: automatically added for existing issue
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME: automatically added for existing issue
  disabled,
}: TemplateHeaderProps) => {
  return (
    <Label marginBottom="oneAndHalf">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <HeadingFour>{title}</HeadingFour>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME: automatically added for existing issue */}
          <TemplateTooltip>{tooltip}</TemplateTooltip>
        </Flex>
      </Flex>
    </Label>
  );
};

interface TemplateStatusProps {
  status: EnrollmentTemplateStatus;
}

export const TemplateStatus = ({ status }: TemplateStatusProps) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      marginBottom="quarter"
    >
      <BodyOne>{formatMessage({ id: 'ENROLLMENT_TEMPLATE_STATUS' })}</BodyOne>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME: automatically added for existing issue */}
      {status && <TemplateStatusPill status={status} />}
    </Flex>
  );
};

interface ContentfulTemplateLinkProps {
  template: any;
}

export const ContentfulTemplateLink = ({
  template,
}: ContentfulTemplateLinkProps) => {
  const { formatMessage } = useIntl();

  return (
    <Link
      marginTop="half"
      display="inline-flex"
      target="_blank"
      aria-labelledby={`template-link-${template?.id}`}
      href={template?.url}
    >
      <span id={`template-link-${template?.id}`}>
        {formatMessage({ id: 'VIEW_IN_CONTENTFUL' })}
      </span>
      <Image marginLeft="half" src={LinkIcon} alt="External link" height={20} />
    </Link>
  );
};

interface CreateNewTemplateButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const CreateNewTemplateButton = ({
  onClick,
  children,
}: CreateNewTemplateButtonProps) => {
  return (
    <SecondaryButton marginBottom="half" marginRight="half" onClick={onClick}>
      <Flex alignItems="center">
        <Image marginRight="half" src={PlusIcon} alt="Plus link" height={20} />
        <span>{children}</span>
      </Flex>
    </SecondaryButton>
  );
};

interface BannerProps {
  type: EnrollmentBannerType;
  children: React.ReactNode;
}

export const Banner = ({ type, children, ...props }: BannerProps) => {
  const getImageSrc = () => {
    switch (type) {
      case EnrollmentBannerTypes.ERROR:
        return ErrorIcon;
      case EnrollmentBannerTypes.WARNING:
        return WarningIcon;
      default:
        return InfoIcon;
    }
  };

  const getBackground = () => {
    switch (type) {
      case EnrollmentBannerTypes.ERROR:
        return 'critical.background.subdued';
      case EnrollmentBannerTypes.WARNING:
        return 'warning.background.subdued';
      default:
        return 'highlight.background.subdued';
    }
  };

  const getBorder = () => {
    switch (type) {
      case EnrollmentBannerTypes.ERROR:
        return 'critical.border.default';
      case EnrollmentBannerTypes.WARNING:
        return 'warning.border.default';
      default:
        return 'highlight.border.default';
    }
  };

  return (
    <Flex
      padding="threeQuarters"
      alignItems="center"
      backgroundColor={getBackground()}
      borderStyle="solid"
      borderWidth="thin"
      borderColor={getBorder()}
      borderRadius="small"
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <Image
        src={getImageSrc()}
        size={26}
        marginRight="one"
        role="presentation"
      />
      {children}
    </Flex>
  );
};

interface TemplateTooltipProps {
  children: React.ReactNode;
}

export const TemplateTooltip = ({ children }: TemplateTooltipProps) => (
  <TooltipHeader
    tooltipLink={
      <BodyTwoSecondary
        marginLeft="quarter"
        borderRadius="circle"
        borderStyle="solid"
        borderWidth="thin"
        borderColor="onSurface.border.default"
        size={18}
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        ?
      </BodyTwoSecondary>
    }
    tooltipText={
      <Box fontSize="body2" color="onSurface.text.primary" minWidth={200}>
        {children}
      </Box>
    }
    inline
    right={false}
    center
    verticalPosition="bottom: 30px"
  />
);

interface TemplateStatusPillProps {
  status: EnrollmentTemplateStatus;
}

export const TemplateStatusPill = ({ status }: TemplateStatusPillProps) => {
  const { formatMessage } = useIntl();

  const getBackground = () => {
    switch (status) {
      case EnrollmentTemplateStatuses.NO_TEMPLATE:
        return 'critical.background.subdued';
      case EnrollmentTemplateStatuses.INACTIVE:
        return 'interactive.background.disabled';
      default:
        return 'success.background.subdued';
    }
  };

  const getCircleBackground = () => {
    switch (status) {
      case EnrollmentTemplateStatuses.NO_TEMPLATE:
        return 'critical.icon';
      case EnrollmentTemplateStatuses.INACTIVE:
        return 'onSurface.text.subdued';
      default:
        return 'success.icon';
    }
  };

  return (
    <Flex
      alignItems="center"
      borderRadius="pill"
      backgroundColor={getBackground()}
      paddingX="half"
      paddingY="quarter"
    >
      <Box
        marginRight="quarter"
        borderRadius="circle"
        size={12}
        // TODO: investigate why `onSurface.text.subdued` is being used here if it's not supported
        // @ts-expect-error
        backgroundColor={getCircleBackground()}
      />
      <Overline as="span" color="onSurface.text.subdued">
        {formatMessage({ id: status })}
      </Overline>
    </Flex>
  );
};
