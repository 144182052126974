/* eslint react/prop-types: 0 */
import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
// @ts-ignore
import css from '@styled-system/css';
import * as components from './SelectComponents';
import { SelectProps } from './SelectTypes';
import { optionHasValue, flattenOptions } from './utilities';

export const Combobox = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      disabled,
      canCreate,
      canCreatePrompt,
      id,
      isClearable,
      placeholder,
      options,
      value,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const defaultCanCreatePrompt = 'Type to create a new option!';
    const defaultPlaceholder = 'Select...';

    const promptToCreate = {
      label: canCreatePrompt || defaultCanCreatePrompt,
      isDisabled: true,
    };

    const augmentedOptions = canCreate ? [...options, promptToCreate] : options;

    const flattenedOptions = flattenOptions(augmentedOptions);

    const defaultOption = flattenedOptions.find(optionHasValue(defaultValue));

    const selectedOption = flattenedOptions.find(optionHasValue(value));

    const SelectComponent = canCreate ? ReactSelectCreatable : ReactSelect;

    const styles = {
      input: (provided: Record<string, unknown>) =>
        css({
          ...provided,
          marginX: 'quarter',
          input: {
            fontFamily: 'inherit',
          },
        }),
    };

    return (
      // @ts-ignore
      <SelectComponent
        className="genesis Select"
        value={selectedOption}
        defaultValue={defaultOption}
        components={components}
        isDisabled={disabled}
        isClearable={isClearable}
        options={augmentedOptions}
        placeholder={placeholder || defaultPlaceholder}
        formatCreateLabel={(userInput: string) => `Add "${userInput}"`}
        styles={styles}
        inputId={id}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
) as React.FunctionComponent<SelectProps>;

Combobox.displayName = 'Select';
