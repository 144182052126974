import { omit } from 'lodash';
import { get } from 'lodash/fp';
import { compose, defaultProps, setDisplayName, mapProps } from 'recompose';
import Default from './employee-benefit-default.view';
import Documents from './documents.view';
import EmployeeDayPicker from './employee-day-picker.view';
import DependentClassAmounts from '../benefits/dependent-class-amounts/dependent-class-amounts.container';
import Submit from './submit.view';

const components = {
  'parent.benefit.dependent_class_amounts': DependentClassAmounts,
  'parent.documents': Documents,
  benefit_start_date: EmployeeDayPicker,
  benefit_end_date: EmployeeDayPicker,
  submit: Submit,
};

const layouts = {
  aetna_insurance_dental: 'employeeUSDental',
  aetna_insurance_medical: 'employeeUSMedical',
  alegeus_spending_dca: 'employeeSpendingAccountBenefit',
  alegeus_savings_hsa: 'employeeHealthSavings',
  alegeus_spending_fsa: 'employeeSpendingAccountBenefit',
  ameriflex_spending_dca: 'employeeSpendingAccountBenefit',
  ameriflex_spending_fsa: 'employeeSpendingAccountBenefit',
  'deltadental-il_insurance_dental': 'employeeUSDental',
  'deltadental-wa_insurance_dental': 'employeeUSDental',
  'gwl_insurance_best-doctors': 'employeeBestDoctors',
  guardian_insurance_dental: 'employeeUSDental',
  guardian_insurance_vision: 'employeeUSVision',
  'guardian_insurance_life-and-add': 'employeeUSLifeAdd',
  guardian_insurance_ltd: 'employeeUSLTD',
  guardian_insurance_std: 'employeeUSSTD',
  gwl_insurance_dental: 'employeeGwlDental',
  gwl_insurance_drug: 'employeeGwlDrugs',
  gwl_insurance_eap: 'employeeGwlRBCAssistanceProgram',
  gwl_insurance_healthcare: 'employeeGWLMedical',
  'gwl_insurance_life-and-add': 'employeeGwlRBCLifeADD',
  gwl_insurance_ltd: 'employeeGwlLTD',
  gwl_insurance_std: 'employeeGwlSTD',
  gwl_insurance_paramedical: 'employeeGwlRBCParamedical',
  gwl_insurance_travel: 'employeeGwlTravel',
  healthequity_savings_hsa: 'employeeHealthSavings',
  kaiserpermanente_insurance_dental: 'employeeUSDental',
  kaiserpermanente_insurance_medical: 'employeeUSMedical',
  'lifemap_insurance_life-and-add': 'employeeUSLifeAdd',
  lifeworks_insurance_eap: 'employeeLifeworksEAP',
  lincolnfinancial_insurance_dental: 'employeeUSDental',
  lincolnfinancial_insurance_ltd: 'employeeUSLTD',
  lincolnfinancial_insurance_std: 'employeeUSSTD',
  'lincolnfinancial_insurance_life-and-add': 'employeeUSLifeAdd',
  league_spending_charity: 'employeeSpendingAccountBenefit',
  league_spending_hsa: 'employeeSpendingAccountBenefit',
  league_spending_lsa: 'employeeSpendingAccountBenefit',
  league_spending_sa: 'employeeSpendingAccountBenefit',
  league_spending_fsa: 'employeeSpendingAccountBenefit',
  league_spending_ldsa: 'employeeSpendingAccountBenefit',
  premerabluecross_insurance_medical: 'employeeUSMedical',
  manulife_insurance_drug: 'employeeGwlDrugs',
  manulife_insurance_ltd: 'employeeGwlLTD',
  manulife_insurance_travel: 'employeeGWLTravel',
  metlife_insurance_ltd: 'employeeUSLTD',
  'metlife_insurance_life-and-add': 'employeeUSLifeAdd',
  optum_savings_hsa: 'employeeHealthSavings',
  nationwide_insurance_pet: 'employeenwPetLSA',
  rbc_insurance_ltd: 'employeeRbcInsurance',
  rbc_insurance_dental: 'employeeRBCDental',
  rbc_insurance_drug: 'employeeGwlDrugs',
  rbc_insurance_paramedical: 'employeeGwlRBCParamedical',
  'rbc_insurance_medical-services-and-supplies': 'employeeRBCMedicalServices',
  rbc_insurance_travel: 'employeeRBCTravel',
  'rbc_insurance_best-doctors': 'employeeBestDoctors',
  'rbc_insurance_life-and-add': 'employeeGwlLifeADD',
  rbc_insurance_eap: 'employeeGwlRBCAssistanceProgram',
  unitedhealthcare_insurance_vision: 'employeeUSVision',
  regenceblueshield_insurance_dental: 'employeeUSDental',
  regenceblueshield_insurance_medical: 'employeeUSMedical',
  regenceblueshield_insurance_vision: 'employeeUSVision',
  lincolnfinancial_insurance_vision: 'employeeUSVision',
  tufts_insurance_medical: 'employeeUSMedical',
  unitedhealthcare_insurance_dental: 'employeeUSDental',
  'unitedhealthcare_insurance_life-and-add': 'employeeUSLifeAdd',
  unitedhealthcare_insurance_medical: 'employeeUSMedical',
  unum_insurance_accident: 'employeeUnumInsuranceAccident',
  'unum_insurance_critical-illness': 'employeeUnumCriticalIllness',
  vsp_insurance_vision: 'employeeUSVision',
};

const benefitToLayout = props => ({
  layoutName: get(props.benefitType, layouts),
  ...props,
  customComponents: props.benefitType.includes('league_spending_')
    ? omit(props.customComponents, 'parent.documents')
    : props.customComponents,
});

export default compose(
  defaultProps({ customComponents: components }),
  setDisplayName('BenefitDefault'),
  mapProps(benefitToLayout),
)(Default);
