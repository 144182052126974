import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
import { injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import { KEY } from '@leagueplatform/web-common';
import {
  getThemeValue,
  colour,
  fontSizeInPx,
  spaceInPx,
  media,
} from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const InputWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  border: 1px solid ${colour('greyLighter')};
  border-radius: ${getThemeValue('radii.2')}px;
  background-color: ${colour('white')};
  max-width: 240px;
  ${media.belowTablet`
    margin-top: ${spaceInPx(1)};
    max-width: none;
  `};
  ${({ hasError, ...rest }) =>
    hasError &&
    `
    border-color:${colour('red')(rest)};
    background-color: ${colour('redLightest')(rest)};
  `};
  > input {
    outline: none;
    text-align: center;
    box-shadow: none;
    background-color: transparent;
    border: 0;
    display: flex;
    padding: ${spaceInPx(1)};
    width: 100%;
    min-height: 23px;
    color: ${colour('navyDark')};
    font-size: ${fontSizeInPx(1)};
    font-weight: 500;
    line-height: 18px;
  }
`;

const InputLabel = styled(Flex).attrs({
  py: 1,
  px: 2,
  align: 'center',
  justify: 'center',
  fontSize: 0,
  bg: 'offWhite',
  color: 'grey',
})`
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
`;

const getLocalizedDecimalSeparator = intl =>
  (1.1).toLocaleString(intl.locale).replace(/1/g, '');

// Removes non-numerics, and characters after the decimal separator
export const sanitizeValue = (value, decimalSeparator, isNegativeAllowed) => {
  if (isNil(value)) return value;
  let sanitizedValue = `${value}`;
  let centsValue;
  let dollarsValue;

  // Check if there is a decimal in the number
  if (sanitizedValue.indexOf(decimalSeparator) !== -1) {
    // if there IS a decimal, separate the string into two parts, the dollars (pre decimal)
    // and the cents (decimal and onward) then strip out non-numerical characters
    centsValue = sanitizedValue
      .substr(sanitizedValue.indexOf(decimalSeparator))
      .replace(/\D/g, '');
    dollarsValue = sanitizedValue
      .substring(0, sanitizedValue.indexOf(decimalSeparator))
      .replace(/\D/g, '');
  } else {
    // strip non-numerical characters
    dollarsValue = sanitizedValue.replace(/\D/g, '');
  }

  sanitizedValue = centsValue ? `${dollarsValue}.${centsValue}` : dollarsValue;
  return sanitizedValue.length
    ? parseFloat(
        isNegativeAllowed && value < 0 ? -sanitizedValue : sanitizedValue,
      )
    : null;
};

/**
 * @function formatCurrency - This function takes in a value, and will format it
 * such that it fits the format of the browser's locale.
 *
 * @param {*} intl intl object to determine the locale. also utilizes the formatNumber
 * function to do the actual formatting.
 * @param {*} currency got the currency from the group and uses it for the symbol
 * @param {*} value The value that will be formatted. can be string or float
 * @param {*} isNegativeAllowed allows negative dollar amounts when true
 *
 * @returns {(string)} the formatted currency string
 */
export const formatCurrency = (intl, currency, value, isNegativeAllowed) => {
  const decimalSeparator = getLocalizedDecimalSeparator(intl);

  // On initial load, value passed in is a valid float so we do not need to sanitize it
  const formattedValue =
    typeof value === 'string'
      ? sanitizeValue(value, decimalSeparator, isNegativeAllowed)
      : value;

  return formattedValue || formattedValue === 0
    ? intl.formatNumber(formattedValue, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency,
        minimumFractionDigits: 2,
      })
    : undefined;
};
class CurrencyInputPresenter extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { intl, currency, value, isNegativeAllowed } = props;
    const isNotFocused = !state.isFocused;
    const isNewValue = value !== state.value;
    const hasAnyDigits = !isNil(state.sanitizedValue);

    if (isNotFocused && isNewValue && hasAnyDigits) {
      return {
        value,
        formattedValue: formatCurrency(
          intl,
          currency,
          value,
          isNegativeAllowed,
        ),
        sanitizedValue: sanitizeValue(
          value,
          getLocalizedDecimalSeparator(intl),
          isNegativeAllowed,
        ),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { ...this.setValue(value), isFocused: false };
  }

  setValue(value) {
    const { intl, currency, isNegativeAllowed } = this.props;
    return {
      value,
      formattedValue: formatCurrency(intl, currency, value, isNegativeAllowed),
      sanitizedValue: sanitizeValue(
        value,
        getLocalizedDecimalSeparator(intl),
        isNegativeAllowed,
      ),
    };
  }

  render() {
    const {
      name,
      id = name,
      intl,
      currency,
      onFormat,
      onChange,
      hasError,
      periodType,
      disabled,
      isNegativeAllowed,
    } = this.props;
    const { formattedValue } = this.state;
    const placeholder = formatCurrency(intl, currency, 0, isNegativeAllowed);
    return (
      <InputWrapper hasError={hasError} hasLabel={!!periodType}>
        <input
          name={name}
          id={id}
          disabled={disabled}
          onChange={e => {
            e.persist();
            const { value, sanitizedValue } = this.setValue(e.target.value);
            this.setState(
              { value, sanitizedValue, formattedValue: value },
              () => {
                onChange(e, this.state);
              },
            );
          }}
          onKeyDown={e => {
            e.persist();
            if (e.key === KEY.ENTER) {
              this.setState(this.setValue(e.target.value), () =>
                onFormat(e, this.state),
              );
            }
          }}
          onBlur={e => {
            e.persist();
            this.setState(
              { ...this.setValue(e.target.value), isFocused: false },
              () => onFormat(e, this.state),
            );
          }}
          onFocus={() => this.setState({ isFocused: true })}
          value={formattedValue}
          placeholder={placeholder}
        />
        {periodType && (
          <InputLabel>{intl.formatMessage({ id: periodType })}</InputLabel>
        )}
      </InputWrapper>
    );
  }
}

export const CurrencyInput = injectIntl(CurrencyInputPresenter);

CurrencyInputPresenter.defaultProps = {
  name: 'currency',
  value: null,
  onFormat: noop,
  onChange: noop,
  hasError: false,
  id: undefined, // this will be set to name if undefined
  periodType: undefined,
  disabled: false,
  isNegativeAllowed: false,
};

CurrencyInputPresenter.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  /** Display value if it persists upon autosave */
  value: PropTypes.number,
  onFormat: PropTypes.func,
  onChange: PropTypes.func,
  intl: intlShape.isRequired,
  currency: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  periodType: PropTypes.string,
  disabled: PropTypes.bool,
  isNegativeAllowed: PropTypes.bool,
};
