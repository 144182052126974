import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export type GetRiskPersonaParams = {
  groupId: string;
  planId: string;
  setId: string;
};

export const getRiskPersona = ({
  groupId,
  planId,
  setId,
}: GetRiskPersonaParams) =>
  call(websocketFetch, 'get_risk_persona', {
    group_id: groupId,
    plan_id: planId,
    set_id: setId,
  });
