import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { MultiSelect } from '@leagueplatform/ui-kit';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  Box,
  BodyOne,
  Label,
  LabelText,
  SecondaryButton,
  PrimaryButton,
  HeadingTwo,
  Image,
  Flex,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';
import arrow from './assets/arrow.-right.svg';
import { cleanJsonForEdit } from '../JsonEditMode/json-edit-mode.container';

export const createBenefitPricePayload = ({
  code,
  selectedBenefits,
  groupId,
}) => {
  const editedJson = JSON.parse(code);
  editedJson.provider_id = groupId;
  editedJson.provider_type = 'employer';
  const payload = selectedBenefits.map(option => ({
    ...editedJson,
    benefit_id: option.value,
  }));
  return {
    provider_id: groupId,
    provider_type: 'employer',
    benefit_prices: payload,
  };
};

export const onSubmit = ({
  submitBenefitPriceConfig,
  initialJson,
  selectedBenefits,
  groupId,
  onClose,
  showError,
}) => {
  try {
    submitBenefitPriceConfig(
      createBenefitPricePayload({
        code: cleanJsonForEdit(initialJson),
        selectedBenefits,
        groupId,
      }),
    );
    onClose();
  } catch (e) {
    const errorObject = {
      info: {
        reason: e.message,
      },
    };
    showError(errorObject);
  }
};

export const CopyPricingConfig = ({
  benefits,
  initialJson,
  groupId,
  onClose,
  submitBenefitPriceConfig,
  showError,
  selectedBenefitLabel,
  benefitYearOptions,
}) => {
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [benefitYear, setBenefitYear] = useState();
  const [benefitType, setBenefitType] = useState();
  const [filteredBenefits, setFilteredBenefits] = useState();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (benefits[benefitYear]) {
      const selectedBenefitType = benefits[benefitYear].find(
        benefit => benefit.value === benefitType,
      );
      setFilteredBenefits(selectedBenefitType?.benefitTypeOptions);
    }
  }, [benefits, benefitType, benefitYear]);

  return (
    <Modal maxHeight={960} overflowY="scroll">
      <HeadingTwo>{formatMessage({ id: 'COPY_PRICING_CONFIG' })}</HeadingTwo>
      <ModalContent>
        <BodyOne>{formatMessage({ id: 'COPY_PRICING_CONFIG_COPY' })}</BodyOne>
        <SubtitleOne>
          {formatMessage({ id: 'COPY_PRICING_CONFIG_NOTE' })}
        </SubtitleOne>
        <Flex
          justifyContent="center"
          alignItems="center"
          marginTop="one"
          flexDirection="column"
        >
          <Box
            borderColor="onSurface.border.default"
            borderRadius="small"
            borderStyle="solid"
            borderWidth="thin"
            padding="one"
          >
            <BodyOne>{selectedBenefitLabel}</BodyOne>
          </Box>
          <Box marginY="one" textAlign="center">
            <BodyOne marginBottom="quarter">
              {formatMessage({ id: 'COPY' })}
            </BodyOne>
            <Image height="oneAndHalf" src={arrow} />
          </Box>
        </Flex>
        <Label marginBottom="one">
          <LabelText>{formatMessage({ id: 'BENEFIT_YEAR' })}</LabelText>
          <Select
            value={benefitYear}
            onChange={e => {
              setBenefitYear(e.value);
            }}
            options={benefitYearOptions}
          />
        </Label>
        <Label marginBottom="one">
          <LabelText>{formatMessage({ id: 'BENEFIT_TYPE_FILTER' })}</LabelText>
          <Select
            value={benefitType}
            onChange={e => {
              setBenefitType(e.value);
            }}
            options={benefits[benefitYear] ?? []}
          />
        </Label>
        <Label maxWidth={880}>
          <LabelText>{formatMessage({ id: 'EMPLOYER_BENEFIT' })}</LabelText>
          <MultiSelect
            value={selectedBenefits}
            onChange={e => setSelectedBenefits(e)}
            options={filteredBenefits}
            isMulti
          />
        </Label>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton
          onClick={() =>
            onSubmit({
              onClose,
              submitBenefitPriceConfig,
              initialJson,
              selectedBenefits,
              groupId,
              showError,
            })
          }
        >
          {formatMessage({ id: 'COPY_PRICING_CONFIG' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

CopyPricingConfig.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  benefits: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  initialJson: PropTypes.objectOf(PropTypes.any),
  selectedBenefitLabel: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  benefitYearOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  submitBenefitPriceConfig: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

CopyPricingConfig.defaultProps = {
  initialJson: {},
};
