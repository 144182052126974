import {
  INITIALIZE,
  EXITED,
  TOGGLE_DOCUMENT_SELECTED,
} from './claim-details.actions';
import { GET_CLAIM } from '../../submit-claim/state/submit-claim.actions';

export const initialState = {
  ready: false,
  error: false,
  claim: null,
  claimHistory: null,
  isModalOpen: false,
  selectedImageUrl: null,
};

export const setInitialResources = (state, action) => ({
  ...state,
  ...action.payload,
  ready: true,
});

export const setClaim = (state, action) => ({
  ...state,
  claim: action.payload.info,
});

export const setError = state => ({
  ...state,
  ready: true,
  error: true,
});

export const onToggleDocumentSelected = (state, action) => ({
  ...state,
  isModalOpen: !state.isModalOpen,
  selectedImageUrl: action.payload.selectedImageUrl,
});

export const keepTheClaim = state => ({
  ...initialState,
  claim: state.claim,
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const claimDetailsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case INITIALIZE.SUCCEEDED:
      return setInitialResources(state, action);
    case GET_CLAIM.SUCCEEDED:
      return setClaim(state, action);
    case INITIALIZE.ERRORED:
      return setError(state, action);
    case TOGGLE_DOCUMENT_SELECTED:
      return onToggleDocumentSelected(state, action);
    case EXITED:
      return keepTheClaim(state);
    default:
      return state;
  }
};
