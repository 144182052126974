import React from 'react';
import PropTypes from 'prop-types';
import { Image, Link } from '@leagueplatform/genesis-commons';
import LinkIcon from 'apps/public/assets/external-link-icon.svg';

export const TemplateLink = ({ children, handleClick, url, uniqueId }) => (
  <Link
    marginTop="half"
    display="inline-flex"
    target="_blank"
    aria-labelledby={uniqueId}
    onClick={handleClick}
    href={url}
  >
    <span id={uniqueId}>{children}</span>
    <Image marginLeft="half" src={LinkIcon} alt="External link" height={20} />
  </Link>
);

TemplateLink.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func,
  url: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
};

TemplateLink.defaultProps = {
  handleClick: () => {},
};
