import { vars, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

export const FundsAllocationDisplay = styled(Box)`
  position: relative;
`;

export const AmountBox = styled(Box).attrs({
  flex: 2,
  alignSelf: 'center',
})`
  .field .fields {
    margin-bottom: 0;
  }
`;

export const DependentClassDescription = styled(Box).attrs({
  flex: 3,
  alignSelf: 'center',
  pr: 2,
})`
  line-height: 1.4;
`;
export const DescriptionContainer = styled(Flex).attrs({
  mt: 2,
  mb: 2,
  ml: 3,
  mr: 3,
  pt: 2,
  pb: 2,
})``;

export const UnderlinedToolTip = styled.span`
  border-bottom: 0.1rem dotted ${vars.colour.darkGrey};
`;

export const CurrencySignLabelContainer = styled(Flex).attrs({
  p: 1,
  pl: 2,
  pr: 2,
  alignItems: 'center',
  justifyContent: 'center',
})`
  background-color: ${vars.colour.lightMediumGrey};
  border: 1px solid ${vars.colour.lightMediumGrey};
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  font-size: 1.2rem;
  color: ${vars.colour.darkGrey};
`;

export const CurrencyLabelContainer = styled(CurrencySignLabelContainer)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`;

export const StyledInput = styled(Input)`
  border-radius: 0;
  border-color: ${vars.colour.greyLight};

  &.ui.input input[type='number'] {
    border-radius: 0;
    border-color: inherit;

    &:active,
    &:focus {
      border-radius: 0;
    }
  }
`;
