import { fork, takeLatest, all, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getTemplatedGenericBenefits } from '../services/get-templated-generic-benefits';
import { setTemplatedGenericBenefit } from '../services/set-templated-generic-benefit';
import {
  RECOMMENDATION_IDS_LIFECYCLE,
  GET_TEMPLATED_GENERIC_BENEFITS,
  SET_TEMPLATED_GENERIC_BENEFIT,
} from './recommendation-ids.actions';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* initialize() {
  yield request(GET_TEMPLATED_GENERIC_BENEFITS, getTemplatedGenericBenefits());
}

export function* setRecommendationIds(action) {
  yield request(
    SET_TEMPLATED_GENERIC_BENEFIT,
    setTemplatedGenericBenefit(action.payload),
  );
}

export function* sendFeedbackToast({ error, payload }) {
  const toastMessage = error
    ? {
        type: TOAST_STATUS.ERROR,
        text: error.message || payload.info.reason,
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        text: payload.info.result,
      };
  yield call(toastActions.add, toastMessage);
}

export function* recommendationIdsSaga() {
  yield fork(all, [
    takeLatest(
      [
        RECOMMENDATION_IDS_LIFECYCLE.VISITED,
        SET_TEMPLATED_GENERIC_BENEFIT.SUCCEEDED,
      ],
      initialize,
    ),
    takeLatest(SET_TEMPLATED_GENERIC_BENEFIT.BASE, setRecommendationIds),
    takeLatest(
      [
        SET_TEMPLATED_GENERIC_BENEFIT.SUCCEEDED,
        SET_TEMPLATED_GENERIC_BENEFIT.ERRORED,
      ],
      sendFeedbackToast,
    ),
  ]);
}
