import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Button } from 'semantic-ui-react';

const Document = ({
  documentId,
  title,
  onRemoveDocument,
  isAdmin,
  documentUrl,
}) => {
  return (
    <Segment vertical className="segment--benefit-document" clearing>
      {documentUrl ? <a href={documentUrl}>{title}</a> : title}
      {isAdmin && (
        <Button
          floated="right"
          basic
          icon="trash outline"
          className="button--action"
          onClick={() => onRemoveDocument(documentId)}
        />
      )}
    </Segment>
  );
};

Document.propTypes = {
  documentId: PropTypes.string.isRequired,
  documentUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

Document.defaultProps = {
  documentUrl: null,
  isAdmin: false,
};

export default Document;
