import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers, lifecycle, withStateHandlers } from 'recompose';
import { selectUploadStatus } from './selectors';
import { FormattedMessage } from 'react-intl';
import { uploadCsv as uploadCsvFile } from './actions';
import { Button, Segment, Input, Container, Header } from 'semantic-ui-react';
import { Text } from '@leagueplatform/ui-kit';
import Page from './Page';

const withState = connect(
  state => ({
    bulkUploading: selectUploadStatus(state),
  }),
  { uploadCsv: uploadCsvFile },
);

const withLoading = withStateHandlers(({ loading = false }) => ({ loading }), {
  startLoading: () => () => ({ loading: true }),
});

const withUploadSuccessWatcher = lifecycle({
  componentWillReceiveProps({ bulkUploading, stepForward }) {
    if (!bulkUploading) {
      stepForward();
    }
  },
});

const UploadCensus = ({ stepBackward, handleFileUpload, loading, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Page {...rest}>
    <Segment attached padded="very">
      <Container>
        <Header as="h3">
          <FormattedMessage id="BULK_UPLOAD_STEP_2_HEADER" />
        </Header>
        <Text>
          <FormattedMessage id="BULK_UPLOAD_STEP_2_CONTENT" />
        </Text>
      </Container>
      <Button className="button--upload" color="violet" loading={loading}>
        <span>
          <FormattedMessage id="BULK_UPLOAD_CENSUS_BTN_LABEL" />
        </span>
        <Input type="file" onChange={handleFileUpload} />
      </Button>
    </Segment>
    <Segment attached padded clearing>
      <Button floated="left" onClick={() => stepBackward(1)}>
        <FormattedMessage id="BULK_UPLOAD_BACK_BTN_LABEL" />
      </Button>
    </Segment>
  </Page>
);

UploadCensus.propTypes = {
  stepBackward: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(
  withState,
  withLoading,
  withUploadSuccessWatcher,
  withHandlers({
    handleFileUpload:
      ({ uploadCsv, startLoading }) =>
      ({ target }) => {
        const file = target.files[0];
        startLoading();
        uploadCsv({ file });
      },
  }),
)(UploadCensus);
