import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  Flex,
  Box,
  HeadingTwo,
  BodyOne,
  PrimaryButton,
  SecondaryButton,
  Label,
} from '@leagueplatform/genesis-commons';
import { AnnualPeriodDate } from 'apps/employer-experience/ui-kit/annual-period-date.view';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';

const calculateNewPlanPeriod = planPeriod => {
  const newStartDate = moment(planPeriod?.startDate ?? new Date())
    .add(1, 'year')
    .toDate();
  const newEndDate = moment(planPeriod?.endDate ?? new Date())
    .add(1, 'year')
    .toDate();

  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
};

export const useRenewPlanHandlers = ({
  groupId,
  benefitPlanId,
  planPeriod,
  benefitClassId,
  renewBenefitPlan,
  onClose,
}) => {
  const defaultNewPlanPeriod = useMemo(
    () => calculateNewPlanPeriod(planPeriod),
    [planPeriod],
  );

  const [newPlanStartDate, setNewPlanStartDate] = useState(
    defaultNewPlanPeriod.startDate,
  );

  const [newPlanEndDate, setNewPlanEndDate] = useState(
    defaultNewPlanPeriod.endDate,
  );

  const onSubmit = useCallback(() => {
    renewBenefitPlan({
      groupId,
      originalBenefitPlanId: benefitPlanId,
      planPeriod: {
        start_date: newPlanStartDate,
        end_date: newPlanEndDate,
      },
      benefitClassId,
    });
    onClose();
  }, [
    renewBenefitPlan,
    benefitPlanId,
    groupId,
    onClose,
    newPlanStartDate,
    newPlanEndDate,
    benefitClassId,
  ]);

  return {
    newPlanStartDate,
    newPlanEndDate,
    setNewPlanStartDate,
    setNewPlanEndDate,
    defaultNewPlanPeriod,
    onSubmit,
  };
};

export const RenewPlanPresenter = ({
  onClose,
  benefitPlanName,
  employerTzName,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    onSubmit,
    newPlanStartDate,
    newPlanEndDate,
    setNewPlanStartDate,
    setNewPlanEndDate,
  } = useRenewPlanHandlers({
    ...props,
    onClose,
    benefitPlanName,
  });

  return (
    <Modal>
      <HeadingTwo>
        {formatMessage({ id: 'RENEW_PLAN_HEADER' }, { benefitPlanName })}
      </HeadingTwo>
      <ModalContent width={600}>
        <BodyOne>
          {formatMessage({ id: 'RENEW_PLAN_DESCRIPTION' }, { benefitPlanName })}
        </BodyOne>
        <Box marginTop="oneAndHalf">
          <Label>
            {formatMessage({ id: 'PLAN_START_DATE' })}
            <Flex width="100%">
              <AnnualPeriodDate
                value={newPlanStartDate}
                setDateValue={momentDate => {
                  setNewPlanStartDate(momentDate.toDate());
                }}
                name="start_date"
                employerTzName={employerTzName}
              />
            </Flex>
          </Label>
        </Box>
        <Box marginTop="one">
          <Label>
            {formatMessage({ id: 'PLAN_END_DATE' })}
            <Flex>
              <AnnualPeriodDate
                value={newPlanEndDate}
                setDateValue={momentDate => {
                  setNewPlanEndDate(momentDate.toDate());
                }}
                name="end_date"
                employerTzName={employerTzName}
              />
            </Flex>
          </Label>
        </Box>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit}>
          {formatMessage({ id: 'SUBMIT' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

RenewPlanPresenter.propTypes = {
  benefitPlanName: PropTypes.string.isRequired,
  employerTzName: PropTypes.string.isRequired,
  planPeriod: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
