import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Box, SubtitleOne } from '@leagueplatform/genesis-commons';
import { breakpoints, Responsive } from '@leagueplatform/ui-kit';
import { useFormattedInsuredCoverageSummaryMessages } from '../../use-formatted-coverage-summary-messages.hook';
import {
  SelectionWrapper,
  SelectionTitle,
  SelectionValue,
  BenefitCost,
  CoverageWithPeriodType,
} from './selection-wrapper.view';
import { ApprovedCoverage } from './approved-coverage.view';

export const InsuredBenefitsSummarySelection = ({
  showBenefitName,
  multipleBenefits,
  employerPaysAllCosts,
  benefit_name: benefitName,
  title,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    formattedCoverage,
    formattedCoveragePeriodType,
    formattedSelection,
    formattedCost,
    formattedPeriodType,
    formattedDependentCoverage,
    formattedApprovedCoverage,
    formattedDependentCoverages,
    formattedApprovedCost,
  } = useFormattedInsuredCoverageSummaryMessages(props);

  const showCost = formattedCost && !employerPaysAllCosts;
  const formattedSelectionTitle = formatMessage({ id: 'PLAN_SELECTION' });
  const formattedCoverageSelectionTitle = formatMessage({ id: 'COVERAGE' });
  const formattedCoverageForTitle = formatMessage({ id: 'COVERAGE_FOR' });
  const formattedCostTitle = formattedApprovedCost
    ? formatMessage({ id: 'TOTAL_COST' })
    : formatMessage({ id: 'COST' });

  return (
    <Box color="onSurface.text.subdued">
      {showBenefitName && (
        <SubtitleOne as="h4">{title || benefitName}</SubtitleOne>
      )}
      <Box>
        <Responsive breakpoint={breakpoints.belowTablet}>
          {showCost && !multipleBenefits && (
            <SelectionWrapper>
              <SelectionTitle>{formattedCostTitle}</SelectionTitle>
              <BenefitCost
                formattedCost={formattedCost}
                formattedPeriodType={formattedPeriodType}
              />
            </SelectionWrapper>
          )}
        </Responsive>
        {showCost && multipleBenefits && (
          <SelectionWrapper>
            <SelectionTitle>{formattedCostTitle}</SelectionTitle>
            <BenefitCost
              formattedCost={formattedCost}
              formattedPeriodType={formattedPeriodType}
            />
          </SelectionWrapper>
        )}
        {formattedSelection && (
          <SelectionWrapper>
            <SelectionTitle>{formattedSelectionTitle}</SelectionTitle>
            <SelectionValue>{formattedSelection}</SelectionValue>
          </SelectionWrapper>
        )}
        {formattedCoverage && (
          <SelectionWrapper>
            <SelectionTitle>{formattedCoverageSelectionTitle}</SelectionTitle>
            {formattedCoveragePeriodType ? (
              <CoverageWithPeriodType
                formattedCoverage={formattedCoverage}
                formattedCoveragePeriodType={formattedCoveragePeriodType}
              />
            ) : (
              <SelectionValue>{formattedCoverage}</SelectionValue>
            )}
          </SelectionWrapper>
        )}
        {formattedDependentCoverage && (
          <SelectionWrapper>
            <SelectionTitle>{formattedCoverageForTitle}</SelectionTitle>
            <SelectionValue>{formattedDependentCoverage}</SelectionValue>
          </SelectionWrapper>
        )}
        {Array.isArray(formattedDependentCoverages) &&
          formattedDependentCoverages.map(({ name, value }) => (
            <SelectionWrapper key={name}>
              <>
                <SelectionTitle>{name}</SelectionTitle>
                <SelectionValue>{value}</SelectionValue>
              </>
            </SelectionWrapper>
          ))}
        {formattedApprovedCoverage && (
          <Box marginTop="one">
            <ApprovedCoverage
              formattedApprovedCoverage={formattedApprovedCoverage}
              formattedApprovedCost={formattedApprovedCost}
              formattedPeriodType={formattedPeriodType}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

InsuredBenefitsSummarySelection.propTypes = {
  employerPaysAllCosts: PropTypes.bool,
  showBenefitName: PropTypes.bool,
  benefit_name: PropTypes.string,
  multipleBenefits: PropTypes.bool,
  title: PropTypes.string,
};

InsuredBenefitsSummarySelection.defaultProps = {
  showBenefitName: false,
  multipleBenefits: false,
  employerPaysAllCosts: false,
  benefit_name: undefined,
  title: undefined,
};
