import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Fetch all Maple phase 2, and Lifemark appointments
 * (Only available to Health Admins).
 *
 * @param {Object} request - The request data
 * @param {String} request.userId - Fetch a particular user's history
 * @param {Number?} request.offset - The page offset
 * @param {Number?} request.limit - The page limit
 * @returns {Object} - A redux-saga call effect
 */
export const getCareProviderAppointmentHistory = ({ userId }) =>
  call(websocketFetch, 'get_appointments_by_user', {
    user_id: userId,
  });
