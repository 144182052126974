import { createSelector } from 'reselect';

const selectApplication = state => state?.application;

export const selectApplicationIsBooting = createSelector(
  selectApplication,
  slice => slice?.booting,
);

export const selectIsInWidgetRunner = createSelector(
  selectApplication,
  slice => slice?.isInWidgetRunner,
);
