import { DangerButton, SecondaryButton } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import React from 'react';
import { EnrollmentStepItemType } from '../enrollment-design-options.types';
import EditingPanelHeaderTitle from './editing-panel-header-title.view';

interface EditingPanelStepHeaderProps {
  activeEditingItem: EnrollmentStepItemType | null;
  onCreateGroupClick: (stepId: string) => void;
  clearActiveEditingId: () => void;
  deleteActiveStepOrGroup: () => void;
}

const EditingPanelStepHeader = ({
  activeEditingItem,
  onCreateGroupClick,
  clearActiveEditingId,
  deleteActiveStepOrGroup,
}: EditingPanelStepHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <EditingPanelHeaderTitle
        activeEditingItem={activeEditingItem}
        title="ENROLLMENT_DESIGN_STEP"
        clearActiveEditingId={clearActiveEditingId}
      />

      <SecondaryButton
        marginBottom="one"
        marginRight="half"
        onClick={() =>
          activeEditingItem && onCreateGroupClick(activeEditingItem.id)
        }
      >
        {formatMessage({
          id: 'CREATE_GROUP_TEXT',
        })}
      </SecondaryButton>
      <DangerButton onClick={deleteActiveStepOrGroup} marginBottom="one">
        {formatMessage({ id: 'DELETE_ENROLLMENT_STEP' })}
      </DangerButton>
    </>
  );
};

export default EditingPanelStepHeader;
