import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Formik, Form } from 'formik';
import {
  Box,
  TextField,
  PrimaryButton,
  LabelText,
  Flex,
  Label,
  Spinner,
  HeadingFour,
} from '@leagueplatform/genesis-commons';
import { useNetSuiteId } from './use-net-suite-id.hook';
import PropTypes from 'prop-types';

export const NetSuiteId = ({ groupId }) => {
  const { formatMessage } = useIntl();

  const { onSubmitNetSuiteId, groupData, isLoading } = useNetSuiteId({
    groupId,
  });

  if (isLoading) return <Spinner isLoading={isLoading} />;

  const initialValues = {
    netSuiteId: groupData?.netsuite_id || '',
  };

  return (
    <Box marginTop="two">
      {/* didn't add the validation since NetSuite Id can be empty */}
      <Formik
        marginTop="two"
        initialValues={initialValues}
        onSubmit={values => {
          onSubmitNetSuiteId(values);
        }}
        enableReinitialize
      >
        {({ values, handleChange, handleSubmit, handleBlur }) => (
          <Form>
            <HeadingFour fontWeight="inherit">
              {formatMessage({ id: 'NETSUITE' })}
            </HeadingFour>
            <Label>
              <Flex>
                <LabelText marginRight="half" marginTop="two">
                  {formatMessage({ id: 'NETSUITE_ID' })}
                </LabelText>
              </Flex>
              <TextField
                marginBottom="one"
                name="netSuiteId"
                value={values.netSuiteId}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Label>
            <PrimaryButton onClick={handleSubmit} type="submit">
              {formatMessage({ id: 'SAVE_NETSUITE_ID' })}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

NetSuiteId.propTypes = {
  groupId: PropTypes.string.isRequired,
};
