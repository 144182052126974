import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { FormCard } from 'common/insurance-forms/form-card.view';
import { INSURANCE_FORM_STATUS_TYPES } from 'common/insurance-forms/insurance-forms.constants';

export const DigitalBeneficiariesCardWrapper = ({ children }) => {
  const { formatMessage } = useIntl();

  const tooltipText = formatMessage(
    { id: 'GENERIC_TOOLTIP_NAME' },
    { name: `${formatMessage({ id: 'BENEFICIARIES' })}` },
  );

  return (
    <FormCard
      title={formatMessage({ id: 'BENEFICIARIES' })}
      description={formatMessage({
        id: 'BENEFICIARIES_FORM_CARD_DESCRIPTION',
      })}
      formType={INSURANCE_FORM_STATUS_TYPES.RECOMMENDED}
      tooltip={formatMessage(
        { id: 'BENEFICIARIES_FORM_CARD_TOOLTIP' },
        {
          // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
          p: text => <p>{text}</p>,
        },
      )}
      tooltipText={tooltipText}
    >
      {children}
    </FormCard>
  );
};

DigitalBeneficiariesCardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
