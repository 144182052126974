import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from 'lodash';
import { compose, lifecycle } from 'recompose';
import { UserDocument } from './user-document/user-document.view';
import { Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { isImageContentType } from '@leagueplatform/web-common';

const withLifecycle = lifecycle({
  componentWillUnmount() {
    this.props.resetUserDocuments();
  },
});

const LoadingIndicatorContainer = styled(Box)`
  min-height: 20rem;
`;

const UserDocumentsContainer = styled(Flex)`
  flex-wrap: wrap;
`;

export const UserDocuments = compose(withLifecycle)(
  ({
    documents,
    confirmRemoveUserDocument,
    editUserDocument,
    isLoading,
    userId,
    openViewer,
  }) =>
    isLoading ? (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    ) : (
      <UserDocumentsContainer>
        {documents &&
          documents.length > 0 &&
          documents.map(document => (
            <Box
              width={[1, 1 / 3]}
              mb={1}
              p={1}
              h="10rem"
              key={document.contentId}
            >
              <UserDocument
                contentId={document.contentId}
                documentType={document.documentType}
                documentTypeOtherDescription={
                  document.documentTypeOtherDescription
                }
                expirationDate={document.expirationDate}
                // contentUrls is an array so we do [0] to bring up the first file of the doc
                onImageClick={evt =>
                  isImageContentType(document.contentType)
                    ? openViewer(evt, document.contentUrls[0])
                    : window.open(document.contentUrls[0], '_blank')
                }
                isImageContentType={isImageContentType(document.contentType)}
                onActionLabelClick={() => editUserDocument(document.contentId)}
                onPinClick={() =>
                  confirmRemoveUserDocument({
                    userId,
                    contentId: document.contentId,
                  })
                }
              />
            </Box>
          ))}
      </UserDocumentsContainer>
    ),
);

UserDocuments.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string,
      documentType: PropTypes.string,
      documentTypeOtherDescription: PropTypes.string,
      expirationDate: PropTypes.string,
      contentUrl: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  userId: PropTypes.string,
  resetUserDocuments: PropTypes.func.isRequired,
  confirmRemoveUserDocument: PropTypes.func.isRequired,
  editUserDocument: PropTypes.func.isRequired,
  openViewer: PropTypes.func,
};

UserDocuments.defaultProps = {
  documents: [],
  isLoading: false,
  userId: null,
  openViewer: noop,
};

UserDocuments.displayName = 'UserDocuments';
