import React from 'react';
import PropTypes from 'prop-types';

import { Input, FontAwesomeIcon } from '@leagueplatform/ui-kit';
import { Box, genesisStyled, css } from '@leagueplatform/genesis-commons';

const iconWrapperWidth = 'three';

const StyledInput = genesisStyled(Input)(
  css({
    paddingRight: iconWrapperWidth,
    minHeight: 45,
    height: 'auto',
  }),
);

// Copied from Employee Profile .ui.icon.input > i.icon
export const IconWrapper = genesisStyled(Box)(({ disabled }) =>
  css({
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: 1,
    textAlign: 'center',
    height: '100%',
    width: iconWrapperWidth,
    opacity: 0.5,
    transition: 'opacity 0.3s ease-in-out',

    cursor: !disabled && 'pointer',
    '&:hover': {
      opacity: !disabled && 1,
    },
  }),
);

// Copied from Employee Profile .ui.icon.input > i.icon:before
// eslint-disable-next-line react/jsx-props-no-spreading
const StyledIcon = genesisStyled(props => <FontAwesomeIcon {...props} />)(
  css({
    left: 0,
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    width: '100% !important',
    marginTop: '-0.5em',
  }),
);

export const MaskedNINInputPresenter = ({
  handleIconClick,
  iconVariation,
  iconClickEnabled,
  isLoading,
  isIconHidden,
  ...rest
}) => {
  return (
    <Box position="relative">
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <StyledInput fit {...rest} />
      {!isIconHidden && (
        <IconWrapper disabled={!iconClickEnabled} onClick={handleIconClick}>
          <StyledIcon spin={isLoading} icon={iconVariation} />
        </IconWrapper>
      )}
    </Box>
  );
};

MaskedNINInputPresenter.propTypes = {
  handleIconClick: PropTypes.func.isRequired,
  iconVariation: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    icon: PropTypes.array,
  }).isRequired,
  isIconHidden: PropTypes.bool,
  iconClickEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  value: PropTypes.string.isRequired,
};

MaskedNINInputPresenter.defaultProps = {
  iconClickEnabled: false,
  isLoading: false,
  isIconHidden: false,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  value: '',
};

export default MaskedNINInputPresenter;
