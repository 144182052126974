/* eslint-disable class-methods-use-this */
// eslint-disable-next-line no-restricted-imports
import createAuth0Client from '@auth0/auth0-spa-js';

import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_CORE_API_AUDIENCE,
} from './auth0.constants';
import mobileAuthStateListener from './auth0.mobile-auth-state-listener';

const auth0Config = {
  domain: AUTH0_DOMAIN,
  client_id: AUTH0_CLIENT_ID,
  redirect_uri: `${window.location.origin}/app`,
};

// Lazy getter for the SDK's Auth0Client.
// Caches the result in auth0ClientPromise, and always returns the same promise to ensure that
// multiple calls will always return the same client.
let auth0ClientPromise;
async function getAuth0Client() {
  if (!auth0ClientPromise) {
    auth0ClientPromise = createAuth0Client(auth0Config);
  }

  return auth0ClientPromise;
}

// This class serves as a wrapper for the Auth0Client from the Auth0 SPA SDK.
//
// Most of the time it directly delegates calls to the SDK, however when we are embedded in a
// mobile app webview, it will instead uses authentication state passed by the mobile app via the
// `mobileAuthStateListener`.
export class Auth0ClientWrapper {
  // Wrapper methods for Auth0 SDK methods that depend on credentials
  async isAuthenticated() {
    if (mobileAuthStateListener.getState()?.accessToken) {
      return true;
    }

    const client = await getAuth0Client();

    return client.isAuthenticated();
  }

  // Not quite a 1-1 mapping with the SDK's getTokenSilently, because we can't support the
  // `options` param when using mobileAuthState.
  async getAccessToken() {
    const mobileAuthToken = mobileAuthStateListener.getState()?.accessToken;
    if (mobileAuthToken) {
      return mobileAuthToken;
    }

    const client = await getAuth0Client();

    return client.getTokenSilently({
      audience: AUTH0_CORE_API_AUDIENCE,
    });
  }

  // Not quite a 1-1 mapping with the SDK's getUser, because we can't support the
  // `options` param when using mobileAuthState.
  async getUser() {
    const mobileAuthUser = mobileAuthStateListener.getState()?.user;
    if (mobileAuthUser) {
      return mobileAuthUser;
    }

    const client = await getAuth0Client();

    return client.getUser();
  }

  // Wrapper methods for other SDK methods that don't depend on credentials
  async loginWithRedirect(...params) {
    const client = await getAuth0Client();

    return client.loginWithRedirect(...params);
  }

  async logout(...params) {
    const client = await getAuth0Client();

    return client.logout(...params);
  }

  async handleRedirectCallback(...params) {
    const client = await getAuth0Client();

    return client.handleRedirectCallback(...params);
  }
}

// Export an instance of this class that consumers can use directly.
export const Auth0ClientWrapperInstance = new Auth0ClientWrapper();
