import { takeLatest, fork, all, put, select, call } from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  SET_USER_PROFILE,
  GET_USER_AUTH_METHOD,
  GET_USER_DEPENDENTS,
  REQUEST_PHONE_NUMBER_CHANGE,
  CONFIRM_PHONE_NUMBER_CHANGE,
  REQUEST_CHANGE_PASSWORD,
  UPLOAD_PROFILE_PHOTO,
  REQUEST_PHONE_NUMBER_CHANGE_CALL,
} from './profile-page.actions';
import {
  selectUserId,
  selectUserProfile,
} from 'common/state/user/user.selectors';
import { selectUserGroupId } from 'common/state/user/user-group.selectors';
import { upload } from 'apps/upload/upload.saga';
import { getDependents } from 'common/services/get-dependents.service';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export const serializeUserProfile = (values, userProfile) => {
  const allLocations = userProfile?.locations || [];
  const nonHomeLocations = allLocations.filter(
    location => location.type !== 'home',
  );

  return {
    first_name: values?.firstName || '',
    last_name: values?.lastName || '',
    preferred_first_name: values?.preferredFirstName || '',
    sex: values?.sex || '',
    gender_identity: values?.genderIdentity || [],
    pronouns: values?.pronouns || [],
    date_of_birth: values?.dateOfBirth || new Date(0),
    locale: values?.locale || '',
    phone_number: values?.phoneNumber || '',
    avatar: {
      image_id: values?.avatar?.imageId || '',
    },
    locations: [
      ...nonHomeLocations,
      {
        address1: values?.address1 || '',
        address2: values?.address2 || '',
        city: values?.city || '',
        province: values?.province || '',
        postal: values?.postalCode || '',
        country: values?.country || '',
        isDefault: true,
        type: 'home',
      },
    ],
  };
};

export function* setUserProfile(action) {
  const userProfile = yield select(selectUserProfile);
  yield request(SET_USER_PROFILE, [
    websocketFetch,
    'set_user_profile',
    serializeUserProfile(action?.payload, userProfile),
  ]);
}

export function* getUserAuthMethod() {
  yield request(GET_USER_AUTH_METHOD, [websocketFetch, 'get_auth_method']);
}

export function* getUserDependents() {
  const groupId = yield select(selectUserGroupId);
  const userId = yield select(selectUserId);
  yield request(
    GET_USER_DEPENDENTS,
    getDependents({
      groupId,
      userId,
      includeSuspended: true,
    }),
  );
}

export function* getRequestPhoneNumberChange(action) {
  const requestType = action?.payload?.forceVoiceCall
    ? REQUEST_PHONE_NUMBER_CHANGE_CALL
    : REQUEST_PHONE_NUMBER_CHANGE;

  yield request(requestType, [
    websocketFetch,
    'request_phone_number_change',
    {
      phone_country_code: action?.payload?.countryCode,
      phone_number: action?.payload?.phoneNumber,
      voice_call: action?.payload?.forceVoiceCall || false,
    },
  ]);
}

export function* getConfirmPhoneNumberChange(action) {
  yield request(CONFIRM_PHONE_NUMBER_CHANGE, [
    websocketFetch,
    'confirm_phone_number_change',
    {
      sms_code: action?.payload?.verificationCode,
    },
  ]);
}

export function* getRequestChangePassword(action) {
  yield request(REQUEST_CHANGE_PASSWORD, [
    websocketFetch,
    'change_password',
    {
      auth_token: action?.payload?.currentPassword,
      new_auth_token: action?.payload?.newPassword,
    },
  ]);
}

export function* showProfileSuccessToast(action) {
  const toastTitle = () => {
    switch (action?.type) {
      case SET_USER_PROFILE.SUCCEEDED:
        return 'PROFILE_UPDATED';
      case CONFIRM_PHONE_NUMBER_CHANGE.SUCCEEDED:
        return 'MOBILE_PHONE_NUMBER_UPDATED';
      case REQUEST_CHANGE_PASSWORD.SUCCEEDED:
        return 'PASSWORD_UPDATED';
      case REQUEST_PHONE_NUMBER_CHANGE.SUCCEEDED:
        return 'VERIFICATION_CODE_SENT';
      case REQUEST_PHONE_NUMBER_CHANGE_CALL.SUCCEEDED:
        return 'VERIFICATION_CALL_SENT';
      default:
        return 'PROFILE_UPDATED';
    }
  };

  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: toastTitle(),
  });
}

export function* showProfileErrorToast(error) {
  const toastTitle = () => {
    switch (error?.type) {
      case SET_USER_PROFILE.ERRORED:
        return 'PROFILE_UPDATE_FAILED';
      case GET_USER_DEPENDENTS.ERRORED:
        return 'GET_DEPENDENTS_ERROR';
      case REQUEST_PHONE_NUMBER_CHANGE.ERRORED:
        return 'EDIT_MOBILE_PHONE_FAILED';
      case REQUEST_PHONE_NUMBER_CHANGE_CALL.ERRORED:
        return 'EDIT_MOBILE_PHONE_FAILED';
      default:
        return 'PROFILE_UPDATE_FAILED';
    }
  };

  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: toastTitle(),
    contentId: error?.payload?.info?.reason,
  });
}

export function* startProfilePhotoUpload(action) {
  try {
    yield put(UPLOAD_PROFILE_PHOTO.start());
    const file = action?.payload;

    // The upload saga takes an array of files and returns an array of contentIds
    const contentIds = yield call(upload, [file]);
    const referenceId = contentIds?.[0];

    if (!referenceId) {
      throw new Error();
    }
    yield put(UPLOAD_PROFILE_PHOTO.success({ referenceId }));
  } catch (error) {
    yield put(UPLOAD_PROFILE_PHOTO.error(error));
  }
}

export function* userProfileSaga() {
  yield fork(all, [
    takeLatest(SET_USER_PROFILE.BASE, setUserProfile),
    takeLatest(
      [
        SET_USER_PROFILE.SUCCEEDED,
        CONFIRM_PHONE_NUMBER_CHANGE.SUCCEEDED,
        REQUEST_CHANGE_PASSWORD.SUCCEEDED,
        REQUEST_PHONE_NUMBER_CHANGE.SUCCEEDED,
        REQUEST_PHONE_NUMBER_CHANGE_CALL.SUCCEEDED,
      ],
      showProfileSuccessToast,
    ),
    takeLatest(
      [
        SET_USER_PROFILE.ERRORED,
        REQUEST_PHONE_NUMBER_CHANGE.ERRORED,
        CONFIRM_PHONE_NUMBER_CHANGE.ERRORED,
        REQUEST_CHANGE_PASSWORD.ERRORED,
        UPLOAD_PROFILE_PHOTO.ERRORED,
        GET_USER_DEPENDENTS.ERRORED,
      ],
      showProfileErrorToast,
    ),
    takeLatest(GET_USER_AUTH_METHOD.BASE, getUserAuthMethod),
    takeLatest(GET_USER_DEPENDENTS.BASE, getUserDependents),
    takeLatest(REQUEST_PHONE_NUMBER_CHANGE.BASE, getRequestPhoneNumberChange),
    takeLatest(CONFIRM_PHONE_NUMBER_CHANGE.BASE, getConfirmPhoneNumberChange),
    takeLatest(REQUEST_CHANGE_PASSWORD.BASE, getRequestChangePassword),
    takeLatest(UPLOAD_PROFILE_PHOTO.BASE, startProfilePhotoUpload),
  ]);
}
