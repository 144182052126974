import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const GET_CLAIM_SETTLEMENT_RESOURCES = createRequestTypes(
  'claim-settlement/GET_CLAIM_SETTLEMENT_RESOURCES',
);
export const CLAIM_SETTLEMENT_LIFECYCLE = createLifecycleTypes(
  'claim-settlement/CLAIM_SETTLEMENT_LIFECYCLE',
);
export const CLAIM_FORM_SUBMITTED = 'claim-settlement/CLAIM_FORM_SUBMITTED';
export const CLAIM_APPROVAL_CANCELLED =
  'claim-settlement/CLAIM_APPROVAL_CANCELLED';
export const DUPLICATE_CLAIM_REJECTED =
  'claim-settlement/DUPLICATE_CLAIM_REJECTED';
export const DUPLICATE_CLAIM_APPROVED =
  'claim-settlement/DUPLICATE_CLAIM_APPROVED';
export const DUPLICATE_CLAIM_IDENTIFIED =
  'claim-settlement/DUPLICATE_CLAIM_IDENTIFIED';
export const APPROVE_CLAIM = createRequestTypes(
  'claim-settlement/APPROVE_CLAIM',
);
export const UPDATE_CLAIM = createRequestTypes('claim-settlement/UPDATE_CLAIM');
export const SAVE_PARTIAL_CLAIM = createRequestTypes(
  'claim-settlement/SAVE_PARTIAL_CLAIM',
);
export const RESOLVE_DUPLICATE_CLAIM = createRequestTypes(
  'claim-settlement/RESOLVE_DUPLICATE_CLAIM',
);
export const GET_VENDORS = createRequestTypes('claim-settlement/GET_VENDORS');
export const VENDOR_QUERY_CHANGED = 'claim-settlement/VENDOR_QUERY_CHANGED';
export const EXPENSE_DATE_CHANGED = 'claim-settlement/EXPENSE_DATE_CHANGED';
export const SAVE_CLAIM_VALUES_REQUESTED =
  'claim-settlement/SAVE_CLAIM_VALUES_REQUESTED';
export const UPDATE_CLAIM_ALLOCATION_AMOUNTS =
  'claim-settlement/UPDATE_CLAIM_ALLOCATION_AMOUNTS';
export const READJUST_BENEFIT_AMOUNTS = createRequestTypes(
  'claim-settlement/READJUST_BENEFIT_AMOUNTS',
);
