import { createRequestTypes } from 'common/websocket-redux';

/**
 * Action to signify that user should be sent to sign-in. This usually means
 * the user is not authenticated.
 */
export const REDIRECT_TO_SIGN_IN = 'auth/REDIRECT_TO_SIGN_IN';

/**
 * Action creator to signify that user should be sent to sign-in. This usually
 * means the user is not authenticated.
 * @returns Redux Action { type: REDIRECT_TO_SIGN_IN }
 */
export const redirectToSignIn = () => ({ type: REDIRECT_TO_SIGN_IN });

/**
 * The user is being signed out. Session and other cleanup is performed.
 *
 * SIGN_OUT.request
 *
 * Action creator to signify that user should be signed-out: either they have
 * clicked on a sign-out link, or the user session has ended for some reason:
 * for example privacy lock has timed-out.
 * @param {Object} queryParameters this is set by privacy lock
 * @param {number} queryParameters.timeout this is set by privacy lock and is
 *    read in WidgetRunner to show a message to the user that they were timed
 *    out due to inactivity
 * @returns Redux Action { type: SIGN_OUT.BASE, payload: { queryParameters } }
 */
export const SIGN_OUT = createRequestTypes('auth/SIGN_OUT');
