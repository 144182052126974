import { combineReducers } from 'redux';
import { GET_USER_PLAN_ENROLLMENT } from './plan-enrollment.actions';
import { STATUSES } from './plan-enrollment.constants';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const planEnrollments = (state = [], action) => {
  switch (action?.type) {
    case GET_USER_PLAN_ENROLLMENT.SUCCEEDED:
      return action.payload.info.plan_enrollments ?? [];
    case GET_USER_PLAN_ENROLLMENT.STARTED:
      return [];
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const status = (state = null, action) => {
  switch (action?.type) {
    case GET_USER_PLAN_ENROLLMENT.STARTED:
      return STATUSES.LOADING;
    case GET_USER_PLAN_ENROLLMENT.SUCCEEDED:
    case GET_USER_PLAN_ENROLLMENT.CANCELLED:
      return STATUSES.READY;
    case GET_USER_PLAN_ENROLLMENT.ERRORED:
      return STATUSES.ERROR;
    default:
      return state;
  }
};

export const planEnrollmentReducer = combineReducers({
  planEnrollments,
  status,
});
