/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  Flex,
  SubtitleOne,
  BodyOne,
} from '@leagueplatform/genesis-commons';
import { TEMPLATE_STATUS, TEMPLATE_TYPES } from '../wallet-template-utils';
import { TemplateLink } from './template-link.view';
import { TemplateTooltip } from './template-tooltip.view';
import { TemplateStatusPill } from './template-status-pill.view';

export const TemplatePair = ({
  pair,
  isFirstPair,
  isLastPair,
  benefitId,
  onCreateTemplateSelected,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      marginTop={isFirstPair && 'oneAndHalf'}
      borderTopStyle="solid"
      borderTopColor="onSurface.border.subdued"
      borderTopWidth="thin"
      paddingX="oneAndHalf"
      paddingBottom="none"
    >
      {pair.map((template, index) => {
        const { template_type, benefit_type, status, url } = template;
        const isCustomTemplate = template_type === TEMPLATE_TYPES.GROUP;
        const noTemplate = status === TEMPLATE_STATUS.NO_TEMPLATE;
        const isFirstItem = index === 0;
        const isLastItem = index === 1;

        return (
          <Box key={benefit_type + template_type}>
            {isFirstItem && (
              <Flex justifyContent="space-between" paddingTop="oneAndHalf">
                <SubtitleOne>{template.name}</SubtitleOne>
                <SubtitleOne as="span" color="onSurface.text.subdued">
                  {benefit_type}
                </SubtitleOne>
              </Flex>
            )}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingTop="oneAndHalf"
              paddingBottom={
                (isLastPair && isLastItem) || isFirstItem
                  ? 'none'
                  : 'oneAndHalf'
              }
            >
              <Box>
                <Flex justifyContent="space-between" alignItems="center">
                  <BodyOne>
                    {formatMessage({
                      id: isCustomTemplate
                        ? 'CUSTOM_TEMPLATE_STATUS'
                        : 'DEFAULT_TEMPLATE_STATUS',
                    })}
                  </BodyOne>
                  <TemplateTooltip>
                    {formatMessage({
                      id: isCustomTemplate
                        ? 'CUSTOM_TEMPLATE_TOOLTIP'
                        : 'DEFAULT_TEMPLATE_TOOLTIP',
                    })}
                  </TemplateTooltip>
                </Flex>
                {!isCustomTemplate && !noTemplate && (
                  <TemplateLink
                    url={url}
                    uniqueId={template_type + benefit_type}
                  >
                    {formatMessage({ id: 'VIEW_IN_CONTENTFUL' })}
                  </TemplateLink>
                )}
                {isCustomTemplate && (
                  <TemplateLink
                    uniqueId={template_type + benefit_type}
                    url={url}
                    handleClick={event => {
                      if (noTemplate) {
                        onCreateTemplateSelected({
                          benefitId,
                          benefitType: benefit_type,
                        });
                        event.preventDefault();
                      }
                    }}
                  >
                    {noTemplate
                      ? formatMessage({
                          id: 'CREATE_IN_CONTENTFUL',
                        })
                      : formatMessage({ id: 'VIEW_IN_CONTENTFUL' })}
                  </TemplateLink>
                )}
              </Box>
              <TemplateStatusPill status={status} />
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

TemplatePair.propTypes = {
  pair: PropTypes.arrayOf(
    PropTypes.shape({
      benefit_product_type: PropTypes.string,
      benefit_type: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      template_type: PropTypes.string,
      url: PropTypes.string,
      vendor_id: PropTypes.string,
    }),
  ).isRequired,
  benefitId: PropTypes.string.isRequired,
  onCreateTemplateSelected: PropTypes.func.isRequired,
  isFirstPair: PropTypes.bool,
  isLastPair: PropTypes.bool.isRequired,
};

TemplatePair.defaultProps = {
  isFirstPair: false,
};
