import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';

import {
  BodyOne,
  SecondaryButton,
  HeadingTwo,
} from '@leagueplatform/genesis-commons';

export const ExportPaySchedule = ({ onClose, documentUrl, documentName }) => {
  const { formatMessage } = useIntl();
  return (
    <Modal>
      <HeadingTwo>{formatMessage({ id: 'EXPORT_PAY_SCHEDULE' })}</HeadingTwo>
      <ModalContent>
        <BodyOne marginBottom="threeQuarters">
          {`${formatMessage({
            id: 'DOWNLOAD_EXPORTED_PAY_SCHEDULE',
          })}: ${documentName}`}
        </BodyOne>
        <a href={documentUrl} rel="noopener noreferrer" target="_blank">
          {documentUrl}
        </a>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CLOSE' })}
        </SecondaryButton>
      </ModalButtons>
    </Modal>
  );
};

ExportPaySchedule.propTypes = {
  onClose: PropTypes.func.isRequired,
  documentUrl: PropTypes.string,
  documentName: PropTypes.string,
};

ExportPaySchedule.defaultProps = {
  documentUrl: '',
  documentName: '',
};
