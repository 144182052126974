import React from 'react';
import { AuthView } from './auth.view';
import { Route, Switch } from 'react-router-dom';
import { AuthErrorView } from './auth-error.view';
import { AUTH_ERROR_ROUTE } from 'apps/auth/auth0/auth0.constants';

export function Auth0LandingPage() {
  return (
    <Switch>
      <Route path={AUTH_ERROR_ROUTE} component={AuthErrorView} />
      <Route path="/(sign-in|sign-out)" component={AuthView} />
    </Switch>
  );
}
