export default {
  gwlHealthcare: {
    name: 'gwlHealthcare',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'benefit_year',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
          {
            name: 'opt_out',
            span: 4,
          },
          {
            name: 'auto_renew',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'reimbursement_percent',
            span: 6,
          },

          {
            name: 'maximum_applies',
            span: 6,
          },

          {
            name: 'maximum_amount',
            span: 6,
          },

          {
            name: 'deductible_single',
            span: 6,
          },

          {
            name: 'deductible_family',
            span: 6,
          },

          {
            name: 'deductible_individual',
            span: 6,
          },

          {
            name: 'deductible_individual_combined',
            span: 6,
          },

          {
            name: 'ambulance_included',
            span: 6,
          },

          {
            name: 'diagnostic_health_services_included',
            span: 6,
          },

          {
            name: 'home_nursing_maximum',
            span: 6,
          },

          {
            name: 'hospital_room_type',
            span: 6,
          },

          {
            name: 'medical_supplies_included',
            span: 6,
          },

          {
            name: 'vision_care_included',
            span: 6,
          },

          {
            name: 'vision_care_reimbursement_percent',
            span: 6,
          },

          {
            name: 'eye_exam_included',
            span: 6,
          },

          {
            name: 'eye_exam_glasses_and_contacts_combined_maximum',
            span: 6,
          },

          {
            name: 'glasses_and_contacts_maximum',
            span: 6,
          },

          {
            name: 'glasses_and_contacts_interval_months',
            span: 6,
          },

          {
            name: 'laser_surgery_included',
            span: 6,
          },

          {
            name: 'orthopaedic_shoes_and_orthotics_combined_maximum',
            span: 6,
          },

          {
            name: 'orthopaedic_shoes_and_orthotics_combined_interval_months',
            span: 6,
          },

          {
            name: 'orthopaedic_shoes_maximum',
            span: 6,
          },

          {
            name: 'orthopaedic_shoes_interval_months',
            span: 6,
          },

          {
            name: 'orthotics_maximum',
            span: 6,
          },

          {
            name: 'orthotics_interval_months',
            span: 6,
          },

          {
            name: 'hearing_aids_included',
            span: 6,
          },

          {
            name: 'speech_aids_included',
            span: 6,
          },

          {
            name: 'prescribed_leg_and_knee_brace_maximum',
            span: 6,
          },

          {
            name: 'prescribed_leg_and_knee_brace_interval_months',
            span: 6,
          },

          {
            name: 'accidental_dental_included',
            span: 6,
          },
        ],
      },
    ],
  },
};
