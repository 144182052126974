import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  BodyOne,
  Box,
  DangerButton,
  Flex,
  HeadingThree,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { MODAL_TITLE, MODAL_DESCRIPTION } from 'common/constants';
import { ModalWrapper } from 'apps/enrollment/ui-kit';

interface ConfirmApplyDefaultConfigModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmApplyDefaultConfigModal = ({
  onConfirm,
  onCancel,
}: ConfirmApplyDefaultConfigModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <ModalWrapper
      aria-labelledby={MODAL_TITLE}
      aria-describedby={MODAL_DESCRIPTION}
      onClose={onCancel}
    >
      <HeadingThree id={MODAL_TITLE}>
        {formatMessage({ id: 'APPLY_DEFAULT_CONFIG_TITLE' })}
      </HeadingThree>
      <Box marginTop="oneAndHalf" id={MODAL_DESCRIPTION}>
        <BodyOne marginBottom="oneAndHalf">
          {formatMessage({ id: 'APPLY_DEFAULT_CONFIG_DESCRIPTION' })}
        </BodyOne>
        <BodyOne fontWeight="bold">
          {formatMessage({ id: 'APPLY_DEFAULT_CONFIG_WARNING' })}
        </BodyOne>
      </Box>
      <Flex
        justifyContent="space-between"
        marginTop="oneAndHalf"
        flexDirection={{
          _: 'column',
          tablet: 'row',
        }}
      >
        <PrimaryButton onClick={onCancel}>
          {formatMessage({ id: 'CANCEL' })}
        </PrimaryButton>
        <DangerButton onClick={onConfirm}>
          {formatMessage({ id: 'CONFIRM' })}
        </DangerButton>
      </Flex>
    </ModalWrapper>
  );
};
