import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedHTMLMessage } from '@leagueplatform/locales';
import { Modal, Backdrop, ClickEater } from '@leagueplatform/ui-kit';

import { Button } from 'common/components/button/button.view';

export const BlockToggleNodeModal = ({ onConfirm, onCancel, isConfirming }) => {
  const { formatMessage } = useIntl();
  return (
    isConfirming && (
      <Backdrop onClose={onCancel}>
        <Modal.Container>
          <ClickEater>
            <Modal.Header>
              <Modal.HeaderTitle>
                {formatMessage({ id: 'BLOCK_TOGGLE_NODE_MODAL_HEADER' })}
              </Modal.HeaderTitle>
            </Modal.Header>
            <Modal.Body>
              <FormattedHTMLMessage id="BLOCK_TOGGLE_NODE_MODAL_BODY" />
            </Modal.Body>
            <Modal.Footer>
              <Button warning ml={2} onClick={onConfirm}>
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Footer>
          </ClickEater>
        </Modal.Container>
      </Backdrop>
    )
  );
};

BlockToggleNodeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool,
};

BlockToggleNodeModal.defaultProps = {
  isConfirming: false,
};
