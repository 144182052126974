export const alphaSortFields = ([propertyA], [propertyB]) => {
  if (propertyA < propertyB) {
    return -1;
  }
  if (propertyA > propertyB) {
    return 1;
  }
  return 0;
};

export const getObjectNotationPropertyName = (name, parentPropertyName) =>
  parentPropertyName ? `${parentPropertyName}.${name}` : name;

/**
 * @function makeSortByFieldOrder get yourself a sorting function built using the passed field order array
 * @param {[]string} fieldOrder array of field names in the order you want to display them
 * @returns {function} to pass into Array.prototype.sort()! NOTE: the array being sorted needs to be an array of pairs.
 */

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const makeSortByFieldOrder = (fieldOrder = [], parentPropertyName) => {
  return ([propertyA], [propertyB]) => {
    const fullPropertyA = getObjectNotationPropertyName(
      propertyA,
      parentPropertyName,
    );
    const fullPropertyB = getObjectNotationPropertyName(
      propertyB,
      parentPropertyName,
    );
    const indexOfA =
      fieldOrder.indexOf(fullPropertyA) > -1
        ? fieldOrder.indexOf(fullPropertyA)
        : Infinity;
    const indexOfB =
      fieldOrder.indexOf(fullPropertyB) > -1
        ? fieldOrder.indexOf(fullPropertyB)
        : Infinity;
    if (indexOfA < indexOfB) {
      return -1;
    }
    if (indexOfA > indexOfB) {
      return 1;
    }
    return 0;
  };
};
