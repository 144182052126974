import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  adminGetEmployeeSchemaConfig,
  ADMIN_GET_EMPLOYEE_SCHEMA_CONFIG_MESSAGE_TYPE,
  setGroupRequiredFields,
  RequiredField,
} from '@leagueplatform/admin-api';
import { EmployeeFormField, EmployeeField } from './employee-form-field.type';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

interface ErrorProps {
  message: string;
}

export const useFieldSettings = () => {
  const { groupId } = useParams<{ groupId: string }>();

  const { data, isLoading, isError } = useQuery(
    [ADMIN_GET_EMPLOYEE_SCHEMA_CONFIG_MESSAGE_TYPE, groupId],
    () =>
      adminGetEmployeeSchemaConfig({
        groupId,
      }),
    {
      enabled: !!groupId,
    },
  );

  const nonSystemFields = Object.keys(data || {}).reduce(
    (accum: EmployeeFormField[], key: string) => {
      // We will break logic on the BE side if we make this field a non-system field. But this field is dynamic and is only being used by a provider. So we are filtering it out here.
      // From the BE: this is crappy. We should be able to ask the vendor directly about required fields, but it's not modelled properly anywhere.
      const isRegisteredIndianAct =
        data[key].field_name === 'registered_indian_act';
      if (!data[key].system_field && !isRegisteredIndianAct)
        return [...accum, data[key]];
      return accum;
    },
    [],
  );

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (values: Array<RequiredField>) =>
      setGroupRequiredFields({ groupId, fields: values }),
    {
      onSuccess: () => {
        const queryKey = [
          ADMIN_GET_EMPLOYEE_SCHEMA_CONFIG_MESSAGE_TYPE,
          groupId,
        ];
        const cache = queryClient.getQueryData<Record<string, any>>(queryKey);

        if (cache) {
          queryClient.invalidateQueries(queryKey);
        }

        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          textId: 'SUCCESS',
        });
      },
      onError: (error: ErrorProps) => {
        const errorObj = JSON.parse(error.message);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR',
          content: errorObj?.info?.reason,
        });
      },
    },
  );

  const submitFields = (changedFields: EmployeeFormField[]) => {
    const formatNonSystemFields = changedFields.reduce(
      (accum: EmployeeField[], field: any) => {
        return [
          ...accum,
          {
            name: field.field_name,
            employee_viewable: !field.hidden,
            employer_viewable: !field.hidden,
            type: field.json_type,
            read_only: field.read_only,
          },
        ];
      },
      [],
    );
    // TODO: investigate type mismatch here
    // @ts-expect-error
    mutation.mutate(formatNonSystemFields);
  };

  return {
    groupId,
    isLoading,
    isError,
    nonSystemFields,
    mutation,
    submitFields,
  };
};
