import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { ViewDependent } from './view/view-dependent.container';
import { EditDependent } from './edit/edit-dependent.container';

export const DependentRouter = ({
  match,
  dependentsBaseUrl,
  groupId,
  userId,
}) => (
  <Switch>
    <Route
      exact
      path={`${match.url}`}
      render={props => (
        <ViewDependent
          dependentId={match.params.dependentId}
          dependentsBaseUrl={dependentsBaseUrl}
          groupId={groupId}
          userId={userId}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      )}
    />
    <Route
      exact
      path={`${match.url}/edit`}
      render={props => (
        <EditDependent
          dependentId={match.params.dependentId}
          dependentsBaseUrl={dependentsBaseUrl}
          groupId={groupId}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      )}
    />
  </Switch>
);

DependentRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    params: PropTypes.object,
  }).isRequired,
  dependentsBaseUrl: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};
