import {
  GET_WALLET_ACTIONS,
  BENEFIT_WALLET_DETAILS_CLEARED,
  PROVIDER_BENEFIT_SELECTED,
  SET_PROVIDER_WALLET_ACTIONS,
} from './benefit-wallet.types';
import { get } from 'lodash';
import { ERROR, SUCCESS } from './constants';

export const initialState = {
  benefitWalletReady: true,
  benefitWalletError: false,
  providerBenefits: null,
  selectedProviderBenefit: null,
};

const getWalletActionsStarted = state => ({
  ...state,
  providerBenefits: null,
  benefitWalletReady: false,
});

const getWalletActionsSucceeded = (state, action) => ({
  ...state,
  providerBenefits: action.payload.info.benefit_info,
  benefitWalletReady: true,
});

const modifyWalletActions = (actionsList, key, succeeded, errors = []) => {
  return actionsList.map((item, index) => {
    const itemKey = `${item.type}-${item.index}`;
    const edited = itemKey === key;
    let locked = !item.primary;
    let status = succeeded && edited ? SUCCESS : '';
    if (errors.length > 0 && errors.includes(index)) {
      locked = false;
      status = ERROR;
    }

    return {
      ...item,
      key: itemKey,
      locked,
      status,
      edited,
      newBenefitSelected: false,
    };
  });
};

const initWalletActions = actionsList => {
  return actionsList.map(item => {
    return {
      ...item,
      key: `${item.type}-${item.index}`,
      locked: !item.primary,
      status: '',
      edited: false,
      newBenefitSelected: true,
    };
  });
};

const setWalletActionsSucceeded = (state, action) => {
  const benefitActions = modifyWalletActions(
    get(action, 'payload.info.provider_wallet_action.actions'),
    get(action, 'payload.key'),
    true,
  );
  const benefitType = get(
    action,
    'payload.info.provider_wallet_action.benefit_type',
    '',
  );

  return {
    ...state,
    selectedProviderBenefit: {
      ...state.selectedProviderBenefit,
      provider_wallet_action: {
        ...state.providerBenefits[benefitType].provider_wallet_action,
        actions: benefitActions,
      },
    },
    providerBenefits: {
      ...state.providerBenefits,
      [benefitType]: {
        ...state.providerBenefits[benefitType],
        provider_wallet_action: {
          ...state.providerBenefits[benefitType].provider_wallet_action,
          actions: benefitActions,
        },
      },
    },
  };
};

const setWalletActionsErrored = (state, action) => {
  const parseErrors = action.payload.info.reason.split('actions.');
  parseErrors.shift();
  const errors = parseErrors.map(e => parseInt(e[0], 10));
  const benefitActions = modifyWalletActions(
    get(state, 'selectedProviderBenefit.provider_wallet_action.actions'),
    get(action, 'payload.key'),
    false,
    errors,
  );

  return {
    ...state,
    selectedProviderBenefit: {
      ...state.selectedProviderBenefit,
      provider_wallet_action: {
        ...state.selectedProviderBenefit.provider_wallet_action,
        actions: benefitActions,
      },
    },
  };
};

const providerBenefitSelected = (state, action) => {
  const benefitActions = initWalletActions(
    get(action, 'payload.provider_wallet_action.actions', []),
  );

  return {
    ...state,
    selectedProviderBenefit: {
      ...action.payload,
      provider_wallet_action: {
        ...action.payload.provider_wallet_action,
        actions: benefitActions,
      },
    },
  };
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const benefitWalletReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_WALLET_ACTIONS.STARTED:
      return getWalletActionsStarted(state);
    case GET_WALLET_ACTIONS.SUCCEEDED:
      return getWalletActionsSucceeded(state, action);
    case SET_PROVIDER_WALLET_ACTIONS.SUCCEEDED:
      return setWalletActionsSucceeded(state, action);
    case SET_PROVIDER_WALLET_ACTIONS.ERRORED:
      return setWalletActionsErrored(state, action);
    case PROVIDER_BENEFIT_SELECTED:
      return providerBenefitSelected(state, action);
    case BENEFIT_WALLET_DETAILS_CLEARED:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
