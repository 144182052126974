import moment from 'moment-timezone';

export const convertWithTz = (date, timeZone) => {
  // change the time of the date to always be 11.59pm of the timezone specified
  const dateTime = moment(date).format('YYYY-MM-DD 23:59:00');
  const toEmployerTz = moment.tz(dateTime, timeZone);
  return toEmployerTz.toISOString(); // convert to UTC time
};

export const getErrorMessageTranslationId = fieldName => {
  switch (fieldName) {
    case 'coverageStartDate':
      return 'INITIATE_QLE_COVERAGE_START_DATE_ERROR';
    case 'enrollmentEndDate':
      return 'INITIATE_QLE_ENROLLMENT_END_DATE_ERROR';
    case 'qleType':
      return 'INITIATE_QLE_TYPE_ERROR';
    default:
      return '';
  }
};
