import { select, takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { request } from 'common/websocket-redux';
import { getWallet, submitClaim, setClaim, getClaim } from 'common/services';
import {
  selectUserId,
  selectIsSubmitClaimVisible,
} from 'common/state/user/user.selectors';
import { upload } from 'apps/upload/upload.saga';
import {
  SUBMIT_CLAIM_VISITED,
  SUBMIT_CLAIM,
  GET_CLAIM,
} from './submit-claim.actions';
import { getUserPaymentCredentials } from 'apps/member-experience/resources/payment-credentials/payment-credentials.actions';
import { GET_WALLET } from 'apps/member-experience/pages/wallet/wallet.action-types';
import {
  selectWalletReady,
  selectSpendingAccountsFromActions,
} from 'apps/member-experience/pages/wallet/wallet.selectors';
import { selectClaim } from '../../claim-details/state/claim-details.selectors';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const walletUrl = '/member/wallet';
export function* goToWallet() {
  yield put(push(walletUrl));
}

export function* goToClaimDetail(claimId) {
  const claimDetailUrl = `/member/claims/${claimId}`;
  yield put(push(claimDetailUrl));
}

export function* initializeResources(action) {
  // Fetch wallet if it doesn't exist in the store yet
  const ready = yield select(selectWalletReady);
  if (!ready) {
    const userId = yield select(selectUserId);
    yield request(GET_WALLET, getWallet({ userId }));
  }
  // Gather spending accounts from wallet response
  // If user has no spending accounts, redirect to Wallet Landing
  const spendingAccounts = yield select(selectSpendingAccountsFromActions);
  const showSubmitClaim = yield select(selectIsSubmitClaimVisible);
  if (!showSubmitClaim || spendingAccounts.length === 0) {
    yield goToWallet();
  } else {
    // If SUBMIT_CLAIM_VISITED has claimId in payload, it was emitted from edit-claim, not from submit-claim
    const claimId = action?.payload;
    if (claimId) {
      // Check if claim is in store - if user comes straight from Claim Detail without page reload
      const claim = yield select(selectClaim);
      // Re-fetch if claim is null
      if (claim === null) {
        yield request(GET_CLAIM, getClaim(claimId));
      }
    }
    // Trigger payment credentials resources to check if user has banking info set up to show reminder banner
    yield put(getUserPaymentCredentials());
  }
}

export function* startSubmitClaim(action) {
  const { claimId, values } = action.payload;
  const {
    requestedBenefitType,
    amountClaimed,
    description,
    files,
    claimDocuments,
    currency,
  } = values;
  // If payload has claimId, update existing claim (setClaim service); otherwise, submit new claim (submitClaim)
  try {
    yield put(SUBMIT_CLAIM.start());

    // New files must be uploaded to content server to obtain contentIds
    // Existing claim documents just need to be mapped over for contentIds
    const newContentIds = yield call(upload, files);
    if (claimId) {
      const existingContentIds =
        claimDocuments?.map(doc => doc.content_id) ?? [];
      const contentIds = [...newContentIds, ...existingContentIds];
      yield setClaim({
        claimId,
        requestedBenefitType,
        amountClaimed,
        description,
        contentIds,
        currency,
      });
      yield put(SUBMIT_CLAIM.success({ claimId }));
      yield goToClaimDetail(claimId);
      yield call(toastActions.add, {
        type: TOAST_STATUS.SUCCESS,
        textId: 'UPDATE_CLAIM_SUCCESS',
      });
    } else {
      yield submitClaim({
        requestedBenefitType,
        amountClaimed,
        description,
        contentIds: newContentIds,
      });
      yield put(SUBMIT_CLAIM.success());
    }
  } catch (error) {
    yield put(SUBMIT_CLAIM.error(error));
  }
}

export function* submitClaimSaga() {
  yield takeLatest(SUBMIT_CLAIM_VISITED, initializeResources);
  yield takeLatest(SUBMIT_CLAIM.BASE, startSubmitClaim);
}
