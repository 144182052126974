export default {
  rbc_benefit: {
    name: 'rbc_benefit',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'benefit_year',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
        ],
      },
    ],
  },
};
