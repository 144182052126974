import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { selectRoot } from '../settlement/settlement.selectors';
import { SETTLED } from './payment.constants';

export const selectUI = get('apps.employer-experience.ui.claimPayment');

export const selectError = createSelector(selectUI, get('error'));

export const selectSuccess = createSelector(selectUI, get('success'));

export const isSettled = payment => payment.paymentStatus === SETTLED;

export const hasTransactionNumber = payment =>
  Boolean(payment && payment.transactionNumber);

export const canRetryPayment = payment =>
  hasTransactionNumber(payment) && !isSettled(payment);

export const selectPaymentProps = createSelector(
  [selectRoot, selectSuccess, selectError],
  ({ payment }, success, error) => ({
    ...payment,
    canRetryPayment: canRetryPayment(payment),
    success,
    error,
  }),
);
