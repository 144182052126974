import {
  REQUEST_LIST_FEATURE_FLAGS,
  REQUEST_GET_FEATURE_FLAG,
  REQUEST_SET_FEATURE_FLAG,
} from './flags.action-types';

export const initialState = {
  flags: [],
  flagDetails: null,
};

export const flagsReducer = (state = initialState, action = {}) => {
  if (!action.type) return state;
  switch (action.type) {
    case REQUEST_LIST_FEATURE_FLAGS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const { flags } = action.payload.info;
      return {
        ...state,
        flags,
      };
    case REQUEST_GET_FEATURE_FLAG.SUCCEEDED:
      return {
        ...state,
        flagDetails: action.payload.info.flag,
      };
    case REQUEST_SET_FEATURE_FLAG.SUCCEEDED:
      return {
        ...state,
        flagDetails: action.meta?.flag,
      };
    default:
      return state;
  }
};
