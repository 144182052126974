import {
  SET_BENEFIT_CLASS_TYPEFORM_URLS,
  RESET_INTEGRATION_FORM_UI,
} from './employer-integrations.types';

export const initialState = {
  isSaving: false,
  error: null,
};

export const applyInitialState = () => ({
  ...initialState,
});

export const applySavingStarted = state => ({
  ...state,
  isSaving: true,
  error: null,
});

export const applySavingSucceeded = state => ({
  ...state,
  isSaving: false,
  error: false,
});

export const applySavingErrored = (state, { payload }) => ({
  ...state,
  isSaving: false,
  error: payload.info.reason,
});

export const editEmployerIntegrationsUIReducer = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = initialState,
  action,
) => {
  if (!action) return state;
  switch (action.type) {
    case RESET_INTEGRATION_FORM_UI:
      return applyInitialState();
    case SET_BENEFIT_CLASS_TYPEFORM_URLS.STARTED:
      return applySavingStarted(state);
    case SET_BENEFIT_CLASS_TYPEFORM_URLS.SUCCEEDED:
      return applySavingSucceeded(state);
    case SET_BENEFIT_CLASS_TYPEFORM_URLS.ERRORED:
      return applySavingErrored(state, action);
    default:
      return state;
  }
};
