import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const resetUserEnrollmentPeriod = ({
  userId,
  groupId,
  planId,
  enrollmentType,
  enrollmentEndDate,
}) =>
  call(websocketFetch, 'reset_user_enrollment_period', {
    user_id: userId,
    group_id: groupId,
    plan_id: planId,
    enrollment_type: enrollmentType,
    enrollment_end_date: enrollmentEndDate,
  });
