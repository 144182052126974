import { APP_CONFIG } from 'app-config';
import React, { useEffect } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { Auth0ClientWrapperInstance } from './auth0/auth0.clientwrapper';

/*
This view loads for both sign-in and sign-out, calling Auth0ClientWrapperInstance.logout() for both routes.
Reasoning: if a user navigated specifically to sign-in, they're expecting to be unauthenticated.
*/
export const AuthView = () => {
  useEffect(() => {
    Auth0ClientWrapperInstance.logout({
      returnTo: APP_CONFIG.REACT_APP_WR_URL,
    });
  }, []);
  return <LoadingIndicator />;
};
