import {
  GET_DEPENDENT_ADD_QLE_REASONS,
  GET_DEPENDENT_RELATIONSHIP_TYPES,
  SET_DEPENDENT,
  GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
} from './employee-dependents.action-types';

const initialState = {
  dependentTerminationTypeOptions: [],
  dependentAddQLEReasons: [],
  relationshipTypes: [],
  errors: null,
  loading: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const dependentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPENDENT_TERMINATION_TYPE_OPTIONS.SUCCEEDED:
      return {
        ...state,
        dependentTerminationTypeOptions: action.payload.info.reason_options,
      };
    case GET_DEPENDENT_ADD_QLE_REASONS.SUCCEEDED:
      return {
        ...state,
        dependentAddQLEReasons: action.payload.info.reason_options,
      };
    case GET_DEPENDENT_RELATIONSHIP_TYPES.SUCCEEDED:
      return {
        ...state,
        relationshipTypes: action.payload.info.relationship_types,
      };
    case SET_DEPENDENT.STARTED:
      return { ...state, loading: true };
    case SET_DEPENDENT.ERRORED:
      return {
        ...state,
        errors: action?.payload?.info?.message?.reason,
        loading: false,
      };
    case SET_DEPENDENT.SUCCEEDED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
