import React from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import { Box, colour, media } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { isIE } from '../../helpers';
import { Carousel } from '../auto-play-carousel.view';
import { CarouselCard } from '../carousel-card.view';

// Static assets for carousel cards, French and English
import waveTopBorder from './assets/wave-top.svg';
import waveSideBorder from './assets/wave-side.svg';
import allInOneAppEN from './assets/all_in_one_app_en.png';
import journeyEN from './assets/journey_en.png';
import digitalWalletEN from './assets/digital_wallet_en.png';
import lifemarketEN from './assets/lifemarket_en.png';
import liveChatEN from './assets/live_chat_en.png';

const SideBar = styled(Flex)`
  position: fixed;
  width: 30%;
  height: 100%;
  color: ${colour('purpleDarkest')};
  border-style: solid;
  border-width: 0 0 0 40px;
  border-image: url(${waveSideBorder});
  border-image-slice: 40;

  ${isIE &&
  css`
    ${media.belowLap`
        display: none;
      `};
  `};

  ${media.belowLap`
    flex-flow: column;
    position: relative;
    width: 100%;
    border-style: solid;
    border-width: 40px 0 0 0;
    border-image: url(${waveTopBorder});
    border-image-slice: 40;
  `};
`;

const Body = styled(Box)`
  background-color: ${colour('functional.blueLight')};
  width: 100%;
  height: 100%;
`;

export const InviteAccessSidebar = () => {
  const { formatMessage } = useIntl();
  return (
    <SideBar>
      <Body p={['24px', '24px', '24px', 0]}>
        <Carousel>
          <CarouselCard
            imageUrl={allInOneAppEN}
            heading={formatMessage({ id: 'CAROUSEL_ALL_IN_ONE_HEADING' })}
            text={formatMessage({ id: 'CAROUSEL_ALL_IN_ONE_DESCRIPTION' })}
            key="health"
          />
          <CarouselCard
            imageUrl={journeyEN}
            heading={formatMessage({ id: 'CAROUSEL_JOURNEY_HEADING' })}
            text={formatMessage({ id: 'CAROUSEL_JOURNEY_DESCRIPTION' })}
            key="chat"
          />
          <CarouselCard
            imageUrl={digitalWalletEN}
            heading={formatMessage({ id: 'CAROUSEL_DIGITAL_WALLET_HEADING' })}
            text={formatMessage({ id: 'CAROUSEL_DIGITAL_WALLET_DESCRIPTION' })}
            key="marketplace"
          />
          <CarouselCard
            imageUrl={liveChatEN}
            heading={formatMessage({ id: 'CAROUSEL_CHAT_HEADING' })}
            text={formatMessage({ id: 'CAROUSEL_CHAT_DESCRIPTION' })}
            key="wallet"
          />
          <CarouselCard
            imageUrl={lifemarketEN}
            heading={formatMessage({ id: 'CAROUSEL_LIFEMARKET_HEADING' })}
            text={formatMessage({ id: 'CAROUSEL_LIFEMARKET_DESCRIPTION' })}
            key="wallet"
          />
        </Carousel>
      </Body>
    </SideBar>
  );
};
