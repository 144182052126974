export default {
  employeeUSVision: {
    name: 'employeeUSVision',
    groups: [
      {
        title: 'Member Policy Details',
        fields: [
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Employer Policy Details',
        fields: [
          {
            name: 'parent.benefit_start_date',
            span: 6,
          },
          {
            name: 'parent.benefit_end_date',
            span: 6,
          },
          {
            name: 'parent.policy_id',
            span: 6,
          },
          {
            name: 'parent.documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'parent.prevent_default_allocation',
            span: 4,
          },
          {
            name: 'parent.opt_out',
            span: 4,
          },
          {
            name: 'parent.benefit_waiting_period_months',
            span: 4,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'parent.benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'parent.auto_renew',
            span: 6,
          },
        ],
      },
      {
        title: 'Description of Coverage',
        fields: [
          {
            name: 'parent.benefit_year',
            span: 6,
          },
          {
            name: 'parent.max_amount_basic',
            span: 6,
          },
          {
            name: 'parent.max_amount_major',
            span: 6,
          },
          {
            name: 'parent.basic_included',
            span: 6,
          },
          {
            name: 'parent.basic_reimbursement_percent',
            span: 6,
          },
          {
            name: 'parent.recall_service_frequency',
            span: 6,
          },
          {
            name: 'parent.recall_service_interval_months',
            span: 6,
          },
          {
            name: 'parent.major_restorative_included',
            span: 6,
          },
          {
            name: 'parent.dental_accident_reimbursement_percent',
            span: 6,
          },
          {
            name: 'parent.reimbursement_percent',
            span: 6,
          },
          {
            name: 'parent.eligibility',
            span: 6,
          },
          {
            name: 'parent.lifetime_maximum',
            span: 6,
          },
          {
            name: 'parent.in_network_eye_exam',
            span: 6,
          },

          {
            name: 'parent.in_network_eye_exam_interval',
            span: 6,
          },

          {
            name: 'parent.in_network_single_vision_lenses',
            span: 6,
          },

          {
            name: 'parent.in_network_lined_bifocal_lenses',
            span: 6,
          },

          {
            name: 'parent.in_network_lined_trifocal_lenses',
            span: 6,
          },

          {
            name: 'parent.in_network_lenticular_lenses',
            span: 6,
          },

          {
            name: 'parent.in_network_lenses_interval',
            span: 6,
          },

          {
            name: 'parent.in_network_frames',
            span: 6,
          },

          {
            name: 'parent.in_network_frames_interval',
            span: 6,
          },

          {
            name: 'parent.in_network_elective_contact_lenses',
            span: 6,
          },

          {
            name: 'parent.in_network_medically_necessary_contact_lenses',
            span: 6,
          },

          {
            name: 'parent.in_network_materials_copay',
            span: 6,
          },

          {
            name: 'parent.in_network_laser_eye_surgery',
            span: 6,
          },

          {
            name: 'parent.out_of_network_eye_exam',
            span: 6,
          },

          {
            name: 'parent.out_of_network_eye_exam_interval',
            span: 6,
          },

          {
            name: 'parent.out_of_network_single_vision_lenses',
            span: 6,
          },

          {
            name: 'parent.out_of_network_lined_bifocal_lenses',
            span: 6,
          },

          {
            name: 'parent.out_of_network_lined_trifocal_lenses',
            span: 6,
          },

          {
            name: 'parent.out_of_network_lenticular_lenses',
            span: 6,
          },

          {
            name: 'parent.out_of_network_lenses_interval',
            span: 6,
          },

          {
            name: 'parent.out_of_network_frames',
            span: 6,
          },

          {
            name: 'parent.out_of_network_frames_interval',
            span: 6,
          },

          {
            name: 'parent.out_of_network_elective_contact_lenses',
            span: 6,
          },

          {
            name: 'parent.out_of_network_medically_necessary_contact_lenses',
            span: 6,
          },

          {
            name: 'parent.out_of_network_materials_copay',
            span: 6,
          },

          {
            name: 'parent.out_of_network_laser_eye_surgery',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'user_id',
            span: 6,
          },
          {
            name: 'id',
            span: 6,
          },
          {
            name: 'parent.id',
            span: 6,
          },
          {
            name: 'parent.benefit_vendor_id',
            span: 6,
          },
          {
            name: 'parent.benefit_type',
            span: 6,
          },
          {
            name: 'parent.benefit_product_type',
            span: 6,
          },
          {
            name: 'parent.benefit_status',
            span: 6,
          },
          {
            name: 'grace_period_start_date',
            span: 6,
          },
          {
            name: 'inactive_date',
            span: 6,
          },
          {
            name: 'reinstated_date',
            span: 6,
          },
          {
            name: 'parent.provider_type',
            span: 6,
          },
          {
            name: 'parent.provider_id',
            span: 6,
          },
          {
            name: 'parent.renewed_from',
            span: 6,
          },
          {
            name: 'parent.plan_id',
            span: 6,
          },
          {
            name: 'dependent_coverage',
            span: 6,
          },
          {
            name: 'parent.dependent_classes',
            span: 12,
          },
        ],
      },
    ],
  },
};
