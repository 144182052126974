import {
  GET_USER_AUTH_METHOD,
  GET_USER_DEPENDENTS,
  UPLOAD_PROFILE_PHOTO,
  RESET_PROFILE_PHOTO,
  REQUEST_PHONE_NUMBER_CHANGE,
  CONFIRM_PHONE_NUMBER_CHANGE,
  REQUEST_CHANGE_PASSWORD,
} from './profile-page.actions';

export const initialState = {
  isLoading: true,
  hasError: false,
  error: {},
  authMethod: '',
  dependents: [],
  newProfilePhotoId: '',
};

const applyFetching = state => ({
  ...state,
  isLoading: true,
});

const applyError = (state, action) => ({
  ...state,
  isLoading: false,
  hasError: true,
  error: action?.payload?.info,
});

const applyUserAuthMethod = (state, action) => {
  return {
    ...state,
    isLoading: false,
    authMethod: action?.payload?.info?.auth_method,
  };
};

const applyUserDependents = (state, action) => {
  return {
    ...state,
    isLoading: false,
    dependents: action?.payload?.info?.dependents,
  };
};

const applyProfilePhoto = (state, action) => {
  return {
    ...state,
    isLoading: false,
    newProfilePhotoId: action?.payload?.referenceId,
  };
};

const resetProfilePhoto = state => {
  return {
    ...state,
    isLoading: false,
    newProfilePhotoId: '',
  };
};

export const userProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_AUTH_METHOD.STARTED:
      return applyFetching(state);
    case GET_USER_AUTH_METHOD.SUCCEEDED:
      return applyUserAuthMethod(state, action);
    case GET_USER_AUTH_METHOD.ERRORED:
      return applyError(state, action);
    case GET_USER_DEPENDENTS.STARTED:
      return applyFetching(state);
    case GET_USER_DEPENDENTS.SUCCEEDED:
      return applyUserDependents(state, action);
    case GET_USER_DEPENDENTS.ERRORED:
      return applyError(state, action);
    case UPLOAD_PROFILE_PHOTO.SUCCEEDED:
      return applyProfilePhoto(state, action);
    case RESET_PROFILE_PHOTO.BASE:
      return resetProfilePhoto(state);
    case REQUEST_PHONE_NUMBER_CHANGE.ERRORED:
      return applyError(state, action);
    case CONFIRM_PHONE_NUMBER_CHANGE.ERRORED:
      return applyError(state, action);
    case REQUEST_CHANGE_PASSWORD.ERRORED:
      return applyError(state, action);
    default:
      return state;
  }
};
