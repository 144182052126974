import { createSelector } from 'reselect';
import { LOCATION_TYPES } from './user.constants';

export const selectUser = state => state?.user;

export const selectIsFetching = createSelector(
  selectUser,
  user => user?.isFetching,
);

export const selectError = createSelector(selectUser, user => user?.error);

export const selectUserId = createSelector(selectUser, user => user?.userId);

export const selectIsUserLoaded = createSelector(
  selectUserId,
  userId => !!userId,
);

export const selectPreferredFirstName = createSelector(
  selectUser,
  user =>
    user?.userProfile?.preferredFirstName ??
    user?.userProfile?.firstName ??
    user?.email ??
    '',
);

export const selectLastName = createSelector(
  selectUser,
  user => user?.userProfile?.lastName ?? '',
);

export const selectUserInitials = createSelector(
  selectPreferredFirstName,
  selectLastName,
  (firstName, lastName) => `${firstName.charAt(0)} ${lastName.charAt(0)}`,
);

export const selectFullName = createSelector(
  selectPreferredFirstName,
  selectLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);

export const selectUserLocations = createSelector(
  selectUser,
  user => user?.userProfile?.locations ?? [],
);

export const selectUserHomeAddress = createSelector(
  selectUserLocations,
  locations =>
    locations.find(location => location.type === LOCATION_TYPES.HOME) ?? {},
);

export const selectIsFTUXComplete = createSelector(
  selectUser,
  user => user?.ftuxComplete,
);

export const selectUserTenantId = createSelector(
  selectUser,
  user => user?.tenantId,
);

export const selectMemberPostFtux = createSelector(
  selectUser,
  user => user?.memberPostFtux,
);

// For a dependent, this value will be an array
// For a primary user, it will be null
export const selectIsDependent = createSelector(
  selectUser,
  user => !!user?.dependentsPrimaryUserIds,
);

export const selectIsDependentProfileComplete = createSelector(
  selectMemberPostFtux,
  memberPostFtux => memberPostFtux?.dependentProfile,
);

// user profile selectors

export const selectUserProfile = createSelector(
  selectUser,
  user => user?.userProfile,
);
export const selectUserProfileAvatar = createSelector(
  selectUserProfile,
  profile => profile?.avatar,
);

export const selectNotificationPreferences = createSelector(
  selectUserProfile,
  profile => profile?.notificationPreferences,
);

export const selectUserCurrency = createSelector(
  selectUserProfile,
  profile => profile?.currency,
);

export const selectUserHomeProvince = createSelector(
  selectUserProfile,
  profile =>
    profile?.locations?.find(location => location.type === LOCATION_TYPES.HOME)
      ?.province ?? '',
);

export const selectUserHomeCountry = createSelector(
  selectUserProfile,
  profile =>
    profile?.locations?.find(location => location.type === LOCATION_TYPES.HOME)
      ?.country ?? '',
);

export const selectMemberTermsAndConditions = createSelector(
  selectUserProfile,
  profile => profile?.memberTermsAndConditions,
);

export const selectIsMarketingOptedIn = createSelector(
  selectUserProfile,
  profile => profile?.optedIntoMarketingCommunications,
);

// user config selectors

export const selectUserConfig = createSelector(
  selectUser,
  user => user?.user_config,
);

export const selectIsDependentsMenuItemVisible = createSelector(
  selectUserConfig,
  userConfig => userConfig?.show_dependents_menu_item,
);

// This is being used in a League Family test
export const selectShowDependentsMenuItemIndicator = createSelector(
  selectUserConfig,
  userConfig => userConfig?.show_dependents_menu_item_indicator,
);

export const selectIsBenefitSummaryVisible = createSelector(
  selectUserConfig,
  userConfig => userConfig?.show_benefit_summary,
);

// Note: making this "show" instead of "hide" for consistency with the props
export const selectIsBankingInfoVisible = createSelector(
  selectUserConfig,
  userConfig => !userConfig?.hide_banking_info,
);

// Note: making this "show" instead of "hide" for consistency with the props
export const selectIsSubmitClaimVisible = createSelector(
  selectUserConfig,
  userConfig => !userConfig?.hide_submit_claims,
);

export const selectMustAcceptTerms = createSelector(
  selectUserConfig,
  userConfig => userConfig?.must_accept_terms,
);

export const selectShowOnboarding = createSelector(
  selectUserConfig,
  userConfig => userConfig?.show_onboarding,
);
