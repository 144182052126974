import React from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import EmployeeTabs from './components/EmployeeTabsContainer';

export const EmployeeRouter = withRouter(({ match }) => (
  <Switch>
    <Route
      path={`${match.path}/:panel`}
      render={props => (
        <EmployeeTabs
          employeeId={match.params.employeeId}
          employeeBaseUrl={match.url}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      )}
    />
    <Route component={() => <Redirect to={`${match.url}/profile`} />} />
  </Switch>
));
