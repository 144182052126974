import { takeLatest, put, call } from 'redux-saga/effects';
import {
  DELETE_DEDUCTION_CODES,
  GET_DEDUCTION_CODES,
  SET_DEDUCTION_CODES,
  IMPORT_DEDUCTION_CODES,
  EXPORT_DEDUCTION_CODES,
  LOAD_DEDUCTION_CODE_EDITOR,
} from './deduction-codes.types';
import { getDeductionCodes } from 'common/services/payroll/get-deduction-codes.service';
import { deleteDeductionCodes } from 'common/services/payroll/delete-deduction-codes.service';
import { setDeductionCodes } from 'common/services/payroll/set-deduction-codes.service';
import { importDeductionCodes } from 'common/services/payroll/import-deduction-codes.service';
import { exportDeductionCodes } from 'common/services/payroll/export-deduction-codes.service';
import { getPayrollSchedules } from 'common/services';
import { upload } from 'apps/upload/upload.saga';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getDeductionCodesRequest({ payload }) {
  try {
    yield put(GET_DEDUCTION_CODES.start());
    const response = yield getDeductionCodes(payload);
    yield put(GET_DEDUCTION_CODES.success(response));
  } catch (e) {
    yield put(GET_DEDUCTION_CODES.error(e.info.reason));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* deleteDeductionCodesRequest({ payload }) {
  try {
    yield put(DELETE_DEDUCTION_CODES.start());
    const response = yield deleteDeductionCodes(payload);
    yield put(DELETE_DEDUCTION_CODES.success(response));
  } catch (e) {
    yield put(DELETE_DEDUCTION_CODES.error(e.info.reason));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* reportErrorsFromDeductionCodes(deductionCodes) {
  if (!deductionCodes) return;
  let message = '';
  // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
  for (const [, record] of deductionCodes.entries()) {
    if (record.error) {
      message += `${record.error.replace(
        'failed to set deduction code: ',
        '',
      )}; `;
    }
  }
  if (message) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: message,
    });
  }
}

const removeDeductionCodesWithError = deductionCodes =>
  deductionCodes
    ?.filter(deductionCode => !deductionCode.error)
    .map(deductionCode => deductionCode.deduction_code) ?? [];

export function* setDeductionCodesRequest({ payload }) {
  try {
    yield put(SET_DEDUCTION_CODES.start());
    const response = yield setDeductionCodes(payload);
    const deductionCodes = response?.info?.deduction_codes;
    yield call(reportErrorsFromDeductionCodes, deductionCodes);
    yield put(
      SET_DEDUCTION_CODES.success({
        deductionCodes: removeDeductionCodesWithError(deductionCodes),
      }),
    );
  } catch (e) {
    const errorMessage = e?.info?.reason ?? e.message;
    yield put(SET_DEDUCTION_CODES.error(errorMessage));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: errorMessage,
    });
  }
}

export function* importDeductionCodesRequest({ payload }) {
  try {
    const { groupId, file } = payload;
    yield put(IMPORT_DEDUCTION_CODES.start());

    // The upload saga takes an array of files and returns an array of contentIds
    const contentIds = yield call(upload, [file]);
    const contentId = contentIds?.[0];
    const response = yield importDeductionCodes({
      group_id: groupId,
      content_id: contentId,
    });
    const deductionCodes = response?.info?.deduction_codes;
    yield call(reportErrorsFromDeductionCodes, deductionCodes);
    yield put(
      IMPORT_DEDUCTION_CODES.success({
        deductionCodes: removeDeductionCodesWithError(deductionCodes),
      }),
    );
  } catch (e) {
    const errorMessage = e?.info?.reason ?? e.message;
    yield put(IMPORT_DEDUCTION_CODES.error(errorMessage));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: errorMessage,
    });
  }
}

export function* exportDeductionCodesRequest({ payload }) {
  try {
    yield put(EXPORT_DEDUCTION_CODES.start());
    const response = yield exportDeductionCodes(payload);
    window.open(getContentUrl(response.info.content_id), '_blank');
    yield put(EXPORT_DEDUCTION_CODES.success(response));
  } catch (e) {
    const errorMessage = e?.info?.reason ?? e.message;
    yield put(EXPORT_DEDUCTION_CODES.error(errorMessage));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: errorMessage,
    });
  }
}

export function* loadDeductionCodeEditorRequest({ payload }) {
  try {
    const { groupId } = payload;
    yield put(LOAD_DEDUCTION_CODE_EDITOR.start());
    const deductionCodesResponse = yield getDeductionCodes({
      group_id: groupId,
    });
    const payrollSchedulesResponse = yield getPayrollSchedules({
      group_id: groupId,
    });
    yield put(
      LOAD_DEDUCTION_CODE_EDITOR.success({
        deductionCodesResponse,
        payrollSchedulesResponse,
      }),
    );
  } catch (e) {
    yield put(LOAD_DEDUCTION_CODE_EDITOR.error(e?.info?.reason));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e?.info?.reason,
    });
  }
}

export function* deductionCodesSaga() {
  yield takeLatest(GET_DEDUCTION_CODES.BASE, getDeductionCodesRequest);
  yield takeLatest(DELETE_DEDUCTION_CODES.BASE, deleteDeductionCodesRequest);
  yield takeLatest(SET_DEDUCTION_CODES.BASE, setDeductionCodesRequest);
  yield takeLatest(IMPORT_DEDUCTION_CODES.BASE, importDeductionCodesRequest);
  yield takeLatest(EXPORT_DEDUCTION_CODES.BASE, exportDeductionCodesRequest);
  yield takeLatest(
    LOAD_DEDUCTION_CODE_EDITOR.BASE,
    loadDeductionCodeEditorRequest,
  );
}
