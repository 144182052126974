import { keyBy, map } from 'lodash';
import { get } from 'lodash/fp';
import camelize from 'camelize';
import { createSelector } from 'reselect';
import { transformEmployees } from '../../employer-experience/pages/EmployerDetails/Employees/selectors';
import { selectEmployerId } from '../../employer-experience/pages/EmployerDetails/selectors';
import { selectApp, selectGroup } from '../app.selectors';

export const selectSearch = createSelector(selectApp, get('employeeSearch'));

export const selectQuery = createSelector(selectSearch, get('query'));

export const selectIncludeSuspended = createSelector(
  selectSearch,
  get('include_suspended'),
);

export const selectOffset = createSelector(selectSearch, get('offset'));

export const selectLoading = createSelector(
  selectApp,
  get('pages.employees.loading'),
);

export const selectHasMoreResults = createSelector(
  selectSearch,
  get('hasMoreResults'),
);

export const selectHasEmptyResults = createSelector(
  selectSearch,
  page => page.total < 1,
);

export const selectUsers = createSelector(selectSearch, get('users'));

export const selectGroupBenefitClasses = createSelector(selectGroup, group => {
  const classes = group.benefit_classes;
  return keyBy(classes, 'id');
});

export const selectEmployees = createSelector(
  [selectUsers, selectGroupBenefitClasses, selectEmployerId],
  (users, benefitClasses, employerId) => {
    const camelizedUsers = map(users, camelize);
    const employees = transformEmployees(
      camelizedUsers,
      benefitClasses,
      employerId,
    );
    return employees;
  },
);

/* UI */

export const selectUI = createSelector(selectApp, get('ui.employeeSearch'));

export const selectReady = createSelector(selectUI, get('ready'));
