import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from '@leagueplatform/locales';
import {
  Select,
  StyledDayPickerInput,
  QuestionTooltip,
  Modal,
  ModalButtons,
  Required,
  Segment,
  WhiteSpacePreWrap,
} from 'apps/employer-experience/ui-kit';
import {
  Flex,
  Box,
  HeadingTwo,
  Label,
  Caption,
  ErrorMessage,
  PrimaryButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { DATEPICKER_DATE_FORMAT } from '@leagueplatform/web-common';

const DayPickerWrapper = styled.div`
  margin-top: 0.5rem;
  max-width: 22rem;

  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }

  .DayPickerInput input {
    padding: 0.875rem;
  }
`;

export const InitiateQLEPresenter = ({
  onClose,
  values,
  errors,
  handleSubmit,
  setFieldValue,
  employeeName,
}) => {
  const { formatMessage } = useIntl();
  const options = useMemo(
    () => [
      {
        text: formatMessage({
          id: 'MARRIAGE',
        }),
        value: 'marriage',
      },
      {
        text: formatMessage({
          id: 'BECAME_COMMON_LAW',
        }),
        value: 'became-common-law',
      },
      {
        text: formatMessage({
          id: 'ENDING_COMMON_LAW_RELATIONSHIP',
        }),
        value: 'ended-common-law-relationship',
      },
      {
        text: formatMessage({
          id: 'DIVORCE',
        }),
        value: 'divorce',
      },
      {
        text: formatMessage({
          id: 'SEPARATION',
        }),
        value: 'separation',
      },
      {
        text: formatMessage({
          id: 'BIRTH',
        }),
        value: 'birth',
      },
      {
        text: formatMessage({
          id: 'ADOPTION',
        }),
        value: 'adoption',
      },
      {
        text: formatMessage({
          id: 'DEATH',
        }),
        value: 'death',
      },
      {
        text: formatMessage({
          id: 'DEPENDENT_GAIN_COVERAGE',
        }),
        value: 'date-of-gain-of-coverage',
      },
      {
        text: formatMessage({
          id: 'DEPENDENT_LOST_COVERAGE',
        }),
        value: 'dependent_lost_coverage',
      },
      {
        text: formatMessage({
          id: 'CHANGE_IN_LOCATION',
        }),
        value: 'change-in-location',
      },
      {
        text: formatMessage({
          id: 'OTHER',
        }),
        value: 'other',
      },
    ],
    [formatMessage],
  );
  return (
    <Modal onClose={onClose}>
      <Box overflowY="scroll" maxHeight="100%">
        <HeadingTwo>
          {formatMessage({ id: 'INITIATE_QLE_REENROLLMENT' })}
        </HeadingTwo>

        <Box marginTop="two" marginBottom="one">
          <WhiteSpacePreWrap>
            {formatMessage(
              { id: 'INITIATE_QLE_CONTENT' },
              // eslint-disable-next-line react/no-unstable-nested-components -- FIXME: automatically added for existing issue
              { employeeName, strong: msg => <strong>{msg}</strong> },
            )}
          </WhiteSpacePreWrap>
          {/* QLE TYPE */}
          <Segment>
            <Box marginBottom="one">
              {formatMessage({ id: 'INITIATE_QLE_TYPE_QUESTION' })}
            </Box>
            <Flex>
              <Label htmlFor="qle_type">
                {formatMessage({ id: 'INITIATE_QLE_TYPE_LABEL' })}
              </Label>
              <Required />
            </Flex>
            <Box marginY="one">
              <Select
                name="qleType"
                // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                selectedValue={values.qleType}
                options={options}
                onChange={({ altered, value }) =>
                  altered && setFieldValue('qleType', value)
                }
                noSelectionLabel={formatMessage({
                  id: 'INITIATE_QLE_TYPE_PLACEHOLDER',
                })}
                // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                hasError={errors.qleType}
              />
              {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
              {errors.qleType && (
                <ErrorMessage>
                  {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
                  {formatMessage({ id: errors.qleType })}
                </ErrorMessage>
              )}
            </Box>
          </Segment>
          <Segment>
            <Box marginBottom="one">
              <Box marginBottom="one">
                {formatMessage({
                  id: 'INITIATE_QLE_COVERAGE_START_DATE_QUESTION',
                })}
                <Caption>
                  {formatMessage({
                    id: 'INITIATE_QLE_COVERAGE_START_DATE_NOTE',
                  })}
                </Caption>
              </Box>
              <Flex>
                <Label htmlFor="coverageStartDate">
                  {formatMessage({
                    id: 'INITIATE_QLE_COVERAGE_START_DATE_LABEL',
                  })}
                </Label>
                <Required marginRight="one" />
                <QuestionTooltip>
                  {formatMessage({
                    id: 'INITIATE_QLE_COVERAGE_START_DATE_TOOLTIP',
                  })}
                </QuestionTooltip>
              </Flex>
              <Flex style={{ alignItems: 'flex-end' }}>
                <Box>
                  <DayPickerWrapper>
                    <StyledDayPickerInput
                      dayPickerProps={{
                        enableOutsideDays: false,
                      }}
                      placeholder={DATEPICKER_DATE_FORMAT}
                      format={DATEPICKER_DATE_FORMAT}
                      value={
                        // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                        values.coverageStartDate
                          ? // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                            values.coverageStartDate.format(
                              DATEPICKER_DATE_FORMAT,
                            )
                          : ''
                      }
                      name="coverageStartDate"
                      onDayChange={date =>
                        setFieldValue('coverageStartDate', date)
                      }
                      error={
                        // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                        errors.coverageStartDate &&
                        // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                        formatMessage({ id: errors.coverageStartDate })
                      }
                    />
                  </DayPickerWrapper>
                </Box>
              </Flex>
              {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
              {errors.coverageStartDate && (
                <ErrorMessage>
                  {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
                  {formatMessage({ id: errors.coverageStartDate })}
                </ErrorMessage>
              )}
            </Box>
          </Segment>

          {/* QLE ENROLLMENT END DATE */}
          <Box marginTop="one" paddingTop="half">
            <Box marginBottom="one">
              {formatMessage({
                id: 'INITIATE_QLE_ENROLLMENT_END_DATE_QUESTION',
              })}
            </Box>
            <Flex>
              <Label htmlFor="enrollmentEndDate">
                {formatMessage({
                  id: 'INITIATE_QLE_ENROLLMENT_END_DATE_LABEL',
                })}
              </Label>
              <Required marginRight="one" />
              <QuestionTooltip>
                {formatMessage({
                  id: 'INITIATE_QLE_ENROLLMENT_END_DATE_TOOLTIP',
                })}
              </QuestionTooltip>
            </Flex>
            <Flex style={{ alignItems: 'flex-end' }}>
              <Box>
                <DayPickerWrapper
                  setFieldValue={setFieldValue}
                  date={new Date()}
                >
                  <StyledDayPickerInput
                    dayPickerProps={{
                      enableOutsideDays: false,
                      disabledDays: [
                        {
                          before: new Date(),
                        },
                      ],
                    }}
                    placeholder={DATEPICKER_DATE_FORMAT}
                    format={DATEPICKER_DATE_FORMAT}
                    value={
                      // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                      values.enrollmentEndDate
                        ? // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                          values.enrollmentEndDate.format(
                            DATEPICKER_DATE_FORMAT,
                          )
                        : ''
                    }
                    name="enrollmentEndDate"
                    onDayChange={date =>
                      setFieldValue('enrollmentEndDate', date)
                    }
                    // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
                    error={errors.enrollmentEndDate}
                  />
                </DayPickerWrapper>
              </Box>
            </Flex>
            {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
            {errors.enrollmentEndDate && (
              <ErrorMessage>
                {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
                {formatMessage({ id: errors.enrollmentEndDate })}
              </ErrorMessage>
            )}
          </Box>
        </Box>

        <ModalButtons>
          <SecondaryButton onClick={onClose} marginRight="one">
            {formatMessage({ id: 'CANCEL' })}
          </SecondaryButton>
          <PrimaryButton type="submit" onClick={handleSubmit}>
            {formatMessage({ id: 'SUBMIT' })}
          </PrimaryButton>
        </ModalButtons>
      </Box>
    </Modal>
  );
};

InitiateQLEPresenter.propTypes = {
  employeeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    qle_coverage_start_date: PropTypes.string,
    qle_enrollment_end_date: PropTypes.string,
    qle_type: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    qle_coverage_start_date: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    qle_enrollment_end_date: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    qle_type: PropTypes.string,
  }).isRequired,
};

InitiateQLEPresenter.displayName = 'InitiateQLEPresenter';
