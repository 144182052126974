import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const submitBenefitSelection = ({
  groupId,
  allocateImmediately,
  planId,
}) =>
  call(websocketFetch, 'submit_benefit_selection', {
    group_id: groupId,
    allocate_immediately: !!allocateImmediately,
    plan_id: planId,
  });
