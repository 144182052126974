/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { SERVICE_GET_QLE_REQUEST } from './qle-request.action-types';

export const initialState = {
  qualifyingLifeEventType: '',
  typeformUrl: '',
  loading: true, // Otherwise I'd have to have three states "not started", "false", "true"
  qualifyingLifeEventDate: '',
  allowRemoveDependents: false,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const qleRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_GET_QLE_REQUEST.STARTED:
      return {
        ...state,
        loading: true,
      };
    case SERVICE_GET_QLE_REQUEST.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const {
        qualifying_life_event_type,
        typeform_url,
        allow_to_add_dependents,
        allow_to_remove_dependents,
        qualifying_life_event_date,
      } = action.payload.info;

      return {
        ...state,
        loading: false,
        qualifyingLifeEventType: qualifying_life_event_type,
        typeformUrl: typeform_url,
        allowAddDependents: allow_to_add_dependents,
        qualifyingLifeEventDate: qualifying_life_event_date,
        allowRemoveDependents: allow_to_remove_dependents,
      };
    case SERVICE_GET_QLE_REQUEST.ERRORED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
