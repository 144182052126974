import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';

import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  BodyOne,
  Label,
  LabelText,
  SecondaryButton,
  PrimaryButton,
  TextInput,
  HeadingTwo,
} from '@leagueplatform/genesis-commons';

export const RenamePaySchedule = ({ initialValue, onClose, onSubmit }) => {
  const [calendarName, setCalendarName] = useState(initialValue);
  const { formatMessage } = useIntl();

  return (
    <Modal>
      <HeadingTwo>{formatMessage({ id: 'RENAME_PAY_CALENDAR' })}</HeadingTwo>
      <ModalContent>
        <BodyOne>{formatMessage({ id: 'RENAME_PAY_CALENDAR_COPY' })}</BodyOne>
        <Label marginTop="oneAndHalf">
          <LabelText>{formatMessage({ id: 'PAY_CALENDAR_NAME' })}</LabelText>
          <TextInput
            value={calendarName}
            onChange={e => setCalendarName(e.currentTarget.value)}
            placeholder={formatMessage({ id: 'PROVIDE_PAY_CALENDAR_NAME' })}
          />
        </Label>
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton onClick={() => onSubmit(calendarName)}>
          {formatMessage({ id: 'RENAME_PAY_CALENDAR' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

RenamePaySchedule.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

RenamePaySchedule.defaultProps = {};
