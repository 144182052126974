import React from 'react';
import PropTypes from 'prop-types';
import {
  LabelText,
  Box,
  Flex,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { ErrorMessage } from './error-message.view';
import { Required } from './required.view';

export const FieldWrapper = ({
  title,
  description,
  isRequired,
  name,
  children,
  ...props
}) => (
  <Box width={1} marginY="one">
    <Box>
      <Flex>
        <LabelText>{title || name}</LabelText>
        {isRequired && <Required />}
      </Flex>
      {children}
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      <ErrorMessage {...props} />
    </Box>
    {description && (
      <BodyTwoSecondary marginBottom="half">{description}</BodyTwoSecondary>
    )}
  </Box>
);

FieldWrapper.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FieldWrapper.defaultProps = {
  title: null,
  description: null,
  isRequired: false,
};
