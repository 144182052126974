import { get } from 'lodash';
import React, { useState } from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { createConfiguredStore, history } from './error.store';
import { ErrorMessage } from '../enrollment/error-message.view';
import { rootReducer } from './error.reducer';
import { errorSaga } from './error.saga';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';

const withLoader = compose(
  connect(state => ({
    isBooting: get(state, 'application.booting'),
  })),
  branch(props => props.isBooting, renderComponent(LoadingIndicator)),
);

const BootableConnectedRouter = withLoader(ConnectedRouter);

const AsyncSSOError = React.lazy(() =>
  import(/* webpackChunkName: 'app' */ './sso/sso-error.container'),
);

export const ErrorPages = () => {
  const [store] = useState(() =>
    createConfiguredStore({ reducer: rootReducer(history), saga: errorSaga }),
  );

  return (
    <Provider store={store}>
      <ObservabilityErrorBoundary fallback={<ErrorMessage />}>
        <BootableConnectedRouter history={history}>
          <Route
            exact
            path="/app/error/sso"
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            render={props => <AsyncSSOError {...props} />}
          />
        </BootableConnectedRouter>
      </ObservabilityErrorBoundary>
    </Provider>
  );
};
