import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import {
  Box,
  BodyTwo,
  Flex,
  QuietButton,
  SubtitleOne,
  SubtitleTwo,
  genesisStyled,
} from '@leagueplatform/genesis-commons';

import { useIntl } from '@leagueplatform/locales';
import { colour, spaceInPx, Icon, ICONS } from '@leagueplatform/ui-kit';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';

const StyledRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

const TitleCased = genesisStyled(SubtitleTwo)`
  text-transform: capitalize;
`;

export const Chevron = styled(Icon)`
  fill: ${colour('neutral.gray')};
  display: flex;
  margin-right: ${spaceInPx('genesis.threeQuarters')};
  opacity: 0.7;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'};

  transition-duration: 0.5s;
  transition-timing-function: ease;
`;

export const PendingFeeRow = ({ record, removeFee, billingDivisions }) => {
  const [expanded, setExpanded] = useState(false);
  const { formatMessage, formatDate, formatMoney } = useIntl();

  return (
    <>
      <StyledRow onClick={() => setExpanded(!expanded)}>
        <td>
          <Chevron
            icon={ICONS.CHEVRON_RIGHT}
            isExpanded={expanded}
            size="1rem"
          />
          {formatDate(record.date, {
            ...SHORT_DATE_FORMAT,
            timeZone: 'utc',
          })}
        </td>
        <td>{formatMessage({ id: record.fee_type.toUpperCase() })}</td>
        <td>{formatMoney({ F: record.rate })}</td>
        <td>{record.units}</td>
        <td>
          {record.term
            ? `${record.term} ${formatMessage({
                id: record.term_units.toUpperCase(),
              })}`
            : '-'}
        </td>
      </StyledRow>
      {expanded && (
        <tr>
          <td colSpan="5">
            <Flex
              backgroundColor="surface.background.secondary"
              paddingX="twoAndHalf"
              paddingY="one"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <TitleCased>
                          {formatMessage({ id: 'BILLING_PERIOD_START_DATE' })}:
                        </TitleCased>
                      </td>
                      <td>
                        <BodyTwo paddingLeft="oneAndHalf">
                          {formatDate(record.date, {
                            ...SHORT_DATE_FORMAT,
                            timeZone: 'utc',
                          })}
                        </BodyTwo>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <SubtitleTwo>
                          {formatMessage({ id: 'BILLING_DIVISION' })}:
                        </SubtitleTwo>
                      </td>
                      <td>
                        <BodyTwo paddingLeft="oneAndHalf">
                          {
                            find(billingDivisions, {
                              billingDivisionId:
                                record.billing_division_id ?? '',
                            }).name
                          }
                        </BodyTwo>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <SubtitleTwo>
                          {formatMessage({ id: 'NOTE' })}:
                        </SubtitleTwo>
                      </td>
                      <td>
                        <BodyTwo paddingLeft="oneAndHalf">
                          {record.note}
                        </BodyTwo>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
              <Box>
                <QuietButton
                  backgroundColor="transparent"
                  color="onSurface.text.critical"
                >
                  <SubtitleOne onClick={() => removeFee({ id: record.id })}>
                    {formatMessage({ id: 'REMOVE_FEE' })}
                  </SubtitleOne>
                </QuietButton>
              </Box>
            </Flex>
          </td>
        </tr>
      )}
    </>
  );
};

PendingFeeRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  record: PropTypes.shape(PropTypes.any).isRequired,
  removeFee: PropTypes.func.isRequired,
  billingDivisions: PropTypes.arrayOf(PropTypes.shape(PropTypes.any))
    .isRequired,
};

export default PendingFeeRow;
