import { BENEFIT_SET_TYPE } from './enrollment-experience-config/enrollment-experience-config.constants';
import { get } from 'lodash';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { ENROLLMENT_DESIGN_ROOT_PATH } from './enrollment-design.constants';
import {
  BenefitClass,
  BenefitPlan,
  PlanValidationWarningType,
} from './benefit.types';
import { selectBenefitClasses } from '../Benefits/selectors';
import { selectBenefitPlans } from '../Benefits/BenefitClass/Plans/selectors';
import {
  selectPlanOptionsValidation,
  selectCurrentPlanId,
  selectEmployerExperienceApp,
  selectBenefitSetEntities,
} from '../enrollment-config';
import { getHighestSeverity } from '../enrollment-config/plan-options-validation/plan-options-validation.utils';
import {
  BenefitSet,
  BenefitSetGroup,
  BenefitStep,
  EnrollmentStepItemType,
} from './enrollment-experience-config/enrollment-design-options.types';

// TODO: Define state type: https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-state-type
export const selectMatchParams = (state: any) => {
  const stepParams = get(
    matchPath(state?.router?.location?.pathname, {
      path: `${ENROLLMENT_DESIGN_ROOT_PATH}/:planId/:step`,
    }),
    'params',
  );
  return stepParams;
};

export const selectLocationPlanId = createSelector(selectMatchParams, params =>
  get(params, 'planId'),
);

export const selectPlanId = createSelector(
  selectLocationPlanId,
  selectCurrentPlanId,
  (locationPlanId, currentPlanId) => locationPlanId || currentPlanId,
);

export const selectClassId = createSelector(
  selectPlanId,
  selectBenefitPlans,
  (planId: string, benefitPlans: BenefitPlan[]) => {
    const selectedPlan = benefitPlans?.find(
      (plan: BenefitPlan) => plan.id === planId,
    );
    return selectedPlan?.benefitClassId;
  },
);

// @TODO: Add nested level validations to plan
export const selectMappedClassedAndPlans = createSelector(
  selectBenefitClasses,
  selectBenefitPlans,
  selectPlanOptionsValidation,
  (
    benefitClasses: BenefitClass[],
    benefitPlans: BenefitPlan[],
    allPlanValidationWarnings: {
      [key: string]: PlanValidationWarningType;
    },
  ) => {
    return benefitClasses?.map(benefitClass => ({
      ...benefitClass,
      plans: benefitPlans
        ?.filter(benefitPlan => benefitPlan.benefitClassId === benefitClass.id)
        .map(benefitPlan => {
          const planValidationWarnings =
            allPlanValidationWarnings?.[benefitPlan.id] ?? {};
          const validationWarnings = planValidationWarnings?.planWarnings ?? [];
          return {
            ...benefitPlan,
            validationWarnings,
            validateWarningStatus: getHighestSeverity(validationWarnings),
          };
        }),
    }));
  },
);

export const selectPlanAndClassName = createSelector(
  selectPlanId,
  selectBenefitPlans,
  selectBenefitClasses,
  (
    planId: string,
    benefitPlans: BenefitPlan[],
    benefitClasses: BenefitClass[],
  ) => {
    const selectedPlan = benefitPlans?.find(
      (plan: BenefitPlan) => plan.id === planId,
    );
    const selectedClass = benefitClasses?.find(
      (benefitClass: BenefitClass) =>
        benefitClass.id === selectedPlan?.benefitClassId,
    );

    return {
      plan: selectedPlan?.name,
      class: selectedClass?.name,
    };
  },
);

export const selectEnrollmentExperienceConfig = createSelector(
  selectEmployerExperienceApp,
  employerExperience => get(employerExperience, 'enrollmentExperienceConfig'),
);

export const selectEnrollmentDesignSteps = createSelector(
  selectEnrollmentExperienceConfig,
  enrollmentExperienceConfig =>
    // @ts-expect-error
    enrollmentExperienceConfig?.enrollmentDesignOptions?.steps ?? [],
);

export const selectAvailableBenefitIdList = createSelector(
  selectEnrollmentExperienceConfig,
  enrollmentExperienceConfig =>
    // @ts-expect-error
    enrollmentExperienceConfig?.enrollmentDesignOptions?.availableBenefitSets ??
    [],
);

export const selectAvailableBenefitSets = createSelector(
  selectBenefitSetEntities,
  selectAvailableBenefitIdList,
  (benefitSets: any, availableBenefitSets: string[]) => {
    return Object.entries(benefitSets)
      .filter(([benefitSetId]: any) =>
        availableBenefitSets.includes(benefitSetId),
      )
      .map(
        ([, benefitSet]: any) =>
          ({
            id: benefitSet.id,
            name: benefitSet.name,
            layout: null,
            content: '',
            type: BENEFIT_SET_TYPE,
          } as BenefitSet),
      );
  },
);

export const selectFlattenedEnrollmentDesignSteps = createSelector(
  selectEnrollmentDesignSteps,
  steps => {
    const result: EnrollmentStepItemType[] = [];

    steps.forEach((step: BenefitStep) => {
      const { sets: stepSets, ...stepRest } = step;
      if (stepSets) {
        stepSets.forEach((set: BenefitSet | BenefitSetGroup) => {
          if (set.type === 'group') {
            const { sets: groupSets, ...setRest } = set as BenefitSetGroup;
            if (groupSets) {
              groupSets.forEach((benefitSet: BenefitSet) => {
                result.push(benefitSet);
              });
            }
            // We no longer need to include the sets, since we're flattening the nested array
            result.push(setRest as BenefitSetGroup);
          } else {
            result.push(set as BenefitSet);
          }
        });
      }
      // We no longer need to include the sets, since we're flattening the nested array
      result.push(stepRest as BenefitStep);
    });

    return result;
  },
);

export const selectEnrollmentDesignStepsItemById = createSelector(
  (_: any, props: any) => props.id,
  selectFlattenedEnrollmentDesignSteps,
  (itemId: string, items: EnrollmentStepItemType[]) => {
    return items.find(step => step.id === itemId);
  },
);

export const selectEnrollmentDesignStepsUrls = createSelector(
  selectEnrollmentDesignSteps,
  steps => {
    return steps.map((step: BenefitStep) => ({
      id: step.id,
      url: step.url,
    }));
  },
);

export const selectSavingEnrollmentDesignOptions = createSelector(
  selectEnrollmentExperienceConfig,
  // @ts-expect-error
  enrollmentExperienceConfig => enrollmentExperienceConfig?.saving,
);

export const selectEnrollmentDesignTemplates = createSelector(
  selectEnrollmentExperienceConfig,
  enrollmentExperienceConfig =>
    // @ts-expect-error
    enrollmentExperienceConfig?.contentfulTemplates?.templates ?? [],
);

export const selectEnrollmentDesignTemplatesLoading = createSelector(
  selectEnrollmentExperienceConfig,
  enrollmentExperienceConfig =>
    // @ts-expect-error
    enrollmentExperienceConfig?.contentfulTemplates?.loading,
);
