import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { ProratedLabel } from './prorated-label.view';
import { PERIOD_TYPE_TRANSLATION_IDS } from 'common/benefits/metadata';

export const useFormattedCoverageSummaryMessages = ({
  costMessage,
  coverageMessage,
  coveragePeriodTypeId,
  isProrated,
  periodType,
  selectionMessage,
  flexDollarTermId,
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const flexDollarTerm = formatMessage({ id: flexDollarTermId });
  let formattedCoverage = null;
  let formattedSelection = null;

  // coverage
  if (coverageMessage) {
    const [coverageId, { value, amount } = {}] = coverageMessage;
    const amountValue = amount ? formatMoney(amount) : null;
    formattedCoverage = formatMessage(coverageId, {
      value,
      amount: amountValue,
    });
  }

  // selection
  if (selectionMessage) {
    const [selectionId, { amount: selectionAmount, value } = {}] =
      selectionMessage;
    const formattedSelectionMoney = formatMoney(selectionAmount);
    const selectionData = {
      ...(selectionAmount && { amount: formattedSelectionMoney }),
      value,
    };
    const selection = selectionId
      ? formatMessage(selectionId, selectionData)
      : formattedCoverage;
    formattedSelection = isProrated ? (
      <ProratedLabel flexDollarTerm={flexDollarTerm}>
        {formattedSelectionMoney}
      </ProratedLabel>
    ) : (
      selection
    );
  }

  // cost
  const [costId, { value: costValue }] = costMessage;
  const formattedCost = formatMessage(costId, {
    value: formatMoney(costValue),
  });

  /* COVERAGE PERIOD TYPE */
  // For STD/LTD, the coverage amount is shown with period type
  // Example: $1000 / Week
  const formattedCoveragePeriodType = coveragePeriodTypeId
    ? formatMessage({ id: coveragePeriodTypeId })
    : null;

  const formattedPeriodType =
    periodType === PERIOD_TYPE_TRANSLATION_IDS.Monthly
      ? formatMessage({ id: PERIOD_TYPE_TRANSLATION_IDS.month })
      : formatMessage({ id: periodType || PERIOD_TYPE_TRANSLATION_IDS.month });

  const fundedByLeftoverFlexMessage = formatMessage(
    {
      id: 'ANY_LEFTOVER_FLEX_DOLLARS_WILL_BE_ADDED_INTO_THIS_ACCOUNT',
    },
    { flexDollarTerm },
  );

  return {
    formattedCoverage,
    formattedSelection,
    formattedCost,
    formattedPeriodType,
    fundedByLeftoverFlexMessage,
    formattedCoveragePeriodType,
  };
};
