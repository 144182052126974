import React from 'react';
import { fieldProps } from './field-shape';
import { DateInput } from '@leagueplatform/ui-kit';

export const DateField = ({
  field: { name, ...field },
  form: { setFieldValue, setFieldTouched, setFieldError },
  disabled,
  readOnly,
  ...props
}) => {
  return (
    <DateInput
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...field}
      name={name}
      setFieldError={setFieldError}
      onChange={(_, { date, error }) => {
        setFieldValue(name, error || date, !error);
        if (error) setFieldError(name, error);
      }}
      onBlur={(_, { error }) => {
        setFieldTouched(name, true, !error);
        if (error) {
          setFieldError(name, error);
        }
      }}
      disabled={Boolean(disabled ?? readOnly)}
      data-testid={`date_field_${name}`}
    />
  );
};

DateField.propTypes = fieldProps;
