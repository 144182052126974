import { selectGroupCurrency } from '../selectors';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { BenefitsPickerField } from './field-overrides/benefits-picker-field.view';
import { DocumentsUploader } from './field-overrides/documents-uploader.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { LinkedSelectionSetsSetIds } from './field-overrides/linked-selection-sets-set-ids.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { BundleIdSelect } from './field-overrides/bundle-id-select.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { BenefitIdSelect } from './field-overrides/benefit-id-select.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { BenefitIdEnumSelect } from './field-overrides/benefit-id-enum-select.view';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { SetIdSelect } from './field-overrides/set-id-select.view';
import { AmountBySelection } from './field-overrides/amount-by-selection.view';
import { AmountByDependentClass } from './field-overrides/amount-by-dependent-class.view';
import { AmountByUserAttribute } from './field-overrides/amount-by-user-attribute.view';
import { AnnualPeriod } from './field-overrides/annual-period.view';
import { DEPENDENT_COVERAGE_OPTIONS } from './enrollment-config.constants';
import { InputField } from 'common/json-schema-form/fields/input-field.view';
import {
  ROOT_REF,
  MONEY_REF,
  SCHEMA_ENTITY_TYPES,
  makeRef,
  makePropertyRef,
} from 'common/json-schema-form/json-schema-form.constants';

const PLAN_OPTIONS_REF = ROOT_REF;
const BUNDLE_REF = makeRef('Bundle');
const BUNDLES_REF = makePropertyRef('bundles');
const BENEFIT_SET_REF = makeRef('BenefitSet');
const BENEFIT_SETS_REF = makePropertyRef('benefit_sets');
const BENEFIT_REF = makeRef('Benefit');
const BENEFITS_REF = makePropertyRef('benefits');
const BUNDLED_BENEFIT_REF = makeRef('BundledBenefit');
const SPENDING_ACCOUNT_CONFIG_REF = makeRef('SpendingAccountConfig');
const DOCUMENT_REF = makeRef('DocumentContentRef');
const LINKED_SELECTION_REF = makeRef('LinkedSelection');
const PREREQUISITE_REF = makeRef('Prerequisite');
const SPENDING_ACCOUNT_RESTRICTION_REF = makeRef('SpendingAccountRestrictions');
const AMOUNT_BY_SELECTION_REF = makeRef('AmountBySelection');
const USER_ATTRIBUTE_AMOUNT_REF = makeRef('UserAttributeAmount');
const DEPENDENT_CLASS_AMOUNT_REF = makeRef('DependentClassAmount');
const SELECTION_REF = makeRef('Selection');
const SELECTION_COMBINATION_REF = makeRef('SelectionCombination');
const SELECTION_RESTRICTION_REF = makeRef('SelectionRestriction');
const LADDER_RULE_REF = makeRef('LadderRule');
const CERTIFICATION_REF = makeRef('Certification');

export const schemaMetadata = {
  [PLAN_OPTIONS_REF]: {
    title: 'Plan',
    readOnlyFields: ['plan_id', 'plan_details.status'],
    fieldOrder: [
      'plan_id',
      'plan_details',
      'plan_details.plan_period.start_date',
      'plan_details.plan_period.end_date',
      'enrollment_period',
      'enrollment_period.start_date',
      'enrollment_period.end_date',
      'trigger_annual_enrollment_at_start_date',
      'payment_options',
      'coordination_of_benefits_options',
      'documents',
    ],
    fieldOverrides: {
      documents: DocumentsUploader,
      enrollment_period: AnnualPeriod,
      'plan_details.plan_period': AnnualPeriod,
    },
  },
  [BUNDLE_REF]: {
    title: 'Bundle',
    nameKey: 'name',
    fieldOrder: ['name', 'id'],
    readOnlyFields: ['id'],
  },
  [BENEFIT_SET_REF]: {
    title: 'Benefit Set',
    nameKey: 'name',
    fieldOrder: [
      'name',
      'type',
      'id',
      'benefits',
      'exactly_one',
      'at_most_one',
      'opt_out_reason_required',
      'opt_out_bonus',
      'opt_out_documents',
      'max_cost',
      'exact_cost',
    ],
    readOnlyFields: ['id'],
    fieldOverrides: {
      benefits: BenefitsPickerField,
      documents: DocumentsUploader,
      opt_out_documents: DocumentsUploader,
    },
  },
  [BENEFIT_REF]: {
    title: 'Benefit',
    nameKey: 'benefit_name',
    fieldOrder: [
      'name',
      'benefit_id',
      'default',
      'disabled_outside_enrollment_period',
    ],
    fieldOverrides: {
      documents: DocumentsUploader,
      benefit_id: InputField,
    },
    readOnlyFields: ['benefit_id'],
    hiddenFields: ['amount', 'spending_account_config'],
  },
  [BUNDLED_BENEFIT_REF]: {
    fieldOrder: ['name', 'benefit_id'],
    fieldOverrides: {
      benefit_id: BenefitIdEnumSelect,
    },
  },
  [CERTIFICATION_REF]: {
    fieldOrder: ['name', 'certification_type', 'deadline', 'documents'],
    fieldOverrides: {
      documents: DocumentsUploader,
    },
  },
  [MONEY_REF]: {
    selectors: {
      groupCurrency: selectGroupCurrency,
    },
  },
  [SELECTION_COMBINATION_REF]: {
    fieldOrder: ['name'],
    fieldOverrides: {
      bundle_id: BundleIdSelect,
      waived_set_id: SetIdSelect,
    },
  },
  [SELECTION_REF]: {
    fieldOrder: ['name', 'benefit_id', 'key', 'value'],
    fieldOverrides: {
      benefit_id: BenefitIdEnumSelect,
    },
    schemaOverrides: {
      value: { enum: DEPENDENT_COVERAGE_OPTIONS },
    },
  },
  [SELECTION_RESTRICTION_REF]: {
    fieldOrder: [
      'name',
      'key',
      'less_than_or_equal',
      'less_than_or_equal_percent',
    ],
  },
  [PREREQUISITE_REF]: {
    title: 'Prerequisite',
    fieldOrder: [
      'name',
      'benefit_id',
      'opt_in_on_select',
      'invalid_on_select',
      'selection_restrictions',
    ],
    fieldOverrides: {
      benefit_id: BenefitIdSelect,
    },
  },
  [LINKED_SELECTION_REF]: {
    fieldOrder: ['name', 'key', 'set_ids'],
    fieldOverrides: {
      set_ids: LinkedSelectionSetsSetIds,
    },
  },
  [LADDER_RULE_REF]: {
    fieldOrder: ['name', 'max_steps_up', 'max_steps_down'],
  },
  [SPENDING_ACCOUNT_RESTRICTION_REF]: {
    fieldOrder: [
      'name',
      'contributor_type',
      'contribution_period',
      'fixed_amount',
      'max_amount',
      'min_amount',
      'opt_out_or_minimum',
      'proration',
      'fixed_amount_by_selection',
      'fixed_amount_by_dependent_class',
      'max_amount_by_dependent_class',
      'fixed_amount_by_user_attribute',
    ],
    fieldOverrides: {
      fixed_amount_by_selection: AmountBySelection,
      fixed_amount_by_dependent_class: AmountByDependentClass,
      max_amount_by_dependent_class: AmountByDependentClass,
      fixed_amount_by_user_attribute: AmountByUserAttribute,
    },
  },
  [SELECTION_RESTRICTION_REF]: {
    fieldOrder: [
      'name',
      'key',
      'less_than_or_equal',
      'less_than_or_equal_percent',
    ],
  },
};

export const ENTITY_REFS = {
  BUNDLE: BUNDLE_REF,
  PLAN_OPTIONS: PLAN_OPTIONS_REF,
  BENEFIT_SET: BENEFIT_SET_REF,
  BENEFIT: BENEFIT_REF,
  MONEY: MONEY_REF,
  DOCUMENT: DOCUMENT_REF,
  AMOUNT_BY_SELECTION: AMOUNT_BY_SELECTION_REF,
  USER_ATTRIBUTE_AMOUNT: USER_ATTRIBUTE_AMOUNT_REF,
  DEPENDENT_CLASS_AMOUNT: DEPENDENT_CLASS_AMOUNT_REF,
  SPENDING_ACCOUNT_CONFIG: SPENDING_ACCOUNT_CONFIG_REF,
};

export const PROPERTY_REFS = {
  BUNDLES: BUNDLES_REF,
  BENEFIT_SETS: BENEFIT_SETS_REF,
  BENEFITS: BENEFITS_REF,
};

export const arrayTypeShouldNotBeTreeNode = [
  ENTITY_REFS.DOCUMENT,
  ENTITY_REFS.AMOUNT_BY_SELECTION,
  ENTITY_REFS.DEPENDENT_CLASS_AMOUNT,
  ENTITY_REFS.USER_ATTRIBUTE_AMOUNT,
];

export const showArrayFieldInEntityForm = fieldName =>
  ['benefits', 'set_id'].includes(fieldName);

export const shouldRenderPropertyAsTreeNode = $ref =>
  [
    ENTITY_REFS.SPENDING_ACCOUNT_CONFIG,
    ENTITY_REFS.AMOUNT_BY_SELECTION,
  ].includes($ref);

export const canBeTreeNode = ({ type, items, $ref }) =>
  (type === SCHEMA_ENTITY_TYPES.ARRAY &&
    !arrayTypeShouldNotBeTreeNode.includes(items.$ref)) ||
  shouldRenderPropertyAsTreeNode($ref);
