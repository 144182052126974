import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function validateBenefitSelection - sends a `validate_benefit_selection` message to check all benefit selections
 * before they
 *
 * @arg {Object} info  - An object that
 * @arg {string} info.groupId  - The group id of the user trying to validate their selections
 * @arg {string} info.planId  - The plan id of the user trying to validate their selections
 * @arg {object[]} info.selections - user has submitted benefits
 * @arg {Boolean} info.submit - optional; whether to submit benefit selections as final
 *
 * @returns {Object} - An object
 */

export const validateBenefitSelection = ({
  groupId,
  benefitSelections,
  submit,
  planId,
}) => {
  const params = {
    group_id: groupId,
    plan_id: planId,
  };

  if (submit) {
    params.submit = true;
  }

  if (benefitSelections) {
    params.benefits = benefitSelections;
  }

  return call(websocketFetch, 'validate_benefit_selection', params);
};
