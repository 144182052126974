import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik-legacy';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  createFormGenerator,
  createFormValueFormatter,
  createValidator,
} from 'common/adaptive-forms';
import { invoiceDryRun } from './invoices.actions';
import { FormattedMessage } from 'react-intl';
import layout from './invoices.layout';
import schema from './invoices.schema';

const Submit = ({ loading }) => (
  <Button type="submit" value="Submit" color="violet" loading={loading}>
    <FormattedMessage id="DRY_RUN" />
  </Button>
);

Submit.propTypes = {
  loading: PropTypes.bool,
};

Submit.defaultProps = {
  loading: false,
};

const withFormikEnhancer = Formik({
  validate: (values, props) => props.validate(values),
  mapPropsToValues: props => ({ group_id: props.groupId, ...props.formValues }),
  handleSubmit: (values, { props }) => props.invoiceDryRun(values),
});

const mapStateToProps = () => ({
  generateForm: createFormGenerator({
    schema,
    layout,
    customComponents: {
      submit: Submit,
    },
  }),
  formValues: createFormValueFormatter(schema)({}),
  validate: createValidator(schema),
});

const DryRunInvoice = compose(
  connect(mapStateToProps, { invoiceDryRun }),
  withFormikEnhancer,
)(props => props.generateForm(props));

export default DryRunInvoice;
