import { DO_TOP_UP_USER_BENEFIT, RESET_TOP_UP_STATUS } from './add-funds.types';

export const initialState = {
  topUpStatus: {
    pending: null,
    error: null,
  },
};

export const applyTopUpInitial = state => ({
  ...state,
  topUpStatus: {
    pending: null,
    error: null,
  },
});

export const applyTopUpStarted = state => ({
  ...state,
  topUpStatus: {
    pending: true,
    error: null,
  },
});

export const applyTopUpSucceeded = (state, { payload }) => ({
  ...state,
  topUpStatus: {
    pending: false,
    error: false,
    balance: payload.info.amount_available,
  },
});

export const applyTopUpErrored = (state, { payload }) => ({
  ...state,
  topUpStatus: {
    pending: null,
    error: payload.info.reason,
  },
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employeeAddFundsReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case DO_TOP_UP_USER_BENEFIT.STARTED:
      return applyTopUpStarted(state, action);
    case DO_TOP_UP_USER_BENEFIT.SUCCEEDED:
      return applyTopUpSucceeded(state, action);
    case DO_TOP_UP_USER_BENEFIT.ERRORED:
      return applyTopUpErrored(state, action);
    case RESET_TOP_UP_STATUS:
      return applyTopUpInitial(state);
    default:
      return state;
  }
};
