import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { vars, Input, Select } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Label, QuestionTooltip } from 'apps/employer-experience/ui-kit';
import { TYPE_OPTIONS, INPUT_TYPE_TEXT, INPUT_TYPE_SELECT } from '../constants';
import { find } from 'lodash';

const StyledInput = styled(Input)`
  max-width: 25rem;
  height: 2.5rem;
  font-size: 1rem;
  border: 1px solid ${vars.colour.mediumGrey};
  border-radius: 0.1rem;
  background-color: ${vars.colour.white};

  ${({ error }) =>
    error &&
    `
    border: 1px solid ${vars.colour.red};
  `} ${({ locked }) =>
    locked &&
    `
    background-color: ${vars.colour.offWhite};
    border: none;
    padding: 0;
  `} &&[disabled] {
    color: ${vars.colour.black};
  }
`;

const StyledLabel = styled(Label)`
  margin-right: 0.4rem;
`;

const StyledSelect = styled(Select)`
  max-width: 25rem;
  height: 2.5rem;
  font-size: 1rem;
  border-radius: 0.1rem;
  margin: 0;
  background-color: ${vars.colour.white};

  .rrs {
    border-color: ${vars.colour.mediumGrey};
    height: inherit;

    .rrs__button {
      height: inherit;
      line-height: 2.5rem;
      ${({ error }) =>
        error &&
        `
        border: 1px solid ${vars.colour.red};
      `};
    }

    .rrs__label {
      border-color: ${vars.colour.mediumGrey};
      ${({ error }) =>
        error &&
        `
        border: none;
      `};
      height: inherit;
      padding-left: 1rem;
      line-height: 2.5rem;
    }

    .rrs__option {
      padding: 1rem;
      line-height: 0.5rem;
    }
  }
`;

export const InputField = ({
  label,
  htmlFor,
  value,
  tooltipContent,
  onChange,
  inputType,
  locked,
  error,
}) => (
  <Flex flexDirection="column">
    <Flex>
      <StyledLabel inline htmlFor={htmlFor}>
        {label}
      </StyledLabel>
      <QuestionTooltip> {tooltipContent} </QuestionTooltip>
    </Flex>
    {inputType === INPUT_TYPE_TEXT && (
      <StyledInput
        disabled={locked}
        locked={locked}
        name={htmlFor}
        value={value}
        onChange={onChange}
        error={error}
      />
    )}
    {inputType === INPUT_TYPE_SELECT && !locked && (
      <StyledSelect
        name={htmlFor}
        selectedValue={value}
        onChange={onChange}
        noSelectionLabel=""
        options={TYPE_OPTIONS}
        error={error}
      />
    )}
    {inputType === INPUT_TYPE_SELECT && locked && (
      <StyledInput
        locked={locked}
        disabled={locked}
        name={htmlFor}
        value={find(TYPE_OPTIONS, ['value', value]).text}
      />
    )}
  </Flex>
);

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  htmlFor: PropTypes.string.isRequired,
  value: PropTypes.string,
  tooltipContent: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  locked: PropTypes.bool,
  error: PropTypes.string,
};

InputField.defaultProps = {
  value: '',
  tooltipContent: null,
  inputType: INPUT_TYPE_TEXT,
  locked: false,
  error: null,
};
