import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { SubtitleOne } from '@leagueplatform/genesis-commons';
import { FormattedMessage } from 'react-intl';

import {
  selectGroupId,
  selectBillingDivisions,
  selectGroupCurrency,
} from 'apps/employer-experience/pages/EmployerDetails/selectors';

import { selectFeeStatus } from '../../store/fees.selectors';
import { STATUSES } from '../../store/fees.reducer';

import { useIntl } from '@leagueplatform/locales';
import {
  saveFee as saveFeeAction,
  resetStatus as resetStatusAction,
} from '../../store/fees.actions';

import { feeSchema } from '../../store/fees.schema';

import { AdhocBillingPresenter } from './adhoc-billing.view';

const mapStateToProps = createStructuredSelector({
  billingDivisions: selectBillingDivisions,
  groupId: selectGroupId,
  currency: selectGroupCurrency,
  status: selectFeeStatus,
});

const mapDispatchToActions = {
  saveFee: saveFeeAction,
  resetStatus: resetStatusAction,
};

export const AdhocBilling = connect(
  mapStateToProps,
  mapDispatchToActions,
)(
  ({
    saveFee,
    groupId,
    currency,
    billingDivisions,
    status,
    resetStatus,
    ...rest
  }) => {
    const { formatMessage } = useIntl();

    useEffect(() => {
      if (status === STATUSES.SUCCESS) {
        resetStatus();
      }
    }, [status, resetStatus]);

    const onSubmit = values => {
      const link = (
        <Link to={`/admin/employers/${groupId}/billing/invoices/pending`}>
          {formatMessage({ id: 'PENDING_FEE' })}
        </Link>
      );

      const content = (
        <SubtitleOne>
          <FormattedMessage id="SUBMIT_FEE_SUCCESS" values={{ link }} />
        </SubtitleOne>
      );

      saveFee(
        { fee: values, groupId },
        {
          content,
        },
      );
    };

    const augmentedFields = feeSchema({
      billingDivisions,
      formatMessage,
    });

    return status === STATUSES.SUCCESS ? (
      <div />
    ) : (
      <AdhocBillingPresenter
        onSubmit={onSubmit}
        fields={augmentedFields}
        currency={currency}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...rest}
      />
    );
  },
);
