import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UNICODE_DATE_FORMAT } from '@leagueplatform/web-common';
import { format } from 'date-fns';
import ActionsConfirmationNoticeDateForm from './actions-confirmation-notice-date-form.view';

export const ActionsConfirmationNoticeDateFormContainer = ({
  setActionNoticeDate,
  displayInline,
}) => {
  const [noticeDate, setNoticeDate] = useState('');

  const onDateChange = date => {
    setActionNoticeDate(date ? date.toISOString() : date); // update parent prop actionNoticeDate
    setNoticeDate(date ? format(new Date(date), UNICODE_DATE_FORMAT) : date);
  };

  return (
    <ActionsConfirmationNoticeDateForm
      noticeDateValue={noticeDate}
      onDateChange={onDateChange}
      displayInline={displayInline}
    />
  );
};

ActionsConfirmationNoticeDateFormContainer.propTypes = {
  setActionNoticeDate: PropTypes.func.isRequired,
  displayInline: PropTypes.bool,
};

ActionsConfirmationNoticeDateFormContainer.defaultProps = {
  displayInline: false,
};
