import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Text, Heading, Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { InfoBox } from 'common/components/info-box/info-box.view';
import { useFormattedCoverageSummaryMessages } from './use-formatted-coverage-summary-messages.hook';

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const Key = props => <Text m={0} {...props} />;
const Value = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Text m={0} fontWeight="bold" textAlign="right" {...props} />
);

export const SummaryBenefitSelection = ({
  isFundedByLeftoverFlexDollars,
  showCostsPerPayPeriod,
  showBenefitName,
  benefit_name: benefitName,
  title: benefitSetTitle,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    formattedCoverage,
    formattedSelection,
    formattedCost,
    formattedCoveragePeriodType,
    formattedPeriodType,
    fundedByLeftoverFlexMessage,
  } = useFormattedCoverageSummaryMessages(props);

  const formattedSelectionTitle = formatMessage({ id: 'PLAN_SELECTION' });
  const formattedCoverageSelectionTitle = formatMessage({ id: 'COVERAGE' });
  const formattedCostTitle = formatMessage({ id: 'YOUR_CURRENT_PLAN_COST' });
  return (
    <Box mb="24px">
      {showBenefitName && (
        <Heading level={4} mt={0}>
          {benefitSetTitle || benefitName}
        </Heading>
      )}
      <Box>
        {formattedSelection && (
          <Flex justify="space-between">
            <Key>{formattedSelectionTitle}</Key>
            <Value>{formattedSelection}</Value>
          </Flex>
        )}
        {formattedCoverage && (
          <Flex justify="space-between">
            <Key>{formattedCoverageSelectionTitle}</Key>
            <Value>
              {formattedCoverage}
              {formattedCoveragePeriodType &&
                ` / ${formattedCoveragePeriodType}`}
            </Value>
          </Flex>
        )}
        <Flex justify="space-between">
          <Key>{formattedCostTitle}</Key>
          <Value>
            {formattedCost}
            {showCostsPerPayPeriod && ` / ${formattedPeriodType}`}
          </Value>
        </Flex>
      </Box>
      {isFundedByLeftoverFlexDollars && (
        <InfoBox withIcon borderRadius={2} mt={1}>
          {fundedByLeftoverFlexMessage}
        </InfoBox>
      )}
    </Box>
  );
};

SummaryBenefitSelection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  costMessage: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFundedByLeftoverFlexDollars: PropTypes.bool,
  isProrated: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  coverageMessage: PropTypes.arrayOf(PropTypes.object),
  periodType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  selectionMessage: PropTypes.arrayOf(PropTypes.object),
  showCostsPerPayPeriod: PropTypes.bool,
  benefit_name: PropTypes.string.isRequired,
  showBenefitName: PropTypes.bool,
  title: PropTypes.string,
};

SummaryBenefitSelection.defaultProps = {
  isFundedByLeftoverFlexDollars: false,
  isProrated: false,
  coverageMessage: [],
  periodType: undefined,
  selectionMessage: [],
  showCostsPerPayPeriod: false,
  showBenefitName: false,
  title: undefined,
};
