import { request } from 'common/websocket-redux';
import {
  ADD_VENDOR_TO_PRODUCT_SYNC,
  FETCH_GROUP_VENDORS,
} from './salesforce-sync.types';
import { getGroupVendors, addVendorToProductSync } from 'common/services';
import { takeLatest, call } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { getGroup } from 'apps/employer-experience/pages/EmployerDetails/saga';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchGroupVendors({ payload }) {
  yield request(FETCH_GROUP_VENDORS, getGroupVendors(payload));
}

export function* enableVendorsToProductSync({ payload }) {
  yield request(
    ADD_VENDOR_TO_PRODUCT_SYNC,
    addVendorToProductSync(payload),
    payload,
  );
}

export function* reloadGroupDetails({ meta }) {
  yield call(getGroup, meta?.groupId);
}

export function* showErrorToast(action) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: action?.payload?.info?.reason,
  });
}

export function* showSuccessEnablingVendors() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'SALESFORCE_SYNC_ENABLE_SUCCESS_MESSAGE',
  });
}

export function* salesforceSyncSaga() {
  yield takeLatest(FETCH_GROUP_VENDORS.BASE, fetchGroupVendors);
  yield takeLatest(ADD_VENDOR_TO_PRODUCT_SYNC.BASE, enableVendorsToProductSync);
  yield takeLatest(ADD_VENDOR_TO_PRODUCT_SYNC.ERRORED, showErrorToast);
  yield takeLatest(
    ADD_VENDOR_TO_PRODUCT_SYNC.SUCCEEDED,
    showSuccessEnablingVendors,
  );
  yield takeLatest(ADD_VENDOR_TO_PRODUCT_SYNC.SUCCEEDED, reloadGroupDetails);
}
