import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from '@leagueplatform/locales';
import { ADMIN } from '@leagueplatform/web-common';
import { ActionsConfirmationContainer } from './actions-confirmation.container';
import { AddFunds } from '../../../../add-funds/add-funds.container';
import { ResetUserBenefits } from '../reset-user-benefits/reset-user-benefits.container';
import { InitiateQLE } from 'common/initiate-qle-reenrollment';
import { vars } from '@leagueplatform/ui-kit';
import { ChangeAllocation } from 'apps/employer-experience/pages/change-allocation';
import { FORM_TYPES } from '../../constants';
import { useActionsDropdown } from './use-actions-dropdown.hook';
import { useConfirmActionsDropdown } from './use-confirm-actions-dropdown.hook';

const DropdownMenuWithNegativeAction = styled(Dropdown.Menu)`
  div.item[role='option'] {
    &.cancel-reinstatement,
    &.cancel-suspension,
    &.cancel-termination {
      color: ${vars.colour.red};

      &:before {
        position: absolute;
        left: 1rem;
        top: 0;
        content: '';
        width: calc(100% - 2rem);
        height: 0.1rem;
        background-color: ${vars.colour.greyLighter};
      }
    }
  }
  color: ${vars.colour.red};
`;

const ActionsDropdown = ({
  userId,
  groupId,
  groupRole,
  name,
  status,
  suspendedType,
  suspendedDate,
  text,
  icon,
  url,
  className,
  hasSpendingAccounts,
  resetEmployeeUserBenefits,
  requestInitiateQLE,
  isAdmin,
  employerTz,
  invitationId,
  sendInviteEmail,
  showReinstateAction,
  showReinstateCancel,
  setUserRoleInGroup,
  reinstateEmployees,
  suspendEmployees,
  terminateEmployees,
  unscheduleEmployeesReinstatement,
  unscheduleEmployeesSuspension,
  unscheduleEmployeesTermination,
}) => {
  const { formatMessage } = useIntl();
  const [isChangingAllocation, setIsChangingAllocation] = useState(false);
  const canChangeEmployeeAllocation = isAdmin && hasSpendingAccounts;
  const roleSettable = ['invited', 'active'].includes(status);

  const {
    addFundsConfirmationVisible,
    actionFormType,
    actionsConfirmationVisible,
    resetUserBenefitsConfirmationVisible,
    initiateQLEConfirmationVisible,
    setEffectiveDate,
    setReason,
    setNoticeDate,
    setEOBDate,
    addFundsConfirmation,
    showActionsConfirmation,
    hideActionsConfirmation,
    resetUserBenefitsConfirmation,
    initiateQLEConfirmation,
    actionEffectiveDate,
    reasonForTermination,
    noticeDate,
    eobDate,
  } = useActionsDropdown();

  const { onConfirmation } = useConfirmActionsDropdown(
    hideActionsConfirmation,
    actionEffectiveDate,
    reasonForTermination,
    noticeDate,
    eobDate,
    actionFormType,
    userId,
    groupId,
    setUserRoleInGroup,
    reinstateEmployees,
    suspendEmployees,
    terminateEmployees,
    unscheduleEmployeesReinstatement,
    unscheduleEmployeesSuspension,
    unscheduleEmployeesTermination,
    isAdmin,
  );

  return (
    <>
      <Dropdown
        text={text}
        icon={icon}
        className={`dropdown-benefit-class-options ${className}`}
        aria-label={formatMessage({ id: 'ACTIONS' })}
      >
        <DropdownMenuWithNegativeAction>
          {status === 'invited' && (
            <>
              <Dropdown.Item
                className="resend-invite-email"
                text={formatMessage({ id: 'RESEND_INVITE_EMAIL' })}
                onClick={() => sendInviteEmail({ invitationId })}
              />
              <CopyToClipboard text={url}>
                <Dropdown.Item
                  text={formatMessage({ id: 'COPY_INVITE_URL' })}
                  onClick={() =>
                    showActionsConfirmation(FORM_TYPES.copyInviteUrl)
                  }
                />
              </CopyToClipboard>
            </>
          )}
          {showReinstateAction && (
            <Dropdown.Item
              className="action-reinstate"
              text={formatMessage({ id: 'REINSTATE' })}
              onClick={() =>
                showActionsConfirmation(FORM_TYPES.reinstateConfirmation)
              }
            />
          )}
          {status !== 'suspended' &&
            (!suspendedDate || suspendedType !== 'suspended') && (
              <Dropdown.Item
                className="action-suspend"
                text={formatMessage({ id: 'SUSPEND' })}
                onClick={() =>
                  showActionsConfirmation(FORM_TYPES.suspendConfirmation)
                }
              />
            )}
          {status !== 'terminated' &&
            (!suspendedDate || suspendedType !== 'terminated') && (
              <Dropdown.Item
                className="action-terminate"
                text={formatMessage({ id: 'TERMINATE' })}
                onClick={() =>
                  showActionsConfirmation(FORM_TYPES.terminateConfirmation)
                }
              />
            )}
          {showReinstateCancel && (
            <Dropdown.Item
              className="cancel-reinstatement"
              text={formatMessage({ id: 'UNSCHEDULE_REINSTATE_HEADER' })}
              onClick={() =>
                showActionsConfirmation(FORM_TYPES.unscheduleReinstate)
              }
            />
          )}
          {status !== 'suspended' &&
            status !== 'extension_of_benefits' &&
            suspendedType === 'suspended' &&
            suspendedDate && (
              <Dropdown.Item
                className="cancel-suspension"
                text={formatMessage({ id: 'UNSCHEDULE_SUSPENSION_HEADER' })}
                onClick={() =>
                  showActionsConfirmation(FORM_TYPES.unscheduleSuspend)
                }
              />
            )}
          {status !== 'terminated' &&
            status !== 'extension_of_benefits' &&
            suspendedType === 'terminated' &&
            suspendedDate && (
              <Dropdown.Item
                className="cancel-termination"
                text={formatMessage({ id: 'UNSCHEDULE_TERMINATION_HEADER' })}
                onClick={() =>
                  showActionsConfirmation(FORM_TYPES.unscheduleTerminate)
                }
              />
            )}

          {roleSettable && groupRole === 'member' && (
            <Dropdown.Item
              className="add-admin-role"
              text={formatMessage({ id: 'ADD_ADMIN_ROLE' })}
              onClick={() => showActionsConfirmation(FORM_TYPES.addAdminRole)}
            />
          )}
          {roleSettable && groupRole === ADMIN && (
            <Dropdown.Item
              className="remove-admin-role"
              text={formatMessage({ id: 'REMOVE_ADMIN_ROLE' })}
              onClick={() =>
                showActionsConfirmation(FORM_TYPES.removeAdminRole)
              }
            />
          )}
          {canChangeEmployeeAllocation && (
            <Dropdown.Item
              className="change-allocation"
              text={formatMessage({ id: 'CHANGE_ALLOCATION_DETAILS' })}
              onClick={() => setIsChangingAllocation(true)}
            />
          )}
          {isAdmin && (
            <Dropdown.Item
              className="reset-user-benefits"
              text={formatMessage({ id: 'RESET_USER_BENEFITS' })}
              onClick={() => resetUserBenefitsConfirmation(true)}
            />
          )}

          {isAdmin && (
            <Dropdown.Item
              className="initiate-qle"
              text={formatMessage({ id: 'INITIATE_QLE_REENROLLMENT' })}
              onClick={() => initiateQLEConfirmation(true)}
            />
          )}
        </DropdownMenuWithNegativeAction>
      </Dropdown>
      {actionsConfirmationVisible && (
        <ActionsConfirmationContainer
          onClose={() => hideActionsConfirmation()}
          name={name}
          formType={actionFormType}
          isAdmin={isAdmin}
          url={url}
          onActionConfirmedClick={() => onConfirmation()}
          setEffectiveDate={setEffectiveDate}
          setReason={setReason}
          setNoticeDate={setNoticeDate}
          setEOBDate={setEOBDate}
        />
      )}
      {initiateQLEConfirmationVisible && (
        <InitiateQLE
          userId={userId}
          groupId={groupId}
          employeeName={name}
          employerTz={employerTz}
          requestInitiateQLE={requestInitiateQLE}
          onClose={() => initiateQLEConfirmation(false)}
        />
      )}
      {resetUserBenefitsConfirmationVisible && (
        <ResetUserBenefits
          userId={userId}
          groupId={groupId}
          employeeName={name}
          resetEmployeeUserBenefits={resetEmployeeUserBenefits}
          onClose={() => resetUserBenefitsConfirmation(false)}
        />
      )}
      {addFundsConfirmationVisible && (
        <AddFunds
          userId={userId}
          name={name}
          groupId={groupId}
          onClose={() => addFundsConfirmation(false)}
        />
      )}
      {isChangingAllocation && (
        <ChangeAllocation
          userId={userId}
          groupId={groupId}
          onClose={() => setIsChangingAllocation(false)}
        />
      )}
    </>
  );
};

ActionsDropdown.propTypes = {
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groupRole: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  suspendedType: PropTypes.string,
  suspendedDate: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasSpendingAccounts: PropTypes.bool.isRequired,
  resetEmployeeUserBenefits: PropTypes.func.isRequired,
  requestInitiateQLE: PropTypes.func.isRequired,
  setUserRoleInGroup: PropTypes.func.isRequired,
  terminateEmployees: PropTypes.func.isRequired,
  unscheduleEmployeesReinstatement: PropTypes.func.isRequired,
  unscheduleEmployeesSuspension: PropTypes.func.isRequired,
  unscheduleEmployeesTermination: PropTypes.func.isRequired,
  reinstateEmployees: PropTypes.func.isRequired,
  suspendEmployees: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  employerTz: PropTypes.string,
  invitationId: PropTypes.string,
  sendInviteEmail: PropTypes.func.isRequired,
  showReinstateAction: PropTypes.bool,
  showReinstateCancel: PropTypes.bool,
};

ActionsDropdown.defaultProps = {
  className: '',
  suspendedDate: null,
  suspendedType: null,
  status: null,
  name: null,
  groupRole: null,
  employerTz: '',
  invitationId: '',
  text: '',
  showReinstateAction: false,
  showReinstateCancel: false,
};

export default ActionsDropdown;
