import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { useIntl } from '@leagueplatform/locales';
import { BENEFIT_STATUS_CREATED } from '../constants';

const BenefitOptions = ({
  removeBenefit,
  renewBenefit,
  publishBenefit,
  benefitStatus,
  autoRenewOn,
  onWalletPreviewClick,
  benefitType,
  onUpdateGroupCategories,
  onRemoveGroupCategories,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dropdown
      icon="ellipsis horizontal"
      text=" "
      className="dropdown-benefit-options"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text={formatMessage({ id: 'PREVIEW_BENEFIT' })}
          onClick={onWalletPreviewClick}
        />
        {benefitStatus === BENEFIT_STATUS_CREATED && (
          <Dropdown.Item
            text={formatMessage({ id: 'PUBLISH_BENEFIT' })}
            onClick={publishBenefit}
          />
        )}
        {['hsa', 'lsa', 'ldsa', 'fsa', 'sa', 'psa'].includes(benefitType) && (
          <Dropdown.Item
            text={formatMessage({ id: 'UPDATE_GROUP_CATEGORIES' })}
            onClick={onUpdateGroupCategories}
          />
        )}
        <Dropdown.Item
          disabled={!autoRenewOn}
          text={formatMessage({ id: 'RENEW_BENEFIT' })}
          onClick={renewBenefit}
        />
        {['hsa', 'lsa', 'ldsa', 'fsa', 'sa', 'psa', 'charity'].includes(
          benefitType,
        ) && (
          <Dropdown.Item
            className="dropdown-benefit-options__negative"
            text={formatMessage({ id: 'REMOVE_GROUP_CATEGORIES' })}
            onClick={onRemoveGroupCategories}
          />
        )}
        <Dropdown.Item
          className="dropdown-benefit-options__negative"
          text={formatMessage({ id: 'REMOVE_BENEFIT' })}
          onClick={removeBenefit}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

BenefitOptions.propTypes = {
  onRemoveGroupCategories: PropTypes.func.isRequired,
  removeBenefit: PropTypes.func.isRequired,
  renewBenefit: PropTypes.func.isRequired,
  publishBenefit: PropTypes.func.isRequired,
  onUpdateGroupCategories: PropTypes.func.isRequired,
  benefitType: PropTypes.string.isRequired,
  onWalletPreviewClick: PropTypes.func,
  benefitStatus: PropTypes.string,
  autoRenewOn: PropTypes.bool,
};

BenefitOptions.defaultProps = {
  benefitStatus: null,
  autoRenewOn: false,
  onWalletPreviewClick: () => {},
};

export default BenefitOptions;
