import { compose, branch, renderComponent } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import QLEConfigurationCard from './qle-configuration-card.view';
import { selectBenefitClasses } from '../../selectors';
import { LoadingIndicator } from '@leagueplatform/web-common-components';

const withProps = connect(
  createStructuredSelector({
    benefitClasses: selectBenefitClasses,
  }),
  null,
);

// if benefitClasses are null, it means they have not been fetched yet!
// In this case, wait until fetched and response received
const withLoadingState = branch(
  ({ benefitClasses }) => benefitClasses === null,
  renderComponent(LoadingIndicator),
);

export default compose(
  withRouter,
  withProps,
  withLoadingState,
)(QLEConfigurationCard);
