import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  HeadingFour,
  BodyOne,
  Flex,
  Overline,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { utcToZonedTime } from 'date-fns-tz';
import { EditableAmount } from './editable-amount.view';
import { useContributionUpdates } from './use-contribution-updates.hook';

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const Row = props => <Box as="tr" borderBottomWidth="thin" {...props} />;

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const HeaderCell = props => <SubtitleOne as="th" {...props} />;

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const RowCell = props => <BodyOne as="td" paddingY="half" {...props} />;

export const DeductionsTablePresenter = ({
  totalContributions,
  accountType,
  contributions,
  userId,
  updateContributions,
}) => {
  const { formatMessage, formatMoney } = useIntl();
  const [editable, setEditable] = useState(false);
  const { contributionUpdates, setContributionUpdates, saveContributions } =
    useContributionUpdates({ userId, updateContributions, accountType });
  if (!accountType) {
    return formatMessage({ id: 'NO_CONTRIBUTION_ACCOUNTS' });
  }
  return (
    <Box>
      <Flex
        marginY="two"
        paddingY="one"
        justifyContent="space-around"
        backgroundColor="highlight.background.subdued"
        borderRadius="medium"
        textAlign="center"
      >
        <Box>
          <Overline as="h4">
            {formatMessage({ id: 'YTD_EMPLOYEE_CONTRIBUTION' })}
          </Overline>
          <HeadingFour>
            {formatMoney({ F: totalContributions, C: accountType.amount.C })}
          </HeadingFour>
        </Box>
        <Box>
          <Overline as="h4">
            {formatMessage({ id: 'TARGET_EMPLOYEE_CONTRIBUTION' })}
          </Overline>
          <HeadingFour>
            {accountType
              ? `${formatMoney({
                  F: accountType.amount.F,
                  C: accountType.amount.C,
                })} / ${accountType.period}`
              : ''}
          </HeadingFour>
        </Box>
      </Flex>
      <Box as="table" width={1} style={{ tableLayout: 'fixed' }}>
        <thead>
          <Row>
            <HeaderCell>{formatMessage({ id: 'EFFECTIVE_DATE' })}</HeaderCell>
            <HeaderCell>
              {formatMessage({ id: 'CONTRIBUTION_SOURCE' })}
            </HeaderCell>
            <HeaderCell>{formatMessage({ id: 'PAY_PERIOD' })}</HeaderCell>
            <HeaderCell>{formatMessage({ id: 'AMOUNT' })}</HeaderCell>
          </Row>
        </thead>
        <tbody>
          {contributions?.length ? (
            contributions.map(contribution => (
              <Row
                key={`${contribution.user_benefit_id}_${contribution.effective_date}`}
              >
                <RowCell>
                  {format(
                    utcToZonedTime(contribution.effective_date, 'UTC'),
                    'MM/dd/yyyy',
                  )}
                </RowCell>
                <RowCell>{contribution.contribution_source}</RowCell>
                <RowCell>
                  {contribution.pay_period &&
                    `${format(
                      utcToZonedTime(contribution.pay_period.start_date, 'UTC'),
                      'MM/dd/yyyy',
                    )} - ${format(
                      utcToZonedTime(contribution.pay_period.end_date, 'UTC'),
                      'MM/dd/yyyy',
                    )}`}
                </RowCell>
                <RowCell>
                  <EditableAmount
                    contributionUpdates={contributionUpdates}
                    setContributionUpdates={setContributionUpdates}
                    editable={editable}
                    setEditable={setEditable}
                    contribution={contribution}
                    save={() => {
                      saveContributions();
                    }}
                  />
                </RowCell>
              </Row>
            ))
          ) : (
            <Row>
              <BodyOne as="td" paddingY="two" colSpan="4" textAlign="center">
                {formatMessage({ id: 'NO_CONTRIBUTIONS' })}
              </BodyOne>
            </Row>
          )}
        </tbody>
      </Box>
    </Box>
  );
};
DeductionsTablePresenter.propTypes = {
  contributions: PropTypes.arrayOf(
    PropTypes.shape({
      user_benefit_id: PropTypes.string,
    }),
  ),
  accountType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    amount: PropTypes.shape({
      F: PropTypes.number,
      C: PropTypes.string,
    }),
    period: PropTypes.string,
  }),
  totalContributions: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  updateContributions: PropTypes.func.isRequired,
};

DeductionsTablePresenter.defaultProps = {
  contributions: [],
  accountType: null,
};
