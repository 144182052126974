import { call, takeLatest } from 'redux-saga/effects';
import {
  GET_EMPLOYER_BENEFIT_PLANS,
  GET_EMPLOYER_BENEFIT_PRICE_CONFIG,
  SET_EMPLOYER_BENEFIT_PRICE_CONFIG,
} from '../types';
import {
  ERROR_PARSING_JSON,
  FETCH_BENEFIT_PRICE_BY_ID,
  FETCH_BENEFIT_PLANS_FOR_PRICING_CONFIG,
  SUBMIT_BENEFIT_PRICE_CONFIG,
  FETCH_BENEFIT_PRICES_CSV,
  REQUEST_BENEFIT_PRICES_CSV,
} from './pricing-configuration.types';
import {
  getEmployerBenefitPlans,
  getEmployerBenefitPriceById,
  setBenefitPrices,
  getBenefitPricesAsCsv,
} from 'common/services';
import { request } from 'common/websocket-redux';
import get from 'lodash/get';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchPlansForPricingConfiguration({ payload: { groupId } }) {
  yield request(
    GET_EMPLOYER_BENEFIT_PLANS,
    getEmployerBenefitPlans({ groupId, benefitClassIds: [] }),
    {
      groupId,
    },
  );
}

export function* fetchBenefitPriceById({ payload }) {
  yield request(
    GET_EMPLOYER_BENEFIT_PRICE_CONFIG,
    getEmployerBenefitPriceById(payload),
  );
}

export function* submitBenefitPriceConfig({ payload }) {
  yield request(SET_EMPLOYER_BENEFIT_PRICE_CONFIG, setBenefitPrices(payload));
}

export function* fetchBenefitPricesAsCsv({ payload }) {
  yield request(FETCH_BENEFIT_PRICES_CSV, getBenefitPricesAsCsv(payload));
}

export function* showErrorToast(action) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: get(action, 'payload.info.reason'),
  });
}

export function* showSuccessSavingPriceConfig() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'SET_EMPLOYER_BENEFIT_PRICE_CONFIG_SUCCESS_MESSAGE',
  });
}

export function* employerPricingConfigSaga() {
  yield takeLatest(
    FETCH_BENEFIT_PLANS_FOR_PRICING_CONFIG,
    fetchPlansForPricingConfiguration,
  );
  yield takeLatest(FETCH_BENEFIT_PRICE_BY_ID, fetchBenefitPriceById);
  yield takeLatest(SUBMIT_BENEFIT_PRICE_CONFIG, submitBenefitPriceConfig);
  yield takeLatest(SET_EMPLOYER_BENEFIT_PRICE_CONFIG.ERRORED, showErrorToast);
  yield takeLatest(
    SET_EMPLOYER_BENEFIT_PRICE_CONFIG.SUCCEEDED,
    showSuccessSavingPriceConfig,
  );
  yield takeLatest(ERROR_PARSING_JSON, showErrorToast);
  yield takeLatest(FETCH_BENEFIT_PRICES_CSV.ERRORED, showErrorToast);
  yield takeLatest(REQUEST_BENEFIT_PRICES_CSV, fetchBenefitPricesAsCsv);
}
