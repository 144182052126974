import { Status } from './allocation-tool.types';

const READY: Status = 'ready';
const ACTIVE: Status = 'active';
const ERROR: Status = 'error';

export const STATUSES = {
  READY,
  ACTIVE,
  ERROR,
};
