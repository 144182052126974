import {
  GET_DEPENDENT_TERMINATION_TYPE_OPTIONS,
  GET_DEPENDENT_ADD_QLE_REASONS,
  GET_DEPENDENT_RELATIONSHIP_TYPES,
  TERMINATE_DEPENDENT,
  SET_DEPENDENT,
} from './dependent.action-types';

export const getDependentTerminationTypeOptions = () => {
  return {
    type: GET_DEPENDENT_TERMINATION_TYPE_OPTIONS.BASE,
  };
};

export const getDependentAddQLEReasons = () => {
  return {
    type: GET_DEPENDENT_ADD_QLE_REASONS.BASE,
  };
};

export const getDependentRelationshipTypes = () => {
  return {
    type: GET_DEPENDENT_RELATIONSHIP_TYPES.BASE,
  };
};

export const terminateDependent = payload => {
  return {
    type: TERMINATE_DEPENDENT.BASE,
    payload,
  };
};

export const saveDependent = payload => {
  return {
    type: SET_DEPENDENT.BASE,
    payload,
  };
};
