import { VIEWING_BULK_ADD_EMPLOYEE, UPLOAD_CSV_REQUESTED } from './types';

export const viewingBulkAddEmployee = viewing => ({
  type: VIEWING_BULK_ADD_EMPLOYEE,
  payload: { viewing },
});

export const uploadCsv = ({ file }) => ({
  type: UPLOAD_CSV_REQUESTED,
  payload: {
    file,
  },
});
