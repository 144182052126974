import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

// eslint-disable-next-line @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
export type getDigitalBeneficiaryFormParams = {
  groupId: string;
  planId: string;
};

export const getDigitalBeneficiaryForm = ({
  groupId,
  planId,
}: getDigitalBeneficiaryFormParams) =>
  call(websocketFetch, 'get_digital_beneficiary_form', {
    group_id: groupId,
    plan_id: planId,
  });
