import { GET_GROUP, GET_GROUP_BY_ID } from './groups.actions';
import { combineReducers } from 'redux';
import { getPayloadInfo } from 'common/websocket-redux';
import { makeStatusReducer } from 'common/request/status.reducer';
import { STATUS } from 'common/request/status.constants';

export const initialState = {
  status: STATUS.IDLE,
  activeGroupId: '',
  entities: {},
};

export const activeGroupId = (state = null, action = {}) => {
  switch (action?.type) {
    case GET_GROUP.SUCCEEDED:
      return getPayloadInfo(action)?.group_id;
    default:
      return state;
  }
};

export const entities = (state = initialState.entities, action = {}) => {
  switch (action?.type) {
    case GET_GROUP_BY_ID.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const info = getPayloadInfo(action);

      return { ...state, [info.group_id]: info };
    default:
      return state;
  }
};

export const groupsReducer = combineReducers({
  status: makeStatusReducer(GET_GROUP_BY_ID),
  activeGroupId, // the id from user_profile
  entities,
});
