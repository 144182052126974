import React from 'react';
import { Dimmer, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  StyledDayPickerInput,
  DownloadLinkCSV,
} from 'apps/employer-experience/ui-kit';
import { getThemeValue, Box } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import { Flex } from 'common/components/primitives.view';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { INVOICING_DIAGNOSTIC_DATE, DATE_FORMAT } from '../../../constants';
import { FinanceInvoicingClearDiagnostics } from './finance-invoicing-clear-diagnostics.container';

const StyledLoaderButton = styled(LoaderButton)`
  font-size: ${getThemeValue('space.2')}px;
  height: 3rem;
  padding: 0 1.5rem;
`;

export const StyledButton = styled(Button)`
  font-size: ${getThemeValue('space.2')}px;
  height: 3rem;
  padding: 0 1.5rem;
  margin-left: 1.5rem;
`;

const DayPickerWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 22rem;

  .DayPicker-wrapper {
    padding: 0.7rem 0;

    .DayPicker-Day {
      padding: 0.2rem;
    }
  }

  .DayPickerInput input {
    padding: 0.875rem;
  }
`;

const InvoiceDiagnosticHeader = styled.h5`
  margin: ${getThemeValue('space.3')}px 0 !important;
`;

const FinanceInvoicingDiagnostics = ({
  invoicingDiagnosticsLoading,
  invoicingDiagnosticsContentId,
  handleSubmit,
  handleChange,
  handleClearInvoiceDiagnostics,
  clearInvoiceDiagnosticsVisible,
  showClearInvoiceDiagnostics,
  closeClearInvoiceDiagnostics,
}) => (
  <Form>
    <Form.Group widths="equal">
      <Form.Field>
        <Dimmer.Dimmable as={Box}>
          <InvoiceDiagnosticHeader>
            <FormattedMessage id="INVOICE_DIAGNOSTICS" />
          </InvoiceDiagnosticHeader>
          <Box>
            <DayPickerWrapper>
              <StyledDayPickerInput
                placeholder={DATE_FORMAT}
                format={DATE_FORMAT}
                name={INVOICING_DIAGNOSTIC_DATE}
                onDayChange={date =>
                  handleChange(INVOICING_DIAGNOSTIC_DATE, date)
                }
              />
            </DayPickerWrapper>
          </Box>
          <Flex>
            <StyledLoaderButton
              loading={invoicingDiagnosticsLoading}
              primary
              onClick={handleSubmit}
            >
              <FormattedMessage id="GET_INVOICE_DIAGNOSTICS" />
            </StyledLoaderButton>
            <StyledButton warning onClick={showClearInvoiceDiagnostics}>
              <FormattedMessage id="CLEAR_INVOICING_DIAGNOSTICS" />
            </StyledButton>
          </Flex>
          {clearInvoiceDiagnosticsVisible && (
            <FinanceInvoicingClearDiagnostics
              closeClearInvoiceDiagnostics={closeClearInvoiceDiagnostics}
              handleClearInvoiceDiagnostics={handleClearInvoiceDiagnostics}
            />
          )}
          {invoicingDiagnosticsContentId && (
            <>
              <FormattedMessage id="INVOICE_DIAGNOSTIC_DOWNLOAD" />
              <DownloadLinkCSV contentId={invoicingDiagnosticsContentId} />
            </>
          )}
        </Dimmer.Dimmable>
      </Form.Field>
    </Form.Group>
  </Form>
);

FinanceInvoicingDiagnostics.propTypes = {
  invoicingDiagnosticsContentId: PropTypes.string,
  invoicingDiagnosticsLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClearInvoiceDiagnostics: PropTypes.func.isRequired,
  clearInvoiceDiagnosticsVisible: PropTypes.bool,
  showClearInvoiceDiagnostics: PropTypes.func.isRequired,
  closeClearInvoiceDiagnostics: PropTypes.func.isRequired,
};

FinanceInvoicingDiagnostics.defaultProps = {
  invoicingDiagnosticsContentId: null,
  invoicingDiagnosticsLoading: false,
  clearInvoiceDiagnosticsVisible: false,
};

export default FinanceInvoicingDiagnostics;
