import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colour, getThemeValue, radiusInPx } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';

import MoneyBag from './money-bag-dollar.svg';
import LightBulb from './study-light-idea.svg';
import { Box, Image } from '@leagueplatform/genesis-commons';

const Container = styled(Flex).attrs(props => ({
  flexDirection: 'row',
  p: props.clear ? 0 : 2,
}))`
  ${props =>
    !props.clear &&
    css`
      background-color: ${colour('offWhite')};
    `};
  border-radius: ${radiusInPx(2)};
  align-items: center;
`;

const Content = styled(Flex)`
  font-size: ${getThemeValue('fontSizes.1')}px;
  color: ${colour('neutral.grayDark')};
  line-height: 1.5;
`;

const componentMap = {
  'money-bag': MoneyBag,
  'light-bulb': LightBulb,
};

export const InfoBox = ({ children, withIcon, clear, iconName, ...rest }) => {
  const iconSrc = componentMap[iconName];
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container {...rest} clear={clear}>
      {withIcon && (
        <Box paddingRight="half" width="two" height="oneAndHalf">
          <Image src={iconSrc} alt="" />
        </Box>
      )}
      <Content flex={1}>{children}</Content>
    </Container>
  );
};

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  clear: PropTypes.bool,
  withIcon: PropTypes.bool,
  iconName: PropTypes.string,
};

InfoBox.defaultProps = {
  withIcon: false,
  clear: false,
  iconName: 'light-bulb',
};
