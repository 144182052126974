/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  GET_USER_PROFILE,
  SET_USER_NOTIFICATION_SETTING,
  REQUEST_USER_CONFIG,
} from './user.types';
import { SET_USER_PROFILE } from 'apps/member-experience/pages/UserProfile/profile-page.actions';
import {
  SET_USER_ACCEPTED_TERMS,
  SET_USER_MARKETING_OPT_IN,
} from 'apps/terms/terms.actions';
import camelize from 'camelize';
import { getUserTenantId } from 'common/tenant/get-user-tenant-id';

const initialState = {
  isFetching: false,
  tenantId: getUserTenantId(),
  user_config: {},
};

const applyGetUserProfileFetching = state => {
  return {
    ...state,
    isFetching: true,
  };
};

const getUserProfileSucceeded = (state, action) => {
  const { info } = camelize(action.payload);
  return {
    ...state,
    ...info,
    isFetching: false,
  };
};

const getUserProfileFailed = (state, action) => {
  return {
    ...initialState,
    error: action,
  };
};

const setUserNotificationSettingsSucceeded = (state, action) => {
  const { info: userProfile } = camelize(action.payload);
  return {
    ...state,
    userProfile,
    isFetching: false,
  };
};

const setUserNotificationSettingsErrored = (state, action) => {
  return {
    ...initialState,
    error: action.payload.error,
  };
};

const setUserConfigSucceeded = (state, action) => {
  const {
    info: {
      hide_submit_claims,
      show_benefit_summary,
      show_dependents_menu_item,
      show_dependents_menu_item_indicator,
      hide_banking_info,
      must_accept_terms,
    },
  } = action.payload;
  return {
    ...state,
    user_config: {
      hide_submit_claims,
      show_benefit_summary,
      show_dependents_menu_item,
      show_dependents_menu_item_indicator,
      hide_banking_info,
      must_accept_terms,
    },
    error: false,
  };
};

const setUserConfigErrored = state => {
  return {
    ...state,
    user_config: null,
    error: true,
  };
};

const setUserAcceptTermsSucceeded = (state, action) => {
  const memberTermsAndConditions = camelize(action.payload.info);
  return {
    ...state,
    isFetching: false,
    userProfile: { ...state.userProfile, memberTermsAndConditions },
  };
};

const setUserMarketingOptInSucceeded = (state, action) => {
  const userProfile = camelize(action.payload.info);
  return {
    ...state,
    isFetching: false,
    userProfile,
  };
};

const setUserProfile = (state, action) => {
  const { info } = camelize(action.payload);
  return {
    ...state,
    userProfile: { ...state.userProfile, ...info },
  };
};

export const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_ACCEPTED_TERMS.SUCCEEDED:
      return setUserAcceptTermsSucceeded(state, action);
    case SET_USER_MARKETING_OPT_IN.SUCCEEDED:
      return setUserMarketingOptInSucceeded(state, action);
    case GET_USER_PROFILE.STARTED:
    case SET_USER_NOTIFICATION_SETTING.STARTED:
      return applyGetUserProfileFetching(state, action);
    case GET_USER_PROFILE.SUCCEEDED:
      return getUserProfileSucceeded(state, action);
    case GET_USER_PROFILE.FAILED:
      return getUserProfileFailed(state, action);
    case SET_USER_NOTIFICATION_SETTING.SUCCEEDED:
      return setUserNotificationSettingsSucceeded(state, action);
    case SET_USER_NOTIFICATION_SETTING.ERRORED:
      return setUserNotificationSettingsErrored(state, action);
    case REQUEST_USER_CONFIG.SUCCEEDED:
      return setUserConfigSucceeded(state, action);
    case REQUEST_USER_CONFIG.ERRORED:
      return setUserConfigErrored(state, action);
    case SET_USER_PROFILE.SUCCEEDED:
      return setUserProfile(state, action);
    default:
      return state;
  }
};
