import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const ValidMessage = ({ errors, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <Message negative {...props}>
      <Message.Header>We found a problem with the form below</Message.Header>
      <Message.List>
        {errors.map(error => {
          return (
            <Message.Item key={`error_${Math.random()}`}>{error}</Message.Item>
          );
        })}
      </Message.List>
    </Message>
  );
};

ValidMessage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string), // eslint-disable-line react/forbid-prop-types
};

ValidMessage.defaultProps = {
  errors: [],
};

export default ValidMessage;
