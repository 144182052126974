import slugify from 'slugify';
import { getBenefitPlanOptions as _getBenefitPlanOptions } from 'common/services/enrollment';
import {
  getBenefitType,
  typeToLegacyTypeMap,
  BENEFIT_SET_NAMES,
} from './benefit-set-types';
import {
  BenefitSet,
  Bundle,
  BenefitSetTypeId,
  BenefitSetType,
  BenefitPlanOptions,
  GetBenefitPlanOptionsMessage,
} from 'common/types/enrollment-types';

const slugifyOptions = {
  lower: true,
};

export const getBenefitSetType = (id: BenefitSetType): BenefitSetType =>
  getBenefitType(id) || id;

const enhanceBenefitSetWithType = (
  benefitSet: BenefitSet,
  benefitSetType?: string,
) => {
  /*
    Life and AD&D will get changed to life-and-adandd with slugify.
    So we should get rid of '&' from the string to avoid it.
  */

  /*
    @TODO = remove this once we consume everything from BE.
    As benefitSet.name is an optional field, to avoid issues with the legacy benefit sets
    we use a double jump to create a type from the type we get from BE.
    We fetch the mappings of old types to the new ones from typeToLegacyTypeMap and using
    this old type we fetch the name of the benefit set using BENEFIT_SET_NAMES. Once we have
    a name, we slugify the name to create a type of a benefit set.
  */

  const mappedLegacyBenefitSetType = benefitSetType
    ? typeToLegacyTypeMap[benefitSetType]
    : '';
  const benefitSetName =
    BENEFIT_SET_NAMES[mappedLegacyBenefitSetType] ?? benefitSet.name;
  const normalizedName = benefitSetName?.replace('&', '');

  const typeId = slugify(normalizedName, slugifyOptions) as BenefitSetTypeId;
  const type = getBenefitSetType(typeId);

  return { ...benefitSet, type, typeId, name: benefitSetName };
};

export const normalizeBenefitSet = (benefitSet: BenefitSet): BenefitSet => {
  return enhanceBenefitSetWithType(benefitSet, benefitSet.type);
};

const findSetById = (benefitSet: BenefitSet) => (set: any) =>
  set.id === benefitSet.id;

export const normalizeBenefitSetFromSteps =
  (steps: any[]) =>
  (benefitSet: BenefitSet): BenefitSet => {
    /* try and find a step that contains this benefit set in top-level `.sets` */
    const setStep = steps.find(step =>
      step.sets?.find(findSetById(benefitSet)),
    );
    /* if found, pull out the benefit set with a matching id to `benefitSet.id` */
    const stepBenefitSet = setStep?.sets?.find(findSetById(benefitSet));
    /* if benefitSet.type exists use that else fall back to type from steps */
    const benefitSetType = benefitSet.type || stepBenefitSet?.type;

    return enhanceBenefitSetWithType(benefitSet, benefitSetType);
  };

/**
 * This function is used to enhance the benefit sets with type and content coming within the summary steps
 */
export const normalizeBenefitSetFromStepsWithContent =
  (steps: any[]) =>
  (benefitSet: BenefitSet): BenefitSet => {
    /* try and find a step that contains this benefit set in top-level `.sets` */
    const setStep = steps.find(step =>
      step.sets?.find(findSetById(benefitSet)),
    );
    /* if found, pull out the benefit set with a matching id to `benefitSet.id` */
    const stepBenefitSet = setStep?.sets?.find(findSetById(benefitSet));
    /* if benefitSet.type exists use that else fall back to type from steps */
    const benefitSetType = benefitSet.type || stepBenefitSet?.type;

    return enhanceBenefitSetWithType(
      // This line added the benefit set keys along with content keys of benefit set find inside summarySteps
      { ...benefitSet, ...stepBenefitSet },
      benefitSetType,
    );
  };

export function* getBenefitPlanOptions(
  groupId: string,
  planId: string,
): Generator<any, BenefitPlanOptions> {
  const message = yield _getBenefitPlanOptions({ groupId, planId });
  const { info } = message as GetBenefitPlanOptionsMessage;
  const bundles: Array<Bundle> = info?.bundles ?? [];

  return {
    bundles: bundles.map((bundle: Bundle) => ({
      ...bundle,
      benefit_sets: bundle?.benefit_sets.map(normalizeBenefitSet),
    })),
    employerPaysAllCosts: info?.payment_options?.employer_pays_all_costs,
    showCostsPerPayPeriod: info?.payment_options?.show_costs_per_pay_period,
    bundleDocuments: info?.documents,
    coordinationOfBenefitsOptions: info?.coordination_of_benefits_options,
    planId: info?.plan_id,
    qleDetails: info?.qle_details ?? {},
    prorationDetails: info?.proration_details,
    prorationOptions: info?.proration_options,
    additionalFlexDollarSelectionCombinations:
      info?.additional_flex_dollar_selection_combinations,
    decisionSupportUrl: info?.decision_support_url,
  } as BenefitPlanOptions;
}
