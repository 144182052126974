import {
  REQUEST_DELETE_DIRECT_DEPOSIT,
  DELETE_DIRECT_DEPOSIT,
} from './delete-direct-deposit.types';
import { request } from 'common/websocket-redux';
import { removePaymentCredentials } from 'common/services';
import { deleteDirectDepositAnalytics } from './delete-direct-deposit-analytics.sagas';
import { fork, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';

export function* requestDeleteDirectDeposit({ payload }) {
  yield request(
    REQUEST_DELETE_DIRECT_DEPOSIT,
    removePaymentCredentials(payload.paymentCredentialsId),
    payload,
  );
}

export function* handleSuccessToastMessage() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'YOUR_DEPOSIT_ACCOUNT_WAS_DELETED',
  });
}

function* onDeleteDirectDepositAccountSuccess() {
  yield put(push('/member/banking-info'));
}

export function* deleteDirectDepositSagas() {
  yield fork(takeLatest, DELETE_DIRECT_DEPOSIT, requestDeleteDirectDeposit);
  yield fork(
    takeLatest,
    REQUEST_DELETE_DIRECT_DEPOSIT.SUCCEEDED,
    handleSuccessToastMessage,
  );
  yield fork(
    takeLatest,
    REQUEST_DELETE_DIRECT_DEPOSIT.SUCCEEDED,
    onDeleteDirectDepositAccountSuccess,
  );
  yield fork(deleteDirectDepositAnalytics);
}
