/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox, Header, Form, Button, Grid } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import { cloneDeep } from 'lodash';
import SubscriptionRow from './components/SubscriptionRow';
import { ValidMessage } from 'common/adaptive-forms';
import { formatCurrency } from '@leagueplatform/web-common';

class SubscriptionFee extends Component {
  constructor(props) {
    super(props);
    const initialState = [
      {
        lower: '0.01',
        upper: 'Infinity',
        rate: '0',
      },
    ];
    this.state = {
      isChecked:
        props.subscriptionRates !== initialState &&
        props.subscriptionRates.length !== 0,
      rows:
        this.props.subscriptionRates.length !== 0
          ? this.props.subscriptionRates
          : initialState,
    };

    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentWillMount() {
    this.props.setFieldValue('subRatesChecked', this.state.isChecked);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.subscriptionRates !== this.props.subscriptionRates) {
      this.setState({ ...nextProps });
    }
  }

  // eslint-disable-next-line react/sort-comp -- FIXME: automatically added for existing issue
  addRow(e) {
    e.preventDefault();
    const rows = cloneDeep(this.state.rows);
    rows.push({
      lower: '',
      upper: 'Infinity',
      rate: '0',
    });

    // Sets the second last row's upper limit to empty string
    rows[rows.length - 2].upper = '';
    this.setState({ rows });

    // Update Formik values' subscription rates
    this.props.setFieldValue('changed', true);
    this.props.setFieldValue('subscriptionRates', rows);
  }

  removeRow(e, id) {
    e.preventDefault();
    const rows = cloneDeep(this.state.rows);

    // Removes the selected row
    rows.splice(id, 1);

    // If rows have at least two rows and row is not first and last row
    if (rows.length > 1 && id !== 0 && id !== rows.length) {
      // Sets previous row's upper limit to 1 cent less than current row's lower limit
      rows[id - 1].upper = rows[id].lower
        ? formatCurrency(parseFloat(rows[id].lower) - 0.01)
        : '';
    }
    if (rows.length === 1 && rows[rows.length - 1].lower === '') {
      // set by default to 0.01 if only one row left and the lower value is blank
      rows[rows.length - 1].lower = '0.01';
    }
    // Sets last row's upper limit to Infinity
    rows[rows.length - 1].upper = 'Infinity';
    this.setState({ rows });

    // Update Formik values' subscription rates
    this.props.setFieldValue('changed', true);
    this.props.setFieldValue('subscriptionRates', rows);
  }

  handleUpdate(data) {
    this.setState({ rows: data });

    // Update Formik values' subscription rates
    this.props.setFieldValue('changed', true);
    this.props.setFieldValue('subscriptionRates', data);
  }

  handleToggle(e) {
    e.preventDefault();
    const initialState = [
      {
        lower: '0.01',
        upper: 'Infinity',
        rate: '0',
      },
    ];

    // If it is moving from unchecked to checked
    if (!this.state.isChecked) {
      this.setState({
        isChecked: !this.state.isChecked,
        rows:
          this.props.subscriptionRates.length !== 0
            ? this.props.subscriptionRates
            : initialState,
      });
    } else {
      this.setState({
        isChecked: !this.state.isChecked,
        rows: initialState,
      });
    }
    this.props.setFieldValue('changed', true);
    this.props.setFieldValue('subRatesChecked', !this.state.isChecked);
  }

  render() {
    const { rows, isChecked } = this.state;
    const { groupId, errors, errorIndexList } = this.props;

    return (
      <Box>
        <Header as="h5" className="has-toggle">
          <FormattedMessage id="SUBSCRIPTION_FEE" />
          <Checkbox checked={isChecked} toggle onClick={this.handleToggle} />
        </Header>
        {errors.subscriptionRates ? (
          <ValidMessage errors={errors.subscriptionRates} />
        ) : null}
        <Grid columns="equal" className="billing-fees-grid">
          <Grid.Row>
            <Grid.Column width={3}>
              <FormattedMessage id="LOWER" />
            </Grid.Column>
            <Grid.Column width={3}>
              <FormattedMessage id="UPPER" />
            </Grid.Column>
            <Grid.Column width={3}>
              <FormattedMessage id="RATE" />
            </Grid.Column>
          </Grid.Row>
          {rows.map((row, i) => {
            return (
              <SubscriptionRow
                key={`subRate${Math.random()}_${groupId}`}
                id={i}
                row={row}
                rows={rows}
                handleUpdate={this.handleUpdate}
                removeRow={this.removeRow}
                isChecked={isChecked}
                error={errorIndexList.indexOf(i) !== -1}
              />
            );
          })}
        </Grid>
        <Form.Field>
          <Button
            color="violet"
            disabled={!isChecked}
            onClick={e => this.addRow(e)}
          >
            Add Tier
          </Button>
        </Form.Field>
      </Box>
    );
  }
}

SubscriptionFee.propTypes = {
  groupId: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  subscriptionRates: PropTypes.arrayOf(
    PropTypes.shape({
      lower: PropTypes.string,
      upper: PropTypes.string,
      rate: PropTypes.string,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errorIndexList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SubscriptionFee;
