import { call, fork, takeLatest, put } from 'redux-saga/effects';
import {
  GET_GROUP,
  EMPLOYER_DETAILS_READY,
  GET_EMPLOYER_BENEFITS,
  EMPLOYER_VISITED,
} from './types';
import { request, websocketFetch } from 'common/websocket-redux';
import { editEmployerSaga } from './Profile';
import { employerBillingSaga } from './Billing/saga';
import { employerBenefitsSaga } from './Benefits/saga';
import { employerBenefitPlansSaga } from './Benefits/BenefitClass/Plans/plans.saga';
import { employerPlanBenefitsSaga } from './Benefits/BenefitClass/Plans/BenefitPlan/Benefits/saga';
import { enrollmentConfigSaga } from './enrollment-config/enrollment-config.saga';
import { employerBenefitWalletSaga } from './Benefits/BenefitWallet/benefit-wallet.saga';
import { employerPricingConfigSaga } from './Benefits/PricingConfiguration/pricing-configuration.saga';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { salesforceSyncSaga } from './Benefits/SalesforceSync/salesforce-sync.saga';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { employeesSaga } from './Employees/saga';
import { employeeSaga } from './Employee/saga';
import { bulkAddEmployeesSaga } from './BulkAddEmployees/saga';
import { employerIntegrationsSaga } from './employer-integrations/employer-integrations.saga';
import { employerReportsSaga } from './employer-reports/employer-reports.saga';
import { QLEConfigurationSagas } from './Benefits/QLEConfiguration/qle-configuration.sagas';
import { planDesignSaga } from './Benefits/PlanDesign/plan-design.saga';
import { dependentSaga } from './Employee/components/employee-dependents/dependent/store/dependent.sagas';
import { enrollmentDesignSaga } from './enrollment-design/enrollment-experience-config/enrollment-design-options.saga';

export function* getGroup(id) {
  yield request(GET_GROUP, [websocketFetch, 'get_group', { group_id: id }]);
}

export function* fetchBenefits(id) {
  yield request(GET_EMPLOYER_BENEFITS, [
    websocketFetch,
    'get_employer_benefits',
    { group_id: id },
  ]);
}

export function* initialize(action) {
  const { groupId } = action.payload;
  yield call(getGroup, groupId);
  yield call(fetchBenefits, groupId);
  yield fork(editEmployerSaga, groupId);
  yield fork(employerBenefitsSaga, groupId);
  yield fork(employerBenefitWalletSaga);
  yield fork(employerPricingConfigSaga, groupId);
  yield fork(salesforceSyncSaga, groupId);
  yield fork(employerBenefitPlansSaga);
  yield fork(employerPlanBenefitsSaga);
  yield fork(employeesSaga);
  yield fork(employeeSaga);
  yield fork(employerBillingSaga);
  yield fork(bulkAddEmployeesSaga);
  yield fork(employerIntegrationsSaga);
  yield fork(employerReportsSaga);
  yield fork(planDesignSaga);
  yield fork(dependentSaga);
  yield put({ type: EMPLOYER_DETAILS_READY });
}

export function* employerDetailsSaga() {
  yield fork(enrollmentConfigSaga);
  yield fork(enrollmentDesignSaga);
  yield takeLatest(EMPLOYER_VISITED, initialize);
  yield fork(QLEConfigurationSagas);
}
