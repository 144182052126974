import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const addVendorToProductSync = ({
  groupId,
  vendorIds,
  createCasesForEmployeeCOBRA,
}) =>
  call(websocketFetch, 'add_vendor_to_product_sync', {
    group_id: groupId,
    vendor_ids: vendorIds,
    create_cases_for_employee_cobra: createCasesForEmployeeCOBRA,
  });
