import React from 'react';
import PropTypes from 'prop-types';
import { withFieldHints } from './FieldHints';
import { Select as GenesisSelect } from '@leagueplatform/genesis-core';

export const Select = ({
  id,
  name,
  value,
  placeholder,
  options,
  readOnly,
  setFieldValue,
  setFieldTouched,
}) => {
  const selectOptions = options.map(s => ({ label: s.text, value: s.value }));
  if (!value) {
    selectOptions.unshift({ label: '', value: null });
  }
  return (
    <GenesisSelect
      id={id}
      name={name}
      placeholder={placeholder}
      options={selectOptions}
      value={value}
      disabled={readOnly}
      onBlur={() => setFieldTouched(name, true)}
      onChange={evt => {
        const { value: val = null } = evt.target ?? {};
        setFieldValue(name, val);
      }}
      css={{
        '.GDS-select-menu': {
          height: '$three !important',
          '&:disabled': {
            backgroundColor: '#f7f7f8',
            color: '#424f6f',
            borderColor: '#b2b3b4',
          },
        },
      }}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

Select.defaultProps = {
  id: undefined,
  value: '',
  placeholder: '',
};

export default withFieldHints(Select);
