import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import {
  Box,
  TextInput,
  Label,
  LabelText,
  Text,
  ErrorMessage,
} from '@leagueplatform/genesis-commons';
import { InputFieldGroup } from '../elements';
import { InfoBox } from '../info-box.view';

export const UniqueIdentifierField = ({
  name,
  label,
  placeholder,
  tooltip,
  moreInfo,
  identifierHintToggled,
}) => {
  return (
    <>
      <InputFieldGroup className="unique-identifier-input">
        <Field name={name}>
          {({ field, form: { setFieldValue }, meta: { touched, error } }) => (
            <>
              <Label>
                <LabelText as="span">{label}</LabelText>
                <TextInput
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...field}
                  id={name}
                  placeholder={placeholder}
                  onChange={e => setFieldValue(name, e.target.value.trim())}
                  error={Boolean(touched && error)}
                  aria-invalid={Boolean(touched && error) || undefined}
                  aria-describedby={`${name}_error`}
                  required
                />
                {touched && error && (
                  <ErrorMessage role="alert" id={`${name}_error`}>
                    {error}
                  </ErrorMessage>
                )}
              </Label>
              {tooltip && <Text fontSize="overline">{tooltip}</Text>}
            </>
          )}
        </Field>
      </InputFieldGroup>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
      {moreInfo && <InfoBox {...moreInfo} onToggle={identifierHintToggled} />}
    </>
  );
};

UniqueIdentifierField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  identifierHintToggled: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  moreInfo: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image_url: PropTypes.string,
  }),
};

UniqueIdentifierField.defaultProps = {
  placeholder: '',
  tooltip: '',
  moreInfo: undefined,
};

export const UniqueIdentifierFields = ({
  uniqueIdentifierFields,
  identifierHintToggled,
}) => (
  <FieldArray
    name="uniqueIdentifiers"
    render={() =>
      uniqueIdentifierFields.map((uniqueIdentifier, index) => (
        <Box width={1} key={uniqueIdentifier.name}>
          <UniqueIdentifierField
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...uniqueIdentifier}
            name={`uniqueIdentifiers.${index}.${uniqueIdentifier.name}`}
            identifierHintToggled={identifierHintToggled}
          />
        </Box>
      ))
    }
  />
);

UniqueIdentifierFields.propTypes = {
  uniqueIdentifierFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  identifierHintToggled: PropTypes.func.isRequired,
};

UniqueIdentifierFields.defaultProps = {
  uniqueIdentifierFields: [],
};
