import { takeLatest, all, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { CLAIM_RESOURCES_LOADED } from '../claim-details.types';
import {
  GET_CLAIM_HISTORY,
  CLAIM_NOTE_SUBMITTED,
  SET_CLAIM_NOTE,
  GET_CLAIM_NOTES,
} from './claim-log.types';
import { request, websocketFetch } from 'common/websocket-redux';

export function* getClaimHistory(claimId) {
  yield request(GET_CLAIM_HISTORY, [
    websocketFetch,
    'get_claim_history',
    { claim_id: claimId },
  ]);
}

export function* getNoteHistory(userId) {
  yield request(GET_CLAIM_NOTES, [
    websocketFetch,
    'get_claim_notes',
    { user_id: userId },
  ]);
}

export function* getClaimLogResources(action) {
  const claimId = get(action, 'payload.info.claim_id');
  const userId = get(action, 'payload.info.user_id');
  yield all([call(getClaimHistory, claimId), call(getNoteHistory, userId)]);
}

export function* watchClaimsLogInit() {
  yield takeLatest(
    [CLAIM_RESOURCES_LOADED, SET_CLAIM_NOTE.SUCCEEDED],
    getClaimLogResources,
  );
}

export function* setClaimNote(action) {
  yield request(SET_CLAIM_NOTE, [
    websocketFetch,
    'set_claim_note',
    { claim_id: action.payload.claimId, text: action.payload.text },
  ]);
}

export function* watchClaimNoteSubmission() {
  yield takeLatest(CLAIM_NOTE_SUBMITTED, setClaimNote);
}
