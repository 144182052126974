import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  genesisStyled,
  css,
  QuietButton,
  Overline,
  Image,
  HeadingFour,
  Flex,
  HeadingThree,
} from '@leagueplatform/genesis-commons';
import { KEY } from '@leagueplatform/web-common';
import { useTitleDropdown } from './use-title-dropdown.hook';
import BLURPLE_CHEVRON from 'apps/public/assets/blurple-chevron-down.svg';
import WHITE_CHEVRON from 'apps/public/assets/chevron-white.svg';

const DropdownContainer = genesisStyled(Box)(({ dropdownIsOpen }) => {
  return css({
    transitionProperty: 'opacity',
    transitionDuration: '250ms',
    animationTimingFunction: 'ease',
    opacity: dropdownIsOpen ? '1' : '0',
    backgroundColor: 'surface.card.secondary',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    display: dropdownIsOpen ? 'block' : 'none',
    borderRadius: '3px',
    position: 'absolute',
    zIndex: '1',
    top: '57px',
    width: '100%',
  });
});

const DropdownButton = genesisStyled(QuietButton)(
  css({
    marginBottom: '3px',
    justifyContent: 'left',
    width: '100%',
    textAlign: 'left',
    '&:hover': {
      '::after': {
        content: '""',
        left: '0px',
        top: '1px',
        width: 'quarter',
        height: '44px',
        backgroundColor: 'interactive.action.disabled',
        position: 'absolute',
      },
    },
    '&.active::before': {
      content: '""',
      display: 'inline-block',
      left: '0px',
      top: '1px',
      width: 'quarter',
      height: '44px',
      backgroundColor: 'decorative.brand.primary.darkest',
      position: 'absolute',
    },
  }),
);

const DropdownTitle = genesisStyled(QuietButton)`
  &:hover span, &:focus span, &:active span {
    color: ${({ theme }) => theme.colors.primary.text.default};
  }
`;

export const TitleDropdown = ({ selectedItem, options, onSelect }) => {
  const dropdownContainerRef = useRef();
  const mainButtonRef = useRef();
  const [isButtonFocused, setIsButtonFocused] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const tabArray = [];
  const {
    handleArrowKeyDown,
    focusedTabIndex,
    toggleDropdownKeyDown,
    dropdownIsOpen,
    toggleDropdown,
  } = useTitleDropdown(tabArray, mainButtonRef);

  const setFocusElement = () => {
    const form = dropdownContainerRef.current;
    const focusedEl = form.querySelector('[tabindex = "0"]');
    if (focusedEl) focusedEl.focus();
  };

  const isFocused = useCallback(
    index => (focusedTabIndex === index ? 0 : -1),
    [focusedTabIndex],
  );

  useEffect(() => {
    setFocusElement();
  }, [focusedTabIndex]);

  return (
    <Box position="relative" width="fit-content">
      <DropdownTitle
        onClick={toggleDropdown}
        aria-haspopup="true"
        position="relative"
        ref={mainButtonRef}
        onKeyDown={e => {
          if (KEY.ARROW_DOWN === e.key) {
            e.preventDefault();
          }
          toggleDropdownKeyDown(e);
        }}
        paddingX="none"
        minWidth={350}
        zIndex="2"
        onFocus={() => setIsButtonFocused(true)}
        onBlur={() => setIsButtonFocused(false)}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
      >
        <Flex alignItems="center" paddingX="half">
          <HeadingThree as="span">{selectedItem?.title}</HeadingThree>
          <Image
            marginLeft="half"
            src={
              isButtonHovered || isButtonFocused
                ? WHITE_CHEVRON
                : BLURPLE_CHEVRON
            }
            width={14}
            height={14}
            role="presentation"
          />
        </Flex>
      </DropdownTitle>
      <DropdownContainer
        as="ul"
        dropdownIsOpen={dropdownIsOpen}
        ref={dropdownContainerRef}
      >
        {options.map((heading, index) => (
          <Box as="li" key={heading.label}>
            <Overline
              paddingTop="one"
              paddingLeft="two"
              color="onSurface.text.subdued"
              as={HeadingFour}
            >
              {heading.label}
            </Overline>
            <Box as="ul">
              {heading?.options.map((item, i) => {
                tabArray.push(item);
                return (
                  <Box
                    as="li"
                    aria-describedby={heading?.options[index]?.label}
                    key={item.id}
                  >
                    <DropdownButton
                      tabIndex={isFocused(i + index)}
                      onClick={() => onSelect(item, 'dropdown')}
                      onKeyDown={e => {
                        if ([KEY.ARROW_DOWN, KEY.ARROW_UP].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onKeyUp={e => handleArrowKeyDown(e)}
                      className={selectedItem?.id === item.id && 'active'}
                    >
                      {item.label}
                    </DropdownButton>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </DropdownContainer>
    </Box>
  );
};

const itemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
});

TitleDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(itemPropTypes).isRequired,
    }),
  ).isRequired,
  selectedItem: itemPropTypes.isRequired,
  onSelect: PropTypes.func.isRequired,
};
