import { GET_CHAT_AGENTS } from '../chat-page.action-types';

export const chatAgentsInitialState = {
  isLoading: false,
  hasErrored: false,
  agents: null,
};

export const chatAgentsReducer = (
  state = chatAgentsInitialState,
  action = {},
) => {
  switch (action.type) {
    case GET_CHAT_AGENTS.STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CHAT_AGENTS.SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        agents: action.payload.info.chat_agents,
      };
    case GET_CHAT_AGENTS.ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
      };
    default:
      return state;
  }
};
