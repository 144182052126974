import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const EMPLOYER_PAYROLL_REPORTS_LIFECYCLE = createLifecycleTypes(
  'hr/employer-reports/EMPLOYER_PAYROLL_REPORTS_LIFECYCLE',
);

export const EMPLOYER_PAYROLL_INIT = createRequestTypes(
  'hr/employer-reports/payroll/INIT',
);

export const EMPLOYER_PAYROLL_REPORTS_DOWNLOAD =
  'hr/employer-reports/payroll/DOWNLOAD';
