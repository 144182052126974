import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_YEAR_TO_DATE_REPORT } from '../employer-payroll.types';
import { getYearToDateReport } from 'common/services/payroll/get-year-to-date-report.service';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export const getDefaultErrorMsg = e => {
  const errMessageStart = e.split('.')[0];
  const errMessageEnd = e.split('Validation error').pop();
  return errMessageStart === 'Report payroll_ytd_report validation error'
    ? `Validation error ${errMessageEnd}`
    : e;
};

export function* fetchYearToDateReport({ payload }) {
  try {
    yield put(GET_YEAR_TO_DATE_REPORT.start());
    const response = yield getYearToDateReport(payload);
    if (response?.info?.report?.status !== 'finished') {
      throw response?.info?.report?.status_reason;
    }
    yield put(GET_YEAR_TO_DATE_REPORT.success(response));
  } catch (e) {
    const errorMessage = e?.info?.reason ?? getDefaultErrorMsg(e);
    yield put(GET_YEAR_TO_DATE_REPORT.error(errorMessage));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: errorMessage,
    });
  }
}

export function* yearToDateReportSaga() {
  yield takeLatest(GET_YEAR_TO_DATE_REPORT.BASE, fetchYearToDateReport);
}
