import React from 'react';
import PropTypes from 'prop-types';
import {
  HeadingTwo,
  PrimaryButton,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { treeNodePropType } from './tree-navigation';
import {
  CollectionFormEmpty,
  CollectionFormItem,
  JSONSchemaDefinitionPropType,
} from 'common/json-schema-form';

const itemNodePropShape = PropTypes.shape({
  entityDataPath: PropTypes.string.isRequired,
});

const collectionFormNodePropShape = PropTypes.shape({
  ...treeNodePropType,
  children: PropTypes.arrayOf(itemNodePropShape),
});

export const EnrollmentConfigCollectionForm = ({
  activeNode,
  setActiveNode,
  data,
  schemaDefinition,
  onAddNode,
  onRemoveNode,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingTwo>{schemaDefinition.title}</HeadingTwo>
      <BodyTwoSecondary>{schemaDefinition.description}</BodyTwoSecondary>
      <ul>
        {!data.length ? (
          <CollectionFormEmpty name={schemaDefinition.title} />
        ) : (
          data.map((_, index) => {
            const node = activeNode?.children?.[index];
            return node ? (
              <li key={node.name}>
                <CollectionFormItem
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...props}
                  onItemClick={() =>
                    setActiveNode({
                      ...node,
                      fromCollectionView: true,
                    })
                  }
                  onConfirmRemove={() => onRemoveNode(node)}
                  name={node.name}
                />
              </li>
            ) : null;
          })
        )}
      </ul>
      <PrimaryButton onClick={onAddNode}>
        {formatMessage({ id: 'ADD_AN_ITEM' })}
      </PrimaryButton>
    </>
  );
};

EnrollmentConfigCollectionForm.propTypes = {
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  activeNode: collectionFormNodePropShape.isRequired,
  setActiveNode: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  data: PropTypes.arrayOf(PropTypes.object),
  schemaDefinition: JSONSchemaDefinitionPropType.isRequired,
  onAddNode: PropTypes.func.isRequired,
  onRemoveNode: PropTypes.func.isRequired,
};

EnrollmentConfigCollectionForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  activeNode: { children: [] },
  data: [],
};
