import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  getThemeValue,
  colour,
  spaceInPx,
  Icon,
  Box,
  ICONS,
  StyledComponentRefWrapper,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { StatusLabel } from './StatusLabel.view';
import { DEPENDENT_RELATIONSHIP_MAP } from 'common/constants';
import { useIntl } from '@leagueplatform/locales';
import { SHORT_DATE_FORMAT } from '@leagueplatform/web-common';

const Chevron = styled(Icon)`
  color: ${colour('greyLight')};
  font-size: ${getThemeValue('fontSizes.2')}px;
  margin: 0 ${spaceInPx(2)};
`;

const StyledFlex = styled(Flex)`
  flex-grow: 1;
`;

const Link = styled(StyledComponentRefWrapper(ReactRouterLink))`
  text-decoration: none;
  border: 1px solid ${colour('greyLighter')};
  border-radius: ${getThemeValue('radii.3')}px;
  display: flex;
  justify-content: space-between;
  padding: ${spaceInPx(2)};
  align-items: center;
  &:hover {
    border-color: ${colour('purple')};
    ${Chevron} {
      color: inherit;
    }
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 ${spaceInPx(2)};
`;

export const DependentLink = ({
  to,
  relationshipType,
  pendingSuspensionDate,
  title,
  ...props
}) => {
  const { formatMessage, formatDate } = useIntl();

  const messageId =
    DEPENDENT_RELATIONSHIP_MAP[relationshipType.toLowerCase()] ??
    DEPENDENT_RELATIONSHIP_MAP.other;

  const relationship = formatMessage({
    id: messageId,
  });

  const status = pendingSuspensionDate
    ? formatMessage(
        {
          id: 'DEPENDENT_TERMINATION_PENDING',
        },
        {
          terminationDate: formatDate(pendingSuspensionDate, {
            ...SHORT_DATE_FORMAT,
            timeZone: 'utc',
          }),
        },
      )
    : null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <Link to={to} {...props}>
      <StyledIcon icon={ICONS.TWO_PEOPLE} size={24} />
      <StyledFlex flexDirection="column" marginLeft={2}>
        <Box
          fontSize={2}
          color="purpleDarkest"
          lineHeight="1.5em"
          margin={0}
          fontWeight="500"
        >
          {title}
        </Box>
        <Box
          fontSize={2}
          color="neutral.grayDark"
          fontWeight="normal"
          lineHeight="1.5em"
        >
          {relationship}
        </Box>
      </StyledFlex>
      {status && <StatusLabel marginRight={2}>{status}</StatusLabel>}
      <StyledIcon icon={ICONS.RIGHT_CHEVRON} size="1em" />
    </Link>
  );
};

DependentLink.propTypes = {
  to: PropTypes.string.isRequired,
  pendingSuspensionDate: PropTypes.string,
  relationshipType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

DependentLink.defaultProps = {
  pendingSuspensionDate: null,
};
