import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { DependentForm } from '../components';
import { Divider } from '../../components/divider.view';
import {
  Breadcrumb,
  BreadcrumbLinkItem,
  BreadcrumbItem,
} from 'apps/employer-experience/ui-kit';
import { Box } from '@leagueplatform/genesis-commons';

const SectionHeading = styled(Heading)`
  margin-bottom: 0;
`;

export const AddDependentPresenter = ({
  dependentsBaseUrl,
  onSubmit,
  onCancel,
  fields,
  countryCode,
  loading,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box paddingX="half" overflowY="auto">
      <Breadcrumb>
        <BreadcrumbLinkItem to={dependentsBaseUrl}>
          {formatMessage({ id: 'DEPENDENTS' })}
        </BreadcrumbLinkItem>
        <BreadcrumbItem>{formatMessage({ id: 'ADD' })}</BreadcrumbItem>
      </Breadcrumb>

      <Box marginTop="oneAndHalf" marginBottom="twoAndHalf">
        <Heading level={3}>{formatMessage({ id: 'ADD_DEPENDENT' })}</Heading>
      </Box>

      <SectionHeading level="4">
        {formatMessage({ id: 'BASIC_INFO' })}
      </SectionHeading>
      <Divider />
      <DependentForm
        initialValues={{}}
        onSubmit={onSubmit}
        onCancel={onCancel}
        fields={fields}
        countryCode={countryCode}
        loading={loading}
      />
    </Box>
  );
};

AddDependentPresenter.propTypes = {
  dependent: PropTypes.shape({
    dependent_id: PropTypes.string.isRequired,
    relationship_type: PropTypes.string.isRequired,
    date_of_birth: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    national_identification_number: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    tobacco_user: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  fields: PropTypes.shape(PropTypes.any).isRequired,
  dependentsBaseUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
