/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Header, Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape, useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import DryRunInvoice from './invoice-dry-run.view';
import { Box, Text } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import InvoiceTable from './invoice-table.container';
import { Spinner } from '@leagueplatform/genesis-commons';
import { useActiveInvoices } from './use-active-invoices.hook';
import {
  ANALYTICS_EVENT_HR_ADMIN_CONTACT,
  ANALYTICS_EVENT_HR_ADMIN_MORE,
} from '../constants';

const Page = ({
  invoices,
  isAdmin,
  groupId,
  invoiceDryRuns,
  dryRunLoading,
  intl,
  billingPreference,
  employerInsights,
  ...props
}) => {
  const { isLoading, billingInsightsData, sendAnalytics } = useActiveInvoices(
    groupId,
    isAdmin,
    employerInsights?.dashboards,
  );

  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'BILLING' }));

  if (isLoading) return <Spinner isLoading={isLoading} />;

  return (
    <Dimmer.Dimmable as={Box}>
      {!isAdmin && (
        <>
          <Header as="h1">
            <FormattedMessage id="BILLING" />
          </Header>
          <Flex align="flex-start" justify="space-between">
            <Header as="h2">
              <FormattedMessage id="INVOICES" />
              <Header.Subheader>
                <FormattedMessage id="BILLING_SUBHEADER" />
              </Header.Subheader>
            </Header>
          </Flex>
        </>
      )}
      {!isEmpty(invoices) ? (
        <InvoiceTable
          groupId={groupId}
          invoices={invoices}
          billingPreference={billingPreference}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
          isAdmin={isAdmin}
          billingInsightsData={billingInsightsData?.billing}
        />
      ) : (
        <Message>
          <FormattedMessage id="NO_INVOICES_MESSAGE" />
        </Message>
      )}

      {isAdmin && (
        <Box>
          <DryRunInvoice
            groupId={groupId}
            invoices={invoices}
            loading={dryRunLoading}
          />
          {invoiceDryRuns && (
            <InvoiceTable
              groupId={groupId}
              invoices={invoiceDryRuns}
              billingPreference={billingPreference}
              isDryRun
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...props}
            />
          )}
        </Box>
      )}

      {!isAdmin && (
        <>
          <Text>
            <FormattedMessage
              id="INVOICES_HELP_KB_MESSAGE"
              values={{
                link: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${intl.formatMessage({
                      id: 'KNOWLEDGE_BASE_URL',
                    })}`}
                    onClick={() =>
                      sendAnalytics(
                        ANALYTICS_EVENT_HR_ADMIN_MORE,
                        `${intl.formatMessage({ id: 'KNOWLEDGE_BASE_URL' })}`,
                      )
                    }
                  >
                    <FormattedMessage id="KNOWLEDGE_BASE" />
                  </a>
                ),
              }}
            />
          </Text>
          <Text>
            <FormattedMessage
              id="INVOICES_HELP_EMAIL_MESSAGE"
              values={{
                link: (
                  <a
                    href={`mailto:${intl.formatMessage({
                      id: 'BILLING_EMAIL',
                    })}`}
                    onClick={() =>
                      sendAnalytics(
                        ANALYTICS_EVENT_HR_ADMIN_CONTACT,
                        `${intl.formatMessage({ id: 'BILLING_EMAIL' })}`,
                      )
                    }
                  >
                    <FormattedMessage id="BILLING_EMAIL" />
                  </a>
                ),
              }}
            />
          </Text>
        </>
      )}
    </Dimmer.Dimmable>
  );
};

Page.defaultProps = {
  invoices: null,
  billingDivisions: null,
  contentIdPdf: null,
  contentIdCsv: null,
  billingPreference: 'consolidated',
  invoiceDryRuns: null,
  employerInsights: null,
};

Page.propTypes = {
  invoices: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  isAdmin: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  invoiceDryRuns: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  downloadInvoiceCsv: PropTypes.func.isRequired,
  downloadExternalInvoice: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  dryRunLoading: PropTypes.bool.isRequired,
  billingDivisions: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  billingPreference: PropTypes.string,
  contentIdPdf: PropTypes.array,
  contentIdCsv: PropTypes.array,
  employerInsights: PropTypes.shape({
    dashboards: PropTypes.shape,
  }),
};

export default injectIntl(Page);
