import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getThemeValue, spaceInPx, Box, Text } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { AvailableBenefit, PickedBenefit } from './benefit.view';

const Hint = styled(Text).attrs({
  color: 'grey',
  fontSize: 1,
  lineHeight: '1.3',
})``;

const Legend = styled.legend`
  font-size: ${getThemeValue('fontSizes.2')}px;
  display: block;
  width: 100%;
  line-height: 1.25;
  font-weight: ${getThemeValue('fontWeight.medium')};
  margin-bottom: ${spaceInPx(1)};
`;

const LeftBox = styled(Box).attrs({ width: 1 / 2, pr: [1, 1, 1, 2] })``;
const RightBox = styled(Box).attrs({ width: 1 / 2, pl: [1, 1, 1, 2] })``;

const BenefitList = styled(Box).attrs({
  p: 1,
  pt: 0,
  mt: 2,
  bg: 'greyLightest',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'greyLighter',
  borderRadius: 2,
})`
  height: 340px;
  overflow: auto;
`;

export const BenefitsPicker = ({
  benefitSetDisplayName,
  initialAvailableBenefits,
  initialPickedBenefits,
  onBenefitsChanged,
}) => {
  const [availableBenefits, setAvailableBenefits] = useState(
    initialAvailableBenefits,
  );
  const [pickedBenefits, setPickedBenefits] = useState(initialPickedBenefits);
  useEffect(() => {
    setPickedBenefits(initialPickedBenefits);
  }, [initialPickedBenefits]);
  const onAddBenefitToSet = addedBenefit => {
    const newPickedBenefits = pickedBenefits
      ? [...pickedBenefits, addedBenefit]
      : [addedBenefit];
    const newAvailableBenefits = availableBenefits.filter(
      benefit => benefit.benefit_id !== addedBenefit.benefit_id,
    );
    onBenefitsChanged(newPickedBenefits);
    setAvailableBenefits(newAvailableBenefits);
    setPickedBenefits(newPickedBenefits);
  };
  const onRemoveBenefitFromSet = removedBenefit => {
    const newPickedBenefits = pickedBenefits.filter(
      benefit => benefit.benefit_id !== removedBenefit.benefit_id,
    );
    const newAvailableBenefits = [...availableBenefits, removedBenefit];
    onBenefitsChanged(newPickedBenefits);
    setAvailableBenefits(newAvailableBenefits);
    setPickedBenefits(newPickedBenefits);
  };

  return (
    <Flex pt={3} wrap>
      <LeftBox>
        <Legend>
          <FormattedMessage id="AVAILABLE_BENEFITS" />
        </Legend>
        <Hint>
          <FormattedMessage id="AVAILABLE_BENEFITS_BLURB" />
        </Hint>
      </LeftBox>

      <RightBox>
        <Legend>{benefitSetDisplayName}</Legend>
        <Hint>
          <FormattedMessage id="PICKED_BENEFITS_BLURB" />
        </Hint>
      </RightBox>

      <LeftBox>
        <BenefitList>
          <ul>
            {availableBenefits &&
              availableBenefits.map(benefit => (
                <li key={benefit.benefit_id}>
                  <AvailableBenefit
                    fullName={benefit.benefit_full_name}
                    planName={benefit.benefit_name}
                    onClick={() => onAddBenefitToSet(benefit)}
                  />
                </li>
              ))}
          </ul>
        </BenefitList>
      </LeftBox>

      <RightBox>
        <BenefitList>
          <ul>
            {pickedBenefits &&
              pickedBenefits.map(benefit => (
                <li key={benefit.benefit_id}>
                  <PickedBenefit
                    fullName={benefit.benefit_full_name}
                    planName={benefit.benefit_name}
                    onClick={() => onRemoveBenefitFromSet(benefit)}
                  />
                </li>
              ))}
          </ul>
        </BenefitList>
      </RightBox>
    </Flex>
  );
};

const benefitShape = PropTypes.shape({
  benefit_id: PropTypes.string.isRequired,
  benefit_name: PropTypes.string.isRequired,
  vendor_name: PropTypes.string.isRequired,
});

BenefitsPicker.propTypes = {
  benefitSetDisplayName: PropTypes.string,
  initialAvailableBenefits: PropTypes.arrayOf(benefitShape),
  initialPickedBenefits: PropTypes.arrayOf(benefitShape),
  onBenefitsChanged: PropTypes.func.isRequired,
};

BenefitsPicker.defaultProps = {
  benefitSetDisplayName: 'Benefit Set',
  initialAvailableBenefits: [],
  initialPickedBenefits: [],
};
