import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import {
  PREMIUMS_REPORT_LIFECYCLE,
  GET_PREVIOUS_PREMIUMS_REPORTS,
  GET_PAYROLL_SCHEDULES,
  GET_PREMIUMS_EXPORT_SPLITS,
  SET_PAYROLL_SCHEDULES,
  SHOW_IMPORT_MODAL,
  SHOW_RENAME_MODAL,
  IMPORT_PAYROLL_SCHEDULE,
  EXPORT_PAYROLL_SCHEDULE,
  SHOW_EXPORT_MODAL,
} from './premiums-report.types';

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const initialState = {
  previousReports: [],
  totalPreviousReports: 0,
  previousReportsSkip: 0,
  showRequestReportModal: false,
  payrollSchedules: [],
  premiumsExportSplits: [],
  showImportModal: false,
  showRenameModal: false,
  showExportModal: false,
  documentName: '',
  documentUrl: '',
};

const formatPayPeriodLabels = (payrollSchedules = []) =>
  payrollSchedules.map(payrollSchedule => ({
    ...payrollSchedule,
    schedule: payrollSchedule.schedule?.map(schedule => {
      const startDate = moment.utc(
        schedule.pay_period?.start_date ?? new Date(),
      );
      const endDate = moment.utc(schedule.pay_period?.end_date ?? new Date());
      const startDateString = startDate.format(
        `MMMM D${startDate.year !== endDate.year ? ', YYYY' : ''}`,
      );
      const endDateString = endDate.format('MMMM D, YYYY');
      return { ...schedule, label: `${startDateString} - ${endDateString}` };
    }),
  }));

const saveNewPayPeriod = (payrollSchedules, payload) => {
  return payrollSchedules.map(schedule =>
    schedule.id === payload.info.payroll_schedule.id
      ? payload.info.payroll_schedule
      : schedule,
  );
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const reportsSchedules = (state = initialState, action) => {
  switch (action.type) {
    case PREMIUMS_REPORT_LIFECYCLE.EXITED:
      return initialState;
    case GET_PREVIOUS_PREMIUMS_REPORTS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const reports = action?.payload?.info?.reports;
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const totalReports = action.payload.info?.total_report_documents;
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const skip = action?.payload?.skip;
      return {
        ...state,
        previousReportsSkip: skip,
        previousReports: reports,
        totalPreviousReports: totalReports,
      };
    case GET_PAYROLL_SCHEDULES.SUCCEEDED:
      return {
        ...state,
        payrollSchedules: formatPayPeriodLabels(
          action?.payload?.info?.payroll_schedules,
        ),
      };
    case GET_PREMIUMS_EXPORT_SPLITS.SUCCEEDED:
      return {
        ...state,
        premiumsExportSplits: action?.payload?.info?.premiums_export_splits,
      };
    case SET_PAYROLL_SCHEDULES.SUCCEEDED:
      return {
        ...state,
        showRenameModal: false,
        payrollSchedules: saveNewPayPeriod(
          state.payrollSchedules,
          action.payload,
        ),
      };
    case SHOW_IMPORT_MODAL:
      return {
        ...state,
        showImportModal: action.payload,
      };
    case SHOW_RENAME_MODAL:
      return {
        ...state,
        showRenameModal: action.payload,
      };
    case IMPORT_PAYROLL_SCHEDULE.SUCCEEDED:
      return {
        ...state,
        payrollSchedules: state.payrollSchedules.concat(
          formatPayPeriodLabels([action.payload.info.payroll_schedule]),
        ),
        showImportModal: false,
      };
    case SHOW_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: action.payload,
      };
    case EXPORT_PAYROLL_SCHEDULE.SUCCEEDED:
      return {
        ...state,
        showExportModal: true,
        documentName: action.payload.info.document_name,
        documentUrl: action.payload.info.document_url,
      };
    default:
      return state;
  }
};

const reportStatus = (state = STATUS.IDLE, action = {}) => {
  switch (action?.type) {
    case GET_PREVIOUS_PREMIUMS_REPORTS.STARTED:
      return STATUS.LOADING;
    case GET_PREVIOUS_PREMIUMS_REPORTS.SUCCEEDED:
      return STATUS.SUCCESS;
    case GET_PREVIOUS_PREMIUMS_REPORTS.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

const scheduleStatus = (state = STATUS.IDLE, action = {}) => {
  switch (action?.type) {
    case GET_PAYROLL_SCHEDULES.STARTED:
      return STATUS.LOADING;
    case GET_PAYROLL_SCHEDULES.SUCCEEDED:
      return STATUS.SUCCESS;
    case GET_PAYROLL_SCHEDULES.ERRORED:
      return STATUS.ERROR;
    default:
      return state;
  }
};

export const payrollReducers = combineReducers({
  reportsSchedules,
  reportStatus,
  scheduleStatus,
});
