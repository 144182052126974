import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { PlanEnrollmentPresenter } from './plan-enrollment.view';
import {
  getUserPlanEnrollment,
  removeUserPlanEnrollment,
} from './plan-enrollment.actions';
import {
  selectPlanEnrollments,
  selectStatus,
} from './plan-enrollment.selectors';
import { selectBenefitPlansById } from 'common/benefit-plans';

export const usePlanEnrollment = ({ userId, employeeGroupId: groupId }) => {
  const { planId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const status = useSelector(selectStatus);
  const planEnrollments = useSelector(selectPlanEnrollments);
  const benefitPlansById = useSelector(selectBenefitPlansById);
  const benefitPlan = benefitPlansById?.[planId];
  const onBackClick = () => history.goBack();

  useEffect(() => {
    dispatch(getUserPlanEnrollment({ userId, groupId }));
  }, [userId, groupId, dispatch]);

  const removePlanEnrollment = () =>
    dispatch(removeUserPlanEnrollment({ planId, userId, groupId }));

  return {
    status,
    benefitPlan,
    onBackClick,
    planId,
    removePlanEnrollment,
    planEnrollments: planEnrollments.filter(
      planEnrollment => planEnrollment.plan_id === planId,
    ),
  };
};

export const PlanEnrollment = props => {
  const planEnrollmentProps = usePlanEnrollment(props);
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  return <PlanEnrollmentPresenter {...planEnrollmentProps} />;
};
