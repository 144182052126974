// Returns a compare function for use with Array.sort() for arrays of objects.
// The compare function sorts based on the value the property specified by `prop` for each object.
// Example:
//  const array = [ { a: 2 }, { a: 1 } ];
//  array.sort(propertyCompareFn('a'));
// Returns:
//  [ { a: 1 }, { a: 2 } ]
export function propertyCompareFn(prop) {
  return function propertyCompare(a, b) {
    const aValue = a[prop];
    const bValue = b[prop];

    if (aValue > bValue) {
      return 1;
    }

    if (aValue < bValue) {
      return -1;
    }

    return 0;
  };
}
