import React from 'react';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { EntityFormField } from 'common/json-schema-form/fields';
import { selectGroupCurrency } from '../../selectors';
import { DEPENDENT_COVERAGE_OPTIONS } from '../enrollment-config.constants';
import { AmountByFieldWrapper } from './amount-by-field-wrapper.view';
import { MONEY_REF } from 'common/json-schema-form/json-schema-form.constants';

export const AmountBySelectionPresenter = ({
  name,
  propSchema,
  groupCurrency,
  ...props
}) => {
  const itemProperties = propSchema?.items?.properties ?? {};
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <AmountByFieldWrapper {...props} {...propSchema} name={name}>
      {({ idx }) => (
        <>
          <EntityFormField
            name={`${name}.${idx}.name`}
            propSchema={itemProperties.name}
          />
          <EntityFormField
            name={`${name}.${idx}.key`}
            propSchema={itemProperties.key}
          />
          <EntityFormField
            name={`${name}.${idx}.value`}
            propSchema={{
              enum: DEPENDENT_COVERAGE_OPTIONS,
              ...itemProperties.value,
            }}
          />
          <EntityFormField
            name={`${name}.${idx}.amount`}
            groupCurrency={groupCurrency}
            propSchema={{
              ...itemProperties.amount,
              $ref: MONEY_REF,
            }}
          />
        </>
      )}
    </AmountByFieldWrapper>
  );
};

AmountBySelectionPresenter.propTypes = JSONSchemaFieldPropType;

export const AmountBySelection = connect(state => ({
  groupCurrency: selectGroupCurrency(state),
}))(AmountBySelectionPresenter);
