import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getYearToDateReport } from '../employer-payroll.actions';
import { useYearToDateReport } from './use-year-to-date-report.hook';
import { YearToDateReportPresenter } from './year-to-date-report.view';
import { selectGroupBillingDivisions } from 'apps/employer-experience-v2/employer-divisions/employer-divisions.selectors';
import { selectBenefitClasses } from '../../Benefits/selectors';
import PropTypes from 'prop-types';
import {
  selectContentId,
  selectFileName,
  selectRequestStatus,
} from './year-to-date-report.selector';

export const YearToDateReport = ({ groupId }) => {
  const contentId = useSelector(selectContentId);
  const fileName = useSelector(selectFileName);
  const requestStatus = useSelector(selectRequestStatus);
  const billingDivisions = useSelector(selectGroupBillingDivisions);
  const benefitClasses = useSelector(selectBenefitClasses);
  const yearToDateReportProps = useYearToDateReport({
    billingDivisions,
    benefitClasses,
  });
  const dispatch = useDispatch();

  const onRequestReport = () => {
    dispatch(
      getYearToDateReport({
        user_ids: yearToDateReportProps.userIDs,
        group_id: groupId,
        start_date: yearToDateReportProps.startDateValue,
        end_date: yearToDateReportProps.endDateValue,
        benefit_class_id: yearToDateReportProps.benefitClassesValue,
        billing_division_id: yearToDateReportProps.billingDivisionsValue,
        pay_group: yearToDateReportProps.payGroupValue,
      }),
    );
  };

  return (
    <YearToDateReportPresenter
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...yearToDateReportProps}
      groupId={groupId}
      contentId={contentId}
      fileName={fileName}
      requestStatus={requestStatus}
      onRequestReport={onRequestReport}
    />
  );
};

YearToDateReport.propTypes = {
  groupId: PropTypes.string.isRequired,
};
