import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getUserDeductions = ({
  userId,
  groupId,
  benefitType,
  planPeriod,
}) =>
  call(websocketFetch, 'get_user_deductions', {
    user_id: userId,
    group_id: groupId,
    benefit_type: benefitType,
    plan_period: planPeriod,
  });
