import React from 'react';
import { Box, genesisStyled, css } from '@leagueplatform/genesis-commons';

const StyledGridItem = genesisStyled(Box)(
  ({ useGrid = true }) =>
    useGrid &&
    css({
      '&:nth-of-type(even)': {
        paddingLeft: [0, 0, 'one'],
      },
      '&:nth-of-type(odd)': {
        paddingRight: [0, 0, 'one'],
      },
    }),
);

export const GridItem = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <StyledGridItem position="relative" flexShrink={0} {...props} />
);
