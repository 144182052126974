import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies -- FIXME: automatically added for existing issue
import moment from 'moment';
import PropTypes from 'prop-types';
import { Date } from 'common/adaptive-forms';

const EmployeeDayPicker = props => (
  <div>
    {/* eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue */}
    {props.values.employer_benefit_start_date &&
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    props.values.employer_benefit_end_date ? (
      <Date
        dayPickerProps={{
          disabledDays: [
            {
              // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
              before: moment(props.values.employer_benefit_start_date).toDate(),
              // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
              after: moment(props.values.employer_benefit_end_date).toDate(),
            },
          ],
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
      />
    ) : (
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      <Date {...props} />
    )}
  </div>
);

EmployeeDayPicker.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      employer_benefit_start_date: PropTypes.string,
      employer_benefit_end_date: PropTypes.string,
    }),
  ),
};

EmployeeDayPicker.defaultProps = {
  values: {},
};

export default EmployeeDayPicker;
