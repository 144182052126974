import React from 'react';
import PropTypes from 'prop-types';
import { EnrollmentView } from './enrollment.view';

export const EnrollmentContainer = ({ groupId, dashboards }) => {
  if (!dashboards || dashboards.length <= 0) return null;

  return <EnrollmentView dashboards={dashboards} groupId={groupId} />;
};

EnrollmentContainer.propTypes = {
  groupId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  dashboards: PropTypes.arrayOf(PropTypes.object),
};

EnrollmentContainer.defaultProps = {
  dashboards: [],
};
