import { createSelector } from 'reselect';
import { selectGroupCurrency } from 'apps/employer-experience-v2/app.selectors';

export const selectEmployeeUpdateFlexDollars = state =>
  state?.apps['employer-experience']?.employeeUpdateFlexDollars ?? {};

export const selectTotalFlexDollars = createSelector(
  selectEmployeeUpdateFlexDollars,
  selectGroupCurrency,
  ({ totalFlexDollars }, groupCurrency) =>
    totalFlexDollars || { F: 0, M: 0, C: groupCurrency },
);

export const selectLoadedFlexDollars = createSelector(
  selectEmployeeUpdateFlexDollars,
  selectGroupCurrency,
  ({ loadedFlexDollars }, groupCurrency) =>
    loadedFlexDollars || { F: 0, M: 0, C: groupCurrency },
);
