import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/genesis-commons';
import { FormValidationBanner } from './form-validation-banner.view';
import { WARNING_SEVERITY } from '../enrollment-config.constants';
import {
  getWarningsOfSeverity,
  warningMessageShape,
} from '../plan-options-validation/plan-options-validation.utils';

export const FormValidationBanners = ({ validationWarnings, ...props }) => {
  const getValidationMessages = useCallback(
    severity => {
      return validationWarnings
        .filter(getWarningsOfSeverity(severity))
        .map(({ message }) => message);
    },
    [validationWarnings],
  );

  const errorMessages = getValidationMessages(WARNING_SEVERITY.ERROR);
  const warningMessages = getValidationMessages(WARNING_SEVERITY.WARNING);

  return (
    <Box>
      <FormValidationBanner
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        severity={WARNING_SEVERITY.ERROR}
        messages={errorMessages}
      />
      <FormValidationBanner
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        severity={WARNING_SEVERITY.WARNING}
        messages={warningMessages}
      />
    </Box>
  );
};

FormValidationBanners.propTypes = {
  validationWarnings: PropTypes.arrayOf(warningMessageShape),
};

FormValidationBanners.defaultProps = {
  validationWarnings: [],
};
