import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, genesisStyled, css } from '@leagueplatform/genesis-commons';
import { Backdrop, ClickEater } from '@leagueplatform/ui-kit';
import { noop } from 'lodash';

export const Segment = props => (
  <Box
    borderBottomColor="onSurface.border.subdued"
    borderBottomStyle="solid"
    borderBottomWidth="thin"
    marginY="one"
    paddingY="half"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

export const Required = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Box color="critical.border.default" {...props}>
    *
  </Box>
);

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
export const ModalContent = props => <Box marginY="oneAndHalf" {...props} />;

export const ModalButtons = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Flex align="center" justifyContent="flex-end" {...props} />
);

export const WhiteSpacePreWrap = genesisStyled(Box)`
  white-space: pre-wrap;
`;

const CloseButtonContainer = genesisStyled(Box)`
  transition: opacity 250ms ease-out;
  cursor: pointer;
  position: absolute;
  &:hover {
    opacity: 0.75;
  }
`;

const ModalContainer = genesisStyled(Flex)(
  css({
    position: 'relative',
    whiteSpace: 'normal',

    '&& a': {
      color: 'decorative.brand.primary.default',
    },
  }),
);

export const CloseButton = props => (
  <CloseButtonContainer
    fontSize={48}
    lineHeight={0.75}
    size={48}
    justifyContent="center"
    alignItems="center"
    textAlign="center"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    &times;
  </CloseButtonContainer>
);

export const Modal = ({ onClose, open, children, ...props }) => {
  /* the `open` prop is to be able to match the semantic UI modal api for the cases
    where we don't want to update a bunch of the component's internal working but need to switch out
    the semantic modal because it uses React.Portal in a way that can sometimes break intl context.

    So, if it's not defined we assume that the modal is implemented in a the `someVar && <Modal...` rendering
    and can safely just return the component.
  */
  const visible = typeof open !== 'undefined' ? open : true;
  return visible ? (
    <Backdrop onClose={onClose}>
      <ModalContainer
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        maxWidth={960}
        backgroundColor="surface.background.primary"
        borderRadius="medium"
        padding="twoAndHalf"
        maxHeight="100vh"
        textAlign="left"
      >
        <ClickEater>{children}</ClickEater>
      </ModalContainer>
    </Backdrop>
  ) : null;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  onClose: noop,
  open: undefined,
};
