/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { request } from 'common/websocket-redux';
import { takeLatest, select, put, call } from 'redux-saga/effects';
import { selectGroupId } from '../Employee/selectors';
import {
  GET_EMPLOYER_ENROLMENT_REPORT,
  GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT,
  GET_EMPLOYEE_DEMOGRAPHICS_REPORT,
  EMPLOYER_REPORTS_LIFECYCLE,
  GET_EMPLOYER_HR_DRIVERS,
  GET_EMPLOYER_BENEFITS_REPORT,
  GET_BENEFICIARIES_REPORT,
} from './employer-reports.types';
import {
  getEmployerDrivers,
  getGroupEnrollmentReport,
  getGroupEnrollmentProgressReport,
  getEmployeeDemographicsReport,
  getEmployerBenefitsReport,
} from 'common/services';
import { getBeneficiariesReport } from 'common/services/get-beneficiaries-report.service';
import {
  REPORT_GENERIC_ERROR,
  REPORT_NO_DATA_ERROR,
  REPORT_NO_DATA_ERROR_REASON,
} from 'apps/employer-experience-v2/employer-reports/reports.constants';
import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
  ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
  ANALYTICS_EVENT_DOWNLOAD_FAILURE,
} from '../../../../employer-experience-v2/constants';

const getErrorMessageId = error =>
  error?.info?.reason === REPORT_NO_DATA_ERROR_REASON
    ? REPORT_NO_DATA_ERROR
    : REPORT_GENERIC_ERROR;

export function* getEmployerEnrolmentReport({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;

  try {
    yield put(GET_EMPLOYER_ENROLMENT_REPORT.start());

    const response = yield getGroupEnrollmentReport(rest);
    const contentId = response?.info?.content_id;

    if (!contentId) throw new Error('No content id');

    yield put(GET_EMPLOYER_ENROLMENT_REPORT.success(contentId));

    const url = getContentUrl(contentId);

    window.open(url, '_blank', 'noopener=yes, noreferrer=yes');

    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'enrollment',
        detail: 'generate enrollment report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    yield put(
      GET_EMPLOYER_ENROLMENT_REPORT.error({ error: getErrorMessageId(error) }),
    );
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'enrollment',
        detail: 'generate enrollment report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
  }
}

export function* getEmployerEnrolmentProgressReport({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;

  try {
    yield put(GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT.start());
    const response = yield getGroupEnrollmentProgressReport(rest);

    const contentId =
      response?.info?.report?.report_files?.[0]?.content_id ??
      response?.info?.report?.content_id;

    if (!contentId) throw new Error('No content id');

    yield put(GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT.success(contentId));
    const url = getContentUrl(contentId);

    window.open(url, '_blank', 'noopener=yes, noreferrer=yes');

    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'enrollment progress',
        detail: 'generate enrollment progress report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'enrollment progress',
        detail: 'generate enrollment progress report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }

    yield put(
      GET_EMPLOYER_ENROLMENT_PROGRESS_REPORT.error({
        error: getErrorMessageId(error),
      }),
    );
  }
}

export function* getEmployeeDemographicsReportSaga({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;
  try {
    yield put(GET_EMPLOYEE_DEMOGRAPHICS_REPORT.start());

    const response = yield getEmployeeDemographicsReport(rest);
    const contentId = response?.info?.report?.report_files?.[0]?.content_id;

    if (!contentId) throw new Error('No content id');

    yield put(GET_EMPLOYEE_DEMOGRAPHICS_REPORT.success(contentId));
    const url = getContentUrl(contentId);

    window.open(url, '_blank', 'noopener=yes, noreferrer=yes');
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'employee demograpics',
        detail: 'generate employee demographics report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    yield put(
      GET_EMPLOYEE_DEMOGRAPHICS_REPORT.error({
        error: getErrorMessageId(error),
      }),
    );
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'employee demograpics',
        detail: 'generate employee demographics report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
  }
}

export function* getEmployerBenefitsReportSaga({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;
  try {
    yield put(GET_EMPLOYER_BENEFITS_REPORT.start());

    const response = yield getEmployerBenefitsReport(rest);
    const contentId = response?.info?.report?.report_files?.[0]?.content_id;

    if (!contentId) throw new Error('No content id');

    yield put(GET_EMPLOYER_BENEFITS_REPORT.success(contentId));
    const url = getContentUrl(contentId);

    window.open(url, '_blank', 'noopener=yes, noreferrer=yes');
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'benefits',
        detail: 'generate benefits report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    yield put(
      GET_EMPLOYER_BENEFITS_REPORT.error({
        error: getErrorMessageId(error),
      }),
    );
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'benefits',
        detail: 'generate benefits report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
  }
}

export function* getBeneficiariesReportRequest({ payload }) {
  const { isInHRAdminPortal, ...rest } = payload;
  try {
    yield put(GET_BENEFICIARIES_REPORT.start());

    const response = yield getBeneficiariesReport(rest);
    const contentId = response?.info?.report?.report_files?.[0]?.content_id;

    if (!contentId) throw new Error('No content id');

    yield put(GET_BENEFICIARIES_REPORT.success(contentId));
    const url = getContentUrl(contentId);

    window.open(url, '_blank', 'noopener=yes, noreferrer=yes');

    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'beneficiaries',
        detail: 'generate beneficiaries report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    yield put(
      GET_BENEFICIARIES_REPORT.error({
        error: getErrorMessageId(error),
      }),
    );
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'beneficiaries',
        detail: 'generate beneficiaries report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
  }
}

export function* initializeReportsPage() {
  const groupId = yield select(selectGroupId);
  yield request(GET_EMPLOYER_HR_DRIVERS, getEmployerDrivers(groupId));
}

export function* employerReportsSaga() {
  yield takeLatest(EMPLOYER_REPORTS_LIFECYCLE.VISITED, initializeReportsPage);
  yield takeLatest(
    GET_BENEFICIARIES_REPORT.BASE,
    getBeneficiariesReportRequest,
  );
}
