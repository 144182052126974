import React, { useEffect } from 'react';
import { Switch, Route, matchPath } from 'react-router-dom';
import BenefitClassSelection from './benefit-class-selection/benefit-class-selection.container';
import { QleConfigurationFormContainer } from './qle-configuration-form/qle-configuration-form.container';
import QLEConfigurationTable from './qle-configuration-table/qle-configuration-table.container';
import { Box } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { fullQLERoutePattern } from './qle-configuration.constants';

/**
 * Container for QLE Configuration tool. Handles:
 * 1) Routing
 * 2) If benefit class id in url, fetch its qle configurations
 * 3) Always fetch HR emails on component mount
 *
 * Routing:
 * Each page of the QLE configuration flow is defined here. The general flow is:
 * Select benefit class > See all QLE configurations in table > Select QLE configuration > Add/Edit configuration
 */
export const QLEConfiguration = ({
  match,
  history,
  getQLEConfigurations,
  groupId,
  getEmployerQLEApprovalEmails,
  qleApprovalEmails,
  setBenefitClassId,
}) => {
  const { path } = match;
  // Always fetch HR emails on load
  useEffect(() => {
    if (!Array.isArray(qleApprovalEmails)) {
      getEmployerQLEApprovalEmails({ groupId });
    }
  }, [getEmployerQLEApprovalEmails, qleApprovalEmails, groupId]);

  useEffect(() => {
    /**
     * Make sure we have the necessary data ready whenever the QLE config tool
     * is visited. On component mount, this fetches QLE configurations if benefit class id is in
     * the URL and QLE configs are in initial `null` state.
     *
     * React router's match params will not be the same, due to each component having their own routes:
     * Example:
     * Say we're on `/qle-config/c71dc4143ea8149dbe2c1a064c261af6`.
     * QLEConfiguration will render and the containing component. It will then render QLEConfigurationTable
     * since it matches that child's route.
     *
     * QLEConfiguration's match will have no params...
     * While the QLEConfigurationTable will have `{ benefitClassId: 'c71dc4143ea8149dbe2c1a064c261af6' }`!
     *
     * Thus, use `matchPath` to read from url and setup Redux state appropriately.
     */
    const customMatch = matchPath(history.location.pathname, {
      path: fullQLERoutePattern,
    });
    const benefitClassId = get(customMatch, 'params.benefitClassId');

    if (benefitClassId) {
      setBenefitClassId({ benefitClassId });
      getQLEConfigurations({ benefitClassId, groupId });
    }
  }, [
    history.location.pathname,
    path,
    getQLEConfigurations,
    groupId,
    setBenefitClassId,
  ]);

  return (
    <Box>
      <Box />
      {/* Switch must be child so it will render components within the container */}
      <Switch>
        <Route
          path={`${path}/:benefitClassId/:qleType/:qleConfigurationId?`}
          component={QleConfigurationFormContainer}
        />
        <Route
          exact
          path={`${path}/:benefitClassId`}
          component={QLEConfigurationTable}
        />
        {/* Default path, which at this point, should be `admin/employers/:employerId/benefits/qle-config` */}
        <Route path={path} component={BenefitClassSelection} />
      </Switch>
    </Box>
  );
};

QLEConfiguration.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  getQLEConfigurations: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  getEmployerQLEApprovalEmails: PropTypes.func.isRequired,
  qleApprovalEmails: PropTypes.arrayOf(PropTypes.string),
  setBenefitClassId: PropTypes.func.isRequired,
};

QLEConfiguration.defaultProps = {
  qleApprovalEmails: null, // Also need to be null to indicate if it should be fetched!
};
