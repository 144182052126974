import rbcInsurance from './rbcInsurance';
import gwlInsurance from './gwlInsurance';
import addEmployee from './addEmployee';
import viewEditEmployee from './viewEditEmployee';
import spendingAccountBenefit from './spendingAccountBenefit';
import aetnaDental from './aetnaDental';
import aetnaMedical from './aetnaMedical';
import gwlBestDoctors from './gwlBestDoctors';
import gwlDental from './gwlDental';
import gwlDrugs from './gwlDrugs';
import gwlEmployeeAssistanceProgram from './gwlEmployeeAssistanceProgram';
import gwlHealthcare from './gwlHealthcare';
import gwlLifeADD from './gwlLifeADD';
import gwlLTD from './gwlLTD';
import gwlParamedical from './gwlParamedical';
import gwlTravel from './gwlTravel';
import lfLTD from './lfLTD';
import lfSTD from './lfSTD';
import lfVoluntaryLifeADD from './lfVolluntaryLifeADD';
import leagueCGA from './leagueCGA';
import leagueHSA from './leagueHSA';
import leagueLSA from './leagueLSA';
import nwPet from './nwPet';
import unumCriticalIllnes from './unumCriticalIllnes';
import vspVision from './vspVision';
import employeeLSA from './employeeLayouts/employeeLSA';
import employeeUSDental from './employeeLayouts/employeeUSDental';
import employeeUSMedical from './employeeLayouts/employeeUSMedical';
import employeenwPet from './employeeLayouts/employeeNWPet';
import employeeUSSTD from './employeeLayouts/employeeUSSTD';
import employeeUSLTD from './employeeLayouts/employeeUSLTD';
import employeeUSLifeADD from './employeeLayouts/employeeUSLifeADD';
import employeeRbcInsurance from './employeeLayouts/employeeRbcInsurance';
import employeeBestDoctors from './employeeLayouts/employeeBestDoctors';
import employeeGwlDrugs from './employeeLayouts/employeeGwlDrugs';
import employeeGwlDental from './employeeLayouts/employeeGwlDental';
import employeeGwlTravel from './employeeLayouts/employeeGwlTravel';
import employeeRbcDrug from './employeeLayouts/employeeRbcDrug';
import employeeGwlRBCAssistanceProgram from './employeeLayouts/employeeGwlRBCAssistanceProgram';
import employeeUnumCriticalIllness from './employeeLayouts/employeeUnumCriticalIllness';
import employeeUnumInsuranceAccident from './employeeLayouts/employeeUnumInsuranceAccident';
import employeeUSVision from './employeeLayouts/employeeUSVision';
import employeeHealthSavings from './employeeLayouts/employeeHealthSavings';
import employeeRBCDental from './employeeLayouts/employeeRBCDental';
import employeeRBCTravel from './employeeLayouts/employeeRBCTravel';
import employeeGwlRBCLifeADD from './employeeLayouts/employeeGwlRBCLifeADD';
import employeeGwlRBCParamedical from './employeeLayouts/employeeGwlRBCParamedical';
import employeeGWLMedical from './employeeLayouts/employeeGWLMedical';
import employeeRBCMedicalServices from './employeeLayouts/employeeRBCMedicalServices';
import lifeworksEAP from './lifeworksEAP';
import employeeLifeworksEAP from './employeeLayouts/employeeLifeworksEAP';

export default {
  ...rbcInsurance,
  ...gwlInsurance,
  ...addEmployee,
  ...viewEditEmployee,
  ...spendingAccountBenefit,
  ...employeeLSA,
  ...aetnaDental,
  ...aetnaMedical,
  ...gwlBestDoctors,
  ...gwlDental,
  ...gwlDrugs,
  ...gwlEmployeeAssistanceProgram,
  ...lifeworksEAP,
  ...gwlHealthcare,
  ...gwlLifeADD,
  ...gwlLTD,
  ...gwlParamedical,
  ...gwlTravel,
  ...lfLTD,
  ...lfSTD,
  ...lfVoluntaryLifeADD,
  ...leagueCGA,
  ...leagueHSA,
  ...leagueLSA,
  ...nwPet,
  ...unumCriticalIllnes,
  ...vspVision,
  ...employeeUSDental,
  ...employeeUSMedical,
  ...employeenwPet,
  ...employeeUSSTD,
  ...employeeUSLTD,
  ...employeeUSLifeADD,
  ...employeeRbcInsurance,
  ...employeeBestDoctors,
  ...employeeGwlDrugs,
  ...employeeGwlDental,
  ...employeeGwlTravel,
  ...employeeRbcDrug,
  ...employeeGwlRBCAssistanceProgram,
  ...employeeUnumCriticalIllness,
  ...employeeUnumInsuranceAccident,
  ...employeeUSVision,
  ...employeeHealthSavings,
  ...employeeRBCDental,
  ...employeeRBCTravel,
  ...employeeGwlRBCLifeADD,
  ...employeeGwlRBCParamedical,
  ...employeeRBCMedicalServices,
  ...employeeGWLMedical,
  ...employeeLifeworksEAP,
};
