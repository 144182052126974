import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  Box,
  HeadingTwo,
  Label,
  LabelText,
  PrimaryButton,
  SecondaryButton,
  Link,
} from '@leagueplatform/genesis-commons';
import { ModalButtons, ModalContent } from 'apps/employer-experience/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { Modal } from '@leagueplatform/ui-kit';
import { Input } from 'semantic-ui-react';

const templateCsv =
  'Deduction Code,Premium Type,Benefit Type,Benefit Plan Name,Dependent Coverage Level,Province Of Residence,Province Of Employment,Pay Calendar\n';

export const ImportDeductionCodePresenter = ({
  groupId,
  onClose,
  importDeductionCodes,
}) => {
  const [file, setFile] = useState(null);
  const handleFileSelection = ({ target }) => {
    setFile(target.files[0]);
  };
  const { formatMessage } = useIntl();
  return (
    <Modal.Wrapper onClose={onClose}>
      <Box
        backgroundColor="surface.background.primary"
        padding="two"
        maxWidth={750}
      >
        <HeadingTwo>
          {formatMessage({ id: 'UPLOAD_DEDUCTION_CODES' })}
        </HeadingTwo>
        <ModalContent>
          <BodyOne>
            {formatMessage({ id: 'IMPORT_DEDUCTION_CODES_COPY' })}
          </BodyOne>
          <BodyOne>
            <Link
              textAlign={{ tablet: 'right' }}
              marginTop={['one', 'one', 'none']}
              target="_blank"
              href={`data:text/csv;charset=utf-8,${encodeURI(templateCsv)}`}
              download="deduction-code-template.csv"
            >
              {formatMessage({ id: 'DOWNLOAD_TEMPLATE' })}
            </Link>
          </BodyOne>
          <Label marginTop="oneAndHalf">
            <LabelText>{formatMessage({ id: 'CHOOSE_FILE' })}</LabelText>
            <Input type="file" onChange={handleFileSelection} />
          </Label>
        </ModalContent>
        <ModalButtons>
          <SecondaryButton marginRight="one" onClick={onClose}>
            {formatMessage({ id: 'CANCEL' })}
          </SecondaryButton>
          <PrimaryButton
            disabled={!file}
            onClick={() => {
              importDeductionCodes({ groupId, file });
              onClose();
            }}
          >
            {formatMessage({ id: 'IMPORT' })}
          </PrimaryButton>
        </ModalButtons>
      </Box>
    </Modal.Wrapper>
  );
};

ImportDeductionCodePresenter.propTypes = {
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  importDeductionCodes: PropTypes.func.isRequired,
};
