import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedDate } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Table, Label } from 'semantic-ui-react';

import { Text } from '@leagueplatform/ui-kit';

const StyledRow = styled(Table.Row)`
  &:hover {
    cursor: pointer;
  }
`;

const EmployerTableRow = ({
  groupName,
  groupId,
  dateCreated,
  status,
  newBenefits,
  onSelect,
}) => (
  <StyledRow onClick={() => onSelect(groupId, newBenefits)}>
    <Table.Cell className="title-cell">
      <div className="title-cell__name">
        {newBenefits ? (
          groupName
        ) : (
          <span>
            {groupName}
            <span className="title-cell__legacy">legacy</span>
          </span>
        )}
      </div>
      <Text className="title-cell__id">{groupId}</Text>
    </Table.Cell>
    <Table.Cell>
      <Label className={`label-${status}`} icon="circle" content={status} />
    </Table.Cell>
    <Table.Cell>
      <FormattedDate
        value={dateCreated}
        day="numeric"
        month="short"
        year="numeric"
      />
    </Table.Cell>
  </StyledRow>
);

export const employerPropTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  newBenefits: PropTypes.bool.isRequired,
};

EmployerTableRow.propTypes = {
  onSelect: PropTypes.func.isRequired,
  ...employerPropTypes,
};

export default withRouter(EmployerTableRow);
