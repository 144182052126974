export default {
  aetnaMedical: {
    name: 'aetnaMedical',
    groups: [
      {
        title: 'Policy Period',
        fields: [
          {
            name: 'benefit_start_date',
            span: 6,
          },
          {
            name: 'benefit_end_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Downloadable Files',
        fields: [
          {
            name: 'documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Settings',
        fields: [
          {
            name: 'benefit_available_grace_period_days',
            span: 4,
          },
          {
            name: 'policy_id',
            span: 4,
          },
          {
            name: 'opt_out',
            span: 4,
          },

          {
            name: 'auto_renew',
            span: 4,
          },

          {
            name: 'network_name',
            span: 4,
          },
          {
            name: 'physical_card',
            span: 4,
          },
        ],
      },
      {
        title: 'Advanced',
        fields: [
          {
            name: 'in_network_deductible_single',
            span: 6,
          },

          {
            name: 'in_network_deductible_family',
            span: 6,
          },

          {
            name: 'in_network_embedded_or_non-embedded_deductible',
            span: 6,
          },

          {
            name: 'in_network_out-of-pocket_maximum_single',
            span: 6,
          },

          {
            name: 'in_network_out-of-pocket_maximum_family',
            span: 6,
          },

          {
            name: 'in_network_primary_care_visit',
            span: 6,
          },

          {
            name: 'in_network_specialist_visit',
            span: 6,
          },

          {
            name: 'in_network_preventive_care',
            span: 6,
          },

          {
            name: 'in_network_lab_and_x-ray_services',
            span: 6,
          },

          {
            name: 'in_network_imaging',
            span: 6,
          },

          {
            name: 'in_network_rx_deductible_single',
            span: 6,
          },

          {
            name: 'in_network_rx_deductible_family',
            span: 6,
          },

          {
            name: 'in_network_retail_preferred_generic',
            span: 6,
          },

          {
            name: 'in_network_retail_non-preferred_generic',
            span: 6,
          },

          {
            name: 'in_network_retail_preferred_brand',
            span: 6,
          },

          {
            name: 'in_network_retail_non-preferred_brand',
            span: 6,
          },

          {
            name: 'in_network_retail_preferred_specialty',
            span: 6,
          },

          {
            name: 'in_network_retail_non-preferred_specialty',
            span: 6,
          },

          {
            name: 'in_network_mail_preferred_generic',
            span: 6,
          },

          {
            name: 'in_network_mail_non-preferred_generic',
            span: 6,
          },

          {
            name: 'in_network_mail_preferred_brand',
            span: 6,
          },

          {
            name: 'in_network_mail_non-preferred_brand',
            span: 6,
          },

          {
            name: 'in_network_mail_preferred_specialty',
            span: 6,
          },

          {
            name: 'in_network_mail_non-preferred_specialty',
            span: 6,
          },

          {
            name: 'in_network_outpatient_surgery_facility_fee',
            span: 6,
          },

          {
            name: 'in_network_outpatient_surgery_physician/surgeon_fee',
            span: 6,
          },

          {
            name: 'in_network_inpatient_facility_fee',
            span: 6,
          },

          {
            name: 'in_network_inpatient_physician/surgery_fee',
            span: 6,
          },

          {
            name: 'in_network_emergency_room_visit',
            span: 6,
          },

          {
            name: 'in_network_emergency_medical_transportation',
            span: 6,
          },

          {
            name: 'in_network_urgent_care',
            span: 6,
          },

          {
            name: 'in_network_mental_behavioural_outpatient_services',
            span: 6,
          },

          {
            name: 'in_network_mental_behavioural_inpatient_services',
            span: 6,
          },

          {
            name: 'in_network_maternal_office_visits',
            span: 6,
          },

          {
            name: 'in_network_childbirth_and_delivery_professional_services',
            span: 6,
          },

          {
            name: 'in_network_childbirth_and_delivery_facility_services',
            span: 6,
          },

          {
            name: 'in_network_home_health_care',
            span: 6,
          },

          {
            name: 'in_network_rehabilitation_services',
            span: 6,
          },

          {
            name: 'in_network_habilitation_services',
            span: 6,
          },

          {
            name: 'in_network_skilled_nursing',
            span: 6,
          },

          {
            name: 'in_network_durable_medical_equipment',
            span: 6,
          },

          {
            name: 'in_network_hospice_services',
            span: 6,
          },

          {
            name: 'in_network_child_dental',
            span: 6,
          },

          {
            name: 'in_network_child_vision',
            span: 6,
          },

          {
            name: 'in_network_child_glasses',
            span: 6,
          },

          {
            name: 'out_of_network_deductible_single',
            span: 6,
          },

          {
            name: 'out_of_network_deductible_family',
            span: 6,
          },

          {
            name: 'out_of_network_out-of-pocket_maximum_single',
            span: 6,
          },

          {
            name: 'out_of_network_out-of-pocket_maximum_family',
            span: 6,
          },

          {
            name: 'out_of_network_primary_care_visit',
            span: 6,
          },

          {
            name: 'out_of_network_specialist_visit',
            span: 6,
          },

          {
            name: 'out_of_network_preventive_care',
            span: 6,
          },

          {
            name: 'out_of_network_lab_and_x-ray_services',
            span: 6,
          },

          {
            name: 'out_of_network_imaging',
            span: 6,
          },

          {
            name: 'out_of_network_rx_deductible_single',
            span: 6,
          },

          {
            name: 'out_of_network_rx_deductible_family',
            span: 6,
          },

          {
            name: 'out_of_network_preferred_generic',
            span: 6,
          },

          {
            name: 'out_of_network_non-preferred_generic',
            span: 6,
          },

          {
            name: 'out_of_network_preferred_brand',
            span: 6,
          },

          {
            name: 'out_of_network_non-preferred_brand',
            span: 6,
          },

          {
            name: 'out_of_network_preferred_specialty',
            span: 6,
          },

          {
            name: 'out_of_network_non-preferred_specialty',
            span: 6,
          },

          {
            name: 'out_of_network_outpatient_surgery_facility_fee',
            span: 6,
          },

          {
            name: 'out_of_network_outpatient_surgery_physician/surgeon_fee',
            span: 6,
          },

          {
            name: 'out_of_network_inpatient_facility_fee',
            span: 6,
          },

          {
            name: 'out_of_network_inpatient_physician/surgery_fee',
            span: 6,
          },

          {
            name: 'out_of_network_emergency_room_visit',
            span: 6,
          },

          {
            name: 'out_of_network_emergency_medical_transportation',
            span: 6,
          },

          {
            name: 'out_of_network_urgent_care',
            span: 6,
          },

          {
            name: 'out_of_network_mental_behavioural_outpatient_services',
            span: 6,
          },

          {
            name: 'out_of_network_mental_behavioural_inpatient_services',
            span: 6,
          },

          {
            name: 'out_of_network_maternal_office_visits',
            span: 6,
          },

          {
            name: 'out_of_network_childbirth_and_delivery_professional_services',
            span: 6,
          },

          {
            name: 'out_of_network_childbirth_and_delivery_facility_services',
            span: 6,
          },

          {
            name: 'out_of_network_home_health_care',
            span: 6,
          },

          {
            name: 'out_of_network_rehabilitation_services',
            span: 6,
          },

          {
            name: 'out_of_network_habilitation_services',
            span: 6,
          },

          {
            name: 'out_of_network_skilled_nursing',
            span: 6,
          },

          {
            name: 'out_of_network_durable_medical_equipment',
            span: 6,
          },

          {
            name: 'out_of_network_hospice_services',
            span: 6,
          },

          {
            name: 'out_of_network_child_dental',
            span: 6,
          },

          {
            name: 'out_of_network_child_vision',
            span: 6,
          },

          {
            name: 'out_of_network_child_glasses',
            span: 6,
          },
          { name: 'out_of_network_retail_preferred_generic', span: 6 },

          { name: 'out_of_network_retail_non-preferred_generic', span: 6 },

          { name: 'out_of_network_retail_preferred_brand', span: 6 },

          { name: 'out_of_network_retail_non-preferred_brand', span: 6 },

          { name: 'out_of_network_retail_preferred_specialty', span: 6 },

          { name: 'out_of_network_retail_non-preferred_specialty', span: 6 },

          { name: 'out_of_network_mail_preferred_generic', span: 6 },

          { name: 'out_of_network_mail_non-preferred_generic', span: 6 },

          { name: 'out_of_network_mail_preferred_brand', span: 6 },

          { name: 'out_of_network_mail_non-preferred_brand', span: 6 },

          { name: 'out_of_network_mail_preferred_specialty', span: 6 },

          { name: 'out_of_network_mail_non-preferred_specialty', span: 6 },
          {
            name: 'preferred_medical_network_available',
            span: 6,
          },
        ],
      },
    ],
  },
};
