import { connect } from 'react-redux';
import {
  selectBenefitPlanEntities,
  selectIsLoadingByClassId,
  selectStructure,
  selectAddingBenefitPlan,
  selectErrorAddingBenefitPlan,
  selectErrorRenamingBenefitPlan,
  selectRenamingBenefitPlan,
  selectIsLastBenefitPlan,
  selectHasCatalogue,
} from './selectors';
import { selectGroupId } from '../../../selectors';
import {
  addBenefitPlan,
  renameBenefitPlan,
  removeBenefitPlan,
  fetchCatalogue,
  fetchBenefitTypeSchemas,
  fetchBenefits,
} from './actions';
import { Plans } from './plans.view';

const mapStateToProps = (state, { benefitClassId }) => ({
  benefitClassId,
  allBenefitPlanEntities: selectBenefitPlanEntities(state),
  structure: selectStructure(state),
  loading: selectIsLoadingByClassId(state, { benefitClassId }),
  errorAddingBenefitPlan: selectErrorAddingBenefitPlan(state),
  addingBenefitPlan: selectAddingBenefitPlan(state),
  groupId: selectGroupId(state),
  errorRenamingBenefitPlan: selectErrorRenamingBenefitPlan(state),
  renamingBenefitPlan: selectRenamingBenefitPlan(state),
  isLastBenefitPlan: selectIsLastBenefitPlan(state),
  hasCatalogue: selectHasCatalogue(state),
});

export default connect(mapStateToProps, {
  addBenefitPlan,
  renameBenefitPlan,
  removeBenefitPlan,
  fetchCatalogue,
  fetchBenefits,
  fetchBenefitTypeSchemas,
})(Plans);
