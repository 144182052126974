/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { createSelector } from 'reselect';
import {
  selectUserId,
  selectPreferredFirstName,
  selectUserHomeProvince,
  selectUserProfile,
} from 'common/state/user/user.selectors';
import { selectApp } from '../../../state/selectors';
import { CHAT_STATES, CHAT_MODALS } from './chat-window.constants';

export const selectChatWindow = createSelector(
  selectApp,
  app => app.pages.chatWindow,
);

export const selectAgentType = createSelector(
  selectChatWindow,
  state => state.agentType,
);

export const selectChatMap = createSelector(
  selectChatWindow,
  state => state.chatMap,
);

export const selectChatConfig = createSelector(
  selectChatWindow,
  state => state.chatConfig,
);

export const selectAgentConfig = createSelector(
  selectChatWindow,
  selectAgentType,
  (state, agentType) =>
    Object.values(state.agentConfig.availabilities).find(
      ({ agent_type }) => agent_type === agentType,
    ).chat_config,
);

export const selectWaitTime = createSelector(selectChatWindow, state =>
  Math.max(0, Math.ceil((state.waitTime ?? 0) / 60)),
);

export const selectQueuePosition = createSelector(
  selectChatWindow,
  state => state.queuePosition ?? 0,
);

export const selectIsChatEstablished = createSelector(
  selectChatWindow,
  state => state.chatState === CHAT_STATES.ESTABLISHED,
);

export const selectHasChatFailedToLoad = createSelector(
  selectChatWindow,
  state => state.chatState === CHAT_STATES.FAILED,
);

export const selectLiveagentParams = createSelector(
  selectAgentConfig,
  selectChatMap,
  selectChatConfig,
  selectUserId,
  selectPreferredFirstName,
  selectUserProfile,
  selectUserHomeProvince,
  (
    agentConfig,
    chatMap,
    chatConfig,
    userId,
    firstName,
    { lastName },
    province,
  ) => ({
    agentConfig,
    chatMap,
    chatConfig,
    userId,
    firstName,
    lastName,
    province,
  }),
);

export const selectIsEndChatModalOpen = createSelector(
  selectChatWindow,
  state => state.modal === CHAT_MODALS.END_CHAT,
);

export const selectIsChatReconnectingModalOpen = createSelector(
  selectChatWindow,
  state => state.modal === CHAT_MODALS.CHAT_RECONNECTING,
);

export const selectIsChatFailedModalOpen = createSelector(
  selectChatWindow,
  state => state.modal === CHAT_MODALS.CHAT_FAILED,
);

export const selectIsChatConnectionLostModalOpen = createSelector(
  selectChatWindow,
  state => state.modal === CHAT_MODALS.CHAT_CONNECTION_LOST,
);

export const selectChatIframeOrigin = createSelector(
  selectChatWindow,
  state => state.chatIframeOrigin,
);
