import React from 'react';
import PropTypes from 'prop-types';

import { ActionsConfirmationDateFormContainer } from '../actions-dropdown/actions-confirmation-date-form.container';

export const DateInputForm = props => {
  return (
    <ActionsConfirmationDateFormContainer
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      setActionEffectiveDate={val => props.update({ effectiveDate: val })}
      isDateInvalid={false}
      setActionInvalidDate={() => {}}
      displayInline
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    />
  );
};

DateInputForm.propTypes = {
  update: PropTypes.func.isRequired,
};

export default DateInputForm;
