import { useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from '@leagueplatform/locales';
import { utcToZonedTime } from 'date-fns-tz';
import { updateUserDeductions } from 'apps/employer-experience/pages/EmployerDetails/Employee/actions';
import { useDispatch } from 'react-redux';

export const usePayrollDeductionsRow = ({ payrollDeduction }) => {
  const dispatch = useDispatch();
  const initValue = payrollDeduction?.employee_cost?.total ?? 0;
  const [value, setValue] = useState(initValue);
  const [costType, setCostType] = useState('employee_cost');
  const { formatMessage } = useIntl();
  const selectOptions = ({ value: costTypeValue }) => {
    setCostType(costTypeValue);
    if (payrollDeduction?.[costTypeValue]?.total) {
      setValue(payrollDeduction?.[costTypeValue]?.total);
    } else if (typeof payrollDeduction?.[costTypeValue] !== 'object') {
      const val = payrollDeduction?.[costTypeValue] ?? 0;
      setValue(val);
    } else {
      setValue(0);
    }
  };

  const options = [
    {
      label: formatMessage({ id: 'EMPLOYEE_COST' }),
      value: 'employee_cost',
    },
    {
      label: formatMessage({ id: 'EMPLOYER_COST' }),
      value: 'employer_cost',
    },
    {
      label: formatMessage({ id: 'FLEX_COST' }),
      value: 'flex_cost',
    },
    {
      label: formatMessage({ id: 'QUEBEC_TAXABLE_BENEFIT' }),
      value: 'quebec_taxable_benefit',
    },
    {
      label: formatMessage({ id: 'IMPUTED_INCOME' }),
      value: 'imputed_income',
    },
    {
      label: formatMessage({ id: 'PRE_TAX_COST' }),
      value: 'pre_tax_cost',
    },
    {
      label: formatMessage({ id: 'POST_TAX_COST' }),
      value: 'post_tax_cost',
    },
  ];
  const payPeriod =
    payrollDeduction.pay_period &&
    `${format(
      utcToZonedTime(payrollDeduction.pay_period.start_date, 'UTC'),
      'MM/dd/yyyy',
    )} - ${format(
      utcToZonedTime(payrollDeduction.pay_period.end_date, 'UTC'),
      'MM/dd/yyyy',
    )}`;

  const benefitType = payrollDeduction.benefit_type;

  const updateUserDeduction = (costTypeValue, val) => {
    const deductionValueTypes = ['employee_cost', 'employer_cost', 'flex_cost'];
    const deductionValue = deductionValueTypes.includes(costTypeValue)
      ? { total: val, subtotal: val }
      : val;
    const userDeduction = {
      ...payrollDeduction,
      [costTypeValue]: deductionValue,
    };
    dispatch(updateUserDeductions(userDeduction));
  };

  return {
    value,
    options,
    selectOptions,
    payPeriod,
    updateUserDeduction,
    setValue,
    costType,
    benefitType,
  };
};

const DATE_FORMAT = 'MM/dd/yyyy';
const formatUserDeductionPeriod = date =>
  format(utcToZonedTime(date, 'UTC'), DATE_FORMAT);

const mapSetToOption = option => ({
  label: option,
  value: option,
});

// filter user deductions by plan period (required), pay period and/or benefit type
const filterUserDeductions = ({
  formattedPlanPeriod,
  formattedPayPeriod,
  planPeriodValue,
  payPeriodValue,
  benefitTypeValue,
  userDeduction,
}) => {
  const filterPlanPeriod = formattedPlanPeriod === planPeriodValue;
  const filterBenefitType = userDeduction.benefit_type === benefitTypeValue;
  const filterPayPeriod = formattedPayPeriod === payPeriodValue;
  if (payPeriodValue && benefitTypeValue) {
    return filterPlanPeriod && filterBenefitType && filterPayPeriod;
  }
  return (filterPlanPeriod && (filterBenefitType || filterPayPeriod)) ?? false;
};

export const usePayrollDeductions = ({ userDeductions }) => {
  const planPeriodSet = new Set();
  const benefitTypeSet = new Set();
  const payPeriodSet = new Set();
  const [benefitTypeValue, setBenefitValue] = useState(undefined);
  const [planPeriodValue, setPlanPeriodValue] = useState(undefined);
  const [payPeriodValue, setPayPeriodValue] = useState(undefined);

  const filteredUserDeductions = userDeductions
    ?.reduce((acc, userDeduction) => {
      // format plan period
      const { start_date: planPeriodStartDate, end_date: planPeriodEndDate } =
        // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
        userDeduction?.plan_period;
      const formattedPlanPeriod = `${formatUserDeductionPeriod(
        planPeriodStartDate,
      )} - ${formatUserDeductionPeriod(planPeriodEndDate)}`;
      // format pay period
      const { start_date: payPeriodStartDate, end_date: payPeriodEndDate } =
        // eslint-disable-next-line no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
        userDeduction?.pay_period;
      const formattedPayPeriod =
        formattedPlanPeriod === planPeriodValue
          ? `${formatUserDeductionPeriod(
              payPeriodStartDate,
            )} - ${formatUserDeductionPeriod(payPeriodEndDate)}`
          : undefined;

      planPeriodSet.add(formattedPlanPeriod);
      benefitTypeSet.add(userDeduction.benefit_type);
      payPeriodSet.add(formattedPayPeriod);

      return filterUserDeductions({
        formattedPlanPeriod,
        formattedPayPeriod,
        planPeriodValue,
        payPeriodValue,
        benefitTypeValue,
        userDeduction,
      })
        ? [...acc, userDeduction]
        : acc;
    }, [])
    .sort(
      (a, b) =>
        new Date(b.pay_period?.start_date) - new Date(a.pay_period?.start_date),
    );

  const benefitTypeOptions = [...benefitTypeSet].map(mapSetToOption);
  const planPeriodOptions = [...planPeriodSet].map(mapSetToOption);
  const payPeriodOptions = [...payPeriodSet]
    .filter(e => e !== undefined)
    .map(mapSetToOption);

  return {
    benefitTypeOptions,
    planPeriodOptions,
    payPeriodOptions,
    benefitTypeValue,
    planPeriodValue,
    payPeriodValue,
    setBenefitValue,
    setPlanPeriodValue,
    setPayPeriodValue,
    userDeductions: filteredUserDeductions,
  };
};
