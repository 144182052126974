import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  getThemeValue,
  spaceInPx,
  Text,
  Box,
  FontAwesomeIcon,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Button } from 'apps/employer-experience/ui-kit';

const Benefit = ({ fullName, planName, children }) => {
  const benefitName = fullName || planName;
  const showPlanName = benefitName !== planName;
  return (
    <Flex
      borderWidth="1px"
      borderColor="greyLighter"
      borderRadius={2}
      bg="white"
      py={1}
      px={2}
      mt={1}
      justify="space-between"
      align="center"
    >
      <Box>
        <Text fontWeight="500" m={0} lineHeight="24px">
          {benefitName}
        </Text>
        {showPlanName && (
          <Text fontSize={1} lineHeight="24px">
            {planName}
          </Text>
        )}
      </Box>
      {children}
    </Flex>
  );
};

Benefit.propTypes = {
  fullName: PropTypes.string,
  planName: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Benefit.defaultProps = {
  fullName: '',
  planName: '',
};

const Hidden = styled.span`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
`;

const AddButton = styled(Button)`
  border-color: transparent;
  border-radius: ${spaceInPx(3)};
  width: ${spaceInPx(3)};
  height: ${spaceInPx(3)};
  flex-shrink: 0;
  padding: 0;
`;

const RemoveButton = styled(AddButton)`
  color: ${getThemeValue('colors.red')};
  background-color: ${getThemeValue('colors.lightRose')};
  &:hover:not([disabled]) {
    background-color: ${getThemeValue('colors.darkRose')};
  }
`;

export const AvailableBenefit = ({ onClick, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Benefit {...rest}>
    <AddButton type="button" onClick={onClick}>
      <FontAwesomeIcon icon="plus" />
      <Hidden>Add</Hidden>
    </AddButton>
  </Benefit>
);

AvailableBenefit.propTypes = {
  onClick: PropTypes.func,
};
AvailableBenefit.defaultProps = {
  onClick: () => {},
};

export const PickedBenefit = ({ onClick, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <Benefit {...rest}>
    <RemoveButton type="button" onClick={onClick}>
      <FontAwesomeIcon icon="minus" />
      <Hidden>Remove</Hidden>
    </RemoveButton>
  </Benefit>
);

PickedBenefit.propTypes = {
  onClick: PropTypes.func,
};
PickedBenefit.defaultProps = {
  onClick: () => {},
};
