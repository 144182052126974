import React from 'react';
import PropTypes from 'prop-types';
import { Item, Divider, Card, Form, Popup } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ValidFormField } from 'common/adaptive-forms';
import { AllocationSummary } from './allocation-summary';
import { Box } from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import {
  FundsAllocationDisplay,
  AmountBox,
  DependentClassDescription,
  DescriptionContainer,
  CurrencyLabelContainer,
  CurrencySignLabelContainer,
  UnderlinedToolTip,
  StyledInput,
} from './dependent-ruleset.ui';

const SingleCoupleFamily = ({
  single,
  couple,
  family,
  currency,
  setAmount,
  ruleSet,
  handleBlur,
  errors,
  touched,
  readOnly,
  allocationFrequency,
  benefitStartDate,
  benefitEndDate,
}) => {
  const { formatMessage } = useIntl();
  const spouseTooltipContent = formatMessage({ id: 'SPOUSE_TOOLTIP' });
  const dependentTooltipContent = formatMessage({ id: 'DEPENDENT_TOOLTIP' });
  return (
    <FundsAllocationDisplay className="container--benefit-dependent-rules">
      <Card fluid>
        <div className="card-header">
          <AllocationSummary
            allocationFrequency={allocationFrequency}
            benefitStartDate={benefitStartDate}
            benefitEndDate={benefitEndDate}
          />
        </div>
        <Card.Content style={{ marginLeft: 0 }}>
          <Item>
            <Flex p={3} pb={0}>
              <Box flex={3}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- FIXME: automatically added for existing issue */}
                <label htmlFor="single">
                  <strong>
                    <FormattedMessage id="ALLOCATION_CLASS_LABEL" />
                  </strong>
                </label>
              </Box>
              <Box flex={2}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- FIXME: automatically added for existing issue */}
                <label htmlFor="single">
                  <strong>
                    <FormattedMessage id="FUNDS_PER_POLICY_LABEL" />
                  </strong>
                </label>
              </Box>
            </Flex>
            <DescriptionContainer>
              <DependentClassDescription>
                <FormattedMessage
                  id="EMPLOYEE_ALLOCATION_LABEL"
                  values={{
                    dependentClassDescription: (
                      <strong>
                        <FormattedMessage
                          id="DEPENDENT_CLASS_DESCRIPTION_SINGLE"
                          values={{
                            spouse: (
                              <Popup
                                wide
                                inverted
                                position="top center"
                                trigger={
                                  <UnderlinedToolTip>spouse</UnderlinedToolTip>
                                }
                                content={spouseTooltipContent}
                              />
                            ),
                            dependents: (
                              <Popup
                                wide
                                inverted
                                position="top center"
                                trigger={
                                  <UnderlinedToolTip>
                                    dependents
                                  </UnderlinedToolTip>
                                }
                                content={dependentTooltipContent}
                              />
                            ),
                          }}
                        />
                      </strong>
                    ),
                  }}
                />
              </DependentClassDescription>
              <AmountBox>
                <ValidFormField errors={errors.single} touched={touched.single}>
                  <Form.Group>
                    <CurrencySignLabelContainer basic>
                      $
                    </CurrencySignLabelContainer>
                    <StyledInput
                      disabled={readOnly}
                      name="single"
                      type="number"
                      value={single}
                      onChange={(event, { name, value }) =>
                        setAmount({ name, value, ruleSet })
                      }
                      onBlur={handleBlur}
                    />
                    <CurrencyLabelContainer basic>
                      {currency}
                    </CurrencyLabelContainer>
                  </Form.Group>
                </ValidFormField>
              </AmountBox>
            </DescriptionContainer>
          </Item>
          <Divider style={{ marginLeft: '2em', marginRight: '2em' }} />
          <Item>
            <DescriptionContainer>
              <DependentClassDescription>
                <FormattedMessage
                  id="EMPLOYEE_ALLOCATION_LABEL"
                  values={{
                    dependentClassDescription: (
                      <strong>
                        <FormattedMessage
                          id="DEPENDENT_CLASS_DESCRIPTION_COUPLE"
                          values={{
                            spouse: (
                              <Popup
                                wide
                                inverted
                                position="top center"
                                trigger={
                                  <UnderlinedToolTip>spouse</UnderlinedToolTip>
                                }
                                content={spouseTooltipContent}
                              />
                            ),
                            dependents: (
                              <Popup
                                wide
                                inverted
                                position="top center"
                                trigger={
                                  <UnderlinedToolTip>
                                    dependents
                                  </UnderlinedToolTip>
                                }
                                content={dependentTooltipContent}
                              />
                            ),
                          }}
                        />
                      </strong>
                    ),
                  }}
                />
              </DependentClassDescription>
              <AmountBox>
                <ValidFormField errors={errors.couple} touched={touched.couple}>
                  <Form.Group>
                    <CurrencySignLabelContainer basic>
                      $
                    </CurrencySignLabelContainer>
                    <StyledInput
                      disabled={readOnly}
                      name="couple"
                      type="number"
                      value={couple}
                      onChange={(event, { name, value }) =>
                        setAmount({ name, value, ruleSet })
                      }
                      onBlur={handleBlur}
                    />
                    <CurrencyLabelContainer basic>
                      {currency}
                    </CurrencyLabelContainer>
                  </Form.Group>
                </ValidFormField>
              </AmountBox>
            </DescriptionContainer>
          </Item>
          <Divider style={{ marginLeft: '2em', marginRight: '2em' }} />
          <Item>
            <DescriptionContainer>
              <DependentClassDescription>
                <FormattedMessage
                  id="EMPLOYEE_ALLOCATION_LABEL"
                  values={{
                    dependentClassDescription: (
                      <strong>
                        <FormattedMessage
                          id="DEPENDENT_CLASS_DESCRIPTION_FAMILY"
                          values={{
                            spouse: (
                              <Popup
                                wide
                                inverted
                                position="top center"
                                trigger={
                                  <UnderlinedToolTip>spouse</UnderlinedToolTip>
                                }
                                content={spouseTooltipContent}
                              />
                            ),
                            dependents: (
                              <Popup
                                wide
                                inverted
                                position="top center"
                                trigger={
                                  <UnderlinedToolTip>
                                    dependents
                                  </UnderlinedToolTip>
                                }
                                content={dependentTooltipContent}
                              />
                            ),
                          }}
                        />
                      </strong>
                    ),
                  }}
                />
              </DependentClassDescription>
              <AmountBox>
                <ValidFormField errors={errors.family} touched={touched.family}>
                  <Form.Group>
                    <CurrencySignLabelContainer basic>
                      $
                    </CurrencySignLabelContainer>
                    <StyledInput
                      disabled={readOnly}
                      name="family"
                      type="number"
                      value={family}
                      onChange={(event, { name, value }) =>
                        setAmount({ name, value, ruleSet })
                      }
                      onBlur={handleBlur}
                    />
                    <CurrencyLabelContainer basic>
                      {currency}
                    </CurrencyLabelContainer>
                  </Form.Group>
                </ValidFormField>
              </AmountBox>
            </DescriptionContainer>
          </Item>
        </Card.Content>
      </Card>
    </FundsAllocationDisplay>
  );
};

SingleCoupleFamily.propTypes = {
  single: PropTypes.number,
  couple: PropTypes.number,
  family: PropTypes.number,
  currency: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  ruleSet: PropTypes.string.isRequired,
  errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  allocationFrequency: PropTypes.string,
  benefitStartDate: PropTypes.string,
  benefitEndDate: PropTypes.string,
};

SingleCoupleFamily.defaultProps = {
  single: 0,
  couple: 0,
  family: 0,
  errors: {},
  touched: {},
  allocationFrequency: null,
  benefitStartDate: null,
  benefitEndDate: null,
};

export default injectIntl(SingleCoupleFamily);
