import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export type GetEnrollmentDesignOptionsParams = {
  groupId: string;
  planId: string;
};

export const getEnrollmentDesignOptions = ({
  groupId,
  planId,
}: GetEnrollmentDesignOptionsParams) =>
  call(websocketFetch, 'get_enrollment_design_options', {
    group_id: groupId,
    plan_id: planId,
  });
