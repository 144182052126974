import { createRequestTypes } from 'common/websocket-redux';

export const GET_PAYROLL_BUSINESS_RULES = createRequestTypes(
  'services/GET_PAYROLL_BUSINESS_RULES',
);

export const GET_PAYROLL_BUSINESS_RULES_SCHEMA = createRequestTypes(
  'services/GET_PAYROLL_BUSINESS_RULES_SCHEMA',
);

export const SET_PAYROLL_BUSINESS_RULES = createRequestTypes(
  'services/SET_PAYROLL_BUSINESS_RULES',
);
