import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { injectIntl } from 'react-intl';
import {
  selectBenefitCreationError,
  selectBenefitCreationSuccess,
} from 'apps/employer-experience/pages/EmployerDetails/Benefits/BenefitClass/Plans/BenefitPlan/Benefits/BenefitTypes/selectors';
import {
  selectBenefitClasses,
  selectAddingBenefitClass,
  selectErrorAddingBenefitClass,
  selectRenamingBenefitClass,
  selectErrorRenamingBenefitClass,
  selectIsLastBenefitClass,
} from '../selectors';
import {
  selectGroupId,
  selectIsLoading,
  selectImportPlanDesignRequestStatus,
  selectEmployerBenefits,
  selectFilteredEmployerBenefits,
} from '../../selectors';
import {
  addBenefitClass,
  renameBenefitClass,
  removeBenefitClass,
  fetchPlans,
} from '../actions';
import {
  importPlanDesign,
  exportPlanDesign,
  resetPlanDesignRequestStatus,
} from '../PlanDesign/plan-design.actions';
import { BenefitsOverview } from './benefits-overview.view';
import { createStructuredSelector } from 'reselect';

const mapStateToProps = state => ({
  benefitClasses: selectBenefitClasses(state),
  errorAddingBenefitClass: selectErrorAddingBenefitClass(state),
  addingBenefitClass: selectAddingBenefitClass(state),
  errorRenamingBenefitClass: selectErrorRenamingBenefitClass(state),
  renamingBenefitClass: selectRenamingBenefitClass(state),
  isLastBenefitClass: selectIsLastBenefitClass(state),
  groupId: selectGroupId(state),
  loading: selectIsLoading(state),
  addBenefitError: selectBenefitCreationError(state),
  addBenefitSuccess: selectBenefitCreationSuccess(state),
  planDesignRequestStatus: selectImportPlanDesignRequestStatus(state),
  employerBenefits: selectEmployerBenefits(state),
});

const withState = connect(
  createStructuredSelector({
    filteredEmployerBenefits: selectFilteredEmployerBenefits,
  }),
);

export default compose(
  connect(mapStateToProps, {
    addBenefitClass,
    renameBenefitClass,
    removeBenefitClass,
    fetchPlans,
    importPlanDesign,
    exportPlanDesign,
    resetPlanDesignRequestStatus,
  }),
  withHandlers({
    addBenefitClass:
      props =>
      ({ name }) => {
        props.addBenefitClass({ name, groupId: props.groupId });
      },
    renameBenefitClass:
      props =>
      ({ id, name }) => {
        props.renameBenefitClass({ name, id, groupId: props.groupId });
      },
    removeBenefitClass:
      props =>
      ({ id }) => {
        props.removeBenefitClass({
          benefitClassId: id,
          groupId: props.groupId,
        });
      },
    isLastBenefitClass: props => () => props.isLastBenefitClass,
    fetchPlans:
      props =>
      ({ benefitClassId }) => {
        props.fetchPlans({ benefitClassId, groupId: props.groupId });
      },
  }),
  injectIntl,
  withState,
)(BenefitsOverview);
