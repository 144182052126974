import { createRequestTypes } from 'common/websocket-redux';

export const GET_FEATURE_FLAGS_LIST = 'admin-flags/GET_FEATURE_FLAGS_LIST';
export const GET_FEATURE_FLAG = 'admin-flags/GET_FEATURE_FLAG';
export const FEATURE_FLAG_SET = 'admin-flags/FEATURE_FLAG_SET';
export const REQUEST_GET_FEATURE_FLAG = createRequestTypes(
  'admin-flags/REQUEST_GET_FEATURE_FLAG',
);
export const REQUEST_SET_FEATURE_FLAG = createRequestTypes(
  'admin-flags/REQUEST_SET_FEATURE_FLAG',
);
export const REQUEST_LIST_FEATURE_FLAGS = createRequestTypes(
  'admin-flags/REQUEST_LIST_FEATURE_FLAGS',
);
