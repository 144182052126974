import { delay } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { locationChangedTo } from 'common/utilities';
import { request, websocketFetch } from 'common/websocket-redux';
import {
  GET_GROUPS,
  EMPLOYER_SELECTED,
  SEARCH_TEXT_CHANGED,
  SEARCH_PAGE_REQUESTED,
} from './types';
import { TYPING_DEBOUNCE_INTERVAL } from 'common/constants';
import {
  ITEMS_PER_INFINITE_SCROLL,
  LEGACY_EMPLOYER_PROFILE,
} from './constants';

export const didNavigateToEmployers = locationChangedTo('/admin/employers');

export function* getGroups(action) {
  const info = {
    name: action.payload.searchText || null,
    skip: action.payload.skip || 0,
    limit: ITEMS_PER_INFINITE_SCROLL,
    filter: 'exclude_suspended',
  };
  yield request(GET_GROUPS, [websocketFetch, 'get_groups', info], action);
}

export function* callWithDelay(fn, action) {
  yield call(delay, TYPING_DEBOUNCE_INTERVAL);
  yield call(fn, action);
}

export function* navigateToEmployer(action) {
  const { groupId, newBenefits } = action.payload;
  if (newBenefits) {
    return yield put(push(`/admin/employers/${groupId}/profile`));
  }
  window.location = `${LEGACY_EMPLOYER_PROFILE}${groupId}`;

  return true;
}

export function* watchChangingSearchText() {
  yield takeLatest(SEARCH_TEXT_CHANGED, callWithDelay, getGroups);
}

export function* watchNavigation() {
  yield takeLatest(didNavigateToEmployers, getGroups);
}

export function* watchScrollingPageRequests() {
  yield takeLatest(SEARCH_PAGE_REQUESTED, getGroups);
}

export function* watchEmployerSelection() {
  yield takeLatest(EMPLOYER_SELECTED, navigateToEmployer);
}

export function* employersSaga() {
  yield fork(watchNavigation);
  yield fork(watchChangingSearchText);
  yield fork(watchScrollingPageRequests);
  yield fork(watchEmployerSelection);
}
