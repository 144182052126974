import React from 'react';
import {
  Text,
  QuietButton,
  genesisStyled,
  BodyOneSecondary,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { Table, Label } from 'semantic-ui-react';
import { EmployeeFormField } from './employee-form-field.type';

interface FieldToggleProps {
  field: EmployeeFormField;
  onChange: (field: EmployeeFormField) => void;
}

const ToggleButton = genesisStyled(QuietButton)`
  padding: 0px;
  border-radius: 100px;
`;

const TableCell = genesisStyled(Table.Cell)`
  wordWrap: break-word;
`;

export const FieldToggle = ({ field, onChange }: FieldToggleProps) => {
  const { formatMessage } = useIntl();
  const getColor = () => (!field.hidden ? 'active' : 'suspended');

  const getFieldName = () => `${field.field_name.toUpperCase()}_DESCRIPTION`;

  return (
    <Table.Row>
      <Table.Cell>
        <Text>{field.field_name}</Text>
      </Table.Cell>
      <TableCell>
        <BodyOneSecondary>
          {formatMessage({ id: getFieldName() })}
        </BodyOneSecondary>
      </TableCell>
      <Table.Cell>
        <ToggleButton
          id={field.field_name}
          onClick={() => onChange(field)}
          aria-label={field.field_name}
        >
          <Label
            className={`label-${getColor()}`}
            icon="circle"
            content={formatMessage({
              id: !field.hidden ? 'ON_CAPS' : 'OFF_CAPS',
            })}
          />
        </ToggleButton>
      </Table.Cell>
    </Table.Row>
  );
};
