import { createSelector } from 'reselect';

export const selectUserPlanEnrollment = state =>
  state?.apps?.['employer-experience']?.userPlanEnrollment;

export const selectPlanEnrollments = createSelector(
  selectUserPlanEnrollment,
  userPlanEnrollment => userPlanEnrollment?.planEnrollments,
);

export const selectStatus = createSelector(
  selectUserPlanEnrollment,
  userPlanEnrollment => userPlanEnrollment?.status,
);
