import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Header, Segment, Form } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';

const options = [
  { key: 'cc', text: 'Credit Card', value: 'creditCard' },
  { key: 'invoice', text: 'Invoice', value: 'invoice' },
];

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.resetUpdateStatus('payment');
  }

  handleSelect(e, value) {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('changed', true);
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('method', value);
  }

  render() {
    const { handleSubmit, values, updateStatus } = this.props;
    const buttonMessage = () => {
      switch (updateStatus.payment) {
        case 'start':
          return 'SAVING';
        case 'success':
          return 'PAYMENT_METHOD_SAVED';
        case 'error':
          return 'PAYMENT_METHOD_ERRORED';
        default:
          return 'SAVE_PAYMENT_METHOD';
      }
    };
    return (
      <Box>
        <Header as="h4">
          <FormattedMessage id="PAYMENT" />
        </Header>
        <Segment vertical>
          <Form onSubmit={handleSubmit}>
            <Form.Select
              search
              label="Payment Method"
              options={options}
              name="method"
              value={values.method}
              onChange={(e, { value }) => this.handleSelect(e, value)}
            />
            <Form.Button
              color={updateStatus.payment === 'error' ? 'red' : 'violet'}
              disabled={!values.changed}
              loading={updateStatus.payment === 'start'}
            >
              <FormattedMessage id={buttonMessage()} />
            </Form.Button>
          </Form>
        </Segment>
      </Box>
    );
  }
}

PaymentMethods.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetUpdateStatus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateStatus: PropTypes.shape({
    payment: PropTypes.string,
    fees: PropTypes.string,
    prepayment: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
};

PaymentMethods.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  values: {},
};

export default PaymentMethods;
