/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  GET_AUDIT_FLOWS,
  GET_CARRIER_AUDITS_REPORT,
} from './carrier-audits-report.types';

export const initialState = {
  carrierAuditReportLoading: false,
  auditFlows: [],
};

export const employerCarrierAuditsReportReducer = (
  // eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_CARRIER_AUDITS_REPORT.STARTED:
      return {
        ...state,
        carrierAuditReportLoading: true,
      };
    case GET_CARRIER_AUDITS_REPORT.SUCCEEDED:
    case GET_CARRIER_AUDITS_REPORT.ERRORED:
      return {
        ...state,
        carrierAuditReportLoading: false,
      };

    case GET_AUDIT_FLOWS.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/naming-convention, no-unsafe-optional-chaining -- FIXME: automatically added for existing issue
      const { flow_dtos } = action?.payload?.info;
      return {
        ...state,
        auditFlows: flow_dtos,
      };
    default:
      return state;
  }
};
