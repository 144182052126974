/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Loader, Visibility } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { PremiumsReportTableRow } from './premiums-report-table-row';
import { Table } from '@leagueplatform/ui-kit';
import { SubtitleOne, genesisStyled } from '@leagueplatform/genesis-commons';
import { STATUS } from '../premiums-report.reducer';

const TableHeaderCell = genesisStyled('th')`
  lineHeight: ${({ theme }) => theme.lineHeights.SubtitleOne}px;
  &&& {
    padding: ${({ theme }) => theme.space.half}px;

  }
`;

const TableHead = genesisStyled('thead')({
  height: '20px',
  '&&&': {
    backgroundColor: 'inherit',
    textTransform: 'none',
    verticalAlign: 'middle',
  },
});

export const PremiumsReportTable = ({
  loading,
  getChangeReport,
  reports,
  payrollSchedules,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Visibility once={false}>
      <Table>
        <TableHead>
          <tr>
            <TableHeaderCell>
              <SubtitleOne>{formatMessage({ id: 'DATE' })}</SubtitleOne>
            </TableHeaderCell>
            <TableHeaderCell>
              <SubtitleOne>{formatMessage({ id: 'USER_ID' })}</SubtitleOne>
            </TableHeaderCell>
            <TableHeaderCell width="152px">
              <SubtitleOne>{formatMessage({ id: 'TYPE' })}</SubtitleOne>
            </TableHeaderCell>
            <TableHeaderCell>
              <SubtitleOne>{formatMessage({ id: 'PAY_CALENDAR' })}</SubtitleOne>
            </TableHeaderCell>
            <TableHeaderCell>
              <SubtitleOne>{formatMessage({ id: 'PAY_PERIOD' })}</SubtitleOne>
            </TableHeaderCell>
            <TableHeaderCell width="56px" />
          </tr>
        </TableHead>
        <tbody>
          {reports?.map(row => (
            <PremiumsReportTableRow
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              {...row}
              key={`report_${row.report_id}`}
              getChangeReport={getChangeReport}
              payPeriod={row.pay_period}
              payCalendar={payrollSchedules?.find(
                schedule => schedule.id === row.payroll_schedule_id,
              )}
            />
          ))}
          {loading === STATUS.LOADING && (
            <tr>
              <td colSpan="6">
                <Loader active inline="centered" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Visibility>
  );
};

PremiumsReportTable.propTypes = {
  loading: PropTypes.string.isRequired,
  getChangeReport: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
};
