import { connect } from 'react-redux';
import { BeneficiariesReportPresenter } from './beneficiaries-report.view';
import { EMPLOYER_BENEFICIARIES_REPORT_LIFECYCLE } from './employer-reports.types';
import {
  selectReportContentId,
  selectIsReportLoading,
  selectReportError,
  selectUserEmail,
} from './employer-reports.selectors';

export const mapStateToProps = state => ({
  contentId: selectReportContentId(state),
  loadingState: selectIsReportLoading(state),
  reportError: selectReportError(state),
  email: selectUserEmail(state),
});

export const BeneficiariesReportContainer = connect(mapStateToProps, {
  visit: EMPLOYER_BENEFICIARIES_REPORT_LIFECYCLE.visit,
  exit: EMPLOYER_BENEFICIARIES_REPORT_LIFECYCLE.exit,
})(BeneficiariesReportPresenter);
