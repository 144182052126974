import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { requestBenefitPreview } from './benefit-preview.actions';
import { selectBenefitPreviewCards } from './benefit-preview.selectors';
import { BenefitPreview } from './benefit-preview.view';

export const BenefitPreviewContainer = compose(
  withRouter,
  connect(state => ({ cards: selectBenefitPreviewCards(state) }), {
    requestBenefitPreview,
  }),
)(BenefitPreview);
