import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Button } from 'semantic-ui-react';
import { Flex, Box, Link } from '@leagueplatform/genesis-commons';
import { getContentUrl } from '@leagueplatform/league-content-api';

const Document = ({
  documentId,
  contentId,
  title,
  displayTitle,
  onRemoveDocument,
  onEditDocument,
  onReorderDocument,
  index,
  length,
}) => (
  <Segment vertical className="segment--benefit-document" clearing>
    <Flex justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column" paddingRight="one">
        {index > 0 && (
          <Button
            basic
            icon="chevron up"
            className="button--action"
            onClick={e => {
              e.preventDefault();
              onReorderDocument(index, true);
            }}
          />
        )}
        {index < length - 1 && (
          <Button
            basic
            icon="chevron down"
            className="button--action"
            onClick={e => {
              e.preventDefault();
              onReorderDocument(index, false);
            }}
          />
        )}
      </Flex>
      <Link
        flexGrow={1}
        rel="noopener noreferrer"
        target="_blank"
        href={getContentUrl(contentId || documentId)}
      >
        {displayTitle && <Box>{displayTitle}</Box>}
        <Box>{title}</Box>
      </Link>
      <Flex>
        <Box marginX="one">
          <Button
            basic
            icon="pencil"
            className="button--action"
            onClick={e => {
              e.preventDefault();
              onEditDocument({ documentId, displayTitle, title, contentId });
            }}
          />
        </Box>
        <Button
          basic
          icon="trash outline"
          className="button--action"
          onClick={() => onRemoveDocument(documentId)}
        />
      </Flex>
    </Flex>
  </Segment>
);
Document.propTypes = {
  documentId: PropTypes.string,
  contentId: PropTypes.string,
  displayTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onEditDocument: PropTypes.func.isRequired,
  onReorderDocument: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

Document.defaultProps = {
  documentId: undefined,
  contentId: undefined,
  displayTitle: '',
};

export default Document;
