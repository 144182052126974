const POLICY = 'policy';
const MONTH = 'month';
const YEAR = 'year';
const PAY_PERIOD = 'pay_period';

const MONTHLY = 'Monthly';
const YEARLY = 'Yearly';
const SEMI_MONTHLY = 'Semi-Monthly';
const BI_WEEKLY = 'Bi-Weekly';
const WEEKLY = 'Weekly';
const MONTHLY_DRAW = 'Monthly Draw';
const YEARLY_LOWERCASE = 'yearly';
export const MONTHLY_LOWERCASE = 'monthly';
export const WEEKLY_LOWERCASE = 'weekly';

export const PERIOD_TYPES = {
  MONTH,
  POLICY,
  YEAR,
  PAY_PERIOD,
  MONTHLY,
  YEARLY,
  SEMI_MONTHLY,
  BI_WEEKLY,
  WEEKLY,
  MONTHLY_DRAW,
  MONTHLY_LOWERCASE,
  YEARLY_LOWERCASE,
  WEEKLY_LOWERCASE,
};

export const DEFAULT_PERIOD_TYPE = PERIOD_TYPES.MONTHLY;

export const VENDOR_IDS = {
  GWL: 'gwl',
  CANADA_LIFE: 'canadalife',
};

const MONTHLY_ID = 'MONTHLY';
const POLICY_PERIOD_ID = 'POLICY_PERIOD';
const YEARLY_ID = 'YEARLY';
const PAY_PERIOD_ID = 'PAY_PERIOD';

export const CONTRIBUTION_PERIOD_TYPE_TRANSLATION_IDS = {
  [PERIOD_TYPES.MONTH]: MONTHLY_ID,
  [PERIOD_TYPES.YEAR]: YEARLY_ID,
  [PERIOD_TYPES.POLICY]: POLICY_PERIOD_ID,
  [PERIOD_TYPES.PAY_PERIOD]: PAY_PERIOD_ID,
};

export const PERIOD_TYPE_TRANSLATION_IDS = {
  [PERIOD_TYPES.MONTHLY]: 'MONTHLY',
  [PERIOD_TYPES.YEARLY]: 'YEAR',
  [PERIOD_TYPES.SEMI_MONTHLY]: 'SEMI_MONTHLY',
  [PERIOD_TYPES.BI_WEEKLY]: 'BI_WEEKLY',
  [PERIOD_TYPES.WEEKLY]: 'WEEK',
  [PERIOD_TYPES.MONTHLY_DRAW]: 'MONTHLY_DRAW',
  [PERIOD_TYPES.MONTH]: 'MONTH',
  [PERIOD_TYPES.YEAR]: 'YEAR',
  [PERIOD_TYPES.POLICY]: POLICY_PERIOD_ID,
  [PERIOD_TYPES.PAY_PERIOD]: PAY_PERIOD_ID,
  [PERIOD_TYPES.MONTHLY_LOWERCASE]: 'MONTH',
  [PERIOD_TYPES.YEARLY_LOWERCASE]: 'YEAR',
  [PERIOD_TYPES.WEEKLY_LOWERCASE]: 'WEEK',
};

export const PAY_GROUP_PERIOD_TYPE_TRANSLATION_IDS = {
  [PERIOD_TYPES.MONTHLY]: 'MONTHLY',
  [PERIOD_TYPES.YEARLY]: 'YEARLY',
  [PERIOD_TYPES.SEMI_MONTHLY]: 'SEMI_MONTHLY',
  [PERIOD_TYPES.BI_WEEKLY]: 'BI_WEEKLY',
  [PERIOD_TYPES.WEEKLY]: 'WEEKLY',
  [PERIOD_TYPES.MONTHLY_DRAW]: 'MONTHLY_DRAW',
};

export const CRA = 'cra';
export const LSA = 'lsa';
export const HSA = 'hsa';
const HEALTH_SAVINGS_ACCOUNT = 'health_savings_account';
const FLEXIBLE_SPENDING_ACCOUNT = 'flexible_spending_account';
const LPFSA = 'limited_purpose_flexible_spending_account';
const HFSA = 'healthcare_flexible_spending_account';
const DCFSA = 'dependent_care_flexible_spending_account';
const OPTIONAL_LTD = 'optional_ltd';
const LIFE = 'life';

export const PRODUCT_TYPES = {
  LIFE,
  OPTIONAL_LTD,
  CRA,
  LSA,
  HEALTH_SAVINGS_ACCOUNT,
  FLEXIBLE_SPENDING_ACCOUNT,
  LPFSA,
  HFSA,
  DCFSA,
};

export const PER_PERIOD_TYPE_TRANSLATION_IDS = {
  [MONTH]: 'MONTH',
  [YEAR]: 'YEAR',
  [PAY_PERIOD]: 'PAY_PERIOD',
  [POLICY]: POLICY_PERIOD_ID,
};

export const PRORATION_SCHEDULES = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
};

export const PRORATION_SCHEDULES_TRANSLATION_IDS = {
  [PRORATION_SCHEDULES.DAILY]: 'DAY',
  [PRORATION_SCHEDULES.MONTHLY]: 'MONTH',
  [PRORATION_SCHEDULES.QUARTERLY]: 'QUARTER',
};

export const benefitsMetadata = {
  hsa: {
    shortDesc: 'HSA_SHORT_DESC',
    coverageTree: true,
    periodType: PERIOD_TYPES.POLICY,
  },
  lsa: {
    shortDesc: 'LSA_SHORT_DESC',
    coverageTree: true,
    periodType: PERIOD_TYPES.POLICY,
  },
  psa: {
    shortDesc: 'PSA_SHORT_DESC',
    coverageTree: true,
    periodType: PERIOD_TYPES.POLICY,
  },
  hra: {
    shortDesc: 'HRA_SHORT_DESC',
    periodType: PERIOD_TYPES.YEAR,
  },
  rrsp: {
    shortDesc: 'RRSP_SHORT_DESC',
    periodType: PERIOD_TYPES.POLICY,
  },
  tfsa: {
    shortDesc: 'TFSA_SHORT_DESC',
    periodType: PERIOD_TYPES.POLICY,
  },
  charity: {
    shortDesc: 'CHARITY_SHORT_DESC',
    periodType: PERIOD_TYPES.POLICY,
  },
  pension: {
    shortDesc: 'PENSION_SHORT_DESC',
  },
  ldsa: {
    shortDesc: 'LDSA_SHORT_DESC',
    coverageTree: true,
    periodType: PERIOD_TYPES.POLICY,
  },
  fsa: {
    shortDesc: 'FSA_SHORT_DESC',
    coverageTree: true,
    periodType: PERIOD_TYPES.POLICY,
  },
  sa: {
    coverageTree: true,
    periodType: PERIOD_TYPES.POLICY,
  },
  cra: {
    shortDesc: 'CRA_SHORT_DESC',
    periodType: PERIOD_TYPES.MONTH,
  },
  dependent_care_flexible_spending_account: {
    shortDesc: 'DCFSA_SHORT_DESC',
    periodType: PERIOD_TYPES.YEAR,
  },
  health_savings_account: {
    shortDesc: 'HEALTH_SAVINGS_ACCOUNT_SHORT_DESC',
    periodType: PERIOD_TYPES.YEAR,
  },
  limited_purpose_flexible_spending_account: {
    shortDesc: 'LPFSA_DESC',
    periodType: PERIOD_TYPES.YEAR,
  },
  healthcare_flexible_spending_account: {
    shortDesc: 'HFSA_SHORT_DESC',
    periodType: PERIOD_TYPES.YEAR,
  },
  get flexible_spending_account() {
    // note flexible_spending_account is an alias for healthcare_flexible_spending_account
    return this.healthcare_flexible_spending_account;
  },
};

export const CONTRIBUTOR_TYPES = {
  EMPLOYEE: 'employee',
  EMPLOYER: 'employer',
  LEFTOVER_FLEX: 'leftover_flex',
  TOTAL: 'total',
  QLE_EMPLOYEE: 'qle_employee',
};

export const EMPLOYEE_CONTRIBUTION_TYPES = [
  CONTRIBUTOR_TYPES.EMPLOYEE,
  CONTRIBUTOR_TYPES.LEFTOVER_FLEX,
  CONTRIBUTOR_TYPES.QLE_EMPLOYEE,
];

// this list is used to determine if a benefit is a contribution benefit (as there is no way to tell by examining the benefit props themselves)
export const contributionBenefitProductTypes = Object.keys(benefitsMetadata);

function NoMetaForProductTypeError(productType) {
  this.productType = productType;
  this.toString = () =>
    `no meta data defined for benefit.product_type ${this.productType} in src/apps/enrollment/benefit-metadata/accounts.js`;
}
export const getBenefitMetadata = benefit => {
  const productType = benefit?.product_type;
  const meta = benefitsMetadata[productType];

  if (meta) {
    const vendorId = benefit?.vendor_id ?? '';
    if (
      productType === HSA &&
      (vendorId.toLowerCase() === VENDOR_IDS.GWL ||
        vendorId.toLowerCase() === VENDOR_IDS.CANADA_LIFE)
    ) {
      meta.coverageTree = false;
    }

    return meta;
  }
  throw new NoMetaForProductTypeError(productType);
};
export const getPeriodTypeForContributionBenefit = benefit => {
  try {
    return getBenefitMetadata(benefit).periodType;
  } catch (error) {
    return DEFAULT_PERIOD_TYPE;
  }
};
export const getPeriodTypeForBenefit = benefit => {
  try {
    const { periodType } = getBenefitMetadata(benefit);
    return PERIOD_TYPE_TRANSLATION_IDS[periodType];
  } catch (error) {
    return benefit.payGroupPeriodTypeId;
  }
};

export const getPeriodTypeTranslation = payGroup =>
  PERIOD_TYPE_TRANSLATION_IDS[payGroup];

export const COVERAGE_VOLUME_OPTION = 'coverage_volume_option';
export const DEPENDENT_COVERAGE = 'dependent_coverage';
