import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';

const BenefitLabel = ({ type, name }) => {
  const { formatMessage } = useIntl();
  return (
    <span>
      {`${name} `}
      <span className="accordion--benefit-meta">{`${formatMessage({
        id: type.toUpperCase(),
      })}`}</span>
    </span>
  );
};

BenefitLabel.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default BenefitLabel;
