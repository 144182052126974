/* eslint-disable jsx-a11y/control-has-associated-label -- FIXME: automatically added for existing issue */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
  MORE_INFORMATION_NEEDED,
  FRONTEND_CLAIM_STATUS_TYPES,
} from '@leagueplatform/web-common';
import {
  Box,
  getColourObject,
  StatusCircle,
  fontSizeLineHeight,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { noop } from 'lodash';

const Wrapper = styled(Box)`
  position: relative;
  padding: 0 0 30px 33px;
  margin-left: 13px;
  border-left: 1px dashed ${({ theme }) => getColourObject(theme).greyLight};
  box-sizing: border-box;

  &:last-child {
    border-left: none;
    padding-bottom: 0;
  }
`;

const FlexColumn = styled(Flex)`
  flex-flow: column nowrap;
`;

const Title = styled.h3`
  color: ${({ theme }) => getColourObject(theme).black};
  font-weight: 500;
  margin: 0;
  ${fontSizeLineHeight(20, 30)};
`;

const Meta = styled.p`
  color: ${({ theme }) => getColourObject(theme).greyDark};
  margin: 0;
  ${fontSizeLineHeight(14, 18)};
`;

const InfoWrapper = styled(Box)`
  color: ${({ theme }) => getColourObject(theme).black};
  padding-top: 12px;
  ${fontSizeLineHeight(16, 20)};
`;

const InfoItem = styled.p`
  margin: 0 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemAction = styled.p`
  margin: 0;
  border-radius: 6px;
  padding: 16px;
  background: ${({ theme }) => getColourObject(theme).flushOrangeLight};
  ${fontSizeLineHeight(16, 24)};
`;

const ItemActionInfo = styled.p`
  margin-top: 12px;

  & > a:link,
  & > a:visited {
    color: ${({ theme }) => getColourObject(theme).purple};
    text-decoration: none;
    font-weight: 500;
  }

  & > a:hover,
  & > a:active {
    text-decoration: underline;
  }
`;

const TimelineItem = ({
  author,
  date,
  info,
  status,
  title,
  mostRecent,
  claimId,
  onEditClaim,
}) => {
  const renderInfoSection = () => {
    // highest priority check
    if (status === MORE_INFORMATION_NEEDED && mostRecent) {
      return (
        <>
          <ItemAction>{info}</ItemAction>
          <ItemActionInfo>
            <FormattedMessage id="EDIT_CLAIM_PROVIDE_MORE_INFO" />{' '}
            <a
              href="#edit_claim"
              onClick={event => {
                event.preventDefault();
                onEditClaim(claimId);
              }}
            >
              <FormattedMessage id="EDIT_CLAIM_LINK_TEXT" />
            </a>
          </ItemActionInfo>
        </>
      );
    }
    // map if array
    if (Array.isArray(info)) {
      return info.map(({ key, value }) => (
        <InfoItem>
          {key}: <span>{value}</span>
        </InfoItem>
      ));
    }
    // otherwise just return the string
    return info;
  };

  return (
    <Wrapper>
      <StatusCircle status={status} />
      <FlexColumn>
        <Title>{title}</Title>
        <Meta>
          <FormattedMessage
            id="AUTHOR_ON_DATE"
            values={{
              author,
              date: (
                <FormattedDate
                  value={date}
                  month="short"
                  day="numeric"
                  year="numeric"
                />
              ),
            }}
          />
        </Meta>
        <InfoWrapper>{renderInfoSection()}</InfoWrapper>
      </FlexColumn>
    </Wrapper>
  );
};

TimelineItem.propTypes = {
  author: PropTypes.string,
  date: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
    ),
    PropTypes.string,
  ]),
  mostRecent: PropTypes.bool,
  status: PropTypes.oneOf(FRONTEND_CLAIM_STATUS_TYPES).isRequired,
  title: PropTypes.string.isRequired,
  claimId: PropTypes.string,
  onEditClaim: PropTypes.func,
};

TimelineItem.defaultProps = {
  author: 'Customer Care',
  info: '',
  mostRecent: false,
  claimId: '',
  onEditClaim: noop,
};

export default TimelineItem;
