import { createRequestTypes } from 'common/websocket-redux';

export const GET_BENEFIT_SELECTIONS_DIFF = createRequestTypes(
  'admin:selections-tool/GET_BENEFIT_SELECTIONS_DIFF',
);

type GetBenefitSelectionsDiffParams = {
  userId: string;
  groupId: string;
  planId?: string;
  benefitType?: string;
};

export const getBenefitSelectionsDiff = (
  payload: GetBenefitSelectionsDiffParams,
) => ({
  type: GET_BENEFIT_SELECTIONS_DIFF.BASE,
  payload,
});
