import { takeLatest, fork, all, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { snakeCaseToSentence } from './utils';
import { settlePaymentStatus } from './retrigger-payment/settle-payment-status.service';
import { getPaymentPayoutDetails } from './payment-payout-details/payment-payout-details.service';
import { setPromoCode } from 'common/services/set-promo-code.service';
import { redeemPromoCode } from 'common/services/redeem-promo-code.service';
import { removeUserPromoCode } from 'common/services/remove-user-promo-code.service';
import { updateFundsPromoCode } from 'common/services/update-funds-promo-code.service';
import { retryInteracPayment, refundClaim } from 'common/services';
import {
  REQUEST_RETRIGGER_PAYMENT,
  RETRIGGER_PAYMENT,
} from './retrigger-payment/retrigger-payment.actions';
import {
  REQUEST_GET_PAYMENT_PAYOUT_DETAILS,
  GET_PAYMENT_PAYOUT_DETAILS,
} from './payment-payout-details/payment-payout-details.actions';
import { REQUEST_RESUBMIT_INTERAC_PAYMENT } from './resubmit-interac-payment/resubmit-interac-payment.actions';
import { REQUEST_RESUBMIT_REFUND_CLAIM } from './refund-claim/refund-claim.actions';
import { REQUEST_SET_PROMO_CODE } from './promo-codes/new-promo-code/new-promo-code.actions';
import { REQUEST_REDEEM_PROMO_CODE } from './promo-codes/add-promo-code/add-promo-code.actions';
import { REQUEST_REMOVE_USER_PROMO_CODE } from './promo-codes/remove-promo-code/remove-promo-code.actions';
import { REQUEST_UPDATE_FUNDS_PROMO_CODE } from './promo-codes/update-funds-promo-code/update-funds-promo-code.actions';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* sendFeedbackToast({ error, payload }) {
  const toastMessage = error
    ? {
        type: TOAST_STATUS.ERROR,
        text: `Error! ${error.message || payload.info.reason}`,
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        text: `Success! ${snakeCaseToSentence(payload.message_type)}`,
      };

  yield call(toastActions.add, toastMessage);
}

export function* retriggerPaymentRequest(action) {
  const { payload } = action;
  yield request(RETRIGGER_PAYMENT, settlePaymentStatus(payload));
}

export function* getPaymentPayoutDetailsRequest(action) {
  const { payload } = action;
  yield request(GET_PAYMENT_PAYOUT_DETAILS, getPaymentPayoutDetails(payload));
}

export function* resubmitInteracPaymentRequest(action) {
  const { payload } = action;

  yield request(
    REQUEST_RESUBMIT_INTERAC_PAYMENT,
    retryInteracPayment(payload?.interacTransactionId || ''),
  );
}

export function* resubmitRefundClaimRequest(action) {
  const { payload } = action;

  const claim = {
    claim_id: payload?.claimID || '',
    amounts: {
      hsa: Number(payload?.hsaAmount) || null,
      psa: Number(payload?.lsaAmount) || null,
    },
  };

  yield request(REQUEST_RESUBMIT_REFUND_CLAIM, refundClaim(claim));
}

export function* setPromoCodeRequest(action) {
  const { payload } = action;
  const amount = Number(payload?.amount);

  const promoCode = {
    amounts: { CAD: amount, USD: amount, EUR: amount },
    codes: [payload?.code],
    expires: payload?.expires,
    max_users: Number(payload?.maxUsers),
  };

  yield request(REQUEST_SET_PROMO_CODE, setPromoCode(promoCode));
}

export function* redeemPromoCodeRequest(action) {
  const { payload } = action;

  const promoCode = {
    code: payload?.code,
    user_id: payload?.userID,
  };

  yield request(REQUEST_REDEEM_PROMO_CODE, redeemPromoCode(promoCode));
}

export function* removeUserPromoCodeRequest(action) {
  const { payload } = action;

  const promoCode = {
    code: payload?.code,
    user_id: payload?.userID,
  };

  yield request(REQUEST_REMOVE_USER_PROMO_CODE, removeUserPromoCode(promoCode));
}

export function* updateFundsPromoCodeRequest(action) {
  const { payload } = action;

  const promoCode = {
    amount: parseFloat(payload?.amount),
    user_id: payload?.userID,
    policy_kind: payload?.policyKind,
  };

  yield request(
    REQUEST_UPDATE_FUNDS_PROMO_CODE,
    updateFundsPromoCode(promoCode),
  );
}

export function* scriptHelpersSaga() {
  yield fork(all, [
    takeLatest(REQUEST_RETRIGGER_PAYMENT, retriggerPaymentRequest),
    takeLatest(
      REQUEST_GET_PAYMENT_PAYOUT_DETAILS,
      getPaymentPayoutDetailsRequest,
    ),
    takeLatest(
      REQUEST_RESUBMIT_INTERAC_PAYMENT.BASE,
      resubmitInteracPaymentRequest,
    ),
    takeLatest(REQUEST_RESUBMIT_REFUND_CLAIM.BASE, resubmitRefundClaimRequest),
    takeLatest(REQUEST_SET_PROMO_CODE.BASE, setPromoCodeRequest),
    takeLatest(REQUEST_REDEEM_PROMO_CODE.BASE, redeemPromoCodeRequest),
    takeLatest(REQUEST_REMOVE_USER_PROMO_CODE.BASE, removeUserPromoCodeRequest),
    takeLatest(
      REQUEST_UPDATE_FUNDS_PROMO_CODE.BASE,
      updateFundsPromoCodeRequest,
    ),
    takeLatest(
      [
        RETRIGGER_PAYMENT.SUCCEEDED,
        RETRIGGER_PAYMENT.ERRORED,
        GET_PAYMENT_PAYOUT_DETAILS.SUCCEEDED,
        GET_PAYMENT_PAYOUT_DETAILS.ERRORED,
        REQUEST_RESUBMIT_INTERAC_PAYMENT.SUCCEEDED,
        REQUEST_RESUBMIT_INTERAC_PAYMENT.ERRORED,
        REQUEST_RESUBMIT_REFUND_CLAIM.SUCCEEDED,
        REQUEST_RESUBMIT_REFUND_CLAIM.ERRORED,
        REQUEST_SET_PROMO_CODE.SUCCEEDED,
        REQUEST_SET_PROMO_CODE.ERRORED,
        REQUEST_REDEEM_PROMO_CODE.SUCCEEDED,
        REQUEST_REDEEM_PROMO_CODE.ERRORED,
        REQUEST_REMOVE_USER_PROMO_CODE.SUCCEEDED,
        REQUEST_REMOVE_USER_PROMO_CODE.ERRORED,
        REQUEST_UPDATE_FUNDS_PROMO_CODE.SUCCEEDED,
        REQUEST_UPDATE_FUNDS_PROMO_CODE.ERRORED,
      ],
      sendFeedbackToast,
    ),
  ]);
}
