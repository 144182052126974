import { MALE, FEMALE } from 'common/dependents/dependents-metadata';
import { snakeCase } from 'lodash';

export const dependentSchema = ({
  addQLEReasons,
  relationshipTypes,
  formatMessage,
}) => ({
  date_of_birth: {
    inputType: 'date',
    label: 'DATE_OF_BIRTH',
    name: 'date_of_birth',
    required: true,
  },
  first_name: {
    inputType: 'text',
    label: 'LEGAL_FIRST_NAME',
    name: 'first_name',
    required: true,
  },
  last_name: {
    inputType: 'text',
    label: 'LAST_NAME',
    name: 'last_name',
    required: true,
  },
  email: {
    inputType: 'text',
    label: 'CONTACT_AGENTS_TITLE',
    name: 'email',
    required: false,
  },
  gender: {
    inputType: 'select',
    label: 'SEX',
    name: 'gender',
    options: [
      {
        label: formatMessage({ id: 'MALE' }),
        value: MALE,
      },
      {
        label: formatMessage({ id: 'FEMALE' }),
        value: FEMALE,
      },
    ],
    required: true,
  },

  national_identification_number: {
    inputType: 'optional_ssn',
    label: 'SSN',
    linkedFieldValue: false,
    name: 'national_identification_number',
    required: false,
  },
  omit_national_identification_number: {
    inputType: 'text',
    label: 'omit_national_identification_number',
    name: 'omit_national_identification_number',
    required: false,
  },
  relationship_type: {
    inputType: 'select',
    label: 'RELATIONSHIP_TYPE',
    name: 'relationship_type',
    options: relationshipTypes.map(type => ({
      value: type,
      label: formatMessage({ id: snakeCase(type).toUpperCase() }),
    })),
    required: true,
  },
  partnership_effective_date: {
    inputType: 'date',
    label: 'RELATIONSHIP_EFFECTIVE_DATE',
    name: 'partnership_effective_date',
    required: true,
  },
  add_qualifying_life_event_reason: {
    inputType: 'select',
    label: 'ADD_QLE_TYPE',
    name: 'add_qualifying_life_event_reason',
    options: addQLEReasons.map(reason => ({
      ...reason,
      label: formatMessage({ id: snakeCase(reason.text).toUpperCase() }),
    })),
  },
  add_qualifying_life_event_date: {
    inputType: 'date',
    label: 'ADD_QLE_DATE',
    name: 'add_qualifying_life_event_date',
  },
  verification_status: {
    inputType: 'select',
    label: 'VERIFICATION_STATUS',
    name: 'verification_status',
    options: [
      {
        label: formatMessage({ id: 'VERIFIED' }),
        value: 'verified',
      },
      {
        label: formatMessage({ id: 'REJECTED' }),
        value: 'rejected',
      },
    ],
  },
  verification_status_effective_date: {
    inputType: 'date',
    label: 'VERIFICATION_STATUS_EFFECTIVE_DATE',
    name: 'verification_status_effective_date',
  },
  child_of_domestic_partner: {
    inputType: 'select',
    label: 'DEPENDENT_CHILD_OF_DOMESTIC_PARTNER',
    name: 'child_of_domestic_partner',
    options: [
      {
        label: formatMessage({ id: 'YES' }),
        value: true,
      },
      {
        label: formatMessage({ id: 'NO' }),
        value: false,
      },
    ],
  },
  tobacco_user: {
    inputType: 'select',
    label: 'DEPENDENT_TOBACCO_USER',
    name: 'tobacco_user',
    options: [
      {
        label: formatMessage({ id: 'YES' }),
        value: true,
      },
      {
        label: formatMessage({ id: 'NO' }),
        value: false,
      },
    ],
    required: false,
  },
  student: {
    inputType: 'select',
    label: 'DEPENDENT_FULL_TIME_STUDENT',
    name: 'student',
    options: [
      {
        label: formatMessage({ id: 'YES' }),
        value: true,
      },
      {
        label: formatMessage({ id: 'NO' }),
        value: false,
      },
    ],
    required: false,
  },
  over_age_disabled: {
    inputType: 'select',
    label: 'DEPENDENT_OVERAGE_DISABLED_CHILD',
    name: 'over_age_disabled',
    options: [
      {
        label: formatMessage({ id: 'YES' }),
        value: true,
      },
      {
        label: formatMessage({ id: 'NO' }),
        value: false,
      },
    ],
    required: false,
  },
});
