import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import { FieldWrapper } from 'common/json-schema-form/fields';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { selectEmployerBenefits } from '../enrollment-config.selectors';
import { Select } from 'common/form-fields/Select/Select';
import { propertyCompareFn } from 'propertyCompareFn';

const getBenefitLabel = ({ full_name: fullName, plan_name: planName, id }) => {
  return fullName !== planName && planName
    ? `${fullName} (${planName}) - ${id}`
    : `${fullName} - ${id}`;
};

export const BenefitIdEnumSelectPresenter = ({
  field: { name, value } = {},
  form,
  propSchema,
  benefitsById,
  ...props
}) => {
  const benefitOptions = propSchema.enum
    .map(id => {
      const benefit = benefitsById[id];
      return {
        label: getBenefitLabel(benefit),
        value: id,
      };
    })
    .sort(propertyCompareFn('label'));

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <FieldWrapper {...propSchema} {...props}>
      <Select
        name={name}
        options={benefitOptions}
        placeholder="Choose a benefit"
        disabled={propSchema.readOnly}
        hasError={form.errors[name]}
        isClearable
        onChange={evt => {
          const { value: val } = evt ?? {};
          form.setFieldValue(name, val || evt);
        }}
        value={value}
      />
    </FieldWrapper>
  );
};

BenefitIdEnumSelectPresenter.propTypes = {
  ...JSONSchemaFieldPropType,
  benefitsById: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  benefitsById: selectEmployerBenefits(state),
});

export const BenefitIdEnumSelect = connect(mapStateToProps)(
  BenefitIdEnumSelectPresenter,
);
