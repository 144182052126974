import { createRequestTypes } from 'common/websocket-redux';
import { createLifecycleTypes } from '@leagueplatform/web-common';

export const SET_BILLING_DIVISION_FORM_SUBMITTED =
  'admin/employer-divisions/SET_BILLING_DIVISON_FORM_SUBMITTED';
export const SET_BILLING_DIVISION = createRequestTypes(
  'admin/employer-divisions/SET_BILLING_DIVISION',
);

export const REQUEST_REMOVE_BILLING_DIVISION =
  'admin/employer-divisions/REQUEST_REMOVE_BILLING_DIVISION';
export const REMOVE_BILLING_DIVISION = createRequestTypes(
  'admin/employer-divisions/REMOVE_BILLING_DIVISION',
);

export const EMPLOYER_BILLING_DIVISIONS_LIFECYCLE = createLifecycleTypes(
  'admin/employer-divisions/EMPLOYER_BILLING_DIVISIONS_LIFECYCLE',
);

export const START_BILLING_DIVISION_EDIT =
  'admin/employer-divisions/START_BILLING_DIVISION_EDIT';
export const DONE_BILLING_DIVISION_EDIT =
  'admin/employer-divisions/DONE_BILLING_DIVISION_EDIT';

export const EDIT_BILLING_DIVISION_NAME =
  'admin/employer-divisions/EDIT_BILLING_DIVISION_NAME';
