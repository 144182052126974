import { fork, takeLatest } from 'redux-saga/effects';
import { getHealthSolutionCategory } from 'common/services';
import {
  GET_HEALTH_SOLUTION_CATEGORY,
  REQUEST_GET_HEALTH_SOLUTION_CATEGORY,
} from './parent-category-page.action-types';
import { request } from 'common/websocket-redux';

export function* requestGetHealthSolutionCategory({ payload }) {
  yield request(
    REQUEST_GET_HEALTH_SOLUTION_CATEGORY,
    getHealthSolutionCategory(payload),
  );
}

export function* parentCategoryPageSagas() {
  yield fork(
    takeLatest,
    GET_HEALTH_SOLUTION_CATEGORY,
    requestGetHealthSolutionCategory,
  );
}
