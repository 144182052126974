import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Segment } from 'semantic-ui-react';
import { useIntl } from '@leagueplatform/locales';
import SingleFamily from './dependent-ruleset-single-family.view';
import SingleCoupleFamily from './dependent-ruleset-single-couple-family.view';
import All from './dependent-ruleset-all.view';
import { RULESETS } from './constants';

const DependentClassAmounts = ({
  setAmount,
  errors,
  touched,
  handleBlur,
  amounts,
  ruleSet,
  updateRuleSet,
  readOnly,
  values,
  groupCurrency,
}) => {
  const { formatMessage } = useIntl();
  const options = useMemo(
    () => [
      { value: RULESETS.ALL, text: formatMessage({ id: 'ALL_EMPLOYEES' }) },
      {
        value: RULESETS.SINGLE_COUPLE_FAMILY,
        text: formatMessage({ id: 'SINGLE_COUPLE_FAMILY' }),
      },
      {
        value: RULESETS.SINGLE_FAMILY,
        text: formatMessage({ id: 'SINGLE_FAMILY' }),
      },
    ],
    [formatMessage],
  );
  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
  const { all, single, couple, family } = amounts;
  // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
  const allocationFrequency = values['benefit.allocation_frequency'];
  // for employer benefits, use the start and end date but for employee benefits use the activation/parent end date
  const benefitStartDate =
    // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
    values.benefit_start_date || values.benefit_activation_date;
  const benefitEndDate =
    // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
    values.benefit_end_date || values['parent.benefit_end_date'];

  return (
    <Segment
      vertical
      className="segment--benefit-spending-account"
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <Form.Group widths="equal" className="fields--benefit-dependents">
        <Form.Select
          label={
            !readOnly && formatMessage({ id: 'ALLOCATION_RULE_SET_LABEL' })
          }
          options={options}
          name="ruleSet"
          value={ruleSet}
          onChange={updateRuleSet}
          disabled={readOnly}
        />
      </Form.Group>
      <Segment vertical className="segment--benefit-spending-account">
        {ruleSet === RULESETS.ALL ? (
          <All
            handleBlur={handleBlur}
            setAmount={setAmount}
            ruleSet={ruleSet}
            all={all}
            currency={groupCurrency}
            errors={errors}
            touched={touched}
            readOnly={readOnly}
            allocationFrequency={allocationFrequency}
            benefitStartDate={benefitStartDate}
            benefitEndDate={benefitEndDate}
          />
        ) : null}
        {ruleSet === RULESETS.SINGLE_COUPLE_FAMILY ? (
          <SingleCoupleFamily
            handleBlur={handleBlur}
            setAmount={setAmount}
            ruleSet={ruleSet}
            single={single}
            couple={couple}
            family={family}
            currency={groupCurrency}
            errors={errors}
            touched={touched}
            readOnly={readOnly}
            allocationFrequency={allocationFrequency}
            benefitStartDate={benefitStartDate}
            benefitEndDate={benefitEndDate}
          />
        ) : null}
        {ruleSet === RULESETS.SINGLE_FAMILY ? (
          <SingleFamily
            handleBlur={handleBlur}
            setAmount={setAmount}
            ruleSet={ruleSet}
            all={all}
            family={family}
            currency={groupCurrency}
            errors={errors}
            touched={touched}
            readOnly={readOnly}
            allocationFrequency={allocationFrequency}
            benefitStartDate={benefitStartDate}
            benefitEndDate={benefitEndDate}
          />
        ) : null}
      </Segment>
    </Segment>
  );
};

DependentClassAmounts.propTypes = {
  amounts: PropTypes.shape({
    single: PropTypes.number,
    couple: PropTypes.number,
    family: PropTypes.number,
  }).isRequired,
  groupCurrency: PropTypes.string.isRequired,
  ruleSet: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  updateRuleSet: PropTypes.func.isRequired,
  errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  values: PropTypes.shape({}).isRequired,
};

DependentClassAmounts.defaultProps = {
  errors: {},
  touched: {},
};

export default DependentClassAmounts;
