import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDashboards,
  selectIsLoadingDashboard,
} from './employer-insights.selectors';
import { GET_DASHBOARDS } from './employer-insights.action-types';

export const useInsightsDashboards = groupId => {
  const dispatch = useDispatch();

  const isLoadingDashboards = useSelector(selectIsLoadingDashboard);
  const dashboards = useSelector(selectDashboards);
  const hasDashboards = Object.keys(dashboards).length > 0;

  useEffect(() => {
    if (groupId && !hasDashboards) {
      dispatch(GET_DASHBOARDS.request({ groupId }));
    }
  }, [dispatch, groupId, hasDashboards]);

  return {
    dashboards,
    isLoadingDashboards,
  };
};
