import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { useDispatch } from 'react-redux';
import {
  HeadingFour,
  BodyOne,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { EmployerReportsInfoBox } from './ui-kit';
import { getBeneficiariesReport } from './employer-reports.actions';
import { useIsInHRAdminPortal } from 'common/hooks/use-is-in-hr-admin-portal.hook';

export const BeneficiariesReportPresenter = ({
  groupId,
  loadingState: isLoading,
  contentId,
  reportError,
  email,
  visit,
  exit,
}) => {
  const isInHRAdminPortal = useIsInHRAdminPortal();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // lifecycle
  useEffect(() => {
    visit();
    return () => exit();
  }, [visit, exit]);

  return (
    <>
      <HeadingFour>{formatMessage({ id: 'BENEFICIARIES_REPORT' })}</HeadingFour>
      <BodyOne marginY="one">
        {formatMessage({ id: 'BENEFICIARIES_REPORT_TEXT' })}
      </BodyOne>
      <PrimaryButton
        isLoading={isLoading}
        onClick={() =>
          dispatch(getBeneficiariesReport(groupId, isInHRAdminPortal))
        }
      >
        {formatMessage({ id: 'DOWNLOAD_BENEFICIARIES_REPORT' })}
      </PrimaryButton>
      <EmployerReportsInfoBox
        loading={isLoading}
        error={reportError}
        ready={!!contentId}
        email={email}
      />
    </>
  );
};

BeneficiariesReportPresenter.propTypes = {
  groupId: PropTypes.string,
  loadingState: PropTypes.bool,
  contentId: PropTypes.string,
  reportError: PropTypes.string,
  email: PropTypes.string,
  visit: PropTypes.func.isRequired,
  exit: PropTypes.func.isRequired,
};

BeneficiariesReportPresenter.defaultProps = {
  groupId: null,
  contentId: null,
  reportError: null,
  email: '',
  loadingState: false,
};
