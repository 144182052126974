import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  genesisStyled,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';

const StyledBox = genesisStyled(Box)`
  text-transform: uppercase
`;

export const InfoCardHeading = ({ as, children }) => (
  <StyledBox
    backgroundColor="highlight.background.subdued"
    paddingY="oneAndHalf"
    paddingLeft={{
      _: 'none',
      laptop: 'two',
    }}
    borderTopRightRadius="small"
    borderTopLeftRadius="small"
    textAlign={{
      _: 'center',
      laptop: 'left',
    }}
  >
    <SubtitleOne as={as} fontWeight="bold">
      {children}
    </SubtitleOne>
  </StyledBox>
);

InfoCardHeading.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InfoCardHeading.defaultProps = {
  as: 'h3',
};
