import React from 'react';
import PropTypes from 'prop-types';

import AETNA_LOGO from 'apps/public/assets/logo-aetna.png';
import AMERIHEALTH_LOGO from 'apps/public/assets/amerihealth-logo.png';
import AMWELL_LOGO from 'apps/public/assets/logo-amwell.png';
import ALLSTATE_LOGO from 'apps/public/assets/allstate-logo.png';
import BANKOFAMERICA_LOGO from 'apps/public/assets/bankofamerica-logo.png';
import BANKOFAMERICA_GLYPH from 'apps/public/assets/bankofamerica-glyph.png';
import BCBS_GLYPH from 'apps/public/assets/blue-cross-glyph.png';
import BCBS_LOGO from 'apps/public/assets/blue-cross-blue-shield-logo.png';
import BCBS_CALIFORNIA_LOGO from 'apps/public/assets/logo-california-blueshield.png';
import BCBS_CALIFORNIA_GLYPH from 'apps/public/assets/bcbs-california-glyph.png';
import BENEVITY_GLYPH from 'apps/public/assets/benevity-glyph.png';
import BENEVITY_LOGO from 'apps/public/assets/benevity-logo.png';
import BRMS_LOGO from 'apps/public/assets/logo-brms.png';
import CAFETERIAPLAN_LOGO from 'apps/public/assets/logo-cafeteriaplan.png';
import CANADALIFE_GLYPH from 'apps/public/assets/canadalife-glyph.png';
import CANADALIFE_LOGO from 'apps/public/assets/logo-canadalife.png';
import CHUBB_LOGO from 'apps/public/assets/logo-chubb.png';
import CIGNA_GLYPH from 'apps/public/assets/cigna-glyph.png';
import CONNECTYOURCARE_LOGO from 'apps/public/assets/connectyourcare-logo.png';
import COXHEALTH_LOGO from 'apps/public/assets/coxhealth-logo.png';
import DELTADENTAL_LOGO from 'apps/public/assets/logo-deltadental.png';
import DESJARDINS_GLYPH from 'apps/public/assets/desjardins-glyph.png';
import DISCOVERY_GLYPH from 'apps/public/assets/DBI-glyph.png';
import DISCOVERY_LOGO from 'apps/public/assets/logo-discovery-benefits.png';
import EXPRESS_SCRIPTS_LOGO from 'apps/public/assets/express-scripts-logo.png';
import EXPRESS_SCRIPTS_GLYPH from 'apps/public/assets/express-scripts-glyph.png';
import FILTER_LOGO from 'apps/public/assets/logo-filter.png';
import GUARDIAN_GLYPH from 'apps/public/assets/guardian-glyph.png';
import HEAL_GLYPH from 'apps/public/assets/heal-glyph.png';
import HEAL_LOGO from 'apps/public/assets/logo-heal.png';
import HEALTH_EQUITY_GLYPH from 'apps/public/assets/healthequity-glyph.png';
import HEALTH_EQUITY_LOGO from 'apps/public/assets/logo-healthequity.png';
import HELPR_LOGO from 'apps/public/assets/logo-helpr.png';
import HMSA_LOGO from 'apps/public/assets/hmsa-logo.png';
import HRC_LOGO from 'apps/public/assets/hrc-logo.png';
import INFINISOURCE_LOGO from 'apps/public/assets/infinisource-logo.png';
import IRISHLIFE_GLYPH from 'apps/public/assets/irishlife-glyph.png';
import IRISHLIFE_LOGO from 'apps/public/assets/irishlife-logo.png';
import LEAGUE_GLYPH from 'apps/public/assets/league-glyph.png';
import LEAGUE_LOGO from 'apps/public/assets/league-logo.png';
import LEGALZOOM_LOGO from 'apps/public/assets/logo-legalzoom.png';
import LINCOLN_GLYPH from 'apps/public/assets/lincoln-glyph.png';
import LINCOLN_LOGO from 'apps/public/assets/lincoln-logo.png';
import MANULIFE_GLYPH from 'apps/public/assets/manulife-glyph.svg';
import MANULIFE_LOGO from 'apps/public/assets/manulife-logo.png';
import MAVEN_LOGO from 'apps/public/assets/logo-maven.png';
import METLIFE_GLYPH from 'apps/public/assets/metlife-glyph.png';
import METLIFE_LOGO from 'apps/public/assets/metlife-logo.png';
import MDLIVE_LOGO from 'apps/public/assets/logo-mdlive.png';
import MUTUALOFOMAHA_LOGO from 'apps/public/assets/logo-mutualofomaha.png';
import MYFLEXFOLLARS_LOGO from 'apps/public/assets/logo-myflexdollars.png';
import OPTUM_GLYPH from 'apps/public/assets/optum-glyph.png';
import OPTUM_LOGO from 'apps/public/assets/logo-optum.png';
import PAYFLEX_LOGO from 'apps/public/assets/logo-payflex.png';
import PRUDENTIAL_LOGO from 'apps/public/assets/prudential-logo.png';
import PRINCIPAL_LOGO from 'apps/public/assets/logo-principal.png';
import PRUDENTIAL_GLYPH from 'apps/public/assets/prudential-glyph.png';
import RBC_LOGO from 'apps/public/assets/rbci_logo.png';
import RELIANCE_STANDARD_LOGO from 'apps/public/assets/reliancestandard-logo.png';
import RETHINK_LOGO from 'apps/public/assets/logo-rethink.png';
import SUTTON_LOGO from 'apps/public/assets/logo-sutton.png';
import TASC_LOGO from 'apps/public/assets/logo-tasc.png';
import THRIVEPASS_GLYPH from 'apps/public/assets/thrivepass-glyph.png';
import THRIVEPASS_LOGO from 'apps/public/assets/logo-thrivepass.png';
import TUFTS_LOGO from 'apps/public/assets/tufts-logo.png';
import UHC_LOGO from 'apps/public/assets/unitedhealthcare-logo.png';
import UNUM_LOGO from 'apps/public/assets/unum-logo.png';
import USABLE_LOGO from 'apps/public/assets/logo-usable.png';
import VSP_LOGO from 'apps/public/assets/logo-vsp.png';
import WAGEWORKS_LOGO from 'apps/public/assets/logo-wageworks.png';
import HSA_BANK from 'apps/public/assets/hsabank-logo.png';
import HMAA from 'apps/public/assets/hmaa-logo.png';
import EYEMED from 'apps/public/assets/eyemed-logo.png';

const SUNLIFE = 'sunlife';
export const vendorsToShowNoLogo = [SUNLIFE];
// Populate this map with vendor IDs and their logos
export const logoMap = {
  aetna: {
    logo: AETNA_LOGO,
  },
  amerihealthnj: {
    logo: AMERIHEALTH_LOGO,
  },
  amwell: {
    logo: AMWELL_LOGO,
  },
  allstate: {
    logo: ALLSTATE_LOGO,
  },
  bankofamerica: {
    glyph: BANKOFAMERICA_GLYPH,
    logo: BANKOFAMERICA_LOGO,
  },
  benevity: {
    glyph: BENEVITY_GLYPH,
    logo: BENEVITY_LOGO,
  },
  bluecrossblueshield: {
    glyph: BCBS_GLYPH,
    logo: BCBS_LOGO,
  },
  brms: {
    logo: BRMS_LOGO,
  },
  californiablueshield: {
    logo: BCBS_CALIFORNIA_LOGO,
    glyph: BCBS_CALIFORNIA_GLYPH,
  },
  cafeteriaplan: {
    logo: CAFETERIAPLAN_LOGO,
  },
  canadalife: {
    glyph: CANADALIFE_GLYPH,
    logo: CANADALIFE_LOGO,
  },
  chubb: {
    logo: CHUBB_LOGO,
  },
  cigna: {
    glyph: CIGNA_GLYPH,
  },
  connectyourcare: {
    logo: CONNECTYOURCARE_LOGO,
  },
  coxhealth: {
    logo: COXHEALTH_LOGO,
  },
  discoverybenefits: {
    glyph: DISCOVERY_GLYPH,
    logo: DISCOVERY_LOGO,
  },
  deltadental: {
    logo: DELTADENTAL_LOGO,
  },
  desjardins: {
    glyph: DESJARDINS_GLYPH,
  },
  expressscripts: {
    glyph: EXPRESS_SCRIPTS_GLYPH,
    logo: EXPRESS_SCRIPTS_LOGO,
  },
  filter: {
    logo: FILTER_LOGO,
  },
  gwl: {
    glyph: CANADALIFE_GLYPH,
    logo: CANADALIFE_LOGO,
  },
  guardian: {
    glyph: GUARDIAN_GLYPH,
  },
  heal: {
    logo: HEAL_LOGO,
    glyph: HEAL_GLYPH,
  },
  healthequity: {
    glyph: HEALTH_EQUITY_GLYPH,
    logo: HEALTH_EQUITY_LOGO,
  },
  helpr: {
    logo: HELPR_LOGO,
  },
  hmsa: {
    logo: HMSA_LOGO,
  },
  hrc: {
    logo: HRC_LOGO,
  },
  infinisource: {
    logo: INFINISOURCE_LOGO,
  },
  irishlife: {
    glyph: IRISHLIFE_GLYPH,
    logo: IRISHLIFE_LOGO,
  },
  league: {
    glyph: LEAGUE_GLYPH,
    logo: LEAGUE_LOGO,
  },
  legalzoom: {
    logo: LEGALZOOM_LOGO,
  },
  lincolnfinancial: {
    glyph: LINCOLN_GLYPH,
    logo: LINCOLN_LOGO,
  },
  manulife: {
    logo: MANULIFE_LOGO,
    glyph: MANULIFE_GLYPH,
  },
  maven: {
    logo: MAVEN_LOGO,
  },
  mdlive: {
    logo: MDLIVE_LOGO,
  },
  metlife: {
    logo: METLIFE_LOGO,
    glyph: METLIFE_GLYPH,
  },
  mutualofomaha: {
    logo: MUTUALOFOMAHA_LOGO,
  },
  myflexdollars: {
    logo: MYFLEXFOLLARS_LOGO,
  },
  optum: {
    glyph: OPTUM_GLYPH,
    logo: OPTUM_LOGO,
  },
  payflex: {
    logo: PAYFLEX_LOGO,
  },
  principal: {
    logo: PRINCIPAL_LOGO,
  },
  prudential: {
    glyph: PRUDENTIAL_GLYPH,
    logo: PRUDENTIAL_LOGO,
  },
  rbc: {
    logo: RBC_LOGO,
  },
  reliancestandard: {
    logo: RELIANCE_STANDARD_LOGO,
  },
  rethink: {
    logo: RETHINK_LOGO,
  },
  sutton: {
    logo: SUTTON_LOGO,
  },
  thrivepass: {
    logo: THRIVEPASS_LOGO,
    glyph: THRIVEPASS_GLYPH,
  },
  tufts: {
    logo: TUFTS_LOGO,
  },
  unitedhealthcare: {
    logo: UHC_LOGO,
  },
  unum: {
    logo: UNUM_LOGO,
  },
  usable: {
    logo: USABLE_LOGO,
  },
  tasc: {
    logo: TASC_LOGO,
  },
  vsp: {
    logo: VSP_LOGO,
  },
  wageworks: {
    logo: WAGEWORKS_LOGO,
  },
  hsabank: {
    logo: HSA_BANK,
  },
  hmaa: {
    logo: HMAA,
  },
  eyemed: {
    logo: EYEMED,
  },
};

export const VendorLogo = ({
  vendor_logo_url: vendorLogoUrl,
  vendor_glyph_url: vendorGlyphUrl,
  vendor_id: vendorId,
  size,
  useLogo,
}) => {
  if (vendorsToShowNoLogo.includes(vendorId)) return null;
  const glyph = vendorGlyphUrl || (logoMap?.[vendorId]?.glyph ?? null);
  const logo = vendorLogoUrl || (logoMap?.[vendorId]?.logo ?? null);

  return !glyph || useLogo ? (
    <img src={logo} width={64} alt="" />
  ) : (
    <img src={glyph} width={size} height={size} alt="" />
  );
};

VendorLogo.defaultProps = {
  vendor_logo_url: undefined,
  vendor_glyph_url: undefined,
  vendor_id: null,
  size: 50,
  useLogo: false,
};

VendorLogo.propTypes = {
  vendor_logo_url: PropTypes.string,
  vendor_glyph_url: PropTypes.string,
  vendor_id: PropTypes.string,
  size: PropTypes.number,
  useLogo: PropTypes.bool,
};
