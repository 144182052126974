import { call, select, fork, all, takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getDependentsReport } from 'common/services';
import { GET_DEPENDENTS_REPORT } from './dependents-report.types';
import {
  selectGroupId,
  selectActiveGroupId,
} from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { selectUserEmail } from 'apps/employer-experience-v2/employer-reports/reports.selectors';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import {
  ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
  ANALYTICS_EVENT_DOWNLOAD_FAILURE,
  ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
} from '../../../constants';

export function* requestDependentsReport({ isInHRAdminPortal }) {
  const groupId = yield select(selectGroupId);
  const activeGroupId = yield select(selectActiveGroupId);
  const userEmail = yield select(selectUserEmail);

  let params;
  if (groupId) {
    params = { groupId };
  } else {
    params = { groupId: activeGroupId };
  }

  try {
    yield call(toastActions.add, {
      type: TOAST_STATUS.INFO,
      textId: 'EMPLOYER_REPORT_REQUESTED_COPY',
      values: {
        email: userEmail,
      },
    });
    const response = yield request(
      GET_DEPENDENTS_REPORT,
      getDependentsReport(params),
      params,
    );

    const contentId =
      response?.info?.report?.report_files?.[0]?.content_id ?? null;

    if (!contentId) throw new Error('No content id');

    window.open(getContentUrl(contentId), '_blank');
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'dependents',
        detail: 'generate dependents report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_SUCCESS,
      });
    }
  } catch (error) {
    if (isInHRAdminPortal) {
      yield call(trackAnalyticsEvent, EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_INSIGHTS,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: 'dependents',
        detail: 'generate dependents report',
        sub_detail: ANALYTICS_EVENT_DOWNLOAD_FAILURE,
      });
    }
  }
}

export function* requestDependentsReportError() {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    textId: 'EMPLOYER_REPORT_ERROR_COPY',
    shouldAutoClose: false,
  });
}

export function* dependentsReportsSagas() {
  yield fork(all, [
    takeLatest(GET_DEPENDENTS_REPORT.BASE, requestDependentsReport),
    takeLatest(GET_DEPENDENTS_REPORT.ERRORED, requestDependentsReportError),
  ]);
}
