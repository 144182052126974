import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  FontAwesomeIcon,
  colour,
  getThemeValue,
  spaceInPx,
} from '@leagueplatform/ui-kit';
import { Flex } from 'common/components/primitives.view';
import { Link } from '@leagueplatform/genesis-commons';
import { Button } from 'apps/employer-experience/ui-kit';
import { FormattedMessage } from 'react-intl';

const UploadContainer = styled(Flex)`
  border: 1px solid ${colour('greyLighter')};
  border-style: dashed;
  border-radius: ${getThemeValue('radii.2')}px;
  height: 10rem;
  color: ${colour('grey')};
  background-color: ${colour('offWhite')};

  .fileUpload {
    width: 6rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }
`;

const DocumentWrapper = styled(Flex).attrs({ py: 1, px: 2 })`
  background-color: ${colour('offWhite')};
`;

const DocumentContainer = styled(Box)`
  color: ${colour('purple')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
    margin-left: ${spaceInPx(1)};
  }
`;

const RemoveDocumentButton = styled(Button)`
  color: ${colour('red')};

  &:hover {
    color: ${colour('darkRed')};
  }

  span {
    margin-left: ${spaceInPx(1)};
  }
`;

const resetInputValue = e => {
  e.target.value = null;
};

export const DocumentsUploaderPresenter = ({
  documents,
  onFileUpload,
  onDropFile,
  onDragOverFile,
  onRemoveDocument,
  id,
}) => (
  <>
    {documents.map(doc => (
      <DocumentWrapper
        justify="space-between"
        align="center"
        key={doc.content_id}
      >
        <DocumentContainer>
          <FontAwesomeIcon icon="file" />
          <a target="_blank" href={doc.url} rel="noopener noreferrer">
            {doc.title}
          </a>
        </DocumentContainer>
        <RemoveDocumentButton transparent onClick={() => onRemoveDocument(doc)}>
          <FontAwesomeIcon icon="trash-alt" />
          <span>
            <FormattedMessage id="REMOVE" />
          </span>
        </RemoveDocumentButton>
      </DocumentWrapper>
    ))}
    <UploadContainer
      justify="center"
      align="center"
      onDrop={e => onDropFile(e)}
      onDragOver={e => onDragOverFile(e)}
      mt={2}
    >
      <Flex justify="center" align="center">
        <FormattedMessage id="DRAG_A_FILE_HERE" />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- FIXME: automatically added for existing issue */}
        <Link marginX="half">
          <input
            id={id}
            className="fileUpload"
            type="file"
            onChange={e => onFileUpload(e.currentTarget.files[0])}
            onClick={e => resetInputValue(e)}
          />
          <FormattedMessage id="BROWSE" />
        </Link>
        <FormattedMessage id="UPLOAD_A_FILE" />
      </Flex>
    </UploadContainer>
  </>
);

DocumentsUploaderPresenter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  documents: PropTypes.arrayOf(PropTypes.any),
  onFileUpload: PropTypes.func,
  onRemoveDocument: PropTypes.func,
  onDropFile: PropTypes.func,
  onDragOverFile: PropTypes.func,
  id: PropTypes.string.isRequired,
};

DocumentsUploaderPresenter.defaultProps = {
  documents: [],
  onFileUpload: () => {},
  onRemoveDocument: () => {},
  onDropFile: () => {},
  onDragOverFile: () => {},
};
