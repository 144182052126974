import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@leagueplatform/genesis-commons';
import INFO_ICON from 'apps/public/assets/info-circle-icon.svg';

export const InfoBannerCard = ({
  children,
  backgroundColor,
  borderColor,
  iconSrc,
  iconWidth,
  ...props
}) => {
  return (
    <Flex
      padding="threeQuarters"
      borderRadius="small"
      borderStyle="solid"
      borderWidth="thin"
      alignItems="center"
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
    >
      <Box maxWidth="two">
        <img src={iconSrc} alt="" width={iconWidth} />
      </Box>
      <Box marginLeft="threeQuarters">{children}</Box>
    </Flex>
  );
};

InfoBannerCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  iconSrc: PropTypes.string,
  iconWidth: PropTypes.string,
};

InfoBannerCard.defaultProps = {
  backgroundColor: 'highlight.background.subdued',
  borderColor: 'highlight.border.default',
  iconSrc: INFO_ICON,
  iconWidth: 'two',
};
