import { call, put, takeLatest } from 'redux-saga/effects';
import { getPricingScenarioReport } from 'common/services';
import { GET_PRICING_SCENARIO_REPORT } from './pricing-config-qa-report.types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getPricingScenarioReports({ payload }) {
  try {
    yield put(GET_PRICING_SCENARIO_REPORT.start());
    const response = yield getPricingScenarioReport(payload);
    yield put(GET_PRICING_SCENARIO_REPORT.success(response));
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* pricingScenarioReportSaga() {
  yield takeLatest(GET_PRICING_SCENARIO_REPORT.BASE, getPricingScenarioReports);
}
