import React from 'react';
import PropTypes from 'prop-types';
import { EOICard } from './eoi-card.view';
import { PaperBeneficiariesCard } from './paper-beneficiaries-card.view';
import { DigitalBeneficiariesCard } from './digital-beneficiaries-card.view';
import { breakpoints, Responsive, Divider } from '@leagueplatform/ui-kit';
import { Box } from '@leagueplatform/genesis-commons';

const FormDivider = () => (
  <Responsive breakpoint={breakpoints.belowTablet}>
    <Divider bottomBorder marginTop={3} />
  </Responsive>
);

const FormListItem = ({ idx, children }) => (
  <>
    {idx > 0 && <FormDivider />}
    {children}
  </>
);

FormListItem.propTypes = {
  idx: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export const InsuranceFormsPresenter = ({
  eoiForms,
  beneficiaryForms,
  fromContent,
  ...props
}) => {
  const showFormTypeDivider =
    eoiForms?.length > 0 && beneficiaryForms?.length > 0;

  const formEOIContentId = fromContent?.sunlife_eoi;

  return (
    <>
      {eoiForms?.map((eoiForm, idx) => (
        <FormListItem key={eoiForm.form_id} idx={idx}>
          <Box marginTop="oneAndHalf" key={eoiForm.form_id}>
            <EOICard
              /* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */
              {...props}
              /* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */
              {...eoiForm}
              formEOIContentId={formEOIContentId}
            />
          </Box>
        </FormListItem>
      ))}
      {showFormTypeDivider && <FormDivider />}
      {beneficiaryForms?.map((beneficiaryForm, idx) => (
        <FormListItem key={beneficiaryForm.form_id} idx={idx}>
          <Box marginTop="oneAndHalf" key={beneficiaryForm.form_id}>
            {beneficiaryForm.digital ? (
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              <DigitalBeneficiariesCard {...props} {...beneficiaryForm} />
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
              <PaperBeneficiariesCard {...props} {...beneficiaryForm} />
            )}
          </Box>
        </FormListItem>
      ))}
    </>
  );
};

const formPropShape = PropTypes.shape({
  form_id: PropTypes.string,
  available: PropTypes.bool,
  digital: PropTypes.bool,
});

InsuranceFormsPresenter.propTypes = {
  eoiForms: PropTypes.arrayOf(formPropShape),
  beneficiaryForms: PropTypes.arrayOf(formPropShape),
  loadEmployerBenefitPlans: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  fromContent: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

InsuranceFormsPresenter.defaultProps = {
  eoiForms: [],
  beneficiaryForms: [],
  fromContent: {},
};
