import { combineReducers } from 'redux';
import { makeStatusReducer } from 'common/request/status.reducer';
import { STATUS } from 'common/request/status.constants';
import { GET_USER_OPTIMUM_PROFILE } from './user-optimum-profile.actions';

export const initialState = {
  status: STATUS.IDLE,
  entities: {},
};

const entities = (state = initialState.entities, action = {}) => {
  switch (action.type) {
    case GET_USER_OPTIMUM_PROFILE.SUCCEEDED:
      return { ...state, [action.meta.userId]: action.payload.info };
    default:
      return state;
  }
};

export const userOptimumProfileReducer = combineReducers({
  status: makeStatusReducer(GET_USER_OPTIMUM_PROFILE),
  entities,
});
