import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setQleEnrollment send API call to 'set_qle_enrollment' to activate NEX for member (conveyed via context in backend).
 *
 * @param {string} qleRequestId member's QLE request id
 */
export const setQleEnrollment = ({ qleRequestId }) =>
  call(websocketFetch, 'set_qle_enrollment', { qle_request_id: qleRequestId });
