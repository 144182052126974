import { fork, takeLatest, put, call } from 'redux-saga/effects';
import {
  setGroupBillingDivision,
  removeGroupBillingDivision,
} from 'common/services';

import {
  SET_BILLING_DIVISION,
  SET_BILLING_DIVISION_FORM_SUBMITTED,
  REMOVE_BILLING_DIVISION,
  REQUEST_REMOVE_BILLING_DIVISION,
} from './employer-divisions.types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* setBillingDivision(action) {
  try {
    yield put(SET_BILLING_DIVISION.start());
    const data = {
      default: action.payload.default,
      group_id: action.payload.group_id,
      billing_division: {
        billing_division_id: action.payload.billing_division_id,
        name: action.payload.name,
      },
    };
    const response = yield setGroupBillingDivision(data);
    yield put(SET_BILLING_DIVISION.success(response));
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'DIVISION_ADD_ERROR',
    });
  }
}

export function* removeBillingDivision({ payload }) {
  try {
    yield put(REMOVE_BILLING_DIVISION.start());
    const response = yield removeGroupBillingDivision(payload);
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'DIVISION_DELETED',
    });
    yield put(REMOVE_BILLING_DIVISION.success(response));
  } catch (error) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'DIVISION_DELETE_ERROR',
    });
  }
}

export function* employerDivisionsSagas() {
  yield fork(
    takeLatest,
    SET_BILLING_DIVISION_FORM_SUBMITTED,
    setBillingDivision,
  );
  yield fork(
    takeLatest,
    REQUEST_REMOVE_BILLING_DIVISION,
    removeBillingDivision,
  );
}
