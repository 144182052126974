import { selectApp as selectMemberApp } from 'apps/member-experience/state/selectors';
import { createSelector } from 'reselect';
import { prop } from 'lodash/fp';
import { get } from 'lodash';
import {
  selectEmployerPaysAllCosts,
  mapSummarySetsToSteps,
  selectSummaryBenefitSets,
  selectSummaryBenefitSteps,
  selectShowCostsPerPayPeriod,
  selectShowEmployerCosts,
  selectEmployerContribution,
  selectPayPeriodTotalCost,
} from 'common/coverage-summary/coverage-summary.selectors';
import {
  getPeriodTypeTranslation,
  DEFAULT_PERIOD_TYPE,
} from 'common/benefits/metadata';
import { isKPMG } from 'apps/utils/employer-utils';
import { US } from 'common/constants';

export const selectEmployerName = createSelector(
  selectMemberApp,
  prop('info.group.group.employer.company_name'),
);

export const selectBenefitClasses = createSelector(
  selectMemberApp,
  prop('info.group.benefit_classes'),
);

export const selectCurrency = createSelector(
  selectMemberApp,
  prop('info.group.group.currency'),
);

export const selectCountryCode = createSelector(
  selectMemberApp,
  prop('info.group.group.country_code'),
);

export const selectUserPayGroup = createSelector(
  selectPayPeriodTotalCost,
  payPeriod => get(payPeriod, 'pay_group') || DEFAULT_PERIOD_TYPE,
);

export const selectUserPayGroupPeriodTypeId = createSelector(
  selectUserPayGroup,
  getPeriodTypeTranslation,
);

export const selectCoverageSummarySteps = createSelector(
  selectSummaryBenefitSets,
  selectCurrency,
  selectShowCostsPerPayPeriod,
  selectUserPayGroupPeriodTypeId,
  selectSummaryBenefitSteps,
  mapSummarySetsToSteps,
);

export const selectFlexDollarTermId = createSelector(
  selectEmployerName,
  employerName => (isKPMG(employerName) ? 'WELLNESS_POOL' : 'FLEXIBLE_DOLLARS'),
);

export const selectShowTotalBreakdown = createSelector(
  selectCountryCode,
  selectEmployerPaysAllCosts,
  (countryCode, employerPaysAllCosts) =>
    countryCode !== US && !employerPaysAllCosts,
);

export const selectShowEmployerCostBanner = createSelector(
  selectShowEmployerCosts,
  selectEmployerContribution,
  (showEmployerCosts, employerContribution) => {
    const hasEmployerCostAmount =
      !!employerContribution?.F || employerContribution?.F === 0;
    return showEmployerCosts && hasEmployerCostAmount;
  },
);
