import React, { useState } from 'react';
import {
  spaceInPx,
  Text,
  MultiSelect,
  Box,
  Toggle,
} from '@leagueplatform/ui-kit';
import { LoaderButton } from 'common/components/loader-button/loader-button.view';
import PropTypes from 'prop-types';
import { JsonEdit } from './JsonEditMode';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { intlShape, useIntl } from '@leagueplatform/locales';
import { DownloadLinkCSV } from 'apps/employer-experience/ui-kit';
import {
  Flex,
  BodyOne,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';
import { TreeNodeContainerPresenter } from '../../enrollment-config/tree-navigation/tree-node-container.view';
import { treeStyle } from '../../enrollment-config/tree-navigation/tree-navigation-style';
import { Treebeard, decorators } from 'react-treebeard';
import { CopyPricingConfig } from './CopyPricingConfig/copy-pricing-config.container';

const CODE_EDITOR_LINE_NUMBERS_Z_INDEX = 4;

const StyledMultiSelect = styled(MultiSelect)`
  z-index: ${CODE_EDITOR_LINE_NUMBERS_Z_INDEX + 1};
  margin-bottom: ${spaceInPx(2)};
`;

export const PricingConfigurationBenefitDropDown = ({
  intl,
  employerBenefitsList,
  fetchBenefitPriceById,
  selectedBenefit,
  setSelectedBenefit,
  setSelectedBenefitLabel,
}) => {
  return (
    <StyledMultiSelect
      instanceId="pricing-1"
      name="Pricing Configuration"
      options={employerBenefitsList}
      value={selectedBenefit}
      placeholder={intl.formatMessage({ id: 'SELECT_BENEFIT' })}
      isMulti={false}
      onChange={({ value: benefitId, label } = {}) => {
        if (benefitId) {
          fetchBenefitPriceById(benefitId);
          setSelectedBenefit({ benefitId, label });
          setSelectedBenefitLabel(label);
        }
      }}
    />
  );
};

PricingConfigurationBenefitDropDown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  employerBenefitsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchBenefitPriceById: PropTypes.func.isRequired,
  selectedBenefit: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  setSelectedBenefit: PropTypes.func.isRequired,
  setSelectedBenefitLabel: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export const TreeNodeHeader = ({ terminal, node: { name } }) => {
  return (
    <Flex
      flexGrow={1}
      display="inline-flex"
      color="onSurface.text.primary"
      justifyContent="center"
      height="100%"
    >
      <Flex
        alignItems="center"
        fontSize="body2"
        lineHeight="body1"
        flexGrow={1}
        paddingLeft={terminal ? 'half' : 0}
      >
        {name}
      </Flex>
    </Flex>
  );
};
TreeNodeHeader.propTypes = {
  terminal: PropTypes.bool.isRequired,
  node: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export const PricingConfiguration = ({
  intl,
  employerBenefitsList,
  fetchBenefitPriceById,
  benefitId,
  employerBenefits,
  groupId,
  fetchBenefitPricesAsCsv,
  benefitPricesToCsvLoading,
  benefitPricesCsvContentId,
  structuredEmployeeBenefits,
}) => {
  const [selectedBenefit, setSelectedBenefit] = useState({});
  const [data, setData] = useState(structuredEmployeeBenefits);
  const [cursor, setCursor] = useState(false);
  const [isTreeView, setIsTreeView] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBenefitLabel, setSelectedBenefitLabel] = useState('');
  const { formatMessage } = useIntl();

  const onToggle = (node, toggled) => {
    const selectedNode = node;
    if (cursor) {
      cursor.active = false;
    }
    selectedNode.active = true;
    if (selectedNode.benefitId) {
      fetchBenefitPriceById(selectedNode.benefitId);
      setSelectedBenefit({
        label: selectedNode.name,
        value: selectedNode.benefitId,
      });
      setSelectedBenefitLabel(selectedNode.name);
    }
    if (selectedNode.children) {
      selectedNode.toggled = toggled;
    }
    setCursor(selectedNode);
    setData({ ...data });
  };

  const navigateToNode = ({ node, id }) => {
    const selectedNode = node;
    if (selectedNode?.benefitId === id) {
      return selectedNode;
    }
    selectedNode.toggled = true;
    // eslint-disable-next-line no-restricted-syntax -- FIXME: automatically added for existing issue
    for (const child of selectedNode?.children ?? []) {
      const result = navigateToNode({ node: child, id });
      if (result) {
        result.active = true;
        setCursor(result);
        return result;
      }
    }
    selectedNode.toggled = false;
    return undefined;
  };

  const onChange = () => {
    if (!isTreeView) {
      if (cursor) {
        cursor.active = false;
      }
      navigateToNode({
        node: data,
        id: selectedBenefit.value,
      });
    }
    setIsTreeView(!isTreeView);
  };

  return (
    <>
      {isModalVisible && (
        <CopyPricingConfig
          benefitId={benefitId}
          selectedBenefitLabel={selectedBenefitLabel}
          benefitType={employerBenefits[benefitId].benefitType}
          employerBenefitsList={employerBenefitsList}
          onClose={() => setIsModalVisible(false)}
        />
      )}
      <Flex justifyContent="space-between">
        <Text pb={3}>
          <FormattedMessage id="PRICING_DESIGN_BLURB" />
        </Text>
        <Flex>
          <Toggle onChange={onChange} checked={isTreeView} />
          <BodyOne marginLeft="one">List View</BodyOne>
        </Flex>
      </Flex>
      <Box mb={2}>
        <LoaderButton
          primary
          onClick={() => fetchBenefitPricesAsCsv(groupId)}
          loading={benefitPricesToCsvLoading}
        >
          <FormattedMessage id="EXPORT_PRICING_CONFIG_TO_CSV" />
        </LoaderButton>
        {benefitPricesCsvContentId && (
          <>
            <FormattedMessage id="PRICING_CONFIG_DOWNLOAD" />
            <DownloadLinkCSV contentId={benefitPricesCsvContentId} />
          </>
        )}
      </Box>
      {isTreeView ? (
        <Flex
          maxHeight={300}
          overflowY="auto"
          flexDirection="column"
          marginBottom="one"
          borderStyle="solid"
          borderColor="onSurface.border.default"
          borderRadius="small"
          borderWidth="thin"
        >
          <Treebeard
            style={treeStyle}
            onToggle={onToggle}
            data={data}
            decorators={{
              ...decorators,
              Header: TreeNodeHeader,
              Container: TreeNodeContainerPresenter,
            }}
          />
        </Flex>
      ) : (
        <PricingConfigurationBenefitDropDown
          intl={intl}
          employerBenefitsList={employerBenefitsList}
          fetchBenefitPriceById={fetchBenefitPriceById}
          selectedBenefit={selectedBenefit}
          setSelectedBenefit={setSelectedBenefit}
          setSelectedBenefitLabel={setSelectedBenefitLabel}
        />
      )}

      {benefitId && (
        <Flex
          justifyContent="space-between"
          backgroundColor="highlight.background.subdued"
          borderRadius="small"
          padding="oneAndHalf"
        >
          <Box>
            <Text>
              <strong>
                <FormattedMessage id="BENEFIT_ID" />:
              </strong>{' '}
              {benefitId}
            </Text>
            <Text>
              <strong>
                <FormattedMessage id="BENEFIT_TYPE" />:
              </strong>{' '}
              {employerBenefits[benefitId].benefitType}
            </Text>
          </Box>
          <SecondaryButton
            alignSelf="center"
            onClick={() => setIsModalVisible(true)}
          >
            {formatMessage({ id: 'COPY_PRICING_CONFIG' })}
          </SecondaryButton>
        </Flex>
      )}
      <JsonEdit />
    </>
  );
};

PricingConfiguration.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  employerBenefitsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchBenefitPriceById: PropTypes.func.isRequired,
  benefitId: PropTypes.string,
  intl: intlShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  employerBenefits: PropTypes.objectOf(PropTypes.any),
  groupId: PropTypes.string.isRequired,
  fetchBenefitPricesAsCsv: PropTypes.func.isRequired,
  benefitPricesToCsvLoading: PropTypes.bool.isRequired,
  benefitPricesCsvContentId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  structuredEmployeeBenefits: PropTypes.objectOf(PropTypes.any),
};

PricingConfiguration.defaultProps = {
  benefitId: null,
  employerBenefits: {},
  structuredEmployeeBenefits: {},
  benefitPricesCsvContentId: '',
};
