import { createRequestTypes } from 'common/websocket-redux';

export const CHANGE_USER_BENEFIT_CHANGE_ALLOCATION =
  'employee/benefit/CHANGE_ALLOCATION';

export const DO_CHANGE_USER_BENEFIT_CHANGE_ALLOCATION = createRequestTypes(
  'service/CHANGE_USER_BENEFIT_CHANGE_ALLOCATION',
);

export const RESET_CHANGE_ALLOCATION_STATUS =
  'employee/benefit/ui/RESET_CHANGE_ALLOCATION_STATUS';
