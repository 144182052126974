import {
  GET_PAYROLL_BUSINESS_RULES,
  GET_PAYROLL_BUSINESS_RULES_SCHEMA,
} from './payroll-config.types';

export const payrollConfigState = {
  exclusionsConfig: { type: 'object', properties: {} },
  schemaDefinition: { properties: {} },
};

export const payrollConfig = (state = payrollConfigState, action = {}) => {
  switch (action.type) {
    case GET_PAYROLL_BUSINESS_RULES.SUCCEEDED:
      return {
        ...state,
        exclusionsConfig: action.payload.info.payroll_business_rules,
      };
    case GET_PAYROLL_BUSINESS_RULES_SCHEMA.SUCCEEDED:
      return {
        ...state,
        schemaDefinition: action.payload.info.definitions.BusinessRulesDTO,
      };
    default:
      return state;
  }
};
