import { compose, lifecycle, withProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectEmployerBenefits, selectGroupId } from '../../selectors';
import { selectBenefitPlanEntities } from '../BenefitClass/Plans/selectors';
import {
  selectBenefitClasses,
  selectSelectedBenefitIdForPricingConfig,
  selectSelectedBenefitPrice,
  selectBenefitPricesToCsvLoading,
  selectBenefitPricesCsvContentId,
  selectStructuredEmployerBenefits,
} from '../selectors';
import { map, get } from 'lodash';
import { injectIntl } from 'react-intl';
import { PricingConfiguration } from './pricing-configuration.view';
import {
  fetchPlansForPricingConfiguration,
  fetchBenefitPriceById,
  fetchBenefitPricesAsCsv,
  clearBenefitPriceConfigState,
} from './pricing-configuration.actions';

export const getEmployerBenefitsList = (
  employerBenefits,
  employerBenefitPlans,
  employerBenefitClasses,
) => {
  return employerBenefits
    ? map(employerBenefits, eb => {
        const benefitClassId = get(
          employerBenefitPlans,
          `${eb.planId}.benefitClassId`,
          '',
        );
        const benefitClass = employerBenefitClasses.find(
          bc => bc.id === benefitClassId,
        );
        const benefitClassName = benefitClass ? benefitClass.name : undefined;

        // if either item is undefined, make sure we don't join it (which would cause extra spaces)
        const employerBenefitString = [
          eb.productName,
          benefitClassName,
          eb.fullName,
          eb.planName,
        ]
          .filter(item => item)
          .join(' ');

        return {
          text: employerBenefitString,
          label: employerBenefitString,
          value: eb.id,
        };
      })
    : [];
};

const withState = connect(
  createStructuredSelector({
    employerBenefits: selectEmployerBenefits,
    employerBenefitPlans: selectBenefitPlanEntities,
    employerBenefitClasses: selectBenefitClasses,
    selectedBenefitPriceConfiguration: selectSelectedBenefitPrice,
    benefitId: selectSelectedBenefitIdForPricingConfig,
    groupId: selectGroupId,
    benefitPricesToCsvLoading: selectBenefitPricesToCsvLoading,
    benefitPricesCsvContentId: selectBenefitPricesCsvContentId,
    structuredEmployeeBenefits: selectStructuredEmployerBenefits,
  }),
  {
    fetchBenefitPriceById,
    fetchPlansForPricingConfiguration,
    fetchBenefitPricesAsCsv,
    clearBenefitPriceConfigState,
  },
);

const withBenefitsList = withProps(
  ({ employerBenefits, employerBenefitPlans, employerBenefitClasses }) => {
    return {
      employerBenefitsList: getEmployerBenefitsList(
        employerBenefits,
        employerBenefitPlans,
        employerBenefitClasses,
      ),
    };
  },
);

const withLifecycleActions = lifecycle({
  componentWillMount() {
    this.props.fetchPlansForPricingConfiguration({
      groupId: this.props.groupId,
    });
  },
  componentWillUnmount() {
    this.props.clearBenefitPriceConfigState();
  },
});

const PricingConfigurationTab = compose(
  injectIntl,
  withState,
  withLifecycleActions,
  withBenefitsList,
)(PricingConfiguration);

export { PricingConfigurationTab };
