import { createRequestTypes } from 'common/websocket-redux';

export const LOAD_COVERAGE_SUMMARY = createRequestTypes(
  'summary/LOAD_COVERAGE_SUMMARY',
);

export const loadCoverageSummary = payload => ({
  type: LOAD_COVERAGE_SUMMARY.BASE,
  payload,
});
