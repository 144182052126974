import { normalize, schema } from 'normalizr';
import { GET_BENEFIT_PLAN_OPTIONS } from '../../pages/EmployerDetails/enrollment-config';

const bundlesSchema = new schema.Entity('bundles');

const planOptionsSchema = {
  bundles: [bundlesSchema],
};

const getBenefitPlanOptionsReducer = (
  state,
  { meta: { planId } = {}, payload: { info } = {} },
  responseSchema,
) => {
  if (!planId) return state;
  const normalized = normalize(info, responseSchema);
  const {
    entities: { bundles = {} },
  } = normalized;
  return {
    ...state,
    [planId]: Object.keys(bundles),
  };
};

export const employerBenefitBundlesByPlan = (
  state = {},
  { type, ...action } = {},
) => {
  switch (type) {
    case GET_BENEFIT_PLAN_OPTIONS.SUCCEEDED:
      return getBenefitPlanOptionsReducer(state, action, planOptionsSchema);

    default:
      return state;
  }
};
