import {
  EMPLOYER_SELECTED,
  SEARCH_TEXT_CHANGED,
  SEARCH_PAGE_REQUESTED,
} from './types';

export const searchTextChanged = searchText => {
  return {
    type: SEARCH_TEXT_CHANGED,
    payload: { searchText },
  };
};

export const searchPageRequested = (searchText, skip) => {
  return {
    type: SEARCH_PAGE_REQUESTED,
    payload: { searchText, skip },
  };
};

export const employerSelected = (groupId, newBenefits) => {
  return {
    type: EMPLOYER_SELECTED,
    payload: { groupId, newBenefits },
  };
};
