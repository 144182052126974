import React from 'react';
import { spaceInPx, Text, Input } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { ErrorWrapper } from 'common/components/error-wrapper/error-wrapper.view';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SubmitButton = styled(Button)`
  height: 46px;
  margin-left: ${spaceInPx(1)};
`;

const StyledErrorWrapper = styled(ErrorWrapper)`
  flex-grow: 2;
  min-width: 50%;
`;

export const HREmailInput = ({
  value,
  touched,
  errors,
  intl,
  handleChange,
  handleBlur,
  handleSubmit,
  isEditHREmailEnabled,
  setIsEditHREmailEnabled,
}) => {
  const { formatMessage } = intl;
  const submitWhenEnabled = () => {
    const isEnabledAndValid = isEditHREmailEnabled && !errors.qleApprovalEmails;

    if (!isEditHREmailEnabled) {
      setIsEditHREmailEnabled({ isEditHREmailEnabled: !isEditHREmailEnabled });
    } else if (isEnabledAndValid) {
      // Toggle isEditHREmailEnabled off is controlled in saga during submit.
      handleSubmit();
    }
  };

  return (
    <>
      <StyledErrorWrapper
        error={
          touched.qleApprovalEmails && errors.qleApprovalEmails ? (
            <Text m="0" fontSize={1} lineHeight="26px">
              {errors.qleApprovalEmails}
            </Text>
          ) : null
        }
      >
        <Input
          name="qleApprovalEmails"
          type="text"
          placeholder={formatMessage({ id: 'SEPARATE_EMAILS_BY_COMMA' })}
          value={value}
          hasError={false}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          disabled={!isEditHREmailEnabled}
        />
      </StyledErrorWrapper>
      {/* Only allow HR email editing if edit mode is enabled. Otherwise disabled.  */}
      <SubmitButton
        data-test-id="submit-button"
        primary
        type="button"
        onClick={submitWhenEnabled}
      >
        <Text>
          {isEditHREmailEnabled
            ? intl.formatMessage({ id: 'SAVE' })
            : intl.formatMessage({ id: 'EDIT' })}
        </Text>
      </SubmitButton>
    </>
  );
};

HREmailInput.propTypes = {
  value: PropTypes.string,
  touched: PropTypes.shape({
    qleApprovalEmails: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({
    qleApprovalEmails: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditHREmailEnabled: PropTypes.bool.isRequired,
  setIsEditHREmailEnabled: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

HREmailInput.defaultProps = {
  value: '',
};
