import {
  PRIVACY_POLICY_ES_URL,
  PRIVACY_POLICY_FR_URL,
  PRIVACY_POLICY_URL,
} from 'apps/terms/terms.constants';
import { isSpanish, isFrench } from '@leagueplatform/locales';

export const getPrivacyPolicyUrl = () => {
  if (isFrench()) return PRIVACY_POLICY_FR_URL;
  if (isSpanish()) return PRIVACY_POLICY_ES_URL;
  return PRIVACY_POLICY_URL;
};
