/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import {
  request,
  websocketFetch,
  CONFIGURE_CONNECTION,
  AUTHENTICATE_CONNECTION,
  REAUTHENTICATE_CONNECTION,
} from 'common/websocket-redux';
import {
  AUTHENTICATE_SESSION,
  AUTHENTICATE_AFTER_SIGN_IN,
} from './auth0.actions';
import { selectIsAuthenticating, selectAccessToken } from './auth0.selectors';
import { SIGN_OUT } from '../auth.actions';
import { getTargetUrl, attachCookiesWithAccessToken } from '../auth.utils';
import { Auth0ClientWrapperInstance } from './auth0.clientwrapper';
import mobileAuthStateListener, {
  shouldWaitForMobileAuth,
} from './auth0.mobile-auth-state-listener';
import { APP_CONFIG } from 'app-config';
import {
  AUTH0_CUSTOM_ERROR_TYPE,
  AUTH0_GENERIC_ERROR_PATH,
} from './auth0.constants';

export function* auth0Saga() {
  yield takeLatest(AUTHENTICATE_SESSION.ERRORED, redirectToSignOutSaga);
  yield takeLatest(AUTHENTICATE_AFTER_SIGN_IN.ERRORED, handleAuthError);
  yield takeLatest(AUTHENTICATE_SESSION.BASE, authenticateWithAuth0);
  yield takeLatest(AUTHENTICATE_AFTER_SIGN_IN.BASE, handleRedirectFromSignIn);
  yield takeLatest(AUTHENTICATE_AFTER_SIGN_IN.SUCCEEDED, redirectAfterSignIn);
  yield takeLatest(
    AUTHENTICATE_CONNECTION.BASE,
    authenticateWebSocket,
    AUTHENTICATE_CONNECTION,
  );
  yield takeLatest(
    REAUTHENTICATE_CONNECTION.BASE,
    authenticateWebSocket,
    REAUTHENTICATE_CONNECTION,
  );
  yield takeLatest(SIGN_OUT.BASE, redirectToSignOutSaga);
  yield takeLatest(
    [AUTHENTICATE_SESSION.SUCCEEDED, REAUTHENTICATE_CONNECTION.SUCCEEDED],
    attachContentCookiesWithAccessToken,
  );

  yield put(AUTHENTICATE_SESSION.request());
  yield all([
    take(AUTHENTICATE_SESSION.SUCCEEDED),
    take(CONFIGURE_CONNECTION.SUCCEEDED),
  ]);
  yield put(AUTHENTICATE_CONNECTION.request());
}

export function* authenticateWebSocketMessage(action) {
  try {
    const previousAccessToken = yield select(selectAccessToken);
    // if we don't have a previous access token than we are not yet authenticated and
    // should not try to re-authenticate
    if (!previousAccessToken) return;
    const accessToken = yield call([
      Auth0ClientWrapperInstance,
      Auth0ClientWrapperInstance.getAccessToken,
    ]);
    // if auth0 returns a new access token we need to re-authenticate the websocket
    const previousAccessTokenHasExpired =
      accessToken && accessToken !== previousAccessToken;
    if (previousAccessTokenHasExpired) {
      const isAuthenticating = yield select(selectIsAuthenticating);
      if (!isAuthenticating) {
        yield put(REAUTHENTICATE_CONNECTION.request());
      }
      // wait for the connection to be re-authenticated
      yield take(REAUTHENTICATE_CONNECTION.SUCCEEDED);
    }
  } catch (error) {
    yield put(REAUTHENTICATE_CONNECTION.error(error, { action }));
  }
}

function* redirectToSignInSaga() {
  // TODO: ID-723 -> now that we redirect to '/' after sign in, we don't
  // need to get the target URL. Can remove it to clean up this function
  const targetUrl = yield call(getTargetUrl);
  yield call(
    [Auth0ClientWrapperInstance, Auth0ClientWrapperInstance.loginWithRedirect],
    {
      appState: { targetUrl },
    },
  );
}

function* redirectToSignOutSaga() {
  // TODO: call setURLToRedirectAfterLogin() here
  yield call([Auth0ClientWrapperInstance, Auth0ClientWrapperInstance.logout], {
    returnTo: APP_CONFIG.REACT_APP_WR_URL,
  });
}

export function* redirectToSignUpSaga({ payload }) {
  const { inviteId, inviteType } = payload;

  yield call(
    [Auth0ClientWrapperInstance, Auth0ClientWrapperInstance.loginWithRedirect],
    {
      prompt: 'login',
      screen_hint: 'signup',
      el_invite_code: inviteId,
      el_invite_type: inviteType,
    },
  );
}

function* authenticateWithAuth0() {
  yield put(AUTHENTICATE_SESSION.start());
  try {
    const isAuthenticated = yield call([
      Auth0ClientWrapperInstance,
      Auth0ClientWrapperInstance.isAuthenticated,
    ]);

    // the user has a valid session (authenticated)
    if (isAuthenticated || shouldWaitForMobileAuth()) {
      if (shouldWaitForMobileAuth()) {
        // A mobile app is embedding us and has indicated that auth state will be passed;
        // wait for it before attempting to retrieve the user details.
        // Here we rely on the promise returned from isInitialStateSet only being resolved when
        // the state is set.
        yield call([
          mobileAuthStateListener,
          mobileAuthStateListener.isInitialStateSet,
        ]);
      }
      const user = yield call([
        Auth0ClientWrapperInstance,
        Auth0ClientWrapperInstance.getUser,
      ]);
      yield put(AUTHENTICATE_SESSION.success({ user }));
    } else if (isRedirectFromSignIn() || hasErrorFromSignIn()) {
      // the user is authenticating, we need to validate their session
      yield put(AUTHENTICATE_AFTER_SIGN_IN.request());
    } else {
      // the user is not authenticated and needs to sign in
      // TODO: add setURLToRedirectAfterLogin() here
      yield call(redirectToSignInSaga);
    }
  } catch (error) {
    yield put(AUTHENTICATE_SESSION.error(error));
  }
}

function hasErrorFromSignIn() {
  const { search } = window.location;
  return search.includes('error=');
}

/**
 * When an error has been received from auth0, we want to
 * redirect the user to the /auth-error/ page and include the error message
 * provided by auth0 in a URL parameter.
 *
 * Since we have multiple apps that use different stores, we can't easily
 * store the error message in state when navigating the user to the auth-error
 * page. Because of this, we are passing the message in the URL parameter
 *
 * @param  {} {payload} - includes a message that will be the authentication
 * error message to be displayed to the user
 */
function* handleAuthError({ payload }) {
  const { message } = payload;

  yield put(
    push({
      pathname: `/auth-error/${message}`,
    }),
  );
}

function isRedirectFromSignIn() {
  const { search } = window.location;
  return search.includes('code=') && search.includes('state=');
}

function* handleRedirectFromSignIn() {
  // should try to read cookie
  yield put(AUTHENTICATE_AFTER_SIGN_IN.start());
  try {
    if (hasErrorFromSignIn()) {
      // the 'error' and 'error_description' both come from auth0.
      // if there is a sign in issue, a user will be redirected to
      // a url containing these search params. In that case, we want
      // to throw an error and redirect the to the auth-error page
      const searchParams = new URLSearchParams(window.location.search);
      const errorType = searchParams.get('error');
      const errorDescription = searchParams.get('error_description');
      const errorMessage =
        errorType === AUTH0_CUSTOM_ERROR_TYPE
          ? errorDescription
          : AUTH0_GENERIC_ERROR_PATH;
      throw new Error(errorMessage);
    }
    yield call([
      Auth0ClientWrapperInstance,
      Auth0ClientWrapperInstance.handleRedirectCallback,
    ]);
    const user = yield call([
      Auth0ClientWrapperInstance,
      Auth0ClientWrapperInstance.getUser,
    ]);
    yield put(AUTHENTICATE_AFTER_SIGN_IN.success({ user }));
    yield put(AUTHENTICATE_SESSION.success({ user }));
  } catch (error) {
    yield put(AUTHENTICATE_AFTER_SIGN_IN.error(error));
  }
}

function* redirectAfterSignIn() {
  yield put(replace('/'));
}

function* authenticateWebSocket(ActionTypes) {
  try {
    const jwt = yield call([
      Auth0ClientWrapperInstance,
      Auth0ClientWrapperInstance.getAccessToken,
    ]);

    yield request(
      ActionTypes,
      [websocketFetch, 'authenticate_jwt', { jwt, api_version: 1 }],
      { jwt },
    );
  } catch (error) {
    yield put(ActionTypes.error(error));
  }
}

// Generator function to wrap call to attachCookiesWithAccessToken
// passed to takeLatest
function* attachContentCookiesWithAccessToken() {
  yield call(
    attachCookiesWithAccessToken,
    APP_CONFIG.REACT_APP_CONTENT_SERVER_URL,
  );
}

/**
 * @private exported for testing purposes only
 */
export const privateExports = {
  authenticateWebSocket,
  authenticateWithAuth0,
  handleRedirectFromSignIn,
  redirectAfterSignIn,
  redirectToSignInSaga,
  redirectToSignOutSaga,
  redirectToSignUpSaga,
  handleAuthError,
  attachContentCookiesWithAccessToken,
};
