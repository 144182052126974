export const INVITE_STATES = {
  SENT: 'sent',
  REDEEMED: 'redeemed',
  EXPIRED: 'expired',
};

export const EMAIL_EXISTS_ERROR_CODE =
  'email_already_associated_with_user_account';

/**
 * @function - Generates the query string for viewing a dependent
 * If there is a qleId present, we assume this is coming from the qle request landing page
 * and that users should be allowed to remove with the reason being the qleType
 * and the date of removal being the qleDate
 *
 * If there is no qleId, we allow the user to remove, but we don't show the qleType as the reason
 * Instead the reason is the reason user enters in the "remove dependent" form textfield
 * and the date is the date the user enters in the "remove dependent" form date input field
 *
 * Possible todo: move this into posting the data to WR instead
 * @param {*} a bag of details needed to generate the query string, contains:
 *
 * @param qleId - string
 * @param allowRemoveDependents - boolean
 * @param hsaType - string
 * @param hsaDate - string
 * @param qleType - string
 * @param qleDate - string
 */
export const viewDependentQueryString = ({
  qleId,
  allowRemoveDependents,
  hsaType,
  hsaDate,
  qleType,
  qleDate,
}) => {
  let queryString = '';

  if (qleId) {
    queryString = `?qleId=${qleId}&allowRemoveDependents=${allowRemoveDependents}&qleType=${qleType}&qleDate=${qleDate}`;
  } else if (hsaType && hsaDate) {
    // Rename `hsa` prefixed query params to `qle` prefix to match WR's query convention
    queryString = `?qleType=${hsaType}&qleDate=${hsaDate}&allowRemoveDependents=${allowRemoveDependents}`;
  }

  return queryString;
};
