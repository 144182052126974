import React from 'react';
import { connect } from 'react-redux';
import {
  withStateHandlers,
  branch,
  lifecycle,
  renderComponent,
  compose,
} from 'recompose';
import { viewingBulkAddEmployee } from './actions';
import { steps as bulkAddSteps } from './constants';
import { selectGroupId } from '../Employee/selectors';
import DownloadTemplate from './DownloadTemplate';
import UploadCensus from './UploadCensus';
import SaveImport from './SaveImport';

const withStep = withStateHandlers(
  ({ activeStepIndex = 1 }) => ({ activeStepIndex }),
  {
    stepForward:
      ({ activeStepIndex }) =>
      (value = 1) => ({
        activeStepIndex: activeStepIndex + value,
      }),
    stepBackward:
      ({ activeStepIndex }) =>
      (value = 1) => ({
        activeStepIndex: activeStepIndex - value,
      }),
  },
);

export const DefaultStep = steps => props =>
  (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    <DownloadTemplate steps={steps} {...props} />
  );

export const withUploadCensusStep = steps =>
  branch(
    ({ activeStepIndex }) => activeStepIndex === steps.uploadCensus.index,
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    renderComponent(props => <UploadCensus steps={steps} {...props} />),
  );

export const withSaveImportStep = steps =>
  branch(
    ({ activeStepIndex }) => activeStepIndex === steps.saveImport.index,
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    renderComponent(props => <SaveImport steps={steps} {...props} />),
  );

const withViewState = connect(
  state => ({
    groupId: selectGroupId(state),
  }),
  { viewingBulkAddEmployee },
);

const withInitializer = lifecycle({
  componentDidMount() {
    this.props.viewingBulkAddEmployee(true);
  },

  componentWillUnmount() {
    this.props.viewingBulkAddEmployee(false);
  },
});

export default compose(
  withViewState,
  withStep,
  withInitializer,
  withUploadCensusStep(bulkAddSteps),
  withSaveImportStep(bulkAddSteps),
)(DefaultStep(bulkAddSteps));
