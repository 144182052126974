export const AGENT_TYPES = {
  REGISTERED_NURSE: 'health_concierge',
  CARE_NAVIGATOR: 'care_navigator',
  CUSTOMER_CARE: 'customer_care',
};

export const AGENT_TYPE_SLUGS = {
  REGISTERED_NURSE: 'health-concierge',
  CARE_NAVIGATOR: 'care-navigator',
  CUSTOMER_CARE: 'customer-care',
};

export const AGENT_TYPE_SLUG_TO_KEY = {
  [AGENT_TYPE_SLUGS.REGISTERED_NURSE]: AGENT_TYPES.REGISTERED_NURSE,
  [AGENT_TYPE_SLUGS.CARE_NAVIGATOR]: AGENT_TYPES.CARE_NAVIGATOR,
  [AGENT_TYPE_SLUGS.CUSTOMER_CARE]: AGENT_TYPES.CUSTOMER_CARE,
};

export const AGENT_TYPE_TO_SLUG = {
  [AGENT_TYPES.REGISTERED_NURSE]: AGENT_TYPE_SLUGS.REGISTERED_NURSE,
  [AGENT_TYPES.CARE_NAVIGATOR]: AGENT_TYPE_SLUGS.CARE_NAVIGATOR,
  [AGENT_TYPES.CUSTOMER_CARE]: AGENT_TYPE_SLUGS.CUSTOMER_CARE,
};

export const AGENT_TYPE_ANALYTICS = {
  [AGENT_TYPES.REGISTERED_NURSE]: 'Registered Nurse',
  [AGENT_TYPES.CARE_NAVIGATOR]: 'Care Navigator',
  [AGENT_TYPES.CUSTOMER_CARE]: 'Customer Care',
};

export const SCREEN_NAMES_ANALYTICS = {
  ACTIVE_CHAT: 'active chat',
  EMAIL_DETAILS: 'email details',
  WAITING_ROOM_QUEUE: 'waiting room queue',
  SERVICE_DETAIL: 'service detail',
};

export const IS_SERVICE_OFFLINE_ANALYTICS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
};

export const COMMUNICATION_TYPE_ANALYTICS = {
  CHAT: 'chat',
  EMAIL: 'email',
};

export const DETAIL_ANALYTICS = {
  SEND_EMAIL: 'send email',
  START_CHAT: 'start chat',
};

export const LIMITS = {
  CHAT_TRANSCRIPTS: 10,
};

// TODO: When Registered Nurse starts being used, update here
export const CHAT_DISPLAY_NAMES = {
  CUSTOMER_CARE: ['Benefits Concierge', 'Conseiller en prestations'],
  CARE_NAVIGATOR: ['Care Navigator', 'Navigateur de soins de santé'],
  REGISTERED_NURSE: ['Care Team Expert', 'Expert en soutien'],
};

export const MODALS = {
  OFFLINE_CASE: 'OFFLINE_CASE',
  ONLINE_HOURS: 'ONLINE_HOURS',
};

export const POPOUT_CHAT_WINDOW_URLS = {
  [AGENT_TYPES.CARE_NAVIGATOR]: '/app/member/chat/care-navigator/popout',
  [AGENT_TYPES.CUSTOMER_CARE]: '/app/member/chat/customer-care/popout',
  [AGENT_TYPES.REGISTERED_NURSE]: '/app/member/chat/health-concierge/popout',
};

export const EMBED_CHAT_WINDOW_URLS = {
  [AGENT_TYPES.CARE_NAVIGATOR]: '/app/member/chat/care-navigator/embed',
  [AGENT_TYPES.CUSTOMER_CARE]: '/app/member/chat/customer-care/embed',
  [AGENT_TYPES.REGISTERED_NURSE]: '/app/member/chat/health-concierge/embed',
};
