import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function importPayrollSchedule calls the backend to add a new payroll schedule
 * @param payload contains
 *    group_id - group associated with this schedule
 *    document_url - link to the google sheet for the pay schedule
 *    plan_period - the plan period the calendar is uploaded for
 */
export const importPayrollSchedule = payload =>
  call(websocketFetch, 'import_payroll_schedule', payload);
