import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const INVITE_ACCESS_VISITED = 'invite-access/VISITED';

export const ELIGIBILITY_PAGE_LIFECYCLE = createLifecycleTypes(
  'invite-access/ELIGIBILITY_PAGE_LIFECYCLE',
);

export const VERIFICATION_PAGE_LIFECYCLE = createLifecycleTypes(
  'invite-access/VERIFICATION_PAGE_LIFECYCLE',
);

export const INVITE_EXPIRED_PAGE_LIFECYCLE = createLifecycleTypes(
  'invite-access/INVITE_EXPIRED_PAGE_LIFECYCLE',
);

export const INVITE_NOT_VERIFIED_PAGE_LIFECYCLE = createLifecycleTypes(
  'invite-access/INVITE_NOT_VERIFIED_PAGE_LIFECYCLE',
);

export const GET_GROUP_PRE_ENROLLMENT_CONFIG = createRequestTypes(
  'invite-access/GET_GROUP_PRE_ENROLLMENT_CONFIG',
);

export const ELIGIBILITY_SELECTED = 'invite-access/ELIGIBILITY_SELECTED';

export function eligibilitySelected({
  isEligible,
  anonymousGroupId,
  employerIdentifier,
}) {
  return {
    type: ELIGIBILITY_SELECTED,
    payload: { isEligible, anonymousGroupId, employerIdentifier },
  };
}

export const VERIFY_ACCOUNT = createRequestTypes(
  'invite-access/VERIFY_ACCOUNT',
);

export const VERIFICATION_FORM_SUBMITTED =
  'invite-access/VERIFICATION_FORM_SUBMITTED';

export const submitVerificationForm = ({
  firstName,
  lastName,
  dateOfBirth,
  emailAddress,
  groupConfigId,
  uniqueIdentifiers,
  employerIdentifier,
  invitationId,
}) => ({
  type: VERIFICATION_FORM_SUBMITTED,
  payload: {
    firstName,
    lastName,
    dateOfBirth,
    emailAddress,
    groupConfigId,
    uniqueIdentifiers,
    employerIdentifier,
    invitationId,
  },
});

/**
 * Actions created just for analytics purposes
 */

export const IDENTIFIER_HINT_TOGGLED = 'invite-access/IDENTIFIER_HINT_TOGGLED';

export function identifierHintToggled(visible) {
  return {
    type: IDENTIFIER_HINT_TOGGLED,
    payload: { visible },
  };
}

export const ELIGIBILITY_TOOLTIP_OPENED =
  'invite-access/ELIGIBILITY_TOOLTIP_OPENED';

export function eligibilityTooltipOpened() {
  return {
    type: ELIGIBILITY_TOOLTIP_OPENED,
  };
}

export const ACCOUNT_FIELDS_INVALIDATED =
  'invite-access/ACCOUNT_FIELDS_INVALIDATED';

export function accountFieldsInvalidated(fields, invitationId) {
  return {
    type: ACCOUNT_FIELDS_INVALIDATED,
    payload: fields,
    meta: {
      invitationId,
    },
  };
}

export const CREATE_PASSWORD_SELECTED =
  'invite-access/CREATE_PASSWORD_SELECTED';

export function createPasswordSelected() {
  return { type: CREATE_PASSWORD_SELECTED };
}

export const SIGN_IN_SELECTED = 'invite-access/SIGN_IN_SELECTED';

export function signInSelected() {
  return { type: SIGN_IN_SELECTED };
}

export const HELP_EMAIL_SELECTED = 'invite-access/HELP_EMAIL_SELECTED';

export function helpEmailSelected() {
  return { type: HELP_EMAIL_SELECTED };
}
