import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik-legacy';
import { injectIntl } from 'react-intl';
import {
  selectGroupId,
  selectGroupLocations,
  selectBillingUpdateStatus,
} from '../../selectors';
import {
  groupInvoicePaymentFormSubmitted,
  resetUpdateStatus,
} from '../../actions';
import PaymentMethods from './payment-methods.view';

const withFormikEnhancer = Formik({
  handleSubmit: (values, { props }) => {
    let paymentInfo = null;
    if (values.method === 'invoice') {
      // send request to set payment method to imvoice
      const loc = props.groupLocations[0] ? props.groupLocations[0] : {};
      paymentInfo = {
        name: 'Invoice Info',
        billing_address: loc,
      };
    }
    props.groupInvoicePaymentFormSubmitted({
      groupId: props.groupId,
      info: paymentInfo,
    });
  },
  mapPropsToValues: props => {
    const formValues = {
      method: 'creditCard',
      changed: false,
    };
    if (props.invoiceAsPayment) {
      formValues.method = 'invoice';
    }
    return formValues;
  },
});

const mapStateToProps = state => ({
  groupId: selectGroupId(state),
  groupLocations: selectGroupLocations(state),
  updateStatus: selectBillingUpdateStatus(state),
  resetUpdateStatus,
});

export default compose(
  connect(mapStateToProps, {
    groupInvoicePaymentFormSubmitted,
    resetUpdateStatus,
  }),
  injectIntl,
  withFormikEnhancer,
)(PaymentMethods);
