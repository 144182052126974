import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BodyOne,
  BodyOneSecondary,
  Box,
  css,
  Flex,
  genesisStyled,
  HeadingThree,
  PrimaryButton,
  QuietButton,
  SubtitleOne,
} from '@leagueplatform/genesis-commons';
import { FontAwesomeIcon, Icon, ICONS } from '@leagueplatform/ui-kit';
import {
  Dropdown,
  DropdownItem,
  DropdownItems,
} from 'common/components/dropdown';
import faEllipsisH from '@fortawesome/fontawesome-free-solid/faEllipsisH';
import { useIntl } from '@leagueplatform/locales';
import { PAGE_STATE } from './constants';
import { ImportDeductionCode } from './import-deduction-code.container';
import { useFilterOptions } from '../../resources/deduction-codes/use-filter-options.hook';

const sortedByCode = list =>
  list.sort((a, b) => {
    if (a.code < b.code) return -1;
    if (a.code > b.code) return 1;
    return 0;
  });

// eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
const Row = props => <Box as="tr" {...props} />;
const HeaderCell = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <SubtitleOne as="th" textAlign="left" paddingY="half" {...props} />
);
const RowCell = props => (
  <BodyOne
    as="td"
    paddingLeft="half"
    paddingY="threeQuarters"
    borderTopWidth="thin"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  />
);

const DropdownTrigger = ({ onToggle }) => {
  const { formatMessage } = useIntl();
  return (
    <QuietButton
      paddingX="one"
      onClick={() => {
        onToggle();
      }}
      aria-label={formatMessage({ id: 'MORE_OPTIONS' })}
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </QuietButton>
  );
};
DropdownTrigger.propTypes = {
  onToggle: PropTypes.func,
};
DropdownTrigger.defaultProps = {
  onToggle: () => {},
};

const StyledDropdownItems = genesisStyled(DropdownItems)(
  css({
    '&::before': {
      content: 'none',
    },
    paddingY: 'one',
    top: '50%',
    left: '50%',
    zIndex: 1,
  }),
);
const StyledDropdownItem = genesisStyled(DropdownItem)(
  css({
    paddingY: 'half',
    paddingX: 'oneAndHalf',
  }),
);
const StyledHeaderCell = genesisStyled(HeaderCell)(
  css({
    paddingRight: 'oneAndHalf',
    verticalAlign: 'middle',
  }),
);
const StyledRowCell = genesisStyled(RowCell)(
  css({
    fontSize: 'body2',
    paddingRight: 'half',
    verticalAlign: 'middle',
  }),
);
const StyledBody = genesisStyled.tbody`
  word-break: break-all;
`;

const ImportButton = ({ setShowImport }) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      alignSelf="center"
      marginTop="one"
      cursor="pointer"
      onClick={() => setShowImport(true)}
    >
      <Icon icon={ICONS.FILE_ARROW_UP} size="24px" />
      <BodyOne marginLeft="one">
        {formatMessage({ id: 'UPLOAD_DEDUCTION_CODES' })}
      </BodyOne>
    </Flex>
  );
};
ImportButton.propTypes = {
  setShowImport: PropTypes.func.isRequired,
};

const ExportButton = ({ onClick }) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      alignSelf="center"
      marginTop="one"
      cursor="pointer"
      onClick={() => onClick()}
    >
      <Icon icon={ICONS.FILE_DOWNLOAD} size="24px" />
      <BodyOne marginLeft="one">
        {formatMessage({ id: 'EXPORT_DEDUCTION_CODES' })}
      </BodyOne>
    </Flex>
  );
};
ExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const DeductionCodeTablePresenter = ({
  deductionCodes,
  setCurrentCode,
  setPageState,
  groupId,
  deleteDeductionCodes,
  exportDeductionCodes,
  payrollSchedules,
}) => {
  const { formatMessage } = useIntl();
  const [showImport, setShowImport] = useState(false);
  const { filterOptions, getLabelByFilterOptionAndValue } = useFilterOptions({
    payrollSchedules,
  });

  return (
    <Box>
      {showImport && (
        <ImportDeductionCode
          groupId={groupId}
          onClose={() => setShowImport(false)}
        />
      )}
      <Flex justifyContent="space-between" marginBottom="one">
        <Box>
          <HeadingThree>{formatMessage({ id: 'DEDUCTION_CODE' })}</HeadingThree>
          <BodyOne>{formatMessage({ id: 'DEDUCTION_CODE_COPY' })}</BodyOne>
        </Box>
        <PrimaryButton
          onClick={() => {
            setCurrentCode({ id: '', code: '', filter: {} });
            setPageState(PAGE_STATE.CREATING);
          }}
        >
          {formatMessage({ id: 'NEW_MAPPING' })}
        </PrimaryButton>
      </Flex>
      {deductionCodes.length > 0 ? (
        <>
          <Box as="table" width={1} marginTop="oneAndHalf">
            <thead>
              <Row>
                <StyledHeaderCell>
                  {formatMessage({ id: 'DEDUCTION_CODE' })}
                </StyledHeaderCell>
                {filterOptions.map(option => (
                  <StyledHeaderCell key={option.fieldName}>
                    {option.label}
                  </StyledHeaderCell>
                ))}
              </Row>
            </thead>
            <StyledBody>
              {sortedByCode(deductionCodes).map(code => {
                return (
                  <Row key={code.id}>
                    <StyledRowCell>{code.code}</StyledRowCell>
                    {filterOptions.map(option => (
                      <StyledRowCell key={option.fieldName}>
                        {getLabelByFilterOptionAndValue(
                          option,
                          code?.filter[option.fieldName],
                        )}
                      </StyledRowCell>
                    ))}
                    <RowCell>
                      <Dropdown>
                        <DropdownTrigger />
                        <StyledDropdownItems>
                          <StyledDropdownItem
                            onClick={() => {
                              setCurrentCode(code);
                              setPageState(PAGE_STATE.UPDATING);
                            }}
                          >
                            {formatMessage({ id: 'EDIT' })}
                          </StyledDropdownItem>
                          <StyledDropdownItem
                            onClick={() =>
                              deleteDeductionCodes({
                                group_id: groupId,
                                ids: [code.id],
                              })
                            }
                          >
                            {formatMessage({ id: 'DELETE' })}
                          </StyledDropdownItem>
                        </StyledDropdownItems>
                      </Dropdown>
                    </RowCell>
                  </Row>
                );
              })}
            </StyledBody>
          </Box>
          <Flex flexDirection="column" textAlign="center">
            <ImportButton setShowImport={setShowImport} />
          </Flex>
          <Flex flexDirection="column" textAlign="center">
            <ExportButton
              onClick={() => exportDeductionCodes({ group_id: groupId })}
            />
          </Flex>
        </>
      ) : (
        <Flex flexDirection="column" paddingY="five" textAlign="center">
          <BodyOneSecondary>
            {formatMessage({ id: 'NO_DEDUCTION_CODES' })}
          </BodyOneSecondary>
          <ImportButton setShowImport={setShowImport} />
        </Flex>
      )}
    </Box>
  );
};

DeductionCodeTablePresenter.propTypes = {
  setCurrentCode: PropTypes.func.isRequired,
  setPageState: PropTypes.func.isRequired,
  deleteDeductionCodes: PropTypes.func.isRequired,
  exportDeductionCodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  deductionCodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  payrollSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
};
