/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { components as ReactSelect } from 'react-select';
// @ts-ignore
import css from '@styled-system/css';
import checkmarkIcon from './assets/circled-checkmark.svg';
import addIcon from './assets/circled-add.svg';
import chevronDownIcon from './assets/chevron-down.svg';
import clearIcon from './assets/clear.svg';
import dividerIcon from './assets/divider.svg';

import {
  Image,
  Box,
  Flex,
  SubtitleOne,
  BodyOne,
  Overline,
  trueOrUndefined,
  pickStyleProps,
  pickLayoutProps,
  omitLayoutProps,
  pickGlobalHTMLProps,
  BorderColorPropsType,
} from '@leagueplatform/genesis-commons';

const borderColor = ({
  error = false,
  isFocused = false,
  menuIsOpen = false,
  isDisabled = false,
} = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  let borderColor;
  if (error) {
    borderColor = 'interactive.border.critical.default';
  } else if (isDisabled) {
    borderColor = 'interactive.border.disabled';
  } else if (isFocused || menuIsOpen) {
    borderColor = 'interactive.focus.outer';
  } else {
    borderColor = 'interactive.border.default';
  }
  return borderColor;
};
interface ComponentProps {
  children?: React.ReactElement;
  innerProps?: Record<string, unknown>;
  innerRef?: React.Ref<typeof React.Component>;
  selectProps: Record<string, unknown>;
  error: boolean;
  isFocused: boolean;
  menuIsOpen: boolean;
  isDisabled: boolean;
  data: any;
  hasValue: boolean;
  css?: Record<string, string>;
}
export const SelectContainer = ({
  children,
  innerProps,
  innerRef,
  selectProps,
}: ComponentProps) => (
  <Flex
    className="Genesis-Combobox-Container"
    position="relative"
    height="three"
    minHeight="three"
    flexDirection="column"
    {...pickLayoutProps(selectProps)}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </Flex>
);

export const Control = ({
  innerProps,
  innerRef,
  selectProps,
  children,
  ...props
}: ComponentProps) => (
  <Flex
    flex="1"
    borderRadius="small"
    borderWidth="thin"
    paddingTop="quarter"
    paddingBottom="quarter"
    paddingLeft="half"
    paddingRight="half"
    hoverStyle={{
      cursor: 'pointer',
      borderColor: 'interactive.border.hovered',
    }}
    borderColor={borderColor({ ...props, ...selectProps }) as any}
    backgroundColor={
      props.isDisabled
        ? 'interactive.background.disabled'
        : 'interactive.background.default'
    }
    color={
      props.isDisabled
        ? 'interactive.action.disabled'
        : 'interactive.action.primary'
    }
    {...omitLayoutProps(pickStyleProps(selectProps))}
    css={css({
      position: 'relative',
      '::before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        right: '-2px',
        bottom: '-2px',
        borderRadius: 'small',
      },
      ':focus-within::before': {
        boxShadow: 'focusOutline',
      },
    })}
    className="Genesis-Combobox-Control"
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </Flex>
);

export const ValueContainer = ({
  children,
  innerProps,
  innerRef,
}: ComponentProps) => (
  <Flex
    flexGrow={1}
    flexShrink={1}
    flexBasis="0"
    alignItems="center"
    flexWrap="wrap"
    paddingX="quarter"
    overflow="hidden"
    position="relative"
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </Flex>
);

export const Placeholder = ({
  children,
  innerProps,
  innerRef,
}: ComponentProps) => (
  <BodyOne
    className="Genesis-Combobox-Placeholder"
    color="onSurface.text.subdued"
    position="absolute"
    top="50%"
    left="half"
    transform="translateY(-50%)"
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </BodyOne>
);

export const SingleValue = ({
  children,
  isDisabled,
  innerProps,
  innerRef,
}: ComponentProps) => (
  <BodyOne
    className="Genesis-Combobox-SingleValue"
    color={
      isDisabled ? 'interactive.action.disabled' : 'onSurface.text.primary'
    }
    position="absolute"
    left="half"
    right="half"
    top="50%"
    transform="translateY(-50%)"
    css={{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </BodyOne>
);

interface InputProps {
  selectProps: {
    required: boolean;
    error: boolean;
  };
}
export const Input = ({ selectProps, ...props }: InputProps) => (
  <ReactSelect.Input
    {...pickGlobalHTMLProps(selectProps)}
    className="Genesis-Combobox-Input"
    // @ts-ignore
    required={trueOrUndefined(selectProps.required)}
    aria-invalid={trueOrUndefined(selectProps.error)}
    {...props}
  />
);
interface OptionProps extends ComponentProps {
  isSelected: boolean;
  data: {
    __isNew__: boolean;
    color: any;
    backgroundColor: any;
    label: string;
  };
}
export const Option = ({
  innerProps,
  innerRef,
  isDisabled,
  isFocused,
  isSelected,
  data,
}: OptionProps) => {
  // eslint-disable-next-line no-underscore-dangle
  const Label = isSelected || data.__isNew__ ? SubtitleOne : BodyOne;

  return (
    <Flex
      className="Genesis-Combobox-Option"
      alignItems="center"
      padding="threeQuarters"
      color={(() => {
        if (isDisabled) return 'interactive.action.disabled';
        return data.color || 'onSurface.text.primary';
      })()}
      backgroundColor={(() => {
        if (isFocused && !isDisabled) return 'highlight.background.subdued';
        if (isSelected) return 'interactive.background.primary';
        return data.backgroundColor || 'interactive.background.primary';
      })()}
      hoverStyle={{ cursor: 'pointer' }}
      focusStyle={{ outline: 'none' }}
      ref={innerRef}
      {...innerProps}
    >
      {/* eslint-disable-next-line no-underscore-dangle */}
      {data.__isNew__ && (
        <Image src={addIcon} alt={data.label} size="one" marginRight="half" />
      )}
      <Label as="div" color="inherit" display="inline">
        {data.label}
      </Label>
      {isSelected && (
        <Image
          src={checkmarkIcon}
          alt={data.label}
          size="one"
          marginLeft="threeQuarters"
        />
      )}
    </Flex>
  );
};

export const Menu = ({
  children,
  selectProps,
  innerRef,
  innerProps,
}: ComponentProps) => (
  <Box
    className="Genesis-Combobox-Menu"
    borderColor={
      borderColor({
        menuIsOpen: true,
        error: selectProps.error as boolean,
      }) as BorderColorPropsType
    }
    borderWidth="thin"
    borderRadius="small"
    backgroundColor="interactive.background.default"
    fontSize="body1"
    padding="none"
    boxShadow="card"
    marginY="half"
    ref={innerRef}
    position="absolute"
    top="100%"
    left="0"
    right="0"
    zIndex="dropdown"
    {...innerProps}
  >
    {children}
  </Box>
);
interface MenuListProps extends ComponentProps {
  maxHeight: string;
}
export const MenuList = ({
  children,
  innerRef,
  innerProps,
  maxHeight,
}: MenuListProps) => (
  <Box
    className="Genesis-Combobox-MenuList"
    borderWidth="thin"
    borderRadius="small"
    padding="none"
    maxHeight={maxHeight}
    overflowY="auto"
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </Box>
);

export const GroupHeading = () => null;

export const Group = ({
  children,
  data,
  innerRef,
  innerProps,
}: ComponentProps) => (
  <section
    {...innerProps}
    ref={innerRef as React.LegacyRef<HTMLElement> | undefined}
    className="Genesis-Combobox-Group"
  >
    {data?.label ? (
      <Flex
        padding="threeQuarters"
        backgroundColor="surface.background.secondary"
      >
        <Overline as="h1" color="onSurface.text.subdued">
          {data.label}
        </Overline>
      </Flex>
    ) : null}
    {children}
  </section>
);
interface DropdownIndicatorProps {
  innerProps?: Record<string, unknown>;
  css: Record<string, string>;
  transform: string;
  position: string;
  top: string;
  right: string;
}
export const DropdownIndicator = ({
  innerProps,
  ...props
}: DropdownIndicatorProps) => (
  <Flex
    className="Genesis-Combobox-DropdownIndicator"
    size="two"
    alignItems="center"
    justifyContent="center"
    {...innerProps}
    {...props}
  >
    <Image src={chevronDownIcon} alt="" size="one" />
  </Flex>
);

export const ClearIndicator = ({ innerProps }: ComponentProps) => (
  <Flex
    className="Genesis-Combobox-ClearIndicator"
    size="two"
    alignItems="center"
    justifyContent="center"
    css={{
      position: 'relative',
    }}
    hoverStyle={{
      backgroundColor: 'highlight.background.subdued',
      borderRadius: 'small',
    }}
    {...innerProps}
  >
    <Image src={clearIcon} alt="clear" size="one" />
  </Flex>
);

export const IndicatorSeparator = ({ innerProps, ...props }: ComponentProps) =>
  props.selectProps.isClearable && props.hasValue ? (
    <Flex
      className="Genesis-Combobox-IndicatorSeparator"
      display="inline"
      marginX="quarter"
      alignItems="center"
      aria-hidden
      {...innerProps}
    >
      <img src={dividerIcon} alt="divider" />
    </Flex>
  ) : null;
