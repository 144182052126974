import { createRequestTypes } from 'common/websocket-redux';

export const REQUEST_GET_PAYMENT_PAYOUT_DETAILS =
  'script-helpers/REQUEST_GET_PAYMENT_PAYOUT_DETAILS';

export const GET_PAYMENT_PAYOUT_DETAILS = createRequestTypes(
  'script-helpers/GET_PAYMENT_PAYOUT_DETAILS',
);

export const getPaymentPayoutDetails = payload => ({
  type: REQUEST_GET_PAYMENT_PAYOUT_DETAILS,
  payload,
});
