/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import {
  REQUEST_DEPENDENTS,
  REQUEST_USER_CONFIG,
  INVITE_DEPENDENT,
  TOGGLE_DEX_MODAL,
  SET_DEPENDENT,
  REQUEST_DEPENDENT_SCHEMA,
  REQUEST_DEPENDENT_ACTIONS,
} from './manage-dependents.action-types';
import { EMAIL_EXISTS_ERROR_CODE } from './utils';

export const initialState = {
  dependents: [],
  dependent_schema: {},
  actions: null,
  show_add_dependents_with_qle: false, // insured benefits
  show_add_dependents_without_qle: false, // has hsa
  // we have two separate loading flags so they do not interfere with each other
  loading_dependents: true, // Otherwise I'd have to have three states "not started", "false", "true",
  loading_user_config: true, // Otherwise I'd have to have three states "not started", "false", "true",
  dex_error: false,
  is_dex_modal_open: false,
  is_submitting: false,
  is_schema_loading: false,
  league_family_helpcentre_link: '',
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const manageDependentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DEPENDENTS.STARTED:
      return {
        ...state,
        loading_dependents: true,
      };
    case REQUEST_DEPENDENTS.SUCCEEDED:
      return {
        ...state,
        loading_dependents: false,
        banners: action.payload?.banners,
        dependents:
          action.payload?.dependents?.filter(({ suspended }) => !suspended) ||
          [], // Store raw schema. Normalizing happens in selector.
        league_family_helpcentre_link:
          action.payload?.league_family_helpcentre_link,
      };
    case REQUEST_DEPENDENTS.ERRORED:
      return {
        ...state,
        loading_dependents: false,
      };
    case REQUEST_DEPENDENT_ACTIONS.SUCCEEDED:
      return {
        ...state,
        actions: action.payload.actions,
      };
    case REQUEST_USER_CONFIG.STARTED:
      return {
        ...state,
        loading_user_config: true,
      };
    case REQUEST_USER_CONFIG.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue
      const { show_add_dependents_with_qle, show_add_dependents_without_qle } =
        action.payload.info;
      return {
        ...state,
        show_add_dependents_with_qle,
        show_add_dependents_without_qle,
        loading_user_config: false,
      };
    case REQUEST_USER_CONFIG.ERRORED:
      return {
        ...state,
        loading_user_config: false,
      };
    case INVITE_DEPENDENT.SUCCEEDED:
      return {
        ...state,
        is_dex_modal_open: false,
      };
    case INVITE_DEPENDENT.ERRORED:
      if (action.payload.info?.code === EMAIL_EXISTS_ERROR_CODE) {
        return {
          ...state,
          dex_error: true,
        };
      }
      return {
        ...state,
        dex_error: false,
        is_dex_modal_open: false,
      };
    case TOGGLE_DEX_MODAL:
      if (action.payload.isOpen) {
        return {
          ...state,
          is_dex_modal_open: true,
          dex_error: false,
        };
      }
      return {
        ...state,
        is_dex_modal_open: false,
      };
    case SET_DEPENDENT.STARTED:
      return {
        ...state,
        is_submitting: true,
      };

    case SET_DEPENDENT.SUCCEEDED:
    case SET_DEPENDENT.ERRORED:
      return {
        ...state,
        is_submitting: false,
      };
    case REQUEST_DEPENDENT_SCHEMA.STARTED:
      return {
        ...state,
        is_schema_loading: true,
      };
    case REQUEST_DEPENDENT_SCHEMA.SUCCEEDED:
      // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
      const { id, metadata, schema } = action.payload;
      return {
        ...state,
        is_schema_loading: false,
        dependent_schema: {
          ...state.dependent_schema,
          [id]: {
            metadata,
            schema,
          },
        },
      };
    default:
      return state;
  }
};
