import PropTypes from 'prop-types';

/**
 * In alphabetical order for their localized text, which is how we should display them.
 * The keys match what backend uses. The values are i18n keys prefixed with `QLE_` to hopefully
 * make the keys generable.
 */
export const QLE_MASTER_LIST = {
  'dependent-death': 'QLE_DEPENDENT_DEATH',
  adoption: 'QLE_ADOPTION',
  'civil-union': 'QLE_CIVIL_UNION',
  'became-common-law': 'QLE_BECAME_COMMON_LAW',
  'domestic-partnership': 'QLE_DOMESTIC_PARTNERSHIP',
  divorce: 'QLE_DIVORCE',
  marriage: 'QLE_MARRIAGE',
  separation: 'QLE_SEPARATION',
  birth: 'QLE_BIRTH',
  'move-to-different-zip-country': 'QLE_MOVE_TO_DIFFERENT_ZIP_COUNTRY',
  'move-to-or-from-bc': 'QLE_MOVE_TO_OR_FROM_BC',
  'move-to-or-from-quebec': 'QLE_MOVE_TO_OR_FROM_QUEBEC',
  'foster-care-for-child': 'QLE_FOSTER_CARE_FOR_CHILD',
  'lost-coverage-as-dependent': 'QLE_LOST_COVERAGE_AS_DEPENDENT', // I as a dependent submit QLE because I lost the coverage,
  'student-move-to-or-from-school': 'QLE_STUDENT_MOVE_TO_OR_FROM_SCHOOL',
  'ended-common-law-relationship': 'QLE_ENDED_COMMON_LAW_RELATIONSHIP',
  'date-of-gain-of-coverage': 'QLE_DATE_OF_GAIN_OF_COVERAGE',
  dependent_lost_coverage: 'QLE_DEPENDENT_LOST_COVERAGE', // I submit QLE for my dependent for losing coverage,
  'losing-coverage-on-my-own-plan': 'QLE_LOST_MY_COVERAGE',
};

// Any new additions to the master list should automatically update these prop types!
export const QLE_MASTER_LIST_PROP_TYPES = PropTypes.oneOf(
  Object.keys(QLE_MASTER_LIST),
);
