import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Header, Grid, Form } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';
import ErrorFormField from 'common/adaptive-forms/forms/components/ErrorFormField';

class PrePayments extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.resetUpdateStatus('prepayment');
  }

  handleChange(name, value) {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('changed', true);
    if (value !== '') {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.setFieldValue(name, Number(value));
    } else {
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.setFieldValue(name, value);
    }
  }

  render() {
    const {
      benefitTypes,
      values,
      handleBlur,
      handleSubmit,
      updateStatus,
      errors,
      touched,
    } = this.props;
    const buttonMessage = () => {
      switch (updateStatus.prepayment) {
        case 'start':
          return 'SAVING';
        case 'success':
          return 'PRE_PAYMENTS_SAVED';
        case 'error':
          return 'PRE_PAYMENTS_ERRORED';
        default:
          return 'SAVE_PRE_PAYMENTS';
      }
    };
    const buffer = benefitTypes.map(benefit => {
      return (
        <Grid.Row key={benefit.id} className="billing-gridRow">
          <Grid.Column className="billing-gridColumn">
            <FormattedMessage id={benefit.type.toUpperCase()} />
          </Grid.Column>
          <Grid.Column>
            <ErrorFormField
              fieldName={`billing_${benefit.type}_buffer`}
              errors={errors}
              touched={touched}
            >
              <Form.Input
                icon="percent"
                type="number"
                step="any"
                name={`billing_${benefit.type}_buffer`}
                value={values[`billing_${benefit.type}_buffer`]}
                onChange={(e, { name, value }) =>
                  this.handleChange(name, value)
                }
                onBlur={handleBlur}
              />
            </ErrorFormField>
          </Grid.Column>
        </Grid.Row>
      );
    });
    return (
      <Box>
        <Header as="h4">
          <FormattedMessage id="PRE_PAYMENTS" />
        </Header>
        <Form onSubmit={handleSubmit}>
          <Grid columns={2} className="billing-buffer-grid">
            {benefitTypes.length > 0 ? buffer : null}
          </Grid>
          {benefitTypes.length ? (
            <Form.Button
              type="submit"
              value="Submit"
              color={updateStatus.prepayment === 'error' ? 'red' : 'violet'}
              disabled={!values.changed}
              loading={updateStatus.prepayment === 'start'}
            >
              <FormattedMessage id={buttonMessage()} />
            </Form.Button>
          ) : null}
        </Form>
      </Box>
    );
  }
}

PrePayments.propTypes = {
  benefitTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      benefitType: PropTypes.string,
    }),
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  resetUpdateStatus: PropTypes.func.isRequired,
  updateStatus: PropTypes.shape({
    payment: PropTypes.string,
    fees: PropTypes.string,
    prepayment: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
};

PrePayments.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  values: {},
};

export default PrePayments;
