import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Box, Flex, Overline } from '@leagueplatform/genesis-commons';
import { getColors } from '../wallet-template-utils';

export const TemplateStatusPill = ({ status }) => {
  const { formatMessage } = useIntl();
  const { circle, background } = getColors(status);
  return (
    <Flex
      alignItems="center"
      borderRadius="pill"
      backgroundColor={background}
      paddingX="half"
      paddingY="quarter"
    >
      <Box
        marginRight="quarter"
        borderRadius="circle"
        size={12}
        backgroundColor={circle}
      />
      <Overline as="span" color="onSurface.text.subdued">
        {formatMessage({ id: status })}
      </Overline>
    </Flex>
  );
};

TemplateStatusPill.propTypes = {
  status: PropTypes.string.isRequired,
};
