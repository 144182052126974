import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Heading,
  Text,
  spaceInPx,
  fadeIn,
  slideUp,
  slideDown,
  fadeOut,
} from '@leagueplatform/ui-kit';

const StyledCard = styled(Box)`
  animation: ${({ state }) =>
      (state === 'entering' && fadeIn) || (state === 'exiting' && fadeOut)}
    1.5s ease;
`;

const ImageBox = styled(Box)`
  height: 405px;
`;

const StyledImage = styled.img`
  width: 200px;
  max-width: 100%;
`;

const StyledHeading = styled(Heading)`
  font-weight: 700;
  margin: ${spaceInPx(3)} 0 ${spaceInPx(1)} 0;
  animation: ${({ state }) =>
      (state === 'entering' && slideUp) || (state === 'exiting' && slideDown)}
    0.75s ease-in-out;
  animation-fill-mode: forwards;
`;

const StyledText = styled(Text)`
  margin: 0;
  animation: ${({ state }) =>
      (state === 'entering' && slideUp) || (state === 'exiting' && slideDown)}
    1s ease-in-out;
  animation-fill-mode: forwards;
`;

export const CarouselCard = ({ imageUrl, heading, text, state }) => {
  return (
    <StyledCard textAlign="center" width={293} state={state}>
      <ImageBox>
        <StyledImage src={imageUrl} alt="" />
      </ImageBox>
      <StyledHeading state={state} level={2}>
        {heading}
      </StyledHeading>
      <StyledText state={state}>{text}</StyledText>
    </StyledCard>
  );
};

CarouselCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  state: PropTypes.string,
};

CarouselCard.defaultProps = {
  state: null,
};
