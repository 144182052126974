import React from 'react';
import { fieldProps } from './field-shape';
import { MultiSelect } from '@leagueplatform/ui-kit';

export const MultiSelectField = ({
  field: { name, value, ...field },
  form: { setFieldValue },
  disabled,
  readOnly,
  options,
  ...props
}) => {
  const optionValues = value?.map(option => option?.value ?? option) ?? [];
  const selectedValues = optionValues.map(
    optionValue =>
      options.find(option => option.value === optionValue) ?? {
        label: optionValue,
        value: optionValue,
      },
  );

  return (
    <MultiSelect
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...field}
      name={name}
      options={options}
      disabled={Boolean(disabled ?? readOnly)}
      onChange={(selectedOptions, action) => {
        if (!action) return; // user is typing
        const selectedOptionsArray = selectedOptions || [];
        setFieldValue(
          name,
          selectedOptionsArray.map(opt => opt.value),
        );
      }}
      value={selectedValues}
      data-testid={`multi_select_field_${name}`}
      canCreate
    />
  );
};

MultiSelectField.propTypes = fieldProps;
