import React, { useState, useEffect } from 'react';
import { DocumentsUploaderPresenter } from './documents-uploader.view';

export const useDocumentsUploaderHooks = ({
  uploadDocument,
  documents: documentsList,
  onChange,
}) => {
  const [documents, setDocumentsList] = useState(documentsList);

  useEffect(() => {
    setDocumentsList(documentsList);
  }, [documentsList]);

  const onAddNewDocument = newDoc => {
    const updatedList = documents ? [...documents, newDoc] : [newDoc];
    setDocumentsList(updatedList); // update form values in parent
    if (onChange) onChange(updatedList); // update form values in parent
  };

  const onRemoveDocument = documentToRemove => {
    const updatedList = documents.filter(
      doc => doc.content_id !== documentToRemove.content_id,
    );

    setDocumentsList(updatedList);
    if (onChange) onChange(updatedList); // update form values in parent
  };

  const onFileUpload = file => {
    // upload to content server
    uploadDocument({
      file,
      onUploadSuccess: newDoc => onAddNewDocument(newDoc),
    });
  };

  const onDropFile = e => {
    e.persist();
    e.preventDefault();
    onFileUpload(e.dataTransfer.files[0]);
  };

  const onDragOverFile = e => {
    e.persist();
    e.preventDefault();
  };

  return {
    documents,
    onAddNewDocument,
    onRemoveDocument,
    onDropFile,
    onDragOverFile,
    onFileUpload,
  };
};

export const DocumentsUploader = props => {
  const {
    onAddNewDocument,
    onRemoveDocument,
    onDropFile,
    onDragOverFile,
    onFileUpload,
    documents,
  } = useDocumentsUploaderHooks(props);

  return (
    <DocumentsUploaderPresenter
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...props}
      onAddNewDocument={onAddNewDocument}
      onRemoveDocument={onRemoveDocument}
      onDropFile={onDropFile}
      onDragOverFile={onDragOverFile}
      onFileUpload={onFileUpload}
      documents={documents}
    />
  );
};
