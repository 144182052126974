import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Formik } from 'formik-legacy';
import { injectIntl } from 'react-intl';
import {
  selectGroupId,
  selectSubscriptionRates,
  selectBillingUpdateStatus,
  selectPlatformFees,
} from '../../selectors';
import {
  groupSubscriptionRatesSubmitted,
  groupCreditContractFormSubmitted,
  resetUpdateStatus,
} from '../../actions';
import { mapSubscriptionRates } from '../../operations';
import { merge } from 'lodash';
import Fees from './presenter';
import { createValidator, combineValidators } from 'common/adaptive-forms';
import { adminFeeSchema } from '../../validation-schema';
import { subRatesValidator } from './validators';

const withFormikEnhancer = Formik({
  handleSubmit: (values, { props }) => {
    // set subscription rates
    props.groupSubscriptionRatesSubmitted({
      groupId: props.groupId,
      subscriptionRates: values.subRatesChecked
        ? mapSubscriptionRates(values.subscriptionRates)
        : [],
      platformFees: values.platformFees,
    });
  },
  mapPropsToValues: props => {
    const subRates = { subscriptionRates: props.subscriptionRates };
    const platformFees = { platformFees: props.platformFees };
    return merge(subRates, platformFees, { changed: false });
  },
  validateOnChange: true,
  validate: values =>
    combineValidators(
      subRatesValidator,
      createValidator(adminFeeSchema),
    )(values),
});

const mapStateToProps = state => ({
  subscriptionRates: selectSubscriptionRates(state),
  groupId: selectGroupId(state),
  updateStatus: selectBillingUpdateStatus(state),
  platformFees: selectPlatformFees(state),
  resetUpdateStatus,
});

export default compose(
  connect(mapStateToProps, {
    groupSubscriptionRatesSubmitted,
    groupCreditContractFormSubmitted,
    resetUpdateStatus,
  }),
  injectIntl,
  withFormikEnhancer,
)(Fees);
