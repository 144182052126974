import React from 'react';
import { connect } from 'react-redux';
import { branch, renderComponent, compose } from 'recompose';
import renderNothing from 'recompose/renderNothing';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import {
  selectEmployeeBenefitsError,
  selectAddingEmployeeBenefit,
  selectUpdatingEmployeeBenefit,
  selectRemovingEmployeeBenefit,
  selectRemovedEmployeeBenefit,
  selectUpdatedEmployeeBenefit,
  selectAddedEmployeeBenefit,
  selectChangedEmployeeBenefitClassMessage,
} from './benefits.selectors';
import { Text } from '@leagueplatform/ui-kit';

const Nothing = renderNothing();

const withSuccessAddingBenefit = branch(
  ({ added }) => added,
  renderComponent(() => (
    <Message positive>
      <Text>
        <FormattedMessage id="SUCCESS_ADDING_USER_BENEFIT" />
      </Text>
    </Message>
  )),
);

const withSuccessUpdatingBenefit = branch(
  ({ updated }) => updated,
  renderComponent(() => (
    <Message positive>
      <Text>
        <FormattedMessage id="SUCCESS_UPDATING_USER_BENEFIT" />
      </Text>
    </Message>
  )),
);

const withSuccessRemovingBenefit = branch(
  ({ removed }) => removed,
  renderComponent(() => (
    <Message positive>
      <Text>
        <FormattedMessage id="SUCCESS_REMOVING_USER_BENEFIT" />
      </Text>
    </Message>
  )),
);

const withSuccessUpdatingClass = branch(
  ({ message }) => message,
  renderComponent(({ message }) => (
    <Message positive>
      <Text>{message}</Text>
    </Message>
  )),
);

const withBenefitError = branch(
  ({ error }) => error,
  renderComponent(({ error }) => (
    <Message negative>
      <Message.Header>
        <FormattedMessage id="ERROR_STATE_TITLE" />
      </Message.Header>
      <Text>{error}</Text>
    </Message>
  )),
);

export const ErrorMessage = compose(
  connect(state => ({
    error: selectEmployeeBenefitsError(state),
  })),
  injectIntl,
  withBenefitError,
)(Nothing);

export const RemoveSuccessMessage = compose(
  connect(state => ({
    removing: selectRemovingEmployeeBenefit(state),
    removed: selectRemovedEmployeeBenefit(state),
    error: selectEmployeeBenefitsError(state),
  })),
  injectIntl,
  withSuccessRemovingBenefit,
)(Nothing);

export const UpdateSuccessMessage = compose(
  connect(state => ({
    updating: selectUpdatingEmployeeBenefit(state),
    updated: selectUpdatedEmployeeBenefit(state),
    error: selectEmployeeBenefitsError(state),
  })),
  injectIntl,
  withSuccessUpdatingBenefit,
)(Nothing);

export const AddSuccessMessage = compose(
  connect(state => ({
    adding: selectAddingEmployeeBenefit(state),
    added: selectAddedEmployeeBenefit(state),
    error: selectEmployeeBenefitsError(state),
  })),
  injectIntl,
  withSuccessAddingBenefit,
)(Nothing);

export const UpdateEmployeeClassSuccessMessage = compose(
  connect(state => ({
    message: selectChangedEmployeeBenefitClassMessage(state),
  })),
  withSuccessUpdatingClass,
)(Nothing);
