import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getChatAvailability - fetches a list of chat availabilities so that
 * to determine if a chat is offline and its online hours
 *
 * @return {Object} - A redux-saga call effect
 */
export const getChatAvailability = () =>
  call(websocketFetch, 'get_chat_availability', {
    version: 2,
  });
