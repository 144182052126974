import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TreeNodeContainerPresenter } from '../../enrollment-config/tree-navigation/tree-node-container.view';
import { treeStyle } from '../../enrollment-config/tree-navigation/tree-navigation-style';
import { Treebeard, decorators } from 'react-treebeard';
import {
  Box,
  Flex,
  Checkbox,
  TextInput,
  HeadingThree,
  BodyOne,
  Label,
  LabelText,
  PrimaryButton,
  BodyTwo,
  Link,
  Image,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { useRateSheetExtractHandlers } from './rate-sheet-extract.hook';
import { STATUS } from '../employer-payroll.constants';
import Loader from 'common/ui-kit/assets/loading.svg';
import { getContentUrl } from '@leagueplatform/league-content-api';

export const TreeNodeHeader = ({ terminal, node }) => {
  const onNodeClick = currentNode => {
    const setNodeChildrenChecked = childNode =>
      childNode?.children?.forEach(child => {
        const childRef = child;
        childRef.checked = childNode.checked;
        setNodeChildrenChecked(childRef);
      });
    const currentNodeRef = currentNode;
    currentNodeRef.checked = !currentNodeRef.checked;
    currentNodeRef.toggled = !currentNodeRef.toggled;
    setNodeChildrenChecked(currentNodeRef);
  };
  return (
    <Flex
      flexGrow={1}
      display="inline-flex"
      justifyContent="center"
      paddingLeft={terminal ? 'one' : 0}
    >
      {node.hasCheckBox && (
        <Checkbox
          defaultChecked={node.checked}
          marginRight="half"
          onContainerClick={() => onNodeClick(node)}
        />
      )}
      <BodyTwo flexGrow={1}>{node.name}</BodyTwo>
    </Flex>
  );
};
TreeNodeHeader.propTypes = {
  terminal: PropTypes.bool.isRequired,
  node: PropTypes.shape({
    name: PropTypes.string,
    hasCheckBox: PropTypes.string,
    checked: PropTypes.string,
  }).isRequired,
};

export const RateSheetExtractPresenter = ({
  structuredEmployeeBenefits,
  getRateSheetExtract,
  status,
  contentId,
  fileName,
  getEmployerBenefitPlans,
  groupId,
}) => {
  const { formatMessage } = useIntl();
  const { onChange, onToggle, generatePayload, data, treeData } =
    useRateSheetExtractHandlers({ structuredEmployeeBenefits });

  useEffect(() => {
    if (groupId) {
      getEmployerBenefitPlans({ groupId });
    }
  }, [getEmployerBenefitPlans, groupId]);

  return (
    <Box>
      <Box marginBottom="one">
        <HeadingThree>
          {formatMessage({ id: 'RATE_SHEET_EXTRACT' })}
        </HeadingThree>
        <BodyOne color="onSurface.text.subdued">
          {formatMessage({ id: 'RATE_SHEET_EXTRACT_COPY' })}
        </BodyOne>
      </Box>
      <Label marginTop="one">
        <LabelText>{formatMessage({ id: 'SEARCH' })}</LabelText>
        <TextInput
          placeholder={formatMessage({
            id: 'RATE_SHEET_EXTRACT_SEARCH_PLACEHOLDER',
          })}
          marginBottom="one"
          onChange={onChange}
        />
      </Label>
      <Box
        maxHeight={500}
        overflowY="auto"
        borderStyle="solid"
        borderColor="onSurface.border.default"
        borderRadius="small"
        borderWidth="thin"
      >
        <Treebeard
          style={treeStyle}
          onToggle={onToggle}
          data={treeData}
          decorators={{
            ...decorators,
            Header: TreeNodeHeader,
            Container: TreeNodeContainerPresenter,
          }}
        />
      </Box>
      <PrimaryButton
        marginTop="one"
        onClick={() => getRateSheetExtract(generatePayload({ node: data }))}
        disabled={status === STATUS.LOADING}
      >
        {status === STATUS.LOADING && (
          <Image marginRight="half" src={Loader} size="one" />
        )}
        {formatMessage({ id: 'REQUEST_REPORT' })}
      </PrimaryButton>
      {contentId && (
        <Link marginLeft="one" href={getContentUrl(contentId)}>
          {fileName || formatMessage({ id: 'DOWNLOAD_REPORT' })}
        </Link>
      )}
    </Box>
  );
};

RateSheetExtractPresenter.propTypes = {
  structuredEmployeeBenefits: PropTypes.shape({
    name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
    children: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  getRateSheetExtract: PropTypes.func.isRequired,
  status: PropTypes.string,
  fileName: PropTypes.string,
  contentId: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  getEmployerBenefitPlans: PropTypes.func.isRequired,
};

RateSheetExtractPresenter.defaultProps = {
  status: '',
  fileName: '',
  contentId: '',
};
