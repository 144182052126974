import {
  GET_BENEFIT_FORM_LIST,
  GET_DIGITAL_BENEFICIARY_FORM,
  GET_USER_BENEFIT_FORM,
} from './insurance-forms.actions';

const initialState = {
  benefit_form_list: null,
  isReady: false,
  loadingByFormId: {},
  contentByFormId: {},
  digitalBeneficiaryForm: {
    isReady: true,
    contentId: null,
    error: false,
  },
};

const updateLoadingByFormId = ({ loadingByFormId }, action, isLoading) => ({
  ...loadingByFormId,
  [action?.meta?.formId]: isLoading,
});

const updateContentByFormId = ({ contentByFormId }, action) => ({
  ...contentByFormId,
  [action?.meta?.formId]: action?.payload?.info?.content_id,
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const insuranceFormsReducer = (state = initialState, action) => {
  // console.warn(state)
  switch (action?.type) {
    case GET_BENEFIT_FORM_LIST.SUCCEEDED:
      return {
        ...state,
        benefit_form_list: action?.payload?.info?.benefit_form_list ?? [],
        isReady: true,
      };
    case GET_BENEFIT_FORM_LIST.ERRORED:
    case GET_BENEFIT_FORM_LIST.CANCELLED:
      return {
        ...state,
        isReady: true,
      };
    case GET_USER_BENEFIT_FORM.STARTED:
      return {
        ...state,
        loadingByFormId: updateLoadingByFormId(state, action, true),
      };
    case GET_USER_BENEFIT_FORM.ERRORED:
    case GET_USER_BENEFIT_FORM.SUCCEEDED:
      return {
        ...state,
        loadingByFormId: updateLoadingByFormId(state, action, false),
        contentByFormId: updateContentByFormId(state, action),
      };
    case GET_USER_BENEFIT_FORM.CANCELLED:
      return {
        ...state,
        loadingByFormId: updateLoadingByFormId(state, action, false),
      };
    case GET_DIGITAL_BENEFICIARY_FORM.STARTED:
      return {
        ...state,
        digitalBeneficiaryForm: {
          ...state.digitalBeneficiaryForm,
          isReady: false,
        },
      };
    case GET_DIGITAL_BENEFICIARY_FORM.SUCCEEDED:
      return {
        ...state,
        digitalBeneficiaryForm: {
          isReady: true,
          contentId: action?.payload?.info?.content_id,
          error: false,
        },
      };
    case GET_DIGITAL_BENEFICIARY_FORM.ERRORED:
      return {
        ...state,
        digitalBeneficiaryForm: {
          isReady: true,
          contentId: null,
          error: true,
        },
      };
    case GET_DIGITAL_BENEFICIARY_FORM.CANCELLED:
      return {
        ...state,
        digitalBeneficiaryForm: {
          ...state.digitalBeneficiaryForm,
          isReady: true,
          error: false,
        },
      };
    default:
      return state;
  }
};
