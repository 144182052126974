import { request } from 'common/websocket-redux';
import { call, takeLatest } from 'redux-saga/effects';
import {
  getUserBenefitFlexDollars,
  setUserBenefitFlexDollars,
} from 'common/services';
import {
  GET_USER_BENEFIT_FLEX_DOLLARS,
  SET_USER_BENEFIT_FLEX_DOLLARS,
} from './update-flex-dollars.types';
import { ANALYTICS_EVENT_NAME_ADMIN } from 'apps/employer-experience-v2/employer-reports/reports.constants';
import {
  ANALYTICS_EVENT_CATEGORY_EMPLOYEE_BENEFITS,
  ANALYTICS_EVENT_FLEX_DOLLARS_SUCCESS,
  ANALYTICS_EVENT_LABEL_UPDATE_FLEX_DOLLARS,
} from '../constants';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* fetchUserBenefitFlexDollars({ payload }) {
  yield request(
    GET_USER_BENEFIT_FLEX_DOLLARS,
    getUserBenefitFlexDollars(payload),
  );
}

export function* updateUserBenefitFlexDollars({ payload }) {
  yield request(
    SET_USER_BENEFIT_FLEX_DOLLARS,
    setUserBenefitFlexDollars(payload),
    payload,
  );
}

export function* updateFlexDollarsSuccess({ meta }) {
  yield call(
    sendAnalyticsEvent,
    {
      category: ANALYTICS_EVENT_CATEGORY_EMPLOYEE_BENEFITS,
      action: ANALYTICS_EVENT_FLEX_DOLLARS_SUCCESS,
      label: ANALYTICS_EVENT_LABEL_UPDATE_FLEX_DOLLARS,
      group_id: meta.groupId,
      plan_id: meta.planId,
      user_id: meta.userId,
    },
    ANALYTICS_EVENT_NAME_ADMIN,
  );
  yield call(toastActions.add, {
    type: TOAST_STATUS.SUCCESS,
    textId: 'UPDATE_FLEX_DOLLARS_SUCCESS_MESSAGE',
  });
}
export function* updateFlexDollarsError({ payload }) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: payload?.info?.reason,
  });
}

export function* updateFlexDollarsSagas() {
  yield takeLatest(
    GET_USER_BENEFIT_FLEX_DOLLARS.BASE,
    fetchUserBenefitFlexDollars,
  );

  yield takeLatest(
    SET_USER_BENEFIT_FLEX_DOLLARS.BASE,
    updateUserBenefitFlexDollars,
  );

  yield takeLatest(
    SET_USER_BENEFIT_FLEX_DOLLARS.SUCCEEDED,
    updateFlexDollarsSuccess,
  );

  yield takeLatest(
    SET_USER_BENEFIT_FLEX_DOLLARS.ERRORED,
    updateFlexDollarsError,
  );
}
