import Employers from './presenter';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  searchTextChanged,
  searchPageRequested,
  employerSelected,
} from './actions';

export * from './saga';
export * from './reducer';

const withDispatchers = connect(null, {
  onSearchTextChanged: searchTextChanged,
  onSearchPageRequested: searchPageRequested,
  onEmployerSelected: employerSelected,
});

const enhance = compose(withRouter, withDispatchers);

export default enhance(Employers);
