import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';

export const SET_GROUP_SUBSCRIPTION_RATES_MESSAGE_TYPE =
  'set_group_subscription_rates';
interface SetGroupSubscriptionTypes {
  group_id: string;
  platform_fees_for_invited_users: boolean;
  subscription_rate_pepm?: number;
  platform_fees_per_benefit_class?: Record<string, number>;
}

export const setGroupSubscriptionRate = async (
  info: SetGroupSubscriptionTypes,
) =>
  SocketAsFetch.fetch({
    message_type: SET_GROUP_SUBSCRIPTION_RATES_MESSAGE_TYPE,
    info,
  });
