import { createSelector } from 'reselect';
import {
  getActiveGroup,
  getActiveBusinessProfileGroup,
} from '@leagueplatform/web-common';
import { selectUser } from './user.selectors';

export const selectUserGroups = createSelector(
  selectUser,
  user => user?.groups,
);

export const selectUserGroup = createSelector(selectUserGroups, groups =>
  getActiveGroup(groups),
);

export const selectUserGroupId = createSelector(
  selectUserGroup,
  group => group?.groupId,
);

export const selectActiveBusinessProfileGroup = createSelector(
  selectUserGroups,
  groups => getActiveBusinessProfileGroup(groups),
);
