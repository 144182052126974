import camelize from 'camelize';
import { GET_CHAT_AVAILABILITY } from '../chat-page.action-types';

export const chatAvailabilityInitialState = {
  isLoading: false,
  hasErrored: false,
  availabilities: [],
};

export const chatAvailabilityReducer = (
  state = chatAvailabilityInitialState,
  action = {},
) => {
  switch (action.type) {
    case GET_CHAT_AVAILABILITY.STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CHAT_AVAILABILITY.SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        availabilities: camelize(action.payload.info.availabilities),
      };
    case GET_CHAT_AVAILABILITY.ERRORED:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
      };
    default:
      return state;
  }
};
