/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { combineReducers } from 'redux';
import { LOAD_COVERAGE_SUMMARY } from './coverage-summary.actions';

export const setCoverageSummary = (
  {
    payload: {
      bundles = [],
      employerPaysAllCosts,
      showCostsPerPayPeriod,
      showEmployerCosts,
      employer_contribution: employerContribution,
      total_cost: {
        flex_dollar: totalFlex,
        payroll_deduction: totalPay,
        total_cost: totalCost,
        approved_payroll_deduction: approvedTotalPay,
      },
      pay_group,
      pay_periods_per_year,
      pay_period_total_cost: payPeriodTotalCost,
      allocated_flex_dollars: allocatedFlex,
      last_submitted_time: lastSubmittedTime,
      enrollment_end_date: enrollmentEndDate,
      steps = [],
    },
    meta,
  },
  state,
) => {
  const benefitSets =
    bundles.find(bundle => bundle.selected)?.benefit_sets ?? [];
  const planId = meta?.planId;

  return {
    ...state,
    [planId]: {
      benefitSets,
      cost: {
        totalCost,
        totalFlex,
        totalPay,
        approvedTotalPay,
      },
      employerContribution,
      payPeriodTotalCost: {
        ...payPeriodTotalCost,
        pay_group,
        pay_periods_per_year,
      },
      allocatedFlex,
      isLoaded: true,
      employerPaysAllCosts,
      showCostsPerPayPeriod,
      showEmployerCosts,
      lastSubmittedTime,
      enrollmentEndDate,
      steps,
    },
  };
};

export const handleCoverageSummaryError = ({ payload, meta }, state) => {
  const planId = meta?.planId;

  return {
    ...state,
    [planId]: {
      ...payload.info,
      error: true,
    },
  };
};

export const setCoverageSummaryLoading = ({ meta }, state) => ({
  ...state,
  [meta?.planId]: {
    isLoaded: false,
  },
});

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const byId = (state = {}, action) => {
  if (!action) return state;

  switch (action.type) {
    case LOAD_COVERAGE_SUMMARY.STARTED:
      return setCoverageSummaryLoading(action, state);
    case LOAD_COVERAGE_SUMMARY.SUCCEEDED:
      return setCoverageSummary(action, state);
    case LOAD_COVERAGE_SUMMARY.ERRORED:
      return handleCoverageSummaryError(action, state);
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const currentPlanId = (state = null, action) => {
  switch (action?.type) {
    case LOAD_COVERAGE_SUMMARY.STARTED:
      return action.meta.planId ?? state;
    default:
      return state;
  }
};

export const coverageSummaryReducer = combineReducers({
  byId,
  currentPlanId,
});
