import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getQLEConfigurations - calls `get_qle_configurations` to fetch QLE configurations for a group's benefit class.
 * If a QLE hasn't been configured, it will not be included in response.
 *
 * @arg {string} groupId  - group that target benefit class id comes from
 * @arg {string} benefitClassId  - target benefit class id to get QLE configurations for
 */

export const getQLEConfigurations = ({ groupId, benefitClassId }) =>
  call(websocketFetch, 'get_qle_configurations', {
    benefit_class_id: benefitClassId,
    group_id: groupId,
  });
