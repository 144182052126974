import { takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getBenefitCardPreview } from 'common/services';
import {
  BENEFIT_PREVIEW_REQUESTED,
  GET_BENEFIT_CARD_PREVIEW,
} from './benefit-preview.actions';

export function* benefitPreviewRequest(action) {
  const {
    payload: { benefitId, groupId },
  } = action;
  yield request(
    GET_BENEFIT_CARD_PREVIEW,
    getBenefitCardPreview({ benefitId, groupId }),
  );
}

export function* benefitPreviewSaga() {
  yield takeLatest(BENEFIT_PREVIEW_REQUESTED, benefitPreviewRequest);
}
