import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Header, Message } from 'semantic-ui-react';
import { Box, Text } from '@leagueplatform/ui-kit';
import { selectEmployeeSaveError } from './selectors';
import { Link } from 'react-router-dom';

const NewEmployeePage = ({ children, errorMessage, groupId }) => (
  <Box>
    <Button
      className="text-button"
      as={Link}
      to={`/admin/employers/${groupId}/employees`}
    >
      &larr; <FormattedMessage id="BACK_TO_EMPLOYEES" />
    </Button>
    <Header as="h2">
      <FormattedMessage id="INVITE_AN_EMPLOYEE" />
    </Header>
    <FormattedMessage id="ADD_EMPLOYEE_INTRO" />
    {errorMessage && (
      <Message negative>
        <FormattedMessage id="ERROR_CREATING_EMPLOYEE" />
        <Text>{errorMessage}</Text>
      </Message>
    )}
    {children}
  </Box>
);

NewEmployeePage.propTypes = {
  errorMessage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  groupId: PropTypes.string.isRequired,
};

NewEmployeePage.defaultProps = {
  errorMessage: null,
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  children: null,
};

export default connect(state => ({
  errorMessage: selectEmployeeSaveError(state),
}))(NewEmployeePage);
