import {
  Select,
  Fieldset,
  Button,
  HeadingText,
  ParagraphText,
  Box,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import React, { useState } from 'react';
import { useWalletLandingTemplateForm } from './hooks/use-wallet-landing-template-form.hook';

interface WalletLandingTemplateFormProps {
  groupId: string;
}

export const WalletLandingTemplateForm = ({
  groupId,
}: WalletLandingTemplateFormProps) => {
  const { formatMessage, formatHTMLMessage } = useIntl();
  const { configOptions, onConfigSubmit, defaultConfig, isLoading } =
    useWalletLandingTemplateForm({
      groupId,
    });
  const [hasError, setHasError] = useState(false);
  const [templateId, setTemplateId] = useState(defaultConfig);

  return (
    <Box>
      <HeadingText level="2" size="xl" css={{ marginBottom: '$half' }}>
        {formatMessage({ id: 'BENEFITS_WALLET_LANDING_TEMPLATE_HEADER' })}
      </HeadingText>
      <ParagraphText css={{ marginBottom: '$one' }}>
        {formatHTMLMessage({
          id: 'BENEFITS_WALLET_LANDING_TEMPLATE_DESCRIPTION',
        })}
      </ParagraphText>
      <Fieldset
        id="landing-screen-template-select"
        legend={formatMessage({ id: 'BENEFITS_WALLET_LANDING_TEMPLATE_LABEL' })}
        hideLegend
        inputStatus={hasError ? 'error' : undefined}
        statusMessage={formatMessage({
          id: 'BENEFITS_WALLET_LANDING_TEMPLATE_SELECT_ERROR',
        })}
      >
        {!isLoading && configOptions.length > 0 && (
          <Box css={{ marginBottom: '$twoAndHalf', maxWidth: '330px' }}>
            <label htmlFor="landing-screen-template-select">
              {formatMessage({ id: 'BENEFITS_WALLET_LANDING_TEMPLATE_LABEL' })}
              <Select
                aria-describedby={
                  hasError ? 'landing-screen-template-select-error' : ''
                }
                onChange={event => {
                  const { value } = event.target as HTMLSelectElement;
                  setTemplateId(value);
                }}
                value={templateId ?? defaultConfig}
                id="landing-screen-template-select"
                options={configOptions}
                inputStatus={hasError ? 'error' : undefined}
              />
            </label>
          </Box>
        )}
        <Button
          loading={isLoading}
          priority="primary"
          type="submit"
          onClick={(event: React.FormEvent) => {
            event.preventDefault();
            setHasError(false);
            if (!templateId) {
              setHasError(true);
              return;
            }
            onConfigSubmit(templateId);
          }}
        >
          {formatMessage({ id: 'PUBLISH' })}
        </Button>
      </Fieldset>
    </Box>
  );
};
