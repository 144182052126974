import { createSelector } from 'reselect';
import { selectEmployerPayroll } from '../employer-payroll.selectors';

const selectYearToDateReport = createSelector(
  selectEmployerPayroll,
  employerPayroll => employerPayroll?.yearToDateReport,
);

const selectReports = createSelector(
  selectYearToDateReport,
  yearToDateReport => yearToDateReport?.report,
);

export const selectRequestStatus = createSelector(
  selectYearToDateReport,
  yearToDateReport => yearToDateReport?.requestStatus,
);

export const selectContentId = createSelector(
  selectReports,
  reports => reports?.contentId,
);

export const selectFileName = createSelector(
  selectReports,
  reports => reports?.fileName,
);
