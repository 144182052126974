/* This is old home page code! please improve to best practices as you make changes */
import {
  FETCH_CONFIG,
  FETCH_USER_MERCH_LIST,
  FETCH_USER_LIFEMARKET_MERCHLIST,
} from './home-feed.types';
import { get, filter, pipe } from 'lodash/fp';
import { findIndex } from 'lodash';
import camelize from 'camelize';

const initialState = {
  config: [],
  data: {},
};

const applyConfigData = (state, action) => {
  const { config } = camelize(action.payload.info);
  return {
    ...state,
    config,
  };
};

/* service data might be different across get_merch_list_for_location, get_marketplace_service and get_library service */
export const normalizeLifemarketService = data => {
  /* Get marketplace coverage. data.benefitCoverages === null means feature flag off so we need to preserve that */
  if (data) {
    let { benefitCoverages } = data;
    if (benefitCoverages) {
      benefitCoverages = pipe(
        get('benefitCoverages'),
        filter(benefit => benefit.coverageType !== 'not_covered'),
      )(data);
    }

    return {
      ...data,
      benefitCoverages,
    };
  }

  return data;
};

const applyMerchListData = (state, action) => {
  const listData = camelize(action.payload.info);
  // health_at_work returns null if the group doesn't have health_at_work, but
  // we need to differentiate it between other merch_lists (like banner)
  if (!listData) return state;
  // normalize data
  listData.items = (listData.items || []).map(item => {
    if (item.serviceId) {
      return normalizeLifemarketService(item.service);
    }
    if (item.userId) {
      return item.user;
    }
    return item;
  });

  return {
    ...state,
    data: {
      ...state.data,
      [listData.id]: listData,
    },
  };
};

const applyLifemarketMerchListData = (state, action) => {
  const listData = camelize(action.payload.info);

  // If lifemarket recommended fallback returns Explore carousel instead of Recommended
  const homeFeedConfig = state?.config;
  const recommendedIndex = findIndex(homeFeedConfig, config => {
    if (config?.params) {
      return config?.params?.listId === 'recommended_lifemarket_items';
    }
    return false;
  });

  if (
    (listData?.id === 'explore' || listData?.id === 'best_selling') &&
    recommendedIndex > 0
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        recommended_lifemarket_items: listData,
      },
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      [listData.id]: listData,
    },
  };
};

export const homeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CONFIG.SUCCEEDED:
      return applyConfigData(state, action);
    case FETCH_USER_MERCH_LIST.SUCCEEDED:
      return applyMerchListData(state, action);
    case FETCH_USER_LIFEMARKET_MERCHLIST.SUCCEEDED:
      return applyLifemarketMerchListData(state, action);
    default:
      return state;
  }
};
