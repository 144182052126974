import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * Call the verify_pre_enrollment_user service, passing user details, in order to verify
 * whether that user has a pending invitation. The service responds with the `active` state
 * of the user account and the pending invitation ID if available.
 *
 * See https://everlong.atlassian.net/wiki/spaces/TECH/pages/783417664/Non-email+Invite+Redemption
 *
 * @param params.firstName string
 * @param params.lastName string
 * @param params.dateOfBirth Date
 * @param params.emailAddress string
 * @param params.groupConfigId string
 * @param params.uniqueIdentifiers array - contains fields configured per-group.
 */
export const verifyPreEnrollmentUser = ({
  firstName,
  lastName,
  dateOfBirth,
  emailAddress,
  groupConfigId,
  uniqueIdentifiers,
  invitationId,
}) =>
  call(websocketFetch, 'verify_pre_enrollment_user', {
    first_name: firstName.trim(),
    last_name: lastName.trim(),
    date_of_birth: dateOfBirth,
    email: emailAddress,
    group_config_id: groupConfigId,
    unique_identifiers: uniqueIdentifiers,
    invitation_id: invitationId,
  });
