import { get } from 'lodash';
import {
  EMPLOYER_PROFILE_INIT,
  EMPLOYER_PROFILE_LIFECYCLE,
} from './employer-profile.types';

export const initialState = {
  formData: null,
  schema: null,
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYER_PROFILE_INIT.SUCCEEDED:
      return {
        ...state,
        formData: get(action, 'payload.employer'),
        schema: get(action, 'payload.schema'),
      };
    case EMPLOYER_PROFILE_LIFECYCLE.EXITED:
      return initialState;
    default:
      return state;
  }
};
