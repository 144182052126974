import {
  NEW_EMPLOYER_VISITED,
  NEW_EMPLOYER_FORM_SUBMITTED,
  EDIT_EMPLOYER_VISITED,
  EDIT_EMPLOYER_FORM_SUBMITTED,
  PUBLISH_EMPLOYER_SELECTED,
  ACTIVATE_EMPLOYER_SELECTED,
  SUSPEND_EMPLOYER_SELECTED,
} from './types';

export const newEmployerSubmitted = group => ({
  type: NEW_EMPLOYER_FORM_SUBMITTED,
  payload: group,
});

export const editEmployerSubmitted = group => ({
  type: EDIT_EMPLOYER_FORM_SUBMITTED,
  payload: group,
});

export const newEmployerVisited = () => ({
  type: NEW_EMPLOYER_VISITED,
});

export const editEmployerVisited = groupId => ({
  type: EDIT_EMPLOYER_VISITED,
  payload: groupId,
});

export const publishEmployer = () => ({
  type: PUBLISH_EMPLOYER_SELECTED,
});

export const activateEmployer = () => ({
  type: ACTIVATE_EMPLOYER_SELECTED,
});

export const suspendEmployer = () => ({
  type: SUSPEND_EMPLOYER_SELECTED,
});
