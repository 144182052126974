import React, { useEffect, useState } from 'react';
import {
  Box,
  SubtitleOne,
  Label,
  LabelText,
} from '@leagueplatform/genesis-commons';
import { Select } from 'common/form-fields/Select/Select';

import {
  ContentfulTemplateLink,
  CreateNewTemplateButton,
  TemplateContainer,
  TemplateHeader,
  TemplateStatus,
} from './contentful-integration.styles';
import { useIntl } from '@leagueplatform/locales';
import {
  TEMPLATE_CLASS,
  TEMPLATE_GROUP,
  TEMPLATE_SET,
} from '../enrollment-experience-config.constants';

interface ContentfulTemplatesProps {
  templates: any[];
  selectedTemplateId: string | null;
  setSelectedTemplateId: (templateId: string | null) => void;
  createContentfulTemplate: (type: string) => void;
  isChecked: boolean;
  type: string;
}

export const ContentfulTemplates = ({
  templates,
  selectedTemplateId,
  setSelectedTemplateId,
  isChecked,
  createContentfulTemplate,
  type,
}: ContentfulTemplatesProps) => {
  const { formatMessage } = useIntl();

  const [selectedTemplateIdFromList, setSelectedTemplateIdFromList] =
    useState(selectedTemplateId);
  const defaultTemplateOptions = templates
    .filter((template: any) => template.default)
    .map((template: any) => ({
      label: template?.content_title || template.enrollment_content_id,
      value: template.enrollment_content_id,
    }));

  const customTemplateOptions = templates
    .filter((template: any) => !template.default)
    .map((template: any) => ({
      label: template?.content_title || template.enrollment_content_id,
      value: template.enrollment_content_id,
    }));

  const groupedOptions = [
    {
      label: 'Default Templates',
      options: defaultTemplateOptions,
    },
    {
      label: 'Custom Templates',
      options: customTemplateOptions,
    },
  ];
  useEffect(() => {
    if (isChecked) setSelectedTemplateId(selectedTemplateIdFromList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateIdFromList]);

  return (
    <>
      <TemplateContainer>
        <TemplateHeader
          title={formatMessage({ id: 'ENROLLMENT_CONFIGURE_TEMPLATE' })}
          tooltip={formatMessage({
            id: 'ENROLLMENT_TEMPLATE_TOOLTIP',
          })}
          value="custom"
          isChecked={isChecked}
          onChange={() => setSelectedTemplateId(selectedTemplateIdFromList)}
        />

        <Label marginBottom="one">
          <LabelText>
            {formatMessage({ id: 'ENROLLMENT_TEMPLATE_SELECT_LIST_LABEL' })}:
          </LabelText>
          <Select
            name="contentful-templates"
            options={groupedOptions}
            value={selectedTemplateId}
            onChange={(e: any) => setSelectedTemplateIdFromList(e?.value)}
            placeholder={formatMessage({
              id: 'ENROLLMENT_CHOOSE_A_TEMPLATE',
            })}
            marginBottom="one"
            isClearable
          />
        </Label>

        {selectedTemplateIdFromList && (
          <>
            <TemplateStatus
              status={
                templates.find(
                  (template: any) =>
                    template.enrollment_content_id ===
                    selectedTemplateIdFromList,
                )?.status
              }
            />
            <ContentfulTemplateLink
              template={templates.find(
                (template: any) =>
                  template.enrollment_content_id === selectedTemplateIdFromList,
              )}
            />
          </>
        )}
      </TemplateContainer>

      <Box marginX="oneAndHalf">
        <SubtitleOne marginBottom="one">
          {formatMessage({ id: 'CREATE_NEW_TEMPLATE' })}
        </SubtitleOne>

        <CreateNewTemplateButton
          onClick={() => {
            createContentfulTemplate(TEMPLATE_GROUP);
          }}
        >
          {formatMessage({ id: 'ENROLLMENT_CREATE_TEMPLATE_GROUP' })}
        </CreateNewTemplateButton>

        <CreateNewTemplateButton
          onClick={() => {
            createContentfulTemplate(TEMPLATE_CLASS);
          }}
        >
          {formatMessage({ id: 'ENROLLMENT_CREATE_TEMPLATE_CLASS' })}
        </CreateNewTemplateButton>

        <CreateNewTemplateButton
          onClick={() => {
            createContentfulTemplate(TEMPLATE_SET);
          }}
        >
          {formatMessage({ id: 'ENROLLMENT_CREATE_TEMPLATE_TYPE' }, { type })}
        </CreateNewTemplateButton>
      </Box>
    </>
  );
};
