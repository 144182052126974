import React from 'react';
import { useSelector } from 'react-redux';
import { VisuallyHidden } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import {
  selectAssertiveMessage,
  selectPoliteMessage,
} from './aria-live-region.selectors';

export const AriaLiveRegion = () => {
  const { formatMessage } = useIntl();
  const assertiveMessage = useSelector(selectAssertiveMessage);
  const politeMessage = useSelector(selectPoliteMessage);

  return (
    <VisuallyHidden>
      <span aria-live="assertive" aria-atomic="true">
        {assertiveMessage ? formatMessage({ id: assertiveMessage }) : null}
      </span>
      <span aria-live="polite" aria-atomic="true">
        {politeMessage ? formatMessage({ id: politeMessage }) : null}
      </span>
    </VisuallyHidden>
  );
};
