import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { selectEmployer, selectEmployerDetails } from '../selectors';
import camelize from 'camelize';

export const selectGroupId = createSelector(
  selectEmployer,
  ({ groupId }) => groupId,
);

export const selectPaymentMethod = createSelector(selectEmployer, data =>
  data.invoicePaymentExists ? data.invoicePaymentExists : false,
);

export const selectGroupLocations = createSelector(
  selectEmployer,
  get('group.locations'),
);

export const selectGroupBillingPreference = createSelector(
  selectEmployer,
  get('billingPreference'),
);

export const selectGroupCreditContract = createSelector(selectEmployer, data =>
  data.groupCreditContracts ? data.groupCreditContracts : {},
);

export const selectGroupBillingContact = createSelector(selectEmployer, data =>
  data.billingEmail ? data.billingEmail : '',
);

export const selectIsBillingReady = createSelector(
  selectEmployerDetails,
  get('billingReady'),
);

export const selectBillingUpdateStatus = createSelector(
  selectEmployerDetails,
  get('billingUpdateStatus'),
);

export const selectCreditPropsToValue = props => {
  const formValues = {};
  props.benefitTypes.forEach(benefit => {
    const benefitType = camelize(benefit.benefitType);
    if (!props.groupCreditContracts[benefitType]) {
      formValues[`billing_${benefit.type}_buffer`] = 0;
      formValues[`${benefit.type}.canAdd`] = true;
      formValues[`billing_${benefit.type}_admin_fee`] = 0;
    } else {
      const bufferValue = props.groupCreditContracts[benefitType].buffer * 100;
      const adminFeeValue =
        props.groupCreditContracts[benefitType].adminFee * 100;
      // change to 4 decimal place and remove trailing zeros
      formValues[`billing_${benefit.type}_buffer`] = parseFloat(
        bufferValue.toFixed(4),
      );
      formValues[`${benefit.type}.canAdd`] =
        props.groupCreditContracts[benefitType].canAdd;
      formValues[`billing_${benefit.type}_admin_fee`] = parseFloat(
        adminFeeValue.toFixed(4),
      );
    }
  });
  return formValues;
};

export const selectEmployerBenefits = createSelector(
  selectEmployerDetails,
  data => data.employerBenefits,
);

export const selectSubscriptionRates = createSelector(selectEmployer, data => {
  const subscriptionRates = [];
  if (data.subscriptionRates) {
    data.subscriptionRates.forEach(rate => {
      subscriptionRates.push({
        lower: rate.pepyFrom.toString(),
        upper:
          rate.pepyTo > rate.pepyFrom ? rate.pepyTo.toString() : 'Infinity',
        rate: rate.monthlyPrice.toString(),
      });
    });
  }
  return subscriptionRates;
});

export const selectPlatformFees = createSelector(
  selectEmployer,
  get('subscriptionRatePepm'),
);

export const selectEmployerOutstandingBalance = createSelector(
  selectEmployerDetails,
  get('employerOutstandingBalance'),
);

export const selectEmployerOutstandingBalanceReady = createSelector(
  selectEmployerDetails,
  get('employerOutstandingBalanceReady'),
);

export const selectEmployerOutstandingBalanceError = createSelector(
  selectEmployerDetails,
  get('employerOutstandingBalanceError'),
);
