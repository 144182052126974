import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';
import { isLocalhost } from 'common/utilities';

/**
 * @function getGroupDashboardURL get a unique url to a looker dashboard
 *
 * @param {string} groupId the employer group ID
 * @param {string} dashboardId the dashboard ID
 *
 * @returns {object} an object
 */

export const getGroupDashboardURL = ({ groupId, dashboardId }) =>
  call(websocketFetch, 'get_group_dashboard_url', {
    group_id: groupId,
    dashboard_id: dashboardId,
    use_dev_embed_domain: isLocalhost(),
  });
