import { useEffect } from 'react';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { ANALYTICS_EVENT_HR_ADMIN_BILLING } from '../constants';
import {
  GET_GROUP_DASHBOARDS,
  getGroupDashboards,
} from '@leagueplatform/admin-api';
import { useQuery } from 'react-query';

export const useActiveInvoices = (groupId, isAdmin, dashboards) => {
  useEffect(() => {
    if (!isAdmin) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_BILLING,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: null,
      });
    }
  }, [isAdmin]);

  const { data: billingInsightsData, isLoading } = useQuery(
    [GET_GROUP_DASHBOARDS],
    () =>
      getGroupDashboards({
        groupId,
      }),
    {
      enabled: !dashboards,
    },
  );

  const sendAnalytics = (detail, link) => {
    if (!isAdmin) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        screen_name: ANALYTICS_EVENT_HR_ADMIN_BILLING,
        product_area: PRODUCT_AREA.HR_ADMIN,
        sub_product_area: null,
        detail,
        link,
      });
    }
  };

  return {
    isLoading,
    billingInsightsData: dashboards || billingInsightsData?.dashboards,
    sendAnalytics,
  };
};
