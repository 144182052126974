import { combineReducers } from 'redux';
import {
  SUBMIT_CLAIM,
  CLOSE_CLAIM_ERROR_MODAL,
  CLOSE_CLAIM_SUCCESS_MODAL,
} from './submit-claim.actions';

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const isSubmittingClaim = (state = false, action) => {
  switch (action.type) {
    case SUBMIT_CLAIM.STARTED:
      return true;
    case SUBMIT_CLAIM.ERRORED:
    case SUBMIT_CLAIM.SUCCEEDED:
      return false;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const isClaimErrorModalOpen = (state = false, action) => {
  switch (action.type) {
    case CLOSE_CLAIM_ERROR_MODAL:
      return false;
    case SUBMIT_CLAIM.ERRORED:
      return true;
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
const isClaimSuccessModalOpen = (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLOSE_CLAIM_SUCCESS_MODAL:
      return false;
    case SUBMIT_CLAIM.SUCCEEDED:
      return !payload?.claimId; // Return true only if the claimId is absent in payload
    default:
      return state;
  }
};

export const submitClaimReducer = combineReducers({
  isSubmittingClaim,
  isClaimErrorModalOpen,
  isClaimSuccessModalOpen,
});
