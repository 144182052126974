import { map, find, get } from 'lodash';
import { get as prop } from 'lodash/fp';
import { createSelector } from 'reselect';
import { selectBenefitClassesEntities } from '../Benefits/selectors';
import { selectEmployerId } from '../selectors';
import { EMPLOYEE_STATE_MAP } from 'common/constants';
import { APP_CONFIG } from 'app-config';
import isFuture from 'date-fns/isFuture';

export const selectPage = prop('apps.employer-experience.pages.employees');

export const selectQuery = createSelector(selectPage, prop('query'));

export const selectIncludeSuspended = createSelector(
  selectPage,
  prop('include_suspended'),
);

export const selectAdminRole = createSelector(selectPage, prop('admin_role'));

export const selectOffset = createSelector(selectPage, prop('offset'));

export const transformEmployees = (employees, benefitClasses, employerId) => {
  return map(employees, employee => {
    const group = employee.groups.find(g => g.groupId === employerId);
    const benefitClassName = get(benefitClasses, [
      group.benefitClassId,
      'name',
    ]);
    const mappedStatus = group.state && EMPLOYEE_STATE_MAP[group.state];
    const { firstName, lastName } = employee.userProfile;
    const name = firstName && lastName ? `${firstName} ${lastName}` : '';

    const inactiveStatus = [
      EMPLOYEE_STATE_MAP.suspended,
      EMPLOYEE_STATE_MAP.terminated,
      EMPLOYEE_STATE_MAP.extension_of_benefits,
    ];
    const hasPendingReinstatement =
      group?.reinstatedDate && isFuture(new Date(group?.reinstateDate));
    const showReinstateAction =
      inactiveStatus.includes(mappedStatus) && !hasPendingReinstatement;
    const showReinstateCancel =
      inactiveStatus.includes(mappedStatus) && hasPendingReinstatement;

    return {
      name,
      email: employee.email,
      userId: employee.userId,
      groupId: group.groupId,
      status: mappedStatus,
      statusUpdated: employee.lastUpdatedTime,
      role: employee.role,
      groups: employee.groups,
      benefitClass: benefitClassName,
      reinstatedDate: group.reinstatedDate ? group.reinstatedDate : '',
      suspendedDate: group.suspendedDate ? group.suspendedDate : '',
      suspendedType: group.suspendedType,
      allocated: employee.allocated,
      balance: employee.balance,
      groupRole: group.role,
      invitationId: group.invitationId,
      showReinstateAction,
      showReinstateCancel,
    };
  });
};

export const selectEmployees = createSelector(
  [selectPage, selectBenefitClassesEntities, selectEmployerId],
  (page, benefitClasses, employerId) =>
    transformEmployees(page.employees, benefitClasses, employerId),
);

export const selectLoading = createSelector(selectPage, prop('loading'));

export const selectError = createSelector(selectPage, prop('error'));

export const selectHasMoreResults = createSelector(
  selectPage,
  prop('hasMoreResults'),
);

export const selectHasEmptyResults = createSelector(
  selectPage,
  page => page.total < 1,
);

export const selectTerminationCodes = createSelector(
  selectPage,
  ({ terminationCodes }) =>
    terminationCodes.codes.map(({ type, name }) => ({
      value: type,
      text: name,
    })),
);

export const selectEmployeeStatusInGroup = ({
  employees,
  employerId,
  employeeId,
}) => {
  let canTerminate = true;
  let canSuspend = true;
  let canReinstate = false;
  const employee = find(employees, ({ userId }) => userId === employeeId);
  const employeeGroup =
    employee && employee.groups.find(({ groupId }) => groupId === employerId);
  const status = employeeGroup
    ? {
        suspended: employeeGroup.suspended || false,
        suspendedType: employeeGroup.suspendedType,
        suspendedDate: employeeGroup.suspendedDate,
        reinstated: employeeGroup.reinstated,
        reinstatedDate: employeeGroup.reinstatedDate,
      }
    : {};
  const {
    suspended,
    suspendedDate,
    suspendedType,
    reinstated,
    reinstatedDate,
  } = status;

  if ((suspendedDate || suspended) && suspendedType === 'terminated') {
    canTerminate = false;
    canReinstate = true;
  }

  if ((suspendedDate || suspended) && suspendedType === 'suspended') {
    canSuspend = false;
    canReinstate = true;
  }

  if (reinstatedDate || reinstated) {
    canReinstate = false;
  }

  return { canTerminate, canSuspend, canReinstate };
};

export const selectUpdatingEmployees = createSelector(
  selectPage,
  ({ updatingEmployees }) => updatingEmployees,
);

export const selectEmployeesServiceStatus = createSelector(
  selectPage,
  ({ employeesServiceStatus }) => employeesServiceStatus,
);

export const getInviteURL = (groups, groupId) =>
  `${APP_CONFIG.REACT_APP_WR_URL}/start/${
    groups && groups.length > 0 && groupId
      ? groups.find(group => group.groupId === groupId).invitationCode
      : null
  }`;
