export default {
  addEmployee: {
    name: 'addEmployee',
    groups: [
      {
        position: 0,
        title: 'Basic Info',
        fields: [
          {
            name: 'benefit_class_id',
            position: 0,
            span: 12,
          },
          {
            name: 'user_id',
            position: 1,
            span: 12,
          },
          {
            name: 'first_name',
            position: 2,
            span: 4,
          },
          {
            name: 'last_name',
            position: 3,
            span: 4,
          },
          {
            name: 'email',
            position: 4,
            span: 4,
          },
          {
            name: 'date_of_birth',
            position: 5,
            span: 4,
          },
          {
            name: 'sex',
            position: 6,
            span: 4,
          },
        ],
      },
      {
        position: 1,
        //        	title: 'Job Details',
        fields: [
          {
            name: 'title',
            position: 0,
            span: 6,
          },
          {
            name: 'employer_issued_employee_id',
            position: 1,
            span: 6,
          },
          {
            name: 'currency',
            position: 2,
            span: 6,
          },
          {
            name: 'date_of_hire',
            position: 4,
            span: 6,
          },
          {
            name: 'hrs_worked_per_week',
            position: 5,
            span: 6,
          },
          {
            name: 'province_of_employment',
            position: 6,
            span: 6,
          },
          {
            name: 'employment_status',
            position: 7,
            span: 6,
          },
          {
            name: 'annual_earnings',
            position: 8,
            span: 6,
          },
          {
            name: 'annual_earnings_effective_date',
            position: 9,
            span: 6,
          },
          {
            name: 'annual_earnings_for_pooled_benefits',
            position: 10,
            span: 6,
          },
        ],
      },
      {
        position: 2,
        //        	title: 'Default Settings',
        fields: [
          {
            name: 'benefits_start_date',
            position: 0,
            span: 6,
          },
        ],
      },
    ],
  }, // layout
};
