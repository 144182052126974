import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { colour, Box } from '@leagueplatform/ui-kit';
import styled, { css } from 'styled-components';
import {
  REPORT_GENERIC_ERROR,
  REPORT_NO_DATA_ERROR,
} from 'apps/employer-experience-v2/employer-reports/reports.constants';

const errorRules = css`
  border-color: ${colour('red')};
  background-color: ${colour('redLightest')};
`;

const InfoBox = styled(Box)`
  border: 1px solid ${colour('purple')};
  background-color: ${colour('purpleLightest')};

  ${({ error }) => error && errorRules};
`;

export const EmployerReportsInfoBox = ({
  loading,
  error,
  ready,
  email,
  hasDateInput,
}) => {
  const getErrorMessage = useCallback((err, hasDate) => {
    let messageId = '';
    switch (err) {
      case REPORT_GENERIC_ERROR:
        messageId = hasDate
          ? 'EMPLOYER_REPORT_WITH_DATES_ERROR_COPY'
          : 'EMPLOYER_REPORT_ERROR_COPY';
        return <FormattedMessage id={messageId} />;
      case REPORT_NO_DATA_ERROR:
        messageId = hasDate
          ? 'EMPLOYER_REPORT_WITH_DATES_NO_DATA_ERROR_COPY'
          : 'EMPLOYER_REPORT_NO_DATA_ERROR_COPY';
        return <FormattedMessage id={messageId} />;
      default:
        return null;
    }
  }, []);

  if (!(loading || error || ready)) return null;
  return (
    <InfoBox mt={2} p={1} error={error}>
      {loading && (
        <FormattedMessage
          id="EMPLOYER_REPORT_REQUESTED_COPY"
          values={{ email }}
        />
      )}
      {ready && (
        <FormattedMessage
          id="EMPLOYER_REPORT_GENERATED_COPY"
          values={{ email }}
        />
      )}
      {error && getErrorMessage(error, hasDateInput)}
    </InfoBox>
  );
};

EmployerReportsInfoBox.propTypes = {
  loading: PropTypes.bool,
  ready: PropTypes.bool,
  error: PropTypes.string,
  email: PropTypes.string,
  hasDateInput: PropTypes.bool,
};

EmployerReportsInfoBox.defaultProps = {
  loading: false,
  ready: false,
  error: null,
  email: '',
  hasDateInput: false,
};
