import React from 'react';
import { InfoBox } from 'common/components/info-box/info-box.view';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import { Button } from 'apps/employer-experience/ui-kit';
import { UpdateContentModal } from '../update-content-modal.view';
import { HtmlContentEditor } from '../HtmlContentEditor/html-content-editor.view';
import { Title, ButtonContainer, EmptyState } from '../common-components';

const OverviewPresenter = ({
  providerBenefit = {},
  onClearOverview,
  onUpdateOverview,
  code,
  templateVariableOptions,
  isUpdateModalOpen,
  isClearModalOpen,
  toggleUpdateModal,
  toggleClearModal,
  setCode,
}) => {
  const { formatMessage, formatHTMLMessage } = useIntl();
  return !providerBenefit ? (
    <EmptyState>{formatMessage({ id: 'OVERVIEW_EMPTY' })}</EmptyState>
  ) : (
    <>
      <Title>
        {formatMessage(
          { id: 'OVERVIEW_EMPTY' },
          {
            product: providerBenefit.full_name,
            carrier: providerBenefit.vendor_name,
          },
        )}
      </Title>
      <InfoBox withIcon my={3}>
        {formatHTMLMessage({ id: 'OVERVIEW_INFO_BOX' })}
      </InfoBox>
      <HtmlContentEditor
        value={code}
        templateVariableOptions={templateVariableOptions}
        onChange={setCode}
      />
      <ButtonContainer>
        <Button confirmModal primary onClick={toggleUpdateModal}>
          {formatMessage({ id: 'UPDATE' })}
        </Button>
        <Button
          confirmModal
          cancel
          onClick={toggleClearModal}
          className="clear-overview-button"
        >
          {formatMessage({ id: 'CLEAR' })}
        </Button>
      </ButtonContainer>
      <UpdateContentModal
        isOpen={isUpdateModalOpen}
        toggleModal={toggleUpdateModal}
        onUpdateContent={onUpdateOverview}
        modalHeader="OVERVIEW_UPDATE_MODAL_HEADER"
        productName={`${providerBenefit.vendor_name} ${providerBenefit.full_name}`}
        confirmButtonId="UPDATE"
      >
        {formatHTMLMessage({ id: 'OVERVIEW_UPDATE_MODAL_CONTENT' })}
      </UpdateContentModal>
      <UpdateContentModal
        isOpen={isClearModalOpen}
        toggleModal={toggleClearModal}
        onUpdateContent={onClearOverview}
        modalHeader="OVERVIEW_CLEAR_MODAL_HEADER"
        confirmButtonId="CLEAR"
      >
        {formatMessage({ id: 'OVERVIEW_CLEAR_MODAL_CONTENT' })}
      </UpdateContentModal>
    </>
  );
};

export default OverviewPresenter;

OverviewPresenter.propTypes = {
  providerBenefit: PropTypes.shape({
    full_name: PropTypes.string,
    vendor_name: PropTypes.string,
  }),
  onClearOverview: PropTypes.func.isRequired,
  onUpdateOverview: PropTypes.func.isRequired,
  code: PropTypes.string,
  templateVariableOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  isUpdateModalOpen: PropTypes.bool.isRequired,
  isClearModalOpen: PropTypes.bool.isRequired,
  toggleUpdateModal: PropTypes.func.isRequired,
  toggleClearModal: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
};

OverviewPresenter.defaultProps = {
  providerBenefit: null,
  code: '',
  templateVariableOptions: null,
};
