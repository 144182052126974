import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table, Loader, Visibility } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import EmployerTableRow, { employerPropTypes } from './Row';
import { ITEMS_PER_INFINITE_SCROLL } from '../constants';

class EmployerTable extends Component {
  constructor(props) {
    super(props);
    this.skip = 0;
    this.getNextPage = this.getNextPage.bind(this);
  }

  getNextPage() {
    this.skip += ITEMS_PER_INFINITE_SCROLL;
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.onSearchPageRequested(this.props.searchText, this.skip);
  }

  render() {
    const { loading, employers, onEmployerSelected } = this.props;
    return (
      <Visibility onBottomVisible={this.getNextPage} once={false}>
        <Table basic="very" padded selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="sort-cell">
                <FormattedMessage id="EMPLOYER" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="STATUS" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="CREATED_ON" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {employers.map(employer => (
              <EmployerTableRow
                key={employer.groupId}
                onSelect={onEmployerSelected}
                // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                {...employer}
              />
            ))}
          </Table.Body>
          {loading && (
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Loader active inline="centered" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </Visibility>
    );
  }
}
EmployerTable.propTypes = {
  searchText: PropTypes.string,
  loading: PropTypes.bool,
  employers: PropTypes.arrayOf(PropTypes.shape(employerPropTypes)).isRequired,
  onSearchPageRequested: PropTypes.func.isRequired,
  onEmployerSelected: PropTypes.func.isRequired,
};

EmployerTable.defaultProps = {
  loading: false,
  searchText: null,
};

export default EmployerTable;
