import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export type SetEnrollmentDesignOptionsParams = {
  groupId: string;
  planId: string;
  steps: object;
};

export const setEnrollmentDesignOptions = ({
  groupId,
  planId,
  steps,
}: SetEnrollmentDesignOptionsParams) =>
  call(websocketFetch, 'set_enrollment_design_options', {
    group_id: groupId,
    plan_id: planId,
    steps,
  });
