import {
  REINSTATE_EMPLOYEE,
  SUSPEND_EMPLOYEE,
  TERMINATE_EMPLOYEE,
  SET_USER_ROLE_IN_GROUP,
  SEND_INVITE_EMAIL,
  UNSCHEDULE_EMPLOYEES_REINSTATEMENT,
  UNSCHEDULE_EMPLOYEES_SUSPENSION,
  UNSCHEDULE_EMPLOYEES_TERMINATION,
} from './profile.types';

export const terminateEmployee = payload => ({
  type: TERMINATE_EMPLOYEE,
  payload,
});

export const suspendEmployee = payload => ({
  type: SUSPEND_EMPLOYEE,
  payload,
});

export const reinstateEmployee = payload => ({
  type: REINSTATE_EMPLOYEE,
  payload,
});

export const setUserRoleInGroup = payload => ({
  type: SET_USER_ROLE_IN_GROUP,
  payload,
});

export const sendInviteEmail = payload => ({
  type: SEND_INVITE_EMAIL.BASE,
  payload,
});

export const unscheduleEmployeesSuspension = payload => ({
  type: UNSCHEDULE_EMPLOYEES_SUSPENSION.BASE,
  payload,
});

export const unscheduleEmployeesTermination = payload => ({
  type: UNSCHEDULE_EMPLOYEES_TERMINATION.BASE,
  payload,
});

export const unscheduleEmployeesReinstatement = payload => ({
  type: UNSCHEDULE_EMPLOYEES_REINSTATEMENT.BASE,
  payload,
});
