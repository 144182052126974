import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CoverageSummaryPresenter } from './coverage-summary.view';
import {
  selectEmployerName,
  selectBenefitClasses,
  selectCurrency,
  selectCoverageSummarySteps,
  selectEnrollmentStatus,
  selectLastSubmittedTime,
  selectFlexDollarTermId,
  selectShowTotalBreakdown,
  selectShowEmployerCostBanner,
  selectShowBenefitSelectionsToolLink,
} from './coverage-summary.selectors';
import {
  selectShowCostsPerPayPeriod,
  selectTotalCost,
  selectTotalFlex,
  selectTotalOutOfPocketPay,
  selectPayPeriodTotalCost,
  selectAllocatedFlex,
  selectSummaryBenefitSets,
  selectIsCoverageSummaryLoaded,
  selectInitialBenefitPlanId,
  selectBenefitSetsWithEOIExpired,
  selectEmployerContribution,
} from 'common/coverage-summary/coverage-summary.selectors';
import { loadCoverageSummary } from 'common/coverage-summary';
import {
  loadEmployerBenefitPlans,
  selectBenefitPlanList,
} from 'common/benefit-plans';
import { selectEmployeeFormValues } from '../../selectors';
import { selectEmployerTzName } from '../../../selectors';

const mapStateToProps = createStructuredSelector({
  employerName: selectEmployerName,
  employeeFormValues: selectEmployeeFormValues,
  benefitClasses: selectBenefitClasses,
  currency: selectCurrency,
  showCostsPerPayPeriod: selectShowCostsPerPayPeriod,
  summarySteps: selectCoverageSummarySteps,
  status: selectEnrollmentStatus,
  lastSubmittedTime: selectLastSubmittedTime,
  employerTzName: selectEmployerTzName,
  totalCost: selectTotalCost,
  totalFlex: selectTotalFlex,
  totalPay: selectTotalOutOfPocketPay,
  perPayPeriodCosts: selectPayPeriodTotalCost,
  allocatedFlex: selectAllocatedFlex,
  benefitSets: selectSummaryBenefitSets,
  isCoverageSummaryLoaded: selectIsCoverageSummaryLoaded,
  flexDollarTermId: selectFlexDollarTermId,
  showTotalBreakdown: selectShowTotalBreakdown,
  showEmployerCostBanner: selectShowEmployerCostBanner,
  employerCostAmount: selectEmployerContribution,
  initialPlanId: selectInitialBenefitPlanId,
  benefitPlans: selectBenefitPlanList,
  benefitSetsWithEOIExpired: selectBenefitSetsWithEOIExpired,
  showBenefitSelectionsToolLink: selectShowBenefitSelectionsToolLink,
});

export const CoverageSummary = connect(mapStateToProps, {
  loadCoverageSummary,
  loadEmployerBenefitPlans,
})(withRouter(CoverageSummaryPresenter));
