import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { withStateHandlers } from 'recompose';
import { Accordion, Message, Icon } from 'semantic-ui-react';
import { BenefitWrapperContainer } from '../benefits/benefit-wrapper.container';
import Benefit from './benefit.view';
import { FormattedMessage } from 'react-intl';
import { vars, Box } from '@leagueplatform/ui-kit';
import styled from 'styled-components';
import {
  trackAnalyticsEvent,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { getHRAdminAnalytics } from 'common/utilities/get-hr-admin-analytics';

const GroupBenefit = styled(Box)`
  color: white;
  height: 2rem;
  background-color: ${vars.colour.grey};
  padding-left: 16px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 2rem;
`;

const sendAnalytics = (isOpen, benefitClass) => {
  const getparam = getHRAdminAnalytics();
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.HR_ADMIN,
    screen_name: getparam[0],
    sub_product_area: getparam[1] || null,
    detail: `toggle ${benefitClass} accordion`,
    sub_detail: !isOpen ? 'expand' : 'collapse',
  });
};

const withActive = withStateHandlers(({ active = true }) => ({ active }), {
  toggleActive:
    ({ active }) =>
    () => ({ active: !active }),
});

const BenefitClass = withActive(
  ({ benefitClass, toggleActive, active, readOnly }) => (
    <Accordion styled fluid className="accordion--benefit-class">
      <Accordion.Title
        active={active}
        onClick={() => {
          toggleActive();
          sendAnalytics(active, benefitClass.name);
        }}
      >
        <Icon fitted name="dropdown" />
        {benefitClass.name}
        <span className="accordion--benefit-meta"> Class</span>
      </Accordion.Title>
      <Accordion.Content active={active}>
        {!isEmpty(benefitClass.benefits) ? (
          map(benefitClass.benefits, (benefitsByType, benefitProductType) => (
            <div key={benefitProductType}>
              <GroupBenefit>{benefitProductType}</GroupBenefit>
              {map(benefitsByType, benefits =>
                map(benefits, benefit => (
                  <BenefitWrapperContainer
                    benefit={benefit}
                    fullName={benefit.full_name}
                    vendorName={benefit.benefit_vendor_name}
                    networkName={benefit.network_name}
                    readOnly={readOnly}
                    key={benefit.id}
                    benefitPlanName={benefit.plan_name}
                  >
                    {/* TODO:benefit-case-migration-EMP-1087 */}
                    <Benefit
                      benefitType={benefit.benefit_type}
                      values={benefit}
                    />
                  </BenefitWrapperContainer>
                )),
              )}
            </div>
          ))
        ) : (
          <Message size="large">
            <FormattedMessage id="NO_BENEFITS_FOR_GROUP_MESSAGE" />
          </Message>
        )}
      </Accordion.Content>
    </Accordion>
  ),
);

BenefitClass.propTypes = {
  benefitClass: PropTypes.shape({
    name: PropTypes.string.isRequired,
    plans: PropTypes.array /* eslint react/forbid-prop-types: 0 */,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  toggleActive: PropTypes.func.isRequired,
};

BenefitClass.defaultProps = {
  readOnly: false,
  benefitClass: {
    name: null,
    plans: null,
  },
};

export default withActive(BenefitClass);
