import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { Link, Text } from '@leagueplatform/ui-kit';
import { useIntl } from '@leagueplatform/locales';
import { SidebarLayout } from './sidebar-layout.view';
import { StepPage } from './step-page.view';
import { PageHeading, ResponsiveButton } from './elements';
import { getVerificationPath } from '../invite-access-paths';

export function Error() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { employerIdentifier } = useParams();

  return (
    <SidebarLayout>
      <StepPage>
        <PageHeading>
          {formatMessage({ id: 'UH_OH_SOMETHING_WENT_WRONG' })}
        </PageHeading>
        <Text>{formatMessage({ id: 'SORRY_WE_CANT_FIND_PAGE' })}</Text>
        <Text>
          <FormattedMessage
            id="IF_YOU_NEED_HELP_EMAIL_US"
            values={{
              emailLink: (
                <Link
                  color="red"
                  href={`mailto:${formatMessage({ id: 'HELP_EMAIL' })}`}
                >
                  <FormattedMessage id="HELP_EMAIL" />
                </Link>
              ),
            }}
          />
        </Text>
        <ResponsiveButton
          mt={24}
          primary
          onClick={() => {
            history.push(getVerificationPath(employerIdentifier));
          }}
        >
          {formatMessage({ id: 'FIND_ACCOUNT' })}
        </ResponsiveButton>
      </StepPage>
    </SidebarLayout>
  );
}
