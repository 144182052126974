import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { createFormValueFormatter } from 'common/adaptive-forms';

export const selectApp = get('apps.employer-experience');

export const selectGroup = createSelector(selectApp, get('group'));

export const selectGroupId = createSelector(selectGroup, get('group_id'));

export const selectGroupCurrency = get(
  'apps.employer-experience.pages.employerDetails.employer.group.currency',
);

export const selectEmployerDetails = createSelector(
  selectApp,
  get('pages.employerDetails'),
);

export const selectEmployeeSchema = createSelector(
  selectEmployerDetails,
  get('employeeFormFieldsSchema'),
);

export const selectEmployeeProfile = createSelector(
  selectEmployerDetails,
  get('employeeEditUser'),
);

export const selectFormValueFormatter = createSelector(
  selectEmployeeSchema,
  createFormValueFormatter,
);

export const selectEmployeeFormValues = createSelector(
  [selectFormValueFormatter, selectEmployeeProfile],
  (format, formData) => format(formData),
);
