import { takeLatest, put, select, fork, call } from 'redux-saga/effects';
import {
  GET_RATE_SHEET_EXTRACT,
  GET_REPORTS,
  GET_USER_BENEFITS,
  GET_USER_BENEFIT_CONTRIBUTIONS,
  LOAD_PREMIUMS_EXPORTS,
  GET_PAYROLL_FEATURE_FLAG,
  PAYROLL_FEATURE_FLAG,
  GET_REPORT_PRESETS,
  SET_REPORT_PRESETS,
  GET_CUSTOMIZED_REPORT,
  GET_PAYROLL_REPORT_CONFIGS,
} from './employer-payroll.types';
import {
  GET_PAYROLL_SCHEDULES,
  GET_PREMIUMS_EXPORT_SPLITS,
} from 'apps/employer-experience-v2/employer-reports/components/premiums-report';
import { payrollConfigSaga } from './resources/payroll-config/payroll-config.saga';
import {
  getRateSheetExtracts,
  getUserBenefits,
  getPayrollSchedules,
  getPremiumsExportSplits,
  getFeatureFlag,
} from 'common/services';
import { getBenefitContributions, getReports } from 'common/services/payroll';
import { selectGroupId } from '../Employee/selectors';
import { editPayCalendarSaga } from './resources/edit-pay-calendar/edit-pay-calendar.saga';
import { deductionCodesSaga } from './resources/deduction-codes/deduction-codes.saga';
import { yearToDateReportSaga } from './year-to-date-report/year-to-date-report.saga';
import { getReportPresets } from 'common/services/payroll/get-report-presets.service';
import { setReportPresets } from 'common/services/payroll/set-report-presets.service';
import { getCustomizedReport } from 'common/services/payroll/get-customized-report.service';
import { request } from 'common/websocket-redux';
import { getPayrollReportConfigs } from 'common/services/payroll/get-group-payroll-report-configs.service';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* getRateSheetExtract({ payload }) {
  try {
    yield put(GET_RATE_SHEET_EXTRACT.start());
    const groupId = yield select(selectGroupId);
    const response = yield getRateSheetExtracts({ groupId, ...payload });
    yield put(GET_RATE_SHEET_EXTRACT.success(response));
  } catch (e) {
    yield put(GET_RATE_SHEET_EXTRACT.error());
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* fetchUserBenefits({ payload }) {
  try {
    yield put(GET_USER_BENEFITS.start());
    const benefitResponse = yield getUserBenefits({ ...payload });
    yield put(GET_USER_BENEFITS.success(benefitResponse));
  } catch (e) {
    yield put(GET_USER_BENEFITS.error());
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* fetchUserBenefitContributions({ payload }) {
  try {
    yield put(GET_USER_BENEFIT_CONTRIBUTIONS.start());
    const response = yield getBenefitContributions(payload);
    yield put(GET_USER_BENEFIT_CONTRIBUTIONS.success(response));
  } catch (e) {
    yield put(GET_USER_BENEFIT_CONTRIBUTIONS.error());
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* fetchReports({ payload }) {
  try {
    const response = yield getReports(payload);
    yield put(GET_REPORTS.success(response));
  } catch (e) {
    yield put(GET_REPORTS.error());
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* loadPayroll({ payload }) {
  try {
    yield put(LOAD_PREMIUMS_EXPORTS.start());
    const groupId = payload?.group_id;

    // get payroll schedules
    let response = yield getPayrollSchedules({ group_id: groupId });
    yield put(GET_PAYROLL_SCHEDULES.success(response));

    // get premium export splits
    response = yield getPremiumsExportSplits({ group_id: groupId });
    yield put(GET_PREMIUMS_EXPORT_SPLITS.success(response));

    // get feature flag
    response = yield getFeatureFlag({ flagId: PAYROLL_FEATURE_FLAG });
    yield put(GET_PAYROLL_FEATURE_FLAG.success({ ...response, groupId }));
  } catch (e) {
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      text: e.info.reason,
    });
  }
}

export function* fetchReportPresets({ payload }) {
  yield request(GET_REPORT_PRESETS, getReportPresets(payload));
}

export function* saveReportPresets({ payload }) {
  yield request(SET_REPORT_PRESETS, setReportPresets(payload));
}

export function* fetchCustomizedReport({ payload }) {
  const response = yield request(
    GET_CUSTOMIZED_REPORT,
    getCustomizedReport(payload),
  );
  window.open(getContentUrl(response.info.content_id));
}

export function* fetchPayrollReportConfigs({ payload }) {
  yield request(GET_PAYROLL_REPORT_CONFIGS, getPayrollReportConfigs(payload));
}

export function* showErrorToast(action) {
  yield call(toastActions.add, {
    type: TOAST_STATUS.ERROR,
    text: action?.payload?.info?.reason,
  });
}

export function* employerPayrollSaga() {
  yield fork(payrollConfigSaga);
  yield fork(editPayCalendarSaga);
  yield fork(deductionCodesSaga);
  yield takeLatest(GET_RATE_SHEET_EXTRACT.BASE, getRateSheetExtract);
  yield takeLatest(GET_USER_BENEFITS.BASE, fetchUserBenefits);
  yield takeLatest(
    GET_USER_BENEFIT_CONTRIBUTIONS.BASE,
    fetchUserBenefitContributions,
  );
  yield takeLatest(GET_REPORTS.BASE, fetchReports);
  yield takeLatest(LOAD_PREMIUMS_EXPORTS.BASE, loadPayroll);
  yield fork(yearToDateReportSaga);
  yield takeLatest(GET_REPORT_PRESETS.BASE, fetchReportPresets);
  yield takeLatest(SET_REPORT_PRESETS.BASE, saveReportPresets);
  yield takeLatest(GET_CUSTOMIZED_REPORT.BASE, fetchCustomizedReport);
  yield takeLatest(GET_PAYROLL_REPORT_CONFIGS.BASE, fetchPayrollReportConfigs);
  yield takeLatest(
    [
      GET_REPORT_PRESETS.ERRORED,
      SET_REPORT_PRESETS.ERRORED,
      GET_CUSTOMIZED_REPORT.ERRORED,
      GET_PAYROLL_REPORT_CONFIGS.ERRORED,
    ],
    showErrorToast,
  );
}
