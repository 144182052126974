import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

export const getDependentSchema = ({
  relationshipType,
  action,
  schemaType = '',
  qleRequestId = '',
}) =>
  call(websocketFetch, 'get_dependent_schema_web', {
    action,
    qle_request_id: qleRequestId,
    relationship_type: relationshipType,
    schema_type: schemaType,
  });
