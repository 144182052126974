import React from 'react';
import { Text, RadioGroup, Radio, Label, Box } from '@leagueplatform/ui-kit';
import PropTypes from 'prop-types';
import { TOGGLE } from '../qle-configuration.constants';
import { SectionHeading } from './shared.view';

export const ConfigurationToggle = ({
  handleChange,
  id,
  title,
  firstOption,
  secondOption,
  renderIfToggleOn,
  toggleChecked,
  tooltip,
  disabled,
}) => {
  const checked = toggleChecked ? TOGGLE.SHOULD_DISPLAY : TOGGLE.SHOULD_HIDE;

  const onChange = event => {
    const state = event.target.value;
    handleChange(state);
  };

  return (
    <Box mb={3}>
      <SectionHeading title={title} tooltipText={tooltip} />
      <Box mb={2} mt={1}>
        <RadioGroup radioGroupLabel={id}>
          <Radio
            disabled={disabled}
            name={id}
            id={`${id}-radio-1`}
            value={TOGGLE.SHOULD_HIDE}
            onChange={onChange}
            checked={checked === TOGGLE.SHOULD_HIDE}
          >
            <Label htmlFor={`${id}-radio-1`}>
              <Text fontSize={2} color="purpleGrey">
                {firstOption}
              </Text>
            </Label>
          </Radio>
          <Radio
            disabled={disabled}
            name={id}
            id={`${id}-radio-2`}
            value={TOGGLE.SHOULD_DISPLAY}
            onChange={onChange}
            checked={checked === TOGGLE.SHOULD_DISPLAY}
          >
            <Label htmlFor={`${id}-radio-2`}>
              <Text fontSize={2} color="purpleGrey">
                {secondOption}
              </Text>
            </Label>
          </Radio>
        </RadioGroup>
      </Box>
      {checked === TOGGLE.SHOULD_DISPLAY && renderIfToggleOn}
    </Box>
  );
};

ConfigurationToggle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  firstOption: PropTypes.string.isRequired,
  secondOption: PropTypes.string.isRequired,
  renderIfToggleOn: PropTypes.node,
  toggleChecked: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ConfigurationToggle.defaultProps = {
  renderIfToggleOn: undefined,
};
