import {
  SUBMIT_QLE_CONFIGURATION_FORM,
  CANCEL_QLE_CONFIGURATION_FORM,
} from './qle-configuration-form.action-types';

/**
 * @function submitQLEConfiguration Return action with all data needed to submit QLE configuration.
 * @param {string} coverageEffectiveDatePolicy - Convert to int here!
 */
export const submitQLEConfiguration = ({
  groupId,
  benefitClassId,
  qualifyingLifeEventType,
  daysBeforeQleEventDate,
  daysAfterQleEventDate,
  needsHrApproval,
  allowToAddDependents,
  allowToRemoveDependents,
  allowNex,
  suspended,
  createdAt,
  typeformUrl,
  coverageEffectiveDatePolicy,
}) => {
  return {
    type: SUBMIT_QLE_CONFIGURATION_FORM,
    payload: {
      groupId,
      benefitClassId,
      qualifyingLifeEventType,
      daysBeforeQleEventDate,
      daysAfterQleEventDate,
      needsHrApproval,
      allowToAddDependents,
      allowToRemoveDependents,
      allowNex,
      suspended,
      createdAt,
      typeformUrl,
      coverageEffectiveDatePolicy: parseInt(coverageEffectiveDatePolicy, 10),
    },
  };
};

export const cancelQLEConfiguration = ({ groupId, benefitClassId }) => ({
  type: CANCEL_QLE_CONFIGURATION_FORM,
  payload: {
    groupId,
    benefitClassId,
  },
});
