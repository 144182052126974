/**
 * Created this file to lay some ground work for future refactoring all of direct-deposit-account.
 * Right now, all of this component's redux stuff is handled in banking info, which no longer
 * makes much sense.
 */
import { fork } from 'redux-saga/effects';
import { deleteDirectDepositSagas } from './delete-direct-deposit/delete-direct-deposit.sagas';
import { accountInfoFormSagas } from './account-info-form/account-info-form.sagas';
import { directDepositAccountAnalyticsSaga } from './direct-deposit-account-analytics.sagas';

export function* directDepositAccountSagas() {
  yield fork(accountInfoFormSagas);
  yield fork(deleteDirectDepositSagas);
  yield fork(directDepositAccountAnalyticsSaga);
}
