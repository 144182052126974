import React from 'react';
import PropTypes from 'prop-types';
import {
  colour,
  fontSizeInPx,
  radiusInPx,
  spaceInPx,
  leagueFont,
  Modal,
  Backdrop,
  ClickEater,
  Select,
  Heading,
  Box,
} from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import { Formik } from 'formik';
import { useIntl, FormattedHTMLMessage } from '@leagueplatform/locales';
import { StyledDayPickerInput } from 'apps/employer-experience/ui-kit';
import styled from 'styled-components';

const Field = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin: 0 ${spaceInPx(1)};
`;

const Label = styled.label`
  color: ${colour('neutral.gray')};
  font-size: ${fontSizeInPx(1)};
  font-weight: 500;
  margin-bottom: ${spaceInPx(2)};
`;

const StyledHeading = styled(Heading)`
  color: ${colour('purpleDarkest')};
  font-size: ${fontSizeInPx(3)};

  /**
    Override global styles
   */
  && {
    margin: ${spaceInPx(1)} 0;
  }
`;

const DayPickerWrapper = styled.div`
  .DayPicker-wrapper {
    padding: 0.7rem 0;
    .DayPicker-Day {
      padding: 0.2rem;
    }
  }
  .DayPickerInput input {
    width: 100%;
    ${leagueFont()};
    font-size: ${fontSizeInPx(2)};
    height: 45px;
    padding: 0 ${spaceInPx(2)};
    border: 1px solid ${colour('neutral.grayLight')};
    border-radius: ${radiusInPx(2)};
    color: ${colour('navyDark')};
    outline: 0;
    &:focus {
      border-color: ${colour('purple')};
    }
    &::placeholder {
      color: ${colour('neutral.gray')};
    }
  }
`;

const DATE_FORMAT = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const StyledModalContainer = styled(Modal.Container)`
  overflow: visible;
`;

export const RemoveDependentModalPresenter = ({
  employeeName,
  dependentName,
  terminationOptions,
  onClose,
  onSubmit,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const initialValues = {
    terminationDate: '',
    terminationReason: '',
  };
  return (
    <Backdrop>
      <StyledModalContainer>
        <ClickEater>
          <Modal.Header>
            <StyledHeading level={2}>
              {formatMessage(
                { id: 'REMOVE_DEPENDENT_MODAL_TITLE' },
                { dependentName },
              )}
            </StyledHeading>
          </Modal.Header>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Box mb={3}>
                      <FormattedHTMLMessage
                        id="REMOVE_DEPENDENT_MODAL_MESSAGE"
                        values={{
                          employeeName,
                          dependentName,
                        }}
                      />
                    </Box>
                    <Flex justify="space-between" mx={-1}>
                      <Field>
                        <Label>
                          {formatMessage({ id: 'BENEFITS_TERMINATION_DATE' })}
                        </Label>
                        <DayPickerWrapper>
                          <StyledDayPickerInput
                            value={
                              values.terminationDate
                                ? formatDate(values.terminationDate, {
                                    ...DATE_FORMAT,
                                    timeZone: 'utc',
                                  })
                                : ''
                            }
                            onDayChange={value => {
                              setFieldValue(
                                'terminationDate',
                                value.toISOString(),
                              );
                            }}
                          />
                        </DayPickerWrapper>
                      </Field>
                      <Field>
                        <Label>
                          {formatMessage({ id: 'TERMINATION_REASON' })}
                        </Label>
                        <Select
                          my={0}
                          name="termination_reason"
                          options={terminationOptions}
                          noSelectionLabel={formatMessage({ id: 'SELECT_ONE' })}
                          selectedValue={values.terminationReason}
                          onChange={({ value }) => {
                            setFieldValue('terminationReason', value);
                          }}
                        />
                      </Field>
                    </Flex>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button secondary mr={2} onClick={onClose} type="button">
                      {formatMessage({ id: 'CANCEL' })}
                    </Button>
                    <Button primary type="submit">
                      {formatMessage({ id: 'REMOVE' })}
                    </Button>
                  </Modal.Footer>
                </form>
              );
            }}
          </Formik>
        </ClickEater>
      </StyledModalContainer>
    </Backdrop>
  );
};

RemoveDependentModalPresenter.propTypes = {
  dependentName: PropTypes.string.isRequired,
  employeeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  terminationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
