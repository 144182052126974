import React from 'react';
import { Box } from '@leagueplatform/genesis-commons';
import { isContributionSet } from 'common/benefit-sets/benefit-set-types';
import { InsuredBenefitsSummary } from './insured-benefits-summary/insured-benefits-summary.view';
import { ContributionsSummary } from './contributions-summary/contributions-summary.view';
import { breakpoints, Responsive } from '@leagueplatform/ui-kit';

const SummaryDetailsContainer = props => {
  const commonStyleProps = {
    backgroundColor: 'surface.background.primary',
    borderColor: 'onSurface.border.subdued',
  };
  return (
    <>
      <Responsive breakpoint={breakpoints.belowLap}>
        <Box
          padding="oneAndHalf"
          borderTopWidth="thick"
          borderTopStyle="solid"
          borderBottomWidth="thick"
          borderBottomStyle="solid"
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...commonStyleProps}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      </Responsive>
      <Responsive breakpoint={breakpoints.lapAndAbove}>
        <Box
          borderRadius="medium"
          borderWidth="thick"
          borderStyle="solid"
          padding="two"
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...commonStyleProps}
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          {...props}
        />
      </Responsive>
    </>
  );
};

const useBenefitSummarySteps = ({ summarySteps = [] }) => {
  const insuredBenefitSteps = summarySteps.filter(step =>
    step.benefitSets?.every(set => !isContributionSet(set.typeId)),
  );
  const contributionSteps = summarySteps.filter(step =>
    step.benefitSets?.every(set => isContributionSet(set.typeId)),
  );

  return {
    insuredBenefitSteps,
    contributionSteps,
  };
};

export const CoverageSummaryTable = props => {
  const { insuredBenefitSteps, contributionSteps } =
    useBenefitSummarySteps(props);

  return (
    <Box>
      {insuredBenefitSteps.length > 0 && (
        <SummaryDetailsContainer>
          <InsuredBenefitsSummary
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            summarySteps={insuredBenefitSteps}
          />
        </SummaryDetailsContainer>
      )}
      {contributionSteps.length > 0 &&
        contributionSteps.map(contributionStep => (
          <SummaryDetailsContainer marginY="two" key={contributionStep.id}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
            <ContributionsSummary {...props} {...contributionStep} />
          </SummaryDetailsContainer>
        ))}
    </Box>
  );
};
