import {
  call,
  cancel,
  fork,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { request, websocketFetch } from 'common/websocket-redux';
import { getResourcesForEditEmployer } from 'common/services';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import { explodeObject } from 'common/adaptive-forms';
import {
  EMPLOYER_PROFILE_LIFECYCLE,
  EMPLOYER_PROFILE_INIT,
  FORM_SUBMITTED,
  UPDATE_EMPLOYER,
} from './employer-profile.types';

export function* initialize() {
  const groupId = yield select(selectCurrentUserGroupId);
  yield request(EMPLOYER_PROFILE_INIT, [getResourcesForEditEmployer, groupId]);
}

export function* updateEmployer(action) {
  const info = explodeObject(action.payload);
  yield request(UPDATE_EMPLOYER, [websocketFetch, 'update_employer', info]);
}

export function* employerProfileSaga() {
  while (yield take(EMPLOYER_PROFILE_LIFECYCLE.VISITED)) {
    yield call(initialize);
    const task = yield fork(takeLatest, FORM_SUBMITTED, updateEmployer);
    yield take(EMPLOYER_PROFILE_LIFECYCLE.EXITED);
    yield cancel(task);
  }
}
