import { fork, all, takeLatest, put, takeEvery } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { getBenefitFormList } from 'common/services/enrollment/get-benefit-form-list.service';
import { getUserBenefitForm } from 'common/services/enrollment/get-user-benefit-form.service';
import {
  GET_BENEFIT_FORM_LIST,
  GET_DIGITAL_BENEFICIARY_FORM,
  GET_USER_BENEFIT_FORM,
} from './insurance-forms.actions';
import { getDigitalBeneficiaryForm } from 'common/services';

export function* requestGetBenefitFormList({ payload }) {
  yield request(GET_BENEFIT_FORM_LIST, getBenefitFormList(payload), payload);
}

export function* requestGetUserBenefitForm({ payload }) {
  yield put(GET_USER_BENEFIT_FORM.start(payload));
  try {
    const response = yield getUserBenefitForm(payload);
    const contentId = response?.info?.content_id;
    if (contentId) {
      yield put(GET_USER_BENEFIT_FORM.success(response, payload));
    } else {
      throw new Error('No content ID found');
    }
  } catch (err) {
    yield put(GET_USER_BENEFIT_FORM.error(err, payload));
  }
}

export function* requestGetDigitalBeneficiaryForm({ payload }) {
  yield put(GET_DIGITAL_BENEFICIARY_FORM.start(payload));
  try {
    const response = yield getDigitalBeneficiaryForm(payload);
    const contentId = response?.info?.content_id;
    if (contentId) {
      yield put(GET_DIGITAL_BENEFICIARY_FORM.success(response, payload));
    } else {
      throw new Error('No content ID found');
    }
  } catch (err) {
    yield put(GET_DIGITAL_BENEFICIARY_FORM.error(err, payload));
  }
}

export function* insuranceFormsSaga() {
  yield fork(all, [
    takeLatest(GET_BENEFIT_FORM_LIST.BASE, requestGetBenefitFormList),
    takeEvery(GET_USER_BENEFIT_FORM.BASE, requestGetUserBenefitForm),
    takeLatest(
      GET_DIGITAL_BENEFICIARY_FORM.BASE,
      requestGetDigitalBeneficiaryForm,
    ),
  ]);
}
