import {
  EMPLOYER_PROFILE_INIT,
  UPDATE_EMPLOYER,
  EMPLOYER_PROFILE_LIFECYCLE,
} from './employer-profile.types';

export const initialState = { ready: false, success: null, error: null };

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employerProfileUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYER_PROFILE_INIT.SUCCEEDED:
      return { ...state, ready: true };
    // eslint-disable-next-line default-case-last -- FIXME: automatically added for existing issue
    default:
      return state;
    case UPDATE_EMPLOYER.SUCCEEDED:
      return { ...state, ready: true, success: true };
    case UPDATE_EMPLOYER.ERRORED:
      return { ...state, ready: true, error: true };
    case EMPLOYER_PROFILE_LIFECYCLE.EXITED:
      return initialState;
  }
};
