import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withStateHandlers } from 'recompose';
import { vars, Icon, ICONS } from '@leagueplatform/ui-kit';

import { Flex } from 'common/components/primitives.view';

const withHover = withStateHandlers(
  () => ({
    isHovered: false,
  }),
  {
    onMouseEnter: () => () => ({ isHovered: true }),
    onMouseLeave: () => () => ({ isHovered: false }),
  },
);

const getIconColor = (pinned, hovered) => {
  if (pinned) {
    return hovered ? vars.colour.purpleDark : vars.colour.purple;
  }

  return hovered ? vars.colour.darkGrey : vars.colour.mediumGrey;
};

const PinBox = styled(Flex)`
  cursor: pointer;
  padding: 16px 16px 16px 8px;
  height: 100%;
`;

const PinIcon = styled(Icon)`
  transition: all 250ms ease-out;
  color: ${props =>
    props.pinned ? vars.colour.darkGrey : vars.colour.lightMediumGrey};
  &:hover {
    color: ${props =>
      props.pinned ? vars.colour.darkGrey : vars.colour.darkGrey};
  }
`;

export const UserDocumentPin = compose(withHover)(
  ({ pinned, onClick, onMouseEnter, onMouseLeave, isHovered }) => (
    <PinBox
      onClick={onClick}
      justify="center"
      align="center"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PinIcon
        pinned={pinned}
        size="20px"
        icon={ICONS.DOCUMENT_PIN}
        colour={getIconColor(pinned, isHovered)}
      />
    </PinBox>
  ),
);

UserDocumentPin.propTypes = {
  pinned: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

UserDocumentPin.defaultProps = {
  pinned: false,
};
