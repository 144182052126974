import { createRequestTypes } from 'common/websocket-redux';

export const ON_ROUTE_CHANGE = 'healthConciergeTooling/ON_ROUTE_CHANGE';

export const ON_GET_HEALTH_PROFILE =
  'healthConciergeTooling/ON_GET_HEALTH_PROFILE';

export const GET_HEALTH_PROFILE = createRequestTypes(
  'healthConciergeTooling/GET_HEALTH_PROFILE',
);

export const ON_GET_HEALTH_PROGRAMS =
  'healthConciergeTooling/ON_GET_HEALTH_PROGRAMS';

export const GET_HEALTH_PROGRAMS = createRequestTypes(
  'healthConciergeTooling/GET_HEALTH_PROGRAMS',
);

export const getHealthProfile = userId => ({
  type: ON_GET_HEALTH_PROFILE,
  payload: { userId },
});

export const onRouteChange = () => ({
  type: ON_ROUTE_CHANGE,
});

export const getHealthPrograms = userId => ({
  type: ON_GET_HEALTH_PROGRAMS,
  payload: { userId },
});
