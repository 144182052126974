import { compose } from 'recompose';
import { ClearInvoicingDiagnosticsComponent } from './finance-invoicing-clear-diagnostics.view';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectClearInvoicingDiagnosticsLoading } from '../../../selectors';

const mapStateToProps = connect(
  createStructuredSelector({
    clearInvoiceDiagnosticsLoading: selectClearInvoicingDiagnosticsLoading,
  }),
);

export const FinanceInvoicingClearDiagnostics = compose(mapStateToProps)(
  ClearInvoicingDiagnosticsComponent,
);
