/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch';

import { selectGroupId } from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { selectEmployeeProfile } from 'apps/employer-experience/state/selectors';

import { useFetch } from 'common/hooks/use-fetch/use-fetch.hook';

import { MaskedNINInputPresenter } from './masked-nin-input.view';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

const mapStateToProps = createStructuredSelector({
  groupId: selectGroupId,
  employee: selectEmployeeProfile,
});

export const MaskedNINInput = connect(mapStateToProps)(
  ({
    disabled,
    value,
    groupId,
    employee,
    dependentId = null,
    onChange,
    ...rest
  }) => {
    // TODO research replacement with useReducer
    const [unmaskedValue, setUnmaskedValue] = useState('');
    const [unmaskedValueChanged, setUnmaskedValueChanged] = useState(false);
    const [timeoutID, setTimeoutID] = useState(null);
    const [shouldHideIcon, setShouldHideIcon] = useState(false);
    const initialValueRef = useRef('');

    const { fetch, loading, error } = useFetch();

    const iconWhenNotLoading = timeoutID ? faEye : faEyeSlash;
    const iconVariation = loading ? faCircleNotch : iconWhenNotLoading;
    const iconClickEnabled = !disabled && !loading && !!value;

    const clearStates = () => {
      setUnmaskedValue('');
      clearTimeout(timeoutID);
      setTimeoutID(null);
    };

    const interceptOnChange = event => {
      if (unmaskedValue) {
        setUnmaskedValueChanged(true);
        clearStates();
      }
      onChange(event);
    };

    const undoUnmask = () => {
      onChange({
        target: {
          value: initialValueRef.current,
        },
      });
      clearStates();
    };

    const fetchUnmaskedValue = async () => {
      const request = {
        group_id: groupId,
        user_id: employee?.user_id,
      };

      if (dependentId) request.dependent_id = dependentId;

      const { national_identification_number } = await fetch({
        messageType: 'get_nin',
        info: request,
      });

      setUnmaskedValue(national_identification_number);

      onChange({
        target: {
          value: national_identification_number,
        },
      });
    };

    const handleIconClick = async () => {
      if (iconClickEnabled) {
        if (timeoutID) {
          undoUnmask();
        } else {
          setTimeoutID(setTimeout(undoUnmask, 10000));
          await fetchUnmaskedValue();
        }
      }
    };

    useEffect(() => {
      if (!value || unmaskedValueChanged) {
        setShouldHideIcon(true);
      }
      initialValueRef.current = value;

      // eslint-disable-next-line
    }, [unmaskedValueChanged]);

    useEffect(() => {
      if (error) {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          text: error?.info?.reason,
          shouldAutoClose: false,
        });
        undoUnmask();
      }
      // eslint-disable-next-line
    }, [error]);

    return (
      <MaskedNINInputPresenter
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...rest}
        onChange={interceptOnChange}
        handleIconClick={handleIconClick}
        iconVariation={iconVariation}
        isIconHidden={shouldHideIcon}
        iconClickEnabled={iconClickEnabled}
        isLoading={loading}
        value={value}
        disabled={disabled}
      />
    );
  },
);

MaskedNINInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

MaskedNINInput.defaultProps = {
  value: '',
  placeholder: '',
  readOnly: false,
  disabled: false,
};

export default MaskedNINInput;
