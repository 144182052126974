import PropTypes from 'prop-types';
import { WARNING_SEVERITY } from '../enrollment-config.constants';

export const getWarningsOfSeverity = severity => warning =>
  warning.severity === severity;

export const warningMessageShape = PropTypes.shape({
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
});

export const getHighestSeverity = (validationItems = []) => {
  const severityOrder = [WARNING_SEVERITY.ERROR, WARNING_SEVERITY.WARNING];
  let severityIndex;
  validationItems.forEach(item => {
    const currentSeverityIndex = severityOrder.indexOf(item?.severity);

    if (currentSeverityIndex !== -1) {
      severityIndex =
        severityIndex !== undefined
          ? Math.min(currentSeverityIndex, severityIndex)
          : currentSeverityIndex;
    }
  });

  return severityOrder?.[severityIndex] ?? null;
};
