import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingThree,
  Flex,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { defaultFlexDollarTermId } from 'common/constants';
import { Icon, ICONS } from '@leagueplatform/ui-kit';
import { SummaryBenefitSelection } from './coverage-summary-list-selection.view';
import { lowercaseAndHypen } from '@leagueplatform/web-common';

const TRANSITION_SPEED = '500ms';

export const Chevron = genesisStyled(Icon)`
  fill: currentColor;
  transition: transform ${TRANSITION_SPEED} ease;
  transform: rotate(90deg);
`;

export const AccordionTitle = genesisStyled(Flex)(
  css({
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      borderColor: 'interactive.focus.outer',
      outline: 'none',
    },
    '&[aria-expanded="true"] > svg': {
      transform: 'rotate(-90deg)',
    },
  }),
);

export const CoverageSummaryStep = ({
  name,
  translatedName,
  selections,
  isCollapsible,
  isStepExpanded,
  showCostsPerPayPeriod,
  flexDollarTermId,
  benefitSets,
}) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(isStepExpanded);

  useEffect(() => {
    setIsExpanded(isStepExpanded);
  }, [isStepExpanded]);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const formattedName = name && formatMessage({ id: name });

  const benefitName = translatedName || formattedName || '';

  const showBenefitName = selections.length > 1;

  return (
    <>
      {isCollapsible && (
        <AccordionTitle
          as="button"
          aria-expanded={isExpanded}
          onClick={toggleIsExpanded}
          justifyContent="space-between"
          align="baseline"
          backgroundColor="surface.background.primary"
          width={1}
          borderStyle="solid"
          borderColor="transparent"
        >
          <HeadingThree>{benefitName}</HeadingThree>
          <Chevron
            aria-hidden
            id={`icon-${lowercaseAndHypen(benefitName)}`}
            icon={ICONS.CHEVRON_RIGHT}
            size={20}
          />
        </AccordionTitle>
      )}
      {!isCollapsible && (
        <Flex
          justifyContent="space-between"
          align="baseline"
          backgroundColor="surface.background.primary"
          width={1}
        >
          <HeadingThree>{benefitName}</HeadingThree>
        </Flex>
      )}
      {isExpanded && selections && (
        <ul>
          {selections.map(selection => {
            const { title } =
              benefitSets.find(set => set.id === selection.setId) ?? {};
            return (
              <li key={selection.id}>
                <SummaryBenefitSelection
                  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
                  {...selection}
                  title={title}
                  showBenefitName={showBenefitName}
                  showCostsPerPayPeriod={showCostsPerPayPeriod}
                  flexDollarTermId={flexDollarTermId}
                />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

CoverageSummaryStep.propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string,
  translatedName: PropTypes.string,
  isCollapsible: PropTypes.bool,
  isStepExpanded: PropTypes.bool,
  showCostsPerPayPeriod: PropTypes.bool.isRequired,
  flexDollarTermId: PropTypes.string,
  benefitSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};

CoverageSummaryStep.defaultProps = {
  isCollapsible: false,
  isStepExpanded: true,
  flexDollarTermId: defaultFlexDollarTermId,
  name: null,
  translatedName: null,
  benefitSets: [],
};
