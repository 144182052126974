import React from 'react';
import { FieldArray } from 'formik';
import { JSONSchemaFieldPropType } from 'common/json-schema-form/prop-types';
import {
  Flex,
  Box,
  LabelText,
  BodyTwoSecondary,
  DangerButton,
  SecondaryButton,
} from '@leagueplatform/genesis-commons';

const AmountByFieldItemWrapper = ({ children, idx }) => children({ idx });

export const AmountByFieldWrapper = ({
  title,
  description,
  name,
  field,
  children,
}) => {
  return (
    <Box
      as="fieldset"
      marginY="two"
      padding="half"
      paddingTop="one"
      borderWidth="thin"
      borderColor="interactive.background.disabled"
      borderRadius="small"
    >
      <LabelText as="legend">{title}</LabelText>
      {description && (
        <BodyTwoSecondary marginBottom="threeQuarters">
          {description}
        </BodyTwoSecondary>
      )}
      <FieldArray
        name={name}
        render={({ push, remove }) => {
          return (
            <Flex flexDirection="column">
              {field.value && field.value.length > 0 ? (
                field.value.map((_, idx) => (
                  <Flex
                    key={idx} // eslint-disable-line react/no-array-index-key
                    width={1}
                    paddingX="half"
                    marginY="half"
                    alignItems="center"
                    justifyContent="space-between"
                    borderWidth="thin"
                    borderColor="interactive.border.disabled"
                    borderRadius="small"
                  >
                    <Box paddingX="half">
                      <AmountByFieldItemWrapper idx={idx}>
                        {children}
                      </AmountByFieldItemWrapper>
                    </Box>

                    <Box padding="half">
                      <DangerButton
                        type="button"
                        remove
                        onClick={() => remove(idx)}
                      >
                        Remove
                      </DangerButton>
                    </Box>
                  </Flex>
                ))
              ) : (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  padding="two"
                  backgroundColor="surface.background.secondary"
                  borderRadius="medium"
                  color="onSurface.text.subdued"
                  marginY="half"
                >
                  Nothing added
                </Flex>
              )}
              <SecondaryButton type="button" onClick={() => push({})}>
                + Add {title}
              </SecondaryButton>
            </Flex>
          );
        }}
      />
    </Box>
  );
};

AmountByFieldWrapper.propTypes = JSONSchemaFieldPropType;
