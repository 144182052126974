import { fork, all, put, takeLatest, call } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import {
  getBeneficiaryDetails,
  setBeneficiaryDesignations,
  setBeneficiaries,
} from 'common/services/enrollment';
import {
  GET_BENEFICIARY_DETAILS,
  SET_BENEFICIARY_DESIGNATIONS,
  SET_BENEFICIARIES,
} from './beneficiaries.action-types';
import { sendAnalyticsEvent } from '@leagueplatform/analytics';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* requestGetBeneficiaryDetails({ payload }) {
  yield request(GET_BENEFICIARY_DETAILS, getBeneficiaryDetails(payload));
}

export function* requestSetBeneficiaryDesignations({ payload }) {
  try {
    yield put(SET_BENEFICIARY_DESIGNATIONS.start(payload));
    const response = yield setBeneficiaryDesignations(payload);
    yield put(SET_BENEFICIARY_DESIGNATIONS.success(response));
    yield call(toastActions.add, {
      type: TOAST_STATUS.SUCCESS,
      textId: 'SUCCESS',
      contentId: 'BENEFICIARY_DESIGNATIONS_SAVED',
    });
    yield call(sendAnalyticsEvent, {
      category: 'Digital Beneficiaries',
      action: 'Submit Designations',
      label: 'success',
    });
  } catch (error) {
    yield put(SET_BENEFICIARY_DESIGNATIONS.error(error));
    yield call(toastActions.add, {
      type: TOAST_STATUS.ERROR,
      textId: 'ERROR',
      values: { error: error?.info?.reason },
      contentId: 'ERROR_SAVING_BENEFICIARY_DESIGNATIONS',
    });
    yield call(sendAnalyticsEvent, {
      category: 'Digital Beneficiaries',
      action: 'Submit Designations',
      label: 'error - saving beneficiary designations',
    });
  }
}

export function* requestSetBeneficiaries({ payload }) {
  const response = yield request(
    SET_BENEFICIARIES,
    setBeneficiaries(payload),
    payload?.benefitDesignation, // can be undefined. Will be set when beneficiary added via `Create new` in Add Beneficiary dropdown
  );

  if (response.error) {
    yield call(sendAnalyticsEvent, {
      category: 'Digital Beneficiaries',
      action: 'Save Beneficiary',
      label: 'error',
    });
  } else {
    yield call(sendAnalyticsEvent, {
      category: 'Digital Beneficiaries',
      action: 'Save Beneficiary',
      label: 'success',
    });
  }
}

export function* beneficiariesSaga() {
  yield fork(all, [
    takeLatest(GET_BENEFICIARY_DETAILS.BASE, requestGetBeneficiaryDetails),
    takeLatest(
      SET_BENEFICIARY_DESIGNATIONS.BASE,
      requestSetBeneficiaryDesignations,
    ),
    takeLatest(SET_BENEFICIARIES.BASE, requestSetBeneficiaries),
  ]);
}
