import React from 'react';
import { JSONSchemaFieldPropType } from '../prop-types';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { EntityFormField } from './entity-form-field.view';
import {
  Flex,
  LabelText,
  BodyTwoSecondary,
} from '@leagueplatform/genesis-commons';

export const ObjectField = ({ propSchema, name }) => {
  const { properties, ...parentFieldSchema } = propSchema;
  if (parentFieldSchema.patternProperties) {
    return null; // TODO - we might need to handle this somehow?
  }
  // because of the way we sort properties, they could be an array of pairs or an object
  const renderProperties = Array.isArray(properties)
    ? properties
    : Object.entries(properties);

  return (
    <Flex
      as="fieldset"
      flexDirection="column"
      marginY="two"
      padding="half"
      paddingTop="one"
      borderWidth="thin"
      borderColor="interactive.background.disabled"
      borderRadius="small"
    >
      <LabelText as="legend">{propSchema.title || name}</LabelText>
      {propSchema.description && (
        <BodyTwoSecondary marginBottom="half">
          {propSchema.description}
        </BodyTwoSecondary>
      )}
      {renderProperties &&
        renderProperties.map(([propertyName, propertySchema]) => {
          const fieldName = `${name}.${propertyName}`;
          return (
            <EntityFormField
              key={fieldName}
              name={fieldName}
              propSchema={propertySchema}
            />
          );
        })}
    </Flex>
  );
};

ObjectField.propTypes = JSONSchemaFieldPropType;
