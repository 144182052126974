import {
  GET_ENROLLMENT_DESIGN_OPTIONS,
  GET_ENROLLMENT_TEMPLATES,
  RESET_ENROLLMENT_DESIGN_OPTIONS,
  SET_ENROLLMENT_DESIGN_OPTIONS,
  SET_ENROLLMENT_TEMPLATES,
} from './enrollment-design-options.actions';
import {
  EnrollmentDesignOptionsState,
  BenefitStep,
  ValidationError,
  ContentfulTemplate,
  EnrollmentTemplateTypes,
} from './enrollment-design-options.types';

interface EnrollmentDesignAction {
  type?: string;
  payload?: {
    info: {
      enrollment_design_options: {
        steps: BenefitStep[];
        group_id: string;
        plan_id: string;
      };
      available_sets: string[];
      validation_errors: ValidationError[];
      reason?: string;
    };
  };
}

interface EnrollmentDesignTemplatesAction {
  type?: string;
  payload?: {
    info: {
      benefit_set_group_model?: ContentfulTemplate[];
      benefit_set_model?: ContentfulTemplate[];
      enrollment_step_model?: ContentfulTemplate[];
      reason?: string;
    };
  };
}

export const initialState: EnrollmentDesignOptionsState = {
  loading: false,
  saving: false,
  contentfulTemplates: {
    loading: false,
  },
};

const applyEnrollmentDesignOptionsFetching = (
  state: EnrollmentDesignOptionsState,
) => ({
  ...state,
  loading: true,
});

const applyEnrollmentDesignOptionsSaving = (
  state: EnrollmentDesignOptionsState,
) => ({
  ...state,
  saving: true,
});

const applyTemplatesFetching = (state: EnrollmentDesignOptionsState) => ({
  ...state,
  contentfulTemplates: {
    loading: true,
  },
});

const applyEnrollmentDesignOptionsError = (
  state: EnrollmentDesignOptionsState,
  action: EnrollmentDesignAction = {},
) => ({
  ...state,
  loading: false,
  saving: false,
  error: action?.payload?.info?.reason,
});

const applyTemplatesError = (
  state: EnrollmentDesignOptionsState,
  action: EnrollmentDesignTemplatesAction = {},
) => ({
  ...state,
  contentfulTemplates: {
    loading: false,
    error: action?.payload?.info?.reason,
  },
});

const applyEnrollmentDesignOptions = (
  state: EnrollmentDesignOptionsState,
  action: EnrollmentDesignAction = {},
) => ({
  ...state,
  loading: false,
  saving: false,
  error: null,
  enrollmentDesignOptions: {
    group_id: action?.payload?.info?.enrollment_design_options?.group_id,
    plan_id: action?.payload?.info?.enrollment_design_options?.plan_id,
    steps: action?.payload?.info?.enrollment_design_options?.steps ?? [],
    validationErrors: { ...action?.payload?.info?.validation_errors },
    availableBenefitSets: action?.payload?.info?.available_sets ?? [],
  },
});

export const normalizeTemplates = (templates: any[], type: string) => {
  switch (type) {
    case EnrollmentTemplateTypes.GROUP:
      return templates.map(template => ({
        ...template,
        title:
          template?.benefit_set_group_content_title ||
          template?.benefit_set_group_title,
      }));
    case EnrollmentTemplateTypes.SET:
      return templates.map(template => ({
        ...template,
        title:
          template?.benefit_set_content_title || template?.benefit_set_title,
      }));
    case EnrollmentTemplateTypes.STEP:
      return templates.map(template => ({
        ...template,
        title:
          template?.enrollment_step_content_title ||
          template?.enrollment_step_title,
      }));
    default:
      return templates;
  }
};

const applyEnrollmentTemplates = (
  state: EnrollmentDesignOptionsState,
  action: EnrollmentDesignTemplatesAction = {},
) => ({
  ...state,
  contentfulTemplates: {
    loading: false,
    templates: [
      ...(action?.payload?.info?.benefit_set_group_model ?? []),
      ...(action?.payload?.info?.benefit_set_model ?? []),
      ...(action?.payload?.info?.enrollment_step_model ?? []),
    ],
  },
});

export const enrollmentDesignReducer = (
  state = initialState,
  action: EnrollmentDesignAction | EnrollmentDesignTemplatesAction = {},
) => {
  switch (action?.type) {
    case GET_ENROLLMENT_DESIGN_OPTIONS.STARTED:
      return applyEnrollmentDesignOptionsFetching(state);
    case SET_ENROLLMENT_DESIGN_OPTIONS.STARTED:
    case RESET_ENROLLMENT_DESIGN_OPTIONS.STARTED:
      return applyEnrollmentDesignOptionsSaving(state);
    case GET_ENROLLMENT_DESIGN_OPTIONS.SUCCEEDED:
    case SET_ENROLLMENT_DESIGN_OPTIONS.SUCCEEDED:
      return applyEnrollmentDesignOptions(
        state,
        action as EnrollmentDesignAction,
      );
    case GET_ENROLLMENT_DESIGN_OPTIONS.ERRORED:
    case SET_ENROLLMENT_DESIGN_OPTIONS.ERRORED:
    case RESET_ENROLLMENT_DESIGN_OPTIONS.ERRORED:
      return applyEnrollmentDesignOptionsError(
        state,
        action as EnrollmentDesignAction,
      );
    case GET_ENROLLMENT_TEMPLATES.STARTED:
      return applyTemplatesFetching(state);
    case GET_ENROLLMENT_TEMPLATES.SUCCEEDED:
      return applyEnrollmentTemplates(
        state,
        action as EnrollmentDesignTemplatesAction,
      );
    case GET_ENROLLMENT_TEMPLATES.ERRORED:
    case SET_ENROLLMENT_TEMPLATES.ERRORED:
      return applyTemplatesError(
        state,
        action as EnrollmentDesignTemplatesAction,
      );
    default:
      return state;
  }
};
