import { useReducer } from 'react';

export const ACTION_EOB_DATE = 'action-EOB-date';
export const NOTICE_EOB_DATE = 'notice-EOB-date';
export const ACTION_EFFECTIVE_DATE = 'action-effective-date';
export const ACTION_REASON = 'action-reason';
export const DATE_IS_EMPTY = 'date-is-empty';
export const REASON_IS_EMPTY = 'reason-is-empty';

export const initialState = {
  reasonForTermination: null,
  effectiveDate: null,
  EOBDate: null,
  noticeDate: null,
  dateIsEmpty: false, // old setEmptyDateError
  reasonIsEmpty: false, // old setEmptyReasonError
};

export const actionsConfirmationReducer = (state, action) => {
  switch (action.type) {
    case ACTION_EOB_DATE:
      return {
        ...state,
        EOBDate: action.payload,
      };
    case NOTICE_EOB_DATE:
      return {
        ...state,
        noticeDate: action.payload,
      };
    case ACTION_EFFECTIVE_DATE:
      return {
        ...state,
        dateIsEmpty: false,
        effectiveDate: action.payload,
      };
    case ACTION_REASON:
      return {
        ...state,
        reasonIsEmpty: false,
        reasonForTermination: action.payload,
      };
    case DATE_IS_EMPTY:
      return {
        ...state,
        dateIsEmpty: action.payload,
      };
    case REASON_IS_EMPTY:
      return {
        ...state,
        reasonIsEmpty: action.payload,
      };
    default:
      throw new Error('Error unknown action type');
  }
};

export const useActionsConfirmationReducer = () => {
  const [
    {
      effectiveDate,
      noticeDate,
      EOBDate,
      dateIsEmpty,
      reasonIsEmpty,
      reasonForTermination,
    },
    dispatch,
  ] = useReducer(actionsConfirmationReducer, initialState);

  const handleActionEOBDate = date =>
    dispatch({ type: ACTION_EOB_DATE, payload: date });

  const handleActionNoticeDate = date =>
    dispatch({ type: NOTICE_EOB_DATE, payload: date });

  const handleActionEffectiveDate = date =>
    dispatch({ type: ACTION_EFFECTIVE_DATE, payload: date });

  const setReasonForTermination = reason =>
    dispatch({ type: ACTION_REASON, payload: reason });

  const setDateIsEmpty = isEmpty =>
    dispatch({ type: DATE_IS_EMPTY, payload: isEmpty });

  const setReasonIsEmpty = isEmpty =>
    dispatch({ type: REASON_IS_EMPTY, payload: isEmpty });

  return {
    effectiveDate,
    noticeDate,
    EOBDate,
    dateIsEmpty,
    reasonIsEmpty,
    reasonForTermination,
    handleActionNoticeDate,
    handleActionEOBDate,
    handleActionEffectiveDate,
    setReasonForTermination,
    setDateIsEmpty,
    setReasonIsEmpty,
  };
};
