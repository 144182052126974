import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  ModalButtons,
  ModalContent,
} from 'apps/employer-experience/ui-kit';
import {
  BodyOne,
  Label,
  SecondaryButton,
  PrimaryButton,
  HeadingTwo,
  Checkbox,
  ChoiceText,
} from '@leagueplatform/genesis-commons';
import { selectPayrollReportConfigs } from './employer-payroll.selectors';

export const PayrollReportConfigs = ({
  onClose,
  onSubmit,
  selectedCalendar,
}) => {
  const { formatMessage } = useIntl();
  const payrollReportConfigs = useSelector(selectPayrollReportConfigs);
  const [checkedConfigId, setCheckedConfigId] = useState(
    selectedCalendar.payroll_split_config_id || '',
  );
  const handleCheckboxClick = configId => () => {
    setCheckedConfigId(configId);
  };

  return (
    <Modal>
      <HeadingTwo>{formatMessage({ id: 'PAYROLL_REPORT_CONFIGS' })}</HeadingTwo>
      <ModalContent>
        <BodyOne>
          {formatMessage({ id: 'PAYROLL_REPORT_CONFIGS_COPY' })}
        </BodyOne>
        <Label display="flex" marginTop="one">
          <Checkbox
            checked={checkedConfigId === ''}
            onChange={handleCheckboxClick('')}
            value="Default Config"
          />
          <ChoiceText marginLeft="one">
            {formatMessage({ id: 'DEFAULT_CONFIG' })}
          </ChoiceText>
        </Label>
        {payrollReportConfigs &&
          payrollReportConfigs.map(config => {
            const itemChecked = config.id === checkedConfigId;
            return (
              <Label
                display="flex"
                key={`${config.report_name}}-checkbox`}
                marginTop="one"
              >
                <Checkbox
                  type="checkbox"
                  key={`${config.report_name}}-checkbox`}
                  value={
                    config.friendly_report_name
                      ? config.friendly_report_name
                      : config.report_name
                  }
                  checked={itemChecked}
                  onChange={handleCheckboxClick(config.id)}
                />
                <ChoiceText marginLeft="one">
                  {config.friendly_report_name
                    ? config.friendly_report_name
                    : config.report_name}
                </ChoiceText>
              </Label>
            );
          })}
      </ModalContent>
      <ModalButtons>
        <SecondaryButton marginRight="one" onClick={onClose}>
          {formatMessage({ id: 'CANCEL' })}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            onSubmit(checkedConfigId);
            onClose();
          }}
        >
          {formatMessage({ id: 'LINK_PAYROLL_CONFIGS' })}
        </PrimaryButton>
      </ModalButtons>
    </Modal>
  );
};

PayrollReportConfigs.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- FIXME: automatically added for existing issue
  selectedCalendar: PropTypes.objectOf(PropTypes.any).isRequired,
};

PayrollReportConfigs.defaultProps = {};
