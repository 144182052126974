import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function getQleSchema - calls `get_qle_schema` to get the member's unique schema to be
 * generate the options in QLE screener.
 *
 * @param {string} version: version 0 has days_in_past, and days_in_future
 *                          version 1 has days_before_qle_event_date, days_after_qle_event_date
 *
 * Schemas are unique per group then per benefit class. They will contain all QLE types available
 * to the member. Each QLE type then contains all the text to display on each of the question steps
 * following QLE type selection, including validation rules per each.
 *
 * @return {object} qle schema
 *
 */

export const getQleSchema = () =>
  call(websocketFetch, 'get_qle_schema', { version: 1 });
