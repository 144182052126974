export const fullQLERoutePattern =
  '/admin/employers/:groupId/benefits/qle-config/:benefitClassId?/:qleType?/:qleConfigurationId?';
// radio buttons convert values to strings
export const TOGGLE = {
  SHOULD_DISPLAY: 'show',
  SHOULD_HIDE: 'hide',
};

export const RESET = {
  DAYS_BEFORE_QLE_EVENT_DATE: '',
  DAYS_AFTER_QLE_EVENT_DATE: '',
};

/* Common constants throughout the feature */
/**
 * For date range QLE configuration:
 * `-1` is regarded as infinity
 * `0` is unset
 */
export const INFINITY = -1;
export const UNSET = 0;
