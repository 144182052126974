/* eslint-disable no-use-before-define -- FIXME: automatically added for existing issue */
import { select, call } from 'redux-saga/effects';
import { APP_CONFIG } from 'app-config';
import { selectSessionId } from 'apps/auth/auth-classic/auth-classic.selectors';
import { isAuth0Enabled } from 'apps/auth/auth.utils';
import { Auth0ClientWrapperInstance } from 'apps/auth/auth0/auth0.clientwrapper';

/**
 * @function upload - take an array of files as argument and return an array of reference IDs to be used in URLs
 * @param {File[]} files - array of File objects
 * @return {string[]} - array of content ids
 * @example
 * In your saga, you need to yield this one:
 * const contentIds = yield call(upload, files)
 */

export function* upload(files) {
  const sessionId = yield select(selectSessionId);
  const contentIds = yield call(asyncUpload, files, sessionId);
  return contentIds;
}

export async function asyncUpload(files, sessionId) {
  const contentIds = await Promise.all(
    files.map(file => uploadAndParseResponse(file, sessionId)),
  );
  return contentIds;
}

async function uploadAndParseResponse(file, sessionId) {
  const response = await uploadFile(file, sessionId);
  const { reference_id: contentId } = await response.json();
  if (!contentId) throw new Error();
  return contentId;
}

async function uploadFile(file, sessionId) {
  if (!file) throw new Error('File is required');
  const formData = await createFormData(file);
  const uploadRequest = await createRequest(formData, sessionId);
  return fetch(uploadRequest);
}

function createFormData(file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);
  formData.append('public', file.isPublic);
  return formData;
}

async function createRequest(body, sessionId) {
  if (isAuth0Enabled()) {
    return createAuth0Request(body);
  }
  return createClassicRequest(body, sessionId);
}

async function createClassicRequest(body, sessionId) {
  if (!sessionId) throw new Error('Authentication required');
  const url = `${APP_CONFIG.REACT_APP_CONTENT_SERVER_URL}/contentsave/${sessionId}`;
  return new Request(url, {
    method: 'POST',
    body,
  });
}

async function createAuth0Request(body) {
  const accessToken = await Auth0ClientWrapperInstance.getAccessToken();
  if (!accessToken) throw new Error('Authentication required');
  const url = `${APP_CONFIG.REACT_APP_CONTENT_SERVER_URL}/content`;
  return new Request(url, {
    method: 'POST',
    body,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
  });
}
