import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const ConditionalRenderWrappingTag = ({ displayInline, children }) => {
  if (displayInline) {
    return children;
  }

  return <Form.Group widths="equal">{children}</Form.Group>;
};

ConditionalRenderWrappingTag.propTypes = {
  displayInline: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default ConditionalRenderWrappingTag;
