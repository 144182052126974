import { compose, mapProps, setDisplayName, defaultProps } from 'recompose';
import { get } from 'lodash/fp';
import DefaultBenefit from './DefaultBenefit';
import Documents from './Documents';
import Submit from './Submit';
import { ContentfulToggle } from 'apps/employer-experience/pages/EmployerDetails/wallet-templates/contentful-toggle.container';
import DependentClassAmounts from 'apps/employer-experience-v2/benefits/dependent-class-amounts/dependent-class-amounts.container';
import { omit } from 'lodash';

const components = {
  documents: Documents,
  submit: Submit,
  'benefit.dependent_class_amounts': DependentClassAmounts,
  pull_wallet_template_from_contentful: ContentfulToggle,
};

const layouts = {
  aetna_insurance_dental: 'aetnaDental',
  aetna_insurance_medical: 'aetnaMedical',
  'gwl_insurance_best-doctors': 'gwlBestDoctors',
  gwl_insurance_dental: 'gwlDental',
  gwl_insurance_drug: 'gwlDrugs',
  gwl_insurance_eap: 'gwlEmployeeAssistanceProgram',
  gwl_insurance_healthcare: 'gwlHealthcare',
  'gwl_insurance_life-and-add': 'gwlLifeADD',
  gwl_insurance_ltd: 'gwlLTD',
  gwl_insurance_paramedical: 'gwlParamedical',
  gwl_insurance_travel: 'gwlTravel',
  lincolnfinancial_insurance_ltd: 'lfLTD',
  lincolnfinancial_insurance_std: 'lfSTD',
  'lincolnfinancial_insurance_life-and-add': 'lfVoluntaryLifeADD',
  league_spending_charity: 'leagueCGA',
  league_spending_hsa: 'leagueHSA',
  league_spending_lsa: 'leagueLSA',
  league_spending_sa: 'spendingAccountBenefit',
  league_spending_fsa: 'spendingAccountBenefit',
  league_spending_ldsa: 'spendingAccountBenefit',
  nationwide_insurance_pet: 'nwPetLSA',
  vsp_insurance_vision: 'vspVision',
  lifeworks_insurance_eap: 'lifeworksEAP',
};

const benefitToLayout = props => ({
  layoutName: get(props.benefitType, layouts),
  ...props,
  customComponents: props.benefitType.includes('league_spending_')
    ? omit(props.customComponents, 'documents')
    : props.customComponents,
});

export default compose(
  defaultProps({ customComponents: components }),
  setDisplayName('BenefitDefault'),
  mapProps(benefitToLayout),
)(DefaultBenefit);
