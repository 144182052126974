import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function setPostFtux - calls `set_post_ftux` to set member account state
 *
 * Caution: This API will overwrite the value with whatever is sent. So an object that omits a previously existing
 * key from the `member_post_ftux` object will remove it. As such, you need to pass an updated copy of the `member_post_ftux`
 * object rather than sending a single key
 *
 * There are more possible keys than the ones listed below but these are the ones that we care about in the new
 * enrollment experience
 *
 * @arg {Object} postFtux  - An object with boolean flags
 * @arg {boolean} postFtux.terms  - user has accepted terms and conditions
 * @arg {boolean} postFtux.benefit_selections - user has submitted benefits
 *
 * @returns {Object} - An object
 */

export const setPostFTUX = postFtux =>
  call(websocketFetch, 'set_post_ftux', postFtux);
