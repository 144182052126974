import { all, takeEvery } from 'redux-saga/effects';
import { analytics } from 'common/utilities';
import {
  ANALYTICS_SELECT_ACCOUNT_TYPE,
  ANALYTICS_BANKING_INFO_ACCOUNT_DETAIL,
} from './direct-deposit-account-analytics.types';
import {
  CREATE_DIRECT_DEPOSIT_CONFIRMED,
  CREATE_DIRECT_DEPOSIT_CANCELLED,
} from './direct-deposit-account.types';

export function* directDepositAccountAnalyticsSaga() {
  yield all([
    takeEvery(
      CREATE_DIRECT_DEPOSIT_CONFIRMED,
      analytics.bankingInfo.confirmSubmitAccount,
    ),
    takeEvery(
      CREATE_DIRECT_DEPOSIT_CANCELLED,
      analytics.bankingInfo.cancelSubmitAccount,
    ),
    takeEvery(
      ANALYTICS_SELECT_ACCOUNT_TYPE,
      analytics.bankingInfo.selectAccountType,
    ),
    takeEvery(
      ANALYTICS_BANKING_INFO_ACCOUNT_DETAIL,
      analytics.pageViews.bankingInfo.accountDetail,
    ),
  ]);
}
