import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * @function exportPayrollSchedule calls the backend to create a google sheet of the selected schedule
 * @param {string} payrollScheduleId - id of the schedule to export
 */
export const exportPayrollSchedule = ({ payrollScheduleId }) =>
  call(websocketFetch, 'export_payroll_schedule', {
    payroll_schedule_id: payrollScheduleId,
  });
