import React from 'react';
import {
  fontSizeInPx,
  getThemeValue,
  Box,
  FontAwesomeIcon,
} from '@leagueplatform/ui-kit';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Should this be '@fontawesome'??
import { faClock } from '@fortawesome/free-regular-svg-icons';

const StyledBox = styled(Box)`
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: bold;
  display: inline-block;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledIcon = styled(props => <FontAwesomeIcon {...props} />)`
  margin-top: calc(
    ${getThemeValue('fontSizes.0')}px - ${getThemeValue('fontSizes.1')}px
  );
  font-size: ${fontSizeInPx(1)};
  margin-right: ${getThemeValue('space.genesis.half')}px;
`;

export const StatusLabel = props => {
  const { children, ...rest } = props;
  return (
    <StyledBox
      backgroundColor="offWhite"
      borderRadius={3}
      padding="genesis.threeQuarters"
      color="purpleDarkest"
      fontSize={0}
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...rest}
    >
      <StyledIcon icon={faClock} />
      {children}
    </StyledBox>
  );
};

StatusLabel.propTypes = {
  children: PropTypes.node.isRequired,
};
