import { createLifecycleTypes } from '@leagueplatform/web-common';
import { createRequestTypes } from 'common/websocket-redux';

export const GET_PREMIUMS_REPORT = createRequestTypes(
  'admin/employer-reports/GET_PREMIUMS_REPORT',
);

export const REQUEST_PREMIUMS_REPORT =
  'admin/employer-reports/REQUEST_PREMIUMS_REPORT';

export const PREMIUMS_REPORT_LIFECYCLE = createLifecycleTypes(
  'admin/employer-reports/PREMIUMS_REPORT_LIFECYCLE',
);

export const GET_PREVIOUS_PREMIUMS_REPORTS = createRequestTypes(
  'admin/employer-reports/GET_PREVIOUS_PREMIUMS_REPORTS',
);

export const REQUEST_PREVIOUS_PREMIUMS_REPORT =
  'admin/employer-reports/PREVIOUS_PREMIUMS_REPORTS_REQUESTED';

export const REQUEST_CHANGE_REPORT =
  'admin/employer-reports/REQUEST_CHANGE_REPORT';

export const GET_PAYROLL_SCHEDULES = createRequestTypes(
  'admin/employer-reports/GET_PAYROLL_SCHEDULES',
);

export const GET_PREMIUMS_EXPORT_SPLITS = createRequestTypes(
  'admin/employer-reports/GET_PREMIUMS_EXPORT_SPLITS',
);

export const SET_PAYROLL_SCHEDULES = createRequestTypes(
  'service/SET_PAYROLL_SCHEDULES',
);

export const SHOW_IMPORT_MODAL = 'admin/employer-reports/SHOW_IMPORT_MODAL';

export const SHOW_RENAME_MODAL = 'admin/employer-reports/SHOW_RENAME_MODAL';

export const IMPORT_PAYROLL_SCHEDULE = createRequestTypes(
  'services/IMPORT_PAYROLL_SCHEDULE',
);

export const SHOW_EXPORT_MODAL = 'admin/employer-reports/SHOW_EXPORT_MODAL';
export const EXPORT_PAYROLL_SCHEDULE = createRequestTypes(
  'services/EXPORT_PAYROLL_SCHEDULE',
);
