import { createRequestTypes } from 'common/websocket-redux';

export const RESET_PLAN_DESIGN_REQUEST_STATUS =
  'employer/RESET_PLAN_DESIGN_REQUEST_STATUS';
export const resetPlanDesignRequestStatus = () => ({
  type: RESET_PLAN_DESIGN_REQUEST_STATUS,
});

export const REQUEST_IMPORT_PLAN_DESIGN = 'employer/REQUEST_IMPORT_PLAN_DESIGN';
export const IMPORT_PLAN_DESIGN = createRequestTypes(
  'employer/IMPORT_PLAN_DESIGN',
);
export const importPlanDesign = payload => ({
  type: REQUEST_IMPORT_PLAN_DESIGN,
  payload,
});

export const REQUEST_EXPORT_PLAN_DESIGN = 'employer/REQUEST_EXPORT_PLAN_DESIGN';
export const EXPORT_PLAN_DESIGN = createRequestTypes(
  'employer/EXPORT_PLAN_DESIGN',
);
export const exportPlanDesign = payload => ({
  type: REQUEST_EXPORT_PLAN_DESIGN,
  payload,
});
