import { combineReducers } from 'redux';
import { captureError } from '@leagueplatform/observability';
import {
  ON_INIT_CHAT_WINDOW,
  CREATE_SALESFORCE_CHAT_MAP,
  GET_SALESFORCE_CONFIG,
  GET_AGENT_CONFIG,
  GET_SALESFORCE_LIVEAGENT,
  SET_WAIT_TIME,
  SET_QUEUE_POSITION,
  SET_CHAT_IS_ESTABLISHED,
  SET_CHAT_HAS_FAILED_TO_LOAD,
  OPEN_MODAL,
  CLOSE_MODAL,
} from './chat-window.actions';
import { CHAT_STATES } from './chat-window.constants';

export const chatWindowInitialState = {
  agentType: null,
  chatMap: null,
  chatConfig: null,
  agentConfig: null,
  waitTime: 0,
  queuePosition: 0,
  chatState: CHAT_STATES.INITIAL,
  modal: null,
  chatIframeOrigin: null,
};

const agentType = (state = chatWindowInitialState.agentType, action = {}) => {
  switch (action.type) {
    case ON_INIT_CHAT_WINDOW:
      return action.payload.agentType;
    default:
      return state;
  }
};

const chatMap = (state = chatWindowInitialState.chatMap, action = {}) => {
  switch (action.type) {
    case CREATE_SALESFORCE_CHAT_MAP.SUCCEEDED:
      return action.payload.info;
    default:
      return state;
  }
};

const chatConfig = (state = chatWindowInitialState.chatConfig, action = {}) => {
  switch (action.type) {
    case GET_SALESFORCE_CONFIG.SUCCEEDED:
      return action.payload.info;
    default:
      return state;
  }
};

const agentConfig = (
  state = chatWindowInitialState.agentConfig,
  action = {},
) => {
  switch (action.type) {
    case GET_AGENT_CONFIG.SUCCEEDED:
      return action.payload.info;
    default:
      return state;
  }
};

const waitTime = (state = chatWindowInitialState.waitTime, action = {}) => {
  switch (action.type) {
    case SET_WAIT_TIME:
      return action.payload;
    default:
      return state;
  }
};

const queuePosition = (
  state = chatWindowInitialState.queuePosition,
  action = {},
) => {
  switch (action.type) {
    case SET_QUEUE_POSITION:
      return action.payload;
    default:
      return state;
  }
};

const modal = (state = chatWindowInitialState.modal, action = {}) => {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload;
    case CLOSE_MODAL:
      return chatWindowInitialState.modal;
    default:
      return state;
  }
};

const chatIframeOrigin = (
  state = chatWindowInitialState.chatIframeOrigin,
  action = {},
) => {
  switch (action.type) {
    case SET_CHAT_IS_ESTABLISHED:
      return action.payload.origin;
    default:
      return state;
  }
};

const chatState = (state = chatWindowInitialState.chatState, action = {}) => {
  switch (action.type) {
    case SET_CHAT_IS_ESTABLISHED:
      return CHAT_STATES.ESTABLISHED;
    case CREATE_SALESFORCE_CHAT_MAP.ERRORED:
    case GET_SALESFORCE_CONFIG.ERRORED:
    case GET_AGENT_CONFIG.ERRORED:
    case GET_SALESFORCE_LIVEAGENT.ERRORED:
    case SET_CHAT_HAS_FAILED_TO_LOAD: {
      if (state !== CHAT_STATES.ESTABLISHED) {
        captureError(new Error(`Failed to load chat ${action.type}`));
        return CHAT_STATES.FAILED;
      }
      captureError(
        new Error(
          `Tried to mark chat as failed when it is established ${action.type}`,
        ),
      );

      return state;
    }
    default:
      return state;
  }
};

export const chatWindowReducer = combineReducers({
  agentType,
  chatMap,
  chatConfig,
  agentConfig,
  waitTime,
  queuePosition,
  chatState,
  modal,
  chatIframeOrigin,
});
