import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@leagueplatform/genesis-commons';

const displayName = componentName => `Enrollment.UI.${componentName}`;

export const Card = ({ children, ...props }) => (
  <Box
    className="EnrollmentCard"
    backgroundColor="surface.background.primary"
    borderColor="onSurface.border.subdued"
    padding={['one', 'one', 'one', 'two']}
    borderWidth="thick"
    marginBottom={['one', 'one', 'one', 'two']}
    borderLeftWidth={['none', 'none', 'none', 'thick']}
    borderRightWidth={['none', 'none', 'none', 'thick']}
    borderRadius={['none', 'none', 'none', 'medium']}
    borderStyle="solid"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    {children}
  </Box>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.displayName = displayName('Card');

export const SplitCard = ({ children, ...props }) => (
  <Card
    className="EnrollmentSplitCard"
    display="flex"
    flexDirection="row"
    minHeight={375}
    padding={0}
    marginY="two"
    // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
    {...props}
  >
    {children}
  </Card>
);

SplitCard.propTypes = {
  children: PropTypes.node.isRequired,
};

SplitCard.displayName = displayName('SplitCard');

export const SecondaryCard = ({ children }) => (
  <Box
    className="EnrollmentSecondaryCard"
    paddingX={['two', 'one', 'two']}
    paddingY="one"
    backgroundColor="surface.background.secondary"
    borderWidth="thin"
    borderRadius="medium"
    borderStyle="solid"
    borderColor="onSurface.border.subdued"
  >
    {children}
  </Box>
);

SecondaryCard.propTypes = {
  children: PropTypes.node.isRequired,
};

SecondaryCard.displayName = displayName('SecondaryCard');
