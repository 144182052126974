import {
  ADD_VENDOR_TO_PRODUCT_SYNC,
  FETCH_GROUP_VENDORS,
} from './salesforce-sync.types';

export const fetchGroupVendors = payload => ({
  type: FETCH_GROUP_VENDORS.BASE,
  payload,
});

export const addVendorsToProductSync = payload => ({
  type: ADD_VENDOR_TO_PRODUCT_SYNC.BASE,
  payload,
});
