import { call } from 'redux-saga/effects';
import { websocketFetch } from 'common/websocket-redux';

/**
 * getPremiumsReport requests the premiums export for a group
 * @param {Object} options - payload containing information for:
 * group_id, group id to request report for
 * previous_report_date, optional parameter for change reports
 * plan_status, optional parameter used to get group premiums export
 * report_name, optional parameter to specify split to run for pay period premiums export
 * pay_period, optional parameter to specify period to run for pay period premiums export
 * payroll_schedule_id, optional parameter used to get pay period premiums report
 */
export const getPremiumsReport = options =>
  call(websocketFetch, 'request_report', {
    type: 'payroll_report',
    request: {
      report_name: 'premiums_export',
      ...options,
    },
  });
