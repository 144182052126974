export const STEPS_LIST = 'steps-list';
export const AVAILABLE_BENEFIT_SETS_LIST = 'available-benefit-sets-list';
export const BENEFIT_STEP_TYPE = 'step';
export const BENEFIT_GROUP_TYPE = 'group';
export const BENEFIT_SET_TYPE = 'set';

export const TEMPLATE_GROUP = 'group';
export const TEMPLATE_CLASS = 'class';
export const TEMPLATE_SET = 'set';

export const DROPPABLE_TYPES = {
  STEP: BENEFIT_STEP_TYPE,
  GROUP: BENEFIT_GROUP_TYPE,
};
