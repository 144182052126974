/* eslint-disable camelcase -- FIXME: automatically added for existing issue */
import { concat, isEmpty, cloneDeep, get } from 'lodash';
import camelize from 'camelize';
import {
  GET_USERS_IN_GROUP,
  TERMINATE_EMPLOYEES,
  SUSPEND_EMPLOYEES,
  REINSTATE_EMPLOYEES,
  DO_TERMINATE_EMPLOYEES,
  DO_SUSPEND_EMPLOYEES,
  DO_REINSTATE_EMPLOYEES,
  DO_GET_TERMINATION_CODES,
  RESET_EMPLOYEES_STATE,
} from './types';

export const initialState = {
  loading: false,
  error: false,
  employees: [],
  query: '',
  offset: 0,
  total: 0,
  hasMoreResults: true,
  employeesServiceStatus: null,
  updatingEmployees: null,
  terminationCodes: {
    error: false,
    codes: [],
  },
  include_suspended: true,
  admin_role: false,
};

const handleEmptyResults = (state, action) => ({
  ...state,
  employees: [],
  loading: false,
  hasMoreResults: false,
  total: 0,
  query: action.meta.query,
  offset: action.meta.offset,
  include_suspended: action.meta.include_suspended,
  admin_role: action.meta.admin_role,
});

const handleResults = (state, action) => {
  const { query, offset, limit, include_suspended, admin_role } = action.meta;

  const availableResults = action.payload.info.filtered_total;
  const remainingResults = availableResults - (offset + limit);
  const hasMoreResults = remainingResults > 0;

  const users = camelize(action.payload.info.users);

  const isNewQuery = query !== state.query || offset === 0;
  const employees = isNewQuery ? users : concat(state.employees, users);

  return {
    ...state,
    loading: false,
    error: false,
    employees,
    query,
    offset,
    hasMoreResults,
    total: availableResults,
    include_suspended,
    admin_role,
  };
};

const updateEmployees = (state, action) =>
  isEmpty(action.payload.info.users)
    ? handleEmptyResults(state, action)
    : handleResults(state, action);

const updateEmployeesSucceeded = state => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    updatingEmployees: null,
    employeesServiceStatus: {
      error: false,
      reason: 'Employees Successfully Updated',
    },
  };
};

const updateEmployeesErrored = (state, action) => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    updatingEmployees: null,
    employeesServiceStatus: {
      error: true,
      reason: get(
        action,
        action.payload.info.reason,
        'Oops, something went wrong!',
      ),
    },
  };
};

const updateTerminationCodes = (state, { payload }) => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    terminationCodes: {
      error: false,
      codes: payload.info.suspension_reason_options,
    },
  };
};

const updateTerminationCodesError = (state, { payload }) => {
  const newState = cloneDeep(state);
  return {
    ...newState,
    terminationCodes: {
      codes: [],
      error: true,
      reason: payload.info,
    },
  };
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const employeesReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_USERS_IN_GROUP.STARTED:
      return { ...state, loading: true };
    case GET_USERS_IN_GROUP.ERRORED:
      return { ...state, loading: false, error: true };
    case GET_USERS_IN_GROUP.SUCCEEDED:
      return updateEmployees(state, action);

    case DO_GET_TERMINATION_CODES.STARTED:
      return { ...state, terminationCodes: initialState.terminationCodes };
    case DO_GET_TERMINATION_CODES.ERRORED:
      return updateTerminationCodesError(state, action);
    case DO_GET_TERMINATION_CODES.SUCCEEDED:
      return updateTerminationCodes(state, action);
    case TERMINATE_EMPLOYEES:
      return {
        ...state,
        updatingEmployees: {
          type: action.type,
          payload: action.payload,
        },
      };
    case DO_TERMINATE_EMPLOYEES.SUCCEEDED:
      return updateEmployeesSucceeded(state);
    case DO_TERMINATE_EMPLOYEES.ERRORED:
      return updateEmployeesErrored(state, action);
    case SUSPEND_EMPLOYEES:
      return {
        ...state,
        updatingEmployees: {
          type: action.type,
          payload: action.payload,
        },
      };
    case DO_SUSPEND_EMPLOYEES.SUCCEEDED:
      return updateEmployeesSucceeded(state);
    case DO_SUSPEND_EMPLOYEES.ERRORED:
      return updateEmployeesErrored(state, action);
    case REINSTATE_EMPLOYEES:
      return {
        ...state,
        updatingEmployees: {
          type: action.type,
          payload: action.payload,
        },
      };
    case DO_REINSTATE_EMPLOYEES.SUCCEEDED:
      return updateEmployeesSucceeded(state);
    case DO_REINSTATE_EMPLOYEES.ERRORED:
      return updateEmployeesErrored(state, action);
    case RESET_EMPLOYEES_STATE:
      return initialState;
    default:
      return state;
  }
};
