import { escapeRegExp, filter } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlShape } from '@leagueplatform/locales';
import { Dropdown, Header, Container } from 'semantic-ui-react';
import onClickOutside from 'react-onclickoutside';

const CatalogueResult = ({ title, benefitType, benefitVendorName }) => (
  <Header as="h2" className="header--catalogue-result">
    {benefitVendorName} {title}{' '}
    {benefitType.includes('generic') ? '(Generic)' : ''}
    {/* Note: '(Generic)' is temporary until the backend work for Generic CAD benefit is done */}
    <Header.Subheader>{benefitType}</Header.Subheader>
  </Header>
);

CatalogueResult.propTypes = {
  title: PropTypes.string.isRequired,
  benefitType: PropTypes.string.isRequired,
  benefitVendorName: PropTypes.string,
};

CatalogueResult.defaultProps = {
  benefitVendorName: '',
};

export class SearchCataloguePresenter extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      // eslint-disable-next-line react/no-unused-state -- FIXME: automatically added for existing issue
      value: '',
      options: props.catalogueOptions.map(option => ({
        key: option.benefitType,
        text: option.fullName,
        value: option.benefitType,
        content: (
          <CatalogueResult
            title={option.fullName}
            benefitType={option.benefitType}
            benefitVendorName={option.benefitVendorName}
          />
        ),
      })),
    };
  }

  componentDidMount() {
    /**
     * In search mode Semantic UI React Dropdown uses
     * the first option as the placeholder.
     * We find this is confusing to the user and therefore,
     * are overriding it manually on component mount.
     */
    this.dropdown.ref.querySelector('.default.text').innerText =
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      this.props.intl.formatMessage({
        id: 'SEARCH_BENEFITS_PLACEHOLDER',
      });
  }

  // eslint-disable-next-line react/sort-comp -- FIXME: automatically added for existing issue
  onChange(e, { value }) {
    // eslint-disable-next-line react/no-unused-state -- FIXME: automatically added for existing issue
    this.setState({ value });
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.onChange({ product: this.props.catalogue[value] });
  }

  handleSearch(results, input) {
    const re = new RegExp(escapeRegExp(input), 'i');
    const isMatch = result =>
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      re.test(this.props.catalogue[result.key].fullName) ||
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      re.test(this.props.catalogue[result.key].description) ||
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      re.test(this.props.catalogue[result.key].benefitVendorName) ||
      // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
      re.test(this.props.catalogue[result.key].benefitProductType);
    return filter(results, isMatch);
  }

  handleClickOutside() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.shouldShowCatalogue(false);
  }

  render() {
    const { intl } = this.props;
    const { options } = this.state;
    return (
      <Container className="container--search-catalogue">
        <Dropdown
          ref={el => {
            this.dropdown = el;
          }}
          defaultOpen
          closeOnChange
          closeOnBlur
          selectOnNavigation={false}
          // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
          onClose={() => this.props.shouldShowCatalogue(false)}
          className="dropdown--search-catalogue"
          selection
          onChange={this.onChange}
          fluid
          search={this.handleSearch}
          options={options}
          placeholder={intl.formatMessage({
            id: 'SEARCH_BENEFITS_PLACEHOLDER',
          })}
        />
      </Container>
    );
  }
}

SearchCataloguePresenter.propTypes = {
  intl: intlShape.isRequired,
  catalogue: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  catalogueOptions: PropTypes.arrayOf(
    PropTypes.shape({
      productType: PropTypes.string,
      description: PropTypes.string,
      fullName: PropTypes.string,
      benefitVendorName: PropTypes.string,
    }),
  ).isRequired,
  shouldShowCatalogue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const SearchCatalogue = injectIntl(
  onClickOutside(SearchCataloguePresenter),
);
