import { createLifecycleTypes } from '@leagueplatform/web-common';

export const REACT_HOST_LIFECYCLE = createLifecycleTypes(
  'member/react-host/LIFECYCLE',
);

export const UNHANDLED_MESSAGE_TYPE_POSTED_TO_WR =
  'member/react-host/UNHANDLED_MESSAGE_TYPE_POSTED_TO_WR';

export const GO_TO_URL_POSTED_TO_WR =
  'member/react-host/GO_TO_URL_POSTED_TO_WR';

/**
 * This message type can safely be ignored when migrating a page off
 * WidgetRunner because it is used to update the WidgetRunner window's url
 * without creating a history entry (history.replace). It is always paired
 * with a `history.push` for the react frame, so when we get rid of
 * WidgetRunner it's a no-op see https://everlong.atlassian.net/browse/WEBPLAT-384
 */
export const IGNORED_UPDATE_URL_POSTED_TO_WR =
  'member/react-host/IGNORED_UPDATE_URL_POSTED_TO_WR';

const mapTypeToAction = type => {
  if (typeof type === 'undefined')
    throw new Error('type missing in postToWR message');

  switch (type) {
    case 'goToUrl':
      return GO_TO_URL_POSTED_TO_WR;
    case 'updateUrl':
      return IGNORED_UPDATE_URL_POSTED_TO_WR;
    default:
      return UNHANDLED_MESSAGE_TYPE_POSTED_TO_WR;
  }
};

export const postToWRMessageReceived = messageEvent => {
  const { type, payload } = messageEvent?.data ?? {};

  return {
    type: mapTypeToAction(type),
    payload: { postToWRType: type, ...payload },
  };
};
