import { selectApp as selectMemberApp } from 'apps/member-experience/state/selectors';
import { createSelector } from 'reselect';
import { prop } from 'lodash/fp';
import { get } from 'lodash';
import {
  mapSummarySetsToSteps,
  selectSummaryBenefitSets,
  selectSummaryBenefitSteps,
  selectShowCostsPerPayPeriod,
  selectEmployerPaysAllCosts,
  selectCoverageSummaryByPlanId,
  selectShowEmployerCosts,
  selectEmployerContribution,
} from 'common/coverage-summary';
import { selectFeatureFlags } from 'common/state/featureFlags/feature-flags.selectors';
import { selectEmployeeProfile } from '../../selectors';
import {
  getPeriodTypeTranslation,
  DEFAULT_PERIOD_TYPE,
} from 'common/benefits/metadata';
import { isKPMG } from 'apps/utils/employer-utils';
import {
  selectGroupCurrency,
  selectEmployerName as selectGroupName,
  selectGroupCountryCode,
} from 'apps/employer-experience/pages/EmployerDetails/selectors';
import { IN_ENROLLMENT, ENROLLMENT_CLOSED } from './submitted-status.view';
import { US } from 'common/constants';

export const selectEmployerName = createSelector(
  selectMemberApp,
  selectGroupName,
  (memberApp, employerAppEmployerName) =>
    get(memberApp, 'info.group.group.employer.company_name') ||
    employerAppEmployerName,
);

export const selectBenefitClasses = createSelector(
  selectMemberApp,
  prop('info.group.benefit_classes'),
);

export const selectCurrency = createSelector(
  selectMemberApp,
  selectGroupCurrency,
  (memberApp, employerAppGroupCurrency) =>
    get(memberApp, 'info.group.group.currency') || employerAppGroupCurrency,
);

export const selectCountryCode = createSelector(
  selectMemberApp,
  selectGroupCountryCode,
  (member, groupCountryCode) =>
    groupCountryCode ?? member?.info?.group?.countryCode,
);

export const selectEnrollmentStatus = createSelector(
  selectCoverageSummaryByPlanId,
  summary =>
    summary?.enrollmentEndDate &&
    new Date(summary.enrollmentEndDate) > new Date()
      ? IN_ENROLLMENT
      : ENROLLMENT_CLOSED,
);

export const selectLastSubmittedTime = createSelector(
  selectCoverageSummaryByPlanId,
  summary => summary?.lastSubmittedTime,
);

export const selectUserPayGroup = createSelector(
  selectEmployeeProfile,
  profile => get(profile, 'pay_group') || DEFAULT_PERIOD_TYPE,
);

export const selectUserPayGroupPeriodTypeId = createSelector(
  selectUserPayGroup,
  getPeriodTypeTranslation,
);

export const selectCoverageSummarySteps = createSelector(
  selectSummaryBenefitSets,
  selectCurrency,
  selectShowCostsPerPayPeriod,
  selectUserPayGroupPeriodTypeId,
  selectSummaryBenefitSteps,
  mapSummarySetsToSteps,
);

export const selectFlexDollarTermId = createSelector(
  selectEmployerName,
  employerName => (isKPMG(employerName) ? 'WELLNESS_POOL' : 'FLEXIBLE_DOLLARS'),
);

export const selectShowTotalBreakdown = createSelector(
  selectCountryCode,
  selectEmployerPaysAllCosts,
  (countryCode, employerPaysAllCosts) =>
    countryCode !== US && !employerPaysAllCosts,
);

export const selectShowEmployerCostBanner = createSelector(
  selectShowEmployerCosts,
  selectEmployerContribution,
  (showEmployerCosts, employerContribution) => {
    const hasEmployerCostAmount =
      !!employerContribution?.F || employerContribution?.F === 0;
    return showEmployerCosts && hasEmployerCostAmount;
  },
);

export const selectShowBenefitSelectionsToolLink = createSelector(
  selectFeatureFlags,
  featureFlags => featureFlags?.benefitSelectionsTool ?? false,
);
