import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SubtitleOne } from '@leagueplatform/genesis-commons';

import { useIntl } from '@leagueplatform/locales';
import { spaceInPx } from '@leagueplatform/ui-kit';

// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { PendingFeeRow, RemoveFeeModal } from '..';

const StyledTable = styled.table`
  width: 100%;
  text-align: left;

  td {
    padding: ${spaceInPx('genesis.half')} 0;
  }
`;

export const PendingFeesTable = ({ records, removeFee, billingDivisions }) => {
  const { formatMessage } = useIntl();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [feeIdToRemove, setFeeIdToRemove] = useState(false);

  const handleRemove = id => {
    setFeeIdToRemove(id);
    setShowRemoveModal(true);
  };

  const handleSubmitRemove = () => {
    removeFee(feeIdToRemove);
    setShowRemoveModal(false);
  };

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>
              <SubtitleOne>{formatMessage({ id: 'DATE' })}</SubtitleOne>
            </th>
            <th>
              <SubtitleOne>{formatMessage({ id: 'FEE_TYPE' })}</SubtitleOne>
            </th>
            <th>
              <SubtitleOne>{formatMessage({ id: 'FEE_RATE' })}</SubtitleOne>
            </th>
            <th>
              <SubtitleOne>
                {formatMessage({ id: 'PENDING_FEE_UNITS' })}
              </SubtitleOne>
            </th>
            <th width="400px">
              <SubtitleOne>{formatMessage({ id: 'TERM' })}</SubtitleOne>
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map(record => {
            return (
              <PendingFeeRow
                record={record}
                removeFee={handleRemove}
                billingDivisions={billingDivisions}
                key={record.id}
              />
            );
          })}
        </tbody>
      </StyledTable>
      {showRemoveModal && (
        <RemoveFeeModal
          onClose={() => setShowRemoveModal(false)}
          onSubmit={handleSubmitRemove}
        />
      )}
    </>
  );
};

PendingFeesTable.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)).isRequired,
  billingDivisions: PropTypes.arrayOf(PropTypes.shape(PropTypes.any))
    .isRequired,
  removeFee: PropTypes.func.isRequired,
};

export default PendingFeesTable;
