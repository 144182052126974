import React, { useState, useEffect } from 'react';
import { spaceInPx, Text, Select } from '@leagueplatform/ui-kit';
import { Button } from 'common/components/button/button.view';
import { Flex } from 'common/components/primitives.view';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QLEConfigurationCard from '../qle-configuration-card/qle-configuration-card.container';
import { useIntl } from '@leagueplatform/locales';
import { ConfigurationToggle } from './configuration-toggle.view';
import { EnterDaysLimit } from './enter-days-limit.view';
import { HREmailInput } from './hr-email-input.view';
import { Elections } from './elections.view';
import { TOGGLE, RESET } from '../qle-configuration.constants';
import { ErrorMessage } from './error-message.view';
import { Blurb, RedAsterisk, SectionHeading } from './shared.view';
import { QLE_MASTER_LIST } from '../qle-master-list';
import { ToggleQLEState } from './toggle-qle-state.view';
import { StatusPill } from '../qle-configuration-table/qle-configuration-table.view';
import {
  QLE_DATE,
  FIRST_OF_FOLLOWING_MONTH,
} from './coverage-effective-date-policy.constants';

const BenefitClassType = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.6px;
`;

export const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 16px;
  width: 140px;
  height: 48px;

  > div {
    margin-right: 16px;
  }
`;

const CancelButton = styled(StyledButton)`
  margin-right: ${spaceInPx(1)};
`;

const StyledFlex = styled(Flex)`
  align-items: center;
  > div {
    margin-left: 16px;
  }
`;

export const QLEConfigurationForm = ({
  match,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleSubmit,
  setQLETypeAndId,
  groupId,
  benefitClass,
  qleConfiguration,
  qleConfigurationId: connectedQLEConfigurationId,
  handleReset,
  cancelQLEConfiguration,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const { formatMessage } = useIntl();
  const {
    params: { qleConfigurationId, qleType },
  } = match;

  useEffect(() => {
    setQLETypeAndId({ qleType, qleConfigurationId });
  }, [qleType, qleConfigurationId, setQLETypeAndId]);

  const {
    typeformUrl,
    allowToAddDependents,
    allowToRemoveDependents,
    allowNex,
    enableTypeform,
    enableManageDependents,
    suspended,
  } = values;

  useEffect(() => {
    if (typeformUrl.length > 0 && !allowNex && !enableTypeform) {
      setFieldValue('enableTypeform', true);
    }
    if (
      (allowToAddDependents || allowToRemoveDependents) &&
      !enableManageDependents
    ) {
      setFieldValue('enableManageDependents', true);
    }
    if (allowNex && enableTypeform && enableManageDependents) {
      setFieldValue('enableTypeform', false);
      setFieldValue('enableManageDependents', false);
      setFieldValue('allowToAddDependents', false);
      setFieldValue('allowToRemoveDependents', false);
      setFieldValue('typeformUrl', '');
    }
  }, [
    typeformUrl,
    allowToAddDependents,
    allowToRemoveDependents,
    setFieldValue,
    allowNex,
    enableTypeform,
    enableManageDependents,
  ]);

  if (!benefitClass) {
    return null;
  }

  const toggleAndClearDaysAfterQLEEventDate = value => {
    const result = value === TOGGLE.SHOULD_DISPLAY;
    setFieldValue('allowDaysAfterQleEventDate', result);
    if (!result) {
      setFieldValue('daysAfterQleEventDate', RESET.DAYS_AFTER_QLE_EVENT_DATE);
    }
  };

  const toggleAndClearDaysBeforeQLEEventDate = value => {
    const result = value === TOGGLE.SHOULD_DISPLAY;
    setFieldValue('allowDaysBeforeQleEventDate', result);
    if (!result) {
      setFieldValue('daysBeforeQleEventDate', RESET.DAYS_BEFORE_QLE_EVENT_DATE);
    }
  };

  /**
   * toggleHrApproval: Whether true to false, do not update the HR emails!
   * This is because some configurations require HR emails and some do not
   * @param {boolean} value
   */
  const toggleHrApproval = value => {
    const result = value === TOGGLE.SHOULD_DISPLAY;
    setFieldValue('needsHrApproval', result);
  };

  /**
   * This part is different from the rest of the form. If allow NEX is true,
   * Then we don't allow users to configure the typeformURL and/or manage dependents
   * so we need to hide the Elections component
   */
  const toggleAllowNex = value => {
    const result = value === TOGGLE.SHOULD_HIDE;
    setFieldValue('allowNex', result);
  };

  /**
   * https://github.com/jaredpalmer/formik/issues/213
   * "submitted" used in the HR email validation
   */
  const submit = e => {
    setSubmitted(true);
    handleSubmit(e);
  };

  const cancel = e => {
    handleReset(e);
    cancelQLEConfiguration({
      groupId,
      benefitClassId: benefitClass.id,
    });
  };

  /**
   * Note: we don't have an actual value coming from the backend
   * stating QLE has ever been configured, table is currently
   * only showing the QLEs that have been configured (according to Nic)
   */
  return (
    <QLEConfigurationCard
      paddingAroundContent
      rightSide={
        /**
          We get the QLE config id from the url, unless it's a newly
          configured qle, in which case we get it from the redux state
         */
        qleConfigurationId || connectedQLEConfigurationId ? (
          <ToggleQLEState
            qleConfigurationId={
              qleConfigurationId || connectedQLEConfigurationId
            }
            values={values}
            setFieldValue={setFieldValue}
            qleConfiguration={qleConfiguration}
          />
        ) : null
      }
    >
      <form>
        <Flex mt={3} mb={4} flexDirection="column">
          <BenefitClassType
            fontWeight="bold"
            fontSize={1}
            mt={3}
            color="greyDark"
          >
            {benefitClass.name}
          </BenefitClassType>
          <StyledFlex flexDirection="row">
            <Text fontSize={4} color="purpleText" fontWeight="bold">
              {formatMessage({ id: QLE_MASTER_LIST[match.params.qleType] })}
            </Text>
            {(qleConfigurationId || connectedQLEConfigurationId) && (
              <StatusPill suspended={values.suspended} />
            )}
          </StyledFlex>
        </Flex>
        <Text fontSize={1}>
          <RedAsterisk>*</RedAsterisk>
          {formatMessage({ id: 'REQUIRED_FIELDS' })}
        </Text>
        <Text fontSize={2} fontWeight="bold" color="purpleText">
          {formatMessage({ id: 'QLE_REPORTING_WINDOW' })}
        </Text>
        <Blurb>
          <Text fontSize={2} color="greyDark">
            {formatMessage({ id: 'WHEN_A_MEMBER_REPORTS_A_QLE_TO_LEAGUE' })}
          </Text>
        </Blurb>
        <ConfigurationToggle
          disabled={suspended}
          id="allowDaysAfterQleEventDate"
          title={formatMessage(
            { id: 'ALLOW_MEMBERS_TO_REPORT_THE_QLE_EVENT_DATE' },
            { followOrPrecede: formatMessage({ id: 'FOLLOWING' }) },
          )}
          tooltip={formatMessage({ id: 'ALLOW_FOLLOWING_QLE_DATE_TOOLTIP' })}
          firstOption={formatMessage({ id: 'NO' })}
          secondOption={formatMessage({ id: 'YES' })}
          handleChange={toggleAndClearDaysAfterQLEEventDate}
          // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
          toggleChecked={values.allowDaysAfterQleEventDate}
          renderIfToggleOn={
            <EnterDaysLimit
              after
              name="daysAfterQleEventDate"
              value={values.daysAfterQleEventDate}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              disabled={suspended}
            />
          }
        />
        <ConfigurationToggle
          disabled={suspended}
          id="allowDaysBeforeQleEventDate"
          title={formatMessage(
            { id: 'ALLOW_MEMBERS_TO_REPORT_THE_QLE_EVENT_DATE' },
            { followOrPrecede: formatMessage({ id: 'PRECEDING' }) },
          )}
          tooltip={formatMessage({ id: 'ALLOW_PRECEDING_QLE_DATE_TOOLTIP' })}
          firstOption={formatMessage({ id: 'NO' })}
          secondOption={formatMessage({ id: 'YES' })}
          handleChange={toggleAndClearDaysBeforeQLEEventDate}
          // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
          toggleChecked={values.allowDaysBeforeQleEventDate}
          renderIfToggleOn={
            <EnterDaysLimit
              before
              name="daysBeforeQleEventDate"
              value={values.daysBeforeQleEventDate}
              handleChange={handleChange}
              disabled={suspended}
              setFieldValue={setFieldValue}
            />
          }
        />
        <SectionHeading
          title={formatMessage({ id: 'BENEFIT_EFFECT_DATE' })}
          tooltipText={formatMessage({ id: 'BENEFIT_EFFECT_DATE_TOOLTIP' })}
        />
        <Select
          disabled={suspended}
          options={[
            { text: formatMessage({ id: 'QLE_DATE' }), value: QLE_DATE },
            {
              text: formatMessage({ id: 'FIRST_OF_FOLLOWING_MONTH' }),
              value: FIRST_OF_FOLLOWING_MONTH,
            },
          ]}
          onChange={e =>
            e.altered && setFieldValue('coverageEffectiveDatePolicy', e.value)
          }
          // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
          selectedValue={values.coverageEffectiveDatePolicy}
        />
        {/* eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue */}
        {errors.daysBeforeOrAfter &&
          // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
          (touched.daysBeforeOrAfter || touched.daysBeforeOrAfter) && (
            // eslint-disable-next-line react/prop-types -- FIXME: automatically added for existing issue
            <ErrorMessage>{errors.daysBeforeOrAfter}</ErrorMessage>
          )}
        <ConfigurationToggle
          id="needsHrApproval"
          title={formatMessage({ id: 'REQUIRES_APPROVAL_FROM_HR' })}
          firstOption={formatMessage({ id: 'NO' })}
          secondOption={formatMessage({ id: 'YES' })}
          handleChange={toggleHrApproval}
          disabled={suspended}
          tooltip={formatMessage({ id: 'QLE_REQUIRES_HR_TOOLTIP' })}
          toggleChecked={values.needsHrApproval}
          renderIfToggleOn={
            <HREmailInput
              name="qleApprovalEmails"
              value={values.qleApprovalEmails}
              handleChange={handleChange}
              errors={errors}
              submitted={submitted}
              disabled={suspended}
            />
          }
        />
        <ConfigurationToggle
          id="allowNex"
          title={formatMessage({ id: 'WHERE_SHOULD_MEMBERS_BE_DIRECTED' })}
          firstOption={formatMessage({
            id: 'SEND_MEMBERS_TO_ENROLLMENT_IN_NEX_FULL_QLE',
          })}
          secondOption={formatMessage({
            id: 'SEND_MEMBERS_TO_MANAGE_DEPENDENTS_OR_TYPEFORM',
          })}
          handleChange={toggleAllowNex}
          disabled={suspended}
          tooltip={formatMessage({ id: 'WHERE_TO_DIRECT_MEMBERS_TOOLTIP' })}
          renderIfToggleOn={
            <Elections
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              disabled={suspended}
            />
          }
          toggleChecked={!values.allowNex}
        />
        {errors.noNexCustomSet && submitted && (
          <ErrorMessage>{errors.noNexCustomSet}</ErrorMessage>
        )}
        <Flex justify="flex-end">
          <CancelButton secondary onClick={cancel}>
            {formatMessage({ id: 'BACK' })}
          </CancelButton>
          <StyledButton primary type="submit" onClick={submit}>
            {formatMessage({ id: 'SUBMIT' })}
          </StyledButton>
        </Flex>
      </form>
    </QLEConfigurationCard>
  );
};

export const FormValuesProps = PropTypes.shape({
  qualifyingLifeEventType: PropTypes.string,
  daysAfterQleEventDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  daysBeforeQleEventDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  needsHrApproval: PropTypes.bool,
  qleApprovalEmails: PropTypes.string,
  allowToAddDependents: PropTypes.bool,
  allowToRemoveDependents: PropTypes.bool,
  allowNex: PropTypes.bool,
  typeformUrl: PropTypes.string,
  suspended: PropTypes.bool,
  createdAt: PropTypes.string,
  daysInPastAndFuture: PropTypes.bool,
  noNexCustomSet: PropTypes.bool,
  allowDaysInPast: PropTypes.bool,
  allowDaysInFuture: PropTypes.bool,
  enableManageDependents: PropTypes.bool,
  enableTypeform: PropTypes.bool,
});

const FormErrorsProps = PropTypes.shape({
  daysInPastAndFuture: PropTypes.string,
  qleApprovalEmails: PropTypes.string,
  noNexCustomSet: PropTypes.string,
});

const FormTouchedProps = PropTypes.shape({
  qualifyingLifeEventType: PropTypes.bool,
  daysBeforeQleEventDate: PropTypes.bool,
  daysAfterQleEventDate: PropTypes.bool,
  needsHrApproval: PropTypes.bool,
  allowToAddDependents: PropTypes.bool,
  allowToRemoveDependents: PropTypes.bool,
  allowNex: PropTypes.bool,
  typeformUrl: PropTypes.bool,
  suspended: PropTypes.bool,
});

QLEConfigurationForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      qleConfigurationId: PropTypes.string,
      qleType: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  values: FormValuesProps.isRequired,
  errors: FormErrorsProps.isRequired,
  touched: FormTouchedProps.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setQLETypeAndId: PropTypes.func.isRequired,
  qleConfiguration: PropTypes.shape({}),
  groupId: PropTypes.string.isRequired,
  benefitClass: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  qleConfigurationId: PropTypes.string,
  handleReset: PropTypes.func.isRequired,
  cancelQLEConfiguration: PropTypes.func.isRequired,
};

QLEConfigurationForm.defaultProps = {
  location: {
    pathname: null,
  },
  qleConfiguration: {},
  benefitClass: null,
  qleConfigurationId: null,
};
