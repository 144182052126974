import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NoMatch } from 'apps/public/public-routes.view';
import {
  getVerificationPath,
  getInviteVerificationPath,
  getAccountActivePath,
} from './invite-access-paths';
import { EligibilityContainer } from './components/eligibility/eligibility.container';
import {
  InviteExpired,
  InviteNotVerified,
  VerifyAccountContainer,
} from './components/verify-account';
import { AccountAlreadyActiveContainer } from './components/account-already-active/account-already-active.container';

export const InviteAccessRouter = () => {
  return (
    <Switch>
      <Route exact path="/eligibility/:employerIdentifier">
        <EligibilityContainer />
      </Route>
      <Route exact path={getVerificationPath(':employerIdentifier')}>
        <VerifyAccountContainer />
      </Route>
      <Route
        exact
        path={getInviteVerificationPath(':employerIdentifier', ':invitationId')}
      >
        <VerifyAccountContainer />
      </Route>
      <Route
        exact
        path="/verify/:employerIdentifier/invite/:invitationId/not-verified"
      >
        <InviteNotVerified />
      </Route>
      <Route
        exact
        path="/verify/:employerIdentifier/invite/:invitationId/expired"
      >
        <InviteExpired />
      </Route>
      <Route exact path={getAccountActivePath(':employerIdentifier')}>
        <AccountAlreadyActiveContainer />
      </Route>
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  );
};
