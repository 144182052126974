export default {
  employeeGwlDrugs: {
    name: 'employeeGwlDrugs',
    groups: [
      {
        title: 'Member Policy Details',
        fields: [
          {
            name: 'policy_id',
            span: 6,
          },
          {
            name: 'benefit_activation_date',
            span: 6,
          },
        ],
      },
      {
        title: 'Employer Policy Details',
        fields: [
          {
            name: 'parent.benefit_start_date',
            span: 6,
          },
          {
            name: 'parent.benefit_end_date',
            span: 6,
          },
          {
            name: 'parent.policy_id',
            span: 6,
          },
          {
            name: 'parent.documents',
            span: 12,
          },
        ],
      },
      {
        title: 'Benefit Setup',
        fields: [
          {
            name: 'parent.prevent_default_allocation',
            span: 4,
          },
          {
            name: 'parent.opt_out',
            span: 4,
          },
          {
            name: 'parent.benefit_waiting_period_months',
            span: 4,
          },
        ],
      },
      {
        title: 'Benefit Expiry',
        fields: [
          {
            name: 'parent.benefit_available_grace_period_days',
            span: 6,
          },
          {
            name: 'parent.auto_renew',
            span: 6,
          },
        ],
      },
      {
        title: 'Description of Coverage',
        fields: [
          {
            name: 'parent.benefit_year',
            span: 6,
          },
          {
            name: 'parent.reimbursement_percent',
            span: 6,
          },
          {
            name: 'parent.drug_reimbursement_percent',
            span: 6,
          },
          {
            name: 'parent.drug_reimbursement_percent_costco',
            span: 6,
          },
          {
            name: 'parent.dispensing_fee_cap_amount',
            span: 6,
          },
          {
            name: 'parent.drug_maximum_amount',
            span: 6,
          },
          {
            name: 'parent.drug_maximum_applies',
            span: 6,
          },
          {
            name: 'parent.drug_plan_type',
            span: 6,
          },
          {
            name: 'parent.erectile_dysfunction_included',
            span: 6,
          },
          {
            name: 'parent.fertility_drug_maximum',
            span: 6,
          },
          {
            name: 'parent.formulary',
            span: 6,
          },
          {
            name: 'parent.out_of_pocket_maximum_overall',
            span: 6,
          },
          {
            name: 'parent.out_of_pocket_maximum_per_prescription',
            span: 6,
          },
          {
            name: 'parent.per_prescription_deductible',
            span: 6,
          },
          {
            name: 'parent.per_prescription_deductible_equals_dispensing_fee',
            span: 6,
          },
          {
            name: 'parent.preventative_vaccines_included',
            span: 6,
          },
          {
            name: 'parent.smoking_cessation_maximum',
            span: 6,
          },
        ],
      },
      {
        title: 'Internal Fields',
        fields: [
          {
            name: 'user_id',
            span: 6,
          },
          {
            name: 'id',
            span: 6,
          },
          {
            name: 'grace_period_start_date',
            span: 6,
          },
          {
            name: 'parent.provider_id',
            span: 6,
          },
          {
            name: 'parent.id',
            span: 6,
          },
          {
            name: 'parent.benefit_status',
            span: 6,
          },
          {
            name: 'parent.benefit_vendor_id',
            span: 6,
          },
          {
            name: 'parent.benefit_type',
            span: 6,
          },
          {
            name: 'parent.benefit_product_type',
            span: 6,
          },
          {
            name: 'parent.carrier_id',
            span: 6,
          },
          {
            name: 'parent.provider_type',
            span: 6,
          },
          {
            name: 'parent.plan_id',
            span: 6,
          },
          {
            name: 'inactive_date',
            span: 6,
          },

          {
            name: 'reinstated_date',
            span: 6,
          },
          {
            name: 'parent.renewed_from',
            span: 6,
          },
          {
            name: 'dependent_coverage',
            span: 6,
          },
          {
            name: 'parent.dependent_classes',
            span: 12,
          },
        ],
      },
    ],
  },
};
