import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NoMatch, Onboarding } from 'apps/public/public-routes.view';
import { AchievementsRoutes } from '@leagueplatform/rewards-achievements';
import {
  HealthNeedPages,
  CareCollectionsRoutes,
  useIsCareCollectionsEnabled,
} from '@leagueplatform/care-collections';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import { HealthJourneyRoutes } from '@leagueplatform/health-journey';
import { ONBOARDING_ENABLED } from '@leagueplatform/onboarding';
import { ACHIEVEMENTS_ENABLED } from '@leagueplatform/health-journey-common';
import { WalletRoutes } from '@leagueplatform/wallet';
import { AssessmentRoutes } from '@leagueplatform/assessment';
import { useIsAboutMeEnabled, AboutMeRoutes } from '@leagueplatform/about-me';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  useIsCareDiscoveryEnabled,
  CareDiscoveryRoutes,
} from '@leagueplatform/care-discovery';
import {
  RewardsEventController,
  REWARDS_EVENTS_UPDATES,
} from '@leagueplatform/rewards-events';
import { LoadingIndicator } from '@leagueplatform/web-common-components';

const AsyncHealthProfile = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/health-profile-container'
  ),
);

const AsyncMemberApp = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/components/AppMember/app-member.container'
  ),
);

const HomeFeed = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/HomeFeed'
  ),
);

const ParentCategoryPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Solutions/ParentCategoryPage'
  ),
);

const ClaimHistoryPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/claims/claim-history'
  ),
);

const ClaimDetailsPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/claims/claim-details'
  ),
);

const NotificationsPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/notifications/notifications.hoc'
  ),
);

const RewardsPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Rewards/rewards-page.container'
  ),
);

const UserProfilePage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/UserProfile/profile-page.container'
  ),
);

const ArticlesPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/articles/articles.container'
  ),
);

const TermsPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Terms/terms.view'
  ),
);

export const SubmitClaimPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/claims/submit-claim/submit-claim.container'
  ),
);

export const LifemarketReceiptPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/wallet/receipt/receipt.container'
  ),
);

export const BenefitPreviewPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/wallet/benefit-preview'
  ),
);

const StandaloneCoveragePage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/standalone-coverage/standalone-coverage.hoc'
  ),
);

const MyDocumentsPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/MyDocuments/my-documents.hoc'
  ),
);

const BeneficiariesPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/beneficiaries/beneficiaries.container'
  ),
);

const CoverageSummaryPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/coverage-summary/coverage-summary.container'
  ),
);

const BankingInfoPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/banking-info/banking-info.container'
  ),
);

const QleScreener = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/QleScreener/qle-screener.container'
  ),
);

const ManageDependents = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/manage-dependents/manage-dependents.view'
  ),
);

const QleRequest = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/QleRequest/qle-request.container'
  ),
);

const ChatPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Chat'
  ),
);

const ChatWindow = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Chat/ChatWindow'
  ),
);

const ChatHistoryPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Chat/ChatHistory'
  ),
);

const ChatHistoryDetailsPage = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Chat/ChatHistoryDetails'
  ),
);

const EmbeddedChat = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Chat/EmbeddedChat/embedded-chat.container'
  ),
);

const HealthNeedPageNoSolutionsBanner = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-member' */ 'apps/member-experience/pages/Chat/components/banners/no-solutions-banner.view'
  ),
);

const MemberAppRoutes = ({ children, ...props }) => {
  return (
    <Route
      render={() => (
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        <AsyncMemberApp {...props}>
          <Switch>{children}</Switch>
        </AsyncMemberApp>
      )}
    />
  );
};

export const MemberRoutes = props => {
  const {
    match,
    location: { search },
  } = props;

  // isGetCareModalOpen is used to redirect any old URLs that include "?modal=get-care" to the new Care Discovery page
  // With react router, query params can only be checked against using the search params provided via props
  const isGetCareModalOpen = search.includes('modal=get-care');
  /* Member Experience Routes */
  const { path: memberPath = '/member' } = match?.path ?? {};
  // Achievements Feature Flag
  const { data: isAchievementsEnabled, isLoading: isAchievementsLoading } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  // Onboarding Feature Flag
  const { data: isOnboardingEnabled } = useFeatureFlagQuery(ONBOARDING_ENABLED);

  const { isAboutMeEnabled, isLoading: isAboutMeLoading } =
    useIsAboutMeEnabled();

  const { data: isRewardsLayerEnabled } = useFeatureFlagQuery(
    REWARDS_EVENTS_UPDATES,
  );

  const isCareCollectionsEnabled = useIsCareCollectionsEnabled();
  const isCareDiscoveryEnabled = useIsCareDiscoveryEnabled();

  return (
    <>
      {isRewardsLayerEnabled && isAchievementsEnabled && (
        <RewardsEventController />
      )}
      <Switch>
        {/* Routes that shouldn't have a topbar can be defined in this block */}
        {isOnboardingEnabled && (
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
            component={Onboarding}
          />
        )}
        {isCareDiscoveryEnabled && isGetCareModalOpen && (
          <Redirect to={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)} />
        )}
        <Route
          path={`${memberPath}/chat/:id/:type(popout|embed)`}
          component={ChatWindow}
          exact
        />
        <Route
          path={`${getModulePath(LEAGUE_MODULE_NAMES.assessment)}/:moduleId`}
          component={AssessmentRoutes}
        />
        <Route
          path={`${memberPath}/chat/embedded/:agentType`}
          component={EmbeddedChat}
          exact
        />

        {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
        <MemberAppRoutes {...props}>
          <Route exact path={`${memberPath}/home`} component={HomeFeed} />
          <Redirect exact from={`${memberPath}/`} to={`${memberPath}/home`} />
          <Route
            exact
            path={`${memberPath}/notifications`}
            component={NotificationsPage}
          />
          <Route
            exact
            path={`${memberPath}/claims-history`}
            component={ClaimHistoryPage}
          />
          <Route
            exact
            path={`${memberPath}/claims/:claimId?`}
            component={ClaimDetailsPage}
          />
          <Route path={`${memberPath}/get-rewarded`} component={RewardsPage} />
          <Route path={`${memberPath}/profile`} component={UserProfilePage} />
          <Route
            path={`${memberPath}/profile/:tabId`}
            component={UserProfilePage}
          />
          <Route
            path={[
              getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
              getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
              getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
              getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
            ]}
            component={HealthJourneyRoutes}
          />
          {isAboutMeEnabled
            ? [
                // The old home for dashboard was under `/health-profile`.
                <Redirect
                  from={`${getModulePath(
                    LEAGUE_MODULE_NAMES.healthProfile,
                  )}/insights/:dataType/details`}
                  to={`${getModulePath(
                    LEAGUE_MODULE_NAMES.aboutMe,
                  )}/insights/:dataType/details`}
                  key="details"
                />,
                <Redirect
                  from={`${getModulePath(
                    LEAGUE_MODULE_NAMES.healthProfile,
                  )}/insights/:dataType/all-recorded-data`}
                  to={`${getModulePath(
                    LEAGUE_MODULE_NAMES.aboutMe,
                  )}/insights/:dataType/all-recorded-data`}
                  key="all-recorded-data"
                />,
                <Redirect
                  from={`${getModulePath(
                    LEAGUE_MODULE_NAMES.healthProfile,
                  )}/insights/:dataType/data-sources`}
                  to={`${getModulePath(
                    LEAGUE_MODULE_NAMES.aboutMe,
                  )}/insights/:dataType/data-sources`}
                  key="data-sources"
                />,
                <Redirect
                  from={`${getModulePath(
                    LEAGUE_MODULE_NAMES.healthProfile,
                  )}/insights`}
                  to={`${getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}/insights`}
                  key="insights"
                />,
                // When about me is enabled, health profile shouldn't be.  This redirects users from the older page to the new one
                <Redirect
                  exact
                  from={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
                  to={`${getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}`}
                  key="health-profile"
                />,
              ]
            : null}
          {isAboutMeEnabled ? (
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={AboutMeRoutes}
            />
          ) : null}
          {isAboutMeLoading ? (
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={LoadingIndicator}
            />
          ) : null}
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
            component={AsyncHealthProfile}
          />
          {/* TODO: Remove Parent Category Route */}
          <Route
            exact
            path={`${memberPath}/health-need/:category`}
            component={ParentCategoryPage}
          />
          <Route path={`${memberPath}/articles`} component={ArticlesPage} />
          <Route path={`${memberPath}/terms`} component={TermsPage} />
          <Route
            exact
            path={`${memberPath}/wallet/dependents`}
            component={ManageDependents}
          />
          <Redirect
            exact
            from={`${memberPath}/manage-plan-and-dependents`}
            to={`${memberPath}/wallet/dependents`}
          />
          <Route
            exact
            path={`${memberPath}/submit-claim`}
            component={SubmitClaimPage}
          />
          <Route
            exact
            path={`${memberPath}/edit-claim/:claimId`}
            component={SubmitClaimPage}
          />
          <Route
            exact
            path={`${memberPath}/receipt/:paymentId`}
            component={LifemarketReceiptPage}
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
            component={WalletRoutes}
          />
          <Route
            exact
            path={`${memberPath}/coverage/:benefitId?`}
            component={StandaloneCoveragePage}
          />
          <Route path={`${memberPath}/documents`} component={MyDocumentsPage} />
          <Route
            path={`${memberPath}/beneficiaries`}
            component={BeneficiariesPage}
          />
          <Route
            exact
            path={`${memberPath}/coverage-summary/:planId?`}
            component={CoverageSummaryPage}
          />
          <Route
            exact
            path={`${memberPath}/banking-info/:paymentCredentialId?`}
            component={BankingInfoPage}
          />
          <Route path={`${memberPath}/screener`} component={QleScreener} />
          <Route
            path={`${memberPath}/qle-request/:qleId`}
            component={QleRequest}
          />
          <Route
            path={`${memberPath}/chat/history`}
            component={ChatHistoryPage}
            exact
          />
          <Route
            path={`${memberPath}/chat/history/:chatId`}
            component={ChatHistoryDetailsPage}
            exact
          />

          <Route path={`${memberPath}/chat/:id?`} component={ChatPage} exact />

          {isAchievementsEnabled && (
            <Route
              exact
              path={getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements)}
              component={AchievementsRoutes}
            />
          )}
          {isAchievementsLoading && (
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements)}
              component={LoadingIndicator}
            />
          )}

          {isCareCollectionsEnabled && (
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
            />
          )}

          {isCareCollectionsEnabled ? (
            <Redirect
              from={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
              to={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
            />
          ) : (
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
              render={routeProps => (
                <HealthNeedPages
                  // Temporary Solution to pass in the Chat Banner until Chat is modularized
                  noSolutionsMessage={
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <HealthNeedPageNoSolutionsBanner {...routeProps} />
                  }
                />
              )}
            />
          )}

          {isCareDiscoveryEnabled && (
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={CareDiscoveryRoutes}
            />
          )}

          {isCareDiscoveryEnabled ? (
            <Redirect
              exact
              from={`${memberPath}/get-care`}
              to={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
            />
          ) : (
            <Redirect
              exact
              from={`${memberPath}/get-care`}
              to={`${memberPath}/home?modal=get-care`}
            />
          )}
          <Route component={NoMatch} />
        </MemberAppRoutes>
      </Switch>
    </>
  );
};

MemberAppRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

MemberRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};
