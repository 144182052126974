import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { branch, lifecycle, renderComponent } from 'recompose';
import { Loader } from 'semantic-ui-react';
import { selectReady } from './invoices.selectors';
import { EMPLOYER_INVOICES_LIFECYCLE } from './invoices.types';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { hrGetEmployerOutstandingBalance } from 'apps/employer-experience/pages/EmployerDetails/Billing/actions';
import { selectGroupId } from 'apps/employer-experience/pages/EmployerDetails/selectors';

const mapStateToProps = state => ({
  groupId: selectGroupId(state),
  isAdmin: selectUserIsAdmin(state),
});

export const withState = connect(mapStateToProps, {
  visit: EMPLOYER_INVOICES_LIFECYCLE.visit,
  exit: EMPLOYER_INVOICES_LIFECYCLE.exit,
  hrGetEmployerOutstandingBalance,
});

export const withLifecycleActions = lifecycle({
  componentDidMount() {
    this.props.visit(this.props);
    if (!this.props.isAdmin) {
      this.props.hrGetEmployerOutstandingBalance({
        groupId: this.props.groupId,
      });
    }
  },
  componentWillUnmount() {
    this.props.exit(this.props);
  },
});

const withReadyState = connect(state => ({ ready: selectReady(state) }));

const withLoadingSpinner = branch(
  props => !props.ready,
  renderComponent(() => <Loader active inline="centered" />),
);

export const withInitializer = compose(
  withState,
  withLifecycleActions,
  withReadyState,
  withLoadingSpinner,
);
