import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { InitiateQLEPresenter } from './initiate-qle-reenrollment.view';
import { getErrorMessageTranslationId, convertWithTz } from './utilities';

const validate = values => {
  const errors = {};
  if (!values.coverageStartDate) {
    errors.coverageStartDate =
      getErrorMessageTranslationId('coverageStartDate');
  }
  if (!values.enrollmentEndDate) {
    errors.enrollmentEndDate =
      getErrorMessageTranslationId('enrollmentEndDate');
  }
  if (!values.qleType) {
    errors.qleType = getErrorMessageTranslationId('qleType');
  }
  return errors;
};

export const InitiateQLE = ({
  requestInitiateQLE,
  groupId,
  userId,
  employeeName,
  employerTz,
  onClose,
}) => (
  <Formik
    initialValues={{
      coverageStartDate: '',
      enrollmentEndDate: '',
      qleType: '',
    }}
    validate={validate}
    validateOnChange={false}
    onSubmit={({ coverageStartDate, enrollmentEndDate, qleType }) => {
      requestInitiateQLE({
        groupId,
        userId,
        coverageStartDate,
        enrollmentEndDate: convertWithTz(enrollmentEndDate, employerTz),
        qleType,
        employeeName,
      });

      onClose();
    }}
  >
    {props => (
      <InitiateQLEPresenter
        // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
        {...props}
        onClose={onClose}
        employeeName={employeeName}
      />
    )}
  </Formik>
);

InitiateQLE.propTypes = {
  employeeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  requestInitiateQLE: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  employerTz: PropTypes.string.isRequired,
};
