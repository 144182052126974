import React from 'react';
import { concat, groupBy, includes, map, sortBy } from 'lodash';
import { get, keyBy, mapValues, pipe, reduce, every, isEqual } from 'lodash/fp';
import { Form, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import FieldWrapper from './components/FieldWrapper';
import Group from './components/Group';
// eslint-disable-next-line import/no-cycle -- FIXME: automatically added for existing issue
import { SUBMIT_PATH } from './generator';

export const generateGroupLayout = elementMap => group => {
  // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
  const fieldsInGroup = sortBy(group.fields, 'position');
  return (
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    <Group title={group.title} key={group.title}>
      {map(fieldsInGroup, field =>
        elementMap[field.name] ? (
          <FieldWrapper
            span={field.span}
            fieldName={field.name}
            key={field.name}
          >
            {elementMap[field.name]}
          </FieldWrapper>
        ) : null,
      )}
    </Group>
  );
};

export const indexElementsByPath = pipe(
  keyBy('path'),
  mapValues(get('element')),
);

export const groupElementsInLayout = (layoutElements, layout) => {
  const elementMap = indexElementsByPath(layoutElements);
  const sortedLayoutGroups = sortBy(layout.groups, 'position');
  return map(sortedLayoutGroups, generateGroupLayout(elementMap));
};

export const getFieldsInLayout = pipe(
  get('groups'),
  reduce(
    (fieldsInLayout, group) =>
      concat(fieldsInLayout, map(group.fields, get('name'))),
    [],
  ),
);

export const classify = (layout, fieldElements) => {
  const fieldsInLayout = getFieldsInLayout(layout);
  return groupBy(fieldElements, spec => {
    if (spec.path === SUBMIT_PATH) return 'submitFields';
    return includes(fieldsInLayout, spec.path) ? 'layoutFields' : 'otherFields';
  });
};

const allAre = value => every(pipe(get('inputType'), isEqual(value)));

const allAreHidden = allAre('hidden');

const generateGroupedForm = (fields, props, layout) => {
  const { layoutFields, otherFields, submitFields } = classify(layout, fields);
  const groupedElements = groupElementsInLayout(layoutFields, layout);
  const otherElements = map(otherFields, get('element'));
  const submitElements = map(submitFields, get('element'));
  return (
    // eslint-disable-next-line react/prop-types
    <Form autoComplete="nope" onSubmit={props.handleSubmit}>
      {groupedElements}
      {!allAreHidden(otherFields) && (
        <div className="layout-group layout-group-Other">
          {groupedElements.length > 0 && otherElements.length > 0 && (
            <Header as="h4">
              <FormattedMessage id="OTHER_FIELDS" />
            </Header>
          )}
          {otherElements}
        </div>
      )}
      {submitElements && submitElements}
    </Form>
  );
};

export default generateGroupedForm;
