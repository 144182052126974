import camelize from 'camelize';
import { get } from 'lodash';
import { isImageContentType as _isImageContentType } from '@leagueplatform/web-common';
import {
  REQUEST_USER_DOCUMENTS,
  REQUEST_REMOVE_USER_DOCUMENT,
  RESET_USER_DOCUMENTS,
  REQUEST_PIN_CLAIM_DOCUMENT,
} from './user-documents.types';

const initialState = {
  documents: [],
  forms: [],
};

const applyUserDocuments = (state, action) => {
  const { documents, forms } = camelize(action.payload.info);
  return documents
    ? {
        ...state,
        documents: documents.map(document => ({
          ...document,
          isImageContentType: _isImageContentType(document.contentType),
          name: document.name || null,
        })),
        forms,
      }
    : state;
};

const applyUserDocumentRemoved = (state, action) => {
  const userDocumentId = get(action, 'payload.info.user_document_id');
  return {
    ...state,
    documents: state.documents.filter(
      doc => doc.userDocumentId !== userDocumentId,
    ),
  };
};

const applyClaimDocumentPinned = (state, { payload }) => {
  const { claimId, contentId, contentIds, contentType } = payload;
  return {
    ...state,
    documents: [
      ...state.documents,
      {
        contentId,
        contentIds,
        contentType,
        originalClaimId: claimId,
      },
    ],
  };
};

// eslint-disable-next-line default-param-last -- FIXME: automatically added for existing issue
export const userDocumentsResourcesReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case REQUEST_USER_DOCUMENTS.SUCCEEDED:
      return applyUserDocuments(state, action);
    case REQUEST_REMOVE_USER_DOCUMENT.SUCCEEDED:
      return applyUserDocumentRemoved(state, action);
    case REQUEST_PIN_CLAIM_DOCUMENT.SUCCEEDED:
      return applyClaimDocumentPinned(state, action);
    case RESET_USER_DOCUMENTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
