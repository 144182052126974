import { createRequestTypes } from 'common/websocket-redux';

export const ADD_BENEFIT = 'benefits/ADD_BENEFIT';

export const UPDATE_BENEFIT = 'benefits/UPDATE_BENEFIT';

export const REMOVE_BENEFIT = 'benefits/REMOVE_BENEFIT';

export const RENEW_BENEFIT = 'benefits/RENEW_BENEFIT';

export const REMOVE_EMPLOYER_BENEFIT = createRequestTypes(
  'service/REMOVE_EMPLOYER_BENEFIT',
);

export const SET_EMPLOYER_BENEFITS = createRequestTypes(
  'service/SET_EMPLOYER_BENEFITS',
);

export const RENEW_EMPLOYER_BENEFIT = createRequestTypes(
  'service/RENEW_EMPLOYER_BENEFIT',
);

export const UPDATE_GROUP_CATEGORIES = createRequestTypes(
  'admin/UPDATE_GROUP_CATEGORIES',
);

export const REMOVE_GROUP_CATEGORIES = createRequestTypes(
  'admin/REMOVE_GROUP_CATEGORIES',
);
