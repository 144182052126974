import { withInitializer } from './initializer.view';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import Page from './page.view';
import {
  removeBenefit as onRemoveBenefit,
  updateBenefitStatus,
  adjustBenefitEndDate,
} from './benefits.actions';
import {
  selectEmployeeBenefits,
  selectEmployeeBenefitSummaries,
  selectGroupId,
  selectUserCatalogue,
  selectEmployeeClasses,
  selectEmployeeGroupedBenefits,
  selectEmployeeProfile,
  selectLoading,
  selectEmployeeDependentClass,
  selectChangedEmployeeBenefitClassMessage,
  selectEmployeeBenefitsWithDependentCoverage,
  selectSelectedEmployeeClass,
} from './benefits.selectors';
import { selectEmployeeUserId } from '../employee-details/employee-details.selectors';
import { selectUserIsAdmin } from 'common/state/user/role.selectors';
import { selectFeatureFlags } from 'common/state/featureFlags/feature-flags.selectors';

const dispatchers = {
  onRemoveBenefit,
  updateBenefitStatus,
  adjustBenefitEndDate,
};

const withState = connect(
  createStructuredSelector({
    benefits: selectEmployeeBenefits,
    benefitsByType: selectEmployeeBenefitsWithDependentCoverage,
    groupedBenefits: selectEmployeeGroupedBenefits,
    benefitSummaries: selectEmployeeBenefitSummaries,
    employeeDependentClass: selectEmployeeDependentClass,
    groupId: selectGroupId,
    loadingBenefits: selectLoading,
    catalogue: selectUserCatalogue,
    classes: selectEmployeeClasses,
    employee: selectEmployeeProfile,
    selectedClass: selectSelectedEmployeeClass,
    changedClassMessage: selectChangedEmployeeBenefitClassMessage,
    isAdmin: selectUserIsAdmin,
    featureFlags: selectFeatureFlags,
    employeeUserId: selectEmployeeUserId,
  }),
  dispatchers,
);

const withBenefitProps = withProps(({ employee, selectedClass, classes }) => {
  const employeeClass = classes.find(c => c.key === selectedClass)?.text;

  return {
    firstName: employee?.first_name,
    lastName: employee?.last_name,
    employeeClass,
  };
});

export default withRouter(
  compose(withState, withInitializer, withBenefitProps)(Page),
);

/* eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention -- FIXME: automatically added for existing issue */
export const __test__ = {
  withBenefitProps,
};
