import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ADMIN, HR_ADMIN } from '@leagueplatform/web-common';
import { onlyRoles } from 'common/state/user/role-based-redirect.hocs';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NoMatch } from 'apps/public/public-routes.view';
import { selectCurrentUserGroupId } from 'apps/auth/selectors';
import { GET_GROUP } from './actions';
import { selectInitialBenefitPlanId } from 'common/coverage-summary/coverage-summary.selectors';

const onlyHRAdmin = onlyRoles([HR_ADMIN, ADMIN]);

const AsyncLayout = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/layout.hoc'
  ),
);

const AsyncEmployeeSearch = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/employee-search/page.container'
  ),
);

export const EmployeeSearch = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployeeSearch {...props} />
  </AsyncLayout>
));

const AsyncEmployeeDetails = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/employee-details/employee-details.component'
  ),
);

export const EmployeeDetails = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployeeDetails {...props} />
  </AsyncLayout>
));

const AsyncAddEmployee = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/employee-profile/add-employee/add-employee.component'
  ),
);

export const AddEmployee = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncAddEmployee {...props} />
  </AsyncLayout>
));

const AsyncEmployerBenefits = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ './employer-benefits/page.container'
  ),
);

const AsyncEmployerBilling = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/employer-invoices/invoices-router'
  ),
);

export const EmployerBenefits = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployerBenefits {...props} />
  </AsyncLayout>
));

export const EmployerBilling = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployerBilling {...props} />
  </AsyncLayout>
));

const AsyncEmployerBillingInsights = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'common/billing-insights/billing-insights.component'
  ),
);

export const EmployerBillingInsights = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployerBillingInsights {...props} />
  </AsyncLayout>
));

const AsyncEmployerProfile = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ './employer-profile/employer-profile.page'
  ),
);

export const EmployerProfile = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployerProfile {...props} />
  </AsyncLayout>
));

const AsyncEmployerInsights = React.lazy(() =>
  import(
    /* webpackChunkName: 'app-employer-admin' */ 'apps/employer-experience-v2/employer-insights/employer-insights.container'
  ),
);

export const EmployerInsights = onlyHRAdmin(props => (
  <AsyncLayout>
    {/* eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue */}
    <AsyncEmployerInsights {...props} />
  </AsyncLayout>
));

const useHRAdminRoutes = () => {
  const groupId = useSelector(selectCurrentUserGroupId);
  const initialPlanId = useSelector(selectInitialBenefitPlanId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId) {
      dispatch(GET_GROUP.request(groupId));
    }
  }, [dispatch, groupId]);

  return {
    groupId,
    initialPlanId,
  };
};

export const HRAdminRoutes = () => {
  const { groupId, initialPlanId } = useHRAdminRoutes();

  return (
    <Switch>
      <Route exact path="/manage">
        <Redirect to="/manage/insights" />;
      </Route>
      <Route exact path="/manage/employees" component={EmployeeSearch} />
      <Route exact path="/manage/employees/new" component={AddEmployee} />
      <Route
        path="/manage/employees/:userId"
        render={props => (
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          <EmployeeDetails
            // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
            {...props}
            groupId={groupId}
            initialPlanId={initialPlanId}
          />
        )}
      />
      <Route path="/manage/insights" component={EmployerInsights} />
      <Route exact path="/manage/benefits" component={EmployerBenefits} />
      <Route exact path="/manage/profile" component={EmployerProfile} />
      <Route exact path="/manage/billing" component={EmployerBilling} />
      <Route
        path="/manage/billing/insights/:year/:month"
        search="?billing_division_name=billingDivisionName"
        render={props => (
          // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
          <EmployerBillingInsights {...props} groupId={groupId} />
        )}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};
