import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'common/websocket-redux';
import { assoc } from 'lodash/fp';
import { get } from 'lodash';
import { callWithDelay } from 'common/utilities/call-with-delay';
import {
  approveClaim,
  rejectClaim,
  resetClaim,
  setClaimMoreInfoNeeded,
  setClaimPendingExternalReview,
  getVendors,
  setClaimDetails,
  getClaim,
} from 'common/services';

import {
  APPROVED,
  MORE_INFORMATION_NEEDED,
  PENDING_EXTERNAL_REVIEW,
  SUBMITTED,
  REJECTED,
  UPDATE,
} from 'common/constants';
import {
  getClaimResources,
  parseDuplicateClaimWarning,
  getCategories,
} from './settlement.services';
import { readjustBenefitAmounts } from './readjust-benefit-amounts.service';
import { prepareClaimPayload } from './prepare-claim-payload';
import {
  selectDuplicateClaim,
  selectDuplicateClaimForUpdate,
  selectReferenceId,
} from './settlement.selectors';
import {
  CLAIM_SETTLEMENT_LIFECYCLE,
  GET_CLAIM_SETTLEMENT_RESOURCES,
  VENDOR_QUERY_CHANGED,
  GET_VENDORS,
  SAVE_CLAIM_VALUES_REQUESTED,
  SAVE_PARTIAL_CLAIM,
  CLAIM_FORM_SUBMITTED,
  UPDATE_CLAIM,
  APPROVE_CLAIM,
  CLAIM_APPROVAL_CANCELLED,
  RESOLVE_DUPLICATE_CLAIM,
  DUPLICATE_CLAIM_APPROVED,
  DUPLICATE_CLAIM_IDENTIFIED,
  DUPLICATE_CLAIM_REJECTED,
  READJUST_BENEFIT_AMOUNTS,
  UPDATE_CLAIM_ALLOCATION_AMOUNTS,
} from './settlement.types';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';

export function* cancelApproval() {
  yield put(APPROVE_CLAIM.cancel());
}

export function* rejectDuplicate() {
  const claim = yield select(selectDuplicateClaim);
  const referenceId = yield select(selectReferenceId);
  yield rejectClaim(
    claim.claimId,
    `Your claim ${referenceId} could not be processed because it appears to be a duplicate of claim ${claim.duplicateClaimReferenceId}.`,
  );
}

export function* approveDuplicate() {
  const claim = yield select(selectDuplicateClaimForUpdate);
  yield approveClaim(claim, true);
  yield put(APPROVE_CLAIM.cancel());
}

export function* resolveDuplicateClaim(action) {
  try {
    yield put(RESOLVE_DUPLICATE_CLAIM.start());
    switch (action.type) {
      case DUPLICATE_CLAIM_APPROVED:
        yield call(approveDuplicate);
        break;
      case DUPLICATE_CLAIM_REJECTED:
        yield call(rejectDuplicate);
        break;
      default:
        break;
    }
    yield put(RESOLVE_DUPLICATE_CLAIM.success());
    const { claimId } = action.payload;
    yield put(UPDATE_CLAIM.start());
    const claimResources = yield getClaimResources(claimId);
    yield put(UPDATE_CLAIM.success(claimResources));
  } catch (error) {
    yield put(UPDATE_CLAIM.error(error));
  }
}

export function* checkDuplicateAndApprove(claim) {
  try {
    yield approveClaim(claim);
  } catch (error) {
    const duplicateInfo = parseDuplicateClaimWarning(error);
    if (duplicateInfo) {
      yield put({
        type: DUPLICATE_CLAIM_IDENTIFIED,
        payload: duplicateInfo,
      });
      return false;
    }
    throw error;
  }
  return true;
}

export function* partialSave(action) {
  try {
    const { values } = action.payload;
    yield put(SAVE_PARTIAL_CLAIM.start());
    const {
      info: { claim_id: claimId },
    } = yield setClaimDetails(values);
    const updatedClaim = yield getClaim(claimId);
    const userId = get(updatedClaim, 'info.user_id');
    const funds = get(updatedClaim, 'info.available_funds');
    const categories = yield getCategories(userId, funds);
    yield put(
      SAVE_PARTIAL_CLAIM.success({
        ...updatedClaim,
        categories,
      }),
    );
  } catch (error) {
    yield put(SAVE_PARTIAL_CLAIM.error(error));
  }
}

export const setStatusOptimistically = (resources, status) =>
  assoc('claim.info.status', status)(resources);

export function* handleClaimSubmission(action) {
  const { values, updateType } = action.payload;
  try {
    yield put(UPDATE_CLAIM.start());
    const claim = prepareClaimPayload(values);
    switch (updateType) {
      case APPROVED:
        // eslint-disable-next-line no-case-declarations -- FIXME: automatically added for existing issue
        const approved = yield call(checkDuplicateAndApprove, claim);
        if (!approved) return yield put(UPDATE_CLAIM.cancel());
        break;
      case REJECTED:
        yield rejectClaim(claim.claim_id, claim.message);
        break;
      case MORE_INFORMATION_NEEDED:
        yield setClaimMoreInfoNeeded(claim.claim_id, claim.message);
        break;
      case PENDING_EXTERNAL_REVIEW:
        yield setClaimPendingExternalReview(claim.claim_id, claim.message);
        break;
      case SUBMITTED:
        yield resetClaim(claim.claim_id);
        break;
      case UPDATE:
        yield setClaimDetails(claim);
        break;
      default:
        throw Error(`Claim status not supported: ${claim.status}`);
    }
    const claimResources = yield call(getClaimResources, claim.claim_id);
    const resources = setStatusOptimistically(claimResources, claim.status);
    yield put(UPDATE_CLAIM.success(resources));
  } catch (error) {
    yield put(UPDATE_CLAIM.error(error));
  }
  return null;
}

export function* searchVendors(action) {
  yield request(GET_VENDORS, getVendors(action.payload.query));
}

export function* initialize(action) {
  const { claimId } = action.payload.props;
  yield request(GET_CLAIM_SETTLEMENT_RESOURCES, [getClaimResources, claimId]);
}

export function* readjustBenefitAmountsRequest(action) {
  yield put(READJUST_BENEFIT_AMOUNTS.start());
  try {
    const response = yield readjustBenefitAmounts(action.payload);
    yield put(READJUST_BENEFIT_AMOUNTS.success(response));
  } catch (error) {
    yield put(READJUST_BENEFIT_AMOUNTS.error(error));
  }
}

export function* addReadjustmentOpMessage({ error, payload }) {
  const toastMessage = error
    ? {
        type: TOAST_STATUS.ERROR,
        text: error.message || payload.info.reason,
      }
    : {
        type: TOAST_STATUS.SUCCESS,
        text: 'Allocation adjusted successfully',
      };

  yield call(toastActions.add, toastMessage);
}

export function* claimSettlementSaga() {
  yield all([
    fork(takeLatest, CLAIM_SETTLEMENT_LIFECYCLE.VISITED, initialize),
    fork(takeLatest, VENDOR_QUERY_CHANGED, callWithDelay, searchVendors),
    fork(takeLatest, SAVE_CLAIM_VALUES_REQUESTED, partialSave),
    fork(takeLatest, CLAIM_FORM_SUBMITTED, handleClaimSubmission),
    fork(takeLatest, CLAIM_APPROVAL_CANCELLED, cancelApproval),
    fork(
      takeLatest,
      UPDATE_CLAIM_ALLOCATION_AMOUNTS,
      readjustBenefitAmountsRequest,
    ),
    fork(
      takeLatest,
      [READJUST_BENEFIT_AMOUNTS.SUCCEEDED, READJUST_BENEFIT_AMOUNTS.ERRORED],
      addReadjustmentOpMessage,
    ),
    fork(
      takeLatest,
      [DUPLICATE_CLAIM_APPROVED, DUPLICATE_CLAIM_REJECTED],
      resolveDuplicateClaim,
    ),
  ]);
}
