import { get, flow, reverse } from 'lodash/fp';
import { createSelector } from 'reselect';
import { selectApp } from '../app.selectors';
import { selectEmployer } from 'apps/employer-experience/pages/EmployerDetails/selectors';

export const selectAdjustments = createSelector(
  selectApp,
  flow([get('employerAdjustments.adjustments'), reverse]),
);

export const selectUI = createSelector(
  selectApp,
  get('ui.employerAdjustments'),
);

export const selectAdjustmentsReady = createSelector(selectUI, get('ready'));

export const selectAdding = createSelector(selectUI, get('adding'));

export const selectReady = createSelector(
  [selectAdjustmentsReady],
  adjustmentsReady => adjustmentsReady,
);

export const selectGroupBillingPreference = createSelector(
  selectEmployer,
  get('billingPreference'),
);

export const selectGroupBillingDivisions = createSelector(
  selectEmployer,
  get('billingDivisions'),
);
