import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Header, Segment, Form } from 'semantic-ui-react';
import { Box } from '@leagueplatform/ui-kit';

const PREFERENCE_OPTIONS = [
  { key: 'cons', text: 'Consolidated', value: 'consolidated' },
  { key: 'div', text: 'By Division', value: 'by-division' },
];

const ERROR = 'error';
const SUCCESS = 'success';
const START = 'start';

class BillingPreference extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.getButtonMessage = this.getButtonMessage.bind(this);
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.resetUpdateStatus('billingPreference');
  }

  // eslint-disable-next-line react/sort-comp -- FIXME: automatically added for existing issue
  getButtonMessage() {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    switch (this.props.updateStatus.billingPreference) {
      case START:
        return <FormattedMessage id="SAVING" />;
      case SUCCESS:
        return <FormattedMessage id="BILLING_PREFERENCE_SAVED" />;
      case ERROR:
        return <FormattedMessage id="BILLING_PREFERENCE_ERRORED" />;
      default:
        return <FormattedMessage id="SAVE_BILLING_PREFERENCE" />;
    }
  }

  handleSelect(e, value) {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('changed', true);
    // eslint-disable-next-line react/destructuring-assignment -- FIXME: automatically added for existing issue
    this.props.setFieldValue('billingPreference', value);
  }

  render() {
    const { handleSubmit, values, updateStatus } = this.props;
    return (
      <Box>
        <Header as="h4">
          <FormattedMessage id="BILLING" />
        </Header>
        <Segment vertical>
          <Form onSubmit={handleSubmit}>
            <Form.Select
              search
              label="Billing Preference"
              options={PREFERENCE_OPTIONS}
              name="billingPreference"
              value={values.billingPreference}
              onChange={(e, { value }) => this.handleSelect(e, value)}
            />
            <Form.Button
              color={
                updateStatus.billingPreference === ERROR ? 'red' : 'violet'
              }
              disabled={!values.changed}
              loading={updateStatus.billingPreference === START}
            >
              {this.getButtonMessage()}
            </Form.Button>
          </Form>
        </Segment>
      </Box>
    );
  }
}

BillingPreference.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetUpdateStatus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props -- FIXME: automatically added for existing issue
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateStatus: PropTypes.shape({
    payment: PropTypes.string,
    billingPreference: PropTypes.string,
    fees: PropTypes.string,
    prepayment: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
};

BillingPreference.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types -- FIXME: automatically added for existing issue
  values: {},
};

export default BillingPreference;
