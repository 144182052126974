import React from 'react';
import PropTypes from 'prop-types';
import { JSONSchemaFieldPropType } from '../prop-types';
import { Toggle } from '@leagueplatform/ui-kit';
import { FieldWrapper } from './field-wrapper.view';

export const ToggleField = ({
  field,
  form,
  propSchema: { disabled, ...propSchema },
  children,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
  <FieldWrapper {...propSchema} {...props}>
    <Toggle
      // eslint-disable-next-line react/jsx-props-no-spreading -- FIXME: automatically added for existing issue
      {...field}
      onChange={e => form.setFieldValue(field.name, e.target.checked)}
      checked={field.value}
      disabled={propSchema.disabled}
    >
      {children}
    </Toggle>
  </FieldWrapper>
);

ToggleField.propTypes = {
  ...JSONSchemaFieldPropType,
  children: PropTypes.node.isRequired,
};
