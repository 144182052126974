/* eslint-disable react/style-prop-object */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { upperCase } from 'lodash';
import { compose, setDisplayName } from 'recompose';
import { Item, Label, Icon, Button, Message } from 'semantic-ui-react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { selectPaymentProps } from './payment.selectors';
import { resendPaymentSelected } from './payment.actions';

const enhance = compose(
  connect(selectPaymentProps, { resendPayment: resendPaymentSelected }),
  setDisplayName('Payment'),
);

const PaymentPresenter = ({
  amount,
  paymentStatus,
  paymentId,
  transactionNumber,
  canRetryPayment,
  error,
  resendPayment,
}) => (
  <Item className="claim-payment">
    {error && (
      <Message negative dismiss={() => {}}>
        <Message.Header>Payment Update Failed</Message.Header>
        {error}
      </Message>
    )}
    <Item.Header as="h5">
      <FormattedMessage id="PAYMENT_STATUS" />
    </Item.Header>
    <Label className={`label-${paymentStatus}`}>
      <Icon name="circle" />
      <FormattedMessage id={upperCase(paymentStatus)} />
    </Label>
    <Item.Header as="h5">
      <FormattedMessage id="PAYMENT_ID" />
    </Item.Header>
    <Item.Extra>{paymentId}</Item.Extra>
    {transactionNumber && (
      <>
        <Item.Header as="h5">
          <FormattedMessage id="INTERAC_ID" />
        </Item.Header>
        <Item.Extra>{transactionNumber}</Item.Extra>
      </>
    )}
    <Item.Header as="h5">
      <FormattedMessage id="AMOUNT" />
    </Item.Header>
    <Item.Extra>
      <FormattedNumber value={amount} style="currency" currency="CAD" />
    </Item.Extra>
    {canRetryPayment && (
      <Button color="teal" onClick={() => resendPayment(transactionNumber)}>
        <FormattedMessage id="RESEND_PAYMENT" />
      </Button>
    )}
  </Item>
);

PaymentPresenter.propTypes = {
  amount: PropTypes.number.isRequired,
  paymentStatus: PropTypes.string.isRequired,
  paymentId: PropTypes.string,
  transactionNumber: PropTypes.string,
  canRetryPayment: PropTypes.bool,
  error: PropTypes.string,
  resendPayment: PropTypes.func.isRequired,
};

PaymentPresenter.defaultProps = {
  paymentId: null,
  transactionNumber: null,
  canRetryPayment: false,
  error: null,
};

export const Payment = enhance(PaymentPresenter);
